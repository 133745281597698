import React, { Component } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';

interface RouteWithChannelButtonProps {
    text: string;
    icon: string;
    type: any;
    route: string;
    params: any;
    style: any;
    paramsConfig: any;
}

export class RouteWithStoreButton extends Component<RouteWithChannelButtonProps, any> {
    navigate = () => {
        const { route, params } = this.props;
        const onlineStoreId = params.onlineStoreId;
        if (!onlineStoreId) {
            return message.warn(services.language.getText('storeSelectorModal.pleaseSelect'));
        }
        let newRoute = `${route}/${onlineStoreId}`;
        services.navigation.goto(newRoute);
    };
    render() {
        const { text, icon, type, style } = this.props;
        return (
            <Button icon={icon} type={type} onClick={this.navigate} style={style}>
                {text}
            </Button>
        );
    }
}

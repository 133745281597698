import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { Form as AntForm } from 'antd';
import { OrderInfoProductProps } from '.';
import { language } from '@comall-backend-builder/core/lib/services';
import { GroupItems, GroupItemsRef } from './group-items';

export interface GoodsFormRef {
    openSelectProductModal: () => void;
}

const GoodsForm = forwardRef<GoodsFormRef, OrderInfoProductProps>((props, ref) => {
    const { activeKey } = props;
    const { getFieldDecorator } = props.form;
    const groupItemsRef = useRef<GroupItemsRef | null>(null);

    const { params } = props;

    useImperativeHandle(
        ref,
        () => ({
            openSelectProductModal: () => groupItemsRef.current?.openSelectProdcut(),
        }),
        []
    );

    return (
        <div style={{ display: activeKey === 1 ? 'block' : 'none' }}>
            <AntForm.Item
                label={language.getText('productAdd.basicMessage.selectProduct')}
                required
            >
                {getFieldDecorator(
                    'cskuGroups',
                    {}
                )(
                    <GroupItems
                        ref={groupItemsRef}
                        activeKey={activeKey}
                        onlineStoreIds={params?.onlineStoreIds}
                        {...props}
                    />
                )}
            </AntForm.Item>
        </div>
    );
});

export default GoodsForm;

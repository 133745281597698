import {
    tableMixin,
    flexibleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
} from '@/configs/mixin';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { PRODUCT_PRICE_MONITORING_TABLE } from '@/configs/pro-table-constants';
import { SPECS_TYPE_OPTIONS, PRODUCT_SOURCE_TYPE_OPTIONS } from '@/constants';
export const config: Config = {
    entities: {
        productPriceMonitoring: {
            apiPath: '/loader/dc-goods/admin/rskus/price/store/monitor/findAllByLoginUser',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties('string', 'productPrice.name'),
                    },
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                },
                rsku: {
                    type: 'string',
                    displayName: 'Goods ID',
                },
                specsType: {
                    type: 'string',
                    displayName: '<<productPrice.specsType>>',
                },
                price: {
                    type: 'string',
                    displayName: '<<productPrice.price>>',
                },
                sourceType: {
                    type: 'string',
                    displayName: '<<productPrice.sourceType>>',
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<productPrice.lastedModifyTime>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<productPrice.keyWords>>',
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                    controlConfig: {
                        placeholder: '<<cskuPlaceHolder>>',
                    },
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productPrice.specsType>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                sourceType: {
                    type: 'string.options.select',
                    displayName: '<<productPrice.sourceType>>',
                    options: PRODUCT_SOURCE_TYPE_OPTIONS,
                },
                merchantIdList: {
                    type: 'string.options.select',
                    displayName: '<<productPrice.merchant>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant/findMerchantList',
                    },
                },
            },
        },
    },
    components: {
        ProductPriceMonitoringView: {
            component: 'Viewport',
        },
        ProductPriceMonitoring: {
            component: 'FlexLayout',
            entity: 'productPriceMonitoring',
            direction: 'vertical',
            className: 'product-price-view',
            items: [
                {
                    component: 'ProductPriceList',
                    params: {},
                    filterConfig: {
                        component: 'ProductMonitoringFilter',
                        style: { marginBottom: 15 },
                        privilege: {
                            path: 'goods.goods_price_view.view_list',
                            level: 'full',
                        },
                    },
                    buttonConfig: {
                        component: 'ProductPriceMonitoringButtons',
                        style: { marginBottom: 15 },
                    },
                    tableConfig: {
                        privilege: {
                            path: 'goods.goods_price_view.view_list',
                            level: 'full',
                        },
                        component: 'ProductPriceMonitoringTable',
                    },
                },
            ],
        },
        ProductMonitoringFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productPrice.keyWordsPlaceHolder>>',
                    },
                },
                { property: 'csku' },
                {
                    property: 'specsType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'sourceType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'merchantIdList',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            ],
        },
        ProductPriceMonitoringButtons: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<productPrice.export>>',
                    icon: 'download',
                    type: 'primary',
                    style: {
                        flexGrow: 0,
                        marginRight: 15,
                    },
                    source: {
                        apiPath: '/dc-goods/admin/rskus/price/store/excel/take',
                    },
                    range: {
                        apiPath: '/dc-goods/admin/rskus/price/store/excel/count',
                        perPage: 100000,
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'goods.goods_price_view.export',
                        level: 'full',
                    },
                },
                {
                    component: 'PrintGoodsTagsButton',
                },
            ],
        },
        ProductPriceMonitoringTable: {
            ...tableMixin,
            component: 'ProTable',
            loadFirstPage: false,
            componentName: PRODUCT_PRICE_MONITORING_TABLE,
            className: 'product-price-table',
            columns: [
                ...getLanguageColumns('name', { width: 200 }),
                { property: 'csku', width: '140' },
                { property: 'rsku', width: '140' },
                { property: 'specsType', width: '140' },
                { property: 'price', width: '140', align: 'money' },
                { property: 'sourceType', width: '140' },
                { property: 'lastedModifyTime', width: '200', fixed: 'right' },
            ],
        },
    },
};

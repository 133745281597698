import React, { useEffect, useState, useMemo } from 'react';
import { cloneDeep, map, get, isEmpty, isEqual, concat, set } from 'lodash';
import { Button, Table, InputNumber } from 'antd';
import { modes, formats } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';

const EditableContext = React.createContext({});

class EditableCell extends React.Component<any, any> {
    renderCell = (_params: any) => {
        const {
            editable,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            onChange,
            editConfig = {},
            showType,
            prefix,
            suffix,
            defaultValue,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ display: 'block', flexShrink: 0 }}>{prefix}</span>
                        <InputNumber
                            style={{ margin: '0 5px', maxWidth: '200px' }}
                            {...editConfig}
                            placeholder={language.getText('defaultPlaceholderInput')}
                            value={get(record, `${dataIndex}`)}
                            onChange={(value) => onChange(index, value, dataIndex)}
                        />
                        <span>{suffix}</span>
                    </div>
                ) : dataIndex === 'index' ? (
                    <span>{index + 1}</span>
                ) : dataIndex === 'name' && defaultValue ? (
                    <span>{language.getText(defaultValue)}</span>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

const SalesMultiPromotionItems = React.forwardRef((props: any) => {
    const [dataSource, setDataSource] = useState<any[]>([]);

    const contentMap = props.contentMap;

    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }

        let nDataSource: any[] = props.dataSource;

        if (props.value) {
            nDataSource = props.value.map((item: any, index: number) => {
                delete item.id;
                return { ...nDataSource[index], ...item };
            });
        }
        setDataSource([...nDataSource]);

        if (!isEqual(props.value, nDataSource) && props.value) {
            props.onChange(nDataSource, props.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const onChange = (index: any, val: any, dataIndex: string) => {
        let { name, value, onChange } = props;

        let nVal = cloneDeep(value);
        if (nVal !== null && !isEmpty(nVal)) {
            //@ts-ignore
            set(nVal, `${index}.${dataIndex}`, val);
        } else {
            nVal = [{ [dataIndex]: val }];
        }

        if (onChange) {
            onChange(nVal, name);
        }
    };
    const n: any[] = contentMap.map((item: any) => ({
        ...item,
        dataIndex: `${item.key}`,
    }));

    let columns: any[] = concat(
        n,
        props.showType === 1 || props.showType === 2 || props.showType === 7 || props.showType === 8
            ? []
            : [
                  {
                      title: language.getText('actions'),
                      dataIndex: 'actions',
                      render: (_text: any, _record: any, index: number) => {
                          return (
                              <>
                                  <Button
                                      type='link'
                                      style={{ padding: '0 4px' }}
                                      onClick={() => {
                                          let nVal: any = cloneDeep(dataSource);
                                          nVal.splice(index, 1);
                                          props.onChange(nVal, props.name);
                                      }}
                                  >
                                      {language.getText('delete')}
                                  </Button>
                              </>
                          );
                      },
                  },
              ]
    ).map((col: any) => {
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                ...col,
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                reward: col.reward,
                editable: col.editable,
                prefix: col.prefix,
                suffix: col.suffix,
                showType: props.showType,
                onChange: onChange,
            }),
        };
    });

    const dataItem = useMemo(() => {
        let nVal: AnyObject = {};
        columns.forEach((i: any) => {
            if (i.editable) {
                nVal[i.key] = undefined;
            }
        });

        return nVal;
    }, [columns]);

    return (
        <>
            <EditableContext.Provider value={{}}>
                {props.showAddButton && (
                    <Button
                        type='primary'
                        style={{ marginBottom: '8px' }}
                        disabled={dataSource.length >= 10}
                        onClick={() => {
                            let nVal: any = cloneDeep(dataSource) || [];
                            if (nVal.length < 10) {
                                props.onChange(nVal.concat([dataItem]), props.name);
                            }
                        }}
                    >
                        {language.getText('salesPromotion.newItemAdded')}
                    </Button>
                )}
                <Table
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    scroll={{ x: 'fit-content' }}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={(_, index) => `${index}`}
                    pagination={false}
                />
            </EditableContext.Provider>
        </>
    );
});
export class SalesMultiPromotionItemsMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
            mode: 'multiple',
        };
        return <SalesMultiPromotionItems {...props} />;
    }
}

export class SalesMultiPromotionItemsFormat extends formats.ArrayFormat {
    validate(_rule: any, value: any, callback: any): void {
        callback();
    }
    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: Array<any> | undefined) {
        const result = map(value, (item, index) => ({
            ...item,
            sort: index,
        }));
        return { [key]: result };
    }
}

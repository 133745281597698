import React, { FC } from 'react';
import classnames from 'classnames';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { get } from 'lodash';
import { STATUS_OPTIONS } from '@/constants';

const generateConfigs = (displayField: string, modifying?: boolean) => ({
    className: classnames('tag-form', {
        'hide-891011': displayField === 'product-list',
        'hide-71011': displayField === 'product-brand',
        'hide-789': displayField === 'product-category',
    }),
    labelCol: 6,
    controlCol: 8,
    fields: [
        {
            property: 'productTag',
            displayName: services.language.getText('productTag.productTagName'),
            controlConfig: {
                placeholder: services.language.getText('productTag.pleaseInputTagName'),
            },
        },
        {
            property: 'priorityLevel',
            controlConfig: {
                min: 0,
                placeholder: services.language.getText('productTag.pleaseInputPriority'),
            },
        },
        {
            displayConfig: {
                statusConfig: STATUS_OPTIONS,
            },
            property: 'productStatus',
        },
        {
            property: 'beginTime',
            controlConfig: {
                placeholder: services.language.getText('productTag.beginTime'),
            },
        },
        {
            property: 'endTime',
            controlConfig: {
                placeholder: services.language.getText('productTag.endTime'),
            },
        },
        {
            property: 'tagType',
            modifiable: !modifying,
            rules: [{ required: true }],
            controlConfig: {
                placeholder: services.language.getText('productTag.pleaseSelectTagType'),
            },
        },
        {
            property: 'cskuIds',
            displayConfig: {
                className: 'flex-wrap',
            },
        },
        {
            property: 'brandIds',
            controlConfig: {
                allowClear: true,
                showSearch: true,
                optionFilterProp: 'children',
                placeholder: services.language.getText('productTag.pleaseSelectBrand'),
            },
            displayConfig: {
                displayType: 'dynamic',
                label: services.language.getText('productTag.productBrand'),
                maxLength: 1,
                overlayClassName: 'product-categories-overlay',
            },
        },
        {
            property: 'productBrandExclusions',
            displayConfig: {
                className: 'flex-wrap',
            },
        },
        {
            property: 'categoryIds',
            displayConfig: {
                displayType: 'dynamic',
                label: services.language.getText('productTag.productCategory'),
                maxLength: 1,
                overlayClassName: 'product-categories-overlay',
            },
            controlConfig: {
                placeholder: services.language.getText('productTag.pleaseSelectCategory'),
                treeCheckable: true,
                treeNodeLabelProp: 'display',
                allowClear: true,
                showStrategy: 'child',
                submitStrategy: 'child',
                showSearch: true,
            },
        },
        {
            property: 'productCategoryExclusions',
            displayConfig: {
                className: 'flex-wrap',
            },
        },
    ],
});

interface TagFormProps {
    componentType: 'CreateForm' | 'ModifyForm' | 'Detail';
    entity: Entity;
    forwardComponentId: string;
    displayField: string;
}

const TagFormComponent: FC<TagFormProps> = (props) => {
    const { componentType, entity, displayField, forwardComponentId } = props;
    const componentConfigs =
        componentType === 'Detail'
            ? {
                  footer: {
                      items: [
                          {
                              htmlType: 'button',
                              route: 'goBack',
                              type: 'default',
                              text: services.language.getText('back'),
                              style: {
                                  marginRight: 20,
                              },
                          },
                      ],
                      style: {
                          textAlign: 'right',
                      },
                  },
              }
            : {
                  footer: {
                      items: [
                          {
                              type: 'default',
                              text: services.language.getText('cancel'),
                              htmlType: 'button',
                              route: 'goBack',
                              style: {
                                  marginRight: 8,
                              },
                          },
                          {
                              text: services.language.getText('submit'),
                          },
                      ],
                      style: {
                          textAlign: 'right',
                      },
                  },
                  onSubmitSuccess: () => {
                      AntMessage.success(services.language.getText('successFully'));
                      services.navigation.goto('/productTag');
                  },
              };
    return React.createElement(ComponentsManager.get(componentType), {
        ...generateConfigs(displayField, componentType === 'ModifyForm'),
        ...componentConfigs,
        componentId: forwardComponentId,
        entity,
    });
};

const mapStateToProps = (state: any, props: any) => {
    const { forwardComponentId, componentType, entity } = props;
    const tagType =
        componentType !== 'Detail'
            ? get(state, `components.${forwardComponentId}.fields.tagType`)
            : get(entity, 'fields.tagType');
    let displayField = 'product-list';
    switch (tagType) {
        case '1':
            displayField = 'product-list';
            break;
        case '2':
            displayField = 'product-brand';
            break;
        case '3':
            displayField = 'product-category';
            break;
        default:
            displayField = 'product-list';
    }
    return {
        displayField,
    };
};

export const TagForm = connect(mapStateToProps)(TagFormComponent);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CreateForm, ModifyForm } from '@comall-backend-builder/components-basis';
import { actions, services } from '@comall-backend-builder/core';
import { map, mapValues, find, filter } from 'lodash';

import './index.less';

const HomeManage = (props: any) => {
    const [storeOptions, setOptions] = useState([]);
    const [result, setResult] = useState([]);
    const [formValue, setFormValue] = useState<any>({});
    const isEdit = props.formType === 'ModifyForm';

    const optionsMap = [
        {
            id: 'weapp',
            name: services.language.getText('homeManage.touchTab.1'),
        },
        {
            id: 'web',
            name: services.language.getText('homeManage.touchTab.2'),
        },
        {
            id: 'app',
            name: services.language.getText('homeManage.touchTab.3'),
        },
    ];

    useEffect(() => {
        (async () => {
            try {
                const result: any =
                    (await services.api.get(
                        {},
                        { apiPath: '/dc-cms-api/admin/new_visual_config/online_store/pull_list' }
                    )) || [];
                let options: any = map(result, (item) => ({
                    id: item.onlineStoreId,
                    name: item.onlineStoreName,
                }));

                setOptions(options);

                setResult(result);
            } catch (e) {
                //
            }
        })();
    }, []);

    useEffect(() => {
        if (isEdit) {
            setFormValue(props.entity.fields);
        }
    }, [props.entity.fields, isEdit]);

    const onFieldChange = (field: any, value: string) => {
        setFormValue((prev: any) => ({ ...prev, [field]: value }));
        props.dispatch(actions.formChangeAction(props.componentId, field, value));
    };

    const onSubmit = (event: any, fields: any) => {
        const entityFields = mapValues(fields, (field) => field.value);

        const formType = props.formType;
        entityFields.onlineStoreCode = find(
            result,
            (item: any) => item.onlineStoreId === entityFields.onlineStoreId
        )?.onlineStoreCode;

        if (formType === 'CreateForm') {
            props.entity.add(entityFields, props.params);
        }

        if (formType === 'ModifyForm') {
            props.entity.modify(entityFields, props.params);
        }
    };

    const getConfig = () => {
        const { onlineStoreId } = formValue || {};
        const nowTouchPoint =
            find(result, (item: any) => item.onlineStoreId === onlineStoreId)?.touchPoint || [];
        const touchPointOptions = filter(optionsMap, (item) => nowTouchPoint.includes(item.id));

        let fields: any = [
            { property: 'name' },
            { property: 'enabled' },
            { property: 'onlineStoreId', options: storeOptions, modifiable: !isEdit },
        ];

        if (onlineStoreId) {
            fields.push({
                property: 'touchPoint',
                options: touchPointOptions,
                modifiable: !isEdit,
            });
        }
        fields.push({ property: 'effectTime', controlConfig: { style: { width: '100%' } } });

        return fields;
    };

    const formProps = {
        ...props,
        fields: getConfig(),
        onFieldChange: onFieldChange,
        onSubmit: onSubmit,
    };

    return (
        <div style={{ padding: 20 }}>
            {props.formType === 'CreateForm' && <CreateForm {...formProps} />}
            {props.formType === 'ModifyForm' && <ModifyForm {...formProps} />}
        </div>
    );
};

export const HomeManageHandle = connect((state) => {
    return state;
})(HomeManage);

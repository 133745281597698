import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import classnames from 'classnames';

export interface IConfig {
    disabled?: boolean; // 链接禁用
    path?: string; // 跳转链接
}
interface Props {
    className?: string;
    actionType?: 'link' | 'component';
    row: any;
    text: string;
    path?: string; // 跳转链接
    options: { id: string; name: string }[];
    onMenuClick: (row: any, item: any, props: any) => {};
    component?: any;
    renderOptions?: (row: any, props: any) => { id: string; name: string }[];
    renderConfig?: (row: any, props: any) => IConfig;
}

const defaultConfig = {
    disabled: false,
    tooltip: '',
    path: '/',
};

export const HoverSelect: React.FC<Props> = (props) => {
    const {
        row,
        text,
        path,
        actionType,
        options,
        component,
        className,
        onMenuClick,
        renderConfig,
        renderOptions,
    } = props;

    let config = { ...defaultConfig, path };
    if (typeof renderConfig === 'function') {
        const renderResult = renderConfig(row, props);
        config = Object.assign({}, config, renderResult);
    }

    const renderMenuItem = (item: any) => {
        if (actionType === 'link') {
            return (
                <Link
                    to={services.interpolate(config.path || '', { ...props, options: item }) || ''}
                >
                    <span className={classnames(className, 'table-action-column-item')}>
                        {item.name}
                    </span>
                </Link>
            );
        }
        if (actionType === 'component' && component) {
            return React.createElement(ComponentsManager.get(component), {
                ...props,
                ...config,
                option: item,
            });
        }
        return (
            <Button
                className={className}
                type='link'
                onClick={() => {
                    onMenuClick && onMenuClick(row, item, props);
                }}
            >
                {item.name}
            </Button>
        );
    };

    const renderMenu = () => {
        const optionData = renderOptions ? renderOptions(row, props) : options;

        return (
            <Menu>
                {optionData.map((item: any) => (
                    <Menu.Item key={item.id}>{renderMenuItem(item)}</Menu.Item>
                ))}
            </Menu>
        );
    };

    return config.disabled ? (
        <span className={classnames(className, 'disabled')}>{text}</span>
    ) : (
        <Dropdown overlay={renderMenu()}>
            <a href={'/#'} className={className} onClick={(e) => e.preventDefault()}>
                {text}
            </a>
        </Dropdown>
    );
};

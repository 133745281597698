import { formats } from '@comall-backend-builder/types';

export class StoreImageFormat extends formats.ArrayFormat {
    /**
     * 将数据格式化为请求参数
     */
    public formatParams(key: any, value: any) {
        return { [key]: value };
    }

    /**
     * 对数据进行校验
     */
    public validate(_rule: any, _value: any, callback: any) {
        callback();
    }
}

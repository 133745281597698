import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { getLanguageColumns } from '../../mixin';
import { PICKUP_ATTRIBUTES_VALUES } from '@/constants/store';

function createFields(type: 'edit' | 'view') {
    return [
        { property: 'id', className: 'hidden' },
        {
            property: 'storeInfo.code',
            modifiable: false,
        },
        { property: 'pickStoreType', className: 'hidden' },
        ...getLanguageColumns('storeInfo.languageName', { modifiable: type === 'edit' }),
        ...getLanguageColumns('contactInformation.languageName', { modifiable: type === 'edit' }),
        {
            property: 'contactInformation.masterMobile',
            modifiable: type === 'edit',
        },
        {
            property: 'storeAddress.longitude',
            modifiable: type === 'edit',
        },
        {
            property: 'storeAddress.latitude',
            modifiable: type === 'edit',
        },
        ...getLanguageColumns('storeAddress.languageName', { modifiable: type === 'edit' }),
        {
            property: 'storeAddress.realityImages',
            modifiable: type === 'edit',
            controlConfig: {
                maxCount: 1,
                fileName: 'fileName',
                uploadType: 'multi',
                multiple: true,
                uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                mode: 'picture-card',
                uploadCompleteMessage: null,
                limitTips: {
                    limitText: '<<storeInfo.limitText>>',
                    limitUnit: '<<storeInfo.limitUnit>>',
                },
            },
            displayConfig: {
                imgWidth: 104,
                imgHeight: 104,
            },
        },
        { property: 'storeAddress.pickStoreAttribute', modifiable: type === 'edit' },
        {
            property: 'storeAddress.memberLevels',
            modifiable: type === 'edit',
            visible: (value: any) => {
                return (
                    value.storeAddress.pickStoreAttribute ===
                    PICKUP_ATTRIBUTES_VALUES.SPECIAL_PICKUP_POINT
                );
            },
        },
    ];
}

export const config: Config = {
    components: {
        EditPickStorePage: {
            component: 'FlexLayout',
            entity: 'pickStore',
            loaderType: 'get',
            direction: 'vertical',
            items: [
                {
                    component: 'EditPickStoreInfo',
                },
            ],
        },
        EditPickStoreInfo: {
            component: 'ModifyFormPlus',
            fields: createFields('edit'),
            className: 'custom-form edit-store',
            labelCol: 8,
            controlCol: 9,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('saveFully'));
                services.navigation.goto('/pickStore');
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        type: 'default',
                        text: '<<cancel>>',
                        style: {
                            marginRight: 20,
                        },
                    },
                    {
                        type: 'primary',
                        text: '<<save>>',
                    },
                ],
                style: {
                    textAlign: 'right',
                    marginTop: 20,
                },
            },
        },
        PickStoreInfoPage: {
            component: 'FlexLayout',
            entity: 'pickStore',
            loaderType: 'get',
            direction: 'vertical',
            items: [
                {
                    component: 'ViewPickStoreInfo',
                },
            ],
        },
        ViewPickStoreInfo: {
            component: 'ModifyFormPlus',
            fields: createFields('view'),
            className: 'custom-form edit-store',
            labelCol: 8,
            controlCol: 9,
        },
    },
};

import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';
/**
 * 门店营业状态
 */
export enum STORE_SERVICE_VALUES {
    /**
     * 营业中
     */
    OPENING = 'OPENING',
    /**
     * 休息中
     */
    CLOSING = 'CLOSING',
}
const STORE_SERVICE_BADGE = {
    [STORE_SERVICE_VALUES.OPENING]: 'success',
    [STORE_SERVICE_VALUES.CLOSING]: 'warning',
};
/**
 * 门店营业状态值
 */
export const STORE_SERVICE_NAMES = {
    [STORE_SERVICE_VALUES.OPENING]: language.getText('pickOnlineStore.opening'),
    [STORE_SERVICE_VALUES.CLOSING]: language.getText('pickOnlineStore.closing'),
};
/**
 * 门店营业状态候选项
 */
export const STORE_SERVICE_OPTIONS = generateOptions(
    STORE_SERVICE_VALUES,
    STORE_SERVICE_NAMES,
    (v, n) => ({ id: v, name: n, status: STORE_SERVICE_BADGE[v] })
);

/**
 * 提货点属性
 */
export enum PICKUP_ATTRIBUTES_VALUES {
    /**
     * 普通提货点
     */
    GENERAL_PICKUP_POINT = 'NORMAL',
    /**
     * 特殊提货点
     */
    SPECIAL_PICKUP_POINT = 'SPECIAL',
}

/**
 * 提货点属性值
 */
export const PICKUP_ATTRIBUTES_NAMES = {
    [PICKUP_ATTRIBUTES_VALUES.GENERAL_PICKUP_POINT]: language.getText(
        'pickOnlineStore.generalPickupPoint'
    ),
    [PICKUP_ATTRIBUTES_VALUES.SPECIAL_PICKUP_POINT]: language.getText(
        'pickOnlineStore.specialPickupPoint'
    ),
};

/**
 * 默认选中候选项
 */
export const PICKUP_ATTRIBUTES_OPTIONS = generateOptions(
    PICKUP_ATTRIBUTES_VALUES,
    PICKUP_ATTRIBUTES_NAMES
);

/**
 * 门店属性
 */
export enum STORE_ATTRIBUTES_VALUES {
    /**
     * 普通门店
     */
    GENERAL_STORE = '0',
    /**
     * 特殊门店
     */
    SPECIAL_STORET = '1',
}

/**
 * 门店属性值
 */
export const STORE_ATTRIBUTES_NAMES = {
    [STORE_ATTRIBUTES_VALUES.GENERAL_STORE]: language.getText('pickOnlineStore.generalStore'),
    [STORE_ATTRIBUTES_VALUES.SPECIAL_STORET]: language.getText('pickOnlineStore.specialStore'),
};

/**
 * 默认选中候选项
 */
export const STORE_ATTRIBUTES_OPTIONS = generateOptions(
    STORE_ATTRIBUTES_VALUES,
    STORE_ATTRIBUTES_NAMES
);

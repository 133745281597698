import { formats } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core/lib';

export class TagLevelFormat extends formats.ObjectFormat {
    validate(_rule: any, value: any, callback: any): void {
        if (value && !/^[1-9]\d{0,5}$/.test(value)) {
            callback(new Error(services.language.getText('numberError')));
            return;
        } else {
            callback();
        }
    }
    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any) {
        return { [key]: value ? value + '' : '' };
    }
    format(value: any) {
        return value || '';
    }
}

import { camelCase, get } from 'lodash';
import { DESIGN_MANAGE_LANGUAGE_OPTIONS } from '@/constants/design-manage';

/**
 * 创建多语言属性名
 *
 * @param basePropertyName 基础属性名
 * @param option 多语言选项
 */
function createPropertyName(
    basePropertyName: string,
    option: typeof DESIGN_MANAGE_LANGUAGE_OPTIONS[number]
) {
    return camelCase(`${basePropertyName}_${option.id}`);
}

/**
 * 构建多语言表单字段
 *
 * @param baseFormField 基础表单字段配置
 */
export function buildFormFieldsByLanguage(baseFormField: AnyObject = {}) {
    const formFields: AnyObject[] = [];

    for (const option of DESIGN_MANAGE_LANGUAGE_OPTIONS) {
        const propertyName = createPropertyName(baseFormField.property, option);
        const key = baseFormField.key
            ? createPropertyName(baseFormField.key, option)
            : propertyName;
        formFields.push({
            key,
            ...baseFormField,
            property: propertyName,
        });
    }

    return formFields;
}

/**
 * 构建多语表格列
 *
 * @param baseTableColumn 基础表格列配置
 */
export function buildTableColumnsByLanguage(baseTableColumn: AnyObject = {}) {
    const tableColumns: AnyObject[] = [];

    for (const option of DESIGN_MANAGE_LANGUAGE_OPTIONS) {
        const propertyName = createPropertyName(baseTableColumn.property, option);
        tableColumns.push({
            ...baseTableColumn,
            property: propertyName,
        });
    }

    return tableColumns;
}

/**
 * 构建多语言属性
 *
 * @param basePropertyName 基础属性名
 * @param baseProperty 基础属性配置
 */
export function buildPropertiesByLanguage(basePropertyName: string, baseProperty: AnyObject = {}) {
    const properties: AnyObject = {};

    for (const option of DESIGN_MANAGE_LANGUAGE_OPTIONS) {
        const propertyName = createPropertyName(basePropertyName, option);
        const displayName = `${baseProperty.displayName} (${option.name})`;
        properties[propertyName] = {
            ...baseProperty,
            displayName,
        };
    }

    return properties;
}

/**
 * 多语言map数据转换为扁平数据
 *
 * 示例
 * ```ts
 * const data = {
 *     names: {
 *         zh: '中文',
 *         en: '英文',
 *     }
 * }
 * languageDataToFields('name', data, data.names);
 * data === {
 *     nameZh: '中文',
 *     nameEn: '英文',
 * }
 * ```
 *
 * @param basePropertyName 基础属性名
 * @param dataSource 数据源
 * @returns
 */
export function languageDataToFields(
    basePropertyName: string,
    data: AnyObject,
    dataSource: AnyObject
) {
    for (const option of DESIGN_MANAGE_LANGUAGE_OPTIONS) {
        const propertyName = createPropertyName(basePropertyName, option);
        data[propertyName] = get(dataSource, `${option.id}`) || '';
    }
}

/**
 * 列表数据转换为多语言数据
 *
 * 示例
 * ```ts
 * const data = {
 *     nameZh: '中文',
 *     nameEn: '英文',
 * }
 * fieldsToLanguageData('name', data);
 * data === {
 *     names: {
 *         zh: '中文',
 *         en: '英文',
 *     },
 * }
 *```
 * @param basePropertyName 基础属性名
 * @param dataSource 数据源
 * @returns
 */
export function fieldsToLanguageData(basePropertyName: string, dataSource: AnyObject) {
    const languageData: AnyObject = {};
    for (const option of DESIGN_MANAGE_LANGUAGE_OPTIONS) {
        const propertyName = createPropertyName(basePropertyName, option);
        languageData[option.id] = dataSource[propertyName];
    }
    return languageData;
}

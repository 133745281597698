import { entities } from './entity';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { flexibleFilterForm, tableMixin } from '@/configs/mixin';

import { buildForm } from './sales-multi-form';
import { EVENT_SHOW_STATUS_OPTIONS } from '@/constants';

export const config: Config = {
    entities: entities,
    components: {
        SalesMultiPromotionView: {
            component: 'Viewport',
        },
        SalesMultiPromotionPage: {
            component: 'Card',
            entity: 'salesPromotion',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        ...flexibleFilterForm,
                        fields: [
                            { property: 'discName' },
                            { property: 'discType' },
                            { property: 'promotionStatus' },
                            { property: 'storeId' },
                        ],
                        privilege: {
                            path: 'activity_management.promotion.promotion_event_list',
                            level: 'full',
                        },
                    },
                    {
                        component: 'FlexLayout',
                        direction: 'horizontal',
                        style: {
                            display: 'block',
                            marginBottom: 15,
                        },
                        items: [
                            {
                                component: 'Button',
                                route: '/sales-promotion/sales-multi-promotion/add',
                                icon: 'plus',
                                type: 'primary',
                                text: '<<salesPromotion.newPromotions>>',
                                style: {
                                    marginRight: 15,
                                },
                                privilege: {
                                    path: 'activity_management.promotion.promotion_event_create',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                    {
                        ...tableMixin,
                        columns: [
                            { property: 'discName' },
                            { property: 'startDate' },
                            { property: 'endDate' },
                            { property: 'priority' },
                            { property: 'type' },
                            { property: 'applyType' },
                            { property: 'storeIds' },
                            {
                                property: 'status',
                                displayConfig: {
                                    statusConfig: EVENT_SHOW_STATUS_OPTIONS,
                                },
                            },
                        ],
                        actionColumn: {
                            title: '<<actions>>',
                            fixed: 'right',
                            items: [
                                {
                                    type: 'component',
                                    component: 'TableActionStateful',
                                    text: '<<disable>>',
                                    config: {
                                        actionType: 'component',
                                        component: 'TableActionToggleStatus',
                                        renderConfig: (row: any, props: any) => {
                                            return {
                                                entity: props.entity,
                                                row,
                                                linkProperty: 'labelStatus',
                                                errorStatus: true,
                                                statusApi: {
                                                    false:
                                                        '/dc-promotion/admin/promotion/{{row.id}}/status',
                                                    true:
                                                        '/dc-promotion/admin/promotion/{{row.id}}/status',
                                                },
                                                statusParams: {
                                                    false: { status: 1 },
                                                    true: { status: 0 },
                                                },
                                                statusText: {
                                                    true: '<<disable>>',
                                                    false: '<<enable>>',
                                                },
                                            };
                                        },
                                    },
                                    privilege: {
                                        path:
                                            'activity_management.promotion.promotion_event_enable',
                                        level: 'full',
                                    },
                                },
                                {
                                    text: '<<edit>>',
                                    type: 'link',
                                    path: '/sales-promotion/sales-multi-promotion/edit/{{row.id}}',
                                    privilege: {
                                        path:
                                            'activity_management.promotion.promotion_event_update',
                                        level: 'full',
                                    },
                                },
                            ],
                        },
                        privilege: {
                            path: 'activity_management.promotion.promotion_event_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        SalesMultiPromotionAddPage: {
            component: 'Card',
            entity: 'salesPromotionDetail',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        SalesMultiPromotionModifyPage: {
            component: 'Card',
            entity: 'salesPromotionDetail',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
    },
};

import { services } from '@comall-backend-builder/core';

export const loader = [
    {
        key: '/new_visual_config/topic',
        loader: {
            get: async function(params: any, config: any) {
                return services.api.get(
                    {
                        ...params,
                        type: 'topic',
                    },
                    {
                        ...config,
                        apiPath: '/dc-cms-api/admin/new_visual_config/topic',
                    }
                );
            },
            delete: async function(params: any, config: any) {
                return services.api.delete(
                    {
                        ...params,
                        type: 'topic',
                    },
                    {
                        ...config,
                        apiPath: '/dc-cms-api/admin/new_visual_config/:id',
                    }
                );
            },
        },
    },
    {
        key: '/new_visual_config/topic/page',
        loader: {
            get: async function(params: any, config: any) {
                const result: any = await services.api.get(params, {
                    ...config,
                    apiPath: '/dc-cms-api/admin/new_visual_config/topic/page/:id',
                });

                if (params.id) {
                    result.enabled = result.enabled.toString();
                    result.independent = result.independent.toString();
                }

                return result;
            },
            post: async function(params: any, config: any) {
                return services.api.post(params, {
                    ...config,
                    apiPath: '/dc-cms-api/admin/new_visual_config/topic/page',
                });
            },
            put: async function(params: any, config: any) {
                return services.api.put(params, {
                    ...config,
                    apiPath: '/dc-cms-api/admin/new_visual_config/page/:id',
                });
            },
            delete: async function(params: any, config: any) {
                return services.api.delete(params, {
                    ...config,
                    apiPath: '/dc-cms-api/admin/new_visual_config/topic/page/:id',
                });
            },
        },
    },
];

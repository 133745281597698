import { services, builder, actions } from '@comall-backend-builder/core';
import Keycloak from 'keycloak-js';
import { GlobalData, LoginUrl } from './globalData';
import { renderApp, renderNoAuthApp } from '..';
import { KV } from '@/interfaces';
// import { globalBroadcast } from './broadcast';
import moment from 'moment';
import { BUILD_TIME } from '@/env';
const { localStorage } = services;

export const STORAGE_KEY = {
    VERSION: 'version',
    KEYCLOAK_CONFIG: 'keycloakConfig',
    KEYCLOAK_OPTIONS: 'keycloakOption',
    // 动态创建，保持每个页签都有一个自己的缓存key
    LOGINED_BACK_PATH: Math.random()
        .toString(16)
        .slice(2),
};

/**
 * 刷新token提前时间，单位秒
 */
const ADVANCE_TIME = 60;

let channel: BroadcastChannel;

let timer: NodeJS.Timer | null = null;

function Log(message: string, ...options: any) {
    if (ENV.ENV == 'production') return '';
    return console.log(moment().format('YYYY-MM-DD HH:mm:ss'), message, ...options);
}

// 根绝版本号清除登录信息

function clearLoginLocationStorage() {
    const version = localStorage.get(STORAGE_KEY.VERSION);
    Log('clearLoginLocationStorage', version, ENV.VERSION);
    if (version !== BUILD_TIME) {
        services.localStorage.remove(STORAGE_KEY.KEYCLOAK_OPTIONS);
        services.localStorage.remove(STORAGE_KEY.VERSION);
    }

    localStorage.set(STORAGE_KEY.VERSION, BUILD_TIME);
}
/**
 * isForceRefresh: 是否强制刷新
 * 获取配置信息
 */
async function init(isForceRefresh = false) {
    // Initialize global settings
    builder.init({
        // 'api.token': 'demo-api-token',
        'features.i18n': true,
    });

    clearLoginLocationStorage();
    /**
     * 获取配置文件
     */
    await getInit();
    let _kc: Keycloak;
    if (process.env.NODE_ENV === 'development') {
        /**
         * 本地配置keycloak
         */
        _kc = new Keycloak({
            url: 'https://ssosit.dch-ecomplatform.com',
            realm: 'dch-ecomplatform',
            clientId: 'dch-ecomplatform-local-frontend-client',
            // @ts-ignore
            clientSecret: '8NGgnoll8x9YoSXl5jWpc5fE1l4YCfg4',
        });
    } else {
        const config: any = services.localStorage.get(STORAGE_KEY.KEYCLOAK_CONFIG);

        _kc = new Keycloak({
            url: config.url,
            clientId: config.clientId,
            realm: config.realm,
            // @ts-ignore
            clientSecret: config.clientSecret,
        });
    }
    /**
     * 本地配置keycloak
     */
    // const _kc = new Keycloak({
    //     url: 'https://ssosit.dch-ecomplatform.com',
    //     realm: 'dch-ecomplatform',
    //     clientId: 'dch-ecomplatform-local-frontend-client',
    //     // @ts-ignore
    //     clientSecret: '8NGgnoll8x9YoSXl5jWpc5fE1l4YCfg4',
    // });

    GlobalData._kc = _kc;

    let initParams = localStorage.get<any>(STORAGE_KEY.KEYCLOAK_OPTIONS);

    // 如果 localstorage 存在登录信息 则使用 存储的登录信息初始化
    if (initParams && initParams?.exp * 1000 > Date.now() && !isForceRefresh) {
        initKeycloak(_kc, { ...initParams, unForceRefresh: true });
    } else {
        initKeycloak(_kc);
    }

    channel = new BroadcastChannel('dch-listenRefresh');

    channel.addEventListener('message', (event) => {
        const payload = event.data;
        if (payload.type === 'refreshToken') {
            //@ts-ignore
            if (builder.getStore().getState().user?.token !== payload.data.token) {
                builder.getStore().dispatch(
                    actions.loginSuccessAction({
                        ...payload.data,
                    })
                );
                Log('[Keycloak] 从其他tab签获取到新的token', payload.data);
                _kc.tokenParsed = payload.data.tokenParsed;
                _kc.refreshToken = payload.data.refreshToken;
                _kc.idToken = payload.data.idToken;
                _kc.token = payload.data.token;
                // 重新用新的token信息开始刷新
                startRefresh(_kc);
            }
        }
    });
}

/**
 * keycloak初始化
 * @param _kc keycloak实例
 */
const initKeycloak = async (_kc: Keycloak, newToken?: KV) => {
    try {
        // 处理 keycloack check-login 后回到原有页面
        sessionStorage.setItem(STORAGE_KEY.LOGINED_BACK_PATH, window.location.hash);

        await _kc.init({
            onLoad: 'login-required',
            pkceMethod: 'S256',
            redirectUri: window.location.origin + `/#/`,
            enableLogging: true,
            ...newToken,
        });
        Log('[Keycloak] init', _kc);
        if (_kc.authenticated) {
            setToken(_kc);
        } else {
            _kc.login();
        }
    } catch (e) {
        console.error('error', e);
    }
};

const startRefresh = (_kc: Keycloak) => {
    const tokenMsg = _kc.tokenParsed;

    if (tokenMsg) {
        // @ts-ignore
        const expirationTime = tokenMsg.exp * 1000;
        const remainingTime = expirationTime - Date.now() - ADVANCE_TIME * 1000;

        Log(
            '[Keycloak] timeToRefresh',
            `过期时间 ${moment(new Date((tokenMsg.exp || 0) * 1000), 'hh:mm:ss').format()}`,
            `刷新频率 ${remainingTime / 1000} s`
        );

        timer && clearTimeout((timer as unknown) as number);
        timer = setTimeout(() => {
            // 当前显示在前台的tab才可以刷新token
            if (!document.hidden) {
                Log('[Keycloak] timeToRefresh', '开始刷新token');
                updateToken(_kc, (value) => {
                    console.count('[Keycloak] 接口请求次数');
                    if (value) {
                        setToken(_kc);

                        // 刷新后如页签依旧处于前台，则继续监控刷新
                        if (!document.hidden) {
                            startRefresh(_kc);
                        }
                    }
                });
            }
        }, remainingTime);
    }
};

document.addEventListener('visibilitychange', () => {
    if (!document.hidden && GlobalData._kc) {
        startRefresh(GlobalData._kc);
    }
});

let rendered = false;
/**
 * 获取完token渲染页面
 * @param _kc keycloak实例
 */
const render = (_kc: Keycloak) => {
    if (rendered) return;
    rendered = true;
    startRefresh(_kc);
    renderApp();
};

/**
 * 更新token
 * @param _kc keycloak实例
 * @param time 更新token时间
 * @param successCallback token更新后成功回调函数
 * @returns
 */
const updateToken = async (_kc: Keycloak, successCallback: (val: any) => void) => {
    return _kc
        .updateToken(ADVANCE_TIME)
        .then(successCallback)
        .catch((err) => {
            _kc.logout({ redirectUri: window.location.origin + `/#/${LoginUrl}?status=logout` });
            return Promise.reject(err);
        });
};

const setToken = (_kc: Keycloak): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            services.loading.open();
            const config = {
                customHeaders: {
                    accessToken: _kc.token || '',
                },
                apiPath: '/dc-user/user/keycloak/login',
                apiRoot: ENV.OAUTH_ROOT,
            };

            Log('登录时间');
            const res: any = await services.api.post({}, config);
            if (res.code === 1) {
                rendered = false;
                renderNoAuthApp();
            } else {
                await builder.getStore().dispatch(
                    actions.loginSuccessAction({
                        ...res.data,
                        token: _kc.token,
                    })
                );

                // 通知其它浏览器页签更新token
                channel.postMessage({
                    type: 'refreshToken',
                    data: {
                        ...res.data,
                        idToken: _kc.idToken,
                        refreshToken: _kc.refreshToken,
                        token: _kc.token,
                        tokenParsed: _kc.tokenParsed,
                    },
                });

                // 持久化
                localStorage.set(STORAGE_KEY.KEYCLOAK_OPTIONS, {
                    token: _kc.token,
                    refreshToken: _kc.refreshToken,
                    idToken: _kc.idToken,
                    exp: _kc.tokenParsed?.exp,
                });

                render(_kc);
            }
            resolve();
        } catch (e) {
            reject(e);
            rendered = false;
            _kc.logout();
            window.location.href = `#/${LoginUrl}`;
            console.error(e);
        } finally {
            services.loading.close();
        }
    });
};

/**
 * 获取配置
 */
async function getInit() {
    try {
        services.loading.open();
        const res: any = await services.api.post(
            {},
            {
                apiPath: '/dc-user/user/keycloak/checkAuthorization',
                apiRoot: ENV.OAUTH_ROOT,
            }
        );
        localStorage.set(STORAGE_KEY.KEYCLOAK_CONFIG, res);
    } catch (e) {
        console.log('e', e);
    } finally {
        services.loading.close();
    }
}

interface CheckTokenListener {
    resolved(): Promise<void> | void;
    rejected(err: any): Promise<void> | void;
}

const checkTokenListeners: CheckTokenListener[] = [];
let checkTokenRefreshing = false;
function onCheckToken(
    onSuccess: CheckTokenListener['resolved'],
    onError?: CheckTokenListener['rejected']
): Promise<void> {
    return new Promise(async (resolve, reject) => {
        if (!GlobalData._kc || !GlobalData._kc.isTokenExpired(60)) {
            onSuccess();
            resolve();

            return;
        }

        Log('token 已过期');

        checkTokenListeners.push({
            resolved() {
                onSuccess();
                resolve();
            },
            rejected(err) {
                onError?.(err);
                reject(err);
            },
        });

        if (checkTokenRefreshing) {
            return;
        }
        checkTokenRefreshing = true;

        GlobalData._kc
            .updateToken(-1)
            .then(async (value: any) => {
                console.count('[Keycloak] 接口请求次数');
                if (value) {
                    await setToken(GlobalData._kc);
                }
                checkTokenListeners.forEach(({ resolved }) => resolved());
            })
            .catch((err: any) => {
                checkTokenListeners.forEach(({ rejected }) => rejected(err));
                init();
            })
            .finally(() => {
                checkTokenRefreshing = false;
                checkTokenListeners.length = 0;
            });
    });
}

export const UserService = {
    init,
    onCheckToken,
};

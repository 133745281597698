import React, { PureComponent } from 'react';
import { interpolate } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';

interface IProps {
    className: string;
    row: KV;
    text: string;
    path: string;
    target: '_blank' | '_self';
}

/**
 * 表格链接跳转组件，可是设置 target
 */
export class TableActionLink extends PureComponent<IProps, any> {
    static defaultProps = {
        target: '_self',
    };

    render() {
        let { className, text, path, row, target } = this.props;
        const link: string = interpolate(path, { row });
        return (
            <a target={target} className={className} href={link}>
                {text}
            </a>
        );
    }
}

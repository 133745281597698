import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { Price, PriceType } from '@/components/price';

export class PriceMode extends modes.ObjectMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: PriceType, displayConfig: any) {
        return <Price price={value} {...displayConfig} />;
    }
}

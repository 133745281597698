import { cleanParams } from '@/services';
import { getStoreInfo } from '@/services/utils';
import { services } from '@comall-backend-builder/core/lib';
import { isEmpty, get as lodashGet } from 'lodash';
export default [
    {
        key: '/guide-cms-service/admin/virtual_categorys',
        loader: {
            get: async function(data: any, config: any) {
                return await services.api.get(data, config);
            },
            post: async function(params: any, config: any) {
                delete params.nameTip;
                //@ts-ignore
                const store = getStoreInfo()?.id;
                return await services.api.post({ ...params, onlineStoreId: store }, config);
            },
            put: async function(params: any, config: any) {
                delete params.nameTip;
                return services.api.put(params, config);
            },
        },
    },
    {
        key: '/guide-cms-service/admin/virtual_categorys/categorys_tree/all',
        loader: {
            get: async (data: any, config: any) => {
                let response: any = await services.api.get(data, {
                    ...config,
                    apiPath: '/guide-cms-service/admin/virtual_categorys/categorys_tree',
                });

                function format(a: any) {
                    if (isEmpty(a)) return null;
                    if (a.childTree) {
                        a.children = a.childTree.map((i: any) => format(i));
                    }
                    delete a.childTree;
                    return {
                        ...a,
                        title: a.name,
                        key: a.id,
                        isLeaf: a.hasChild === 1 ? false : true,
                    };
                }
                return isEmpty(response[0]) ? [] : [format(response[0])];
            },
        },
    },
    {
        key: '/virtual_categorys/categorys_tree/all',
        loader: {
            get: async (data: any, config: any) => {
                let response: any = await services.api.get(data, {
                    ...config,
                    apiPath: '/guide-cms-service/admin/virtual_categorys/categorys_tree/all',
                });

                function format(a: any) {
                    if (isEmpty(a)) return null;
                    if (a.childTree) {
                        a.children = a.childTree.map((i: any) => format(i));
                    }
                    delete a.childTree;
                    return {
                        ...a,
                        title: a.name,
                        key: a.id,
                        isLeaf: a.hasChild === 1 ? false : true,
                    };
                }
                return isEmpty(response[0]) ? [] : [format(response[0])];
            },
        },
    },
    {
        key: '/guide-cms-service/admin/virtual_categorys/categorys_tree',
        loader: {
            get: async function(data: any, config: any) {
                config = {
                    apiPath: '/guide-cms-service/admin/virtual_categorys/categorys_tree',
                };
                const response: any = await services.api.get(data, config);

                const treeData = [];
                for (let item of response) {
                    treeData.push({
                        ...item,
                        isLeaf: item.hasChild === 0,
                    });
                }
                return { id: data.parentId, children: treeData };
            },
        },
    },
    {
        key: '/guide-cms-service/admin/virtual_categorys/categorys_tree/categoryForm',
        loader: {
            get: async function(data: any, config: any) {
                config = {
                    apiPath: '/guide-cms-service/admin/virtual_categorys/categorys_tree',
                };
                // data.parentId = data.id;
                delete data.id;
                if (!data.parentId) {
                    data.parentId = '0';
                }
                //@ts-ignore
                const store = getStoreInfo()?.id;
                const response: any = await services.api.get(
                    { ...data, onlineStoreId: store },
                    config
                );
                if (!isEmpty(response)) {
                    const data = response.map((item: any) => {
                        return { ...item, isLeaf: item.hasChild === 0 };
                    });
                    return data;
                }
            },
        },
    },
    {
        key: '/dc-goods/admin/cskus/pageByCategory',
        loader: {
            get: async function(data: any, config: any) {
                data.virtualCategoryId = data.id;
                data.offlineCategoryId = lodashGet(data, 'offlineCategoryId[0].id', '');

                data = cleanParams(data);
                const response: any = await services.api.get(data, {
                    ...config,
                    apiPath: '/dc-goods/admin/cskus/pageByVirtualCategory',
                });
                response.result = (response.result || []).map((item: any) => ({
                    ...item,
                    name: {
                        zh: item?.name,
                        en: item?.enName,
                        jp: item?.jpName,
                        tc: item?.tcName,
                        fr: item?.frName,
                        it: item?.itName,
                        ko: item?.koName,
                    },
                    brandName: {
                        zh: item?.brandName,
                        en: item?.brandNameEn,
                        jp: item?.brandNameJp,
                        tc: item?.brandNameTc,
                        fr: item?.brandNameFr,
                        it: item?.brandNameIt,
                        ko: item?.brandNameKo,
                    },
                    sourceType: item.sourceType + '',
                }));

                return response;
            },
        },
    },
    {
        key: '/dc-goods/admin/cskus/pageByDisplayCategory',
        loader: {
            get: async function(data: any, config: any) {
                data.displayCategoryId = data.id;
                data.offlineCategoryId = lodashGet(data, 'offlineCategoryId[0].id', '');
                data = cleanParams(data);
                const response: any = await services.api.get(data, {
                    ...config,
                    apiPath: '/dc-goods/admin/cskus/pageByDisplayCategory',
                });
                response.result = (response.result || []).map((item: any) => ({
                    ...item,
                    name: {
                        zh: item.name,
                        en: item.enName,
                        jp: item.jpName,
                        tc: item.tcName,
                        fr: item.frName,
                        it: item.itName,
                        ko: item.koName,
                    },
                    brandName: {
                        zh: item.brandName,
                        en: item.brandNameEn,
                        jp: item.brandNameJp,
                        tc: item.brandNameTc,
                        fr: item.brandNameFr,
                        it: item.brandNameIt,
                        ko: item.brandNameKo,
                    },
                    sourceType: item.sourceType * 1,
                }));
                return response;
            },
        },
    },
    {
        key: '/guide-cms-service/admin/virtual_categorys/categorys_tree/onlineStore',
        loader: {
            get: async (data: any, config: any) => {
                let response: any = await services.api.get(data, {
                    ...config,
                    apiPath:
                        '/guide-cms-service/admin/virtual_categorys/categorys_tree/onlineStore',
                });

                function format(a: any) {
                    if (isEmpty(a)) return null;
                    if (a.childTree) {
                        a.children = a.childTree.map((i: any) => format(i));
                    }
                    delete a.childTree;
                    return {
                        ...a,
                        title: a.name,
                        key: a.id,
                        isLeaf: a.hasChild === 1 ? false : true,
                    };
                }
                return isEmpty(response[0]) ? [] : [format(response[0])];
            },
        },
    },
    {
        key: '/dc-goods/admin/cskus/delVirtualCategoryProduct/:csku',
        loader: {
            delete: async (data: any, config: any) => {
                const regex = /\/([^/]+)$/;
                const match = window.location.href.match(regex);

                if (match?.[1]) {
                    await services.api.delete(
                        { cskus: data.csku, virtualCategoryId: match?.[1] },
                        {
                            ...config,
                            apiPath: '/dc-goods/admin/cskus/delVirtualCategoryProduct',
                        }
                    );
                }
            },
        },
    },
];

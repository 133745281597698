import { services } from '@comall-backend-builder/core/lib';
import each from 'lodash/each';
import { getStoreInfo } from '@/services/utils';

export default [
    {
        key: '/dc-store/admin/store_organizations/userlist',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(data, config);
                each(result, (item) => {
                    item.selectable = item.isLeaf;
                });
                return result;
            },
        },
    },
    {
        key: '/dc-stock/admin/rskus/stock/edit',
        loader: {
            get: async (data: any, config: any) => {
                const result: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-stock/admin/rskus/stock/findStockEditDetail`,
                });
                const res: any = {
                    ...result,
                    source:
                        result.source === '2'
                            ? services.language.getText('productInventory.type.group')
                            : result.source === '1'
                            ? services.language.getText('productInventory.type.create')
                            : services.language.getText('productInventory.type.normal'),
                    specsType:
                        result.specsType === '1'
                            ? services.language.getText('productInventory.weighing')
                            : services.language.getText('productInventory.common'),
                };
                return res;
            },
            put: async (data: any, config: any) => {
                const parmas = {
                    csku: data.csku,
                    rsku: data.rsku,
                    safeQuantity: data.safeQuantity,
                    warnQuantity: data.warnQuantity,
                    warrantyPeriod: data.warrantyPeriod || 0,
                    storeId: data.storeId,
                };
                const result: any = await services.api.put(parmas, {
                    ...config,
                    apiPath: `/dc-stock/admin/rskus/stock/edit`,
                });
                return result;
            },
        },
    },
    {
        key: '/dc-stock/admin/rskus/stock/findStockChangeLog',
        loader: {
            get: async (data: any, config: any) => {
                console.debug(services.navigation.getPathname().split('/')[4]);
                // const csku = lodashGet(services.navigation.getPathname().split('/'), '[4]', '');
                delete data.id;
                const result: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-stock/admin/rskus/stock/findStockChangeLog?onlineStoreId=${
                        getStoreInfo().id
                    }`,
                });
                return { ...result };
            },
        },
    },
    {
        key: '/dc-stock/admin/rskus/stock/findStockChangeLogInfo',
        loader: {
            get: async (data: any, config: any) => {
                const mcsStockId = data.id;
                delete data.id;
                const result: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-stock/admin/rskus/stock/findStockChangeLogInfo/${mcsStockId}`,
                });
                return result;
            },
        },
    },
];

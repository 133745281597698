import React, { useState } from 'react';
import { Icon, Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { ComponentConfig } from '@comall-backend-builder/components-basis/lib/flex-layout';
import {
    CustomStyleComponentProps,
    ExtendedParentComponentProps,
    PrivilegeComponentProps,
} from '@comall-backend-builder/components-basis';
import { assign, omit } from 'lodash';

export interface TabPaneProps
    extends TabsProps,
        CustomStyleComponentProps,
        ExtendedParentComponentProps,
        PrivilegeComponentProps {
    /**
     * 鉴权失败占位组件
     */
    privilegeComponent?: ComponentConfig;
    /**
     * 标题
     */
    title: string;
    /**
     * 图标
     */
    icon: string;
    /**
     * 内容组件
     */
    content: ComponentConfig;
}

export interface HistoryTabsProps
    extends TabsProps,
        CustomStyleComponentProps,
        ExtendedParentComponentProps {
    /**
     * 鉴权失败占位组件
     */
    privilegeComponent?: ComponentConfig;
    items?: TabPaneProps[];
}

/**
 *  会把 activeKey 同步至 url 的页签组件
 */
export const HistoryTabs: React.FC<HistoryTabsProps> = (props) => {
    const {
        entity,
        entities,
        params,
        items = [],
        location,
        defaultActiveKey = '0',
        privilegeComponent,
        ...tabsProps
    } = props;

    const [activeKey, setActiveKey] = useState(() => params?.activeKey ?? defaultActiveKey);

    function syncHistory(nextActiveKey: string) {
        const history = services.navigation.getHistory();
        const basePath = location!.pathname.replace(/\/$/, '').replace(`/${activeKey}`, '');
        history.replace({
            pathname: `${basePath}/${nextActiveKey}`,
        });
    }

    function changeActiveKey(activeKey: string) {
        setActiveKey(activeKey);
        syncHistory(activeKey);
    }

    return (
        <Tabs {...tabsProps} activeKey={activeKey} onChange={changeActiveKey}>
            {items.map((item, index) => {
                if (
                    item.privilege &&
                    !services.privilege.check(item.privilege.path, item.privilege.level)
                ) {
                    const componentConfig = item.privilegeComponent || privilegeComponent;
                    return componentConfig
                        ? React.createElement(
                              ComponentsManager.get(componentConfig.component),
                              assign({}, omit(componentConfig, 'component'), {
                                  entity,
                                  entities,
                                  params: assign({}, params, item.params),
                              })
                          )
                        : null;
                }

                return (
                    <Tabs.TabPane
                        {...omit(item, 'content')}
                        key={String(index)}
                        tab={
                            <span>
                                {item.icon ? <Icon type={item.icon} /> : undefined}
                                {item.title}
                            </span>
                        }
                    >
                        {React.createElement(
                            ComponentsManager.get(item.content.component),
                            assign({}, omit(item.content, 'component'), {
                                entity,
                                entities,
                                params: assign({}, params, item.params),
                            })
                        )}
                    </Tabs.TabPane>
                );
            })}
        </Tabs>
    );
};

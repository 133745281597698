import React from 'react';
import { PickingCard } from './base-content';
import { API_TYPE_KEYS, ORDER_STATUS } from '../../enums';
import {
    StockOutItem,
    WaitPackageItem,
    WaitPickupItem,
    CancelledOrderItem,
    WaitPickItem,
    PickingItem,
} from './index';
import { get, isString } from 'lodash';
import { DeliveringItem } from './delivering';

/**
 * 全部订单
 */

export function AllOrderItem(props: any) {
    const { orderStatus, parentProps } = props;

    function unApiType(value: any, key: API_TYPE_KEYS) {
        return isString(value) ? value : get(value, key);
    }

    switch (orderStatus) {
        case ORDER_STATUS.STOCK_OUT:
            return <StockOutItem {...props} type='order' />;
        case ORDER_STATUS.PICKED:
            return <WaitPackageItem {...props} type='order' />;
        case ORDER_STATUS.PACKED:
            return <WaitPickupItem {...props} type='order' />;
        case ORDER_STATUS.CANCELLED:
            return <CancelledOrderItem {...props} type='order' />;
        case ORDER_STATUS.ASSEMBLED:
        case ORDER_STATUS.WAITING_PICK:
            return (
                <WaitPickItem
                    {...props}
                    parentProps={{
                        ...parentProps,
                        apiType: unApiType(parentProps.apiType, API_TYPE_KEYS.PICKING),
                    }}
                    id={props?.pickingListVo?.id}
                    orderStore={{ orderId: props.id }}
                    type='order'
                />
            );
        case ORDER_STATUS.PICKING:
            return (
                <PickingItem
                    {...props}
                    parentProps={{
                        ...parentProps,
                        apiType: unApiType(parentProps.apiType, API_TYPE_KEYS.PICKING),
                    }}
                    id={props?.pickingListVo?.id}
                    orderStore={{ orderId: props.id }}
                    type='order'
                />
            );

        case ORDER_STATUS.DELIVERING:
            return <DeliveringItem {...props} type='order' />;
        default:
            return (
                <PickingCard
                    data={props}
                    type='order'
                    orderPathname={props.parentProps.orderPathname}
                    pickPathname={props.parentProps.pickPathname}
                />
            );
    }
}

import React, { useMemo, useEffect, useRef, useState } from 'react';
import { Popover, Button, Tree } from 'antd';
import { localStorage } from '@comall-backend-builder/core/lib/services';
import { get, isEmpty } from 'lodash';
import { genColumnKey, omitUndefined, useRefFunction, columnSort } from './util';
import useMergedState from './useMergedState';
import { createContainer } from './unstated-next';

import './index.less';
import { services } from '@comall-backend-builder/core';

//列设置的选项,fixed预留暂未使用
export type ColumnsState = {
    show?: boolean;
    fixed?: 'right' | 'left' | undefined;
    order?: number;
};
type UseColumnSettingProps = {
    columns?: any[];
    persistenceKey?: string;
    changeColumns?: (columns: any[], flag: boolean) => void;
};
const useColumnSetting = (props: UseColumnSettingProps = {}) => {
    const [sortKeyColumns, setSortKeyColumns] = useState(props.columns || []);

    const onChangeColumns = (columns: any[], flag: boolean) => {
        props.changeColumns && props.changeColumns(columns, flag);
    };

    //默认全部选中
    const defaultColumnsMap = useMemo(() => {
        const columnKeyMap: Record<string, ColumnsState> = {};
        if (props.persistenceKey) {
            const storageValue: Record<string, ColumnsState> =
                get(localStorage.get('pro-tab-persistence', {}), `${props.persistenceKey}`) || {};
            if (!isEmpty(storageValue)) {
                return storageValue;
            }
        }
        //@ts-ignore
        props.columns?.forEach(({ key, fixed, show = true }, index) => {
            const columnKey = genColumnKey(key, index);
            if (columnKey) {
                columnKeyMap[columnKey] = {
                    show,
                    fixed,
                };
            }
        });
        return columnKeyMap;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.columns]);
    const [columnsMap, setColumnsMap] = useMergedState<Record<string, ColumnsState>>(() => {
        return defaultColumnsMap;
    });
    useEffect(() => {
        props.persistenceKey &&
            localStorage.set('pro-tab-persistence', {
                ...localStorage.get('pro-tab-persistence', {}),
                [props.persistenceKey]: columnsMap,
            });
    }, [columnsMap, props.persistenceKey]);
    useEffect(() => {
        const nOrgColumns = (props.columns || []).sort(columnSort(columnsMap));
        setSortKeyColumns([...nOrgColumns]);
        const newColumns =
            nOrgColumns
                .map((item: any) => {
                    //@ts-ignore
                    const config = columnsMap[item.key];
                    if (config && config.show === false) {
                        return false;
                    }
                    return item;
                })
                .filter(Boolean) || [];
        onChangeColumns(newColumns, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnsMap]);
    return { columnsMap, setColumnsMap, sortKeyColumns, setSortKeyColumns, onChangeColumns };
};

export const ColumnsContainer = createContainer<
    ReturnType<typeof useColumnSetting>,
    UseColumnSettingProps
>(useColumnSetting);

const GroupCheckboxList: React.FC<{
    draggable: boolean;
    checkable: boolean;
    columns: any[];
}> = ({ columns, draggable, checkable, ...rest }) => {
    const { columnsMap, setColumnsMap, sortKeyColumns } = ColumnsContainer.useContainer();
    const treeDataConfig = useMemo(() => {
        const checkedKeys: string[] = [];
        const loopData = (data: any[]) =>
            data.map(({ key, dataIndex, title, ...rest }) => {
                const columnKey = genColumnKey(key, rest.index);
                const config = columnsMap[columnKey || 'null'] || {
                    show: true,
                };

                if (config.show !== false) {
                    checkedKeys.push(columnKey);
                }
                return (
                    <Tree.TreeNode
                        key={columnKey}
                        title={
                            <div>
                                <div className={`${className}-tree-icon`}>
                                    <img
                                        alt=''
                                        className='header-item-logo-img'
                                        src={require('./dragIcon.png')}
                                    />
                                </div>
                                {title}
                            </div>
                        }
                    ></Tree.TreeNode>
                );
            });
        return {
            list: loopData(columns),
            keys: checkedKeys,
        };
    }, [columnsMap, columns]);
    const onCheckTree = useRefFunction((e: any) => {
        const columnKey = get(e, 'node.props.eventKey');
        const tempConfig = columnsMap[columnKey] || {};
        const newSetting = { ...tempConfig };
        if (e.checked) {
            delete newSetting.show;
        } else {
            newSetting.show = false;
        }
        const columnKeyMap = {
            ...columnsMap,
            [columnKey]: omitUndefined(newSetting) as ColumnsState,
        };
        // 如果没有值了，直接干掉他
        if (!omitUndefined(newSetting)) {
            //@ts-ignore
            delete columnKeyMap[columnKey];
        }
        setColumnsMap(columnKeyMap);
    });
    /** 移动到指定的位置 */
    const move = useRefFunction((id: React.Key, targetId: React.Key, dropPosition: number) => {
        const newMap = {
            ...columnsMap,
        };
        const newColumns = [...sortKeyColumns];
        const findIndex = newColumns.findIndex((columnKey) => columnKey.key === id);
        const targetIndex = newColumns.findIndex((columnKey) => columnKey.key === targetId);
        const isDownWord = dropPosition > findIndex;
        if (findIndex < 0) {
            return;
        }
        const targetItem = newColumns[findIndex];
        newColumns.splice(findIndex, 1);
        if (dropPosition === 0) {
            newColumns.unshift(targetItem);
        } else {
            newColumns.splice(isDownWord ? targetIndex : targetIndex + 1, 0, targetItem);
        }

        // 重新生成排序数组
        newColumns.forEach((key, order) => {
            newMap[key.key] = {
                ...(newMap[key.key] || {}),
                order,
            };
        });
        // 更新数组
        setColumnsMap(newMap);
    });
    return (
        <Tree
            checkable={checkable}
            draggable={draggable}
            onCheck={(_, e) => onCheckTree(e)}
            checkedKeys={treeDataConfig.keys}
            showLine={false}
            blockNode
            onDrop={(info) => {
                const dropKey = get(info, 'node.props.eventKey');
                const dragKey = get(info, 'dragNode.props.eventKey');
                const { dropPosition, dropToGap } = info;
                const position =
                    dropPosition === -1 || !dropToGap ? dropPosition + 1 : dropPosition;
                move(dragKey, dropKey, position);
            }}
        >
            <>{treeDataConfig.list}</>
        </Tree>
    );
};

const className = 'ColumnSetting';
interface ColumnSettingProps {
    columns: any[];
}
const ColumnSetting = (props: ColumnSettingProps) => {
    const { columnsMap, setColumnsMap, sortKeyColumns } = ColumnsContainer.useContainer();
    const columnMapRef = useRef({});
    useEffect(() => {
        if (columnsMap) {
            columnMapRef.current = JSON.parse(JSON.stringify(columnsMap));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const clearClick = useRefFunction(() => {
        setColumnsMap(columnMapRef.current);
    });
    return (
        <Popover
            arrowPointAtCenter
            title={
                <div className={`${className}-title`}>
                    {services.language.getText('columnDisplay')}
                    <Button onClick={clearClick} type='link'>
                        {services.language.getText('reset')}
                    </Button>
                </div>
            }
            overlayClassName={`${className}-overlay`}
            trigger='click'
            placement='bottomRight'
            content={
                <GroupCheckboxList
                    {...props}
                    checkable={true}
                    draggable={true}
                    columns={sortKeyColumns}
                />
            }
        >
            <Button type='link' style={{ alignSelf: 'flex-end' }}>
                {services.language.getText('columnSetting')}
            </Button>
        </Popover>
    );
};
export default ColumnSetting;

import { services } from '@comall-backend-builder/core';
import { getStoreInfo } from '@/services/utils';
export default [
    {
        key: '/dc-goods/admin/product_group',
        loader: {
            get: async function(data: any, config: any) {
                let result: any = await services.api.get(data, {
                    ...config,
                });
                result.productList = (result.productList || []).map((item: any) => ({
                    ...item,
                    name: item.productName,
                    id: item.skuId,
                }));
                result.id = data.id;
                return result;
            },

            post: async function(data: any, config: any) {
                data.productList = (data.productList || []).map((e: any) => ({
                    ...e,
                    skuId: e.id,
                }));
                return await services.api.post(
                    {
                        ...data,
                        onlineStoreId: getStoreInfo()?.id,
                    },
                    {
                        ...config,
                    }
                );
            },
            put: async function(data: any, config: any) {
                data.productList = (data.productList || []).map((e: any) => ({
                    ...e,
                    skuId: e.skuId || e.id,
                }));
                return await services.api.put(
                    {
                        ...data,
                        onlineStoreId: getStoreInfo()?.id,
                    },
                    {
                        ...config,
                    }
                );
            },
        },
    },
];

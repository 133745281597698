import { modes } from '@comall-backend-builder/types';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle, isArray, isObject, map, get, isEqual } from 'lodash';
import { default as AntSelect, SelectProps as AntSelectProps, LabeledValue } from 'antd/lib/select';

import {
    basicPropTypes,
    controlPropTypes,
    ExtendedParentComponentProps,
    CustomStyleComponentProps,
} from '@comall-backend-builder/components-basis/lib/component-props';
import { services } from '@comall-backend-builder/core';
import { Option } from '@comall-backend-builder/components-basis/lib/select';

type Value = Option | Array<Option>;

type AntdValue = LabeledValue | LabeledValue[] | undefined;

export interface SelectAutoCompleteI
    extends Pick<
            AntSelectProps,
            'mode' | 'placeholder' | 'disabled' | 'allowClear' | 'onSelect' | 'onDeselect'
        >,
        CustomStyleComponentProps,
        ExtendedParentComponentProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 当前值
     */
    value: Value;
    /**
     * 候选项集合
     */
    options: Array<Option>;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Value, name: string) => void;

    fieldSource: 'properties' | 'filters';

    selectParamKey: string;
}

export class SelectAutoComplete extends Component<SelectAutoCompleteI> {
    static defaultProps = {
        fieldSource: 'properties',
        selectParamKey: 'name',
    };
    static propTypes = {
        ...basicPropTypes,
        ...controlPropTypes,

        value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        fieldSource: PropTypes.oneOf(['properties', 'filters']),
        selectParamKey: PropTypes.string,
        // 待选项
        options: PropTypes.array.isRequired,
    };

    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = throttle(this.handleSearch.bind(this), 1000);
    }

    handleChange(value: AntdValue, option: any) {
        const { name, onChange } = this.props;

        if (onChange) {
            let newValue: Value = [];
            if (isArray(value)) {
                newValue = map(value, ({ key, label }, i) => ({
                    ...JSON.parse(get(option, `[${i}].props.data-params`) || '{}'),
                    id: key,
                    name: label as string,
                }));
            } else if (value !== null && value !== undefined) {
                newValue = {
                    ...JSON.parse(get(option, `props.data-params`) || '{}'),
                    id: value.key,
                    name: value.label as string,
                };
            }
            onChange(newValue, name);
        }
    }

    handleSearch(value: string) {
        const { entity, name, params, fieldSource, selectParamKey } = this.props;
        if (!entity) {
            throw new Error(`Componet SelectAutoComplete must have entity`);
        }

        const source = get(
            entity,
            `metainfo.${fieldSource}.${name.split('.').join('.properties.')}.source`
        );
        const method = fieldSource === 'properties' ? 'loadPropertyOptions' : 'loadFilterOptions';
        entity[method](name, source, {
            ...params,
            [selectParamKey]: value,
        });
    }

    shouldComponentUpdate(nextProps: any) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        const {
            className,
            style,
            mode,
            name,
            value,
            placeholder = services.language.getText('common.pleaseSelect'),
            disabled,
            options,
            allowClear,
            onSelect,
            onDeselect,
        } = this.props;

        let antdValue: AntdValue = undefined;
        const selectProps: { [x: string]: any; value: AntdValue } = {
            // basicPropTypes
            className,
            style,

            // controlPropTypes
            mode,
            name,
            placeholder,
            disabled,
            allowClear,
            labelInValue: true,
            showSearch: true,
            filterOption: false,
            onChange: this.handleChange,
            onSearch: this.handleSearch,
            onSelect,
            onDeselect,
            value: antdValue,
        };
        if (isArray(value)) {
            selectProps.value = value.map(({ id, name, ...rest }) => ({
                key: id,
                label: name,
            }));
        } else if (isObject(value)) {
            selectProps.value = { key: value.id, label: value.name };
        } else {
            selectProps.value = undefined;
        }

        const children = map(options, ({ id, name, ...rest }) => (
            <AntSelect.Option key={id} data-params={JSON.stringify(rest)}>
                {name}
            </AntSelect.Option>
        ));

        return <AntSelect {...selectProps}>{children}</AntSelect>;
    }
}

export class ArrayAllOptionsAutoCompleteMode extends modes.ArrayOptionsAutoCompleteMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
            mode: 'multiple',
        };
        return <SelectAutoComplete {...props} />;
    }
}

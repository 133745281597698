import { get } from 'lodash';
let counter = 0;
export function generateComponentId(prefix = 'anonymous') {
    counter++;
    return `${prefix}-componentId-${counter}`;
}

let unique = 0;
export function generateMockId(prefix = 'anonymous') {
    unique++;
    return `${prefix}-uid-${unique}`;
}

const files = {
    'login_logo.png': require('@/resources/images/login_logo.png'),
};

export function generateImg(picName: string, suffix = 'png') {
    return get(files, `${picName}.${suffix}`);
}

export function generateEmptySearchResult() {
    return {
        page: 1,
        perPage: 10,
        totalNum: 0,
        totalPage: 0,
        result: [],
    };
}

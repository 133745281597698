/**
 * 拣货小助手待拣货item
 */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { message } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { Privilege } from '@/components/privilege';
import { findOrderIdsByPickingIds } from '../../service';
import { PickingCard } from './base-content';
import { PrintButton } from '@/components/print';
import { PickingReceipt } from '@/components/print-template';
import { PATH_NAME, PICKING_STATUS } from '../../enums';
import * as pickingApi from '../../api';
interface IPickingButton extends ButtonProps {
    /**
     * 拣货单ID
     */
    ids: Array<string>;
    /**
     * 打包前的拦截处理
     */
    onBeforeClick: () => Promise<any>;
    /**
     * 接口处理完成回调
     */
    onSuccess: () => void;
    /**
     * 接口类型
     */
    apiType: string;
}

/**
 * 开始拣货按钮组件组件，支持批量
 */

function PickingButton(props: IPickingButton) {
    const { children, type, ids, onBeforeClick, onSuccess, apiType } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [receiptInfo, setReceiptInfo] = useState<any>([]);

    const handleClick = async (printRef: any) => {
        try {
            await onBeforeClick();
            setLoading(true);
            pickingApi
                .changePickingStatus(ids, PICKING_STATUS.PICKING, 'picking', { apiType })
                .then(() => {
                    return findOrderIdsByPickingIds(ids, apiType);
                })
                .then((orderIds) => {
                    return pickingApi.fetchReceiptsInfo(orderIds, 'picking', apiType);
                })
                .then(async (receiptData) => {
                    setReceiptInfo(receiptData);
                    await printRef.current.onPrint();
                    onSuccess();
                })
                .catch(errorHandle)
                .finally(() => setLoading(false));
        } catch (e) {
            return;
        }
    };

    const buttonProps = {
        children,
        type,
        loading,
        onClick: handleClick,
    };

    return (
        <PrintButton
            {...buttonProps}
            template={receiptInfo.map((item: any, index: string) => {
                return <PickingReceipt key={index} data={item} />;
            })}
        />
    );
}

PickingButton.defaultProps = {
    onBeforeClick: () => Promise.resolve(),
    onSuccess: () => {},
};

/**
 * 批量完成拣货并打印按钮组件
 */
export function BatchPickingAndPrintButton(props: any) {
    const { selectedRowKeys, parentProps } = props;

    const confirmHandler = () => {
        if (isEmpty(selectedRowKeys)) {
            message.warn(services.language.getText('picking.selectPickingRow'));
            return Promise.reject();
        }
        return Promise.resolve();
    };

    const successHandler = () => {
        parentProps.refresh();
    };

    if (isEmpty(parentProps.result)) {
        return null;
    }
    return (
        <Privilege
            path={
                parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                    ? 'picking.assistant.batch_print'
                    : 'picking_philips.assistant_philips.batch_print_philips'
            }
        >
            <PickingButton
                type='primary'
                ids={selectedRowKeys}
                onBeforeClick={confirmHandler}
                onSuccess={successHandler}
                apiType={parentProps.apiType}
            >
                {services.language.getText('picking.batchPrintPackaging')}
            </PickingButton>
        </Privilege>
    );
}

/**
 * 待拣货
 **/

export function WaitPickItem(props: any) {
    const { parentProps } = props;
    const successHandler = () => {
        parentProps.refresh();
    };

    return (
        <PickingCard
            type={props?.type}
            data={props}
            actions={
                <Privilege
                    path={
                        parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                            ? 'picking.assistant.print'
                            : 'picking_philips.assistant_philips.print_philips'
                    }
                >
                    <PickingButton
                        type='primary'
                        ids={[props.id]}
                        onSuccess={successHandler}
                        apiType={parentProps.apiType}
                    >
                        {services.language.getText('picking.starPrintPackaging')}
                    </PickingButton>
                </Privilege>
            }
            orderPathname={parentProps.orderPathname}
            pickPathname={parentProps.pickPathname}
        />
    );
}

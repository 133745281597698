import { mockFetch } from './mock';

export default [
    {
        key: '/dc-goods/admin/brand_blacklist',
        loader: {
            get: async function(data: any) {
                if (!data.id) {
                    return await mockFetch({
                        totalNum: 20,
                        page: data.page,
                        perPage: data.perPage,
                        totalPage: '2',
                        result: [
                            {
                                id: '1',
                                reason: '品牌商要求',
                                creator: '张三',
                                createTime: '020-01-01 00:00:00',
                                lastModifiedTime: '2020-01-01 00:00:00',
                            },
                        ],
                    });
                } else {
                    return await new Promise((resolve) => {
                        resolve({
                            id: 1,
                            reason: '品牌商要求',
                            brandIds: ['1', '2', '3'],
                        });
                    });
                }
            },
        },
    },
    {
        key: '/productListBlacklist',
        loader: {
            get: async function(data: any) {
                return await mockFetch({
                    totalNum: 20,
                    page: data.page,
                    perPage: data.perPage,
                    totalPage: '2',
                    result: [
                        {
                            heroImagePath: 'http://rrd.me/gm7Sg',
                            name: '修护精华露',
                            csku: '200001',
                            barCode: '20020000',
                            brandName: '雅诗兰黛',
                            reason: '品牌商要求',
                            creator: '张三',
                            createTime: '020-01-01 00:00:00',
                            lastModifiedTime: '2020-01-01 00:00:00',
                        },
                    ],
                });
            },
        },
    },
];

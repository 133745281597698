/*
 * @Author: 朱璐
 * @Date: 2022-10-17 10:55:04
 * @Description: 会员积分明细
 */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { ComponentsManager, services } from '@comall-backend-builder/core';

export const MembershipPoint = (props: any) => {
    const [info, setInfo] = useState<any>({});
    const loadDetail = async () => {
        try {
            const result = await services.api.get(
                {},
                { apiPath: `/dc-user/admin/members/${props?.params?.id}` }
            );

            setInfo(result);
        } catch (e) {}
    };
    useEffect(() => {
        loadDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Row style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '16px' }}>
                <Col span={12}>
                    {info.totalPoint || 0}
                    <br />
                    累计积分
                </Col>
                <Col span={12}>
                    {info.point || 0}
                    <br />
                    可用积分
                </Col>
            </Row>
            {React.createElement(ComponentsManager.get('MembershipPointList'), {
                ...props,
            })}
        </>
    );
};

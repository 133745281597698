import { services } from '@comall-backend-builder/core/lib';
import { isEmpty } from 'lodash';
import { getStoreInfo } from '@/services/utils';

export default [
    {
        key: '/guide-cms-service/admin/display_categorys',
        loader: {
            get: async function(data: any, config: any) {
                if (data.status === '2') delete data.status;

                if (data.id) {
                    const response = await services.api.get(data, config);
                    //@ts-ignore
                    const { validityStart, validityEnd } = response;
                    let date: any = {
                        start: validityStart || undefined,
                        end: validityEnd || '',
                    };
                    //@ts-ignore
                    if (!validityStart || !validityEnd) {
                        date = null;
                    }

                    //@ts-ignore
                    return { ...response, date };
                }
                if (!data.parentId) data.parentId = 1;
                return await services.api.get(data, config);
            },
            post: async function(params: any, config: any) {
                delete params.nameTip;
                //@ts-ignore
                const store = getStoreInfo()?.id;

                const { start: validityStart, end: validityEnd } = params.date || {};
                return await services.api.post(
                    {
                        ...params,
                        categoryList: params.categorysList.filter((i: any) => !!i),
                        validityStart,
                        validityEnd,
                        virtualCategorysList: params.virtualCategorysList.filter((i: any) => !!i),
                        onlineStoreId: store,
                        genders: [],
                        is_show_first: 1,
                        jump_sex: 0,
                    },
                    config
                );
            },
            put: async function(params: any, config: any) {
                delete params.nameTip;
                //@ts-ignore
                const store = getStoreInfo()?.id;

                const { start: validityStart, end: validityEnd } = params.date || {};
                if (params.bannerPicturePath.zh) {
                    params.bannerPicturePath.en = params.bannerPicturePath.zh;
                    params.bannerPicturePath.tc = params.bannerPicturePath.zh;
                } else {
                    params.bannerPicturePath.en = '';
                    params.bannerPicturePath.tc = '';
                    params.bannerPicturePath.zh = '';
                }

                if (params.pcBannerPicturePath.zh) {
                    params.pcBannerPicturePath.en = params.pcBannerPicturePath.zh;
                    params.pcBannerPicturePath.tc = params.pcBannerPicturePath.zh;
                } else {
                    params.pcBannerPicturePath.en = '';
                    params.pcBannerPicturePath.tc = '';
                    params.pcBannerPicturePath.zh = '';
                }

                if (params.pcPicturePath.zh) {
                    params.pcPicturePath.en = params.pcPicturePath.zh;
                    params.pcPicturePath.tc = params.pcPicturePath.zh;
                } else {
                    params.pcPicturePath.en = '';
                    params.pcPicturePath.tc = '';
                    params.pcPicturePath.zh = '';
                }

                if (params.picturePath.zh) {
                    params.picturePath.en = params.picturePath.zh;
                    params.picturePath.tc = params.picturePath.zh;
                } else {
                    params.picturePath.en = '';
                    params.picturePath.tc = '';
                    params.picturePath.zh = '';
                }

                return await services.api.put(
                    {
                        ...params,
                        virtualCategorysList: params.virtualCategorysList.filter((i: any) => !!i),
                        categoryList: params.categorysList.filter((i: any) => !!i),
                        validityStart,
                        validityEnd,
                        onlineStoreId: store,
                        genders: [],
                        is_show_first: 1,
                        jump_sex: 0,
                    },
                    config
                );
            },
            delete: async function(params: any, config: any) {
                return await services.api.delete(params, config);
            },
        },
    },
    {
        key: '/guide-cms-service/admin/display_categories/categorys_tree',
        loader: {
            get: async function(data: any, config: any) {
                config = {
                    apiPath: '/guide-cms-service/admin/display_categorys/display_tree',
                };
                let response: any = await services.api.get({ ...data, parentId: '' }, config);

                function format(a: any) {
                    if (isEmpty(a)) return null;
                    if (a.childTree) {
                        a.children = a.childTree.map((i: any) => format(i));
                    }
                    delete a.childTree;
                    return {
                        ...a,
                        title: a.name,
                        key: a.id,
                        isLeaf: a.hasChild === 1 ? false : true,
                    };
                }
                return isEmpty(response[0]) ? [] : [format(response[0])];
            },
        },
    },
    {
        key: '/guide-cms-service/admin/display_categories/categorys_tree/categoryForm',
        loader: {
            get: async function(data: any, config: any) {
                config = {
                    apiPath: '/guide-cms-service/admin/display_categorys/display_tree',
                };
                if (!data.parentId) {
                    data.parentId = '0';
                }
                delete data.id;
                //@ts-ignore
                const store = getStoreInfo()?.id;
                const response: any = await services.api.get(
                    { ...data, onlineStoreId: store },
                    config
                );
                if (!isEmpty(response)) {
                    const data = response.map((item: any) => {
                        return { ...item, isLeaf: item.hasChild === 0 };
                    });
                    return data;
                }
            },
        },
    },
    {
        key: '/guide-cms-service/categorys/tree',
        loader: {
            get: async function(params: any, config: any) {
                const { parentId } = params;
                if (!parentId) {
                    params.parentId = '-1';
                }
                delete params.id;
                const result: Array<any> = await services.api.get(params, config);
                const newResult = result.map((item: any) => {
                    const { hasChild, ...rest } = item;
                    rest.isLeaf = hasChild === 1 ? false : true;
                    return rest;
                });
                return newResult;
            },
        },
    },
];

/*
 * @Author: 朱璐
 * @Date: 2022-10-24 13:50:11
 * @Description: 店员管理form
 */

import React, { useState, useRef } from 'react';
import { Modal, Button } from 'antd';
import { get } from 'lodash';
import { ComponentsManager, Loader, services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';

const { language } = services;
const title = {
    modify: language.getText('salesclerk.modifyCode'),
    add: language.getText('salesclerk.modalAdd'),
};
const SalesclerkFormContainer: React.FC = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const form = useRef();
    if (props.type === 'modify' && !props.content) return null;
    return (
        <>
            <Button
                type={props.type === 'modify' ? 'link' : 'primary'}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                {language.getText(props.text)}
            </Button>
            <Modal
                // @ts-ignore
                title={title[props.type]}
                visible={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}
                onOk={async () => {
                    const {
                        form: { validateFieldsAndScroll }, //@ts-ignore
                    } = form.current.props;

                    validateFieldsAndScroll((err: any, values: any) => {
                        if (!err) {
                            const { type } = props;
                            const params =
                                type === 'modify'
                                    ? {
                                          ...props.row,
                                          ...values,
                                      }
                                    : { ...values, userId: get(values, 'userId.id') || '' };
                            Loader.load(type === 'modify' ? 'put' : 'post', {
                                params: { ...params },
                                apiPath: '/loader/dc-user/admin/userJob',
                            })
                                .then(() => {
                                    setShowModal(false);
                                    props.entity.search({
                                        ...props.entity.paging,
                                        ...props.entity.params,
                                    });
                                })
                                .catch((e: any) => {
                                    errorHandle(e);
                                });
                        }
                    });
                }}
            >
                {showModal &&
                    React.createElement(ComponentsManager.get(props.content.component), {
                        ...props.content,
                        wrappedComponentRef: (ref: any) => (form.current = ref),
                    })}
            </Modal>
        </>
    );
};
export const SalesclerkForm = SalesclerkFormContainer;

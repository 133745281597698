import { services, Loader } from '@comall-backend-builder/core';
import { Modal as AntModal } from 'antd';
import { KV } from '@/interfaces';

interface IOptions {
    /**
     * 模块名
     */
    name: string;
    /**
     * 接口地址
     */
    apiPath: string;
    /**
     * 接口参数
     */
    params: KV;
    [prop: string]: any;
}

/**
 * 导入数据后记录导入日志公共方法
 * @params options
 */
export function recordImportLogs(options: IOptions) {
    const { name, apiPath, params } = options;
    Loader.load('post', {
        apiPath,
        params,
    })
        .then(() => {
            AntModal.success({
                title: `${name}${services.language.getText('recordToast')}`,
            });
        })
        .catch(services.errorHandle);
}

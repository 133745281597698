/*
 * @Author: zhulu
 * @Date: 2022-10-09 15:45:15
 * @Description:导入模板地址
 */

export const IMPORT_TEMPLATE_URL = {
    /**
     * 商品管理/商品价格管理 价格导入模板
     */
    PRICE_TEMPLATE: encodeURIComponent('IMPORT PRICE.xlsx'),
    /**
     * 商品管理/线上门店选品 商品选品
     */
    PRODUCT_SELECTION: encodeURIComponent('IMPORT RSKU.xlsx'),
    /**
     * 商品管理/虚拟商品管理 虚拟分类商品导入
     */
    VIRTUAL_CATEGORY_PRODUCT: encodeURIComponent('IMPORT VIRTUAL CATEGORY PRODUCT.xlsx'),
    /**
     *商品管理/商品资料管理 新增商品资料导入
     */
    PRODUCT_DETAILS: encodeURIComponent('IMPORT UPDATE PRODUCT DETAILS.xlsx'),
    /**
     * 商品管理/商品库存管理 库存导入
     */
    PRODUCT_STOCK: encodeURIComponent('IMPORT STOCK.xlsx'),
    /**
     *品类管理/品牌管理 品牌导入
     */
    BRAND_TEMPLATE: encodeURIComponent('IMPORT BRAND.xlsx'),
    /**
     * 上下架导入
     */
    PRODUCT_SHELF: encodeURIComponent('IMPORT UPLOAD USING SKU.xlsx'),
    /** overrideCode导入 */
    OVERRIDE_CODE: encodeURIComponent('IMPORT OVERRIDECODE CSKU.xlsx'),
    /** 代金券导入 */
    CASH_COUPON: encodeURIComponent('IMPORT CASH COUPON.xlsx'),
    THIRD_PARTY_COUPON: encodeURIComponent('IMPORT OTHER COUPON.xlsx'),
    /** cms商品导入模板 */
    CMS_PRODUCT: encodeURIComponent('IMPORT HOME PRODUCT.xlsx'),
    /** 导入会员模版 */
    MEMBER_INFO: encodeURIComponent('IMPORT MEMBER INFO.xlsx'),
    /** 现货库存模版 */
    MCS_STOCK: encodeURIComponent('IMPORT MCS STOCK.xlsx'),
    /** 导入会员级别 */
    MEMBER_LEVEL: encodeURIComponent('IMPORT MEMBER LEVEL.xlsx'),
    /** 导入会员价格 */
    MEMBER_PRICE: encodeURIComponent('IMPORT MEMBER PRICE.xlsx'),
    /** 导入会员产品 */
    MEMBER_PRODUCT: encodeURIComponent('IMPORT MEMBER PRODUCT.xlsx'),
    /** 导入推荐商品 */
    RECOMMEND_PRODUCT: encodeURIComponent('IMPORT_RECOMMEND_SKU.xlsx'),
    /** 导入更新会员 */
    UPDATE_MEMBER_INFO: encodeURIComponent('IMPORT_UPDATE_MEMBER_INFO.xlsx'),
    /** url mapping模版 */
    URKL_MAPPING: encodeURIComponent('IMPORT_URLMAPPING.xlsx'),
    /** 展示分类模版 */
    DISPLAY: encodeURIComponent('IMPROT DISPLAY CATEGORY.xlsx'),
    /** 商品提货限制模版 */
    PRODUCT_PICKUP_LIMIT: encodeURIComponent('SKU PICK LIMIT.xlsx'),
    /** 商品SEO管理模版 */
    SEO: encodeURIComponent('SKU SEO IMPORT.xlsx'),
};

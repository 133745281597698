import { cloneDeep } from 'lodash';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, multipleFilterForm } from '../mixin';
import {
    DISTRIBUTION_RULES_TABLE_PICKUP,
    DISTRIBUTION_RULES_TABLE_DISTRIBUTION,
    DISTRIBUTION_RULES_TABLE_COURIER,
} from '@/configs/pro-table-constants';
const entityProperties = {
    onlineStoreName: {
        type: 'string',
        displayName: '<<storeFreightRules.onlineStoreName>>',
    },
    onlineStoreCode: {
        type: 'string',
        displayName: '<<storeFreightRules.onlineStoreCode>>',
    },
    baseFreight: {
        type: 'string',
        displayName: '<<storeFreightRules.baseFreight>>',
    },
};

const entityFilters = {
    onlineStoreId: {
        type: 'string.treeNode.cascader',
        displayName: '<<storeFreightRules.onlineStoreName>>',
        options: [],
        source: {
            apiPath: '/loader/dc-store/admin/onlineStore/own',
        },
        controlConfig: {
            allowClear: true,
        },
    },
};
const tableConfig = (componentName: string, type: string) => {
    const columns = [
        { property: 'onlineStoreName' },
        { property: 'onlineStoreCode' },
        type !== 'courier' && { property: 'baseFreight' },
    ].filter(Boolean);

    return {
        ...tableMixin,
        componentName,
        component: 'ProTable',
        rowKey: 'onlineStoreId',
        scroll: {
            x: 'max-content',
        },
        columns,
        actionColumn: {
            title: '<<actions>>',
            width: 100,
            fixed: 'right',
            items: [
                {
                    text: '<<edit>>',
                    type: 'link',
                    path:
                        type === 'pickUp'
                            ? `/storeFreightRules/edit/1/{{row.onlineStoreId}}/1`
                            : type === 'distribution'
                            ? `/storeFreightRules/edit/1/{{row.onlineStoreId}}/2`
                            : `/storeFreightRules/edit/1/{{row.onlineStoreId}}/3`,
                    privilege: {
                        path: 'delivery.goods_freight.edit',
                        level: 'full',
                    },
                },
            ],
        },
    };
};

export const config: Config = {
    entities: {
        pickUpList: {
            apiPath: '/dc-goods/admin/store/freight/store/findFreightList?roleType=1&freightType=1',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
        distributionList: {
            apiPath: '/dc-goods/admin/store/freight/store/findFreightList?roleType=1&freightType=2',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
        courierList: {
            apiPath: '/dc-goods/admin/store/freight/store/findFreightList?roleType=1&freightType=3',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
    },
    components: {
        StoreFreightRulesContainer: {
            component: 'Viewport',
        },
        StoreFreightRulesManagement: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [{ component: 'StoreFreightRulesTabs' }],
            },
        },
        StoreFreightRulesTabs: {
            component: 'HistoryTabs',
            destroyInactiveTabPane: true,
            items: [
                {
                    title: '<<storeFreightRules.pickUp>>',
                    content: {
                        component: 'StoreFreightRulesTabPickUp',
                    },
                },
                {
                    title: '<<storeFreightRules.distribution>>',
                    content: {
                        component: 'StoreFreightRulesTabDistribution',
                    },
                },
                {
                    title: '<<storeFreightRules.courier>>',
                    content: {
                        component: 'StoreFreightRulesTabCourier',
                    },
                },
            ],
        },
        StoreFreightRulesTabPickUp: {
            entity: 'pickUpList',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'StoreFreightRulesFilter',
                    privilege: {
                        path: 'delivery.goods_freight.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'Button',
                    text: '<<add>>',
                    type: 'primary',
                    style: { width: 'fit-content' },
                    route: `/storeFreightRules/add/pickup`,
                    privilege: {
                        path: 'delivery.goods_freight.add',
                        level: 'full',
                    },
                },
                {
                    component: 'StoreFreightRulesTablePickUp',
                    privilege: {
                        path: 'delivery.goods_freight.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        StoreFreightRulesTabDistribution: {
            entity: 'distributionList',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'StoreFreightRulesFilter',
                    privilege: {
                        path: 'delivery.goods_freight.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'Button',
                    text: '<<add>>',
                    type: 'primary',
                    style: { width: 'fit-content' },
                    route: `/storeFreightRules/add/distribution`,
                    privilege: {
                        path: 'delivery.goods_freight.add',
                        level: 'full',
                    },
                },
                {
                    component: 'StoreFreightRulesDistribution',
                    privilege: {
                        path: 'delivery.goods_freight.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        StoreFreightRulesTabCourier: {
            entity: 'courierList',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'StoreFreightRulesFilter' },
                {
                    component: 'Button',
                    text: '<<add>>',
                    type: 'primary',
                    style: { width: 'fit-content' },
                    route: `/storeFreightRules/add/courier`,
                },
                { component: 'StoreFreightRulesCourier' },
            ],
        },
        StoreFreightRulesFilter: {
            ...multipleFilterForm,
            className: 'global-flexible-filter-form',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            simple: {
                fieldCol: {
                    span: 8,
                },
                fields: ['onlineStoreId'],
            },
            fields: [
                {
                    property: 'onlineStoreId',
                    controlConfig: {
                        allowClear: true,
                    },
                },
            ],
        },
        StoreFreightRulesTablePickUp: tableConfig(DISTRIBUTION_RULES_TABLE_PICKUP, 'pickUp'),
        StoreFreightRulesDistribution: tableConfig(
            DISTRIBUTION_RULES_TABLE_DISTRIBUTION,
            'distribution'
        ),
        StoreFreightRulesCourier: tableConfig(DISTRIBUTION_RULES_TABLE_COURIER, 'courier'),
    },
};

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { Select } from '@comall-backend-builder/components-basis';
import { isEmpty } from 'lodash';

export class IdArraySelectMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        const { value, options, ...otherProps } = controlInfo;
        const control = {
            ...otherProps,
            options,
            mode: 'multiple',
            value: value,
        };
        return <Select {...control} />;
    }

    public getDisplayComponent(array: any, displayInfo: any): JSX.Element {
        if (isEmpty(array)) {
            return <span>-</span>;
        }

        const names = displayInfo.options
            ?.filter((option: any) => array.includes(option.id))
            .map((option: any) => option.name);
        if (isEmpty(names)) {
            return <span>-</span>;
        }

        return <span>{names.join('/')}</span>;
    }
}

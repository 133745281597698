import { services } from '@comall-backend-builder/core';
import { isEmpty, toString } from 'lodash';

function formatOnlineStoreDeliveryDate(data: any) {
    const onlineStoreDeliveryDate = {
        firstPickUpDay: data.firstPickUpDay,
        days: data.deliveryDate,
        pickType: data.supportAirPickup,
        dutyFreeDeadlineTime: data.supportAirPickup === '1' ? data.dutyFreeDeadlineTime : '',
        payDeadlineTime: data.supportAirPickup === '1' ? data.payDeadlineTime : '',
        deliveryTime: data.supportAirPickup === '1' ? data.deliveryTime : '',
        time: 1,
        unit: 'MINUTES',
        orderDeadlineTime: data.orderDeadlineTime,
        pickupTimeDay: '00:00', //页面没有 暂时给默认值
    };
    return onlineStoreDeliveryDate;
}
export const loader = [
    {
        key: '/dc-store/admin/pickOnlineStore',
        loader: {
            get: async function(data: any, config: any) {
                if (!data.id) {
                    return await services.api.get(data, config);
                }
                config.apiPath = `/dc-store/admin/pickOnlineStore/findById/${data.id}`;
                const result: any = await services.api.get(data, config);
                if (result.onlineBusinessTimes.length === 0) {
                    result.onlineBusinessTimes.push({
                        id: null,
                        startWeek: 'MONDAY',
                        endWeek: 'SUNDAY',
                        startTime: '10:00',
                        endTime: '22:00',
                        quantity: 0,
                    });
                }
                result.businessTimes = result.onlineBusinessTimes;
                result.pickUpTime = result.onlineStoreDeliveryDate.pickupTimeDay;
                result.orderDeadlineTime = result.onlineStoreDeliveryDate.orderDeadlineTime;
                result.deliveryDate = result.onlineStoreDeliveryDate.days || 1;
                result.firstPickUpDay = result.onlineStoreDeliveryDate.firstPickUpDay || 0;
                result.onlineStoreId = toString(result.onlineStoreId);
                result.pickOnlineBrands = result.pickOnlineBrandStores
                    ? result.pickOnlineBrandStores
                    : null;
                result.supportAirPickup = result.onlineStoreDeliveryDate.pickType + '';
                result.dutyFreeDeadlineTime =
                    result.onlineStoreDeliveryDate.dutyFreeDeadlineTime || '16:00';
                result.payDeadlineTime = result.onlineStoreDeliveryDate.payDeadlineTime || '16:00';
                result.deliveryTime = result.onlineStoreDeliveryDate.deliveryTime || '16:00';
                if (!isEmpty(result.pickOnlineMerchants)) {
                    result.pickOnlineMerchants = result.pickOnlineMerchants.map(
                        (item: any) => item.merchantId
                    );
                }
                delete result.onlineBusinessTimes;

                return result;
            },
            post: async function(data: any, config: any) {
                if (data.deliveryDate < data.firstPickUpDay) {
                    return Promise.reject({
                        response: {
                            body: {
                                message: services.language.getText(
                                    'storeChannels.deliveryDateError'
                                ),
                            },
                        },
                    });
                }
                const params = {
                    ...data,
                    pickStoreId: data.id,
                    onlineStoreDeliveryDate: formatOnlineStoreDeliveryDate(data),
                    onlineBusinessTimes: data.businessTimes,
                    pickOnlineBrands: null,
                    pickOnlineMerchants: data.pickOnlineMerchants.map((item: string) => ({
                        merchantId: item,
                    })),
                };
                delete params['firstPickUpDay'];
                delete params['id'];
                delete params['deliveryDate'];
                delete params['earliestDeliveryTime'];
                delete params['orderDeadlineTime'];
                delete params['pickUpTime'];
                delete params['businessTimes'];
                delete params['pickType'];
                delete params['payDeadlineTime'];
                delete params['deliveryTime'];
                return await services.api.post(params, config);
            },
            put: async function(data: any, config: any) {
                config.apiPath = `/dc-store/admin/pickOnlineStore/update/${data.id}`;

                if (!isEmpty(data.pickOnlineMerchants)) {
                    data.pickOnlineMerchants = data.pickOnlineMerchants.map((item: string) => ({
                        merchantId: item,
                    }));
                }
                if (data.deliveryDate < data.firstPickUpDay) {
                    return Promise.reject({
                        body: {
                            message: services.language.getText('storeChannels.deliveryDateError'),
                        },
                    });
                }
                const params = {
                    ...data,
                    onlineStoreDeliveryDate: formatOnlineStoreDeliveryDate(data),
                    onlineBusinessTimes: data.businessTimes,
                    pickOnlineBrands: null,
                };

                delete params['businessTimes'];
                delete params['firstPickUpDay'];
                delete params['deliveryDate'];
                delete params['earliestDeliveryTime'];
                delete params['orderDeadlineTime'];
                delete params['pickUpTime'];
                delete params['pickType'];
                delete params['payDeadlineTime'];
                delete params['deliveryTime'];
                return await services.api.put(params, config);
            },
        },
    },
];

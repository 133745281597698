/*
 * @Author: zhulu
 * @Date: 2022-12-01 09:31:43
 * @Description: 自定义viewport
 */
import { connect } from 'react-redux';
import React, { useEffect, createElement, useState } from 'react';
import { Spin } from 'antd';
import { ComponentsManager } from '@comall-backend-builder/core';
import { builder, actions } from '@comall-backend-builder/core';
import { get as lodashGet } from 'lodash';
import {
    CustomStyleComponentProps,
    ExtendedParentComponentProps,
} from '@comall-backend-builder/components-basis/lib/component-props';
import { STORAGE_KEY } from '@/utils/user-service';

export interface ViewportConfig extends CustomStyleComponentProps, ExtendedParentComponentProps {
    content: any;
}
export const SelfViewport = connect(
    (state) => {
        // @ts-ignore
        return { user: state?.user };
    },
    () => {}
)((props: any) => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (lodashGet(props.user, 'accessToken')) {
            if (lodashGet(props.user, 'privileges.length', 0) <= 0) {
                const {
                    entities: { privileges },
                } = props;

                builder.getStore().dispatch(actions.getPrivilegeAction(privileges, props.user));
            } else {
                setTimeout(() => {
                    setReady(true);
                }, 200);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lodashGet(props.user, 'accessToken'), lodashGet(props.user, 'privileges.length')]);

    useEffect(() => {
        if (ready) {
            // 需要等待 builder 初始化完成 后 再跳转
            if (sessionStorage.getItem(STORAGE_KEY.LOGINED_BACK_PATH)) {
                const path = sessionStorage.getItem(STORAGE_KEY.LOGINED_BACK_PATH);
                window.location.href = path ? `/${path}` : '/';
                sessionStorage.removeItem(STORAGE_KEY.LOGINED_BACK_PATH);
            }
        }
    }, [ready]);

    const { content, ...other } = props;
    const { component, ...rest } = content;

    if (!ready)
        return (
            <div
                style={{
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Spin tip='Loading...'></Spin>
            </div>
        );
    return (
        <div>{createElement(ComponentsManager.get(content.component), { ...rest, ...other })}</div>
    );
});

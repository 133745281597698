import React, { FC } from 'react';

import { formatNumber } from '@/services';
import { isString } from 'lodash';

export interface PriceType {
    amount: number;
    unit: string;
    isocode: string;
    suffix: string;
}

export enum Size {
    mini = 10,
    small = 12,
    base = 14,
    big = 16,
    large = 18,
    larger = 20,
    bigger = 22,
}

export enum Color {
    red = '#c0311a',
    green = '#06a16c',
    black = 'rgba(0, 0, 0, 0.65)',
    gray = '#767676',
}

enum Weight {
    bold = 'bold',
    normal = 'normal',
}

export interface PriceProps {
    size?: Size;
    color?: Color;
    weight?: Weight;
    price: PriceType;
    dividing?: boolean;
    showNegative?: boolean;
    className?: string;
}

const defaultPrice: PriceType = {
    amount: 0.0,
    unit: '',
    isocode: '',
    suffix: '',
};

export const Price: FC<PriceProps> = (props) => {
    const { price, size, color, weight, className } = props;
    const newPrice = price || defaultPrice;
    const amount = isString(newPrice) ? newPrice : formatNumber(newPrice.amount);
    const showAmount = amount.replace(/-/, '');

    return (
        <span
            className={`price ${className}`}
            style={{
                color,
                fontSize: `${size}px`,
                fontWeight: weight,
            }}
        >
            <span>{showAmount || '0.00'}</span>
        </span>
    );
};

Price.defaultProps = {
    price: {
        amount: 0,
        unit: '$',
        isocode: 'USD',
        suffix: '',
    },
    showNegative: false,
    // color: Color.black,
    size: Size.base,
    weight: Weight.normal,
    dividing: true,
    className: '',
};

import { Config } from '@comall-backend-builder/core/lib/parser';
import { formFooter } from '@/configs/mixin';

export const config: Config = {
    entities: {
        exportLiability: {
            apiPath: '/loader/dc-order/admin/report/liability/export',
            properties: {
                storeId: {
                    type: 'string.options.select',
                    displayName: '<<exportLiability.store>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/findAll',
                    },
                    rules: [{ required: true }],
                },
                createDate: {
                    type: 'object.dateRange',
                    displayName: '<<exportLiability.time>>',
                    controlConfig: {
                        style: { width: '100%' },
                    },
                    rules: [{ required: true }],
                },
            },
        },
    },
    components: {
        ExportLiabilityPage: {
            component: 'Card',
            bordered: false,
            title: '<<exportLiability.breadcrumbName>>',
            content: {
                component: 'Card',
                type: 'inner',
                title: '<<exportLiability.title>>',
                content: {
                    component: 'ExportLiabilityLayout',
                },
            },
        },
        ExportLiabilityLayout: {
            component: 'CreateForm',
            entity: 'exportLiability',
            footer: formFooter,
            labelCol: 3,
            controlCol: 8,
            fields: [
                {
                    property: 'storeId',
                },
                {
                    property: 'createDate',
                },
            ],
        },
    },
};

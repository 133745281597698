import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';

import '../index.less';
import { OrderInfoProductProps } from '.';

interface IProps extends OrderInfoProductProps {
    visible: boolean;
    handleCancel: Function;
    handleSelect: Function;
}

export interface AddressItem {
    [key: string]: any;
}

export const RegIncludingCN = /.*[\u4e00-\u9fa5]+.*$/;

/**
 * 姓名格式化
 * 中文 姓名 英文 名 姓
 * @params firstName 名 lastName 姓
 */
export function nameFormat({ firstName, lastName }: { firstName?: string; lastName?: string }) {
    return RegIncludingCN.test(`${firstName} ${lastName}`)
        ? `${lastName || ''}${firstName || ''}`
        : `${firstName || ''} ${lastName || ''}`;
}

export default (props: IProps) => {
    const [address, setAddress] = useState<AddressItem[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (props.visible) {
            setLoading(true);
            services.api
                .get(
                    {
                        onlineStoreId: props.params?.onlineStoreIds,
                        memberId: props.memberInfo?.memberId,
                    },
                    {
                        apiPath: `/dc-checkout-api/admin/offline/checkout/memberAddress/findByMember`,
                    }
                )
                .then((res: any) => {
                    setAddress(res);
                })
                .catch((error) => {
                    errorHandle(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.visible]);
    return (
        <Modal
            wrapClassName='select-address-modal'
            visible={props.visible}
            onCancel={() => props.handleCancel()}
            closable={false}
            footer={[
                <Button onClick={() => props.handleCancel()}>
                    {language.getText('common.cancel')}
                </Button>,
            ]}
        >
            <Spin spinning={loading}>
                <div className='select-member-address'>
                    {address.map((item: AddressItem) => {
                        return (
                            <div
                                key={item.id}
                                onClick={() => props.handleSelect(item)}
                                className='select-member-address-item'
                            >
                                <div className='address-item-name'>
                                    {nameFormat({
                                        firstName: item.firstName,
                                        lastName: item.lastName,
                                    })}
                                </div>
                                <div className='address-item-text'>{item.mobile}</div>
                                <div className='address-item-text'>{`${item.parentRegionName} ${item.childRegionName}`}</div>
                                <div className='address-item-text'>{`${item.streetAddress} ${item.houseNumber}`}</div>
                                <Button
                                    disabled={!item.storeCanDelivery}
                                    className='address-item-select'
                                    type='primary'
                                    size='small'
                                >
                                    {language.getText('offlineOrder.create.selectText')}
                                </Button>
                            </div>
                        );
                    })}
                </div>
            </Spin>
        </Modal>
    );
};

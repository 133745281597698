import { checkPrivilege } from '@/components/privilege';
import { generateEmptySearchResult } from '@/services';
import { services } from '@comall-backend-builder/core/lib';
import { message } from 'antd';

export default [
    {
        key: '/dc-goods/admin/sku_evaluation/list',
        loader: {
            get: async function(data: any, config: any) {
                if (!data.onlineStoreIds) {
                    message.error(services.language.getText('picking.retailStoreSelectTip'));
                    return new Promise((resolove) => resolove(generateEmptySearchResult()));
                }
                if (checkPrivilege('opinion_management.sku_evaluation.sku_evaluation_list')) {
                    const { evaluationStatus } = data;
                    data.status = evaluationStatus;
                    const result = await services.api.get(data, config);
                    return result;
                }
                return [];
            },
        },
    },
];

import { Config } from '@comall-backend-builder/core/lib/parser';
import { PRODUCT_INVENTORY_MONITORING_TABLE } from '@/configs/pro-table-constants';
import { SPECS_TYPE_OPTIONS, PRODUCT_SOURCE_TYPE_OPTIONS } from '@/constants';
import {
    tableMixin,
    flexibleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
} from '@/configs/mixin';
import { getStoreInfo } from '@/services/utils';

export const config: Config = {
    entities: {
        productInventoryMonitoring: {
            apiPath: '/dc-stock/admin/rskus/stock/monitor/findAllByLoginUser',
            properties: {
                id: { type: 'string' },
                productLanguageName: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties('string', 'productInventory.productName'),
                    },
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                },
                goodsId: {
                    type: 'string',
                    displayName: 'Goods ID',
                },
                specsType: {
                    type: 'string',
                    displayName: '<<productInventory.specsType>>',
                },
                source: {
                    type: 'string',
                    displayName: '<<productInventory.sourceType>>',
                },
                stockQuantity: {
                    type: 'string',
                    displayName: '<<productInventory.existQuantity>>',
                },
                safeQuantity: {
                    type: 'string',
                    displayName: '<<productInventory.safeQuantity>>',
                },
                orderQuantity: {
                    type: 'string',
                    displayName: '<<productInventory.reserveQuantity>>',
                },
                availableStock: {
                    type: 'string',
                    displayName: '<<productInventory.saleableQuantity>>',
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<productInventory.lastedModifyTime>>',
                },
            },
            filters: {
                searchCode: {
                    type: 'string',
                    displayName: '<<productInventory.keyWords>>',
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                    controlConfig: {
                        placeholder: '<<cskuPlaceHolder>>',
                    },
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productInventory.specsType>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                source: {
                    type: 'string.options.select',
                    displayName: '<<productInventory.sourceType>>',
                    options: PRODUCT_SOURCE_TYPE_OPTIONS,
                },
                merchantId: {
                    type: 'string.options.select',
                    displayName: '<<productList.merchantName>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant/findMerchantList',
                    },
                },
            },
        },
    },
    components: {
        ProductInventoryMonitoringView: {
            component: 'Viewport',
        },
        ProductInventoryMonitoring: {
            component: 'FlexLayout',
            entity: 'productInventoryMonitoring',
            direction: 'vertical',
            className: 'product-inventory-view',
            items: [
                {
                    component: 'ProductPriceList',
                    params: {},
                    filterConfig: {
                        component: 'ProductInventoryMonitoringFilter',
                        style: { marginBottom: 15 },
                        privilege: {
                            path: 'goods.goods_stock_view.view_list',
                            level: 'full',
                        },
                    },
                    buttonConfig: {
                        component: 'ProductInventoryMonitoringButtons',
                        style: { marginBottom: 15 },
                    },
                    tableConfig: {
                        component: 'ProductInventoryMonitoringTable',
                        privilege: {
                            path: 'goods.goods_stock_view.view_list',
                            level: 'full',
                        },
                    },
                },
            ],
        },
        ProductInventoryMonitoringFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'searchCode',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productInventory.keyWordsPlaceHolder>>',
                    },
                },
                { property: 'csku' },
                {
                    property: 'specsType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'source',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'merchantId',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            ],
        },
        ProductInventoryMonitoringButtons: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<productInventory.exportQuantity>>',
                    icon: 'download',
                    type: 'primary',
                    style: {
                        flexGrow: 0,
                        marginRight: 15,
                    },
                    source: {
                        apiPath: '/dc-stock/admin/rskus/stock/export/take',
                    },
                    range: {
                        apiPath: '/dc-stock/admin/rskus/stock/export/count',
                        perPage: 100000,
                    },
                    formatter: (params: any) => {
                        params.onlineStoreId = getStoreInfo()?.id;
                        return params;
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'goods.goods_stock_view.export',
                        level: 'full',
                    },
                },
            ],
        },
        ProductInventoryMonitoringTable: {
            ...tableMixin,
            component: 'ProTable',
            loadFirstPage: false,
            componentName: PRODUCT_INVENTORY_MONITORING_TABLE,
            className: 'product-inventory-table',
            columns: [
                ...getLanguageColumns('productLanguageName', { width: 200 }),
                { property: 'csku', width: '140' },
                { property: 'goodsId', width: '140' },
                { property: 'specsType', width: '140' },
                { property: 'stockQuantity', width: '140' },
                { property: 'safeQuantity', width: '140' },
                { property: 'orderQuantity', width: '140' },
                { property: 'availableStock', width: '140' },
                { property: 'source', width: '140' },
                { property: 'lastedModifyTime', width: '200' },
            ],
        },
    },
};

/*
 * @Author: 朱璐
 * @Date: 2022-10-19 14:49:56
 * @Description: OverrideCode 管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';

import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';

import {
    OVERRIDE_CODE_TYPE_VALUES,
    OVERRIDE_CODE_TYPE_OPTIONS,
    OVERRIDE_CODE_OPTIONS,
    IMPORT_TEMPLATE_URL,
} from '@/constants';
import { recordImportLogs } from '@/services';

export const config: Config = {
    entities: {
        overrideCode: {
            apiPath: '/loader/dc-price/admin/overridecode',
            properties: {
                id: { type: 'string' },
                overrideCode: {
                    type: 'string',
                    displayName: 'OverrideCode',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<overrideCode.effectiveStartTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<overrideCode.effectiveEndTime>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<overrideCode.type>>',
                    options: OVERRIDE_CODE_TYPE_OPTIONS,
                },
                codeStatus: {
                    type: 'string.status',
                    displayName: '<<overrideCode.status>>',
                },
                ableStores: {
                    type: 'array.onlineStoreGroup',
                    displayName: '<<overrideCode.onlineStore>>',
                },
            },
            filters: {
                overrideCode: {
                    type: 'string',
                    displayName: 'OverrideCode',
                },
                onlineStoreId: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<overrideCode.onlineStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        OverrideCodeContainer: {
            component: 'Viewport',
        },
        OverrideCodeList: {
            entity: 'overrideCode',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'overrideCode',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                        {
                            property: 'onlineStoreId',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                    ],
                    privilege: {
                        path: 'offline.override_code.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<overrideCode.add>>',
                            route: '/override-code/create',
                            privilege: {
                                path: 'offline.override_code.add',
                                level: 'full',
                            },
                        },
                        {
                            component: 'OSSExportButton',
                            text: '<<virtualCategory.downloadTemplate>>',
                            type: 'link',
                            style: {
                                marginRight: 'auto',
                                marginLeft: 10,
                                flexGrow: 0,
                            },
                            privilege: {
                                path: 'offline.override_code.import',
                                level: 'full',
                            },
                            url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.OVERRIDE_CODE}`,
                        },
                    ],
                },
                {
                    component: 'OverrideCodeTable',
                    privilege: {
                        path: 'offline.override_code.view_list',
                        level: 'full',
                    },
                },
            ]),
        },
        OverrideCodeTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'overrideCode',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'type',
                },
                {
                    property: 'ableStores',
                },
                {
                    property: 'codeStatus',
                    displayConfig: {
                        statusConfig: OVERRIDE_CODE_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<virtualCategory.importProduct>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionImport',
                            renderConfig: (row: any, props: any) => {
                                if (row.type === OVERRIDE_CODE_TYPE_VALUES.PRESET) {
                                    const { text } = props;
                                    return {
                                        row,
                                        text,
                                        style: {
                                            marginRight: '8px',
                                        },
                                        apiPath: `/dc-file/excels?location_id=2`,
                                        showSuccessMessage: false,
                                        showErrorMessage: true,

                                        onUploadComplete(response: any, row: any) {
                                            if (response) {
                                                recordImportLogs({
                                                    name: services.language.getText(
                                                        'virtualCategory.importProduct'
                                                    ),
                                                    apiPath: `/dc-price/admin/overridecode/import`,
                                                    params: {
                                                        resourceId: response.id,
                                                        id: row.id,
                                                    },
                                                });
                                            }
                                        },
                                    };
                                }
                                return {};
                            },
                        },
                        privilege: {
                            path: 'offline.override_code.import',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<virtualCategory.exportProduct>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionExport',
                            renderConfig: (row: any, props: any) => {
                                if (row.type === OVERRIDE_CODE_TYPE_VALUES.PRESET) {
                                    return {
                                        ...props,
                                        row,
                                        source: {
                                            apiPath:
                                                '/dc-price/admin/overridecode/export/take/{{row.id}}',
                                            paramsFilter: [],
                                        },
                                        range: {
                                            apiPath:
                                                '/dc-price/admin/overridecode/export/count/{{row.id}}',
                                        },
                                        showErrorMessage: true,
                                        showSuccessTip: true,
                                    };
                                }
                                return {};
                            },
                        },
                        style: {
                            marginRight: '8px',
                        },

                        privilege: {
                            path: 'offline.override_code.export',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<statusValue.disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                if (row.admin) {
                                    return {
                                        disabled: true,
                                    };
                                } else {
                                    return {
                                        entity: props.entity,
                                        row,
                                        statusApi: {
                                            '1': '/dc-price/admin/overridecode/{{row.id}}/enable',
                                            '0': '/dc-price/admin/overridecode/{{row.id}}/disable',
                                        },
                                        statusText: {
                                            '1': '<<statusActions.enable>>',
                                            '0': '<<statusActions.disable>>',
                                        },
                                    };
                                }
                            },
                        },
                        privilege: {
                            path: 'offline.override_code.enable_disable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/override-code/edit/{{row.id}}',
                        privilege: {
                            path: 'offline.override_code.update',
                            level: 'full',
                        },
                    },
                ],
            },
        },

        OverrideCodeCreate: {
            ...createPageLayout([{ component: 'OverrideCodeForm', type: 'create' }]),
        },
        OverrideCodeEdit: {
            ...createPageLayout([{ component: 'OverrideCodeForm', type: 'edit' }]),
        },
    },
};

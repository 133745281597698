import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import ProductShelfModal, { Product } from '../shelf-modal';
import { SHELF_KEYS } from '../constants';

interface Props {
    text: string;
    selectedRowKeys: string[];
    status: SHELF_KEYS;
    tableComponentId: string;
    entity: Entity;
}

const ProductShelfButtonComponent = (props: Props) => {
    const [visible, setVisible] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const { entity } = props;

    const handleClick = useCallback(() => {
        const { selectedRowKeys } = props;
        if (isEmpty(selectedRowKeys)) {
            message.warning(services.language.getText('productShelf.pleaseSelectProducts'));
            return;
        }
        const entityResult = (entity.result || []) as any[];
        const selectedRows = selectedRowKeys.map((key) => {
            return entityResult.find((item) => item.id === key)!;
        });
        setProducts(
            selectedRows.map((row) => ({
                csku: row.csku,
                rsku: row.rsku,
                onlineStoreCode: row.onlineStoreCode,
                productId: row.productId,
                onShelvesValues: row.onShelvesValues,
            }))
        );
        if (!selectedRows.every((item) => !item.abnormalReason)) {
            return message.warn(services.language.getText('productShelf.offShelfWarningTips'));
        }
        setVisible(true);
    }, [props, entity]);

    return (
        <>
            <Button type='primary' style={{ marginLeft: 10 }} onClick={handleClick}>
                {props.text}
            </Button>
            <ProductShelfModal
                batch
                visible={visible}
                products={products}
                status={props.status}
                onClose={() => {
                    setVisible(false);
                }}
                onSuccess={() => {
                    setVisible(false);
                    entity.search(entity.params);
                }}
            />
        </>
    );
};

function mapState2Props(state: any, props: any) {
    const { tableComponentId } = props;
    const selectedRowKeys = get(state.components, tableComponentId, {}).selectedRowKeys;
    return {
        selectedRowKeys,
    };
}

export const ProductShelfButton = connect(mapState2Props, null)(ProductShelfButtonComponent);

import { Config } from '@comall-backend-builder/core/lib/parser';
import { formFooter, getLanguageProperties, getLanguageColumns } from '@/configs/mixin';
import './index.less';

export const config: Config = {
    entities: {
        brandInfo: {
            apiPath: '/loader/dc-goods/admin/brands',
            properties: {
                searchCode: {
                    type: 'string',
                    displayName: '<<brandEdit.code>>',
                },
                // name: {
                //     type: 'string',
                //     displayName: '<<brandEdit.brandNameCN>>',
                //     controlConfig: {
                //         placeholder: '<<brandEdit.brandNameCNPlaceholder>>',
                //         maxLength: 50,
                //     },
                //     rules: [
                //         { required: true },
                //         { whitespace: true, message: '<<brandEdit.brandNameCNNoEmpty>>' },
                //         { max: 50, message: '<<brandEdit.brandNameCNNoLength>>' },
                //     ],
                // },
                // ename: {
                //     type: 'string',
                //     displayName: '<<brandEdit.brandNameEN>>',
                //     controlConfig: {
                //         placeholder: '<<brandEdit.brandNameENPlaceholder>>',
                //         maxLength: 50,
                //     },
                //     rules: [
                //         { required: true },
                //         { whitespace: true, message: '<<brandEdit.brandNameENNoEmpty>>' },
                //         { max: 50, message: '<<brandEdit.brandNameENNoLength>>' },
                //     ],
                // },
                languageName: {
                    type: 'object.subForm',
                    properties: getLanguageProperties('string.text.trim', 'brands.brandName', {
                        controlConfig: {
                            placeholder: '<<brandEdit.brandNamePlaceholder>>',
                            maxLength: 50,
                        },
                        rules: [
                            { required: true, whitespace: true },
                            { max: 50, message: '<<brandEdit.brandNameENNoLength>>' },
                        ],
                    }),
                },
                logoFeature: {
                    type: 'array.brandImage',
                    displayName: '<<brandEdit.brandLogo>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1024,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.logoLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    },
                },
                mpBanner: {
                    type: 'array.brandImage',
                    displayName: '<<brandEdit.mplogoBanner>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1024,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.bannerLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    },
                },
                pcBanner: {
                    type: 'array.brandImage',
                    displayName: '<<brandEdit.pclogoBanner>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1024,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.bannerLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    },
                },
                targetUrl: {
                    type: 'string',
                    displayName: '<<displayCategory.bannerUrl>>',
                    controlConfig: {
                        maxLength: 100,
                        placeholder: '<<displayCategory.bannerPlaceholder>>',
                    },
                    rules: [
                        {
                            pattern: /(https):\/\/([\w.]+\/?)\S*/,
                            message: '<<displayCategory.bannerPlaceholder>>',
                        },
                    ],
                },
            },
        },
    },
    components: {
        BrandCreatePage: {
            component: 'Card',
            content: {
                component: 'BrandCreateForm',
            },
        },
        BrandCreateForm: {
            component: 'CreateFormPlus',
            entity: 'brandInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            className: 'none-sub-form brand-edit-from',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                ...getLanguageColumns('languageName'),
                {
                    property: 'logoFeature',
                },
                {
                    property: 'mpBanner',
                },
                {
                    property: 'pcBanner',
                },
                { property: 'targetUrl' },
            ],
        },
        BrandEditPage: {
            component: 'Card',
            loaderType: 'get',
            content: {
                component: 'BrandEditForm',
            },
        },
        BrandEditForm: {
            component: 'ModifyFormPlus',
            entity: 'brandInfo',
            className: 'none-sub-form brand-edit-from',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            loaderType: 'get',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                {
                    property: 'searchCode',
                    modifiable: false,
                },
                ...getLanguageColumns('languageName'),
                {
                    property: 'logoFeature',
                },
                {
                    property: 'mpBanner',
                },
                {
                    property: 'pcBanner',
                },
                { property: 'targetUrl' },
            ],
        },
    },
};

import React from 'react';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { tableMixin, getLanguageColumns, getLanguageProperties, formFooter } from '@/configs/mixin';
import { eventCenter } from '@/services/events';
import { IMPORT_TEMPLATE_URL } from '@/constants';
import { VIRTUAL_CATEGORY_TABLE } from '@/configs/pro-table-constants';
import { recordImportLogs } from '@/services';
import { getStoreInfo } from '@/services/utils';

const picLangMap = {
    zh: services.language.getText('chinese'),
};

const virtualCategoryEntity = {
    nameTip: {
        displayName: '<<virtualCategory.nameTips>>',
        type: 'string',
    },
    languageName: {
        properties: getLanguageProperties('string', 'virtualCategory.name', {
            controlConfig: {
                placeholder: '<<virtualCategory.namePlaceholder>>',
            },
            rules: [
                {
                    required: true,
                    message: '<<virtualCategory.nameRequired>>',
                },
                {
                    whitespace: true,
                    message: '<<virtualCategory.nameRequired>>',
                },
            ],
        }),
        type: 'object.subForm',
    },
    id: {
        type: 'string',
        displayName: '<<virtualCategory.id>>',
    },
    parent: {
        type: 'object.treeSelect.async',
        displayName: '<<virtualCategory.parentId>>',
        options: [],
        source: {
            apiPath:
                '/loader/guide-cms-service/admin/virtual_categorys/categorys_tree/categoryForm',
        },
        controlConfig: {
            allowClear: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
        rules: [{ required: true }],
    },
    productNumber: {
        type: 'string',
        displayName: '<<virtualCategory.productNumber>>',
    },
    details: {
        properties: getLanguageProperties('string.text.paragraph', 'virtualCategory.desc', {
            controlConfig: {
                placeholder: '<<virtualCategory.descPlaceholder>>',
            },
        }),
        type: 'object.subForm',
    },
    pictureId: {
        properties: getLanguageProperties(
            'string.image.picker.local',
            'virtualCategory.picture',
            {
                controlConfig: {
                    fileName: 'fileName',
                    uploadType: 'single',
                    uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    mode: 'picture-card',
                    maxCount: 1,
                    // 这里重新定义超过文件大小后的message，原因是uploadFile接口的onUploadComplete只有成功的数量与失败的数量
                    maxSize: 5 * 1024,
                    uploadLimitSizeErrorMessage: '<<virtualCategory.imageOverstep>>',
                    limitTips: {
                        className: 'hidden',
                    },
                },
            },
            picLangMap
        ),
        type: 'object.subForm',
    },
    pcPictureId: {
        properties: getLanguageProperties(
            'string.image.picker.local',
            'virtualCategory.picturePC',
            {
                controlConfig: {
                    fileName: 'fileName',
                    uploadType: 'single',
                    uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    mode: 'picture-card',
                    maxCount: 1,
                    // 这里重新定义超过文件大小后的message，原因是uploadFile接口的onUploadComplete只有成功的数量与失败的数量
                    maxSize: 5 * 1024,
                    uploadLimitSizeErrorMessage: '<<virtualCategory.imageOverstep>>',
                    limitTips: {
                        className: 'hidden',
                    },
                },
            },
            picLangMap
        ),
        type: 'object.subForm',
    },
};
export const config: Config = {
    entities: {
        virtualCategoryInfo: {
            apiPath: '/loader/guide-cms-service/admin/virtual_categorys',
            properties: virtualCategoryEntity,
        },
        virtualCategory: {
            apiPath: '/loader/guide-cms-service/admin/virtual_categorys',
            properties: {
                languageName: {
                    properties: getLanguageProperties('string', 'virtualCategory.name', {
                        controlConfig: {
                            placeholder: '<<virtualCategory.namePlaceholder>>',
                        },
                        rules: [
                            {
                                required: true,
                                message: '<<virtualCategory.nameRequired>>',
                            },
                            {
                                whitespace: true,
                                message: '<<virtualCategory.nameRequired>>',
                            },
                        ],
                    }),
                    type: 'object.subForm',
                },
                id: {
                    type: 'string',
                    displayName: '<<virtualCategory.id>>',
                },

                productNumber: {
                    type: 'string',
                    displayName: '<<virtualCategory.productNumber>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<virtualCategory.keyWords>>',
                    controlConfig: {
                        placeholder: '<<virtualCategory.keyWordsPlaceholder>>',
                    },
                },
            },
        },
    },
    components: {
        VirtualCategoryContainer: {
            component: 'Viewport',
        },
        VirtualCategoryManagement: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'virtualCategory',
            items: [
                {
                    component: 'CategorySelectorContainer',
                    treeSource:
                        '/loader/guide-cms-service/admin/virtual_categorys/categorys_tree/all',
                    rightComponent: 'VirtualCategoryContent',
                    listPrivilege: {
                        path: 'category.virtual_categorys.categorys_tree',
                        level: 'full',
                    },
                },
            ],
        },
        VirtualCategoryHeader: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'flex',
                flex: '1',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
            },
            items: [
                {
                    component: 'Button',
                    text: '<<virtualCategory.add>>',
                    icon: 'plus',
                    type: 'primary',

                    style: {
                        flexGrow: 0,
                    },
                    onClick: () => {
                        //先判断是否选择过store
                        if (!isEmpty(getStoreInfo())) {
                            return services.navigation.goto('/virtualCategory/add');
                        }
                        message.warn('请选择门店');
                    },
                    privilege: {
                        path: 'category.virtual_categorys.add',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<virtualCategory.downloadTemplate>>',
                    type: 'link',
                    style: {
                        marginRight: 'auto',
                        marginLeft: 10,
                        flexGrow: 0,
                    },
                    privilege: {
                        path: 'category.virtual_categorys.download_import_template',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.VIRTUAL_CATEGORY_PRODUCT}`,
                },
                {
                    component: 'VirtualCategoryFilter',
                },
            ],
        },
        VirtualCategoryFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            style: {
                textAlign: 'right',
            },
            reset: true,
            fields: [
                {
                    property: 'keyword',
                },
            ],
        },
        VirtualCategoryContent: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                marginLeft: 15,
            },
            items: [
                {
                    component: 'VirtualCategoryHeader',
                },
                {
                    component: 'VirtualCategoryTable',
                    privilege: {
                        path: 'category.virtual_categorys.view_ovirtual_categorys_list',
                        level: 'full',
                    },
                },
            ],
        },
        VirtualCategoryTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: VIRTUAL_CATEGORY_TABLE,
            loadFirstPage: false,
            scroll: {
                x: 'max-content',
            },
            columns: [
                ...getLanguageColumns('languageName'),
                { property: 'id' },
                { property: 'productNumber' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionImport',
                        text: '<<virtualCategory.importProduct>>',

                        config: {
                            style: {
                                marginRight: 15,
                            },
                            apiPath: `/dc-file/excels?location_id=2`,
                            showSuccessMessage: false,
                            showErrorMessage: true,
                            onUploadComplete(response: any, row: any) {
                                if (response) {
                                    recordImportLogs({
                                        name: services.language.getText(
                                            'virtualCategory.importProduct'
                                        ),
                                        apiPath: `/guide-cms-service/admin/vc_product_import`,
                                        params: {
                                            resourceId: response.id,
                                            virtualCategoryId: row.id,
                                            onlineStoreId: getStoreInfo().id,
                                        },
                                    });
                                }
                            },
                        },
                        privilege: {
                            path: 'category.virtual_categorys.import',
                            level: 'full',
                        },
                    },

                    {
                        type: 'component',
                        component: 'TableActionExport',
                        text: '<<virtualCategory.exportProduct>>',
                        style: {
                            marginRight: 15,
                        },
                        config: {
                            source: {
                                apiPath:
                                    '/guide-cms-service/admin/virtual_categorys/export_products/{{row.id}}/take',
                                paramsFilter: [],
                            },
                            range: {
                                apiPath:
                                    '/guide-cms-service/admin/virtual_categorys/export/products/{{row.id}}/count',
                            },
                            showErrorMessage: true,
                            showSuccessTip: true,
                        },
                        privilege: {
                            path: 'category.virtual_categorys.export',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<virtualCategory.viewProduct>>',
                        type: 'link',
                        path: '/virtualCategory/product/{{row.id}}',
                        privilege: {
                            path: 'category.virtual_categorys.view_goods',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/virtualCategory/edit/{{row.id}}',
                        privilege: {
                            path: 'category.virtual_categorys.edit_detail',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<delete>>',
                        config: {
                            actionType: 'delete',
                            component: 'TableActionToggleStatus',
                            deleteConfig: {
                                render: (row: any, onDelete: any, children: any) => {
                                    const showConfirm = () => {
                                        Modal.confirm({
                                            title: services.language.getText('areYouSureToDelete'),
                                            content:
                                                row.hasChild > 0
                                                    ? services.language.getText(
                                                          'deleteParentContent'
                                                      )
                                                    : '',
                                            okText: services.language.getText('confirm'),
                                            cancelText: services.language.getText('cancel'),
                                            onOk() {
                                                onDelete();
                                            },
                                        });
                                    };
                                    return <span onClick={showConfirm}> {children}</span>;
                                },
                                callback: () => {
                                    eventCenter.trigger('tree_refresh', 'hello');
                                },
                            },
                            renderConfig: (row: any, props: any) => {
                                if (row.hasChild > 0) {
                                    return { disabled: true };
                                }
                                return { ...props };
                            },
                        },
                        privilege: {
                            path: 'category.virtual_categorys.delete',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        VirtualCategoryAddPage: {
            component: 'Card',
            content: {
                component: 'VirtualCategoryAddForm',
            },
        },
        VirtualCategoryAddForm: {
            component: 'CreateForm',
            className: 'none-sub-form category',
            entity: 'virtualCategoryInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                { property: 'nameTip', className: 'nameTip' },
                {
                    property: 'parent',
                },
                ...getLanguageColumns('languageName'),
                ...getLanguageColumns('details'),
                ...getLanguageColumns('pictureId', {}, picLangMap),
                ...getLanguageColumns('pcPictureId', {}, picLangMap),
            ],
        },
        // 编辑
        VirtualCategoryEditPage: {
            component: 'Card',
            content: {
                component: 'VirtualCategoryEditForm',
            },
        },
        VirtualCategoryEditForm: {
            component: 'ModifyForm',
            className: 'none-sub-form category',
            loaderType: 'get',
            entity: 'virtualCategoryInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                { property: 'nameTip', className: 'nameTip' },
                {
                    property: 'id',
                    modifiable: false,
                },
                {
                    property: 'parent',
                    modifiable: false,
                },
                ...getLanguageColumns('languageName'),
                ...getLanguageColumns('details'),
                ...getLanguageColumns('pictureId', {}, picLangMap),
                ...getLanguageColumns('pcPictureId', {}, picLangMap),
            ],
        },
    },
};

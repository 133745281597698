import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Form, Modal } from 'antd';
import SelectAutoComplete from '@/components/select-auto-complete';

const { api, errorHandle, language } = services;

interface IProps {
    className: string;
    text: string;
    style: any;
    row: any;
    apiPath: string;
    entity: Entity;
    params: any;
    form: any;
}

interface IState {
    visible: boolean;
    value: null | Array<any>;
    submitLoading: boolean;
}

/**
 * 三方账户信息 新增信息
 */
export class thirdPartAccountAdd extends Component<IProps, IState> {
    state = {
        visible: false,
        value: null,
        submitLoading: false,
    };

    submitAdd = async (values: any) => {
        const { entity } = this.props;
        try {
            this.setState({
                submitLoading: true,
            });
            await api.post(
                { name: values.name.name },
                {
                    apiPath: `/dc-master-data/admin/channel/account`,
                }
            );
            entity.search(entity.params);
            this.toggleModal(false);
        } catch (e) {
            //@ts-ignore
            errorHandle(e);
        } finally {
            this.setState({
                submitLoading: false,
            });
        }
    };

    toggleModal = (visible: boolean) => {
        this.setState({
            visible,
        });
    };

    handleClick = () => {
        this.toggleModal(true);
    };

    handleCancel = () => {
        this.toggleModal(false);
    };

    handleOk = () => {
        const { validateFields } = this.props.form;
        validateFields((errors: any, values: any) => {
            if (!errors) {
                this.submitAdd(values);
            }
        });
    };

    render() {
        const { className, style, text, form } = this.props;
        const { visible, submitLoading } = this.state;
        const { getFieldDecorator } = form;

        return (
            <>
                <Button
                    className={className}
                    style={style}
                    type='primary'
                    onClick={this.handleClick}
                >
                    {text}
                </Button>
                <Modal
                    title={language.getText('add')}
                    visible={visible}
                    confirmLoading={submitLoading}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    okText={language.getText('save')}
                >
                    <Form layout='horizontal' labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                        <Form.Item label={language.getText('thirdPartyManagement.thirdPartyName')}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: services.language.getText(
                                            'thirdPartyAccount.thirdPartyNameTips'
                                        ),
                                    },
                                ],
                            })(
                                <SelectAutoComplete
                                    placeholder={services.language.getText(
                                        'defaultPlaceholderSelect'
                                    )}
                                    apiPath='/dc-master-data/admin/channel/account/dataPage'
                                    selectParamKey='keyword'
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export const ThirdPartAccountAdd = Form.create({ name: 'third-part-account-add-form' })(
    thirdPartAccountAdd
);

import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/dc-price/admin/deposits',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, config);
                return response;
            },
            put: async function(data: any, config: any) {
                const response: any = await services.api.put(data, config);
                return response;
            },
        },
    },
];

import { generateOptions } from '@/services/generate-options';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 获取方式值
 */
export enum ACQUISITION_METHOD_VALUES {
    /**
     * 领取
     */
    RECEIVE = 'RECEIVE',
    /**
     * 积分兑换
     */
    POINT_REDEMPTION = 'POINT_REDEMPTION',
}

/**
 * 获取方式名
 */
export const ACQUISITION_METHOD_NAMES = {
    [ACQUISITION_METHOD_VALUES.RECEIVE]: language.getText('couponManage.couponCenter.edit.receive'),
    [ACQUISITION_METHOD_VALUES.POINT_REDEMPTION]: language.getText(
        'couponManage.couponCenter.edit.pointRedemption'
    ),
};

/**
 * 获取方式候选项
 */
export const ACQUISITION_METHOD_OPTIONS = generateOptions(
    ACQUISITION_METHOD_VALUES,
    ACQUISITION_METHOD_NAMES
);

/**
 * 事件类型状态值
 */
export enum CENTER_STATUS_VALUES {
    /**
     * 待领取
     */
    TOBECOLLECTED = 1,
    /**
     * 待使用
     */
    HAVENOTBEGUN = 2,
    /**
     * 已使用
     */
    INPROGRESS = 3,
    /**
     * 已过期
     */
    FINISHED = 4,
}

/**
 * 事件类型状态名
 */
export const CENTER_STATUS_NAMES = {
    [CENTER_STATUS_VALUES.TOBECOLLECTED]: language.getText(
        'couponManage.couponCenter.viewData.collected'
    ),
    [CENTER_STATUS_VALUES.HAVENOTBEGUN]: language.getText(
        'couponManage.couponCenter.viewData.toBeused'
    ),
    [CENTER_STATUS_VALUES.INPROGRESS]: language.getText('couponManage.couponCenter.viewData.used'),
    [CENTER_STATUS_VALUES.FINISHED]: language.getText('couponManage.couponCenter.viewData.expired'),
};

/**
 * 事件类型候选项
 */
export const CENTER_STATUS_OPTIONS = generateOptions(CENTER_STATUS_VALUES, CENTER_STATUS_NAMES);

/**
 * 活动类型角标颜色
 */
export const CENTER_STATUS_COLORS = {
    [CENTER_STATUS_VALUES.TOBECOLLECTED]: 'default',
    [CENTER_STATUS_VALUES.HAVENOTBEGUN]: 'success',
    [CENTER_STATUS_VALUES.INPROGRESS]: 'warning',
    [CENTER_STATUS_VALUES.FINISHED]: 'default',
};

/**
 * 活动类型候选项
 */
export const CENTER_SHOW_STATUS_OPTIONS = generateOptions(
    CENTER_STATUS_VALUES,
    CENTER_STATUS_NAMES,
    (v, n) => ({ id: v, name: n, status: CENTER_STATUS_COLORS[v] })
);

/**
 * 退款汇总报表
 */

import { REFUND_METHODS_OPTIONS, REFUND_OPTIONS } from '@/constants/after-sale';
import { formatDate } from '@/services/data-formatter';
import { Config, EntityProperties } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '../mixin';

import { getFilterProperties, getFields } from './utils';

export const getEntityProperties: () => EntityProperties = () => {
    let nVal: AnyObject = {};
    [
        'onlineStoreCode',
        'name',
        'orderRefundNumber',
        'outerOrderNumber',
        'createTime',
        'pickUpMobile',
        'pickUpMember',
        'orderMobile',
        'orderMember',
        'refundRemark',
        'orderRefundStatus',
        'orderRefundChannelType',
        'refundAmount',
        'voucherAmount',
        'payableAmount',
        'point',
    ].forEach((i: any) => {
        switch (i) {
            case 'orderRefundStatus':
                nVal[i] = {
                    type: 'string.options.select',
                    displayName: `<<report.refundSummary.${i}>>`,
                    options: REFUND_OPTIONS,
                };
                break;
            case 'orderRefundChannelType':
                nVal[i] = {
                    type: 'string.options.select',
                    displayName: `<<report.refundSummary.${i}>>`,
                    options: REFUND_METHODS_OPTIONS,
                };
                break;
            default:
                nVal[i] = { type: 'string', displayName: `<<report.refundSummary.${i}>>` };
        }
    });

    return nVal;
};
const filterKeys = [
    'onlineStoreIds',
    'refundTime',
    'orderNumber',
    'pickUpMember',
    'orderMember',
    'refundStatus',
    'merchantIds',
    'liquidState',
];
export const config: Config = {
    entities: {
        refundSummaryReport: {
            apiPath: '/loader/dc-order/admin/refundGather',
            properties: getEntityProperties(),
            filters: getFilterProperties(filterKeys),
        },
    },
    components: {
        RefundSummaryPage: {
            entity: 'refundSummaryReport',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    privilege: {
                        path: 'forms.refund_gather.list',
                        level: 'full',
                    },
                    fields: getFields(getFilterProperties(filterKeys)),
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: { marginBottom: '16px' },
                    items: [
                        {
                            component: 'ExportButtonWithFilter',
                            text: '<<report.exportResult>>',
                            icon: 'download',
                            type: 'primary',
                            style: {
                                flexGrow: 0,
                                marginRight: 15,
                            },
                            source: {
                                apiPath:
                                    '/dc-order/admin/refundGather/exportStatement/refundGather/take',
                            },
                            formatter: (params: any) => {
                                const newParams: any = { ...params };
                                return {
                                    ...newParams,
                                    orderStatus: newParams.refundStatus,
                                    ...formatDate(newParams.refundTime, 'createTime'),
                                };
                            },
                            isAsync: true,
                            showSuccessTips: true,
                            showErrorMessage: true,
                            privilege: {
                                path: 'forms.refund_gather.export',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    ...tableMixin,
                    privilege: {
                        path: 'forms.refund_gather.list',
                        level: 'full',
                    },
                    columns: getFields(getEntityProperties()),
                },
            ]),
        },
    },
};

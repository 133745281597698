import { services } from '@comall-backend-builder/core';
import { transformResponse } from './product-list';

export default [
    {
        key: '/dc-goods/admin/recommend',
        loader: {
            async get(data: any, config: any) {
                config.apiPath = '/dc-goods/admin/recommend/:recommendType';
                return transformResponse('', await services.api.get(data, config));
            },
        },
    },
];

import { useLatest, useMemoizedFn } from 'ahooks';
import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';

export interface StoreLogo {
    loading: boolean;
    onlineStoreId: string;
    logo: string;
}

export interface GlobalConfig {
    storeLogos: StoreLogo[];
}

const DEFAULT_VALUE: GlobalConfig = {
    storeLogos: [],
};

const GlobalConfigContext = React.createContext<GlobalConfig>(DEFAULT_VALUE);

let setGlobalConfig: React.Dispatch<React.SetStateAction<GlobalConfig>>;

export function useGlobalConfig() {
    const globalConfig = useContext(GlobalConfigContext);
    return [globalConfig, setGlobalConfig] as const;
}

export const GlobalConfigProvider: React.FC = ({ children }) => {
    const [state, setState] = useState(DEFAULT_VALUE);
    const stateRef = useLatest(state);

    setGlobalConfig = useMemoizedFn((value) => {
        stateRef.current = Object.assign({}, cloneDeep(state), value);
        setState(stateRef.current);
    });

    return (
        <GlobalConfigContext.Provider value={stateRef.current}>
            {children}
        </GlobalConfigContext.Provider>
    );
};

import React, { Component, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { OptionType } from '@/interfaces';
import { KV } from '@/interfaces';
import { OrganizationSelector } from '@/components/organization-selector';
import './index.less';

interface Props {
    entity: Entity;
    entities: { [prop: string]: Entity };
    tableConfig: KV;
}

interface State {}

export class GuideShoppingDiscountList extends Component<Props, State> {
    divisionId = '';

    renderTable = () => {
        const { entity, tableConfig } = this.props;
        const tableComponent = createElement(ComponentsManager.get(tableConfig.component), {
            entity,
            ...tableConfig,
        });
        return tableComponent;
    };

    onChangeDivision = (division: OptionType) => {
        this.divisionId = division.id;
        this.refreshData();
    };

    refreshData = () => {
        const { entity } = this.props;
        entity.filtersChange({
            organizationId: this.divisionId,
        } as any);
        entity.pageChange({ page: 1 });
        entity.search(entity.params);
    };

    render() {
        const { entities } = this.props;
        return (
            <div className='guide-shopping-discount-page'>
                <div className='guide-shopping-discount-page-header'>
                    <OrganizationSelector
                        entity={entities.organizationSelector}
                        onSelect={this.onChangeDivision}
                    />
                </div>
                <div className='guide-shopping-discount-page-body'>{this.renderTable()}</div>
            </div>
        );
    }
}

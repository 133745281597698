import * as React from 'react';

import { RichTextVideo } from '@/components/rich-text-video';
import { StringMode } from '@comall-backend-builder/types';

export class StringRichTextVideoMode extends StringMode {
    /**
     * 获取展示组件
     */
    public getAvailableDisplayComponent(value: any, displayInfo: any) {
        const controlInfo = Object.assign({}, displayInfo, {
            disabled: true,
            value: value,
        });
        return <RichTextVideo {...controlInfo} />;
    }
    /**
     * 获取输入组件，日期选择
     */
    public getControlComponent(controlInfo: any) {
        return <RichTextVideo {...controlInfo} />;
    }
}

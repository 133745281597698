import React, { useState } from 'react';
import { Modal as AntModal, Button as AntButton, Form as AntForm, Input as AntInput } from 'antd';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { DELIVERY_TYPE_NAMES, DELIVERY_TYPE_VALUES } from '@/constants/order';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';

/**
 * 确认发货
 */
export const DeliverTheOrder: React.FC<AnyObject> = (props) => {
    const { id, parentProps, config } = props;
    const [visible, setVisible] = useState(false);
    const [expressNo, setExpressNo] = useState<string>();
    const [loading, setLoading] = useState(false);

    const disabled = props.deliveryType === DELIVERY_TYPE_VALUES.PICK_UP ? false : !expressNo;

    async function confirm() {
        try {
            setLoading(true);
            await services.api.put(
                {
                    expressNo,
                    id,
                },
                {
                    apiPath: formatUrl(API_MAP.ORDER_DELIVER, config.type),
                }
            );

            parentProps.entity.search();
            parentProps.fetchStatistics();
        } catch (e) {
            errorHandle(e as ErrorInfo);
        } finally {
            setVisible(false);
            setLoading(false);
        }
    }

    return (
        <>
            <AntButton type='primary' onClick={() => setVisible(true)}>
                {language.getText('confirmDelivery')}
            </AntButton>
            <AntModal
                visible={visible}
                title={language.getText('confirmDelivery')}
                okText={language.getText('confirm')}
                cancelText={language.getText('cancel')}
                onOk={confirm}
                okButtonProps={{
                    disabled,
                    loading,
                }}
                onCancel={() => setVisible(false)}
            >
                <AntForm
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                >
                    <AntForm.Item label={language.getText('deliveryType.title')}>
                        {DELIVERY_TYPE_NAMES[props.deliveryType as DELIVERY_TYPE_VALUES]}
                    </AntForm.Item>
                    {[DELIVERY_TYPE_VALUES.DELIVERY, DELIVERY_TYPE_VALUES.EXPRESS].includes(
                        props.deliveryType
                    ) && (
                        <AntForm.Item required label={language.getText('order.expressNo')}>
                            <AntInput
                                value={expressNo}
                                onChange={(e) => setExpressNo(e.target.value)}
                            />
                        </AntForm.Item>
                    )}
                </AntForm>
            </AntModal>
        </>
    );
};

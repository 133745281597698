import { getLanguageProperties } from '../mixin';
import { services } from '@comall-backend-builder/core';
export const LANGUAGE_MAP_REQUIRE: { [name: string]: string } = {
    en: services.language.getText('english'),
    zh: services.language.getText('chinese'),
    tc: services.language.getText('tc'),
};

export const formatNameProperties = (
    name: string,
    placeholder: string,
    error: string,
    max = 50
) => {
    return {
        ...getLanguageProperties(
            'string.text.trim',
            name,
            {
                controlConfig: {
                    placeholder: services.language.getText(placeholder),
                    maxLength: max,
                },
                rules: [
                    { whitespace: true, required: true },
                    {
                        max: max,
                        message: services.interpolate(services.language.getText(error), {
                            max: max,
                        }),
                    },
                ],
            },
            LANGUAGE_MAP_REQUIRE
        ),
    };
};

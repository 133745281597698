import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        selfHarvesting: {
            apiPath: '',
        },
    },
    components: {
        SelfHarvestingContainer: {
            component: 'Viewport',
            entity: 'selfHarvesting',
        },
        SelfHarvestingManagement: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'SelfHarversting',
                },
            ],
        },
    },
};

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { get as lodashGet } from 'lodash';
export class BrandRuleModal extends modes.ArrayImagesMode {
    public getDisplayComponent(value: any, displayInfo: any): any {
        if (value && value.length > 0) {
            const nValue = value.map((item: any) => {
                const mappingBrands = lodashGet(item, 'mappingBrand.name', '');
                const categorys = lodashGet(item, 'mappingCategorys', []) || [];
                const mappingCategorys = categorys.map((ele: any) => ele.name);

                return mappingCategorys.length > 0
                    ? [mappingBrands, mappingCategorys.join(',')].join('-')
                    : mappingBrands;
            });
            return <span>{nValue.join('、')}</span>;
        } else {
            return <span className='type-not-available'>-</span>;
        }
    }
}

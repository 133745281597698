import { formatDate } from '@/services';
import { services } from '@comall-backend-builder/core/lib';

async function getTypeData(data: any, api: string) {
    let apiPath: any;
    let formatData: any;

    if (data.liquidState === 'other') {
        apiPath = `/dc-order/${api}`;
        formatData = {
            ...data,
            ...formatDate(data.orderTime, 'createTime'),
            ...formatDate(data.shipTime, 'expressDeliveryTime'),
            ...formatDate(data.deliveryTime, 'shipTime'),
        };
    } else {
        const res: any = await services.api.get(
            { treeType: 1 },
            { apiPath: '/dc-store/admin/onlineStore/tree' }
        );
        apiPath = `/base-order-philips/${api}`;
        formatData = {
            ...data,
            ...formatDate(data.orderTime, 'createTime'),
            ...formatDate(data.shipTime, 'expressDeliveryTime'),
            ...formatDate(data.deliveryTime, 'shipTime'),
            onlineStoreIds: data.onlineStoreIds
                ? data.onlineStoreIds
                : res.onlineStoreTreeVos[0].onlineStoreTreeVos[0].id,
        };
    }
    delete formatData.liquidState;

    return { apiPath, formatData };
}

/**
 * 订单发运报表
 */
export default [
    {
        //中台查询订单发运报表
        key: '/dc-order/admin/order/orderShipExcelPage',
        loader: {
            get: async (data: any, config: any) => {
                const { apiPath, formatData } = await getTypeData(
                    data,
                    'admin/order/orderShipExcelPage'
                );
                delete formatData.orderTime;
                delete formatData.deliveryTime;
                delete formatData.shipTime;
                const result: any = await services.api.get(formatData, {
                    ...config,
                    apiPath,
                });
                console.error('result', result);
                return result;
            },
        },
    },
    {
        //订单汇总报表
        key: '/dc-order/admin/orderGather',
        loader: {
            get: async (data: any, config: any) => {
                const { apiPath, formatData } = await getTypeData(data, 'admin/orderGather');

                delete formatData.orderTime;
                delete formatData.shipTime;
                const result: any = await services.api.get(formatData, {
                    ...config,
                    apiPath,
                });

                return result;
            },
        },
    },
    {
        //订单明细报表
        key: '/dc-order/admin/orderDetails',
        loader: {
            get: async (data: any, config: any) => {
                console.error(data);
                const { apiPath, formatData } = await getTypeData(data, 'admin/orderDetails');
                delete formatData.orderTime;
                delete formatData.shipTime;
                const result: any = await services.api.get(formatData, {
                    ...config,
                    apiPath,
                });

                return result;
            },
        },
    },
    {
        //订单明细报表-无价格
        key: '/dc-order/admin/orderDetailsNoPrice',
        loader: {
            get: async (data: any, config: any) => {
                const { apiPath, formatData } = await getTypeData(
                    data,
                    'admin/orderDetailsNoPrice'
                );
                delete formatData.orderTime;
                delete formatData.shipTime;
                const result: any = await services.api.get(formatData, {
                    ...config,
                    apiPath,
                });

                return result;
            },
        },
    },
    {
        //订单退款汇总报表
        key: '/dc-order/admin/refundGather',
        loader: {
            get: async (data: any, config: any) => {
                const { apiPath, formatData: dataTransform } = await getTypeData(
                    data,
                    'admin/refundGather'
                );
                const formatData = {
                    ...data,
                    orderStatus: data.refundStatus,
                    ...formatDate(data.refundTime, 'createTime'),
                    ...dataTransform,
                };
                delete formatData.refundStatus;
                delete formatData.refundTime;
                delete formatData.liquidState;
                const result: any = await services.api.get(formatData, {
                    ...config,
                    apiPath,
                });

                return result;
            },
        },
    },
    {
        /** 三方映射SKU报表 */
        key: '/dc-order/admin/thirdSku',
        loader: {
            get: async (data: any, config: any) => {
                const res: any = await services.api.get(data, {
                    ...config,
                });
                res.result = (res.result || []).map((item: any) => ({
                    ...item,
                    name: {
                        zh: item.nameZh,
                        en: item.nameEn,
                        tc: item.nameTc,
                    },
                }));
                return res;
            },
        },
    },
    {
        //兑换券发放及使用报表
        key: '/dc-order/admin/exchangeCoupon',
        loader: {
            get: async (data: any, config: any) => {
                const formatData = {
                    ...data,
                    ...formatDate(data.sendTime, 'bonusTime'),
                };
                delete formatData.sendTime;
                const result: any = await services.api.get(formatData, {
                    ...config,
                });

                return result;
            },
        },
    },
];

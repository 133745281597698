import React from 'react';
import { OrderReceipt } from './order-receipt';

/**
 * 打包单模板
 */
export function PackageReceipt(props: AnyObject) {
    const { data } = props;
    return <OrderReceipt data={data} orderPrice={data} isPackage />;
}

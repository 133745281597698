import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { find, get, isEmpty, isEqual } from 'lodash';
import { Button, Icon, message, Modal } from 'antd';
import { services, ComponentsManager, actions } from '@comall-backend-builder/core';
import { tableMixin } from '@/configs/mixin';
import { OptionType } from '@/interfaces';
import './index.less';

const { localStorage, uuid } = services;

const localStorageKey = 'dcOrganization';

const hasOrganizationInfo = () => !!localStorage.get(localStorageKey);

const preDefinedComponentId = 'organization-selector-' + uuid();

interface Props {
    /**
     * Division选择回调
     */
    onSelect: (organization: OptionType, autoTrigger?: boolean) => void;
    setInitValue: (organization?: OptionType) => void;
    [props: string]: any;
}

interface State {
    visible: boolean;
    isLoad: boolean;
    selectedOrganization: OptionType | undefined;
}

class OrganizationSelectorComponent extends Component<Props, State> {
    static defaultProps = {
        onSelect: () => {},
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            visible: false,
            isLoad: false,
            selectedOrganization: localStorage.get<OptionType>(localStorageKey),
        };
    }

    shouldComponentUpdate(nextProps: Props, nextState: any) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    componentDidMount() {
        // 主动触发 props.onSelect
        const { selectedOrganization } = this.state;
        if (selectedOrganization) {
            this.props.onSelect(selectedOrganization, true);
        }
    }

    componentDidUpdate() {
        // 在仅有一个机构时默认选中
        const { entity } = this.props;
        const result = entity.result || [];
        const { isLoad } = this.state;
        if (result.length > 0 && !isLoad) {
            if (result.length === 1) {
                this.onSelect(result[0]);
            }
            this.setState({
                visible: !hasOrganizationInfo() && result.length > 1,
                isLoad: true,
            });
        }
    }

    onSelect = (organization: OptionType) => {
        const { onSelect } = this.props;
        this.setState({
            selectedOrganization: organization,
        });
        localStorage.set(localStorageKey, organization);
        onSelect(organization);
    };

    showModal = () => {
        const { setInitValue } = this.props;
        this.setState({
            visible: true,
        });
        setTimeout(() => {
            setInitValue(this.state.selectedOrganization);
        }, 0);
    };

    onConfirm = () => {
        const { selectedRowKeys, entity } = this.props;
        if (isEmpty(selectedRowKeys)) {
            message.warning(services.language.getText('organizationSelectorModal.pleaseSelect'));
            return;
        }
        const targetOrganization = find(entity.result, (option) => {
            return option.id === selectedRowKeys[0];
        });
        if (!isEmpty(targetOrganization)) {
            const organization = {
                ...targetOrganization,
                id: targetOrganization.id,
                name: targetOrganization.name,
            };
            this.onSelect(organization);
            this.setState({
                visible: false,
            });
        }
    };

    render() {
        const { visible, selectedOrganization } = this.state;
        const { entity } = this.props;
        const result = entity.result || [];
        const organization: OptionType =
            selectedOrganization || (result.length === 1 ? result[0] : {});

        const currentLanguage = get(services.language.getCurrentLanguage(), 'id');
        return (
            <div className='organization-selector'>
                <Button type='link' onClick={this.showModal}>
                    {get(organization, `nameLanguage.${currentLanguage}`) ||
                        get(organization, 'name') ||
                        services.language.getText('organizationSelectorModal.pleaseSelect')}
                    <Icon type='down' style={{ marginLeft: 8 }} />
                </Button>
                <Modal
                    className='store-selector-modal'
                    title={services.language.getText('organizationSelectorModal.modalTitle')}
                    width={800}
                    maskClosable={false}
                    visible={visible}
                    forceRender
                    onCancel={this.onConfirm}
                    footer={[
                        <Button key='back' onClick={this.onConfirm}>
                            {services.language.getText('cancel')}
                        </Button>,
                        <Button key='submit' type='primary' onClick={this.onConfirm}>
                            {services.language.getText('confirm')}
                        </Button>,
                    ]}
                >
                    {createElement(ComponentsManager.get('DataTable'), {
                        entity,
                        ...tableMixin,
                        className: 'store-selector-table',
                        componentId: preDefinedComponentId,
                        columns: [{ property: 'name' }, { property: 'division' }],
                        rowSelection: {
                            type: 'radio',
                            onChange() {},
                        },
                    })}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const component = state.components[preDefinedComponentId];
    return {
        selectedRowKeys: get(component, 'selectedRowKeys'),
    };
};

const mapDispatchToProps = (dispatch: any, props: any) => {
    return {
        setInitValue(defaultOrganization?: OptionType) {
            const result = props.entity.result || [];
            const organization = defaultOrganization || (result.length === 1 ? result[0] : {});
            if (get(organization, 'id')) {
                dispatch(
                    actions.tableRowSelectionChangeAction(preDefinedComponentId, [organization.id])
                );
            }
        },
    };
};

export const OrganizationSelector = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationSelectorComponent);

import React, { Component } from 'react';
import { Tree, Popover, Button, Icon } from 'antd';
import { services } from '@comall-backend-builder/core';
import { load } from '@comall-backend-builder/core/lib/loaders';
import { isEmpty } from 'lodash';
import { STORE_TREE_STORAGE_KEY } from '@/constants';

import './index.less';

const { localStorage, errorHandle } = services;

const { TreeNode } = Tree;

const storageKey = STORE_TREE_STORAGE_KEY;
interface Props {
    style?: React.CSSProperties;
    apiPath?: string;
    checkable?: boolean;
    selectable?: boolean;
    onChange: (stores: any[]) => void;
    params?: {};
    storageKey?: string;
}

interface State {
    visible: boolean;
    value: any[];
    treeData: any[];
    checkedKeys: string[];
    checkedStores: any[];
}

export class StoreTreeSelector extends Component<Props, State> {
    state: State = {
        visible: false,
        value: [],
        treeData: [],
        checkedKeys: [],
        checkedStores: [],
    };

    async componentDidMount() {
        const _storageKey = this.props.storageKey || storageKey;

        const onlineStoreIds = window.sessionStorage.getItem('selectOnlineStoreIds');
        if (onlineStoreIds) {
            const parseOnlineStoreIds = JSON.parse(onlineStoreIds);
            const checkedStores: any = localStorage.get(_storageKey, []);
            if (!isEmpty(checkedStores)) {
                const findStoreId = parseOnlineStoreIds.findIndex(
                    (item: any) => item === checkedStores[0].id
                );
                if (findStoreId !== -1) {
                    this.setState({
                        checkedStores,
                        checkedKeys: checkedStores.map((item: any) => item.id),
                    });
                    localStorage.set(_storageKey, checkedStores);
                    this.props.onChange(checkedStores);
                    this.fetchTreeData();
                } else {
                    this.resetTree();
                }
            } else {
                this.resetTree();
            }
        } else {
            this.resetTree();
        }
    }

    resetTree = async () => {
        await this.fetchTreeData();
        this.setState({ visible: true });
    };

    fetchTreeData = () => {
        const { apiPath, params = {} } = this.props;
        return load('get', {
            apiPath: apiPath || '/loader/dc-store/admin/onlineStore/own',
            params: { ...params },
        })
            .then((treeData: any) => {
                this.setState({ treeData });
            })
            .catch(errorHandle);
    };

    onChange = (keys: any, nodes: any) => {
        const _storageKey = this.props.storageKey || storageKey;
        const checkedStores = this.getNodesData(nodes);
        this.setState({
            checkedKeys: ['20701'],
            checkedStores,
            visible: false,
        });
        localStorage.set(_storageKey, checkedStores);
        this.props.onChange(checkedStores);
    };

    getNodesData = (nodes: any[]) => {
        return nodes
            .filter((node: any) => node.props.isLeaf)
            .map((node: any) => ({ id: node.props.id, name: node.props.name }));
    };

    renderTreeNodes = (data: any) =>
        data.map((item: any) => {
            if (item.children) {
                return (
                    <TreeNode {...item} title={item.name} key={item.id}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} key={item.id} title={item.name} />;
        });

    render() {
        const { visible, treeData, checkedKeys, checkedStores } = this.state;
        const { style, selectable = false, checkable = true } = this.props;
        let displayStoreName = services.language.getText('storeSelectorModal.pleaseSelect');

        if (checkedStores.length) {
            displayStoreName =
                checkedStores.length > 1
                    ? services.interpolate(
                          services.language.getText('storeSelectorModal.displayStoreName'),
                          { count: checkedStores.length }
                      )
                    : `${checkedStores[0].name}`;
        }

        return (
            <div className='store-tree-selector-picker' style={style}>
                <Popover
                    visible={visible}
                    placement='bottomRight'
                    onVisibleChange={(visible) => {
                        this.setState({ visible });
                    }}
                    content={
                        <div style={{ width: '400px' }}>
                            <Tree
                                defaultExpandAll
                                checkable={checkable}
                                selectable={selectable}
                                checkedKeys={checkedKeys}
                                defaultSelectedKeys={checkedKeys}
                                onCheck={(keys, info) => {
                                    checkable && this.onChange(keys, info.checkedNodes);
                                }}
                                onSelect={(keys, info) => {
                                    selectable && this.onChange(keys, info.selectedNodes);
                                }}
                            >
                                {this.renderTreeNodes(treeData)}
                            </Tree>
                        </div>
                    }
                >
                    <Button type='link'>
                        {displayStoreName}
                        <Icon type='down' style={{ marginLeft: 8 }} />
                    </Button>
                </Popover>
            </div>
        );
    }
}

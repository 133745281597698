import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    createPageLayout,
    flexibleFilterForm,
    getLanguageColumns,
    getLanguageProperties,
    tableMixin,
} from '@/configs/mixin';
import { CENTER_SHOW_STATUS_OPTIONS } from '@/constants';

export const config: Config = {
    entities: {
        couponCenterData: {
            apiPath: '/loader/dc-ecoupon/admin/voucherCenter/getData',
            properties: {
                phone: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewCenterData.mobile>>',
                },
                couponNames: {
                    type: 'object.subForm',
                    properties: { ...getLanguageProperties('string', 'productList.productName') },
                },
                point: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewCenterData.point>>',
                },
                drawTime: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewCenterData.drawTime>>',
                },
                couponNum: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewCenterData.couponNum>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<couponManage.couponCenter.viewCenterData.status>>',
                },
            },
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.mobile>>',
                },
            },
        },
    },
    components: {
        CouponCenterDataPage: {
            entity: 'couponCenterData',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'mobile',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                    ],
                },
                {
                    component: 'CouponCenterDataPageTable',
                },
            ]),
        },
        CouponCenterDataPageTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'phone',
                },
                ...getLanguageColumns('couponNames'),
                {
                    property: 'point',
                },
                {
                    property: 'drawTime',
                },
                {
                    property: 'couponNum',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: CENTER_SHOW_STATUS_OPTIONS,
                    },
                },
            ],
        },
    },
};

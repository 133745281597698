/*
 * @Author: zhulu
 * @Date: 2022-10-10 15:44:55
 * @Description: 猜你喜欢
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '../mixin';
import { STATUS_OPTIONS } from '@/constants';
export const config: Config = {
    entities: {
        guessYouLikeSetting: {
            apiPath: '/dc-cms-api/admin/guess_like',
            properties: {
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<onlineStore.storeName>>',
                },
                onlineStoreCode: {
                    type: 'string',
                    displayName: '<<onlineStore.storeCode>>',
                },
                status: {
                    type: 'string.status',
                },
                lastModifyUser: {
                    type: 'string',
                    displayName: '<<designManager.guessYouLikeSetting.lastModifyName>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<designManager.guessYouLikeSetting.lastModifyTime>>',
                },
                category: {
                    type: 'array.treeNode.cascader',
                    displayName: '<<search.guessYouLikeSettings.category>>',
                    options: [],
                    source: {
                        apiPath: '/loader/virtualCategorys/categorys_tree',
                        loadOnInit: false,
                        dataType: 'treeNode',
                    },
                    controlConfig: {
                        rootId: 1,
                    },
                },
            },
        },
    },
    components: {
        GuessYouLikeSettingView: {
            component: 'Viewport',
            entity: 'guessYouLikeSetting',
        },

        GuessYouLikeSettingPage: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'GuessYouLikeSettingTable',
                        privilege: {
                            path: 'finish.like_goods_manage.view_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        GuessYouLikeSettingTable: {
            ...tableMixin,
            component: 'DataTable',
            columns: [
                { property: 'onlineStoreName', width: 220 },
                { property: 'onlineStoreCode', width: 120 },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                },
                {
                    property: 'lastModifyUser',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<set>>',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionGuessYouLike',
                        text: '<<designManager.guessYouLikeSetting.virtualCategory>>',
                        privilege: {
                            path: 'finish.like_goods_manage.add',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                '0': '/dc-cms-api/admin/guess_like/{{row.id}}/enable',
                                '1': '/dc-cms-api/admin/guess_like/{{row.id}}/disable',
                            },
                            statusText: {
                                '0': '<<enable>>',
                                '1': '<<disable>>',
                            },
                        },
                        privilege: {
                            path: 'finish.like_goods_manage.disable',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

import { Table, Row as AntRow, Col as AntCol, InputNumber, Form as AntForm } from 'antd';
import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import { OrderInfoProductProps } from '.';
import { services } from '@comall-backend-builder/core';
import { cloneDeep, isEmpty } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

interface StockDetail {
    name: string;
    barCode: string;
    brandLanguageName: { en: string; tc: string; zh: string };
    productLanguageName: { en: string; tc: string; zh: string };
    specsType: number;
    source: number;
    quantity: number;
    goodsId: string;
    stockBatchVolist:
        | {
              batchSurplusQuantity: number;
              mcsLocation: string;
              orderQuantity: number;
              goodsId: string;
              quantity: number | undefined;
          }[]
        | null;
}

const EditableCell = (props: any) => {
    const {
        editable,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        onChange,
        ...restProps
    } = props;
    const [inputValue, setInputValue] = useState<number | undefined>(record?.quantity || undefined);

    const inputError = useMemo(() => {
        if (inputValue && inputValue > 0) {
            return record.batchSurplusQuantity - record.orderQuantity - inputValue < 0;
        }
        return false;
    }, [inputValue]);

    const inputTypeError = useMemo(() => {
        if (inputValue) {
            return !/^[1-9]\d{0,7}$/.test(inputValue.toString());
        }
        return false;
    }, [inputValue]);

    useEffect(() => {
        if (record?.quantity && index === 0) {
            setInputValue(record.quantity);
        } else {
            setInputValue(undefined);
        }
    }, [record?.quantity]);

    return (
        <td {...restProps}>
            {editable ? (
                <>
                    <InputNumber
                        placeholder={language.getText('common.placeInput')}
                        value={inputValue}
                        style={{
                            margin: '0 5px',
                            maxWidth: '200px',
                            border:
                                inputError || inputTypeError
                                    ? '1px solid red'
                                    : '1px solid #d9d9d9',
                        }}
                        onChange={(value) => {
                            setInputValue(value);
                            onChange(index, value, record.goodsId);
                        }}
                        max={99999999}
                        min={0}
                    />
                    {(inputError || inputTypeError) && (
                        <span style={{ color: 'red' }}>
                            {language.getText('offlineOrder.inventory.insufficientInventory')}
                        </span>
                    )}
                </>
            ) : (
                children
            )}
        </td>
    );
};

export interface InventoryDeductionRef {
    allComplete(): void;
}

export const InventoryDeduction = forwardRef<InventoryDeductionRef, OrderInfoProductProps>(
    ({ activeKey, memberInfo, params, form }, ref) => {
        const [stockDetailData, setData] = useState<StockDetail[] | undefined>(undefined);
        const { getFieldDecorator } = form;

        const getData = async () => {
            const res: StockDetail[] = await services.api.get<any>(
                { onlineStoreId: params.onlineStoreIds, memberId: memberInfo.memberId },
                {
                    apiPath: '/dc-checkout-api/admin/offline/checkout/findStockDetail',
                }
            );
            const mapArr = res
                .filter((item) => !isEmpty(item.stockBatchVolist))
                .map((item) => {
                    return {
                        ...item,
                        stockBatchVolist: item.stockBatchVolist!.map((i) => {
                            return {
                                ...i,
                                goodsId: item.goodsId,
                            };
                        }),
                    };
                });
            setData(mapArr);
        };

        useEffect(() => {
            if (activeKey === 4) getData();
        }, [activeKey]);

        /**
         * 点击全齐时,更新form数据
         */
        useImperativeHandle(
            ref,
            () => ({
                allComplete: () => {
                    const cloneData = cloneDeep(stockDetailData);
                    const arr = cloneData
                        ?.filter((item) => !isEmpty(item.stockBatchVolist))
                        .map((item) => {
                            return {
                                ...item,
                                stockBatchVolist: item.stockBatchVolist!.map((i, index) => {
                                    if (index === 0) {
                                        return {
                                            ...i,
                                            goodsId: item.goodsId,
                                            quantity: item.quantity,
                                        };
                                    }
                                    return { ...i, quantity: 0 };
                                }),
                            };
                        });

                    setData(arr);
                    const filterArr = arr
                        ?.map((item) => {
                            return item.stockBatchVolist;
                        })
                        .flat()
                        .map((i) => ({
                            rskuId: i?.goodsId,
                            location: i?.mcsLocation,
                            quantity: i?.quantity || 0,
                        }));
                    form.setFieldsValue({
                        stockDeliveredParams: {
                            items: filterArr,
                        },
                    });
                },
            }),
            [stockDetailData]
        );

        const onChange = (index: number, value: number, goodsId: string) => {
            const cloneData = cloneDeep(stockDetailData);
            if (cloneData) {
                cloneData
                    .filter((item) => !isEmpty(item.stockBatchVolist))
                    .map((item) => {
                        /**
                         * 用户选择的商品输入扣减库存的数量
                         */
                        if (item.goodsId === goodsId) {
                            item.stockBatchVolist = item.stockBatchVolist!.map((i, idx) => {
                                if (idx === index) {
                                    return {
                                        ...i,
                                        quantity: value,
                                    };
                                }
                                return i;
                            });
                            return item;
                        }
                        return item;
                    });
            }

            setData(cloneData);
            const filterArr = cloneData
                ?.map((item) => {
                    return item.stockBatchVolist;
                })
                .flat()
                .map((i) => ({
                    rskuId: i?.goodsId,
                    location: i?.mcsLocation,
                    quantity: i?.quantity || 0,
                }));
            form.setFieldsValue({
                stockDeliveredParams: {
                    items: filterArr,
                },
            });
        };

        const columns = [
            {
                title: 'MCS Location',
                key: 'mcsLocation',
                dataIndex: 'mcsLocation',
            },
            {
                title: language.getText('offlineOrder.inventory.batchSurplusQuantity'),
                key: 'batchSurplusQuantity',
                dataIndex: 'batchSurplusQuantity',
            },
            {
                title: language.getText('offlineOrder.inventory.orderQuantity'),
                key: 'orderQuantity',
                dataIndex: 'orderQuantity',
            },
            {
                title: language.getText('offlineOrder.inventory.quantity'),
                key: 'quantity',
                dataIndex: 'quantity',
                editable: true,
            },
        ].map((col: any) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (
                    record: {
                        batchSurplusQuantity: number;
                        mcsLocation: string;
                        orderQuantity: number;
                        goodsId: string;
                    },
                    index: number
                ) => ({
                    index,
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editable: col.editable,
                    onChange: onChange,
                }),
            };
        });

        return (
            <>
                {activeKey === 4 && (
                    <AntForm.Item>
                        {getFieldDecorator('stockDeliveredParams')(
                            <>
                                {!isEmpty(stockDetailData) &&
                                    stockDetailData?.map((item) => (
                                        <div style={{ margin: '0 20px' }} key={item.barCode}>
                                            <AntRow>
                                                <AntCol span={24}>
                                                    <AntRow>
                                                        {language.getText(
                                                            'offlineOrder.inventory.productName'
                                                        )}
                                                        ：{item.productLanguageName.tc}
                                                    </AntRow>
                                                </AntCol>
                                                <AntCol span={24}>
                                                    <AntRow>
                                                        {language.getText(
                                                            'offlineOrder.inventory.barCode'
                                                        )}
                                                        ：{item.barCode}
                                                    </AntRow>
                                                </AntCol>
                                                <AntCol span={24}>
                                                    <AntRow>
                                                        {language.getText(
                                                            'offlineOrder.inventory.brandLanguageName'
                                                        )}
                                                        ：{item.brandLanguageName.tc}
                                                    </AntRow>
                                                </AntCol>
                                                <AntCol span={24}>
                                                    <AntRow>
                                                        {language.getText(
                                                            'offlineOrder.inventory.specsType'
                                                        )}
                                                        ：
                                                        {item.source === 2
                                                            ? language.getText(
                                                                  'offlineOrder.inventory.combinationGoods'
                                                              )
                                                            : item.specsType === 0
                                                            ? language.getText(
                                                                  'offlineOrder.inventory.generalMerchandise'
                                                              )
                                                            : language.getText(
                                                                  'offlineOrder.inventory.weighGoods'
                                                              )}
                                                    </AntRow>
                                                </AntCol>
                                                <AntCol span={24}>
                                                    <AntRow>
                                                        {language.getText(
                                                            'offlineOrder.inventory.buyQuantity'
                                                        )}
                                                        ：{item.quantity}
                                                    </AntRow>
                                                </AntCol>
                                            </AntRow>
                                            <Table
                                                scroll={{ x: 'fit-content' }}
                                                columns={columns}
                                                dataSource={item.stockBatchVolist || []}
                                                components={{ body: { cell: EditableCell } }}
                                                pagination={false}
                                                rowKey={'batchNo'}
                                            ></Table>
                                        </div>
                                    ))}
                            </>
                        )}
                    </AntForm.Item>
                )}
            </>
        );
    }
);

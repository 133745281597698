import { services } from '@comall-backend-builder/core';
import { message } from 'antd';

export const limitImg = (
    file: any,
    fileList: any[],
    totalList: any[],
    config: { maxSize?: number; maxCount?: number; totalMax?: number; accept?: string }
): Promise<boolean> => {
    //type
    return new Promise((resolve, reject) => {
        const { maxSize, maxCount, totalMax, accept } = config;
        const accepts = (accept || '').split(',');

        if (accepts && accepts.length > 0 && accepts.indexOf(file.type) === -1) {
            message.error(
                services.interpolate(services.language.getText('uploadImageVideo.error.type'), {
                    maxCount: accept?.replace('/', '//'),
                })
            );
            reject();
            return;
        }
        const imgList = totalList.filter((item) => !item.video);
        //上传总数大小判断
        if (totalMax && totalMax < fileList.length + totalList.length) {
            message.error(
                services.interpolate(services.language.getText('uploadImageVideo.error.limit'), {
                    maxCount: totalMax,
                })
            );
            reject();
            return;
        }
        //上传图片总数大小判断
        if (
            maxCount &&
            (maxCount < fileList.length || maxCount < fileList.length + imgList.length)
        ) {
            message.error(
                services.interpolate(
                    services.language.getText('uploadImageVideo.error.img.limit'),
                    { maxCount }
                )
            );
            reject();
            return;
        }
        if (maxSize) {
            if (file.size > maxSize * 1024) {
                //超出最大限制
                message.error(
                    services.interpolate(
                        services.language.getText('uploadImageVideo.error.img.size'),
                        { maxSize: maxSize / 1024 }
                    )
                );
                reject();
                return;
            }
        }
        resolve(true);
    });
};

export const limitVideo = (
    file: any,
    fileList: any[],
    totalList: any[],
    config: {
        maxSize?: number;
        maxCount?: number;
        minDuration?: number;
        maxDuration?: number;
        totalMax?: number;
        accept?: string;
    }
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const { maxSize, maxCount, maxDuration, minDuration, totalMax } = config;
        const videoList = totalList.filter((item) => item.video);
        //上传总数大小
        if (totalMax && totalMax < fileList.length + totalList.length) {
            message.error(
                services.interpolate(services.language.getText('uploadImageVideo.error.limit'), {
                    maxCount: totalMax,
                })
            );
            reject();
            return;
        }
        //上传视频总数大小
        if (
            maxCount &&
            (maxCount < fileList.length || maxCount < fileList.length + videoList.length)
        ) {
            message.error(
                services.interpolate(
                    services.language.getText('uploadImageVideo.error.img.limit'),
                    { maxCount }
                )
            );
            reject();
            return;
        }
        if (maxSize) {
            console.warn('video size=', file.size);
            if (file.size > maxSize * 1024) {
                //超出最大限制
                message.error(
                    services.interpolate(
                        services.language.getText('uploadImageVideo.error.video.size'),
                        { maxSize: maxSize / 1024 }
                    )
                );
                reject();
                return;
            }
        }
        window.URL = window.URL || window.webkitURL;
        let video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function(ev: any) {
            window.URL.revokeObjectURL(video.src);
            file.aspectRatio = video.videoWidth / video.videoHeight;
            let duration = video.duration;
            console.warn('video duration=', duration);
            if (maxDuration && duration > maxDuration + 1) {
                message.error(
                    services.interpolate(
                        services.language.getText('uploadImageVideo.error.video.duration'),
                        { maxDuration }
                    )
                );
                reject();
                return;
            }
            if (minDuration && duration < minDuration) {
                message.error(
                    services.interpolate(
                        services.language.getText('uploadImageVideo.error.video.minDuration'),
                        { minDuration }
                    )
                );
                reject();
                return;
            }
            resolve(true);
        };
        video.src = URL.createObjectURL(file);
    });
};

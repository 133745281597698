/*
 * @Author: zhulu
 * @Date: 2022-10-09 15:12:49
 * @Description: 装修管理
 */
export { config as SearchWordsManageConfig } from './search-words-manage';
export { config as HotWordsManageConfig } from './hot-words-manage';
export { config as guessYouLikeSettingConfig } from './guess-you-like-setting';
export { config as MenuBarManagementConfig } from './menu-bar-management';
export { config as NoticeBarManagementConfig } from './notice-bar-management';
export { config as RecommendedBrandConfig } from './recommended-brand';
export { config as PopupAdsConfig } from './popup-ads';

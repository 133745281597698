import React from 'react';

import { modes } from '@comall-backend-builder/types';

export class ArrayThirdPartyOrderGoodsMode extends modes.ArrayMode {
    public getDisplayComponent(value: any[] = []) {
        return (
            <div>
                {value.map((item: any) => (
                    <div key={item.sku}>
                        <span style={{ paddingRight: '30px' }}>{item.sku}</span>
                        <span>*{item.quantity}</span>
                    </div>
                ))}
            </div>
        );
    }
}

import { Config } from '@comall-backend-builder/core/lib/parser';
import { MEMBER_SOURCE_LIST } from '@/configs/pro-table-constants';
import { services } from '@comall-backend-builder/core';

import { recordImportLogs } from '@/services';
import { createPageLayout, tableMixin } from '@/configs/mixin';

export const config: Config = {
    entities: {
        membershipSourceList: {
            apiPath: '/loader/dc-user/admin/memberSourceList',
            properties: {
                id: { type: 'string', displayName: '<<membership.id>>' },
                source: {
                    type: 'string',
                    displayName: '<<memberSource.source>>',
                },
                remark: {
                    type: 'string',
                    displayName: '<<memberSource.remark>>',
                },
                dchLivingLogo: {
                    type: 'string.image.text',
                    displayName: '<<memberSource.dchlivingLogo>>',
                },
                foodMartLogo: {
                    type: 'string.image.text',
                    displayName: '<<memberSource.foodmartLogo>>',
                },
                sourceLogo: {
                    type: 'string.image.text',
                    displayName: '<<memberSource.sourceIcon>>',
                },
            },
            filters: {},
        },
    },
    components: {
        MembershipSourceListContainer: {
            component: 'Viewport',
        },
        MembershipSourceList: {
            entity: 'membershipSourceList',
            ...createPageLayout([
                {
                    component: 'MembershipSourceListAction',
                    style: { marginBottom: 15 },
                },
                {
                    privilege: {
                        path: 'member.member_source.member_source_view_list',
                        level: 'full',
                    },
                    component: 'MembershipSourceListTable',
                },
            ]),
        },
        MembershipSourceListAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Button',
                    text: '<<memberSource.add>>',
                    icon: 'plus',
                    type: 'primary',
                    route: '/member-source/add',
                    style: {
                        width: 150,
                        marginRight: 50,
                    },
                    privilege: {
                        path: 'member.member_source.member_source_add',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productInventory.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 10,
                        marginRight: 10,
                        alignSelf: 'center',
                    },
                    privilege: {
                        path: 'member.member_source.member_source_download_import_update_template',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/IMPORT%20MEMBER%20SOURCE.xlsx`,
                },
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<memberSource.importUpdate>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('memberSource.importUpdate'),
                                apiPath: `/dc-user/admin/member_source/import`,
                                params: { resourceId: response.id },
                            });
                        }
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'member.member_source.member_source_import',
                        level: 'full',
                    },
                },
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<memberSource.export>>',
                    icon: 'download',
                    style: {
                        flexGrow: 0,
                        marginLeft: 15,
                    },
                    source: {
                        apiPath: '/dc-user/admin/member_source/export/take',
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'member.member_source.member_source_export',
                        level: 'full',
                    },
                },
            ],
        },
        MembershipSourceListTable: {
            ...tableMixin,
            componentName: MEMBER_SOURCE_LIST,
            columns: [
                { property: 'source' },
                { property: 'remark', width: 200 },
                {
                    property: 'dchLivingLogo',
                    displayConfig: {
                        style: {
                            width: 50,
                            height: 50,
                            border: '1px solid #eee',
                        },
                    },
                },
                {
                    property: 'foodMartLogo',
                    displayConfig: {
                        style: {
                            width: 50,
                            height: 50,
                            border: '1px solid #eee',
                        },
                    },
                },
                {
                    property: 'sourceLogo',
                    displayConfig: {
                        style: {
                            width: 50,
                            height: 50,
                            border: '1px solid #eee',
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                width: 200,
                items: [
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/member-source/edit/{{row.id}}',
                        privilege: {
                            path: 'member.member_source.member_source_edit',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<membershipLevel.blackSkuProduct.title>>',
                        type: 'link',
                        path: '/member-source/black-product-list/{{row.id}}',
                        privilege: {
                            path: 'member.member_source.member_source_black_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

/**
 * 三方映射sku报表
 */

import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    createPageLayout,
    flexibleFilterForm,
    getLanguageColumns,
    getLanguageProperties,
    tableMixin,
} from '../mixin';
import { getFields } from './utils';

const filterProperties = {
    channelId: {
        type: 'string.option.autoCompleteDefault',
        displayName: '<<thirdPartyManagement.thirdPartyName>>',
        options: [],
        source: {
            apiPath: '/dc-master-data/admin/channel/findOnlineThirdChannels',
        },
    },
};
const entityProperties = {
    id: { type: 'string' },
    name: {
        type: 'object.subForm',
        properties: {
            ...getLanguageProperties('string', 'productPrice.name'),
        },
    },
    sku: {
        type: 'string',
        displayName: 'SKU',
    },
    thirdName: {
        type: 'string',
        displayName: '<<thirdPartyManagement.thirdPartyName>>',
    },
    specs: {
        type: 'string',
        displayName: '<<productPrice.specsType>>',
    },
    timeStr: {
        type: 'string',
        displayName: '<<productPrice.lastedModifyTime>>',
    },
};

export const config: Config = {
    entities: {
        productMapReport: {
            apiPath: '/loader/dc-order/admin/thirdSku',
            properties: entityProperties,
            filters: filterProperties,
        },
    },
    components: {
        ProductMapReportPage: {
            entity: 'productMapReport',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: getFields(filterProperties),
                    privilege: {
                        path: 'forms.third_sku.list',
                        level: 'full',
                    },
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: { marginBottom: '16px' },
                    items: [
                        {
                            component: 'ExportButtonWithFilter',
                            text: '<<report.exportResult>>',
                            icon: 'download',
                            type: 'primary',
                            style: {
                                flexGrow: 0,
                                marginRight: 15,
                            },
                            source: {
                                apiPath: '/dc-order/admin/thirdSku/exportStatement/thirdSku/take',
                            },
                            privilege: {
                                path: 'forms.third_sku.export',
                                level: 'full',
                            },

                            isAsync: true,
                            showSuccessTips: true,
                            showErrorMessage: true,
                        },
                    ],
                },
                {
                    ...tableMixin,
                    columns: [
                        ...getLanguageColumns('name'),
                        { property: 'sku' },
                        { property: 'thirdName' },
                        { property: 'specs' },
                        { property: 'timeStr', fixed: 'right' },
                    ],
                    privilege: {
                        path: 'forms.third_sku.list',
                        level: 'full',
                    },
                },
            ]),
        },
    },
};

import React, { Component } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { actions, services } from '@comall-backend-builder/core';
import { STORE_TREE_STORAGE_KEY } from '@/constants';
interface LogoutProps {
    entity: any;
    params: any;
}
export class Logout extends Component<LogoutProps> {
    logout = async () => {
        const { entity, params } = this.props;
        entity.setMeta({ Logout: true });
        entity.delete(params, {
            action: actions.logoutAction(),
        });
        services.localStorage.remove('dcStore'); // 订单管理的门店id
        services.localStorage.remove('dcOrganization'); // 商品库存的Division
        services.localStorage.remove('limitChannelId'); // 商品限购的渠道id
        services.localStorage.remove('freebieGroupChannelId'); //赠品组的渠道id
        services.localStorage.remove('channelIds'); // 用户的渠道id
        services.localStorage.remove('retailStore');
        services.localStorage.remove('onlineStore');
        services.localStorage.remove('product-shelf-online-stores'); // 上下架管理选中的线上门店
        services.localStorage.remove(STORE_TREE_STORAGE_KEY);
        localStorage.removeItem('keycloak'); // 清楚keyclock数据
    };
    render() {
        return (
            <Button
                text={services.language.getText('logout')}
                icon='logout'
                onClick={this.logout}
                style={{ border: 'none', padding: 0 }}
            />
        );
    }
}

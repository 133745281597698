/*
 * @Author: zhulu
 * @Date: 2022-04-28 14:45:04
 * @Description: 商品套装
 */

import { message } from 'antd';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { load } from '@comall-backend-builder/core/lib/loaders';
import { PRODUCT_SUITE } from '@/configs/pro-table-constants';

import { Config } from '@comall-backend-builder/core/lib/parser';

import {
    tableMixin,
    getLanguageColumns,
    getLanguageProperties,
    formFooter,
    flexibleFilterForm,
    deletePopConfirmConfig,
} from '@/configs/mixin';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';

export const config: Config = {
    entities: {
        productSuite: {
            apiPath: '/dc-goods/admin/suite/pageOfOnlineStore',
            properties: {
                id: { type: 'string', displayName: 'ID' },
                itemCount: { type: 'string', displayName: '<<productSuite.count>>' },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<productSuite.modifyTime>>',
                },
                lastedModifyUser: {
                    type: 'string',
                    displayName: '<<productSuite.modifyUser>>',
                },
                name: {
                    properties: getLanguageProperties('string', 'productSuite.name', {
                        controlConfig: {
                            placeholder: '<<productSuite.namePlaceholder>>',
                        },
                    }),
                    type: 'object.subForm',
                },
            },
            filters: {
                nameKeyword: { type: 'string', displayName: '<<keyword>>' },
                csku: { type: 'string', displayName: '<<productSuite.csku>>' },
            },
        },
        productSuiteInfo: {
            apiPath: '/loader/dc-goods/admin/suite',
            properties: {
                id: { type: 'string', displayName: 'ID' },
                name: {
                    properties: getLanguageProperties('string', 'productSuite.name', {
                        controlConfig: {
                            placeholder: '<<productSuite.namePlaceholder>>',
                        },
                        rules: [{ required: true }],
                    }),
                    type: 'object.subForm',
                },
                items: {
                    type: 'array.productSuiteItems',
                    displayName: '选择商品',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-goods/admin/cskus/queryCskuByPage',
                    },
                    rules: [{ required: true }],
                },
            },
        },
    },
    components: {
        ProductSuiteView: {
            component: 'Viewport',
        },
        ProductSuiteList: {
            component: 'FlexLayout',
            entity: 'productSuite',
            direction: 'vertical',
            className: 'product-price-view',
            items: [
                {
                    component: 'StoreSelectorContainer',
                    params: {},
                    storeKey: 'onlineStoreId',
                    filterConfig: {
                        component: 'ProductSuiteFilter',
                        style: { marginBottom: 15 },
                        privilege: {
                            path: 'goods.goods_merge_manage.view_list',
                            level: 'full',
                        },
                    },
                    buttonConfig: {
                        component: 'ProductSuiteAction',
                        style: { marginBottom: 15 },
                    },
                    tableConfig: {
                        privilege: {
                            path: 'goods.goods_merge_manage.view_list',
                            level: 'full',
                        },
                        component: 'ProductSuiteTable',
                    },
                },
            ],
        },
        ProductSuiteFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'nameKeyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productSuite.namePlaceholder>>',
                    },
                },
                {
                    property: 'csku',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productSuite.cskuPlaceholder>>',
                    },
                },
            ],
        },
        ProductSuiteAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'Button',
                    text: '<<productSuite.add>>',
                    icon: 'plus',
                    type: 'primary',
                    style: {
                        flexGrow: 0,
                        marginRight: 15,
                    },
                    route: `/product-suite/add`,
                    privilege: {
                        path: 'goods.goods_merge_manage.add',
                        level: 'full',
                    },
                },
            ],
        },
        ProductSuiteTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: PRODUCT_SUITE,
            scroll: {
                x: 'max-content',
            },
            loadFirstPage: false,
            columns: [
                ...getLanguageColumns('name'),
                { property: 'id' },
                { property: 'itemCount' },
                { property: 'lastedModifyTime' },
                { property: 'lastedModifyUser' },
            ],
            onDeleteItem: async (id: string, row: any) => {
                try {
                    load('delete', {
                        apiPath: '/loader/dc-goods/admin/suite',
                        params: row,
                    });
                    message.success(language.getText('modifyFully'));
                } catch (e) {
                    errorHandle(e as ErrorInfo);
                }
            },
            actionColumn: {
                title: '<<actions>>',
                width: 100,
                fixed: 'right',
                items: [
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/product-suite/edit/{{row.id}}',
                        privilege: {
                            path: 'goods.goods_merge_manage.edit',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        text: '<<delete>>',
                        component: 'TableActionDelete',
                        config: {
                            ...deletePopConfirmConfig,
                            apiPath: '/dc-goods/admin/suite/delete/{{row.id}}',
                        },
                        privilege: {
                            path: 'goods.goods_merge_manage.delete',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ProductSuiteAdd: {
            component: 'Card',
            content: {
                component: 'ProductSuiteAddForm',
            },
        },
        ProductSuiteAddForm: {
            component: 'CreateFormPlus',
            entity: 'productSuiteInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            className: 'none-sub-form brand-edit-from',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                ...getLanguageColumns('name'),
                {
                    property: 'items',
                },
            ],
        },
        ProductSuiteEdit: {
            component: 'Card',
            content: {
                component: 'ProductSuiteEditForm',
            },
        },
        ProductSuiteEditForm: {
            component: 'ModifyFormPlus',
            entity: 'productSuiteInfo',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            className: 'none-sub-form brand-edit-from',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                { property: 'id', modifiable: false },
                ...getLanguageColumns('name'),
                {
                    property: 'items',
                },
            ],
        },
    },
};

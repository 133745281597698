/*
 * @Author: 朱璐
 * @Date: 2022-10-20 14:07:26
 * @Description: 优惠券 override code
 */
import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';
/**
 * overrideCode 状态
 */
export enum OVERRIDE_CODE_VALUES {
    /** 未开始 */
    UN_START = 1,
    /** 已结束 */
    FINISHED = 0,
    /** 进行中 */
    PROGRESSING = 2,
}

/**
 * overrideCode 状态名
 */
export const OVERRIDE_CODE_NAMES = {
    [OVERRIDE_CODE_VALUES.UN_START]: language.getText('overrideCode.statusMap.unStart'),
    [OVERRIDE_CODE_VALUES.FINISHED]: language.getText('overrideCode.statusMap.finished'),
    [OVERRIDE_CODE_VALUES.PROGRESSING]: language.getText('overrideCode.statusMap.progressing'),
};
/**
 * overrideCode 状态选项
 */
export const OVERRIDE_CODE_OPTIONS = [
    {
        id: OVERRIDE_CODE_VALUES.UN_START,
        name: language.getText('overrideCode.statusMap.unStart'),
        status: 'success',
    },
    {
        id: OVERRIDE_CODE_VALUES.FINISHED,
        name: language.getText('overrideCode.statusMap.finished'),
        status: 'default',
    },
    {
        id: OVERRIDE_CODE_VALUES.PROGRESSING,
        name: language.getText('overrideCode.statusMap.progressing'),
        status: 'warning',
    },
];

/**
 * override code 类型value
 */
export enum OVERRIDE_CODE_TYPE_VALUES {
    /** 自定义 */
    CUSTOM = '0',
    /** 预设 */
    PRESET = '1',
}
export const OVERRIDE_CODE_TYPE__NAMES = {
    [OVERRIDE_CODE_TYPE_VALUES.CUSTOM]: language.getText('overrideCode.typeMap.custom'),
    [OVERRIDE_CODE_TYPE_VALUES.PRESET]: language.getText('overrideCode.typeMap.preset'),
};
export const OVERRIDE_CODE_TYPE_OPTIONS = generateOptions(
    OVERRIDE_CODE_TYPE_VALUES,
    OVERRIDE_CODE_TYPE__NAMES
);

/**
 * 他方券状态VALUES
 */
export enum THIRD_PARTY_COUPON_STATUS_VALUES {
    // /** 待绑定 */
    // WAIT_BINDING = '1',
    // /** 待使用 */
    // WAIT_USE = '2',
    // /** 已使用 */
    // USED = '3',
    // /** 已过期 */
    // EXPIRED = '4',
    /** 未发放 */
    WAIT_SEND = '5',
    /** 已发放 */
    SENDED = '6',
}
export const THIRD_PARTY_COUPON_STATUS_NAMES = {
    [THIRD_PARTY_COUPON_STATUS_VALUES.WAIT_SEND]: language.getText(
        'couponManage.thirdPartyCoupon.statusMap.waitSend'
    ),
    [THIRD_PARTY_COUPON_STATUS_VALUES.SENDED]: language.getText(
        'couponManage.thirdPartyCoupon.statusMap.sended'
    ),
};
export const THIRD_PARTY_COUPON_STATUS_OPTIONS = generateOptions(
    THIRD_PARTY_COUPON_STATUS_VALUES,
    THIRD_PARTY_COUPON_STATUS_NAMES
);

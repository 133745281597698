// export { config as licenseProductReport } from './license-product-report';
export { config as orderShipping } from './order-shipping';
export { config as orderSummary } from './order-summary';
export { config as orderDetail } from './order-detail';
export { config as orderDetailUnPrice } from './order-detail-unprice';
export { config as refundSummary } from './refund-summary';

export { config as voucher } from './voucher';
export { config as productMapReport } from './product-map';

export const reportConfig = {
    components: {
        ReportPage: {
            component: 'Viewport',
        },
    },
};

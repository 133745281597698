import React, { createElement } from 'react';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { RefundListItem } from '@/components/refund-list-item';
import { REFUND_STATUS_NAMES, REFUND_STATUS_VALUES } from '@/constants/after-sale';
import { checkPrivilege } from '@/components/privilege';
import { Modal, message } from 'antd';
import { RefundMethod } from './refund-method';

/**
 * 审核不通过按钮
 */
const rejectBtn = (props: any) => {
    const {
        id,
        parentProps: { entity },
    } = props;
    if (!checkPrivilege('aftermarket.order_refund_view.reject')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'completePackButton',
        config: {
            type: 'primary',
            text: services.language.getText('afterSale.statusMap.rejected'),
            style: {
                marginRight: 15,
            },
            onClick: () => {
                Modal.confirm({
                    title: services.language.getText('afterSale.tips.reject'),
                    onOk: () => {
                        services.api
                            .get({ id }, { apiPath: `/base-order/admin/refund/fail` })
                            .then(() => {
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 重新发送退款请求
 */
const resendRefundBtn = (props: any) => {
    const {
        id,
        parentProps: { entity },
    } = props;
    if (!checkPrivilege('aftermarket.order_refund_view.refund_again')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'completePackButton',
        config: {
            type: 'primary',
            text: services.language.getText('afterSale.statusMap.resendRefund'),
            style: {
                marginRight: 15,
            },
            onClick: () => {
                Modal.confirm({
                    title: services.language.getText('afterSale.tips.resend'),
                    onOk: () => {
                        services.api
                            .get({ id }, { apiPath: `/base-order/admin/refund/refundagain` })
                            .then(() => {
                                message.success(
                                    services.language.getText('afterSale.tips.message')
                                );
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 显示按钮映射
 */
const showBtnMap: AnyObject = {
    approvalPass: {
        component: RefundMethod,
    },
    approvalFail: rejectBtn,
    refundAgain: resendRefundBtn,
};

export const RefundDataListItem: React.FC<any> = (props) => {
    const getItemHeader = () => {
        const {
            merchantName,
            deliveryFlag,
            aftermarketNumber,
            aftermarketId,
            memberLabel,
            orderRefundNumber,
            ...rest
        } = props;
        return {
            merchantName,
            deliveryFlag,
            aftermarketNumber,
            memberLabel,
            orderRefundNumber,
            ...rest,
        };
    };

    const getItemContent = () => {
        const {
            id,
            orderRefundStatusEnum,
            returnGoodsSign,
            outerOrderNumber,
            aftermarketId,
            orderCustomer,
            receiveAddress,
            parentOrderNumber,
            createTime,
            refundFailedReason,
            ...rest
        } = props;

        const status = {
            title: REFUND_STATUS_NAMES[orderRefundStatusEnum as REFUND_STATUS_VALUES],
            color:
                orderRefundStatusEnum === REFUND_STATUS_VALUES.REJECTED ? 'transparent' : 'green',
        };

        return {
            status,
            info: {
                id,
                returnGoodsSign,
                outerOrderNumber,
                parentOrderNumber,
                aftermarketId,
                customer: orderCustomer,
                receiveAddress,
                createTime,
                refundFailedReason,
                ...rest,
            },
        };
    };

    const getItemFooter = () => {
        return `${services.language.getText('afterSale.reviewer')}：${props.approvalUser || '-'}`;
    };

    const renderOperational = (): React.ReactElement => {
        const { showButtons } = props;
        return (
            <>
                {Object.keys(showBtnMap).map((key) => {
                    if (showButtons[key]) {
                        const getConfig = showBtnMap[key];

                        // react component
                        if (getConfig.component) {
                            return createElement(
                                getConfig.component,
                                Object.assign(
                                    {
                                        key,
                                    },
                                    props
                                )
                            );
                        }

                        const config = getConfig(props);
                        if (config) {
                            return createElement(
                                ComponentsManager.get(config.component),
                                Object.assign(
                                    {
                                        key,
                                    },
                                    config.config
                                )
                            );
                        }
                    }
                    return null;
                })}
            </>
        );
    };
    return (
        <RefundListItem
            key={props.id}
            header={getItemHeader()}
            content={getItemContent()}
            footer={getItemFooter()}
            renderOperational={renderOperational}
        />
    );
};

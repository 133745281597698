import React, { PureComponent } from 'react';
import {
    Card as AntCard,
    List as AntList,
    Row as AntRow,
    Col as AntCol,
    Tag,
    message as AntMessage,
    Checkbox,
} from 'antd';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import { Price } from '../../../components/price';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import {
    PRODUCT_SOURCE_TYPE_NAMES,
    PRODUCT_SOURCE_TYPE_VALUES,
    SPECS_TYPE_NAMES,
    SPECS_TYPE_VALUES,
} from '@/constants/product';
import { AFTER_SALE_TYPE_VALUES } from '@/constants/after-sale';
import { Button } from '@comall-backend-builder/components-basis';
//@ts-ignore
import InputNumber from 'bee-input-number';
import 'bee-input-number/build/InputNumber.css';
import { Privilege } from '@/components/privilege';
interface OrderInfoProductProps {
    [key: string]: any;
}
/** 创建售后单-选择商品信息 */
interface CreateProduct {
    id: number;
    skuId: number;
    quantity: number;
}
/** 创建售后单参数*/
interface CreateParams {
    orderNumber: string;
    productList: CreateProduct[];
    remarkList?: {
        content: string;
        createTime: string;
        createUser: string;
    };
    returnGoodsSign: AFTER_SALE_TYPE_VALUES;
    refundFreightAmount?: number;
}
/**
 * 创建售后单 - 售后单产品
 */
class afterSaleCreateProduct extends PureComponent<OrderInfoProductProps, any> {
    state: {
        checked: boolean;
        createProductList: { [key: string]: CreateProduct };
    } = {
        createProductList: {},
        checked: false,
    };

    renderProductItem = (item: any, subProduct = false) => {
        const {
            id,
            skuId,
            enName,
            name,
            barcode,
            csku,
            weight,
            sourceType,
            specsType,
            quantity,
            lackQuantity,
            pickingQuantity,
            pictureUrl,
            refundQuantity,
            salePrices,
        } = item;
        const orderStatus = get(this.props, 'orderTraces.orderStatus');

        function renderQuantity() {
            switch (specsType) {
                case SPECS_TYPE_VALUES.NORMAL:
                    return (
                        <>
                            {services.language.getText('afterSale.product.orderQuantity')}：
                            {quantity}
                            {(pickingQuantity > 0 || lackQuantity > 0) && (
                                <span>
                                    {` (${services.language.getText(
                                        'order.product.lackQuantity'
                                    )}x${lackQuantity}/${services.language.getText(
                                        'order.product.pickingQuantity'
                                    )}x${pickingQuantity})`}
                                </span>
                            )}
                        </>
                    );
                case SPECS_TYPE_VALUES.WEIGH:
                    return (
                        <>
                            {services.language.getText('weight')}：{weight}
                        </>
                    );
            }
        }

        function renderProductTags() {
            return (
                <span
                    style={{
                        paddingLeft: '15px',
                    }}
                >
                    {sourceType === PRODUCT_SOURCE_TYPE_VALUES.COMBINATION && (
                        <Tag color='#448ef8'>
                            {PRODUCT_SOURCE_TYPE_NAMES[PRODUCT_SOURCE_TYPE_VALUES.COMBINATION]}*
                            {quantity}
                        </Tag>
                    )}
                    {sourceType === PRODUCT_SOURCE_TYPE_VALUES.VIRTUAL && (
                        <Tag color='#e89e43'>
                            {PRODUCT_SOURCE_TYPE_NAMES[PRODUCT_SOURCE_TYPE_VALUES.VIRTUAL]}
                        </Tag>
                    )}
                </span>
            );
        }

        const changeAntInputNumber = (createProduct: CreateProduct) => {
            this.setState((val: any) => {
                val.createProductList[createProduct.id] = createProduct;
                return val;
            });
            // this.createProductList[createProduct.id] = createProduct;
        };

        return (
            <div
                className='order-info-product-item'
                style={{
                    width: '100%',
                    lineHeight: '2.2em',
                    display: 'flex',
                }}
            >
                <div
                    className={classNames('product-item-img', {
                        'out-of-stock': lackQuantity,
                        'product-item-img--sub': subProduct,
                    })}
                >
                    <img className='product-img' alt='' src={pictureUrl} />
                </div>
                <div
                    className={classNames('product-item-info', {
                        'product-item-info--sub': subProduct,
                    })}
                >
                    <AntRow>
                        <AntCol>
                            {services.language.getText('order.product.name')}：{name || enName}
                            {renderProductTags()}
                        </AntCol>
                    </AntRow>
                    <AntRow>
                        <AntCol span={8}>SKU：{csku}</AntCol>
                        <AntCol span={8}>
                            {language.getText('productBarcode')}：{barcode}
                        </AntCol>
                        <AntCol span={8}>
                            {language.getText('productSpecsType.title')}：
                            {SPECS_TYPE_NAMES[specsType as SPECS_TYPE_VALUES]}
                        </AntCol>
                    </AntRow>
                    <AntRow
                        style={{
                            marginBottom: isEmpty(salePrices) ? 0 : 16,
                        }}
                    >
                        <AntCol span={8} style={{ zIndex: 2 }}>
                            {services.language.getText('afterSale.product.salePrice')}：
                            <Price price={salePrices} dividing={false} />
                        </AntCol>
                        <AntCol span={8}>{renderQuantity()}</AntCol>
                        {orderStatus !== 'CANCELLED' && refundQuantity > 0 && (
                            <AntCol span={4}>
                                {services.language.getText('order.product.refundQuantity')}：
                                {refundQuantity}
                            </AntCol>
                        )}
                    </AntRow>
                </div>
                {!subProduct ? (
                    <div className='product-item-input'>
                        <InputNumber
                            value={this.state.createProductList[id]?.quantity || 0}
                            min={0}
                            max={quantity}
                            onChange={(e: number) =>
                                changeAntInputNumber({ id, skuId, quantity: e as number })
                            }
                        />
                    </div>
                ) : null}
            </div>
        );
    };

    renderProduct = (item: any) => {
        return (
            <>
                <AntList.Item>{this.renderProductItem(item)}</AntList.Item>
                {item.combinationGoods && item.combinationGoods.length > 0 && (
                    <AntList.Item
                        style={{
                            flexFlow: 'column',
                        }}
                    >
                        {item.combinationGoods.map((subProduct: any) =>
                            this.renderProductItem(subProduct, true)
                        )}
                    </AntList.Item>
                )}
            </>
        );
    };

    cancelButton = () => {
        return (
            <Button route='goBack' style={{ marginRight: '10px' }}>
                {services.language.getText('cancel')}
            </Button>
        );
    };
    submitButton = () => {
        const { createProductList } = this.state;
        const handleSubmit = () => {
            if (
                (Object.values(createProductList).length === 0 ||
                    !Object.values(createProductList).some((i) => Number(i.quantity))) &&
                !this.state.checked
            )
                return AntMessage.warning(services.language.getText('afterSale.noSelectTips'));
            const createParams: CreateParams = {
                orderNumber: this.props.orderNumber,
                productList: Object.values(createProductList).filter((i) => Number(i.quantity)),
                remarkList: this.props.remarks.map((item: any) => ({ ...item, createUser: null })),
                returnGoodsSign: this.props.afterSaleType,
                refundFreightAmount: this.state.checked
                    ? this.props.refundFreightAmount
                    : undefined,
            };
            services.api
                .post(
                    {
                        ...createParams,
                    },
                    {
                        apiPath: '/base-order/admin/aftermarket/create',
                    }
                )
                .then((res) => {
                    services.navigation.goto(`/afterSalesOrder`);
                })
                .catch(services.errorHandle);
        };
        return (
            <Button type='primary' style={{ marginRight: '10px' }} onClick={handleSubmit}>
                {services.language.getText('submit')}
            </Button>
        );
    };
    approvedButton = () => {
        const { createProductList } = this.state;
        const handleApproved = () => {
            if (
                (Object.values(createProductList).length === 0 ||
                    !Object.values(createProductList).some((i) => Number(i.quantity))) &&
                !this.state.checked
            )
                return AntMessage.warning(services.language.getText('afterSale.noSelectTips'));
            const createParams: CreateParams = {
                orderNumber: this.props.orderNumber,
                productList: Object.values(createProductList).filter((i) => Number(i.quantity)),
                remarkList: this.props.remarks.map((item: any) => ({ ...item, createUser: null })),
                returnGoodsSign: this.props.afterSaleType,
                refundFreightAmount: this.state.checked
                    ? this.props.refundFreightAmount
                    : undefined,
            };
            services.api
                .post(
                    {
                        ...createParams,
                    },
                    {
                        apiPath: '/base-order/admin/aftermarket/createandpass',
                    }
                )
                .then((res) => {
                    services.navigation.goto(`/afterSalesOrder`);
                })
                .catch(services.errorHandle);
        };
        return (
            <Privilege path='aftermarket.after_sale_view.add_pass'>
                <Button type='primary' onClick={handleApproved}>
                    {services.language.getText('afterSale.submitApproved')}
                </Button>
            </Privilege>
        );
    };
    componentWillUnmount() {
        this.props.entity.setFields({});
    }
    render() {
        const { checked } = this.state;
        const { itemPos, style, refundFreightAmount } = this.props;
        const cardProps = {
            className: 'after-sale-create-product',
            bordered: false,
            title: services.language.getText('afterSale.selectAfterSaleNum'),
            style,
        };

        const listProps = {
            dataSource: itemPos?.filter((el: any) => el?.quantity > 0),
            renderItem: this.renderProduct,
        };

        return (
            <>
                {itemPos ? (
                    <>
                        <AntCard {...cardProps}>
                            <AntList {...listProps} />
                        </AntCard>
                    </>
                ) : null}
                {refundFreightAmount > 0 && (
                    <div className='freight-content'>
                        <div>
                            {services.language.getText('afterSale.freightCollected')}
                            {refundFreightAmount}
                        </div>
                        <div>
                            <Checkbox
                                checked={checked}
                                className='checkbox'
                                onChange={(e) => {
                                    this.setState((val: any) => ({
                                        ...val,
                                        checked: e.target.checked,
                                    }));
                                }}
                            ></Checkbox>
                            {services.language.getText('afterSale.refundFreight')}
                        </div>
                    </div>
                )}
                {itemPos && (
                    <div className='product-foot'>
                        {this.cancelButton()}
                        {this.submitButton()}
                        {this.approvedButton()}
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const AfterSaleCreateProduct = connect(mapStateToProps, null)(afterSaleCreateProduct);

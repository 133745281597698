import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { DeliveryFreightRuleRegionsSelector } from './delivery-freight-rule-regions-selector';
import './index.less';
import { services } from '@comall-backend-builder/core';
import { uuid } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';
import { cloneDeep, isEqual } from 'lodash';

export interface DeliveryFreightRulesSetUpProps {
    row: any;
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * value值
     */
    value: any;
    /**
     * onchange
     */
    onChange: (value: any, name: string) => void;
}

export class DeliveryFreightRulesSetUp extends Component<
    DeliveryFreightRulesSetUpProps,
    { value: KV[] }
> {
    state = { value: [] };
    static getDerivedStateFromProps(props: DeliveryFreightRulesSetUpProps, state: any) {
        const { value = [] } = props;

        if (value.length > 0 && !isEqual(state.value, value)) {
            return { value: value.map((item: any) => ({ ...item, key: item.key || uuid() })) };
        }
    }

    changeValue = (changeValue: any, valueIndex: number) => {
        const { name, onChange } = this.props;
        const { value } = this.state;
        let nVal: any[] = cloneDeep(value);
        nVal.splice(valueIndex, 1, { ...nVal[valueIndex], extraFreight: changeValue });
        onChange(nVal, name);
    };

    // 添加项
    addFreightRange = () => {
        const { name, onChange } = this.props;
        let { value } = this.state;
        onChange(
            [
                ...value,
                {
                    //@ts-ignore
                    deliveryArea: '',
                },
            ],
            name
        );
    };

    deleteFreightRange = (freightRangeIndex: number) => {
        const { name, onChange } = this.props;
        const { value } = this.state;
        let nVal: any[] = cloneDeep(value);
        nVal.splice(freightRangeIndex, 1);
        nVal =
            nVal.length <= 0
                ? [
                      {
                          //@ts-ignore
                          deliveryArea: '',
                      },
                  ]
                : nVal;
        onChange(nVal, name);
    };

    handleFreightRuleChange = (freight: any, valueIndex: number) => {
        const { name, onChange } = this.props;
        const { value } = this.state;
        const nVal = cloneDeep(value);
        //@ts-ignore
        nVal.splice(valueIndex, 1, { ...value[valueIndex], ...freight });
        onChange(nVal, name);
    };

    renderFreightRule = () => {
        const { value } = this.state;
        return (
            <div className='delivery-freight-rule-set-up'>
                <table className='delivery-freight-rule-table' cellSpacing={0} cellPadding={5}>
                    <tbody>
                        <tr className='table-tr'>
                            <th className='table-td-width-area'>
                                {services.language.getText('storeFreightRules.distributionArea')}
                            </th>
                            <th className='table-td-width-order'>
                                {services.language.getText('storeFreightRules.extraFreight')}
                            </th>
                            <th className='table-td-width-delete'></th>
                        </tr>
                        {(value || []).map((item: any, index: any) => {
                            return (
                                <tr
                                    key={item.key}
                                    style={{
                                        textAlign: 'left',
                                        borderBottom: '1px solid #e8e8e8',
                                    }}
                                >
                                    <td className='table-td-width-area'>
                                        <div className='table-td-width-area-button'>
                                            <DeliveryFreightRuleRegionsSelector
                                                freightRule={item}
                                                onChange={(val) => {
                                                    this.handleFreightRuleChange(val, index);
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td className='table-td-width-order'>
                                        {this.renderFreightRanges(item, index)}
                                    </td>
                                    <td>
                                        <div className='table-td-width-delete table-flex'>
                                            <span
                                                style={{
                                                    marginLeft: '5px',
                                                    color: '#1890ff',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={this.deleteFreightRange.bind(this, index)}
                                            >
                                                {services.language.getText('delete')}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div
                    className='delivery-freight-rule-add'
                    onClick={this.addFreightRange.bind(this)}
                >
                    {services.language.getText('storeFreightRules.addArea')}
                </div>
            </div>
        );
    };

    renderFreightRanges = (val: any, index: any) => {
        return (
            <div>
                <div className='table-flex'>
                    <div className='table-td-width table-flex'>
                        <InputNumber
                            type='number'
                            key={val.key}
                            min={0}
                            step={0.01}
                            precision={2}
                            value={val.extraFreight}
                            placeholder={services.language.getText('defaultPlaceholderInput')}
                            onChange={(changeValue: any) => this.changeValue(changeValue, index)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return this.renderFreightRule();
    }
}

import * as React from 'react';

import { StringOptionSelectMode } from '@comall-backend-builder/types';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';

export class StringOptionCheckboxMode extends StringOptionSelectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        return <CheckboxGroup {...controlInfo} />;
    }
}

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { get } from 'lodash';

/**
 * 状态类型
 */
export class StoreOrderMode extends modes.StringMode {
    getControlComponent(controlInfo: any) {
        const { entity } = controlInfo;
        const organizationName = get(entity, 'fields.organizationName');
        const retailStoreId = get(entity, 'fields.retailStoreId');
        return (
            <>
                <span style={{ paddingRight: 20 }}>{organizationName}</span>
                <span>{retailStoreId}</span>
            </>
        );
    }
}

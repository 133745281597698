import React, { PureComponent } from 'react';
import { matchPath } from 'react-router';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { RoutesManager } from '@comall-backend-builder/core';
import { OrderInfoStore } from '../order-info-store';
/**
 * 实际组件暴露的 Props
 *
 * 是由转发的 antd Props 组合组件自身的 Props 组成的
 */
interface BreadcrumbNavProps extends BreadcrumbProps {
    /**
     * 面包屑链接
     */
    location: BreadcrumbLocation;
}
interface BreadcrumbLocation {
    /**
     * 面包屑路径名称
     */
    pathname: string;
}

/**
 * 匹配路径参数
 */
interface MatchProps {
    /**
     * 路由地址
     */
    path: string;
    /**
     * 是否精确匹配
     */
    isExact: boolean;
}

/**
 * 功能同组件Breadcrumb,
 * 增加功能：如果在route中配置category，则会在菜单链接最前面加上一层菜单
 */
export class BreadcrumbNav extends PureComponent<BreadcrumbNavProps, any> {
    convertRoutes(pathname: string, originRoutes: Array<any>) {
        let routes: Array<any> = [];
        for (let route of originRoutes) {
            let match = matchPath(pathname, route);
            if (match) {
                let { breadcrumbName, childRoutes } = route;
                if (breadcrumbName) {
                    //如果有标识菜单分类，则增加一层路由
                    if (route.category) {
                        routes.push({ breadcrumbName: route.category, isCategory: true });
                    }
                    routes.push(route);
                }
                if (childRoutes) {
                    if (match.url !== '/') {
                        pathname = pathname.substring(match.url.length);
                    }
                    routes = routes.concat(this.convertRoutes(pathname, childRoutes));
                }
                break;
            }
        }
        return routes;
    }

    itemRender(route: any, params: string, routes: Array<any>, paths: Array<any>) {
        const isLastItem = routes.indexOf(route) === routes.length - 1;
        let href = '#/' + paths.join('/');
        if (route.isCategory || isLastItem) {
            return <span>{route.breadcrumbName}</span>;
        }
        return <a href={href}>{route.breadcrumbName}</a>;
    }

    render() {
        let pathname = this.props.location.pathname;
        let routes = this.convertRoutes(pathname, RoutesManager.getRoutes());
        let title = undefined;

        if (routes && routes.length) {
            title = routes[routes.length - 1].breadcrumbName;
        } else {
            return null;
        }

        return (
            <div className='breadcrumb-layout'>
                <div>
                    <AntBreadcrumb
                        className='breadcrumb-nav'
                        {...this.props}
                        itemRender={this.itemRender}
                        routes={routes}
                    />
                    <h1 className='breadcrumb-page-title'>{title}</h1>
                </div>
                <OrderInfoStore />
            </div>
        );
    }
}

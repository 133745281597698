import React from 'react';
import classNames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { ImagePreview } from '@comall-backend-builder/components-basis';
import { UploadImageVideo, UploadType } from '@/components/upload-image-video';

export class BrandImageMode extends modes.ArrayImagesMode {
    public getDisplayComponent(array: any, displayInfo: any): any {
        const { className, style, preview } = displayInfo;

        if (array && array.length) {
            // 图片配置信息
            const imageConfig = {
                className: classNames('type-array mode-array-images', className),
                style: { ...style, maxWidth: '200px' },
                src: array[0].path || array,
            };
            // 预览组件配置信息
            const previewProps = {
                type: 'popover',
                ...preview,
                imageConfig,
            };

            return <ImagePreview {...previewProps} />;
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        const {
            maxCount = 1,
            uploadUrl,
            maxSize,
            fileName,
            accept = 'image/png,image/jpg,image/jpeg,image/svg',
        } = controlInfo;

        const config = {
            maxCount,
            uploadType: ['image'] as UploadType[],
            img: {
                uploadUrl,
                maxSize,
                fileName,
                accept,
            },
        };
        return <UploadImageVideo {...config} />;
    }
}

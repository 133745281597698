import React from 'react';
import { Tag } from 'antd';
import { PriceType } from '@/components/price';
import { Countdown } from '@/components/countdown';
import { services } from '@comall-backend-builder/core';
import { KV } from '@/interfaces';
import { DELIVERY_TYPE_NAMES, DELIVERY_TYPE_VALUES, MEMBER_LABEL_VALUES } from '@/constants/order';
import { Privilege } from '../privilege';

const getText = services.language.getText;

interface Props {
    header: {
        partner: string; //第三方渠道用户标签
        merchantName: string; //合作商户
        waitDeliveryTime?: string; //预计提货时间
        taxFlag?: string; //Duty free or Duty paid
        saDepositFlag: boolean; // deposit订单标记
        deliveryType: DELIVERY_TYPE_VALUES; // 配送类型
        memberLabel: MEMBER_LABEL_VALUES; // 用户标签
    };
    content: {
        status: {
            title: string; //状态名称
            color: string; //颜色
        };
        info: {
            number: number; //订单件数
            concludeAmount: PriceType; //订单金额
            id: string; //订单id
            orderNumber: string; //订单编号
            outerOrderNumber: string; //外部订单编号
            posOrderNumber: string; //POS单号
            txnOrderNumber: string; //dfs txn 单号
            customer: string; //下单客户
            picker: string; //提货客户
            storeName: string; //门店
            stock?: {
                allLack: boolean; //是否全部售罄
                allLackRemark: string; //全部售罄备注
                stockItems: any[];
            }; //缺货信息
            pickedAmount: PriceType; //缺货配后价格
            paidPayAmount: string; //实付金额
            exceptions?: any[]; //异常订单详情
            saInfo: KV; // sax信息
            thirdPartyCreateTime: string;
            transportation?: string;
            frightNo?: string;
            departureDate?: string;
            depositProportion?: string;
            remainAmount?: PriceType; //剩余应付金额
            parentOrderNumber?: string;
            depositAmount?: PriceType; // deposit金额
            orderDepositStatus: String; //订单交易类型
            orderStore: any;
            staffDiscountFlag?: boolean; //员工折扣
            deliveryFlag?: string; //提货类型 0 自提 1配送
            receiveAddress: string; // 收货地址
            payType: string; // 支付方式
        };
    };
    footer: string;
    renderOperational: () => React.ReactElement;
}

export const OfflineOrderListItem: React.FC<Props> = (props) => {
    const {
        header,
        content: { status, info },
        footer,
    } = props;
    return (
        <div className='order-item'>
            {/* header部分 */}
            <h2 className='order-head'>
                <span className='header-item'>
                    <span className='bold-head'>
                        {getText('merchant')}：{header.merchantName}
                    </span>
                </span>
                <span className='header-item'>
                    <span className='bold-head'>
                        {getText('deliveryType.title')}：{DELIVERY_TYPE_NAMES[header.deliveryType]}
                    </span>
                </span>
                {info.deliveryFlag !== String(DELIVERY_TYPE_VALUES.EXPRESS) && (
                    <span className='expected-delivery-time  header-item'>
                        {getText('order.waitDeliveryTime')}：{header.waitDeliveryTime || '-'}
                    </span>
                )}
                <div className='header-item-logo'>
                    <Tag color='green'>{header.memberLabel}</Tag>
                </div>
            </h2>
            {/* content部分 */}
            <div className='order-list'>
                {/* 订单状态 */}
                <div className='order-list-countdown'>
                    <Countdown {...status} needChange={true} />
                </div>
                {/* 订单信息 */}
                <div className='order-list-info'>
                    {/* 右侧订单数量与金额 */}
                    <div className='order-list-info-item order-list-info-right'>
                        <p className='info-more'>
                            <label className='info-more-quantity align-left'>
                                {getText('order.orderListItem.dealAmount')}：{info.concludeAmount}
                            </label>
                            <label className='info-more-amount align-right'>
                                {getText('order.orderListItem.amount')}：{info.paidPayAmount}
                            </label>
                        </p>
                    </div>
                    {/* 订单编号 */}
                    <ItemInfo
                        title={getText('order.orderListItem.parentOrderNumber')}
                        value={info.parentOrderNumber}
                    />
                    {/* 订单编号 */}
                    <div className='order-list-info-item'>
                        <span className='order-list-label'>
                            {getText('order.orderListItem.orderNumber')}:
                        </span>
                        <Privilege path='order.order.view_detail'>
                            {(hasPrivilege) => {
                                if (hasPrivilege) {
                                    return (
                                        <a
                                            href={`#/offlineOrders/info/${info.outerOrderNumber}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {info.outerOrderNumber}
                                        </a>
                                    );
                                } else {
                                    return <span>{info.outerOrderNumber}</span>;
                                }
                            }}
                        </Privilege>
                    </div>
                    <ItemInfo
                        title={getText('order.orderListItem.customer')}
                        value={info.customer}
                    />
                    <ItemInfo
                        title={getText('order.createTime')}
                        value={info.thirdPartyCreateTime}
                    />
                    <ItemInfo title={getText('receiveAddress')} value={info.receiveAddress} />
                    <ItemInfo title={getText('order.payMode')} value={info.payType} />
                    {/* 异常订单信息 */}
                    {info.exceptions?.map((item) => (
                        <ItemInfo title='备注' key={item.id} value={item.msg} color='red' />
                    ))}
                    {info.stock && info.stock.allLack && (
                        <ItemInfo title='备注' value={info.stock.allLackRemark} color='red' />
                    )}
                    <div className='button-wrapper'>{props.renderOperational()}</div>
                </div>
            </div>
            {/* footer部分 */}
            <div className='list-footer' dangerouslySetInnerHTML={{ __html: footer }} />
        </div>
    );
};

const ItemInfo: React.FC<{ title?: string; value?: string | React.ReactNode; color?: string }> = (
    props
) => {
    return (
        <div className='order-list-info-item'>
            <span className='order-list-label'>{props.title ? `${props.title}:` : ''}</span>
            <span style={{ color: props.color }}>{props.value || '-'}</span>
        </div>
    );
};

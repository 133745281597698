/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { forIn, map, assign, cloneDeep, pick } from 'lodash';

import {
    Form as AntForm,
    Spin as AntSpin,
    Row as AntRow,
    Col as AntCol,
    Icon as AntIcon,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { RowProps as AntRowProps } from 'antd/lib/row';
import { ColProps as AntColProps } from 'antd/lib/col';

import { Button } from '@comall-backend-builder/components-basis';
import { ButtonProps } from '@comall-backend-builder/components-basis/lib/button';
import { TypesManager } from '@comall-backend-builder/core';
import { services } from '@comall-backend-builder/core';
import { Fields, Field } from '@comall-backend-builder/core/lib/types';
import { ReduxWrappedComponentProps } from '@comall-backend-builder/core/lib/reducers';
import {
    ExtendedParentComponentProps,
    CustomStyleComponentProps,
} from '@comall-backend-builder/components-basis/lib/component-props';
import { language } from '@comall-backend-builder/core/lib/services';

// 栅格总列数
const GRID_COLUMN_NUMBER = 24;

/**
 * 根据属性路径获取表单域
 * @param path 属性路径
 * @param fields 顶级表单域结构
 */
export function getField(path: string, fields: Fields) {
    let propertyNames = path.split('.');
    let field: Field;
    let fieldName: string[] = [];
    for (let name of propertyNames) {
        fieldName.push(name);
        field = fields[fieldName.join('.')];
        if (!field) {
            break;
        }
        fields = field.fields;
    }
    return field!;
}

/**
 * 页脚
 */
export interface BasicFormFooterProps extends CustomStyleComponentProps {
    /**
     * 行配置
     */
    row: AntRowProps;
    /**
     * 列配置
     */
    col: AntColProps;
    /**
     * 按钮组
     */
    items: ButtonProps[];
}

/**
 * 高级搜索单行模式配置
 */
interface Simple {
    fields: string[];
    direction: 'horizontal' | 'vertical' | 'inline';
    fieldCol?: object;
    fieldRow?: object;
}

export interface BasicFormProps
    extends FormComponentProps,
        CustomStyleComponentProps,
        ReduxWrappedComponentProps,
        ExtendedParentComponentProps {
    /**
     * 是否展示加载中样式
     */
    showSpin: boolean;
    /**
     * 列配置
     */
    fieldCol: Partial<AntColProps>;
    /**
     * 行配置
     */
    fieldRow: Partial<AntRowProps>;
    /**
     * 布局方向
     *
     * @default 'horizontal'
     */
    direction: 'horizontal' | 'inline' | 'vertical';
    /**
     * 定义 label 宽度
     *
     * @default 8
     */
    labelCol: number;
    /**
     * 定义 输入控件 宽度
     */
    controlCol: number;
    /**
     * 初始化事件
     */
    onInit: () => void;
    /**
     * 表单提交事件
     *
     * 该事件将在点击提交按钮时触发
     */
    onSubmitStart: () => void;
    /**
     * 表单提交事件
     *
     * 该事件将在点击提交按钮并校验完成后触发
     */
    onSubmit: (event: any, fields: Fields) => void;
    /**
     * 表单域状态改变事件
     *
     * FIXME: state type
     */
    onFieldStatusChange: (fieldName: string, state: any) => void;
    /**
     * 表单域修改事件
     */
    onFieldChange: (fieldName: string, value: any) => void;
    /**
     * 表单重置事件
     */
    onReset: (event: any, fields: Fields) => void;
    /**
     * 表单域字段数据源
     */
    fieldSource: 'properties' | 'filters';
    /**
     * 提交按钮配置
     */
    submit: ButtonProps;
    /**
     * 页脚配置
     */
    footer?: BasicFormFooterProps;
    /**
     *高级搜索单行模式配置
     */
    simple?: Simple;
    /**
     * 表单是否需要显示重置按钮，默认文本为“重置”，配置为 object 时将作为按钮的配置使用
     */
    reset?: boolean | ButtonProps;
}

interface BasicFormState {
    expandForm: boolean;
    advancedSearch: boolean;
}

/**
 * 负责对接 Ant.Design 的表单组件
 */
export class BasicForm extends Component<BasicFormProps, BasicFormState> {
    static defaultProps: Partial<BasicFormProps> = {
        direction: 'horizontal',
        labelCol: 8,
        reset: false,
    };

    constructor(props: BasicFormProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
        this.state = {
            expandForm: true,
            advancedSearch: false, //是否为高级搜索(包含折叠、展开)
        };
    }

    componentWillMount() {
        const onInit = this.props.onInit;
        if (this.props.simple) {
            this.setState({ advancedSearch: true });
        }
        if (onInit) {
            onInit();
        }
    }

    /**
     * 表单提交事件处理函数
     */
    handleSubmit(event: any) {
        if (this.props.state.requestStatus === 'pending') {
            return;
        }

        if (event) {
            event.preventDefault();
        }

        this.props.form.validateFields((err, _values) => {
            if (!err) {
                const { onSubmitStart, onSubmit } = this.props;

                if (onSubmitStart) {
                    onSubmitStart();
                }

                if (onSubmit) {
                    onSubmit(event, this.props.state.fields!);
                }
            }
        });
    }

    /**
     * 表单重置处理事件
     */
    handleReset(event: any) {
        const {
            onReset,
            form: { resetFields },
        } = this.props;

        resetFields();
        if (onReset) {
            onReset(event, this.props.state.fields!);
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const prevStatus = this.props.state.requestStatus;
        const nextStatus = nextProps.state.requestStatus;
        const {
            state: { fields, submitType },
            onSubmitSuccess,
            onSubmitError,
            submitSuccessBehavior,
            submitErrorBehavior,
            onSubmitFinish,
            onReloadOptions,
        } = nextProps;
        if (submitType && prevStatus === 'pending' && nextStatus === 'success') {
            if (onSubmitSuccess) {
                onSubmitSuccess(nextProps.state.fields, submitType);
            }
            if (submitSuccessBehavior) {
                services.behaviorHandle(submitSuccessBehavior);
            }
            if (onSubmitFinish) {
                onSubmitFinish();
            }
        }
        if (submitType && prevStatus === 'pending' && nextStatus === 'error') {
            if (onSubmitError) {
                onSubmitError(nextProps.state.fields, submitType);
            }
            if (submitErrorBehavior) {
                services.behaviorHandle(submitErrorBehavior);
            }
            if (onSubmitFinish) {
                onSubmitFinish();
            }
        }
        forIn(fields, (field, name) => {
            if (
                field.value !== (this.props.state.fields && this.props.state.fields[name].value) &&
                field.triggerOptionsReload &&
                onReloadOptions
            ) {
                for (let fieldName of field.triggerOptionsReload) {
                    onReloadOptions(fieldName, fields);
                }
            }
        });
    }

    render() {
        const { direction, className, style, showSpin, state, fieldRow, fieldSource } = this.props;
        const { advancedSearch, expandForm } = this.state;
        let formFields = this.renderFormFields(this.props.state.fields);
        let formDirection = direction;
        if (advancedSearch && this.props.simple && !expandForm) {
            formDirection = this.props.simple.direction;
        }

        let form = (
            <AntForm
                layout={formDirection}
                onSubmit={this.handleSubmit}
                className={className}
                style={style}
            >
                {direction === 'inline' || !fieldRow ? (
                    formFields
                ) : (
                    <AntRow {...fieldRow}>{formFields}</AntRow>
                )}
                {fieldSource === 'filters'
                    ? !advancedSearch && this.renderFormFooter()
                    : this.renderFormFooter()}
            </AntForm>
        );

        if (showSpin !== false) {
            let spinning = !!state && state.requestStatus === 'pending';

            return <AntSpin spinning={spinning}>{form}</AntSpin>;
        } else {
            return form;
        }
    }

    /**
     * 渲染表单域
     */
    renderFormFields(fields: any): React.ReactNode {
        let { direction, fieldCol, fieldRow } = this.props;
        const { advancedSearch } = this.state;
        /**如果高级搜索 */
        if (advancedSearch) {
            const expandForm = this.state.expandForm;

            if (expandForm) {
                return (
                    <Fragment>
                        {this.renderAdvanceExpandForm(fields)} {this.renderFilterButton(fields)}
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        {this.renderAdvanceCollapseForm(fields)} {this.renderFilterButton(fields)}
                    </Fragment>
                );
            }
        }

        if (direction === 'inline' || !fieldRow) {
            return this.renderFields(fields, { fieldCol, direction });
        } else {
            return (
                <AntRow {...fieldRow}>{this.renderFields(fields, { fieldCol, direction })}</AntRow>
            );
        }
    }

    /**
     * 渲染高级搜索收起单行
     */
    renderAdvanceCollapseForm(allFields: any) {
        //收起模式 simple
        if (!this.props.simple) {
            return;
        }
        const {
            fieldRow: defaultFieldRow,
            fieldCol: defaultFieldCol,
            direction: defaultDirection,
        } = this.props;
        const {
            simple: {
                fields,
                direction = defaultDirection,
                fieldRow = defaultFieldRow,
                fieldCol = defaultFieldCol,
            },
        } = this.props;
        const simpleFields = pick(allFields, fields);

        if (direction === 'inline' || !fieldRow) {
            return this.renderFields(simpleFields, { fieldCol, direction });
        } else {
            return (
                <AntRow {...fieldRow}>
                    {this.renderFields(simpleFields, { fieldCol, direction })}
                </AntRow>
            );
        }
    }
    /**
     * 渲染高级搜索多行展开
     */
    renderAdvanceExpandForm(fields: any) {
        const { fieldRow, fieldCol, direction } = this.props;
        return <AntRow {...fieldRow}>{this.renderFields(fields, { fieldCol, direction })}</AntRow>;
    }

    renderFields(fields: any, config: any) {
        let { direction, fieldCol } = config;
        return map(fields, (field, fieldname) => {
            if (field.type === 'object.subForm') {
                return this.renderSubForm(fieldname, field);
            } else {
                let item = this.renderFormItem(fieldname, field);
                if (direction === 'inline' || !fieldCol) {
                    return item;
                } else {
                    return (
                        <AntCol {...fieldCol} key={fieldname}>
                            {item}
                        </AntCol>
                    );
                }
            }
        });
    }

    /**
     * 渲染子表单
     */
    renderSubForm(fieldname: any, field: any) {
        const { direction, fieldRow } = this.props;
        let formFields = this.renderFormFields(field.fields);
        if (direction === 'inline') {
            // 行内表单不添加子表单标题和缩进
            return formFields;
        } else {
            const label = field.displayName || fieldname;
            return (
                <fieldset className='sub-form' key={fieldname}>
                    <legend className='sub-form-title'>{cloneDeep(label)}</legend>
                    {fieldRow ? <AntRow {...fieldRow}>{formFields}</AntRow> : formFields}
                </fieldset>
            );
        }
    }

    /**
     * 渲染表单项
     */
    renderFormItem(fieldname: any, field: any) {
        const { type, className } = field;
        const label = field.label || field.displayName || fieldname;
        const value = field.value;

        // 表单项设置
        const formItemProps = {
            key: fieldname,
            //当有reactElement形式的label时，防止开发环境下报错
            label: cloneDeep(label),
            className,
            ...this.getFormItemLayout(),
        };

        // 渲染不可编辑域
        if (field.modifiable === false) {
            return (
                <AntForm.Item {...formItemProps}>
                    {TypesManager.get(type).getDisplayComponent(value, {
                        ...field.displayConfig,
                        name: field.name,
                        options: field.options,
                        row: this.props.state.fieldValues,
                    })}
                </AntForm.Item>
            );
        }

        const { getFieldDecorator } = this.props.form;
        const validate = TypesManager.get(type).validate;
        let rules = field.rules || [];

        // 统一required校验失败message
        rules = rules.map((rule: any) => {
            if (rule.required && !rule.message) {
                return { ...rule, message: `${label}不能为空` };
            }
            return rule;
        });

        return (
            <AntForm.Item {...formItemProps}>
                {getFieldDecorator<string>(fieldname, {
                    rules: [...rules, { validator: validate }],
                    initialValue: value,
                })(this.renderFormControl(fieldname, field))}
            </AntForm.Item>
        );
    }

    /**
     * 渲染表单项中的输入组件
     */
    renderFormControl(fieldname: any, field: any) {
        const { entity, params, fieldSource } = this.props;
        const { type } = field;

        const props = {
            ...field.controlConfig,
            name: fieldname,
            entity,
            params,
            fieldSource,
            options: field.options,
            row: this.props.state.fieldValues,
        };

        let control = TypesManager.get(type).getControlComponent(props);

        return control;
    }

    /**
     * 渲染表单页脚
     */
    renderFormFooter() {
        let { direction, submit, footer, reset } = this.props;
        let { className, style, row, col, items } = footer || {};
        let buttons: JSX.Element[] = [];

        if (submit) {
            if (typeof submit !== 'object') {
                submit = {};
            }
            buttons.push(this.renderFooterButton(submit, 'footer-submit'));
        }

        if (reset) {
            if (typeof reset !== 'object') {
                reset = {};
            }
            assign(reset, {
                text: reset.text || services.language.getText('reset'),
                type: 'default',
                htmlType: 'reset',
                style: {
                    marginLeft: 10,
                },
            });
            reset.onClick = this.handleReset;
            buttons.push(this.renderFooterButton(reset, 'footer-reset'));
        }

        if (footer && items) {
            items.forEach((button, index) => {
                buttons.push(this.renderFooterButton(button, index));
            });
        }

        if (direction === 'inline') {
            return (
                <AntForm.Item className={className} style={style} {...this.getTailFormItemLayout()}>
                    {buttons}
                </AntForm.Item>
            );
        } else {
            return (
                <AntRow className={className} style={style} {...row}>
                    <AntCol {...col}>
                        <AntForm.Item {...this.getTailFormItemLayout()}>{buttons}</AntForm.Item>
                    </AntCol>
                </AntRow>
            );
        }
    }

    toggleForm(event: any) {
        if (!this.props.simple) {
            return;
        }
        const expandForm = this.state.expandForm;
        const allFields = this.props.state.fields;
        const {
            simple: { fields },
            onReset,
        } = this.props;

        if (expandForm) {
            let resetFields: any = {};
            for (let key in allFields) {
                if (fields.indexOf(key) === -1) {
                    resetFields[key] = allFields[key];
                }
            }
            // onReset(event, resetFields);
        }
        this.setState({
            expandForm: !expandForm,
        });
    }

    /**
     * 筛选操作按钮
     */
    renderFilterButton(entityFields: any) {
        let { direction, submit, reset, footer } = this.props;
        let fields = this.props.simple ? this.props.simple.fields : [];
        let className,
            style,
            row,
            col,
            items: any = [];
        if (footer) {
            className = footer.className;
            style = footer.style;
            row = footer.row;
            col = footer.col;
            items = footer.items;
        }

        const { advancedSearch, expandForm } = this.state;
        let buttons: any = [];
        const needExpandForm =
            Object.getOwnPropertyNames(entityFields).length !== (fields || []).length;

        if (submit) {
            if (typeof submit !== 'object') {
                submit = {};
            }
            buttons.push(this.renderFooterButton(submit, 'footer-submit'));
        }

        if (reset) {
            if (typeof reset !== 'object') {
                reset = {};
            }
            assign(reset, {
                text: reset.text || services.language.getText('reset'),
                type: 'default',
                htmlType: 'reset',
                style: {
                    marginLeft: 10,
                },
            });
            reset.onClick = this.handleReset;
            buttons.push(this.renderFooterButton(reset, 'footer-reset'));
        }

        if (footer && items) {
            items.forEach((button: any, index: any) => {
                buttons.push(this.renderFooterButton(button, index));
            });
        }
        if (advancedSearch && needExpandForm) {
            if (this.state.expandForm) {
                buttons.push(
                    <a style={{ marginLeft: 10 }} key='collapse' onClick={this.toggleForm}>
                        {language.getText('collapse')} <AntIcon type='up' />
                    </a>
                );
            } else {
                buttons.push(
                    <a style={{ marginLeft: 10 }} key='expand' onClick={this.toggleForm}>
                        {language.getText('expand')} <AntIcon type='down' />
                    </a>
                );
            }
        }
        if ((advancedSearch && needExpandForm && !expandForm) || direction === 'inline') {
            let inlineStyle = assign({}, style, { display: 'inline-block' });
            if (fields && fields.length < 3) {
                return (
                    <AntForm.Item className={className} style={inlineStyle}>
                        {buttons}
                    </AntForm.Item>
                );
            } else {
                return <div style={{ textAlign: 'right', paddingRight: 24 }}>{buttons}</div>;
            }
        } else {
            return (
                <AntRow className={className} type='flex' justify='end' style={style} {...row}>
                    <AntCol {...col} style={{ minWidth: 'auto', textAlign: 'right' }}>
                        <AntForm.Item>{buttons}</AntForm.Item>
                    </AntCol>
                </AntRow>
            );
        }
    }

    renderFooterButton(props: any, key: any) {
        let btnProps = assign(
            {},
            {
                text: language.getText('submit'),
                type: 'primary',
                htmlType: 'submit',
            },
            props
        );

        return <Button {...btnProps} key={key} />;
    }

    /**
     * 获取表单项布局设置
     */
    getFormItemLayout() {
        const { direction, labelCol, controlCol } = this.props;

        return direction === 'horizontal'
            ? {
                  labelCol: {
                      span: labelCol,
                  },
                  wrapperCol: {
                      span: controlCol || GRID_COLUMN_NUMBER - labelCol,
                  },
              }
            : undefined;
    }

    /**
     * 获取尾部表单项布局设置
     */
    getTailFormItemLayout() {
        const { direction, labelCol, controlCol } = this.props;

        return direction === 'horizontal'
            ? {
                  wrapperCol: {
                      offset: labelCol,
                      span: controlCol || GRID_COLUMN_NUMBER - labelCol,
                  },
              }
            : undefined;
    }
}

export const CustomForm = AntForm.create<BasicFormProps>({
    mapPropsToFields(props) {
        return (function generateFields(source) {
            let fields: any = {};
            forIn(source, (field, name) => {
                if (field.type === 'object.subForm') {
                    Object.assign(fields, generateFields(field.fields));
                } else {
                    fields[name] = AntForm.createFormField({
                        ...field.status,
                        value: field.value,
                    });
                }
            });
            return fields;
        })(props.state.fields!);
    },
    onFieldsChange(props, changedFields) {
        const {
            state: { fields },
            onFieldStatusChange,
        } = props;

        if (onFieldStatusChange) {
            (function handleFieldsChange(fields, changedFields, prefix) {
                forIn(changedFields, (changedField, name) => {
                    let fieldName = prefix + name;
                    let field = fields[fieldName];
                    if (field.type === 'object.subForm') {
                        handleFieldsChange(field.fields, changedField, fieldName + '.');
                    } else {
                        let { value, ...status } = changedField;
                        onFieldStatusChange(fieldName, status);
                    }
                });
            })(fields!, changedFields, '');
        }
    },
    onValuesChange(props, changedValues) {
        const {
            state: { fields },
            onFieldChange,
        } = props;
        if (onFieldChange) {
            (function handleValuesChange(fields, changedValues, prefix) {
                forIn(changedValues, (changedValue, name) => {
                    let fieldName = prefix + name;
                    let field = fields[fieldName];
                    if (field.type === 'object.subForm') {
                        handleValuesChange(field.fields, changedValue, fieldName + '.');
                    } else {
                        onFieldChange(fieldName, changedValue);
                    }
                });
            })(fields!, changedValues, '');
        }
    },
})(BasicForm);

/*
 * @Author: zhulu
 * @Date: 2021-07-13 13:57:42
 * @Descripttion: 优惠券规则管理
 */
import React from 'react';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { flexibleFilterForm, tableMixin } from '@/configs/mixin';
import { CouponImageUpload } from '@/containers/coupon-image-upload';
import { recordImportLogs } from '@/services';
import {
    couponRuleProps,
    filterCouponRuleProps,
    filterCouponRuleFields,
    couponRuleColumns,
} from './properties/couponRule';
export default {
    entities: {
        couponRules: {
            apiPath: '/loader/dc-ecoupon/admin/ecoupons_definition_rules',
            properties: couponRuleProps,
            filters: filterCouponRuleProps,
        },
    },

    components: {
        CouponRuleContainer: {
            component: 'Viewport',
        },
        CouponRuleManagement: {
            component: 'Card',
            entity: 'couponRules',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'CouponRuleFilter',
                        privilege: {
                            path: 'promotions_manager.coupon_rule.list',
                            level: 'full',
                        },
                    },
                    { component: 'CouponRuleActions' },
                    {
                        component: 'CouponRuleTable',
                        privilege: {
                            path: 'promotions_manager.coupon_rule.list',
                            level: 'full',
                        },
                    },
                ],
            },
        },

        CouponRuleFilter: {
            ...flexibleFilterForm,
            fieldCol: { xs: 24, sm: 20, md: 16, lg: 12, xl: 8, xxl: 6 },
            fields: filterCouponRuleFields,
        },
        CouponRuleActions: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<couponRule.exportFile>>',
                    icon: 'download',
                    style: {
                        marginRight: 15,
                    },
                    source: {
                        apiPath: '/dc-ecoupon/admin/ecoupons_definition_rules/export/take',
                    },
                    showErrorMessage: true,
                    isAsync: true,
                    formatter: (params: any) => {
                        if (params.startDate) {
                            params.startDateStart = params.startDate.start + ' 00:00:00';
                            params.startDateEnd = params.startDate.end + ' 00:00:00';
                            delete params.startDate;
                        }
                        if (params.endDate) {
                            params.endDateStart = params.endDate.start + ' 00:00:00';
                            params.endDateEnd = params.endDate.end + ' 00:00:00';
                            delete params.endDate;
                        }
                        return params;
                    },
                    privilege: {
                        path: 'promotions_manager.coupon_rule.export',
                        level: 'full',
                    },
                },
                {
                    component: 'Upload',
                    apiPath: '/dc-file/excels?location_id=1',
                    showSuccessMessage: false,
                    showErrorMessage: true,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<couponRule.importFile>>',
                        style: {
                            marginRight: 15,
                        },
                    },
                    onUploadComplete: (response: any) => {
                        if (response) {
                            //TODO
                            recordImportLogs({
                                name: services.language.getText('couponRule.importInfo'),
                                apiPath: '/dc-ecoupon/admin/ecoupons_definition_rules/import',
                                params: { resourceId: response.id },
                            });
                        }
                    },
                    privilege: {
                        path: 'promotions_manager.coupon_rule.import',
                        level: 'full',
                    },
                },

                {
                    component: 'OSSExportButton',
                    text: '<<downloadTemplate>>',
                    type: 'link',
                    style: {
                        alignSelf: 'center',
                        flexGrow: 0,
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/${encodeURIComponent(
                        'Coupon criteria Import template.xlsx'
                    )}`,
                    privilege: {
                        path: 'promotions_manager.coupon_rule.import',
                        level: 'full',
                    },
                },
            ],
        },
        CouponRuleTable: {
            ...tableMixin,
            scroll: {
                x: 'max-content',
            },
            columns: couponRuleColumns,
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        config: {
                            component: 'CouponImageUpload',
                            actionType: 'component',
                            render: (row: any, text: string, className: string, props: any) => {
                                //@ts-ignore
                                return <CouponImageUpload {...props} row={row} />;
                            },
                        },
                        privilege: {
                            path: 'promotions_manager.coupon_rule.upload_image',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
} as Config;

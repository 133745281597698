import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';
import {
    TYPE_NAME_OPTIONS,
    EVENT_STATUS_OPTIONS,
    EVENT_SHOW_STATUS_OPTIONS,
    INVITATION_WAYOPTIONS,
    REWARD_WAYSOPTIONS,
} from '@/constants';
import { buildForm } from './event-marketing-form';

export const config: Config = {
    entities: {
        eventMarketing: {
            apiPath: '/loader/dc-master-data/admin/activity',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '<<salesPromotion.eventMarketing.base.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 100,
                    },
                },
                effectTime: {
                    type: 'object.dateTimeRange',
                    displayName: '<<couponManage.effectTime.title>>',
                },
                effectiveStartTime: {
                    type: 'string',
                    displayName: '<<salesPromotion.eventMarketing.base.effectiveStartTime>>',
                },
                effectiveEndTime: {
                    type: 'string',
                    displayName: '<<salesPromotion.eventMarketing.base.effectiveEndTime>>',
                },
                typeName: {
                    type: 'string',
                    displayName: '<<salesPromotion.eventMarketing.base.typeName>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<salesPromotion.eventMarketing.base.type>>',
                    options: TYPE_NAME_OPTIONS,
                },
                stores: {
                    type: 'array.onlineStoreGroup',
                    displayName: '<<couponManage.common.stores>>',
                },
                storeIds: {
                    type: 'array.onlineStoreGroup',
                    displayName: '<<couponManage.storeIds.title>>',
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/findAll',
                    },
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                        titles: [
                            '<<couponManage.storeIds.left>>',
                            '<<couponManage.storeIds.right>>',
                        ],
                        nameProp: 'onlineStoreName',
                    },
                },
                status: {
                    type: 'string.status',
                    displayName: '<<salesPromotion.eventMarketing.base.status>>',
                },
                orderAmount: {
                    type: 'number',
                    displayName: '<<salesPromotion.eventMarketing.conditionsReward.orderAmount>>',
                },
                invitationWay: {
                    type: 'string.options.radio',
                    options: INVITATION_WAYOPTIONS,
                    displayName: '<<salesPromotion.eventMarketing.conditionsReward.invitationWay>>',
                },
                rewardWays: {
                    type: 'string.options.radio',
                    options: REWARD_WAYSOPTIONS,
                    displayName: '<<salesPromotion.eventMarketing.conditionsReward.rewardWays>>',
                },
                prizeSetting: {
                    type: 'object.eventMarketingItems',
                    displayName: '<<salesPromotion.eventMarketing.matchArrangement.prizeSetting>>',
                },
                memberMobiles: {
                    type: 'string.text.paragraph',
                    displayName: '<<salesPromotion.eventMarketing.conditionsReward.phone>>',
                },
                timedReleaseTime: {
                    type: 'string.pickUpTimes',
                    displayName: '<<salesPromotion.eventMarketing.conditionsReward.triggerTime>>',
                    format: 'HH',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<salesPromotion.eventMarketing.base.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                types: {
                    type: 'string.options.select',
                    displayName: '<<salesPromotion.eventMarketing.base.type>>',
                    options: TYPE_NAME_OPTIONS,
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<salesPromotion.eventMarketing.activityState>>',
                    // @ts-ignore
                    options: EVENT_STATUS_OPTIONS,
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                onlineStoreId: {
                    type: 'string.treeNode.cascader',
                    displayName: '<<overrideCode.onlineStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        EventMarketingView: {
            component: 'Viewport',
        },
        EventMarketingPage: {
            entity: 'eventMarketing',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'name',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                        {
                            property: 'types',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                        {
                            property: 'status',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                        {
                            property: 'onlineStoreId',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                    ],
                    privilege: {
                        path: 'activity_management.activity_event.activity_event_list',
                        level: 'full',
                    },
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<salesPromotion.eventMarketing.newActivities>>',
                            route: '/sales-promotion/event-marketing/add',
                            privilege: {
                                path: 'activity_management.activity_event.activity_event_create',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'EventMarketingTable',
                    privilege: {
                        path: 'activity_management.activity_event.activity_event_list',
                        level: 'full',
                    },
                },
            ]),
        },
        EventMarketingTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'effectiveStartTime',
                },
                {
                    property: 'effectiveEndTime',
                },
                {
                    property: 'typeName',
                },
                {
                    property: 'stores',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: EVENT_SHOW_STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                true: '/dc-master-data/admin/activity/disable/{{row.id}}',
                                false: '/dc-master-data/admin/activity/enable/{{row.id}}',
                            },
                            statusText: {
                                true: '<<disable>>',
                                false: '<<enable>>',
                            },
                            linkProperty: 'enable',
                            errorStatus: true,
                        },
                        privilege: {
                            path: 'activity_management.activity_event.activity_event_enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/sales-promotion/event-marketing/edit/{{row.id}}',
                        privilege: {
                            path: 'activity_management.activity_event.activity_event_get',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        EventMarketingAddPage: {
            component: 'Card',
            entity: 'eventMarketing',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        EventMarketingEditPage: {
            component: 'Card',
            entity: 'eventMarketing',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
    },
};

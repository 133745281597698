import { services } from '@comall-backend-builder/core';
import { getStoreInfo } from '@/services/utils';

export default [
    {
        key: '/dc-user/admin/members/voucherManagement',
        loader: {
            get: async function(data: any, config: any) {
                if (getStoreInfo()?.id) {
                    const response: any = await services.api.get(
                        { ...data, page: 1, perPage: 20, onlineStoreId: getStoreInfo().id },
                        { ...config, apiPath: '/dc-user/admin/members' }
                    );
                    let result = response.result
                        .map((item: any) => {
                            if (item) {
                                return {
                                    ...item,
                                    name: item.phone,
                                    memberPhone: item.phone,
                                    householdSize: item.familySize,
                                };
                            } else {
                                return {};
                            }
                        })
                        .filter((i: any) => Object.keys(i).length > 0);
                    return result;
                }
            },
        },
    },
    {
        key: '/dc-master-data/admin/voucherGrant',
        loader: {
            post: async function(data: any, config: any) {
                return await services.api.post(
                    { ...data },
                    { ...config, apiPath: '/dc-master-data/admin/voucherGrant/add' }
                );
            },
            get: async function(params: any, config: any) {
                if (params.id) {
                    const res: any = await services.api.get(
                        {},
                        {
                            ...config,
                            apiPath: `/dc-master-data/admin/voucherGrant/${params.id}`,
                        }
                    );

                    const newItems = res.items
                        ? res.items.map((item: any) => ({
                              ...item,
                              id: item.memberId,
                              name: item.memberPhone,
                          }))
                        : [];
                    return {
                        ...res,
                        mainDistributionStoreId: res.mainStores.id,
                        items: newItems,
                    };
                } else if (params.onlineStoreId) {
                    const res: any = await services.api.get(
                        { ...params },
                        {
                            ...config,
                            apiPath: '/dc-master-data/admin/voucherGrant',
                        }
                    );

                    return res;
                } else {
                    return [];
                }
            },
        },
    },
    {
        key: '/dc-master-data/admin/voucherGrant/:id',
        loader: {
            get: async function(params: any, config: any) {
                const res: any = services.api.get(
                    {},
                    { ...config, apiPath: '/dc-master-data/admin/voucherGrant/:id' }
                );

                const newItems = (res.items.map = (item: any) => ({
                    ...item,
                    id: item.memberId,
                    name: item.memberPhone,
                }));

                return {
                    ...res,
                    mainStores: res.mainStores.name,
                    items: newItems,
                };
            },
        },
    },
    {
        key: '/dc-master-data/admin/voucherGrant/member/datas',
        loader: {
            get: async function(params: any, config: any) {
                const newParams = { ...params, voucherGrantId: params.id };
                delete newParams.id;
                const res: any = services.api.get(
                    { ...newParams },
                    { ...config, apiPath: '/dc-master-data/admin/voucherGrant/member/datas' }
                );
                return res;
            },
        },
    },
];

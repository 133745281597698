import { ACTIVE_STATUS } from '../constants';
export const couponRuleProps = {
    createTime: {
        type: 'string',
        displayName: '<<couponRule.createTime>>',
    },
    definitionId: {
        type: 'string',
        displayName: '<<couponRule.definitionId>>',
    },
    versionStatus: {
        type: 'string.color',
        displayName: '<<couponRule.versionStatus>>',
    },
    id: {
        type: 'string',
    },
    code: {
        type: 'string',
        displayName: '<<couponRule.code>>',
    },
    promotionId: {
        type: 'string',
        displayName: '<<couponRule.promotionId>>',
    },
    status: {
        type: 'string.color',
        displayName: '<<couponRule.status>>',
    },
    customerType: {
        type: 'string',
        displayName: '<<couponRule.customerType>>',
    },
    couponHeader: {
        type: 'string',
        displayName: '<<couponRule.couponHeader>>',
    },
    couponDetail: {
        type: 'string.text.long',
        displayName: '<<couponRule.couponDetail>>',
    },
    couponCondition: {
        type: 'string',
        displayName: '<<couponRule.couponCondition>>',
    },
    ecouponType: {
        type: 'string',
        displayName: '<<couponRule.ecouponType>>',
    },
    value: {
        type: 'string',
        displayName: '<<couponRule.value>>',
    },
    spendThreshold: {
        type: 'string',
        displayName: '<<couponRule.spendThreshold>>',
    },
    currency: {
        type: 'string',
        displayName: '<<couponRule.currency>>',
    },
    divisions: {
        displayName: '<<couponRule.division>>',
        type: 'string',
    },
    stores: {
        type: 'string',
        displayName: '<<couponRule.stores>>',
    },
    loyalT: {
        type: 'string',
        displayName: '<<couponRule.loyalT>>',
    },
    partner: {
        type: 'string',
        displayName: '<<couponRule.channel>>',
    },
    eligibleCsku: {
        type: 'string.text.long',
        displayName: '<<couponRule.eligibleCSKU>>',
    },
    exclusionCsku: {
        type: 'string.text.long',
        displayName: '<<couponRule.exclusionCSKU>>',
    },
    partipateDepartment: {
        type: 'string',
        displayName: '<<couponRule.partipateDepartment>>',
    },
    partipateBrand: {
        type: 'string',
        displayName: '<<couponRule.partipateBrand>>',
    },
    startDate: {
        type: 'string',
        displayName: '<<couponRule.startDate>>',
    },
    endDate: {
        type: 'string',
        displayName: '<<couponRule.endDate>>',
    },
    couponImage: {
        type: 'string.image.text',
        displayName: '<<couponRule.couponImage>>',
    },
    marketingMessage: {
        type: 'string.text.long',
        displayName: '<<couponRule.marketingMessage>>',
    },
    termAndConditionDetail: {
        type: 'string.text.long',
        displayName: '<<couponRule.termAndConditionDetail>>',
    },
    maxIssuance: {
        type: 'string',
        displayName: '<<couponRule.maxIssuance>>',
    },
    exclusionBrand: {
        type: 'string',
        displayName: '<<couponRule.exclusionBrand>>',
    },
    perIssuance: {
        type: 'string',
        displayName: '<<couponRule.perIssuance>>',
    },
    mustBuy: {
        type: 'string.text.long',
        displayName: '<<couponRule.mustBuy>>',
    },
    tag: {
        type: 'string',
        displayName: '<<couponRule.tag>>',
    },
};

export const filterCouponRuleProps = {
    promotionId: {
        type: 'string',
        displayName: '<<couponRule.promotionId>>',
    },
    versionStatus: {
        type: 'string.options.select',
        displayName: '<<couponRule.versionStatus>>',
        options: [
            { id: '1', name: '<<couponRule.enable>>' },
            { id: '0', name: '<<couponRule.disable>>' },
        ],
    },
    ecouponType: {
        type: 'string.options.select',
        displayName: '<<couponRule.ecouponType>>',
        options: [
            { id: '$', name: '$' },
            { id: '%', name: '%' },
        ],
    },
    divisions: {
        type: 'string.treeNode.cascader',
        displayName: '<<couponRule.division>>',
        options: [],
        source: {
            apiPath: '/dc-master-data/admin/organizations/findAllByLoginUser',
        },
    },
    stores: {
        displayName: '<<couponRule.stores>>',
        type: 'string',
    },
    couponHeader: {
        type: 'string',
        displayName: '<<couponRule.couponHeader>>',
    },
    status: {
        type: 'string.options.select',
        displayName: '<<couponRule.status>>',
        options: [
            { id: 'Active', name: 'Active' },
            { id: 'Invalid', name: 'Invalid' },
        ],
    },
    partipateDepartment: {
        displayName: '<<couponRule.partipateDepartment>>',
        type: 'string',
    },
    partipateBrand: {
        displayName: '<<couponRule.partipateBrand>>',
        type: 'array.all.params',
        options: [],
        source: {
            apiPath: '/dc-goods/admin/brands/list?page_size=20',
        },
    },
    startDate: {
        type: 'object.dateRange',
        displayName: '<<couponRule.startDate>>',
    },
    endDate: {
        type: 'object.dateRange',
        displayName: '<<couponRule.endDate>>',
    },
};

export const filterCouponRuleFields = [
    {
        property: 'promotionId',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<couponRule.promotionIdPlaceholder>>',
        },
    },
    {
        property: 'versionStatus',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'status',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'divisions',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'stores',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<couponRule.storesPlaceholder>>',
        },
    },
    {
        property: 'partipateBrand',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'couponHeader',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<couponRule.couponHeaderPlaceholder>>',
        },
    },
    {
        property: 'partipateDepartment',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<couponRule.partipateDepartmentPlaceholder>>',
        },
    },
    {
        property: 'ecouponType',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'startDate',
        controlConfig: {
            format: 'DD-MM-YYYY',
        },
    },
    {
        property: 'endDate',
        controlConfig: {
            format: 'DD-MM-YYYY',
        },
    },
];

export const couponRuleColumns = [
    { property: 'createTime', fixed: 'left' },
    {
        property: 'versionStatus',
        displayConfig: {
            statusConfig: ACTIVE_STATUS,
            id: 'versionId',
            prop: 'value',
        },
        fixed: 'left',
    },
    { property: 'promotionId', fixed: 'left' },
    {
        property: 'status',
        displayConfig: {
            statusConfig: ACTIVE_STATUS,
            id: 'statusId',
        },
    },
    { property: 'couponImage', className: 'coupon-rule-img' },
    { property: 'divisions' },
    { property: 'stores' },
    { property: 'partner' },
    { property: 'code' },
    { property: 'loyalT' },
    { property: 'customerType' },
    { property: 'couponHeader' },
    { property: 'couponDetail', width: '300px' },
    { property: 'tag' },
    { property: 'marketingMessage', width: '300px' },
    { property: 'couponCondition' },
    { property: 'ecouponType' },
    { property: 'currency' },
    { property: 'value' },
    { property: 'startDate' },
    { property: 'endDate' },
    { property: 'spendThreshold' },
    { property: 'maxIssuance' },
    { property: 'perIssuance' },
    { property: 'partipateDepartment', width: '300px' },
    { property: 'partipateBrand', width: '200px' },
    { property: 'exclusionBrand', width: '200px' },
    { property: 'eligibleCsku', width: '300px' },
    { property: 'exclusionCsku', width: '300px' },
    { property: 'termAndConditionDetail', width: '300px' },
    { property: 'mustBuy', width: '300px' },
    { property: 'definitionId' },
];

import { services } from '@comall-backend-builder/core';
import { PrivilegeLevel } from '@comall-backend-builder/core/lib/services';

type Level = keyof typeof PrivilegeLevel;

interface IProps {
    path: string;
    level: Level;
    children: JSX.Element | ((flag: boolean) => JSX.Element);
}

/**
 * 权限检测方法，在函数中使用
 */
export function checkPrivilege(path: string, level: Level = 'full') {
    if (!path) return true;
    return services.privilege.check(path, level);
}

/**
 * 权限展示组件，在组件中使用
 * @param {IProps} props
 * @returns
 */
export function Privilege(props: IProps) {
    const { path, level, children } = props;

    const hasPrivilege = checkPrivilege(path, level);
    if (typeof children === 'function') {
        return children(hasPrivilege);
    } else if (hasPrivilege) {
        return children;
    } else {
        return null;
    }
}

Privilege.defaultProps = {
    level: 'full',
};

import queryString from 'querystring';
import { get } from 'lodash';
import { OptionType } from '@/interfaces';
import { services } from '@comall-backend-builder/core';
import { STORE_TREE_STORAGE_KEY } from '@/constants';
/**
 * 移除链接中的指定 query
 */
export const removeQueryFromHref = (href: string, ...fields: any) => {
    const [_host, _query] = href.split('?');
    const queryObject = queryString.parse(_query);
    if (queryObject) {
        fields.forEach((field: any) => {
            delete queryObject[field];
        });
    }
    //@ts-ignore
    const qs = queryString.stringify(queryObject, { encode: false });
    return qs ? `${_host}?${qs}` : _host;
};
/**
 * 移除链接上的指定 query
 * @param fields
 */
export const replaceLocationByQuery = (...fields: any) => {
    const newUrl = removeQueryFromHref(window.location.href, ...fields);
    window.history.replaceState('', '', newUrl);
};

/**
 * 获取本地存储的线上门店
 * @param name
 * @returns
 */
export function getStoreInfo(name = STORE_TREE_STORAGE_KEY) {
    let storeInfo: OptionType[] | undefined = services.localStorage.get(name);
    return get(storeInfo, '[0]') || {};
}

const inputNumberRE = /([0-9]|Backspace)/;
/**
 * 禁用数字键和删除键以外的键盘事件
 */
export function disabledInputNotNumber(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!inputNumberRE.test(e.key)) {
        e.preventDefault();
        e.stopPropagation();
    }
}

/**
 * 检测是否为售罄商品
 */
export function checkSoldOutProduct(product?: { soldOutStatus?: boolean; isSellOut?: boolean }) {
    return Boolean(product?.soldOutStatus || product?.isSellOut);
}

/**
 * 检测是否为下架商品
 */
export function checkUnShelveProduct(product?: { onShelves?: number; isOffShelves?: boolean }) {
    return Boolean(product?.onShelves === 2 || product?.isOffShelves);
}

/**
 * 检测是否为预售商品
 */
export function checkPreSaleProduct(product?: { sellFlag?: boolean; isPresale?: boolean }) {
    return Boolean(product?.sellFlag || product?.isPresale);
}

/**
 * 检测是否为无效商品(售罄或者下架)
 */
export function checkInvalidProduct(product?: {
    soldOutStatus?: boolean;
    isSellOut?: boolean;
    onShelves?: number;
    isOffShelves?: boolean;
    sellFlag?: boolean;
    isPresale?: boolean;
    vaild?: boolean;
}) {
    return (
        checkUnShelveProduct(product) ||
        checkSoldOutProduct(product) ||
        checkPreSaleProduct(product)
    );
}

/*
 * @Author: zhulu
 * @Date: 2022-04-25 15:27:49
 * @Description: form 的 SelectAutoComplete
 */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Loader } from '@comall-backend-builder/core';
import { default as Select, SelectProps } from 'antd/lib/select';
import { isArray, isObject, find } from 'lodash';

const { Option } = Select;

interface Value {
    /**
     * 候选项id
     */
    id: string;
    /**
     * 候选项显示名称
     */
    name: string;
}
interface Props
    extends Pick<
        SelectProps,
        'mode' | 'placeholder' | 'disabled' | 'allowClear' | 'onSelect' | 'onDeselect'
    > {
    max?: number;
    value: Value | Value[];
    name: string;
    loadFirstPage?: boolean;
    /**
     * 搜索的key
     */
    selectParamKey?: string;
    /**
     * placeholder
     */
    placeholder?: string;
    /**
     * 请求接口地址
     */
    apiPath: string;
    /**
     * 搜索的附加参数
     */
    params: { [key: string]: any };
    /**
     * 是否展示箭头
     */
    showArrow: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Value | Value[]) => void;
    formatResponse?: (response: any) => Value[];
}
const SelectAutoComplete = React.forwardRef((props: any, _ref: any) => {
    const [data, setData] = useState([]);

    const {
        value,
        name,
        onChange,
        apiPath,
        params,
        selectParamKey,
        formatResponse,
        placeholder = '',
        loadFirstPage,
        max,
        allowClear,
        showArrow = false,
        ...rest
    } = props;

    const handleSearch = async (search: string, load?: boolean) => {
        if (search || load) {
            let searchKey = selectParamKey || name;
            let result = await Loader.load('get', {
                apiPath,
                params: { ...params, [searchKey]: search },
            });
            if (formatResponse) result = formatResponse(result);

            setData(result);
        } else {
            setData([]);
        }
    };

    const handleChange = (val: { key: string; label: string }) => {
        let newValue: Value | Value[] = [];
        if (isArray(val)) {
            if (max && val.length > max) return;
            newValue = val.map(({ key, label }: any) => {
                const otherParams = find(data, (e: any) => e.id === key);
                return { ...otherParams, id: key, name: label as string };
            });
        } else if (val !== null && val !== undefined) {
            const otherParams = find(data, (e: any) => e.id === val.key);
            newValue = { ...otherParams, id: val.key, name: val.label as string };
        }
        onChange(newValue);
    };
    const options = useMemo(() => {
        return data.map((d: { id: string; name: string }) => (
            <Option key={d.id} data-params={JSON.stringify(d)}>
                {d.name}
            </Option>
        ));
    }, [data]);

    const _value = useMemo(() => {
        if (isArray(value)) {
            return value.map(({ id, name, ...rest }) => ({
                ...rest,
                key: id as string,
                label: name as string,
            }));
        } else if (isObject(value)) {
            //@ts-ignore
            return { ...value, key: value.id as string, label: value.name as string };
        } else {
            return undefined;
        }
    }, [value]);

    useEffect(() => {
        if (loadFirstPage && apiPath) {
            handleSearch('', true);
        }
        // eslint-disable-next-line
    }, [apiPath]);

    const firstLoaded = useRef(false);

    return (
        <Select
            ref={_ref}
            {...rest}
            allowClear={allowClear}
            showSearch
            labelInValue
            value={_value}
            placeholder={placeholder}
            defaultActiveFirstOption={false}
            showArrow={showArrow}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onFocus={() => {
                if (!firstLoaded.current && !loadFirstPage) {
                    handleSearch('');
                    firstLoaded.current = true;
                }
                props.onfocus && props.onfocus();
            }}
        >
            {options}
        </Select>
    );
});
SelectAutoComplete.defaultProps = {
    loadFirstPage: true,
};
export default SelectAutoComplete;

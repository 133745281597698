import { services } from '@comall-backend-builder/core/lib';
import { generateEmptySearchResult } from '@/services';
import { message } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';

async function wrapOrderListPicking(data: any, config: any) {
    if (!data.onlineStoreId) {
        message.error(services.language.getText('picking.retailStoreSelectTip'));
        return new Promise((resolove) => resolove(generateEmptySearchResult()));
    }
    const response: any = await services.api.get(data, config);
    const result = get(response, 'result');
    const newResult = map(result, (item) => {
        item.member.code = item.member && item.member.code ? `(${item.member.code})` : '';
        item.delivery.code = item.delivery && item.delivery.code ? `(${item.delivery.code})` : '';
        item.disabled = item.orderStatus === 'PACKED' ? false : !item.showPacked;
        return item;
    });
    const newResponse = {
        ...response,
        result: newResult,
    };
    return new Promise((resolove) => resolove(newResponse));
}

async function wrapPickingLists(data: any, config: any) {
    if (!data.onlineStoreId) {
        message.error(services.language.getText('picking.retailStoreSelectTip'));
        return new Promise((resolove) => resolove(generateEmptySearchResult()));
    }
    const response: any = await services.api.get(data, {
        ...config,
    });

    const result = get(response, 'result');

    const newResult = map(result, (item) => {
        item.disabled = false;
        return item;
    });
    return new Promise((resolove) =>
        resolove({
            ...response,
            result: newResult,
        })
    );
}

export default [
    {
        key: '/base-order/admin/order/order_list_picking',
        loader: {
            get: async function(data: any, config: any) {
                const res = await wrapOrderListPicking(data, config);
                return res;
            },
        },
    },
    {
        key: '/dc-order-picking/admin/picking_lists',
        loader: {
            get: async function(data: any, config: any) {
                const res = await wrapPickingLists(data, config);
                return res;
            },
        },
    },
    {
        key: '/base-order-philips/admin/order/order_list_picking',
        loader: {
            get: async function(data: any, config: any) {
                const res = await wrapOrderListPicking(data, config);
                return res;
            },
        },
    },
    {
        key: '/dc-order-picking-philips/admin/picking_lists',
        loader: {
            get: async function(data: any, config: any) {
                const res = await wrapPickingLists(data, config);
                return res;
            },
        },
    },
];

import { useCountDown } from 'ahooks';
import { useState } from 'react';

export const useTimepiece = () => {
    const [targetDate, setTargetDate] = useState<number>();

    const [countdown] = useCountDown({
        targetDate,
    });

    const reset = () => {
        setTargetDate(Date.now() + 120 * 1000);
    };
    return {
        countdown,
        reset,
    };
};

import React from 'react';
import { message, Icon, Progress } from 'antd';
import RcUpload from 'rc-upload';
import classNames from 'classnames';
import { services } from '@comall-backend-builder/core';
import { limitImg } from './utils';

import './index.less';

const prefixItemCls = 'cm-upload-image-video-item';
const prefixCommonCls = 'cm-upload-image-video-common';

export const UploadItem: React.FC<any> = (props) => {
    const {
        status,
        url,
        name,
        video,
        percent,
        poster,
        onDelete,
        onLoadPoster,
        onPoster,
        onPreview,
        showDownLoad,
    } = props;
    return (
        <div
            className={classNames(prefixItemCls, prefixCommonCls, {
                [`${prefixItemCls}-${status}`]: true,
            })}
        >
            <div className={classNames(`${prefixItemCls}-content`)}>
                {url && !video && <img src={url} alt={name} />}
                {url && video && <video src={url} poster={poster || ''} />}
                {url && video && <Icon type='play-circle' />}
            </div>
            {status === 'error' && (
                <div className={classNames(`${prefixItemCls}-error-info`)}>
                    <Icon type={video ? 'video-camera' : 'picture'} />
                    <div className={classNames(`${prefixItemCls}-error-name`)}>{name}</div>
                </div>
            )}
            {status === 'uploading' && (
                <div className={classNames(`${prefixItemCls}-progress`)} key='progress'>
                    <span className={classNames(`${prefixItemCls}-progress-tip`)}>
                        {services.language.getText('uploadImageVideo.text.uploading')}
                    </span>
                    <Progress type='line' percent={percent} showInfo={false} />
                </div>
            )}
            <div className={classNames(`${prefixCommonCls}-info`)}>
                <div className={classNames(`${prefixCommonCls}-actions`)}>
                    {video && (
                        <RcUpload
                            accept='image/png,image/jpg'
                            beforeUpload={async (file: any, fileListArgs: any[]) => {
                                return await limitImg(file, fileListArgs, [], {
                                    maxCount: 1,
                                    maxSize: 2 * 1024,
                                });
                            }}
                            onSuccess={(response: any) => {
                                try {
                                    if (typeof response === 'string') {
                                        response = JSON.parse(response);
                                    }
                                } catch (e) {
                                    /* do nothing */
                                }
                                onPoster && onPoster(response);
                            }}
                            onError={() => {
                                message.error(
                                    services.language.getText('uploadImageVideo.error.video.poster')
                                );
                            }}
                            customRequest={onLoadPoster}
                            multiple={false}
                            disabled={status !== 'done'}
                        >
                            <span className={classNames(`${prefixCommonCls}-action-icon`)}>
                                <Icon type='edit' />
                            </span>
                        </RcUpload>
                    )}
                    <span
                        className={classNames(`${prefixCommonCls}-action-icon`)}
                        onClick={() => {
                            if (status !== 'done') return;
                            onPreview && onPreview();
                        }}
                    >
                        <Icon type='eye' />
                    </span>
                    {showDownLoad && (
                        <span
                            className={classNames(`${prefixCommonCls}-action-icon`)}
                            onClick={async () => {
                                if (status !== 'done') return;
                                await services.api.download(
                                    {},
                                    { apiRoot: props.url, apiPath: '' }
                                );
                            }}
                        >
                            <Icon type='download' />
                        </span>
                    )}
                    <span
                        className={classNames(`${prefixCommonCls}-action-icon`)}
                        onClick={() => {
                            onDelete && onDelete();
                        }}
                    >
                        <Icon type='delete' />
                    </span>
                </div>
            </div>
        </div>
    );
};

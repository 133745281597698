import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { AsyncExportButton } from '@/components/async-export-button';
interface exportMarkGroupButtonContentProps {
    filters: any;
    style: any;
    params: any;
}
export class exportMarkGroupButtonContent extends Component<
    exportMarkGroupButtonContentProps,
    any
> {
    render() {
        const { filters, style, params } = this.props;

        let exportButton = {
            style,
            text: services.language.getText('markGroup.ExportMarkGroup'),
            downloadUrl: '',
            tooltip: '',
            showErrorMessage: true,
            source: {
                apiPath: '/dc-goods/admin/tag_groups/export/task',
                paramsFilter: ['name', 'productTag', 'status', 'channelId', 'organizationId'],
                apiRoot: '',
            },
            params: {
                name: filters && filters.name,
                productTag: filters && filters.productTag,
                status: filters && filters.status,
                channelId: params && params.channelId,
                organizationId: params && params.organizationId,
            },
            onSuccess: function() {},
            onError: function() {},
        };
        return <AsyncExportButton showSuccessTips={true} {...exportButton}></AsyncExportButton>;
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;

    return {
        filters: entity.filters,
    };
}
export const ExportMarkGroupButtonContent = connect(mapStateToProps)(exportMarkGroupButtonContent);

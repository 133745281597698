import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, getLanguageColumns } from '../../mixin';
import { formatNameProperties } from '../util';
import { DELIVERY_FLAG_OPTIONS } from '@/constants/order';
import { ONLINE_STORE_TABLE } from '@/configs/pro-table-constants';
import { STORE_ATTRIBUTES_OPTIONS, STORE_ATTRIBUTES_VALUES } from '@/constants/store';
export const config: Config = {
    entities: {
        onlineStore: {
            apiPath: '/loader/dc-store/admin/onlineStore',
            properties: {
                id: {
                    type: 'string',
                },
                isShow: {
                    type: 'number',
                },
                channelId: {
                    type: 'string',
                },
                currencyIsoCode: {
                    type: 'string',
                    displayName: '<<onlineStore.currencyIsoCode>>',
                    rules: [{ required: true }],
                },
                businessType: {
                    type: 'string',
                    displayName: '<<onlineStore.businessType>>',
                    rules: [{ required: true }],
                },
                orderOwn: {
                    type: 'string.store.order',
                    displayName: '<<onlineStore.orderOwn>>',
                },
                shippingMethodIds: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<onlineStore.shippingMethods.title>>',
                    options: DELIVERY_FLAG_OPTIONS,
                },
                onlineStoreName: {
                    type: 'string.text.trim',
                    displayName: '<<onlineStore.storeName>>',
                    rules: [
                        { required: true },
                        { whitespace: true, message: '<<onlineStore.storeNameNotEmpty>>' },
                        { max: 100, message: '<<onlineStore.storeNameError>>' },
                    ],
                },
                onlineStoreCode: {
                    type: 'string',
                    displayName: '<<onlineStore.storeCode>>',
                },
                onlineStockStoreVolist: {
                    type: 'string.store.location',
                    displayName: '<<onlineStore.stockMcsLocation>>',
                },
                languageName: {
                    type: 'object.subForm',
                    properties: formatNameProperties(
                        'onlineStore.storeName',
                        'storeInfo.storeNamePlaceHolder',
                        'storeInfo.nameError',
                        100
                    ),
                },
                logo: {
                    type: 'array.storeImage',
                    displayName: '<<onlineStore.storeLogo>>',
                },
                memberLevelType: {
                    type: 'string.options.radio',
                    options: STORE_ATTRIBUTES_OPTIONS,
                    displayName: '<<onlineStore.storeAttribute>>',
                    defaultValue: STORE_ATTRIBUTES_VALUES.GENERAL_STORE,
                    rules: [{ required: true }],
                },
                memberLevelIds: {
                    type: 'array.optionIds.select',
                    displayName: '<<pickStoreInfo.activeMembershipLevel>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-user/admin/online-store/member-level',
                    },
                    rules: [{ required: true }],
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<onlineStore.keyWords>>',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<onlineStore.keywordPlaceholder>>',
                    },
                },
            },
        },
    },
    components: {
        OnlineStoreContainer: {
            component: 'Viewport',
        },
        OnlineStoreManagement: {
            component: 'Card',
            entity: 'onlineStore',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'OnlineStoreFilter',
                    },
                    {
                        component: 'OnlineStoreTable',
                    },
                ],
                privilege: {
                    path: 'store.onlinestore.onlinestoreview',
                    level: 'full',
                },
            },
        },
        OnlineStoreFilter: {
            component: 'FilterFormPlus',
            style: {
                float: 'right',
                marginBottom: 15,
            },
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            fields: [{ property: 'keyword' }],
        },
        OnlineStoreTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: ONLINE_STORE_TABLE,
            columns: [...getLanguageColumns('languageName'), { property: 'onlineStoreCode' }],
            actionColumn: {
                title: '<<actions>>',
                width: 150,
                fixed: 'right',
                items: [
                    {
                        text: '<<view>>',
                        type: 'link',
                        path: '/onlineStore/detail/{{row.id}}',
                        privilege: {
                            path: 'store.onlinestore.view_detail',
                            level: 'full',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<edit>>',
                        path: '/onlineStore/edit/{{row.id}}',
                        privilege: {
                            path: 'store.onlinestore.onlinestoreedit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

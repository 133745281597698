import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import { map, flatten, isEmpty } from 'lodash';
import moment from 'moment';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { getStoreInfo } from '@/services/utils';
import * as pickingApi from './api';
import { API_TYPE } from './enums';
import { PHILIPS_STORE_TREE_STORAGE_KEY, STORE_TREE_STORAGE_KEY } from '@/constants';
/**
 * 格式化日期
 * @param {string} dateTime 时间字符串，例如：2020-03-26 10:16:01
 * @param {string} format 格式，例如：YYYY-MM-DD
 * @returns
 */
export function formatDateTime(dateTime: string, format: string) {
    if (isEmpty(dateTime)) {
        return '';
    }
    return moment(dateTime).format(format);
}

/**
 * Promise 风格的 confirm
 */
export function createConfirmPromise(config: ModalFuncProps) {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            ...config,
            onOk: () => {
                resolve(true);
            },
            onCancel: () => {
                // reject();
            },
        });
    });
}

/**
 * 跳转拣货单详情页面
 * @params id 拣货单 ID
 */
export function navigateToDetail(id: string, pathname: string) {
    return services.navigation.goto(`/${pathname}/detail/${id}`);
}

/**
 * 通过拣货单 id 查找所属拣货单下所有的 order_id
 * @param ids 拣货单 id，example: ['1524690094205132802', '1524690094205132803']
 */
export async function findOrderIdsByPickingIds(
    ids: Array<string>,
    apiType: string
): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        /**
         * 根据渲染的type取不同的存储key
         */
        const storeName =
            apiType === API_TYPE.PICKING_PHILIPS
                ? PHILIPS_STORE_TREE_STORAGE_KEY
                : STORE_TREE_STORAGE_KEY;

        pickingApi
            .fetchPickingList({
                pickingListIds: ids.join(','),
                onlineStoreId: getStoreInfo(storeName)?.id,
                apiType,
            })
            .then((pickingListData) => {
                const orderIds = map(pickingListData.result, (item) => {
                    return map(item.pickingListOrders, 'orderId');
                });
                const flattenIds = flatten(orderIds);
                resolve(flattenIds);
            })
            .catch((e) => {
                errorHandle(e);
            });
    });
}

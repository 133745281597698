/*
 * @Author: zhulu
 * @Date: 2022-05-06 11:01:44
 * @Description:线上门店选品
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    tableMixin,
    getLanguageColumns,
    getLanguageProperties,
    flexibleFilterForm,
} from '@/configs/mixin';
import { services } from '@comall-backend-builder/core';
import { IMPORT_TEMPLATE_URL } from '@/constants';
import { recordImportLogs } from '@/services';
import { PRODUCT_SELECTION } from '@/configs/pro-table-constants';
export const config: Config = {
    entities: {
        productSelection: {
            apiPath: '/dc-store/admin/onlineStore/pageOfSelectSku',
            properties: {
                id: { type: 'string' },
                onlineStoreCode: {
                    type: 'string',
                    displayName: '<<productSelection.onlineStoreCode>>',
                },
                skuCount: {
                    type: 'string',
                    displayName: '<<productSelection.count>>',
                },
                onlineStoreName: {
                    properties: getLanguageProperties(
                        'string',
                        'productSelection.onlineStoreName',
                        {
                            controlConfig: {
                                placeholder: '<<productSuite.namePlaceholder>>',
                            },
                        }
                    ),
                    type: 'object.subForm',
                },
            },
            filters: {
                onlineStoreKeyword: { type: 'string', displayName: '<<keyword>>' },
            },
        },
    },
    components: {
        ProductSelectionView: {
            component: 'Viewport',
        },
        ProductSelectionList: {
            component: 'FlexLayout',
            entity: 'productSelection',
            direction: 'vertical',
            className: 'product-price-view',
            items: [
                {
                    component: 'ProductSelectionFilter',
                    privilege: {
                        path: 'goods.store_goods.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'ProductSelectionAction',
                },
                {
                    component: 'ProductSelectionTable',
                    privilege: {
                        path: 'goods.store_goods.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        ProductSelectionFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'onlineStoreKeyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productSelection.keywordPlaceholder>>',
                    },
                },
            ],
        },
        ProductSelectionAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'OSSExportButton',
                    text: '<<productSelection.exportInfo>>',
                    type: 'link',
                    style: {
                        marginRight: 'auto',
                        marginLeft: 10,
                        flexGrow: 0,
                    },
                    privilege: {
                        path: 'goods.store_goods.download_import_template',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.PRODUCT_SELECTION}`,
                },
            ],
        },
        ProductSelectionTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: PRODUCT_SELECTION,
            scroll: {
                x: 'max-content',
            },
            columns: [
                ...getLanguageColumns('onlineStoreName'),
                { property: 'onlineStoreCode' },
                { property: 'skuCount' },
            ],

            actionColumn: {
                title: '<<actions>>',
                width: 100,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionImport',
                        text: '<<productSelection.importProduct>>',
                        config: {
                            apiPath: `/dc-file/excels?location_id=2`,
                            showSuccessMessage: false,
                            showErrorMessage: true,
                            onUploadComplete(response: any, row: any) {
                                console.debug(row);
                                if (response) {
                                    recordImportLogs({
                                        name: services.language.getText(
                                            'productSelection.importProduct'
                                        ),
                                        apiPath: `/dc-goods/admin/rskuImport/importRsku`,
                                        params: {
                                            resourceId: response.id,
                                            onlineStoreId: row.onlineStoreId,
                                        },
                                    });
                                }
                            },
                        },
                        privilege: {
                            path: 'goods.store_goods.import_goods_detail',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/admin/inmail',
        loader: {
            get: async function(data: AnyObject, config: AnyObject = {}) {
                if (data.id) {
                    return services.api
                        .get<AnyObject>(data, {
                            ...config,
                            apiPath: `/dc-order-message/admin/inmail/${data.id}`,
                        })
                        .then(({ isOpen, sendType, ...res }) => ({
                            ...res,
                            isOpen: String(isOpen),
                            sendType: String(sendType),
                        }));
                } else {
                    return services.api.get<AnyObject>(data, {
                        ...config,
                        apiPath: '/dc-order-message/admin/inmail',
                    });
                }
            },
            post: async function(data: AnyObject, config: AnyObject = {}) {
                return services.api.post(
                    { ...data, isOpen: data.isOpen === 'true' },
                    { ...config, apiPath: '/dc-order-message/admin/inmail' }
                );
            },
            put: async function(data: AnyObject, config: AnyObject = {}) {
                return services.api.put(
                    { ...data, isOpen: data.isOpen === 'true' },
                    { ...config, apiPath: `/dc-order-message/admin/inmail/${data.id}` }
                );
            },
        },
    },
];

/**
 * 订单明细报表
 */

import { ORDER_STATUS_ALL_OPTIONS } from '@/constants/order';
import { DELIVERY_TYPE_OPTIONS } from '@/constants/report';
import { formatDate } from '@/services';
import { Config, EntityProperties } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '../mixin';

import { getFilterProperties, getFields } from './utils';

export const getEntityProperties: () => EntityProperties = () => {
    let nVal: AnyObject = {};
    [
        'onlineStoreCode',
        'name',
        'outerOrderNumber',
        'createTime',
        'orderStatus',
        'expressDeliveryTime',
        'pickUpMobile',
        'pickUpMember',
        'orderMobile',
        'orderMember',
    ].forEach((i: any) => {
        switch (i) {
            case 'orderDeliveryType':
                nVal[i] = {
                    type: 'string.options.select',
                    displayName: `<<report.orderShipping.${i}>>`,
                    options: DELIVERY_TYPE_OPTIONS,
                };
                break;
            case 'expressDeliveryTime':
                nVal[i] = { type: 'string', displayName: `<<report.orderDetail.${i}>>` };
                break;
            case 'orderStatus':
                nVal[i] = {
                    type: 'string.options.select',
                    displayName: `<<report.orderShipping.${i}>>`,
                    options: ORDER_STATUS_ALL_OPTIONS,
                };
                break;
            default:
                nVal[i] = { type: 'string', displayName: `<<report.orderSummary.${i}>>` };
        }
    });
    [
        'csku',
        'productName',
        'categoryName',
        'brandName',
        'price',
        'quantity',
        'subtotal',
        'voucherAmount',
        'payableAmount',
        'point',
        'lackQuantity',
        'lackSubtotal',
        'finalQuantity',
        'finalSubtotal',
    ].forEach(
        (i: any) => (nVal[i] = { type: 'string', displayName: `<<report.orderDetail.${i}>>` })
    );
    return nVal;
};
const filterKeys = [
    'onlineStoreIds',
    'orderTime',
    'shipTime',
    'orderDeliveryType',
    'orderNumber',
    'orderStatus',
    'pickUpMember',
    'orderMember',
    'merchantIds',
    'liquidState',
    'isOfflineOrder',
];
export const config: Config = {
    entities: {
        orderDetailReport: {
            apiPath: '/loader/dc-order/admin/orderDetails',
            properties: getEntityProperties(),
            filters: getFilterProperties(filterKeys),
        },
    },
    components: {
        OrderDetailPage: {
            entity: 'orderDetailReport',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    privilege: {
                        path: 'forms.order_details.list',
                        level: 'full',
                    },
                    fields: getFields(getFilterProperties(filterKeys)),
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: { marginBottom: '16px' },
                    items: [
                        {
                            component: 'ExportButtonWithFilter',
                            text: '<<report.exportResult>>',
                            icon: 'download',
                            type: 'primary',
                            style: {
                                flexGrow: 0,
                                marginRight: 15,
                            },
                            source: {
                                apiPath:
                                    '/dc-order/admin/orderDetails/exportStatement/orderDetails/take',
                            },
                            formatter: (params: any) => {
                                const newParams: any = { ...params };
                                return {
                                    ...newParams,
                                    ...formatDate(newParams.orderTime, 'createTime'),
                                    ...formatDate(newParams.deliveryTime, 'shipTime'),
                                };
                            },
                            isAsync: true,
                            showSuccessTips: true,
                            showErrorMessage: true,
                            privilege: {
                                path: 'forms.order_details.export',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    ...tableMixin,
                    columns: getFields(getEntityProperties()),
                    privilege: {
                        path: 'forms.order_details.list',
                        level: 'full',
                    },
                },
            ]),
        },
    },
};

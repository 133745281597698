import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Form, InputNumber, Modal, Button } from 'antd';
import { get } from 'lodash';
import builder, { ComponentsManager, services } from '@comall-backend-builder/core';
import './system.less';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const testRange = {
    '2001': [11, 99],
    '2002': [16, 99],
};
const DepositDeployFormContent = (props: any, ref: any) => {
    const [help, setHelp] = useState('');
    const [row, setRow] = useState<any>();
    useEffect(() => {
        const { depositMin, depositMax } = props.row;
        setRow({ depositMin, depositMax });
    }, [props.row]);
    useImperativeHandle(
        ref,
        () => {
            return {
                reset: () => {
                    const { depositMin, depositMax } = props.row;
                    setRow({ depositMin, depositMax });
                    setHelp('');
                },
                handleSubmit: async () => {
                    if (validate(row)) return false;

                    const hasRange = ['1001', '1002'].indexOf(get(props.row, 'id')) > -1;
                    try {
                        const { id } = props.row;
                        await services.api.put(
                            hasRange ? { ...row } : { ...row, depositMax: row.depositMin },
                            {
                                apiPath: `/dc-price/admin/deposits/${id}`,
                            }
                        );
                        return true;
                    } catch (e) {
                        services.errorHandle(e as ErrorInfo);
                        return false;
                    }
                },
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [row]
    );
    const validate = (value: any) => {
        const { depositMin, depositMax } = value;
        //@ts-ignore
        const testRangeValue = testRange[get(props.row, 'id') + ''];
        if (testRangeValue && (depositMin < testRangeValue[0] || depositMax > testRangeValue[1])) {
            return services.interpolate(services.language.getText('deposit.errorMessage'), {
                min: testRangeValue[0],
                max: testRangeValue[1],
            });
        }
        if (!Object.values(value).every((v: any) => /^([1-9]|([1-9]\d))$/.test(v))) {
            return services.interpolate(services.language.getText('deposit.errorMessage'), {
                min: 1,
                max: 99,
            });
        }
        if (
            ['1001', '1002'].indexOf(get(props.row, 'id')) > -1 &&
            value.depositMax < value.depositMin
        ) {
            return services.language.getText('deposit.rangeErrorMessage');
        }
        return '';
    };
    const change = (value: any, name: string) => {
        const { depositMin, depositMax } = row;
        let nRow = { depositMin, depositMax, [name]: value };
        if (depositMin === depositMax) {
            nRow = {
                depositMin: value,
                depositMax: value,
            };
        }
        setRow(nRow);
        setHelp(validate(nRow));
    };
    return (
        <Form>
            <Form.Item
                {...formItemLayout}
                label={services.language.getText('deposit.ecDepositSet')}
                required
                help={help}
                validateStatus={help ? 'error' : ''}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputNumber
                        onChange={(value) => change(value, 'depositMin')}
                        value={get(row, 'depositMin')}
                        formatter={(value) => `${value}%`}
                        parser={(value) => (value || '').replace('%', '')}
                    />
                    {['1001', '1002'].indexOf(get(props.row, 'id')) > -1 && (
                        <>
                            -
                            <InputNumber
                                onChange={(value) => change(value, 'depositMax')}
                                value={get(row, 'depositMax')}
                                formatter={(value) => `${value}%`}
                                parser={(value) => (value || '').replace('%', '')}
                            />
                        </>
                    )}
                </div>
            </Form.Item>
        </Form>
    );
};
const DepositDeployForm = Form.create()(forwardRef(DepositDeployFormContent));
class DepositDeploy extends React.PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        const { row } = props;
        if (!ComponentsManager.has(`DepositDeployForm-${row.id}`)) {
            builder.registerComponent(`DepositDeployForm-${row.id}`, DepositDeployForm);
        }
        this.state = { visible: false };
    }
    listenRef: any;
    render() {
        const { visible } = this.state;
        const { row } = this.props;
        return (
            <>
                <Button
                    onClick={() => {
                        this.setState({ visible: true });
                    }}
                    type='link'
                >
                    {services.language.getText('set')}
                </Button>
                <Modal
                    title={services.language.getText('deposit.ecDepostRules')}
                    onCancel={() => {
                        this.listenRef.reset();
                        this.setState({ visible: false });
                    }}
                    onOk={async () => {
                        const result = await this.listenRef.handleSubmit();
                        if (result) {
                            this.setState({ visible: false });
                            const { entity, params } = this.props;
                            entity.search({
                                ...params,
                                ...entity.filters,
                            });
                        }
                    }}
                    visible={visible}
                >
                    {React.createElement(ComponentsManager.get(`DepositDeployForm-${row.id}`), {
                        row: row,
                        wrappedComponentRef: (ref: any) => (this.listenRef = ref),
                    })}
                </Modal>
            </>
        );
    }
}
export { DepositDeploy };

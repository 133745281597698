import React, { Component, createElement } from 'react';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { Button as AntButton, Icon as AntIcon, Modal as AntModal, message } from 'antd';
import { ExportButtonWithFilter } from '@/components/export-button-with-filter';

const { language } = services;

interface MarkGroupExportButtonStates {
    modalVisible: boolean;
    ids: any;
    type: string;
}
export class MarkGroupExportButton extends Component<any, MarkGroupExportButtonStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            modalVisible: false,
            ids: '',
            type: '',
        };
    }

    toggleModal = (visible: boolean) => {
        this.setState({
            modalVisible: visible,
        });
    };

    changeType = (value: string) => {
        this.setState({ type: value, ids: '' });
    };

    changeValue = (value: any) => {
        const { type } = this.state;
        let ids: string = '';
        if (type === '1') {
            ids = value.map((item: any) => item.id).join(';');
        } else if (type === '0') {
            ids = value.join(';');
        } else {
            ids = value;
        }
        this.setState({
            ids,
        });
    };

    beforeExport = () => {
        const { type, ids } = this.state;
        return new Promise((resolve, reject) => {
            if (!type) {
                message.error(services.language.getText('markGroup.exportError.chooseType'));
                reject();
            }
            if (!ids) {
                if (type === '0') {
                    message.error(services.language.getText('markGroup.exportError.chooseBrand'));
                }
                if (type === '1') {
                    message.error(
                        services.language.getText('markGroup.exportError.chooseCategory')
                    );
                }
                if (type === '2') {
                    message.error(services.language.getText('markGroup.exportError.csku'));
                }
                reject();
            }
            resolve(true);
        });
    };

    exportSuccess = () => {
        this.toggleModal(false);
    };

    render() {
        const { modalVisible, type, ids } = this.state;
        const { style, entity, componentId } = this.props;

        const exportButton = {
            entity,
            text: services.language.getText('export'),
            type: 'primary',
            showErrorMessage: true,
            showSuccessTips: true,
            source: {
                apiPath: `/dc-goods/admin/tag_groups/csku/type/${type}/export/task`,
                paramsFilter: ['type', 'ids'],
            },
            range: {
                apiPath: `/dc-goods/admin/tag_groups/csku/type/${type}/export/count`,
            },
            params: {
                type,
                ids,
            },
            beforeExport: this.beforeExport,
            onSuccess: this.exportSuccess,
        };

        const displayClassName = (fieldKey: string) => {
            return fieldKey === type ? 'export-modal-show' : 'export-modal-hidden';
        };

        return (
            <>
                <AntButton style={style} onClick={() => this.toggleModal(true)}>
                    {services.language.getText('markGroup.exportProduct')}{' '}
                    <AntIcon type='down'></AntIcon>
                </AntButton>
                <AntModal
                    width={800}
                    visible={modalVisible}
                    onCancel={() => this.toggleModal(false)}
                    footer={[
                        <AntButton key='back' onClick={() => this.toggleModal(false)}>
                            {services.language.getText('cancel')}
                        </AntButton>,
                        <ExportButtonWithFilter key='export' {...exportButton} />,
                    ]}
                >
                    <div className='mark-group-export-product-form'>
                        {createElement(ComponentsManager.get('CreateForm'), {
                            entity,
                            componentId,
                            labelCol: 4,
                            controlCol: 18,
                            submit: false,
                            fields: [
                                {
                                    property: 'tagType',
                                    controlConfig: {
                                        onChange: this.changeType.bind(this),
                                        allowClear: true,
                                    },
                                },
                                {
                                    property: 'brandIds',
                                    className: displayClassName('0'),
                                    controlConfig: {
                                        onChange: this.changeValue.bind(this),
                                        allowClear: true,
                                        showArrow: true,
                                    },
                                },
                                {
                                    property: 'categoryIds',
                                    className: displayClassName('1'),
                                    controlConfig: {
                                        onChange: this.changeValue.bind(this),
                                        dropdownStyle: {
                                            maxHeight: '400px',
                                        },
                                        allowClear: true,
                                        showArrow: true,
                                        treeCheckable: true,
                                        submitStrategy: 'child',
                                        showStrategy: 'parent',
                                        sourceParams: {
                                            id: -1,
                                        },
                                        source: {
                                            apiPath:
                                                '/loader/dc-goods/admin/offline_categories/tree_no_root',
                                        },
                                    },
                                },
                                {
                                    property: 'productIds',
                                    className: displayClassName('2'),
                                    controlConfig: {
                                        placeholder: language.getText('cskuPlaceHolder'),
                                        style: {
                                            height: 90,
                                        },
                                        onChange: this.changeValue.bind(this),
                                    },
                                },
                            ],
                        })}
                    </div>
                </AntModal>
            </>
        );
    }
}

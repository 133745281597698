import { IMPORT_STATUS_OPTIONS, RECOMMEND_PRODUCT_TYPE } from '@/constants';
import { services } from '@comall-backend-builder/core';
import { ComponentMetadata } from '@comall-backend-builder/core/lib/parser/component';
import { ComponentName2RouterName } from '../product/product-list';

const getRecordContentTableConfig = (privilege: string): ComponentMetadata => {
    return {
        component: 'DataTable',
        loadFirstPage: true,
        pagination: {
            pageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '15', '20'],
            showTotal(total: number) {
                return services.interpolate(services.language.getText('table.total'), {
                    total,
                });
            },
        },
        className: 'import-record-table',
        columns: [
            { property: 'createUser' },
            { property: 'createTime' },
            {
                property: 'status',
                displayConfig: {
                    statusConfig: IMPORT_STATUS_OPTIONS,
                },
            },
            { property: 'importResult' },
        ],
        actionColumn: {
            title: '<<actions>>',
            className: 'fixed-status-table-action',
            width: 200,
            items: [
                {
                    type: 'component',
                    component: 'ExportErrorButton',
                    privilege: {
                        path: `log.import_log.${privilege}_export_fail`,
                        level: 'full',
                    },
                },
                {
                    type: 'component',
                    component: 'ExportAllButton',
                    privilege: {
                        path: `log.import_log.${privilege}_export_all`,
                        level: 'full',
                    },
                },
            ],
        },
    };
};
const getImportRecordContentConfig = (privilege: string): ComponentMetadata => {
    return {
        component: 'FlexLayout',
        direction: 'vertical',
        items: [{ component: 'ImportRecordFilter' }, { ...getRecordContentTableConfig(privilege) }],
    };
};
export const config = {
    entities: {
        importRecord: {
            apiPath: '/loader/import_records',
            properties: {
                createUser: { type: 'string', displayName: '<<importRecord.createUser>>' },
                createTime: {
                    type: 'string.dateTime',
                    displayName: '<<importRecord.createTime>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<importRecord.status>>',
                },
                importResult: {
                    type: 'string',
                    displayName: '<<importRecord.importResult>>',
                },
            },
            filters: {
                userName: {
                    type: 'string',
                    displayName: '<<importRecord.createUser>>',
                    controlConfig: {
                        style: { width: '300px' },
                        placeholder: '<<importRecord.placeholders.createUser>>',
                    },
                },
                operationTime: {
                    type: 'object.dateTimeRange',
                    displayName: '<<importRecord.createTime>>',
                    controlConfig: {
                        style: { width: '300px' },
                        showTime: { defaultValue: ['00:00:00', '23:59:59'] },
                    },
                },
            },
        },
    },
    components: {
        ImportRecordView: {
            component: 'Viewport',
            entity: 'importRecord',
        },
        ImportRecordLayout: {
            component: 'FlexLayout',
            className: 'import-record-view',
            direction: 'vertical',
            align: 'start',
            items: [{ component: 'ImportRecordTab' }],
        },
        ImportRecordTab: {
            component: 'Tabs',
            animated: false,
            cache: false,
            style: { width: '100%' },
            items: [
                {
                    content: {
                        ...getImportRecordContentConfig('brand'),
                    },
                    params: {
                        recordType: 'brandInfo',
                    },
                    title: '<<importRecord.tabs.brandInfo>>',
                    privilege: {
                        path: 'log.import_log.brand_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('onlinestore'),
                    },
                    params: {
                        recordType: 'onlineStoreSelection',
                    },
                    title: '<<importRecord.tabs.onlineStoreSelection>>',
                    privilege: {
                        path: 'log.import_log.onlinestore_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('virtual_categorys'),
                    },
                    params: {
                        recordType: 'virtualCategory',
                    },
                    title: '<<importRecord.tabs.virtualCategory>>',
                    privilege: {
                        path: 'log.import_log.virtual_categorys_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('goods'),
                    },
                    params: {
                        recordType: 'productInfo',
                        sourceType: 0,
                    },
                    title: '<<importRecord.tabs.productInfo>>',
                    privilege: {
                        path: 'log.import_log.goods_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('group_goods'),
                    },
                    params: {
                        recordType: 'productInfo',
                        sourceType: 2,
                    },
                    title: '<<importRecord.tabs.groupProductInfo>>',
                    privilege: {
                        path: 'log.import_log.group_goods_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('virtual'),
                    },
                    params: {
                        recordType: 'productInfo',
                        sourceType: 3,
                    },
                    title: '<<importRecord.tabs.virtualProductInfo>>',
                    privilege: {
                        path: 'log.import_log.virtual_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('goods_price'),
                    },
                    params: {
                        recordType: 'productPrice',
                    },
                    title: '<<importRecord.tabs.productPrice>>',
                    privilege: {
                        path: 'log.import_log.goods_price_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('goods_stock'),
                    },
                    params: {
                        recordType: 'productInventory',
                    },
                    title: '<<importRecord.tabs.productInventory>>',
                    privilege: {
                        path: 'log.import_log.goods_stock_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('productscroll'),
                    },
                    params: {
                        recordType: 'productShelf',
                    },
                    title: '<<importRecord.tabs.productShelf>>',
                    privilege: {
                        path: 'log.import_log.productscroll_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('override_code'),
                    },
                    params: {
                        recordType: 'overrideCode',
                    },
                    title: '<<importRecord.tabs.overrideCode>>',
                    privilege: {
                        path: 'log.import_log.override_code_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('voucher_import_record'),
                    },
                    params: {
                        recordType: 'cashCoupon',
                    },
                    title: '<<importRecord.tabs.cashCoupon>>',
                    privilege: {
                        path: 'log.import_log.voucher_import_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('other_coupon_import_record'),
                    },
                    params: {
                        recordType: 'otherCoupon',
                    },
                    title: '<<importRecord.tabs.otherCoupon>>',
                    privilege: {
                        path: 'log.import_log.otherCoupon_import_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('voucher_grant_member_import_record'),
                    },
                    params: {
                        recordType: 'voucherIssuingStaff',
                    },
                    title: '<<importRecord.tabs.voucherIssuingStaff>>',
                    privilege: {
                        path: 'log.import_log.voucher_grant_member_import_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('member_import_record'),
                    },
                    params: {
                        recordType: 'memberInfo',
                    },
                    title: '<<importRecord.tabs.importMembers>>',
                    privilege: {
                        path: 'log.import_log.member_import_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('member_prices_import_record'),
                    },
                    params: {
                        recordType: 'memberPrice',
                    },
                    title: '<<importRecord.tabs.importMemberPrice>>',
                    privilege: {
                        path: 'log.import_log.member_prices_import',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('level_member_product_import'),
                    },
                    params: {
                        recordType: 'memberProduct',
                    },
                    title: '<<importRecord.tabs.importMemberProduct>>',
                    privilege: {
                        path: 'log.import_log.level_member_product_import_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('level_member_import'),
                    },
                    params: {
                        recordType: 'leverMember',
                    },
                    title: '<<importRecord.tabs.importLevelMember>>',
                    privilege: {
                        path: 'log.import_log.level_member_import_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('member_source'),
                    },
                    params: {
                        recordType: 'memberSource',
                    },
                    title: '<<importRecord.tabs.importMemberSource>>',
                    privilege: {
                        path: 'log.import_log.member_source_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('source_member_product_import'),
                    },
                    params: {
                        recordType: 'memberSourceBlackProducts',
                    },
                    title: '<<importRecord.tabs.importMemberSourceBlackProducts>>',
                    privilege: {
                        path: 'log.import_log.source_member_product_import_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('brand_import_recommend'),
                    },
                    params: {
                        recordType: `recommend/${RECOMMEND_PRODUCT_TYPE.BRAND}`,
                    },
                    title: '<<importRecord.tabs.brandImportRecommendProduct>>',
                    privilege: {
                        path: 'log.import_log.brand_import_recommend_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('display_categorys_import_recommend'),
                    },
                    params: {
                        recordType: `recommend/${RECOMMEND_PRODUCT_TYPE.DISPLAY_CATEGORY}`,
                    },
                    title: '<<importRecord.tabs.displayCategorysImportRecommendProduct>>',
                    privilege: {
                        path: 'log.import_log.display_categorys_import_recommend_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('merchant_import_recommend'),
                    },
                    params: {
                        recordType: `recommend/${RECOMMEND_PRODUCT_TYPE.MERCHANT}`,
                    },
                    title: '<<importRecord.tabs.merchantImportRecommendProduct>>',
                    privilege: {
                        path: 'log.import_log.merchant_import_recommend_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('goods_attr'),
                    },
                    params: {
                        recordType: ComponentName2RouterName.ProductList,
                        sourceType: 0,
                    },
                    title: '<<importRecord.tabs.goodsAttr>>',
                    privilege: {
                        path: 'log.import_log.goods_attr_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('virtual_attr'),
                    },
                    params: {
                        recordType: ComponentName2RouterName.VirtualProductList,
                        sourceType: 3,
                    },
                    title: '<<importRecord.tabs.virtualAttr>>',
                    privilege: {
                        path: 'log.import_log.virtual_attr_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('group_goods_attr'),
                    },
                    params: {
                        recordType: ComponentName2RouterName.GroupProductList,
                        sourceType: 2,
                    },
                    title: '<<importRecord.tabs.groupGoodsAttr>>',
                    privilege: {
                        path: 'log.import_log.group_goods_attr_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('url_mapping_import'),
                    },
                    params: {
                        recordType: 'urlMapping',
                    },
                    title: '<<importRecord.tabs.urlMapping>>',
                    privilege: {
                        path: 'log.import_log.url_mapping_import_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('dispaly_category'),
                    },
                    params: {
                        recordType: 'display',
                    },
                    title: '<<importRecord.tabs.display>>',
                    privilege: {
                        path: 'log.import_log.dispaly_category_view_lsit',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('product_limit'),
                    },
                    params: {
                        recordType: 'productPickupLimit',
                    },
                    title: '<<importRecord.tabs.productPickupLimit>>',
                    privilege: {
                        path: 'log.import_log.product_limit_view_list',
                        level: 'full',
                    },
                },
                {
                    content: {
                        ...getImportRecordContentConfig('sku_seo'),
                    },
                    params: {
                        recordType: 'skuSeo',
                    },
                    title: '<<importRecord.tabs.skuSeo>>',
                    privilege: {
                        path: 'log.import_log.sku_seo_view_list',
                        level: 'full',
                    },
                },
            ],
        },
        ImportRecordContent: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'ImportRecordFilter' }, { component: 'ImportRecordDataTable' }],
        },
        ImportRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [{ property: 'userName' }, { property: 'operationTime' }],
        },
        ImportRecordDataTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('table.total'), {
                        total,
                    });
                },
            },
            className: 'import-record-table',
            columns: [
                { property: 'createUser' },
                { property: 'createTime' },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: IMPORT_STATUS_OPTIONS,
                    },
                },
                { property: 'importResult' },
            ],
            actionColumn: {
                title: '<<actions>>',
                className: 'fixed-status-table-action',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'ExportErrorButton',
                        privilege: {
                            path: 'log.import_log.export_fail',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportAllButton',
                        privilege: {
                            path: 'log.import_log.export_all',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ExportAllButton: {
            type: 'all',
            component: 'TableExportButton',
        },
        ExportErrorButton: {
            type: 'error',
            component: 'TableExportButton',
        },
    },
};

import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Form, Modal, Input, Radio } from 'antd';
import { isEmpty } from 'lodash';
import SelectAutoComplete from '@/components/select-auto-complete';
import { THIRD_PARTY_TYPE_OPTIONS } from '@/constants';
import { PermissionListEdit } from './permission-list-edit';

const { api, errorHandle, language } = services;

interface IProps {
    className: string;
    text: string;
    style: any;
    row: any;
    apiPath: string;
    entity: Entity;
    params: any;
    form: any;
}

interface IState {
    visible: boolean;
    value: null | Array<any>;
    submitLoading: boolean;
    validated: boolean;
}

const thirdPartyTypeOptions = THIRD_PARTY_TYPE_OPTIONS.map((i) => ({ label: i.name, value: i.id }));
/**
 * 三方管理 新增信息
 */
export class thirdPartManagementAdd extends Component<IProps, IState> {
    state = {
        visible: false,
        value: null,
        submitLoading: false,
        validated: false,
    };

    submitAdd = async (values: any) => {
        const { entity } = this.props;
        const channelDesignSaveParams = {
            ...values,
            permissionList: values.permissionList?.map(
                ({ onlineStoreIdList, merchantIdList }: any) => ({
                    onlineStoreIdList: onlineStoreIdList.map((item: any) => item.id),
                    merchantIdList: merchantIdList.map((item: any) => item.id),
                })
            ),
        };
        try {
            this.setState({
                submitLoading: true,
            });
            await api.post(channelDesignSaveParams, { apiPath: `/dc-master-data/admin/channel` });
            entity.search(entity.params);
            this.toggleModal(false);
        } catch (e) {
            //@ts-ignore
            errorHandle(e);
        } finally {
            this.setState({
                submitLoading: false,
            });
        }
    };

    toggleModal = (visible: boolean) => {
        this.setState({
            visible,
            validated: false,
        });
    };

    handleClick = () => {
        this.toggleModal(true);
    };

    handleCancel = () => {
        this.toggleModal(false);
    };

    handleOk = () => {
        const { validateFields } = this.props.form;
        validateFields((errors: any, values: any) => {
            this.setState({
                validated: true,
            });
            if (!errors) {
                this.submitAdd(values);
            }
        });
    };

    render() {
        const { className, style, text, form } = this.props;
        const { visible, submitLoading } = this.state;
        const { getFieldDecorator, resetFields } = form;

        return (
            <>
                <Button
                    className={className}
                    style={style}
                    type='primary'
                    onClick={this.handleClick}
                >
                    {text}
                </Button>
                <Modal
                    title={language.getText('add')}
                    width={800}
                    visible={visible}
                    confirmLoading={submitLoading}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    okText={language.getText('save')}
                    afterClose={resetFields}
                >
                    <Form layout='horizontal' labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                        <Form.Item label={language.getText('thirdPartyManagement.thirdPartyName')}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: services.language.getText(
                                            'thirdPartyManagement.thirdPartyNameTips'
                                        ),
                                        whitespace: true,
                                    },
                                ],
                            })(
                                <Input
                                    placeholder={services.language.getText(
                                        'defaultPlaceholderInput'
                                    )}
                                    maxLength={100}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={language.getText('thirdPartyManagement.thirdPartyType')}>
                            {getFieldDecorator('type', {
                                initialValue: thirdPartyTypeOptions[0].value,
                            })(<Radio.Group options={thirdPartyTypeOptions} />)}
                        </Form.Item>
                        <Form.Item label={language.getText('thirdPartyManagement.white')}>
                            {getFieldDecorator('whiteStr')(
                                <Input.TextArea
                                    maxLength={500}
                                    style={{ height: 120, resize: 'none' }}
                                    placeholder={language.getText(
                                        'thirdPartyManagement.whitePlaceholder'
                                    )}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={language.getText('thirdPartyManagement.permissions')}>
                            {getFieldDecorator('permissionsResultList')(
                                <SelectAutoComplete
                                    mode='multiple'
                                    placeholder={services.language.getText(
                                        'defaultPlaceholderSelect'
                                    )}
                                    apiPath='/dc-master-data/admin/channel/permissions'
                                    selectParamKey='keyword'
                                />
                            )}
                        </Form.Item>
                        {getFieldDecorator('permissionList', {
                            rules: [
                                {
                                    validator(_: never, value: any[], callback: Function) {
                                        if (isEmpty(value)) {
                                            return callback('error');
                                        }
                                        for (const { onlineStoreIdList, merchantIdList } of value) {
                                            if (
                                                !onlineStoreIdList.length ||
                                                !merchantIdList.length
                                            ) {
                                                return callback('error');
                                            }
                                        }
                                        callback();
                                    },
                                },
                            ],
                        })(<PermissionListEdit validated={this.state.validated} />)}
                    </Form>
                </Modal>
            </>
        );
    }
}

export const ThirdPartManagementAdd = Form.create({ name: 'third-part-modal-add-form' })(
    thirdPartManagementAdd
);

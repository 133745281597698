import { UserService } from '@/utils/user-service';
import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/dc-user/admin/roles',
        loader: {
            put: async function(data: any, config: any) {
                const res: any = await services.api.put(data, config);
                if (res.data.isSameRole) UserService.init(true);
            },
        },
    },
];

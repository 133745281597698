export interface Brand {
    /***
     * id
     */
    id: string;
    /***
     * 品牌编码
     */
    searchCode: string;
    /***
     * 品牌中文
     */
    name: string;
    /***
     * 品牌名称英文
     */
    ename: string;
    /***
     * 原产地
     */
    origin: string;
    /***
     * 状态
     */
    status: 'DISABLED' | 'ENABLED';
}

import React, { useState } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { KV } from '@/interfaces';
import { SHELF_KEYS } from '../constants';
import ProductShelfModal from '../shelf-modal';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';

interface Props {
    status: SHELF_KEYS;
    entity: Entity;
    row: KV;
    refreshData: () => void;
}

export const ProductShelfTableActionButton = (props: Props) => {
    const [visible, setVisible] = useState(false);
    const { row, refreshData } = props;

    const buttonProps = {
        ...props,
        onClick: () => {
            if (row.abnormalReason) {
                return message.warn(services.language.getText('productShelf.offShelfWarningTip'));
            }
            setVisible(true);
        },
    };
    return (
        <>
            <Button {...buttonProps} />
            <ProductShelfModal
                visible={visible}
                products={[
                    {
                        csku: row.csku,
                        rsku: row.rsku,
                        onlineStoreCode: row.onlineStoreCode,
                        productId: row.productId,
                        onShelvesValues: row.onShelvesValues,
                    },
                ]}
                status={props.status}
                onClose={() => {
                    setVisible(false);
                }}
                onSuccess={() => {
                    setVisible(false);
                    refreshData();
                }}
            />
        </>
    );
};

import React, { useState } from 'react';
import { checkPrivilege } from '@/components/privilege';
import { Button as AntButton, Form, Modal, Select } from 'antd';

import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export const RefundMethod: React.FC<AnyObject> = (props) => {
    const [refundMethod, setRefundMethod] = useState<Number>(0); // 0: 原路返回, 1: 返还代金券
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const { Option } = Select;
    const {
        id,
        payType,
        parentProps: { entity },
    } = props;
    if (!checkPrivilege('aftermarket.order_refund_view.agree')) {
        return null;
    }

    const handleSubmit = () => {
        setLoading(true);
        services.api
            .get({ id, refundType: refundMethod }, { apiPath: `/base-order/admin/refund/pass` })
            .then(() => {
                entity.search(entity.params);
                setLoading(false);
                setVisible(false);
            })
            .catch(errorHandle);
    };

    return (
        <>
            <AntButton
                type='primary'
                onClick={() => {
                    setVisible(true);
                }}
                style={{ marginRight: 15 }}
            >
                {language.getText('afterSale.statusMap.approved')}
            </AntButton>
            <Modal
                title={services.language.getText('afterSale.tips.refundMethod')}
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={handleSubmit}
                confirmLoading={loading}
            >
                <Form.Item
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 10 }}
                    label={services.language.getText('afterSale.tips.refundPayMethod')}
                >
                    {payType || '-'}
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    required
                    label={services.language.getText('afterSale.tips.refundMethod')}
                >
                    <Select
                        defaultValue={0}
                        value={refundMethod}
                        onChange={setRefundMethod}
                        style={{ width: '100%' }}
                    >
                        <Option value={0}>
                            {services.language.getText('afterSale.tips.return')}
                        </Option>
                        <Option value={1}>
                            {services.language.getText('afterSale.tips.returnVoucher')}
                        </Option>
                    </Select>
                </Form.Item>
            </Modal>
        </>
    );
};

import { services } from '@comall-backend-builder/core';
import { each, filter } from 'lodash';
import { CHANNEL } from '@/constants';

export default [
    {
        key: '/dc-store/admin/store_channels',
        loader: {
            get: async function(data: any, config: any) {
                if (!data.id) {
                    config.apiPath = '/dc-store/admin/store_channels/findAllByLoginUser';
                    return await services.api.get(data, config);
                } else {
                    const result: any = await services.api.get(data, config);
                    result.pickUpTimes = result.storeChannelPickUpTime;
                    if (result.businessTimes.length === 0) {
                        result.businessTimes.push({
                            id: null,
                            startWeek: 'MONDAY',
                            endWeek: 'SUNDAY',
                            startTime: '00:00',
                            endTime: '22:30',
                            storeChannelPicks: [
                                {
                                    startTime: '00:00',
                                    endTime: '22:30',
                                    quantity: 1,
                                },
                            ],
                        });
                    }
                    if (result.businessTimes.length > 0) {
                        each(result.businessTimes, function(item, _index) {
                            if (!item.storeChannelPicks) {
                                item.storeChannelPicks = [];
                            }
                            if (item.storeChannelPicks.length === 0) {
                                item.storeChannelPicks.push({
                                    startTime: '00:00',
                                    endTime: '22:30',
                                    quantity: 1,
                                });
                            }
                        });
                    }

                    result.pickUpTime = result.storeChannelDeliveryDate.pickupTimeDay;
                    result.orderDeadlineTime = result.storeChannelDeliveryDate.orderDeadlineTime;
                    result.deliveryDate = result.storeChannelDeliveryDate.days;
                    result.firstPickUpDay = result.storeChannelDeliveryDate.firstPickUpDay || 0;
                    result.channelIds = result.channelId;
                    return result;
                }
            },
            put: async function(data: any, config: any) {
                let params = {
                    ...data,
                    storeId: data.id,
                    deliveryDateCommand: {
                        days: data.deliveryDate,
                        time: 1,
                        unit: 'MINUTES',
                        orderDeadlineTime: data.orderDeadlineTime,
                        pickupTimeDay: data.pickUpTime !== '00:00' ? data.pickUpTime : '00:00', //页面没有 暂时给默认值
                    },
                    pickUpTimes: {
                        endTime: '00:00',
                        startTime: '00:00',
                    },
                };
                params.businessTimes = data.businessTimes;
                params.deliveryDateCommand.firstPickUpDay = data.firstPickUpDay;
                delete params['firstPickUpDay'];
                delete params['channelIds'];
                return await services.api.put(params, config);
            },
            post: async function(data: any, config: any) {
                let selectChannelIds = [];
                selectChannelIds.push(data.channelIds);
                let params = {
                    ...data,
                    storeId: data.id,
                    channelIds: selectChannelIds,
                    deliveryDateCommand: {
                        days: data.deliveryDate,
                        time: 1,
                        unit: 'MINUTES',
                        orderDeadlineTime: data.orderDeadlineTime,
                        pickupTimeDay: '00:00', //页面没有 暂时给默认值
                    },
                    pickUpTimes: {
                        endTime: '00:00',
                        startTime: '00:00',
                    },
                };
                params.deliveryDateCommand.firstPickUpDay = data.firstPickUpDay;
                delete params['firstPickUpDay'];
                delete params['id'];
                delete params['deliveryDate'];
                delete params['earliestDeliveryTime'];
                delete params['orderDeadLineTime'];
                delete params['pickUpTime'];
                return await services.api.post(params, config);
            },
        },
    },
    {
        key: '/dc-master-data/admin/distribution_channels/findAllByLoginUser/order',
        loader: {
            get: async function(data: any, config: any) {
                data = { ...data, status: 'ENABLED' };
                config.apiPath = '/dc-master-data/admin/distribution_channels/findAllByLoginUser';
                return await services.api.get(data, config);
            },
        },
    },
    {
        key: '/dc-master-data/admin/distribution_channels/findAllByLoginUser',
        loader: {
            get: async function(data: any, config: any) {
                data = { ...data, status: 'ENABLED' };
                const result: any = await services.api.get(data, config);
                // return filter(result, (item) => item.id !== CHANNEL.GUIDE_SHOPPING);
                return result;
            },
        },
    },
    {
        key: '/dc-master-data/admin/distribution_channels/findAllByLoginUser/channel',
        loader: {
            get: async function(data: any, config: any) {
                config.apiPath = '/dc-master-data/admin/distribution_channels/findAllByLoginUser';
                const result: any = await services.api.get(data, config);
                // return filter(result, (item) => item.id !== CHANNEL.GUIDE_SHOPPING);
                return result;
            },
        },
    },
    {
        key: '/dc-master-data/admin/distribution_channels/findAllByLoginUser/all',
        loader: {
            get: async function(data: any, config: any) {
                data.status = 'ENABLED';
                config.apiPath = '/dc-master-data/admin/distribution_channels/findAllByLoginUser';
                const result: object[] = await services.api.get(data, config);
                result.unshift({
                    id: 'all',
                    name: '全部',
                    status: 'ENABLED',
                    type: '全部',
                });
                return result;
            },
        },
    },
    {
        key: '/dc-master-data/admin/distribution_channels/findAllByLoginUser/freebie-group',
        loader: {
            get: async function(data: any, config: any) {
                config.apiPath = '/dc-master-data/admin/distribution_channels/findAllByLoginUser';
                const result: any = await services.api.get(data, config);
                return filter(result, (item) => item.id !== CHANNEL.SELECT);
            },
        },
    },
];

import { ACQUISITION_METHOD_VALUES } from '@/constants';
import { buildFormFieldsByLanguage } from '@/services/language-utils';

export function buildForm(mode: string) {
    return {
        component: 'SuperForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'cards',
                itemLayout: 'vertical',
                key: 'cards',
                split: false,
                items: [
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.couponCenter.base>>',
                        key: 'cards.base',
                        bordered: false,
                        items: [
                            ...buildFormFieldsByLanguage({
                                renderType: 'field',
                                property: 'name',
                                maxLength: 100,
                                fieldOpts: {
                                    rules: [{ whitespace: true, required: true }],
                                },
                            }),
                            {
                                renderType: 'field',
                                property: 'effectTime',
                                key: 'effectTime',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'storeIds',
                                key: 'storeIds',
                                disabled: mode !== 'add',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'businessType',
                                key: 'businessType',
                                fieldOpts: {},
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        key: 'cards.conditionsReward',
                        title: '<<couponManage.couponCenter.prodcutSetting>>',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'couponId',
                                key: 'couponId',
                                formItem: {
                                    labelCol: { span: 4 },
                                    wrapperCol: { span: 20 },
                                },
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'sort',
                                key: 'sort',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: /^[1-9]\d{0,100}$/,
                                            message: '<<errorMessages.pleaseInputPositive>>',
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'type',
                                key: 'type',
                                disabled: mode !== 'add',
                                fieldOpts: {
                                    initialValue: ACQUISITION_METHOD_VALUES.RECEIVE,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'point',
                                key: 'point',
                                renderable: (values: AnyObject) =>
                                    values.type !== ACQUISITION_METHOD_VALUES.RECEIVE,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: /^[1-9]\d{0,4}$/,
                                            message: '<<errorMessages.pleaseInputPositive>>',
                                        },
                                    ],
                                },
                            },
                            ...buildFormFieldsByLanguage({
                                renderType: 'field',
                                property: 'description',
                                maxLength: 500,
                                fieldOpts: {
                                    rules: [{ whitespace: true, required: true }],
                                },
                            }),
                            ...buildFormFieldsByLanguage({
                                renderType: 'field',
                                property: 'disclaimer',
                                maxLength: 500,
                                fieldOpts: {
                                    rules: [{ whitespace: true, required: true }],
                                },
                            }),
                        ],
                    },
                ],
            },
        ],
    };
}

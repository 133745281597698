/**
 * 表格操作菜单中，切换启用、禁用通用组件
 */

import React, { useState } from 'react';
import { Modal as AntModal } from 'antd';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { interpolate, errorHandle } from '@comall-backend-builder/core/lib/services';
import { formatLanguage } from '@/services';
import { KV } from '@/interfaces';
import { Loader, services } from '@comall-backend-builder/core';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import { isString } from 'lodash';

function formatStatusText(textMap: KV) {
    const map: KV = {};
    for (let key in textMap) {
        map[key] = formatLanguage(textMap[key]);
    }
    return map;
}

interface IProps {
    /**
     * 状态对应的 API
     * 例如状态 0 表示禁用, 1 表示启用
     * 0 对应的api则为启用的api
     * 1 对应的api则为禁用的api
     * 实例如下：
     * statusApi: {
            '0': '/dc-user/admin/users/{{row.id}}/enable',
            '1': '/dc-user/admin/users/{{row.id}}/disable',
        },
     */
    statusApi: KV;
    /**
     * 状态对应的展示文本，同 statusApi
     */
    statusText: KV;
    /**
     * 当前表格行的数据
     */
    row: KV;
    entity: Entity;
    params: KV;
    style: KV;
    /**
     * 启用 / 禁用 modal确认框
     *	'0':{ 'title':'','content':''}
     *	'1':{ 'title':'','content':''}
     */
    tipConfig: KV;
    statusParams: KV; // 启用/禁用传参
    linkProperty: string; // 显示的text关联的属性，默认是status
    errorStatus?: string | number | boolean;
}

function fetchApi(apiPath: string, params: KV) {
    // 支持自定义 loader
    return Loader.load('put', {
        apiRoot: ENV.API_ROOT,
        apiPath,
        params,
    });
}

export function TableActionToggleStatus(props: IProps) {
    let {
        style,
        entity,
        row,
        params,
        statusApi,
        statusText,
        tipConfig,
        statusParams,
        linkProperty,
        errorStatus,
    } = props;
    const propertyName = linkProperty || 'status';
    const status = row[propertyName];
    const isError = status === errorStatus;
    const [loading, setLoading] = useState<boolean>(false);
    const showText = formatStatusText(statusText)[status];
    const showTipConfig = tipConfig && tipConfig[status];
    const apiPath = statusApi[status];
    const apiParams = statusParams ? statusParams[status] ?? statusParams : statusParams;
    const fetchData = async () => {
        try {
            setLoading(true);
            const formattedApiPath = interpolate(apiPath, { row });
            // 支持翻译参数
            // 如 { id: '{{row.id}}' } => { id: '1' }
            const formattedApiParams = Object.fromEntries(
                Object.entries(apiParams || {}).map(([k, v]) => [
                    k,
                    isString(v) ? interpolate(v, { row }) : v,
                ])
            );
            await fetchApi(formattedApiPath, formattedApiParams);
            entity.search(params);
        } catch (error) {
            errorHandle(error as ErrorInfo);
        } finally {
            setLoading(false);
        }
    };

    const handleClick = async () => {
        if (loading || !apiPath) return;
        if (showTipConfig) {
            AntModal.confirm({
                title: (showTipConfig.title && formatLanguage(showTipConfig.title)) || '',
                content: (showTipConfig.content && formatLanguage(showTipConfig.content)) || '',
                okText: services.language.getText('confirm'),
                cancelText: services.language.getText('cancel'),
                icon: 'exclamation-circle',
                className: 'mark-group-modal',
                onOk() {
                    fetchData();
                },
            });
        } else {
            fetchData();
        }
    };

    if (!showText) {
        return null;
    }

    return (
        <Button
            className='table-action-column-item'
            style={style}
            type='link'
            onClick={handleClick}
        >
            {isError ? (
                <span
                    style={{
                        color: '#f5222d',
                    }}
                >
                    {showText}
                </span>
            ) : (
                showText
            )}
        </Button>
    );
}

/** 商品列表 */
export { config as productListConfig } from './product-list';
/** 库存监控 */
export { config as productInventoryMonitoringConfig } from './product-inventory-monitoring';
/** 库存管理 */
export { config as productInventoryConfig } from './product-inventory';
/** 价格管理 */
export { config as productPrice } from './product-price';
/** 价格监控 */
export { config as productPriceMonitoring } from './product-price-monitoring';
/** 组合商品 */
export { config as productGroup } from './product-group';
/** 商品信息编辑 */
export { productFormConfig } from './product-info-form';
/** 商品上下架 */
export { config as productShelfConfig } from './product-shelf';
/** 商品套装 */
export { config as productSuiteConfig } from './product-suite';
/** 商品关联 */
export { config as productSelectionConfig } from './product-selection';
/** seo */
export { config as ProductSEOConfig } from './product-seo';
/** 品类 */
export { config as ProductStyleConfig } from './product-style';
export { config as productMapConfig } from './product-map';
/** 商品限购管理 */
export { config as orderLimitManagementConfig } from './order-limit-management';
/** 推荐商品管理 */
export { config as recommendProduct } from './recommend-product';
/** 商品配送限制管理 */
export { config as productPickupLimitConfig } from './product-pickup-limit';

import { connect } from 'react-redux';
import { get, map, defaults } from 'lodash';

import { List } from '../../components/list';
import { actions } from '@comall-backend-builder/core';

const DEFAULT_PAGE_INDEX = 1;
const DEFAULT_STATE = {
    result: [],
};

function mapStateToProps(state, props) {
    const { entity, componentId } = props;
    const component = state.components[componentId];
    const fields = component && component.fields;

    let componentState = {
        requestStatus: entity.requestStatus,
        result: entity.result,
        ...entity.paging,
    };

    if (fields) {
        componentState.result = map(componentState.result, (row) => {
            if (row.id === fields.id) {
                return { ...fields };
            } else {
                return row;
            }
        });
    }

    return defaults(componentState, DEFAULT_STATE);
}

function mapDispatchToProps(dispatch, props) {
    const { componentId, entity, params, onPageChange } = props;

    return defaults({
        // 设置初始分页
        onInit: () => {
            const { loaderType } = props;
            if (loaderType) {
                return;
            }

            if (props.pagination && !entity.paging) {
                const page = get(props, 'pagination.defaultCurrent', DEFAULT_PAGE_INDEX);
                const perPage = get(props, 'pagination.pageSize');
                entity.pageChange({ page, perPage });
            }

            if (props.infiniteScroll) {
                const page = get(props, 'infiniteScroll.defaultCurrent', DEFAULT_PAGE_INDEX);
                const perPage = get(props, 'infiniteScroll.pageSize');
                entity.pageChange({ page, perPage });
            }

            if (props.loadFirstPage) {
                dispatch(actions.componentSubmitAction(componentId, 'paging'));
                entity.search(params);
            }
        },

        onChange: (page, pageSize) => {
            const paging = entity.paging;

            if (page && pageSize && (page !== paging.page || pageSize !== paging.perPage)) {
                dispatch(actions.componentSubmitAction(componentId, 'paging'));
                entity.pageChange(defaults({ page, perPage: pageSize }, paging));
                entity.search(params);
                if (typeof onPageChange === 'function') {
                    onPageChange();
                }
            }
        },
    });
}

export const DataList = connect(mapStateToProps, mapDispatchToProps)(List);

import { isFunction, isPlainObject } from 'lodash';
import { modes } from '@comall-backend-builder/types';

/**
 * string 展示内容转换，避免在 loader 中做转换
 */
export class ConverterMode extends modes.StringMode {
    getDisplayComponent(value: any, displayConfig: any) {
        const { converter, row } = displayConfig;
        let _value = value;
        if (isFunction(converter)) {
            _value = converter(value, row);
        }
        if (isPlainObject(converter)) {
            _value = converter[value];
        }
        return _value || 'NA';
    }
}

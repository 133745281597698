import { services } from '@comall-backend-builder/core';
import { ComponentConfig } from '@comall-backend-builder/components-basis/lib/flex-layout';
import { KV } from '@/interfaces';
import { DESIGN_MANAGE_LANGUAGE_NAMES } from '@/constants';

export const paginationMixin = {
    pageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal(total: number) {
        return services.interpolate(services.language.getText('table.total'), {
            total,
        });
    },
};

export const tableMixin = {
    component: 'DataTable',
    loadFirstPage: true,
    pagination: paginationMixin,
    scroll: {
        x: 'max-content',
    },
};

export const noFooterForm = {
    footer: {
        style: {
            display: 'none',
        },
    },
};

export const multipleFilterForm = {
    component: 'FilterFormPlus',
    direction: 'horizontal',
    fieldCol: { span: 8 },
    fieldRow: {},
    labelCol: 10,
    footer: {
        style: {
            textAlign: 'right',
        },
    },
    submit: true,
};

/**
 * 自适应筛选表单配置
 */
export const flexibleFilterForm = {
    className: 'global-flexible-filter-form',
    component: 'FilterFormPlus',
    direction: 'horizontal',
    fieldCol: { span: 8 },
    fieldRow: {},
    labelCol: 0,
    reset: true,
    submit: {
        text: '<<query>>',
    },
    footer: {
        className: 'global-flexible-filter-form-footer',
    },
};

export const simpleFilterForm = {
    component: 'FilterFormPlus',
    direction: 'inline',
    submit: true,
};

export const createPageLayout = (items: ComponentConfig[]) => {
    return {
        component: 'Card',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: items,
        },
    };
};

export const formFooter = {
    items: [
        {
            type: 'default',
            text: '<<cancel>>',
            htmlType: 'button',
            route: 'goBack',
            style: {
                marginRight: 10,
            },
        },
        {
            text: '<<submit>>',
        },
    ],
    style: {
        textAlign: 'right',
    },
};

/**
 * 确认删除弹框文本配置
 */
export const deletePopConfirmConfig = {
    title: '<<areYouSureToDelete>>',
    okText: '<<confirm>>',
    cancelText: '<<cancel>>',
};

/**
 * 生成 [{property: 'xxx'}] 的格式
 */
export function genColumns(properties: any, otherProps?: KV) {
    const keys = Object.keys(properties);
    return keys.map((item: string) => ({ property: item, ...otherProps }));
}

export const getLanguageProperties = (
    type: string,
    displayName: string,
    otherConfig?: any,
    languageMap?: any
) => {
    const properties: any = {};
    const lanMap = languageMap || DESIGN_MANAGE_LANGUAGE_NAMES;
    Object.keys(lanMap).forEach((key) => {
        properties[`${key}`] = {
            type: type,
            displayName: lanMap[`${key}`]
                ? `${services.language.getText(displayName)}（${lanMap[`${key}`]}）`
                : `${services.language.getText(displayName)}`,
            ...otherConfig,
        };
    });
    return properties;
};

export const getLanguageColumns = (columnKey: string, config?: any, languageMap?: any) => {
    const columns: any[] = [];
    const lanMap = languageMap || DESIGN_MANAGE_LANGUAGE_NAMES;
    Object.keys(lanMap).forEach((key) => {
        columns.push({
            property: `${columnKey}.${key}`,
            ...config,
        });
    });

    return columns;
};

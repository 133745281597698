import React, { Component, createElement } from 'react';
import { Tabs as AntTabs } from 'antd';
import { connect } from 'react-redux';
import { camelCase, includes, get } from 'lodash';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { extendsParentProps } from '@comall-backend-builder/components-basis';
// import { Privilege } from '@/components/privilege';
import { StoreTreeSelector } from '@/components/store-tree-selector';
import { VoiceBroadcast } from '@/components/voice-broadcast';
// import { ExportButtonWithFilter } from '@/components/export-button-with-filter';
import { ORDER_STATUS_OPTIONS } from '@/constants/order';
import { paginationMixin } from '@/configs';
import { API_MAP, STORE_STORAGE_KEY } from '@/constants/order/api-map';
import { formatUrl } from '@/constants/order/utils';
const orderBroadcast = require('@/resources/audios/order-broadcast.mp3');
const AntTabPane = AntTabs.TabPane;

const DEFAULT_ACTIVE_TAB = 'ALL';

const fieldsList = [
    {
        property: 'pickStoreName',
        className: 'form-inline-col',
    },
    {
        property: 'outerOrderNumber',
        className: 'form-inline-col',
    },
    {
        property: 'orderNumber',
        className: 'form-inline-col',
    },
    {
        property: 'orderStatus',
        className: 'form-inline-col',
    },
    {
        property: 'keyword',
        className: 'form-inline-col',
    },
    {
        property: 'createTime',
        className: 'form-inline-col',
    },
    {
        property: 'deliveryFlag',
        className: 'form-inline-col',
    },
    {
        property: 'merchantId',
        className: 'form-inline-col',
    },
    {
        property: 'payType',
        className: 'form-inline-col',
    },
    {
        property: 'waitDeliveryTime',
        className: 'form-inline-col',
    },
    {
        property: 'stockOut',
        className: 'form-inline-col',
    },
    {
        property: 'memberType',
        className: 'form-inline-col',
    },
];
const fieldsListPart = fieldsList.filter((i) => i.property !== 'orderStatus');

export class OrderTabsFilter extends Component {
    status;
    tabs = ORDER_STATUS_OPTIONS;
    state = {
        activeKey: null,
        statistics: {},
    };

    newProps = {
        refresh: this.fetchOrganization,
    };
    paneProps = extendsParentProps(this.newProps, this.props);
    otherTypeOptions = [];

    componentWillMount() {
        const {
            entity: { filters },
            params: { orderStatus = DEFAULT_ACTIVE_TAB },
            config,
        } = this.props;
        let { entity } = this.props;
        entity.filtersChange({
            organizationId: 1002,
        });
        this.setState({
            activeKey:
                filters && filters.orderStatus ? filters.orderStatus : orderStatus.toUpperCase(),
        });
        if (typeof this.props.params.orderStatus === 'undefined') {
            const history = services.navigation.getHistory();
            if (!filters || (filters && !filters.orderStatus)) {
                history.replace({ pathname: config.pathname + orderStatus.toLowerCase() });
            } else {
                history.replace({ pathname: config.pathname + filters.orderStatus });
            }
        } else {
            let { entity } = this.props;
            const fields = Object.assign({}, entity.filters, {
                orderStatus:
                    filters && filters.orderStatus
                        ? filters.orderStatus
                        : orderStatus.toUpperCase() === 'ALL'
                        ? undefined
                        : orderStatus.toUpperCase(),
            });
            entity.filtersChange(fields);
        }
    }

    componentWillReceiveProps(props) {
        const {
            params: { orderStatus },
        } = props;
        this.setState({
            activeKey: (orderStatus || DEFAULT_ACTIVE_TAB).toUpperCase(),
        });
    }

    fetchOrganization = async (resetPage = false) => {
        const { entity } = this.props;
        const { orderStatus: prevOrderStatus } = entity.filters;
        if (resetPage)
            entity.pageChange(Object.assign({}, entity.paging, { page: 1, perPage: 10 }));
        this.fetchStatistics();
        entity.search(entity.params, () => prevOrderStatus !== entity.filters.orderStatus);
    };

    fetchStatistics = async () => {
        const { entity, config } = this.props;
        return services.api
            .get(
                {
                    pageType: 'order_list',
                    onlineStoreId: entity.filters.onlineStoreIds,
                    organizationId: 1002,
                },
                {
                    apiPath: formatUrl(API_MAP.ORDER_STATISTICS, config.type),
                }
            )
            .then((rep) => {
                this.setState({
                    statistics: rep,
                });
                return rep;
            });
    };

    onChange = (key) => {
        const { entity, config } = this.props;
        entity.filtersChange({
            orderStatus: key.toUpperCase() === 'ALL' ? undefined : key,
        });
        const history = services.navigation.getHistory();
        history.replace({ pathname: config.pathname + key.toLowerCase() });
        this.fetchOrganization(true);
    };
    onChangeStore = (onlineStores) => {
        const { entity } = this.props;
        const onlineStoreIds = onlineStores.map((item) => item.id).join(',');
        if (!onlineStoreIds) return;
        entity.filtersChange({
            onlineStoreIds,
        });
        this.fetchOrganization(true);
    };

    checkCanPlay = async () => {
        const { entity, config } = this.props;
        const count = await services.api.get(
            {
                onlineStoreId: entity.filters.onlineStoreIds,
            },
            {
                apiPath: formatUrl(API_MAP.COUNTBYUNPICK, config.type),
            }
        );

        return count > 0;
    };

    renderTabPane = () => {
        const {
            params: { orderStatus },
        } = this.props;
        let activeKey = (orderStatus || DEFAULT_ACTIVE_TAB).toUpperCase();

        return this.tabs.map((item) => {
            return (
                <AntTabPane
                    className={item.className}
                    tab={`${item.name} ( ${this.state.statistics[camelCase(item.id)] || 0} )`}
                    key={item.id}
                >
                    {activeKey !== item.id ? null : (
                        <OrderListPane
                            {...this.paneProps}
                            fetchStatistics={this.fetchStatistics}
                            otherTypeOptions={this.otherTypeOptions}
                            activeKey={activeKey}
                            config={this.props.config}
                        />
                    )}
                </AntTabPane>
            );
        });
    };

    render() {
        const voiceBroadcastDuration = 5 * 60 * 1000;

        return (
            <div className='order-list'>
                <div className='order-list-header'>
                    <div className='voice-broadcast'>
                        <VoiceBroadcast
                            src={orderBroadcast}
                            duration={voiceBroadcastDuration}
                            checkCanPlay={this.checkCanPlay}
                        />
                    </div>
                    <StoreTreeSelector
                        style={{
                            position: 'static',
                        }}
                        params={
                            this.props.config.treeType
                                ? { treeType: this.props.config.treeType }
                                : {}
                        }
                        storageKey={
                            this.props.config.type === 'philips'
                                ? STORE_STORAGE_KEY.PHILIPS_STORE_TREE_STORAGE_KEY
                                : STORE_STORAGE_KEY.STORE_TREE_STORAGE_KEY
                        }
                        checkable={false}
                        selectable={true}
                        onChange={this.onChangeStore}
                    />
                </div>
                <AntTabs activeKey={this.state.activeKey} onChange={this.onChange} animated={false}>
                    {this.renderTabPane()}
                </AntTabs>
            </div>
        );
    }
}

class orderListPane extends Component {
    formatterExportParams = (params) => {
        if (params.channels) {
            // 可能filterchange没有更新完，因此这里需要直接使用channelIds
            if (includes(params.channels, 'all')) {
                const channelIds = services.localStorage.get('channelIds');
                params.channels = channelIds.join(',');
            } else {
                params.channels = params.channels.join(',');
            }
        }
        if (params.createTime) {
            params.beginTime = params.createTime.start;
            params.endTime = params.createTime.end;
            delete params.createTime;
        }
        if (params.waitDeliveryTime) {
            params.preStartDeliveryTime = params.waitDeliveryTime.start;
            params.preEndDeliveryTime = params.waitDeliveryTime.end;
            delete params.waitDeliveryTime;
        }
        if (params.timeOut) {
            params.timeOut = params.timeOut.join(',');
        }

        if (params.orderStatus === 'ABNORMAL_ORDERS') {
            params.query_type = 'ABNORMAL_ORDERS';
            delete params.orderStatus;
        }
        return params;
    };

    render() {
        const isDelivering = this.props?.activeKey === 'DELIVERING';

        const filterConfig = {
            entity: this.props.entity,
            direction: 'inline',
            fieldCol: {
                span: 8,
            },
            labelCol: { span: 8 },
            // className: 'store-filter-form',
            className: 'global-flexible-filter-form',
            submit: {
                text: services.language.getText('query'),
            },
            reset: true,
            simple: {
                fields: ['channels', 'createTime', 'keyword'],
            },
            fields:
                this.props?.activeKey === 'ALL'
                    ? fieldsList
                    : isDelivering
                    ? [
                          ...fieldsList,
                          {
                              property: 'expressDeliveryTime',
                              className: 'form-inline-col',
                          },
                      ]
                    : fieldsListPart,
            footer: {
                style: {
                    textAlign: 'right',
                },
            },
        };
        const listConfig = {
            className: 'dc-order-list order-receiving',
            split: false,
            loadFirstPage: false,
            pagination: paginationMixin,
            renderItem: 'OrderDataListItem',
            config: this.props.config,
            entity: this.props.entity,
            refresh: this.props.refresh,
            fetchStatistics: this.props.fetchStatistics,
            onPageChange: () => {
                // props.refresh(true);
            },
        };

        return (
            <div>
                <div>{createElement(ComponentsManager.get('FilterFormPlus'), filterConfig)}</div>
                <div style={{ marginTop: 15 }}>
                    {createElement(ComponentsManager.get('DataList'), listConfig)}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    const {
        entity,
        entity: { filters },
        entities,
    } = props;
    const organizations = get(entities, 'organizationSelector.fields', []);
    organizations.length === 1 && services.localStorage.set('dcOrganization', organizations[0]);
    return {
        entity,
        filters,
        organizations,
    };
}

const OrderListPane = connect(mapStateToProps, null)(orderListPane);

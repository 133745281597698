import React, { useEffect, useState } from 'react';
import { modes } from '@comall-backend-builder/types';
import { Input as AntdInput } from 'antd';
import classNames from 'classnames';
export class InputArrayMode extends modes.ArrayMode {
    public getDisplayComponent(value: any, displayInfo: any) {
        const { className, style } = displayInfo;
        displayInfo = {
            ...displayInfo,
            className: classNames('type-string', className),
        };
        return (
            <span className={className} style={style}>
                {value ? value : '--'}
            </span>
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <InputArrayForm {...controlInfo} />;
    }
}

const InputArrayForm: React.FC<any> = (props) => {
    const [showValue, setShowValue] = useState<any[]>([]);

    useEffect(() => {
        /**
         *
         * A , B 为文本
         * input 为输入框
         *
         * 		 A
         *
         * input   input
         *
         * 		 A        B
         *
         * input   input     input
         *
         * 二叉树形式
         */
        const processValue = (value: any) => {
            const newValue: any[] = [];
            value.forEach((item: any, index: number) => {
                let insertInput = false; //是否新增输入框
                if (index === 0 && !item.change) {
                    //首个不为输入框
                    insertInput = true;
                }
                if (index > 0 && !item.change && !value[index - 1].change) {
                    //两个不可输入相连
                    insertInput = true;
                }

                if (insertInput) {
                    newValue.push({ content: '', change: true });
                }
                newValue.push({ ...item });

                if (index === value.length - 1 && !item.change) {
                    //最后一个不为输入框
                    newValue.push({ content: '', change: true });
                }
            });
            return newValue;
        };
        if (showValue.length === 0 && props.value) {
            const value = processValue(props.value);
            setShowValue(value);
            props.onChange(value);
        }
    }, [props, showValue]);

    return (
        <div style={{ display: 'flex' }}>
            {showValue.map((item, index) => {
                return item.change ? (
                    <AntdInput
                        key={`${index}`}
                        style={{
                            flex: 2,
                            marginTop: '5px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                        value={item.content}
                        onChange={(event) => {
                            item.content = event.target.value;
                            props.onChange(showValue);
                        }}
                    ></AntdInput>
                ) : (
                    <span
                        key={`${index}`}
                        style={{ margin: '0 10px', flex: 1, textAlign: 'center' }}
                    >
                        {item.content}
                    </span>
                );
            })}
        </div>
    );
};

import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, flexibleFilterForm } from '../mixin';
import { filterProperties } from '@/constants/picking/properties';
import { API_TYPE, PATH_NAME } from '@/containers/picking/enums';
import { ORDER_TYPE } from '@/constants/order/enum';

export const config: Config = {
    entities: {
        // 拣货 tab entity
        picking: {
            apiPath: '/loader/dc-order-picking/admin/picking_lists',
            filters: filterProperties,
        },
        // 订单 tab entity
        orders: {
            apiPath: '/loader/base-order/admin/order/order_list_picking',
            filters: filterProperties,
        },
    },

    components: {
        PickingAssistantContainer: {
            component: 'Viewport',
        },
        PickingAssistantFilter: {
            ...flexibleFilterForm,
            component: 'FilterFormPlus',
            className: 'global-flexible-filter-form promotion-macau-filter',
            fields: [{ property: 'outerOrderNumber' }, { property: 'deliveryType' }],
        },

        PickingAssistantPage: {
            component: 'Card',
            entities: [
                { name: 'picking', entityName: 'picking' },
                { name: 'orders', entityName: 'orders' },
            ],
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'PickingAssistant',

                        items: [
                            {
                                name: 'WAITING_PICK',
                                component: 'PickingAssistantWaitPickPane',
                                statusKey: 'status',
                            },
                            {
                                name: 'PICKING',
                                component: 'PickingAssistantPickingPane',
                                statusKey: 'status',
                            },
                            {
                                name: 'STOCKOUT',
                                component: 'PickingAssistantStockOutPane',
                                statusKey: 'queryType',
                            },
                            {
                                name: 'PICKED',
                                component: 'PickingAssistantWaitPackagePane',
                                statusKey: 'queryType',
                            },
                            {
                                name: 'PACKED',
                                component: 'PickingAssistantWaitPickupPane',
                                statusKey: 'queryType',
                            },
                            {
                                name: 'CANCELLED',
                                component: 'PickingAssistantCancelledPane',
                                statusKey: 'queryType',
                            },
                            {
                                name: 'ALL',
                                component: 'PickingAssistantAllOrderPane',
                                statusKey: 'queryType',
                            },
                        ],

                        config: {
                            pathname: '/picking-assistant/',
                            apiType: API_TYPE.ORDER,
                        },
                    },
                ],
                privilege: {
                    path: 'picking.assistant.view_list',
                    level: 'full',
                },
            },
        },
        // 待拣货
        PickingAssistantWaitPickPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'picking',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                renderItem: 'WaitPickItem',
                rowSelection: {},
                form: {
                    type: 'component',
                    component: 'PickingAssistantFilter',
                },
                header: {
                    type: 'component',
                    component: 'BatchPickingAndPrintButton',
                },
                pagination: tableMixin.pagination,
                apiType: API_TYPE.PICKING,
                pickPathname: PATH_NAME.PICK_PATH_NAME,
                orderPathname: PATH_NAME.ORDER_PATH_NAME,
                type: ORDER_TYPE.BASE,
            },
        },
        // 拣货中
        PickingAssistantPickingPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'picking',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                renderItem: 'PickingItem',
                pagination: tableMixin.pagination,
                header: {
                    type: 'component',
                    component: 'PickingAssistantFilter',
                },
                apiType: API_TYPE.PICKING,
                pickPathname: PATH_NAME.PICK_PATH_NAME,
                orderPathname: PATH_NAME.ORDER_PATH_NAME,
                type: ORDER_TYPE.BASE,
            },
        },
        // 缺货订单
        PickingAssistantStockOutPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'orders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                header: {
                    type: 'component',
                    component: 'PickingAssistantFilter',
                },
                renderItem: 'StockOutItem',
                pagination: tableMixin.pagination,
                apiType: {
                    picking: API_TYPE.PICKING,
                    order: API_TYPE.ORDER,
                },
                pickPathname: PATH_NAME.PICK_PATH_NAME,
                orderPathname: PATH_NAME.ORDER_PATH_NAME,
                type: ORDER_TYPE.BASE,
            },
        },
        // 待打包
        PickingAssistantWaitPackagePane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'orders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                renderItem: 'WaitPackageItem',
                rowSelection: {},
                form: {
                    type: 'component',
                    component: 'PickingAssistantFilter',
                },
                header: {
                    type: 'component',
                    component: 'WaitPackageBatchActions',
                },
                pagination: tableMixin.pagination,
                apiType: {
                    picking: API_TYPE.PICKING,
                    order: API_TYPE.ORDER,
                },
                pickPathname: PATH_NAME.PICK_PATH_NAME,
                orderPathname: PATH_NAME.ORDER_PATH_NAME,
                type: ORDER_TYPE.BASE,
            },
        },
        // 待发货
        PickingAssistantWaitPickupPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'orders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                form: {
                    type: 'component',
                    component: 'PickingAssistantFilter',
                },
                header: {
                    type: 'component',
                    component: 'WaitPickupBatchActions',
                },
                rowSelection: {},
                renderItem: 'WaitPickupItem',
                apiType: {
                    picking: API_TYPE.PICKING,
                    order: API_TYPE.ORDER,
                },
                pagination: tableMixin.pagination,
                pickPathname: PATH_NAME.PICK_PATH_NAME,
                orderPathname: PATH_NAME.ORDER_PATH_NAME,
                type: ORDER_TYPE.BASE,
            },
        },
        // 取消订单
        PickingAssistantCancelledPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'orders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                header: {
                    type: 'component',
                    component: 'PickingAssistantFilter',
                },
                renderItem: 'CancelledOrderItem',
                pagination: tableMixin.pagination,
                pickPathname: PATH_NAME.PICK_PATH_NAME,
                orderPathname: PATH_NAME.ORDER_PATH_NAME,
                type: ORDER_TYPE.BASE,
            },
        },
        // 全部订单
        PickingAssistantAllOrderPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'orders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                renderItem: 'AllOrderItem',
                header: {
                    type: 'component',
                    component: 'PickingAssistantFilter',
                },
                pagination: tableMixin.pagination,
                apiType: {
                    picking: API_TYPE.PICKING,
                    order: API_TYPE.ORDER,
                },
                pickPathname: PATH_NAME.PICK_PATH_NAME,
                orderPathname: PATH_NAME.ORDER_PATH_NAME,
                type: ORDER_TYPE.BASE,
            },
        },
    },
};

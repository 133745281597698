import React, { useState, useImperativeHandle } from 'react';

import { Button, Modal } from 'antd';
import { DataItem, formatMobile } from '.';
import { services } from '@comall-backend-builder/core';
import { Privilege } from '@/components/privilege';

export interface ModalRef {
    open(): void;
}

interface Props {
    data: DataItem[];
    confirmReceipt: (phone: string, id: string) => Promise<void>;
}

export const QueryOrderModal = React.forwardRef<ModalRef | undefined, Props>((props, ref) => {
    const [visible, setVisible] = useState<boolean>(false);
    const { data, confirmReceipt } = props;

    const submit = async (phone: string, id: string) => {
        setVisible(false);
        confirmReceipt && confirmReceipt(phone, id);
    };

    useImperativeHandle(
        ref,
        () => ({
            open: () => setVisible(true),
        }),
        []
    );

    return (
        <Modal
            title={services.language.getText('orderManagement.orderTitle')}
            visible={visible}
            footer={<></>}
            className='modal-content'
            closeIcon={
                <div>
                    <Button size='small' onClick={() => setVisible(false)}>
                        {services.language.getText('orderManagement.close')}
                    </Button>
                </div>
            }
        >
            {data.map((item, index) => (
                <section
                    key={item.id}
                    className='modal-item'
                    style={{
                        marginTop: index > 0 ? '20px' : '0',
                    }}
                >
                    <div className='modal-item-title'>{item.parentOrderNumber}</div>
                    <div className='modal-item-content'>
                        <div className='modal-item-view'>
                            <p>{services.language.getText('orderManagement.merchantName')}:</p>
                            <p className='modal-item-text'>{item.merchantName}</p>
                        </div>
                        <div className='modal-item-view'>
                            <p>{services.language.getText('orderManagement.orderCustomer')}:</p>
                            <p className='modal-item-text'>
                                **{item.pickUpInfo.firstName} -{' '}
                                {formatMobile(item.pickUpInfo.mobile)}
                            </p>
                        </div>
                        <div className='modal-item-view'>
                            <p>{services.language.getText('orderManagement.orderTime')}:</p>
                            <p className='modal-item-text'>{item.thirdPartyCreateTime}</p>
                        </div>
                    </div>
                    <div className='modal-item-btn'>
                        <Privilege path='order.self_pickup_view.self_pickup_order_send_sms'>
                            <Button
                                type='primary'
                                onClick={() => submit(item.pickUpInfo.mobile, item.id)}
                            >
                                {services.language.getText('orderManagement.confirmReceipt')}
                            </Button>
                        </Privilege>
                    </div>
                </section>
            ))}
        </Modal>
    );
});

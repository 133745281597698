import React, { Component, createElement } from 'react';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services, ComponentsManager } from '@comall-backend-builder/core/lib';
import { recordImportLogs } from '@/services';

export interface SelectMemberDiscountButtonProps {
    className: string;
    entity: Entity;
    row: any;
    type: 'export' | 'import';
}

export class SelectMemberDiscountButton extends Component<SelectMemberDiscountButtonProps, any> {
    render() {
        let { className, row, type, entity } = this.props;
        const ExportProps = {
            className,
            text: services.language.getText('selectMemberDiscount.exportDiscount'),
            type: 'link',
            source: {
                apiPath: `/dc-promotion/admin/promotions/csku/export/${row.memberLevel.toLocaleLowerCase()}`,
                paramsFilter: [],
            },
        };
        const ImportProps = {
            className,
            entity,
            apiPath: `/dc-file/excels?location_id=2`,
            showSuccessMessage: false,
            triggerComponent: {
                component: 'Button',
                type: 'link',
                children: services.language.getText('selectMemberDiscount.importDiscount'),
            },
            onUploadComplete(response: any) {
                if (response) {
                    recordImportLogs({
                        name: '商品优惠',
                        apiPath: `/dc-price/admin/member_discount/${row.id}/csku/up_excel`,
                        params: { resourceId: response.id },
                    });
                }
            },
        };
        return (
            <React.Fragment>
                {type === 'export'
                    ? createElement(ComponentsManager.get('ExportButton'), ExportProps)
                    : createElement(ComponentsManager.get('Upload'), ImportProps)}
            </React.Fragment>
        );
    }
}

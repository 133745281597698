import React, { useState } from 'react';
import { Modal, Descriptions } from 'antd';
import { Button, Image } from '@comall-backend-builder/components-basis';
import { SHELF_KEYS } from '../constants';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core/lib';
import changeEvaluationStatus from '../utils/change-evaluation-status';
import { Privilege } from '@/components/privilege';

interface IProps {
    entity: any;
    row: {
        id: string;
    };
}

interface Data {
    content?: string;
    createTime?: string;
    evaluatorNumber?: string;
    grade?: string;
    id?: string;
    imgs?: string[];
    skuName?: string;
}

export const EvaluationModal: React.FC<IProps> = (props) => {
    const { entity, row } = props;
    const [data, setData] = useState<Data>({});
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        const result: Data = await services.api.get(
            {},
            {
                apiPath: `/dc-goods/admin/sku_evaluation/${row.id}`,
            }
        );
        setData(result);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleOk = () => {
        changeEvaluationStatus({ id: row.id, status: '1' }).then(() => {
            entity.search(entity.params);
            setLoading(false);
        });
    };

    const handleCancel = () => {
        changeEvaluationStatus({ id: row.id, status: '2' }).then(() => {
            entity.search(entity.params);
            setLoading(false);
        });
    };

    return (
        <>
            <Button
                {...props}
                onClick={() => {
                    getData();
                    setVisible(true);
                }}
            />
            <Modal
                title={
                    entity.filters?.evaluationStatus === SHELF_KEYS.NOT_REVIEWED
                        ? language.getText('commodityEvaluation.evaluationExamine')
                        : language.getText('commodityEvaluation.evaluationView')
                }
                destroyOnClose
                width={600}
                visible={visible}
                onCancel={onClose}
                onOk={handleOk}
                footer={
                    entity.filters?.evaluationStatus === SHELF_KEYS.NOT_REVIEWED
                        ? [
                              <Privilege path='opinion_management.sku_evaluation.sku_evaluation_update'>
                                  <Button type='primary' key='back' onClick={handleOk}>
                                      {language.getText('commodityEvaluation.examineResolve')}
                                  </Button>
                              </Privilege>,
                              <Privilege path='opinion_management.sku_evaluation.sku_evaluation_update'>
                                  <Button
                                      type='default'
                                      key='submit'
                                      loading={loading}
                                      onClick={handleCancel}
                                  >
                                      {language.getText('commodityEvaluation.reject')}
                                  </Button>
                              </Privilege>,
                          ]
                        : null
                }
            >
                <Descriptions column={1}>
                    <Descriptions.Item label={language.getText('commodityEvaluation.productName')}>
                        {data.skuName || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={language.getText('commodityEvaluation.evaluationScore')}
                    >
                        {data.grade || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={language.getText('commodityEvaluation.contentReview')}
                    >
                        {data.content || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label={language.getText('commodityEvaluation.reviewer')}>
                        {data.evaluatorNumber || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={language.getText('commodityEvaluation.evaluationTime')}
                    >
                        {data.createTime || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={language.getText('commodityEvaluation.evaluationPicture')}
                    >
                        {data.imgs?.map((url) => (
                            <Image
                                key={url}
                                imageurl={url}
                                style={{ width: '100%', height: '100%' }}
                            />
                        ))}
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        </>
    );
};

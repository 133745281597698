import builder, { Type } from '@comall-backend-builder/core';
import { formats, StringFormat } from '@comall-backend-builder/types';

import {
    StatusMode,
    RadioMode,
    StringOptionCheckboxMode,
    PickUpTimesMode,
    StringTextBoxMode,
    LocationSelectMode,
    StoreOrderMode,
    StoreLocationMode,
    CascaderShopMode,
    StoreBrandMode,
    StringRichTextVideoMode,
    ConverterMode,
    ColorTextMode,
    LongTextMode,
    StoreNameMode,
    LinkTextMode,
    Array2StringMode,
    StringImagePickerFormat,
    ClickLookMode,
    AutoCompleteDefaultMode,
    StringOptionAutoCompleteMode,
    NewStringPasswordMode,
    CouponCenterItemsMode,
    CouponCenterItemsFormat,
    ViewTextMode,
    ViewTextFormat,
    DateTimePlusMode,
} from './modes';
import { StringOptionCheckboxFormat, PickUpTimesFormat, StoreLocationFormat } from './formats';
import { BrandImageMode } from '../array/modes';

// 简单封装 registerType 方法，使写法更简单、直观
function registerType(name: string, format: any, mode: any) {
    return builder.registerType(name, new Type(new format(), new mode()));
}

builder.registerType('string.status', new Type(new formats.StringFormat(), new StatusMode()));
builder.registerType('string.radio', new Type(new formats.StringFormat(), new RadioMode()));
builder.registerType(
    'string.options.checkbox',
    new Type(new StringOptionCheckboxFormat(), new StringOptionCheckboxMode())
);
builder.registerType(
    'string.pickUpTimes',
    new Type(new PickUpTimesFormat(), new PickUpTimesMode())
);

builder.registerType(
    'string.textBox',
    new Type(new formats.StringFormat(), new StringTextBoxMode())
);

builder.registerType(
    'string.location.select',
    new Type(new formats.StringFormat(), new LocationSelectMode())
);
builder.registerType(
    'string.store.order',
    new Type(new formats.StringFormat(), new StoreOrderMode())
);
builder.registerType(
    'string.store.location',
    new Type(new StoreLocationFormat(), new StoreLocationMode())
);
builder.registerType(
    'string.store.name',
    new Type(new formats.StringFormat(), new StoreNameMode())
);
builder.registerType(
    'string.treeNode.cascader.single',
    new Type(new formats.StringFormat(), new CascaderShopMode())
);
builder.registerType(
    'array.select.store.brand',
    new Type(new formats.ArrayNoValidationFormat(), new StoreBrandMode())
);
builder.registerType(
    'string.richText.video',
    new Type(new StringFormat(), new StringRichTextVideoMode())
);
builder.registerType(
    'string.option.autoCompleteDefault',
    new Type(new formats.StringFormat(), new AutoCompleteDefaultMode())
);
builder.registerType('string.clickLook', new Type(new formats.StringFormat(), new ClickLookMode()));

builder.registerType('string.converter', new Type(new formats.StringFormat(), new ConverterMode()));
builder.registerType('string.color', new Type(new formats.StringFormat(), new ColorTextMode()));
builder.registerType('string.text.long', new Type(new formats.StringFormat(), new LongTextMode()));
builder.registerType('string.link', new Type(new formats.StringFormat(), new LinkTextMode()));
builder.registerType('string.format', new Type(new formats.StringFormat(), new Array2StringMode()));
registerType('string.image.picker.local', StringImagePickerFormat, BrandImageMode);
builder.registerType(
    'string.options.search',
    new Type(new formats.StringFormat(), new StringOptionAutoCompleteMode())
);
builder.registerType(
    'string.newPassword',
    new Type(new formats.StringFormat(), new NewStringPasswordMode())
);

builder.registerType(
    'string.couponCenterItems',
    new Type(new CouponCenterItemsFormat(), new CouponCenterItemsMode())
);

builder.registerType('string.viewText', new Type(new ViewTextFormat(), new ViewTextMode()));

builder.registerType(
    'string.dateTimePlus',
    new Type(new formats.StringFormat(), new DateTimePlusMode())
);

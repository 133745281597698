import React, { Component, createElement } from 'react';
import { Tabs } from 'antd';
import { get, map } from 'lodash';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { StoreTreeSelector } from '@/components/store-tree-selector';
import { KV } from '@/interfaces';
import { getStoreInfo } from '@/services/utils';
import * as pickingApi from '../api';
import './index.less';
import { API_TYPE } from '../enums';
import { PHILIPS_STORE_TREE_STORAGE_KEY, STORE_TREE_STORAGE_KEY } from '@/constants';

const DEFAULT_ACTIVE_TAB_KEY = 'WAITING_PICK'; // 默认选中的 tab 项

const PICKING_TYPES_MAP: KV = {
    WAITING_PICK: services.language.getText('picking.status.waitPicking'),
    PICKING: services.language.getText('picking.status.picking'),
    STOCKOUT: services.language.getText('picking.status.stockOut'),
    PICKED: services.language.getText('picking.status.picked'),
    PACKED: services.language.getText('picking.status.packed'),
    CANCELLED: services.language.getText('picking.status.cancel'),
    ALL: services.language.getText('picking.status.all'),
};

export class PickingAssistant extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeKey: get(props.params, 'status', DEFAULT_ACTIVE_TAB_KEY),
            time: Date.now(),
            tabStatusCount: {},
        };
    }

    componentDidMount() {
        const status = get(this.props, 'params.status');
        if (!status) {
            this.replaceHistory(this.state.activeKey);
            this.fetchTabStatusCount();
        }
    }

    // 避免报错
    componentWillUnmount() {
        this.setState = () => {};
    }

    replaceHistory(status: string) {
        const { config } = this.props;
        const history = services.navigation.getHistory();
        history.replace({ pathname: config.pathname + status });
    }

    // 获取拣货状态tab项对应的数量
    fetchTabStatusCount = async () => {
        const format = (data: any) => {
            return {
                WAITING_PICK: data.waitPick,
                PICKING: data.picking,
                STOCKOUT: data.lack,
                PICKED: data.picked,
                PACKED: data.packed,
                CANCELLED: data.cancelled,
                ALL: data.all,
            };
        };
        /**
         * 根据渲染的type取不同的存储key
         */
        const storeName =
            this.props.config.apiType === API_TYPE.ORDER_PHILIPS
                ? PHILIPS_STORE_TREE_STORAGE_KEY
                : STORE_TREE_STORAGE_KEY;

        const storeId = getStoreInfo(storeName)?.id;

        if (storeId) {
            const result = await pickingApi.fetchTabStatusCount(storeId, this.props.config.apiType);
            const data = format(result);
            this.setState({ tabStatusCount: data });
        }
    };

    createTabPanes = () => {
        const { tabStatusCount, activeKey, time } = this.state;
        const { items } = this.props;
        /**
         * 根据渲染的type取不同的存储key
         */
        const storeName =
            this.props.config.apiType === API_TYPE.ORDER_PHILIPS
                ? PHILIPS_STORE_TREE_STORAGE_KEY
                : STORE_TREE_STORAGE_KEY;
        return map(items, (item) => {
            const { name, component, statusKey } = item;

            const tabCount = get(tabStatusCount, name, 0);
            const tab = `${PICKING_TYPES_MAP[name]} (${tabCount})`;
            const content = createElement(ComponentsManager.get(component), {
                time,
                isActive: activeKey === name,
                refresh: this.refreshData,
                params: {
                    [statusKey]: name, // 拣货维度 statusKey = status, 订单维度 statusKey = queryType
                    onlineStoreId: getStoreInfo(storeName)?.id,
                },
            });
            return (
                <Tabs.TabPane tab={tab} key={name}>
                    {content}
                </Tabs.TabPane>
            );
        });
    };

    onTabChange = (activeKey: string) => {
        this.setState({ activeKey });
        this.replaceHistory(activeKey);
        this.fetchTabStatusCount();
    };

    /**
     * 更新tab数据
     * @params flag 是否需要触发列表数据更新
     */
    refreshData = (flag: boolean = false) => {
        this.fetchTabStatusCount();
        if (flag) {
            this.setState({ time: Date.now() });
        }
    };

    render() {
        const { activeKey } = this.state;
        const { config } = this.props;
        return (
            <div className='product-shelf-list'>
                <div className='product-shelf-header' style={{ position: 'relative' }}>
                    <StoreTreeSelector
                        checkable={false}
                        selectable={true}
                        params={config.treeType ? { treeType: config.treeType } : {}}
                        onChange={() => {
                            this.refreshData();
                        }}
                        storageKey={
                            config.apiType === API_TYPE.ORDER_PHILIPS
                                ? PHILIPS_STORE_TREE_STORAGE_KEY
                                : STORE_TREE_STORAGE_KEY
                        }
                    />
                </div>
                <div className='product-shelf-body'>
                    <Tabs activeKey={activeKey} animated={false} onChange={this.onTabChange}>
                        {this.createTabPanes()}
                    </Tabs>
                </div>
            </div>
        );
    }
}

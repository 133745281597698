import React, { useState } from 'react';
import { Modal as AntModal, Button as AntButton } from 'antd';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';

export const SubmitFreightRefund: React.FC<AnyObject> = (props) => {
    const { id, parentProps, config } = props;
    const [loading, setLoading] = useState(false);

    async function confirm() {
        try {
            setLoading(true);
            await services.api.put(
                { id },
                {
                    apiPath: formatUrl(API_MAP.SUBMIT_FREIGHT_REFUND, config.type),
                }
            );

            parentProps.entity.search();
            parentProps.fetchStatistics();
        } catch (e) {
            errorHandle(e as ErrorInfo);
        } finally {
            setLoading(false);
        }
    }

    function waitConfirm() {
        AntModal.confirm({
            title: language.getText('order.submitFreightRefund.title'),
            content: language.getText('order.submitFreightRefund.content'),
            okText: language.getText('confirm'),
            cancelText: language.getText('cancel'),
            okButtonProps: {
                loading,
            },
            onOk: confirm,
        });
    }

    return (
        <AntButton type='primary' onClick={waitConfirm}>
            {language.getText('order.submitFreightRefund.button')}
        </AntButton>
    );
};

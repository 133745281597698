import React, { useState } from 'react';
import { Modal as AntModal, Button as AntButton, Icon } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';
import { Image } from '@comall-backend-builder/components-basis';
import Viewer from 'react-viewer';
import { services } from '@comall-backend-builder/core';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';

import './index.less';

export const ShowPaymentVoucher: React.FC = (props: any) => {
    const { parentProps, parentOrderNumber } = props;
    const [visible, setVisible] = useState(false);
    const [imgView, setImgView] = useState(false);
    const [imgUrl, setImgUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = async () => {
        setLoading(true);
        try {
            if (!parentOrderNumber) setImgUrl(null);
            else {
                const res: { image: { id: string; path: string } | null } = await services.api.get(
                    { parentOrderNumber },
                    { apiPath: formatUrl(API_MAP.GET_PAYMENT_VOUCHER, parentProps.config.type) }
                );
                if (res.image) {
                    setImgUrl(res.image.path);
                } else {
                    setImgUrl(null);
                }
            }
        } catch (e) {
            setImgUrl(null);
        } finally {
            setLoading(false);
            setVisible(true);
        }
    };

    return (
        <>
            <AntButton onClick={handleClick} loading={loading}>
                {language.getText('order.viewProof')}
            </AntButton>
            <AntModal
                className='order-list-payment-voucher'
                closable={false}
                visible={visible}
                title={language.getText('order.paymentDocument')}
                footer={
                    <AntButton onClick={() => setVisible(false)}>
                        {language.getText('cancel')}
                    </AntButton>
                }
            >
                <div className='payment-voucher-info'>
                    <div className='payment-voucher-conclude-amount'>
                        <span>{language.getText('order.concludeAmount')}：</span>
                        <span>{props.concludeAmount}</span>
                    </div>
                    <div className='payment-voucher-paid-pay-amount'>
                        <span>{language.getText('order.paidPayAmount')}：</span>
                        <span>{props.payableAmount}</span>
                    </div>
                </div>
                <div className='payment-voucher-preview-item'>
                    <div
                        onClick={() => setImgView(true)}
                        className='payment-voucher-preview-item-inner'
                    >
                        {imgUrl && <Image imageurl={imgUrl} />}
                        <Icon type='eye' />
                    </div>
                </div>
                {imgUrl && (
                    <Viewer
                        defaultScale={3}
                        visible={imgView}
                        images={[{ src: imgUrl, alt: '' }]}
                        onClose={() => setImgView(false)}
                        /** 是否显示变换按钮 */
                        scalable={false}
                        /** 是否显示旋转按钮 */
                        rotatable={false}
                        attribute={false}
                        noNavbar={true}
                    />
                )}
            </AntModal>
        </>
    );
};

import React, { PureComponent, createElement } from 'react';
import { InputNumber, Modal, Form, message } from 'antd';
import { get } from 'lodash';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { ComponentsManager } from '@comall-backend-builder/core';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';

const MIN = 1;
const MAX = 99;

interface Props {
    className: string;
    entity: Entity;
    row: KV;
    text: string;
}

interface State {
    discountNumber: number;
    visible: boolean;
    loading: boolean;
}

export class GuideShoppingDiscountTableActionEdit extends PureComponent<Props, State> {
    state = {
        discountNumber: get(this.props, 'row.discountNumber') || MIN,
        visible: false,
        loading: false,
    };

    toggleModal = (visible: boolean) => {
        this.setState({ visible });
    };

    onChange = (discountNumber: any) => {
        this.setState({
            discountNumber: discountNumber || MIN,
        });
    };

    onConfirm = () => {
        const { entity, row } = this.props;
        const { discountNumber } = this.state;
        if (discountNumber >= MIN && discountNumber <= MAX) {
            this.setState({ loading: true });
            api.put(
                {
                    discountNumber,
                },
                {
                    apiPath: `/dc-promotion/admin/custom/discount/discountNumber/${row.id}`,
                }
            )
                .then(() => {
                    entity.search(entity.params);
                    this.toggleModal(false);
                })
                .catch(errorHandle)
                .finally(() => {
                    this.setState({ loading: false });
                });
        } else {
            message.error('Input Illegal');
        }
    };

    render() {
        const { discountNumber, visible, loading } = this.state;
        let { className, entity, text, row } = this.props;
        const props = {
            className,
            entity,
            text,
            type: 'link',
            onClick: this.toggleModal.bind(this, true),
        };
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        return (
            <>
                {createElement(ComponentsManager.get('Button'), props)}
                <Modal
                    title={language.getText('promotion.guideShoppingDiscount.editDiscountNumber')}
                    width={600}
                    visible={visible}
                    onCancel={this.toggleModal.bind(this, false)}
                    onOk={this.onConfirm}
                    okButtonProps={{
                        loading,
                    }}
                >
                    <Form>
                        <Form.Item
                            {...formItemLayout}
                            label={language.getText('promotion.guideShoppingDiscount.categoryName')}
                        >
                            {row.offlineCategoryName}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={language.getText(
                                'promotion.guideShoppingDiscount.discountNumberLimit'
                            )}
                        >
                            <InputNumber
                                min={MIN}
                                max={MAX}
                                maxLength={3}
                                precision={0}
                                style={{ width: 200 }}
                                value={discountNumber}
                                formatter={(value) => `${value}%`}
                                parser={(value) => (value ? value.replace('%', '') : '')}
                                onChange={this.onChange}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

/*
 * @Author: 朱璐
 * @Date: 2022-10-20 11:41:18
 * @Description: 线上门店列表选择
 */

import * as React from 'react';
import { Popover, Tag } from 'antd';
import { modes } from '@comall-backend-builder/types';
import { Transfer } from '@comall-backend-builder/components-basis';

const TransformOptions = React.forwardRef<
    AnyObject,
    {
        nameProp?: string;
        valueProp?: string;
        options?: AnyObject[];
        value?: AnyObject;
        onChange?(option: AnyObject): void;
        children(options: AnyObject[], restProps: AnyObject): React.ReactElement;
    }
>((props) => {
    const {
        nameProp = 'name',
        valueProp = 'id',
        options: originalOptions = [],
        children,
        // Form.Item 传入的 props 需要透传给下级表单组件
        ...restProps
    } = props;

    const options = React.useMemo(
        () =>
            originalOptions.map((option) => ({
                ...option,
                id: option[valueProp],
                name: option[nameProp],
            })),
        [nameProp, valueProp, originalOptions]
    );

    return children(options, restProps);
});

export class ArrayOnlineStoreGroupMode extends modes.ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { style } = displayInfo;

        if (array && array.length) {
            return (
                <div style={style}>
                    {array[0]['onlineStoreName'] || array[0]['name']}{' '}
                    <Popover
                        placement={'right'}
                        content={
                            <div style={{ maxWidth: 400 }}>
                                {array.map((i: any) => (
                                    <Tag key={i.id} style={{ marginBottom: 10 }}>
                                        {i.onlineStoreName || i.name}
                                    </Tag>
                                ))}
                            </div>
                        }
                    >
                        <span style={{ color: '#29d' }}>共{array.length}个门店</span>
                    </Popover>
                </div>
            );
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        const { nameProp, valueProp, options, ...restControlInfo } = controlInfo;

        return (
            <TransformOptions nameProp={nameProp} valueProp={valueProp} options={options}>
                {(options, restProps) => (
                    <Transfer {...restControlInfo} options={options} {...restProps} />
                )}
            </TransformOptions>
        );
    }
}

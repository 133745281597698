import { services } from '@comall-backend-builder/core';
import lodashGet from 'lodash/get';
export default [
    {
        key: '/dc-user/admin/users',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, config);
                return response;
            },
            post: async function(data: any, config: any) {
                const response: any = await services.api.post(
                    { ...data, userName: data.email, organizationIds: [] },
                    config
                );
                return response;
            },
        },
    },
    {
        key: '/dc-user/admin/users/options',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(
                    { ...data, keyword: data.name },
                    {
                        ...config,
                        apiPath: '/dc-user/admin/users',
                    }
                );
                return (response.result || []).map((item: any) => ({
                    ...item,
                    name: item.realName,
                }));
            },
        },
    },
    {
        key: '/dc-user/admin/userJob',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, config);

                response.result = (response.result || []).map((item: any) => ({
                    ...item,
                    userJob: lodashGet(item, 'userJob.key', '') + '',
                }));

                return response;
            },
            put: async function(data: any, config: any) {
                return await services.api.put(
                    { ...data, id: data.id },
                    {
                        ...config,
                        apiPath: '/dc-user/admin/userJob',
                    }
                );
            },
        },
    },
];

import React, { PureComponent, Fragment } from 'react';
import { Button, Modal, Form, DatePicker, Select, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import moment from 'moment';
import { findIndex, get as lodashGet, find } from 'lodash';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';
const { Option } = Select;
const { api } = services;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
interface IDateTime {
    date: string;
    time: Array<ITime>;
}
interface IWeekItem {
    week: string;
    storeChannelPickTimeVos: ITime;
}
interface ITime {
    id: string;
    time: string;
}
function fetchPickTime(id: string): Promise<Array<IDateTime>> {
    return api.get(
        {},
        {
            apiPath: `/dc-store/admin/pickOnlineStore/${id}/storePickTimes`,
        }
    );
}
class pickupDate extends PureComponent<any, any> {
    state = {
        ModalText: 'Content of the modal',
        visible: false,
        btnLoading: false,
        confirmLoading: false,
        date: null,
        dateTime: [],
        timeRange: [],
        pickDateEnd: '',
        pickWeek: [],
    };

    showModal = () => {
        const { id } = this.props;
        this.setState({ btnLoading: true });
        fetchPickTime(id)
            .then((response) => {
                const date = lodashGet(response, 'pickOnlineStorePickDates', []).map(
                    (item: IDateTime) => item.date
                );

                const dateTime = lodashGet(response, 'pickOnlineStorePickDates', []);
                const pickWeek = lodashGet(response, 'pickWeeks', []);
                const pickDateEnd = lodashGet(response, 'pickDateEnd', []);

                this.setState({
                    date,
                    dateTime,
                    pickWeek,
                    pickDateEnd,
                    visible: true,
                });
            })
            .catch(services.errorHandle)
            .finally(() => {
                this.setState({ btnLoading: false });
            });
    };

    handleOk = () => {
        const { orderId, type } = this.props;
        this.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });
                api.put(
                    {
                        preStartDeliveryTime: values['pickDate'].format('YYYY-MM-DD'),
                        storeChannelPickId: values['storePickId'],
                        orderId,
                    },
                    { apiPath: formatUrl(API_MAP.ORDER_PICK_UP_TIME, type) }
                )
                    .then(() => {
                        message.success(services.language.getText('modifyFully'));
                        this.props.form.resetFields();
                        const { entity, params } = this.props;
                        entity.search(params);
                    })
                    .catch(services.errorHandle)
                    .finally(() => {
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                    });
            }
        });
    };

    handleCancel = () => {
        const { form } = this.props;
        form.resetFields();
        this.setState({
            visible: false,
        });
    };
    dateChange = (value: moment.Moment | null, dateString: string) => {
        const { form } = this.props;
        const timeRange = this.getTime(dateString);
        form.resetFields();
        this.setState({ timeRange });
    };

    getTime = (pickDate: string, params?: Object) => {
        //@ts-ignore
        const { date, dateTime, pickWeek, pickDateEnd } = params ? params : this.state;
        // 日期在14天日期内么
        //@ts-ignore
        if (date.indexOf(pickDate) > -1) {
            const timeIndex = findIndex(date, (item) => item === pickDate);
            const timeRange = lodashGet(dateTime[timeIndex], 'pickTimes', []);

            return timeRange;
        } else if (moment(pickDate).isSameOrAfter(pickDateEnd)) {
            const currentPickWeek = find(
                pickWeek,
                (item: IWeekItem) =>
                    item.week ===
                    moment(pickDate)
                        .weekday()
                        .toString()
            );

            return currentPickWeek ? currentPickWeek.weekPickTimes : [];
        }
    };
    render() {
        const {
            visible,
            confirmLoading,
            date = [],
            timeRange = [],
            btnLoading,
            pickDateEnd,
        } = this.state;
        const {
            form: { getFieldDecorator },
        } = this.props;

        const disabledDate = (current: moment.Moment | null) => {
            //@ts-ignore
            const reg1 = date && date.indexOf(moment(current).format('YYYY-MM-DD')) < 0;
            return !!(current && !moment(current).isSameOrAfter(pickDateEnd) && reg1);
        };
        return (
            <Fragment>
                <Button
                    style={{ marginRight: '15px' }}
                    onClick={this.showModal}
                    loading={btnLoading}
                >
                    {services.language.getText('order.pickUpDateButton')}
                </Button>
                <Modal
                    title={services.language.getText('order.pickUpDateButton')}
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <Form {...formItemLayout}>
                        <Form.Item label={services.language.getText('order.pickDateLabel')}>
                            {getFieldDecorator('pickDate', {
                                rules: [
                                    {
                                        required: true,
                                        message: services.language.getText('order.pickDate'),
                                    },
                                ],
                            })(
                                <DatePicker
                                    // defaultValue={moment('2015-06-06', dateFormat)}
                                    placeholder={services.language.getText('order.pickDate')}
                                    disabledDate={disabledDate}
                                    onChange={this.dateChange}
                                    format={'YYYY-MM-DD'}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={services.language.getText('order.pickTimeLabel')}>
                            {getFieldDecorator('storePickId', {
                                rules: [
                                    {
                                        required: true,
                                        message: services.language.getText('order.pickTime'),
                                    },
                                ],
                            })(
                                <Select placeholder={services.language.getText('order.pickTime')}>
                                    {timeRange.map((time: ITime) => (
                                        <Option key={time.id}>{time.time}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export const PickupDate = Form.create()(pickupDate);

import { formats } from '@comall-backend-builder/types/lib';
import { each } from 'lodash';
import { services } from '@comall-backend-builder/core';
let Week = {
    MONDAY: '1',
    TUESDAY: '2',
    WEDNESDAY: '3',
    THURSDAY: '4',
    FRIDAY: '5',
    SATURDAY: '6',
    SUNDAY: '7',
};

export class MacauTimeRangeFormat extends formats.ArrayFormat {
    validate(rule: any, value: any, callback: any) {
        if (value && value.length >= 1) {
            validateTimeRanges(value, callback);
        } else {
            callback();
        }
    }
}

function validateTimeRanges(value: any, callback: any) {
    let isCross = false;

    each(value, (timeRange, index) => {
        let startWeek: keyof typeof Week = timeRange.startWeek;
        let endWeek: keyof typeof Week = timeRange.endWeek;
        if (Week[startWeek] > Week[endWeek]) {
            callback(services.language.getText('store.timeRangeError'));
            isCross = true;
            return false;
        }
    });
    if (!isCross) {
        callback();
    }
}

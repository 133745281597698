import { formats } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core/lib';

export class DeliveryTimeFormat extends formats.ObjectFormat {
    validate(_rule: any, value: any, callback: any): void {
        if (!value || value.time === null || value === '') {
            callback(
                new Error(services.language.getText('storeChannels.earliestDeliveryTimeError'))
            );
            return;
        } else {
            callback();
        }
    }
}

/**
 * 商品评价管理
 */

import { cloneDeep } from 'lodash';
import { Config } from '@comall-backend-builder/core/lib/parser';
// import { services } from '@comall-backend-builder/core';
import { flexibleFilterForm, getLanguageProperties } from '../../mixin';

const entityProperties = {
    skuNameMap: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'commodityEvaluation.productName') },
    },
    sku: {
        type: 'string',
        displayName: 'SKU',
    },
    content: {
        type: 'string',
        displayName: '<<commodityEvaluation.contentReview>>',
    },
    grade: {
        type: 'string',
        displayName: '<<commodityEvaluation.score>>',
    },
    evaluatorNumber: {
        type: 'string.converter',
        displayName: '<<commodityEvaluation.reviewer>>',
    },
    orderNumber: {
        type: 'string',
        displayName: '<<commodityEvaluation.associatedOrder>>',
    },
    createTime: {
        type: 'string',
        displayName: '<<commodityEvaluation.evaluationTime>>',
    },
};
const entityFilters = {
    evaluatorNumber: {
        type: 'string',
        displayName: '<<commodityEvaluation.reviewer>>',
    },
    sku: {
        type: 'string',
        displayName: '<<commodityEvaluation.productReview>>',
    },
    orderNumber: {
        type: 'string',
        displayName: '<<commodityEvaluation.associatedOrderNum>>',
    },
};

export const config: Config = {
    entities: {
        commentsNotModerated: {
            apiPath: '/loader/dc-goods/admin/sku_evaluation/list',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
        commentsModerated: {
            apiPath: '/loader/dc-goods/admin/sku_evaluation/list',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
        failedToPass: {
            apiPath: '/loader/dc-goods/admin/sku_evaluation/list',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
    },
    components: {
        commodityEvaluationContainer: {
            component: 'Viewport',
        },
        commodityEvaluationPage: {
            component: 'Card',
            entities: [
                { name: 'commentsNotModerated', entityName: 'commentsNotModerated' },
                { name: 'commentsModerated', entityName: 'commentsModerated' },
                { name: 'failedToPass', entityName: 'failedToPass' },
            ],
            content: {
                component: 'EvaluationList',
                filterConfig: {
                    component: 'commodityEvaluationFilter',
                },
            },
        },
        commodityEvaluationFilter: {
            ...flexibleFilterForm,
            fields: [
                {
                    property: 'evaluatorNumber',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<commodityEvaluation.placeholder.evaluatorNumber>>',
                    },
                },
                {
                    property: 'sku',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<commodityEvaluation.placeholder.sku>>',
                    },
                },

                {
                    property: 'orderNumber',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderInput>>',
                    },
                },
            ],
        },
    },
};

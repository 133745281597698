import { services } from '@comall-backend-builder/core';

function formatSa(item: any) {
    const enable = item.enable ? '1' : '0';
    const thirdFactorStatus = item.thirdFactorStatus ? 'active' : 'inactive';
    return { enable, thirdFactorStatus };
}
export default [
    {
        key: '/dc-user/guide_shopping/sa',
        loader: {
            get: async function(data: any, config: any) {
                let res: any;
                if (!data.id) {
                    if (data.createTime) {
                        data.createBeginTime = data.createTime.start;
                        data.createEndTime = data.createTime.end;
                        delete data.createTime;
                    }
                    if (data.lastLoginTime) {
                        data.lastLoginBeginTime = data.lastLoginTime.start;
                        data.lastLoginEndTime = data.lastLoginTime.end;
                        delete data.lastLoginTime;
                    }
                    if (data.active) {
                        data.active = data.active === '1' ? true : false;
                    }
                    res = await services.api.get(data, config);
                    res.result = res.result.map((item: any) => {
                        return { ...item, ...formatSa(item) };
                    });
                } else {
                    res = await services.api.get(data, config);
                    res = { ...res, ...formatSa(res), password: '******' };
                }
                return res;
            },
            post: async function(data: any, config: any) {
                data.enable = data.enable === '1' ? true : false;
                data.nickName = data.nickName.trim();
                return services.api.post(data, config);
            },
            put: async function(data: any, config: any) {
                data.enable = data.enable === '1' ? true : false;
                data.nickName = data.nickName.trim();
                if (!data.posId) {
                    delete data.posId;
                }
                if (data.password === '******') {
                    delete data.password;
                }
                return services.api.put(data, config);
            },
        },
    },
];

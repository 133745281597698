import React, { Component } from 'react';
import { InputNumber } from 'antd';
import './index.less';
import { forEach } from 'lodash';

export class NumberInputTip extends Component<any, any> {
    render() {
        const { addonafter, addonbefore, tip, rules, style } = this.props;
        let ruleInfo = {};
        if (rules && rules.length) {
            forEach(rules, (item) => {
                Object.assign(ruleInfo, item);
            });
        }
        return (
            <div className='number-input-tip'>
                <span className='ant-input-group-wrapper' style={style}>
                    <span className='ant-input-wrapper ant-input-group'>
                        {addonbefore && (
                            <span className='ant-input-group-addon'>{addonbefore}</span>
                        )}
                        <InputNumber {...ruleInfo} {...this.props} />
                        {addonafter && <span className='ant-input-group-addon'>{addonafter}</span>}
                    </span>
                </span>
                {tip && <div style={{ fontSize: 12, color: '#ccc' }}>{tip}</div>}
            </div>
        );
    }
}

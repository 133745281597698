import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import {
    createPageLayout,
    flexibleFilterForm,
    tableMixin,
    getLanguageProperties,
    getLanguageColumns,
    deletePopConfirmConfig,
} from '@/configs/mixin';
import { IMPORT_TEMPLATE_URL, SPECS_TYPE_OPTIONS } from '@/constants';
import { MEMBER_PRICE } from '@/configs/pro-table-constants';
import { recordImportLogs } from '@/services';

/**
 * 会员等级会员价商品
 */
export const config: Config = {
    entities: {
        memberPriceProduct: {
            apiPath: '/loader/dc-price/admin/memberLevel/priceProduct',
            properties: {
                id: { type: 'string' },
                productLanguageName: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties(
                            'string',
                            'membershipLevel.blackSkuProduct.productName'
                        ),
                    },
                },
                csku: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.SKU>>',
                },
                memberPrice: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.memberPrice>>',
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productSpecsType.title>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.lastedModifyTime>>',
                },
                lastedModifyUserRealName: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.lastedModifyUser>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<keyword>>',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<membershipLevel.blackSkuProduct.productName>>',
                    },
                },
                csku: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.SKU>>',
                    controlConfig: {
                        allowClear: true,
                    },
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productSpecsType.title>>',
                    options: SPECS_TYPE_OPTIONS,
                },
            },
        },
    },
    components: {
        memberPriceProductList: {
            entity: 'memberPriceProduct',
            ...createPageLayout([
                {
                    component: 'memberPriceProductListFilter',
                    style: { marginBottom: 15 },
                    privilege: {
                        path: 'member.member_level_view.member_prices',
                        level: 'full',
                    },
                },
                {
                    component: 'memberPriceProductListAction',
                    style: { marginBottom: 15 },
                },
                {
                    privilege: {
                        path: 'member.member_level_view.member_prices',
                        level: 'full',
                    },
                    component: 'memberPriceProductListTable',
                },
            ]),
        },
        memberPriceProductListFilter: {
            ...flexibleFilterForm,
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membershipLevel.blackSkuProduct.productName>>',
                    },
                },
                {
                    property: 'csku',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membershipLevel.blackSkuProduct.skuPlaceholder>>',
                    },
                },
                {
                    property: 'specsType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                    },
                },
            ],
        },
        memberPriceProductListAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<membershipLevel.importPrice>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        const id = window.location.hash.replace(
                            '#/member-level/price-product-list/',
                            ''
                        );
                        if (response && id) {
                            recordImportLogs({
                                name: services.language.getText('membershipLevel.importPrice'),
                                apiPath: `/dc-price/admin/member_prices/import`,
                                params: { resourceId: response.id, requiredMemberLevel: id },
                            });
                        }
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'member.member_level_view.member_prices_import',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productInventory.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 10,
                        alignSelf: 'center',
                    },
                    privilege: {
                        path: 'member.member_level_view.member_prices_import',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.MEMBER_PRICE}`,
                },
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<membershipLevel.exportPrice>>',
                    icon: 'download',
                    style: {
                        marginLeft: 15,
                    },
                    source: {
                        apiPath: `/dc-price/admin/member_prices/export/take`,
                    },
                    range: {
                        apiPath: '/dc-price/admin/member_prices/export/count',
                        perPage: 100000,
                    },
                    formatter: (params: any) => {
                        return params;
                    },
                    paramNameAs: {
                        id: 'requiredMemberLevel',
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: `member.member_level_view.ex_member_level_price`,
                        level: 'full',
                    },
                },
            ],
        },
        memberPriceProductListTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: MEMBER_PRICE,
            columns: [
                ...getLanguageColumns('productLanguageName'),
                { property: 'csku' },
                { property: 'specsType' },
                { property: 'memberPrice' },
                { property: 'lastedModifyTime' },
                { property: 'lastedModifyUserRealName' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        text: '<<edit>>',
                        component: 'TableMemberPriceEditForm',
                        privilege: {
                            path: 'member.member_level_view.member_prices_edit',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        text: '<<delete>>',
                        component: 'TableActionDelete',
                        config: {
                            ...deletePopConfirmConfig,
                            apiPath: '/dc-price/admin/member_prices/{{row.id}}',
                        },
                        privilege: {
                            path: 'member.member_level_view.member_prices_deleted',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

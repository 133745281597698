import React, { useCallback, useRef, useState } from 'react';
import { Modal as AntModal, Icon, Carousel } from 'antd';
import { fileToObject, getFileItem, removeFileItem } from 'antd/lib/upload/utils';
import RcUpload from 'rc-upload';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { services } from '@comall-backend-builder/core';
import { get, isArray, isEmpty, isObject, isString, uniqueId } from 'lodash';
import { KV } from '@/interfaces';
import { UploadItem } from './item';
import { limitImg, limitVideo } from './utils';

import './index.less';
import { useMemoizedFn, useMount, useUpdateEffect } from 'ahooks';

const prefixCls = 'cm-upload-image-video';
const prefixCommonCls = 'cm-upload-image-video-common';

export type UploadType = 'image' | 'video';

export type FileValue = {
    id: string;
    url: string;
    path?: string;
    video: boolean;
    poster?: string;
};

interface Props {
    uploadType: Array<UploadType>;
    name?: string;
    params?: KV;
    fileName?: string;
    maxCount: number; //总最大可上传数
    img?: {
        fileName: string;
        uploadUrl: string;
        accept?: string;
        maxSize?: number; //上传文件的最大文件大小限制，单位kb
        maxCount?: number; //图片最大可上传数   需小于总最大可上传数
    };
    video?: {
        fileName: string;
        uploadUrl: string; //上传地址
        accept?: string; //
        maxSize?: number; //上传文件的最大文件大小限制，单位kb
        maxCount?: number; //视频最大可上传数   需小于总最大可上传数
        maxDuration?: number; //时长,单位 s
        minDuration?: number; //时长,单位 s
    };
    disabled?: boolean;
    value?: FileValue[] | FileValue;
    showDownLoad?: boolean;
    onChange?: (value: any, name: any) => void;
    onSuccessFileInfo?: (value: { id: string; url: string }) => void;
    beforeUpload?: (file: any, FileList: any[]) => boolean | PromiseLike<void>;
}

const toFile = (data: FileValue) => {
    const itemName = (data.url || data?.path || '').split('/').pop()!;
    return {
        video: data.video,
        url: data.url || data?.path,
        name: itemName,
        status: 'done',
        percent: 100,
        uid: data.id,
        size: 0, // 需要知道当前图片的大小，单位为kb
        type: '', // 需要知道当前图片的类型
        poster: data.poster,
    };
};

const valuesToFiles = (value?: FileValue[] | FileValue | string) => {
    if (value && isString(value))
        return [
            {
                video: '',
                url: value,
                name: '',
                status: 'done',
                percent: 100,
                uid: uniqueId(),
                size: 0, // 需要知道当前图片的大小，单位为kb
                type: '', // 需要知道当前图片的类型
                poster: '',
            },
        ];

    if (value && isArray(value)) {
        return value.map(toFile);
    }
    if (value && isObject(value)) return [toFile(value)];
    return [];
};
const filesToValues = (value: any[]) => {
    return value
        .filter((item) => item.status === 'done')
        .map((item) => {
            return {
                url: item.url || get(item, 'response.url', '') || '',
                id: get(item, 'response.id', '') || item.uid || '',
                video: item.video,
                poster: item.poster || '',
                aspectRatio: item.aspectRatio,
            };
        });
};

const Upload = React.forwardRef((props: Props, _ref) => {
    const { name, maxCount, img, video, uploadType, onChange, disabled, onSuccessFileInfo } = props;
    const [showPreview, setPreview] = useState(false);
    const fileListRef = useRef<any[]>(valuesToFiles(props.value) || []);
    const [fileList, setFileList] = useState<any[]>(fileListRef.current.concat());
    const carouselRef = useRef<any>();
    const previewIndex = useRef(0);

    useUpdateEffect(() => {
        if (fileListRef.current.length > 0) return;
        if (isEmpty(props.value)) return;
        fileListRef.current = valuesToFiles(props.value);
        setFileList(fileListRef.current.concat());
    }, [props.value]);

    useUpdateEffect(() => {
        fileListRef.current = valuesToFiles(props.value);
        setFileList(fileListRef.current.concat());
    }, [name]);

    useMount(() => {
        if (onChange) {
            const value = filesToValues(fileList);

            if (value && value.length > 0) {
                onChange(value, name);
            }
        }
    });

    useUpdateEffect(
        useMemoizedFn(() => {
            if (onChange) {
                const value = filesToValues(fileList);
                onChange(value, name);
            }
        }),
        [name, fileList]
    );

    const onStart = useCallback((file: any, video?: boolean) => {
        const targetItem = fileToObject(file);
        targetItem.status = 'uploading';
        //@ts-ignore;
        targetItem.video = video || false;
        //@ts-ignore;
        targetItem.aspectRatio = file.aspectRatio;
        const nextFileList = fileListRef.current.concat();
        const fileIndex = nextFileList.findIndex(({ uid }: any) => uid === targetItem.uid);
        if (fileIndex === -1) {
            nextFileList.push(targetItem);
        } else {
            nextFileList[fileIndex] = targetItem;
        }
        fileListRef.current = nextFileList;
        setFileList(nextFileList);
    }, []);

    const onProgress = useCallback((e: { percent: number }, file: any) => {
        const targetItem = getFileItem(file, fileListRef.current);
        if (!targetItem) return; //removed
        targetItem.percent = e.percent;
        setFileList(fileListRef.current.concat());
    }, []);

    const onError = useCallback((error: Error, response: any, file: any) => {
        const targetItem = getFileItem(file, fileListRef.current);
        if (!targetItem) return; //removed
        targetItem.error = error;
        targetItem.response = response;
        targetItem.status = 'error';
        setFileList(fileListRef.current.concat());
    }, []);

    const onSuccess = useCallback((response: any, file: any, xhr: any) => {
        try {
            if (typeof response === 'string') {
                response = JSON.parse(response);
            }
        } catch (e) {
            /* do nothing */
        }
        const targetItem = getFileItem(file, fileListRef.current);
        if (!targetItem) return; //removed
        targetItem.status = 'done';
        targetItem.response = response;
        targetItem.xhr = xhr;
        targetItem.url = response.url;
        setFileList(fileListRef.current.concat());
    }, []);

    const beforeUpload = useCallback(
        async (file: any, fileListArgs: any[], isVideo?: boolean) => {
            const { img, video, maxCount } = props;
            //计算视频或图片大小与格式
            return isVideo
                ? await limitVideo(file, fileListArgs, fileListRef.current, {
                      ...video,
                      totalMax: maxCount,
                  })
                : await limitImg(file, fileListArgs, fileListRef.current, {
                      ...img,
                      totalMax: maxCount,
                  });
        },
        [props]
    );

    const customRequest = useCallback(
        async (fileInfo: any, isVideo?: boolean) => {
            const { params, img, video } = props;
            const file = fileInfo.file;
            let data: any = {
                files: file,
                otherParams: params,
            };
            let config: any = {
                progressCallBack: (percent: number) => {
                    fileInfo.onProgress && fileInfo.onProgress({ percent: percent }, file);
                },
                fileName: isVideo
                    ? get(video, 'fileName') || 'fileName'
                    : get(img, 'fileName') || 'fileName',
                apiRoot: isVideo ? get(video, 'uploadUrl') : get(img, 'uploadUrl'),
                apiPath: '',
            };

            try {
                const response = await services.api.upload(data, config);
                fileInfo.onSuccess({ id: response.id, url: response.path }, file);
                onSuccessFileInfo && onSuccessFileInfo({ id: response.id, url: response.path });
            } catch (e) {
                fileInfo.onError(e);
            }
        },
        [props]
    );

    const onDragEnd = useCallback((result: any) => {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = fileListRef.current.splice(startIndex, 1);
        fileListRef.current.splice(endIndex, 0, removed);
        setFileList(fileListRef.current.concat());
    }, []);

    return (
        <div>
            <div className='cm-upload-image-video-wrap'>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        isDropDisabled={fileList.length <= 1 && disabled}
                        droppableId='coupon-list'
                        direction='horizontal'
                    >
                        {(provided: any) => (
                            <div
                                className='cm-upload-image-video-wrap-content'
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {fileList.map((item, index) => (
                                    <Draggable
                                        isDragDisabled={fileList.length <= 1 && disabled}
                                        draggableId={`item-${item.uid}-${item.url}-${index}`}
                                        key={index}
                                        index={index}
                                    >
                                        {(provided: any) => (
                                            <div
                                                key={`${item.uid}-${item.url}-${index}`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <UploadItem
                                                    showDownLoad={
                                                        props.showDownLoad === undefined
                                                            ? true
                                                            : props.showDownLoad
                                                    }
                                                    key={`${item.uid}-${item.url}-${index}`}
                                                    {...item}
                                                    onPreview={() => {
                                                        previewIndex.current = index;
                                                        setPreview(true);
                                                        setTimeout(() => {
                                                            carouselRef.current &&
                                                                carouselRef.current.goTo(
                                                                    index,
                                                                    true
                                                                );
                                                        }, 100);
                                                    }}
                                                    onDelete={() => {
                                                        fileListRef.current =
                                                            removeFileItem(
                                                                item,
                                                                fileListRef.current
                                                            ) || [];
                                                        setFileList(fileListRef.current.concat());
                                                    }}
                                                    onLoadPoster={customRequest}
                                                    onPoster={(file: any) => {
                                                        const targetItem = getFileItem(
                                                            item,
                                                            fileListRef.current
                                                        );
                                                        //@ts-ignore
                                                        targetItem.poster = file.url;
                                                        setFileList(fileListRef.current.concat());
                                                    }}
                                                ></UploadItem>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {fileList.length < maxCount && !disabled && (
                    <div>
                        <div className={classNames(prefixCls, prefixCommonCls)}>
                            <Icon type='plus' />
                            <div className='ant-upload-text'>
                                {services.language.getText('uploadImageVideo.text.upload')}
                            </div>
                            <div className={classNames(`${prefixCommonCls}-info`)}>
                                <div className={classNames(`${prefixCommonCls}-actions`)}>
                                    {uploadType.indexOf('image') !== -1 && (
                                        <RcUpload
                                            onStart={onStart}
                                            onProgress={onProgress}
                                            onError={onError}
                                            onSuccess={onSuccess}
                                            beforeUpload={beforeUpload}
                                            customRequest={customRequest}
                                            multiple={false}
                                            accept={
                                                img ? img.accept : 'image/png,image/jpg,image/svg'
                                            }
                                        >
                                            <span
                                                className={classNames(
                                                    `${prefixCommonCls}-action-icon`
                                                )}
                                            >
                                                <Icon type='picture' />
                                                <span>
                                                    {services.language.getText(
                                                        'uploadImageVideo.text.img'
                                                    )}
                                                </span>
                                            </span>
                                        </RcUpload>
                                    )}
                                    {uploadType.indexOf('video') !== -1 && (
                                        <RcUpload
                                            onStart={(file: any) => {
                                                return onStart(file, true);
                                            }}
                                            onProgress={onProgress}
                                            onError={onError}
                                            onSuccess={onSuccess}
                                            beforeUpload={(file: any, fileListArgs: any[]) => {
                                                return beforeUpload(file, fileListArgs, true);
                                            }}
                                            customRequest={(file: any) => {
                                                customRequest(file, true);
                                            }}
                                            multiple={false}
                                            accept={video ? video.accept : 'video/mp4'}
                                        >
                                            <span
                                                className={classNames(
                                                    `${prefixCommonCls}-action-icon`
                                                )}
                                            >
                                                <Icon type='video-camera' />
                                                <span>
                                                    {services.language.getText(
                                                        'uploadImageVideo.text.video'
                                                    )}
                                                </span>
                                            </span>
                                        </RcUpload>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {maxCount && maxCount > 1 && (
                <div className='cm-upload-image-video-wrap-tip'>
                    {services.interpolate(services.language.getText('uploadImageVideo.tip.limit'), {
                        maxCount,
                    })}
                </div>
            )}
            <AntModal
                visible={showPreview}
                footer={null}
                onCancel={() => {
                    setPreview(false);
                }}
            >
                <div className={classNames(`${prefixCls}-preview`)}>
                    {fileList.length > 1 && (
                        <div
                            onClick={() => {
                                carouselRef.current && carouselRef.current.prev();
                            }}
                        >
                            <Icon type='caret-left' />
                        </div>
                    )}
                    {fileList.length > 1 && (
                        <div
                            onClick={() => {
                                carouselRef.current && carouselRef.current.next();
                            }}
                        >
                            <Icon type='caret-right' />
                        </div>
                    )}
                    <Carousel ref={carouselRef}>
                        {fileList.map((item, index) => (
                            <div
                                className='cm-upload-image-video-wrap-item'
                                key={`${item.uid}-${index}`}
                            >
                                {item.url && !item.video && <img src={item.url} alt={item.name} />}
                                {item.url && item.video && (
                                    <video src={item.url} poster={item.poster || ''} controls />
                                )}
                            </div>
                        ))}
                    </Carousel>
                </div>
            </AntModal>
        </div>
    );
});

Upload.defaultProps = {
    maxCount: 5,
    img: {
        uploadUrl: '',
        fileName: 'fileName',
        accept: 'image/png,image/jpg,image/svg',
        maxSize: 2 * 1024,
    },
    video: {
        uploadUrl: '',
        fileName: 'fileName',
        accept: 'video/mp4',
        maxSize: 20 * 1024,
        maxDuration: 60,
    },
    params: {
        rename: true,
    },
    value: [],
};

export const UploadImageVideo = React.memo(Upload);

import { DISPLAY_LOCATION_VALUES } from '@/constants/popup-ads';

export function buildForm(mode: string) {
    return {
        component: 'SuperForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'field',
                property: 'activityName',
                key: 'activityName',
                maxLength: 100,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'displayTime',
                key: 'displayTime',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'onlineStoreId',
                key: 'onlineStoreId',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'displayLocation',
                key: 'displayLocation',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'brandId',
                key: 'brandId',
                renderable: (values: AnyObject) =>
                    values.displayLocation === DISPLAY_LOCATION_VALUES.SPECIFIC_BRAND,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'categoryId',
                key: 'categoryId',
                renderable: (values: AnyObject) =>
                    values.displayLocation === DISPLAY_LOCATION_VALUES.SPECIFIC_CATEGORY,
                source: {
                    apiPath: '/loader/guide-cms-service/admin/display_categories/categorys_tree',
                    cleanValueOnReload: true,
                    dependencies: [
                        {
                            property: 'onlineStoreId',
                            appendToParams: true,
                        },
                    ],
                },
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'merchantId',
                key: 'merchantId',
                renderable: (values: AnyObject) =>
                    values.displayLocation === DISPLAY_LOCATION_VALUES.SPECIFIC_MERCHANT,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'sendFrequency',
                key: 'sendFrequency',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'priority',
                key: 'priority',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'popupAdPc',
                key: 'popupAdPc',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'popupAdMob',
                key: 'popupAdMob',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'redirectUrl',
                key: 'redirectUrl',
            },
        ],
    };
}

import { API_PREFIX, wrapApiResult } from '@/constants/order/utils';
import { generateEmptySearchResult } from '@/services';
import { services } from '@comall-backend-builder/core/lib';
import { message } from 'antd';

/**
 * 订单管理-订单列表
 */
export default [
    {
        key: '/dc-order/admin/orders',
        loader: {
            get: async function(data: any = {}, config: any) {
                if (data.payType) {
                    data.paymentModeId = data.payType;
                    delete data.payType;
                }
                // notOnlineStoreIds兼容需要判断onlineStoreIds标识
                if (!data.orderNo && !data.onlineStoreIds && !data.notOnlineStoreIds) {
                    message.error(services.language.getText('picking.retailStoreSelectTip'));
                    return new Promise((resolove) => resolove(generateEmptySearchResult()));
                }
                const res = await wrapApiResult(data, config, API_PREFIX.BASE);
                return res;
            },
        },
    },
    {
        key: '/dc-order/admin/orders/:id/traces',
        loader: {
            get: async function(data: any, config: any) {
                return await services.api.get(data, config);
            },
        },
    },
];

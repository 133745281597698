import { createElement, PureComponent } from 'react';
import { mapValues, defaults, forEach, some, isFunction } from 'lodash';
import { connect } from 'react-redux';
import { ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { actions, builder, services } from '@comall-backend-builder/core';

let COMPONENT_UUID: string;

/**
 * 扩展 CreateForm、ModifyForm 组件，支持 field.visible 动态设置field是否展示，onValidate提交前校验
 */
class dataFormPlus extends PureComponent<any> {
    constructor(props: any) {
        super(props);
        if (props.componentId) {
            COMPONENT_UUID = 'DataFormPlus-' + props.componentId;
        } else {
            COMPONENT_UUID = 'DataFormPlus-' + services.uuid();
        }
    }

    inited = false;

    componentWillUnmount() {
        this.props.unmountComponent();
    }

    getConfig() {
        const { fields, fieldValues, ...rest } = this.props;
        let newFields: any[] = [];
        const filterFields = this.inited && some(fields, (i) => i.visible);
        if (filterFields) {
            forEach(fields, (field) => {
                if (field.visible) {
                    if (
                        typeof field.visible === 'function' &&
                        field.visible.call(null, fieldValues || {})
                    ) {
                        newFields.push(field);
                    }
                    if (typeof field.visible === 'boolean' && field.visible) {
                        newFields.push(field);
                    }
                } else {
                    newFields.push(field);
                }
            });
        } else {
            newFields = fields;
        }
        this.inited = true;
        return { ...rest, fields: newFields, componentId: COMPONENT_UUID };
    }

    render() {
        const config = this.getConfig();
        const { formType } = this.props;
        return createElement(ComponentsManager.get(formType), config);
    }
}

function mapStateToProps(_state: any) {
    const fieldValues =
        _state.components[COMPONENT_UUID] && _state.components[COMPONENT_UUID].fields;
    return { fieldValues };
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, entity, params, onValidate } = props;

    return defaults(
        {
            onSubmit,
        },
        {
            onSubmit: (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });

                function commit(): void {
                    const formType = props.formType;
                    if (formType === 'CreateForm') {
                        entity.add(entityFields, params);
                    }
                    if (formType === 'ModifyForm') {
                        entity.modify(entityFields, params);
                    }
                }

                if (entityFields) {
                    // onValidate 不存在时无需校验，提交
                    if (!onValidate) {
                        return commit();
                    }

                    const result = onValidate(entityFields);

                    if (result) {
                        // 校验结果为真且不为 promise，提交
                        if (!isFunction(result.then)) {
                            return commit();
                        }
                        // 校验结果为 promise，且 promise 返回值为真，提交
                        result.then((_result?: boolean) => _result && commit());
                    }
                }
            },
            unmountComponent: () => {
                builder.getStore().dispatch(actions.unmountComponentAction(COMPONENT_UUID));
            },
        }
    );
}
export const DataFormPlus = connect(mapStateToProps, mapDispatchToProps)(dataFormPlus);

import React, { createElement, Component } from 'react';
import { map, get as lodashGet } from 'lodash';
// import { METHOD_GET } from 'comall-backend-builder/lib/services/api';
// import { parser } from 'comall-backend-builder/lib/parser';
import { ComponentsManager, Loader, services } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
interface ChannelCategoryViewProps {
    tabContent: any;
    tabItems?: any;
    // config: any;
    params: any;
    channelApiPath: string;
    showAll: boolean;
    allTitle: string;
    channelFilter: any;
    memorable: boolean;
    entity: Entity;
    cache: boolean;
}
export class ChannelCategoryView extends Component<ChannelCategoryViewProps, any> {
    state = {
        items: [],
    };

    static defaultProps = {
        memorable: false,
        cache: false,
    };

    componentDidMount() {
        const {
            tabItems,
            tabContent,
            // config,
            params,
            channelApiPath,
            showAll,
            allTitle,
            channelFilter, // 过滤函数，用于过滤需要展示的渠道
        } = this.props;
        const channelIds: any = services.localStorage.get('channelIds');
        Loader.load('get', { params, apiPath: channelApiPath }).then((rsp: any) => {
            if (channelFilter && typeof channelFilter === 'function') {
                rsp = rsp.filter(channelFilter);
            }
            let items: any = [];
            if (!rsp || rsp.length === 0) {
                return items;
            }
            map(rsp, (item) => {
                if (channelIds.indexOf(item.id) >= 0) {
                    items.push({
                        title: item.name,
                        params: {
                            channelId: item.id,
                        },
                        content: lodashGet(tabItems, `[${item.id}].content`) || {
                            component: tabContent,
                        },
                    });
                }
            });
            if (showAll) {
                items.unshift({
                    title: allTitle,
                    params: {
                        channelId: undefined,
                    },
                    content: {
                        component: tabContent,
                    },
                });
            }
            this.setState({
                items,
            });
        });
    }

    render() {
        const { entity, params, cache, memorable } = this.props;
        const { items } = this.state;
        const renderComponent = memorable ? 'MemorableTabs' : 'Tabs';
        return (
            <div className={'channel-category-view-container'}>
                {createElement(ComponentsManager.get(renderComponent), {
                    entity,
                    params,
                    items,
                    cache,
                    animated: false,
                })}
            </div>
        );
    }
}

import React, { useState } from 'react';
import { Modal as AntModal, Button as AntButton } from 'antd';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { fetchLinkedOrder } from '../cancel-order-modal';
import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';

export const ReceivedPaymentModal = (props: any) => {
    const { id, parentProps, config } = props;
    const [visible, setVisible] = useState(false);
    const [orderNumberList, setOrderNumberList] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = async () => {
        setLoading(true);
        try {
            const res = await fetchLinkedOrder(id);
            setOrderNumberList(res.map((item) => item.outerOrderNumber));
        } catch (e) {
            setOrderNumberList([]);
        } finally {
            setLoading(false);
            setVisible(true);
        }
    };

    const handleOk = () => {
        setVisible(false);
        const { entity } = parentProps;
        services.api
            .put({ id }, { apiPath: formatUrl(API_MAP.OFFLINE_PAYMENT_PAID, config.type) })
            .then(() => {
                entity.search(entity.params);
                parentProps.fetchStatistics();
            })
            .catch(errorHandle);
    };

    return (
        <>
            <AntButton onClick={handleClick} type='primary' loading={loading}>
                {language.getText('order.receivedPay')}
            </AntButton>
            <AntModal
                onOk={handleOk}
                onCancel={() => setVisible(false)}
                closable={false}
                visible={visible}
                title={language.getText('order.receivedTitle')}
            >
                <div style={{ fontSize: '16px', fontWeight: '500' }}>
                    {language.getText('order.receivedContent')}
                </div>
                {!isEmpty(orderNumberList) && (
                    <>
                        <div style={{ fontSize: '16px', fontWeight: '500', marginTop: '10px' }}>
                            {language.getText('order.orderContent')}
                        </div>
                        {orderNumberList.map((item) => (
                            <div style={{ marginTop: '10px' }} key={item}>
                                {item}
                            </div>
                        ))}
                    </>
                )}
            </AntModal>
        </>
    );
};

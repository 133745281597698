import { Config } from '@comall-backend-builder/core/lib/parser';
import { filterProperties } from '@/constants/order/properties';
import { PATH_NAME, ORDER_TYPE } from '@/constants/order/enum';

export const config: Config = {
    entities: {
        orderList: {
            apiPath: '/loader/dc-order/admin/orders',
            filters: filterProperties,
        },
    },
    components: {
        OrdersContainer: {
            component: 'Viewport',
            entity: 'orderList',
        },
        OrdersManagement: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OrderTabsFilter',
                    frequency: 30,
                    config: {
                        pathname: PATH_NAME.ORDER_PATH_NAME,
                        type: ORDER_TYPE.BASE,
                    },
                },
            ],
        },
        // OrderListPane: {
        //     component: 'DataList',
        //     loadFirstPage: true,
        //     className: 'dc-order-list order-receiving',
        //     split: false,
        //     pagination: paginationMixin,
        //     renderItem: 'OrderDataListItem',
        //     config: {
        //         pathname: '/orders/',
        //     },
        // },
    },
};

import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';

export default [
    {
        key: '/dc-master-data/admin/homeBrand',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, {
                    ...config,
                    apiPath: `${config.apiPath}`,
                });
                if (!isEmpty(response.result)) {
                    response.result = response.result.map((item: any) => ({
                        status: item.enable ? 1 : 0,
                        ...item,
                    }));
                } else {
                    response.result = [];
                }
                return response;
            },
        },
    },
];

/*
 * @Author: zhulu
 * @Date: 2022-09-20 14:47:41
 * @Description: configs
 */

export * from './mixin';

export { config as routes } from './routes';
export { config as home } from './home';

//门店及渠道管理
export {
    storeConfig,
    addStoreConfig,
    editStoreConfig,
    onlineStoreConfig,
    editOnlineStoreConfig,
    pickOnlineStore,
    updatePickOnlineStore,
    retailStoreConfig,
    merchantsStore,
    editMerchantsStoreConfig,
} from './store';

//品类管理
export {
    offlineCategory,
    brandsChannels,
    brandsInfo,
    brandsEdit,
    virtualCategory,
    displayCategory,
    categoryProduct,
} from './brand-category';

//商品管理
export {
    productListConfig,
    productInventoryMonitoringConfig,
    productInventoryConfig,
    productPrice,
    productPriceMonitoring,
    productGroup,
    productFormConfig,
    productShelfConfig,
    productSuiteConfig,
    productSelectionConfig,
    ProductSEOConfig,
    ProductStyleConfig,
    productMapConfig,
    recommendProduct,
    productPickupLimitConfig,
} from './product';

//配送管理
export {
    storeFreightRulesConfig,
    storeFreightRulesEditConfig,
    merchantFreightRulesConfig,
    merchantFreightRulesEditConfig,
} from './distribution';

//交易管理
export {
    orderInfo,
    orders,
    EcdepositConfig,
    paymentDetail,
    orderCancel,
    // 自提收货
    selfHarverstingConfig,
} from './order';

//拣货管理
export { pickingAssistantConfig, pickingAssistantDetailConfig } from './picking';

//售后管理
export {
    afterSaleOrders,
    afterSaleOrdersInfo,
    RefundOrder,
    afterSaleOrdersCreate,
} from './after-sale';

//会员管理
export {
    membershipList,
    membershipDetail,
    membershipLevelList,
    membershipLevelEdit,
    memberLevelMember,
    memberLevelBlackProductList,
    memberLevelPriceProductList,
    memberExamine,
    membershipSource,
    membershipSourceEdit,
    MembershipSourceBlackProduct,
} from './membership';

//系统管理
export {
    organizations,
    channels,
    channelContacts,
    EmailMessageManagement,
    UrlMappingManagementConfig,
    PayManagementConfig,
    PaymentEditConfig,
} from './system';

//权限管理
export { usersConfig, rolesConfig } from './privilege';

//日志管理
export { importRecord, exportFiles } from './record';

//负债报表
export { exportLiabilityConfig } from './liability';

//重发sales
export { resendSalesConfig, resendBlConfig } from './operation';

//报表管理
export {
    orderShipping,
    reportConfig,
    orderSummary,
    orderDetail,
    orderDetailUnPrice,
    refundSummary,
    voucher,
    productMapReport,
} from './report';

//优惠券
export { couponConfig, couponRuleConfig } from './coupon';

//三方对接
export {
    thirdPartyManagementConfig,
    thirdPartyAccountConfig,
    thirdInterfaceLogsConfig,
    thirdPartyWarehouseConfig,
    thirdPartyDeliveryConfig,
    thirdPartySyncConfig,
} from './third-party';

export {
    SearchWordsManageConfig,
    HotWordsManageConfig,
    guessYouLikeSettingConfig,
    MenuBarManagementConfig,
    NoticeBarManagementConfig,
    RecommendedBrandConfig,
    PopupAdsConfig,
} from './design-manage';
export { homeManageConfig, topicManageConfig } from './virtual-design';

//线下业务管理
export {
    overrideCodeConfig,
    salesclerkConfig,
    offlineOrders,
    offlineOrderInfo,
    offlineOrdersCreate,
} from './offline';
// 卡券管理
export {
    cashCouponConfig,
    discountCouponConfig,
    discountCouponDataConfig,
    freightCouponConfig,
    freightCouponDataConfig,
    thirdPartyCouponConfig,
    voucherManagementConfig,
    voucherManagementDataConfig,
    couponCenterConfig,
    couponCenterDataConfig,
    couponPackageConfig,
} from './coupon-manage';
// 商品限购管理
export { orderLimitManagementConfig } from './product';
// 飞利浦拣货管理
export {
    philipsPickingAssistantConfig,
    philipsPickingAssistantDetailConfig,
} from './picking-philips/index';

// 飞利浦订单管理
export { philipsOrderConfig, philipsOrderInfoConfig } from './philips-order';
export { eventMarketingConfig, salesMultiPromotionConfig } from './sales-promotion';
// 舆情管理
export { commodityEvaluationConfig } from './public-opinion';
// 通知管理
export {
    // 消息通知管理（站内信）
    siteMessagesConfig,
} from './notification';

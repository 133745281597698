import React, { Fragment } from 'react';
import { get } from 'lodash';
import { KV } from '@/interfaces';
import classNames from 'classnames';
import { interpolate, language } from '@comall-backend-builder/core/lib/services';
import { SPECS_TYPE_NAMES, DELIVERY_TYPE_NAMES } from '@/constants';
import './template.less';
import { PRODUCT_TYPE_VALUES } from '@/constants/picking/product-type';

interface IProps {
    data: KV;
}

/**
 * 拣货单模板
 */
export function PickingReceipt(props: IProps) {
    const { data } = props;

    const exchangeName = (quantity: number) => {
        return new Map([
            [
                PRODUCT_TYPE_VALUES.ASSEMBLY_GOODS,
                interpolate(language.getText('picking.printReceipt.assembly'), { quantity }),
            ],
            [PRODUCT_TYPE_VALUES.GIVEAWAY, language.getText('picking.printReceipt.giveaway')],
            [
                PRODUCT_TYPE_VALUES.EXCHANGE_GOODS,
                language.getText('picking.printReceipt.exchangeGoods'),
            ],
        ]);
    };

    const info = [
        {
            label: 'picking.pickNumber',
            key: 'pickingListNumber',
        },
        {
            label: 'picking.outerOrderNumber',
            key: 'outerOrderNumber',
        },
        {
            label: 'picking.deliveryFlag',
            key: 'delivery',
            format: (value: any) => {
                return get(DELIVERY_TYPE_NAMES, get(value, 'deliveryType')) || '';
            },
        },
        {
            label: 'picking.earliestPickUpTime',
            key: 'delivery.preCanPickUpTime',
        },
        {
            label: 'picking.deliveryAddress',
            key: 'consignee',
            format: (value: any) => {
                const name = [
                    `${get(value, 'city', '')}`,
                    get(value, 'province', ''),
                    get(value, 'area', ''),
                    get(value, 'address', ''),
                ].filter((item) => !!item);
                return name.join('  ');
            },
        },
        {
            label: 'picking.printReceipt.printTime',
            key: 'printStartTime',
        },
    ];
    return (
        <div className='receipt-template a4-size monopoly-page'>
            <header className='receipt-template-header'>
                {language.getText('picking.printReceipt.pickingTitle')}
            </header>
            <main className='receipt-template-body'>
                <div className='info'>
                    {info.map((item) => {
                        const value = item.format
                            ? item.format(get(data, `${item.key}`))
                            : get(data, `${item.key}`);

                        return (
                            <div className='info-item' key={item.label}>
                                <label>{language.getText(item.label)}：</label>
                                <span>{value || '-'}</span>
                            </div>
                        );
                    })}
                </div>
                <div className='scan-barcode'>
                    <div>Order Number</div>
                    <img
                        alt=''
                        id='qrcode'
                        className='scan-barcode-img'
                        src={get(data, 'outerOrderNumberQrCode') || ''}
                    />
                    <div>{get(data, 'outerOrderNumber') || ''}</div>
                </div>
            </main>

            <div className='receipt-template-table'>
                <div className='table-row th' style={{ width: '200mm' }}>
                    <div style={{ width: '20mm' }}>{language.getText('picking.sn')}</div>
                    <div style={{ width: '20mm' }}>{language.getText('picking.productImage')}</div>
                    <div style={{ width: '20mm' }}>{language.getText('picking.brand')}</div>
                    <div style={{ width: '60mm' }}>{language.getText('picking.productName')}</div>
                    <div style={{ width: '20mm' }}>{language.getText('picking.SKU')}</div>
                    <div style={{ width: '20mm' }}>{language.getText('picking.barcode')}</div>
                    <div style={{ width: '20mm' }}>{language.getText('picking.size')}</div>
                    <div style={{ width: '20mm' }}>{language.getText('picking.needPickNum')}</div>
                </div>

                {(get(data, 'orderItems', []) || []).map((el: any, index: number) => {
                    return (
                        <>
                            <div
                                key={index}
                                className={classNames('table-row', 'table-row-items')}
                                style={{ width: '200mm' }}
                            >
                                <div style={{ width: '20mm' }}>{index + 1}</div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        borderLeft: 0,
                                        width: '180mm',
                                        borderBottom: 0,
                                    }}
                                >
                                    {el.orderItemReceiptVolist.map((item: any, ind: number) => (
                                        <>
                                            <div
                                                className={classNames('table-row', {
                                                    bt: ind !== 0,
                                                })}
                                                style={{ width: '180mm' }}
                                            >
                                                <div style={{ width: '20mm' }}>
                                                    <span className='goods-img'>
                                                        {item.productType === '2' && (
                                                            <img
                                                                alt=''
                                                                className='gift-tag'
                                                                src={require('@/resources/images/gift-tag.png')}
                                                            />
                                                        )}
                                                        <img
                                                            src={item.pictureUrl}
                                                            alt={item.enName}
                                                        />
                                                    </span>
                                                </div>
                                                <div style={{ width: '20mm' }}>
                                                    <span> {item.brandName}</span>
                                                </div>
                                                <div
                                                    style={{
                                                        width: '60mm',
                                                    }}
                                                >
                                                    <span> {item.name || item.enName}</span>
                                                </div>
                                                <div style={{ width: '20mm' }}>
                                                    <span> {item.csku}</span>
                                                </div>
                                                <div style={{ width: '20mm' }}>
                                                    <span> {item.barCode}</span>
                                                </div>
                                                <div style={{ width: '20mm' }}>
                                                    <span>
                                                        {get(
                                                            SPECS_TYPE_NAMES,
                                                            `${item.specType * 1}`
                                                        )}
                                                    </span>
                                                </div>
                                                <div style={{ width: '20mm' }}>
                                                    <span>
                                                        <b>{item.quantity}</b>
                                                    </span>
                                                </div>
                                            </div>

                                            {item.pickingLocations &&
                                                item.pickingLocations.length === 0 && (
                                                    <div
                                                        key={`${item.barCode}-unlocation`}
                                                        className='table-row bt'
                                                    >
                                                        <div
                                                            style={{ color: 'red', width: '180mm' }}
                                                        >
                                                            {language.getText(
                                                                'picking.printReceipt.productStockOutErrMsg'
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                            {item.pickingLocations &&
                                                item.pickingLocations.length > 0 && (
                                                    <Fragment>
                                                        <div
                                                            key={`${item.barCode}-location`}
                                                            className='table-row bt has-bg-grey'
                                                        >
                                                            <div
                                                                className='location-title-font'
                                                                style={{
                                                                    width: '100mm',
                                                                }}
                                                            >
                                                                <span>
                                                                    {exchangeName(
                                                                        item.quantity
                                                                    ).get(item.itemType) ??
                                                                        language.getText(
                                                                            'picking.printReceipt.locationName'
                                                                        )}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className='location-title-font'
                                                                style={{ width: '40mm' }}
                                                            >
                                                                <span>
                                                                    {language.getText(
                                                                        'picking.printReceipt.availableStock'
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className='location-title-font'
                                                                style={{ width: '40mm' }}
                                                            >
                                                                <span>
                                                                    {language.getText(
                                                                        'picking.printReceipt.pickingNum'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {(
                                                            get(item, 'pickingLocations', []) || []
                                                        ).map((locationItem: any) => (
                                                            <div
                                                                key={locationItem.location}
                                                                className='table-row bt '
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: '100mm',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {locationItem.location !==
                                                                            -1 ?? ''}
                                                                        {locationItem.locationName}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        width: '40mm',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {locationItem.quantitySrc}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        width: '40mm',
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        ))}
                                                    </Fragment>
                                                )}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
            <footer style={{ float: 'right', marginTop: '10px', fontSize: '4.5mm' }}>
                {interpolate(language.getText('picking.printReceipt.totalQuantity'), {
                    totalQuantity: get(data, 'needPickQuantity') || '-',
                })}
            </footer>
            <div className='page-breaker'></div>
        </div>
    );
}

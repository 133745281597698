import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';
import { APPLY_TYPE_OPTIONS, EXCLUDE_TYPE_LIMIT_OPTIONS } from '@/constants';
import { buildForm } from './order-limit-form';

export const config: Config = {
    entities: {
        orderLimitManagement: {
            apiPath: '/loader/dc-goods/admin/product/limit',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '<<orderLimitManagement.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 100,
                    },
                },
                sequence: {
                    type: 'number',
                    displayName: '<<orderLimitManagement.sequence>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                storeIds: {
                    type: 'array.onlineStoreGroup',
                    displayName: '<<couponManage.storeIds.title>>',
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/findAll',
                    },
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                        titles: [
                            '<<couponManage.storeIds.left>>',
                            '<<couponManage.storeIds.right>>',
                        ],
                        nameProp: 'onlineStoreName',
                    },
                },
                specialType: {
                    type: 'string.options.select',
                    displayName: '<<orderLimitManagement.specialType>>',
                    options: APPLY_TYPE_OPTIONS,
                },
                applyGoods: {
                    type: 'string.text.paragraph',
                    displayName: '<<couponManage.applyType.goodsTitle>>',
                    controlConfig: {
                        placeholder: '<<couponManage.applyType.goodsPlaceholder>>',
                    },
                },
                applyBrand: {
                    type: 'string.options.search',
                    displayName: '<<couponManage.applyType.brandTitle>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath: '/dc-goods/admin/brands/list',
                        },
                        optionFilterProp: 'label',
                        showSearch: true,
                    },
                },
                applyOfflineCategory: {
                    type: 'string.treeNode.tree',
                    displayName: '<<couponManage.applyType.offlineCategoryTitle>>',
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath:
                                '/loader/dc-goods/admin/offline_categories/offline_category_tree',
                        },
                    },
                },
                applyVirtualCategory: {
                    type: 'string.treeNode.tree',
                    displayName: '<<couponManage.applyType.virtualCategoryTitle>>',
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath: '/loader/virtual_categorys/categorys_tree/all',
                        },
                    },
                },
                excludeType: {
                    type: 'string.options.select',
                    displayName: '<<orderLimitManagement.excludeType>>',
                    options: EXCLUDE_TYPE_LIMIT_OPTIONS,
                },
                excludeGoods: {
                    type: 'string.text.paragraph',
                    displayName: '<<couponManage.applyType.goodsTitle>>',
                    controlConfig: {
                        placeholder: '<<couponManage.applyType.goodsPlaceholder>>',
                    },
                },
                excludeBrand: {
                    type: 'string.options.search',
                    displayName: '<<couponManage.applyType.brandTitle>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath: '/dc-goods/admin/brands/list',
                        },
                        optionFilterProp: 'label',
                        showSearch: true,
                    },
                },
                excludeOfflineCategory: {
                    type: 'string.treeNode.tree',
                    displayName: '<<couponManage.applyType.offlineCategoryTitle>>',
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath:
                                '/loader/dc-goods/admin/offline_categories/offline_category_tree',
                        },
                    },
                },
                excludeVirtualCategory: {
                    type: 'string.treeNode.tree',
                    displayName: '<<couponManage.applyType.virtualCategoryTitle>>',
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath: '/loader/virtual_categorys/categorys_tree/all',
                        },
                    },
                },
                limitNum: {
                    type: 'number',
                    displayName: '<<orderLimitManagement.limitNum>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                onlineStoreList: {
                    type: 'array.onlineStoreGroup',
                    displayName: '<<couponManage.common.stores>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<orderLimitManagement.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                onlineStoreId: {
                    type: 'string.treeNode.cascader',
                    displayName: '<<overrideCode.onlineStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        OrderLimitManagementView: {
            component: 'Viewport',
        },
        OrderLimitManagementPage: {
            entity: 'orderLimitManagement',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'name',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                        {
                            property: 'onlineStoreId',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<orderLimitManagement.newPurchaseRestriction>>',
                            route: '/product/order-limit-management/add',
                            privilege: {
                                path: 'goods.product_limit.product_limit_create',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'OrderLimitManagementTable',
                    privilege: {
                        path: 'goods.product_limit.product_limit_get',
                        level: 'full',
                    },
                },
            ]),
        },
        OrderLimitManagementTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'specialType',
                },
                {
                    property: 'onlineStoreList',
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                true: '/dc-goods/admin/product/limit/disable/{{row.id}}',
                                false: '/dc-goods/admin/product/limit/enable/{{row.id}}',
                            },
                            statusText: {
                                true: '<<disable>>',
                                false: '<<enable>>',
                            },
                            linkProperty: 'enable',
                            errorStatus: true,
                        },
                        privilege: {
                            path: 'goods.product_limit.product_limit_enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/product/order-limit-management/edit/{{row.id}}',
                        privilege: {
                            path: 'goods.product_limit.product_limit_update',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        OrderLimitManagementAddPage: {
            component: 'Card',
            entity: 'orderLimitManagement',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        OrderLimitManagementEditPage: {
            component: 'Card',
            entity: 'orderLimitManagement',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
    },
};

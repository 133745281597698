import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 订单状态值
 */
export enum ORDER_STATUS_VALUES {
    /**
     * 全部（前端特有状态用于查询）
     */
    ALL = 'ALL',
    /**
     * 待付款
     */
    WAIT_PAY = 'WAIT_PAY',
    /**
     * 待拣货
     */
    ASSEMBLED = 'ASSEMBLED',
    /**
     * 拣货中
     */
    PICKING = 'PICKING',
    /**
     * 待打包
     */
    PICKED = 'PICKED',
    /**
     * 待发货
     */
    PACKED = 'PACKED',
    /**
     * 已发货
     */
    DELIVERING = 'DELIVERING',
    /**
     * 已签收
     */
    COMPLETED = 'COMPLETED',
    /**
     * 退款中
     */
    REFUNDING = 'REFUNDING',
    /**
     * 已取消
     */
    CANCELLED = 'CANCELLED',
}

/*
 * 全一点的订单状态值
 */
export const ORDER_STATUS_ALL_VALUES = Object.assign({}, ORDER_STATUS_VALUES, {
    /**
     * 已付款
     */
    PAID: 'PAID',
    /**
     * 已退款
     */
    REFUNDED: 'RETURNED',
});
/**
 * 订单状态名
 */
export const ORDER_STATUS_NAMES = {
    [ORDER_STATUS_ALL_VALUES.ALL]: language.getText('order.statusMap.all'),
    [ORDER_STATUS_ALL_VALUES.WAIT_PAY]: language.getText('order.statusMap.waitPay'),
    [ORDER_STATUS_ALL_VALUES.ASSEMBLED]: language.getText('order.statusMap.assembled'),
    [ORDER_STATUS_ALL_VALUES.PICKING]: language.getText('order.statusMap.picking'),
    [ORDER_STATUS_ALL_VALUES.PICKED]: language.getText('order.statusMap.picked'),
    [ORDER_STATUS_ALL_VALUES.PACKED]: language.getText('order.statusMap.waitDelivery'),
    [ORDER_STATUS_ALL_VALUES.DELIVERING]: language.getText('order.statusMap.delivering'),
    [ORDER_STATUS_ALL_VALUES.COMPLETED]: language.getText('order.statusMap.completed'),
    [ORDER_STATUS_ALL_VALUES.REFUNDING]: language.getText('order.statusMap.refunding'),
    [ORDER_STATUS_ALL_VALUES.CANCELLED]: language.getText('order.statusMap.cancelled'),
    [ORDER_STATUS_ALL_VALUES.PAID]: language.getText('offlineOrder.statusMap.paid'),
    [ORDER_STATUS_ALL_VALUES.REFUNDED]: language.getText('offlineOrder.statusMap.refunded'),
};

/**
 * 订单状态候选项
 */
export const ORDER_STATUS_OPTIONS = generateOptions(ORDER_STATUS_VALUES, ORDER_STATUS_NAMES);
export const ORDER_STATUS_ALL_OPTIONS = generateOptions(
    ORDER_STATUS_ALL_VALUES,
    ORDER_STATUS_NAMES
);
const {
    // eslint-disable-next-line no-empty-pattern
    ALL: {},
    ...ORDER_STATUS_PART_VALUES
} = ORDER_STATUS_VALUES;
const {
    // eslint-disable-next-line no-empty-pattern
    ALL: {},
    ...ORDER_STATUS_PART_NAMES
} = ORDER_STATUS_NAMES;

/**
 * 订单状态筛选项
 */
export const ORDER_STATUS_PART_OPTIONS = generateOptions(
    ORDER_STATUS_PART_VALUES,
    ORDER_STATUS_PART_NAMES
);

/**
 * 配送类型值
 */
export enum DELIVERY_TYPE_VALUES {
    /**
     * 自提
     */
    PICK_UP = 'ZT',
    /**
     * 配送
     */
    DELIVERY = 'PS',
    /**
     * 快递
     */
    EXPRESS = 'KD',
}

/**
 * 配送类型名
 */
export const DELIVERY_TYPE_NAMES = {
    [DELIVERY_TYPE_VALUES.PICK_UP]: language.getText('deliveryType.pickUp'),
    [DELIVERY_TYPE_VALUES.DELIVERY]: language.getText('deliveryType.delivery'),
    [DELIVERY_TYPE_VALUES.EXPRESS]: language.getText('deliveryType.express'),
};

export const DELIVERY_TYPE_NAMES_TC = {
    [DELIVERY_TYPE_VALUES.PICK_UP]: language.getText('deliveryType.tc.pickUp'),
    [DELIVERY_TYPE_VALUES.DELIVERY]: language.getText('deliveryType.tc.delivery'),
    [DELIVERY_TYPE_VALUES.EXPRESS]: language.getText('deliveryType.tc.express'),
};
/**
 * 配送类型候选项
 */
export const DELIVERY_TYPE_OPTIONS = generateOptions(DELIVERY_TYPE_VALUES, DELIVERY_TYPE_NAMES);

/**
 * 用户标签值
 */
export enum MEMBER_LABEL_VALUES {
    /**
     * 游客
     */
    TOURIST = 1,
    /**
     * 会员
     */
    MEMBER,
    /**
     * 员工
     */
    STAFF,
}

/**
 * 用户标签名
 */
export const MEMBER_LABEL_NAMES = {
    [MEMBER_LABEL_VALUES.TOURIST]: language.getText('order.memberLabel.tourist'),
    [MEMBER_LABEL_VALUES.MEMBER]: language.getText('order.memberLabel.member'),
    [MEMBER_LABEL_VALUES.STAFF]: language.getText('order.memberLabel.staff'),
};

/**
 * 用户标签候选项
 */
export const MEMBER_LABEL_OPTIONS = generateOptions(MEMBER_LABEL_VALUES, MEMBER_LABEL_NAMES);

/**
 * 订单进度状态值
 */
export enum ORDER_PROGRESS_STATUS_VALUES {
    /**
     * 提交定单
     */
    WAIT_PAY = 'WAIT_PAY',
    /**
     * 已付款
     */
    ORDER_PAID = 'ORDER_PAID',
    /**
     * 已拣货
     */
    PICKED = 'PICKED',
    /**
     * 已打包
     */
    PACKED = 'PACKED',
    /**
     * 已发货
     */
    DELIVERED = 'DELIVERED',
    /**
     * 已签收
     */
    RECEIVED = 'RECEIVED',
    /**
     * 已取消
     */
    CANCELLED = 'CANCELLED',
    /**
     * 已退款
     */
    REFUNDED = 'RETURNED',
}

/**
 * 订单进度状态名
 */
export const ORDER_PROGRESS_STATUS_NAMES = {
    [ORDER_PROGRESS_STATUS_VALUES.WAIT_PAY]: language.getText('order.progress.waitPay'),
    [ORDER_PROGRESS_STATUS_VALUES.ORDER_PAID]: language.getText('order.progress.orderPaid'),
    [ORDER_PROGRESS_STATUS_VALUES.PICKED]: language.getText('order.progress.picked'),
    [ORDER_PROGRESS_STATUS_VALUES.PACKED]: language.getText('order.progress.packed'),
    [ORDER_PROGRESS_STATUS_VALUES.DELIVERED]: language.getText('order.progress.delivered'),
    [ORDER_PROGRESS_STATUS_VALUES.RECEIVED]: language.getText('order.progress.received'),
    [ORDER_PROGRESS_STATUS_VALUES.CANCELLED]: language.getText('order.progress.cancelled'),
    [ORDER_PROGRESS_STATUS_VALUES.REFUNDED]: language.getText('offlineOrder.statusMap.refunded'),
};

/**
 * 订单进度候选项
 */
export const ORDER_PROGRESS_STATUS_OPTIONS = generateOptions(
    ORDER_PROGRESS_STATUS_VALUES,
    ORDER_PROGRESS_STATUS_NAMES
);
/**
 * 拣货状态
 */
export enum PICK_STATUS_VALUES {
    /**
     * 待拣货
     */
    WAITING_PICK = 'WAITING_PICK',
    /**
     * 拣货中
     */
    PICKING = 'PICKING',
    /**
     * 拣货完成
     */
    FINISH_PICK = 'FINISH_PICK',
}
/**
 * 拣货状态名
 */
export const PICK_STATUS_NAMES = {
    WAITING_PICK: language.getText('picking.status.waitPicking'),
    PICKING: language.getText('picking.status.picking'),
    FINISH_PICK: language.getText('picking.status.finishPick'),
    CANCELLED: language.getText('picking.status.cancelled'),
};

/**
 * 运输方式值
 */
export enum DELIVERY_FLAG_VALUES {
    /**
     * 自提
     */
    PICK_UP = '0',
    /**
     * 配送
     */
    DELIVERY = '1',
    /**
     * 快递
     */
    EXPRESS = '2',
}

/**
 * 运输方式名
 */
export const DELIVERY_FLAG_NAMES = {
    [DELIVERY_FLAG_VALUES.PICK_UP]: language.getText('deliveryType.pickUp'),
    [DELIVERY_FLAG_VALUES.DELIVERY]: language.getText('deliveryType.delivery'),
    [DELIVERY_FLAG_VALUES.EXPRESS]: language.getText('deliveryType.express'),
};

/**
 * 运输方式候选项
 */
export const DELIVERY_FLAG_OPTIONS = generateOptions(
    DELIVERY_FLAG_VALUES,
    DELIVERY_FLAG_NAMES,
    (v, n) => ({ value: String(v), name: n, id: String(v), label: n })
) as any;

/**
 * 是否缺货值
 */
export enum STOCK_OUT_VALUES {
    /**
     * 缺货
     */
    YES = 'true',
    /**
     * 否
     */
    NO = 'false',
}

/**
 * 是否缺货值名
 */
export const STOCK_OUT_NAMES = {
    [STOCK_OUT_VALUES.YES]: language.getText('yes'),
    [STOCK_OUT_VALUES.NO]: language.getText('no'),
};

/**
 * 是否缺货值候选项
 */
export const STOCK_OUT_OPTIONS = generateOptions(STOCK_OUT_VALUES, STOCK_OUT_NAMES);

import React, { PureComponent } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { ExportButtonProps } from '@comall-backend-builder/components-basis/lib/export-button';
import { services } from '@comall-backend-builder/core';
import { Modal } from 'antd';
import { interpolate } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';

interface IProps extends ExportButtonProps {
    /**
     * 是否显示成功后提示
     */
    showSuccessTips?: boolean;
    /**
     * 表格当前行数据
     */
    row?: KV;

    text?: string;
}

interface IState {
    showLoading: boolean;
}

/**
 * 异步导出
 * 使用同 ExportButton 组件
 */
export class AsyncExportButton extends PureComponent<Omit<IProps, 'downloadUrl'>, IState> {
    constructor(props: any) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.state = {
            showLoading: false,
        };
    }

    async fetch() {
        this.setState({ showLoading: false });
        const {
            source,
            params,
            showSuccessTips,
            onSuccess,
            showErrorMessage,
            onError,
            row,
        } = this.props;
        let { apiPath } = source;
        if (row) {
            apiPath = interpolate(apiPath, { row });
        }
        try {
            const result = await services.api.request({ ...source, apiPath, params });
            if (showSuccessTips) {
                Modal.success({
                    content: services.language.getText('exportModal.exportSuccessMsg'),
                });
            }
            onSuccess && onSuccess(result);
        } catch (err) {
            if (showErrorMessage) {
                services.errorHandle(err as ErrorInfo);
            }
            onError && onError(err);
        } finally {
            this.setState({ showLoading: false });
        }
    }

    handleClick(event: any) {
        const { beforeExport, onClick } = this.props;
        const { showLoading } = this.state;
        if (onClick) {
            onClick(event);
        }
        if (showLoading) return;
        if (beforeExport) {
            beforeExport().then(this.fetch, () => {});
        } else {
            this.fetch();
        }
    }

    render() {
        const props = Object.assign({}, this.props, {
            loading: this.state.showLoading,
            onClick: this.handleClick,
        });

        return <Button {...props}></Button>;
    }
}

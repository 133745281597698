import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 事件类型状态值
 */
export enum TYPE_NAME_VALUES {
    /**
     * 新会员注册
     */
    NEW_MEMBER_REGISTER = 'NEW_MEMBER_REGISTER',
    /**
     * 订单满赠
     */
    FREE_ON_FULL_ORDER = 'FREE_ON_FULL_ORDER',
    /**
     * 生日有礼
     */
    BIRTHDAY_COURTESY = 'BIRTHDAY_COURTESY',
    /**
     * 邀请有礼
     */
    REWARD_FOR_INVITING_OTHERS = 'REWARD_FOR_INVITING_OTHERS',
    /**
     * 定时发放
     */
    TIMED_RELEASE = 'TIMED_RELEASE',
}

/**
 * 事件类型状态名
 */
export const TYPE_NAME_NAMES = {
    [TYPE_NAME_VALUES.NEW_MEMBER_REGISTER]: language.getText(
        'salesPromotion.eventMarketing.register'
    ),
    [TYPE_NAME_VALUES.FREE_ON_FULL_ORDER]: language.getText('salesPromotion.eventMarketing.order'),
    [TYPE_NAME_VALUES.BIRTHDAY_COURTESY]: language.getText(
        'salesPromotion.eventMarketing.birthday'
    ),
    [TYPE_NAME_VALUES.REWARD_FOR_INVITING_OTHERS]: language.getText(
        'salesPromotion.eventMarketing.invitation'
    ),
    [TYPE_NAME_VALUES.TIMED_RELEASE]: language.getText(
        'salesPromotion.eventMarketing.timedRelease'
    ),
};

/**
 * 事件类型候选项
 */
export const TYPE_NAME_OPTIONS = generateOptions(TYPE_NAME_VALUES, TYPE_NAME_NAMES);

/**
 * 事件类型状态值
 */
export enum EVENT_STATUS_VALUES {
    /**
     * 未开始
     */
    HAVENOTBEGUN = 0,
    /**
     * 进行中
     */
    INPROGRESS = 1,
    /**
     * 已结束
     */
    FINISHED = 2,
}

/**
 * 事件类型状态名
 */
export const EVENT_STATUS_NAMES = {
    [EVENT_STATUS_VALUES.HAVENOTBEGUN]: language.getText(
        'salesPromotion.eventMarketing.notBeginning'
    ),
    [EVENT_STATUS_VALUES.INPROGRESS]: language.getText('salesPromotion.eventMarketing.progress'),
    [EVENT_STATUS_VALUES.FINISHED]: language.getText('salesPromotion.eventMarketing.end'),
};

/**
 * 事件类型候选项
 */
export const EVENT_STATUS_OPTIONS = generateOptions(EVENT_STATUS_VALUES, EVENT_STATUS_NAMES);

/**
 * 活动类型角标颜色
 */
export const EVENT_STATUS_COLORS = {
    [EVENT_STATUS_VALUES.HAVENOTBEGUN]: 'success',
    [EVENT_STATUS_VALUES.INPROGRESS]: 'warning',
    [EVENT_STATUS_VALUES.FINISHED]: 'default',
};

/**
 * 活动类型候选项
 */
export const EVENT_SHOW_STATUS_OPTIONS = generateOptions(
    EVENT_STATUS_VALUES,
    EVENT_STATUS_NAMES,
    (v, n) => ({ id: v, name: n, status: EVENT_STATUS_COLORS[v] })
);

/**
 * 邀请方式状态值
 */
export enum INVITATION_WAY_VALUES {
    /**
     * 被邀请人注册并完成一笔订单
     */
    COMPLETE_ORDER = '1',
}

/**
 * 邀请方式状态名
 */
export const INVITATION_WAY_NAMES = {
    [INVITATION_WAY_VALUES.COMPLETE_ORDER]: language.getText(
        'salesPromotion.eventMarketing.complete'
    ),
};

/**
 * 邀请方式候选项
 */
export const INVITATION_WAYOPTIONS = generateOptions(INVITATION_WAY_VALUES, INVITATION_WAY_NAMES);

/**
 * 奖励方式状态值
 */
export enum REWARD_WAYS_VALUES {
    /**
     * 邀请人获得
     */
    INVITER = '1',
    /**
     * 双方获得
     */
    TWOSIDES = '2',
}

/**
 * 奖励方式状态名
 */
export const REWARD_WAYS_NAMES = {
    [REWARD_WAYS_VALUES.INVITER]: language.getText('salesPromotion.eventMarketing.obtain'),
    [REWARD_WAYS_VALUES.TWOSIDES]: language.getText('salesPromotion.eventMarketing.both'),
};

/**
 * 奖励方式候选项
 */
export const REWARD_WAYSOPTIONS = generateOptions(REWARD_WAYS_VALUES, REWARD_WAYS_NAMES);

/* eslint-disable */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { get as lodashGet, transform as lodashTransform } from 'lodash';

import { Tooltip, Popconfirm } from 'antd';
import { services, ComponentsManager, Loader } from '@comall-backend-builder/core';
import { formatLanguage } from '@/services';
import './index.less';

/**
 * 有状态的表格操作项
 * 根据表格字段自定义操作项
 */

const defaultConfig = {
    disabled: false,
    tooltip: '',
    path: '/',
    isShow: true,
};

export interface IConfig {
    disabled?: boolean; // 链接禁用
    tooltip?: string; // 提示文字
    path?: string; // 跳转链接
    isShow?: boolean; // 是否显示
}

interface IProps {
    entity: any;
    source: AnyObject;
    className: string;
    row: any;
    rowKey: string;
    text: string;
    /**
     * 操作类型
     */
    actionType: 'delete' | 'link' | 'component' | null;
    /**
     * actionType 为 component 时得组件名
     */
    component: 'string';
    /**
     * actionType 为 delete 的删除弹窗配置, 详见 <Popconfirm />
     */
    deleteConfig?: any;
    /**
     * 生成渲染结构
     */
    render?: (row: any, text: string, className: string, props?: any) => JSX.Element;
    /**
     * 生成渲染配置数据
     */
    renderConfig?: (row: any, props: any) => IConfig;
}

export class TableActionStateful extends PureComponent<IProps, never> {
    static defaultProps = {
        actionType: 'link',
        deleteConfig: {},
    };

    onDelete = () => {
        const { entity, row, rowKey = 'id', source, deleteConfig } = this.props;
        let apiPath = '';
        if (source) {
            apiPath = source.apiPath;
        } else {
            apiPath = entity.metainfo.apiPath.replace('/loader', '');
        }

        // 支持携带额外参数，并且支持翻译参数
        const rawParams = lodashGet(deleteConfig, 'params', {});
        const params = lodashTransform(rawParams, (result: AnyObject, value, key: string) => {
            result[key] = services.interpolate(value, this.props);
        });

        // 支持 loader，支持自定义请求方法
        Loader.load(lodashGet(deleteConfig, 'method', 'delete'), {
            apiPath: `${apiPath}/:${rowKey}`,
            params: {
                ...params,
                [rowKey]: row[rowKey],
            },
        })
            .then(() => {
                const callback = lodashGet(deleteConfig, 'callback');
                callback && callback();
                entity.search(entity.params);
            })
            .catch(services.errorHandle);
    };

    render() {
        const {
            className,
            row,
            text,
            renderConfig,
            render,
            actionType,
            deleteConfig,
            component,
        } = this.props;
        const DELETE_CONFIRM_PROPS = {
            title: services.language.getText('areYouSureToDelete'),
            okText: services.language.getText('confirm'),
            cancelText: services.language.getText('cancel'),
        };

        // 通过配置渲染
        if (typeof renderConfig === 'function') {
            let config = { ...defaultConfig };
            const renderResult = renderConfig(row, this.props);
            if (renderResult) {
                config = Object.assign({}, config, renderResult);
            }
            const { tooltip, disabled, path, isShow } = config;
            // 禁用状态
            if (disabled) {
                return (
                    <Tooltip title={formatLanguage(tooltip)}>
                        <span className={classnames(className, 'disabled')}>{text}</span>
                    </Tooltip>
                );
            } else {
                if (isShow) {
                    // 删除类型
                    if (actionType === 'delete') {
                        return (
                            <Popconfirm
                                {...DELETE_CONFIRM_PROPS}
                                {...deleteConfig}
                                onConfirm={this.onDelete}
                            >
                                <a className={classnames(className)}>{text}</a>
                            </Popconfirm>
                        );
                    }

                    // 链接类型
                    if (actionType === 'link') {
                        return (
                            <Link to={path} className={className}>
                                {text}
                            </Link>
                        );
                    }

                    // 组件类型
                    if (actionType === 'component' && component) {
                        const targetComponent = React.createElement(
                            ComponentsManager.get(component),
                            renderResult
                        );
                        return targetComponent || null;
                    }
                } else {
                    return <></>;
                }
            }
        }

        // 自定义渲染
        if (typeof render === 'function') {
            return render(row, text, className, this.props);
        }

        // 删除类型
        if (actionType === 'delete') {
            return (
                <Popconfirm {...DELETE_CONFIRM_PROPS} {...deleteConfig} onConfirm={this.onDelete}>
                    <a className={classnames(className)}>{text}</a>
                </Popconfirm>
            );
        }
        return <a className={className}>{text}</a>;
    }
}

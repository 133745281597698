import React, { Fragment } from 'react';
import { isEmpty, get } from 'lodash';
import classNames from 'classnames';
import { Price, PriceType } from '@/components/price';
import { OrderInfoLine } from './order-info-line';
import './index.less';

interface Props {
    /**
     * 订单号
     */
    id: string;
    /**
     * 机构
     */
    organization: Organization;
    /**
     * 订单编号
     */
    orderNumber: string;
    /**
     * 外部订单编号
     */
    outerOrderNumber: string;
    /**
     * 第三方创建时间
     */
    thirdPartyCreateTime: string;
    /**
     * SA信息
     */
    saInfoVo: SaInfo | null;
    loyaltCode: string | null;
    paxNumber: string | null;
    /**
     * 收货人信息
     */
    consignee: CustomerInfo;
    /**
     * 订单门店信息
     */
    orderStore: OrderStoreInfo;
    pickUpInfo: OrderStoreInfo;
    paymentType: string;
    enPaymentType: string;
    /**
     * 订单商品
     */
    goods: GoodsType[];
    /**
     * 订单赠品
     */
    presents: GoodsType[];
    deliveryFlag: string;
    delivery: any;
    /**
     * 商品金额
     */
    recptTotalAmountVo: PriceType;
    /**
     * 商品优惠金额
     */
    recptTotalDiscountAmountVo: PriceType;
    /**
     * 促销优惠金额
     */
    recptPromotionAmount: PriceType;
    /**
     * 优惠券金额
     */
    recptCouponAmount: PriceType;
    /**
     * 成交金额
     */
    recptDealAmount: PriceType;
    /**
     * 积分抵扣
     */
    recptPointAmount: PointType;
    /**
     * 实付总金额
     */
    recptPickAmount: PriceType;
    recptPaidAmountVo: PriceType;
    orderTraces: {
        traces: OrderTrace[];
    };
    /**
     * 渠道 3-eshop，5-select，7-guide shopping
     */
    channel: {
        id: string;
        name: string;
    };
    memberInfo: {
        code: string;
        mobile: string;
    };
    /**员工折扣 */
    recptStaffDiscountAmount: PriceType;
    /**是否使用了员工折扣 */
    staffDiscountFlag: boolean;
    /** 优惠券折扣 */
    couponDiscountFlag: boolean;
    /** 折扣券码 */
    discountCode: string;
    /** 优惠券标题 */
    discountTitle: string;
}
interface PointType extends PriceType {
    point: string;
}
interface Organization {
    categoryId: number;
    currency: string;
    currencyIsoCode: string;
    divisionNumber: string;
    id: string;
    language: string;
    languageIsoCode: string;
    name: string;
    parentId: string;
    status: string;
    timeZone: string;
    zoneId: string;
}
interface SaInfo {
    cartId: string;
    posId: string;
    saId: string;
    saName: string;
    saNo: string;
}
interface CustomerInfo {
    address: string;
    area: string;
    city: string;
    code: string;
    firstName: string;
    id: string;
    lastName: string;
    mobile: string;
    name: string;
    /**
     * 性别 1-男  0-女
     */
    gender: number;
}
interface OrderStoreInfo {
    id: string;
    pickStoreName: string;
    enPickStoreName: string;
}
interface GoodsType {
    /**
     * 标识
     */
    id: string;
    rsku: string;
    csku: string;
    guideOnlyStatus: boolean;
    /**
     * 订单项id
     */
    orderItemId: number;
    goodsWjaVolist: WjaItem[];
    /**
     * 商品类型 1.普通商品，2.赠品
     */
    productType: string;
    /**
     * 商品ID
     */
    goodsId: string;
    name: string;
    enName: string;
    brandNameEn: string;
    specNameEn: string;
    pictureUrl: string;
    /**
     * 购买数量
     */
    quantity: number;
    /**
     * 原始商品数量
     */
    originQuantity: number;
    /**
     * 商品原价
     */
    recptPriceVo: PriceType;
    /**
     * 单价（执行价）
     */
    salePrice: PriceType;
    recptOriginPromotionPrice: PriceType;
    /**
     * 金额
     */
    amount: PriceType;
    /**
     * 单项商品折后总价
     */
    afterFoldingPriceVo: PriceType;
    /**
     * 退款数量
     */
    refundQuantity: number;
    /**
     * 缺货数量
     */
    lackQuantity: number;
    /**
     * 拣货数量
     */
    pickingQuantity: number;
}
export interface WjaItem {
    caratRemark: string | null;
    csku: string;
    id: string;
    jewel: string | null;
    metal: string | null;
    orderItemId: string;
    outOrderNumber: string | null;
    rsku: string;
    serialNo: string | null;
    styleId: string;
    watchMovement: string | null;
}
export interface OrderTrace {
    content: string;
    createTime: string;
    id: number;
    operateType: string;
    operator: { id: number; name: string; storeName: string };
}
export const OrderReceipt = (props: Props) => {
    let createTime = props.thirdPartyCreateTime;
    let pickupTime =
        props.orderTraces.traces.find((item) => item.operateType === 'DELIVERY_SUCCEED')
            ?.createTime || '';
    // 订单信息
    const renderOrderInfo = () => {
        return (
            <div className='order-info'>
                <OrderInfoLine label='Order Number'>
                    <span className='.order-info-value'>#{props.outerOrderNumber}</span>
                </OrderInfoLine>
                <OrderInfoLine label='Order Date'>
                    <span className='.order-info-value'>{createTime.replace(/ /g, ', ')}</span>
                </OrderInfoLine>
                {/* GS时展示sa信息 */}
                {props.channel.id === '7' && (
                    <OrderInfoLine label='Personal Shopper'>
                        <span className='.order-info-value'>
                            {props.saInfoVo?.saName}, {props.saInfoVo?.posId}
                        </span>
                    </OrderInfoLine>
                )}
            </div>
        );
    };
    // 提货信息
    const renderPickupDetail = () => {
        const formatGender = (gender: number): string => {
            let genderName;
            switch (gender) {
                case 0:
                    genderName = 'Ms.';
                    break;
                case 1:
                    genderName = 'Mr.';
                    break;

                default:
                    genderName = '';
                    break;
            }
            return genderName;
        };

        return (
            <div className='pickup-detail'>
                <div className='pickup-detail-title item-title'>
                    {get(props, 'deliveryFlag') === '1' ? 'DELIVERY DETAILS' : 'PICKUP DETAILS'}
                </div>
                <div className='pickup-detail-content'>
                    <div className='group'>
                        <OrderInfoLine label='Loyal T Number'>
                            <span>{props.loyaltCode}</span>
                        </OrderInfoLine>
                        <OrderInfoLine label='Pax Number'>
                            <span>{props.paxNumber}</span>
                        </OrderInfoLine>
                        <OrderInfoLine label='Customer Name'>
                            <span>
                                {formatGender(props.consignee.gender)} {props.consignee.firstName}{' '}
                                {props.consignee.lastName}
                            </span>
                        </OrderInfoLine>
                        <OrderInfoLine label='Contact Number'>
                            <span>
                                {props.memberInfo.code} {props.memberInfo.mobile}
                            </span>
                        </OrderInfoLine>
                        <OrderInfoLine label='Email Address'>
                            <span>{props.delivery.email}</span>
                        </OrderInfoLine>
                    </div>
                    <div className='group'>
                        <OrderInfoLine
                            label={
                                get(props, 'deliveryFlag') === '1'
                                    ? 'Delivery Method'
                                    : 'Pickup Location'
                            }
                        >
                            <span>{props.orderStore.enPickStoreName}</span>
                        </OrderInfoLine>
                        {get(props, 'deliveryFlag') === '1' && (
                            <OrderInfoLine label='Delivery Address'>
                                <div>
                                    <span>{`${get(props, 'delivery.deliveryAddress') || ''}`}</span>
                                    <br />
                                    {get(props, 'delivery.deliverySecondAddress') && (
                                        <Fragment>
                                            <span>
                                                {`${get(props, 'delivery.deliverySecondAddress')}`}
                                            </span>
                                            <br />
                                        </Fragment>
                                    )}
                                    <span>{`${get(props, 'delivery.street') || ''}`}</span>
                                    <br />
                                    <span> {`${get(props, 'delivery.area') || ''}`}</span>
                                </div>
                            </OrderInfoLine>
                        )}
                        {get(props, 'deliveryFlag') === '0' && (
                            <OrderInfoLine label='Pickup Date'>
                                <span>{pickupTime.replace(/ /g, ', ')}</span>
                            </OrderInfoLine>
                        )}
                    </div>
                    <div className='group'>
                        <OrderInfoLine label='Payment Method'>
                            <span>{props.enPaymentType}</span>
                        </OrderInfoLine>
                    </div>
                </div>
            </div>
        );
    };

    // 订单商品
    const renderProductDetail = () => {
        const WjaItemLine: React.FC<{ label: string }> = (props) => {
            const { label, children } = props;
            return children ? (
                <div className='wja-item-line'>
                    <div className='wja-item-label'>{label}:</div>
                    <div className='wja-item-value'>{children}</div>
                </div>
            ) : null;
        };

        const renderWja = (wjaList: WjaItem[]) => {
            return (
                <div className='wja_area'>
                    {wjaList.map((item, index: number) => (
                        <div key={item.styleId + index} className='wja-item'>
                            <WjaItemLine label='Style ID'>{item.styleId}</WjaItemLine>
                            <WjaItemLine label='Serial No.'>{item.serialNo}</WjaItemLine>
                            <WjaItemLine label='Metal/Material'>{item.metal}</WjaItemLine>
                            <WjaItemLine label='Jewel'>{item.jewel}</WjaItemLine>
                            <WjaItemLine label='Carat/Remark'>{item.caratRemark}</WjaItemLine>
                            <WjaItemLine label='Watch Movement'>{item.watchMovement}</WjaItemLine>
                        </div>
                    ))}
                </div>
            );
        };

        return (
            <div className='content-product-detail'>
                <div className='thead'>
                    <span className='sku'>SKU</span>
                    <span className='description'>Description</span>
                    <span className='unit-price'>Unit Price</span>
                    <span className='quantity'>Quantity</span>
                    <span className='total-price'>Net Total Price</span>
                </div>
                <ul className='tbody'>
                    {props.goods.map((item) => {
                        // 过滤全部缺货的商品
                        if (item.pickingQuantity < 1) return null;
                        return (
                            <li key={item.csku}>
                                {item.guideOnlyStatus && (
                                    <div className='guide-tag-line'>
                                        <div className='tag'>Exclusive</div>
                                        This product is only for specific customers and is not
                                        publicly available for sale online.
                                    </div>
                                )}
                                <div className='brand-name_line'>
                                    <span className='sku'>{item.rsku}</span>
                                    <span className='description'>{item.brandNameEn}</span>
                                    <span className='unit-price'>
                                        <Price
                                            className={classNames({
                                                'unit-price_line-through': !isEmpty(
                                                    item.recptOriginPromotionPrice
                                                ),
                                            })}
                                            size={11}
                                            price={item.recptPriceVo}
                                        />
                                    </span>
                                    <span className='quantity'>x {item.pickingQuantity}</span>
                                    <span className='total-price'>
                                        <Price size={11} price={item.afterFoldingPriceVo} />
                                    </span>
                                </div>
                                <div className='product-name_line '>
                                    <span className='description'>{item.enName}</span>
                                    {!isEmpty(item.recptOriginPromotionPrice) && (
                                        <span className='unit-price'>
                                            <Price
                                                size={11}
                                                price={item.recptOriginPromotionPrice}
                                            />
                                        </span>
                                    )}
                                </div>
                                <div className='product-size_line description'>
                                    {item.specNameEn}
                                </div>
                                {item.goodsWjaVolist.length > 0 && renderWja(item.goodsWjaVolist)}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    };
    //员工折扣
    const renderPromotionalCode = () => {
        const { staffDiscountFlag, couponDiscountFlag, discountCode } = props || {};
        if ((staffDiscountFlag || couponDiscountFlag) && discountCode) {
            return (
                <div className='content-promotional-code'>
                    <div className='title item-title'>Promotional Code</div>
                    <div className='subtitle'>
                        {staffDiscountFlag
                            ? `${discountCode} - DFS Staff Promo Code`
                            : `${discountCode}`}
                    </div>
                </div>
            );
        }

        return null;
    };
    // 赠品区域
    const renderAdditionalOffer = () => {
        return props.presents.length > 0 ? (
            <div className='content-additional-offer'>
                <div className='title item-title'>ADDITIONAL OFFERS</div>
                <div className='subtitle'>SAMPLE DETAILS</div>
                <ul>
                    {props.presents.map((item) => {
                        if (item.pickingQuantity < 1) return null;
                        return (
                            <li key={item.csku} className='item'>
                                <div className='brand'>{item.brandNameEn}</div>
                                <div className='info'>
                                    <div className='name'>{item.enName}</div>
                                    <div className='count'>X{item.pickingQuantity}</div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        ) : null;
    };
    // 价格详情
    const renderPriceDetail = () => {
        const {
            recptTotalAmountVo,
            recptTotalDiscountAmountVo,
            recptPromotionAmount,
            recptCouponAmount,
            recptDealAmount,
            recptPointAmount,
            recptPickAmount,
            recptStaffDiscountAmount,
            staffDiscountFlag,
            couponDiscountFlag,
            discountCode,
        } = props;

        //@ts-ignore
        const hasPrice = (price: PriceType | PointType) => price && price.amount !== '0.00';

        return (
            <div className='content-price-detail'>
                <div className='item-title title'>Price Details</div>
                <div className='item-subtotal price-item'>
                    Item Subtotal <Price size={11} price={recptTotalAmountVo} />
                </div>
                {hasPrice(recptTotalDiscountAmountVo) && (
                    <div className='promotion-subtotal price-item'>
                        Total Discount <Price size={11} price={recptTotalDiscountAmountVo} />
                    </div>
                )}

                {/* item discount */}
                {hasPrice(recptPromotionAmount) && (
                    <div className='item-discount price-item'>
                        Item Discount
                        <Price
                            size={11}
                            price={recptPromotionAmount}
                            className='discount-price'
                            showNegative
                        />
                    </div>
                )}

                {/* 普通折扣 酒店折扣 */}
                {hasPrice(recptCouponAmount) && couponDiscountFlag && (
                    <div className='price-item item-discount'>
                        {discountCode ? `Promotional Code (${discountCode})` : 'Coupon'}
                        <Price
                            size={11}
                            price={recptCouponAmount}
                            className='discount-price'
                            showNegative
                        />
                    </div>
                )}
                {/* 员工折扣 */}
                {staffDiscountFlag && (
                    <div className='price-item item-discount'>
                        Staff Discount
                        <Price
                            size={11}
                            price={recptStaffDiscountAmount}
                            className='discount-price'
                            showNegative
                        />
                    </div>
                )}
                <div className='grand-total price-item'>
                    Grand Total
                    <Price size={11} price={recptDealAmount} />
                </div>
                {/* Loyal T价格 */}
                {hasPrice(recptPointAmount) && (
                    <div className='loyal-redemption price-item'>
                        Loyal T Redemption ({recptPointAmount.point} Points)
                        <span>
                            - <Price size={11} price={recptPointAmount} />
                        </span>
                    </div>
                )}

                <div className='paid-amount price-item'>
                    Actual Paid Amount
                    <Price size={14} price={recptPickAmount} />
                </div>
            </div>
        );
    };

    // 机构映射
    const formatDivision = (id: string) => {
        let divisionName: string;
        switch (id) {
            case '1001':
                divisionName = 'Macau';
                break;
            case '1002':
                divisionName = 'Hong Kong';
                break;
            default:
                divisionName = '';
                break;
        }
        return divisionName;
    };

    return (
        <div className='order-receipt'>
            <header className='header'>
                <img
                    className='header-logo'
                    src={require('@/resources/images/logo-dfsT.png')}
                    alt=''
                />
                <h3 className='header-division'>
                    T Galleria by DFS, {formatDivision(props.organization.id)}
                </h3>
                <h4 className='header-title'>SALES INVOICE</h4>
            </header>
            <div className='content'>
                {renderOrderInfo()}
                {renderPickupDetail()}
                {renderProductDetail()}
                {renderPromotionalCode()}
                {renderAdditionalOffer()}
                {renderPriceDetail()}
            </div>
            <footer className='footer'>
                <div className='footer-title'>Thank you for Shopping at DFS!</div>
                <div className='footer-context'>
                    Yoy may return or exchange your purchase within 90 days of the date stated on
                    this receipt. Merchandise purchase on sale, clearance, or other special offers
                    cannot be returned or exchanged. Visit DFS.com for full details. For Loyal T By
                    DFS Members: * Some points adjustments may take 7 days to reflect in your
                    account.
                </div>
            </footer>
        </div>
    );
};

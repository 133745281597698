import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';

import { buildPropertiesByLanguage } from '@/services/language-utils';

import { buildForm } from './payment-mode-form';

export const config: Config = {
    entities: {
        paymentModeEdit: {
            apiPath: '/loader/paymentMode/edit',
            properties: {
                id: {
                    type: 'string',
                },
                internalName: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.internalName>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.code>>',
                },
                channelId: {
                    type: 'string.options.select',
                    displayName: '<<systemManagement.paymentMode.channelName>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath: '/dc-master-data/admin/onlineStore/simpleChannel',
                        },
                    },
                },
                ...buildPropertiesByLanguage('nameLanguageList', {
                    type: 'string.text.paragraph',
                    displayName: '<<systemManagement.paymentMode.name>>',
                }),
                sequence: {
                    type: 'number.integer',
                    displayName: '<<systemManagement.paymentMode.sequence>>',
                    controlConfig: {
                        placeholder: '<<displayCategory.orderPlaceholder>>',
                        min: 1,
                        max: 99999,
                    },
                },
                ...buildPropertiesByLanguage('descLanguageList', {
                    type: 'string.text.paragraph',
                    displayName: '<<systemManagement.paymentMode.desc>>',
                }),
                descTime: {
                    type: 'object.dateTimeRange',
                    displayName: '<<systemManagement.paymentMode.descTime>>',
                },
                installmentFlag: {
                    type: 'string.options.radio',
                    displayName: '<<systemManagement.paymentMode.installmentFlag>>',
                    options: [
                        {
                            id: '0',
                            name: '<<systemManagement.paymentMode.notAllow>>',
                        },
                        {
                            id: '2',
                            name: '<<systemManagement.paymentMode.onlyAllow>>',
                        },
                        {
                            id: '1',
                            name: '<<systemManagement.paymentMode.allow>>',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 375,
                        },
                    },
                },
                offlineFlag: {
                    type: 'string.options.radio',
                    displayName: '<<systemManagement.paymentMode.offlineFlag>>',
                    options: [
                        {
                            id: '1',
                            name: '<<yes>>',
                        },
                        {
                            id: '0',
                            name: '<<no>>',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 375,
                        },
                    },
                },
                icon: {
                    type: 'array.brandImage',
                    displayName: '<<systemManagement.paymentMode.logo>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 3 * 1024,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: services.interpolate(
                            services.language.getText('uploadImageVideo.error.img.size'),
                            { maxSize: 3 }
                        ),
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                        limitTips: {
                            className: 'hidden',
                        },
                        accept: 'image/png,image/jpg,image/jpeg',
                    },
                },
            },
        },
    },
    components: {
        PaymentModeAddPage: {
            component: 'Card',
            entity: 'paymentModeEdit',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        PaymentModeEditPage: {
            component: 'Card',
            entity: 'paymentModeEdit',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
    },
};

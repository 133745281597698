import * as React from 'react';
import { modes } from '@comall-backend-builder/types';

import { Input } from 'antd';

export class NewStringPasswordMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <Input.Password {...controlInfo} autoComplete='new-password' />;
    }
}

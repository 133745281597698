/*
 * @Author: 朱璐
 * @Date: 2022-10-21 17:36:20
 * @Description: 用户相关状态
 */
import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';
/**
 * 店员身份值
 */
export enum USER_JOB_VALUES {
    /**
     * 店员
     */
    CLERK = '0',
    /**
     * 店长
     */
    MANAGER = '1',
}
/**
 * 店员身份NAME
 */
export const USER_JOB_NAMES = {
    [USER_JOB_VALUES.CLERK]: language.getText('salesclerk.clerk'),
    [USER_JOB_VALUES.MANAGER]: language.getText('salesclerk.manager'),
};
/**
 * 店员身份候选项
 */
export const USER_JOB_OPTIONS = generateOptions(USER_JOB_VALUES, USER_JOB_NAMES);

/**
 * 会员类型
 */
export enum MEMBER_SPECIAL_TYPE_VALUES {
    /** 普通 */
    NORMAL = '0',
    /** 特殊 */
    SPECIAL = '1',
}
export const MEMBER_SPECIAL_TYPE_NAMES = {
    [MEMBER_SPECIAL_TYPE_VALUES.NORMAL]: language.getText('membershipLevel.type.normal'),
    [MEMBER_SPECIAL_TYPE_VALUES.SPECIAL]: language.getText('membershipLevel.type.special'),
};
export const MEMBER_SPECIAL_TYPE_OPTIONS = generateOptions(
    MEMBER_SPECIAL_TYPE_VALUES,
    MEMBER_SPECIAL_TYPE_NAMES
);

/** 等级审核状态 */
export enum MEMBER_EXAMINE_STATUS {
    /** 待审核 */
    PENDING = '0',
    /** 审核通过 */
    APPROVED = '1',
    /** 审核未通过 */
    REJECTED = '2',
}
export const MEMBER_EXAMINE_NAMES = {
    [MEMBER_EXAMINE_STATUS.PENDING]: language.getText('memberExamine.status.pending'),
    [MEMBER_EXAMINE_STATUS.APPROVED]: language.getText('memberExamine.status.approved'),
    [MEMBER_EXAMINE_STATUS.REJECTED]: language.getText('memberExamine.status.rejected'),
};

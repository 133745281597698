import React, { PureComponent } from 'react';
import {
    Form as AntForm,
    Input as AntInput,
    Button,
    InputNumber,
    Radio,
    DatePicker,
    TimePicker,
    Cascader,
    Modal as AntModal,
} from 'antd';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services/language';
// import { trim } from 'lodash';
import { GroupItems } from './group-items';
import SelectAutoComplete from '@/components/select-auto-complete';
import moment from 'moment';
import AccessModal from '@/containers/offline-order/offline-create-basic-form/access-modal';

interface OrderInfoProductProps {
    [key: string]: any;
}

interface StorePickTime {
    pickDate: string;
    startPickTime: string;
    endPickTime: string;
}

interface State {
    storeTimeList: StorePickTime[];
    residences: any[];
    disabledCouponNo: boolean;
}
const deliveryTypeOptions = [
    { label: language.getText('offlineOrder.create.immediatelyInStore'), value: 1 },
    { label: language.getText('offlineOrder.create.appointmentInStore'), value: 2 },
    { label: language.getText('offlineOrder.create.homeDelivery'), value: 3 },
];

const pickUpNameOptions = [
    { label: language.getText('offlineOrder.create.selfPacking'), value: 1 },
    { label: language.getText('offlineOrder.create.otherPacking'), value: 2 },
];

/**
 * 创建订单（线下） - 表单
 */
class offlineCreateBasicForm extends PureComponent<OrderInfoProductProps, State> {
    state: State = {
        storeTimeList: [],
        residences: [],
        disabledCouponNo: false,
    };
    componentDidMount(): void {
        services.api
            .get(
                {},
                {
                    apiPath: `/dc-goods/admin/store/freight/getDeliveryRegion`,
                }
            )
            .then((res: any) => {
                const list = res.region[0].children.map((item: any) => ({
                    value: item.name,
                    label: item.name,
                    children: item.children.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                    })),
                }));
                this.setState({ residences: list });
            })
            .catch(services.errorHandle);
    }
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: any) => {
            if (!err) {
                const params = {
                    online_store_id: this.props.params?.onlineStoreIds,
                    member_id: this.props?.memberInfo?.memberId,
                    items: values.cskuGroups?.map((item: any) => ({
                        csku: item.csku,
                        price: item.price,
                        quantity: item.purchasesNum,
                        rsku: item.rsku,
                        override_code: item.overrideCode,
                        override_code_type: item.codeType,
                        executePrice: item.overrideMoney || item.price,
                    })),
                    consignee_name: values.consignee_name,
                    consignee_mobile: values.consignee_mobile,
                    freight_fee_amount: values.freight_fee_amount,
                    coupon_no: values?.coupon_no?.id,
                    point: values.point,
                    cash_coupon_amount: values.cash_coupon_amount,
                    delivery_flag: values.delivery_flag - 2,
                    accessCode: '',
                    pick_up_info:
                        values.delivery_flag !== 3
                            ? {
                                  pickOnlineStoreId: values.pick_up_store_id?.id,
                                  pick_up_date: moment(values.pick_up_date).format('YYYY-MM-DD'),
                                  pick_up_start_time: moment(values.pickingTime.start).format(
                                      'HH:mm:ss'
                                  ),
                                  pick_up_end_time: moment(values.pickingTime.end).format(
                                      'HH:mm:ss'
                                  ),
                              }
                            : undefined,
                    delivery_info:
                        values.delivery_flag === 3
                            ? {
                                  area: values.area[0],
                                  region: values.area[1],
                                  detail_address: values.detail_address,
                                  delivery_date: moment(values.delivery_date).format('YYYY-MM-DD'),
                                  delivery_start_time: moment(values.deliveryTime.start).format(
                                      'HH:mm:ss'
                                  ),
                                  delivery_end_time: moment(values.deliveryTime.end).format(
                                      'HH:mm:ss'
                                  ),
                              }
                            : undefined,
                };
                const createOffline = () => {
                    services.api
                        .put(params, {
                            apiPath: `/base-order/admin/order/offline_create`,
                        })
                        .then((res) => {
                            this.props.entity.setFields({
                                ...this.props.entity.fields,
                                memberInfo: {},
                            });
                            services.navigation.goto('/offlineOrders');
                        })
                        .catch((e) => {
                            services.errorHandle(e);
                        });
                };
                //TODO 获取角色职位，后优化
                const userJob = window.sessionStorage.getItem('userJob');
                const userJobKey = userJob && JSON.parse(userJob)?.key;
                if (userJobKey !== 1 && params.items?.some((i: any) => !!i.override_code)) {
                    const getValue = (value: string) => {
                        accessCode = value;
                    };
                    let accessCode = '';
                    AntModal.confirm({
                        icon: null,
                        title: (
                            <div style={{ textAlign: 'center' }}>
                                {services.language.getText('offlineOrder.requiredAuthorization')}
                            </div>
                        ),
                        content: <AccessModal getValue={getValue} />,
                        onOk() {
                            params.accessCode = accessCode;
                            createOffline();
                        },
                    });
                } else {
                    createOffline();
                }
            }
        });
    };
    handleCancel = () => {
        this.props.entity.setFields({ ...this.props.entity.fields, memberInfo: {} });
        services.navigation.goBack();
    };
    // 更改提货门店
    changePickUpStore = (val: any) => {
        services.api
            .get(
                {
                    orderTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                },
                {
                    apiPath: `/dc-store/admin/pickOnlineStore/findAvailableDate/${val.id}`,
                }
            )
            .then((res: any) => {
                this.setState({
                    storeTimeList: res as StorePickTime[],
                });
                this.autoCompletePickTime();
            })
            .catch(services.errorHandle);
    };

    // 自动填充收货时间
    autoCompletePickTime = () => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        setTimeout(() => {
            if (getFieldValue('delivery_flag') === 1 && this.state.storeTimeList[0]) {
                setFieldsValue({
                    pick_up_date: moment(this.state.storeTimeList[0].pickDate, 'YYYY-MM-DD'),
                });
                setFieldsValue({
                    pickingTime: {
                        start: moment(this.state.storeTimeList[0].startPickTime, 'HH:mm:ss'),
                        end: moment(this.state.storeTimeList[0].endPickTime, 'HH:mm:ss'),
                    },
                });
            }
        }, 0);
    };

    // 禁用CouponNo
    disabledCouponNo = () => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        setTimeout(() => {
            if (getFieldValue('cskuGroups')?.some((item: any) => item.overrideCode)) {
                setFieldsValue({ coupon_no: undefined });
                this.setState({
                    disabledCouponNo: true,
                });
            } else {
                this.setState({
                    disabledCouponNo: false,
                });
            }
        }, 0);
    };
    // 禁用收货日期
    disabledPickDate = (current: any) => {
        return !this.state.storeTimeList.some(
            (i) => moment(current).format('YYYY-MM-DD') === i.pickDate
        );
    };
    // 禁用收货时间
    disabledPickTime = () => {
        const { getFieldValue } = this.props.form;
        const dateOption = this.state.storeTimeList.find(
            (item) => item.pickDate === moment(getFieldValue('pick_up_date')).format('YYYY-MM-DD')
        );
        if (dateOption) {
            const startTimeHours = Number(dateOption.startPickTime.split(':')[0]);
            const endTimeHours = Number(dateOption.endPickTime.split(':')[0]);
            const disabledHoursList = Array.from({ length: 24 })
                .map((_, i) => i)
                .filter((i) => i < startTimeHours || i >= endTimeHours);
            return disabledHoursList;
        } else {
            return null;
        }
    };

    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const { memberInfo, params } = this.props;

        return (
            <>
                <AntForm
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    style={{ padding: '25px 0 25px' }}
                    onSubmit={this.handleSubmit}
                    className='offline-create-basic-form'
                >
                    {/* 商品信息 */}
                    <div className='offline-order-create-title'>
                        {language.getText('offlineOrder.create.goodsInfo')}
                    </div>
                    <AntForm.Item label={language.getText('productAdd.basicMessage.selectProduct')}>
                        {getFieldDecorator('cskuGroups', {
                            rules: [
                                {
                                    required: true,
                                    message: services.language.getText('defaultPlaceholderSelect'),
                                },
                            ],
                        })(
                            <GroupItems
                                onlineStoreIds={params?.onlineStoreIds}
                                onChange={(e: any) => {
                                    this.disabledCouponNo();
                                }}
                            />
                        )}
                    </AntForm.Item>
                    {/* 配送信息 */}
                    <div className='offline-order-create-title'>
                        {language.getText('offlineOrder.create.deliveryInfo')}
                    </div>
                    {/* 选择送货方式 */}
                    <AntForm.Item
                        label={language.getText('offlineOrder.create.selectDeliveryType')}
                    >
                        {getFieldDecorator('delivery_flag', { initialValue: 1 })(
                            <Radio.Group
                                options={deliveryTypeOptions}
                                onChange={this.autoCompletePickTime}
                            />
                        )}
                    </AntForm.Item>
                    {getFieldValue('delivery_flag') !== 3 ? (
                        <>
                            {/* 自取门店 */}
                            <AntForm.Item
                                label={language.getText('offlineOrder.create.pickingStore')}
                            >
                                {getFieldDecorator('pick_up_store_id', {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText(
                                                'offlineOrder.tips.pickingStore'
                                            ),
                                        },
                                    ],
                                })(
                                    <SelectAutoComplete
                                        placeholder={services.language.getText(
                                            'defaultPlaceholderSelect'
                                        )}
                                        apiPath='/dc-store/admin/pickOnlineStore/findByOnlineStore'
                                        params={{ onlineStoreId: params?.onlineStoreIds }}
                                        selectParamKey='keyword'
                                        formatResponse={(response: any) => {
                                            const result = response.map((item: any) => ({
                                                id: item.id,
                                                pickStoreId: item.pickStoreId,
                                                name: item.pickStoreName,
                                            }));
                                            return result;
                                        }}
                                        onChange={this.changePickUpStore}
                                    />
                                )}
                            </AntForm.Item>
                            {/* 取货日期 */}
                            <AntForm.Item
                                label={language.getText('offlineOrder.create.pickingDate')}
                            >
                                {getFieldDecorator('pick_up_date', {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText(
                                                'offlineOrder.tips.pickingDate'
                                            ),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        style={{ width: 600 }}
                                        disabled={getFieldValue('delivery_flag') === 1}
                                        placeholder={services.language.getText('order.pickDate')}
                                        disabledDate={this.disabledPickDate}
                                        // onChange={this.dateChange}
                                        format={'YYYY-MM-DD'}
                                    />
                                )}
                            </AntForm.Item>
                            {/* 取货时间 */}
                            <AntForm.Item
                                label={language.getText('offlineOrder.create.pickingTime')}
                            >
                                {getFieldDecorator('pickingTime', {
                                    initialValue: { start: null, end: null },
                                    rules: [
                                        {
                                            validator: (rule: any, value: any, callback: any) => {
                                                try {
                                                    if (!value.start || !value.end) {
                                                        callback(
                                                            new Error(
                                                                language.getText(
                                                                    'offlineOrder.tips.pickingTime'
                                                                )
                                                            )
                                                        );
                                                    } else {
                                                        callback();
                                                    }
                                                } catch (err) {
                                                    callback(err);
                                                }
                                            },
                                        },
                                    ],
                                })(
                                    //@ts-ignore
                                    <DataTimeRange
                                        disabled={
                                            getFieldValue('delivery_flag') === 1 ||
                                            !getFieldValue('pick_up_date')
                                        }
                                        disabledHours={this.disabledPickTime}
                                    />
                                )}
                            </AntForm.Item>
                        </>
                    ) : null}
                    {getFieldValue('delivery_flag') === 3 ? (
                        <>
                            {/* 送货区域 */}
                            <AntForm.Item
                                label={language.getText('offlineOrder.create.deliveryArea')}
                            >
                                {getFieldDecorator('area', {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText(
                                                'offlineOrder.tips.deliveryArea'
                                            ),
                                        },
                                    ],
                                })(<Cascader options={this.state.residences} />)}
                            </AntForm.Item>
                            {/* 详细地址 */}
                            <AntForm.Item label={language.getText('offlineOrder.create.address')}>
                                {getFieldDecorator('detail_address', {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText('offlineOrder.tips.address'),
                                        },
                                    ],
                                })(
                                    <AntInput
                                        placeholder={language.getText('defaultPlaceholderInput')}
                                        maxLength={200}
                                    />
                                )}
                            </AntForm.Item>
                            {/* 送货日期 */}
                            <AntForm.Item
                                label={language.getText('offlineOrder.create.deliveryDate')}
                            >
                                {getFieldDecorator('delivery_date', {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText(
                                                'offlineOrder.tips.deliveryDate'
                                            ),
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        style={{ width: 600 }}
                                        // defaultValue={moment('2015-06-06', dateFormat)}
                                        placeholder={services.language.getText('order.pickDate')}
                                        // disabledDate={disabledDate}
                                        // onChange={this.dateChange}
                                        format={'YYYY-MM-DD'}
                                    />
                                )}
                            </AntForm.Item>
                            {/* 送货时间 */}
                            <AntForm.Item
                                label={language.getText('offlineOrder.create.deliveryTime')}
                            >
                                {getFieldDecorator('deliveryTime', {
                                    initialValue: { start: null, end: null },
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText(
                                                'offlineOrder.tips.deliveryTime'
                                            ),
                                        },
                                    ],
                                })(
                                    //@ts-ignore
                                    <DataTimeRange disabled={!getFieldValue('delivery_date')} />
                                )}
                            </AntForm.Item>
                        </>
                    ) : null}
                    {/* 收货人 */}
                    <AntForm.Item label={language.getText('order.orderReceipt.pickUpName')}>
                        {getFieldDecorator('pickUpType', { initialValue: 2 })(
                            <Radio.Group
                                options={pickUpNameOptions}
                                onChange={(e) => {
                                    if (e.target.value === 1) {
                                        setFieldsValue({
                                            consignee_name: memberInfo?.memberId
                                                ? memberInfo?.surname + memberInfo?.name
                                                : undefined,
                                        });
                                        setFieldsValue({ consignee_mobile: memberInfo?.phone });
                                    }
                                }}
                            />
                        )}
                    </AntForm.Item>
                    {/* 收货人姓名 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.pickUpName')}>
                        {getFieldDecorator('consignee_name', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.pickUpName'),
                                },
                            ],
                        })(
                            <AntInput
                                disabled={getFieldValue('pickUpType') === 1}
                                placeholder={language.getText('defaultPlaceholderInput')}
                            />
                        )}
                    </AntForm.Item>
                    {/* 收货人联系方式 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.pickUpPhone')}>
                        {getFieldDecorator('consignee_mobile', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.pickUpPhone'),
                                },
                            ],
                        })(
                            <AntInput
                                disabled={getFieldValue('pickUpType') === 1}
                                placeholder={language.getText('defaultPlaceholderInput')}
                                maxLength={15}
                            />
                        )}
                    </AntForm.Item>
                    {/* 运费 */}
                    <AntForm.Item label={language.getText('freight')}>
                        {getFieldDecorator('freight_fee_amount', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.freight'),
                                },
                            ],
                        })(
                            <InputNumber
                                placeholder={services.language.getText(
                                    'offlineOrder.placeholder.payAccount'
                                )}
                                type='number'
                                min={0}
                                step={0.01}
                                precision={2}
                                max={99999999}
                            />
                        )}
                    </AntForm.Item>
                    {/* COUPON信息 */}
                    <div className='offline-order-create-title'>
                        {language.getText('offlineOrder.create.coupon')}
                    </div>
                    {/* Coupon */}
                    <AntForm.Item label={language.getText('offlineOrder.create.coupon')}>
                        {getFieldDecorator('coupon_no')(
                            <SelectAutoComplete
                                disabled={this.state.disabledCouponNo}
                                placeholder={
                                    this.state.disabledCouponNo
                                        ? '订单已使用OVERRIDCODE'
                                        : services.language.getText('defaultPlaceholderSelect')
                                }
                                apiPath={`/dc-ecoupon/admin/coupons/findByStore/${this.props.params?.onlineStoreIds}`}
                                params={{ page: 1, perPage: 10 }}
                                selectParamKey='name'
                                formatResponse={(response: any) => {
                                    let result =
                                        response?.map((item: any) => ({
                                            id: item.id,
                                            name: item.name,
                                        })) || [];
                                    return result;
                                }}
                            />
                        )}
                    </AntForm.Item>
                    {/* 折扣信息 */}
                    <div className='offline-order-create-title'>
                        {language.getText('offlineOrder.create.discountInfo')}
                    </div>
                    {/* 积分折扣 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.pointDiscount')}>
                        {getFieldDecorator('point')(
                            <InputNumber
                                placeholder={services.language.getText(
                                    'offlineOrder.placeholder.payAccount'
                                )}
                                type='number'
                                min={0.0}
                                precision={2}
                                max={99999999}
                            />
                        )}
                    </AntForm.Item>
                    {/* 代金券折扣 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.voucherDiscount')}>
                        {getFieldDecorator('cash_coupon_amount')(
                            <InputNumber
                                placeholder={services.language.getText(
                                    'offlineOrder.placeholder.payAccount'
                                )}
                                type='number'
                                min={0.0}
                                precision={2}
                                max={99999999}
                            />
                        )}
                    </AntForm.Item>
                    <AntForm.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button style={{ marginRight: 30 }} onClick={this.handleCancel}>
                            {language.getText('cancel')}
                        </Button>
                        <Button type='primary' htmlType='submit'>
                            {language.getText('offlineOrder.create.submitOrder')}
                        </Button>
                    </AntForm.Item>
                </AntForm>
            </>
        );
    }
}
const offlineCreateBasicFormInstance = AntForm.create()(offlineCreateBasicForm);

const mapStateToProps = (state: any, ownProps: any) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const OfflineCreateBasicForm = connect(
    mapStateToProps,
    null
)(offlineCreateBasicFormInstance);

const DataTimeRange = React.forwardRef((props: any) => {
    const { value, onChange, disabled, disabledHours } = props;
    const { start, end } = value;
    const change = (val: any, type: 'start' | 'end') => {
        onChange({ ...value, [type]: val });
    };
    return (
        <div style={{ display: 'flex' }}>
            <TimePicker
                disabled={disabled}
                disabledHours={disabledHours}
                style={{ width: 200 }}
                format='HH:mm:ss'
                value={start}
                placeholder='开始时间'
                onChange={(value, dateString) => change(value, 'start')}
            />
            <TimePicker
                style={{ width: '200px', marginLeft: '24px' }}
                disabled={disabled}
                disabledHours={disabledHours}
                format='HH:mm:ss'
                value={end}
                placeholder='结束时间'
                onChange={(value, dateString) => change(value, 'end')}
            />
        </div>
    );
});

import React from 'react';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message, Modal } from 'antd';
import { isEmpty } from 'lodash';
import {
    tableMixin,
    getLanguageColumns,
    getLanguageProperties,
    formFooter,
    deletePopConfirmConfig,
} from '@/configs/mixin';
import { eventCenter } from '@/services/events';
import { DISPLAY_CATEGORY_TABLE } from '@/configs/pro-table-constants';
import { getStoreInfo } from '@/services/utils';
import { IMPORT_TEMPLATE_URL, RECOMMEND_PRODUCT_TYPE } from '@/constants';
import { createRecommendProductComponents } from '@/configs/product/recommend-product';
import { recordImportLogs } from '@/services';

const picLangMap = {
    zh: '',
};

const displayCategoryInfo = {
    nameTip: {
        displayName: '<<displayCategory.nameTips>>',
        type: 'string',
    },
    name: {
        properties: getLanguageProperties('string', 'displayCategory.name', {
            controlConfig: {
                maxLength: 100,
                placeholder: '<<displayCategory.namePlaceholder>>',
            },
            rules: [
                {
                    required: true,
                    message: '<<displayCategory.nameRequired>>',
                },
                {
                    whitespace: true,
                    message: '<<displayCategory.nameRequired>>',
                },
            ],
        }),
        type: 'object.subForm',
    },
    id: {
        type: 'string',
        displayName: '<<displayCategory.id>>',
    },
    parent: {
        type: 'object.treeSelect.async',
        displayName: '<<displayCategory.parentId>>',
        options: [],
        source: {
            apiPath:
                '/loader/guide-cms-service/admin/display_categories/categorys_tree/categoryForm',
        },
        controlConfig: {
            allowClear: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
        rules: [{ required: true }],
    },
    categorysList: {
        type: 'array.treeSelect.async',
        displayName: '<<displayCategory.saleCategories>>',
        options: [],
        source: {
            apiPath: '/loader/guide-cms-service/categorys/tree',
        },
        controlConfig: {
            placeholder: '<<defaultPlaceholderSelect>>',
            allowClear: true,
            params: { id: '-1' },
            rootId: '-1',
        },
    },
    sequence: {
        type: 'number.integer',
        displayName: '<<displayCategory.order>>',
        controlConfig: {
            placeholder: '<<displayCategory.orderPlaceholder>>',
            min: 0,
            max: 999999,
        },
        rules: [{ required: true }],
    },
    virtualCategorysList: {
        type: 'array.treeSelect.async',
        displayName: '<<displayCategory.virtualCategories>>',
        options: [],
        source: {
            apiPath:
                '/loader/guide-cms-service/admin/virtual_categorys/categorys_tree/categoryForm',
            paramsFilter: [],
        },

        controlConfig: {
            placeholder: '<<defaultPlaceholderSelect>>',
            allowClear: true,
        },
    },
    status: {
        type: 'object.option.radio.state',
        displayName: '<<displayCategory.status>>',
        options: [
            {
                id: '1',
                name: '<<enable>>',
            },
            {
                id: '0',
                name: '<<disable>>',
            },
        ],
        defaultValue: '1',
        rules: [{ required: true }],
    },
    details: {
        properties: getLanguageProperties('string.text.paragraph', 'displayCategory.desc', {
            controlConfig: {
                placeholder: '<<displayCategory.descPlaceholder>>',
            },
        }),
        type: 'object.subForm',
    },
    picturePath: {
        properties: getLanguageProperties(
            'string.image.picker.local',
            'displayCategory.picture',
            {
                controlConfig: {
                    fileName: 'fileName',
                    uploadType: 'single',
                    uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    mode: 'picture-card',
                    maxCount: 1,
                    // 这里重新定义超过文件大小后的message，原因是uploadFile接口的onUploadComplete只有成功的数量与失败的数量
                    maxSize: 5 * 1024,
                    uploadLimitSizeErrorMessage: '<<displayCategory.imageOverstep>>',
                    limitTips: {
                        className: 'hidden',
                    },
                },
            },
            picLangMap
        ),
        type: 'object.subForm',
    },
    pcPicturePath: {
        properties: getLanguageProperties(
            'string.image.picker.local',
            'displayCategory.pcPicture',
            {
                controlConfig: {
                    fileName: 'fileName',
                    uploadType: 'single',
                    uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    mode: 'picture-card',
                    maxCount: 1,
                    // 这里重新定义超过文件大小后的message，原因是uploadFile接口的onUploadComplete只有成功的数量与失败的数量
                    maxSize: 5 * 1024,
                    uploadLimitSizeErrorMessage: '<<displayCategory.imageOverstep>>',
                    limitTips: {
                        className: 'hidden',
                    },
                },
            },
            picLangMap
        ),
        type: 'object.subForm',
    },
    bannerPicturePath: {
        properties: getLanguageProperties(
            'string.image.picker.local',
            'displayCategory.bannerPicturePath',
            {
                controlConfig: {
                    fileName: 'fileName',
                    uploadType: 'single',
                    uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    mode: 'picture-card',
                    maxCount: 1,
                    // 这里重新定义超过文件大小后的message，原因是uploadFile接口的onUploadComplete只有成功的数量与失败的数量
                    maxSize: 5 * 1024,
                    uploadLimitSizeErrorMessage: '<<displayCategory.imageOverstep>>',
                    limitTips: {
                        className: 'hidden',
                    },
                },
            },
            picLangMap
        ),
        type: 'object.subForm',
    },
    pcBannerPicturePath: {
        properties: getLanguageProperties(
            'string.image.picker.local',
            'displayCategory.pcBannerPicturePath',
            {
                controlConfig: {
                    fileName: 'fileName',
                    uploadType: 'single',
                    uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    mode: 'picture-card',
                    maxCount: 1,
                    // 这里重新定义超过文件大小后的message，原因是uploadFile接口的onUploadComplete只有成功的数量与失败的数量
                    maxSize: 5 * 1024,
                    uploadLimitSizeErrorMessage: '<<displayCategory.imageOverstep>>',
                    limitTips: {
                        className: 'hidden',
                    },
                },
            },
            picLangMap
        ),
        type: 'object.subForm',
    },
    bannerTargetUrl: {
        type: 'string',
        displayName: '<<displayCategory.bannerUrl>>',
        controlConfig: { maxLength: 100, placeholder: '<<displayCategory.bannerPlaceholder>>' },
        rules: [
            {
                pattern: /(https):\/\/([\w.]+\/?)\S*/,
                message: '<<displayCategory.bannerPlaceholder>>',
            },
        ],
    },
    date: {
        type: 'object.dateTimeRange',
        displayName: '<<displayCategory.effectiveTime>>',
        controlConfig: {
            style: { width: '100%' },
        },
    },
};
export const config: Config = {
    entities: {
        displayCategory: {
            apiPath: '/loader/guide-cms-service/admin/display_categorys',
            properties: {
                name: {
                    properties: getLanguageProperties('string', 'displayCategory.name', {
                        controlConfig: {
                            placeholder: '<<displayCategory.namePlaceholder>>',
                        },
                        rules: [
                            {
                                required: true,
                                message: '<<displayCategory.nameRequired>>',
                            },
                            {
                                whitespace: true,
                                message: '<<displayCategory.nameRequired>>',
                            },
                        ],
                    }),
                    type: 'object.subForm',
                },
                id: {
                    type: 'string',
                    displayName: '<<displayCategory.id>>',
                },
                sequence: {
                    type: 'number.integer',
                    displayName: '<<displayCategory.order>>',
                    controlConfig: {
                        placeholder: '<<displayCategory.orderPlaceholder>>',
                        min: 0,
                        max: 999999,
                    },
                    rules: [{ required: true }],
                },
                status: {
                    type: 'object.option.radio.state',
                    displayName: '<<displayCategory.status>>',
                    options: [
                        {
                            id: '1',
                            name: '<<enable>>',
                        },
                        {
                            id: '0',
                            name: '<<disable>>',
                        },
                    ],
                    defaultValue: '1',
                    rules: [{ required: true }],
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<displayCategory.keyWords>>',
                    controlConfig: {
                        placeholder: '<<displayCategory.keyWordsPlaceholder>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<displayCategory.status>>',
                    options: [
                        { id: '0', name: '<<displayCategory.forbidden>>' },
                        { id: '1', name: '<<displayCategory.enable>>' },
                        { id: '2', name: '<<displayCategory.all>>' },
                    ],
                    defaultValue: '1',
                    controlConfig: {
                        allowClear: true,
                        style: { width: 100 },
                    },
                },
            },
        },
        displayCategoryInfo: {
            apiPath: '/loader/guide-cms-service/admin/display_categorys',
            properties: displayCategoryInfo,
        },
    },
    components: {
        DisplayCategoryContainer: {
            component: 'Viewport',
        },
        DisplayCategoryManagement: {
            component: 'Card',
            entity: 'displayCategory',
            content: {
                component: 'CategorySelectorContainer',
                treeSource: '/loader/guide-cms-service/admin/display_categories/categorys_tree',
                rightComponent: 'DisplayCategoryContent',
                listPrivilege: {
                    path: 'category.display_categorys.view_display_categorys_list_tree',
                    level: 'full',
                },
            },
        },
        DisplayCategoryHeader: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'flex',
                flex: '1',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: 10,
            },
            items: [
                {
                    component: 'DisplayCategoryFilter',
                },
            ],
        },
        DisplayCategoryCreateButton: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
            },
            items: [
                {
                    component: 'Button',
                    text: '<<displayCategory.add>>',
                    icon: 'plus',
                    type: 'primary',

                    style: {
                        flexGrow: 0,
                    },
                    onClick: () => {
                        //先判断是否选择过store
                        if (!isEmpty(getStoreInfo())) {
                            return services.navigation.goto('/displayCategory/add');
                        }
                        message.warn('请选择门店');
                    },
                    privilege: {
                        path: 'category.display_categorys.add_category',
                        level: 'full',
                    },
                },
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<displayCategory.exportDisplay>>',
                    icon: 'download',
                    style: {
                        marginLeft: 15,
                    },
                    source: {
                        apiPath: `/guide-cms-service/admin/display_categorys/export/displayCategory/take`,
                    },
                    range: {
                        apiPath:
                            '/guide-cms-service/admin/display_categorys/export/displayCategory/count',
                        perPage: 100000,
                    },
                    formatter: (params: any) => {
                        if (!params.parentId) params.parentId = 1;
                        if (params.status === '2') delete params.status;
                        return params;
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: `category.display_categorys.export_dispaly_category`,
                        level: 'full',
                    },
                },
                {
                    component: 'Upload',
                    apiPath: '/dc-file/excels?location_id=2',
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<displayCategory.importDisplay>>',
                        style: {
                            marginLeft: 15,
                            marginRight: 15,
                        },
                    },
                    onUploadComplete(response: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('productList.productInfo'),
                                apiPath: `/guide-cms-service/admin/display_categorys/excel/importData`,
                                params: {
                                    resourceId: response.id,
                                    onlineStoreId: getStoreInfo().id,
                                },
                            });
                        }
                    },
                    privilege: {
                        path: `category.display_categorys.import_dispaly_category`,
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productList.downloadTemplate>>',
                    type: 'link',
                    privilege: {
                        path: `category.display_categorys.download_display_category_import_template`,
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.DISPLAY}`,
                },
            ],
        },
        DisplayCategoryFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            style: {
                textAlign: 'right',
            },
            reset: true,
            fields: [
                {
                    property: 'status',
                },
                {
                    property: 'keyword',
                },
            ],
        },
        DisplayCategoryContent: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                marginLeft: 15,
            },
            items: [
                {
                    component: 'DisplayCategoryHeader',
                },
                {
                    component: 'DisplayCategoryCreateButton',
                },
                {
                    component: 'DisplayCategoryTable',
                    privilege: {
                        path: 'category.display_categorys.view_display_categorys_list',
                        level: 'full',
                    },
                },
            ],
        },
        DisplayCategoryTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: DISPLAY_CATEGORY_TABLE,
            loadFirstPage: false,
            scroll: {
                x: 'max-content',
            },
            columns: [
                ...getLanguageColumns('name'),
                { property: 'id' },
                { property: 'status' },
                { property: 'sequence' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<common.disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                if (row.admin) {
                                    return {
                                        disabled: true,
                                    };
                                } else {
                                    return {
                                        entity: props.entity,
                                        row,
                                        statusApi: {
                                            '0':
                                                '/guide-cms-service/admin/display_categorys/{{row.id}}/1',
                                            '1':
                                                '/guide-cms-service/admin/display_categorys/{{row.id}}/0',
                                        },
                                        statusText: {
                                            '0': '<<enable>>',
                                            '1': '<<disable>>',
                                        },
                                    };
                                }
                            },
                        },
                        privilege: {
                            path: 'category.display_categorys.enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<virtualCategory.viewProduct>>',
                        type: 'link',
                        path: '/displayCategory/product/{{row.id}}',
                        privilege: {
                            path: 'category.display_categorys.view_goods',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<displayCategory.recommendProduct>>',
                        type: 'link',
                        path: `/displayCategory/recommend-product/${RECOMMEND_PRODUCT_TYPE.DISPLAY_CATEGORY}/{{row.id}}`,
                        privilege: {
                            path: 'category.display_categorys.view_recommend_sku_list',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/displayCategory/edit/{{row.id}}',
                        privilege: {
                            path: 'category.display_categorys.edit',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<delete>>',
                        config: {
                            actionType: 'delete',
                            component: 'TableActionToggleStatus',
                            deleteConfig: {
                                ...deletePopConfirmConfig,
                                render: (row: any, onDelete: any, children: any) => {
                                    const showConfirm = () => {
                                        Modal.confirm({
                                            title: services.language.getText(
                                                'common.areYouSureToDelete'
                                            ),
                                            content:
                                                row.hasChild > 0
                                                    ? services.language.getText(
                                                          'category.deleteParentContent'
                                                      )
                                                    : '',
                                            okText: services.language.getText('common.ok'),
                                            cancelText: services.language.getText('common.cancel'),
                                            onOk() {
                                                onDelete();
                                            },
                                        });
                                    };
                                    return <span onClick={showConfirm}> {children}</span>;
                                },
                                callback: () => {
                                    eventCenter.trigger('tree_refresh', 'hello');
                                },
                            },
                            renderConfig: (row: any, props: any) => {
                                if (row.hasChild === '1') {
                                    return {
                                        disabled: true,
                                    };
                                }
                                return { ...props };
                            },
                        },
                        privilege: {
                            path: 'category.display_categorys.delete',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        DisplayCategoryAddPage: {
            component: 'Card',
            content: {
                component: 'DisplayCategoryAddForm',
            },
        },
        DisplayCategoryAddForm: {
            component: 'CreateForm',
            className: 'none-sub-form category',
            entity: 'displayCategoryInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            onSubmitSuccess: () => {
                eventCenter.trigger('tree_refresh', 'display_category');
                message.success(services.language.getText('successFully'));
                services.navigation.goBack();
            },
            fields: [
                { property: 'nameTip', className: 'nameTip' },
                {
                    property: 'parent',
                },
                ...getLanguageColumns('name'),
                { property: 'categorysList' },
                { property: 'virtualCategorysList' },
                { property: 'sequence' },
                ...getLanguageColumns('details'),
                ...getLanguageColumns('picturePath', {}, picLangMap),
                ...getLanguageColumns('pcPicturePath', {}, picLangMap),
                ...getLanguageColumns('bannerPicturePath', {}, picLangMap),
                ...getLanguageColumns('pcBannerPicturePath', {}, picLangMap),
                { property: 'bannerTargetUrl' },
                { property: 'date' },
                { property: 'status' },
            ],
        },
        // 编辑
        DisplayCategoryEditPage: {
            component: 'Card',
            content: {
                component: 'DisplayCategoryEditForm',
            },
        },
        DisplayCategoryEditForm: {
            component: 'ModifyForm',
            className: 'none-sub-form category',
            loaderType: 'get',
            entity: 'displayCategoryInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            onSubmitSuccess: () => {
                eventCenter.trigger('tree_refresh', 'display_category');
                message.success(services.language.getText('successFully'));
                services.navigation.goBack();
            },
            fields: [
                { property: 'nameTip', className: 'nameTip' },
                {
                    property: 'parent',
                    modifiable: false,
                },
                { property: 'id', modifiable: false },
                ...getLanguageColumns('name'),
                { property: 'categorysList' },
                { property: 'virtualCategorysList' },
                { property: 'sequence' },
                ...getLanguageColumns('details'),
                ...getLanguageColumns('picturePath', {}, picLangMap),
                ...getLanguageColumns('pcPicturePath', {}, picLangMap),
                ...getLanguageColumns('bannerPicturePath', {}, picLangMap),
                ...getLanguageColumns('pcBannerPicturePath', {}, picLangMap),
                { property: 'bannerTargetUrl' },
                { property: 'date' },
                { property: 'status' },
            ],
        },
        ...createRecommendProductComponents('DisplayCategory', 'category.display_categorys'),
    },
};

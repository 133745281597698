import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { get, isFunction } from 'lodash';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ExportButtonProps } from '@comall-backend-builder/components-basis/lib/export-button';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { KV } from '@/interfaces';
import { AsyncExportButton } from '../async-export-button';
import ExportRangeModal, { Range } from '../export-range-modal';

interface IProps extends ExportButtonProps {
    /**
     * 是否为异步
     */
    isAsync?: boolean;
    /***
     * 用于异步导出成功后统一提示
     */
    showSuccessTips?: string;
    /**
     * 实体
     */
    entity: Entity;
    /**
     * 筛选参数
     */
    filterParams: KV;
    /**
     * 分区间导出配置项
     */
    range?: Range;
    /**
     * 参数别名
     */
    paramNameAs?: KV;
    /**
     * 对 filterParams 格式化, 并返回格式化后的 filterParams
     */
    formatter?: (filterParams: KV) => KV;
}

function ExportButtonWithFilterComponent(props: IProps) {
    const {
        isAsync,
        showSuccessTips,
        filterParams,
        params,
        range,
        formatter,
        beforeExport,
        paramNameAs,
        ...buttonProps
    } = props;

    const [showModal, setShowModal] = useState(false);
    let _filterParams = filterParams;
    if (isFunction(formatter)) {
        _filterParams = formatter({ ...filterParams });
    }
    const exportParams = {
        ...params,
        ..._filterParams,
    };

    const mergedParams = useMemo(() => {
        const params = { ...exportParams };
        if (paramNameAs) {
            for (const [name, as] of Object.entries(paramNameAs)) {
                params[as] = params[name];
                params[name] = undefined;
            }
        }
        return params;
    }, [paramNameAs, exportParams]);
    return (
        <>
            {isAsync ? (
                <AsyncExportButton
                    {...buttonProps}
                    params={mergedParams}
                    showSuccessTips
                    beforeExport={async () => {
                        beforeExport && (await beforeExport());
                        if (range) {
                            setShowModal(true);
                            return Promise.reject();
                        }
                        return Promise.resolve();
                    }}
                />
            ) : (
                <ExportButton
                    {...buttonProps}
                    params={mergedParams}
                    beforeExport={async () => {
                        beforeExport && (await beforeExport());
                        if (range) {
                            setShowModal(true);
                            return Promise.reject();
                        }
                        return Promise.resolve();
                    }}
                />
            )}
            <ExportRangeModal
                {...buttonProps}
                showSuccessTips={showSuccessTips}
                range={range}
                visible={showModal}
                params={mergedParams}
                onClose={() => setShowModal(false)}
            />
        </>
    );
}

function mapStateToProps(state: any, props: any) {
    const filterParams = get(props, 'entity.filters', {});
    return {
        filterParams,
    };
}

/**
 * 携带筛选参数的导出按钮
 */
export const ExportButtonWithFilter = connect(
    mapStateToProps,
    null
)(ExportButtonWithFilterComponent);

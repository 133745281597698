import React, { createElement, Component } from 'react';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { message as AntMessage, Button, Modal } from 'antd';
import lodashGet from 'lodash/get';
import { tableMixin } from '@/configs/mixin';

const preDefinedComponentId = 'store-selector-' + services.uuid();

export class ProductBasic extends Component {
    state = {
        visible: false,
    };
    // 打开查看日志的modal
    openLogo = async () => {
        this.setState({
            visible: true,
        });
    };
    hideModal = () => {
        this.setState({ visible: false });
    };
    render() {
        const {
            entity,
            componentId,
            onFieldChange,
            onInit,
            renderComponent,
            wrappedComponentRef,
            initButton,
        } = this.props;
        const logEntity = lodashGet(this.props, 'entities.productPictureLogs');
        logEntity.params = { ...logEntity.params, csku: lodashGet(entity, 'fields.csku') };
        const { visible } = this.state;
        return (
            <div>
                {createElement(ComponentsManager.get(renderComponent), {
                    entity,
                    componentId,
                    className: 'product-basic',
                    onFieldChange,
                    onInit,
                    onSubmitError: () => {
                        AntMessage.error(services.language.getText('productInfo.infoChangeFail'));
                        initButton();
                    },
                    onSubmitSuccess: () => {
                        AntMessage.success(
                            services.language.getText('productInfo.infoChangeSuccess')
                        );
                    },
                    wrappedComponentRef,
                    direction: 'horizontal',
                    fields: [
                        {
                            property: 'csku',
                        },
                        {
                            property: 'barcode',
                        },
                        {
                            property: 'brandName',
                        },
                        {
                            property: 'itemTitleEn',
                        },
                        {
                            property: 'itemTitleZh',
                        },
                        {
                            property: 'category',
                        },
                        {
                            property: 'sizeEn',
                        },
                        {
                            property: 'sizeZh',
                        },
                        {
                            property: 'colorEn',
                        },
                        {
                            property: 'colorZh',
                        },
                        {
                            property: 'brandId',
                        },
                    ],
                    symbol: '',
                    fieldRow: {
                        gutter: 32,
                    },
                    fieldCol: {
                        lg: 8,
                        md: 12,
                        sm: 24,
                    },
                    labelCol: 24,
                    controlCol: 24,
                })}
                <div className='product-basic-pic'>
                    <i className='product-basic-pic-line'></i>
                    <div className='pic-title'>
                        {services.language.getText('productInfo.imageInfo')}
                        <Button
                            type='primary'
                            style={{ marginLeft: '20px' }}
                            onClick={this.openLogo}
                        >
                            {services.language.getText('productInfo.imageLogView')}
                        </Button>
                    </div>
                    <Modal
                        className='store-selector-modal'
                        title={services.language.getText('productInfo.imageLog')}
                        width={1000}
                        visible={visible}
                        onCancel={this.hideModal}
                        footer={[
                            <Button key='back' onClick={this.hideModal}>
                                {services.language.getText('cancel')}
                            </Button>,
                        ]}
                    >
                        {createElement(ComponentsManager.get('DataTable'), {
                            ...tableMixin,
                            entity: logEntity,
                            columns: [
                                { property: 'imageVersion' },
                                { property: 'mediaName', width: 300 },
                                { property: 'mediaType' },
                                { property: 'dealStatus' },
                                { property: 'createTime' },
                            ],
                            componentId: preDefinedComponentId,
                        })}
                    </Modal>
                    {createElement(ComponentsManager.get(renderComponent), {
                        entity,
                        wrappedComponentRef,
                        componentId,
                        className: 'product-list-pic',
                        onFieldChange,
                        onInit,
                        direction: 'horizontal',
                        style: {
                            marginTop: 20,
                        },
                        fields: [
                            {
                                property: 'pictures',
                                displayConfig: {
                                    imgWidth: 104,
                                    imgHeight: 104,
                                },
                            },
                            {
                                property: 'itemDescriptionEn',
                            },
                            {
                                property: 'itemDescriptionZh',
                            },
                        ],
                        fieldRow: {
                            gutter: 128,
                        },
                        fieldCol: {
                            lg: 18,
                            md: 21,
                            sm: 24,
                        },
                        footer: {
                            style: {
                                display: 'none',
                            },
                        },
                        labelCol: 24,
                        symbol: '',
                        controlCol: 24,
                    })}
                </div>
                <div className='product-basic-pic'>
                    <i className='product-basic-pic-line'></i>
                    <div className='pic-title'>
                        {services.language.getText('productInfo.productGMO')}
                    </div>
                    {createElement(ComponentsManager.get(renderComponent), {
                        entity,
                        wrappedComponentRef,
                        componentId,
                        className: 'product-list-pic',
                        onFieldChange,
                        onInit,
                        direction: 'horizontal',
                        style: {
                            marginTop: 20,
                        },
                        fields: [
                            {
                                property: 'gender',
                            },
                            {
                                property: 'globalItemId',
                            },
                            {
                                property: 'styleId',
                            },
                        ],
                        fieldRow: {
                            gutter: 32,
                        },
                        fieldCol: {
                            lg: 8,
                            md: 12,
                            sm: 24,
                        },
                        labelCol: 24,
                        controlCol: 24,
                    })}
                </div>
            </div>
        );
    }
}

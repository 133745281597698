import { PureComponent, createElement } from 'react';
import { Modal } from 'antd';
import { isFunction } from 'lodash';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { ComponentsManager } from '@comall-backend-builder/core';
import { api, errorHandle, interpolate } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';

interface IProps {
    className: string;
    entity: Entity;
    apiPath: string;
    row: KV;
    text: string;
    title: string | ((row: KV) => any);
    content: string | ((row: KV) => any);
}

/**
 * 表格操作项中的导入按钮功能
 */
export class TableActionDelete extends PureComponent<IProps, any> {
    static defaultProps = {
        title: '',
        content: '',
    };

    onDelete = () => {
        //@ts-ignore
        const { entity, row, apiPath, params } = this.props;
        return api
            .delete({}, { apiPath: interpolate(apiPath, { row }) })
            .then(() => {
                entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                entity.search({ ...entity.params, ...params });
            })
            .catch(errorHandle);
    };

    handleClick = () => {
        let { title, content, row } = this.props;
        title = isFunction(title) ? title(row) : title;
        content = isFunction(content) ? content(row) : content;
        Modal.confirm({
            title,
            content,
            onOk: () => {
                return this.onDelete();
            },
        });
    };

    render() {
        let { className, entity, text } = this.props;
        const props = {
            className,
            entity,
            text,
            type: 'link',
            onClick: this.handleClick,
        };
        return createElement(ComponentsManager.get('Button'), props);
    }
}

import { services } from '@comall-backend-builder/core';
export default [
    {
        key: '/dc-goods/admin/product_spec',
        loader: {
            get: async function(data: any, config: any) {
                console.debug(data);
                let result: any = await services.api.get(data, config);

                return { ...result, id: data.id };
            },
        },
    },
];

import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';
import { COUPON_DATA_STATUS_OPTIONS } from '@/constants';

export const config: Config = {
    entities: {
        discountCouponData: {
            apiPath: '/loader/discount-coupon/data',
            properties: {
                couponNum: {
                    type: 'string',
                    displayName: '<<couponManage.common.couponCode>>',
                },
                conversion: {
                    type: 'string',
                    displayName: '<<couponManage.common.redeemCode>>',
                },
                memberPhone: {
                    type: 'string',
                    displayName: '<<couponManage.common.mobile>>',
                },
                status: {
                    type: 'string.options.select',
                    options: COUPON_DATA_STATUS_OPTIONS,
                    displayName: '<<couponManage.common.couponsStatus.title>>',
                },
                drawTime: {
                    type: 'string',
                    displayName: '<<couponManage.common.receiveTime>>',
                },
                drawStore: {
                    type: 'string',
                    displayName: '<<couponManage.common.receiveStore>>',
                },
                useTime: {
                    type: 'string',
                    displayName: '<<couponManage.common.useTime>>',
                },
                useStore: {
                    type: 'string',
                    displayName: '<<couponManage.common.useStore>>',
                },
                orderNum: {
                    type: 'string',
                    displayName: '<<couponManage.common.orderNum>>',
                },
                orderPrice: {
                    type: 'string',
                    displayName: '<<couponManage.common.orderPrice>>',
                },
            },
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<couponManage.common.mobile>>',
                },
                couponNum: {
                    type: 'string',
                    displayName: '<<couponManage.common.couponCode>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.common.couponsStatus.title>>',
                    options: COUPON_DATA_STATUS_OPTIONS,
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                conversion: {
                    type: 'string',
                    displayName: '<<couponManage.common.redeemCode>>',
                },
            },
        },
    },
    components: {
        DiscountCouponDataPage: {
            entity: 'discountCouponData',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'mobile',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                        {
                            property: 'couponNum',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                        {
                            property: 'status',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                        {
                            property: 'conversion',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'ExportButtonWithFilter',
                            text: '<<couponManage.common.exportCoupon>>',
                            icon: 'download',
                            style: {
                                marginRight: 15,
                            },
                            source: {
                                apiPath: `/dc-ecoupon/admin/coupons/export/take`,
                            },
                            range: {
                                apiPath: `/dc-ecoupon/admin/coupons/export/count`,
                                perPage: 100000,
                            },
                            paramNameAs: {
                                id: 'couponId',
                            },
                            isAsync: true,
                            showSuccessTips: true,
                            showErrorMessage: true,
                            privilege: {
                                path: 'coupon.discount_coupon.discount_coupon_export',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'DiscountCouponDataTable',
                },
            ]),
        },
        DiscountCouponDataTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'couponNum',
                },
                {
                    property: 'conversion',
                },
                {
                    property: 'memberPhone',
                },
                {
                    property: 'status',
                },
                {
                    property: 'drawTime',
                },
                {
                    property: 'drawStore',
                },
                {
                    property: 'useTime',
                },
                {
                    property: 'useStore',
                },
                {
                    property: 'orderNum',
                },
                {
                    property: 'orderPrice',
                },
            ],
        },
    },
};

import React, { Component } from 'react';
import classnames from 'classnames';
import { Tag } from 'antd';
import { map, get, isEmpty } from 'lodash';
import { Image, AnchorNavigation, Anchor } from '@comall-backend-builder/components-basis';
import { language } from '@comall-backend-builder/core/lib/services';
import { PICKING_STATUS } from '../../enums';
import { KV } from '@/interfaces';
import {
    SPECS_TYPE_NAMES,
    PRODUCT_SOURCE_TYPE_NAMES,
    PRODUCT_SOURCE_TYPE_VALUES,
} from '@/constants';
import { PickForm } from './pick-form';
import './index.less';
import { PRODUCT_TYPE_NAMES, PRODUCT_TYPE_VALUES } from '@/constants/picking/product-type';

interface IProps {
    /**
     * 拣货状态
     */
    status: string;
    /**
     * 数据源
     */
    dataSource: Array<any>;
    /**
     * 缺货调整回调
     */
    onChange: (id: string, value: KV) => void;
}

export class Goods extends Component<IProps> {
    static defaultProps = {
        dataSource: [],
        onChange: () => {},
    };

    handlePickNumberChange = (currentPackage: any, value: KV) => {
        const { onChange } = this.props;
        const { combinationGoodsId, goodsId } = currentPackage;

        onChange && onChange(`${combinationGoodsId}-${goodsId}`, value);
    };

    /**
     * 渲染商品行，支持拣货单分包
     * packages 表示分包信息
     */
    renderProduct = (item: any): Array<JSX.Element> => {
        const { status } = this.props;
        const showInputNumber = status === PICKING_STATUS.PICKING;
        return map(item.pickingListBrandItems, (listItem, index) => {
            const totalNeedPickQuantity = get(listItem, 'needPickQuantity');
            return (
                <div
                    key={listItem.id}
                    className={classnames('product-item', {
                        cancelled: listItem.cancelQuantity > 0,
                    })}
                >
                    <div className='product-item-content'>
                        <div className={classnames('product-img')}>
                            <Image imageurl={get(listItem, 'pictureUrl')} />
                        </div>
                        <div className='product-detail'>
                            <p>
                                {language.getText('picking.productName')}：
                                {listItem.name || listItem.enName}
                                {Number(listItem.sourceType) ===
                                    PRODUCT_SOURCE_TYPE_VALUES['COMBINATION'] && (
                                    <Tag style={{ marginLeft: '16px' }} color='#87d068'>
                                        {`${get(
                                            PRODUCT_SOURCE_TYPE_NAMES,
                                            Number(listItem.sourceType)
                                        )} *
                                       ${listItem?.combinationQuantiy ?? 0}`}
                                    </Tag>
                                )}
                                {Number(listItem.sourceType) ===
                                    PRODUCT_SOURCE_TYPE_VALUES.VIRTUAL && (
                                    <Tag style={{ marginLeft: '16px' }} color='#e89e43'>
                                        {
                                            PRODUCT_SOURCE_TYPE_NAMES[
                                                PRODUCT_SOURCE_TYPE_VALUES.VIRTUAL
                                            ]
                                        }
                                    </Tag>
                                )}
                                {Number(listItem.itemType) === PRODUCT_TYPE_VALUES.GIVEAWAY && (
                                    <Tag style={{ marginLeft: '16px' }} color='#80B436'>
                                        {PRODUCT_TYPE_NAMES[PRODUCT_TYPE_VALUES.GIVEAWAY]}
                                    </Tag>
                                )}
                                {Number(listItem.itemType) ===
                                    PRODUCT_TYPE_VALUES.EXCHANGE_GOODS && (
                                    <Tag style={{ marginLeft: '16px' }} color='#80B436'>
                                        {PRODUCT_TYPE_NAMES[PRODUCT_TYPE_VALUES.EXCHANGE_GOODS]}
                                    </Tag>
                                )}
                            </p>
                            <p>
                                {language.getText('picking.barcode')}：{listItem.barCode}
                                <span style={{ marginLeft: '16px' }}>
                                    {language.getText('picking.SKU')}：{listItem.sku || ''}
                                </span>
                            </p>
                            <p>
                                {language.getText('picking.brand')}：{listItem.brandName || ''}
                            </p>
                            <p>
                                {language.getText('picking.size')}：
                                {get(SPECS_TYPE_NAMES, `${listItem.specsType}`)}
                            </p>
                        </div>
                    </div>
                    <PickForm
                        combinationQuantity={listItem.combinationQuantiy}
                        showInputNumber={showInputNumber}
                        needPickQuantity={totalNeedPickQuantity}
                        locationData={listItem.pickingLocations}
                        onChange={(pickData: any) => {
                            this.handlePickNumberChange(listItem, pickData);
                        }}
                    />
                </div>
            );
        });
    };

    render() {
        const { dataSource } = this.props;
        const brandList = dataSource.map((brand, index) => {
            return {
                id: index,
                name: index + 1,
            };
        });
        return (
            <div className='product-container'>
                {!isEmpty(brandList) && (
                    <AnchorNavigation
                        items={brandList}
                        align='left'
                        inkType='line'
                        style={{ width: 200 }}
                    />
                )}
                <div className='product-wrapper' id='product-wrapper'>
                    {dataSource.map((item: any, index) => (
                        <>
                            {/* @ts-ignore */}
                            <Anchor
                                key={`${index}-1`}
                                id={`${index}`}
                                content={{ component: 'Text', children: [] }}
                            ></Anchor>
                            <div key={`${index}-2`} className='product-group'>
                                {this.renderProduct(item)}
                            </div>
                        </>
                    ))}
                </div>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Form, Modal, Input } from 'antd';
import { isEmpty } from 'lodash';
import SelectAutoComplete from '@/components/select-auto-complete';
import { PermissionListEdit } from '../third-part-management-add/permission-list-edit';

const { api, errorHandle, language } = services;

interface EditInfo {
    name?: string;
    type?: string;
    permissionsResultList?: any[];
    whiteStr?: string;
}
interface IProps {
    className: string;
    text: string;
    style: any;
    row: any;
    apiPath: string;
    entity: Entity;
    params: any;
    form: any;
}

interface IState {
    visible: boolean;
    value: null | Array<any>;
    submitLoading: boolean;
    modalInfo: EditInfo;
    validated: boolean;
}

/**
 * 三方管理 编辑
 */
export class tableActionThirdPartEdit extends Component<IProps, IState> {
    state: IState = {
        visible: false,
        value: null,
        submitLoading: false,
        modalInfo: {},
        validated: false,
    };

    submitEdit = async (whiteStr: any, permissionsResultList: any) => {
        const { row, entity, form } = this.props;

        form.validateFields(async (errors: any, values: any) => {
            this.setState({
                validated: true,
            });
            if (!errors) {
                const channelDesignSaveParams = {
                    name: this.state.modalInfo.name,
                    type: this.state.modalInfo.type,
                    whiteStr: whiteStr,
                    permissionsResultList: permissionsResultList,
                    permissionList: values.permissionList?.map(
                        ({ onlineStoreIdList, merchantIdList }: any) => ({
                            onlineStoreIdList: onlineStoreIdList.map((item: any) => item.id),
                            merchantIdList: merchantIdList.map((item: any) => item.id),
                        })
                    ),
                };

                try {
                    this.setState({
                        submitLoading: true,
                    });
                    await api.put(channelDesignSaveParams, {
                        apiPath: `/dc-master-data/admin/channel/${row.id}`,
                    });
                    entity.search(entity.params);
                    this.toggleModal(false);
                } catch (e) {
                    //@ts-ignore
                    errorHandle(e);
                } finally {
                    this.setState({
                        submitLoading: false,
                    });
                }
            }
        });
    };

    toggleModal = (visible: boolean) => {
        this.setState({
            visible,
            validated: false,
        });
    };

    handleClick = () => {
        const { row } = this.props;
        const { setFieldsValue } = this.props.form;
        api.get(
            {},
            {
                apiPath: `/dc-master-data/admin/channel/${row.id}`,
            }
        )
            .then((res: any) => {
                this.setState({
                    modalInfo: res,
                });
                setFieldsValue({ whiteStr: res.whiteStr });
                setFieldsValue({ permissionsResultList: res.permissionsResultList });
                setFieldsValue({ permissionList: res.permissionList });
            })
            .catch(errorHandle)
            .finally(() => {
                this.toggleModal(true);
            });
    };

    handleCancel = () => {
        this.toggleModal(false);
    };

    handleOk = () => {
        const { getFieldValue } = this.props.form;
        const { row } = this.props;
        const whiteStr = getFieldValue('whiteStr');
        const permissionsResultList = getFieldValue('permissionsResultList');
        const getPermissionsListName = (list: any) =>
            Object.values(list).map((i: any) => i.name).join;
        // 未更新
        if (
            whiteStr === row.whiteStr &&
            getPermissionsListName(permissionsResultList) === row.permissionsResultList
        ) {
            this.toggleModal(false);
            return;
        }
        this.submitEdit(whiteStr, permissionsResultList);
    };

    render() {
        const { className, style, text, form, row } = this.props;
        const { visible, submitLoading, modalInfo } = this.state;
        const { getFieldDecorator, resetFields } = form;

        return (
            <>
                <Button className={className} style={style} type='link' onClick={this.handleClick}>
                    {text}
                </Button>
                <Modal
                    title={language.getText('edit')}
                    width={800}
                    visible={visible}
                    confirmLoading={submitLoading}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    okText={language.getText('save')}
                    afterClose={resetFields}
                >
                    <Form layout='horizontal' labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
                        <Form.Item label={language.getText('thirdPartyManagement.thirdPartyName')}>
                            {modalInfo?.name}
                        </Form.Item>
                        <Form.Item label={language.getText('thirdPartyManagement.thirdPartyType')}>
                            {row?.type}
                        </Form.Item>
                        <Form.Item label={language.getText('thirdPartyManagement.white')}>
                            {getFieldDecorator('whiteStr')(
                                <Input.TextArea
                                    maxLength={500}
                                    style={{ height: 120, resize: 'none' }}
                                    placeholder={language.getText(
                                        'thirdPartyManagement.whitePlaceholder'
                                    )}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={language.getText('thirdPartyManagement.permissions')}>
                            {getFieldDecorator('permissionsResultList')(
                                <SelectAutoComplete
                                    mode='multiple'
                                    placeholder={services.language.getText(
                                        'defaultPlaceholderSelect'
                                    )}
                                    apiPath='/dc-master-data/admin/channel/permissions'
                                    selectParamKey='keyword'
                                />
                            )}
                        </Form.Item>
                        {getFieldDecorator('permissionList', {
                            rules: [
                                {
                                    validator(_: never, value: any[], callback: Function) {
                                        if (isEmpty(value)) {
                                            return callback('error');
                                        }
                                        for (const { onlineStoreIdList, merchantIdList } of value) {
                                            if (
                                                !onlineStoreIdList.length ||
                                                !merchantIdList.length
                                            ) {
                                                return callback('error');
                                            }
                                        }
                                        callback();
                                    },
                                },
                            ],
                        })(<PermissionListEdit validated={this.state.validated} />)}
                    </Form>
                </Modal>
            </>
        );
    }
}

export const TableActionThirdPartEdit = Form.create({ name: 'third-part-modal-edit-form' })(
    tableActionThirdPartEdit
);

import { Config } from '@comall-backend-builder/core/lib/parser';
import { language } from '@comall-backend-builder/core/lib/services';

import { buildPropertiesByLanguage } from '@/services/language-utils';

import {
    createPageLayout,
    flexibleFilterForm,
    getLanguageColumns,
    getLanguageProperties,
    tableMixin,
} from '../../mixin';
export const config: Config = {
    entities: {
        paymentMode: {
            apiPath: '/dc-master-data/admin/payment_mode',
            properties: {
                id: {
                    type: 'string',
                },
                internalName: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.internalName>>',
                },
                nameLanguageList: {
                    type: 'object.subForm',
                    properties: getLanguageProperties(
                        'string.text.trim',
                        'systemManagement.paymentMode.name',
                        {
                            rules: [
                                {
                                    whitespace: true,
                                    message: '<<pickStoreInfo.storeNameError>>',
                                },
                                { max: 50, message: '<<pickStoreInfo.storeNameLenError>>' },
                            ],
                        }
                    ),
                },
                ...buildPropertiesByLanguage('instruction', {
                    type: 'string.text.paragraph',
                    displayName: '<<systemManagement.paymentMode.name>>',
                }),
                channel: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.channel>>',
                },
                channelName: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.channelName>>',
                },
                sequence: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.sequence>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<systemManagement.paymentMode.status>>',
                },
                lastModifyUserName: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.lastModifyUser>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.lastModifyTime>>',
                },
            },
            filters: {
                channel: {
                    type: 'string',
                    displayName: '<<systemManagement.paymentMode.channel>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
            },
        },
    },
    components: {
        PaymentModePage: {
            entity: 'paymentMode',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'channel',
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<systemManagement.paymentMode.addText>>',
                            route: '/system/payment-mode/add',
                            privilege: {
                                path: 'system_management.payment_mode.payment_mode_add',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'PaymentModeTable',
                    privilege: {
                        path: 'system_management.payment_mode.payment_mode_view_list',
                        level: 'full',
                    },
                },
            ]),
        },
        PaymentModeTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'internalName',
                },
                ...getLanguageColumns('nameLanguageList'),
                {
                    property: 'channelName',
                },

                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: [
                            {
                                id: 'ENABLED',
                                name: language.getText('enabled'),
                                status: 'success',
                            },
                            {
                                id: 'DISABLED',
                                name: language.getText('disabled'),
                                status: 'default',
                            },
                        ],
                    },
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'lastModifyUserName',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                DISABLED: '/dc-master-data/admin/payment_mode/{{row.id}}/status',
                                ENABLED: '/dc-master-data/admin/payment_mode/{{row.id}}/status',
                            },

                            statusParams: {
                                ENABLED: { status: 'DISABLED' },
                                DISABLED: { status: 'ENABLED' },
                            },
                            statusText: {
                                ENABLED: '<<disable>>',
                                DISABLED: '<<enable>>',
                            },
                            errorStatus: 'ENABLED',
                            linkProperty: 'status',
                        },
                        privilege: {
                            path: 'system_management.payment_mode.payment_mode_enable_disable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/system/payment-mode/edit/{{row.id}}',
                        privilege: {
                            path: 'system_management.payment_mode.payment_mode_edit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

import { useRef } from 'react';
import { ColumnsState } from './columnSetting';
export const useRefFunction = <T extends (...args: any) => any>(reFunction: T) => {
    const ref = useRef<any>(null);
    ref.current = reFunction;
    return (...rest: Parameters<T>): ReturnType<T> => {
        return ref.current?.(...(rest as any));
    };
};

/**
 * 根据 key 和 dataIndex 生成唯一 id
 *
 * @param key 用户设置的 key
 * @param dataIndex 在对象中的数据
 * @param index 序列号，理论上唯一
 */
export const genColumnKey = (key?: React.ReactText | undefined, index?: number): string => {
    if (key) {
        return Array.isArray(key) ? key.join('-') : key.toString();
    }
    return `${index}`;
};
export const omitUndefined = <T extends AnyObject>(obj: T): T => {
    const newObj = {} as T;
    Object.keys(obj || {}).forEach((key) => {
        //@ts-ignore
        if (obj[key] !== undefined) {
            //@ts-ignore
            newObj[key] = obj[key];
        }
    });
    if (Object.keys(newObj).length < 1) {
        return undefined as any;
    }
    return newObj;
};

export const columnSort = (columnsMap: Record<string, ColumnsState>) => (a: any, b: any) => {
    const { index: aIndex } = a;
    const { index: bIndex } = b;

    // 如果没有index，在 dataIndex 或者 key 不存在的时候他会报错
    const aKey = a.key || `${aIndex}`;
    const bKey = b.key || `${bIndex}`;
    if (columnsMap[aKey]?.order || columnsMap[bKey]?.order) {
        return (columnsMap[aKey]?.order || 0) - (columnsMap[bKey]?.order || 0);
    }
    return (a.index || 0) - (b.index || 0);
};

import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '../mixin';
export const config: Config = {
    entities: {
        ecDeposit: {
            apiPath: '/dc-price/admin/deposits',
            properties: {
                id: { type: 'string' },
                division: { type: 'string', displayName: 'Division' },
                depositMin: { type: 'string.format', displayName: 'Deposit' },
                depositMax: { type: 'string.format', displayName: 'Deposit' },
            },
        },
    },
    components: {
        EcDepositContianer: {
            component: 'Viewport',
            entity: 'ecDeposit',
        },
        EcDepositManagement: {
            component: 'Card',
            content: {
                component: 'EcDepositTable',
                privilege: {
                    path: 'order.deposit_setting.view_list',
                    level: 'full',
                },
            },
        },
        EcDepositTable: {
            ...tableMixin,
            scroll: { x: 'widthFix' },
            columns: [
                { property: 'division' },
                {
                    property: 'depositMin',
                    displayConfig: {
                        row: `{{row}}`,
                        keys: ['depositMin', 'depositMax'],
                        style: { color: '#1890ff' },
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                width: 100,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'DepositDeploy',
                        privilege: {
                            path: 'order.deposit_setting.edit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

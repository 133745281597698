import { ENABLE_STATUS_OPTIONS } from '@/constants';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        channels: {
            apiPath:
                '/loader/dc-master-data/admin/distribution_channels/findAllByLoginUser/channel',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<channels.channelName>>',
                },
                type: {
                    type: 'string',
                    displayName: '<<channels.channelType>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<status>>',
                    rules: [{ required: true }],
                },
                cancelStatus: {
                    type: 'string.status',
                    displayName: '<<status>>',
                    rules: [{ required: true }],
                },
            },
        },
    },

    components: {
        ChannelsContainer: {
            component: 'Viewport',
            entity: 'channels',
        },
        ChannelsManagement: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'ChannelsTable',
                        privilege: {
                            path: 'system.channel.view_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },

        ChannelsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: 1300,
            },
            columns: [
                { property: 'name' },
                { property: 'type' },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: ENABLE_STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                width: 300,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<channels.autoCancelSetting>>',
                        config: {
                            actionType: 'link',
                            renderConfig: (row: any) => {
                                //为0 禁用
                                if (row.cancelStatus === '0') {
                                    return {
                                        disabled: true,
                                    };
                                } else {
                                    return {
                                        path: `/channels/auto-cancel/${row.id}`,
                                    };
                                }
                            },
                        },
                        privilege: {
                            path: 'system.channel.update_cancel_config',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<channels.view>>',
                        type: 'link',
                        path: '/channels/detail/{{row.id}}/{{row.name}}',
                        privilege: {
                            path: 'system.channel.view_touch_point_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

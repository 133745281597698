import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { CHANNEL } from '@/constants';
import { get } from 'lodash';

interface props {
    entity: any;
    params: any;
}
class productLimitTable extends Component<props, any> {
    render() {
        const {
            entity,
            params: { channelId },
        } = this.props;
        services.localStorage.set('limitChannelId', channelId);
        return (
            <div>
                {channelId === CHANNEL.MACAU && (
                    <React.Fragment>
                        {createElement(ComponentsManager.get('LimitTipButton'), {
                            params: { channelId },
                        })}
                        {createElement(ComponentsManager.get('DataTable'), {
                            entity,
                            loadFirstPage: true,
                            pagination: {
                                pageSize: 10,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                pageSizeOptions: ['10', '15', '20'],
                                showTotal(total: number) {
                                    return services.interpolate(
                                        services.language.getText('table.total'),
                                        {
                                            total,
                                        }
                                    );
                                },
                            },
                            columns: [
                                { property: 'categoryName' },
                                { property: 'categoryLimitNumber' },
                                { property: 'cskuLimitNumber' },
                            ],
                            actionColumn: {
                                title: services.language.getText('actions'),
                                width: 200,
                                items: [
                                    {
                                        text: services.language.getText('edit'),
                                        type: 'link',
                                        path: `/productLimit/edit/{{row.id}}`,
                                        privilege: {
                                            path: 'goods.goods_limit.modify',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                        })}
                    </React.Fragment>
                )}
                {channelId === CHANNEL.SELECT && (
                    <React.Fragment>
                        {createElement(ComponentsManager.get('DataTable'), {
                            entity,
                            loadFirstPage: true,
                            pagination: {
                                pageSize: 10,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                pageSizeOptions: ['10', '15', '20'],
                                showTotal(total: number) {
                                    return services.interpolate(
                                        services.language.getText('table.total'),
                                        {
                                            total,
                                        }
                                    );
                                },
                            },
                            columns: [{ property: 'type' }, { property: 'limitNum' }],
                            actionColumn: {
                                title: services.language.getText('actions'),
                                width: 200,
                                items: [
                                    {
                                        text: services.language.getText('edit'),
                                        type: 'link',
                                        path: `/productLimit/selectEdit/{{row.id}}/{{row.type}}`,
                                        privilege: {
                                            path: 'goods.goods_limit.modify',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                        })}
                    </React.Fragment>
                )}
                {channelId === CHANNEL.GUIDE_SHOPPING && (
                    <React.Fragment>
                        {createElement(ComponentsManager.get('LimitTipButton'), {
                            params: { channelId },
                        })}
                        {createElement(ComponentsManager.get('DataTable'), {
                            entity,
                            loadFirstPage: true,
                            pagination: {
                                pageSize: 10,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                pageSizeOptions: ['10', '15', '20'],
                                showTotal(total: number) {
                                    return services.interpolate(
                                        services.language.getText('table.total'),
                                        {
                                            total,
                                        }
                                    );
                                },
                            },
                            columns: [
                                { property: 'categoryName' },
                                { property: 'categoryLimitNumber' },
                                { property: 'cskuLimitNumber' },
                            ],
                            actionColumn: {
                                title: services.language.getText('actions'),
                                width: 200,
                                items: [
                                    {
                                        text: services.language.getText('edit'),
                                        type: 'link',
                                        path: `/productLimit/edit/{{row.id}}`,
                                        privilege: {
                                            path: 'goods.goods_limit.modify',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                        })}
                    </React.Fragment>
                )}
            </div>
        );
    }
}
function mapStateToProps(_state: any, props: any) {
    return {
        entity: get(props, 'entity'),
    };
}

export const ProductLimitTable = connect(mapStateToProps)(productLimitTable);

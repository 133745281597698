import React, { useCallback, useEffect, useState, useRef } from 'react';
import { isEmpty, omit } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { ExportButtonProps } from '@comall-backend-builder/components-basis/lib/export-button';
import { Modal as AntModal, message as AntMessage, Tag as AntTag, Spin as AntSpin } from 'antd';
import { KV } from '@/interfaces';

const defaultPerPage = 20000;

// 千分位
const format = (num: number) => {
    return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
};

const generateRanges = (totalNum: number, perPage = defaultPerPage) => {
    if (totalNum === 0) {
        return [];
    }
    const result = [];
    const totalPage = Math.ceil(totalNum / perPage);
    const restNum = totalNum % perPage;
    for (let i = 0; i < totalPage; i++) {
        const isSpecialLastPage = i === totalPage - 1 && restNum > 0;
        const prefix = i * perPage + 1;
        const suffix = isSpecialLastPage
            ? (i - 1) * perPage + perPage + restNum
            : i * perPage + perPage;
        result.push({
            name: `${format(prefix)} ~ ${format(suffix)}`,
            page: i + 1,
            perPage,
            pageStart: prefix,
            pageEnd: suffix,
        });
    }
    return result;
};

export interface Range {
    apiPath: string;
    perPage?: number;
}

interface Props extends ExportButtonProps {
    /**
     * 分区间导出配置项
     */
    range: Range;
    showSuccessTips?: string;
    visible: boolean;
    params: KV;
    onClose: () => void;
}

export default function ExportRangeModal(props: Props) {
    const {
        range,
        source,
        showSuccessTips,
        showErrorMessage,
        visible,
        params,
        onClose,
        onSuccess,
    } = props;
    let init = useRef(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState<KV>({});
    const [totalNum, setTotalNum] = useState(0);
    const exportRanges = generateRanges(totalNum, range.perPage);

    const onCloseModal = useCallback(() => {
        setSelectedItem({});
        setTotalNum(0);
        onClose();
        init.current = false;
    }, [setSelectedItem, onClose]);

    const onExport = useCallback(() => {
        if (isEmpty(selectedItem)) {
            AntMessage.error(services.language.getText('exportModal.pleaseSelect'));
            return;
        }
        const exportParams = {
            ...params,
            ...omit(selectedItem, ['name']),
        };
        setExportLoading(true);
        services.api
            .get(exportParams, {
                apiPath: source.apiPath,
            })
            .then((response) => {
                showSuccessTips &&
                    AntModal.success({
                        content: services.language.getText('exportModal.exportSuccessMsg'),
                    });
                onSuccess(response);
                onClose && onClose();
            })
            .catch((e) => {
                console.error(e);
                showErrorMessage && services.errorHandle(e);
            })
            .finally(() => {
                setExportLoading(false);
            }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source, showSuccessTips, showErrorMessage, selectedItem, params, onSuccess]);

    useEffect(() => {
        if (init.current) return;
        if (visible && range) {
            services.api
                .get(params, {
                    apiPath: range.apiPath,
                })
                .then((count) => {
                    setTotalNum(count as number);
                })
                .catch((e) => {
                    services.errorHandle(e);
                })
                .finally(() => {
                    init.current = true;
                    setLoading(false);
                });
        }
    }, [params, visible, range]);

    return (
        <AntModal
            visible={visible}
            width={850}
            title={services.language.getText('exportModal.pleaseSelect')}
            okText={services.language.getText('exportModal.confirmText')}
            closable={false}
            okButtonProps={{
                disabled: !totalNum,
                loading: exportLoading,
            }}
            onCancel={onCloseModal}
            onOk={onExport}
        >
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <AntSpin tip={services.language.getText('exportModal.loadingText')} />
                </div>
            ) : !isEmpty(exportRanges) ? (
                exportRanges.map((item) => (
                    <AntTag.CheckableTag
                        key={item.name}
                        style={{
                            textAlign: 'center',
                            width: 150,
                            cursor: 'pointer',
                            border: '1px solid #ccc',
                            padding: '5px 15px',
                            margin: '5px',
                        }}
                        checked={selectedItem.name === item.name}
                        onChange={() => {
                            setSelectedItem(item);
                        }}
                    >
                        {item.name}
                    </AntTag.CheckableTag>
                ))
            ) : (
                <div style={{ textAlign: 'center' }}>
                    {services.language.getText('exportModal.emptyText')}
                </div>
            )}
        </AntModal>
    );
}

ExportRangeModal.defaultProps = {
    params: {},
    range: {
        perPage: defaultPerPage,
    },
    onSuccess: () => {},
};

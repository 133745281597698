import { range } from 'lodash';

function mockFetch<T>(mockData: T): Promise<T> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(mockData);
        }, 1000);
    });
}
function mockFail<T>(mockData: T): Promise<T> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(mockData);
        }, 1000);
    });
}

function mockArray<T>(value: T, num: number): T[] {
    return range(0, num).map((_, k) => {
        return {
            ...value,
            id: k.toString(),
        };
    });
}

export { mockFetch, mockFail, mockArray };

import React, { useCallback, useState } from 'react';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { message, Button } from 'antd';

/**
 * 日志管理 - 导出文件管理 - 下载文件按钮
 *
 * source 下载目标的资源信息
 * params 请求参数
 */
export const RecordFileExportButton: React.FC<any> = (props) => {
    const [showLoading, setLoading] = useState(false);

    const handelClick = useCallback(() => {
        if (props.row && props.row.id) {
            setLoading(true);
            const { params, apiPath, apiRoot, paramsFilter } = props.source;
            const path = services.interpolate(apiPath, props);
            services.api
                .download(params, { apiRoot, apiPath: path, paramsFilter })
                .catch((e: any) => {
                    message.error(
                        get(e.response, 'body.message') ||
                            get(e, 'body.err_msg') ||
                            '下载失败,请稍后再试'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props]);

    const getDisabled = useCallback(() => {
        if (props.row && props.row.status && props.row.status === 'SUCCESS') {
            return false;
        }
        return true;
    }, [props.row]);

    return (
        <>
            <Button
                type='link'
                disabled={getDisabled()}
                loading={showLoading}
                onClick={handelClick}
            >
                {props.text}
            </Button>
        </>
    );
};

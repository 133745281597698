import { mockFetch } from '../mock';
import { isEmpty, get as lodashGet } from 'lodash';
import { services } from '@comall-backend-builder/core/lib';
function formatDate(
    date: { start: string; end: string },
    prop: string,
    beginSuffix = 'Start',
    afterSuffix = 'End'
) {
    if (date) {
        return {
            [prop + beginSuffix]: date.start + ' 00:00:00',
            [prop + afterSuffix]: date.end + ' 00:00:00',
        };
    } else {
        return {};
    }
}
export default [
    {
        key: '/dc-ecoupon/admin/coupons',
        loader: {
            get: async function(data: any, config: any) {
                const sendTime = formatDate(data.sendTime, 'sendTime');
                const receiveTime = formatDate(data.receiveTime, 'receiveTime');
                const useTime = formatDate(data.useTime, 'useTime');
                delete data.sendTime;
                delete data.receiveTime;
                delete data.useTime;
                return services.api.get(
                    { ...data, ...sendTime, ...receiveTime, ...useTime },
                    config
                );
            },
        },
    },
    {
        key: '/dc-ecoupon/admin/ecoupons_definition_rules',
        loader: {
            put: async function(data: any) {
                return await mockFetch({});
            },
            get: async function(data: any, config: any) {
                const startDate = formatDate(data.startDate, 'startDate');
                const endDate = formatDate(data.endDate, 'endDate');
                if (!isEmpty(data.partipateBrand)) {
                    data.partipateBrand = (lodashGet(data, 'partipateBrand') || [])
                        .map((item: any) => item.searchCode)
                        .join(',');
                }
                delete data.startDate;
                delete data.endDate;
                return services.api.get({ ...data, ...startDate, ...endDate }, config);
            },
        },
    },
];

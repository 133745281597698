import { services } from '@comall-backend-builder/core';
import React, { useState } from 'react';
import { PrintButton } from '../print';
import { GoodsTags } from '../print-template';
import { Privilege } from '../privilege';

export const PrintGoodsTagsButton: React.FC<{
    entity: AnyObject;
}> = (props) => {
    const [loading, setLoading] = useState(false);
    const [goodsList, setGoodsList] = useState<AnyObject[]>([]);

    async function print(
        ref: React.MutableRefObject<{
            onPrint: Function;
        }>
    ) {
        try {
            setLoading(true);
            const data = await services.api.get<AnyObject[]>(props.entity.filters, {
                apiRoot: ENV.API_ROOT,
                apiPath: '/dc-goods/admin/rskus/price/store/printLable',
            });

            if (data && data.length) {
                setGoodsList(data);

                await ref.current.onPrint();
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Privilege path='goods.goods_price_view.print_lable'>
            <PrintButton
                loading={loading}
                template={<GoodsTags data={goodsList} />}
                onClick={print}
            >
                {services.language.getText('components.PrintGoodsTagsButton.buttonText')}
            </PrintButton>
        </Privilege>
    );
};

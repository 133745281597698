import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        AfterSaleOrdersInfo: {
            component: 'Card',
            className: 'after-sale-refund-item',
            type: 'inner',
            entity: 'afterSaleOrdersList',
            loaderType: 'get',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'AfterSaleInfoBasic',
                    },
                    {
                        component: 'AfterInfoRemark',
                    },
                    {
                        component: 'OrderInfoProduct',
                        instanceType: 'afterSaleOrdersInfo',
                    },
                    {
                        component: 'AfterSaleRefundItem',
                    },
                ],
            },
        },
    },
};

import { ArrayFormat } from '@comall-backend-builder/types';

export class UploadImageVideoFormat extends ArrayFormat {
    /**
     * 将数据格式化为请求参数
     */
    public formatParams(key: string, value: any) {
        let pathArr = [];

        if (value && value.length) {
            pathArr = value.map((item: any, index: number) => {
                if (typeof item === 'string') {
                    return { url: item, sequence: index };
                } else {
                    return {
                        url: item.url,
                        video: item.video,
                        poster: item.poster,
                        sequence: index,
                    };
                }
            });
        }
        return { [key]: pathArr };
    }

    /**
     * 对数据进行校验
     */
    public validate(_rule: any, _value: any, callback: any) {
        callback();
    }
}

import { ORDER_STATUS_NAMES } from '@/constants';
import { isEmpty } from 'lodash';
import React from 'react';

interface Props {
    listData: {
        orderStatus: string;
        outerOrderNumber: string;
    }[];
    msg: string;
}

export const OrderStatusMsg: React.FC<Props> = ({ listData, msg }) => {
    return (
        <>
            {!isEmpty(listData) && (
                <section>
                    <div style={{ fontWeight: '500' }}>{msg}</div>
                    {listData.map((item, index) => (
                        <div
                            key={item.outerOrderNumber}
                            style={{
                                marginTop: '10px',
                                marginBottom: index === listData.length - 1 ? '10px' : '0',
                            }}
                        >
                            {item.outerOrderNumber}（{ORDER_STATUS_NAMES[item.orderStatus]}）
                        </div>
                    ))}
                </section>
            )}
        </>
    );
};

import { API_PREFIX, wrapApiResult } from '@/constants/order/utils';

/**
 * 飞利浦订单管理-订单列表
 */
export default [
    {
        key: '/base-order-philips/admin/orders',
        loader: {
            get: async function(data: any = {}, config: any) {
                const res = await wrapApiResult(data, config, API_PREFIX.PHILIPS);
                return res;
            },
        },
    },
];

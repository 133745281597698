import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        paymentDetailList: {
            apiPath: '/dc-order/admin/orders/findHisRecordByOrderAndChannel',
            properties: {
                seq: {
                    type: 'string',
                },
                type: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                },
                amount: {
                    type: 'string',
                },
                number: {
                    type: 'string',
                },
                status: {
                    type: 'string',
                },
                time: {
                    type: 'string',
                },
            },
        },
    },
    components: {
        PaymentDetailTable: {
            entity: 'paymentDetailList',
            loaderType: 'search',
            component: 'DataTable',
            scroll: { y: 360 },
            showHeader: true,
            loadFirstPage: true,
            columns: [
                {
                    title: '<<paymentDetail.seq>>',
                    property: 'seq',
                },
                {
                    title: '<<paymentDetail.type>>',
                    property: 'type',
                },
                {
                    title: '<<paymentDetail.name>>',
                    property: 'name',
                },
                {
                    title: '<<paymentDetail.amount>>',
                    property: 'amount',
                },
                {
                    title: '<<paymentDetail.pspNumber>>',
                    property: 'number',
                },
                {
                    title: '<<paymentDetail.status>>',
                    property: 'status',
                },
                {
                    title: '<<paymentDetail.time>>',
                    property: 'time',
                },
            ],
        },
    },
};

import { getStoreInfo } from '@/services/utils';
import React from 'react';

const ORDER_CREATE_URL = '#/offlineOrders/create';

export const OrderInfoStore = () => {
    const url = window.location.hash;

    return (
        <>
            {url.startsWith(ORDER_CREATE_URL) && (
                <div style={{ marginRight: '30px', color: '#1890ff' }}>{getStoreInfo()?.name}</div>
            )}
        </>
    );
};

import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';

export default [
    {
        key: '/dc-master-data/admin/activity',
        loader: {
            get: async (data: AnyObject = {}, config: AnyObject = {}) => {
                if (data.id) {
                    const res = await services.api.get<AnyObject>(data, {
                        ...config,
                        apiPath: `/dc-master-data/admin/activity/:id`,
                    });
                    res.effectTime = {
                        start: res.effectiveStartTime,
                        end: res.effectiveEndTime,
                    };
                    res.storeIds = res.stores.map((item: AnyObject) => item.id);
                    res.prizeSetting = { val: '', other: [], items: [] };
                    if (!isEmpty(res.activityAwards)) {
                        res.activityAwards.forEach((item: any) => {
                            if (item.type === 'POINTS') {
                                res.prizeSetting.val = item.value;
                            }
                            if (item.type === 'COUPON') {
                                item.couponNum = item.value;
                                res.prizeSetting.items.push(item);
                            }
                            if (item.type === 'OTHER_COUPON') {
                                item.otherNum = item.value;
                                res.prizeSetting.other.push(item);
                            }
                        });
                    }

                    if (!isEmpty(res.awardConditions)) {
                        res.awardConditions.forEach((item: any) => {
                            if (item.key === 'ORDER_FULL') {
                                res.orderAmount = item.value;
                            }
                            if (item.key === 'INITIAL') {
                                res.invitationWay = item.value;
                            }
                            if (item.key === 'INCENTIVES') {
                                res.rewardWays = item.value;
                            }
                        });
                    }
                    return res;
                } else {
                    return services.api.get<AnyObject>(data, {
                        ...config,
                        apiPath: '/dc-master-data/admin/activity',
                    });
                }
            },
            post: async (data: AnyObject = {}, config: AnyObject = {}) => {
                if (data.timedReleaseTime && data.timedReleaseTime.startsWith('0')) {
                    data.timedReleaseTime = data.timedReleaseTime.substring(1);
                }
                const res = await services.api.post<AnyObject>(data, {
                    ...config,
                    apiPath: `/dc-master-data/admin/activity/add`,
                });
                return res;
            },
            put: async (data: AnyObject = {}, config: AnyObject = {}) => {
                if (data.timedReleaseTime && data.timedReleaseTime.startsWith('0')) {
                    data.timedReleaseTime = data.timedReleaseTime.substring(1);
                }
                const res = await services.api.put<AnyObject>(data, {
                    ...config,
                    apiPath: `/dc-master-data/admin/activity/:id`,
                });
                return res;
            },
        },
    },
    {
        key: '/dc-goods/admin/cskus/queryCskuByPage',
        loader: {
            get: async (data: AnyObject = {}, config: AnyObject = {}) => {
                const res: any = await services.api.get<AnyObject>(
                    { ...data, page: 1, perPage: 10 },
                    {
                        ...config,
                        apiPath: '/dc-goods/admin/cskus/queryCskuByPage',
                    }
                );
                return res.result;
            },
        },
    },
];

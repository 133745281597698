import React, { useEffect, useState } from 'react';
import { Loader } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { Row, Col, Table } from 'antd';
import { LogDetailData } from './data';
import { services } from '@comall-backend-builder/core';

const columns = [
    {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'paymentDetail.amount',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'importRecord.createUser',
        dataIndex: 'operateUser',
        key: 'operateUser',
    },
    {
        title: 'importRecord.createTime',
        dataIndex: 'operateTime',
        key: 'operateTime',
    },
    {
        title: 'productShelf.logsTable.reason',
        dataIndex: 'remark',
        key: 'remark',
        width: '30%',
    },
];

function buildLanguage(arr: any) {
    return (
        arr &&
        arr.map((item: any) => {
            return {
                ...item,
                title: services.language.getText(item.title),
            };
        })
    );
}

function LogDetail(props: any) {
    const [PageData, setPageData] = useState<LogDetailData.RootObject>();
    function request() {
        Loader.load('get', {
            apiPath: `/loader/dc-order/admin/deposit/getDetail`,
            params: {
                id: get(props, 'id', ''),
            },
        }).then((res: LogDetailData.RootObject) => {
            setPageData(res);
        });
    }
    useEffect(() => {
        request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    return (
        <div>
            <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col className='gutter-row' span={8}>
                    <div>
                        <span>{services.language.getText('order.onlineStore')}:</span>
                        <span>{PageData?.depositHead.storeName}</span>
                    </div>
                </Col>
                <Col className='gutter-row' span={8}>
                    <div>
                        <span>{services.language.getText('deposit.DepositTheSerialNumber')}:</span>
                        <span>{PageData?.depositHead.serialNo}</span>
                    </div>
                </Col>
                <Col className='gutter-row' span={8}>
                    <div>
                        <span>{services.language.getText('deposit.pickingListOrders')}:</span>
                        <span>{PageData?.depositHead.orderNumber}</span>
                    </div>
                </Col>
            </Row>
            <Table
                columns={buildLanguage(columns)}
                dataSource={PageData?.depositBody}
                rowKey={(row) => row.operateTime}
            />
        </div>
    );
}

export { LogDetail };

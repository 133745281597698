/**
 * 商品限购管理
 */
export interface ProductLimit {
    /**
     * id
     */
    id: number;
    /**
     * 分类名称
     */
    categoryName: string;
    /**
     * 分类限购数
     */
    categoryLimitNumber: string;
    /**
     * 商品限购数
     */
    cskuLimitNumber: string;
}
/**
 * 限购提示
 */
export interface ProductLimitTip {
    /**
     * id
     */
    id: string;
    /**
     * 模板内容
     */
    templateContent: string;
    /**
     * 提示模板类型
     * 1=分类限购 2=单品限购
     */
    type: string;
}

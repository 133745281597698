import {
    DESIGN_MANAGE_LANGUAGE_NAMES,
    DESIGN_MANAGE_LANGUAGE_VALUES,
} from './../../../constants/design-manage';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, getLanguageProperties, getLanguageColumns } from '@/configs/mixin';
import { toString } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { PICK_STORE_TABLE } from '@/configs/pro-table-constants';
import { formatNameProperties } from '../util';
import { PICKUP_ATTRIBUTES_OPTIONS, PICKUP_ATTRIBUTES_VALUES } from '@/constants';

export const config: Config = {
    entities: {
        pickStore: {
            apiPath: '/loader/dc-store/admin/pickStore',
            properties: {
                id: {
                    type: 'string',
                },
                pickStoreName: {
                    type: 'string.text.trim',
                    displayName: '<<pickStore.storeName>>',
                },
                pickStoreType: {
                    type: 'string',
                },
                retailStoreId: {
                    type: 'string.text.trim',
                    displayName: '<<pickStore.retailStoreCode>>',
                },
                terminalId: {
                    type: 'string.text.trim',
                    displayName: '<<pickStore.terminalId>>',
                },
                storeInfo: {
                    type: 'object.subForm',
                    displayName: '<<pickStoreInfo.storeInfo>>',
                    properties: {
                        code: {
                            type: 'string.text.trim',
                            displayName: '<<pickStore.retailStoreCode>>',
                            rules: [
                                { required: true },
                                {
                                    whitespace: true,
                                    message: '<<pickStore.storeCodeNotEmpty>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<pickStore.retailStoreCodePlaceholder>>',
                                maxLength: 100,
                            },
                        },
                        languageName: {
                            type: 'object.subForm',
                            properties: formatNameProperties(
                                'pickStore.storeName',
                                'pickStore.pickStoreNamePlaceholder',
                                'pickStore.pickStoreNameError',
                                100
                            ),
                        },
                    },
                },
                contactInformation: {
                    type: 'object.subForm',
                    displayName: '<<pickStoreInfo.contactInformation>>',
                    properties: {
                        languageName: {
                            type: 'object.subForm',
                            properties: getLanguageProperties(
                                'string.text.trim',
                                'pickStoreInfo.contacts',
                                {
                                    controlConfig: {
                                        placeholder: '<<pickStoreInfo.contactsPlaceHolder>>',
                                        maxLength: 100,
                                    },
                                    rules: [
                                        {
                                            whitespace: true,
                                            message: '<<pickStoreInfo.contactsPlaceHolder>>',
                                        },
                                        {
                                            max: 100,
                                            message: services.interpolate(
                                                services.language.getText(
                                                    'pickStoreInfo.contactsLenError'
                                                ),
                                                {
                                                    language:
                                                        DESIGN_MANAGE_LANGUAGE_NAMES[
                                                            DESIGN_MANAGE_LANGUAGE_VALUES.ZH
                                                        ],
                                                    max: 100,
                                                }
                                            ),
                                        },
                                    ],
                                }
                            ),
                        },
                        masterMobile: {
                            type: 'string.text.trim',
                            displayName: '<<pickStoreInfo.masterMobile>>',
                            rules: [
                                { required: true },
                                {
                                    whitespace: true,
                                    message: '<<pickStoreInfo.masterMobileError>>',
                                },
                                {
                                    max: 100,
                                    message: '<<pickStoreInfo.masterMobileLenError>>',
                                },
                            ],
                            controlConfig: {
                                maxLength: 100,
                                placeholder: '<<pickStoreInfo.masterMobilePlaceHolder>>',
                            },
                        },
                        contacts: {
                            type: 'string.text.trim',
                            displayName: '<<pickStoreInfo.masterMobile>>',
                            rules: [
                                { required: true },
                                {
                                    whitespace: true,
                                    message: '<<pickStoreInfo.masterMobileError>>',
                                },
                                {
                                    max: 20,
                                    message: '<<pickStoreInfo.masterMobileLenError>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<pickStoreInfo.masterMobilePlaceHolder>>',
                            },
                        },
                    },
                },
                storeAddress: {
                    type: 'object.subForm',
                    displayName: '<<pickStoreInfo.addressInfo>>',
                    properties: {
                        longitude: {
                            type: 'number.float',
                            displayName: '<<pickStoreInfo.longitude>>',
                            controlConfig: {
                                placeholder: '<<pickStoreInfo.longitudePlaceHolder>>',
                                min: -180,
                                max: 180,
                            },
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        const newValue = toString(value);
                                        if (newValue.length > 18) {
                                            return new Error(
                                                services.language.getText(
                                                    'pickStore.longitudeLenError'
                                                )
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],
                        },
                        latitude: {
                            type: 'number.float',
                            displayName: '<<pickStoreInfo.latitude>>',
                            controlConfig: {
                                placeholder: '<<pickStoreInfo.latitudePlaceHolder>>',
                                min: -90,
                                max: 90,
                            },
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        const newValue = toString(value);
                                        if (newValue.length > 18) {
                                            return new Error(
                                                services.language.getText(
                                                    'pickStore.latitudeLenError'
                                                )
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],
                        },
                        languageName: {
                            type: 'object.subForm',
                            properties: getLanguageProperties(
                                'string.text.trim',
                                'pickStoreInfo.storeAddress',
                                {
                                    rules: [
                                        {
                                            whitespace: true,
                                            required: true,
                                        },
                                        {
                                            max: 200,
                                            message: services.interpolate(
                                                services.language.getText(
                                                    'pickStoreInfo.storeAddressLenError'
                                                ),
                                                {
                                                    language:
                                                        DESIGN_MANAGE_LANGUAGE_NAMES[
                                                            DESIGN_MANAGE_LANGUAGE_VALUES.ZH
                                                        ],
                                                    max: 200,
                                                }
                                            ),
                                        },
                                    ],
                                    controlConfig: {
                                        placeholder: '<<pickStoreInfo.addressPlaceHolder>>',
                                    },
                                }
                            ),
                        },
                        realityImages: {
                            type: 'array.storeImage',
                            displayName: '<<pickStoreInfo.realityImages>>',
                        },
                        pickStoreAttribute: {
                            type: 'string.options.radio',
                            options: PICKUP_ATTRIBUTES_OPTIONS,
                            displayName: services.language.getText(
                                'pickStoreInfo.pickupPointAttributes'
                            ),
                            defaultValue: PICKUP_ATTRIBUTES_VALUES.GENERAL_PICKUP_POINT,
                            rules: [{ required: true }],
                        },
                        memberLevels: {
                            type: 'array.optionIds.select',
                            displayName: '<<pickStoreInfo.activeMembershipLevel>>',
                            options: [],
                            source: {
                                apiPath: '/loader/dc-user/admin/online-store/member-level',
                            },
                            rules: [{ required: true }],
                        },
                    },
                },
                languageName: {
                    type: 'object.subForm',
                    properties: getLanguageProperties('string.text.trim', 'pickStore.storeName', {
                        controlConfig: {
                            placeholder: '<<pickStore.pickupgoods>>',
                        },
                        rules: [
                            {
                                required: true,
                                message: '<<category.displayCategory.nameRequired>>',
                            },
                            {
                                whitespace: true,
                                message: '<<category.displayCategory.nameRequired>>',
                            },
                            {
                                max: 50,
                                message: services.interpolate(
                                    services.language.getText('category.displayCategory.nameError'),
                                    {
                                        language:
                                            DESIGN_MANAGE_LANGUAGE_NAMES[
                                                DESIGN_MANAGE_LANGUAGE_VALUES.ZH
                                            ],
                                        max: 50,
                                    }
                                ),
                            },
                        ],
                    }),
                },
            },
            filters: {
                idOrName: {
                    type: 'string.text.trim',
                    displayName: '<<keyword>>',
                },
            },
        },
    },

    components: {
        PickStoreContainer: {
            component: 'Viewport',
        },
        PickStoreManagement: {
            component: 'Card',
            entity: 'pickStore',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'PickStoreHeader',
                    },
                    {
                        component: 'PickStoreTable',
                    },
                ],
                privilege: {
                    path: 'store.pickstore.pickstoreview',
                    level: 'full',
                },
            },
        },
        PickStoreHeader: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'PickStoreButtons',
                },
                {
                    component: 'PickStoreFilter',
                },
            ],
        },
        PickStoreButtons: {
            component: 'GridLayout',
            items: [
                {
                    component: 'Button',
                    text: '<<pickStore.addStore>>',
                    icon: 'plus',
                    type: 'primary',
                    route: '/pickStore/add-store',
                    privilege: {
                        path: 'store.pickstore.pickstoreadd',
                        level: 'full',
                    },
                },
            ],
        },
        PickStoreFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            fields: [
                {
                    property: 'idOrName',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<pickStore.keywordPlaceHolder>>',
                        style: {
                            width: 220,
                        },
                    },
                },
            ],
        },
        PickStoreTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: PICK_STORE_TABLE,
            scroll: {
                x: 'max-content',
            },
            columns: [...getLanguageColumns('languageName'), { property: 'retailStoreId' }],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                width: 250,
                items: [
                    {
                        text: '<<view>>',
                        type: 'link',
                        path: '/pickStore/detail/{{row.id}}',
                        privilege: {
                            path: 'store.pickstore.view_detail',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        config: {
                            actionType: 'link',
                            renderConfig: (row: any) => {
                                if (row.noOnlineStore) {
                                    return {
                                        tooltip: '<<store.noMoreChannel>>',
                                        disabled: true,
                                    };
                                } else {
                                    return {
                                        path: `/pickStore/create-online-store/${row.id}`,
                                    };
                                }
                            },
                        },
                        text: '<<pickStore.createOnlineStore>>',
                        privilege: {
                            path: 'store.pickstore.pickdockingonline',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/pickStore/edit-store/{{row.id}}',
                        privilege: {
                            path: 'store.pickstore.pickstoreedit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

import React, { PureComponent, createElement, Fragment } from 'react';

import {
    ComponentsManager,
    services,
    builder,
    actions,
    Loader,
} from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { message as AntMessage, Modal, Button } from 'antd';
import { mapValues, get } from 'lodash';
function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields };
}
export const ModalUpload = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('ModifyForm'), {
        ...props,
        componentId: `ModalUpload-${props.row.id}`,
        style: {
            width: '400px',
        },
        fields: [
            { property: 'id', className: 'hidden' },
            {
                property: 'couponImage',
                type: 'array.brandImage',
                controlConfig: {
                    mode: 'picture-card',
                    multiple: false,
                    maxCount: 1,
                    maxSize: 5 * 1024,
                    fileName: 'fileName',
                    uploadCompleteMessage: null,
                    uploadLimitSizeErrorMessage: services.language.getText(
                        'brandEdit.logoLimitSize'
                    ),
                    uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                },
            },
        ],
        onSubmit: async (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });
            if (!get(entityFields, 'couponImage[0].path')) {
                AntMessage.error('请先上传图片', 1.5);
                return;
            }
            console.info(entityFields);
            const params = {
                couponImage: get(entityFields, 'couponImage[0].path'),
                id: entityFields['id'],
            };
            Loader.load('put', {
                params,
                apiPath: `/dc-ecoupon/admin/ecoupons_definition_rules/${entityFields['id']}/update_image`,
            })
                .then(() => {
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success(services.language.getText('saveFully'), 1.5);
                })
                .catch(services.errorHandle);
        },
    });
});

export class CouponImageUpload extends PureComponent<any> {
    state = { visible: false };
    openModal = () => {
        this.setState({ visible: true });
        const { entity, row } = this.props;
        entity && entity.setFields(row);
    };

    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
    };
    render() {
        return (
            <Fragment>
                <Button className='modal-hot_brands-trigger' onClick={this.openModal} type='link'>
                    {services.language.getText('couponRule.uploadButton')}
                </Button>
                <Modal
                    visible={this.state.visible}
                    title={services.language.getText('couponRule.uploadButton')}
                    mask={false}
                    maskClosable
                    okText={services.language.getText('submit')}
                    cancelText={services.language.getText('cancel')}
                    wrapClassName='modal-hot_brands-trigger'
                    onOk={() => {
                        this.listenRef.handleSubmit();
                        this.setState({ visible: false });
                    }}
                    onCancel={() => {
                        const { entity, row } = this.props;
                        let { fields: entityFields } = entity;
                        const loaded = !!entityFields;
                        builder.getStore().dispatch(
                            actions.formInitAction(`ModalUpload-${row.id}`, {
                                type: 'EditForm',
                                entityId: entity.id,
                                fields: row,
                                loaded,
                            })
                        );
                        this.setState({ visible: false });
                    }}
                >
                    {this.state.visible && (
                        <ModalUpload
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                        />
                    )}
                </Modal>
            </Fragment>
        );
    }
}

import { services } from '@comall-backend-builder/core';
import { APPLY_TYPE_VALUES, EXCLUDE_TYPE_LIMIT_VALUES } from '@/constants/coupon-manage';
import { dataPropValueToStr } from '@/services';

/**
 * 表单数据转换为接口数据
 */
function formDataToApiData(data: AnyObject) {
    data.onlineStoreIdList = data.storeIds;

    switch (data.specialType) {
        case APPLY_TYPE_VALUES.GOODS:
            data.specialTarget = data.applyGoods;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.specialTarget = data.applyBrand;
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.specialTarget = data.applyOfflineCategory;
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.specialTarget = data.applyVirtualCategory;
            break;
    }

    if (data.excludeType) {
        switch (data.excludeType) {
            case EXCLUDE_TYPE_LIMIT_VALUES.GOODS:
                data.excludeTarget = data.excludeGoods;
                break;
            case EXCLUDE_TYPE_LIMIT_VALUES.BRAND:
                data.excludeTarget = data.excludeBrand;
                break;
            case EXCLUDE_TYPE_LIMIT_VALUES.OFFLINE_CATEGORY:
                data.excludeTarget = data.excludeOfflineCategory;
                break;
            case EXCLUDE_TYPE_LIMIT_VALUES.VIRTUAL_CATEGORY:
                data.excludeTarget = data.excludeVirtualCategory;
                break;
        }
    } else {
        data.excludeType = '';
    }

    return data;
}

/**
 * 接口数据转换为表单数据
 */
function apiDataToFormData(data: AnyObject) {
    data.storeIds = data.onlineStoreList.map((item: AnyObject) => item.id);
    data.specialType = data.specialType.toString();
    data.excludeType = (data.excludeType && data.excludeType.toString()) || null;
    switch (data.specialType.toString()) {
        case APPLY_TYPE_VALUES.GOODS:
            data.applyGoods = data.specialTarget;
            break;
        case APPLY_TYPE_VALUES.BRAND:
            data.applyBrand = data.specialTarget;
            break;
        case APPLY_TYPE_VALUES.OFFLINE_CATEGORY:
            data.applyOfflineCategory = data.specialTarget;
            break;
        case APPLY_TYPE_VALUES.VIRTUAL_CATEGORY:
            data.applyVirtualCategory = data.specialTarget;
            break;
    }

    if (data.excludeType) {
        switch (data.excludeType) {
            case EXCLUDE_TYPE_LIMIT_VALUES.GOODS:
                data.excludeGoods = data.excludeTarget;
                break;
            case EXCLUDE_TYPE_LIMIT_VALUES.BRAND:
                data.excludeBrand = data.excludeTarget;
                break;
            case EXCLUDE_TYPE_LIMIT_VALUES.OFFLINE_CATEGORY:
                data.excludeOfflineCategory = data.excludeTarget;
                break;
            case EXCLUDE_TYPE_LIMIT_VALUES.VIRTUAL_CATEGORY:
                data.excludeVirtualCategory = data.excludeTarget;
                break;
        }
    } else {
        data.excludeType = '';
    }

    return data;
}

/**
 * 优惠券管理
 */
export default [
    {
        key: '/dc-goods/admin/product/limit',
        loader: {
            get(data: AnyObject = {}, config: AnyObject = {}) {
                if (data.id) {
                    return services.api
                        .get<AnyObject>(data, {
                            ...config,
                            apiPath: '/dc-goods/admin/product/limit/:id',
                        })
                        .then(apiDataToFormData);
                } else {
                    return services.api
                        .get<AnyObject>(data, {
                            ...config,
                            apiPath: '/dc-goods/admin/product/limit',
                        })
                        .then((data: AnyObject) => {
                            data.result = data.result.map((item: AnyObject) => {
                                dataPropValueToStr(item, ['status']);
                                return item;
                            });
                            return data;
                        });
                }
            },
            post(data: AnyObject = {}, config: AnyObject = {}) {
                return services.api.post<AnyObject>(formDataToApiData(data), {
                    ...config,
                    apiPath: '/dc-goods/admin/product/limit',
                });
            },
            put(data: AnyObject = {}, config: AnyObject = {}) {
                return services.api.put<AnyObject>(formDataToApiData(data), {
                    ...config,
                    apiPath: '/dc-goods/admin/product/limit/:id',
                });
            },
        },
    },
];

import { Config } from '@comall-backend-builder/core/lib/parser';

export const config = {
    apiRoot: ENV.API_ROOT,
    entities: {
        sessions: {
            apiPath: '/loader/sessions',
            properties: {
                name: {
                    type: 'string',
                },
                password: {
                    type: 'string.password',
                    format: 'password',
                },
            },
        },
        privileges: {
            apiPath: '/dc-user/admin/user_privileges',
        },
    },
    components: {
        SideNavFrame: {
            component: 'SelfViewport',
            entities: [
                {
                    name: 'sessions',
                    entityName: 'sessions',
                },
                {
                    name: 'privileges',
                    entityName: 'privileges',
                },
            ],
            content: {
                component: 'Frame',
                type: 'pro-style-nav',

                style: {
                    minHeight: '100%',
                },
                sider: {
                    items: [
                        {
                            component: 'Link',
                            url: '/#/',
                            imageurl: 'logo-dark.png',
                            text: 'logo',
                            className: 'flex-layout align-center justify-start global-logo',
                        },
                        {
                            component: 'Nav',
                            mode: 'inline',
                            theme: 'dark',
                            items: [
                                {
                                    type: 'item',
                                    key: 'home',
                                    title: '<<home.title>>',
                                    icon: 'home',
                                    route: '/home',
                                },
                                // 门店管理
                                {
                                    type: 'menu',
                                    key: 'storeManage',
                                    title: '<<storeManage.title>>',
                                    icon: 'shop',
                                    privilege: {
                                        path: 'store',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'onlineStore',
                                            title: '<<onlineStore.title>>',
                                            route: '/onlineStore',
                                            privilege: {
                                                path: 'store.onlinestore',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'pickStore',
                                            title: '<<pickStore.title>>',
                                            route: '/pickStore',
                                            privilege: {
                                                path: 'store.pickstore',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'pickOnlineStore',
                                            title: '<<pickOnlineStore.title>>',
                                            route: '/pickOnlineStore',
                                            privilege: {
                                                path: 'store.pickonlinestore',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'retailStore',
                                            title: '<<retailStore.title>>',
                                            route: '/retailStore',
                                            privilege: {
                                                path: 'store.retailstore',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'businessCooperator',
                                            title: '<<businessCooperator.title>>',
                                            route: '/businessCooperator',
                                            privilege: {
                                                path: 'store.merchant',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 品类管理
                                {
                                    type: 'menu',
                                    key: 'categoryManage',
                                    title: '<<categoryManage.title>>',
                                    icon: 'appstore',
                                    privilege: {
                                        path: 'category',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'brands',
                                            title: '<<brands.title>>',
                                            route: '/brands',
                                            privilege: {
                                                path: 'category.brand',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'offlineCategory',
                                            title: '<<offlineCategory.title>>',
                                            route: '/offlineCategory',
                                            privilege: {
                                                path: 'category.offline_categories',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'virtualCategory',
                                            title: '<<virtualCategory.navTitle>>',
                                            route: '/virtualCategory',
                                            privilege: {
                                                path: 'category.virtual_categorys',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'displayCategory',
                                            title: '<<displayCategory.title>>',
                                            route: '/displayCategory',
                                            privilege: {
                                                path: 'category.display_categorys',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 商品管理
                                {
                                    type: 'menu',
                                    key: 'productManage',
                                    title: '<<productManage.title>>',
                                    icon: 'shopping',
                                    privilege: {
                                        path: 'goods',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'productList',
                                            title: '<<productList.normal.title>>',
                                            route: '/productList/normal',
                                            privilege: {
                                                path: 'goods.goods',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productListVirtual',
                                            title: '<<productList.virtual.title>>',
                                            route: '/productList/virtual',
                                            privilege: {
                                                path: 'goods.virtual_goods_manage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productListGroup',
                                            title: '<<productList.group.title>>',
                                            route: '/productList/group',
                                            privilege: {
                                                path: 'goods.group_goods',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productSelection',
                                            title: '<<productSelection.title>>',
                                            route: '/product-selection',
                                            privilege: {
                                                path: 'goods.store_goods',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productPriceMonitoring',
                                            title: '<<productPrice.monitoring>>',
                                            route: '/product-price-monitoring',
                                            privilege: {
                                                path: 'goods.goods_price_view',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productPrice',
                                            title: '<<productPrice.title>>',
                                            route: '/product-price',
                                            privilege: {
                                                path: 'goods.goods_price',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productInventoryMonitoring',
                                            title: '<<productInventory.monitoring>>',
                                            route: '/product-inventory-monitoring',
                                            privilege: {
                                                path: 'goods.goods_stock_view',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productInventory',
                                            title: '<<productInventory.title>>',
                                            route: '/product-inventory',
                                            privilege: {
                                                path: 'goods.goods_stock',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productSuite',
                                            title: '<<productSuite.title>>',
                                            route: '/product-suite',
                                            privilege: {
                                                path: 'goods.goods_merge_manage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productSpec',
                                            title: '<<productSpec.title>>',
                                            route: '/product-style',
                                            privilege: {
                                                path: 'goods.goods_style',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productGroup',
                                            title: '<<productGroup.title>>',
                                            route: '/product-group',
                                            privilege: {
                                                path: 'goods.goods_relevance',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productSeo',
                                            title: '<<productSeo.title>>',
                                            route: '/productList/seo',
                                            privilege: {
                                                path: 'goods.goods_seo',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productShelf',
                                            title: '<<productShelf.title>>',
                                            route: '/productShelf',
                                            privilege: {
                                                path: 'goods.productscroll',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productMap',
                                            title: '<<productMap.title>>',
                                            route: '/product-map',
                                            privilege: {
                                                path: 'goods.goods_map',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'orderLimitManagement',
                                            title: '<<orderLimitManagement.title>>',
                                            route: '/product/order-limit-management',
                                            privilege: {
                                                path: 'goods.product_limit',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productPickupLimit',
                                            title: '<<productPickupLimit.title>>',
                                            route: '/product/pickup-limit',
                                            privilege: {
                                                path: 'goods.product_pick_limit',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                //配送管理
                                {
                                    type: 'menu',
                                    key: 'distributionManage',
                                    title: '<<distribution.title>>',
                                    icon: 'car',
                                    privilege: {
                                        path: 'delivery',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'storeFreightRules',
                                            title: '<<storeFreightRules.title>>',
                                            route: '/storeFreightRules',
                                            privilege: {
                                                path: 'delivery.goods_freight',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'merchantFreightRules',
                                            title: '<<merchantFreightRules.title>>',
                                            route: '/merchantFreightRules',
                                            privilege: {
                                                path: 'delivery.merchant_goods_freight',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                //订单管理
                                {
                                    type: 'menu',
                                    key: 'orderManage',
                                    title: '<<orderManage.title>>',
                                    icon: 'form',
                                    privilege: {
                                        path: 'order',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'orders',
                                            title: '<<order.title>>',
                                            route: '/orders',
                                            privilege: {
                                                path: 'order.order',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'selfHarvesting',
                                            title: '<<orderManage.selfHarvesting>>',
                                            route: '/order/self-harvesting',
                                            privilege: {
                                                path: 'order.self_pickup_view',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 拣货管理
                                {
                                    type: 'menu',
                                    key: 'picking',
                                    title: '<<picking.pickingManagement>>',
                                    icon: 'shopping-cart',
                                    privilege: {
                                        path: 'picking',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'pickingAssistant',
                                            title: '<<picking.pickingAssistant>>',
                                            route: '/picking-assistant',
                                            privilege: {
                                                path: 'picking.assistant',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 售后管理
                                {
                                    type: 'menu',
                                    key: 'afterSalesManage',
                                    title: '<<afterSalesManage>>',
                                    icon: 'dashboard',
                                    privilege: {
                                        path: 'aftermarket',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'afterSalesOrder',
                                            title: '<<afterSalesOrder.title>>',
                                            route: '/afterSalesOrder',
                                            privilege: {
                                                path: 'aftermarket.after_sale_view',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'refundOrder',
                                            title: '<<afterSalesOrder.refundOrder>>',
                                            route: '/refundOrder',
                                            privilege: {
                                                path: 'aftermarket.order_refund_view',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                }, // 线下业务管理
                                {
                                    type: 'menu',
                                    key: 'offlineManage',
                                    title: '<<offlineManage.title>>',
                                    icon: 'profile',
                                    privilege: {
                                        path: 'offline',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'offlineOrder',
                                            title: '<<offlineOrder.title>>',
                                            route: '/offlineOrders',
                                            privilege: {
                                                path: 'offline.offline_order',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'overrideCode',
                                            title: '<<overrideCode.title>>',
                                            route: '/override-code',
                                            privilege: {
                                                path: 'offline.override_code',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'salesclerk',
                                            title: '<<salesclerk.title>>',
                                            route: '/salesclerk',
                                            privilege: {
                                                path: 'offline.salesclerk',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 装修管理
                                {
                                    type: 'menu',
                                    key: 'designManager',
                                    title: '<<designManager.title>>',
                                    icon: 'search',
                                    privilege: {
                                        path: 'finish',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'searchWordsManagePage',
                                            title: '<<designManager.searchWords.title>>',
                                            route: '/search/search-words',
                                            privilege: {
                                                path: 'finish.default_manage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'hotWordsManagePage',
                                            title: '<<designManager.hotWords.title>>',
                                            route: '/search/hot-words',
                                            privilege: {
                                                path: 'finish.hot_manage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'guessYouLikeSettingView',
                                            title: '<<designManager.guessYouLikeSetting.title>>',
                                            route: '/search/guess-you-like-settings',
                                            privilege: {
                                                path: 'finish.like_goods_manage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'home-manage',
                                            title: '<<routes.HomeManage>>',
                                            route: '/search/home-manage',
                                            privilege: {
                                                path: 'finish.home_page_manage_new',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'topic-manage',
                                            title: '<<routes.TopicManage>>',
                                            route: '/search/topic-manage',
                                            privilege: {
                                                path: 'finish.topic_manage_new',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'menuBarManagementView',
                                            title: '<<routes.menuBarManagementView>>',
                                            route: '/search/menu-bar',
                                            privilege: {
                                                path: 'finish.menu_bar',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'noticeBarManagement',
                                            title: '<<routes.noticeBarManagement>>',
                                            route: '/search/notice',
                                            privilege: {
                                                path: 'finish.notification_bar',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'recommendedBrand',
                                            title: '<<routes.recommendedBrand>>',
                                            route: '/search/recommended-brand',
                                            privilege: {
                                                path: 'finish.menu_bar',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'popupAds',
                                            title: '<<popupAds.title>>',
                                            route: '/popup-ads',
                                            privilege: {
                                                path: 'finish.advertisement_menu_bar',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 权限管理
                                {
                                    type: 'menu',
                                    key: 'privilegeManage',
                                    title: '<<privilegeManage.title>>',
                                    icon: 'lock',
                                    privilege: {
                                        path: 'privilege',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'privilegeUsers',
                                            title: '<<privilegeManage.users.title>>',
                                            route: '/privilege/users',
                                            privilege: {
                                                path: 'privilege.user',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'privilegeRoles',
                                            title: '<<privilegeManage.roles.title>>',
                                            route: '/privilege/roles',
                                            privilege: {
                                                path: 'privilege.role',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },

                                // 会员管理
                                {
                                    type: 'menu',
                                    key: 'memberManage',
                                    title: '<<membership.title>>',
                                    icon: 'profile',
                                    privilege: {
                                        path: 'member',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'membershipList',
                                            title: '<<membership.membershipList>>',
                                            route: '/members',
                                            privilege: {
                                                path: 'member.member_view',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'membershipLevel',
                                            title: '<<membershipLevel.title>>',
                                            route: '/member-level',
                                            privilege: {
                                                path: 'member.member_level_view',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'memberExamine',
                                            title: '<<memberExamine.title>>',
                                            route: '/member-examine',
                                            privilege: {
                                                path: 'member.member_examine',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'memberSource',
                                            title: '<<memberSource.title>>',
                                            route: '/member-source',
                                            privilege: {
                                                path: 'member.member_source',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 日志管理
                                {
                                    type: 'menu',
                                    key: 'logsManage',
                                    title: '<<importRecord.title>>',
                                    icon: 'profile',
                                    privilege: {
                                        path: 'log',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'importRecord',
                                            title: '<<importRecord.breadcrumbName>>',
                                            route: '/import-records',
                                            privilege: {
                                                path: 'log.import_log',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'exportFiles',
                                            title: '<<exportFiles.breadcrumbName>>',
                                            route: '/export-files',
                                            privilege: {
                                                path: 'log.data_export',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 三方对接管理
                                {
                                    type: 'menu',
                                    key: 'thirdParty',
                                    title: '<<thirdParty.title>>',
                                    icon: 'dashboard',
                                    privilege: {
                                        path: 'channel',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'thirdPartyManagement',
                                            title: '<<thirdPartyManagement.title>>',
                                            route: '/thirdPartyManagement',
                                            privilege: {
                                                path: 'channel.channel_manage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'thirdPartyAccount',
                                            title: '<<thirdPartyAccount.title>>',
                                            route: '/thirdPartyAccount',
                                            privilege: {
                                                path: 'channel.channel_account_manage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'thirdInterfaceLogs',
                                            title: '<<thirdInterfaceLogs.title>>',
                                            route: '/thirdInterfaceLogs',
                                            privilege: {
                                                path: 'channel.channel_log_manage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'thirdPartyWarehouse',
                                            title: '<<thirdPartyWarehouse.title>>',
                                            route: '/third-party-warehouse',
                                            privilege: {
                                                path: 'channel.channel_third_order_add',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'thirdPartyDelivery',
                                            title: '<<thirdPartyDelivery.title>>',
                                            route: '/third-party-delivery',
                                            privilege: {
                                                path: 'channel.channel_third_order_deliery',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'thirdPartySync',
                                            title: '<<thirdPartySync.title>>',
                                            route: '/third-party-sync',
                                            privilege: {
                                                path: 'channel.channel_third_stock_sync',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 卡券管理
                                {
                                    type: 'menu',
                                    key: 'couponManage',
                                    title: '<<couponManage.title>>',
                                    icon: 'account-book',
                                    privilege: {
                                        path: 'coupon',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'discountCoupon',
                                            title: '<<couponManage.discountCoupon.title>>',
                                            route: '/discount-coupon',
                                            privilege: {
                                                path: 'coupon.discount_coupon',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'freightCoupon',
                                            title: '<<couponManage.freightCoupon.title>>',
                                            route: '/freight-coupon',
                                            privilege: {
                                                path: 'coupon.freight_coupon',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'cashCoupon',
                                            title: '<<couponManage.cashCoupon.title>>',
                                            route: '/cash-coupon',
                                            privilege: {
                                                path: 'coupon.voucher',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'thirdPartyCoupon',
                                            title: '<<couponManage.thirdPartyCoupon.title>>',
                                            route: '/third-party-coupon',
                                            privilege: {
                                                path: 'coupon.otherCoupon',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'voucherManagement',
                                            title: '<<couponManage.voucherManagement.title>>',
                                            route: '/voucher-management',
                                            privilege: {
                                                path: 'coupon.voucher_grant',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'couponCenter',
                                            title: '<<couponManage.couponCenter.title>>',
                                            route: '/coupon-center',
                                            privilege: {
                                                path: 'coupon.center',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'couponPackage',
                                            title: '<<couponManage.couponPackage.title>>',
                                            route: '/coupon-package',
                                            privilege: {
                                                path: 'coupon.center',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 营促销管理
                                {
                                    type: 'menu',
                                    key: 'salesPromotion',
                                    title: '<<salesPromotion.title>>',
                                    icon: 'account-book',
                                    privilege: {
                                        path: 'activity_management',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'salesMultiPromotion',
                                            title: '<<salesPromotion.sub.singleMultipleProduct>>',
                                            route: '/sales-promotion/sales-multi-promotion',
                                            privilege: {
                                                path: 'activity_management.promotion',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'eventMarketing',
                                            title: '<<salesPromotion.eventMarketing.title>>',
                                            route: '/sales-promotion/event-marketing',
                                            privilege: {
                                                path: 'activity_management.activity_event',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },

                                // 报表管理
                                {
                                    type: 'menu',
                                    key: 'reportsManage',
                                    title: '<<report.title>>',
                                    icon: 'profile',
                                    privilege: {
                                        path: 'forms',
                                        level: 'full',
                                    },
                                    items: [
                                        /** 订单发运报表 */
                                        {
                                            type: 'item',
                                            key: 'orderShippingReport',
                                            title: '<<report.orderShipping.title>>',
                                            route: '/report/order-shipping',
                                            privilege: {
                                                path: 'forms.order_ship',
                                                level: 'full',
                                            },
                                        },
                                        /** 订单汇总报表 */
                                        {
                                            type: 'item',
                                            key: 'orderSummaryReport',
                                            title: '<<report.orderSummary.title>>',
                                            route: '/report/order-summary',
                                            privilege: {
                                                path: 'forms.order_gather',
                                                level: 'full',
                                            },
                                        },
                                        /** 订单明细报表 */
                                        {
                                            type: 'item',
                                            key: 'orderDetailReport',
                                            title: '<<report.orderDetail.title>>',
                                            route: '/report/order-detail',
                                            privilege: {
                                                path: 'forms.order_details',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'orderDetailUnPriceReport',
                                            title: '<<report.orderDetailUnPrice.title>>',
                                            route: '/report/order-detail-unprice',
                                            privilege: {
                                                path: 'forms.order_details_no_price',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'refundSummaryReport',
                                            title: '<<report.refundSummary.title>>',
                                            route: '/report/order-refund',
                                            privilege: {
                                                path: 'forms.refund_gather',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'voucherReport',
                                            title: '<<report.voucher.title>>',
                                            route: '/report/voucher',
                                            privilege: {
                                                path: 'forms.exchange_coupon',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'productMapReport',
                                            title: '<<report.productMap.title>>',
                                            route: '/report/product-map',
                                            privilege: {
                                                path: 'forms.third_sku',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                }, // 飞利浦订单管理
                                {
                                    type: 'menu',
                                    key: 'philipsOrder',
                                    title: '<<philipsOrder.title>>',
                                    icon: 'account-book',
                                    privilege: {
                                        path: 'order_philips',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'philipsOrderInfo',
                                            title: '<<philipsOrder.subTitle>>',
                                            route: '/orders-philips',
                                            privilege: {
                                                path: 'order_philips.order_philips',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 飞利浦拣货管理
                                {
                                    type: 'menu',
                                    key: 'pickingPhilips',
                                    title: '<<pickingPhilips.title>>',
                                    icon: 'shopping-cart',
                                    privilege: {
                                        path: 'picking_philips',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'philipsPickingAssistant',
                                            title: '<<pickingPhilips.subTitle>>',
                                            route: '/picking-assistant-philips',
                                            privilege: {
                                                path: 'picking_philips.assistant_philips',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 舆情管理
                                {
                                    type: 'menu',
                                    key: 'publicOpinion',
                                    title: '<<publicOpinion.title>>',
                                    icon: 'account-book',
                                    privilege: {
                                        path: 'opinion_management',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'commodityEvaluation',
                                            title: '<<commodityEvaluation.title>>',
                                            route: '/public-opinion/commodity-evaluation',
                                            privilege: {
                                                path: 'opinion_management.sku_evaluation',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 营促销管理
                                {
                                    type: 'menu',
                                    key: 'notification',
                                    title: '<<notification.title>>',
                                    icon: 'account-book',
                                    privilege: {
                                        path: 'notification',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'siteMessages',
                                            title: '<<siteMessages.title>>',
                                            route: '/site-messages',
                                            privilege: {
                                                path: 'notification.inmail_menu_bar',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                // 系统管理
                                {
                                    type: 'menu',
                                    key: 'systemManagement',
                                    title: '<<systemManagement.title>>',
                                    icon: 'account-book',
                                    privilege: {
                                        path: 'system_management',
                                        level: 'full',
                                    },
                                    items: [
                                        {
                                            type: 'item',
                                            key: 'urlMapping',
                                            title: '<<systemManagement.urlMapping.title>>',
                                            route: '/system/url-mapping',
                                            privilege: {
                                                path: 'system_management.url_mapping_view',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: 'paymentMode',
                                            title: '<<systemManagement.paymentMode.title>>',
                                            route: '/system/payment-mode',
                                            privilege: {
                                                path: 'system_management.payment_mode',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                content: {
                    className: 'page-content',
                    items: [
                        {
                            component: 'BreadcrumbNav',
                        },
                        {
                            component: 'Viewport',
                            style: {
                                margin: 20,
                                border: 0,
                            },
                        },
                    ],
                },
                header: {
                    items: [
                        {
                            component: 'LanguageSelect',
                            flex: '0 0 auto',
                        },
                        {
                            component: 'UserMenu',
                            flex: '0 0 auto',
                        },
                    ],
                },
                footer: {
                    items: [
                        {
                            component: 'Text',
                            text: '<<copyright>>',
                        },
                    ],
                },
            },
        },
        Home: {
            component: 'Card',
            content: {
                component: 'Text',
                text: '<<welcome>>',
            },
        },
        PrivilegeContainer: {
            component: 'Card',
            content: {
                component: 'Text',
                text: '<<Privilege.text>>',
            },
        },
        LoginBackPage: {
            component: 'LoginBack',
            entities: [
                {
                    name: 'sessions',
                    entityName: 'sessions',
                },
                {
                    name: 'privileges',
                    entityName: 'privileges',
                },
            ],
        },
        LoginPage: {
            component: 'Login',
            entities: [
                {
                    name: 'sessions',
                    entityName: 'sessions',
                },
                {
                    name: 'privileges',
                    entityName: 'privileges',
                },
            ],
        },
        UserMenu: {
            component: 'Nav',
            entity: 'sessions',

            className: 'nav-header',
            mode: 'horizontal',
            theme: 'light',
            items: [
                {
                    key: 'noAuthorizationRefresh',
                    component: {
                        name: 'NoAuthorizationRefresh',
                    },
                },
                {
                    type: 'menu',
                    key: 'user',
                    component: {
                        name: 'UserProfileMini',
                        loginUserApiPath: '/dc-user/admin/login_user',
                        avatar: {
                            displayConfig: {
                                style: {
                                    backgroundColor: '#91d5ff',
                                    marginRight: 5,
                                    display: 'inline-block',
                                },
                                size: 'small',
                                icon: 'user',
                            },
                        },
                        userName: {
                            configs: {
                                style: {
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                },
                            },
                        },
                    },
                    items: [
                        {
                            type: 'item',
                            key: 'logout',
                            component: {
                                name: 'Logout',
                            },
                        },
                    ],
                },
            ],
        },
    },
} as Config;

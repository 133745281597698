/**
 * 代金券
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { recordImportLogs } from '@/services';
import { services } from '@comall-backend-builder/core';
import { flexibleFilterForm, tableMixin } from '@/configs/mixin';
import { IMPORT_TEMPLATE_URL } from '@/constants';
import { getStoreInfo } from '@/services/utils';

export const config: Config = {
    entities: {
        cashCoupon: {
            apiPath: '/loader/dc-ecoupon/admin/vouchers',
            properties: {
                id: { type: 'string' },
                voucherNum: {
                    type: 'string',
                    displayName: '<<couponManage.cashCoupon.couponNo>>',
                },
                memberPhone: {
                    type: 'string',
                    displayName: '<<couponManage.cashCoupon.mobile>>',
                },
                totalAmount: {
                    type: 'string',
                    displayName: '<<couponManage.cashCoupon.totalAmount>>',
                },
                balance: {
                    type: 'string',
                    displayName: '<<couponManage.cashCoupon.remindAmount>>',
                },
                effectStartTime: {
                    type: 'string',
                    displayName: '<<couponManage.cashCoupon.startTime>>',
                },
                effectEndTime: {
                    type: 'string',
                    displayName: '<<couponManage.cashCoupon.endTime>>',
                },
            },
            filters: {
                voucherNum: {
                    type: 'string',
                    displayName: '<<couponManage.cashCoupon.couponNo>>',
                },
                memberPhone: {
                    type: 'string',
                    displayName: '<<couponManage.cashCoupon.mobile>>',
                },
            },
        },
    },
    components: {
        CashCouponView: {
            component: 'Viewport',
        },
        CashCouponPage: {
            component: 'Card',
            entity: 'cashCoupon',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'StoreSelectorContainer',
                        params: {},
                        storeKey: 'onlineStoreId',
                        filterConfig: {
                            ...flexibleFilterForm,
                            fields: [{ property: 'voucherNum' }, { property: 'memberPhone' }],
                            privilege: {
                                path: 'coupon.voucher.voucher_list',
                                level: 'full',
                            },
                        },
                        buttonConfig: {
                            component: 'FlexLayout',
                            direction: 'horizontal',
                            style: {
                                display: 'block',
                                marginBottom: 15,
                            },
                            items: [
                                {
                                    component: 'ExportButtonWithFilter',
                                    text: '<<couponManage.cashCoupon.export>>',
                                    icon: 'download',
                                    style: {
                                        marginRight: 15,
                                    },
                                    source: {
                                        apiPath: `/dc-ecoupon/admin/vouchers/export/take`,
                                    },
                                    range: {
                                        apiPath: '/dc-ecoupon/admin/vouchers/export/count',
                                        perPage: 100000,
                                    },
                                    isAsync: true,
                                    showSuccessTips: true,
                                    showErrorMessage: true,
                                    formatter: (params: any) => {
                                        const newParams: any = { ...params };

                                        return { ...newParams };
                                    },
                                    privilege: {
                                        path: 'coupon.voucher.voucher_export',
                                        level: 'full',
                                    },
                                },
                                {
                                    component: 'Upload',
                                    apiPath: '/dc-file/excels?location_id=2',
                                    showSuccessMessage: false,
                                    className: 'upload-link',
                                    triggerComponent: {
                                        component: 'Button',
                                        icon: 'upload',
                                        text: '<<couponManage.cashCoupon.import>>',
                                        style: {
                                            marginRight: 15,
                                        },
                                    },
                                    onUploadComplete(response: any) {
                                        if (response) {
                                            recordImportLogs({
                                                name: services.language.getText(
                                                    'couponManage.cashCoupon.importTip'
                                                ),
                                                apiPath: `/dc-ecoupon/admin/vouchers/import`,
                                                params: {
                                                    resourceId: response.id,
                                                    onlineStoreId: getStoreInfo().id,
                                                },
                                            });
                                        }
                                    },
                                    privilege: {
                                        path: 'coupon.voucher.voucher_import',
                                        level: 'full',
                                    },
                                },
                                {
                                    component: 'OSSExportButton',
                                    text: '<<productList.downloadTemplate>>',
                                    type: 'link',
                                    privilege: {
                                        path: `coupon.voucher.download_import_template`,
                                        level: 'full',
                                    },
                                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.CASH_COUPON}`,
                                },
                            ],
                        },
                        tableConfig: {
                            ...tableMixin,
                            loadFirstPage: false,
                            columns: [
                                { property: 'voucherNum' },
                                { property: 'memberPhone' },
                                { property: 'totalAmount' },
                                { property: 'balance' },
                                { property: 'effectStartTime' },
                                { property: 'effectEndTime' },
                            ],
                            privilege: {
                                path: 'coupon.voucher.voucher_list',
                                level: 'full',
                            },
                        },
                    },
                ],
            },
        },
    },
};

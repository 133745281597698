import React, { PureComponent } from 'react';
import { modes } from '@comall-backend-builder/types';
import { Textbox, ExportButton } from '@comall-backend-builder/components-basis';
import { Upload } from '@/containers';
import { services } from '@comall-backend-builder/core';
import { trim, isEmpty, join } from 'lodash';

export class UploadArrayMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <UploadArrayComponent {...controlInfo} />;
    }
}

class UploadArrayComponent extends PureComponent<any> {
    render() {
        const { value = [], name, onChange: onParentChange } = this.props;
        const onValueChange = (v: string, name: string) => {
            const trimResult = trim(v);
            if (isEmpty(trimResult)) {
                onParentChange([], name);
            } else {
                //去重
                const set = new Set(trimResult.split(';'));
                onParentChange(Array.from(set), name);
            }
        };
        const onUpload = (v: string[], name: string) => {
            //去重
            const set = new Set([...value, ...v]);
            onParentChange(Array.from(set), name);
        };
        return (
            <div className='upload-array'>
                <Textbox
                    //@ts-ignore
                    onChange={onValueChange}
                    name={name}
                    placeholder={services.language.getText('productTag.uploadPlaceholder')}
                    value={join(value, ';')}
                    type='textarea'
                />
                <Upload
                    name={name}
                    apiPath='/dc-goods/admin/tags/upload'
                    triggerComponent={{
                        component: 'Button',
                        text: services.language.getText('productTag.uploadProducts'),
                        icon: 'upload',
                    }}
                    onChange={onUpload}
                />
                <div className='bottom-area'>
                    <span
                        className='upload-tip'
                        style={{
                            color: 'rgba(0, 0, 0, 0.45)',
                        }}
                    >
                        {services.language.getText('productTag.uploadTip')}
                    </span>
                    <ExportButton
                        source={{
                            apiPath: '/dc-file/templates/10000',
                            paramsFilter: [],
                        }}
                        //@ts-ignore
                        type='link'
                        text={services.language.getText('downloadTemplate')}
                    />
                </div>
            </div>
        );
    }
}

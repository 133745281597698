import React, { Suspense } from 'react';
import { Spin } from 'antd';

export const DesignPageComponent = React.lazy(() =>
    import('./design-page-component').then(({ DesignPageComponent }) => ({
        default: DesignPageComponent,
    }))
);

export function DesignPage(props: AnyObject) {
    return (
        <Suspense
            fallback={
                <Spin
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    size='large'
                    tip='loading...'
                />
            }
        >
            <DesignPageComponent {...props} />
        </Suspense>
    );
}

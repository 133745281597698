import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        PhilipsOrderInfo: {
            component: 'Card',
            className: 'order-info',
            type: 'inner',
            entity: 'philipsOrderList',
            loaderType: 'get',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'OrderInfoBasic',
                        config: {
                            type: 'philips',
                        },
                    },
                    {
                        component: 'OrderInfoRemark',
                    },
                    {
                        component: 'OrderInfoProgressCard',
                        // orderRefundApiPath: '/dc-order/admin/order_refunds',
                    },
                    {
                        component: 'OrderInfoProduct',
                    },
                    {
                        component: 'OrderInfoCheckout',
                    },
                ],
            },
        },
    },
};

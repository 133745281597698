import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { WeightStepFreight } from '@/components/weight-step-freight';

export class WeightStepFreightMode extends modes.NumberIntegerMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <WeightStepFreight {...displayInfo} />;
    }
}

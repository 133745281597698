import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '@/configs/mixin';
import { STATUS_NUMBER_OPTIONS } from '@/constants';

export const config: Config = {
    entities: {
        thirdPartyManagement: {
            apiPath: '/dc-master-data/admin/channel',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<thirdPartyManagement.thirdPartyName>>',
                },
                type: {
                    type: 'string',
                    displayName: '<<thirdPartyManagement.thirdPartyType>>',
                },
                whiteStr: {
                    type: 'string',
                    displayName: '<<thirdPartyManagement.white>>',
                },
                permissionsStr: {
                    type: 'string',
                    displayName: '<<thirdPartyManagement.permissions>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<status>>',
                },
            },
            // filters: {
            //     idOrName: {
            //         type: 'string.text.trim',
            //         displayName: '<<keyword>>',
            //     },
            // },
        },
    },

    components: {
        ThirdPartyManagementContainer: {
            component: 'Viewport',
        },
        ThirdPartyManagement: {
            component: 'Card',
            entity: 'thirdPartyManagement',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'ThirdPartyManagementHeader',
                    },
                    {
                        component: 'ThirdPartyManagementTable',
                    },
                ],
                privilege: {
                    path: 'channel.channel_manage.view_list',
                    level: 'full',
                },
            },
        },
        ThirdPartyManagementHeader: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 15,
            },
            items: [
                {
                    type: 'component',
                    component: 'ThirdPartManagementAdd',
                    text: '<<thirdPartyManagement.add>>',
                    style: {
                        width: '150px',
                        flex: '0 0 auto',
                    },
                    privilege: {
                        path: 'channel.channel_manage.add',
                        level: 'full',
                    },
                },
                // {
                //     component: 'ThirdPartyManagementFilter',
                // },
            ],
        },
        // ThirdPartyManagementFilter: {
        //     component: 'FilterFormPlus',
        //     direction: 'inline',
        //     submit: {
        //         text: '<<query>>',
        //     },
        //     reset: true,
        //     fields: [
        //         {
        //             property: 'idOrName',
        //             controlConfig: {
        //                 allowClear: true,
        //                 showSearch: true,
        //                 placeholder: '<<defaultPlaceholderInput>>',
        //                 style: {
        //                     width: 220,
        //                 },
        //             },
        //         },
        //     ],
        // },
        ThirdPartyManagementTable: {
            ...tableMixin,
            component: 'DataTable',
            // component: 'ProTable',
            // componentName: PICK_STORE_TABLE,
            // columns: [...getLanguageColumns('languageName'), { property: 'retailStoreId' }],
            scroll: {
                x: 'max-content',
            },
            columns: [
                { property: 'name' },
                { property: 'type' },
                { property: 'whiteStr' },
                { property: 'permissionsStr' },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: STATUS_NUMBER_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                width: 250,
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                // '0': '/dc-cms-api/guess_like/{{row.id}}/enable',
                                // '1': '/dc-cms-api/guess_like/{{row.id}}/disable',
                                '0': '/dc-master-data/admin/channel/{{row.id}}/status',
                                '1': '/dc-master-data/admin/channel/{{row.id}}/status',
                            },
                            statusParams: {
                                '0': { status: 1 },
                                '1': { status: 0 },
                            },
                            statusText: {
                                '0': '<<enable>>',
                                '1': '<<disable>>',
                            },
                        },
                        privilege: {
                            path: 'channel.channel_manage.disable',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionThirdPartEdit',
                        text: '<<edit>>',
                        config: {
                            modalType: 'edit',
                        },
                        privilege: {
                            path: 'channel.channel_manage.edit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

import React, { PureComponent } from 'react';
import {
    Card as AntCard,
    Table as AntTable,
    Modal as AntModal,
    Input as AntInput,
    message as AntMessage,
} from 'antd';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { trim, cloneDeep } from 'lodash';
import moment from 'moment';

/**
 * 创建售后单 - 售后单备注信息
 */
class afterSaleCreateRemark extends PureComponent {
    state = {
        showRemarkModal: false,
        remarkValue: '',
        loading: false,
    };

    remarkButton = () => {
        const handleOk = () => {
            if (!this.state.remarkValue) {
                AntMessage.error(services.language.getText('order.orderRemarkTips'));
                return;
            }
            this.setState({ loading: true });
            const newRemarks = cloneDeep(this.props.entity.fields.remarks);
            newRemarks.push({
                content: trim(this.state.remarkValue),
                createTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                createUser: window.sessionStorage.getItem('realName'),
            });
            this.props.entity.setFields({ ...this.props.entity.fields, remarks: newRemarks });
            this.setState({
                showRemarkModal: false,
                remarkValue: '',
                loading: false,
            });
        };

        return (
            <div className='remark-button-row'>
                {services.language.getText('order.orderInfoRemark.remark')}
                <Button
                    onClick={() => {
                        this.setState({ showRemarkModal: true });
                    }}
                >
                    {services.language.getText('order.orderRemarkTitle')}
                </Button>
                <AntModal
                    title={services.language.getText('order.orderRemarkTitle')}
                    visible={this.state.showRemarkModal}
                    onOk={handleOk}
                    onCancel={() => {
                        this.setState({ showRemarkModal: false });
                    }}
                    confirmLoading={this.state.loading}
                >
                    <AntInput.TextArea
                        rows={4}
                        maxLength={200}
                        value={this.state.remarkValue}
                        onChange={(e) => {
                            this.setState({ remarkValue: e.target.value });
                        }}
                        placeholder={services.language.getText('order.orderRemarkPlaceholder')}
                    />
                </AntModal>
            </div>
        );
    };

    render() {
        const { style, remarks } = this.props;
        const cardProps = {
            className: 'after-sale-create-remark',
            bordered: false,
            title: this.remarkButton(),
            style,
        };

        const tableProps = {
            rowKey: 'createTime',
            pagination: false,
            columns: [
                {
                    title: services.language.getText('order.orderInfoRemark.content'),
                    dataIndex: 'content',
                    key: 'content',
                },
                {
                    title: services.language.getText('order.orderInfoRemark.createTime'),
                    dataIndex: 'createTime',
                    key: 'createTime',
                    width: '25%',
                },
                {
                    title: services.language.getText('order.orderInfoRemark.userName'),
                    dataIndex: 'createUser',
                    key: 'createUser',
                    width: '15%',
                },
            ],
            dataSource: remarks,
        };
        return !!remarks ? (
            <AntCard {...cardProps}>
                <AntTable {...tableProps} />
            </AntCard>
        ) : null;
    }
}

const mapStateToProps = (state, ownProps) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const AfterSaleCreateRemark = connect(mapStateToProps, null)(afterSaleCreateRemark);

import React, { createElement, PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions, ComponentsManager, services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { get, mapValues } from 'lodash';
import { message as AntMessage } from 'antd';

const RENDER_COMPONENT = {
    modify: 'ModifyForm',
    view: 'Detail',
};

export class ProductInfoComponent extends PureComponent {
    static defaultProps = {
        renderType: 'modify',
    };
    state = {
        loading: false,
    };
    onSubmit = () => {
        const { fields, entity, params, onSubmitStart } = this.props;
        this.ProductBasicRef &&
            this.ProductMarketRef &&
            this.ProductDisplayRef &&
            this.ProductBasicRef.props.form.validateFields((err) => {
                if (err) {
                    AntMessage.error(services.language.getText('productInfo.errorTip.basic'));
                    return;
                }
                this.ProductMarketRef.props.form.validateFields((err) => {
                    if (err) {
                        AntMessage.error(services.language.getText('productInfo.errorTip.sale'));
                        return;
                    }
                    this.ProductDisplayRef.props.form.validateFields((err) => {
                        if (err) {
                            AntMessage.error(
                                services.language.getText('productInfo.errorTip.show')
                            );
                            return;
                        }
                        if (onSubmitStart) {
                            onSubmitStart();
                        }
                        this.setState({ loading: true });
                        entity.modify(fields, params, {
                            route: 'goBack',
                        });
                    });
                });
            });
    };
    /**
     * 覆盖本来有的init方法，不需要做初始化
     */
    noInit = () => {};

    render() {
        const {
            entity,
            componentId,
            onFieldChange,
            onInit,
            renderType,
            params,
            entities,
        } = this.props;

        const renderComponent = RENDER_COMPONENT[renderType];
        //三个页签这里要一起处理，所以共用一个componentId
        return (
            <div>
                <div className={'product-info'}>
                    {createElement(ComponentsManager.get('Tabs'), {
                        entity,
                        entities,
                        componentId,
                        animated: false,
                        onInit: this.onInit,
                        params,
                        items: [
                            {
                                title: services.language.getText('productInfo.productBasic'),
                                forceRender: true,
                                entity: 'product',
                                entities,
                                content: {
                                    component: 'ProductBasic',
                                    componentId,
                                    wrappedComponentRef: (ref) => (this.ProductBasicRef = ref),
                                    onInit: onInit,
                                    onFieldChange,
                                    renderComponent,
                                    initButton: () => {
                                        this.setState({
                                            loading: false,
                                        });
                                    },
                                },
                            },
                            {
                                title: services.language.getText('productInfo.productEC'),
                                forceRender: true,
                                content: {
                                    component: 'ProductMarket',
                                    componentId,
                                    onFieldChange,
                                    onInit: this.noInit,
                                    wrappedComponentRef: (ref) => (this.ProductMarketRef = ref),
                                    renderComponent,
                                },
                            },
                            {
                                title: services.language.getText('productInfo.productOther'),
                                forceRender: true,
                                content: {
                                    component: 'ProductDisplay',
                                    componentId,
                                    onFieldChange,
                                    wrappedComponentRef: (ref) => (this.ProductDisplayRef = ref),
                                    onInit: this.noInit,
                                    renderComponent,
                                },
                            },
                            {
                                title: services.language.getText('productInfo.productAll'),
                                content: {
                                    component: 'ProductAttributesTab',
                                },
                            },
                        ],
                    })}
                </div>
                <div className={'product-operation fixed-submit'}>
                    {/* <Button type='primary' text={'返回'} route={'goBack'} /> */}
                    {renderType === 'modify' ? (
                        <Button
                            text={services.language.getText('save')}
                            onClick={this.onSubmit}
                            loading={this.state.loading}
                            type={'primary'}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { componentId } = ownProps;
    const fields = get(state, `components.${componentId}.fields`, {});
    return { fields };
};

const mapDispatchToProps = (dispatch, props) => {
    const { componentId, entity } = props;
    let {
        fields: entityFields,
        metainfo: { properties },
    } = entity;
    const loaded = !!entityFields;
    const fields = mapValues(properties, (config, name) => {
        return entityFields ? entityFields[name] : undefined;
    });
    return {
        onFieldChange: (name, value) => {
            dispatch(actions.formChangeAction(componentId, name, value));
        },
        onSubmitStart: () => {
            dispatch(actions.componentSubmitAction(componentId, 'modify'));
        },
        onInit: () => {
            dispatch(
                actions.formInitAction(componentId, {
                    type: 'EditForm',
                    entityId: entity.id,
                    fields,
                    loaded,
                })
            );
        },
    };
};

export const ProductInfo = connect(mapStateToProps, mapDispatchToProps)(ProductInfoComponent);

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, Modal, Table, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash-es';

import { OrderInfoProductProps } from '.';
import './index.less';

const initData = { page: 1, perPage: 10, keywords: undefined };
export interface GoodSelectorRef {
    open(): void;
}

const GoodSelector = forwardRef<GoodSelectorRef, OrderInfoProductProps>((props, ref) => {
    const [showSelect, setShowSelect] = useState(false);
    const [params, setParams] = useState(initData);
    const [goodList, setGoodList] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<AnyObject[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [total, setTotal] = useState(0);

    const columns = [
        {
            title: services.language.getText('offlineOrder.selectGoods.name'),
            key: 'cskuName',
            dataIndex: 'cskuName',
            render: (val: string) => {
                return <div className='offline-order-select-goods-name'>{val}</div>;
            },
        },
        {
            title: services.language.getText('offlineOrder.selectGoods.sku'),
            key: 'csku',
            dataIndex: 'csku',
        },
        {
            title: services.language.getText('offlineOrder.selectGoods.barCode'),
            key: 'barCode',
            dataIndex: 'barCode',
        },
    ];

    useImperativeHandle(
        ref,
        () => ({
            open: () => setShowSelect(true),
        }),
        []
    );

    useEffect(() => {
        if (showSelect) {
            getProductList(initData);
            setSelectedRows([]);
        }
    }, [showSelect]);

    function getProductList(filter = {}) {
        setLoading(true);
        services.api
            .get(
                {
                    onlineStoreId: props.onlineStoreIds,
                    memberId: props.memberId,
                    sort: 1,
                    ...filter,
                },
                { apiPath: '/dc-checkout-api/admin/offline/product/open/filter' }
            )
            .then((res: any) => {
                setGoodList(res.result);
                setTotal(res.totalNum);
            })
            .catch(services.errorHandle)
            .finally(() => {
                setLoading(false);
            });
    }

    function handleSearch(vals: any) {
        const newParams = { ...params, ...vals, page: 1 };
        setParams(newParams);
        setSelectedRows([]);
        getProductList(newParams);
    }

    function handleReset() {
        setShowSelect(false);
        setParams(initData);
        setSelectedRows([]);
    }

    function onPageChange(page: any) {
        const newParams = { ...params, page };
        setParams(newParams);
        getProductList(newParams);
    }

    const handleOk = async () => {
        if (!selectedRows.length) {
            return message.error(services.language.getText('productShelf.pleaseSelectProducts'));
        }
        const csku = get(selectedRows[0], 'csku');
        setSelectLoading(true);
        props
            .onSelect(csku)
            .then(() => {
                setShowSelect(false);
            })
            .finally(() => {
                setSelectLoading(false);
            });
    };

    return (
        <>
            <Button
                type='link'
                onClick={() => {
                    setShowSelect(true);
                }}
            >
                {services.language.getText('productAdd.basicMessage.selectProduct')}
            </Button>
            {showSelect && (
                <Modal
                    width='800px'
                    title={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                {services.language.getText('productShelf.pleaseSelectProducts')}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    type='primary'
                                    style={{ marginRight: '10px' }}
                                    onClick={handleOk}
                                >
                                    {services.language.getText('common.ok')}
                                </Button>
                                <Button onClick={handleReset}>
                                    {services.language.getText('common.cancel')}
                                </Button>
                            </div>
                        </div>
                    }
                    visible={showSelect}
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    confirmLoading={selectLoading}
                    onOk={handleOk}
                    onCancel={handleReset}
                    closable={false}
                >
                    <FilterForm handleSubmit={handleSearch} />
                    <Table
                        loading={loading}
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys: selectedRows.map((item) => item.csku),
                            onChange: (_, selectedRows) => {
                                console.log('selectedRows', selectedRows);
                                setSelectedRows(selectedRows);
                            },
                        }}
                        rowKey='csku'
                        dataSource={goodList}
                        columns={columns}
                        pagination={{
                            onChange: onPageChange,
                            pageSize: params.perPage,
                            current: params.page,
                            total: total,
                        }}
                        onRow={(record) => ({
                            onClick: () => setSelectedRows([record]),
                        })}
                    />
                </Modal>
            )}
        </>
    );
});

const FilterForm: any = Form.create({ name: 'select-goods-filter' })((props: any) => {
    const { getFieldDecorator, getFieldsValue } = props.form;
    return (
        <Form
            layout='inline'
            style={{ textAlign: 'right', marginBottom: 15 }}
            onSubmit={() => props.handleSubmit(getFieldsValue())}
        >
            <Form.Item label={services.language.getText('offlineOrder.selectGoods.keywords')}>
                {getFieldDecorator('keywords')(
                    <Input
                        autoFocus={true}
                        style={{ width: 250 }}
                        placeholder={services.language.getText(
                            'offlineOrder.selectGoods.placeholder'
                        )}
                    />
                )}
            </Form.Item>
            <Form.Item>
                <Button type='primary' htmlType='submit'>
                    {services.language.getText('common.search')}
                </Button>
            </Form.Item>
        </Form>
    );
});

export default GoodSelector;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card as AntCard } from 'antd';
import { services } from '@comall-backend-builder/core';
import { Icon } from 'antd';
import { Prices } from './prices';
import './index.less';

/**
 * 订单详情 - 订单结算信息
 */
class orderInfoCheckout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fold: true,
        };
    }

    handleClick = () => {
        this.setState({
            fold: !this.state.fold,
        });
    };

    render() {
        const { fold } = this.state;
        const {
            goodsAmount,
            style,
            pointAmount,
            promotionAmount,
            couponAmount,
            freightFeeAmount,
            paidPayAmount,
            payDiscount,
            giftCardAmount,
            freightFeeDiscountAmount,
            updatedOrderAmountVo,
        } = this.props;

        return (
            <AntCard bordered={false} style={style}>
                {updatedOrderAmountVo && (
                    <div className='order-info-title' onClick={this.handleClick.bind(this)}>
                        <span className='order-info-text'>
                            {services.language.getText('order.checkout.originalOrder')}
                        </span>
                        <Icon type={fold ? 'up' : 'down'} />
                    </div>
                )}
                {/* 折叠价格展示 */}
                {!fold && (
                    <div className='order-info-checkout'>
                        <Prices
                            list={[
                                {
                                    name: services.language.getText('order.checkout.totalAmount'),
                                    amount: goodsAmount || '0.00',
                                    show: true,
                                },
                                {
                                    name: services.language.getText('freight'),
                                    amount: `+${freightFeeAmount || '$ 0.00'}`,
                                    show: true,
                                },
                                {
                                    name: services.language.getText(
                                        'order.checkout.promotionAmount'
                                    ),
                                    amount: `-${promotionAmount || '$ 0.00'}`,
                                    show: true,
                                },
                                {
                                    name: services.language.getText('order.checkout.couponAmount'),
                                    amount: `-${couponAmount || '$ 0.00'}`,
                                    show: true,
                                },
                                {
                                    name: services.language.getText(
                                        'order.checkout.freightFeeDiscountAmount'
                                    ),
                                    amount: `-${freightFeeDiscountAmount || '$ 0.00'}`,
                                    show: true,
                                },
                                {
                                    name: services.language.getText('integral'),
                                    amount: `-${pointAmount || '$ 0.00'}`,
                                    show: true,
                                },

                                {
                                    name: services.language.getText('order.checkout.giftCard'),
                                    amount: `-${giftCardAmount || '$ 0.00'}`,
                                    show: true,
                                },

                                {
                                    name: services.language.getText('order.checkout.payDiscount'),
                                    amount: `-${payDiscount || '$ 0.00'}`,
                                    show: false,
                                },
                            ]}
                        />
                        {/* 实付总金额 */}
                        <div className='order-total-amount'>
                            {services.language.getText('order.orderListItem.amount')}：
                            <span className='order-total-amount-value'>
                                {paidPayAmount || '$ 0.00'}
                            </span>
                        </div>
                    </div>
                )}
                {/* 正常价格展示 */}
                {fold && !updatedOrderAmountVo && (
                    <div className='order-info-checkout'>
                        <Prices
                            list={[
                                {
                                    name: services.language.getText('order.checkout.totalAmount'),
                                    amount: goodsAmount || '$ 0.00',
                                    show: true,
                                },
                                {
                                    name: services.language.getText('freight'),
                                    amount: `+${freightFeeAmount || '$ 0.00'}`,
                                    show: true,
                                },
                                {
                                    name: services.language.getText(
                                        'order.checkout.promotionAmount'
                                    ),
                                    amount: `-${promotionAmount || '$ 0.00'}`,
                                    show: true,
                                },
                                {
                                    name: services.language.getText('order.checkout.couponAmount'),
                                    amount: `-${couponAmount || '$ 0.00'}`,
                                    show: true,
                                },
                                {
                                    name: services.language.getText(
                                        'order.checkout.freightFeeDiscountAmount'
                                    ),
                                    amount: `-${freightFeeDiscountAmount || '$ 0.00'}`,
                                    show: true,
                                },
                                {
                                    name: services.language.getText('integral'),
                                    amount: `-${pointAmount || '$ 0.00'}`,
                                    show: true,
                                },

                                {
                                    name: services.language.getText('order.checkout.giftCard'),
                                    amount: `-${giftCardAmount || '$ 0.00'}`,
                                    show: true,
                                },

                                {
                                    name: services.language.getText('order.checkout.payDiscount'),
                                    amount: `-${payDiscount || '$ 0.00'}`,
                                    show: false,
                                },
                            ]}
                        />
                        {/* 实付总金额 */}
                        <div className='order-total-amount'>
                            {services.language.getText('order.orderListItem.amount')}：
                            <span className='order-total-amount-value'>
                                {paidPayAmount || '$ 0.00'}
                            </span>
                        </div>
                    </div>
                )}
                {/* 更新后价格展示 */}
                {updatedOrderAmountVo && (
                    <>
                        <div className='order-info-title' onClick={this.handleClick.bind(this)}>
                            <span className='order-info-text'>
                                {services.language.getText('order.checkout.updateAmount')}
                            </span>
                        </div>
                        <div className='order-info-checkout'>
                            <Prices
                                list={[
                                    {
                                        name: services.language.getText(
                                            'order.checkout.totalAmount'
                                        ),
                                        amount: updatedOrderAmountVo.goodsAmount || '$ 0.00',
                                        show: true,
                                    },
                                    {
                                        name: services.language.getText('freight'),
                                        amount: `+${updatedOrderAmountVo.freightFeeAmount ||
                                            '$ 0.00'}`,
                                        show: true,
                                    },
                                    {
                                        name: services.language.getText(
                                            'order.checkout.promotionAmount'
                                        ),
                                        amount: `-${updatedOrderAmountVo.promotionAmount ||
                                            '$ 0.00'}`,
                                        show: true,
                                    },
                                    {
                                        name: services.language.getText(
                                            'order.checkout.couponAmount'
                                        ),
                                        amount: `-${updatedOrderAmountVo.couponAmount || '$ 0.00'}`,
                                        show: true,
                                    },
                                    {
                                        name: services.language.getText(
                                            'order.checkout.freightFeeDiscountAmount'
                                        ),
                                        amount: `-${updatedOrderAmountVo.freightFeeDiscountAmount ||
                                            '$ 0.00'}`,
                                        show: true,
                                    },
                                    {
                                        name: services.language.getText('integral'),
                                        amount: `-${updatedOrderAmountVo.pointAmount || '$ 0.00'}`,
                                        show: true,
                                    },

                                    {
                                        name: services.language.getText('order.checkout.giftCard'),
                                        amount: `-${updatedOrderAmountVo.giftCardAmount ||
                                            '$ 0.00'}`,
                                        show: true,
                                    },

                                    {
                                        name: services.language.getText(
                                            'order.checkout.payDiscount'
                                        ),
                                        amount: `-${updatedOrderAmountVo.payDiscount || '$ 0.00'}`,
                                        show: false,
                                    },
                                ]}
                            />
                            {/* 实付总金额 */}
                            <div className='order-total-amount'>
                                {services.language.getText('order.orderListItem.amount')}：
                                <span className='order-total-amount-value'>
                                    {updatedOrderAmountVo.paidPayAmount || '$ 0.00'}
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </AntCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const OrderInfoCheckout = connect(mapStateToProps, null)(orderInfoCheckout);

import * as React from 'react';
import { modes, formats } from '@comall-backend-builder/types';
import { MerchantBannerListEdit } from '@/components/merchant-banner-list-edit';
import { Form } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

export class ArrayMerchantBannerListEditMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        return <MerchantBannerListEdit {...controlInfo} />;
    }

    getDisplayComponent(value: any[]) {
        if (value && value.length) {
            return (
                <>
                    {value.map(({ id, mobile, pc, target }) => (
                        <div key={id}>
                            <Form.Item
                                className='merchant-banner-list-edit-form-item'
                                label={language.getText('businessCooperator.mpBanner')}
                                labelCol={{
                                    span: 8,
                                }}
                            >
                                <img src={mobile} style={{ maxWidth: '200px' }} />
                            </Form.Item>
                            <Form.Item
                                className='merchant-banner-list-edit-form-item'
                                label={language.getText('businessCooperator.pcBanner')}
                                labelCol={{
                                    span: 8,
                                }}
                            >
                                <img src={pc} style={{ maxWidth: '200px' }} />
                            </Form.Item>
                            <Form.Item
                                className='merchant-banner-list-edit-form-item'
                                label={language.getText('businessCooperator.jumpLink')}
                                labelCol={{
                                    span: 8,
                                }}
                            >
                                <span>{target}</span>
                            </Form.Item>
                        </div>
                    ))}
                </>
            );
        }
        return <span className='type-not-available'>-</span>;
    }
}

export class ArrayMerchantBannerListEditFormat extends formats.ArrayFormat {
    /**
     * 对数据进行校验
     */
    validate(rule: any, value: any = [], callback: any) {
        if (value.length > 1) {
            for (const { mobile, pc, target } of value) {
                if (mobile ? !pc : pc) {
                    return callback(
                        new Error(language.getText('businessCooperator.merchantBannerError'))
                    );
                } else if (mobile && pc && target && !/^https:\/\/.+/.test(target)) {
                    return callback(
                        new Error(language.getText('businessCooperator.merchantBannerError'))
                    );
                }
            }
        }
        callback();
    }
}

import React, { Component } from 'react';
import { Tree as AntTree, message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

const TreeNode = AntTree.TreeNode;

interface OrganizationsProps {
    style: any;
    entity: any;
    location: any;
}

interface OrganizationsStates {
    treeNodeId: string;
    treeData: Array<any>;
    treeNodePath: Array<any>;
    showButton: boolean;
    expandedKeys: Array<any>;
    selectedKeys?: string;
}
export class OrganizationsPage extends Component<OrganizationsProps, OrganizationsStates> {
    constructor(props: OrganizationsProps) {
        super(props);
        this.state = {
            treeNodeId: '0',
            treeData: [],
            treeNodePath: [],
            showButton: true,
            expandedKeys: ['1'],
        };
        this.onSelected = this.onSelected.bind(this);
        this.renderTreeNodes = this.renderTreeNodes.bind(this);
        this.add = this.add.bind(this);
    }
    componentWillMount() {
        const { entity, location } = this.props;
        if (location.search) {
            const pathIds = location.search.split('=')[1].split(',');
            this.setState({
                expandedKeys: pathIds,
            });
        }
        services.api
            .get(
                {},
                {
                    apiPath: '/dc-master-data/admin/organizations/findAllByLoginUser',
                }
            )
            .then((res: any) => {
                entity.params.id = entity.params.id || res[0].id;
                entity.search(entity.params);
                this.setState({
                    treeData: res,
                });
            })
            .catch(services.errorHandle);
        // .finally(() => {
        //     loading.close();
        // });
    }
    onSelected(key: any) {
        if (key.length === 0) {
            this.setState({
                treeNodeId: '0',
            });
            return;
        }
        const id = key[0];
        const { entity } = this.props;
        entity.params.id = id;
        this.setState({
            treeNodeId: id,
        });
        const tree = this.state.treeData;
        const treeNodePath: Array<any> = [];
        const treeNodeKeys: Array<any> = [];
        function findParent(node: any, id: string) {
            treeNodePath.push(node.name);
            treeNodeKeys.push(node.id);
            if (node.id === id) {
                throw id;
            }
            if (node.children && node.children.length > 0) {
                for (let i = 0; i < node.children.length; i++) {
                    findParent(node.children[i], id);
                }
                treeNodePath.pop();
                treeNodeKeys.pop();
            } else {
                treeNodePath.pop();
                treeNodeKeys.pop();
            }
        }
        try {
            findParent(tree[0], id);
        } catch (e) {
            this.setState({
                treeNodePath,
                expandedKeys: treeNodeKeys,
                selectedKeys: key,
            });
        }
        const showButton = treeNodePath.length < 4;
        this.setState({
            showButton,
        });
        entity.search(entity.params);
    }
    renderTreeNodes = (data: any) => {
        return data.map((item: any) => {
            if (item.children) {
                return (
                    <TreeNode title={item.name} key={item.id} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.name} key={item.id} dataRef={item} />;
        });
    };
    add(e: Event) {
        if (this.state.treeNodeId === '0') {
            e.preventDefault();
            AntMessage.warning(services.language.getText('organizationSelectorModal.pleaseSelect'));
        }
    }
    render() {
        const { style } = this.props;
        const { expandedKeys, treeData } = this.state;

        return (
            <div style={style}>
                <AntTree onSelect={this.onSelected} defaultExpandedKeys={expandedKeys}>
                    {this.renderTreeNodes(treeData)}
                </AntTree>
            </div>
        );
    }
}

import React, { Fragment, useState, useRef } from 'react';
import { Form as AntdForm, Input as AntInput, Tabs } from 'antd';
import { capitalize } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { RichTextVideo } from '@/components/rich-text-video';
import { UploadImageVideo } from '@/components/upload-image-video';
import { DESIGN_MANAGE_LANGUAGE_NAMES, DESIGN_MANAGE_LANGUAGE_VALUES } from '@/constants';

const { TabPane } = Tabs;

const contentMap = [{ key: 'en' }, { key: 'zh' }, { key: 'tc' }];

interface DescribeProps {
    params?: any;
    form?: any;
}

const DescribeMessageForm: React.FC<DescribeProps> = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setpaneKey] = useState<string>('app');

    const changePaneKeyHandler = (key: any) => {
        setpaneKey(key === '1' ? 'app' : 'pc');
    };

    const {
        form: { getFieldDecorator },
    } = props;
    const isDetail = useRef(services.navigation.getPathname().split('/')[3] === 'detail');
    return (
        <Fragment>
            {/* 商品图片 */}
            <AntdForm.Item label={services.language.getText('productAdd.describeMessage.pic')}>
                {getFieldDecorator('customPictures', {
                    initialValue: [],
                })(
                    <UploadImageVideo
                        disabled={isDetail.current}
                        name='customPictures'
                        maxCount={5}
                        uploadType={['image', 'video']}
                        video={{
                            uploadUrl:
                                ENV.API_ROOT +
                                '/dc-file/video/oss/video_locations/11/videos?rename=true',
                            fileName: 'fileName',
                            accept: 'video/mp4',
                            maxSize: 20 * 1024,
                            maxDuration: 60,
                            minDuration: 9,
                        }}
                        img={{
                            uploadUrl:
                                ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                            fileName: 'fileName',
                            accept: 'image/png,image/jpg,image/jpeg',
                            maxSize: 2 * 1024,
                        }}
                    />
                )}
            </AntdForm.Item>
            {/* 简介 */}
            {contentMap.map((item, index) => {
                return (
                    <AntdForm.Item
                        label={`${services.language.getText(
                            'productAdd.describeMessage.abstract'
                        )}（${
                            DESIGN_MANAGE_LANGUAGE_NAMES[
                                `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                            ]
                        }）`}
                        key={index}
                    >
                        {getFieldDecorator(`descriptionMap.${item.key}`)(
                            <AntInput
                                disabled={isDetail.current}
                                placeholder={services.language.getText(
                                    'productAdd.describeMessage.placeholder'
                                )}
                                maxLength={100}
                            />
                        )}
                    </AntdForm.Item>
                );
            })}
            {/* 标签 */}
            {contentMap.map((item, index) => {
                return (
                    <AntdForm.Item
                        label={`${services.language.getText('productAdd.describeMessage.label')}（${
                            DESIGN_MANAGE_LANGUAGE_NAMES[
                                `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                            ]
                        }）`}
                        key={index}
                    >
                        {getFieldDecorator(`skuLabelMap.${item.key}`)(
                            <AntInput
                                disabled={isDetail.current}
                                placeholder={services.language.getText(
                                    'productAdd.describeMessage.placeholder'
                                )}
                                maxLength={100}
                            />
                        )}
                    </AntdForm.Item>
                );
            })}
            {/* 别名 */}
            {contentMap.map((item, index) => {
                return (
                    <AntdForm.Item
                        label={`${services.language.getText('productAdd.describeMessage.alias')}（${
                            DESIGN_MANAGE_LANGUAGE_NAMES[
                                `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                            ]
                        }）`}
                        key={index}
                    >
                        {getFieldDecorator(`skuAliasMap.${item.key}`)(
                            <AntInput
                                disabled={isDetail.current}
                                placeholder={services.language.getText(
                                    'productAdd.describeMessage.placeholder'
                                )}
                                maxLength={100}
                            />
                        )}
                    </AntdForm.Item>
                );
            })}
            <Tabs defaultActiveKey='1' onChange={changePaneKeyHandler} destroyInactiveTabPane>
                {/* PC版 */}
                <TabPane
                    tab={services.language.getText('productAdd.describeMessage.pc')}
                    key='1'
                    forceRender={true}
                >
                    {contentMap.map((item, index) => {
                        return (
                            <AntdForm.Item
                                style={{ paddingBottom: '24px' }}
                                label={`${services.language.getText(
                                    'productAdd.describeMessage.detailDes'
                                )}（${
                                    DESIGN_MANAGE_LANGUAGE_NAMES[
                                        `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                                    ]
                                }）`}
                                key={index}
                            >
                                {props.form.getFieldDecorator(
                                    `skuDetailMap.pc.content.${item.key}`
                                )(
                                    //@ts-ignore
                                    <RichTextVideo
                                        disabled={isDetail.current}
                                        name={`content${capitalize(item.key)}`}
                                        video={{
                                            uploadUrl:
                                                ENV.API_ROOT +
                                                '/dc-file/video/oss/video_locations/11/videos?rename=true',
                                            fileName: 'fileName',
                                            accept: 'video/mp4',
                                            maxSize: 20 * 1024,
                                            // maxDuration: 60,
                                        }}
                                        img={{
                                            uploadUrl:
                                                ENV.API_ROOT +
                                                '/dc-file/oss/image_locations/1/images?rename=true',
                                            fileName: 'fileName',
                                            accept: 'image/png,image/jpg,image/svg',
                                            // maxSize: 2 * 1024,
                                        }}
                                    />
                                )}
                            </AntdForm.Item>
                        );
                    })}
                    {props.form.getFieldDecorator('skuDetailMap.pc.showType', {
                        initialValue: '1',
                    })(<Fragment />)}
                    {props.form.getFieldDecorator('skuDetailMap.pc.sortType', {
                        initialValue: '1',
                    })(<Fragment />)}
                </TabPane>
                {/* MP版 */}
                <TabPane
                    tab={services.language.getText('productAdd.describeMessage.mobile')}
                    key='2'
                    forceRender={true}
                >
                    {contentMap.map((item, index) => {
                        return (
                            <AntdForm.Item
                                style={{ paddingBottom: '24px' }}
                                label={`${services.language.getText(
                                    'productAdd.describeMessage.detailDes'
                                )}（${
                                    DESIGN_MANAGE_LANGUAGE_NAMES[
                                        `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                                    ]
                                }）`}
                                key={index}
                            >
                                {props.form.getFieldDecorator(
                                    `skuDetailMap.mp.content.${item.key}`
                                )(
                                    //@ts-ignore
                                    <RichTextVideo
                                        disabled={isDetail.current}
                                        name={`content${capitalize(item.key)}`}
                                        video={{
                                            uploadUrl:
                                                ENV.API_ROOT +
                                                '/dc-file/video/oss/video_locations/11/videos?rename=true',
                                            fileName: 'fileName',
                                            accept: 'video/mp4',
                                            maxSize: 20 * 1024,
                                            // maxDuration: 60,
                                        }}
                                        img={{
                                            uploadUrl:
                                                ENV.API_ROOT +
                                                '/dc-file/oss/image_locations/1/images?rename=true',
                                            fileName: 'fileName',
                                            accept: 'image/png,image/jpg,image/svg',
                                            // maxSize: 2 * 1024,
                                        }}
                                    />
                                )}
                            </AntdForm.Item>
                        );
                    })}
                    {props.form.getFieldDecorator('skuDetailMap.mp.showType', {
                        initialValue: '1',
                    })(<Fragment />)}
                    {props.form.getFieldDecorator('skuDetailMap.mp.sortType', {
                        initialValue: '1',
                    })(<Fragment />)}
                </TabPane>
            </Tabs>
        </Fragment>
    );
};

export default DescribeMessageForm;

import React from 'react';
import { cloneDeep, assign } from 'lodash';
import { DataTable } from '@comall-backend-builder/components-basis';
import ColumnSetting, { ColumnsContainer } from './columnSetting';
import './index.less';

 const proTable = (WrappedComponent: any) => {
    class ProTable extends React.Component<any, any> {
        formatColumns = (columns: any) => {
            const { entity } = this.props;
            const metainfo = entity.metainfo;
            const localColumns = columns.map((columnConfig: any, _index: number) => {
                let column: any = assign({}, { property: columnConfig.property });
                // 实体属性
                let property;
                let entityProperties = metainfo.properties;
                let configProperties = columnConfig.property.split('.');

                if (configProperties.length > 1) {
                    let parent = entityProperties;
                    for (let configProperty of configProperties) {
                        property = parent![configProperty];
                        if (!property) {
                            throw new Error(
                                `Property ${columnConfig.property} not found` +
                                    ` in Entity ${entity.id}`
                            );
                        }
                        parent = property.properties;
                    }
                } else {
                    property = entityProperties![columnConfig.property];
                }

                if (!property) {
                    throw new Error(
                        `Property ${columnConfig.property} not found in Entity ${entity.id}`
                    );
                }
                // 列名称：取title；无title，则取displayName；无displayName，则取property
                const title = columnConfig.title || property.displayName || columnConfig.property;
                //当有reactElement形式的title时，防止开发环境下报错
                column.title = cloneDeep(title);
                column.key = columnConfig.key || columnConfig.property;
                if (columnConfig.property) {
                    column.dataIndex = columnConfig.property;
                }
                return { ...columnConfig, ...column, index: _index };
            });
            return localColumns;
        };
        state = {
            columns: this.props.columns,
            titleColumns: this.formatColumns(this.props.columns),
        };

        changeColumns = (nColumns: any) => {
            const nC = nColumns.map((item: any) => {
                return this.props.columns.find((el: any) => el.property === item.property);
            });
            this.setState({
                columns: nC,
            });
        };
        render(): React.ReactNode {
            return (
                <div className='pro-table'>
                    <ColumnsContainer.Provider
                        initialState={{
                            columns: this.state.titleColumns,
                            changeColumns: this.changeColumns,
                            persistenceKey: this.props.componentName,
                        }}
                    >
                        <ColumnSetting columns={this.state.titleColumns} />

                        <WrappedComponent {...this.props} columns={this.state.columns} />
                    </ColumnsContainer.Provider>
                </div>
            );
        }
    }
    return ProTable;
};
export const ProTable = proTable(DataTable);

import React from 'react';
import { modes, formats } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';
import { AsyncTreeSelect } from '@/components/async-tree-select';
export class AsyncArrayTreeNodeIdsTreeFormat extends formats.ArrayFormat {
    /**
     * 对数据进行校验
     */
    //@ts-ignore
    public validate(_rule, _value, callback) {
        callback();
    }
}
export class AsyncArrayTreeNodeIdsTreeMode extends modes.ObjectMode {
    public getDisplayComponent(value: any, displayInfo: any) {
        const { options } = displayInfo;
        if (!value || !options || options.length < 1) {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
        return super.getDisplayComponent(
            services.convertTreeNodeIdArrayToTreeNodeArray(value, options),
            displayInfo
        );
    }
    getControlComponent(controlInfo: any) {
        let { options: treeData } = controlInfo;
        const treeSelectProps = { ...controlInfo, treeData, multiple: true };
        return <AsyncTreeSelect {...treeSelectProps} />;
    }
}

import { ComponentMetadata, Config } from '@comall-backend-builder/core/lib/parser';
import { flexibleFilterForm, tableMixin } from '@/configs/mixin';
import { MEMBER_EXAMINE_STATUS } from '@/constants/user';

const getContentTableConfig = (status: MEMBER_EXAMINE_STATUS): ComponentMetadata => {
    const config = {
        ...tableMixin,
        className: 'import-record-table',
        columns: [
            { property: 'mobile' },
            { property: 'lastName' },
            { property: 'firstName' },
            { property: 'email' },
            { property: 'applyTime' },
            (status === MEMBER_EXAMINE_STATUS.APPROVED ||
                status === MEMBER_EXAMINE_STATUS.REJECTED) && { property: 'reviewedTime' },
            (status === MEMBER_EXAMINE_STATUS.APPROVED ||
                status === MEMBER_EXAMINE_STATUS.REJECTED) && { property: 'reviewer' },
            status === MEMBER_EXAMINE_STATUS.APPROVED && { property: 'levelName' },
        ].filter((item) => item),
    };
    const actionColumn = {
        title: '<<actions>>',
        className: 'fixed-status-table-action',
        width: 200,
        items: [
            {
                type: 'component',
                component: 'TableMemberExamine',
                text: '审核',
                privilege: {
                    path: `member.member_examine.member_examine_reviewed`,
                    level: 'full',
                },
            },
        ],
    };

    return status === MEMBER_EXAMINE_STATUS.PENDING
        ? {
              ...config,
              actionColumn,
          }
        : config;
};
const getContentConfig = (status: MEMBER_EXAMINE_STATUS): ComponentMetadata => {
    return {
        component: 'FlexLayout',
        direction: 'vertical',
        items: [{ component: 'MemberExamineFilter' }, { ...getContentTableConfig(status) }],
    };
};
/**
 * 会员等级审核
 */
export const config: Config = {
    entities: {
        memberExamine: {
            apiPath: '/dc-user/admin/memberExamine',
            properties: {
                id: { type: 'string' },
                mobile: { type: 'string', displayName: '<<memberExamine.mobile>>' },
                lastName: { type: 'string', displayName: '<<memberExamine.lastName>>' },
                firstName: { type: 'string', displayName: '<<memberExamine.firstName>>' },
                email: { type: 'string', displayName: '<<memberExamine.email>>' },
                applyTime: { type: 'string', displayName: '<<memberExamine.applyTime>>' },
                levelName: { type: 'string', displayName: '<<memberExamine.levelName>>' },
                reviewedTime: { type: 'string', displayName: '<<memberExamine.reviewedTime>>' },
                reviewer: { type: 'string', displayName: '<<memberExamine.reviewer>>' },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<memberExamine.name>>',
                    controlConfig: {
                        placeholder: '<<memberExamine.name>>',
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<memberExamine.mobile>>',
                    controlConfig: {
                        placeholder: '<<memberExamine.mobile>>',
                    },
                },
                email: {
                    type: 'string',
                    displayName: '<<memberExamine.email>>',
                    controlConfig: {
                        placeholder: '<<memberExamine.email>>',
                    },
                },
            },
        },
    },
    components: {
        MemberExamineContainer: {
            component: 'Viewport',
        },
        MemberExamineLayout: {
            entity: 'memberExamine',
            component: 'FlexLayout',
            className: 'import-record-view',
            direction: 'vertical',
            align: 'start',
            items: [
                {
                    component: 'MemberExamineTab',
                    privilege: {
                        path: 'member.member_examine.member_examine_list',
                        level: 'full',
                    },
                },
            ],
        },
        MemberExamineTab: {
            component: 'Tabs',
            animated: false,
            cache: false,
            style: { width: '100%' },
            items: [
                {
                    content: getContentConfig(MEMBER_EXAMINE_STATUS.PENDING),
                    params: {
                        status: MEMBER_EXAMINE_STATUS.PENDING,
                    },
                    title: '<<memberExamine.status.pending>>',
                },
                {
                    content: getContentConfig(MEMBER_EXAMINE_STATUS.APPROVED),
                    params: {
                        status: MEMBER_EXAMINE_STATUS.APPROVED,
                    },
                    title: '<<memberExamine.status.approved>>',
                },
                {
                    content: getContentConfig(MEMBER_EXAMINE_STATUS.REJECTED),
                    params: {
                        status: MEMBER_EXAMINE_STATUS.REJECTED,
                    },
                    title: '<<memberExamine.status.rejected>>',
                },
            ],
        },
        MemberExamineFilter: {
            ...flexibleFilterForm,
            fields: [{ property: 'name' }, { property: 'mobile' }, { property: 'email' }],
        },
    },
};

import React, { Component, Fragment } from 'react';
import {
    Button as AntButton,
    Modal as AntModal,
    Input as AntInput,
    message as AntMessage,
} from 'antd';
import { trim } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';

const { TextArea } = AntInput;
export class RemarkModal extends Component {
    state = { visible: false, loading: false, value: '' };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        const { value } = this.state;
        if (!value) {
            AntMessage.error(services.language.getText('order.orderRemarkTips'));
            return;
        }
        this.setState({
            loading: true,
        });
        services.api
            .post(
                {
                    content: trim(this.state.value),
                    aftermarketId: this.props.apiPath ? this.props.aftermarketId : null,
                    id: this.props.orderNumber,
                },
                {
                    apiPath: this.props.apiPath
                        ? this.props.apiPath
                        : formatUrl(API_MAP.ORDER_REMARK, this.props.type),
                }
            )
            .catch(services.errorHandle)
            .finally(() => {
                this.setState({
                    visible: false,
                    value: '',
                    loading: false,
                });
                AntMessage.success(services.language.getText('order.orderRemarkTipsSucc'));
            });
    };

    onChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { loading } = this.state;
        return (
            <Fragment>
                <AntButton onClick={this.showModal}>
                    {services.language.getText('order.orderRemarkTitle')}
                </AntButton>
                <AntModal
                    title={services.language.getText('order.orderRemarkTitle')}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    confirmLoading={loading}
                >
                    <TextArea
                        maxLength={200}
                        rows={4}
                        value={this.state.value}
                        onChange={this.onChange}
                        placeholder={services.language.getText('order.orderRemarkPlaceholder')}
                    />
                </AntModal>
            </Fragment>
        );
    }
}

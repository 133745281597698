/*
 * @Author: zhulu
 * @Date: 2022-04-25 14:52:05
 * @Description: 相关商品
 */
import { services } from '@comall-backend-builder/core';
import { Form } from 'antd';
import React, { Fragment, useRef } from 'react';
import SelectAutoComplete from '@/components/select-auto-complete';
const productLength = Array(10)
    .fill('')
    .map((_, i) => i + 1);
const BasicForm = (props: any) => {
    const {
        form: { getFieldDecorator },
    } = props;
    const isDetail = useRef(services.navigation.getPathname().split('/')[3] === 'detail');

    return (
        <Fragment>
            {productLength.map((item, index) => (
                <Form.Item
                    key={item}
                    label={`${services.language.getText(
                        'productAdd.relatedProducts.title'
                    )}${item}`}
                >
                    {getFieldDecorator(`relatedProducts.${index}`)(
                        <SelectAutoComplete
                            allowClear
                            disabled={isDetail.current}
                            placeholder={services.language.getText('defaultPlaceholderSelect')}
                            apiPath='/loader/dc-goods/admin/cskus/queryCskuByPage'
                            params={{
                                page: 1,
                                perPage: 10,
                            }}
                            selectParamKey='keyword'
                        />
                    )}
                </Form.Item>
            ))}
        </Fragment>
    );
};

export default BasicForm;

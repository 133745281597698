import React from 'react';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '@/configs/mixin';
import { Button, message } from 'antd';
import { services } from '@comall-backend-builder/core';

const resendButton = (row: any, text: string, props: any, type: '7' | '8') => {
    const { entity } = props;
    return (
        <Button
            type='link'
            onClick={() => {
                services.api
                    .put(
                        {},
                        {
                            apiPath: `/dc-order/admin/orders/sales/send?orderId=${row.orderId}&sendType=${type}`,
                        }
                    )
                    .then(() => {
                        message.success('重发成功');
                        entity.search(entity.params);
                    })
                    .catch(services.errorHandle);
            }}
        >
            {text}
        </Button>
    );
};

export const config: Config = {
    entities: {
        resendSalesInfo: {
            apiPath: '/dc-order/admin/orders/sales',
            properties: {
                orderNo: {
                    type: 'string',
                    displayName: '<<resendSales.orderNumber>>',
                },
                salestxn: {
                    type: 'string',
                    displayName: '<<resendSales.salesTxn>>',
                },
                refundSalestxn: {
                    type: 'string',
                    displayName: '<<resendSales.refundSalesTxn>>',
                },
                terminalId: {
                    type: 'string',
                    displayName: '<<resendSales.terminalId>>',
                },
                channel: {
                    type: 'string',
                    displayName: '<<resendSales.channel>>',
                },
            },
            filters: {
                number: {
                    type: 'string',
                    displayName: '<<keyword>>',
                    controlConfig: {
                        placeholder: '<<resendSales.keywordPlaceholder>>',
                        style: {
                            width: 240,
                            marginRight: 36,
                        },
                    },
                },
            },
        },
    },
    components: {
        ResendSalesPage: {
            component: 'Card',
            bordered: false,
            title: '<<resendSales.title>>',
            entity: 'resendSalesInfo',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    { component: 'ResendSalesFilter', style: { marginBottom: 32 } },
                    { component: 'ResendSalesTable' },
                ],
            },
        },
        ResendSalesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            fields: [
                {
                    property: 'number',
                },
            ],
        },
        ResendSalesTable: {
            ...tableMixin,
            loadFirstPage: false,
            columns: [
                { property: 'orderNo' },
                { property: 'salestxn' },
                { property: 'refundSalestxn' },
                { property: 'terminalId' },
                { property: 'channel' },
            ],
            actionColumn: {
                title: '<<actions>>',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<resendSales.resendSales>>',
                        config: {
                            render: (row: any, text: any, className: String, props: any) => {
                                if (row.showSales) {
                                    return resendButton(row, text, props, '7');
                                } else {
                                    return <> </>;
                                }
                            },
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<resendSales.resendRefundSales>>',
                        config: {
                            render: (row: any, text: any, className: String, props: any) => {
                                if (row.showRefundSales) {
                                    return resendButton(row, text, props, '8');
                                } else {
                                    return <></>;
                                }
                            },
                        },
                    },
                ],
            },
        },
    },
};

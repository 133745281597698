import { Config } from '@comall-backend-builder/core/lib/parser';
import { ENABLE_STATUS_OPTIONS } from '@/constants';

export const config: Config = {
    entities: {
        organizations: {
            apiPath: '/loader/dc-master-data/admin/organizations',
            properties: {
                id: {
                    type: 'string',
                },

                name: {
                    type: 'string',
                    displayName: '<<organizations.name>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<status>>',
                },
                defaultLanguage: {
                    type: 'string',
                    displayName: '<<organizations.language>>',
                },
                defaultTimeZone: {
                    type: 'string',
                    displayName: '<<organizations.timeZone>>',
                },
                defaultCurrency: {
                    type: 'string',
                    displayName: '<<organizations.currencyType>>',
                },
                currencyIsoCode: {
                    type: 'string',
                },
            },
            paramsFilter: ['id', 'name', 'page', 'perPage'],
        },
    },
    components: {
        OrganizationsContainer: {
            component: 'Viewport',
            entity: 'organizations',
        },
        OrganizationsManagement: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OrganizationsContent',
                    privilege: {
                        path: 'system.organization.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        OrganizationsContent: {
            component: 'FlexLayout',
            items: [
                {
                    component: 'OrganizationsPage',
                    style: {
                        width: '25%',
                    },
                },
                {
                    component: 'OrganizationList',
                },
            ],
        },
        OrganizationList: {
            component: 'DataTable',
            loaderType: 'search',
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'defaultCurrency',
                    align: 'center',
                },
                {
                    property: 'defaultLanguage',
                    align: 'center',
                },
                {
                    property: 'defaultTimeZone',
                    align: 'center',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: ENABLE_STATUS_OPTIONS,
                    },
                },
            ],
        },
    },
};

import { services } from '@comall-backend-builder/core/lib';
import { filter, isEmpty, some } from 'lodash';

import { mockFetch } from './mock';
// const formatLanguage = (fn: Function, data: any) => {
//     return {
//         zh: fn(data.zh || ''),
//         en: fn(data.en || ''),
//         jp: fn(data.jp || ''),
//         it: fn(data.it || ''),
//         fr: fn(data.fr || ''),
//         ko: fn(data.ko || ''),
//         tc: fn(data.tc || ''),
//     };
// };
export default [
    {
        key: '/brands',
        loader: {
            get: async function(data: any) {
                return await mockFetch({
                    totalNum: 20,
                    page: data.page,
                    perPage: data.perPage,
                    totalPage: '2',
                    result: [
                        {
                            id: '1',
                            brandCode: '211122',
                            brandNameEn: 'Estee Lauder',
                            brandNameZh: '雅诗兰黛',
                            status: 'ENABLED',
                        },
                        {
                            id: '2',
                            brandCode: '211122',
                            brandNameEn: 'Lamer',
                            brandNameZh: '雅诗兰黛',
                            status: 'DISABLED',
                        },
                    ],
                });
            },
        },
    },
    {
        //品牌关联渠道
        key: '/dc-goods/admin/brands/channel_brands',
        loader: {
            put: async (data: any, config: any) => {
                return await services.api.put(data, {
                    ...config,
                    apiPath: `/dc-goods/admin/brands/${data.id}/channel_brands`,
                });
            },
        },
    },
    {
        //品牌设置规则
        key: '/dc-goods/admin/brands/rule',
        loader: {
            put: async (data: any, config: any) => {
                return await services.api.put(data, {
                    ...config,
                    apiPath: `/dc-goods/admin/brands/rule/${data.id}`,
                });
            },
        },
    },
    {
        //编辑品牌
        key: '/dc-goods/admin/brands',
        loader: {
            get: async (data: any, config: any) => {
                const result: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-goods/admin/brands/${data.id}`,
                });

                const processImage = (logoData: any): any[] => {
                    let realData: any[] = [];
                    if (logoData && logoData.imageId) {
                        realData = [
                            {
                                id: logoData.imageId,
                                path: logoData.imageUrl,
                            },
                        ];
                    }
                    return realData;
                };

                // result.logoTop = processImage(result.logoTop);
                // result.logoBottom = processImage(result.logoBottom);
                result.logoFeature = processImage(result.logoFeature);
                result.mpBanner = processImage(result.mpBanner);
                result.pcBanner = processImage(result.pcBanner);
                return result;
            },
            put: async (data: any, config: any) => {
                // 清空富文本内容时会存在空标签
                // data.description = (data.description || '').replace('<p><br></p>', '');
                // data.edescription = (data.edescription || '').replace('<p><br></p>', '');
                // const handleDescription = (value: string) =>
                //     (value || '').replace('<p><br></p>', '');
                // data.languageDescription = formatLanguage(
                //     handleDescription,
                //     data.languageDescription
                // );
                // data.logoBanner = formatLanguage(String, data.logoBanner);
                // data.pcLogoBanner = formatLanguage(String, data.pcLogoBanner);
                return await services.api.put(data, {
                    ...config,
                    //TODO 后期去掉 typ =1
                    apiPath: `/dc-goods/admin/brands/${data.id}?type=1`,
                });
            },
            post: async (data: any, config: any) => {
                // 清空富文本内容时会存在空标签
                // data.description = (data.description || '').replace('<p><br></p>', '');
                // data.edescription = (data.edescription || '').replace('<p><br></p>', '');
                // const handleDescription = (value: string) =>
                //     (value || '').replace('<p><br></p>', '');
                // data.languageDescription = formatLanguage(
                //     handleDescription,
                //     data.languageDescription
                // );
                return await services.api.post(data, {
                    ...config,
                    //TODO 后期去掉 type=1
                    apiPath: `/dc-goods/admin/brands?type=1`,
                });
            },
        },
    },
    {
        key: '/dc-master-data/admin/distribution_channels/findAllByLoginUser/brand',
        loader: {
            get: async function(data: any, config: any) {
                config.apiPath = '/dc-master-data/admin/distribution_channels/findAllByLoginUser';
                const result: any = await services.api.get(data, config);
                return filter(result, (item) => item.id !== '7');
            },
        },
    },
    {
        key: '/dc-goods/admin/brands/findAllByLoginUser',
        loader: {
            get: async function(data: any, config: any) {
                const { id } = data || {};
                const modifyBrand = !isEmpty(id);
                config.apiPath = modifyBrand
                    ? `/dc-goods/admin/brands/findAllByLoginUser/${id}`
                    : '/dc-master-data/admin/organizations/findAllByLoginUser';
                const result: any[] = await services.api.get({}, config);

                if (!isEmpty(id)) {
                    //编辑品牌
                    result.forEach((item) => {
                        //总部
                        (item.children || []).forEach((continent: any) => {
                            //洲
                            continent.disabled = some(continent.children, (_) => _.disabled !== 1)
                                ? continent.disabled
                                : 1;
                            return continent;
                        });
                        item.disabled = some(item.children, (_) => _.disabled !== 1)
                            ? item.disabled
                            : 1;
                        return item;
                    });
                }
                return result;
            },
        },
    },
];

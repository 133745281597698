import { services } from '@comall-backend-builder/core';
import React, { useState } from 'react';
import { PrintButton } from '../print';
import { OrderReceipt } from '@/components/print-template';
import { Privilege } from '../privilege';
import { formatUrl } from '@/constants/order/utils';
import { useGlobalConfig } from '@/utils/global-config';
import { ORDER_TYPE } from '@/constants/order/enum';

export const PrintOrderButton: React.FC<any> = (props) => {
    const { outerOrderNumber, config } = props;
    const [orderPrice, setOrderPrice] = useState(undefined);
    const [orderInfo, setOrderInfo] = useState<any>();
    const [{ storeLogos }, setGlobalConfig] = useGlobalConfig();

    function loadLogo(onlineStoreId: string) {
        const storeLogo = storeLogos.find((item) => item.onlineStoreId === onlineStoreId) || {
            loading: false,
            onlineStoreId,
            logo: '',
        };

        if (!storeLogo.logo && !storeLogo.loading) {
            storeLogo.loading = true;
            setGlobalConfig({
                storeLogos: [
                    ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                    storeLogo,
                ],
            });

            return services.api
                .get(
                    {},
                    {
                        apiPath: `/dc-store/admin/onlineStore/findById/${onlineStoreId}`,
                    }
                )
                .then((res: any) => {
                    storeLogo.loading = false;
                    storeLogo.logo = res.logo;
                    setGlobalConfig({
                        storeLogos: [
                            ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                            storeLogo,
                        ],
                    });
                })
                .catch(services.errorHandle);
        }
    }

    async function printClick(printRef: any) {
        services.api
            .get(
                {
                    outerOrderNumber: outerOrderNumber,
                },
                {
                    apiPath: formatUrl('/admin/order/orderPrint', config?.type),
                }
            )
            .then(async (res: any) => {
                setOrderPrice(res);
                if (res.store?.id) {
                    await loadLogo(res.store.id);
                }
                // 获取订单详情
                const result: object = await services.api.get(
                    {
                        outerOrderNumber: outerOrderNumber,
                    },
                    {
                        apiPath: `/base-order/admin/order/info`,
                    }
                );
                setOrderInfo(result);
                await printRef.current.onPrint();
            })
            .catch(services.errorHandle);
    }

    return (
        <Privilege
            path={
                config?.type === ORDER_TYPE.BASE
                    ? 'order.order.print_order'
                    : 'order_philips.order_philips.print_order_philips'
            }
        >
            <PrintButton
                type='primary'
                onClick={printClick}
                template={
                    <OrderReceipt
                        data={{ ...((orderPrice ?? {}) as AnyObject), ...orderInfo }}
                        orderPrice={orderPrice}
                    />
                }
            >
                {services.language.getText('order.printOrder')}
            </PrintButton>
        </Privilege>
    );
};

import { OBTAIN_STATUS } from '../constants';
export const couponProps = {
    activeStatus: {
        type: 'string.color',
        displayName: '<<status>>',
    },
    id: { type: 'string' },
    promotionId: {
        type: 'string',
        displayName: '<<coupon.promotionId>>',
    },
    couponNo: {
        type: 'string',
        displayName: '<<coupon.couponNo>>',
    },
    useLoyaltNo: {
        type: 'string',
        displayName: '<<coupon.useLoyaltNo>>',
    },
    loyaltNo: {
        type: 'string',
        displayName: '<<coupon.loyaltNo>>',
    },
    openId: {
        type: 'string',
        displayName: '<<coupon.openId>>',
    },
    unionId: {
        type: 'string',
        displayName: '<<coupon.unionId>>',
    },
    status: {
        type: 'string.options.select',
        displayName: '<<coupon.status>>',
        options: OBTAIN_STATUS,
    },
    receiveTime: {
        type: 'string',
        displayName: '<<coupon.receiveTime>>',
    },
    useTime: {
        type: 'string',
        displayName: '<<coupon.useTime>>',
    },
    sendTime: {
        type: 'string',
        displayName: '<<coupon.sendTime>>',
    },
    sendStore: {
        type: 'string',
        displayName: '<<coupon.sendStore>>',
    },
    receiveStore: {
        type: 'string',
        displayName: '<<coupon.receiveStore>>',
    },
    useStore: {
        type: 'string',
        displayName: '<<coupon.useStore>>',
    },
    couponChannel: {
        type: 'string',
        displayName: '<<coupon.couponChannel>>',
    },
    sendOrderNo: {
        type: 'string',
        displayName: '<<coupon.sendOrderNo>>',
    },
    useOrderNo: {
        type: 'string',
        displayName: '<<coupon.useOrderNo>>',
    },
    code: {
        type: 'string',
        displayName: '<<couponRule.code>>',
    },
};

export const filterCouponProps = {
    promotionId: {
        type: 'string',
        displayName: '<<coupon.promotionId>>',
    },
    couponNo: {
        type: 'string',
        displayName: '<<coupon.couponNo>>',
    },

    loyaltNo: {
        type: 'string',
        displayName: '<<coupon.loyaltNo>>',
    },
    useLoyaltNo: {
        type: 'string',
        displayName: '<<coupon.useLoyaltNo>>',
    },
    // sourceType: {
    //     type: 'string.options.select',
    //     displayName: '<<coupon.souceType>>',
    //     options: SOURCE_TYPE,
    // },
    status: {
        type: 'string.options.select',
        displayName: '<<coupon.status>>',
        options: OBTAIN_STATUS,
    },
    receiveTime: {
        type: 'object.dateRange',
        displayName: '<<coupon.receiveTime>>',
    },
    useTime: {
        type: 'object.dateRange',
        displayName: '<<coupon.useTime>>',
    },
    sendTime: {
        type: 'object.dateRange',
        displayName: '<<coupon.sendTime>>',
    },
    sendStore: {
        type: 'array.treeNodeIds.tree',
        displayName: '<<coupon.sendStore>>',
        options: [],
        source: {
            apiPath: '/loader/dc-store/admin/onlineStore/own',
        },
    },
    useStore: {
        type: 'array.treeNodeIds.tree',
        displayName: '<<coupon.useStore>>',
        options: [],
        source: {
            apiPath: '/loader/dc-store/admin/onlineStore/own',
        },
    },
    receiveStore: {
        type: 'array.treeNodeIds.tree',
        displayName: '<<coupon.receiveStore>>',
        options: [],
        source: {
            apiPath: '/loader/dc-store/admin/onlineStore/own',
        },
    },
    couponChannel: {
        type: 'string.options.select',
        displayName: '<<coupon.couponChannel>>',
        options: [{ id: '001', name: '001' }],
    },
    sendOrderNo: {
        type: 'string',
        displayName: '<<coupon.sendOrderNo>>',
    },
    useOrderNo: {
        type: 'string',
        displayName: '<<coupon.useOrderNo>>',
    },
    activeStatus: {
        type: 'string.options.select',
        displayName: '<<coupon.activeStatus>>',
        options: [
            { id: 'VALID', name: 'VALID' },
            { id: 'DISABLED', name: 'DISABLED' },
        ],
    },
};

export const filterCouponFields = [
    {
        property: 'promotionId',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<coupon.promotionIdPlaceholder>>',
        },
    },
    {
        property: 'couponNo',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<coupon.codePlaceholder>>',
        },
    },
    {
        property: 'loyaltNo',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<coupon.loyaltNumPlaceholder>>',
        },
    },
    {
        property: 'useLoyaltNo',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<coupon.loyaltNumPlaceholder>>',
        },
    },
    {
        property: 'status',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'sendOrderNo',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<coupon.orderNoPlaceholder>>',
        },
    },
    {
        property: 'useOrderNo',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<coupon.orderNoPlaceholder>>',
        },
    },
    {
        property: 'activeStatus',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'sendStore',

        controlConfig: {
            showArrow: true,
            allowClear: true,
            treeCheckable: true,
            treeNodeFilterProp: 'name',
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'receiveStore',

        controlConfig: {
            showArrow: true,
            allowClear: true,
            treeCheckable: true,
            treeNodeFilterProp: 'name',
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'useStore',

        controlConfig: {
            showArrow: true,
            allowClear: true,
            treeCheckable: true,
            treeNodeFilterProp: 'name',
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'sendTime',
        controlConfig: {
            format: 'DD-MM-YYYY',
        },
    },
    {
        property: 'receiveTime',
        controlConfig: {
            format: 'DD-MM-YYYY',
        },
    },
    {
        property: 'useTime',
        controlConfig: {
            format: 'DD-MM-YYYY',
        },
    },
    {
        property: 'couponChannel',
        controlConfig: {
            allowClear: true,
            showSearch: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    // {
    //     property: 'sourceType',
    //     controlConfig: {
    //         allowClear: true,
    //         showSearch: true,
    //         placeholder: '<<defaultPlaceholderSelect>>',
    //     },
    // },
];

import { isEmpty, get as lodashGet } from 'lodash';
import { services } from '@comall-backend-builder/core/lib';
export default [
    {
        key: '/dc-goods/admin/productscroll',
        loader: {
            get: async (params: any, config: any) => {
                if (!isEmpty(params.brandId)) {
                    params.brandId = params.brandId.map((item: any) => item.id).join(',');
                }
                config.apiPath = '/dc-goods/admin/productscroll';
                let data: any = await services.api.get(params, config);

                data.result = data.result.map((item: any) => ({
                    ...item,
                    onlineStoreName: lodashGet(
                        item,
                        `onlineStoreNameLanguage.${services.language.getCurrentLanguage().id}`
                    ),
                    dutyFreeOnly:
                        lodashGet(item, 'dutyFreeOnly') === true
                            ? services.language.getText('yes')
                            : services.language.getText('no'),
                }));
                return data;
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/findByChannelId',
        loader: {
            get: async (params: any, config: any) => {
                const { onlineStoreCodes, ...restParams } = params;
                const data: any = await services.api.get(restParams, config);
                data.result = data.result.map((item: any) => ({
                    id: item.onlineStoreCode,
                    name: item.onlineStoreName,
                }));
                return data;
            },
        },
    },
];

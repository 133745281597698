import React, { PureComponent } from 'react';
import { Form as AntForm, Input as AntInput } from 'antd';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Row as AntRow, Col as AntCol } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services/language';
import { trim } from 'lodash';
import { Privilege } from '@/components/privilege';

interface OrderInfoProductProps {
    [key: string]: any;
}

/**
 * 创建订单（线下） - 会员信息
 */
class offlineCreateOrderMember extends PureComponent<OrderInfoProductProps, any> {
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                services.api
                    .get(
                        {
                            mobile: trim(values.mobile),
                            onlineStoreId: this.props.params?.onlineStoreIds,
                        },
                        {
                            apiPath: `/dc-user/admin/members/getByMobile`,
                        }
                    )
                    .then((result: any) => {
                        let memberInfo = {};
                        memberInfo = result;
                        this.props.entity.setFields({ ...this.props.entity.fields, memberInfo });
                    })
                    .catch(services.errorHandle);
            }
        });
    };
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { memberInfo } = this.props;
        const phoneVal = window.location.href.match(/\?phone=(\d+)/);
        const initPhoneVal = phoneVal && phoneVal[1];

        return (
            <>
                <div className='offline-order-create-title'>
                    {language.getText('offlineOrder.create.memberInfo')}
                </div>
                <AntForm
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    style={{ padding: '25px 0 25px' }}
                    onSubmit={this.handleSubmit}
                    className='offline-create-member-form'
                    hideRequiredMark={true}
                >
                    <AntForm.Item label={language.getText('offlineOrder.memberPhone')}>
                        {getFieldDecorator('mobile', {
                            // rules: [
                            //     {
                            //         required: true,
                            //         message: language.getText('offlineOrder.tips.memberPhone'),
                            //         whitespace: true,
                            //     },
                            // ],
                            initialValue: initPhoneVal,
                        })(
                            <AntInput
                                placeholder={language.getText('defaultPlaceholderInput')}
                                maxLength={15}
                            />
                        )}
                        <Privilege path='offline.offline_order.mobile'>
                            <Button
                                disabled={!trim(getFieldValue('mobile'))}
                                type='primary'
                                htmlType='submit'
                                style={{ marginRight: 25 }}
                            >
                                {language.getText('query')}
                            </Button>
                        </Privilege>
                        <Button
                            type='primary'
                            onClick={() =>
                                services.navigation.goto(
                                    `/members/create?offlineBackId=${this.props.params?.onlineStoreIds}`
                                )
                            }
                        >
                            {language.getText('offlineOrder.create.crateMember')}
                        </Button>
                    </AntForm.Item>
                </AntForm>
                {memberInfo?.phone ? (
                    <div className='offline-order-member-info'>
                        <AntRow style={{ marginBottom: 25 }}>
                            <AntCol span={3}></AntCol>
                            <AntCol span={6}>
                                {language.getText('offlineOrder.create.memberID')}：
                                {memberInfo?.memberId ?? '-'}
                            </AntCol>
                            <AntCol span={6}>
                                {language.getText('offlineOrder.create.memberName')}：
                                {memberInfo?.surname + memberInfo?.name ?? '-'}
                            </AntCol>
                            <AntCol span={6}>
                                {language.getText('offlineOrder.create.memberLevel')}：
                                {memberInfo?.levelName ?? '-'}
                            </AntCol>
                        </AntRow>
                        <AntRow>
                            <AntCol span={3}></AntCol>
                            <AntCol span={6}>
                                {language.getText('integral')}：{memberInfo?.point ?? '-'}
                            </AntCol>
                            <AntCol span={6}>
                                {language.getText('offlineOrder.create.cashCoupon')}：
                                {memberInfo?.voucherBalance ?? '-'}
                            </AntCol>
                        </AntRow>
                    </div>
                ) : null}
            </>
        );
    }
}
const offlineCreateOrderMemberInstance = AntForm.create()(offlineCreateOrderMember);

const mapStateToProps = (state: any, ownProps: any) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const OfflineCreateOrderMember = connect(
    mapStateToProps,
    null
)(offlineCreateOrderMemberInstance);

import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    components: {
        AfterSaleOrdersCreate: {
            component: 'Card',
            className: 'after-sale-refund-item',
            type: 'inner',
            loaderType: 'get',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'AfterSaleCreateForm',
                    },
                    {
                        component: 'AfterSaleCreateRemark',
                    },
                    {
                        component: 'AfterSaleCreateProduct',
                    },
                ],
            },
        },
    },
};

import React, { PureComponent } from 'react';
import { Card as AntCard, Steps as AntSteps } from 'antd';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { ORDER_PROGRESS_STATUS_NAMES, ORDER_PROGRESS_STATUS_VALUES } from '@/constants/order';

/**
 * 订单详情 - 订单进度
 */
class orderInfoProgress extends PureComponent<any, any> {
    static defaultProps = {
        logistics: {},
        progresses: [],
        traces: {},
    };

    getStepsInfo() {
        const { progresses } = this.props;

        const steps = (progresses as any[]).sort((a, b) => a.ordering - b.ordering);
        const current = steps.reduce(
            (cur, item) => (item.finished && item.ordering > cur ? item.ordering : cur),
            0
        );

        return {
            steps,
            current,
        };
    }

    render() {
        const stepsInfo = this.getStepsInfo();

        return (
            <AntCard
                className='order-info-progress'
                bordered={false}
                title={services.language.getText('order.progress.title')}
            >
                <AntSteps progressDot current={stepsInfo.current}>
                    {stepsInfo.steps.map((step) => (
                        <AntSteps.Step
                            key={step.id}
                            title={
                                ORDER_PROGRESS_STATUS_NAMES[
                                    step.progressStatus as ORDER_PROGRESS_STATUS_VALUES
                                ]
                            }
                            description={
                                <div>
                                    <p>{step?.operator?.name}</p>
                                    <p>{step?.updateTime}</p>
                                </div>
                            }
                        />
                    ))}
                </AntSteps>
            </AntCard>
        );
    }
}

const mapStateToProps = (state: AnyObject, ownProps: AnyObject) => {
    const {
        entity: { id },
    } = ownProps;
    const orderEntity = state.entities[id];
    const progresses = get(orderEntity, 'fields.orderTraces.progresses', []);
    return {
        ...ownProps,
        progresses,
    };
};

export const OrderInfoProgress = connect(mapStateToProps, null)(orderInfoProgress);

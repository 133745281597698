import { Config } from '@comall-backend-builder/core/lib/parser';
import { filterProperties } from '@/constants/order/properties';
import { PATH_NAME, ORDER_TYPE } from '@/constants/order/enum';

export const config: Config = {
    entities: {
        philipsOrderList: {
            apiPath: '/loader/base-order-philips/admin/orders',
            filters: filterProperties,
        },
    },
    components: {
        PhilipsOrdersContainer: {
            component: 'Viewport',
            entity: 'philipsOrderList',
        },
        PhilipsOrdersManagement: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OrderTabsFilter',
                    frequency: 30,
                    config: {
                        pathname: PATH_NAME.PHILIPS_ORDER_PATH_NAME,
                        type: ORDER_TYPE.PHILIPS,
                        treeType: 1,
                    },
                },
            ],
        },
    },
};

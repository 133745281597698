import { Quill } from 'react-quill';

let ImageEmbed = Quill.import('formats/image');

export class ImageBlot extends ImageEmbed {
    static create(value: any) {
        const node = super.create(value);
        if (typeof value === 'string') {
            node.setAttribute('src', this.sanitize(value));
            node.setAttribute('class', 'img');
        }
        return node;
    }
}

/*
 * @Author: zhulu
 * @Date: 2022-09-19 17:52:45
 * @Description: 做状态展示用的option
 */

import { services } from '@comall-backend-builder/core';
const { language } = services;

/**
 * 状态值
 * 1:启用
 * 2:禁用
 */
export const STATUS_OPTIONS = [
    {
        id: 1,
        name: language.getText('statusValue.enabled'),
        status: 'success',
    },
    {
        id: 0,
        name: language.getText('statusValue.disabled'),
        status: 'warning',
    },
];
/**
 * 状态值
 * 1:启用
 * 2:禁用
 */
export const STATUS_NUMBER_OPTIONS = [
    {
        id: 1,
        name: language.getText('statusValue.enabled'),
        status: 'success',
    },
    {
        id: 0,
        name: language.getText('statusValue.disabled'),
        status: 'warning',
    },
];
/**
 * 状态值
 * ENABLED：启用
 * DISABLED： 禁用
 */
export const ENABLE_STATUS_OPTIONS = [
    {
        id: 'ENABLED',
        name: language.getText('statusValue.enabled'),
        status: 'success',
    },
    {
        id: 'DISABLED',
        name: language.getText('statusValue.disabled'),
        status: 'warning',
    },
];
/**
 * 导入状态
 * SUCCESSFUL: 成功
 * FAIL: 失败
 * PARTIALLY_SUCCESSFUL: 部分成功
 * PROCESSING: 进行中
 */
export const IMPORT_STATUS_OPTIONS = [
    {
        id: 'SUCCESSFUL',
        name: language.getText('importRecord.statusNames.SUCCESSFUL'),
        status: 'success',
    },
    {
        id: 'FAIL',
        name: language.getText('importRecord.statusNames.FAIL'),
        status: 'error',
    },
    {
        id: 'PARTIALLY_SUCCESSFUL',
        name: language.getText('importRecord.statusNames.PARTIALLY_SUCCESSFUL'),
        status: 'warning',
    },
    {
        id: 'PROCESSING',
        name: language.getText('importRecord.statusNames.PROCESSING'),
        status: 'processing',
    },
];

/**
 * 导出状态
 * SUCCESSFUL: 成功
 * FAIL: 失败
 * PENDING: 待处理
 * PROCESSING: 进行中
 * EXPIRED: 已过期
 */
export const EXPORT_STATUS_OPTIONS = [
    {
        id: 'SUCCESS',
        name: language.getText('exportFiles.statusNames.SUCCESS'),
        status: 'success',
    },
    {
        id: 'FAIL',
        name: language.getText('exportFiles.statusNames.FAIL'),
        status: 'error',
    },
    {
        id: 'PROCESSING',
        name: language.getText('exportFiles.statusNames.PROCESSING'),
        status: 'warning',
    },
    {
        id: 'PENDING',
        name: language.getText('exportFiles.statusNames.PENDING'),
        status: 'warning',
    },
    {
        id: 'EXPIRED',
        name: language.getText('exportFiles.statusNames.EXPIRED'),
        status: 'default',
    },
];

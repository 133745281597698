import { services } from '@comall-backend-builder/core/lib';
import { isEmpty } from 'lodash';

export default [
    {
        // 查询通知栏管理数据
        key: '/dc-master-data/admin/homeFeature',
        loader: {
            get: async (data: any, config: any) => {
                const result: any = await services.api.get(data, {
                    ...config,
                });
                if (!isEmpty(result.result) && result.result.length > 0) {
                    result.result.map((item: any) => {
                        item.quantity = { zh: item.countZh, en: item.countEn, tc: item.countTc };
                        item.status = item.enable ? 1 : 0;

                        return {
                            ...item,
                        };
                    });
                } else {
                    result.result = [];
                }
                return result;
            },
        },
    },
];

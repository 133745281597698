import React, { Component } from 'react';

import { StringTreeNodeCascaderMode } from '@comall-backend-builder/types/lib/mode';
import { Cascader } from 'antd';

class CascaderOption extends Component<any, any> {
    state = {
        value: undefined,
    };
    onChange = (value: any, selectedOption: any) => {
        const { name, onChange } = this.props;
        this.setState({ value });
        onChange(value, name);
    };

    render() {
        let { showComponent, value } = this.props;
        const newProps = { ...this.props, value };
        return showComponent ? <Cascader {...newProps} onChange={this.onChange} /> : null;
    }
}

export class ArrayTreeOptionNodeMode extends StringTreeNodeCascaderMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CascaderOption {...controlInfo} />;
    }
}

import React, { createElement, useRef, useState } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
import { Button, Modal, Typography } from 'antd';
import { services } from '@comall-backend-builder/core';
import './index.less';

interface IProps {
    orderNumber: string;
    channelId: string;
}

const PaymentDetail: React.FC<IProps> = (props) => {
    const [visible, setVisible] = useState(false);

    const hideModal = useRef(() => {
        setVisible(false);
    });

    return (
        <>
            <Button
                className='payment-detail-button'
                type='link'
                onClick={() => {
                    setVisible(true);
                }}
            >
                <Typography.Text underline className='text'>
                    {services.language.getText('paymentDetail.showDetail')}
                </Typography.Text>
            </Button>
            <Modal
                style={{ top: '20%' }}
                width='1000px'
                visible={visible}
                title={services.language.getText('paymentDetail.logs')}
                closable={false}
                onCancel={hideModal.current}
                footer={[
                    <Button type='primary' onClick={hideModal.current}>
                        {services.language.getText('close')}
                    </Button>,
                ]}
            >
                {visible &&
                    createElement(ComponentsManager.get('PaymentDetailTable'), {
                        params: {
                            orderNumber: props.orderNumber,
                            channel: props.channelId,
                        },
                    })}
            </Modal>
        </>
    );
};

export default PaymentDetail;

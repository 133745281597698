export const ONLINE_STORE_TABLE = 'online_store_table';
export const PRODUCT_LIST_TABLE_ALL = 'product_list_table_all';
export const PRODUCT_LIST_TABLE_NORMAL = 'product_list_table_normal';
export const PRODUCT_LIST_TABLE_GWP = 'product_list_table_gwp';
export const PICK_STORE_TABLE = 'pick_store_table';
export const RETAIL_STORE_TABLE = 'retail_store_table';
export const BRANDS_TABLE = 'brands_table';
export const PRODUCT_BASIC_TAG_TABLE = 'product_basic_tag_table';
export const PRODUCT_AP_TOOL_TAG_TABLE = 'product_ap_tool_tag_table';
export const ON_SHELF_TABLE = 'on_shelf_table';
export const OFF_SHELF_TABLE = 'off_shelf_table';
export const PRODUCT_PRICE_TABLE = 'product_price_table';
export const PRODUCT_PRICE_MONITORING_TABLE = 'product_price_monitoring_table';
export const PRODUCT_INVENTORY_MONITORING_TABLE = 'product_inventory_monitoring_table';
export const PRODUCT_INVENTORY_TABLE_PRODUCT = 'product_inventory_table_product';
export const PRODUCT_INVENTORY_TABLE_NORMAL = 'product_inventory_table_normal';
export const PRODUCT_REPORT_SPU_LIST_TABLE = 'product_report_spu_list_table';
export const PRODUCT_REPORT_BLOCK_UP_TABLE = 'product_report_block_up_table';
export const LICENSE_PRODUCT_TABLE = 'license_product_table';
export const PRODUCT_BLACK_LIST_TABLE = 'product_black_list_table';
export const VIRTUAL_CATEGORY_TABLE = 'virtual_category_table';
export const DISPLAY_CATEGORY_TABLE = 'display_category_table';
export const PRODUCT_SUITE = 'product_suite';
export const PRODUCT_SELECTION = 'product_selection';
export const PRODUCT_LIST_STYLE = 'product_list_style';
export const DISTRIBUTION_RULES_TABLE_PICKUP = 'distribution_rules_table_pickup';
export const DISTRIBUTION_RULES_TABLE_DISTRIBUTION = 'distribution_rules_table_distribution';
export const DISTRIBUTION_RULES_TABLE_COURIER = 'distribution_rules_table_courier';
export const PICK_ONLINE_STORE_LIST = 'pick_online_store_list';
export const CATEGORY_PRODUCT_LIST = 'category_product_list';
export const MERCHANT_DISTRIBUTION_RULES_TABLE_PICKUP = 'merchant_distribution_rules_table_pickup';
export const MERCHANT_DISTRIBUTION_RULES_TABLE_COURIER =
    'merchant_distribution_rules_table_courier';
export const MERCHANT_PICKUPATHONE_RULES_TABLE_COURIER =
    'merchant_pickupathome_rules_table_courier';
export const DEFAULT_SEARCH_KEY_TABLE = 'default_search_key_table';
export const HOT_WORD_TABLE = 'hot_word_table';
export const MEMBER_SHIP_LIST = 'member_ship_list';
export const MEMBER_SOURCE_LIST = 'member_ship_list';
export const EVALUATION_LIST_TABLE = 'evaluation_list_table';
export const MEMBER_PRODUCT = 'member_product';
export const MEMBER_PRICE = 'member_price';
export const RECOMMEND_PRODUCT_TABLE = 'recommend_product_table';

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { ORDER_STATUS_NAMES } from '@/constants/order';

/**
 * 订单状态标签
 *  @param {Boolean} fill -  标签是否填充
 *  @param {String} status -  订单状态
 */
export class OrderStatusBadge extends PureComponent {
    static propTypes = {
        fill: PropTypes.bool,
        status: PropTypes.string,
    };

    render() {
        const { fill, orderStatus } = this.props;
        let className = classNames('order-status-badge', kebabCase(orderStatus));
        if (fill) {
            className = classNames(className, 'fill');
        }
        return <span className={className}>{ORDER_STATUS_NAMES[orderStatus]}</span>;
    }
}

import React from 'react';
import { language, uuid } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { Button, Form } from 'antd';
import { AsyncTreeSelect } from '@/components/async-tree-select';
import SelectAutoComplete from '@/components/select-auto-complete';
import './index.less';

export const PermissionListEdit: React.FC<{
    value?: any[];
    validated?: boolean;
    onChange?(value: any[]): void;
}> = (props) => {
    const { value: _value, validated, onChange } = props;
    let value: any[] =
        !_value || _value.length === 0 ? [{ onlineStoreIdList: [], merchantIdList: [] }] : _value;
    value = value.map((item) => (item.id ? item : { ...item, id: uuid() }));

    return (
        <>
            {value.map(({ id, onlineStoreIdList, merchantIdList }, index) => (
                <div key={id}>
                    <Form.Item
                        className='permission-list-edit-form-item'
                        label={language.getText('thirdPartyManagement.onlineStoreIdList')}
                        required
                        validateStatus={
                            validated && !onlineStoreIdList.length ? 'error' : 'success'
                        }
                    >
                        {
                            // @ts-ignore
                            <AsyncTreeSelect
                                multiple
                                loadNow
                                loadAll
                                treeNodeLabelProp='name'
                                name='onlineStoreIdList'
                                source={{
                                    apiPath: '/loader/dc-store/admin/onlineStore/own',
                                }}
                                allowClear
                                value={onlineStoreIdList}
                                onChange={(onlineStoreIdList) => {
                                    const nextValue = value.map((item, i) =>
                                        i === index ? { ...item, onlineStoreIdList } : item
                                    );
                                    onChange?.(nextValue);
                                }}
                            />
                        }
                        {validated && !onlineStoreIdList.length && (
                            <div className='ant-form-explain permission-list-edit-error'>
                                {language.getText('common.pleaseSelect')}
                                {language.getText('thirdPartyManagement.onlineStoreIdList')}
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item
                        className='permission-list-edit-form-item'
                        label={language.getText('thirdPartyManagement.merchantIdList')}
                        required
                        validateStatus={validated && !merchantIdList.length ? 'error' : 'success'}
                    >
                        <SelectAutoComplete
                            mode='multiple'
                            placeholder={services.language.getText('defaultPlaceholderSelect')}
                            apiPath='/loader/dc-goods/admin/merchant/byUser'
                            selectParamKey='keyword'
                            allowClear
                            value={merchantIdList}
                            onChange={(merchantIdList: any) => {
                                const nextValue = value.map((item, i) =>
                                    i === index
                                        ? {
                                              ...item,
                                              merchantIdList,
                                          }
                                        : item
                                );
                                onChange?.(nextValue);
                            }}
                        />
                        {validated && !merchantIdList.length && (
                            <div className='ant-form-explain permission-list-edit-error'>
                                {language.getText('common.pleaseSelect')}
                                {language.getText('thirdPartyManagement.merchantIdList')}
                            </div>
                        )}
                    </Form.Item>
                    <div className='permission-list-edit-buttons'>
                        {value.length > 1 && (
                            <Button
                                className='permission-list-edit-button'
                                onClick={() => {
                                    const nextValue = value.filter((_, i) => i !== index);
                                    onChange?.(nextValue);
                                }}
                            >
                                {language.getText('common.delete')}
                            </Button>
                        )}
                        {value.length - 1 === index && value.length < 10 && (
                            <Button
                                className='permission-list-edit-button'
                                onClick={() => {
                                    const nextValue = [
                                        ...value,
                                        {
                                            onlineStoreIdList: [],
                                            merchantIdList: [],
                                        },
                                    ];
                                    onChange?.(nextValue);
                                }}
                            >
                                {language.getText('common.add')}
                            </Button>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

/*
 * @Author: 朱璐
 * @Date: 2022-10-17 09:38:20
 * @Description: 会员资料查看历史订单
 */
import React, { useState, useRef } from 'react';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Modal } from 'antd';
import { paginationMixin } from '@/configs';

const { language } = services;
export const MembershipOrderModal = (props: any) => {
    const [showModal, setShowModal] = useState(false);

    const listConfig = useRef({
        className: 'dc-order-list order-receiving',
        split: false,
        loadFirstPage: false,
        pagination: paginationMixin,
        entity: props.entities.membershipOrderList,
        renderItem: (data: any, _props: any) =>
            React.createElement(ComponentsManager.get('OrderDataListItem'), {
                ...data,
                showButtons: [],
                parentProps: _props,
            }),
        onPageChange: () => {
            // props.refresh(true);
        },
    });
    return (
        <>
            <div className='ant-col-offset-8'>
                {React.createElement(ComponentsManager.get('Button'), {
                    type: 'primary',
                    text: language.getText('membership.viewHistoryOrder'),
                    onClick: () => {
                        setShowModal(true);
                        const entity = props.entities.membershipOrderList;
                        const detail = props.entity.fields;
                        entity.filtersChange({
                            organizationId: 1002,
                            memberId: detail?.memberId,
                            memberSource: 1,
                            page: 1,
                            perPage: 10,
                            // 不需要online_store_ids字段标识
                            notOnlineStoreIds: true,
                        });
                        entity.search();
                    },
                })}
            </div>

            <Modal
                title={language.getText('membership.viewHistoryOrder')}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={null}
                width='80%'
            >
                {React.createElement(ComponentsManager.get('DataList'), listConfig.current)}
            </Modal>
        </>
    );
};

import React, { Component, createElement } from 'react';
import { Tabs as AntTabs } from 'antd';
import { connect } from 'react-redux';
import { camelCase, includes, get } from 'lodash';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { extendsParentProps } from '@comall-backend-builder/components-basis';
import { Privilege } from '@/components/privilege';
import { StoreTreeSelector } from '@/components/store-tree-selector';
// import { ExportButtonWithFilter } from '@/components/export-button-with-filter';
import { AFTER_SALE_OPTIONS } from '@/constants';
import { paginationMixin } from '@/configs';

const AntTabPane = AntTabs.TabPane;

const DEFAULT_ACTIVE_TAB = 'ALL';

export class AfterSaleTabsFilter extends Component {
    status;
    tabs = AFTER_SALE_OPTIONS;
    state = {
        activeKey: null,
        /** 各分类页签内数字集 */
        statistics: {},
    };

    newProps = {
        refresh: this.fetchOrganization,
    };
    paneProps = extendsParentProps(this.newProps, this.props);
    otherTypeOptions = [];
    componentWillMount() {
        const {
            entity: { filters },
            params: { aftermarketStatus = DEFAULT_ACTIVE_TAB },
        } = this.props;
        let { entity } = this.props;
        entity.filtersChange({
            organizationId: 1002,
        });
        this.setState({
            activeKey:
                filters && filters.aftermarketStatus
                    ? filters.aftermarketStatus
                    : aftermarketStatus.toUpperCase(),
        });
        if (typeof this.props.params.aftermarketStatus === 'undefined') {
            const history = services.navigation.getHistory();
            if (!filters || (filters && !filters.aftermarketStatus)) {
                history.replace({
                    pathname: '/afterSalesOrder/' + aftermarketStatus.toLowerCase(),
                });
            } else {
                history.replace({ pathname: '/afterSalesOrder/' + filters.aftermarketStatus });
            }
        } else {
            let { entity } = this.props;
            const fields = Object.assign({}, entity.filters, {
                aftermarketStatus:
                    filters && filters.aftermarketStatus
                        ? filters.aftermarketStatus
                        : aftermarketStatus.toUpperCase() === 'ALL'
                        ? undefined
                        : aftermarketStatus.toUpperCase(),
            });
            entity.filtersChange(fields);
        }
    }

    componentWillReceiveProps(props) {
        const {
            params: { aftermarketStatus },
        } = props;
        this.setState({
            activeKey: (aftermarketStatus || DEFAULT_ACTIVE_TAB).toUpperCase(),
        });
    }

    fetchOrganization = async (resetPage = false) => {
        let { entity } = this.props;
        resetPage && entity.pageChange(Object.assign({}, entity.paging, { page: 1, perPage: 10 }));
        await entity.search(entity.params);
        this.fetchStatistics();
    };

    fetchStatistics = () => {
        const { entity } = this.props;
        services.api
            .get(
                {
                    onlineStoreIds: entity.filters.onlineStoreIds,
                    organizationId: 1002,
                },
                {
                    apiPath: '/base-order/admin/aftermarket/statistics',
                }
            )
            .then((rep) => {
                this.setState({
                    statistics: rep,
                });
            });
    };

    onChange = (key) => {
        const { entity } = this.props;
        entity.filtersChange({
            aftermarketStatus: key.toUpperCase() === 'ALL' ? undefined : key,
        });
        const history = services.navigation.getHistory();
        history.replace({ pathname: '/afterSalesOrder/' + key.toLowerCase() });
        this.fetchOrganization(true);
    };
    onChangeStore = (onlineStores) => {
        const { entity } = this.props;
        const onlineStoreIds = onlineStores.map((item) => item.id).join(',');
        if (!onlineStoreIds) return;
        entity.filtersChange({
            onlineStoreIds,
        });
        this.fetchOrganization(true);
    };
    renderTabPane = () => {
        const {
            params: { aftermarketStatus },
        } = this.props;
        let activeKey = (aftermarketStatus || DEFAULT_ACTIVE_TAB).toUpperCase();
        return this.tabs.map((item) => {
            return (
                <AntTabPane
                    className={item.className}
                    tab={`${item.name} ( ${this.state.statistics[
                        camelCase(item.id.toLocaleLowerCase())
                    ] || 0} )`}
                    key={item.id}
                >
                    {activeKey !== item.id ? null : (
                        <AfterSaleListPane
                            {...this.paneProps}
                            fetchStatistics={this.fetchStatistics}
                            otherTypeOptions={this.otherTypeOptions}
                        />
                    )}
                </AntTabPane>
            );
        });
    };
    render() {
        return (
            <div className='order-list'>
                <div className='order-list-header'>
                    <StoreTreeSelector
                        style={{
                            position: 'static',
                        }}
                        checkable={false}
                        selectable={true}
                        onChange={this.onChangeStore}
                    />
                </div>
                <AntTabs activeKey={this.state.activeKey} onChange={this.onChange} animated={false}>
                    {this.renderTabPane()}
                </AntTabs>
            </div>
        );
    }
}

class afterSaleListPane extends Component {
    formatterExportParams = (params) => {
        if (params.channels) {
            // 可能filterchange没有更新完，因此这里需要直接使用channelIds
            if (includes(params.channels, 'all')) {
                const channelIds = services.localStorage.get('channelIds');
                params.channels = channelIds.join(',');
            } else {
                params.channels = params.channels.join(',');
            }
        }
        if (params.createTime) {
            params.beginTime = params.createTime.start;
            params.endTime = params.createTime.end;
            delete params.createTime;
        }
        if (params.waitDeliveryTime) {
            params.preStartDeliveryTime = params.waitDeliveryTime.start;
            params.preEndDeliveryTime = params.waitDeliveryTime.end;
            delete params.waitDeliveryTime;
        }
        if (params.timeOut) {
            params.timeOut = params.timeOut.join(',');
        }

        if (params.aftermarketStatus === 'ABNORMAL_ORDERS') {
            params.query_type = 'ABNORMAL_ORDERS';
            delete params.aftermarketStatus;
        }
        return params;
    };

    render() {
        const filterConfig = {
            entity: this.props.entity,
            direction: 'inline',
            fieldCol: {
                span: 8,
            },
            labelCol: { span: 8 },
            // className: 'store-filter-form',
            className: 'global-flexible-filter-form',
            submit: {
                text: services.language.getText('query'),
            },
            reset: true,
            simple: {
                fields: ['channels', 'createTime', 'keyword'],
            },
            fields: [
                {
                    property: 'receiveAddress',
                    className: 'form-inline-col',
                },
                {
                    property: 'orderNumber',
                    className: 'form-inline-col',
                },
                {
                    property: 'aftermarketNumber',
                    className: 'form-inline-col',
                },
                {
                    property: 'keyword',
                    className: 'form-inline-col',
                },
                {
                    property: 'createTime',
                    className: 'form-inline-col',
                },
                {
                    property: 'deliveryFlag',
                    className: 'form-inline-col',
                },
                {
                    property: 'merchant',
                    className: 'form-inline-col',
                },
                {
                    property: 'memberType',
                    className: 'form-inline-col',
                },
            ],
            footer: {
                style: {
                    textAlign: 'right',
                },
            },
        };
        const getButtonText = () => {
            return `${services.language.getText('afterSale.add')}`;
        };
        const buttonConfig = {
            text: getButtonText(),
            type: 'primary',
            style: {
                flexGrow: 0,
                marginRight: 15,
                padding: '0 45px',
            },
            route: `/afterSalesOrder/add`,
        };

        const listConfig = {
            className: 'dc-order-list after-sale-receiving',
            split: false,
            loadFirstPage: false,
            pagination: paginationMixin,
            renderItem: 'AfterSaleDataListItem',
            entity: this.props.entity,
            refresh: this.props.refresh,
            fetchStatistics: this.props.fetchStatistics,
            onPageChange: () => {
                // props.refresh(true);
            },
        };

        return (
            <div>
                <div>{createElement(ComponentsManager.get('FilterFormPlus'), filterConfig)}</div>
                {/* <Privilege path='order.order.export_order_report'>
                    <ExportButtonWithFilter
                        isAsync
                        showErrorMessage
                        showSuccessTips
                        entity={this.props.entity}
                        text={services.language.getText('order.exportOrder')}
                        type='primary'
                        icon='download'
                        source={{
                            apiPath: '/base-order/admin/orders/excel/order_report/take',
                        }}
                        formatter={this.formatterExportParams}
                    />
                </Privilege> */}
                <Privilege path='aftermarket.after_sale_view.add'>
                    <div>{createElement(ComponentsManager.get('Button'), buttonConfig)}</div>
                </Privilege>
                <Privilege path='aftermarket.after_sale_view.find'>
                    <div style={{ marginTop: 15 }}>
                        {createElement(ComponentsManager.get('DataList'), listConfig)}
                    </div>
                </Privilege>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    const {
        entity,
        entity: { filters },
        entities,
    } = props;
    const organizations = get(entities, 'organizationSelector.fields', []);
    organizations.length === 1 && services.localStorage.set('dcOrganization', organizations[0]);
    return {
        entity,
        filters,
        organizations,
    };
}

const AfterSaleListPane = connect(mapStateToProps, null)(afterSaleListPane);

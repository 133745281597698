import { Component, createElement } from 'react';
import { isEqual } from 'lodash';
import { ComponentsManager } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { extendsParentProps } from '@comall-backend-builder/components-basis/lib/component-props';
import { KV } from '@/interfaces';

interface IProps {
    entity: Entity;
    params: KV;
    content: KV;
    /**
     * 刷新方法，父组件做更新数据操作
     */
    refresh: (flag: boolean) => void;
    /**
     * tabPane 是否为状态
     */
    isActive: boolean;
    /**
     * 用于控制是否需要刷新列表数据
     */
    time: number;
}

/**
 * tabPane 容器组件，方便做一些公共处理
 */
export class PickingAssistantTabPaneWrapper extends Component<IProps, any> {
    static defaultProps = {
        refresh: () => {},
    };

    componentDidMount() {
        const { entity, params, content } = this.props;

        if (content.loadFirstPage !== true && params.onlineStoreId) {
            entity.search(params);
        }
    }

    shouldComponentUpdate(nextProps: any) {
        if (!nextProps.isActive) {
            return false;
        }
        if (isEqual(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        const { entity, params } = nextProps;
        if (!entity && !params.onlineStoreId) {
            return;
        }

        if (nextProps.isActive) {
            // 切换门店
            if (params.onlineStoreId !== this.props.params?.onlineStoreId) {
                entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                entity.search(params);
                return;
            }
            // 需要刷新列表数据
            if (nextProps.time && nextProps.time !== this.props.time) {
                entity.search(params);
                return;
            }
            // 切换 tab 时候刷新当前 tab 数据
            if (nextProps.isActive !== this.props.isActive) {
                entity.search(params);
                return;
            }
        }
    }

    render() {
        const { content, refresh } = this.props;
        let { component, ...otherProps } = content;
        otherProps.refresh = () => refresh(true);
        otherProps.onPageChange = () => refresh(false);
        let props = extendsParentProps(otherProps, this.props);

        return createElement(ComponentsManager.get(component), props);
    }
}

import { formats } from '@comall-backend-builder/types/lib';
import { services } from '@comall-backend-builder/core';
let Week: { [key: string]: number } = {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6,
};

export class SelectTimeRangeFormat extends formats.ArrayFormat {
    validate(rule: any, value: any, callback: any) {
        if (value && value.length >= 1) {
            const range = [0, 1, 2, 3, 4, 5, 6, 7];
            try {
                value.forEach((v: Value, i: number) => {
                    const checkQuantity = Object.keys(v).indexOf('quantity') > -1;
                    if (
                        checkQuantity &&
                        (v.quantity === '' || v.quantity === undefined || v.quantity === null)
                    ) {
                        throw new Error(services.language.getText('storeInfo.pickUpError'));
                    }
                    if (checkQuantity && !/^[0-9]\d*$/.test(v.quantity)) {
                        throw new Error(services.language.getText('storeInfo.quantityNumber'));
                    }
                    value.forEach((c: Value, j: number) => {
                        if (i !== j) {
                            const _range = range.slice(Week[v.startWeek], Week[v.endWeek] + 1);
                            if (
                                _range.indexOf(Week[c.startWeek]) > -1 ||
                                _range.indexOf(Week[c.endWeek]) > -1
                            ) {
                                throw new Error(services.language.getText('storeInfo.timeError'));
                            }
                        }
                    });
                });
                callback();
            } catch (e) {
                callback(e);
            }
        } else {
            callback();
        }
    }
}
interface Value {
    endTime: string;
    endWeek: string;
    quantity: string;
    startTime: string;
    startWeek: string;
}

/*
 * @Author: 朱璐
 * @Date: 2022-10-20 14:49:24
 * @Description: override code form 编辑新增
 */
import React, { useEffect, useState } from 'react';

import { Form, Card, Row, Col, Button, message } from 'antd';

import { services, TypesManager } from '@comall-backend-builder/core';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { OVERRIDE_CODE_TYPE_OPTIONS, OVERRIDE_CODE_TYPE_VALUES } from '@/constants';
import { Product } from './product';

export const BasicForm = (props: any) => {
    const {
        getFieldDecorator,
        getFieldValue,
        validateFieldsAndScroll,
        getFieldsValue,
        setFieldsValue,
    } = props.form;
    const [loading, setLoading] = useState(false);
    const [onlineStore, setOnlineStore] = useState([]);
    const [detail, setDetail] = useState<any>({});
    const {
        params: { id },
    } = props;
    const loadOnlineStore = async () => {
        try {
            let { result = [] }: any = await services.api.get(
                { page: 1, perPage: 100000 },
                {
                    apiPath: '/dc-store/admin/onlineStore',
                }
            );
            result = result.map((item: any) => ({ ...item, name: item.onlineStoreName }));
            setOnlineStore(result);
        } catch (e) {}
    };
    useEffect(() => {
        setFieldsValue({ ableStores: detail?.ableStores });
        // eslint-disable-next-line
    }, [onlineStore]);
    const loadDetail = async () => {
        const result: any = await services.api.get(
            {},
            {
                apiPath: `/dc-price/admin/overridecode/${id}`,
            }
        );
        const orgFormFields = getFieldsValue();
        let nFormFields: any = {};
        Object.keys(orgFormFields).forEach((key) => {
            switch (key) {
                case 'effectiveTime':
                    nFormFields[key] = { start: result['startTime'], end: result['endTime'] };
                    break;
                case 'type':
                    nFormFields[key] = result[key] + '';
                    break;
                case 'ableStores':
                    nFormFields[key] = (result[key] || []).map((item: any) => item.id);
                    break;
                default:
                    nFormFields[key] = result[key] || '';
            }
        });
        setDetail({
            ...result,
            ableStores: (result['ableStores'] || []).map((item: any) => item.id),
        });
        setFieldsValue(nFormFields);
    };
    useEffect(() => {
        loadOnlineStore();
        if (id) {
            loadDetail();
        }
        // eslint-disable-next-line
    }, []);
    const submit = () => {
        validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                const { effectiveTime, productInfoList, ...rest } = values;
                const {
                    history: { goBack },
                } = props;
                const params = {
                    ...rest,
                    startTime: `${effectiveTime?.start}`,
                    endTime: `${effectiveTime?.end}`,
                    productInfoList:
                        rest.type === OVERRIDE_CODE_TYPE_VALUES.CUSTOM
                            ? []
                            : (productInfoList || []).map((item: any) => ({
                                  ...item,
                                  skuId: item.cskuId || item.skuId,
                                  priceReduction: item.priceReduction,
                              })),
                };
                if (id) params.id = id;
                const call = id ? 'put' : 'post';
                setLoading(true);
                services.api[call](
                    {
                        ...params,
                    },
                    {
                        apiPath: `/dc-price/admin/overridecode`,
                    }
                )
                    .then((res) => {
                        message.success(services.language.getText('successFully'));
                        goBack();
                    })
                    .catch((err) => {
                        errorHandle(err);
                    })
                    .finally(() => setLoading(false));
            }
        });
    };
    return (
        <>
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                <Card title='基本设置' bordered={false}>
                    <Form.Item label='OverrideCode'>
                        {getFieldDecorator('overrideCode', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('overrideCode.emptyMsg.overrideCode'),
                                },
                            ],
                        })(
                            TypesManager.get('string').getControlComponent({
                                maxLength: 20,
                                name: 'overrideCode',
                                disabled: Boolean(id),
                            })
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('overrideCode.effectiveTime')} required>
                        {getFieldDecorator('effectiveTime', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText(
                                        'overrideCode.emptyMsg.effectiveTime'
                                    ),
                                },
                            ],
                        })(TypesManager.get('object.dateTimeRange').getControlComponent({}))}
                    </Form.Item>
                    <Form.Item label={language.getText('overrideCode.onlineStore')}>
                        {getFieldDecorator('ableStores', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('overrideCode.emptyMsg.onlineStore'),
                                },
                            ],
                        })(
                            TypesManager.get('array.onlineStoreGroup').getControlComponent({
                                titles: ['可选列表', '已选列表'],
                                options: onlineStore,
                                controlConfig: {
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText(
                                                'overrideCode.emptyMsg.onlineStore'
                                            ),
                                        },
                                    ],
                                },
                            })
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('overrideCode.type')}>
                        {getFieldDecorator('type', {
                            initialValue: OVERRIDE_CODE_TYPE_VALUES.PRESET,
                        })(
                            TypesManager.get('string.options.radio').getControlComponent({
                                options: OVERRIDE_CODE_TYPE_OPTIONS,
                                disabled: Boolean(id),
                            })
                        )}
                    </Form.Item>
                </Card>
                <Card title='商品信息' bordered={false}>
                    {getFieldValue('type') === OVERRIDE_CODE_TYPE_VALUES.PRESET && (
                        <Form.Item label={language.getText('overrideCode.productSelect')}>
                            {getFieldDecorator('productInfoList', {
                                rules: [
                                    {
                                        validator: function(rule: any, value: any, callback: any) {
                                            try {
                                                if (
                                                    value &&
                                                    value.some((i: any) => !i.priceReduction)
                                                ) {
                                                    callback(
                                                        new Error(
                                                            language.getText(
                                                                'overrideCode.emptyMsg.discountPrice'
                                                            )
                                                        )
                                                    );
                                                } else {
                                                    callback();
                                                }
                                            } catch (err) {
                                                callback(err);
                                            }
                                        },
                                    },
                                ],
                            })(<Product />)}
                        </Form.Item>
                    )}
                </Card>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={4}></Col>
                    <Col span={16}>
                        <Row style={{ textAlign: 'right' }}>
                            <Button
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                {language.getText('cancel')}
                            </Button>
                            <Button type='primary' onClick={submit} loading={loading}>
                                {language.getText('submit')}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export const OverrideCodeForm = Form.create()(BasicForm);

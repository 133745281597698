import { PromotionExchangeGroupProduct } from '@/components/promotion-exchange-group-product';
import { modes } from '@comall-backend-builder/types';
import React from 'react';

export class PromotionExchangeGroupProductMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
        };
        return <PromotionExchangeGroupProduct {...props} />;
    }
}

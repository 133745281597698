import { services } from '@comall-backend-builder/core';

import { fieldsToLanguageData, languageDataToFields } from '@/services/language-utils';

function transformDate(data: any) {
    if (data.descTime && data.descTime.start) {
        data.descStartTime = data.descTime.start;
        data.descEndTime = data.descTime.end;
    }

    data.descLanguageList = fieldsToLanguageData('descLanguageList', data);
    data.nameLanguageList = fieldsToLanguageData('nameLanguageList', data);
    return data;
}

function apiDataToFormData(data: any) {
    languageDataToFields('descLanguageList', data, data.descLanguageList);
    languageDataToFields('nameLanguageList', data, data.nameLanguageList);
    if (data.descStartTime && data.descEndTime) {
        data.descTime = { start: data.descStartTime, end: data.descEndTime };
    }
    if (data.icon && data.iconUrl) {
        const id = data.icon;
        const path = data.iconUrl;
        data.icon = {
            id,
            path,
        };
    }
    data.installmentFlag = data.installmentFlag + '';
    data.offlineFlag = data.offlineFlag + '';
    data.channelId = data.channelId + '';

    return data;
}
export default [
    {
        key: '/paymentMode/edit',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(
                    {},
                    {
                        ...config,
                        apiPath: `/dc-master-data/admin/payment_mode/${data.id}`,
                    }
                );
                return apiDataToFormData(response);
            },
            post: async function(data: any, config: any) {
                const params = transformDate(data);
                const response: any = await services.api.post(
                    { ...params },
                    {
                        ...config,
                        apiPath: `/dc-master-data/admin/payment_mode/add`,
                    }
                );
                return response;
            },
            put: async function(data: any, config: any) {
                const params = transformDate(data);
                const response: any = await services.api.post(
                    { ...params },
                    {
                        ...config,
                        apiPath: `/dc-master-data/admin/payment_mode/edit`,
                    }
                );
                return response;
            },
        },
    },
];

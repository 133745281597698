import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 装修管理多语言值
 */
export enum DESIGN_MANAGE_LANGUAGE_VALUES {
    ZH = 'zh',
    TC = 'tc',
    EN = 'en',
    // JP = 'jp',
    // FR = 'fr',
    // IT = 'it',
    // KO = 'ko',
}

/**
 * 装修管理多语言名
 */
export const DESIGN_MANAGE_LANGUAGE_NAMES = {
    [DESIGN_MANAGE_LANGUAGE_VALUES.ZH]: language.getText('chinese'),
    [DESIGN_MANAGE_LANGUAGE_VALUES.TC]: language.getText('tc'),
    [DESIGN_MANAGE_LANGUAGE_VALUES.EN]: language.getText('english'),
    // [DESIGN_MANAGE_LANGUAGE_VALUES.JP]: language.getText('japanese'),
    // [DESIGN_MANAGE_LANGUAGE_VALUES.FR]: language.getText('french'),
    // [DESIGN_MANAGE_LANGUAGE_VALUES.IT]: language.getText('it'),
    // [DESIGN_MANAGE_LANGUAGE_VALUES.KO]: language.getText('ko'),
};

/**
 * 装修管理多语言候选项
 */
export const DESIGN_MANAGE_LANGUAGE_OPTIONS = generateOptions(
    DESIGN_MANAGE_LANGUAGE_VALUES,
    DESIGN_MANAGE_LANGUAGE_NAMES
);

/**
 * 菜单管理
 */
export enum COMPONENT_TYPE {
    MENU_BAR_WORDS = 'menuBarWords',
    NOTICE_BAR_WORDS = 'noticeBarWords',
}

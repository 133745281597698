/*
 * @Author: zhulu
 * @Date: 2022-04-20 17:07:04
 * @Description:
 */
import * as React from 'react';
import { modes, formats } from '@comall-backend-builder/types';
import { Badge } from 'antd';
import { services } from '@comall-backend-builder/core';

const lang = services.language;

/**
 * 模板启用 / 禁用状态的展示
 */

export class RadioStateMode extends modes.StringOptionRadioMode {
    /**
     * 获取有数据状态展示组件
     */
    public getAvailableDisplayComponent(value: any, displayInfo: any) {
        const { className, style, options } = displayInfo;
        const enable = value === '1';
        let text = '';
        if (options) {
            const selectItem = options.find((item: any) => item.id === value);
            text = selectItem ? selectItem.name : '';
        }
        // 如果没有配置相关的配置项，则使用通用配置项
        if (!text) {
            text = enable ? lang.getText('common.enable') : lang.getText('common.disable');
        }

        return (
            <Badge
                className={className}
                style={style}
                status={enable ? 'success' : 'error'}
                text={text}
            />
        );
    }
}

export class RadioStateFormat extends formats.StringFormat {
    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any) {
        return { [key]: `${value}` };
    }
}

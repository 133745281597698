import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { LogDetail } from './LogDetail';
import { services } from '@comall-backend-builder/core';

function ViewlogModal(props: any) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div>
            <Button type='link' onClick={showModal}>
                {services.language.getText('deposit.viewLog')}
            </Button>
            <Modal
                title={services.language.getText('deposit.viewLog')}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
                footer={null}
            >
                {isModalVisible && <LogDetail id={props.row.id} />}
            </Modal>
        </div>
    );
}

export { ViewlogModal };

import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { PriceSection } from '@/containers/price-section';

export class PriceSectionMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PriceSection {...controlInfo} />;
    }
}

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MacauTimeRangesPicker } from '../../../containers';

export class MacauTimeRangesMode extends modes.ArrayMode {
    getDisplayComponent(value: any, displayInfo: any) {
        return (
            <span>
                {value.map((item: any) => (
                    <span style={{ marginRight: 8 }}>
                        {item.startTime}-{item.endTime}
                    </span>
                ))}
            </span>
        );
    }
    getControlComponent(controlInfo: any) {
        return <MacauTimeRangesPicker {...controlInfo} />;
    }
}

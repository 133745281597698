import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { TimePicker as AntTimePicker } from 'antd';
import moment from 'moment';
import { range, cloneDeep } from 'lodash';
import { CHANNEL } from '@/constants';

interface PickUpTimesProps {
    [key: string]: any;
}

interface PickUpTimesStates {
    value: string;
}

export class PickUpTimesComponent extends Component<PickUpTimesProps, PickUpTimesStates> {
    value = '00:00';
    format = this.props.format ?? 'HH:mm';

    disabledHours() {
        let { row, name } = this.props;
        if (name === 'pickUpTime') {
            return range(0, moment(row.orderDeadlineTime, this.format).hours());
        } else {
            return range(0, 0);
        }
    }
    disabledMinutes(selectedHour: any) {
        let { row, name, entity } = this.props;
        const noDisable = [
            'dutyFreeDeadlineTime',
            'payDeadlineTime',
            'deliveryTime',
            'timedReleaseTime',
        ];
        if (noDisable.indexOf(name) > -1) {
            return range(0);
        }
        const pickUpTime = moment(row.pickUpTime, this.format);
        const orderDeadlineTime = moment(row.orderDeadlineTime, this.format);
        if (name === 'pickUpTime') {
            return range(
                selectedHour === orderDeadlineTime.hours() ? orderDeadlineTime.minutes() : 60,
                selectedHour === orderDeadlineTime.hours() ? orderDeadlineTime.minutes() + 1 : 60
            );
        } else {
            if (
                entity.fields &&
                entity.fields.channelId &&
                entity.fields.channelId === CHANNEL.SELECT
            ) {
                return range(0);
            } else {
                return range(selectedHour === pickUpTime.hours() ? pickUpTime.minutes() : 60, 60);
            }
        }
    }
    onTimeChange(timeString: any) {
        const { onChange, name, value } = this.props;
        if (timeString === '' || timeString === null) {
            this.value = value;
        } else {
            this.value = moment(timeString).format(this.format);
        }
        onChange(this.value, name);
    }

    render() {
        const { value } = this.props;
        if (value) {
            this.value = cloneDeep(value);
        }
        const props = {
            allowEmpty: false,
            style: {
                width: '110px',
            },
        };
        return (
            <React.Fragment>
                {this.value ? (
                    <span>
                        <AntTimePicker
                            {...props}
                            value={moment(this.value, this.format)}
                            onChange={this.onTimeChange.bind(this)}
                            disabledHours={this.disabledHours.bind(this)}
                            disabledMinutes={this.disabledMinutes.bind(this)}
                            format={this.format}
                        />
                    </span>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    }
}
/**
 * 状态类型
 */
export class PickUpTimesMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        return <PickUpTimesComponent {...controlInfo} />;
    }
}

import { services } from '@comall-backend-builder/core/lib';

export default [
    {
        key: '/sessions',
        loader: {
            post: async function(data: any, config: any) {
                config = {
                    customHeaders: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    apiPath:
                        '/dc-user/admin/user/oauth/token?client_id=dch_ecomplatform&client_secret=5292A587F72DB34B6711562D587D0FC0&grant_type=password',
                    apiRoot: ENV.OAUTH_ROOT,
                };
                data.username = data.name;
                return await services.api.post(data, config);
            },

            delete: async function(data: any, config: any) {
                config.apiRoot = ENV.OAUTH_ROOT;
                config.apiPath = '/dc-user/admin/logout';
                return await services.api.delete(data, config);
            },
        },
    },
];

import { services } from '@comall-backend-builder/core';
import { each, toString, map } from 'lodash';
function formatData(data: any) {
    if (data.retailBusinessTimes === null) {
        data.retailBusinessTimes = [];
    }
    if (data.retailBusinessTimes?.length === 0) {
        data.retailBusinessTimes?.push({
            id: null,
            retailStoreId: data.retailStoreId,
            startWeek: 'MONDAY',
            endWeek: 'SUNDAY',
            startTime: '00:00',
            endTime: '22:30',
        });
    }
    data.basicInfo = {
        retailStoreId: data.retailStoreId,
        organizationId: toString(data.organizationId),
        languageName: data.languageName,
        mcsLocationIds: map(data.retailMcsLocationPolist, (location) => location.mcsLocation).join(
            ','
        ),
        isShow: data.isShow,
    };
    data.contactInformation = {
        contactMap: data.contactMap,
        phone: data.phone,
    };
    data.addressInfo = {
        longitude: data.longitude,
        latitude: data.latitude,
        addressMap: data.addressMap,
        retailBusinessTimes: data.retailBusinessTimes,
        pic: data.pic,
    };

    return data;
}
export const loader = [
    {
        key: '/dc-store/admin/retailStore',
        loader: {
            get: async function(data: any, config: any) {
                if (data.id) {
                    config.apiPath = `/dc-store/admin/retailStore/findById/${data.id}`;
                }
                const respData: any = await services.api.get(data, config);
                if (!data.id && respData.result) {
                    each(respData.result, (item) => {
                        item.code = item.retailStoreId;
                        item.name = item.retailStoreName;
                        formatData(item);
                    });
                } else {
                    formatData(respData);
                }

                return respData;
            },
            put: async function(data: any, config: any) {
                config.apiPath = '/dc-store/admin/retailStore/update';
                const params = {
                    id: data.id,
                    retailStoreId: data.basicInfo.retailStoreId,
                    languageName: data.basicInfo.languageName,
                    mcsLocationIds: data.basicInfo.mcsLocationIds,
                    isShow: data.basicInfo.isShow,
                    contactMap: data.contactInformation.contactMap,
                    phone: data.contactInformation.phone,
                    longitude: data.addressInfo.longitude,
                    latitude: data.addressInfo.latitude,
                    addressMap: data.addressInfo.addressMap,
                    retailBusinessTimes: data.addressInfo.retailBusinessTimes,
                    pic: data.addressInfo.pic || '',
                };
                return await services.api.put(params, config);
            },
        },
    },
    {
        key: '/dc-store/admin/retailStore/findStoreOrganizations',
        loader: {
            get: async function(data: any, config: any) {
                const result = await services.api.get(data, config);
                return [result];
            },
        },
    },
    {
        key: '/dc-store/admin/retailStore/findByLoginUser',
        loader: {
            get: async function(data: any, config: any) {
                data.keyword = data.codeOrName;
                delete data.codeOrName;
                const respData: any = await services.api.get(data, config);
                each(respData.result, (item) => {
                    item.code = item.retailStoreId;
                    item.name = item.retailStoreName;
                });
                return respData;
            },
        },
    },
];

import React, { Component, Fragment } from 'react';
import {
    Button as AntButton,
    Modal as AntModal,
    message as AntMessage,
    Select,
    Input,
    Button,
} from 'antd';
import { trim, map } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { OptionType } from '@/interfaces';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';
import { Privilege } from '../privilege';
import { ORDER_TYPE } from '@/constants/order/enum';
import { OrderStatusMsg } from './order-status-msg';

function fetchReasonsApi(type: string): Promise<Array<OptionType>> {
    const isZh = services.language.getCurrentLanguage().id === 'zh';

    return services.api
        .get<(OptionType & { nameZh: string })[]>(
            {},
            {
                apiPath: formatUrl(API_MAP.CANCEL_REASONS, type),
            }
        )
        .then((data) =>
            data.map((item) => ({
                id: item.id,
                name: isZh ? item.nameZh : item.name,
            }))
        );
}

export function fetchLinkedOrder(id: string): Promise<OrderData[]> {
    return services.api
        .get<OrderData[]>(
            {},
            {
                apiPath: `/base-order/admin/order/${id}/getLinkedOrder`,
            }
        )
        .then((data) => data);
}

interface IProps {
    orderNumber: string;
    parentProps: any;
    menuType: 'picking' | 'order';
    type: string;
}

type OrderData = {
    orderStatus: string;
    outerOrderNumber: string;
};

interface IState {
    confirmLoading: boolean;
    fetchReasonsLoading: boolean;
    visible: boolean;
    reasonId: string | undefined;
    reasonContent: string | undefined;
    options: Array<OptionType>;
    linkedOrderData: OrderData[];
    showReasons: boolean;
}

export class CancelOrderModal extends Component<IProps, IState> {
    state: IState = {
        confirmLoading: false,
        fetchReasonsLoading: false,
        visible: false,
        reasonId: undefined,
        reasonContent: undefined,
        options: [],
        linkedOrderData: [],
        showReasons: true,
    };

    toggleModal = (flag: boolean) => {
        this.setState({
            visible: flag,
        });
    };

    onChangeReasonId = (value: string) => {
        this.setState({
            reasonId: value,
        });
    };

    onChangeReasonContent = (value: string) => {
        this.setState({
            reasonContent: value,
        });
    };

    handleClick = async () => {
        try {
            this.setState({ fetchReasonsLoading: true });
            if (this.props.type === 'base') {
                const [reasons, linkedOrderData] = await Promise.all([
                    fetchReasonsApi(this.props.type),
                    fetchLinkedOrder(this.props.orderNumber),
                ]);
                const showReasons = linkedOrderData.some(
                    (item) => item.orderStatus === 'COMPLETED'
                );
                this.setState({
                    options: reasons,
                    linkedOrderData,
                    showReasons,
                });
            } else {
                const reasons = await fetchReasonsApi(this.props.type);
                this.setState({
                    options: reasons,
                });
            }
            this.toggleModal(true);
        } catch (e) {
            services.errorHandle(e as ErrorInfo);
        } finally {
            this.setState({ fetchReasonsLoading: false });
        }
    };

    handleOk = () => {
        const { reasonId, reasonContent } = this.state;
        const {
            orderNumber,
            menuType,
            parentProps: { entity },
            type,
        } = this.props;

        if (!reasonId) {
            AntMessage.error(services.language.getText('order.pleaseSelectCancelOrderReason'));
            return;
        }
        this.setState({ confirmLoading: true });
        services.api
            .put(
                {
                    id: reasonId,
                    remark: trim(reasonContent),
                    menuType,
                    orderNumber,
                },
                {
                    apiPath: formatUrl(API_MAP.ORDER_CANCEL, type!),
                }
            )
            .then(() => {
                entity.search(entity.params);
                this.toggleModal(false);
                this.setState({
                    reasonId: undefined,
                    reasonContent: undefined,
                });
                AntMessage.success(services.language.getText('order.orderCancelSuccess'));
            })
            .catch(services.errorHandle)
            .finally(() => {
                this.setState({ confirmLoading: false });
            });
    };

    render() {
        const {
            fetchReasonsLoading,
            confirmLoading,
            reasonId,
            reasonContent,
            options,
            visible,
            linkedOrderData,
            showReasons,
        } = this.state;

        return (
            <Fragment>
                <Privilege
                    path={
                        this.props.type === ORDER_TYPE.BASE
                            ? 'order.order.cancel_reasons'
                            : 'order_philips.order_philips.cancel_reasons_philips'
                    }
                >
                    <AntButton loading={fetchReasonsLoading} onClick={() => this.handleClick()}>
                        {services.language.getText('order.cancelOrder')}
                    </AntButton>
                </Privilege>
                <AntModal
                    title={services.language.getText('order.conformCancelOrder')}
                    visible={visible}
                    confirmLoading={confirmLoading}
                    onCancel={() => {
                        this.toggleModal(false);
                    }}
                    footer={
                        <>
                            <Button onClick={() => this.toggleModal(false)}>
                                {services.language.getText('close')}
                            </Button>
                            {!showReasons && (
                                <Button type='primary' onClick={this.handleOk}>
                                    {services.language.getText('common.ok')}
                                </Button>
                            )}
                        </>
                    }
                >
                    <OrderStatusMsg
                        listData={linkedOrderData}
                        msg={
                            !showReasons
                                ? services.language.getText('order.orderStatusMsg')
                                : services.language.getText('order.orderCompleteMsg')
                        }
                    />
                    {!showReasons && (
                        <>
                            <Select
                                allowClear
                                placeholder={services.language.getText(
                                    'order.pleaseSelectCancelOrderReason'
                                )}
                                style={{ width: '100%', marginBottom: 15 }}
                                onChange={this.onChangeReasonId}
                                value={reasonId}
                            >
                                {map(options, (item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Input.TextArea
                                value={reasonContent}
                                placeholder={services.language.getText(
                                    'order.cancelOrderDescPlaceholder'
                                )}
                                rows={3}
                                onChange={(e) => this.onChangeReasonContent(e.target.value)}
                                maxLength={200}
                            />
                        </>
                    )}
                </AntModal>
            </Fragment>
        );
    }
}

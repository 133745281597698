import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { get, isEmpty } from 'lodash';

const StoreNameView: React.FC<{ data: any }> = (props) => {
    const { data } = props;
    let info = data;

    if (isEmpty(info)) {
        return <span>-</span>;
    }
    if (!(info instanceof Array)) {
        info = [info];
    }
    return (
        <div>
            {info.map((item: any) => (
                <div key={item.retailStoreId}>
                    <span style={{ paddingRight: 20 }}>{item.retailStoreName}</span>
                    <span style={{ paddingRight: 20 }}>{item.retailStoreId}</span>
                </div>
            ))}
        </div>
    );
};

class StoreName extends Component<any, any> {
    render() {
        const { name, entity } = this.props;
        const data = get(entity, `fields.${name}`, []);
        return <StoreNameView data={data} />;
    }
}

/**
 * 状态类型
 */
export class StoreNameMode extends modes.StringMode {
    getControlComponent(controlInfo: any) {
        return <StoreName {...controlInfo} />;
    }
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, displayConfig: any) {
        return <StoreNameView data={value} />;
    }
}

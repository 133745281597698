import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/dc-order/admin/orders/sales',
        loader: {
            get: async function(data: any, config: any) {
                const { keyword } = data;
                if (keyword) {
                    delete data.keyword;
                    const response: any = await services.api.get(data, {
                        ...config,
                        apiPath: `${config.apiPath}/${keyword}`,
                    });
                    return response;
                }
            },
        },
    },
];

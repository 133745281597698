import { Config } from '@comall-backend-builder/core/lib/parser';
const creatProductRoute = (type: 'normal' | 'group' | 'virtual') => {
    const componentMap = {
        normal: {
            container: 'ProductListContainer',
            list: 'ProductListManagement',
        },
        group: {
            container: 'GroupProductListContainer',
            list: 'GroupProductListManagement',
        },
        virtual: {
            container: 'VirtualProductListContainer',
            list: 'VirtualProductListManagement',
        },
    };
    return {
        path: `/productList/${type}`,
        //@ts-ignore
        component: componentMap[type]['container'],
        breadcrumbName: `<<productList.${type}.title>>`,
        category: '<<productManage.title>>',
        childRoutes: [
            {
                path: '/add',
                component: 'ProductDetailAddWrap',
                breadcrumbName: `<<productList.${type}.add>>`,
            },
            {
                path: '/edit/:id',
                component: 'ProductDetailEditWrap',
                breadcrumbName: `<<productList.${type}.edit>>`,
            },
            {
                path: '/detail/:id',
                component: 'ProductDetailWrap',
                breadcrumbName: `<<productList.${type}.view>>`,
            },
            {
                path: '/',
                //@ts-ignore
                component: componentMap[type]['list'],
                isIndex: true,
            },
        ],
    };
};
export const config = {
    routes: [
        {
            path: '/virtual-design/:id',
            component: 'DesignPage',
        },
        // {
        //     path: '/login-back',
        //     component: 'LoginBackPage',
        // },
        {
            path: '/login',
            component: 'SsoLogin',
        },
        {
            path: '/',
            component: 'SideNavFrame',
            // authentication: '/login',
            childRoutes: [
                {
                    path: '/home',
                    isIndex: true,
                    component: 'Home',
                    breadcrumbName: '<<home.breadcrumbName>>',
                },

                // 商品库存监控
                {
                    path: '/product-inventory-monitoring',
                    component: 'ProductInventoryMonitoringView',
                    breadcrumbName: '<<productInventory.monitoring>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ProductInventoryMonitoring',
                        },
                    ],
                },
                // 商品库存管理
                {
                    path: '/product-inventory',
                    component: 'ProductInventoryView',
                    breadcrumbName: '<<productInventory.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            breadcrumbName: '<<productInventory.changeLog>>',
                            path: '/changeLog/:id/:csku',
                            component: 'ProductInventoryChangeLogPage',
                        },
                        {
                            breadcrumbName: '<<productInventory.edit>>',
                            path: '/edit/:storeId/:csku/:rsku',
                            component: 'ProductInventoryEditPage',
                        },
                        {
                            breadcrumbName: '<<productInventory.supplement>>',
                            path: '/supplement/:storeId/:csku/:rsku',
                            component: 'ProductInventorySupplementPage',
                        },
                        {
                            breadcrumbName: '<<productInventory.deductions>>',
                            path: '/deductions/:storeId/:csku/:rsku',
                            component: 'ProductInventoryDeductionsPage',
                        },
                        {
                            path: '/',
                            component: 'ProductInventory',
                        },
                    ],
                },
                { ...creatProductRoute('normal') },
                { ...creatProductRoute('virtual') },
                { ...creatProductRoute('group') },

                {
                    path: '/product-price',
                    component: 'ProductPriceView',
                    breadcrumbName: '<<productPrice.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            breadcrumbName: '<<productPrice.priceChangeLog>>',
                            path: '/price-change-log/:rsku',
                            component: 'PriceChangeLogPage',
                        },
                        {
                            breadcrumbName: '<<productPrice.productPriceEdit>>',
                            path: '/edit/:onlineStoreId/:csku/:rsku',
                            component: 'ProductPriceEditPage',
                        },
                        {
                            path: '/',
                            component: 'ProductPrice',
                        },
                    ],
                },
                // 商品价格监控
                {
                    path: '/product-price-monitoring',
                    component: 'ProductPriceMonitoringView',
                    breadcrumbName: '<<productPrice.monitoring>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ProductPriceMonitoring',
                        },
                    ],
                },
                //商品套转
                {
                    path: '/product-suite',
                    component: 'ProductSuiteView',
                    breadcrumbName: '<<productSuite.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            breadcrumbName: '<<productSuite.add>>',
                            path: '/add',
                            component: 'ProductSuiteAdd',
                        },
                        {
                            breadcrumbName: '<<productSuite.edit>>',
                            path: '/edit/:id',
                            component: 'ProductSuiteEdit',
                        },
                        {
                            path: '/',
                            component: 'ProductSuiteList',
                        },
                    ],
                },
                // 关联商品
                {
                    path: '/product-group',
                    component: 'ProductGroupView',
                    breadcrumbName: '<<productGroup.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            breadcrumbName: '<<productGroup.add>>',
                            path: '/add',
                            component: 'ProductGroupAdd',
                        },
                        {
                            breadcrumbName: '<<productGroup.edit>>',
                            path: '/edit/:id',
                            component: 'ProductGroupEdit',
                        },
                        {
                            path: '/',
                            component: 'ProductGroupList',
                        },
                    ],
                },
                //线上门店选品
                {
                    path: '/product-selection',
                    component: 'ProductSelectionView',
                    breadcrumbName: '<<productSelection.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ProductSelectionList',
                        },
                    ],
                },
                //商品seo设置
                {
                    path: '/productList/seo',
                    component: 'ProductSEOContainer',
                    breadcrumbName: '<<productSeo.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            breadcrumbName: '<<productSeo.info>>',
                            path: '/info/:id',
                            component: 'ProductSeoInfo',
                        },
                        {
                            breadcrumbName: '<<productSeo.edit>>',
                            path: '/edit/:id',
                            component: 'ProductSeoModify',
                        },
                        {
                            path: '/',
                            component: 'ProductSEO',
                        },
                    ],
                },
                // 商品映射管理
                {
                    path: '/product-map',
                    component: 'ProductMapView',
                    breadcrumbName: '<<productMap.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ProductMap',
                        },
                    ],
                },
                //商品style设置
                {
                    path: '/product-style',
                    component: 'ProductStyleContainer',
                    breadcrumbName: '<<productSpec.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            breadcrumbName: '<<productSpec.add>>',
                            path: '/add',
                            component: 'ProductStyleCreate',
                        },
                        {
                            breadcrumbName: '<<productSpec.edit>>',
                            path: '/edit/:id',
                            component: 'ProductStyleModify',
                        },
                        {
                            path: '/',
                            component: 'ProductStyleView',
                        },
                    ],
                },
                //门店管理-线上门店管理
                {
                    path: '/onlineStore',
                    isIndex: true,
                    component: 'OnlineStoreContainer',
                    breadcrumbName: '<<onlineStore.title>>',
                    category: '<<storeManage.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'EditOnlineStorePage',
                            breadcrumbName: '<<onlineStore.editStore>>',
                        },
                        {
                            path: '/detail/:id',
                            component: 'ViewOnlineStorePage',
                            breadcrumbName: '<<onlineStore.viewStore>>',
                        },
                        {
                            path: '/',
                            component: 'OnlineStoreManagement',
                            isIndex: true,
                        },
                    ],
                },
                //门店管理-提货点管理
                {
                    path: '/pickStore',
                    isIndex: true,
                    component: 'PickStoreContainer',
                    breadcrumbName: '<<pickStore.title>>',
                    category: '<<storeManage.title>>',
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'PickStoreInfoPage',
                            breadcrumbName: '<<pickStore.viewStore>>',
                        },
                        {
                            path: '/add-store',
                            component: 'AddStoreLayout',
                            breadcrumbName: '<<pickStore.addStoreTitle>>',
                        },
                        {
                            path: '/create-online-store/:id',
                            component: 'CreateStoreChannelPage',
                            breadcrumbName: '<<pickStore.createOnlineStore>>',
                        },
                        {
                            path: '/edit-store/:id',
                            component: 'EditPickStorePage',
                            breadcrumbName: '<<pickStore.editStoreTitle>>',
                        },
                        {
                            path: '/',
                            component: 'PickStoreManagement',
                            isIndex: true,
                        },
                    ],
                },
                // 提货点上线管理
                {
                    path: '/pickOnlineStore',
                    component: 'PickOnlineStoreContainer',
                    breadcrumbName: '<<pickOnlineStore.title>>',
                    category: '<<storeManage.title>>',
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'PickOnlineStoreInfoPage',
                            breadcrumbName: '<<pickStore.viewStore>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'EditStoreChannelPage',
                            breadcrumbName: '<<pickOnlineStore.editStoreTitle>>',
                        },
                        {
                            path: '/',
                            component: 'PickOnlineStoreManagement',
                            isIndex: true,
                        },
                    ],
                },
                // 线下门店管理
                {
                    path: '/retailStore',
                    component: 'RetailStoreContainer',
                    breadcrumbName: '<<retailStore.title>>',
                    category: '<<storeManage.title>>',
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'RetailStoreInfoPage',
                            breadcrumbName: '<<retailStore.viewStore>>',
                        },
                        {
                            path: '/edit-store/:id',
                            component: 'EditRetailStorePage',
                            breadcrumbName: '<<retailStore.editStoreTitle>>',
                        },
                        {
                            path: '/',
                            component: 'RetailStoreManagement',
                            isIndex: true,
                        },
                    ],
                },
                // 门店管理-合作商户管理
                {
                    path: '/businessCooperator',
                    component: 'MerchantsStoreContainer',
                    breadcrumbName: '<<businessCooperator.title>>',
                    category: '<<storeManage.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'EditMerchantsStorePage',
                            breadcrumbName: '<<businessCooperator.editMerchantsStore>>',
                        },
                        {
                            path: '/detail/:id',
                            component: 'ViewMerchantsStorePage',
                            breadcrumbName: '<<businessCooperator.viewMerchantsStore>>',
                        },
                        {
                            path: '/recommend-product/:recommendType/:recommendSourceId',
                            breadcrumbName: '<<businessCooperator.recommendProduct>>',
                            component: 'MerchantsRecommendProductPage',
                        },
                        {
                            path: '/',
                            component: 'MerchantsStoreManagement',
                            isIndex: true,
                        },
                    ],
                },
                //配送管理-门店运费规则管理
                {
                    path: '/storeFreightRules',
                    component: 'StoreFreightRulesContainer',
                    breadcrumbName: '<<storeFreightRules.title>>',
                    category: '<<distribution.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:roleType/:onlineStoreId/:freightType',
                            component: 'StoreFreightRulesEditPage',
                            breadcrumbName: '<<storeFreightRules.editMerchantsStore>>',
                        },
                        {
                            path: '/add/:freightType',
                            component: 'StoreFreightRulesAddPage',
                            breadcrumbName: '<<storeFreightRules.editMerchantsStore>>',
                        },
                        {
                            path: '/:activeKey',
                            component: 'StoreFreightRulesManagement',
                        },
                        {
                            path: '/',
                            component: 'StoreFreightRulesManagement',
                            isIndex: true,
                        },
                    ],
                },
                //配送管理-合作商户运费规则管理
                {
                    path: '/merchantFreightRules',
                    component: 'MerchantFreightRulesContainer',
                    breadcrumbName: '<<merchantFreightRules.title>>',
                    category: '<<distribution.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:roleType/:merchantId/:freightType',
                            component: 'MerchantFreightRulesEditPage',
                            breadcrumbName: '<<merchantFreightRules.editMerchant>>',
                        },
                        {
                            path: '/add/:freightType',
                            component: 'MerchantFreightRulesAddPage',
                            breadcrumbName: '<<merchantFreightRules.addMerchant>>',
                        },
                        {
                            path: '/:activeKey',
                            component: 'MerchantFreightRulesManagement',
                        },
                        {
                            path: '/',
                            component: 'MerchantFreightRulesManagement',
                            isIndex: true,
                        },
                    ],
                },
                //管理Division
                {
                    path: '/organizations',
                    component: 'OrganizationsContainer',
                    breadcrumbName: '<<organizations.title>>',
                    category: '<<systemManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'OrganizationsManagement',
                            isIndex: true,
                        },
                    ],
                },
                //管理渠道
                {
                    path: '/channels',
                    component: 'ChannelsContainer',
                    breadcrumbName: '<<channels.title>>',
                    category: '<<systemManage.title>>',
                    childRoutes: [
                        {
                            path: '/auto-cancel/:id',
                            breadcrumbName: '<<autoCancel.title>>',
                            component: 'AutoCancelPage',
                        },
                        {
                            path: '/detail/:id/:name',
                            component: 'ChannelContactsContainer',
                            breadcrumbName: '<<channels.childTitle>>',
                        },
                        {
                            path: '/',
                            component: 'ChannelsManagement',
                            isIndex: true,
                        },
                    ],
                },
                // 管理Ecdepoist配置
                {
                    path: '/ECdeposit',
                    component: 'EcDepositContianer',
                    breadcrumbName: '<<deposit.ecDepostRules>>',
                    category: '<<systemManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'EcDepositManagement',
                            isIndex: true,
                        },
                    ],
                },
                //管理标准分类
                {
                    path: '/offlineCategory',
                    component: 'OfflineCategoryContainer',
                    breadcrumbName: '<<offlineCategory.title>>',
                    category: '<<categoryManage.title>>',
                    childRoutes: [
                        {
                            path: '/specialAttribute/:id',
                            component: 'SpecialAttributePage',
                            breadcrumbName: '<<offlineCategory.specialAttribute>>',
                        },
                        {
                            path: '/',
                            component: 'OfflineCategoryManagement',
                            isIndex: true,
                        },
                    ],
                },
                //管理虚拟分类
                {
                    path: '/virtualCategory',
                    component: 'VirtualCategoryContainer',
                    breadcrumbName: '<<virtualCategory.navTitle>>',
                    category: '<<categoryManage.title>>',
                    childRoutes: [
                        {
                            breadcrumbName: '<<virtualCategory.add>>',
                            path: '/add',
                            component: 'VirtualCategoryAddPage',
                        },
                        {
                            breadcrumbName: '<<virtualCategory.edit>>',
                            path: '/edit/:id',
                            component: 'VirtualCategoryEditPage',
                        },
                        {
                            breadcrumbName: '<<virtualCategory.productList>>',
                            path: '/product/:id',
                            component: 'CategoryProductManagement',
                        },
                        {
                            path: '/',
                            component: 'VirtualCategoryManagement',
                        },
                    ],
                },
                //管理展示分类
                {
                    path: '/displayCategory',
                    component: 'DisplayCategoryContainer',
                    breadcrumbName: '<<displayCategory.title>>',
                    category: '<<categoryManage.title>>',
                    childRoutes: [
                        {
                            breadcrumbName: '<<displayCategory.add>>',
                            path: '/add',
                            component: 'DisplayCategoryAddPage',
                        },
                        {
                            breadcrumbName: '<<displayCategory.edit>>',
                            path: '/edit/:id',
                            component: 'DisplayCategoryEditPage',
                        },
                        {
                            breadcrumbName: '<<virtualCategory.productList>>',
                            path: '/product/:id',
                            component: 'DisplayCategoryProduct',
                        },
                        {
                            path: '/recommend-product/:recommendType/:recommendSourceId',
                            breadcrumbName: '<<displayCategory.recommendProduct>>',
                            component: 'DisplayCategoryRecommendProductPage',
                        },
                        {
                            path: '/',
                            component: 'DisplayCategoryManagement',
                        },
                    ],
                },
                //管理品牌
                {
                    path: '/brands',
                    component: 'BrandsContainer',
                    breadcrumbName: '<<brands.title>>',
                    category: '<<categoryManage.title>>',
                    childRoutes: [
                        {
                            path: '/create',
                            component: 'BrandCreatePage',
                            breadcrumbName: '<<brandEdit.createBreadcrumbName>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'BrandEditPage',
                            breadcrumbName: '<<brandEdit.editBreadcrumbName>>',
                        },
                        {
                            path: '/detail/:id',
                            component: 'BrandsInfoPage',
                            breadcrumbName: '<<brands.viewStore>>',
                        },
                        {
                            path: '/recommend-product/:recommendType/:recommendSourceId',
                            breadcrumbName: '<<brands.recommendProduct>>',
                            component: 'BrandsRecommendProductPage',
                        },
                        {
                            path: '/',
                            component: 'BrandsManagement',
                            isIndex: true,
                        },
                    ],
                },

                //商品上下架管理
                {
                    path: '/productShelf',
                    component: 'ProductShelfContainer',
                    breadcrumbName: '<<productShelf.title>>',
                    category: '<<productManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ProductShelfPage',
                        },
                    ],
                },

                //会员管理
                {
                    path: '/members',
                    component: 'MembershipListContainer',
                    category: '<<membership.title>>',
                    breadcrumbName: '<<membership.membershipList>>',
                    childRoutes: [
                        {
                            path: '/create',
                            component: 'MembershipCreatePage',
                            breadcrumbName: '<<membership.add>>',
                        },
                        {
                            path: '/info/:id',
                            component: 'membershipDetailPage',
                            breadcrumbName: '<<membership.detail>>',
                        },
                        {
                            path: '/',
                            component: 'MembershipList',
                            breadcrumbName: '<<membership.memberList>>',
                        },
                    ],
                },
                // 会员等级管理
                {
                    path: '/member-level',
                    component: 'MembershipLevelListContainer',
                    category: '<<membership.title>>',
                    breadcrumbName: '<<membershipLevel.title>>',
                    childRoutes: [
                        {
                            path: '/create',
                            component: 'LevelCreatePage',
                            breadcrumbName: '<<membershipLevel.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'LevelEditPage',
                            breadcrumbName: '<<membershipLevel.edit>>',
                        },
                        {
                            path: '/black-product-list/:id',
                            component: 'MemberLevelBlackProductList',
                            breadcrumbName: '<<membershipLevel.blackSkuProduct.title>>',
                        },
                        {
                            path: '/price-product-list/:id',
                            component: 'memberPriceProductList',
                            breadcrumbName: '<<membershipLevel.memberPriceProduct.title>>',
                        },
                        {
                            path: '/member/:id',
                            component: 'memberLevelMember',
                            breadcrumbName: '<<membershipLevel.memberLevelMember.title>>',
                        },
                        {
                            path: '/',
                            component: 'MembershipLevelList',
                        },
                    ],
                },
                // 会员来源管理
                {
                    path: '/member-source',
                    component: 'MembershipSourceListContainer',
                    category: '<<membership.title>>',
                    breadcrumbName: '<<memberSource.title>>',
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MemberSourcePage',
                            breadcrumbName: '<<memberSource.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MemberSourceEditPage',
                            breadcrumbName: '<<memberSource.edit>>',
                        },
                        {
                            path: '/black-product-list/:id',
                            component: 'MemberSourceBlackProductList',
                            breadcrumbName: '<<membershipLevel.blackSkuProduct.title>>',
                        },
                        {
                            path: '/',
                            component: 'MembershipSourceList',
                        },
                    ],
                },
                // 会员等级审核
                {
                    path: '/member-examine',
                    component: 'MemberExamineLayout',
                    category: '<<membership.title>>',
                    breadcrumbName: '<<memberExamine.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'MemberExamineLayout',
                        },
                    ],
                },
                // 领券中心
                {
                    path: '/coupon-center',
                    component: 'CouponCenterView',
                    category: '<<couponManage.title>>',
                    breadcrumbName: '<<couponManage.couponCenter.title>>',
                    childRoutes: [
                        {
                            path: '/data/:id',
                            component: 'CouponCenterDataPage',
                            breadcrumbName: '<<couponManage.couponCenter.viewTitle>>',
                        },
                        {
                            path: '/create',
                            component: 'CouponCenterAddPage',
                            breadcrumbName: '<<couponManage.couponCenter.addTitle>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CouponCenterEditPage',
                            breadcrumbName: '<<couponManage.couponCenter.editTitle>>',
                        },
                        {
                            path: '/',
                            component: 'CouponCenterPage',
                        },
                    ],
                },
                // 券包管理
                {
                    path: '/coupon-package',
                    component: 'Viewport',
                    category: '<<couponManage.title>>',
                    breadcrumbName: '<<couponManage.couponPackage.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'CouponPackageEditPage',
                            breadcrumbName: '<<couponManage.couponPackage.editTitle>>',
                        },
                        {
                            path: '/add',
                            component: 'CouponPackageAddPage',
                            breadcrumbName: '<<couponManage.couponPackage.addTitle>>',
                        },
                        {
                            path: '/',
                            component: 'CouponPackagePage',
                        },
                    ],
                },
                //订单管理
                {
                    path: '/orders',
                    component: 'OrdersContainer',
                    breadcrumbName: '<<order.title>>',
                    category: '<<orderManage.title>>',
                    childRoutes: [
                        {
                            path: '/info/:orderNo',
                            component: 'OrderInfo',
                            breadcrumbName: '<<orderInfo.title>>',
                        },
                        {
                            path: '/:orderStatus',
                            component: 'OrdersManagement',
                        },
                        {
                            path: '/',
                            component: 'OrdersManagement',
                            isIndex: true,
                        },
                    ],
                }, //订单管理
                {
                    path: '/order-auto-cancel',
                    component: 'AutoCancelContainer',
                    breadcrumbName: '<<order.autoCancel.title>>',
                    category: '<<orderManage.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:channelId/:organizationId',
                            breadcrumbName: '<<order.autoCancel.subTitle>>',
                            component: 'AutoCancelEdit',
                        },
                        {
                            path: '/',
                            component: 'AutoCancelManagement',
                            isIndex: true,
                        },
                    ],
                },
                //线下业务-线下订单管理
                {
                    path: '/offlineOrders',
                    component: 'OfflineOrderContainer',
                    breadcrumbName: '<<offlineOrder.title>>',
                    category: '<<offlineBusiness.title>>',
                    childRoutes: [
                        {
                            path: '/create/:onlineStoreIds',
                            component: 'OfflineOrdersCreate',
                            breadcrumbName: '<<offlineOrder.create.title>>',
                            isIndex: true,
                        },
                        {
                            path: '/info/:orderNo',
                            component: 'OrderInfo',
                            breadcrumbName: '<<orderInfo.title>>',
                        },
                        {
                            path: '/:orderStatus',
                            component: 'OfflineOrderManagement',
                        },
                        {
                            path: '/',
                            component: 'OfflineOrderManagement',
                            isIndex: true,
                        },
                    ],
                },
                //deposit管理
                {
                    path: '/depositManage',
                    component: 'depositContainer',
                    breadcrumbName: '<<deposit.DepositManagement>>',
                    category: '<<orderManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'depositManagement',
                            isIndex: true,
                        },
                    ],
                },
                // 拣货管理-拣货小助手
                {
                    path: '/picking-assistant',
                    component: 'PickingAssistantContainer',
                    category: '<<picking.pickingManagement>>',
                    breadcrumbName: '<<picking.pickingAssistant>>',
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'PickingAssistantDetailPage',
                            breadcrumbName: '<<picking.pickingAssistantDetail>>',
                        },
                        {
                            path: '/:status',
                            component: 'PickingAssistantPage',
                        },
                        {
                            path: '/',
                            component: 'PickingAssistantPage',
                        },
                    ],
                },
                // 售后管理 - 售后单管理
                {
                    path: '/afterSalesOrder',
                    component: 'AfterSaleOrdersContainer',
                    breadcrumbName: '<<afterSalesOrder.title>>',
                    category: '<<afterSalesManage>>',
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'AfterSaleOrdersCreate',
                            breadcrumbName: '<<afterSale.add>>',
                            isIndex: true,
                        },
                        {
                            path: '/info/:aftermarketId',
                            component: 'AfterSaleOrdersInfo',
                            breadcrumbName: '<<afterSalesOrder.info>>',
                        },
                        {
                            path: '/:aftermarketStatus',
                            component: 'AfterSaleOrdersManagement',
                        },
                        {
                            path: '/',
                            component: 'AfterSaleOrdersManagement',
                            isIndex: true,
                        },
                    ],
                },
                // 售后管理 - 退款单管理
                {
                    path: '/refundOrder',
                    component: 'RefundOrderContainer',
                    breadcrumbName: '<<afterSalesOrder.refundOrder>>',
                    category: '<<afterSalesManage>>',
                    childRoutes: [
                        {
                            path: '/:orderRefundStatus',
                            component: 'RefundOrderManagement',
                        },
                        {
                            path: '/',
                            component: 'RefundOrderManagement',
                            isIndex: true,
                        },
                    ],
                },
                // 权限管理 - 用户管理
                {
                    path: '/privilege/users',
                    category: '<<privilegeManage.title>>',
                    breadcrumbName: '<<privilegeManage.users.title>>',
                    component: 'UsersContainer',
                    childRoutes: [
                        {
                            breadcrumbName: '<<privilegeManage.users.editUser>>',
                            path: '/edit/:id',
                            component: 'UsersEditPage',
                        },
                        {
                            breadcrumbName: '<<privilegeManage.users.addUser>>',
                            path: '/add',
                            component: 'UsersAddPage',
                        },
                        {
                            path: '/',
                            component: 'UsersPage',
                        },
                    ],
                },
                // 权限管理 - 角色管理
                {
                    path: '/privilege/roles',
                    category: '<<privilegeManage.title>>',
                    breadcrumbName: '<<privilegeManage.roles.title>>',
                    component: 'RolesContainer',
                    childRoutes: [
                        {
                            breadcrumbName: '<<privilegeManage.roles.editRole>>',
                            path: '/edit/:id',
                            component: 'RolesEditPage',
                        },
                        {
                            breadcrumbName: '<<privilegeManage.roles.addRole>>',
                            path: '/add',
                            component: 'RolesAddPage',
                        },
                        {
                            path: '/',
                            component: 'RolesPage',
                        },
                    ],
                },
                //日志管理 日志管理
                {
                    path: '/import-records',
                    category: '<<importRecord.title>>',
                    breadcrumbName: '<<importRecord.breadcrumbName>>',
                    component: 'ImportRecordView',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ImportRecordLayout',
                        },
                    ],
                },
                //日志管理 导出文件
                {
                    path: '/export-files',
                    category: '<<importRecord.title>>',
                    breadcrumbName: '<<exportFiles.breadcrumbName>>',
                    component: 'ExportFilesView',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ExportFilesContent',
                        },
                    ],
                },
                // 自动取消订单管理
                {
                    path: '/autoCancelOrder',
                    category: '<<systemManage.title>>',
                    breadcrumbName: '<<autoCancelOrder.title>>',
                    component: 'AutoCancelOrder',
                },
                //促销管理
                {
                    path: '/promotion/promotion-macau',
                    component: 'PromotionMacauPage',
                    breadcrumbName: '<<promotionMacau.title>>',
                    category: '<<promotion.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'PromotionMacauList',
                        },
                    ],
                },
                // 导购自定义折扣管理
                {
                    path: '/promotion/guide-shopping-discount',
                    component: 'GuideShoppingDiscountContainer',
                    breadcrumbName: '<<promotion.guideShoppingDiscount.title>>',
                    category: '<<promotion.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'GuideShoppingDiscountPage',
                        },
                    ],
                },
                //coupon
                {
                    path: '/coupon',
                    component: 'CouponContainer',
                    breadcrumbName: '<<coupon.manage>>',
                    category: '<<promotion.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'CouponManagement',
                        },
                    ],
                },
                {
                    path: '/coupon-rule',
                    component: 'CouponRuleContainer',
                    breadcrumbName: '<<couponRule.title>>',
                    category: '<<promotion.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'CouponRuleManagement',
                            isIndex: true,
                        },
                    ],
                },
                {
                    path: '/email-message-management',
                    component: 'EmailMessageContainer',
                    breadcrumbName: '<<emailMessage.title>>',
                    category: '<<systemManage.title>>',
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'EmailMessageInfo',
                            breadcrumbName: '<<emailMessage.view>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'EmailMessagePage',
                            breadcrumbName: '<<emailMessage.edit>>',
                        },
                        {
                            path: '/',
                            component: 'EmailMessageManagement',
                            isIndex: true,
                        },
                    ],
                },

                // 搜索配置 - 搜索词管理
                {
                    path: '/search/search-words',
                    breadcrumbName: '<<designManager.searchWords.title>>',
                    category: '<<designManager.title>>',
                    component: 'SearchWordsManageView',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'SearchWordsManagePage',
                        },
                    ],
                },
                // 搜索配置 - 热词管理
                {
                    path: '/search/hot-words',
                    breadcrumbName: '<<designManager.hotWords.title>>',
                    category: '<<designManager.title>>',
                    component: 'HotWordsManageView',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'HotWordsManagePage',
                        },
                    ],
                },
                // 搜索配置 - 猜你喜欢商品配置
                {
                    path: '/search/guess-you-like-settings',
                    component: 'GuessYouLikeSettingView',
                    breadcrumbName: '<<designManager.guessYouLikeSetting.title>>',
                    category: '<<designManager.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'GuessYouLikeSettingPage',
                        },
                    ],
                },
                //三方对接管理-三方管理
                {
                    path: '/thirdPartyManagement',
                    isIndex: true,
                    component: 'ThirdPartyManagementContainer',
                    breadcrumbName: '<<thirdPartyManagement.title>>',
                    category: '<<thirdParty.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ThirdPartyManagement',
                            isIndex: true,
                        },
                    ],
                },
                //三方对接管理-三方账户信息
                {
                    path: '/thirdPartyAccount',
                    isIndex: true,
                    component: 'ThirdPartyAccountContainer',
                    breadcrumbName: '<<thirdPartyAccount.title>>',
                    category: '<<thirdParty.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ThirdPartyAccount',
                            isIndex: true,
                        },
                    ],
                },
                //三方对接管理-三方接口日志
                {
                    path: '/thirdInterfaceLogs',
                    isIndex: true,
                    component: 'ThirdInterfaceLogsContainer',
                    breadcrumbName: '<<thirdInterfaceLogs.title>>',
                    category: '<<thirdParty.title>>',
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'ThirdInterfaceLogsInfoPage',
                            breadcrumbName: '<<thirdInterfaceLogs.view>>',
                        },
                        {
                            path: '/',
                            component: 'ThirdInterfaceLogsPage',
                        },
                    ],
                },
                //三方对接管理-三方订单入库
                {
                    path: '/third-party-warehouse',
                    category: '<<thirdParty.title>>',
                    breadcrumbName: '<<thirdPartyWarehouse.title>>',
                    component: 'Viewport',
                    childRoutes: [
                        {
                            path: '/:id',
                            breadcrumbName: '<<thirdPartyWarehouse.view>>',
                            component: 'ThirdPartyWarehouseDetailPage',
                        },
                        {
                            path: '/',
                            component: 'ThirdPartyWarehousePage',
                        },
                    ],
                },
                //三方对接管理-三方订单发货
                {
                    path: '/third-party-delivery',
                    category: '<<thirdParty.title>>',
                    breadcrumbName: '<<thirdPartyDelivery.title>>',
                    component: 'Viewport',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ThirdPartyDeliveryPage',
                        },
                    ],
                },
                //三方对接管理-三方库存同步
                {
                    path: '/third-party-sync',
                    category: '<<thirdParty.title>>',
                    breadcrumbName: '<<thirdPartySync.title>>',
                    component: 'Viewport',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ThirdPartySyncPage',
                        },
                    ],
                },
                // OverrideCode 管理
                {
                    path: '/override-code',
                    component: 'OverrideCodeContainer',
                    breadcrumbName: '<<overrideCode.title>>',
                    category: '<<offlineManage.title>>',
                    childRoutes: [
                        {
                            path: '/create',
                            component: 'OverrideCodeCreate',
                            breadcrumbName: '<<overrideCode.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'OverrideCodeEdit',
                            breadcrumbName: '<<edit>>',
                        },
                        {
                            path: '/',
                            component: 'OverrideCodeList',
                        },
                    ],
                },
                // 店员管理
                {
                    path: '/salesclerk',
                    component: 'SalesclerkContainer',
                    breadcrumbName: '<<salesclerk.title>>',
                    category: '<<offlineManage.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'SalesclerkList',
                        },
                    ],
                },
                {
                    path: '/search/home-manage',
                    component: 'HomeManageView',
                    breadcrumbName: '<<routes.HomeManage>>',
                    category: '<<routes.Home>>',
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<routes.HomeManageAdd>>',
                            component: 'HomeManagePageAdd',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<routes.HomeManageEdit>>',
                            component: 'HomeManagePageEdit',
                        },
                        {
                            path: '/',
                            component: 'HomeManagePage',
                        },
                    ],
                },
                {
                    path: '/search/topic-manage',
                    component: 'TopicManageView',
                    breadcrumbName: '<<routes.TopicManage>>',
                    category: '<<routes.Home>>',
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<routes.TopicManageAdd>>',
                            component: 'TopicManagePageAdd',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<routes.TopicManageEdit>>',
                            component: 'TopicManagePageEdit',
                        },
                        {
                            path: '/',
                            component: 'TopicManagePage',
                        },
                    ],
                },
                // 优惠券管理
                {
                    path: '/discount-coupon',
                    component: 'DiscountCouponView',
                    category: '<<couponManage.title>>',
                    breadcrumbName: '<<couponManage.discountCoupon.title>>',
                    childRoutes: [
                        {
                            path: '/data/:id',
                            breadcrumbName: '<<couponManage.discountCoupon.data.title>>',
                            component: 'DiscountCouponDataPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<couponManage.discountCoupon.edit.title>>',
                            component: 'DiscountCouponEditPage',
                        },
                        {
                            path: '/add',
                            breadcrumbName: '<<couponManage.discountCoupon.add.title>>',
                            component: 'DiscountCouponAddPage',
                        },
                        {
                            path: '/',
                            component: 'DiscountCouponPage',
                        },
                    ],
                },
                // 运费券管理
                {
                    path: '/freight-coupon',
                    component: 'FreightCouponView',
                    category: '<<couponManage.title>>',
                    breadcrumbName: '<<couponManage.freightCoupon.title>>',
                    childRoutes: [
                        {
                            path: '/data/:id',
                            breadcrumbName: '<<couponManage.freightCoupon.data.title>>',
                            component: 'FreightCouponDataPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<couponManage.freightCoupon.edit.title>>',
                            component: 'FreightCouponEditPage',
                        },
                        {
                            path: '/add',
                            breadcrumbName: '<<couponManage.freightCoupon.add.title>>',
                            component: 'FreightCouponAddPage',
                        },
                        {
                            path: '/',
                            component: 'FreightCouponPage',
                        },
                    ],
                },
                // 代金券管理
                {
                    path: '/cash-coupon',
                    component: 'CashCouponView',
                    category: '<<couponManage.title>>',
                    breadcrumbName: '<<couponManage.cashCoupon.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'CashCouponPage',
                        },
                    ],
                },
                // 他方券管理
                {
                    path: '/third-party-coupon',
                    component: 'ThirdPartyCouponView',
                    category: '<<couponManage.title>>',
                    breadcrumbName: '<<couponManage.thirdPartyCoupon.title>>',
                    childRoutes: [
                        {
                            path: '/data/:couponId',
                            breadcrumbName: '<<couponManage.thirdPartyCoupon.data.title>>',
                            component: 'ThirdPartyCouponDataPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<couponManage.thirdPartyCoupon.edit.title>>',
                            component: 'ThirdPartyCouponEditPage',
                        },
                        {
                            path: '/add',
                            breadcrumbName: '<<couponManage.thirdPartyCoupon.add.title>>',
                            component: 'ThirdPartyCouponAddPage',
                        },
                        {
                            path: '/',
                            component: 'ThirdPartyCouponPage',
                        },
                    ],
                },
                // 代金券发放管理
                {
                    path: '/voucher-management',
                    component: 'VoucherManagementView',
                    category: '<<couponManage.title>>',
                    breadcrumbName: '<<couponManage.voucherManagement.title>>',
                    childRoutes: [
                        {
                            path: '/data/:id',
                            breadcrumbName: '<<couponManage.voucherManagement.viewData.title>>',
                            component: 'VoucherManagementDataPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<couponManage.voucherManagement.editTitle>>',
                            component: 'VoucherManagementEditPage',
                        },
                        {
                            path: '/add',
                            breadcrumbName: '<<couponManage.voucherManagement.addTitle>>',
                            component: 'VoucherManagementAddPage',
                        },
                        {
                            path: '/',
                            component: 'VoucherManagementPage',
                        },
                    ],
                },
                // 事件营销
                {
                    path: '/sales-promotion/event-marketing',
                    component: 'VoucherManagementView',
                    category: '<<salesPromotion.title>>',
                    breadcrumbName: '<<salesPromotion.eventMarketing.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<salesPromotion.eventMarketing.edit>>',
                            component: 'EventMarketingEditPage',
                        },
                        {
                            path: '/add',
                            breadcrumbName: '<<salesPromotion.eventMarketing.add>>',
                            component: 'EventMarketingAddPage',
                        },
                        {
                            path: '/',
                            component: 'EventMarketingPage',
                        },
                    ],
                },
                // 商品限购管理
                {
                    path: '/product/order-limit-management',
                    component: 'OrderLimitManagementView',
                    category: '<<productManage.title>>',
                    breadcrumbName: '<<orderLimitManagement.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<orderLimitManagement.editTitle>>',
                            component: 'OrderLimitManagementEditPage',
                        },
                        {
                            path: '/add',
                            breadcrumbName: '<<orderLimitManagement.addTitle>>',
                            component: 'OrderLimitManagementAddPage',
                        },
                        {
                            path: '/',
                            component: 'OrderLimitManagementPage',
                        },
                    ],
                },
                // 商品限制管理
                {
                    path: '/product/pickup-limit',
                    component: 'Viewport',
                    category: '<<productManage.title>>',
                    breadcrumbName: '<<productPickupLimit.title>>',
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<productPickupLimit.editTitle>>',
                            component: 'ProductPickupLimitEditPage',
                        },
                        {
                            path: '/',
                            component: 'ProductPickupLimitPage',
                        },
                    ],
                },
                // 报表管理
                {
                    path: '/report',
                    component: 'ReportPage',
                    category: '<<report.title>>',
                    childRoutes: [
                        {
                            path: '/order-shipping',
                            category: '<<report.title>>',
                            breadcrumbName: '<<report.orderShipping.title>>',
                            component: 'OrderShippingPage',
                        },
                        {
                            path: '/order-summary',
                            category: '<<report.title>>',
                            breadcrumbName: '<<report.orderSummary.title>>',
                            component: 'OrderSummaryPage',
                        },
                        {
                            path: '/order-detail',
                            category: '<<report.title>>',
                            breadcrumbName: '<<report.orderDetail.title>>',
                            component: 'OrderDetailPage',
                        },
                        {
                            path: '/order-detail-unprice',
                            category: '<<report.title>>',
                            breadcrumbName: '<<report.orderDetailUnPrice.title>>',
                            component: 'OrderDetailUnPriceReport',
                        },
                        {
                            path: '/order-refund',
                            category: '<<report.title>>',
                            breadcrumbName: '<<report.refundSummary.title>>',
                            component: 'RefundSummaryPage',
                        },
                        {
                            path: '/voucher',
                            category: '<<report.title>>',
                            breadcrumbName: '<<report.voucher.title>>',
                            component: 'VoucherReportPage',
                        },
                        {
                            path: '/product-map',
                            category: '<<report.title>>',
                            breadcrumbName: '<<report.productMap.title>>',
                            component: 'ProductMapReportPage',
                        },
                    ],
                },
                // 商品评价管理
                {
                    path: '/public-opinion/commodity-evaluation',
                    component: 'commodityEvaluationContainer',
                    category: '<<publicOpinion.title>>',
                    breadcrumbName: '<<commodityEvaluation.title>>',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'commodityEvaluationPage',
                        },
                    ],
                },
                // 飞利浦订单管理
                {
                    path: '/orders-philips',
                    component: 'PhilipsOrdersContainer',
                    breadcrumbName: '<<philipsOrder.subTitle>>',
                    category: '<<philipsOrder.title>>',
                    childRoutes: [
                        {
                            path: '/info/:orderNo',
                            component: 'PhilipsOrderInfo',
                            breadcrumbName: '<<orderInfo.title>>',
                        },
                        {
                            path: '/:orderStatus',
                            component: 'PhilipsOrdersManagement',
                        },
                        {
                            path: '/',
                            component: 'PhilipsOrdersManagement',
                            isIndex: true,
                        },
                    ],
                },
                // 飞利浦拣货管理-飞利浦拣货小助手
                {
                    path: '/picking-assistant-philips',
                    component: 'PickingAssistantContainer',
                    category: '<<pickingPhilips.title>>',
                    breadcrumbName: '<<pickingPhilips.subTitle>>',
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'PhilipsPickingAssistantDetailPage',
                            breadcrumbName: '<<pickingPhilips.subTitle>>',
                        },
                        {
                            path: '/:status',
                            component: 'PhilipsPickingAssistantPage',
                        },
                        {
                            path: '/',
                            component: 'PhilipsPickingAssistantPage',
                        },
                    ],
                },
                // 营促销管理
                {
                    path: '/sales-promotion/sales-multi-promotion',
                    component: 'SalesMultiPromotionView',
                    category: '<<salesPromotion.title>>',
                    breadcrumbName: '<<salesPromotion.sub.singleMultipleProduct>>',
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<salesPromotion.sub.add>>',
                            component: 'SalesMultiPromotionAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<salesPromotion.sub.edit>>',
                            component: 'SalesMultiPromotionModifyPage',
                        },
                        {
                            path: '/',
                            component: 'SalesMultiPromotionPage',
                        },
                    ],
                },
                // 菜单栏管理
                {
                    path: '/search/menu-bar',
                    breadcrumbName: '<<routes.menuBarManagementView>>',
                    category: '<<designManager.title>>',
                    component: 'MenuBarManagementView',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'MenuBarManagementPage',
                        },
                    ],
                },
                // 通知栏管理
                {
                    path: '/search/notice',
                    breadcrumbName: '<<routes.noticeBarManagement>>',
                    category: '<<designManager.title>>',
                    component: 'NoticeBarManagementView',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'NoticeBarManagementPage',
                        },
                    ],
                },
                // 推荐品牌管理
                {
                    path: '/search/recommended-brand',
                    breadcrumbName: '<<routes.recommendedBrand>>',
                    category: '<<designManager.title>>',
                    component: 'RecommendedBrandView',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'RecommendedBrandPage',
                        },
                    ],
                },
                // 消息通知管理
                {
                    path: '/site-messages',
                    category: '<<notification.title>>',
                    breadcrumbName: '<<siteMessages.title>>',
                    component: 'Viewport',
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<siteMessages.add>>',
                            component: 'SiteMessagesAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<siteMessages.edit>>',
                            component: 'SiteMessagesEditPage',
                        },
                        {
                            path: '/',
                            component: 'SiteMessagesPage',
                        },
                    ],
                },

                // 弹窗广告管理
                {
                    path: '/popup-ads',
                    breadcrumbName: '<<popupAds.title>>',
                    category: '<<designManager.title>>',
                    component: 'Viewport',
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<popupAds.add>>',
                            component: 'PopupAdsAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<popupAds.edit>>',
                            component: 'PopupAdsEditPage',
                        },
                        {
                            path: '/',
                            component: 'PopupAdsPage',
                        },
                    ],
                },

                // urlMapping管理
                {
                    path: '/system/url-mapping',
                    breadcrumbName: '<<systemManagement.urlMapping.title>>',
                    category: '<<systemManagement.title>>',
                    component: 'UrlMappingManagementContainer',
                    childRoutes: [
                        {
                            path: '/:id',
                            breadcrumbName: '<<systemManagement.urlMapping.view>>',
                            component: 'UrlMappingList',
                        },
                        {
                            path: '/',
                            component: 'UrlMappingManagement',
                        },
                    ],
                },

                // 支付方式管理
                {
                    path: '/system/payment-mode',
                    breadcrumbName: '<<systemManagement.paymentMode.title>>',
                    category: '<<systemManagement.title>>',
                    component: 'Viewport',
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<systemManagement.paymentMode.add>>',
                            component: 'PaymentModeAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<systemManagement.paymentMode.edit>>',
                            component: 'PaymentModeEditPage',
                        },
                        {
                            path: '/',
                            component: 'PaymentModePage',
                        },
                    ],
                },
                // 自提收货
                {
                    path: '/order/self-harvesting',
                    component: 'SelfHarvestingContainer',
                    childRoutes: [
                        {
                            path: '/',
                            component: 'SelfHarvestingManagement',
                        },
                    ],
                },
            ],
        },
    ],
} as Config;

import React, { useState } from 'react';
import { Modal, Input, message } from 'antd';
import { Button } from '@comall-backend-builder/components-basis';
import { language, errorHandle, api } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';

function changePasswordApi(id: string, params: KV) {
    return api.put(params, {
        apiRoot: ENV.API_ROOT,
        apiPath: `/dc-user/guide_shopping/sa/${id}`,
    });
}

/**
 * SA账号管理 - 修改密码按钮
 */
export function TableActionChangeSaPassword(props: KV) {
    const { className, row } = props;
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
        value = value.replace(/\s*/g, ''); // 移除空格
        setPassword(value);
    };

    const confirmHandler = async () => {
        if (!password) {
            return message.error(language.getText('sa.newPasswordPlaceholder'));
        }
        // password check
        if (!/^[0-9a-zA-Z]{0,6}$/.test(password)) {
            return message.error(language.getText('sa.errorMsg.password'));
        }
        try {
            setLoading(true);
            await changePasswordApi(row.id, { password });
            setPassword('');
            setVisible(false);
            message.success(language.getText('sa.changePasswordSuccess'));
        } catch (e) {
            errorHandle(e as ErrorInfo);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button className={className} type='link' onClick={() => setVisible(true)}>
                {language.getText('sa.changePassword')}
            </Button>
            <Modal
                title={language.getText('sa.changePassword')}
                visible={visible}
                onOk={confirmHandler}
                onCancel={() => setVisible(false)}
                confirmLoading={loading}
            >
                <Input.Password
                    placeholder={language.getText('sa.newPasswordPlaceholder')}
                    value={password}
                    maxLength={20}
                    onChange={changeHandler}
                />
            </Modal>
        </>
    );
}

import { cloneDeep } from 'lodash';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, flexibleFilterForm } from '../mixin';
import {
    MERCHANT_DISTRIBUTION_RULES_TABLE_PICKUP,
    MERCHANT_DISTRIBUTION_RULES_TABLE_COURIER,
    MERCHANT_PICKUPATHONE_RULES_TABLE_COURIER,
} from '@/configs/pro-table-constants';
const entityProperties = {
    merchantName: {
        type: 'string',
        displayName: '<<merchantFreightRules.merchantName>>',
    },
    merchantId: {
        type: 'string',
        displayName: '<<merchantFreightRules.merchantId>>',
    },
};

const entityFilters = {
    merchantName: {
        type: 'string',
        displayName: '<<merchantFreightRules.merchantName>>',
        controlConfig: {
            allowClear: true,
        },
    },
};

const editPath = (type: 'distribution' | 'courier' | 'pickUpAtHome') => {
    switch (type) {
        case 'distribution':
            return `/merchantFreightRules/edit/2/{{row.merchantId}}/2`;
        case 'courier':
            return `/merchantFreightRules/edit/2/{{row.merchantId}}/3`;
        case 'pickUpAtHome':
            return `/merchantFreightRules/edit/2/{{row.merchantId}}/1`;
    }
};

const tableConfig = (componentName: string, type: 'distribution' | 'courier' | 'pickUpAtHome') => {
    return {
        ...tableMixin,
        componentName,
        component: 'ProTable',
        rowKey: 'merchantId',
        scroll: {
            x: 'max-content',
        },
        columns: [{ property: 'merchantName' }, { property: 'merchantId' }],
        actionColumn: {
            title: '<<actions>>',
            width: 100,
            fixed: 'right',
            items: [
                {
                    text: '<<edit>>',
                    type: 'link',
                    path: editPath(type),
                    privilege: {
                        path: 'delivery.merchant_goods_freight.edit',
                        level: 'full',
                    },
                },
            ],
        },
    };
};

export const config: Config = {
    entities: {
        merchantPickUpAtHomeList: {
            apiPath:
                '/dc-goods/admin/store/freight/merchant/findFreightList?roleType=2&freightType=1',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
        merchantPickUpList: {
            apiPath:
                '/dc-goods/admin/store/freight/merchant/findFreightList?roleType=2&freightType=2',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
        merchantCourierList: {
            apiPath:
                '/dc-goods/admin/store/freight/merchant/findFreightList?roleType=2&freightType=3',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
    },
    components: {
        MerchantFreightRulesContainer: {
            component: 'Viewport',
        },
        MerchantFreightRulesManagement: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [{ component: 'MerchantFreightRulesTabs' }],
            },
        },
        MerchantFreightRulesTabs: {
            component: 'HistoryTabs',
            destroyInactiveTabPane: true,
            items: [
                {
                    title: '<<storeFreightRules.pickUpAtHome>>',
                    content: {
                        component: 'MerchantFreightRulesTabPickUpAtHome',
                    },
                },
                {
                    title: '<<storeFreightRules.distribution>>',
                    content: {
                        component: 'MerchantFreightRulesTabPickUp',
                    },
                },
                {
                    title: '<<storeFreightRules.courier>>',
                    content: {
                        component: 'MerchantFreightRulesTabCourier',
                    },
                },
            ],
        },
        MerchantFreightRulesTabPickUp: {
            entity: 'merchantPickUpList',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MerchantFreightRulesFilter',
                    privilege: {
                        path: 'delivery.merchant_goods_freight.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'Button',
                    text: '<<add>>',
                    type: 'primary',
                    style: { width: 'fit-content' },
                    route: `/merchantFreightRules/add/distribution`,
                    privilege: {
                        path: 'delivery.merchant_goods_freight.add',
                        level: 'full',
                    },
                },
                {
                    component: 'MerchantFreightRulesTablePickUp',
                    privilege: {
                        path: 'delivery.merchant_goods_freight.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        MerchantFreightRulesTabCourier: {
            entity: 'merchantCourierList',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MerchantFreightRulesFilter',
                    privilege: {
                        path: 'delivery.merchant_goods_freight.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'Button',
                    text: '<<add>>',
                    type: 'primary',
                    style: { width: 'fit-content' },
                    route: `/merchantFreightRules/add/courier`,
                    privilege: {
                        path: 'delivery.merchant_goods_freight.add',
                        level: 'full',
                    },
                },
                {
                    component: 'MerchantFreightRulesCourier',
                    privilege: {
                        path: 'delivery.merchant_goods_freight.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        MerchantFreightRulesTabPickUpAtHome: {
            entity: 'merchantPickUpAtHomeList',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MerchantFreightRulesFilter',
                    privilege: {
                        path: 'delivery.merchant_goods_freight.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'Button',
                    text: '<<add>>',
                    type: 'primary',
                    style: { width: 'fit-content' },
                    route: `/merchantFreightRules/add/pickup`,
                    privilege: {
                        path: 'delivery.merchant_goods_freight.add',
                        level: 'full',
                    },
                },
                {
                    component: 'MerchantFreightPickUpAtHome',
                    privilege: {
                        path: 'delivery.merchant_goods_freight.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        MerchantFreightRulesFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            simple: {
                fieldCol: {
                    span: 8,
                },
                fields: ['merchantName'],
            },
            reset: true,
            fields: [
                {
                    property: 'merchantName',
                    controlConfig: {
                        allowClear: true,
                    },
                },
            ],
        },
        MerchantFreightRulesTablePickUp: tableConfig(
            MERCHANT_DISTRIBUTION_RULES_TABLE_PICKUP,
            'distribution'
        ),
        MerchantFreightRulesCourier: tableConfig(
            MERCHANT_DISTRIBUTION_RULES_TABLE_COURIER,
            'courier'
        ),
        MerchantFreightPickUpAtHome: tableConfig(
            MERCHANT_PICKUPATHONE_RULES_TABLE_COURIER,
            'pickUpAtHome'
        ),
    },
};

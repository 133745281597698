import { services } from '@comall-backend-builder/core';
import React, { useState, useEffect, useMemo } from 'react';

import {
    Form as AntForm,
    Cascader,
    DatePicker,
    Radio,
    Input as AntInput,
    InputNumber,
    Button,
    Select,
} from 'antd';
import moment from 'moment';
import { isEmpty, map } from 'lodash-es';
import { OrderInfoProductProps } from '.';
import { language } from '@comall-backend-builder/core/lib/services';
import { disabledInputNotNumber } from '@/services/utils';
import SelectAddressModal, { AddressItem } from './select-address-modal';

import './index.less';

const deliveryTypeOptions = [
    { label: language.getText('offlineOrder.create.appointmentInStore'), value: 0 },
    { label: language.getText('offlineOrder.create.homeDelivery'), value: 1 },
];

export default function AddressForm(props: OrderInfoProductProps) {
    const { activeKey, memberInfo, checkoutData = {} } = props;
    const { getFieldDecorator, getFieldValue, setFields } = props.form;
    const [residences, setResidences] = useState([]);
    const [showAddressSelect, setShowAddressSelect] = useState(false);
    const defaultedAddress = checkoutData.defaultedAddress || {};
    const consigneeInfo = checkoutData.consigneeInfo || {};
    const checkoutMerchant = checkoutData.checkoutMerchant || {};

    const pickupDatesMap = useMemo(() => {
        const map: AnyObject = {};
        checkoutMerchant?.pickupTimes
            ?.filter((item: AnyObject) => item.startTime && item.endTime)
            .forEach((item: any) => {
                map[item.date] = item;
            });
        return map;
    }, [checkoutMerchant]);
    const pickUpDate = useMemo(() => {
        const date = getFieldValue('pickUpDate');
        if (date) {
            return pickupDatesMap[moment(date).format('YYYY-MM-DD')];
        } else {
            return pickupDatesMap[moment().format('YYYY-MM-DD')];
        }
    }, [pickupDatesMap, getFieldValue('pickUpDate')]);

    const deliveryDatesMap = useMemo(() => {
        const map: AnyObject = {};
        checkoutMerchant.deliveryTimes
            ?.filter((item: AnyObject) => item.startTime && item.endTime)
            .forEach((item: any) => {
                map[item.date] = item;
            });
        return map;
    }, [checkoutMerchant]);
    const deliveryDate = useMemo(() => {
        const date = getFieldValue('deliveryDate');
        if (date) {
            return deliveryDatesMap[moment(date).format('YYYY-MM-DD')];
        } else {
            return deliveryDatesMap[moment().format('YYYY-MM-DD')];
        }
    }, [deliveryDatesMap, getFieldValue('deliveryDate')]);

    /**
     * 设置默认值: 店内取货-取货日期,运费,自取店铺
     *           送货上门-送货日期,运费
     */
    useEffect(() => {
        if (activeKey === 2) {
            const deliveryType = getFieldValue('deliveryType');
            const pickUpDate = getFieldValue('pickUpDate');
            if (!pickUpDate) {
                setFields({
                    pickUpDate: {
                        value: moment(),
                    },
                });
            }

            if (!isEmpty(checkoutData) && !isEmpty(checkoutData?.checkoutMerchants)) {
                checkoutData?.checkoutMerchants?.forEach((item: any) => {
                    const freightFeeAmount = getFieldValue(`freightFeeAmount-${item.merchantId}`);
                    if (!freightFeeAmount) {
                        setFields({
                            [`freightFeeAmount-${item.merchantId}`]: { value: 0 },
                        });
                    }
                });
            }

            const deliveryDate = getFieldValue('deliveryDate');
            if (!deliveryDate && deliveryType === 1) {
                setFields({
                    deliveryDate: {
                        value: moment(),
                    },
                });
            }

            if (!isEmpty(checkoutData) && !isEmpty(checkoutData?.pickOnlineStores)) {
                const pickStore = getFieldValue('pickStoreId');
                if (!pickStore) {
                    setFields({
                        pickStoreId: { value: checkoutData?.pickOnlineStores[0].pickStoreId },
                    });
                }
            }
        }
    }, [activeKey, getFieldValue('deliveryType')]);

    // 初始化用户信息
    useEffect(() => {
        if (getFieldValue('deliveryType') === 1) {
            handelAddressSelect(defaultedAddress);
        } else {
            const { gender, name, lastName, mobile } = consigneeInfo;
            setFields({
                user: {
                    value: {
                        gender: gender ?? 1,
                        firstName: name,
                        lastName,
                    },
                },
                mobile: {
                    value: mobile,
                },
            });
        }
    }, [
        getFieldValue('deliveryType'),
        JSON.stringify(defaultedAddress),
        JSON.stringify(consigneeInfo),
    ]);

    useEffect(() => {
        services.api
            .get(
                {},
                {
                    apiPath: `/dc-goods/admin/store/freight/getDeliveryRegion`,
                }
            )
            .then((res: any) => {
                const list = res.region[0].children.map((item: any) => ({
                    value: String(item.id),
                    label: item.name,
                    children: item.children.flatMap((item: any) =>
                        item.children.map((item: any) => ({
                            ...item,
                            value: String(item.id),
                            label: item.name,
                        }))
                    ),
                }));
                setResidences(list);
            })
            .catch(services.errorHandle);
    }, []);

    function handelAddressSelect(item: AddressItem) {
        const {
            gender,
            mobile,
            firstName,
            lastName,
            parentRegionId,
            regionId,
            childRegionId,
            streetAddress,
            houseNumber,
        } = item;
        setFields({
            user: {
                value: {
                    gender: gender ?? 1,
                    firstName,
                    lastName,
                },
            },
            mobile: {
                value: mobile,
            },
            regionId: {
                value: regionId,
            },
            area: {
                value: [parentRegionId, childRegionId],
            },
            streetAddress: {
                value: streetAddress,
            },
            houseNumber: {
                value: houseNumber,
            },
        });
        setShowAddressSelect(false);
    }

    useEffect(() => {
        if (!pickUpDate) {
            setFields({
                pickUpDate: {
                    value: null,
                },
            });
        }
    }, [pickUpDate]);

    useEffect(() => {
        if (!deliveryDate) {
            setFields({
                deliveryDate: {
                    value: null,
                },
            });
        }
    }, [deliveryDate]);

    return (
        <div style={{ display: activeKey === 2 ? 'block' : 'none' }}>
            <AntForm.Item label={language.getText('offlineOrder.create.selectDeliveryType')}>
                {getFieldDecorator('deliveryType', {
                    initialValue: 0,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                })(<Radio.Group options={deliveryTypeOptions} />)}
            </AntForm.Item>
            {/* 收货人姓名 */}
            <AntForm.Item label={language.getText('offlineOrder.create.pickUpName')}>
                {getFieldDecorator('user', {
                    rules: [
                        {
                            required: true,
                        },
                        {
                            validator: (_: never, value: any, callback: any) => {
                                if (!value?.lastName?.trim()) {
                                    callback(language.getText('offlineOrder.tips.lastName'));
                                    return;
                                }
                                if (!value?.firstName?.trim()) {
                                    callback(language.getText('offlineOrder.tips.firstName'));
                                    return;
                                }

                                callback();
                            },
                        },
                    ],
                })(
                    <UserInput
                        showSelectButton={
                            !!(getFieldValue('deliveryType') === 1 && memberInfo?.phone)
                        }
                        onSelectButtonClick={() => {
                            setShowAddressSelect(true);
                        }}
                    />
                )}
            </AntForm.Item>
            {/* 收货人联系方式 */}
            <AntForm.Item label={language.getText('offlineOrder.create.pickUpPhone')}>
                {getFieldDecorator('mobile', {
                    rules: [
                        {
                            required: true,
                            message: language.getText('offlineOrder.tips.pickUpPhone'),
                        },
                        {
                            validator: (_: never, value: any, callback: any) => {
                                if (String(value).length !== 8) {
                                    callback(
                                        language.getText('offlineOrder.tips.pickUpPhoneLength')
                                    );

                                    return;
                                }

                                callback();
                            },
                        },
                    ],
                })(
                    <AntInput
                        style={{
                            imeMode: 'disabled',
                        }}
                        placeholder={language.getText('defaultPlaceholderInput')}
                        maxLength={8}
                        onKeyDown={disabledInputNotNumber}
                        onChange={(e) => {
                            const input = e.nativeEvent.target as HTMLInputElement;
                            setTimeout(() => {
                                input.value = input.value?.replace(/[^0-9]/g, '').slice(0, 8);
                                setFields({
                                    mobile: {
                                        value: input.value,
                                    },
                                });
                            });
                        }}
                    />
                )}
            </AntForm.Item>
            {getFieldValue('deliveryType') !== 1 ? (
                <>
                    {/* 自取门店 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.pickingStore')}>
                        {getFieldDecorator('pickStoreId', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.pickingStore'),
                                },
                            ],
                        })(
                            <Select
                                allowClear
                                placeholder={services.language.getText('defaultPlaceholderSelect')}
                                onChange={(_, option: AnyObject) => {
                                    setFields({
                                        pickOnlineStoreId: {
                                            value: option?.key,
                                        },
                                    });
                                }}
                            >
                                {map(checkoutData?.pickOnlineStores, (item) => (
                                    <Select.Option key={item.id} value={item.pickStoreId}>
                                        {item.pickStoreName}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </AntForm.Item>
                    {/* 取货日期 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.pickingDate')}>
                        {getFieldDecorator('pickUpDate', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.pickingDate'),
                                },
                            ],
                        })(
                            <DatePicker
                                style={{ width: 600 }}
                                disabled={getFieldValue('deliveryType') === 1}
                                placeholder={services.language.getText('order.pickDate')}
                                format={
                                    pickUpDate
                                        ? `YYYY-MM-DD ${pickUpDate.startTime}~${pickUpDate.endTime}`
                                        : ''
                                }
                                disabledDate={(date) =>
                                    !pickupDatesMap[moment(date).format('YYYY-MM-DD')]
                                }
                            />
                        )}
                    </AntForm.Item>
                </>
            ) : null}
            {getFieldValue('deliveryType') === 1 ? (
                <>
                    {/* 送货区域 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.deliveryArea')}>
                        {getFieldDecorator('area', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.deliveryArea'),
                                },
                                {
                                    validator: (_: never, value: any, callback: any) => {
                                        if (!value || !value[0] || !value[1]) {
                                            callback(
                                                language.getText('offlineOrder.tips.deliveryArea')
                                            );
                                            return;
                                        }

                                        callback();
                                    },
                                },
                            ],
                        })(
                            <Cascader
                                options={residences}
                                allowClear={false}
                                expandTrigger='hover'
                                onChange={(_, items) => {
                                    setFields({
                                        regionId: {
                                            value: items?.[1]?.parentId,
                                        },
                                    });
                                }}
                            />
                        )}
                    </AntForm.Item>
                    {/* 二级地址占位，缺少改占位组将将无法设置表单的 regionId 字段*/}
                    <AntForm.Item className='hidden'>
                        {getFieldDecorator('regionId')(<AntInput />)}
                    </AntForm.Item>
                    {/* 详细地址 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.streetAddress')}>
                        {getFieldDecorator('streetAddress', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.streetAddress'),
                                },
                                {
                                    validator: (_: never, value: any, callback: any) => {
                                        if (!value?.trim()) {
                                            callback(
                                                language.getText('offlineOrder.tips.streetAddress')
                                            );
                                            return;
                                        }

                                        callback();
                                    },
                                },
                            ],
                        })(
                            <AntInput
                                placeholder={language.getText('defaultPlaceholderInput')}
                                maxLength={200}
                            />
                        )}
                    </AntForm.Item>
                    {/* 楼层/单位 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.houseNumber')}>
                        {getFieldDecorator('houseNumber', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.houseNumber'),
                                },
                                {
                                    validator: (_: never, value: any, callback: any) => {
                                        if (!value?.trim()) {
                                            callback(
                                                language.getText('offlineOrder.tips.houseNumber')
                                            );
                                            return;
                                        }

                                        callback();
                                    },
                                },
                            ],
                        })(
                            <AntInput
                                placeholder={language.getText('defaultPlaceholderInput')}
                                maxLength={200}
                            />
                        )}
                    </AntForm.Item>
                    {/* 送货日期 */}
                    <AntForm.Item label={language.getText('offlineOrder.create.deliveryDate')}>
                        {getFieldDecorator('deliveryDate', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.deliveryDate'),
                                },
                            ],
                        })(
                            <DatePicker
                                style={{ width: 600 }}
                                placeholder={services.language.getText('order.pickDate')}
                                format={
                                    deliveryDate
                                        ? `YYYY-MM-DD ${deliveryDate.startTime}~${deliveryDate.endTime}`
                                        : ''
                                }
                                disabledDate={(date) =>
                                    !deliveryDatesMap[moment(date).format('YYYY-MM-DD')]
                                }
                            />
                        )}
                    </AntForm.Item>
                </>
            ) : null}

            {/* 运费 */}

            {checkoutData?.checkoutMerchants?.map((item: any) => {
                return (
                    <AntForm.Item
                        key={item.merchantId}
                        label={language.getText('freight') + `(${item.merchantName})`}
                    >
                        {getFieldDecorator(`freightFeeAmount-${item.merchantId}`, {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('offlineOrder.tips.freight'),
                                },
                            ],
                        })(
                            <InputNumber
                                placeholder={services.language.getText(
                                    'offlineOrder.placeholder.payAccount'
                                )}
                                type='number'
                                min={0}
                                step={0.01}
                                precision={2}
                                max={99999999}
                            />
                        )}
                    </AntForm.Item>
                );
            })}

            {/* 备注 */}
            <AntForm.Item label={language.getText('picking.remark')}>
                {getFieldDecorator('remark')(
                    <AntInput.TextArea
                        placeholder={services.language.getText(
                            'offlineOrder.placeholder.payAccount'
                        )}
                        maxLength={1000}
                    />
                )}
            </AntForm.Item>
            <SelectAddressModal
                {...props}
                handleSelect={handelAddressSelect}
                visible={showAddressSelect}
                handleCancel={() => {
                    setShowAddressSelect(false);
                }}
            />
        </div>
    );
}

export interface UserInputValue {
    gender: number;
    lastName: string;
    firstName: string;
}
const UserInput: React.FC<{
    value?: UserInputValue;
    showSelectButton?: boolean;
    onSelectButtonClick?(): void;
    onChange?(value: UserInputValue): void;
}> = ({ value, showSelectButton, onChange, onSelectButtonClick }) => {
    const genders = [
        { id: 0, name: language.getText('offlineOrder.create.gender.0') },
        { id: 1, name: language.getText('offlineOrder.create.gender.1') },
    ];

    return (
        <>
            <div className='user-input'>
                <Select
                    value={value?.gender}
                    onChange={(gender: number) => {
                        onChange?.(
                            Object.assign({}, value, {
                                gender,
                            })
                        );
                    }}
                >
                    {genders.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
                <AntInput
                    className='user-input-center'
                    value={value?.lastName}
                    placeholder={language.getText('offlineOrder.tips.lastName')}
                    maxLength={20}
                    onChange={(e) => {
                        onChange?.(
                            Object.assign({}, value, {
                                lastName: e.target.value,
                            })
                        );
                    }}
                />
                <AntInput
                    value={value?.firstName}
                    placeholder={language.getText('offlineOrder.tips.firstName')}
                    maxLength={20}
                    onChange={(e) => {
                        onChange?.(
                            Object.assign({}, value, {
                                firstName: e.target.value,
                            })
                        );
                    }}
                />
            </div>
            {showSelectButton && (
                <Button type='link' onClick={onSelectButtonClick}>
                    {language.getText('offlineOrder.create.selectMemberAddress')}
                </Button>
            )}
        </>
    );
};

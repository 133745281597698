import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '@/configs/mixin';
import { EXPORT_STATUS_OPTIONS } from '@/constants';

export const config: Config = {
    entities: {
        exportFilesList: {
            apiPath: '/loader/dc-data-export/admin/export/records',
            properties: {
                fileName: {
                    type: 'string',
                },
                createTime: {
                    type: 'string.dateTime',
                },
                status: {
                    type: 'string.status',
                },
                id: { type: 'string' },
            },
        },
    },
    components: {
        ExportFilesView: {
            component: 'Viewport',
            entity: 'exportFilesList',
        },
        ExportFilesContent: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'ExportFilesTable' }],
            privilege: {
                path: 'log.data_export.view_list',
                level: 'full',
            },
        },
        ExportFilesTable: {
            ...tableMixin,
            showHeader: true,
            loadFirstPage: true,
            columns: [
                { property: 'fileName', title: '<<exportFiles.fileName>>' },
                { property: 'createTime', title: '<<exportFiles.createTime>>' },
                {
                    title: '<<exportFiles.exportStatus>>',
                    property: 'status',
                    displayConfig: {
                        statusConfig: EXPORT_STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'DownloadButton',
                        privilege: {
                            path: 'log.data_export.download',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        DownloadButton: {
            component: 'RecordFileExportButton',
            text: '<<download>>',
            source: {
                apiPath: '/dc-data-export/admin/export/records/{{row.id}}/download',
            },
        },
    },
};

import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/admin/advertisements',
        loader: {
            get: async function(_data: any, config: any = {}) {
                const { id, ...data } = _data;
                if (id) {
                    const {
                        displayStartTime,
                        displayEndTime,
                        onlineStoreId,
                        sendFrequency,
                        ...result
                    } = await services.api.get<AnyObject>(data, {
                        ...config,
                        apiPath: `/dc-cms-api/admin/advertisements/${id}`,
                    });
                    return {
                        ...result,
                        displayTime: {
                            start: displayStartTime,
                            end: displayEndTime,
                        },
                        onlineStoreId: String(onlineStoreId),
                        sendFrequency: String(sendFrequency),
                    };
                }
                return services.api.get(data, {
                    ...config,
                    apiPath: '/dc-cms-api/admin/advertisements',
                });
            },
            post: async function(data: any, config: any = {}) {
                data.displayStartTime = data.displayTime.start;
                data.displayEndTime = data.displayTime.end;
                data.displayTime = undefined;
                return services.api.post(data, {
                    ...config,
                    apiPath: '/dc-cms-api/admin/advertisements',
                });
            },
            put: async function(data: any, config: any = {}) {
                data.displayStartTime = data.displayTime.start;
                data.displayEndTime = data.displayTime.end;
                data.displayTime = undefined;
                return services.api.put(data, {
                    ...config,
                    apiPath: `/dc-cms-api/admin/advertisements/${data.id}`,
                });
            },
        },
    },
];

import React, { Component } from 'react';
import { language } from '@comall-backend-builder/core/lib/services';
import OtpInput from 'react-otp-input';

interface IProps {
    getValue(value?: string): void;
}

interface IState {
    otp?: string;
}

export default class AccessModal extends Component<IProps, IState> {
    state: IState = { otp: undefined };
    handleChange = (otp: string | undefined) => {
        this.props.getValue(otp);
        return this.setState({ otp });
    };
    render() {
        return (
            <div className='authorization-modal'>
                <div className='authorization-modal-title'>
                    {language.getText('offlineOrder.confirmAuthorization')}
                </div>
                <div className='authorization-modal-content'>
                    <OtpInput
                        shouldAutoFocus
                        value={this.state.otp}
                        onChange={this.handleChange}
                        numInputs={4}
                        separator={<span> </span>}
                    />
                </div>
            </div>
        );
    }
}

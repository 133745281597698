import { services } from '@comall-backend-builder/core/lib';

interface Params {
    id: string;
    /** 通过:1, 不通过: 2 */
    status?: '1' | '2';
}

export default (params: Params) =>
    new Promise((resolve, reject) => {
        const { id, status } = params;
        services.api
            .put(
                { id, status },
                {
                    apiPath: `/dc-goods/admin/sku_evaluation/${id}/status`,
                }
            )
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                services.errorHandle(error as any);
                reject();
            });
    });

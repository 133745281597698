import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';

export const config: Config = {
    entities: {
        voucherManagementData: {
            apiPath: '/loader/dc-master-data/admin/voucherGrant/member/datas',
            properties: {
                couponCode: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.couponCode>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.mobile>>',
                },
                totalAmount: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.totalAmount>>',
                },
                balance: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.balance>>',
                },
                effectStateTime: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.effectStateTime>>',
                },
                effectEndTime: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.effectEndTime>>',
                },
            },
            filters: {
                couponCode: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.couponCode>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.viewData.mobile>>',
                },
            },
        },
    },
    components: {
        VoucherManagementDataPage: {
            entity: 'voucherManagementData',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'couponCode',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                        {
                            property: 'mobile',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                    ],
                },
                {
                    component: 'VoucherManagementDataTable',
                },
            ]),
        },
        VoucherManagementDataTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'couponCode',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'totalAmount',
                },
                {
                    property: 'balance',
                },
                {
                    property: 'effectStateTime',
                },
                {
                    property: 'effectEndTime',
                },
            ],
        },
    },
};

import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '@/configs/mixin';

export const config: Config = {
    entities: {
        thirdInterfaceLogs: {
            apiPath: '/loader/dc-master-data/admin/channel/log',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.id>>',
                },
                modelName: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.modelName>>',
                },
                ip: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.ip>>',
                },
                channel: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.channel>>',
                },
                httpStatus: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.httpStatus>>',
                },
                message: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.message>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.createTime>>',
                },
                requestData: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.requestData>>',
                },
                responseData: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.responseData>>',
                },
                headerStr: {
                    type: 'string',
                    displayName: '<<thirdInterfaceLogs.headerStr>>',
                },
            },
            filters: {
                channel: {
                    type: 'string.options.autoComplete',
                    displayName: '<<thirdInterfaceLogs.channel>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-master-data/admin/channel/log/channelList',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        ThirdInterfaceLogsContainer: {
            component: 'Viewport',
        },
        ThirdInterfaceLogsPage: {
            component: 'Card',
            entity: 'thirdInterfaceLogs',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    { component: 'ThirdInterfaceLogsFilter' },
                    {
                        component: 'ThirdInterfaceLogsTable',
                    },
                ],
                privilege: {
                    path: 'channel.channel_log_manage.view_list',
                    level: 'full',
                },
            },
        },
        ThirdInterfaceLogsFilter: {
            component: 'FilterFormPlus',
            style: {
                marginBottom: 10,
            },
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            fields: [{ property: 'channel' }],
        },
        ThirdInterfaceLogsTable: {
            ...tableMixin,
            component: 'DataTable',
            scroll: {
                x: 'max-content',
            },
            columns: [
                { property: 'id' },
                { property: 'modelName' },
                { property: 'ip' },
                { property: 'channel' },
                {
                    property: 'httpStatus',
                },
                { property: 'message' },
                { property: 'createTime' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        text: '<<thirdInterfaceLogs.view>>',
                        type: 'link',
                        path: '/thirdInterfaceLogs/detail/{{row.id}}',
                        privilege: {
                            path: 'channel.channel_log_manage.edit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ThirdInterfaceLogsInfoPage: {
            component: 'FlexLayout',
            entity: 'thirdInterfaceLogs',
            loaderType: 'get',
            direction: 'vertical',
            items: [
                {
                    component: 'ThirdInterfaceLogsInfo',
                },
            ],
        },
        ThirdInterfaceLogsInfo: {
            component: 'ModifyFormPlus',
            fields: [
                {
                    property: 'id',
                    modifiable: false,
                },
                {
                    property: 'headerStr',
                    modifiable: false,
                    displayConfig: {
                        style: {
                            wordBreak: 'break-all',
                            maxHeight: '6.5em',
                            overflow: 'auto',
                            display: 'block',
                            lineHeight: '1.5em',
                        },
                    },
                },
                {
                    property: 'message',
                    modifiable: false,
                    displayConfig: {
                        style: {
                            wordBreak: 'break-all',
                            maxHeight: '6.5em',
                            overflow: 'auto',
                            display: 'block',
                            lineHeight: '1.5em',
                        },
                    },
                },
                {
                    property: 'requestData',
                    modifiable: false,
                    displayConfig: {
                        style: {
                            wordBreak: 'break-all',
                            maxHeight: '6.5em',
                            overflow: 'auto',
                            display: 'block',
                            lineHeight: '1.5em',
                        },
                    },
                },
                {
                    property: 'responseData',
                    modifiable: false,
                    displayConfig: {
                        style: {
                            wordBreak: 'break-all',
                            maxHeight: '6.5em',
                            overflow: 'auto',
                            display: 'block',
                            lineHeight: '1.5em',
                        },
                    },
                },
            ],
            className: 'custom-form edit-store',
            labelCol: 8,
            controlCol: 9,
        },
    },
};

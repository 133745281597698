import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { find, get } from 'lodash';

/**
 * 标题类型
 */
export class ColorTextMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, displayConfig: any) {
        const { statusConfig, id, prop } = displayConfig;
        const key = id || 'id';
        const style = find(statusConfig, { [key]: value });
        const displayTxt = prop ? get(style, prop) : value;
        return <span style={style}>{displayTxt}</span>;
    }
}

/**
 * 区间价格mode
 */
import React, { Component } from 'react';
import { Row as AntRow, Button, message, Popconfirm, InputNumber } from 'antd';
import { concat, isEmpty, clone } from 'lodash';
import { DateTimeRangePicker } from '@comall-backend-builder/components-basis';

import './index.less';
type PriceSectionPropsValue = {
    id?: number;
    /**
     * 区间价格
     */
    price?: any;
    /**
     * 生效区间
     */
    startTime?: any;
    endTime?: any;
    time?: any;
};
enum SectionType {
    price = 'price',
    time = 'time',
}

export interface PriceSectionProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;

    value: Array<PriceSectionPropsValue>;

    /**
     * 新增最大数量
     */
    maxCount: number;

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<PriceSectionPropsValue> | null, name: string) => void;
}

export class PriceSection extends Component<PriceSectionProps> {
    static defaultProps = {
        maxCount: 3,
    };

    componentDidMount() {
        let { name, value, onChange } = this.props;

        if (!value) {
            let item: PriceSectionPropsValue = {};
            value = concat([], item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }
    onAddSection() {
        let { name, value, onChange, maxCount } = this.props;
        if (value !== null && !isEmpty(value)) {
            if (value.length >= maxCount) {
                message.warning('最多可支持' + maxCount + '档，不可再添加');
                return;
            }
            value.push({
                id: 0,
            });
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveSection = (index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                let item: PriceSectionPropsValue = {};
                value = concat([], item);
            }
        } else {
            let item: PriceSectionPropsValue = {};
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    };

    onInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === SectionType.price) {
                item.price = inputValue;
            }
            if (type === SectionType.time) {
                item.startTime = inputValue?.start;
                item.endTime = inputValue?.end;
                item.time = inputValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        let { value = [], maxCount } = this.props;

        return (
            <div className='price'>
                {value.map((section, index) => {
                    return this.renderSection(section, index);
                })}
                {value && value.length < maxCount && (
                    <Button
                        type='default'
                        className='price-add-button'
                        onClick={() => this.onAddSection()}
                    >
                        新增价格
                    </Button>
                )}
            </div>
        );
    }

    renderSection(section: PriceSectionPropsValue, index: number) {
        return (
            <div className='section' key={index}>
                <div className='section-content-list'>
                    <div className='section-content-item'>
                        <div className='section-content-name'>
                            <div className='section-content-text'>区间价格{index + 1}：</div>
                            <InputNumber
                                // type='number'
                                placeholder='请输入'
                                value={section?.price}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, SectionType.price)
                                }
                            />
                        </div>
                        <div className='section-content-name'>
                            <div className='section-content-time'>生效区间：</div>
                            <DateTimeRangePicker
                                style={{ width: '375px' }}
                                onChange={
                                    ((e: any) =>
                                        this.onInputValueChange(e, index, SectionType.time)) as any
                                }
                                name={index + ''}
                                value={section.time}
                            />
                        </div>
                    </div>
                </div>
                <AntRow>
                    <div className='section-content'>
                        <Popconfirm
                            onConfirm={this.onRemoveSection.bind(this, index)}
                            title='是否删除该价格'
                            okText='确定'
                            cancelText='取消'
                        >
                            <Button type='default' className='price-remove-button'>
                                删除价格
                            </Button>
                        </Popconfirm>
                    </div>
                </AntRow>
            </div>
        );
    }
}

import React, { PureComponent } from 'react';
import { Card as AntCard, Table as AntTable } from 'antd';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';

/**
 * 售后单详情 - 售后单备注信息
 */
class afterInfoRemark extends PureComponent {
    render() {
        const { id, style, remarkPos = [] } = this.props;
        const cardProps = {
            className: 'order-info-remark',
            bordered: false,
            loading: !id,
            title: services.language.getText('order.orderInfoRemark.remark'),
            style,
        };

        const tableProps = {
            rowKey: 'createTime',
            pagination: false,
            columns: [
                {
                    title: services.language.getText('order.orderInfoRemark.content'),
                    dataIndex: 'remark',
                    key: 'remark',
                },
                {
                    title: services.language.getText('order.orderInfoRemark.createTime'),
                    dataIndex: 'createTime',
                    key: 'createTime',
                    width: '25%',
                },
                {
                    title: services.language.getText('order.orderInfoRemark.userName'),
                    dataIndex: 'createUser',
                    key: 'createUser',
                    width: '15%',
                },
            ],
            dataSource: remarkPos,
        };
        return !!remarkPos.length ? (
            <AntCard {...cardProps}>
                <AntTable {...tableProps} />
            </AntCard>
        ) : null;
    }
}

const mapStateToProps = (state, ownProps) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const AfterInfoRemark = connect(mapStateToProps, null)(afterInfoRemark);

import React, { createElement } from 'react';
import { Popover as AntPopover } from 'antd';
import { ComponentsManager } from '@comall-backend-builder/core';

interface Props {
    content: any;
    text: any;
    trigger: 'click' | 'hover';
}

export const Popover = (props: Props) => {
    const { trigger, content, text } = props;
    const contentComponent = createElement(ComponentsManager.get(content.component), content);
    const childrenComponent = createElement(ComponentsManager.get(text.component), text);
    return (
        <AntPopover content={contentComponent} trigger={trigger} placement='bottom'>
            &nbsp;{childrenComponent}&nbsp;
        </AntPopover>
    );
};

Popover.defaultProps = {
    content: {},
    text: {},
    trigger: 'hover',
};

import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    tableMixin,
    flexibleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
} from '@/configs/mixin';
import { BRAND_TYPE_OPTIONS, IMPORT_TEMPLATE_URL, RECOMMEND_PRODUCT_TYPE } from '@/constants';
import { recordImportLogs } from '@/services';
import { BRANDS_TABLE } from '@/configs/pro-table-constants';
import { createRecommendProductComponents } from '@/configs/product/recommend-product';

export const config: Config = {
    entities: {
        brands: {
            apiPath: '/dc-goods/admin/brands',
            properties: {
                searchCode: {
                    type: 'string',
                    displayName: '<<brands.brandCode>>',
                },
                languageName: {
                    type: 'object.subForm',
                    properties: getLanguageProperties('string.text.trim', 'brands.brandName', {
                        controlConfig: {
                            placeholder: '<<category.displayCategory.namePlaceholder>>',
                        },
                        rules: [
                            {
                                whitespace: true,
                                message: '<<pickStoreInfo.storeNameError>>',
                            },
                            { max: 50, message: '<<pickStoreInfo.storeNameLenError>>' },
                        ],
                    }),
                },
                description: {
                    type: 'string',
                    displayName: '<<brands.brandDescription>>',
                },
                logoTop: {
                    type: 'array.brandImage',
                    displayName: '<<brands.topLogo>>',
                },
                logoFeature: {
                    type: 'array.brandImage',
                    displayName: '<<brands.featuredLogo>>',
                },
                logoBottom: {
                    type: 'array.brandImage',
                    displayName: '<<brands.bottomLogo>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<brands.brandSource>>',
                    options: BRAND_TYPE_OPTIONS,
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<brands.keyWords>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<brands.brandSource>>',
                    options: BRAND_TYPE_OPTIONS,
                },
            },
        },
    },

    components: {
        BrandsContainer: {
            component: 'Viewport',
        },
        BrandsManagement: {
            component: 'Card',
            entity: 'brands',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'BrandsFilter',
                        privilege: {
                            path: 'category.brand.view_list',
                            level: 'full',
                        },
                    },
                    { component: 'BrandsActions' },
                    {
                        component: 'BrandsTable',
                        privilege: {
                            path: 'category.brand.view_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },

        BrandsFilter: {
            ...flexibleFilterForm,
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<brands.keyWordsPlaceHolder>>',
                    },
                },
                {
                    property: 'type',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            ],
        },
        BrandsActions: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Button',
                    text: '<<brands.brandAdd>>',
                    icon: 'plus',
                    type: 'primary',
                    route: '/brands/create',
                    style: {
                        marginRight: 15,
                    },
                    privilege: {
                        path: 'category.brand.add',
                        level: 'full',
                    },
                },
                // {
                //     component: 'ExportButtonWithFilter',
                //     text: '导出品牌信息',
                //     icon: 'download',
                //     style: {
                //         marginRight: 15,
                //     },
                //     source: {
                //         apiPath: '/dc-goods/admin/brand_info_records/export',
                //     },
                //     formatter: (params: any) => {
                //         return params;
                //     },
                // },
                {
                    component: 'Upload',
                    apiPath: '/dc-file/excels?location_id=2',
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<brands.importInfo>>',
                        style: {
                            marginRight: 15,
                        },
                    },
                    onUploadComplete(response: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('brands.brandInfo'),
                                apiPath: '/dc-goods/admin/brand_info_records/importData',
                                params: { resourceId: response.id },
                            });
                        }
                    },
                    privilege: {
                        path: 'category.brand.import_goods',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<brands.exportInfo>>',
                    type: 'link',
                    style: {
                        alignSelf: 'center',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.BRAND_TEMPLATE}`,
                    privilege: {
                        path: 'category.brand.download_import_template',
                        level: 'full',
                    },
                },
            ],
        },
        BrandsTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: BRANDS_TABLE,
            scroll: {
                x: 'max-content',
            },
            columns: [
                { property: 'searchCode' },
                ...getLanguageColumns('languageName'),
                { property: 'type' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/brands/edit/{{row.id}}',
                        privilege: {
                            path: 'category.brand.view_detail',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<brands.recommendProduct>>',
                        type: 'link',
                        path: `/brands/recommend-product/${RECOMMEND_PRODUCT_TYPE.BRAND}/{{row.id}}`,
                        privilege: {
                            path: 'category.brand.view_recommend_sku_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ...createRecommendProductComponents('Brands', 'category.brand'),
    },
};

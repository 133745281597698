import React, { PureComponent } from 'react';
import { interpolate } from '@comall-backend-builder/core/lib/services';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ExportButtonProps } from '@comall-backend-builder/components-basis/lib/export-button';
import ExportRangeModal, { Range } from '../export-range-modal';

interface Props extends ExportButtonProps {
    /**
     * 分区间导出配置项
     */
    range: Range;
    text: React.ReactNode;
    showSuccessTip?: string;
    row: any;
    params: any;
}

/**
 * 表格操作项中的导出按钮功能
 */
export class TableActionExport extends PureComponent<Props, any> {
    state = {
        showModal: false,
    };
    render() {
        const { showModal } = this.state;
        const {
            className,
            row,
            text,
            source,
            range,
            params,
            showErrorMessage,
            showSuccessTip,
        } = this.props;
        const buttonProps = {
            type: 'link',
            downloadUrl: '',
            showErrorMessage,
            params,
            source: {
                apiPath: interpolate(source?.apiPath, { row }),
                paramsFilter: source?.paramsFilter,
            },
            onSuccess: () => {},
            onError: () => {},
        };

        return (
            <>
                <ExportButton
                    className={className}
                    beforeExport={() => {
                        if (range) {
                            this.setState({ showModal: true });
                            return Promise.reject();
                        }
                        return Promise.resolve();
                    }}
                    {...buttonProps}
                >
                    {text}
                </ExportButton>

                {range && (
                    <ExportRangeModal
                        {...buttonProps}
                        showSuccessTips={showSuccessTip}
                        range={{
                            ...range,
                            apiPath: interpolate(range.apiPath, { row }),
                        }}
                        visible={showModal}
                        onClose={() => this.setState({ showModal: false })}
                    />
                )}
            </>
        );
    }
}

import React, { useState } from 'react';
import classnames from 'classnames';
import { isFunction } from 'lodash';
import { Upload as AntUpload, message } from 'antd';
import { UploadProps as AntUploadProps, RcFile as AntRcFile } from 'antd/lib/upload';
import { RcCustomRequestOptions as AntRcCustomRequestOptions } from 'antd/lib/upload/interface';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Button } from '@comall-backend-builder/components-basis';
import { ComponentConfig } from '@comall-backend-builder/components-basis/lib/flex-layout';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';

//antd 支持而我们目前不支持的属性
type ExcludeProps =
    | 'action'
    | 'method'
    | 'customRequest'
    | 'data'
    | 'supportServerRender'
    | 'fileList'
    | 'openFileDialogOnClick'
    | 'onChange'
    | 'name'
    //不支持多文件上传
    | 'multiple';

interface UploadValue {
    id: string;
    path: string;
}

interface Props extends Omit<AntUploadProps, ExcludeProps> {
    entity?: Entity;
    params?: any;
    /**
     * 文件大小限制 M
     */
    size: number;
    name: string;
    /**
     * 上传根地址
     */
    apiRoot: string;
    /**
     * 上传路径
     */
    apiPath: string;
    /**
     * 自定义上传组件
     */
    triggerComponent?: ComponentConfig;
    value: UploadValue[];
    /**
     * 上传文件的 key
     */
    fileName: string;

    /**
     * 上传完是否需要刷新
     */
    refresh?: boolean;
    /**
     * 是否展示上传成功的提示
     */
    showSuccessMessage?: boolean;
    /**
     * 是否展示上传异常的提示
     */
    showErrorMessage?: boolean;
    /**
     * 上传完成的事件
     */
    onUploadComplete?: (value: any) => void;
    onChange?: (value: any, name: string) => void;
}

/**
 * 检查文件大小
 * @param {number} [size=2]
 * @returns
 */
function checkUploadSize(file: File, size: number) {
    const isAllowedSize = file.size < size * 1024 * 1024;
    if (!isAllowedSize) {
        const msg = services.interpolate(services.language.getText('upload.sizeLimit'), { size });
        message.error(msg);
    }
    return isAllowedSize;
}

export function Upload(props: Props) {
    const {
        className,
        entity,
        params,
        size,
        refresh,
        apiRoot,
        apiPath,
        triggerComponent,
        name,
        fileName,
        value,
        beforeUpload,
        onChange,
        onUploadComplete,
        showSuccessMessage,
        showErrorMessage,
        ...antdProps
    } = props;
    const [loading, setLoading] = useState(false);
    const customRequest = async ({ file, onProgress }: AntRcCustomRequestOptions) => {
        !loading && setLoading(true);
        const data = {
            files: file,
            otherParams: params || {},
        };

        const config = {
            progressCallBack: (percent = 0) => {
                onProgress({ percent: percent }, file);
            },
            fileName,
            apiRoot,
            apiPath,
        };
        try {
            const result = await services.api.upload(data, config);
            refresh && entity && entity.search(params);
            showSuccessMessage && message.success(services.language.getText('upload.success'));
            isFunction(onChange) && onChange(result, name);
            isFunction(onUploadComplete) && onUploadComplete(result);
        } catch (e) {
            showErrorMessage && errorHandle(e as ErrorInfo);
        } finally {
            setLoading(false);
        }
    };

    const onBeforeUpload = (file: AntRcFile, fileList: AntRcFile[]) => {
        if (isFunction(beforeUpload)) {
            return beforeUpload(file, fileList);
        }
        return checkUploadSize(file, size);
    };
    return (
        <AntUpload
            name={name}
            multiple={false}
            className={classnames('upload', className)}
            customRequest={customRequest}
            beforeUpload={onBeforeUpload}
            {...antdProps}
        >
            <div
                onClick={(e) => {
                    //防止 loading 时仍触发上传
                    if (loading) {
                        e.stopPropagation();
                    }
                }}
            >
                {triggerComponent ? (
                    React.createElement(ComponentsManager.get(triggerComponent.component), {
                        ...triggerComponent,
                        loading,
                        component: undefined,
                    })
                ) : (
                    <Button
                        icon='upload'
                        loading={loading}
                        text={services.language.getText('Upload.triggerText')}
                    />
                )}
            </div>
        </AntUpload>
    );
}

Upload.defaultProps = {
    apiRoot: ENV.API_ROOT,
    size: 10,
    value: [],
    fileName: 'file',
    accept: '.xls,.xlsx,.csv',
    showUploadList: false,
    showSuccessMessage: true,
    showErrorMessage: true,
};

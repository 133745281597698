/*
 * @Author: zhulu
 * @Date: 2021-12-06 14:11:47
 * @Description: 邮件管理邮箱输入框
 */
import React from 'react';
import { Tag, Input, Tooltip, Icon } from 'antd';
import { isEqual } from 'lodash';
import { modes } from '@comall-backend-builder/types';
import { ExtendedParentComponentProps } from '@comall-backend-builder/components-basis/lib/component-props';
import { services } from '@comall-backend-builder/core';
interface Props extends ExtendedParentComponentProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 当前值
     */
    value: string[];
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: string[], name: string) => void;
}
interface State {
    tags: string[];
    inputVisible: boolean;
    inputValue: string;
}
class EmailInput extends React.Component<Props, State> {
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (!isEqual(nextProps.value, prevState.value)) {
            return { tags: nextProps.value };
        }
        return undefined;
    }
    state = {
        tags: [],
        inputVisible: false,
        inputValue: '',
    };
    input!: Input | null;
    handleClose = (removedTag: string, index: number) => {
        const _tags = [...this.state.tags];
        _tags.splice(index, 1);
        console.info(_tags, index, removedTag);
        const { name, onChange } = this.props;
        this.setState({ tags: _tags }, () => {
            onChange(_tags, name);
        });
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input && this.input.focus());
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue, tags } = this.state;
        let _tags: string[] = tags;
        //@ts-ignore
        if (inputValue) {
            _tags = [...tags, inputValue];
        }
        const { name, onChange } = this.props;

        this.setState(
            {
                tags: _tags,
                inputVisible: false,
                inputValue: '',
            },
            () => {
                onChange(_tags, name);
            }
        );
    };

    saveInputRef = (input: Input) => (this.input = input);

    render() {
        const { tags, inputVisible, inputValue } = this.state;
        return (
            <div
                className='email-message-input'
                style={{
                    marginTop: '6px',
                    padding: '0 11px',
                    border: '1px solid rgb(217, 217, 217)',
                    borderRadius: '4px',
                }}
            >
                {tags.map((tag: string, index: number) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag
                            visible={true}
                            closable={true}
                            onClose={() => this.handleClose(tag, index)}
                            style={{ fontSize: '14px', lineHeight: '1.5' }}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                    return (
                        <span key={`${tag}_${index}`}>
                            {isLongTag ? <Tooltip title={tag}>{tagElem}</Tooltip> : tagElem}
                        </span>
                    );
                })}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type='text'
                        size='small'
                        style={{ width: 120 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag
                        onClick={this.showInput}
                        style={{
                            background: '#fff',
                            borderStyle: 'dashed',
                            fontSize: '14px',
                            lineHeight: '1.5',
                        }}
                    >
                        <Icon type='plus' /> {services.language.getText('add')}
                    </Tag>
                )}
            </div>
        );
    }
}

export class ArrayEmailInputMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
        };
        return <EmailInput {...props} />;
    }
    getDisplayComponent(value: any, displayInfo: any) {
        if (value && value.length > 0) {
            return (
                <div>
                    {value.map((tag: string, index: number) => {
                        const isLongTag = tag.length > 20;
                        const tagElem = (
                            <Tag key={tag} style={{ fontSize: '14px', lineHeight: '1.5' }}>
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                            </Tag>
                        );
                        return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                                {tagElem}
                            </Tooltip>
                        ) : (
                            tagElem
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
}

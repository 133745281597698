import { generateOptions } from '@/services/generate-options';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 发送人群
 */
export enum SEND_TYPE_VALUES {
    /**
     * 全部会员
     */
    ALL = '1',
    /**
     * 指定会员
     */
    PART = '2',
}

/**
 * 发送人群名称
 */
export const SEND_TYPE_NAMES = {
    [SEND_TYPE_VALUES.ALL]: language.getText('siteMessages.sendTypeMap.all'),
    [SEND_TYPE_VALUES.PART]: language.getText('siteMessages.sendTypeMap.part'),
};

/**
 * 发送人群候选项
 */
export const SEND_TYPE_OPTIONS = generateOptions(SEND_TYPE_VALUES, SEND_TYPE_NAMES);

import React from 'react';
import { map, isPlainObject } from 'lodash';
import classNames from 'classnames';

export function Prices(props) {
    const { list = [] } = props;

    return (
        <>
            {map(list, (item) => (
                <p key={item.name} className={classNames('checkout-item', { hide: !item.show })}>
                    <label className='checkout-name'>{item.name}：</label>
                    <label className='checkout-value'>
                        {(isPlainObject(item.amount) ? item.amount.amount : item.amount) ?? '0.00'}
                    </label>
                </p>
            ))}
        </>
    );
}

import builder from '@comall-backend-builder/core';
import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { forIn, flatten } from 'lodash';

import productTagContainers from './product-tag';
// import storeContainers from './store';
import organizationsContainers from './organizations';
import channelsContainers from './channels';
import storeChannelsContainers from './store-channels';
import brandsContainers from './brands';
import {
    virtualCategoryLoader,
    displayCategoryLoader,
    offlineCategoriesContainers,
} from './categories';
import LoginContainers from './login';
import productLimitContainers from './product-limit';
import blacklistContainers from './blacklist';
import membersContainers from './members';
import orderContainers from './order';
import importRecordContainers from './import-record';
import selectMemberLoader from './select-member';
import productInventoryLoader from './product-inventory';
import pickingAssistantLoader from './picking-assistant';
import categorySafeStock from './category-safe-stock';
import paymentContainers from './payment';
import exportLiability from './export-liability';
import promotionContainers from './promotion';
import depositManageLoader from './depositManage';
import systemLoader from './system';
import pickEmailConfig from './pick-email-config';
import coupon from './coupon';
import distributionRules from './distribution-rules';
import productThirdSku from './product-third-sku';
import {
    infoReport,
    shelfLoader,
    productListContainers,
    productSuiteLoader,
    productGroupLoader,
    productSeoLoader,
    productSpecLoader,
    productPriceLoader,
    recommendProductLoader,
    productPickupLimitLoader,
} from './product';
import resendSales from './resend-sales';

import saLoader from './sa';
import userLoader from './user';
import {
    onlineStoreLoader,
    pickStoreLoader,
    pickOnlineStoreLoader,
    retailStoreLoader,
} from './store/index';
import afterSaleOrderLoader from './after-sale-order';
import { loader as homeLoader } from './home';
import { loader as topicLoader } from './topic';
import overrideCodeLoader from './override-code';
import freightCouponLoader from './freight-coupon';
import discountCouponLoader from './discount-coupon';
import eventMarketingLoader from './event-marketing';
import voucherManagementLoader from './coupon/voucher-management';
import thirdInterfaceLogs from './third-interface-logs';
import evaluationListLoader from './evaluation-list';
import membershipLoader from './membership';
import orderLimitManagement from './order-limit-management';
import philipsOrderLoader from './philips-order';
import offlineLoader from './offline';
import orderReportLoader from './report/order';
import menuBarManagementLoader from './menu-bar-management';
import noticeBarManagementLoader from './notice-bar-management';
import couponCenterLoader from './coupon-center';
import cashCouponLoader from './cash-coupon';
import merchantsStoerLoader from './merchants-store';
import recommendedBrandLoader from './recommended-brand';
import { siteMessagesLoader } from './notification';
import popupAdsLoader from './popup-ads';
import urlMappingLoader from './url-mapping';
import paymentModeLoader from './payment-mode';
import couponPackageLoader from './coupon-package';
import rolesLoader from './roles';

interface LoaderContainer {
    key: string;
    loader: Loader;
}

function loadContainers(...containers: LoaderContainer[][]) {
    const allLoaderContainers: LoaderContainer[] = flatten(containers);
    forIn(allLoaderContainers, (container) => {
        //@ts-ignore
        builder.registerLoader(container.key, container.loader);
    });
}

loadContainers(
    productSpecLoader,
    productSeoLoader,
    productGroupLoader,
    productSuiteLoader,
    productTagContainers,
    productPriceLoader,
    pickStoreLoader,
    organizationsContainers,
    channelsContainers,
    storeChannelsContainers,
    brandsContainers,
    virtualCategoryLoader,
    LoginContainers,
    productLimitContainers,
    blacklistContainers,
    membersContainers,
    offlineCategoriesContainers,
    orderContainers,
    productListContainers,
    importRecordContainers,
    selectMemberLoader,
    productInventoryLoader,
    pickingAssistantLoader,
    categorySafeStock,
    paymentContainers,
    exportLiability,
    promotionContainers,
    infoReport,
    resendSales,
    saLoader,
    onlineStoreLoader,
    pickOnlineStoreLoader,
    retailStoreLoader,
    shelfLoader,
    depositManageLoader,
    coupon,
    systemLoader,
    userLoader,
    pickEmailConfig,
    displayCategoryLoader,
    distributionRules,
    productThirdSku,
    afterSaleOrderLoader,
    overrideCodeLoader,
    offlineLoader,
    freightCouponLoader,
    discountCouponLoader,
    eventMarketingLoader,
    voucherManagementLoader,
    thirdInterfaceLogs,
    membershipLoader,
    orderLimitManagement,
    orderReportLoader,
    philipsOrderLoader,
    evaluationListLoader,
    menuBarManagementLoader,
    noticeBarManagementLoader,
    couponCenterLoader,
    cashCouponLoader,
    merchantsStoerLoader,
    recommendedBrandLoader,
    siteMessagesLoader,
    popupAdsLoader,
    recommendProductLoader,
    urlMappingLoader,
    paymentModeLoader,
    productPickupLimitLoader,
    couponPackageLoader,
    rolesLoader
);

loadContainers(homeLoader);
loadContainers(topicLoader);

import { services } from '@comall-backend-builder/core/lib';
import { set, get as lodashGet } from 'lodash';

export enum API_PREFIX {
    BASE = '/base-order',
    PHILIPS = '/base-order-philips',
}

export function formatUrl(url: string, type: string): string {
    if (type === 'philips') {
        return `${API_PREFIX.PHILIPS}${url}`;
    } else if (type === 'base') {
        return `${API_PREFIX.BASE}${url}`;
    } else {
        return '';
    }
}

export async function wrapApiResult(data: any, config: any, apiPrefix: string) {
    if (data.orderNo) {
        // 详情
        const result: object = await services.api.get(
            {
                outerOrderNumber: data.orderNo,
            },
            {
                ...config,
                apiPath: `${apiPrefix}/admin/order/info`,
            }
        );
        const consigneeCode = lodashGet(result, 'consignee.code');
        const deliveryCode = lodashGet(result, 'delivery.code');
        set(result, 'consignee.code', consigneeCode ? `(${consigneeCode})` : '');
        set(result, 'delivery.code', deliveryCode ? `(${deliveryCode})` : '');
        return new Promise((resolve) => {
            resolve(result);
        });
    } else {
        // 列表
        // 如果没有选择机构则提示

        if (data.orderStatus === 'ABNORMAL_ORDERS') {
            data.queryType = data.orderStatus;
            delete data.orderStatus;
        }
        if (data.createTime) {
            data.beginTime = data.createTime.start;
            data.endTime = data.createTime.end;
            delete data.createTime;
        }
        if (data.waitDeliveryTime) {
            data.preStartDeliveryTime = data.waitDeliveryTime.start;
            data.preEndDeliveryTime = data.waitDeliveryTime.end;
            delete data.waitDeliveryTime;
        }
        if (data.expressDeliveryTime) {
            if (data.orderStatus === 'DELIVERING') {
                data.expressDeliveryTimeStart = data.expressDeliveryTime.start;
                data.expressDeliveryTimeEnd = data.expressDeliveryTime.end;
            }
            delete data.expressDeliveryTime;
        }
        const response: any = await services.api.get(data, {
            ...config,
            apiPath: `${apiPrefix}/admin/order/find`,
        });
        return new Promise((resolve) => {
            resolve(response);
        });
    }
}

import Quill from 'quill';

let BlockEmbed = Quill.import('blots/block/embed');

class IframeBlot extends BlockEmbed {
    static blotName = 'iframe'; // 定义自定义Blot的名字（必须全局唯一）
    static tagName = 'iframe';

    static create(value: any): any {
        const node = super.create(value);
        node.setAttribute('src', `${value.url}&autoplay=0 `);
        node.setAttribute('frameborder', '0');
        node.setAttribute('allowfullscreen', 'true');
        node.setAttribute('allow', 'accelerometer; encrypted-media; gyroscope; picture-in-picture');
        if (value.width !== undefined) {
            node.setAttribute('width', value.width);
        }
        return node;
    }

    // 返回options数据
    static value(node: any): any {
        return {
            url: node.getAttribute('src'),
            width: node.getAttribute('width'),
        };
    }
}

export default IframeBlot;

/*
 * @Author: zhulu
 * @Date: 2021-12-22 11:52:24
 * @Description: 登录页后门
 */
import React from 'react';
import { mapValues } from 'lodash';

import { CreateForm } from '@comall-backend-builder/components-basis';
import { actions } from '@comall-backend-builder/core';
import { services } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
// import './index.scss';

export interface AuthProps {
    /**
     * 组件绑定的实体
     */
    entities: {
        sessions: Entity;
        privileges: Entity;
    };
    /**
     * logo图片
     */
    logo?: any;
    /**
     * 路由参数
     */
    params: object;
}

export const LoginBack: React.FC<AuthProps> = ({ entities: { sessions, privileges }, params }) => {
    return (
        <div className='login-container'>
            <div style={{ paddingTop: '100px', textAlign: 'center' }}>
                <img alt='' src='./logo-dark.png' />
                <span>{ENV.ENV}</span>
            </div>
            <div className='login-main'>
                <CreateForm
                    componentId='login'
                    entity={sessions}
                    direction='vertical'
                    submit={{
                        style: {
                            width: '100%',
                        },
                        text: services.language.getText('components.Auth.login'),
                        size: 'large',
                    }}
                    onSubmit={(_event: any, fields: any) => {
                        const entityFields = mapValues(fields, (field, _name) => {
                            return field.value;
                        });
                        sessions.add(
                            entityFields,
                            params,
                            {
                                action: actions.loginAction(sessions, privileges),
                            },
                            {}
                        );
                    }}
                    fields={[
                        {
                            label: '用户名',
                            property: 'name',
                            controlConfig: {
                                prefixIcon: 'user',
                                placeholder: '用户名',
                                size: 'large',
                            },
                        },
                        {
                            label: '密码',
                            property: 'password',
                            controlConfig: {
                                prefixIcon: 'lock',
                                placeholder: '密码',
                                size: 'large',
                            },
                        },
                    ]}
                />
            </div>
            <div className='login-footer'>{services.language.getText('common.copyrights')}</div>
        </div>
    );
};

import { cloneDeep, defaults } from 'lodash';
import { actions, Loader, services } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { ModifyForm } from '@comall-backend-builder/components-basis';
import { message as AntMessage } from 'antd';
// import { Form, Input, Select } from 'antd';

// interface Props {}
// interface State {}
// class onlineStoreForm extends Component<Props, State> {
//     render() {
//         return (
//             <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
//                 <Form.Item label='线上门店编号'></Form.Item>
//             </Form>
//         );
//     }
// }

function mapStateToProps(state: any, props: any) {
    return defaults({
        fields: [
            {
                property: 'onlineStoreCode',
                modifiable: false,
            },
            {
                property: 'onlineStoreName',
            },
            {
                property: 'channelId',
            },
            { property: 'organizationId', modifiable: false },
            { property: 'retailStoreName', modifiable: false },
            { property: 'sapMcsLocation', modifiable: false },
            { property: 'stockMcsLocation', modifiable: false },
            { property: 'guideToEshop', className: 'hidden' },
        ],
        className: 'custom-form edit-store',
        labelCol: 3,
        controlCol: 8,
        onSubmitSuccess: () => {
            AntMessage.success(services.language.getText('saveFully'));
            services.navigation.goto('/store');
        },
        footer: {
            items: [
                {
                    htmlType: 'button',
                    route: 'goBack',
                    type: 'default',
                    text: '<<cancel>>',
                    style: {
                        marginRight: 20,
                    },
                },
                {
                    type: 'primary',
                    text: '<<save>>',
                },
            ],
            style: {
                textAlign: 'right',
                marginTop: 20,
            },
        },
    });
}

function mapDispatchToProps(dispatch: any, props: any) {
    const { componentId, entity, onFieldChange } = props;
    let form: any;
    return defaults(
        { onFieldChange, entity },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            onFieldChange: async (name: any, value: any) => {
                let _metainfo;
                if (name === 'retailStoreId') {
                    // 获取线下店铺的mcs location
                    const location: {
                        sapMcsLocation: string;
                        stockMcsLocation: string;
                    } = await Loader.load('get', { apiPath: '/loader/dc-store/admin/location' });
                    dispatch(
                        actions.formChangeAction(
                            componentId,
                            'sapMcsLocation',
                            location.sapMcsLocation
                        )
                    );
                    dispatch(
                        actions.formChangeAction(
                            componentId,
                            'stockMcsLocation',
                            location.stockMcsLocation
                        )
                    );
                    form.setFields({
                        sapMcsLocation: { value: location.sapMcsLocation },
                        stockMcsLocation: { value: location.stockMcsLocation },
                    });
                    _metainfo = cloneDeep(entity.metainfo);
                    form.validateFields(['sapMcsLocation', 'stockMcsLocation']);
                    entity.setMeta(_metainfo);
                }
            },
        }
    );
}

export const OnlineStoreForm = connect(mapStateToProps, mapDispatchToProps)(ModifyForm);

/**
 * pathname
 */
export enum PATH_NAME {
    ORDER_PATH_NAME = '/orders/',
    PHILIPS_ORDER_PATH_NAME = '/orders-philips/',
}

/**
 * ordertype
 */
export enum ORDER_TYPE {
    BASE = 'base',
    PHILIPS = 'philips',
}

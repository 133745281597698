import React from 'react';

export function DiscountRemarkTitle() {
    return <div className='discount-remark-title'>会员折扣管理</div>;
}

export function DiscountRemarkContent() {
    return (
        <ul className='discount-remark-content'>
            <li>1、初始化两个级别：lite和plus。</li>
            <li>2、每个级别下，通过【导入商品】维护各自级别下各个csku的折扣。</li>
            <li>3、状态为“启用”时，该等级会员才在H5上享受价格折扣。</li>
        </ul>
    );
}

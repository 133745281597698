import React from 'react';
import { KV } from '@/interfaces';
import { Button } from '@comall-backend-builder/components-basis';
import changeEvaluationStatus from '../utils/change-evaluation-status';

interface IProps {
    entity: any;
    row: KV;
    text: string;
    /** 通过:1, 不通过: 2 */
    status?: '1' | '2';
}

export const EvaluationButton: React.FC<IProps> = (props) => {
    const { row, status, entity } = props;
    const handleClick = () => {
        changeEvaluationStatus({ id: row.id, status }).then(() => {
            entity.search(entity.params);
        });
    };
    return (
        <Button onClick={handleClick} {...props}>
            {props.text}
        </Button>
    );
};

/*
 * @Author: zhulu
 * @Date: 2021-07-13 13:57:42
 * @Descripttion: 优惠券管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { flexibleFilterForm, tableMixin } from '@/configs/mixin';
import { couponProps, filterCouponProps, filterCouponFields } from './properties/coupon';
import { ACTIVE_STATUS } from './constants';

function formatDate(
    date: { start: string; end: string },
    prop: string,
    beginSuffix = 'Start',
    afterSuffix = 'End'
) {
    if (date) {
        return {
            [prop + beginSuffix]: date.start + ' 00:00:00',
            [prop + afterSuffix]: date.end + ' 00:00:00',
        };
    } else {
        return {};
    }
}
export default {
    entities: {
        coupons: {
            apiPath: '/loader/dc-ecoupon/admin/coupons',
            properties: couponProps,
            filters: filterCouponProps,
        },
    },
    components: {
        CouponContainer: {
            component: 'Viewport',
        },
        CouponManagement: {
            component: 'Card',
            entity: 'coupons',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'CouponFilter',
                        privilege: {
                            path: 'promotions_manager.coupon.list',
                            level: 'full',
                        },
                    },
                    { component: 'CouponActions' },
                    {
                        component: 'CouponTable',
                        privilege: {
                            path: 'promotions_manager.coupon.list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        CouponFilter: {
            ...flexibleFilterForm,
            fields: filterCouponFields,
        },
        CouponActions: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<coupon.exportInfo>>',
                    icon: 'download',
                    style: {
                        marginRight: 15,
                    },
                    isAsync: true,
                    source: {
                        apiPath: '/dc-ecoupon/admin/coupons/export/take',
                    },
                    formatter: (params: any) => {
                        const newParms: any = { ...params };
                        const sendTime = formatDate(newParms.sendTime, 'sendTime');
                        const receiveTime = formatDate(newParms.receiveTime, 'receiveTime');
                        const useTime = formatDate(newParms.useTime, 'useTime');
                        delete newParms.sendTime;
                        delete newParms.receiveTime;
                        delete newParms.useTime;
                        return { ...newParms, ...sendTime, ...receiveTime, ...useTime };
                    },
                    showErrorMessage: true,
                    privilege: {
                        path: 'promotions_manager.coupon.export',
                        level: 'full',
                    },
                },
            ],
        },
        CouponTable: {
            ...tableMixin,
            scroll: {
                x: 'max-content',
            },
            columns: [
                {
                    property: 'activeStatus',
                    displayConfig: {
                        statusConfig: ACTIVE_STATUS,
                        id: 'id',
                        prop: 'id',
                    },
                    fixed: 'left',
                },
                { property: 'promotionId', fixed: 'left' },
                { property: 'couponNo' },
                { property: 'loyaltNo' },
                { property: 'useLoyaltNo' },
                { property: 'status' },
                { property: 'sendOrderNo' },
                { property: 'useOrderNo' },
                { property: 'unionId' },
                { property: 'couponChannel' },
                { property: 'code' },
                { property: 'sendStore' },
                { property: 'sendTime' },
                { property: 'receiveStore' },
                { property: 'receiveTime' },
                { property: 'useStore' },
                { property: 'useTime' },
            ],
        },
    },
} as Config;

import React, { Component } from 'react';
import { language } from '@comall-backend-builder/core/lib/services';
import { Table, Button, message, Popconfirm, Input, InputNumber, Checkbox } from 'antd';
import { concat, isEmpty, filter, cloneDeep, get, set } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { DESIGN_MANAGE_LANGUAGE_NAMES, DESIGN_MANAGE_LANGUAGE_VALUES } from '@/constants';

type SpecialAttributeValue = {
    uuid?: string;
    id?: string;
    /**
     * 属性名称
     */
    nameMap?: { [key: string]: '' };
    /**
     * 排序
     */
    sort?: number;
};
const contentMap = [{ key: 'en' }, { key: 'zh' }, { key: 'tc' }];

export interface SpecialAttributeProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;

    value: Array<SpecialAttributeValue>;

    /**
     * 新增最大数量
     */
    maxCount: number;

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<SpecialAttributeValue> | null, name: string) => void;
}

const EditableContext = React.createContext({});

class EditableCell extends React.Component<any, any> {
    renderCell = (params: any) => {
        const {
            editable,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            onChange,
            type,
            editConfig = {},
            ...restProps
        } = this.props;
        const renderType = (type: string) => {
            if (type === 'number') {
                return (
                    <InputNumber
                        disabled={record.deleteEnable === undefined ? false : !record.deleteEnable}
                        placeholder={language.getText('defaultPlaceholderInput')}
                        value={get(record, `${dataIndex}`)}
                        min={1}
                        {...editConfig}
                        onChange={(e) => onChange(index, e, dataIndex)}
                    />
                );
            } else if (type === 'checkbox') {
                return (
                    <Checkbox
                        disabled={record.deleteEnable === undefined ? false : !record.deleteEnable}
                        checked={get(record, `${dataIndex}`)}
                        onChange={(e) => {
                            onChange(index, e.target.checked, dataIndex);
                        }}
                    />
                );
            } else {
                return (
                    <Input
                        disabled={record.deleteEnable === undefined ? false : !record.deleteEnable}
                        placeholder={language.getText('defaultPlaceholderInput')}
                        value={get(record, `${dataIndex}`)}
                        {...editConfig}
                        onChange={(e) => onChange(index, e.target.value, dataIndex)}
                    />
                );
            }
        };

        return <td {...restProps}>{editable ? renderType(type) : children}</td>;
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}
export class SpecialAttribute extends Component<SpecialAttributeProps> {
    static defaultProps = {
        maxCount: 3,
    };

    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            let item: SpecialAttributeValue = {};
            value = concat([], item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }
    onAddAttribute() {
        let { name, value, onChange, maxCount } = this.props;
        if (value !== null && !isEmpty(value)) {
            if (value.length >= maxCount) {
                message.warning('最多可支持' + maxCount + '档，不可再添加');
                return;
            }
            value = concat([], cloneDeep(value), { id: services.uuid() });
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveAttribute = (record: any) => {
        let { name, value, onChange } = this.props;
        const _value = filter(cloneDeep(value), (i) => record.id !== i.id);
        if (onChange) {
            onChange(_value, name);
        }
    };

    onChange = (index: any, val: any, dataIndex: string) => {
        let { name, value, onChange } = this.props;
        let nVal = cloneDeep(value);
        if (nVal !== null && !isEmpty(nVal)) {
            //@ts-ignore
            set(nVal, `${index}.${dataIndex}`, val);
        }
        if (nVal !== null && !isEmpty(nVal) && dataIndex === 'filterEnabled') {
            set(nVal, `${dataIndex}`, val);
        }
        if (onChange) {
            onChange(nVal, name);
        }
    };
    render() {
        let { value = [{}], maxCount } = this.props;
        const n = contentMap.map((item: any) => ({
            title: `属性名称（${
                DESIGN_MANAGE_LANGUAGE_NAMES[`${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES]
            }）`,
            dataIndex: `nameMap.${item.key}`,
            editable: true,
            editConfig: {
                maxLength: 100,
            },
        }));
        const _n: any[] = [];
        const columns: any[] = concat(_n, n, [
            {
                title: language.getText('productGroup.sort'),
                dataIndex: 'sort',
                type: 'number',
                editable: true,
                editConfig: {
                    max: 99999,
                    maxLength: 5,
                },
            },
            {
                title: '作为筛选使用',
                dataIndex: 'filterEnabled',
                type: 'checkbox',
                editable: true,
                align: 'center',
            },
            {
                title: language.getText('actions'),
                dataIndex: 'actions',
                render: (text: any, record: any, index: number) => {
                    return (
                        <Popconfirm
                            onConfirm={this.onRemoveAttribute.bind(this, record)}
                            title='是否删除该属性'
                            okText='确定'
                            cancelText='取消'
                        >
                            {(record.deleteEnable === undefined ? true : record.deleteEnable) && (
                                <Button type='link'>删除属性</Button>
                            )}
                        </Popconfirm>
                    );
                },
            },
        ]).map((col: any) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: any, index: number) => ({
                    ...col,
                    index,
                    type: col.type || 'text',
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editable: col.editable,
                    onChange: this.onChange,
                }),
            };
        });

        value = value.map((item: any, index: number) => ({ ...item, index }));
        return (
            <div className='special-attribute'>
                {value && value.length < maxCount && (
                    <Button
                        type='primary'
                        style={{ marginBottom: '8px' }}
                        className='attribute-add-attribute-button'
                        onClick={() => this.onAddAttribute()}
                    >
                        新增属性
                    </Button>
                )}
                <EditableContext.Provider value={{}}>
                    <Table
                        size='small'
                        scroll={{ x: 'fix-content' }}
                        components={{ body: { cell: EditableCell } }}
                        bordered
                        dataSource={value}
                        columns={columns}
                        pagination={false}
                        rowKey={(_: any) => _.id}
                    />
                </EditableContext.Provider>
            </div>
        );
    }
}

import React, { useCallback, useState } from 'react';
import { services } from '@comall-backend-builder/core';
import { Modal, Input, Row, Col, Icon } from 'antd';
import { SHELF_KEYS } from '../constants';

const { language, interpolate, errorHandle } = services;

export interface Product {
    csku: string;
    rsku: string;
    onlineStoreCode: string;
    productId: string;
    onShelvesValues: string;
}

interface Props {
    visible: boolean;
    /**
     * 是否批量操作
     */
    batch: boolean;
    /**
     * 上架 or 下架
     */
    status: SHELF_KEYS;
    /**
     * 上下架数据
     */
    products: Product[];
    onClose: () => void;
    /**
     * 操作成功回调
     */
    onSuccess: () => void;
}

const ProductShelfModal = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [reason, onChangeReason] = useState('');

    const handleOk = useCallback(() => {
        const apiPathMap = {
            [SHELF_KEYS.ON_SHELF_KEY]: '/dc-goods/admin/productscroll/putonshelves',
            [SHELF_KEYS.OFF_SHELF_KEY]: '/dc-goods/admin/productscroll/pulloffshelves',
        };
        setLoading(true);
        services.api
            .post(
                {
                    reason,
                    products: props.products,
                },
                {
                    apiPath: apiPathMap[props.status],
                }
            )
            .then(() => {
                props.onSuccess();
            })
            .catch(errorHandle)
            .finally(() => {
                setLoading(false);
            });
    }, [props, reason]);

    let modalTitle = '';

    if (props.status === SHELF_KEYS.OFF_SHELF_KEY) {
        if (props.batch) {
            modalTitle = language.getText('productShelf.shelfModal.batchOffShelf');
        } else {
            modalTitle = language.getText('productShelf.shelfModal.offShelf');
        }
    }

    if (props.status === SHELF_KEYS.ON_SHELF_KEY) {
        if (props.batch) {
            modalTitle = language.getText('productShelf.shelfModal.batchOnShelf');
        } else {
            modalTitle = language.getText('productShelf.shelfModal.onShelf');
        }
    }

    return (
        <>
            <Modal
                destroyOnClose
                width={600}
                visible={props.visible}
                closable={false}
                onCancel={props.onClose}
                onOk={handleOk}
                okButtonProps={{
                    loading: loading,
                }}
            >
                <div
                    style={{
                        marginBottom: 20,
                        lineHeight: 1,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Icon
                        theme='filled'
                        type='exclamation-circle'
                        style={{ color: '#FAAD14', fontSize: 18 }}
                    />
                    <b style={{ fontSize: 14, paddingLeft: 10 }}>{modalTitle}</b>
                </div>
                <Row style={{ paddingLeft: 30 }}>
                    <Col span={3}>{language.getText('productShelf.shelfModal.reason')}：</Col>
                    <Col span={21}>
                        <Input.TextArea
                            rows={4}
                            maxLength={500}
                            value={reason}
                            onChange={(e: any) => {
                                onChangeReason(e.target.value);
                            }}
                        />
                        <div style={{ marginTop: 10 }}>
                            {interpolate(language.getText('productShelf.shelfModal.reasonMaxTip'), {
                                max: 500,
                            })}
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

ProductShelfModal.defaultProps = {
    products: [],
    batch: false,
};

export default ProductShelfModal;

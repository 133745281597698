import { PICKUP_ATTRIBUTES_VALUES } from '@/constants/store';
import { services } from '@comall-backend-builder/core';
import { forEach, toString, get as lodashGet } from 'lodash';

export const loader = [
    {
        key: '/dc-store/admin/pickStore',
        loader: {
            get: async function(data: any, config: any) {
                if (!data.id) {
                    const respData: any = await services.api.get(data, config);
                    forEach(respData.result, (item: any) => {
                        item.organizationId = toString(item.organizationId);
                    });
                    return respData;
                } else {
                    config.apiPath = `/dc-store/admin/pickStore/findById/${data.id}`;
                    delete data.id;
                    const result: any = await services.api.get(data, config);
                    let images = [];
                    if (result.realityImages) {
                        images = result.realityImages.map((item: any) => ({
                            id: item.imageId,
                            path: item.imageUrl,
                        }));
                    }

                    result.id = toString(result.id);
                    result.storeInfo = {
                        name: result.pickStoreName,
                        code: result.retailStoreId,
                        organizationId: '1002',
                        languageName: result.languageName,
                    };
                    result.storeAddress = {
                        ...result.storeAddress,
                        realityImages: images,
                        pickStoreAttribute: result.pickStoreAttribute,
                        memberLevels: result.memberLevels.filter((item: any) => item !== ''),
                    };
                    result.contactInformation = {
                        languageName: lodashGet(result, 'contactInformation.languageName', null),
                        masterMobile: lodashGet(result, 'mobile.number', null),
                    };
                    return result;
                }
            },
            put: async function(data: any, config: any) {
                config.apiPath = `/dc-store/admin/pickStore/update/${data.id}`;
                const params = {
                    ...data,
                    id: data.id,
                    pickStoreName: data.storeInfo.languageName.zh,
                    languageName: data.storeInfo.languageName,
                    contactInformation: {
                        contacts: data.contactInformation.languageName.zh,
                        languageName: data.contactInformation.languageName,
                    },
                    mobile: {
                        number: data.contactInformation.masterMobile,
                    },
                    organizationId: '1002',
                    realityImages: (data.storeAddress.realityImages || []).map((item: any) => ({
                        imageId: item.id,
                        imageUrl: item.path,
                    })),
                    pickStoreAttribute: data.storeAddress.pickStoreAttribute,
                    memberLevels:
                        data.storeAddress.pickStoreAttribute ===
                        PICKUP_ATTRIBUTES_VALUES.GENERAL_PICKUP_POINT
                            ? []
                            : data.storeAddress.memberLevels,
                };
                return await services.api.put(params, config);
            },
            post: async function(data: any, config: any) {
                const params = {
                    retailStoreId: data.storeInfo.code,
                    pick_store_name: data.storeInfo.languageName.zh,
                    languageName: data.storeInfo.languageName,

                    contactInformation: {
                        contacts: data.contactInformation.zh,
                        languageName: data.contactInformation.languageName,
                    },
                    mobile: {
                        number: data.contactInformation.masterMobile,
                    },
                    organizationId: '1002',
                    storeAddress: data.storeAddress,
                    realityImages:
                        !data.storeAddress.realityImages ||
                        data.storeAddress.realityImages.length === 0
                            ? undefined
                            : data.storeAddress.realityImages.map((item: any) => ({
                                  imageId: item.id,
                                  imageUrl: item.path,
                              })),
                    pickStoreAttribute: data.storeAddress.pickStoreAttribute,
                    memberLevels:
                        data.storeAddress.pickStoreAttribute ===
                        PICKUP_ATTRIBUTES_VALUES.GENERAL_PICKUP_POINT
                            ? []
                            : data.storeAddress.memberLevels,
                };
                return await services.api.post(params, config);
            },
        },
    },
];

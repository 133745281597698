import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 将带有多语言标记的字符串格式化为标准的文本
 */
export function formatLanguage(str: string): string {
    const startMark = '<<';
    const endMark = '>>';
    if (str.startsWith(startMark) && str.endsWith(endMark)) {
        str = str.replace(startMark, '').replace(endMark, '');
        return language.getText(str);
    } else {
        return str;
    }
}

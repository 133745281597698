import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import {
    createPageLayout,
    flexibleFilterForm,
    tableMixin,
    getLanguageProperties,
    getLanguageColumns,
    deletePopConfirmConfig,
} from '@/configs/mixin';
import { IMPORT_TEMPLATE_URL, SPECS_TYPE_OPTIONS } from '@/constants';
import { MEMBER_PRODUCT } from '@/configs/pro-table-constants';
import { recordImportLogs } from '@/services';

/**
 * 会员来源黑名单商品
 */
export const config: Config = {
    entities: {
        memberSourceBlackProduct: {
            apiPath: '/loader/dc-user/admin/memberSource/blackSkuProduct',
            properties: {
                id: { type: 'string' },
                nameMap: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties(
                            'string',
                            'membershipLevel.blackSkuProduct.productName'
                        ),
                    },
                },
                sku: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.SKU>>',
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productSpecsType.title>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.lastedModifyTime>>',
                },
                lastedModifyUser: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.lastedModifyUser>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<keyword>>',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<membershipLevel.blackSkuProduct.productName>>',
                    },
                },
                sku: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuProduct.SKU>>',
                    controlConfig: {
                        allowClear: true,
                    },
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productSpecsType.title>>',
                    options: SPECS_TYPE_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                    },
                },
            },
        },
    },
    components: {
        MemberSourceBlackProductList: {
            entity: 'memberSourceBlackProduct',
            ...createPageLayout([
                {
                    component: 'MemberSourceBlackProductListFilter',
                    style: { marginBottom: 15 },
                    privilege: {
                        path: 'member.member_source.member_source_black_list',
                        level: 'full',
                    },
                },
                {
                    component: 'MemberSourceBlackProductListAction',
                    style: { marginBottom: 15 },
                },
                {
                    privilege: {
                        path: 'member.member_source.member_source_black_list',
                        level: 'full',
                    },
                    component: 'MemberSourceBlackProductListTable',
                },
            ]),
        },
        MemberSourceBlackProductListFilter: {
            ...flexibleFilterForm,
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membershipLevel.blackSkuProduct.productName>>',
                    },
                },
                {
                    property: 'sku',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membershipLevel.blackSkuProduct.skuPlaceholder>>',
                    },
                },
                {
                    property: 'specsType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                    },
                },
            ],
        },
        MemberSourceBlackProductListAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<membershipLevel.importProduct>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        const id = window.location.hash.replace(
                            '#/member-source/black-product-list/',
                            ''
                        );
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('membershipLevel.importProduct'),
                                apiPath: `/dc-user/admin/member_source/product/importData/${id}`,
                                params: { resourceId: response.id },
                            });
                        }
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'member.member_source.member_source_importData_black',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productInventory.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 10,
                        alignSelf: 'center',
                    },
                    // privilege: {
                    //     path: 'member.member_source_view.importData_black',
                    //     level: 'full',
                    // },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.MEMBER_PRODUCT}`,
                },
            ],
        },
        MemberSourceBlackProductListTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: MEMBER_PRODUCT,
            columns: [
                ...getLanguageColumns('nameMap'),
                { property: 'sku' },
                { property: 'specsType' },
                { property: 'lastedModifyTime' },
                { property: 'lastedModifyUser' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        text: '<<delete>>',
                        component: 'TableActionDelete',
                        config: {
                            ...deletePopConfirmConfig,
                            apiPath: '/dc-user/admin/member_source/product/{{row.id}}',
                        },
                        privilege: {
                            path: 'member.member_source.member_source_importData_black_delete',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

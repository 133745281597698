import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { RadioGroup } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { find } from 'lodash';
import { Badge as AntBadge } from 'antd';

const DEFAULT_BADGE_CONFIG = {
    status: 'default',
    text: services.language.getText('unknown'),
};

/**
 * 状态类型
 */
export class RadioMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, displayConfig: any) {
        const { statusConfig } = displayConfig;
        const targetConfig = find(statusConfig, { id: value });
        const props = targetConfig
            ? {
                  status: targetConfig.status,
                  text: targetConfig.name,
              }
            : DEFAULT_BADGE_CONFIG;
        return <AntBadge {...props} />;
    }
    getControlComponent(controlInfo: any) {
        return <RadioGroup {...controlInfo} />;
    }
}

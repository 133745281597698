import React from 'react';
import { KV } from '@/interfaces';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core/lib';
import { message } from 'antd';

interface IProps {
    entity: any;
    text: string;
    config: KV;
}

export const BatchAuditButton: React.FC<IProps> = (props) => {
    const { config, text, entity } = props;
    const handleClick = () => {
        if (!config?.activeIdList?.length) {
            return message.warning(services.language.getText('productShelf.pleaseSelectProducts'));
        }
        services.api
            .post(
                {
                    ids: config.activeIdList,
                    status: config.status,
                },
                {
                    apiPath: `/dc-goods/admin/sku_evaluation/batchStatus`,
                }
            )
            .then(() => {
                entity.search(entity.params);
            })
            .catch((error) => {
                services.errorHandle(error as any);
            });
    };
    return (
        <Button {...config} onClick={handleClick}>
            {text}
        </Button>
    );
};

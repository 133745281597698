import React, { useState, useEffect } from 'react';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { load } from '@comall-backend-builder/core/lib/loaders';

export interface LoadSourceOptionsProps {
    options: AnyObject[];
    source: ApiRequestConfig & { params: AnyObject; deps?: string[] };
    children(options: AnyObject[]): React.ReactElement;
}

export const LoadSourceOptions: React.FC<LoadSourceOptionsProps> = (props) => {
    const { options: defaultOptions, source, children } = props;
    const [options, setOptions] = useState<AnyObject[]>([]);

    const { params = {}, ...config } = source;

    useEffect(() => {
        load('get', {
            ...config,
            params,
        }).then((data: AnyObject | AnyObject[]) => {
            data = data || [];
            if (!Array.isArray(data) && Array.isArray(data.result)) {
                data = data.result;
            }
            setOptions(data as AnyObject[]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children(options.length ? options : defaultOptions);
};

import * as React from 'react';
import { isEqual, cloneDeep, map } from 'lodash';
import {
    TreeNodeNormal as AntTreeNodeNormal,
    TreeNodeSimpleMode as AntTreeNodeSimpleMode,
} from 'antd/lib/tree-select/interface';
import { Loader } from '@comall-backend-builder/core';
import { TreeSelect } from '@comall-backend-builder/components-basis';
import {
    TreeSelectProps,
    TreeNode,
} from '@comall-backend-builder/components-basis/lib/tree-select';

function getTargetItem(options: any, id: any) {
    for (let item of options) {
        if (item.id === id) {
            return item;
        }
        if (item.children) {
            let deepItem: any = getTargetItem(item.children, id);
            if (deepItem) {
                return deepItem;
            }
        }
    }
    return null;
}

type TreeNodeValue = (AntTreeNodeNormal & { id?: string; name?: string }) | AntTreeNodeSimpleMode;

interface IProps extends TreeSelectProps {
    [key: string]: any;
}

export class AsyncTreeSelect extends React.Component<IProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }

    // 初次加载完成
    loaded = false;

    componentDidMount() {
        // 立即加载
        if (this.props.loadNow) {
            const { source, sourceParams } = this.props;
            source && this.onLoadData({ props: { ...sourceParams } });
        }
    }

    // 不能用 getDerivedStateFromProps，否则 onLoadData 的 setState 不更新数据
    componentWillReceiveProps(nextProps: any) {
        if (!isEqual(nextProps.options, this.props.options)) {
            this.setState({
                options: nextProps.options,
            });
        }
    }
    onLoadData = (treeNode: any) => {
        // 一次性加载全部时，不需要二次加载
        if (this.props.loadAll && this.loaded) {
            return Promise.resolve();
        }
        this.loaded = true;

        const { params, source } = this.props;
        const { options } = this.state;
        const { id } = treeNode.props;
        return Loader.load('get', { params: { ...params, id: id, parentId: id }, ...source }).then(
            (response: any) => {
                let newOptions = cloneDeep(options);
                let targetItem = getTargetItem(newOptions, id);
                if (targetItem) {
                    targetItem.children = response;
                } else {
                    newOptions = response;
                }

                this.setState({
                    options: newOptions,
                });
            }
        );
    };

    transformValue = (value: TreeNode): TreeNode | undefined => {
        if (!value) {
            return undefined;
        }
        const { multiple } = this.props;
        if (!multiple) {
            let newVal = value as TreeNodeValue;
            return {
                ...newVal,
                value: newVal.id,
                title: newVal.name,
                label: newVal.name,
            };
        }
        return map(value as Array<TreeNodeValue>, (value) => ({
            ...value,
            value: value.id,
            title: value.name,
            label: value.name,
        }));
    };

    onChange = (value: any) => {
        this.props.onChange(value, this.props.name);
    };

    render() {
        const { options } = this.state;
        const { value, ...props } = this.props;
        const newVal = this.transformValue(value) as TreeNode;
        return (
            <TreeSelect
                {...props}
                dropdownStyle={{ maxHeight: 400, overflowY: 'auto' }}
                value={newVal}
                options={options}
                onChange={this.onChange}
                loadData={this.onLoadData}
            />
        );
    }
}

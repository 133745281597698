/*
 * @Author: zhulu
 * @Date: 2021-09-02 17:01:29
 * @Descripttion: 对象转字符串
 */

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { uniq } from 'lodash';

/**
 * 标题类型
 */
export class Array2StringMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, displayConfig: any) {
        const { keys, row, style, format, name } = displayConfig;

        //@ts-ignore
        const text = format ? format(row[name]) : uniq(keys.map((key) => row[key] + '%')).join('~');
        return <span style={style}>{text}</span>;
    }
}

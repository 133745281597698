/*
 * @Author: zhulu
 * @Description: 商品关联选择商品
 */
import React, { useEffect, useState } from 'react';
import { cloneDeep, map, find } from 'lodash';
import { Input, Button, Table } from 'antd';
import { modes, formats } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';
import { SelectAutoComplete } from './all-array-params';
import { isEqual } from 'lodash/fp';
const EditableContext = React.createContext({});

class EditableCell extends React.Component<any, any> {
    renderCell = (params: any) => {
        const {
            editable,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            onChange,
            ...restProps
        } = this.props;

        return (
            <td {...restProps}>
                {editable ? (
                    <Input
                        style={{ width: '240px' }}
                        placeholder={language.getText('defaultPlaceholderInput')}
                        value={record[dataIndex]}
                        onChange={(e) => onChange(index, e.target.value)}
                    />
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}
const ProductGroupItems = (props: any) => {
    const [dataSource, setDataSource] = useState<any[]>([]);
    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }
        let nDataSource: any[] = (props.value || []).map((item: any, index: number) => {
            let dataSourceItem = find(dataSource, (i) => i.id === item.id);
            let v = {
                ...dataSourceItem,
                ...item,
            };
            return v;
        });
        setDataSource([...nDataSource]);
        if (!isEqual(props.value, nDataSource) && props.value) {
            props.onChange(nDataSource, props.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);
    const onChange = (index: any, val: any) => {
        let nVal: any = cloneDeep(dataSource);
        nVal[index]['sort'] = val;
        props.onChange(nVal, props.name);
    };
    const columns = [
        {
            title: language.getText('productGroup.productName'),
            dataIndex: 'name',
        },
        {
            title: language.getText('productGroup.csku'),
            dataIndex: 'csku',
        },
        {
            title: language.getText('productGroup.sort'),
            dataIndex: 'sort',
            editable: true,
        },
        {
            title: language.getText('actions'),
            dataIndex: 'actions',
            render: (text: any, record: any, index: number) => {
                return (
                    <Button
                        type='link'
                        onClick={() => {
                            let nVal: any = cloneDeep(dataSource);
                            nVal.splice(index, 1);
                            props.onChange(nVal, props.name);
                        }}
                    >
                        {language.getText('delete')}
                    </Button>
                );
            },
        },
    ].map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editable: col.editable,
                onChange: onChange,
            }),
        };
    });
    return (
        <>
            <SelectAutoComplete {...props} selectParamKey='keyword' />
            <EditableContext.Provider value={{}}>
                <Table
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={(_) => _.id}
                    pagination={false}
                />
            </EditableContext.Provider>
        </>
    );
};
export class ProductGroupItemsMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
            mode: 'multiple',
        };
        return <ProductGroupItems {...props} />;
    }
}

export class ProductGroupItemsFormat extends formats.ArrayFormat {
    /**
     * 对数据进行校验
     */
    validate(rule: any, value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: Array<any> | undefined) {
        const result = map(value, (item) => ({
            ...item,
        }));
        return { [key]: result };
    }
}

import { services } from '@comall-backend-builder/core/lib';
import { getStoreInfo } from '@/services/utils';

export default [
    {
        //查询会员等级列表
        key: '/dc-user/admin/memberLevelList',
        loader: {
            get: async (data: any, config: any) => {
                const result: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-user/admin/memberLevel`,
                });
                result.result.forEach((item: any) => {
                    item.picUrl = item.picUrl?.path;
                    item.cardPicUrl = item.cardPicUrl?.path;
                    item.memberPricePicUrl = item.memberPricePicUrl?.path;
                    item.type = `${item?.type}`;

                    return item;
                });

                return result;
            },
        },
    },
    {
        //编辑会员等级
        key: '/dc-user/admin/memberLevel',
        loader: {
            get: async (data: any, config: any) => {
                const result: any = await services.api.get(
                    {},
                    {
                        ...config,
                        apiPath: `/dc-user/admin/memberLevel/${data.id}`,
                    }
                );
                result.expLower && (result.expLower = result.expLower.toString());
                result.expUpper && (result.expUpper = result.expUpper.toString());
                result.picUrl = result.picUrl ? [result.picUrl] : [];
                result.memberPricePicUrl = result.memberPricePicUrl
                    ? [result.memberPricePicUrl]
                    : [];
                result.cardPicUrl = result.cardPicUrl ? [result.cardPicUrl] : [];
                return result;
            },
            put: async (data: any, config: any) => {
                return await services.api.put(
                    { ...data, onlineStoreId: getStoreInfo().id },
                    {
                        ...config,
                        apiPath: `/dc-user/admin/memberLevel`,
                    }
                );
            },
            post: async (data: any, config: any) => {
                return await services.api.post(
                    { ...data, onlineStoreId: getStoreInfo().id },
                    {
                        ...config,
                        apiPath: `/dc-user/admin/memberLevel`,
                    }
                );
            },
        },
    },
    {
        //查询会员来源列表
        key: '/dc-user/admin/memberSourceList',
        loader: {
            get: async (data: any, config: any) => {
                const result: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-user/admin/member_source`,
                });
                result.result.forEach((item: any) => {
                    item.dchLivingLogo = item.dchLivingLogo?.path;
                    item.foodMartLogo = item.foodMartLogo?.path;
                    item.sourceLogo = item.sourceLogo?.path;

                    return item;
                });

                return result;
            },
        },
    },

    {
        //编辑会员来源
        key: '/dc-user/admin/memberSource',
        loader: {
            get: async (data: any, config: any) => {
                const result: any = await services.api.get(
                    {},
                    {
                        ...config,
                        apiPath: `/dc-user/admin/member_source/${data.id}`,
                    }
                );
                result.dchLivingLogo = result.dchLivingLogo ? [result.dchLivingLogo] : [];
                result.foodMartLogo = result.foodMartLogo ? [result.foodMartLogo] : [];
                result.sourceLogo = result.sourceLogo ? [result.sourceLogo] : [];
                return result;
            },
            put: async (data: any, config: any) => {
                return await services.api.put(
                    { ...data },
                    {
                        ...config,
                        apiPath: `/dc-user/admin/member_source`,
                    }
                );
            },
            post: async (data: any, config: any) => {
                return await services.api.post(
                    { ...data },
                    {
                        ...config,
                        apiPath: `/dc-user/admin/member_source`,
                    }
                );
            },
        },
    },
    {
        //黑名单商品
        key: '/dc-user/admin/memberSource/blackSkuProduct',
        loader: {
            get: async (data: any, config: any) => {
                const { id, ...rest } = data;
                if (data.id) {
                    return await services.api.get(
                        { ...rest, sourceId: id },
                        {
                            apiPath: '/dc-user/admin/member_source/product',
                        }
                    );
                }
            },
        },
    },
    {
        //黑名单商品
        key: '/dc-user/admin/memberLevel/blackSkuProduct',
        loader: {
            get: async (data: any, config: any) => {
                const { id, ...rest } = data;
                if (data.id) {
                    return await services.api.get(
                        { ...rest, levelId: id },
                        {
                            apiPath: '/dc-user/admin/memberLevel/product',
                        }
                    );
                }
            },
        },
    },
    {
        //会员价商品
        key: '/dc-price/admin/memberLevel/priceProduct',
        loader: {
            get: async (data: any, config: any) => {
                const { id, ...rest } = data;
                if (data.id) {
                    const result = await services.api.get(
                        { ...rest, requiredMemberLevel: id },
                        {
                            apiPath: '/dc-price/admin/member_prices',
                        }
                    );

                    return result;
                }
            },
        },
    },
    {
        //会员信息列表
        key: '/dc-user/admin/membershipList',
        loader: {
            get: async (data: any, config: any) => {
                const { lastModifyTime } = data;
                if (lastModifyTime) {
                    data.modifyStartTime = lastModifyTime.start;
                    data.modifyEndTime = lastModifyTime.end;
                }
                delete data.lastModifyTime;

                const result = await services.api.get(
                    { ...data },
                    {
                        apiPath: '/dc-user/admin/members',
                    }
                );

                return result;
            },
        },
    },
];

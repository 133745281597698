import { formats } from '@comall-backend-builder/types';

export class StringOptionCheckboxFormat extends formats.StringFormat {
    /**
     * 对数据进行校验
     */
    validate(rule: any, value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any) {
        const _value = value ? value.join(',') : '';
        return { [key]: _value };
    }
}

import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Button, Form, Icon, InputNumber } from 'antd';
import { get } from 'lodash';
import { useMemoizedFn } from 'ahooks';
import { language } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';
import { eventCenter } from '@/services/events';
import { EventCenterKeys } from '@/constants/event-center-keys';
import { PickedTotalContext } from '../..';
import './index.less';

const getText = language.getText;

const BasicForm: React.FC<any> = (props) => {
    const [, setPickedTotal] = useContext(PickedTotalContext);
    const [pickCount, setPickCount] = useState(0);
    const [showMoreItem, setShowMoreItem] = useState(false);

    const validateNum = useCallback(
        (rule, value, callback) => {
            const isNaturalNumber = (number: any) => {
                return /^[0-9]*$/.test(number);
            };
            const { field } = rule;
            const locationInfo = props.locationData.filter(
                (item: any) => `${item.location}` === field
            );
            const fields = props.form.getFieldsValue();
            const { onChange } = props;
            let count = 0;
            const pickData: KV = {};
            Object.keys(fields).forEach((key) => {
                const itemVlaue = fields[key];
                if (isNaturalNumber(itemVlaue)) {
                    count += itemVlaue || 0;
                    pickData[key] = itemVlaue || 0;
                }
            });
            setPickCount(count);
            setPickedTotal((value) => value - pickCount + count);
            onChange && onChange(pickData);
            if (value && !isNaturalNumber(value)) {
                return new Error('只可输入自然数');
            }

            //库存为负数 且拣货数为0时不比较
            if (
                locationInfo &&
                Number(value) > locationInfo[0].quantity &&
                !(locationInfo[0].quantity <= 0 && Number(value) === 0)
            ) {
                return new Error(getText('picking.detail.exceedMaxPickingNumErrMsg'));
            }
            callback();
        },
        [props, pickCount]
    );

    const packingComplete = useMemoizedFn(() => {
        // 剩余需拣货数量
        let remain = props.needPickQuantity;
        // 多仓库按顺序扣减，直至拣货完成
        for (const item of props.locationData) {
            if (remain) {
                if (item.quantity < remain) {
                    // 当前仓库数量小于剩余需拣货数量，没拣完的剩余需拣货数量交给后面的仓库继续扣减
                    props.form.setFieldsValue({
                        [item.location]: item.quantity,
                    });
                    remain -= item.quantity;
                } else {
                    // 当前仓库数量大于等于剩余需拣货数量，直接拣货完成
                    props.form.setFieldsValue({
                        [item.location]: remain,
                    });
                    remain = 0;
                }
            } else {
                props.form.setFieldsValue({
                    [item.location]: 0,
                });
            }
        }

        const pickedCount = props.needPickQuantity - remain;
        setPickCount(pickedCount);
        setPickedTotal((value) => value - pickCount + pickedCount);
        props.onChange?.(props.form.getFieldsValue());
    });

    useEffect(() => {
        (eventCenter.on as Function)(EventCenterKeys.packingComplete, packingComplete);
        return () => {
            (eventCenter.off as Function)(EventCenterKeys.packingComplete, packingComplete);
        };
    }, []);

    return (
        <div className='product-picking-form'>
            <div className='product-pick-info-title'>
                <span className='title'>{getText('picking.detail.pickingInfo')}</span>
                <span>
                    {getText('picking.detail.needPickNum')}：{props.needPickQuantity}
                </span>
                {props.showInputNumber && (
                    <span>
                        {getText('picking.detail.pickedNum')}：{pickCount}
                    </span>
                )}
                {pickCount > props.needPickQuantity && (
                    <span style={{ color: 'red' }}>
                        *{getText('picking.detail.exceedMaxPickedNumErrMsg')}
                    </span>
                )}
                {props.combinationQuantiy && pickCount % props.combinationQuantity !== 0 && (
                    <span style={{ color: 'red' }}>
                        *{getText('picking.detail.combinationErrMsg')}
                    </span>
                )}
                {!get(props, 'locationData') ||
                    (props.locationData.length === 0 && (
                        <span style={{ color: 'red' }}>
                            *{getText('picking.detail.productStockOutErrMsg')}
                        </span>
                    ))}
            </div>
            {get(props, 'locationData') && props.locationData.length > 0 && (
                <Form>
                    <div className='picking-form-item picking-form-item-header'>
                        <div className='product-picking-location'>
                            {getText('picking.detail.locationName')}
                        </div>
                        <div className='product-picking-quantity'>
                            {getText('picking.detail.availableStock')}
                        </div>
                        {props.showInputNumber && (
                            <div className='product-picking-num'>
                                {getText('picking.detail.pickingNum')}
                            </div>
                        )}
                    </div>
                    {props.locationData.map((item: any, index: number) => {
                        const showItem = index < 3 || showMoreItem;
                        return showItem ? (
                            <div className='picking-form-item' key={item.location}>
                                <div className='product-picking-location product-picking-item-info'>
                                    {item.location > 0 ? item.location : ''} {item.locationName}
                                </div>
                                <div className='product-picking-quantity product-picking-item-info'>
                                    {item.quantity}
                                </div>
                                {props.showInputNumber && (
                                    <Form.Item
                                        className='product-picking-num'
                                        validateStatus={
                                            pickCount > props.needPickQuantity ? 'error' : undefined
                                        }
                                    >
                                        {props.form.getFieldDecorator(`${item.location}`, {
                                            rules: [{ validator: validateNum }],
                                            initialValue: item.pickNum,
                                        })(
                                            <InputNumber
                                                className='product-picking-num-input'
                                                placeholder={getText('picking.detail.pickingNum')}
                                            />
                                        )}
                                    </Form.Item>
                                )}
                            </div>
                        ) : (
                            <></>
                        );
                    })}
                </Form>
            )}
            {props.locationData.length > 3 && (
                <Button
                    className='product-show-more'
                    type='link'
                    onClick={() => {
                        setShowMoreItem(!showMoreItem);
                    }}
                >
                    {showMoreItem ? '点击收起列表' : '点击展开更多'}
                    <Icon type={showMoreItem ? 'double-left' : 'double-right'} />
                </Button>
            )}
        </div>
    );
};

export const PickForm = Form.create<any>()(BasicForm);

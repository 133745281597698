import { services } from '@comall-backend-builder/core/lib';

/**
 * 支付明细
 */
export default [
    {
        key: 'dc-payment/paymentRecord/findHisRecordByOrderAndChannel',
        loader: {
            get: async function(data: any, config: any) {
                return await services.api.get(data, config);
            },
        },
    },
];

/*
 * @Author: zhulu
 * @Date: 2022-11-23 16:41:43
 * @Description:  { }
 */
import {
    tableMixin,
    flexibleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
    formFooter,
} from '@/configs/mixin';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { PRODUCT_PRICE_TABLE } from '@/configs/pro-table-constants';
import { IMPORT_TEMPLATE_URL, PRODUCT_SOURCE_TYPE_OPTIONS } from '@/constants';
import { SPECS_TYPE_OPTIONS } from '@/constants';
import { recordImportLogs } from '@/services';
import { services } from '@comall-backend-builder/core';
import { getStoreInfo } from '@/services/utils';

function checkPrice(value: any, callback: any) {
    const reg = /^0\.[0-9]{1,2}$|^[1-9]\d{0,7}\.\d{1,2}$|^[1-9]\d{0,7}$/;
    if (!value) {
        callback();
    } else if (!reg.test(value)) {
        callback(new Error(services.language.getText('productPrice.priceError')));
    }
    callback();
}

export const config: Config = {
    entities: {
        productPrice: {
            apiPath: '/loader/dc-goods/admin/rskus/price/store/manage/findAllByLoginUser',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties('string', 'productPrice.name'),
                    },
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                },
                rsku: {
                    type: 'string',
                    displayName: 'Goods ID',
                },
                specsType: {
                    type: 'string',
                    displayName: '<<productPrice.specsType>>',
                },
                price: {
                    type: 'string',
                    displayName: '<<productPrice.price>>',
                },
                sourceType: {
                    type: 'string',
                    displayName: '<<productPrice.sourceType>>',
                },
                middleCurrentPrice: {
                    type: 'string',
                    displayName: '<<productPrice.middleCurrentPrice>>',
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<productPrice.lastedModifyTime>>',
                },
                lastedModifyUserRealName: {
                    type: 'string',
                    displayName: '<<productPrice.lastedModifyUser>>',
                },
                interCurrentPrice: {
                    type: 'string',
                    displayName: '<<productPrice.interCurrentPrice>>',
                },
                interLastedModifyTime: {
                    type: 'string',
                    displayName: '<<productPrice.interLastedModifyTime>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<productPrice.keyWords>>',
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                    controlConfig: {
                        placeholder: '<<cskuPlaceHolder>>',
                    },
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productPrice.specsType>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                sourceType: {
                    type: 'string.options.select',
                    displayName: '<<productPrice.sourceType>>',
                    options: PRODUCT_SOURCE_TYPE_OPTIONS,
                },
                merchantIdList: {
                    type: 'string.options.select',
                    displayName: '<<productPrice.merchant>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant/findMerchantList',
                    },
                },
            },
        },
        productPriceEdit: {
            apiPath: '/loader/dc-price/admin/price/getForMiddleEdit',
            properties: {
                id: { type: 'string' },
                cskuName: {
                    type: 'string',
                    displayName: '<<productPrice.cskuName>>',
                    modifiable: false,
                },
                cskuNameTc: {
                    type: 'string',
                    displayName: '<<productPrice.cskuNameTc>>',
                    modifiable: false,
                },
                cskuNameEn: {
                    type: 'string',
                    displayName: '<<productPrice.cskuNameEn>>',
                    modifiable: false,
                },
                csku: {
                    type: 'string',
                    displayName: '<<productPrice.sku>>',
                    modifiable: false,
                },
                rsku: {
                    type: 'string',
                    displayName: 'Goods ID',
                    modifiable: false,
                },
                cskuSpecsType: {
                    type: 'string',
                    displayName: '<<productPrice.specsType>>',
                    modifiable: false,
                },
                cskuSourceType: {
                    type: 'string',
                    displayName: '<<productPrice.sourceType>>',
                    modifiable: false,
                },
                interCurrentPrice: {
                    type: 'string',
                    displayName: '<<productPrice.interCurrentPrice>>',
                    modifiable: false,
                },
                interLastedModifyTime: {
                    type: 'string',
                    displayName: '<<productPrice.interLastedModifyTime>>',
                    modifiable: false,
                },
                suggestedRetailPrice: {
                    type: 'string',
                    displayName: '<<productPrice.suggestedRetailPrice>>',
                    rules: [
                        { required: true },
                        {
                            validator: (_rule: any, value: any, callback: any) => {
                                checkPrice(value, callback);
                            },
                        },
                    ],
                },
                price: {
                    type: 'string',
                    displayName: '<<productPrice.locationIdPrice>>',
                    rules: [
                        { required: true },
                        {
                            validator: (_rule: any, value: any, callback: any) => {
                                checkPrice(value, callback);
                            },
                        },
                    ],
                },
                costPrice: {
                    type: 'string',
                    displayName: '<<productPrice.costPrice>>',
                    controlConfig: {
                        style: { marginBottom: '8px' },
                    },
                    rules: [
                        { required: true },
                        {
                            validator: (_rule: any, value: any, callback: any) => {
                                checkPrice(value, callback);
                            },
                        },
                    ],
                },
                rangePrices: {
                    type: 'array.priceSection',
                    displayName: '<<productPrice.section>>',
                    options: [],
                    controlConfig: {
                        maxCount: 10,
                    },
                    rules: [
                        {
                            validator: (_rule: any, value: any, callback: any) => {
                                if (value.some((item: any) => item.price && !item.startTime)) {
                                    return new Error('区间生效时间未填写');
                                } else if (
                                    value.some((item: any) => item.startTime && !item.price)
                                ) {
                                    return new Error('区间价格未填写');
                                } else if (
                                    value.some((item: any) => item.startTime && item.price)
                                ) {
                                    checkPrice(value.map((item: any) => item.price)[0], callback);
                                }
                                callback();
                            },
                        },
                    ],
                },
                divisionNumber: {
                    type: 'string',
                },
                retailStoreId: {
                    type: 'string',
                },
            },
        },
        priceChangeLogTable: {
            apiPath: '/dc-price/admin/priceLogs',
            properties: {
                afterPrice: {
                    type: 'string',
                    displayName: '<<productPrice.salePrice>>',
                },
                content: {
                    type: 'string',
                    displayName: '<<productPrice.changeCause>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<productPrice.changeTime>>',
                },
                createUserName: {
                    type: 'string',
                    displayName: '<<productPrice.Operator>>',
                },
            },
        },
        priceChangeLogDetail: {
            apiPath: '/loader/dc-goods/admin/rskus/:id/csku',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<productPrice.enName>>',
                },
                csku: {
                    type: 'string',
                    displayName: '<<productPrice.sku>>',
                },
            },
        },
    },
    components: {
        ProductPriceView: {
            component: 'Viewport',
        },
        ProductPrice: {
            component: 'FlexLayout',
            entity: 'productPrice',
            // entities: [
            //     { name: 'productRetailStoreSelector', entityName: 'productRetailStoreSelector' },
            // ],
            direction: 'vertical',
            className: 'product-price-view',
            items: [
                {
                    component: 'ProductPriceList',
                    params: {},
                    filterConfig: {
                        component: 'ProductFilter',
                        style: { marginBottom: 15 },
                    },
                    buttonConfig: {
                        component: 'ProductPriceButtons',
                        style: { marginBottom: 15 },
                    },
                    tableConfig: {
                        component: 'ProductPriceTable',
                    },
                },
            ],
            privilege: {
                path: 'goods.goods_price.view_list',
                level: 'full',
            },
        },
        ProductFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productPrice.keyWordsPlaceHolder>>',
                    },
                },
                { property: 'csku' },
                {
                    property: 'specsType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'sourceType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'merchantIdList',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            ],
        },
        ProductPriceButtons: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<productPrice.importPrice>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('productPrice.importPrice'),
                                apiPath: `/dc-price/admin/priceImport/importPrice`,
                                params: {
                                    resourceId: response.id,
                                    onlineStoreId: getStoreInfo().id,
                                },
                            });
                        }
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'goods.goods_price.import_goods_price',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productPrice.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 20,
                        alignSelf: 'center',
                    },
                    privilege: {
                        path: 'goods.goods_price.download_import_template',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.PRICE_TEMPLATE}`,
                },
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<productPrice.exportProductPrice>>',
                    icon: 'download',
                    style: {
                        marginLeft: 15,
                    },
                    source: {
                        apiPath: `/dc-goods/admin/rskus/export/take`,
                    },
                    range: {
                        apiPath: '/dc-goods/admin/rskus/export/count',
                        perPage: 100000,
                    },
                    formatter: (params: any) => {
                        return params;
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: `goods.goods_price.ex_price_count`,
                        level: 'full',
                    },
                },
            ],
        },
        ProductPriceTable: {
            ...tableMixin,
            component: 'ProTable',
            loadFirstPage: false,
            componentName: PRODUCT_PRICE_TABLE,
            className: 'product-price-table',
            columns: [
                ...getLanguageColumns('name', { width: 200 }),
                { property: 'csku', width: '140' },
                { property: 'rsku', width: '140' },
                { property: 'specsType', width: '140' },
                { property: 'price', width: '140', align: 'money' },
                { property: 'sourceType', width: '140' },
                { property: 'middleCurrentPrice', width: '140' },
                { property: 'lastedModifyTime', width: '200' },
                { property: 'lastedModifyUserRealName', width: '200' },
                { property: 'interCurrentPrice', width: '200' },
                { property: 'interLastedModifyTime', width: '200' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        text: '<<productPrice.priceChangeLog>>',
                        type: 'link',
                        path: '/product-price/price-change-log/{{row.rsku}}',
                        privilege: {
                            path: 'goods.goods_price.price_log_list',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/product-price/edit/{{row.onlineStoreId}}/{{row.csku}}/{{row.rsku}}',
                        privilege: {
                            path: 'goods.goods_price.edit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        PriceChangeLogTables: {
            ...tableMixin,
            entity: 'priceChangeLogTable',
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('table.total'), {
                        total,
                    });
                },
            },
            className: 'product-inventory-table',
            columns: [
                { property: 'afterPrice', width: '140' },
                { property: 'content', width: '140' },
                { property: 'createTime', width: '140' },
                { property: 'createUserName', width: '140' },
            ],
        },
        /**
         * 价格变动日志
         */
        PriceChangeLogPage: {
            component: 'Card',
            entities: [
                {
                    name: 'priceChangeLogTable',
                    entityName: 'priceChangeLogTable',
                },
                {
                    name: 'priceChangeLogDetail',
                    entityName: 'priceChangeLogDetail',
                },
            ],
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    { component: 'PriceChangeLogDetail' },
                    { component: 'PriceChangeLogTables' },
                ],
            },
        },
        PriceChangeLogDetail: {
            component: 'Detail',
            entity: 'priceChangeLogDetail',
            loaderType: 'get',
            direction: 'inline',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'csku',
                },
            ],
        },
        /**
         * 编辑
         */
        ProductPriceEditPage: {
            component: 'Card',
            content: {
                component: 'ProductPriceEditForm',
            },
        },
        ProductPriceEditForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            entity: 'productPriceEdit',
            labelCol: 6,
            controlCol: 9,
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                { property: 'cskuName' },
                { property: 'cskuNameTc' },
                { property: 'cskuNameEn' },
                { property: 'csku' },
                { property: 'rsku' },
                { property: 'cskuSpecsType' },
                { property: 'interCurrentPrice' },
                { property: 'interLastedModifyTime' },
                { property: 'cskuSourceType' },
                { property: 'suggestedRetailPrice' },
                { property: 'price' },
                { property: 'costPrice' },
                { property: 'rangePrices' },
                {
                    property: 'divisionNumber',
                    className: 'hidden',
                },
                {
                    property: 'retailStoreId',
                    className: 'hidden',
                },
            ],
        },
    },
};

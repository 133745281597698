import React, { Component } from 'react';
import { Modal as AntModal } from 'antd';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core/lib';

export interface MarkGroupDeleteProps {
    entity: Entity;

    /**
     * 按钮文案
     */
    text: string;

    /**
     * 状态
     */
    status: string;

    /**
     * 标题
     */
    title: string;

    /**
     * 提示
     */
    toolTip: string;

    /**
     * 接口地址
     */
    api: string;

    /**
     * 点击事件
     */
    onClick(): void;

    row: any;

    /**
     * 字体颜色
     */
    color: string;

    //区分是否不区分状态全部展示提示语
    isMarkGroup: boolean;
}

export class MarkGroupDelete extends Component<MarkGroupDeleteProps, any> {
    handleClick = () => {
        //@ts-ignore
        let { row, entity, api, title, toolTip, isMarkGroup, params } = this.props;

        AntModal.confirm({
            title: title,
            content: row.status === '0' && isMarkGroup ? '' : toolTip,
            okText: services.language.getText('confirm'),
            cancelText: services.language.getText('cancel'),
            icon: 'exclamation-circle',
            className: 'mark-group-modal',
            onOk() {
                services.api
                    .delete({}, { apiPath: services.interpolate(api, { row }) })
                    .then(() => {
                        entity.search({ ...entity.params, ...params });
                    })
                    .catch(services.errorHandle);
            },
        });
    };
    render() {
        let { text, color } = this.props;
        const props = {
            style: {
                display: 'inline-block',
                color: color,
                marginRight: 10,
                cursor: 'pointer',
            },
            onClick: this.handleClick,
        };
        return <a {...props}>{text}</a>;
    }
}

import { services } from '@comall-backend-builder/core/lib';

export default [
    {
        key: '/dc-stock/admin/category_stocks',
        loader: {
            get: async function(params: any, config: any) {
                if (params.id) {
                    const response: any = await services.api.get(params, config);
                    const {
                        categoryId,
                        categoryName,
                        organizationId,
                        organizationName,
                        ...rest
                    } = response;
                    if (categoryId && categoryName) {
                        rest.categoryId = {
                            id: categoryId,
                            name: categoryName,
                        };
                    }
                    if (organizationId && organizationName) {
                        rest.organizationId = {
                            id: organizationId,
                            name: organizationName,
                        };
                    }
                    return rest;
                } else {
                    config.apiPath = `/dc-stock/admin/category_stocks`;
                    if (params.organizationId && params.storeId) {
                        return await services.api.get(params, config);
                    } else {
                        return [];
                    }
                }
            },
            put: async function(params: any, config: any) {
                const { organizationId, categoryId, ...rest } = params;
                if (organizationId) {
                    rest.organizationId = organizationId.id;
                }
                if (categoryId) {
                    rest.categoryId = categoryId.id;
                }
                return await services.api.put(rest, config);
            },
        },
    },
    {
        key: '/dc-stock/admin/category_stocks/tree',
        loader: {
            get: async function(params: any, config: any) {
                config.apiPath = `/dc-goods/admin/offline_categories`;
                // params.filter_two_level = true;
                const response: any = await services.api.get(params, config);
                let result = response.result;
                const treeData = [];
                for (let item of result) {
                    treeData.push({ ...item, isLeaf: item.isLeaf });
                }
                return { id: params.parentId, children: treeData };
            },
        },
    },
];

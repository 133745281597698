import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, deletePopConfirmConfig, formFooter } from '@/configs/mixin';
import { STATUS_OPTIONS } from '@/constants';

export const config: Config = {
    entities: {
        roles: {
            apiPath: '/loader/dc-user/admin/roles',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<privilegeManage.roles.roleName>>',
                    controlConfig: {
                        placeholder: '<<privilegeManage.roles.roleNamePlaceholder>>',
                        style: {
                            width: '50%',
                        },
                        maxLength: 50,
                    },
                    rules: [
                        { required: true, whitespace: true },
                        {
                            min: 1,
                            max: 50,
                            message: '<<privilegeManage.roles.roleNameNoLength>>',
                        },
                    ],
                },
                roleDesc: {
                    type: 'string.text.paragraph',
                    displayName: '<<privilegeManage.roles.roleDesc>>',
                    controlConfig: {
                        placeholder: '<<privilegeManage.roles.roleDescPlaceholder>>',
                        style: {
                            width: '50%',
                        },
                        maxLength: 500,
                    },
                    rules: [
                        { required: true, whitespace: true },
                        {
                            min: 1,
                            max: 500,
                            message: '<<privilegeManage.roles.roleDescNoLength>>',
                        },
                    ],
                },
                status: {
                    type: 'string.status',
                    displayName: '<<privilegeManage.roles.status>>',
                },
                menuIds: {
                    type: 'array.rolePrivilege',
                    rules: [
                        {
                            required: true,
                            message: '<<privilegeManage.roles.menuIdsRequired>>',
                        },
                    ],
                    options: [],
                    source: {
                        apiPath: '/dc-user/admin/menus',
                    },
                    displayName: '<<privilegeManage.roles.setting>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<keyword>>',
                    controlConfig: {
                        placeholder: '<<privilegeManage.roles.keywordPlaceHolder>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<privilegeManage.roles.status>>',
                    options: [
                        { id: '1', name: '<<statusValue.enabled>>' },
                        { id: '0', name: '<<statusValue.disabled>>' },
                    ],
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                        style: { width: '170px' },
                    },
                },
            },
        },
    },
    components: {
        RolesContainer: {
            component: 'Viewport',
        },
        RolesPage: {
            component: 'Card',
            entity: 'roles',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [{ component: 'RolePageHeader' }, { component: 'RolesTable' }],
                privilege: {
                    path: 'privilege.role.view_list',
                    level: 'full',
                },
            },
        },
        RolePageHeader: {
            component: 'FlexLayout',
            style: {
                marginBottom: 10,
            },
            items: [
                {
                    component: 'RolesButtons',
                    privilege: {
                        path: 'privilege.role.add',
                        level: 'full',
                    },
                },
                { component: 'RolesFilter' },
            ],
        },
        RolesButtons: {
            component: 'GridLayout',
            items: [
                {
                    component: 'Button',
                    text: '<<privilegeManage.roles.addRole>>',
                    icon: 'plus',
                    type: 'primary',
                    route: '/privilege/roles/add',
                },
            ],
        },
        RolesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            fields: [{ property: 'keyword' }],
        },
        RolesTable: {
            ...tableMixin,
            scroll: { x: 'widthFix' },
            columns: [
                { property: 'name', width: '30%' },
                { property: 'roleDesc', width: '45%' },
            ],
            actionColumn: {
                title: '<<actions>>',
                items: [
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/privilege/roles/edit/{{row.id}}',
                        privilege: {
                            path: 'privilege.role.view_detail',
                            level: 'full',
                        },
                        // statusKey: 'row.modify',
                    },
                    {
                        type: 'delete',
                        text: '<<delete>>',
                        config: {
                            type: 'link',
                            tooltip: null,
                            ...deletePopConfirmConfig,
                        },
                        privilege: {
                            path: 'privilege.role.delete',
                            level: 'full',
                        },
                        statusKey: 'row.modify',
                    },
                ],
            },
        },
        RolesAddPage: {
            component: 'Card',
            content: {
                component: 'RolesAddForm',
            },
        },
        RolesAddForm: {
            component: 'CreateForm',
            entity: 'roles',
            labelCol: 5,
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'roleDesc',
                },
                {
                    property: 'menuIds',
                },
            ],
        },
        RolesEditPage: {
            component: 'Card',
            content: {
                component: 'RolesEditForm',
            },
        },
        RolesEditForm: {
            component: 'ModifyForm',
            entity: 'roles',
            loaderType: 'get',
            labelCol: 5,
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'roleDesc',
                },
                {
                    property: 'menuIds',
                },
            ],
        },
    },
};

/* eslint-disable */
import React, { PureComponent } from 'react';
import { Form, message, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { map, find } from 'lodash';

import './index.less';

interface IProps {
    entity: any;
    dispatch: any;
    componentId: string;
    row?: any;
}

interface State {
    visible: boolean;
    options: Array<any>;
    onlineStoreId: any;
    result: Array<any>;
}

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

class CopyButton extends PureComponent<IProps, State> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            visible: false,
            options: [],
            onlineStoreId: '',
            result: [],
        };
    }

    formRef = React.createRef<any>();

    handleOk = async () => {
        const { onlineStoreId, result } = this.state;
        const { row, entity } = this.props;
        const onlineStoreCode = find(result, (item: any) => item.onlineStoreId === onlineStoreId)
            ?.onlineStoreCode;

        if (!onlineStoreId) {
            return;
        }

        try {
            await services.api.post(
                { onlineStoreId, onlineStoreCode },
                { apiPath: `/dc-cms-api/admin/new_visual_config/page/copy/${row.id}` }
            );
            this.handleCancel();
            message.success(services.language.getText('homeManage.copy.success'));

            entity.search(entity.params);
        } catch (e) {
            services.errorHandle(e as any);
        }
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    onChange = (value: any) => {
        this.setState({ onlineStoreId: value });
    };

    // 点击复制按钮
    copyHandle = async () => {
        const { options } = this.state;
        const { touchPoint } = this.props.row || {};

        if (!options.length) {
            try {
                const result: any =
                    (await services.api.get(
                        { touchPoint },
                        { apiPath: '/dc-cms-api/admin/new_visual_config/online_store/pull_list' }
                    )) || [];

                let options: any = map(result, (item) => ({
                    id: item.onlineStoreId,
                    name: item.onlineStoreName,
                }));

                this.setState({ options, result });
            } catch (e) {}
        }

        this.setState({ visible: true });
    };

    render() {
        const { visible, options } = this.state;

        return (
            <div className='copy-warp'>
                <a className='copy-btn' onClick={() => this.copyHandle()}>
                    {services.language.getText('homeManage.copy.btnText')}
                </a>

                {/* @ts-ignore */}
                <Modal
                    title={services.language.getText('homeManage.copy.title')}
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Form ref={this.formRef}>
                        <Form.Item
                            label={services.language.getText('homeManage.copy.onlineStoreId')}
                            required
                            {...FormItemLayout}
                        >
                            {/* @ts-ignore */}
                            <Select onChange={this.onChange}>
                                {options.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>

                            <p className='ant-form-extra'>
                                {services.language.getText('homeManage.copy.extra')}
                            </p>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export const HomeManageCopyButton = connect()(CopyButton);

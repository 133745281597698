import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { formFooter, getLanguageColumns, getLanguageProperties, tableMixin } from '@/configs/mixin';

export const config: Config = {
    entities: {
        offlineCategory: {
            apiPath: '/loader/dc-goods/admin/offline_categories',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<offlineCategory.categoryId>>',
                    rule: [{ required: true }],
                },
                parentId: {
                    type: 'string',
                    displayName: '<<offlineCategory.categoryId>>',
                },
                nameMap: {
                    properties: getLanguageProperties('string', 'offlineCategory.name'),
                    type: 'object.subForm',
                },
                code: {
                    type: 'string',
                    displayName: '<<offlineCategory.categoryId>>',
                },
                specialAttributeTip: {
                    type: 'string',
                    displayName: '<<offlineCategory.specialAttributeTip>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<offlineCategory.keyWords>>',
                },
            },
        },
        specialAttribute: {
            apiPath: '/loader/dc-goods/admin/offline_categories_attribute',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<offlineCategory.categoryId>>',
                    rule: [{ required: true }],
                },
                specialAttributeTip: {
                    type: 'string',
                    displayName: '<<offlineCategory.specialAttributeTip>>',
                },
                categoryName: {
                    type: 'string',
                    displayName: '<<offlineCategory.name>>',
                    modifiable: false,
                },
                attributeList: {
                    type: 'array.specialAttribute',
                    displayName: '<<offlineCategory.attribute>>',
                    options: [],
                    controlConfig: {
                        maxCount: 50,
                    },
                },
                // categoryName: {
                //     type: 'string',
                //     displayName: '<<offlineCategory.specialAttributeTip>>',
                //     properties: getLanguageProperties('string.text.trim', 'offlineCategory.name'),
                //     modifiable: false,
                // },
            },
        },
    },

    components: {
        OfflineCategoryContainer: {
            component: 'Viewport',
        },
        OfflineCategoryManagement: {
            component: 'Card',
            entity: 'offlineCategory',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    { component: 'OfflineCategoryFilter' },
                    { component: 'OfflineCategoryContent' },
                ],
                privilege: {
                    path: 'category.offline_categories.view_offline_categories_list',
                    level: 'full',
                },
            },
        },
        OfflineCategoryFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            style: {
                marginBottom: '30px',
                textAlign: 'right',
            },
            reset: true,
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<offlineCategory.categoryOrCode>>',
                    },
                },
            ],
        },
        OfflineCategoryContent: {
            component: 'FlexLayout',
            items: [
                {
                    component: 'CategorySelectorContainer',
                    noStore: true,
                    treeSource: '/loader/dc-goods/admin/offline_categories/offline_category_tree',
                    rightComponent: 'OfflineCategoryList',
                    listPrivilege: {
                        path: 'category.offline_categories.view_offline_categories_list_tree',
                        level: 'full',
                    },
                },
            ],
        },
        OfflineCategoryList: {
            ...tableMixin,
            columns: [...getLanguageColumns('nameMap'), { property: 'code' }],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        text: '<<offlineCategory.specialAttribute>>',
                        type: 'link',
                        path: '/offlineCategory/specialAttribute/{{row.id}}',
                        privilege: {
                            path: 'category.offline_categories.Set_specific_attributes',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        /**
         * 设置分类特有属性
         */
        SpecialAttributePage: {
            component: 'Card',
            content: {
                component: 'SpecialAttributeForm',
            },
        },
        SpecialAttributeForm: {
            component: 'ModifyForm',
            loaderType: 'get',
            entity: 'specialAttribute',
            labelCol: 4,
            controlCol: 16,
            footer: formFooter,
            onSubmitSuccess: () => {
                message.success(services.language.getText('successFully'));
                services.navigation.goBack();
            },
            fields: [
                {
                    property: 'specialAttributeTip',
                    className: 'nameTip',
                },
                { property: 'categoryName' },
                {
                    property: 'attributeList',
                    rules: [
                        {
                            validator: (_rule: any, _value: any, callback: any) => {
                                if (
                                    _value &&
                                    _value.length > 0 &&
                                    _value.some((item: any) => {
                                        const hasValue: any[] = Object.values(item.nameMap).filter(
                                            (i: any) => !!i
                                        );
                                        return [0, 3].indexOf(hasValue.length) < 0 || !item.sort;
                                    })
                                ) {
                                    return new Error('请填写属性');
                                }
                                callback();
                            },
                        },
                    ],
                },
            ],
        },
    },
};

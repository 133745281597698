import { Config } from '@comall-backend-builder/core/lib/parser';

export const productFormConfig: Config = {
    entities: {},
    components: {
        ProductDetailAddWrap: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ProductDetailAddForm',
                },
            ],
        },
        ProductDetailAddForm: {
            component: 'ProductForm',
            config: {
                type: 'add',
            },
        },
        ProductDetailEditWrap: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ProductDetailEditForm',
                },
            ],
        },
        ProductDetailEditForm: {
            component: 'ProductForm',
            config: {
                type: 'edit',
            },
        },
        ProductDetailWrap: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ProductDetailForm',
                },
            ],
        },
        ProductDetailForm: {
            component: 'ProductForm',
            config: {
                type: 'view',
            },
        },
    },
};

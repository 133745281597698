import { Config, Entity } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '../mixin';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';

export const config: Config = {
    entities: {
        thirdPartyDelivery: {
            apiPath: '/base-order/admin/third/order/deliver',
            properties: {
                channel: {
                    type: 'string',
                    displayName: '<<thirdPartyDelivery.channel>>',
                },
                orderNumber: {
                    type: 'string',
                    displayName: '<<thirdPartyDelivery.orderNumber>>',
                },
                parentOrderNumber: {
                    type: 'string',
                    displayName: '<<thirdPartyDelivery.parentOrderNumber>>',
                },
                orderTime: {
                    type: 'string',
                    displayName: '<<thirdPartyDelivery.orderTime>>',
                },
                responseBody: {
                    type: 'string',
                    displayName: '<<thirdPartyDelivery.responseBody>>',
                },
            },
            filters: {
                channelId: {
                    type: 'string.options.select',
                    displayName: '<<thirdPartyWarehouse.channel>>',
                    options: [],
                    source: {
                        apiPath: '/base-order/admin/third/channel',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        ThirdPartyDeliveryPage: {
            component: 'Card',
            entity: 'thirdPartyDelivery',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'ThirdPartyDeliveryFilter',
                    },
                    {
                        component: 'ThirdPartyDeliveryActions',
                    },
                    {
                        component: 'ThirdPartyDeliveryTable',
                    },
                ],
                privilege: {
                    path: 'channel.channel_third_order_deliery.view_list',
                    level: 'full',
                },
            },
        },
        ThirdPartyDeliveryFilter: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterFormPlus',
                    style: {
                        marginBottom: 30,
                    },
                    direction: 'inline',
                    submit: {
                        text: '<<query>>',
                    },
                    reset: true,
                    fields: [
                        {
                            property: 'channelId',
                        },
                    ],
                },
            ],
        },
        ThirdPartyDeliveryActions: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 20,
            },
            items: [
                {
                    component: 'ButtonPlus',
                    text: '<<thirdPartyDelivery.allRepush>>',
                    icon: 'plus',
                    type: 'primary',
                    style: {
                        marginRight: 20,
                    },
                    popconfirm: {
                        title: '<<thirdPartyDelivery.allRepushTips>>?',
                    },
                    async onClick(_: any, { entity }: { entity: Entity }) {
                        const params = Object.assign(
                            {},
                            entity.params,
                            entity.filters
                        ) as AnyObject;
                        await services.api.put(params, {
                            apiPath: '/base-order/admin/third/order/deliver/all',
                        });
                        message.success(services.language.getText('thirdPartyDelivery.repushMsg'));
                        entity.search(params);
                    },
                    privilege: {
                        path: 'channel.channel_third_order_deliery.send_all',
                        level: 'full',
                    },
                },
            ],
        },
        ThirdPartyDeliveryTable: {
            ...tableMixin,
            rowKey: 'id',
            columns: [
                { property: 'channel' },
                { property: 'parentOrderNumber' },
                { property: 'orderNumber' },
                { property: 'orderTime' },
                { property: 'responseBody' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<thirdPartyDelivery.repush>>',
                        config: {
                            actionType: 'delete',
                            rowKey: 'id',
                            deleteConfig: {
                                title: '<<thirdPartyDelivery.repushTips>>？',
                                method: 'put',
                                params: {
                                    channelId: '{{entity.filters.channelId}}',
                                },
                                callback() {
                                    message.success(
                                        services.language.getText('thirdPartyDelivery.repushMsg')
                                    );
                                },
                            },
                        },
                        privilege: {
                            path: 'channel.channel_third_order_deliery.send',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

import React, { Component, createElement } from 'react';
import { Tabs as AntTabs, Modal, DatePicker, Button, LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { connect } from 'react-redux';
import { camelCase, get } from 'lodash';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { extendsParentProps } from '@comall-backend-builder/components-basis';
import { Privilege } from '@/components/privilege';
import { StoreTreeSelector } from '@/components/store-tree-selector';
import { OFFLINE_ORDER_STATUS_OPTIONS } from '@/constants/offline-order';
import { language } from '@comall-backend-builder/core/lib/services';
import { paginationMixin } from '@/configs';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const AntTabPane = AntTabs.TabPane;

const DEFAULT_ACTIVE_TAB = 'ALL';

export class OfflineOrderTabsFilter extends Component {
    status;
    tabs = OFFLINE_ORDER_STATUS_OPTIONS;
    state = {
        activeKey: null,
        statistics: {},
    };

    newProps = {
        refresh: this.fetchOrganization,
    };
    paneProps = extendsParentProps(this.newProps, this.props);
    otherTypeOptions = [];
    componentWillMount() {
        const {
            entity: { filters },
            params: { orderStatus = DEFAULT_ACTIVE_TAB },
        } = this.props;
        let { entity } = this.props;
        entity.filtersChange({
            organizationId: 1002,
        });
        this.setState({
            activeKey:
                filters && filters.orderStatus ? filters.orderStatus : orderStatus.toUpperCase(),
        });
        if (typeof this.props.params.orderStatus === 'undefined') {
            const history = services.navigation.getHistory();
            if (!filters || (filters && !filters.orderStatus)) {
                history.replace({ pathname: '/offlineOrders/' + orderStatus.toLowerCase() });
            } else {
                history.replace({ pathname: '/offlineOrders/' + filters.orderStatus });
            }
        } else {
            let { entity } = this.props;
            const fields = Object.assign({}, entity.filters, {
                orderStatus:
                    filters && filters.orderStatus
                        ? filters.orderStatus
                        : orderStatus.toUpperCase() === 'ALL'
                        ? undefined
                        : orderStatus.toUpperCase(),
            });
            entity.filtersChange(fields);
        }
    }

    componentWillReceiveProps(props) {
        const {
            params: { orderStatus },
        } = props;
        this.setState({
            activeKey: (orderStatus || DEFAULT_ACTIVE_TAB).toUpperCase(),
        });
    }

    fetchOrganization = async (resetPage = false) => {
        const { entity } = this.props;
        const { orderStatus: prevOrderStatus } = entity.filters;
        if (resetPage)
            entity.pageChange(Object.assign({}, entity.paging, { page: 1, perPage: 10 }));
        this.fetchStatistics();
        entity.search(entity.params, () => prevOrderStatus !== entity.filters.orderStatus);
    };

    fetchStatistics = () => {
        const { entity } = this.props;
        services.api
            .get(
                {
                    pageType: 'order_list',
                    onlineStoreId: entity.filters.onlineStoreIds,
                    organizationId: 1002,
                },
                {
                    apiPath: '/base-order/admin/order/offline_statistics',
                }
            )
            .then((rep) => {
                this.setState({
                    statistics: rep,
                });
            });
    };

    onChange = (key) => {
        const { entity } = this.props;
        entity.filtersChange({
            orderStatus: key.toUpperCase() === 'ALL' ? undefined : key,
        });
        const history = services.navigation.getHistory();
        history.replace({ pathname: '/offlineOrders/' + key.toLowerCase() });
        this.fetchOrganization(true);
    };
    onChangeStore = (onlineStores) => {
        const { entity } = this.props;
        const onlineStoreIds = onlineStores.map((item) => item.id).join(',');
        if (!onlineStoreIds) return;
        entity.filtersChange({
            onlineStoreIds,
        });
        entity.params = entity.params || {};
        entity.params.onlineStoreIds = onlineStoreIds;
        this.fetchOrganization(true);
    };
    renderTabPane = () => {
        const {
            params: { orderStatus },
        } = this.props;
        let activeKey = (orderStatus || DEFAULT_ACTIVE_TAB).toUpperCase();
        return this.tabs.map((item) => {
            return (
                <AntTabPane
                    className={item.className}
                    tab={`${item.name} ( ${this.state.statistics[camelCase(item.id)] || 0} )`}
                    key={item.id}
                >
                    {activeKey !== item.id ? null : (
                        <OfflineOrderListPane
                            {...this.paneProps}
                            fetchStatistics={this.fetchStatistics}
                            otherTypeOptions={this.otherTypeOptions}
                            activeKey={activeKey}
                            config={this.props.config}
                        />
                    )}
                </AntTabPane>
            );
        });
    };
    render() {
        return (
            <div className='offline-order-list'>
                <div className='order-list-header'>
                    <StoreTreeSelector
                        style={{
                            position: 'static',
                        }}
                        checkable={false}
                        selectable={true}
                        onChange={this.onChangeStore}
                    />
                </div>
                <AntTabs activeKey={this.state.activeKey} onChange={this.onChange} animated={false}>
                    {this.renderTabPane()}
                </AntTabs>
            </div>
        );
    }
}

const ExportStatementButton = ({ onlineStoreIds }) => {
    const getExportDate = () =>
        new Promise((resolve, reject) => {
            let exportDate = [];
            const handleChange = (_, dateString) => {
                exportDate = dateString;
            };
            Modal.confirm({
                icon: null,
                title: language.getText('offlineOrder.exportStatements'),
                content: (
                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ flexShrink: 0, marginRight: '8px', fontWeight: 'bold' }}>
                            {language.getText('offlineOrder.create.payTime')}:
                        </div>
                        <LocaleProvider locale={zhCN}>
                            <DatePicker.RangePicker width={220} onChange={handleChange} />
                        </LocaleProvider>
                    </div>
                ),
                // okButtonProps: {disabled: !exportDate.length},
                okText: language.getText('export'),
                onOk: () => {
                    resolve(exportDate);
                },
                onCancel: () => {
                    reject();
                },
            });
        });

    const handleClick = async () => {
        try {
            const date = await getExportDate();
            const params = { onlineStoreIds };
            if (date.length) {
                params.startDate = date[0];
                params.endDate = date[1];
            }
            await services.api.get(params, {
                apiPath: '/base-order/admin/payment_type/exportStatement/offline/take',
            });
            Modal.success({
                content: services.language.getText('exportModal.exportSuccessMsg'),
            });
        } catch (error) {
            if (error) {
                services.errorHandle(error);
            }
        }
    };

    return (
        <Button type='primary' onClick={handleClick}>
            {language.getText('offlineOrder.exportStatements')}
        </Button>
    );
};

class offlineOrderListPane extends Component {
    render() {
        const onlineStoreIds = this.props.entity?.filters?.onlineStoreIds;
        const filterConfig = {
            entity: this.props.entity,
            direction: 'inline',
            fieldCol: {
                span: 8,
            },
            labelCol: { span: 8 },
            // className: 'store-filter-form',
            className: 'global-flexible-filter-form',
            submit: {
                text: services.language.getText('query'),
            },
            reset: true,
            simple: {
                fields: ['channels', 'createTime', 'keyword'],
            },
            fields:
                this.props?.activeKey === 'ALL'
                    ? [
                          {
                              property: 'pickStoreName',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'outerOrderNumber',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'orderStatus',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'keyword',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'createTime',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'deliveryFlag',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'paid',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'merchantId',
                              className: 'form-inline-col',
                          },
                      ]
                    : [
                          {
                              property: 'pickStoreName',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'outerOrderNumber',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'keyword',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'createTime',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'deliveryFlag',
                              className: 'form-inline-col',
                          },
                          {
                              property: 'paid',
                              className: 'form-inline-col',
                          },
                      ],
            footer: {
                style: {
                    textAlign: 'right',
                },
            },
        };
        const buttonConfig = {
            text: language.getText('offlineOrder.createOrder'),
            type: 'primary',
            style: {
                flexGrow: 0,
                marginRight: 15,
                // padding: '0 45px',
            },
            route: `/offlineOrders/create/${onlineStoreIds}`,
        };

        const listConfig = {
            className: 'dc-order-list order-receiving',
            split: false,
            loadFirstPage: false,
            pagination: paginationMixin,
            renderItem: 'OfflineOrderDataListItem',
            config: this.props.config,
            entity: this.props.entity,
            refresh: this.props.refresh,
            fetchStatistics: this.props.fetchStatistics,
            onPageChange: () => {
                // props.refresh(true);
            },
        };

        return (
            <div>
                <div>{createElement(ComponentsManager.get('FilterFormPlus'), filterConfig)}</div>
                <Privilege path='offline.offline_order.offline_order_create'>
                    {createElement(ComponentsManager.get('Button'), buttonConfig)}
                </Privilege>
                <Privilege path='offline.offline_order.export_offline_payment_statement'>
                    <ExportStatementButton
                        onlineStoreIds={this.props.entity?.filters?.onlineStoreIds}
                    />
                </Privilege>
                <div style={{ marginTop: 15 }}>
                    {createElement(ComponentsManager.get('DataList'), listConfig)}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    const {
        entity,
        entity: { filters },
        entities,
    } = props;
    const organizations = get(entities, 'organizationSelector.fields', []);
    organizations.length === 1 && services.localStorage.set('dcOrganization', organizations[0]);
    return {
        entity,
        filters,
        organizations,
    };
}

const OfflineOrderListPane = connect(mapStateToProps, null)(offlineOrderListPane);

// 默认搜索词
import { Config } from '@comall-backend-builder/core/lib/parser';
import { DESIGN_MANAGE_LANGUAGE_OPTIONS, STATUS_OPTIONS } from '@/constants';
import { createPageLayout, tableMixin, getLanguageColumns, getLanguageProperties } from '../mixin';

import { DEFAULT_SEARCH_KEY_TABLE } from '@/configs/pro-table-constants';
export const config: Config = {
    entities: {
        SearchWordsManageEntity: {
            apiPath: '/dc-cms-api/admin/searchWords',
            properties: {
                id: { type: 'string' },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<onlineStore.storeName>>',
                },
                onlineStoreCode: {
                    type: 'string',
                    displayName: '<<onlineStore.storeCode>>',
                },
                quantity: {
                    properties: getLanguageProperties('string', 'designManager.searchWords.num'),
                    type: 'object',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<designManager.searchWords.status>>',
                },
                lastModifyUser: {
                    type: 'string',
                    displayName: '<<designManager.searchWords.lastModifyName>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<designManager.searchWords.lastModifyTime>>',
                },
                searchWords: { type: 'object' },
            },
        },
    },
    components: {
        SearchWordsManageView: {
            component: 'Viewport',
            entity: 'SearchWordsManageEntity',
        },
        SearchWordsManageTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: DEFAULT_SEARCH_KEY_TABLE,
            className: 'hot-brands-table',
            componentId: 'SearchWordsManageTable',
            columns: [
                { property: 'onlineStoreName' },
                { property: 'onlineStoreCode' },
                ...getLanguageColumns('quantity'),
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                },
                { property: 'lastModifyUser' },
                { property: 'lastModifyTime' },
            ],
            actionColumn: {
                fixed: 'right',
                title: '<<set>>',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<common.disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                return {
                                    entity: props.entity,
                                    row,
                                    statusApi: {
                                        '0': '/dc-cms-api/admin/searchWords/{{row.id}}/status',
                                        '1': '/dc-cms-api/admin/searchWords/{{row.id}}/status',
                                    },
                                    statusParams: {
                                        '0': { status: 1 },
                                        '1': { status: 0 },
                                    },
                                    statusText: {
                                        '0': '<<enable>>',
                                        '1': '<<disable>>',
                                    },
                                };
                            },
                        },
                        privilege: {
                            path: 'finish.default_manage.disable',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'HoverSelect',
                        text: '<<designManager.searchWords.setWords>>',
                        config: {
                            actionType: 'component',
                            component: 'ModalSetWords',
                            componentType: 'searchWords',
                            options: DESIGN_MANAGE_LANGUAGE_OPTIONS,
                        },
                        privilege: {
                            path: 'finish.default_manage.add',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        SearchWordsManagePage: createPageLayout([
            {
                component: 'SearchWordsManageTable',
                privilege: {
                    path: 'finish.default_manage.view_list',
                    level: 'full',
                },
            },
        ]),
    },
};

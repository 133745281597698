/*
 * @Author: zhulu
 * @Date: 2022-04-20 17:04:46
 * @Description:
 */
import * as React from 'react';
import { get, isArray, map } from 'lodash';
import { modes, formats } from '@comall-backend-builder/types';
import { AsyncTreeSelect } from '@/components/async-tree-select';

export class ArrayAsyncTreeSelectMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = controlInfo.fieldSource === 'filters' ? 'filters' : 'properties';
        return (
            <AsyncTreeSelect
                multiple
                // treeCheckable
                // showStrategy="all"
                {...controlInfo}
                {...get(controlInfo, `entity.metainfo.${props}.${controlInfo.name}`, {})}
            />
        );
    }
}

export class ArrayAsyncTreeSelectFormat extends formats.ArrayFormat {
    /**
     * 对数据进行校验
     */
    validate(rule: any, value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: Array<any> | undefined) {
        if (!isArray(value)) {
            return { [key]: [value] };
        }
        const result = map(value, (item) => ({
            id: get(item, 'value', item.id),
            name: get(item, 'label', item.name),
        }));
        return { [key]: result };
    }
}

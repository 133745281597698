import {
    tableMixin,
    flexibleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
} from '@/configs/mixin';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { PRODUCT_PRICE_MONITORING_TABLE } from '@/configs/pro-table-constants';
import { SPECS_TYPE_OPTIONS, PRODUCT_SOURCE_TYPE_OPTIONS } from '@/constants';
export const config: Config = {
    entities: {
        productMap: {
            apiPath: '/loader/dc-goods/admin/goodsThirdXref/pageByChannel',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties('string', 'productPrice.name'),
                    },
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                },
                channelName: {
                    type: 'string',
                    displayName: '<<thirdPartyManagement.thirdPartyName>>',
                },
                specsType: {
                    type: 'string',
                    displayName: '<<productPrice.specsType>>',
                },
                sourceType: {
                    type: 'string',
                    displayName: '<<productPrice.sourceType>>',
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<productPrice.lastedModifyTime>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<productPrice.keyWords>>',
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                    controlConfig: {
                        placeholder: '<<cskuPlaceHolder>>',
                    },
                },
                thirdSku: {
                    type: 'string',
                    displayName: '<<productMap.mapSkuType>>',
                    controlConfig: {
                        placeholder: '<<productMap.mapSkuPlaceHolder>>',
                    },
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productPrice.specsType>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                sourceType: {
                    type: 'string.options.select',
                    displayName: '<<productPrice.sourceType>>',
                    options: PRODUCT_SOURCE_TYPE_OPTIONS,
                },
                channelId: {
                    type: 'string.option.autoCompleteDefault',
                    displayName: '<<thirdPartyManagement.thirdPartyName>>',
                    options: [],
                    source: {
                        apiPath: '/dc-master-data/admin/channel/findOnlineThirdChannels',
                    },
                },
            },
        },
    },
    components: {
        ProductMapView: {
            component: 'Viewport',
        },
        ProductMap: {
            component: 'FlexLayout',
            entity: 'productMap',
            direction: 'vertical',
            className: 'product-price-view',
            items: [
                {
                    component: 'ProductMapFilter',
                    style: { marginBottom: 15 },
                    privilege: {
                        path: 'goods.goods_map.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'ProductMapTable',
                    privilege: {
                        path: 'goods.goods_map.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        ProductMapFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productPrice.keyWordsPlaceHolder>>',
                    },
                },
                { property: 'csku' },
                { property: 'thirdSku' },
                {
                    property: 'sourceType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'specsType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'channelId',
                    controlConfig: {
                        allowClear: true,
                    },
                },
            ],
        },
        ProductMapTable: {
            ...tableMixin,
            component: 'ProTable',
            loadFirstPage: false,
            componentName: PRODUCT_PRICE_MONITORING_TABLE,
            className: 'sku-map-table',
            columns: [
                ...getLanguageColumns('name', { width: 200 }),
                { property: 'csku', width: 200 },
                { property: 'channelName', width: 200 },
                { property: 'specsType', width: 200 },
                { property: 'sourceType', fixed: 'right' },
                { property: 'lastedModifyTime', fixed: 'right' },
            ],
            actionColumn: {
                title: '<<productMap.mapSkuType>>',
                className: 'sky-map-action',
                items: [
                    {
                        type: 'component',
                        component: 'SkuMapInput',
                        // privilege: {
                        //     path: 'goods.goods_map.view_list',
                        //     level: 'full',
                        // },
                    },
                ],
            },
        },
    },
};

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { RolePrivilegeSelector } from '@/containers/privilege/roles';

export class RolePrivilegeMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <RolePrivilegeSelector {...controlInfo} />;
    }
}

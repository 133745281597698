import { SEND_TYPE_VALUES } from '@/constants/site-messages';
import { language } from '@comall-backend-builder/core/lib/services';
import moment from 'moment';

export function buildForm(mode: string) {
    return {
        component: 'SuperForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'field',
                property: 'name',
                key: 'name',
                maxLength: 100,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'sendTime',
                key: 'sendTime',
                disabled: mode !== 'add',
                startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'subject',
                key: 'subject',
                maxLength: 100,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'content',
                key: 'content',
                maxLength: 500,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'isOpen',
                key: 'isOpen',
                fieldOpts: {
                    initialValue: 'true',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'urlSubject',
                key: 'urlSubject',
                maxLength: 100,
                renderable: (values: AnyObject) => values.isOpen === 'true',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'targetUrl',
                key: 'targetUrl',
                maxLength: 200,
                renderable: (values: AnyObject) => values.isOpen === 'true',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'sendType',
                key: 'sendType',
                fieldOpts: {
                    initialValue: SEND_TYPE_VALUES.ALL,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'phoneNumbers',
                key: 'phoneNumbers',
                maxLength: 5000,
                renderable: (values: AnyObject) => values.sendType === SEND_TYPE_VALUES.PART,
                placeholder: language.getText('siteMessages.phoneNumbersPlaceholder'),
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
        ],
    };
}

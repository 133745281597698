import { Config } from '@comall-backend-builder/core/lib/parser';
import { getLanguageColumns, getLanguageProperties } from '../../mixin';
import { tableMixin } from '@/configs/mixin';
import { formatNameProperties } from '../util';
import { RETAIL_STORE_TABLE } from '@/configs/pro-table-constants';
import { services } from '@comall-backend-builder/core';
import { toString } from 'lodash';
import { DESIGN_MANAGE_LANGUAGE_NAMES } from '@/constants';
function createFields(type: 'edit' | 'view') {
    return [
        { property: 'id', className: 'hidden' },
        {
            property: 'basicInfo.retailStoreId',
            modifiable: false,
        },
        {
            property: 'basicInfo.mcsLocationIds',
            modifiable: false,
        },
        ...getLanguageColumns('basicInfo.languageName', { modifiable: type === 'edit' }),
        { property: 'basicInfo.isShow', modifiable: type === 'edit' },
        ...getLanguageColumns('contactInformation.contactMap', { modifiable: type === 'edit' }),
        { property: 'contactInformation.phone', modifiable: type === 'edit' },
        { property: 'addressInfo.longitude', modifiable: type === 'edit' },
        { property: 'addressInfo.latitude', modifiable: type === 'edit' },
        ...getLanguageColumns('addressInfo.addressMap', { modifiable: type === 'edit' }),
        { property: 'addressInfo.retailBusinessTimes', modifiable: type === 'edit' },
        { property: 'addressInfo.pic' },
    ];
}
export const config: Config = {
    entities: {
        retailStore: {
            apiPath: '/loader/dc-store/admin/retailStore',
            properties: {
                id: {
                    type: 'string',
                },
                retailStoreId: {
                    type: 'string',
                    displayName: '<<retailStore.retailId>>',
                },
                languageName: {
                    type: 'object.subForm',
                    properties: formatNameProperties(
                        'retailStore.storeName',
                        'retailStore.storeNamePlaceHolder',
                        'storeInfo.storeNameError',
                        100
                    ),
                },
                basicInfo: {
                    type: 'object.subForm',
                    displayName: '<<retailStore.offlineStoreMsg>>',
                    properties: {
                        retailStoreId: {
                            type: 'string',
                            displayName: '<<retailStore.retailId>>',
                        },
                        mcsLocationIds: {
                            type: 'string',
                            displayName: '<<retailStore.mcsLocationIds>>',
                        },
                        retailStoreName: {
                            type: 'string.text.trim',
                            displayName: '<<retailStore.storeName>>',
                            rules: [
                                { required: true },
                                {
                                    whitespace: true,
                                    message: '<<retailStore.storeNameNotEmpty>>',
                                },
                                { max: 100, message: '<<retailStore.storeNameError>>' },
                            ],
                            controlConfig: {
                                maxLength: 100,
                                placeholder: '<<retailStore.storeNamePlaceholder>>',
                            },
                        },
                        languageName: {
                            type: 'object.subForm',
                            properties: getLanguageProperties(
                                'string.text.trim',
                                'retailStore.storeName',
                                {
                                    controlConfig: {
                                        maxLength: 100,
                                        placeholder: '<<retailStore.storeNamePlaceholder>>',
                                    },
                                    rules: [
                                        { required: true },
                                        {
                                            whitespace: true,
                                            message: '<<retailStore.storeNameNotEmpty>>',
                                        },
                                        {
                                            max: 100,
                                            message: '<<retailStore.storeNameError>>',
                                        },
                                    ],
                                }
                            ),
                        },
                        isShow: {
                            type: 'string.options.radio',
                            displayName: '<<retailStore.isShow>>',
                            options: [
                                { id: '1', name: '<<yes>>' },
                                { id: '0', name: '<<no>>' },
                            ],
                            defaultValue: '1',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                contactInformation: {
                    type: 'object.subForm',
                    displayName: '<<retailStore.contactInformation>>',
                    properties: {
                        contactMap: {
                            type: 'object.subForm',
                            properties: getLanguageProperties(
                                'string.text.trim',
                                'retailStore.contactName',
                                {
                                    controlConfig: {
                                        maxLength: 100,
                                        placeholder: '<<retailStore.contactsPlaceHolder>>',
                                    },
                                    rules: [
                                        {
                                            whitespace: true,
                                            message: '<<retailStore.contactsPlaceHolder>>',
                                        },
                                        {
                                            max: 100,
                                            message: services.interpolate(
                                                services.language.getText(
                                                    'retailStore.contactsLenError'
                                                ),
                                                {
                                                    language: DESIGN_MANAGE_LANGUAGE_NAMES,
                                                    max: 100,
                                                }
                                            ),
                                        },
                                    ],
                                }
                            ),
                        },
                        phone: {
                            type: 'string.text.trim',
                            displayName: '<<retailStore.masterMobile>>',
                            rules: [
                                { required: true },
                                {
                                    whitespace: true,
                                    message: '<<pickStoreInfo.masterMobilePlaceHolder>>',
                                },
                                {
                                    max: 100,
                                    message: '<<retailStore.masterMobileLenError>>',
                                },
                                {
                                    validator: (_rule: any, value: string, callback: any) => {
                                        if (!/^[0-9-\s()]*$/.test(value)) {
                                            return new Error(
                                                services.language.getText(
                                                    'retailStore.masterMobileError'
                                                )
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<retailStore.masterMobilePlaceHolder>>',
                            },
                        },
                    },
                },
                addressInfo: {
                    type: 'object.subForm',
                    displayName: '<<retailStore.addressInfo>>',
                    properties: {
                        longitude: {
                            type: 'number.float',
                            displayName: '<<retailStore.longitude>>',
                            controlConfig: {
                                placeholder: '<<retailStore.longitudePlaceHolder>>',
                                min: -180,
                                max: 180,
                            },
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        const newValue = toString(value);
                                        if (newValue.length > 18) {
                                            return new Error(
                                                services.language.getText(
                                                    'retailStore.longitudeLenError'
                                                )
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],
                        },
                        latitude: {
                            type: 'number.float',
                            displayName: '<<retailStore.latitude>>',
                            controlConfig: {
                                placeholder: '<<retailStore.latitudePlaceHolder>>',
                                min: -90,
                                max: 90,
                            },
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        const newValue = toString(value);
                                        if (newValue.length > 18) {
                                            return new Error(
                                                services.language.getText(
                                                    'retailStore.latitudeLenError'
                                                )
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],
                        },
                        addressMap: {
                            type: 'object.subForm',
                            properties: formatNameProperties(
                                'retailStore.address',
                                'retailStore.addressPlaceHolder',
                                'retailStore.storeAddressLenError',
                                200
                            ),
                        },
                        retailBusinessTimes: {
                            type: 'array.selectTimeRange',
                            displayName: '<<storeChannels.businessTimes>>',
                            rules: [{ required: true }],
                            defaultValue: [
                                {
                                    id: null,
                                    startWeek: 'MONDAY',
                                    endWeek: 'SUNDAY',
                                    startTime: '00:00',
                                    endTime: '22:30',
                                },
                            ],
                            controlConfig: {
                                noQuantity: true,
                                format: 'HH:mm',
                                maxRangesCount: 7,
                                singleNum: false,
                            },
                        },
                        pic: {
                            type: 'array.urlImage',
                            displayName: '<<retailStore.realisticPicture>>',
                            controlConfig: {
                                fileName: 'fileName',
                                uploadType: 'single',
                                multiple: false,
                                mode: 'picture-card',
                                maxCount: 1,
                                maxSize: 5 * 1000,
                                uploadCompleteMessage: null,
                                uploadLimitSizeErrorMessage: '<<retailStore.picLimitSize>>',
                                uploadUrl:
                                    ENV.API_ROOT +
                                    '/dc-file/oss/image_locations/1/images?rename=true',
                            },
                        },
                    },
                },
            },
            filters: {
                keyword: {
                    type: 'string.text.trim',
                    displayName: '<<keyword>>',
                },
            },
        },
    },

    components: {
        RetailStoreContainer: {
            component: 'Viewport',
        },
        RetailStoreManagement: {
            component: 'Card',
            entity: 'retailStore',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'GridLayout',
                        style: {
                            marginBottom: 15,
                        },
                        items: [
                            {
                                component: 'RetailStoreFilter',
                                style: {
                                    float: 'right',
                                },
                            },
                        ],
                    },
                    {
                        component: 'RetailStoreTable',
                    },
                ],
                privilege: {
                    path: 'store.retailstore.retailstoreview',
                    level: 'full',
                },
            },
        },
        RetailStoreFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<retailStore.keywordPlaceHolder>>',
                        style: {
                            width: 220,
                        },
                    },
                },
            ],
        },
        RetailStoreTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: RETAIL_STORE_TABLE,
            scroll: {
                x: 'max-content',
            },
            columns: [...getLanguageColumns('languageName'), { property: 'retailStoreId' }],
            actionColumn: {
                title: '<<actions>>',
                width: 250,
                fixed: 'right',
                items: [
                    {
                        text: '<<view>>',
                        type: 'link',
                        path: '/retailStore/detail/{{row.id}}',
                        privilege: {
                            path: 'store.retailstore.view_detail',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/retailStore/edit-store/{{row.id}}',
                        privilege: {
                            path: 'store.retailstore.retailstoreedit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        EditRetailStorePage: {
            component: 'FlexLayout',
            entity: 'retailStore',
            loaderType: 'get',
            direction: 'vertical',
            items: [
                {
                    component: 'EditRetailStoreInfo',
                },
            ],
        },
        EditRetailStoreInfo: {
            component: 'ModifyFormPlus',
            labelCol: 5,
            controlCol: 9,
            className: 'custom-form edit-store',
            fields: createFields('edit'),
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        type: 'default',
                        text: '<<cancel>>',
                        style: {
                            marginRight: 20,
                        },
                    },
                    {
                        type: 'primary',
                        text: '<<save>>',
                    },
                ],
                style: {
                    textAlign: 'right',
                    marginTop: 20,
                },
            },
            submitSuccessBehavior: {
                route: '/retailStore',
            },
        },
        RetailStoreInfoPage: {
            component: 'FlexLayout',
            entity: 'retailStore',
            loaderType: 'get',
            direction: 'vertical',
            items: [
                {
                    component: 'RetailStoreInfo',
                },
            ],
        },
        RetailStoreInfo: {
            component: 'ModifyForm',
            labelCol: 6,
            controlCol: 9,
            className: 'custom-form edit-stor',
            fields: createFields('view'),
        },
    },
};

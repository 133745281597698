import React, { createElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { message as AntMessage, Modal as AntModal, Upload as AntUpload } from 'antd';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';

const { interpolate } = services;

/**
 * 导入功能
 *  @param {Object} buttons -  参照buttons组件配置
 *  @param {String} apiRoot -  接口的根路径
 *  @param {String} action -   接口的根相对路径
 *  @param {Number} maxSize -  导入文件大小限制
 *  @param {String} successTitle -  导入成功时modal的title
 *  @param {String} successContent -  导入成功时modal的content
 *  @param {String} failTitle -  导入失败时modal的title
 *  @param {Boolean} disabled -  按钮启用、禁用状态
 *  @param {Boolean} refresh -  导入成功后是否刷新页面
 *  其他配置参照antd的Upload组件配置
 */

interface UploadButtonProps {
    [key: string]: any;
}

export class ImportButton extends PureComponent<UploadButtonProps, any> {
    static propTypes = {
        buttons: PropTypes.object,
        apiRoot: PropTypes.string,
        action: PropTypes.string.isRequired,
        maxSize: PropTypes.number,
        successTitle: PropTypes.string,
        successContent: PropTypes.string,
        failTitle: PropTypes.string,
        disabled: PropTypes.bool,
        refresh: PropTypes.bool,
        responseMessage: PropTypes.bool,
    };

    static defaultProps = {
        showUploadList: false,
        responseMessage: true,
        name: 'file',
        maxSize: 2048,
        buttons: {
            text: '导入',
        },
    };
    /**
     * 默认的上传处理,成功后触发外部回调
     * @param info
     */
    onChange = (info: any) => {
        const { refresh, entity, params } = this.props;
        if (info.fileList.some((file: any) => file.status === 'uploading')) {
            this.setState({
                loading: true,
            });
        }
        let nFile = info.fileList.filter((i: any) => !!i.status);
        if (info.file.status === 'done' || info.file.status === 'error') {
            if (nFile.every((item: any) => item.status === 'done' || item.status === 'error')) {
                // 旧的上传数据不需要统计数量
                const currentFiles = info.fileList.filter((file: AnyObject) => {
                    if (!file.oldFile) {
                        file.oldFile = true;
                        return true;
                    }
                    return false;
                });
                let doneFiles = currentFiles.filter((file: any) => file.status === 'done');
                let errorFiles = currentFiles.filter((file: any) => file.status === 'error');
                AntModal.success({
                    content: `成功${doneFiles.length}，失败${errorFiles.length}`,
                });
                if (doneFiles.length > 0 && refresh) {
                    entity.search(params);
                }
            }
            this.setState({
                loading: false,
            });
        }
    };
    /**
     * 上传前校验文件大小
     * @param file
     * @returns {boolean}
     */
    beforeUpload = (file: any, fileList: any) => {
        const { maxSize, beforeUpload } = this.props;
        if (beforeUpload) return beforeUpload(file, fileList);
        if (file.size > maxSize * 1024) {
            AntMessage.error('上传文件大小不能超过 ' + maxSize + 'KB');
            return false;
        }
        return !this.state.loading;
    };

    constructor(props: UploadButtonProps) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],
        };
    }

    render() {
        let {
            action,
            data,
            buttons,
            params,
            disabled,
            accept,
            apiRoot = ENV.API_ROOT,
        } = this.props;
        let context = {
            params,
        };
        action = interpolate(action, context);
        const { loading } = this.state;
        let props = {
            ...this.props,
            beforeUpload: this.beforeUpload,
            action: apiRoot + action,
            data,
            accept,
            disabled: loading,
        };
        let children = null;
        children = (
            <Button {...buttons} disabled={disabled} icon={loading ? 'loading' : buttons.icon}>
                {buttons.text}
            </Button>
        );

        //如果是非可编辑状态的话，不显示上传按钮
        return createElement(
            AntUpload,
            {
                ...props,
                onChange: this.onChange,
            },
            children
        );
    }
}

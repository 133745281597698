import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectTimeRangesPicker } from '../../../containers';
import { Icon as AntIcon } from 'antd';
import { services } from '@comall-backend-builder/core';
export enum Weeks {
    MONDAY = 'week.monday',
    TUESDAY = 'week.tuesday',
    WEDNESDAY = 'week.wednesday',
    THURSDAY = 'week.thursday',
    FRIDAY = 'week.friday',
    SATURDAY = 'week.saturday',
    SUNDAY = 'week.sunday',
}
interface RangesProps {
    startWeek: keyof typeof Weeks;
    endWeek: keyof typeof Weeks;
    startTime: string;
    endTime: string;
    storeChannelPicks: Array<storeChannelPicks>;
    quantity?: number;
}
interface storeChannelPicks {
    startTime: string;
    endTime: string;
    quantity: number;
}
interface TimeRangesViewStates {
    radioValue: number;
}

export class SelectTimeRangesView extends Component<any, TimeRangesViewStates> {
    state: TimeRangesViewStates = {
        radioValue: 0,
    };
    onChange = (e: any) => {
        this.setState({
            radioValue: e.target.value,
        });
    };
    render() {
        const { value } = this.props;
        return (
            <span className='time-range-view'>
                {value &&
                    value?.map((item: RangesProps, index: number) => (
                        <>
                            <div style={{ marginRight: 8 }} key={index}>
                                <span style={{ marginRight: '15px' }}>
                                    {services.language.getText(Weeks[item.startWeek])} To{' '}
                                    {services.language.getText(Weeks[item.endWeek])}
                                </span>
                                <span>
                                    {' '}
                                    {item.startTime}
                                    <AntIcon
                                        type='clock-circle-o'
                                        style={{ margin: '0 5px' }}
                                    /> To {item.endTime}
                                    <AntIcon type='clock-circle-o' style={{ margin: '0 5px' }} />
                                </span>
                                {/* {!isEmpty(item.quantity + '') && (
                                    <span>
                                        {` ${item.quantity || ''} ${services.language.getText(
                                            'storeInfo.quantity'
                                        )}`}
                                    </span>
                                )} */}
                            </div>
                        </>
                    ))}
            </span>
        );
    }
}

export class SelectTimeRangesMode extends modes.ArrayMode {
    getDisplayComponent(value: any, displayInfo: any) {
        return <SelectTimeRangesView value={value}></SelectTimeRangesView>;
    }
    getControlComponent(controlInfo: any) {
        return <SelectTimeRangesPicker {...controlInfo} />;
    }
}

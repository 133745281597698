import React from 'react';
import { Popover } from 'antd';
import { modes } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';

/**
 * 状态类型
 */
export class LongTextMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, displayConfig: any) {
        return value && value.length > 70 ? (
            <Popover
                content={<p style={{ whiteSpace: 'pre-line' }}>{value}</p>}
                title={services.language.getText(`couponRule.${displayConfig.name}`)}
            >
                <p style={{ whiteSpace: 'pre-line' }}>{value.substring(0, 70) + '...'} </p>
            </Popover>
        ) : (
            <div>{value}</div>
        );
    }
}

import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';
import { buildForm } from './voucher-management-form';
import {
    PROVISIONING_OBJECTS_OPTIONS,
    RELEASE_PERIOD_OPTIONS,
    VOUCHER_STATUS_OPTIONS,
} from '@/constants/voucher-management';
import { services } from '@comall-backend-builder/core';
import { recordImportLogs } from '@/services';

export const config: Config = {
    entities: {
        voucherManagement: {
            apiPath: '/loader/dc-master-data/admin/voucherGrant',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.tableCol.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 100,
                    },
                },
                effectTime: {
                    type: 'string.dateTime',
                    displayName: '<<couponManage.voucherManagement.tableCol.effectTime>>',
                },
                releasePeriod: {
                    type: 'string.options.radio',
                    options: RELEASE_PERIOD_OPTIONS,
                    displayName: '<<couponManage.voucherManagement.tableCol.releasePeriod>>',
                },
                mainDistributionStoreId: {
                    type: 'string.treeNode.cascader',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                    },
                    rules: [
                        {
                            required: true,
                            message: '<<couponManage.voucherManagement.selectStore>>',
                        },
                    ],
                    displayName:
                        '<<couponManage.voucherManagement.tableCol.mainDistributionStore>>',
                },
                mainDistributionStore: {
                    type: 'string',
                    displayName:
                        '<<couponManage.voucherManagement.tableCol.mainDistributionStore>>',
                },
                mainStore: {
                    type: 'string.treeNode.cascader',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                    },
                    rules: [
                        {
                            required: true,
                            message: '<<couponManage.voucherManagement.selectStore>>',
                        },
                    ],
                    displayName:
                        '<<couponManage.voucherManagement.tableCol.mainDistributionStore>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<couponManage.voucherManagement.tableCol.status>>',
                },
                provisioningObjects: {
                    type: 'string.options.radio',
                    options: PROVISIONING_OBJECTS_OPTIONS,
                    displayName: '<<couponManage.voucherManagement.tableCol.provisioningObjects>>',
                },
                amountPayment: {
                    type: 'number',
                    displayName: '<<couponManage.voucherManagement.tableCol.amountPayment>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        min: 0,
                        max: 999999,
                    },
                },
                numberIssues: {
                    type: 'number',
                    displayName: '<<couponManage.voucherManagement.tableCol.numberIssues>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        min: 0,
                        max: 999999,
                    },
                },
                items: {
                    type: 'array.voucherManagementItems',
                    displayName: '<<couponManage.voucherManagement.tableCol.selectPhone>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-user/admin/members/voucherManagement',
                    },
                    rules: [{ required: true }],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<couponManage.voucherManagement.tableCol.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                grantStoreId: {
                    type: 'string.treeNode.cascader',
                    displayName: '<<couponManage.voucherManagement.mainDistributionStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        VoucherManagementView: {
            component: 'Viewport',
        },
        VoucherManagementPage: {
            entity: 'voucherManagement',
            ...createPageLayout([
                {
                    component: 'ProductPriceList',
                    params: {},
                    filterConfig: {
                        component: 'VoucherManagementFilter',
                        style: { marginBottom: 15 },
                        privilege: {
                            path: 'coupon.voucher_grant.voucher_grant_list',
                            level: 'full',
                        },
                    },
                    buttonConfig: {
                        component: 'VoucherManagementAction',
                        style: { marginBottom: 15 },
                    },
                    tableConfig: {
                        component: 'VoucherManagementTable',
                        privilege: {
                            path: 'coupon.voucher_grant.voucher_grant_list',
                            level: 'full',
                        },
                    },
                },
            ]),
        },
        VoucherManagementFilter: {
            ...flexibleFilterForm,
            params: {},
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderInput>>',
                    },
                },
                {
                    property: 'grantStoreId',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            ],
        },
        VoucherManagementAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Button',
                    type: 'primary',
                    icon: 'plus',
                    text: '<<couponManage.voucherManagement.tableCol.add>>',
                    route: '/voucher-management/add',
                    privilege: {
                        path: 'coupon.voucher_grant.voucher_grant_create',
                        level: 'full',
                    },
                },
            ],
        },
        VoucherManagementTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'effectTime',
                },
                {
                    property: 'releasePeriod',
                },
                {
                    property: 'mainDistributionStore',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: VOUCHER_STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionImport',
                        text: '<<couponManage.voucherManagement.importingMembers>>',
                        config: {
                            style: {
                                marginRight: '8px',
                            },
                            apiPath: `/dc-file/excels?location_id=2`,
                            showSuccessMessage: false,
                            showErrorMessage: true,
                            onUploadComplete(response: any, row: any) {
                                if (response) {
                                    recordImportLogs({
                                        name: services.language.getText(
                                            'productSelection.importProduct'
                                        ),
                                        apiPath: `/dc-master-data/admin/voucherGrant/importMember`,
                                        params: {
                                            resourceId: response.id,
                                            voucherGrantId: row.id,
                                        },
                                    });
                                }
                            },
                        },
                        privilege: {
                            path: 'coupon.voucher_grant.voucher_grant_member_import',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<couponManage.voucherManagement.exportMembership>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionExport',
                            renderConfig: (row: any, props: any) => {
                                return {
                                    ...props,
                                    row,
                                    source: {
                                        apiPath:
                                            '/dc-master-data/admin/voucherGrant/exportMember/take?voucherGrantId={{row.id}}',
                                        paramsFilter: [],
                                    },
                                    range: {
                                        apiPath:
                                            '/dc-master-data/admin/voucherGrant/exportMember/count?voucherGrantId={{row.id}}',
                                    },
                                    showErrorMessage: true,
                                    showSuccessTip: true,
                                };
                            },
                        },
                        privilege: {
                            path: 'coupon.voucher_grant.voucher_grant_member_export',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                true: '/dc-master-data/admin/voucherGrant/disable/{{row.id}}',
                                false: '/dc-master-data/admin/voucherGrant/enable/{{row.id}}',
                            },
                            statusText: {
                                true: '<<disable>>',
                                false: '<<enable>>',
                            },
                            linkProperty: 'enable',
                            errorStatus: true,
                        },
                        privilege: {
                            path: 'coupon.voucher_grant.voucher_grant_enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/voucher-management/edit/{{row.id}}',
                        privilege: {
                            path: 'coupon.voucher_grant.voucher_grant_update',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<couponManage.voucherManagement.viewRecords>>',
                        type: 'link',
                        path: '/voucher-management/data/{{row.id}}',
                        privilege: {
                            path: 'coupon.voucher_grant.voucher_grant_record_detail',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        VoucherManagementAddPage: {
            component: 'Card',
            entity: 'voucherManagement',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        VoucherManagementEditPage: {
            component: 'Card',
            entity: 'voucherManagement',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
    },
};

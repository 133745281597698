import React, { useState } from 'react';
import { Button, Form, InputNumber, Modal, message } from 'antd';
import { PickingCard } from './base-content';
import { Privilege } from '@/components/privilege';
import { ORDER_TYPE } from '@/constants/order/enum';
import { services } from '@comall-backend-builder/core';
import { getPackageNumber, modifyPackageNumber } from '../../api';

const cleanFloat = (v = 0) => +String(v || 0).replace(/\..*$/, '');

export function DeliveringItem(props: AnyObject) {
    const { parentProps } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const [loadingModal, setModalLoading] = useState<boolean>(false);
    const [formValue, setFormValue] = useState<{ coldNumber: number; normalNumber: number }>({
        coldNumber: 0,
        normalNumber: 0,
    });

    const showModal = async () => {
        try {
            setModalLoading(true);
            setVisible(true);
            const res: any = await getPackageNumber(props.id);
            setFormValue({ coldNumber: res.coldNumber, normalNumber: res.normalNumber });
        } finally {
            setModalLoading(false);
        }
    };

    const submit = async () => {
        try {
            setModalLoading(true);
            await modifyPackageNumber({
                id: props.id,
                status: props.orderStatus,
                ...formValue,
            });
            setVisible(false);
            message.success(services.language.getText('picking.modifiedSuccessfully'));
        } catch (e) {
            return;
        } finally {
            setModalLoading(false);
        }
    };
    return (
        <>
            <PickingCard
                data={props}
                type='order'
                actions={
                    <>
                        {parentProps.type === ORDER_TYPE.BASE && (
                            <Privilege path='picking.assistant.update_bag'>
                                <Button type='primary' onClick={showModal}>
                                    {services.language.getText('picking.modifyPackages')}
                                </Button>
                            </Privilege>
                        )}
                    </>
                }
                orderPathname={props.parentProps.orderPathname}
                pickPathname={props.parentProps.pickPathname}
            />
            <Modal
                visible={visible}
                title={services.language.getText('picking.confirmPackages')}
                onCancel={() => setVisible(false)}
                onOk={submit}
                okButtonProps={{
                    loading: loadingModal,
                }}
            >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
                    <Form.Item label={services.language.getText('picking.temperatureNums')}>
                        <InputNumber
                            min={0}
                            maxLength={5}
                            value={formValue.normalNumber}
                            onChange={(v) => {
                                setFormValue((val) => ({ ...val, normalNumber: cleanFloat(v) }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={services.language.getText('picking.frozenNums')}>
                        <InputNumber
                            min={0}
                            maxLength={5}
                            value={formValue.coldNumber}
                            onChange={(v) => {
                                setFormValue((val) => ({ ...val, coldNumber: cleanFloat(v) }));
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

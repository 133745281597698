import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { getLanguageColumns } from '../../mixin';
import { PICKUP_ATTRIBUTES_VALUES } from '@/constants';

export const config: Config = {
    components: {
        AddStoreLayout: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'pickStore',
            align: 'start',
            items: [
                {
                    component: 'AddStoreContent',
                },
            ],
            className: 'custom-form-layout',
        },

        AddStoreContent: {
            component: 'CreateFormPlus',
            className: 'custom-form',
            labelCol: 8,
            controlCol: 9,
            submit: false,
            fields: [
                {
                    property: 'storeInfo.code',
                },
                ...getLanguageColumns('storeInfo.languageName'),
                ...getLanguageColumns('contactInformation.languageName'),
                {
                    property: 'contactInformation.masterMobile',
                },
                {
                    property: 'storeAddress.longitude',
                },
                {
                    property: 'storeAddress.latitude',
                },
                ...getLanguageColumns('storeAddress.languageName'),
                {
                    property: 'storeAddress.realityImages',
                    controlConfig: {
                        maxCount: 1,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        multiple: true,
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                        mode: 'picture-card',
                        limitTips: {
                            limitText: '<<storeInfo.limitText>>',
                            limitUnit: '<<storeInfo.limitUnit>>',
                        },
                    },
                },
                { property: 'storeAddress.pickStoreAttribute' },
                {
                    property: 'storeAddress.memberLevels',
                    visible: (value: any) => {
                        return (
                            value.storeAddress.pickStoreAttribute ===
                            PICKUP_ATTRIBUTES_VALUES.SPECIAL_PICKUP_POINT
                        );
                    },
                },
            ],
            footer: {
                items: [
                    {
                        type: 'default',
                        text: '<<cancel>>',
                        htmlType: 'button',
                        route: 'goBack',
                        style: {
                            marginRight: 20,
                        },
                    },
                    {
                        text: '<<submit>>',
                    },
                ],
                style: {
                    textAlign: 'right',
                    marginTop: 20,
                },
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('successFully'));
                services.navigation.goto('/pickStore');
            },
            submitSuccessBehavior: {
                route: '/pickStore',
            },
        },
    },
};

import React, { useState } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { ButtonProps } from '@comall-backend-builder/components-basis/lib/button';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

export interface ExportButtonPlusProps extends ButtonProps {
    entity?: Entity;
    /**
     * 请求参数
     */
    params?: AnyObject;
    /**
     * 下载时是否携带 entity 上的过滤参数
     */
    withEntityParams?: boolean;
    /**
     * 数据源
     */
    source?: Pick<ApiRequestConfig, 'apiPath' | 'apiRoot' | 'paramsFilter'>;
}

/**
 * 支持自动携带 entity 上的过滤参数的下载按钮
 *
 * 用于满足需要根据过滤表单下载指定内容的需求
 */
export const ExportButtonPlus: React.FC<ExportButtonPlusProps> = (props) => {
    const { entity, withEntityParams = true, params = {}, source } = props;
    const [loading, setLoading] = useState(false);

    async function download() {
        if (source) {
            try {
                setLoading(true);
                const curEntity = (entity || {}) as Entity;
                const entityParams = withEntityParams
                    ? Object.assign({}, curEntity.params, curEntity.filters)
                    : {};
                const mergedParams = Object.assign({}, entityParams, params);
                await services.api.download(mergedParams, source);
            } finally {
                setLoading(false);
            }
        }
    }

    return <Button {...props} loading={loading} onClick={download} />;
};

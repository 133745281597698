import { message } from 'antd';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { load } from '@comall-backend-builder/core/lib/loaders';
/*
 * @Author: zhulu
 * @Date: 2022-04-28 14:45:04
 * @Description: 商品关联
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, flexibleFilterForm, deletePopConfirmConfig } from '@/configs/mixin';
import { PRODUCT_SUITE } from '@/configs/pro-table-constants';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
export const config: Config = {
    entities: {
        productGroup: {
            apiPath: '/dc-goods/admin/product_group',
            properties: {
                id: { type: 'string', displayName: 'ID' },
                cskuCount: { type: 'string', displayName: '<<productGroup.count>>' },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<productGroup.modifyTime>>',
                },
                lastedModifyUser: {
                    type: 'string',
                    displayName: '<<productGroup.modifyUser>>',
                },
                name: {
                    displayName: '<<productGroup.name>>',
                    type: 'string',
                },
            },
            filters: {
                nameKeyword: { type: 'string', displayName: '<<keyword>>' },
                csku: { type: 'string', displayName: '<<productGroup.csku>>' },
            },
        },
        productGroupInfo: {
            apiPath: '/loader/dc-goods/admin/product_group',
            properties: {
                id: { type: 'string', displayName: 'ID' },
                name: {
                    displayName: '<<productGroup.name>>',
                    type: 'string',
                    rules: [{ required: true, message: '<<productGroup.groupNameError>>' }],
                },
                productList: {
                    type: 'array.productGroupItems',
                    displayName: '选择商品',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-goods/admin/cskus/queryCskuByPage/normal',
                    },
                    rules: [{ required: true }],
                },
            },
        },
    },
    components: {
        ProductGroupView: {
            component: 'Viewport',
        },
        ProductGroupList: {
            component: 'FlexLayout',
            entity: 'productGroup',
            direction: 'vertical',
            className: 'product-price-view',
            items: [
                {
                    component: 'ProductGroupFilter',
                    style: { marginBottom: 15 },
                    privilege: {
                        path: 'goods.goods_relevance.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'ProductGroupAction',
                    style: { marginBottom: 15 },
                    privilege: {
                        path: 'goods.goods_relevance.add',
                        level: 'full',
                    },
                },
                {
                    component: 'ProductGroupTable',
                    style: { marginBottom: 15 },
                    privilege: {
                        path: 'goods.goods_relevance.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        ProductGroupFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'nameKeyword',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productGroup.namePlaceholder>>',
                    },
                },
                {
                    property: 'csku',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productGroup.cskuPlaceholder>>',
                    },
                },
            ],
        },
        ProductGroupAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'Button',
                    text: '<<productGroup.add>>',
                    icon: 'plus',
                    type: 'primary',
                    style: {
                        flexGrow: 0,
                        marginRight: 15,
                    },
                    route: `/product-group/add`,
                },
            ],
        },
        ProductGroupTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: PRODUCT_SUITE,
            scroll: {
                x: 'max-content',
            },
            loadFirstPage: true,
            columns: [
                { property: 'name' },
                { property: 'id' },
                { property: 'cskuCount' },
                { property: 'lastedModifyTime' },
                { property: 'lastedModifyUser' },
            ],
            onDeleteItem: async (id: string, row: any) => {
                try {
                    load('delete', {
                        apiPath: '/loader/dc-goods/admin/suite',
                        params: row,
                    });
                    message.success(language.getText('modifyFully'));
                } catch (e) {
                    errorHandle(e as ErrorInfo);
                }
            },
            actionColumn: {
                title: '<<actions>>',
                width: 100,
                fixed: 'right',
                items: [
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/product-group/edit/{{row.id}}',
                        privilege: {
                            path: 'goods.goods_relevance.view',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        text: '<<delete>>',
                        component: 'TableActionDelete',
                        config: {
                            ...deletePopConfirmConfig,
                            apiPath: '/dc-goods/admin/product_group/{{row.id}}',
                        },
                        privilege: {
                            path: 'goods.goods_relevance.delete',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ProductGroupAdd: {
            component: 'Card',
            content: {
                component: 'ProductGroupAddForm',
            },
        },
        ProductGroupAddForm: {
            component: 'ProductGroupForm',
            type: 'add',
        },
        ProductGroupEdit: {
            component: 'Card',
            content: {
                component: 'ProductGroupEditForm',
            },
        },
        ProductGroupEditForm: {
            component: 'ProductGroupForm',
            type: 'edit',
        },
    },
};

import { generateOptions } from '@/services/generate-options';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 规格类型值
 */
export enum SPECS_TYPE_VALUES {
    /**
     * 普通商品
     */
    NORMAL,
    /**
     * 称重商品
     */
    WEIGH,
}

/**
 * 规格类型名
 */
export const SPECS_TYPE_NAMES = {
    [SPECS_TYPE_VALUES.NORMAL]: language.getText('productSpecsType.normal'),
    [SPECS_TYPE_VALUES.WEIGH]: language.getText('productSpecsType.weigh'),
};

/**
 * 规格类型候选项
 */
export const SPECS_TYPE_OPTIONS = generateOptions(SPECS_TYPE_VALUES, SPECS_TYPE_NAMES, (v, n) => ({
    id: String(v),
    name: n,
})) as any;

/**
 * 品牌类型值
 */
export enum BRAND_TYPE_VALUES {
    /**
     * 标准品牌(ERP品牌)
     */
    NORMAL,
    /**
     * 自建品牌
     */
    CREATE,
}

/**
 * 品牌类型名
 */
export const BRAND_TYPE_NAMES = {
    [BRAND_TYPE_VALUES.CREATE]: language.getText('brands.type.create'),
    [BRAND_TYPE_VALUES.NORMAL]: language.getText('brands.type.normal'),
};

/**
 * 品牌类型选项
 */
export const BRAND_TYPE_OPTIONS = generateOptions(BRAND_TYPE_VALUES, BRAND_TYPE_NAMES, (v, n) => ({
    id: String(v),
    name: n,
}));
/**
 * 商品来源值
 */
export enum PRODUCT_SOURCE_TYPE_VALUES {
    /**
     * 自建
     */
    SELF_BUILT,
    /**
     * 标准
     */
    STANDARD,
    /**
     * 组合
     */
    COMBINATION,
    /**
     * 虚拟
     */
    VIRTUAL,
}

/**
 * 商品来源名
 */
export const PRODUCT_SOURCE_TYPE_NAMES = {
    [PRODUCT_SOURCE_TYPE_VALUES.SELF_BUILT]: language.getText('productSourceType.selfBuilt'),
    [PRODUCT_SOURCE_TYPE_VALUES.STANDARD]: language.getText('productSourceType.standard'),
    [PRODUCT_SOURCE_TYPE_VALUES.COMBINATION]: language.getText('productSourceType.combination'),
    [PRODUCT_SOURCE_TYPE_VALUES.VIRTUAL]: language.getText('productSourceType.virtual'),
};

/**
 * 商品来源候选项
 */
export const PRODUCT_SOURCE_TYPE_OPTIONS = generateOptions(
    PRODUCT_SOURCE_TYPE_VALUES,
    PRODUCT_SOURCE_TYPE_NAMES,
    (v, n) => ({
        id: String(v),
        name: n,
    })
);
/**
 * 只有自建和标准的商品来源候选项
 */
export const SOURCE_TYPE_OPTIONS = PRODUCT_SOURCE_TYPE_OPTIONS.filter(
    (i: any) =>
        [
            String(PRODUCT_SOURCE_TYPE_VALUES.SELF_BUILT),
            String(PRODUCT_SOURCE_TYPE_VALUES.STANDARD),
        ].indexOf(i.id) > -1
);

/**
 * 售卖渠道值
 */
export enum PRODUCT_SALE_CHANNEL_VALUES {
    /**
     * 线上
     */
    ONLINE = '1',
    /**
     * 线下
     */
    OFFLINE = '2',
}
/**
 * 售卖渠道名
 */
export const PRODUCT_SALE_CHANNEL_NAMES = {
    [PRODUCT_SALE_CHANNEL_VALUES.ONLINE]: '线上',
    [PRODUCT_SALE_CHANNEL_VALUES.OFFLINE]: '线下',
};
/**
 * 售卖渠道候选项
 */
export const PRODUCT_SALE_CHANNEL_OPTIONS = generateOptions(
    PRODUCT_SALE_CHANNEL_VALUES,
    PRODUCT_SALE_CHANNEL_NAMES,
    (v, n) => ({
        id: v,
        label: n,
        value: v,
        name: n,
    })
);

/**
 * 商品状态值
 */
export enum PRODUCT_SKU_STATUS_VALUES {
    /**
     * 在售
     */
    ON_SALE = '100',
    /**
     * 停售
     */
    OFF_SALE = '200',
    /**
     * 无库存
     */
    OOS = '300',
    /**
     * 停产
     */
    DISCONTINUED = '400',
    /**
     * 附加商品
     */
    ADDITIONAL_GOODS = '500',
}
/**
 * 商品状态名
 */
export const PRODUCT_SKU_STATUS_NAMES = {
    [PRODUCT_SKU_STATUS_VALUES.ON_SALE]: '在售',
    [PRODUCT_SKU_STATUS_VALUES.OFF_SALE]: '停售',
    [PRODUCT_SKU_STATUS_VALUES.OOS]: 'OOS',
    [PRODUCT_SKU_STATUS_VALUES.DISCONTINUED]: '停产',
    [PRODUCT_SKU_STATUS_VALUES.ADDITIONAL_GOODS]: '附加商品',
};
/**
 * 商品状态候选项
 */
export const PRODUCT_SKU_STATUS_OPTIONS = generateOptions(
    PRODUCT_SKU_STATUS_VALUES,
    PRODUCT_SKU_STATUS_NAMES,
    (v, n) => ({
        id: v,
        label: n,
        value: v,
        name: n,
    })
);

/**
 * 推荐商品类型
 */
export enum RECOMMEND_PRODUCT_TYPE {
    /**
     * 品牌
     */
    BRAND = 'brand',
    /**
     * 展示分类
     */
    DISPLAY_CATEGORY = 'display_categorys',
    /**
     * 合作商户
     */
    MERCHANT = 'merchant',
}

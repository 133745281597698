import builder, { Type } from '@comall-backend-builder/core';
import { NumberFormat } from '@comall-backend-builder/types';
import { TagLevelFormat, DeliveryDayFormat, WeightFormat } from './formats';
import { DeliveryDayMode, NumberInputTipMode, TagLevelMode, WeightStepFreightMode } from './modes';

builder.registerType(
    'number.deliveryDay',
    new Type(new DeliveryDayFormat(), new DeliveryDayMode())
);
builder.registerType('number.tagLevel', new Type(new TagLevelFormat(), new TagLevelMode()));
builder.registerType('number.tip', new Type(new NumberFormat(), new NumberInputTipMode()));
builder.registerType('weightStepFreigh', new Type(new WeightFormat(), new WeightStepFreightMode()));

import { Button } from 'antd';
import React, { useEffect } from 'react';
import { UserService } from '@/utils/user-service';

export const SsoLogin = () => {
    useEffect(() => {
        try {
            const hash = window.location.hash;
            const params = hash.split('?')[1];
            if (params.includes('login')) {
                UserService.init();
            }
        } catch (e) {}
    }, []);

    return (
        <main
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div style={{ fontSize: '16px', color: 'black' }}>Single Sign-On</div>
            <Button
                type='primary'
                onClick={() => UserService.init(true)}
                style={{ width: '300px', height: '40px', marginTop: '20px' }}
            >
                登入
            </Button>
        </main>
    );
};

import { STORE_SERVICE_OPTIONS } from '@/constants';
import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    tableMixin,
    getLanguageProperties,
    getLanguageColumns,
    flexibleFilterForm,
} from '../../mixin';

import { PICK_ONLINE_STORE_LIST } from '@/configs/pro-table-constants';
export const config: Config = {
    entities: {
        pickOnlineStore: {
            apiPath: '/dc-store/admin/pickOnlineStore',
            properties: {
                id: {
                    type: 'string',
                },
                pickStoreNameMap: {
                    type: 'object.subForm',
                    properties: getLanguageProperties(
                        'string.text.trim',
                        'pickOnlineStore.storeName',
                        {
                            controlConfig: {
                                placeholder: '<<category.displayCategory.namePlaceholder>>',
                            },
                            rules: [
                                {
                                    whitespace: true,
                                    message: '<<pickStoreInfo.storeNameError>>',
                                },
                                { max: 50, message: '<<pickOnlineStore.storeNameError>>' },
                            ],
                        }
                    ),
                },
                retailStoreId: {
                    type: 'string',
                    displayName: '<<pickOnlineStore.retailStoreId>>',
                },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<pickOnlineStore.onlineStore>>',
                },
                organizationName: {
                    type: 'string',
                    displayName: '<<pickOnlineStore.organization>>',
                },
                serviceStatus: {
                    type: 'string.status',
                    displayName: '<<pickOnlineStore.serviceStatus>>',
                    defaultValue: 'OPENING',
                    options: STORE_SERVICE_OPTIONS,
                    rules: [{ required: true }],
                },

                sort: {
                    type: 'string.text.trim',
                    displayName: '<<pickOnlineStore.sort>>',
                },
            },
            filters: {
                retailStoreIdOrName: {
                    type: 'string',
                    displayName: '<<keyword>>',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<pickOnlineStore.keywordPlaceholder>>',
                    },
                },
                onlineStoreId: {
                    type: 'string.treeNode.cascader',
                    displayName: '<<pickOnlineStore.onlineStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                    },
                },
                serviceStatus: {
                    type: 'string.options.select',
                    displayName: '<<storeChannels.serviceStatus>>',
                    options: STORE_SERVICE_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        PickOnlineStoreContainer: {
            component: 'Viewport',
        },
        PickOnlineStoreManagement: {
            component: 'Card',
            entity: 'pickOnlineStore',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'PickOnlineStoreFilter',
                    },
                    { component: 'PickOnlineStoreTable' },
                ],
                privilege: {
                    path: 'store.pickonlinestore.pickonlinestoreview',
                    level: 'full',
                },
            },
        },
        PickOnlineStoreFilter: {
            ...flexibleFilterForm,
            fields: [
                { property: 'retailStoreIdOrName' },
                { property: 'onlineStoreId' },
                { property: 'serviceStatus' },
            ],
        },
        PickOnlineStoreTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: PICK_ONLINE_STORE_LIST,
            scroll: {
                x: 'max-content',
            },
            columns: [
                ...getLanguageColumns('pickStoreNameMap'),
                { property: 'retailStoreId' },
                { property: 'onlineStoreName' },
                {
                    property: 'serviceStatus',
                    displayConfig: {
                        statusConfig: STORE_SERVICE_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                width: 150,
                items: [
                    {
                        text: '<<view>>',
                        type: 'link',
                        path: '/pickOnlineStore/detail/{{row.id}}',
                        privilege: {
                            path: 'store.pickonlinestore.view_detail',
                            level: 'full',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<edit>>',
                        path: '/pickOnlineStore/edit/{{row.id}}',
                        privilege: {
                            path: 'store.pickonlinestore.pickstoreedit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

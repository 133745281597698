import { Quill } from 'react-quill';

let BlockEmbed = Quill.import('blots/block/embed');

const ATTRIBUTES = ['height', 'width'];

export class VideoBlot extends BlockEmbed {
    config: any;

    static create(configStr: string) {
        let node = super.create();
        this.config = configStr ? JSON.parse(configStr) : this.config;
        node.setAttribute('src', this.config.src || '');
        // Set non-format related attributes with static values
        node.setAttribute('frameborder', '0');
        node.setAttribute('allowfullscreen', true);
        node.setAttribute('controls', 'controls');
        node.setAttribute('poster', this.config.poster);
        node.setAttribute('aspectratio', this.config.aspectRatio);
        return node;
    }

    static formats(domNode: any) {
        return ATTRIBUTES.reduce((formats: any, attribute: any) => {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }

    static value(domNode: any) {
        const config = {
            src: domNode.getAttribute('src'),
            poster: domNode.getAttribute('poster'),
            aspectRatio: domNode.getAttribute('aspectratio'),
        };
        return JSON.stringify(config);
    }

    format(name: any, value: any) {
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }

    html() {
        const { video } = this.value();
        return `<a href="${video}">${video}</a>`;
    }
}

VideoBlot.blotName = 'video';
VideoBlot.className = 'ql-video';
VideoBlot.tagName = 'video';

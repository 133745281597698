import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        OfflineOrdersCreate: {
            component: 'Card',
            className: 'offline-orders-create',
            type: 'inner',
            loaderType: 'get',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'OfflineCreateBasicForm',
                    },
                ],
            },
        },
    },
};

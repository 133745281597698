import { languageDataToFields } from '@/services/language-utils';
import { services } from '@comall-backend-builder/core';

function transformData(data: AnyObject) {
    languageDataToFields('name', data, data.nameMap);
    data.merchantName = data.merchantNameMap?.tc;
    data.merchantFreightType = data.merchantFreightType?.split(',');
    return data;
}

export default [
    {
        key: '/dc-goods/admin/product/pick/limit',
        loader: {
            get: async function(data: any, config: any) {
                if (data.id) {
                    return services.api.get<AnyObject>(data, config).then(transformData);
                }
                return services.api.get<AnyObject>(data, config).then((res) => {
                    res.result = res.result.map(transformData);
                    return res;
                });
            },
        },
    },
];

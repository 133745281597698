import React, { Component } from 'react';
import {
    Button as AntButton,
    Modal as AntModal,
    message as AntMessage,
    Table as AntTable,
    InputNumber,
    Select,
} from 'antd';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import { isEmpty } from 'lodash';
import { isValidValue } from '@/services';
interface IProps {
    id: string;
    parentProps: any;
}
interface IState {
    confirmLoading: boolean;
    visible: boolean;
    validatePaymentType: {
        status: 'error' | undefined;
        help: string | undefined;
    };
    validatePaymentAmount: {
        status: 'error' | undefined;
        help: string | undefined;
    };
    amountList: AnyObject[];
    discountList: AnyObject[];
    stockList: AnyObject[];
}

export class RefundAndReturnModal extends Component<IProps, IState> {
    state: IState = {
        confirmLoading: false,
        visible: false,
        validatePaymentType: {
            status: undefined,
            help: undefined,
        },
        validatePaymentAmount: {
            status: undefined,
            help: undefined,
        },
        amountList: [],
        discountList: [],
        stockList: [],
    };

    toggleModal = (flag: boolean) => {
        if (flag === true) {
            const { id } = this.props;
            this.setState({ confirmLoading: true });
            services.api
                .get(
                    {},
                    {
                        apiPath: `/base-order/admin/order/offline_refundable_info/${id}`,
                    }
                )
                .then((res: any) => {
                    console.log(res);
                    // 折扣类型需要单独展示
                    const discountTypes = ['VOUCHER', 'POINT'];
                    const amountList = res.paymentRecords
                        .filter((item: AnyObject) => !discountTypes.includes(item.paymentType))
                        .map((item: any) => ({
                            id: item.id,
                            paymentTypeName: item.paymentTypeName,
                            paymentType: item.paymentType,
                            netAmount: item.netAmount,
                            paidAmount: item.paidAmount,
                        }));
                    const discountList = res.paymentRecords
                        .filter((item: AnyObject) => discountTypes.includes(item.paymentType))
                        .map((item: any) => ({
                            id: item.id,
                            paymentTypeName: item.paymentTypeName,
                            paymentType: item.paymentType,
                            netAmount: item.netAmount,
                            paidAmount: item.paidAmount,
                        }));
                    this.setState(
                        {
                            amountList,
                            discountList,
                            stockList: res.orderItems.map((item: any) => ({
                                id: item.id,
                                name: item.name,
                                csku: item.csku,
                                rsku: item.rsku,
                                quantity: item.quantity,
                                mcsLocationList: item.mcsLocationList,
                            })),
                        },
                        () => {
                            console.log('this.state', this.state);
                        }
                    );
                })
                .catch(services.errorHandle)
                .finally(() => {
                    this.setState({ confirmLoading: false });
                });
        }

        this.setState({
            visible: flag,
        });
    };

    handleOk = () => {
        const { amountList, discountList, stockList } = this.state;
        const {
            id,
            parentProps: { entity },
        } = this.props;

        const refundPrm = {
            items: stockList.map((item: any) => ({
                orderItemId: item.id,
                refundQuantity: item.inputValue,
                location: item.location,
            })),
            refundRecords: [
                ...amountList.map((item: any) => ({
                    orderPaymentRecordId: item.id,
                    refundAmount: item.inputValue,
                })),
                ...discountList.map((item: any) => ({
                    orderPaymentRecordId: item.id,
                    refundAmount: item.inputValue,
                })),
            ],
        };
        if (!refundPrm.refundRecords.some((i: any) => Number(i.refundAmount))) {
            AntMessage.error(services.language.getText('offlineOrder.tips.returnAndRefund'));
            return;
        }
        if (
            !refundPrm.items.every(
                (i: any) =>
                    (i.location && Number(i.refundQuantity)) ||
                    (!isValidValue(i.location) && !isValidValue(i.refundQuantity))
            )
        ) {
            AntMessage.error(services.language.getText('offlineOrder.tips.returnLocation'));
            return;
        }
        refundPrm.items = refundPrm.items.filter(
            (i: any) => Number(i.refundQuantity) && isValidValue(i.location)
        );
        this.setState({ confirmLoading: true });
        services.api
            .put(refundPrm, {
                apiPath: `/base-order/admin/order/${id}/offline_return_and_refund`,
            })
            .then(() => {
                entity.search(entity.params);
                this.toggleModal(false);
                this.setState({
                    amountList: [],
                    discountList: [],
                    stockList: [],
                });
                AntMessage.success(services.language.getText('order.orderCancelSuccess'));
            })
            .catch(services.errorHandle)
            .finally(() => {
                this.setState({ confirmLoading: false });
            });
    };

    changeAmountList(value: number = 0, index: number) {
        this.setState({
            amountList: this.changeInputValue(this.state.amountList, value, index, 'inputValue'),
        });
    }

    changeDiscountList(value: number = 0, index: number) {
        this.setState({
            discountList: this.changeInputValue(
                this.state.discountList,
                value,
                index,
                'inputValue'
            ),
        });
    }

    changeStockList(value: number = 0, index: number) {
        this.setState({
            stockList: this.changeInputValue(this.state.stockList, value, index, 'inputValue'),
        });
    }

    changeInputValue(items: AnyObject[], inputValue: number | string, index: number, key: string) {
        return items.map((item, idx) => (idx === index ? { ...item, [key]: inputValue } : item));
    }

    amountColumns = [
        {
            title: language.getText('organizationNumber'),
            render: (text: any, record: any, index: number) => `${index + 1}`,
            key: 'organizationNumber',
        },
        {
            title: language.getText('order.payMode'),
            dataIndex: 'paymentTypeName',
            key: 'paymentTypeName',
        },
        {
            title: language.getText('paymentDetail.amount'),
            dataIndex: 'paidAmount',
            key: 'paidAmount',
        },

        {
            title: language.getText('afterSale.refundItem.refundAmount'),
            key: 'refundAmount',
            render: (text: any, record: any, index: number) => {
                return (
                    <InputNumber
                        type='number'
                        min={0}
                        max={99999999}
                        precision={2}
                        value={text.inputValue}
                        onChange={(e) => this.changeAmountList(e, index)}
                        style={{ width: '150px' }}
                    />
                );
            },
        },
    ];

    discountColumns = [
        {
            title: language.getText('organizationNumber'),
            render: (text: any, record: any, index: number) => `${index + 1}`,
            key: 'organizationNumber',
        },
        {
            title: language.getText('order.payMode'),
            dataIndex: 'paymentTypeName',
            key: 'paymentTypeName',
        },
        {
            title: language.getText('paymentDetail.amount'),
            dataIndex: 'paidAmount',
            key: 'paidAmount',
        },
        {
            title: language.getText('afterSale.refundItem.refundAmount'),
            key: 'refundAmount',
            render: (text: any, record: any, index: number) => {
                return (
                    <InputNumber
                        type='number'
                        min={0}
                        max={99999999}
                        precision={2}
                        value={text.inputValue}
                        onChange={(e) => this.changeDiscountList(e, index)}
                        style={{ width: '150px' }}
                    />
                );
            },
        },
    ];

    stockColumns = [
        {
            title: language.getText('organizationNumber'),
            render: (text: any, record: any, index: number) => `${index + 1}`,
            key: 'organizationNumber',
        },
        {
            title: language.getText('productList.productName'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: language.getText('productList.csku'),
            dataIndex: 'csku',
            key: 'csku',
        },
        {
            title: language.getText('offlineOrder.purchasesNum'),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: language.getText('offlineOrder.returnLocation'),
            dataIndex: 'mcsLocation',
            key: 'mcsLocation',
            render: (text: any, record: any, index: number) => {
                return (
                    <Select
                        style={{ width: '150px' }}
                        value={record?.location}
                        allowClear
                        onChange={(e: string) => {
                            this.setState({
                                stockList: this.changeInputValue(
                                    this.state.stockList,
                                    e,
                                    index,
                                    'location'
                                ),
                            });
                        }}
                    >
                        {(record.mcsLocationList ?? []).map((item: any) => (
                            <Select.Option value={item} key={item}>
                                {item}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: language.getText('offlineOrder.returnStock'),
            key: 'returnStock',
            render: (text: any, record: any, index: number) => (
                // <span>
                //     <div>{language.getText('delete')}</div>
                // </span>
                <InputNumber
                    type='number'
                    min={0}
                    max={record?.quantity || 99999999}
                    width={100}
                    value={text.inputValue}
                    onChange={(e) => this.changeStockList(e, index)}
                    style={{ width: '150px' }}
                />
            ),
        },
    ];

    render() {
        const { confirmLoading, visible } = this.state;

        return (
            <>
                <AntButton
                    type='primary'
                    style={{ marginRight: 15 }}
                    onClick={() => this.toggleModal(true)}
                >
                    {language.getText('offlineOrder.refundAndReturn')}
                </AntButton>
                <AntModal
                    width={800}
                    title={language.getText('offlineOrder.refundAndReturn')}
                    visible={visible}
                    confirmLoading={confirmLoading}
                    okText={language.getText('offlineOrder.refundAndReturn')}
                    onOk={this.handleOk}
                    onCancel={() => this.toggleModal(false)}
                >
                    <AntTable
                        rowKey='id'
                        columns={this.amountColumns}
                        dataSource={this.state.amountList}
                        pagination={false}
                        style={{ marginBottom: '20px' }}
                    />
                    {!isEmpty(this.state.discountList) && (
                        <AntTable
                            rowKey='id'
                            columns={this.discountColumns}
                            dataSource={this.state.discountList}
                            pagination={false}
                            style={{ marginBottom: '20px' }}
                        />
                    )}
                    <AntTable
                        rowKey='id'
                        columns={this.stockColumns}
                        dataSource={this.state.stockList}
                        pagination={false}
                    />
                </AntModal>
            </>
        );
    }
}

import React, { useState, useContext } from 'react';
import { get, filter, groupBy } from 'lodash';
import { Progress, message, Descriptions, Button } from 'antd';
import { errorHandle, language, interpolate } from '@comall-backend-builder/core/lib/services';
import { Privilege } from '@/components/privilege';
import { KV } from '@/interfaces';
import { DELIVERY_TYPE_NAMES, PICK_STATUS_NAMES } from '@/constants/order';
import { PATH_NAME, PICKING_STATUS } from '../../enums';
import { IPickData } from '../../interfaces';
import { PickingReceipt, PackageReceipt } from '@/components/print-template';
import { PrintButton } from '@/components/print';
import { eventCenter } from '@/services/events';
import { EventCenterKeys } from '@/constants/event-center-keys';
import { findOrderIdsByPickingIds, createConfirmPromise } from '../../service';
import * as pickingApi from '../../api';
import { PickedTotalContext } from '..';
import './index.less';

const getText = language.getText;

interface IInfoCardProps extends AnyObject {
    /**
     * 订单数据
     */
    data: KV;
    /**
     * 拣货数据
     */
    pickLocationData: Array<IPickData>;
    /**
     * 状态变更成功回调
     */
    onSuccess: () => void;
    /**
     * 状态更改需要刷新拣货单 : 库存数量变化
     */
    onRefresh: () => void;
    /**
     * api状态
     */
    apiType: string;
}
// 开始拣货按钮
function PickingButton(props: IInfoCardProps) {
    const { data, onSuccess, apiType } = props;
    const [loading, setLoading] = useState(false);
    const [receiptInfo, setReceiptInfo] = useState<KV>({});

    const handlePicking = (printRef: any) => {
        const pickingIds = [data.id];
        setLoading(true);
        pickingApi
            .changePickingStatus(pickingIds, PICKING_STATUS.PICKING, 'picking', { apiType })
            .then(() => {
                return findOrderIdsByPickingIds(pickingIds, apiType);
            })
            .then((orderIds) => {
                return pickingApi.fetchReceiptsInfo(orderIds, 'picking', apiType);
            })
            .then(async (receiptData) => {
                setReceiptInfo(receiptData[0]);
                await printRef.current.onPrint();
                onSuccess();
            })
            .catch(errorHandle)
            .finally(() => setLoading(false));
    };

    return (
        <PrintButton
            loading={loading}
            type='primary'
            template={<PickingReceipt data={receiptInfo} />}
            onClick={handlePicking}
        >
            {getText('picking.starPrintPackaging')}
        </PrintButton>
    );
}

/**
 * 处理组合商品拣货
 * @param data
 */
const handleCombinationItem = (data: IPickData[]) => {
    const formatData = data.filter((item) => !!item.combinationSku);

    const combinationData = groupBy(formatData, 'combinationSku');
    Object.keys(combinationData).forEach((key: string) => {
        const locationPick: { [key: string]: any } = {};
        combinationData[key].forEach((combinationItem) => {
            if (combinationItem.hadPickQuantity % combinationItem.combinationQuantiy !== 0) {
                message.error(getText('picking.detail.combinationErrMsg'));
                throw new Error('');
            }
            combinationItem.pickingLocations.forEach((location) => {
                if (location.pickNum > 0) {
                    locationPick[location.location] = {
                        ...locationPick[location.location],
                        [combinationItem.goodsId]: `${location.pickNum}/${combinationItem.combinationQuantiy}`,
                    };
                }
            });
        });

        Object.keys(locationPick).forEach((location) => {
            const pickArray = Object.values(locationPick[location]);
            if (pickArray.length !== combinationData[key].length) {
                message.error(getText('picking.detail.combinationLocationErrMsg'));
                throw new Error('');
            }

            // eslint-disable-next-line no-eval
            if (!pickArray.every((i: any) => eval(i) === eval(pickArray[0] as string))) {
                message.error(getText('picking.detail.combinationLocationErrMsg'));
                throw new Error('');
            }
        });
    });

    return true;
};

// 完成拣货按钮
function FinishPickingButton(props: IInfoCardProps) {
    const { data, pickLocationData, onSuccess, onRefresh, apiType } = props;
    const [loading, setLoading] = useState(false);
    const [receiptInfo, setReceiptInfo] = useState<KV>({});

    const handleFinishPicking = (printRef: any) => {
        //判断是否存在拣货 > 库存
        //库存为负数 且拣货数为0时不比较
        let hadOverflowQuantity = false;
        for (let i = 0; i < pickLocationData.length; i++) {
            const item = pickLocationData[i];
            const overflowQuantity = item.pickingLocations.filter(
                (item) =>
                    item.pickNum > (item.quantity || 0) &&
                    !(item.pickNum === 0 && (item.quantity || 0) < 0)
            );
            hadOverflowQuantity = overflowQuantity.length > 0;
            if (hadOverflowQuantity) {
                message.error(getText('picking.detail.exceedMaxPickingNumErrMsg'));
                return;
            }
        }
        //筛选已拣货数量大于需拣货数量  数据
        const overflowPickData = filter(
            pickLocationData,
            (item) => item.hadPickQuantity > item.needPickQuantity
        );
        if (overflowPickData.length > 0) {
            message.error(getText('picking.detail.exceedPartialPickingNumErrMsg'));
            return;
        }

        // 组合商品需按比例及同仓库拣货
        try {
            handleCombinationItem(pickLocationData);
        } catch (e) {
            return;
        }
        //赠品不参与商品缺货判断
        /**
         * productType:
         * COMBINATION(1, "组合商品"),
         * VIRTUAL(2, "虚拟商品"),
         * GIFT(3, "赠品"),
         * ADDITIONAL(4, "加购商品");
         */
        const productLocationData = filter(pickLocationData, (item) => item.productType !== '3');

        //筛选缺货商品 不包含赠品
        const stockOutData = filter(
            productLocationData,
            (item) => item.hadPickQuantity < item.needPickQuantity
        );

        //判断是否全部缺货
        const allStockData = filter(productLocationData, (item) => item.hadPickQuantity === 0);

        const pickingIds = [data.id];
        createConfirmPromise({
            title: interpolate(getText('picking.detail.confirmPickingFinished'), {
                pickingListNumber: data.pickingListNumber,
            }),
            content: (
                <div className='picking-detail-info-modal-content'>
                    <span className='status-title'>{getText('picking.detail.pickStatus')}：</span>
                    <span className='status-content'>
                        {stockOutData.length === 0
                            ? getText('picking.detail.noStockOut')
                            : allStockData.length === productLocationData.length
                            ? getText('picking.detail.allStockOut')
                            : getText('picking.detail.partialStockOut')}
                    </span>
                    {stockOutData.length !== 0 &&
                        allStockData.length !== productLocationData.length && (
                            <div className='out-stock-goods-content'>
                                {stockOutData.map((item) => (
                                    <div className='out-stock-goods-item'>
                                        <div className='out-stock-title'>{item.goodsName}</div>
                                        <span className='out-stock-detail'>
                                            {getText('picking.detail.needPickNum')}:{' '}
                                            {item.needPickQuantity}
                                        </span>
                                        <span className='out-stock-detail'>
                                            {getText('picking.detail.pickedNum')}:{' '}
                                            {item.hadPickQuantity}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                </div>
            ),
        })
            .then(() => {
                setLoading(true);
                return pickingApi.changePickingStatus(
                    pickingIds,
                    PICKING_STATUS.FINISH_PICK,
                    'packed',
                    {
                        pickItems: pickLocationData.map((item) => {
                            return {
                                goodsId: item.goodsId,
                                pickingLocations: item.pickingLocations.map((item) => {
                                    return { location: item.location, quantity: item.pickNum || 0 };
                                }),
                                combinationGoodsId: item.combinationGoodsId,
                                combinationQuantiy: item.combinationQuantiy,
                                pickingListItemId: item.id,
                            };
                        }),
                        stockOutItems: filter(
                            pickLocationData,
                            (item) => item.hadPickQuantity < item.needPickQuantity
                        ).map((item) => {
                            return {
                                goodsId: item.goodsId,
                                pickingListItemId: item.id,
                                combinationQuantiy: item.combinationQuantiy,
                                stockOutQuantity: item.needPickQuantity - item.hadPickQuantity,
                            };
                        }),
                        apiType,
                    }
                );
            })
            .then(() => {
                return findOrderIdsByPickingIds(pickingIds, apiType);
            })
            .then((orderIds) => {
                return pickingApi.fetchPrintPickingList(orderIds, apiType);
            })
            .then(async (receiptData) => {
                setReceiptInfo(receiptData[0]);
                await printRef.current.onPrint();
                onSuccess();
            })
            .catch((err) => {
                const response = err.response;
                if (response && response.body && response.body.err_code === 400104102) {
                    message.error(getText('picking.detail.stockChangedErrMsg'));
                    onRefresh();
                } else {
                    errorHandle(err);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <PrintButton
            loading={loading}
            type='primary'
            template={<PackageReceipt data={receiptInfo} />}
            onClick={handleFinishPicking}
        >
            {getText('picking.detail.finishPickingAndPrint')}
        </PrintButton>
    );
}

function InfoCardComponent(props: IInfoCardProps) {
    const { data, pickLocationData } = props;

    const [pickedTotal] = useContext(PickedTotalContext);

    const list = [
        {
            label: 'picking.pickingListNumber',
            key: 'pickingListNumber',
        },
        {
            label: 'picking.outerOrderNumber',
            key: 'outerOrderNumber',
        },
        {
            label: 'picking.deliveryFlag',
            key: 'delivery',
            format: (value: any) =>
                get(DELIVERY_TYPE_NAMES, get(data, 'delivery.deliveryType')) || '',
        },
        {
            label: 'picking.earliestPickUpTime',
            key: 'delivery.preCanPickUpTime',
        },
        {
            label: 'picking.orderCustomer',
            key: 'delivery',
            format: (value: any) => {
                const name = [
                    `${get(value, 'orderLastName', '')}${get(value, 'orderFirstName', '')}`,
                    get(value, 'orderMobile', ''),
                    get(value, 'email', ''),
                ].filter((item) => !!item);
                return name.join(' - ');
            },
        },
        {
            label: 'picking.deliveryAddress',
            key: 'consignee',
            format: (value: any) => {
                const name = [
                    `${get(value, 'city', '')}`,
                    get(value, 'province', ''),
                    get(value, 'area', ''),
                    get(value, 'address', ''),
                ].filter((item) => !!item);
                return name.join('  ');
            },
        },
        {
            label: 'picking.sorterName',
            key: 'sorterName',
        },
    ];

    const needPickTotal = pickLocationData.reduce((total, cur) => total + cur.needPickQuantity, 0);

    return (
        <div className='picking-detail-info'>
            <div className='picking-state'>
                <Progress
                    type='circle'
                    percent={100}
                    width={110}
                    strokeWidth={3}
                    format={() => get(PICK_STATUS_NAMES, data.status)}
                />
            </div>
            <div className='picking-info'>
                <Descriptions column={1}>
                    {list.map((item) => {
                        const value = item.format
                            ? item.format(get(data, `${item.key}`))
                            : get(data, `${item.key}`);

                        return (
                            <Descriptions.Item key={item.label} label={getText(item.label)}>
                                {value || '-'}
                            </Descriptions.Item>
                        );
                    })}
                </Descriptions>
                <div className='picking-total'>
                    <div className='picking-total-item'>
                        {getText('picking.needPickTotal')}：{needPickTotal}
                    </div>
                    <div className='picking-total-item'>
                        {getText('picking.pickedTotal')}：{pickedTotal}
                    </div>
                </div>
            </div>
            <div className='picking-actions'>
                {data.status === PICKING_STATUS.WAIT_PICKING && (
                    <Privilege
                        path={
                            props.pickPathname === PATH_NAME.PICK_PATH_NAME
                                ? 'picking.assistant.print'
                                : 'picking_philips.assistant_philips.print_philips'
                        }
                    >
                        <PickingButton {...props} />
                    </Privilege>
                )}
                {data.status === PICKING_STATUS.PICKING && (
                    <Privilege
                        path={
                            props.pickPathname === PATH_NAME.PICK_PATH_NAME
                                ? 'picking.assistant.finished_picking'
                                : 'picking_philips.assistant_philips.finished_picking_philips'
                        }
                    >
                        <>
                            <Button
                                style={{
                                    marginRight: '15px',
                                }}
                                onClick={() => {
                                    eventCenter.trigger(EventCenterKeys.packingComplete);
                                }}
                            >
                                {getText('picking.complete')}
                            </Button>
                            <FinishPickingButton {...props} />
                        </>
                    </Privilege>
                )}
            </div>
        </div>
    );
}

export const InfoCard = InfoCardComponent;

import { get } from 'lodash';
/**
 * 格式化数字串
 *
 * @param value 数值
 * @param precision 保留小数位数
 * @param fill 小数位数不够是否用0填充
 */
function formatNumber(value: number | string, precision = 2, fill = true) {
    let [integer, fraction = ''] = String(value).split(/\./);
    if (precision === 0) return integer;
    if (fraction.length < precision) {
        if (!fill) return String(value);
        fraction = fraction.padEnd(precision, '0');
    }
    return `${integer}.${fraction.slice(0, precision)}`;
}
const formatProductReportParams = (data: any) => {
    if (!data) return data;
    if (data.departmentGroupDescription) {
        data.departmentGroupDescription = data.departmentGroupDescription.map((item: any) => {
            return item.name;
        });
    }
    if (data.brandName) {
        data.brandName = data.brandName.map((item: any) => {
            return item.name;
        });
    }
    if (data.divisionNumbers) {
        data.divisionNumbers = data.divisionNumbers.map((item: any) => {
            return item.name;
        });
    }
    if (data.createdDate) {
        data.startTime = data.createdDate.start;
        data.endTime = data.createdDate.end;
    }
    delete data.createdDate;
    return data;
};
export const formatUrlParams = (url: string): any => {
    // url = (url + '').replace(/#\/.*/, '');
    const math = url.match(/([^?=&]+)(=([^&]*))/g);
    if (!math) {
        return {};
    }
    let param: any = {};
    math.map((v: string) => {
        param[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1);
        return v;
    });
    return param;
};
interface TouchPoint {
    id: '1' | '5' | '6' | string;
    name: 'Web' | 'H5' | '微信小程序' | string;
}
interface Channel {
    id: '5' | '3' | '7' | string;
    name: 'SELECT' | 'ESHOP' | 'GUIDE_SHOPPING' | string;
}
export const formatFullName = ({
    touchPoint,
    firstName,
    lastName,
    channel,
}: {
    touchPoint: TouchPoint;
    firstName: string;
    lastName: string;
    channel: Channel;
}) => {
    if (get(touchPoint, 'id') === '1' || get(channel, 'name') === 'SELECT') {
        return `${lastName} ${firstName}`;
    }
    return `${firstName} ${lastName}`;
};
export { formatNumber, formatProductReportParams };

import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, formFooter, tableMixin } from '@/configs/mixin';
import { EVENT_SHOW_STATUS_OPTIONS } from '@/constants';
import { buildForm } from './coupon-package-form';

export const config: Config = {
    entities: {
        couponPackage: {
            apiPath: '/loader/dc-ecoupon/admin/coupons/pack',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '<<couponManage.couponPackage.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 100,
                    },
                },
                effectStartTime: {
                    type: 'string',
                    displayName: '<<couponManage.couponPackage.effectStartTime>>',
                },
                effectEndTime: {
                    type: 'string',
                    displayName: '<<couponManage.couponPackage.effectEndTime>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<couponManage.couponPackage.code>>',

                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 50,
                    },
                },
                status: {
                    type: 'string.status',
                    displayName: '<<couponManage.couponPackage.status>>',
                },
                effectTime: {
                    type: 'object.dateTimeRange',
                    displayName: '<<couponManage.couponPackage.effectTime>>',
                },
                storeIds: {
                    type: 'array.onlineStoreGroup',
                    displayName: '<<couponManage.storeIds.title>>',
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/findAll',
                    },
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                        titles: [
                            '<<couponManage.storeIds.left>>',
                            '<<couponManage.storeIds.right>>',
                        ],
                        nameProp: 'onlineStoreName',
                    },
                },
                applyCouponParamsList: {
                    type: 'array.couponGroup',
                    displayName: '<<couponManage.couponPackage.couponGroup>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<couponManage.couponPackage.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                storeIds: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<couponManage.couponPackage.onlineStoreId>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        CouponPackagePage: {
            entity: 'couponPackage',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'name',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                        {
                            property: 'storeIds',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<couponManage.couponPackage.addTitle>>',
                            route: '/coupon-package/add',
                            privilege: {
                                path: 'coupon.coupons-pack.coupons-pack-add',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'CouponPackageTable',
                    privilege: {
                        path: 'coupon.coupons-pack.coupons-pack-list',
                        level: 'full',
                    },
                },
            ]),
        },
        CouponPackageTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'effectStartTime',
                },
                {
                    property: 'effectEndTime',
                },
                {
                    property: 'code',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: EVENT_SHOW_STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                true: '/dc-ecoupon/admin/coupons/pack/disable/{{row.id}}',
                                false: '/dc-ecoupon/admin/coupons/pack/enable/{{row.id}}',
                            },
                            statusText: {
                                true: '<<disable>>',
                                false: '<<enable>>',
                            },
                            linkProperty: 'enable',
                            errorStatus: true,
                        },
                        privilege: {
                            path: 'coupon.coupons-pack.coupons-pack-enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/coupon-package/edit/{{row.id}}',
                        privilege: {
                            path: 'coupon.coupons-pack.coupons-pack-detail',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<common.delete>>',
                        config: {
                            actionType: 'delete',
                            source: {
                                apiPath: '/dc-ecoupon/admin/coupons/pack',
                            },
                        },
                        privilege: {
                            path: 'coupon.coupons-pack.coupons-pack-delete',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        CouponPackageAddPage: {
            component: 'Card',
            entity: 'couponPackage',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
            privilege: {
                path: 'coupon.coupons-pack.coupons-pack-add',
                level: 'full',
            },
        },
        CouponPackageEditPage: {
            component: 'Card',
            entity: 'couponPackage',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
            privilege: {
                path: 'coupon.coupons-pack.coupons-pack-update',
                level: 'full',
            },
        },
    },
};

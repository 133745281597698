import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, tableMixin } from '@/configs/mixin';
// import { STATUS_NUMBER_OPTIONS } from '@/constants';
import { MEMBER_SHIP_LIST } from '@/configs/pro-table-constants';
import { MEMBER_SPECIAL_TYPE_OPTIONS, MEMBER_SPECIAL_TYPE_VALUES } from '@/constants/user';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        membershipLevelList: {
            apiPath: '/loader/dc-user/admin/memberLevelList',
            properties: {
                id: { type: 'string', displayName: '<<membership.id>>' },
                levelNumber: {
                    type: 'string',
                    displayName: '<<membershipLevel.level>>',
                },
                sequence: {
                    type: 'string',
                    displayName: '<<membershipLevel.sequence>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<membershipLevel.name>>',
                },
                expLower: {
                    type: 'string',
                    displayName: '<<membershipLevel.expStart>>',
                },
                expUpper: {
                    type: 'string',
                    displayName: '<<membershipLevel.expEnd>>',
                },
                picUrl: {
                    type: 'string.image.text',
                    displayName: '<<membershipLevel.picUrl>>',
                },
                memberPricePicUrl: {
                    type: 'string.image.text',
                    displayName: '<<membershipLevel.memberPriceIcon>>',
                },
                cardPicUrl: {
                    type: 'string.image.text',
                    displayName: '<<membershipLevel.cardPicUrl>>',
                },
                blackSkuSize: {
                    type: 'string',
                    displayName: '<<membershipLevel.blackSkuSize>>',
                },
                memberPricSize: {
                    type: 'string',
                    displayName: '<<membershipLevel.memberPricSize>>',
                },
                type: {
                    type: 'string.options.radio',
                    displayName: '<<membershipLevel.type.title>>',
                    options: MEMBER_SPECIAL_TYPE_OPTIONS,
                },
                // status: {
                //     type: 'string.status',
                //     displayName: '<<membershipLevel.status>>',
                // },
            },
            filters: {},
        },
    },
    components: {
        MembershipLevelListContainer: {
            component: 'Viewport',
        },
        MembershipLevelList: {
            entity: 'membershipLevelList',
            ...createPageLayout([
                {
                    privilege: {
                        path: 'member.member_level_view.add',
                        level: 'full',
                    },
                    component: 'MembershipLevelListAction',
                    style: { marginBottom: 15 },
                },
                {
                    privilege: {
                        path: 'member.member_level_view.view_list',
                        level: 'full',
                    },
                    component: 'MembershipLevelListTable',
                },
            ]),
        },
        MembershipLevelListAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Button',
                    text: '<<membershipLevel.add>>',
                    type: 'primary',
                    route: '/member-level/create',
                    style: {
                        width: 180,
                    },
                    privilege: {
                        path: 'member.member_level_view.add',
                        level: 'full',
                    },
                },
            ],
        },
        MembershipLevelListTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: MEMBER_SHIP_LIST,
            columns: [
                { property: 'type' },
                { property: 'levelNumber' },
                { property: 'sequence' },
                { property: 'name' },
                { property: 'expLower' },
                { property: 'expUpper' },
                { property: 'blackSkuSize' },
                { property: 'memberPricSize' },
                {
                    property: 'picUrl',
                    width: 150,
                    displayConfig: {
                        style: {
                            width: 50,
                            height: 50,
                            border: '1px solid #eee',
                        },
                    },
                },
                {
                    property: 'memberPricePicUrl',
                    width: 150,
                    displayConfig: {
                        style: {
                            width: 50,
                            height: 50,
                            border: '1px solid #eee',
                        },
                    },
                },
                {
                    property: 'cardPicUrl',
                    width: 150,
                    displayConfig: {
                        style: {
                            width: 50,
                            height: 50,
                            border: '1px solid #eee',
                        },
                    },
                },
                // {
                //     property: 'status',
                //     displayConfig: {
                //         statusConfig: STATUS_NUMBER_OPTIONS,
                //     },
                // },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/member-level/edit/{{row.id}}',
                        privilege: {
                            path: 'member.member_level_view.update',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<membershipLevel.blackSkuProduct.title>>',
                        type: 'link',
                        path: '/member-level/black-product-list/{{row.id}}',
                        privilege: {
                            path: 'member.member_level_view.black_list',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<membershipLevel.memberPriceProduct.title>>',
                        type: 'link',
                        path: '/member-level/price-product-list/{{row.id}}',
                        privilege: {
                            path: 'member.member_level_view.member_prices',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        config: {
                            actionType: 'component',
                            component: 'Button',
                            renderConfig: (row: any, props: any) => {
                                if (`${row.type}` === MEMBER_SPECIAL_TYPE_VALUES.SPECIAL) {
                                    return {
                                        children: language.getText(
                                            'membershipLevel.memberLevelMember.title'
                                        ),
                                        type: 'link',
                                        row,
                                        onClick: () => {
                                            // 跳转管理会员页面
                                            return services.navigation.goto(
                                                `/member-level/member/${row.id}`
                                            );
                                        },
                                    };
                                } else
                                    return {
                                        style: { display: 'none' },
                                    };
                            },
                        },
                        privilege: {
                            path: 'member.member_level_view.view_level_member_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

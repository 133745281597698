import { TYPE_NAME_VALUES } from '@/constants/event-marketing';
import { services } from '@comall-backend-builder/core';

export function buildForm(mode: string) {
    return {
        component: 'EventMarketingForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'cards',
                itemLayout: 'vertical',
                key: 'cards',
                split: false,
                items: [
                    {
                        renderType: 'cardPane',
                        title: '<<salesPromotion.eventMarketing.base.title>>',
                        key: 'cards.base',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'type',
                                key: 'type',
                                disabled: mode !== 'add',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'name',
                                key: 'name',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'effectTime',
                                key: 'effectTime',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'storeIds',
                                key: 'storeIds',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        key: 'cards.conditionsReward',
                        title: '<<salesPromotion.eventMarketing.conditionsReward.title>>',
                        bordered: false,
                        renderable: (values: AnyObject) =>
                            values.type === TYPE_NAME_VALUES.FREE_ON_FULL_ORDER ||
                            values.type === TYPE_NAME_VALUES.REWARD_FOR_INVITING_OTHERS ||
                            values.type === TYPE_NAME_VALUES.TIMED_RELEASE,
                        items: [
                            {
                                renderType: 'field',
                                property: 'orderAmount',
                                key: 'orderAmount',
                                formItem: {
                                    labelCol: { span: 8 },
                                    wrapperCol: { span: 10 },
                                },
                                renderable: (values: AnyObject) =>
                                    values.type === TYPE_NAME_VALUES.FREE_ON_FULL_ORDER,
                                fieldOpts: {
                                    rules: [
                                        { required: true },
                                        {
                                            validator: (_rule: any, value: any, callback: any) => {
                                                const reg = /^([1-9]+[\d]*(.[0-9]{1,2})?)$/;
                                                if (
                                                    !reg.test(value) ||
                                                    value.toString().length > 8
                                                ) {
                                                    return new Error(
                                                        services.language.getText(
                                                            'salesPromotion.eventMarketing.matchArrangement.validNumbers'
                                                        )
                                                    );
                                                } else if (!value) {
                                                    return new Error(
                                                        services.language.getText(
                                                            'salesPromotion.eventMarketing.matchArrangement.orderAmount'
                                                        )
                                                    );
                                                }
                                                callback();
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'invitationWay',
                                key: 'invitationWay',
                                formItem: {
                                    labelCol: { span: 8 },
                                    wrapperCol: { span: 10 },
                                },
                                renderable: (values: AnyObject) =>
                                    values.type === TYPE_NAME_VALUES.REWARD_FOR_INVITING_OTHERS,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'rewardWays',
                                key: 'rewardWays',
                                renderable: (values: AnyObject) =>
                                    values.type === TYPE_NAME_VALUES.REWARD_FOR_INVITING_OTHERS,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'memberMobiles',
                                key: 'memberMobiles',
                                placeholder:
                                    '<<salesPromotion.eventMarketing.conditionsReward.phonePlaceholder>>',
                                maxLength: 100000,
                                renderable: (values: AnyObject) =>
                                    values.type === TYPE_NAME_VALUES.TIMED_RELEASE,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'timedReleaseTime',
                                key: 'timedReleaseTime',
                                renderable: (values: AnyObject) =>
                                    values.type === TYPE_NAME_VALUES.TIMED_RELEASE,
                                fieldOpts: {
                                    initialValue: '00',
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '<<salesPromotion.eventMarketing.matchArrangement.title>>',
                        key: 'cards.matchArrangement',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'prizeSetting',
                                key: 'prizeSetting',
                                formItem: {
                                    labelCol: { span: 2 },
                                    wrapperCol: { span: 18 },
                                },
                                fieldOpts: {
                                    rules: [
                                        { required: true },
                                        {
                                            validator: (_rule: any, value: any, callback: any) => {
                                                if (value) {
                                                    if (
                                                        (value.val === '' || value.val === null) &&
                                                        !value.items &&
                                                        !value.other
                                                    ) {
                                                        return new Error(
                                                            services.language.getText(
                                                                'salesPromotion.eventMarketing.matchArrangement.selectReward'
                                                            )
                                                        );
                                                    } else if (
                                                        value.items &&
                                                        value.items.length === 0 &&
                                                        !value.val &&
                                                        !value.other
                                                    ) {
                                                        return new Error(
                                                            services.language.getText(
                                                                'salesPromotion.eventMarketing.matchArrangement.selectReward'
                                                            )
                                                        );
                                                    } else if (
                                                        value.other &&
                                                        value.other.length === 0 &&
                                                        !value.val &&
                                                        !value.items
                                                    ) {
                                                        return new Error(
                                                            services.language.getText(
                                                                'salesPromotion.eventMarketing.matchArrangement.selectReward'
                                                            )
                                                        );
                                                    } else if (
                                                        value.items &&
                                                        value.other &&
                                                        value.items.length === 0 &&
                                                        value.other.length === 0 &&
                                                        !value.val
                                                    ) {
                                                        return new Error(
                                                            services.language.getText(
                                                                'salesPromotion.eventMarketing.matchArrangement.selectReward'
                                                            )
                                                        );
                                                    } else if (Object.keys(value).length === 0) {
                                                        return new Error(
                                                            services.language.getText(
                                                                'salesPromotion.eventMarketing.matchArrangement.selectReward'
                                                            )
                                                        );
                                                    } else {
                                                        callback();
                                                    }
                                                } else {
                                                    callback();
                                                }
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };
}

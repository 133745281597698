import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';

export const ENABLED_OPTIONS = [
    { id: 'true', name: '<<topicManage.validStatusTab.1>>' },
    { id: 'false', name: '<<topicManage.validStatusTab.2>>' },
];

export const ENABLED_STATUS = [
    {
        id: 'true',
        name: 'topicManage.validStatusTab.1',
        status: 'success',
    },
    {
        id: 'false',
        name: 'topicManage.validStatusTab.2',
        status: 'success',
    },
];

export const config: Config = {
    entities: {
        TopicManageEntity: {
            apiPath: '/loader/new_visual_config/topic',
            properties: {
                displayId: { type: 'string', displayName: '<<topicManage.columns.id>>' },
                name: { type: 'string', displayName: '<<topicManage.columns.name>>' },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<topicManage.columns.onlineStoreName>>',
                },
                touchPoint: { type: 'string', displayName: '<<topicManage.columns.tap>>' },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<topicManage.columns.status>>',
                    options: ENABLED_OPTIONS,
                    displayConfig: {
                        statusConfig: ENABLED_STATUS,
                    },
                },
                createUser: {
                    type: 'string',
                    displayName: '<<topicManage.columns.createUser>>',
                },
                lastModifyUser: {
                    type: 'string',
                    displayName: '<<topicManage.columns.modifyUser>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<topicManage.columns.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<topicManage.columns.lastModifyTime>>',
                },
                onlineStoreId: {
                    type: 'string',
                    displayName: '<<topicManage.columns.onlineStoreId>>',
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<topicManage.filter.keywords>>',
                    controlConfig: {
                        style: { width: '120px' },
                    },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<topicManage.filter.status>>',
                    options: ENABLED_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<common.pleaseSelect>>',
                        style: { width: '130px' },
                    },
                },
                onlineStoreCode: {
                    type: 'string.options.select',
                    displayName: '<<topicManage.filter.onlineStoreIds>>',
                    options: [],
                    source: {
                        apiPath: '/loader/new_visual_config/online_store/pull_list',
                    },
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<common.pleaseSelect>>',
                        style: { width: '220px' },
                    },
                },
            },
        },
        TopicManageHandleEntity: {
            apiPath: '/loader/new_visual_config/topic/page',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<topicManage.form.name>>',
                    rules: [
                        { required: true, whitespace: true },
                        {
                            max: 50,
                            message: '<<topicManage.form.nameMaxLength>>',
                        },
                    ],
                },
                enabled: {
                    type: 'string.options.radio',
                    displayName: '<<topicManage.form.status>>',
                    options: ENABLED_OPTIONS,
                    rules: [{ required: true }],
                    defaultValue: 'false',
                },
                onlineStoreId: {
                    type: 'string.options.select',
                    displayName: '<<topicManage.form.onlineStoreIds>>',
                    options: [],
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<common.pleaseSelect>>',
                    },
                    rules: [{ required: true }],
                },
                touchPoint: {
                    type: 'string.options.radio',
                    displayName: '<<topicManage.form.touchPoint>>',
                    options: [],
                    rules: [{ required: true }],
                },
                independent: {
                    type: 'string.options.radio',
                    displayName: '<<topicManage.form.independent>>',
                    options: [
                        {
                            id: 'true',
                            name: '<<topicManage.independentTab.1>>',
                        },
                        {
                            id: 'false',
                            name: '<<topicManage.independentTab.2>>',
                        },
                    ],
                    extra: '<<topicManage.independentExtra>>',
                    rules: [{ required: true }],
                },
            },
        },
    },
    components: {
        TopicManageView: {
            component: 'Viewport',
            entity: 'TopicManageEntity',
        },
        TopicManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: 20,
            },
            items: [
                {
                    component: 'topicManageHeader',
                    privilege: {
                        path: 'finish.topic_manage_new.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('table.total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: 'max-content' },
                    columns: [
                        { property: 'displayId' },
                        { property: 'name', width: 180 },
                        { property: 'onlineStoreName', width: 180 },
                        { property: 'onlineStoreId' },
                        { property: 'touchPoint' },
                        { property: 'enabled' },
                        { property: 'createUser' },
                        { property: 'lastModifyUser' },
                        { property: 'createTime', width: 180 },
                        { property: 'lastModifyTime', width: 180 },
                    ],
                    actionColumn: {
                        title: '<<common.operator>>',
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'RowLink',
                                text: '<<common.decorate>>',
                                config: {
                                    path: '/virtual-design/{{row.id}}?pageType=topic',
                                    target: '_blank',
                                },
                                privilege: {
                                    path: 'finish.topic_manage_new.draw',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'component',
                                component: 'RowLink',
                                text: '<<common.edit>>',
                                config: {
                                    path: '/search/topic-manage/edit/{{row.id}}',
                                    disabledTips: '<<topicManage.disabledEditTips>>',
                                },
                                privilege: {
                                    path: 'finish.topic_manage_new.edit',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'component',
                                component: 'TopicManageCopyButton',
                                componentId: 'topicManageCopyButton',
                                privilege: {
                                    path: 'finish.topic_manage_new.copy',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'component',
                                component: 'TableActionStateful',
                                text: '<<common.delete>>',
                                config: {
                                    actionType: 'delete',
                                    renderConfig: (row: any, props: any) => {
                                        return {
                                            disabled: row.enabled,
                                            tooltip: '<<topicManage.disabledDeleteTips>>',
                                        };
                                    },
                                    source: {
                                        apiPath: '/dc-cms-api/admin/new_visual_config/topic',
                                    },
                                },
                                privilege: {
                                    path: 'finish.topic_manage_new.delete',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                    privilege: {
                        path: 'finish.topic_manage_new.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        topicManageHeader: {
            component: 'FlexLayout',
            style: {
                marginBottom: 20,
                alignItems: 'center',
            },
            items: [
                {
                    component: 'TopicManageAddButtons',
                    privilege: {
                        path: 'finish.topic_manage_new.add',
                        level: 'full',
                    },
                },
                {
                    component: 'topicManageFilter',
                },
            ],
        },
        TopicManageAddButtons: {
            component: 'GridLayout',
            items: [
                {
                    component: 'Button',
                    text: '<<topicManage.addBtn>>',
                    icon: 'plus',
                    type: 'primary',
                    route: '/search/topic-manage/add',
                },
            ],
        },
        topicManageFilter: {
            component: 'FilterForm',
            componentId: 'DesignTopicFilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'keyword',
                },
                {
                    property: 'enabled',
                },
                {
                    property: 'onlineStoreCode',
                },
            ],
            reset: true,
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
        },
        TopicManagePageAdd: {
            component: 'TopicManageHandle',
            componentId: 'TopicManageHandle',
            formType: 'CreateForm',
            entity: 'TopicManageHandleEntity',
            labelCol: 4,
            controlCol: 8,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            style: {
                paddingTop: 24,
            },
            footer: {
                items: [
                    {
                        type: 'default',
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        route: 'goBack',
                        style: {
                            marginRight: 10,
                        },
                    },
                    {
                        text: '<<common.ok>>',
                    },
                ],
                style: {
                    textAlign: 'right',
                },
            },
        },
        TopicManagePageEdit: {
            component: 'TopicManageHandle',
            componentId: 'TopicManageHandle',
            formType: 'ModifyForm',
            entity: 'TopicManageHandleEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 8,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            style: {
                paddingTop: 24,
            },
            footer: {
                items: [
                    {
                        type: 'default',
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        route: 'goBack',
                        style: {
                            marginRight: 10,
                        },
                    },
                    {
                        text: '<<common.ok>>',
                    },
                ],
                style: {
                    textAlign: 'right',
                },
            },
        },
    },
};

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { DatePicker } from 'antd';

import moment from 'moment';
import { DatePickerProps } from 'antd/lib/date-picker/interface';

/**
 * 日期时间选择器类型
 *
 * 支持起始时间和结束时间
 */
export class DateTimePlusMode extends modes.StringMode {
    getDisplayComponent(value: any, displayConfig: any) {
        if (displayConfig.format) {
            value = moment(value, 'YYYY-MM-DD HH:mm:ss').format(displayConfig.format);
        }
        return <span>{value}</span>;
    }
    getControlComponent(controlInfo: any) {
        return <DateTimePicker {...controlInfo} />;
    }
}

interface DateTimePickerProps extends Omit<DatePickerProps, 'onChange'> {
    startDate?: string;
    endDate?: string;
    onChange?(date: string): void;
}

const DateTimePicker = React.forwardRef<any, DateTimePickerProps>((props, ref) => {
    const { startDate: _startDate, endDate: _endDate, value, onChange, ...resetProps } = props;

    let disabledDate: any | undefined;
    let disabledTime: any | undefined;

    if (_startDate) {
        const startDate = moment(_startDate);

        disabledDate = (current: moment.Moment) => {
            return current && moment(current).isBefore(startDate, 'day');
        };

        disabledTime = (current: moment.Moment) => {
            if (current && moment(current).isSame(startDate, 'day')) {
                const hours = startDate.hours();
                const minutes = startDate.minutes();
                const seconds = startDate.seconds();
                return {
                    disabledHours: () => range(0, hours),
                    disabledMinutes: (selectedHours: number) =>
                        selectedHours <= hours ? range(0, minutes) : [],
                    disabledSeconds: (selectedHours: number, selectedMinutes: number) =>
                        selectedHours <= hours && selectedMinutes <= minutes
                            ? range(0, seconds)
                            : [],
                };
            }
        };
    }
    if (_endDate) {
        const endDate = moment(_endDate);

        const disabledStartDate = disabledDate;
        disabledDate = (current: moment.Moment) => {
            return (
                disabledStartDate?.(current) === true ||
                (current && moment(current).isAfter(endDate, 'day'))
            );
        };

        const disabledStartTime = disabledTime;
        disabledTime = (current: moment.Moment) => {
            const start = disabledStartTime?.(current);
            if (start) return start;

            if (current && moment(current).isSame(endDate, 'day')) {
                const hours = endDate.hours();
                const minutes = endDate.minutes();
                const seconds = endDate.seconds();
                return {
                    disabledHours: () => range(hours, 24),
                    disabledMinutes: (selectedHours: number) =>
                        selectedHours >= hours ? range(minutes, 60) : [],
                    disabledSeconds: (selectedHours: number, selectedMinutes: number) =>
                        selectedHours >= hours && selectedMinutes >= minutes
                            ? range(seconds, 60)
                            : [],
                };
            }
        };
    }

    function range(current: number, end: number) {
        const ranges = [];
        while (current < end) {
            ranges.push(current);
            current++;
        }
        return ranges;
    }

    return (
        <DatePicker
            ref={ref}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
            format='YYYY-MM-DD HH:mm:ss'
            showTime
            value={value && moment(value)}
            onChange={(_, date) => onChange?.(date)}
            {...resetProps}
        />
    );
});

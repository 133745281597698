import React from 'react';
import classNames from 'classnames';
import './index.less';
interface Props {
    label: any;
    className?: any;
}
export const OrderInfoLine: React.FC<Props> = (props) => {
    const { label, children, className } = props;
    //@ts-ignore
    if (!children.props.children) return null;
    return (
        <div className={classNames('order-info-line ', className)}>
            <div className='order-info-label' dangerouslySetInnerHTML={{ __html: label }} />
            {children}
        </div>
    );
};

import React from 'react';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { load } from '@comall-backend-builder/core/lib/loaders';
import { useMount, useSafeState, useUpdateEffect } from 'ahooks';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { get, isNumber } from 'lodash';

export interface LoadSourceDependencie {
    /**
     * 依赖字段
     */
    property: string;
    /**
     * 依赖字段添加到请求参数
     */
    appendToParams?: boolean;
    /**
     * 请求参数别名（默认取 property）
     */
    paramKeyAlias?: string;
    /**
     * 请求参数默认值
     */
    defaultParamValue?: any;
}

export interface LoadSourceMeta extends ApiRequestConfig {
    /**
     * 参数
     */
    params: AnyObject;
    /**
     * 依赖项
     */
    dependencies?: LoadSourceDependencie[];
    /**
     * 重新加载时清空旧值
     */
    cleanValueOnReload?: boolean;
}

export interface LoadSourceOptionsProps {
    options: AnyObject[];
    entity?: Entity;
    source: LoadSourceMeta;
    onReload(): void;
    children(options: AnyObject[]): React.ReactElement;
}

export const LoadSourceOptions: React.FC<LoadSourceOptionsProps> = (props) => {
    const { options: defaultOptions, entity, source, children, onReload } = props;
    const [options, setOptions] = useSafeState<AnyObject[]>([]);
    const { params = {}, dependencies = [], ...config } = source;
    const depValues = getDepValues();
    useMount(loadOptions);

    useUpdateEffect(() => {
        loadOptions();
        onReload();
    }, depValues);

    async function loadOptions() {
        const depParams = getDepParams();
        const data = await load('get', {
            ...config,
            params: {
                ...params,
                ...depParams,
            },
        });

        let options = get(data, 'result') || data;
        if (!Array.isArray(options)) options = [];
        setOptions(options);
    }

    function getDepValues() {
        const depValues = [];
        if (entity) {
            for (const dep of dependencies) {
                depValues.push((entity.fields as AnyObject)[dep.property]);
            }
        }
        return depValues;
    }

    function getDepParams() {
        const depParams: AnyObject = {};
        Object.values(dependencies).forEach((dep, index) => {
            if (dep.appendToParams) {
                const paramKey = dep.paramKeyAlias ?? dep.property;
                const paramValue = depValues[index] ?? dep.defaultParamValue;
                if (isNumber(paramValue) || paramValue) {
                    depParams[paramKey] = paramValue;
                }
            }
        });
        return depParams;
    }

    return children(options.length ? options : defaultOptions);
};

import { ORDER_STATUS_VALUES, ORDER_STATUS_NAMES } from '@/constants';
import { services } from '@comall-backend-builder/core';
/**
 * 拣货状态枚举
 */
export enum PICKING_STATUS {
    /**
     * 待拣货
     */
    WAIT_PICKING = 'ASSEMBLED',
    /**
     * 拣货中
     */
    PICKING = 'PICKING',
    /**
     * 拣货完成
     */
    FINISH_PICK = 'FINISH_PICK',
}

/**
 * 拣货数据中订单状态枚举
 */
export const ORDER_STATUS = Object.assign(ORDER_STATUS_VALUES, {
    /**
     * 缺货订单
     */
    STOCK_OUT: 'STOCKOUT',
    /**
     * 拣货中
     */

    PICKING: 'PICKING',
    /**
     * 待打包
     */
    WAITING_PICK: 'WAITING_PICK',
});

export const ORDER_STATUS_PROGRESS_NAMES = Object.assign(ORDER_STATUS_NAMES, {
    /**
     * 拣货中
     */
    [ORDER_STATUS.PICKING]: services.language.getText('order.statusMap.picking'),
    [ORDER_STATUS.STOCK_OUT]: services.language.getText('picking.status.stockOut'),
    PAID: services.language.getText('order.statusMap.paid'),
});
/**
 * 入库状态枚举
 */
export enum STORAGE_STATUS {
    /**
     * 待出库
     */
    WAITING = 'WAITING',
}

/**
 * API类型 key
 */
export enum API_TYPE_KEYS {
    PICKING = 'picking',
    ORDER = 'order',
}

/**
 * API类型
 */
export enum API_TYPE {
    // 拣货打包
    PICKING = '/dc-order-picking',

    // 订单服务
    ORDER = '/base-order',

    // 飞利浦拣货打包
    PICKING_PHILIPS = '/dc-order-picking-philips',

    // 飞利浦订单服务
    ORDER_PHILIPS = '/base-order-philips',
}

/**
 * 跳转path
 */
export enum PATH_NAME {
    PICK_PATH_NAME = 'picking-assistant',
    ORDER_PATH_NAME = 'orders',
    PHILIPS_PICK_PATH_NAME = 'picking-assistant-philips',
    PHILIPS_ORDER_PATH_NAME = 'orders-philips',
}

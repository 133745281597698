import { APPLY_TYPE_VALUES, EXCLUDE_TYPE_LIMIT_VALUES } from '@/constants/coupon-manage';

export function buildForm(mode: string) {
    return {
        component: 'SuperForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'cards',
                itemLayout: 'vertical',
                key: 'cards',
                split: false,
                items: [
                    {
                        renderType: 'cardPane',
                        title: '基本设置',
                        key: 'cards.base',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'name',
                                key: 'name',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'storeIds',
                                key: 'storeIds',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'sequence',
                                key: 'sequence',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: /^[1-9]\d{0,7}$/,
                                            message: '请输入8位正整数',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '商品设置',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'specialType',
                                key: 'specialType',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyGoods',
                                key: 'applyGoods',
                                renderable: (values: AnyObject) =>
                                    values.specialType === APPLY_TYPE_VALUES.GOODS,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyBrand',
                                key: 'applyBrand',
                                renderable: (values: AnyObject) =>
                                    values.specialType === APPLY_TYPE_VALUES.BRAND,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyOfflineCategory',
                                key: 'applyOfflineCategory',
                                renderable: (values: AnyObject) =>
                                    values.specialType === APPLY_TYPE_VALUES.OFFLINE_CATEGORY,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyVirtualCategory',
                                key: 'applyVirtualCategory',
                                renderable: (values: AnyObject) =>
                                    values.specialType === APPLY_TYPE_VALUES.VIRTUAL_CATEGORY,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'excludeType',
                                key: 'excludeType',
                            },
                            {
                                renderType: 'field',
                                property: 'excludeGoods',
                                key: 'excludeGoods',
                                renderable: (values: AnyObject) =>
                                    values.excludeType === EXCLUDE_TYPE_LIMIT_VALUES.GOODS,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeBrand',
                                key: 'excludeBrand',
                                renderable: (values: AnyObject) =>
                                    values.excludeType === EXCLUDE_TYPE_LIMIT_VALUES.BRAND,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeOfflineCategory',
                                key: 'excludeOfflineCategory',
                                renderable: (values: AnyObject) =>
                                    values.excludeType ===
                                    EXCLUDE_TYPE_LIMIT_VALUES.OFFLINE_CATEGORY,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeVirtualCategory',
                                key: 'excludeVirtualCategory',
                                renderable: (values: AnyObject) =>
                                    values.excludeType ===
                                    EXCLUDE_TYPE_LIMIT_VALUES.VIRTUAL_CATEGORY,
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '限购设置',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'limitNum',
                                key: 'limitNum',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: /^[1-9]\d{0,7}$/,
                                            message: '请输入8位正整数',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };
}

import { services } from '@comall-backend-builder/core/lib';

function formDataToApiData(data: AnyObject) {
    data.effectStartTime = data.effectTime.start;
    data.effectEndTime = data.effectTime.end;
    data.applyCouponParamsList = data.applyCouponParamsList?.map(
        (item: AnyObject, index: number) => ({
            couponId: item.couponId,
            id: item.id,
            number: item.number,
            sequence: index + 1,
        })
    );

    delete data.effectTime;

    return data;
}

function apiDataToFormData(data: AnyObject) {
    data.effectTime = {
        start: data.effectStartTime,
        end: data.effectEndTime,
    };
    data.storeIds = data.stores.map((item: AnyObject) => item.id);
    data.applyCouponParamsList = data.applyCouponVolist;
    return data;
}

export default [
    {
        key: '/dc-ecoupon/admin/coupons/pack',
        loader: {
            async get(data: any, config: any) {
                if (data.id) {
                    return services.api
                        .get<AnyObject>(data, {
                            ...config,
                            apiPath: `/dc-ecoupon/admin/coupons/pack/${data.id}`,
                        })
                        .then(apiDataToFormData);
                } else {
                    return services.api.get(data, {
                        ...config,
                        apiPath: '/dc-ecoupon/admin/coupons/pack',
                    });
                }
            },
            async post(data: any, config: any) {
                return services.api.post<AnyObject>(formDataToApiData(data), {
                    ...config,
                    apiPath: '/dc-ecoupon/admin/coupons/pack',
                });
            },
            async put(data: any, config: any) {
                return services.api.put<AnyObject>(formDataToApiData(data), {
                    ...config,
                    apiPath: `/dc-ecoupon/admin/coupons/pack/${data.id}`,
                });
            },
        },
    },
];

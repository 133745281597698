import { DELIVERY_TYPE_OPTIONS } from '@/constants/order';

export const filterProperties = {
    outerOrderNumber: {
        type: 'string',
        displayName: '<<picking.orderId>>',
        options: [],
        controlConfig: {
            placeholder: '<<afterSale.filters.orderNumberPlaceholder>>',
            allowClear: true,
        },
    },
    deliveryType: {
        type: 'string.options.select',
        displayName: '<<picking.deliveryTag>>',
        options: DELIVERY_TYPE_OPTIONS,
        controlConfig: {
            placeholder: '<<defaultPlaceholderSelect>>',
            allowClear: true,
        },
    },
};

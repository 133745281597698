import React, { useState } from 'react';

import { MemberExamine } from '@/interfaces/member';
import { Form, Modal, Button } from 'antd';
import { services } from '@comall-backend-builder/core';
import SelectAutoComplete from '../select-auto-complete';
import { MEMBER_EXAMINE_STATUS } from '@/constants';
import { Entity } from '@comall-backend-builder/core/lib/parser';
const { language, errorHandle, api } = services;
interface IProps {
    row: MemberExamine;
    text: string;
    entity: Entity;
}
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
/**
 * 特殊会员等级审核modal
 */

export const TableMemberExamine = (props: IProps) => {
    const { text, row, entity } = props;

    const [visible, setVisible] = useState(false);
    const [levelId, setLevelId] = useState<{ id: string; name: string } | null>(null);

    const handleApprove = async (status: MEMBER_EXAMINE_STATUS) => {
        try {
            await api.post(
                {
                    status,
                    levelId: levelId?.id,
                    id: row.id,
                },
                { apiPath: '/dc-user/admin/memberExamine' }
            );
            entity.search({ ...entity.params, status: 0 });
            setVisible(false);
        } catch (error) {
            errorHandle(error as any);
        }
    };

    if (`${row.status}` !== MEMBER_EXAMINE_STATUS.PENDING) return null;
    return (
        <>
            <Button
                type='link'
                onClick={() => {
                    setVisible(true);
                }}
            >
                {text}
            </Button>
            <Modal visible={visible} onCancel={() => setVisible(false)} footer={null}>
                <Form {...formItemLayout}>
                    <Form.Item label={language.getText('memberExamine.mobile')}>
                        {row.mobile}
                    </Form.Item>
                    <Form.Item label={language.getText('memberExamine.lastName')}>
                        {row.lastName}
                    </Form.Item>
                    <Form.Item label={language.getText('memberExamine.firstName')}>
                        {row.firstName}
                    </Form.Item>
                    <Form.Item label={language.getText('memberExamine.email')}>
                        {row.email}
                    </Form.Item>
                    <Form.Item label={language.getText('memberExamine.levelName')}>
                        <SelectAutoComplete
                            value={levelId}
                            style={{ width: '170px' }}
                            params={{ perPage: 20, page: 1, type: 1 }}
                            placeholder={services.language.getText(
                                'memberExamine.selectPlaceholder'
                            )}
                            apiPath='/loader/dc-user/admin/memberLevelList'
                            formatResponse={(response: any) => {
                                const result = response.result || [];

                                return result;
                            }}
                            selectParamKey='name'
                            onChange={setLevelId}
                            showArrow={true}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div style={{ float: 'right' }}>
                            <Button
                                style={{ marginRight: '16px' }}
                                onClick={() => handleApprove(MEMBER_EXAMINE_STATUS.REJECTED)}
                            >
                                不通过
                            </Button>
                            <Button
                                type='primary'
                                onClick={() => handleApprove(MEMBER_EXAMINE_STATUS.APPROVED)}
                            >
                                审核通过
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

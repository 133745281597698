import { Loader, TypesManager } from '@comall-backend-builder/core';
import { modes, formats } from '@comall-backend-builder/types';
import React, { useEffect, useState } from 'react';

const BaseComponent = ({ value, onChange }: any) => {
    const [areaCode, setAreaCode] = useState([]);
    useEffect(() => {
        Loader.load('get', { apiPath: '/loader/dc-cms-api/areaCodes/findAll' }).then(setAreaCode);
    }, []);
    return (
        <div style={{ display: 'flex', gap: '8px' }}>
            {TypesManager.get('object.option.select').getControlComponent({
                options: areaCode,
                style: { width: '180px' },
                value: value?.areaCode,
                onChange: (v: any) => {
                    onChange({ ...value, areaCode: v });
                },
            })}
            {TypesManager.get('string').getControlComponent({
                value: value?.mobile,
                controlConfig: {
                    maxLength: 20,
                },
                onChange: (v: any) => {
                    onChange({ ...value, mobile: v });
                },
            })}
        </div>
    );
};

export class MobileHasAreaCodeMode extends modes.ObjectMode {
    /**
     * 获取有数据状态展示组件
     */
    public getControlComponent(controlInfo: any): JSX.Element {
        return <BaseComponent {...controlInfo} />;
    }
    public getDisplayComponent(value: any, displayInfo: any): JSX.Element {
        return <div>{`${value.areaCode} ${value.mobile}`}</div>;
    }
}
export class MobileHasAreaCodeFormat extends formats.ObjectFormat {
    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any) {
        return { [key]: `${value?.areaCode.name}${value?.mobile}` };
    }
}

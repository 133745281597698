/*
 * @Author: zhulu
 * @Date: 2022-05-16 16:49:27
 * @Description: 商品款式管理
 */
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { isEmpty, get } from 'lodash';
import {
    tableMixin,
    multipleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
    formFooter,
} from '../mixin';
import { PRODUCT_LIST_STYLE } from '@/configs/pro-table-constants';
export const config: Config = {
    entities: {
        productStyle: {
            apiPath: '/dc-goods/admin/product_spec',
            properties: {
                id: { type: 'string' },
                styleName: {
                    type: 'string',
                    displayName: '<<productSpec.name>>',
                },
                displayName: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties('string', 'productSpec.displayName'),
                    },
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<keyword>>',
                },
            },
        },
        productStyleInfo: {
            apiPath: '/loader/dc-goods/admin/product_spec',
            properties: {
                id: { type: 'string' },
                styleName: {
                    type: 'string',
                    displayName: '<<productSpec.name>>',
                    rules: [{ required: true }, { max: 100 }],
                    controlConfig: {
                        maxLength: 100,
                    },
                },
                displayName: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties('string', 'productSpec.displayName', {
                            rules: [{ required: true }, { max: 100 }],
                            controlConfig: {
                                maxLength: 100,
                            },
                        }),
                    },
                },
                styles: {
                    type: 'array.productStyleItems',
                    displayName: '<<productSpec.style>>',
                    rules: [
                        {
                            validator: (_rule: any, _value: any, callback: any) => {
                                if (!_value || _value.length <= 0) {
                                    return new Error(
                                        services.language.getText('productSpec.styleLengthError')
                                    );
                                }
                                if (
                                    _value.some((item: any) => {
                                        if (
                                            isEmpty(get(item, 'name.zh')) ||
                                            isEmpty(get(item, 'name.tc')) ||
                                            isEmpty(get(item, 'name.en'))
                                        ) {
                                            return true;
                                        }
                                        return false;
                                    })
                                ) {
                                    return new Error(
                                        services.language.getText('productSpec.styleError')
                                    );
                                }
                                callback();
                            },
                        },
                    ],
                },
            },
        },
    },
    components: {
        ProductStyleContainer: {
            component: 'Viewport',
        },
        ProductStyleView: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'ProductStyleList',
                    },
                ],
            },
        },
        ProductStyleList: {
            entity: 'productStyle',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    ...multipleFilterForm,
                    submit: {
                        text: '<<query>>',
                    },
                    reset: true,
                    fields: [
                        {
                            property: 'keyword',
                            controlConfig: {
                                allowClear: true,
                                placeholder: '<<productSpec.name>>',
                            },
                        },
                    ],
                    privilege: {
                        path: 'goods.goods_style.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            text: '<<productSpec.add>>',
                            icon: 'plus',
                            type: 'primary',
                            route: `/product-style/add`,
                            style: {
                                marginRight: 15,
                            },
                        },
                    ],
                    privilege: {
                        path: 'goods.goods_style.add',
                        level: 'full',
                    },
                },
                {
                    component: `ProductStyleTable`,
                    privilege: {
                        path: 'goods.goods_style.view_list',
                        level: 'full',
                    },
                },
            ],
        },
        ProductStyleTable: {
            ...tableMixin,
            componentName: PRODUCT_LIST_STYLE,
            component: 'ProTable',
            scroll: {
                x: 'max-content',
            },
            columns: [{ property: 'styleName' }, ...getLanguageColumns('displayName')],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        text: '<<edit>>',
                        type: 'component',
                        component: 'TableActionStateful',
                        config: {
                            actionType: 'link',
                            renderConfig: (row: any, props: any) => {
                                return {
                                    path: `/product-style/edit/${row.id}`,
                                };
                            },
                        },
                        privilege: {
                            path: 'goods.goods_style.edit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ProductStyleCreate: {
            component: 'Card',
            content: {
                component: 'ProductStyleCreateForm',
            },
        },
        ProductStyleCreateForm: {
            component: 'CreateForm',
            entity: 'productStyleInfo',
            labelCol: 6,
            controlCol: 16,
            footer: formFooter,
            className: 'none-sub-form brand-edit-from',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                { property: 'styleName' },
                ...getLanguageColumns('displayName'),
                { property: 'styles' },
            ],
        },
        ProductStyleModify: {
            component: 'Card',
            content: {
                component: 'ProductStyleModifyForm',
            },
        },
        ProductStyleModifyForm: {
            component: 'ModifyFormPlus',
            entity: 'productStyleInfo',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 16,
            footer: formFooter,
            className: 'none-sub-form brand-edit-from',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                { property: 'id', modifiable: false },
                { property: 'styleName' },
                ...getLanguageColumns('displayName'),
                { property: 'styles' },
            ],
        },
    },
};

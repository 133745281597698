import React, { useEffect, useState, useCallback } from 'react';
import { get, each, noop } from 'lodash';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';
import { Divider, Spin } from 'antd';
import { IPickData } from '../interfaces';
import { InfoCard } from './info';
import { Goods } from './goods';
import * as pickingApi from '../api';
import './index.less';

/**
 * 将拣货单数据格式化为缺货数据
 * @param {*} data 拣货单数据
 */

function formatPickingDataToLocationPickData(data: any, pickingLocations: IPickData[]) {
    const result: Array<IPickData> = [];
    const pickingLocationData: KV = {};

    pickingLocations.forEach((item) => {
        const locationData: KV = {};
        item.pickingLocations.forEach((locationItem) => {
            locationData[locationItem.location] = locationItem.pickNum;
        });
        pickingLocationData[item.pickingListItemId] = locationData;
    });

    each(data.pickingListBrands, (brand, index: number) => {
        each(brand.pickingListBrandItems, (item) => {
            const locationInfo = pickingLocationData[`${get(item, 'id')}-${item.goodsId}`];
            result.push({
                goodsName: get(item, 'name'),
                goodsId: get(item, 'goodsId'),
                productType: get(item, 'productType'),
                id: get(item, 'id'),
                pickingListItemId: `${get(item, 'combinationGoodsId')}-${item.goodsId}`,
                needPickQuantity: get(item, 'needPickQuantity'),
                hadPickQuantity: 0,
                pickingLocations: get(item, 'pickingLocations', []).map((locationItem: any) => {
                    locationItem.pickNum = get(locationInfo, 'pickNum');
                    return locationItem;
                }),
                sourceType: get(item, 'sourceType'),
                combinationQuantiy: get(item, 'combinationQuantiy'),
                combinationSku: get(item, 'combinationSku'),
                combinationGoodsId: get(item, 'combinationGoodsId'),
            });
        });
    });
    return result;
}

export const PickedTotalContext = React.createContext<
    [number, React.Dispatch<React.SetStateAction<number>>]
>([0, noop]);

/**
 * 拣货单详情
 */
export function PickingAssistantDetail(props: any) {
    const { params, apiType, pickPathname } = props;
    const [loading, setLoading] = useState(false);
    const [pickingData, setPickingData] = useState<any>({});
    const [pickLocationData, setPickLocationData] = useState<Array<IPickData>>([]);
    // 加载拣货单数据
    function fetchData(id: string) {
        setLoading(true);
        pickingApi
            .fetchPickingDataById(id, apiType)
            .then((result) => {
                setPickingData(result);
                setPickLocationData(formatPickingDataToLocationPickData(result, pickLocationData));
            })
            .catch(errorHandle)
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchData(params.id);
        // eslint-disable-next-line
    }, [params]);

    // 修改拣货数据
    const pickChangeHandler = useCallback(
        (id: any, value: KV) => {
            const newPickData = [...pickLocationData];

            each(newPickData, (item) => {
                if (item.pickingListItemId === id) {
                    let count = 0;
                    each(item.pickingLocations, (locationItem) => {
                        const pickNum = value[locationItem.location] || 0;
                        locationItem.pickNum = pickNum;
                        count += pickNum;
                    });
                    item.hadPickQuantity = count;
                }
            });

            setPickLocationData(newPickData);
        },
        [pickLocationData]
    );

    // 拣货状态变更，刷新数据
    const statusChangeHandler = useCallback(() => {
        fetchData(params.id);
        // eslint-disable-next-line
    }, [params]);

    return (
        <PickedTotalContext.Provider value={useState(0)}>
            <Spin size='large' spinning={loading}>
                <div className='picking-detail'>
                    <InfoCard
                        data={pickingData}
                        pickLocationData={pickLocationData}
                        onSuccess={statusChangeHandler}
                        onRefresh={() => {
                            fetchData(params.id);
                        }}
                        pickPathname={pickPathname}
                        apiType={apiType}
                    />
                    <Divider />
                    <Goods
                        status={pickingData.status}
                        dataSource={pickingData.pickingListBrands}
                        onChange={pickChangeHandler}
                    />
                </div>
            </Spin>
        </PickedTotalContext.Provider>
    );
}

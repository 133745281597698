/**
 * 订单明细报表-无价格版
 */

import { Config, EntityProperties } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '../mixin';
import { getFilterProperties, getFields } from './utils';
import { getEntityProperties } from './order-detail';
import { formatDate } from '@/services/data-formatter';

const filterKeys = [
    'onlineStoreIds',
    'orderTime',
    'shipTime',
    'orderDeliveryType',
    'orderNumber',
    'orderStatus',
    'pickUpMember',
    'orderMember',
    'merchantIds',
    'liquidState',
];

const formatEntityProperties: () => EntityProperties = () => {
    const nVal: AnyObject = {};
    const orgVal = getEntityProperties();

    Object.keys(orgVal).forEach((i) => {
        if (
            [
                'price',
                'subtotal',
                'voucherAmount',
                'payableAmount',
                'point',
                'lackSubtotal',
                'finalSubtotal',
            ].indexOf(i) < 0
        ) {
            nVal[i] = orgVal[i];
        }
    });
    return nVal;
};

export const config: Config = {
    entities: {
        orderDetailUnPriceReport: {
            apiPath: '/loader/dc-order/admin/orderDetailsNoPrice',
            properties: getEntityProperties(),
            filters: getFilterProperties(filterKeys),
        },
    },
    components: {
        OrderDetailUnPriceReport: {
            entity: 'orderDetailUnPriceReport',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    privilege: {
                        path: 'forms.order_details_no_price.list',
                        level: 'full',
                    },
                    fields: getFields(getFilterProperties(filterKeys)),
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: { marginBottom: '16px' },
                    items: [
                        {
                            component: 'ExportButtonWithFilter',
                            text: '<<report.exportResult>>',
                            icon: 'download',
                            type: 'primary',
                            style: {
                                flexGrow: 0,
                                marginRight: 15,
                            },
                            source: {
                                apiPath:
                                    '/dc-order/admin/orderDetailsNoPrice/exportStatement/orderDetailsNoPrice/take',
                            },
                            formatter: (params: any) => {
                                const newParams: any = { ...params };
                                return {
                                    ...newParams,
                                    ...formatDate(newParams.orderTime, 'createTime'),
                                    ...formatDate(newParams.deliveryTime, 'shipTime'),
                                };
                            },
                            isAsync: true,
                            showSuccessTips: true,
                            showErrorMessage: true,
                            privilege: {
                                path: 'forms.order_details_no_price.export',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    ...tableMixin,
                    columns: getFields(formatEntityProperties()),
                    privilege: {
                        path: 'forms.order_details_no_price.list',
                        level: 'full',
                    },
                },
            ]),
        },
    },
};

/**
 * 兑换券发放及使用报表
 */

import { RELEASE_PERIOD_STR_OPTIONS } from '@/constants/voucher-management';
import { Config, EntityProperties } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '../mixin';
import { getFields } from './utils';
import { get } from 'lodash';

const filterProperties: EntityProperties = {
    sendTime: {
        type: 'object.dateTimeRange',
        displayName: '<<report.voucher.bonusTime>>',
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
            showTime: { defaultValue: ['00:00:00', '23:59:59'] },
        },
    },
    planName: {
        type: 'string',
        displayName: '<<report.voucher.name>>',
        controlConfig: {
            allowClear: true,
        },
    },
    mobile: {
        type: 'string',
        displayName: '<<report.voucher.mobile>>',
        controlConfig: {
            allowClear: true,
        },
    },
    bonusPeriod: {
        type: 'string.options.select',
        displayName: '<<report.voucher.bonusPeriod>>',
        options: RELEASE_PERIOD_STR_OPTIONS,
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
};
const getEntityProperties: () => EntityProperties = () => {
    let nVal: AnyObject = {};
    [
        'name',
        'effectiveStartTime',
        'bonusPeriod',
        'num',
        'mobile',
        'memberName',
        'remarks',
        'distributionObject',
        'households',
        'bonusTime',
        'issuedCoupon',
        'usedCoupon',
        'noUsedCoupon',
    ].forEach((i: any) => (nVal[i] = { type: 'string', displayName: `<<report.voucher.${i}>>` }));

    return nVal;
};

export const config: Config = {
    entities: {
        voucherReport: {
            apiPath: '/loader/dc-order/admin/exchangeCoupon',
            properties: getEntityProperties(),
            filters: filterProperties,
        },
    },
    components: {
        VoucherReportPage: {
            entity: 'voucherReport',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: getFields(filterProperties),
                    privilege: {
                        path: 'forms.exchange_coupon.list',
                        level: 'full',
                    },
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: { marginBottom: '16px' },
                    items: [
                        {
                            component: 'ExportButtonWithFilter',
                            text: '<<report.exportResult>>',
                            icon: 'download',
                            type: 'primary',
                            style: {
                                flexGrow: 0,
                                marginRight: 15,
                            },
                            source: {
                                apiPath:
                                    '/dc-order/admin/exchangeCoupon/exportStatement/exchangeCoupon/take',
                            },
                            privilege: {
                                path: 'forms.exchange_coupon.export',
                                level: 'full',
                            },
                            isAsync: true,
                            showSuccessTips: true,
                            showErrorMessage: true,
                            formatter: (params: any) => {
                                const sendTime = get(params, 'sendTime', undefined);
                                if (!sendTime) return params;
                                params.bonusTimeStart = sendTime['start'];
                                params.bonusTimeEnd = sendTime['end'];
                                delete params.sendTime;
                                return params;
                            },
                        },
                    ],
                },
                {
                    ...tableMixin,
                    columns: getFields(getEntityProperties()),
                    privilege: {
                        path: 'forms.exchange_coupon.list',
                        level: 'full',
                    },
                },
            ]),
        },
    },
};

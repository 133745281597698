import infoReport from './info-report';
import shelfLoader from './shelf';
import productListContainers from './product-list';
import productSuiteLoader from './product-suite';
import productGroupLoader from './product-group';
import productSeoLoader from './product-seo';
import productSpecLoader from './product-spec';
import productPriceLoader from './product-price';
import recommendProductLoader from './recommend-product';
import productPickupLimitLoader from './product-pickup-limit';
export {
    productSpecLoader,
    infoReport,
    shelfLoader,
    productListContainers,
    productSuiteLoader,
    productGroupLoader,
    productSeoLoader,
    productPriceLoader,
    recommendProductLoader,
    productPickupLimitLoader,
};

import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';
import moment from 'moment';

const apiRoot = ENV.API_ROOT;

type IOrderStatus = 'PACKED';

type IPickingStatus = 'PICKING' | 'FINISH_PICK';

/**
 * 订单维度, 修改状态
 *
 * @param ids 需要修改的订单号, example ['1', '2']
 *
 * @param status 需要变更的状态, PACKED => 打包完成
 *
 */
export function changeOrderStatus({
    ids,
    status,
    apiType,
    restParams,
}: {
    ids: Array<string>;
    status: IOrderStatus;
    apiType: string;
    restParams?: AnyObject;
}): Promise<any> {
    let params: any = {
        status,
        ...restParams,
    };
    let configs: any = {
        apiRoot,
    };
    if (ids.length > 1) {
        // 批量修改
        params.orderIds = ids;
        configs.apiPath = `${apiType}/admin/orders/status`;
    } else {
        // 单个修改
        configs.apiPath = `${apiType}/admin/order/${ids[0]}/status`;
    }
    return api.put(params, configs);
}

/**
 * 拣货维度, 修改状态
 *
 * @export
 * @param {Array<string>} ids 需要修改的拣货id, 非picking_list_number, example ['1', '2']
 * @param {IPickingStatus} status 需要变更的状态, PICKING => 拣货中 | FINISH_PICK =>  拣货完成
 * @param {('picking'|'packed'|'repick')} type menu type
 * @param {KV} [extraParams] 额外参数，如 { stockOutItems }
 * @return {*}  {Promise<any>}
 */
export function changePickingStatus(
    ids: Array<string>,
    status: IPickingStatus,
    type: 'picking' | 'packed' | 'repick',
    extraParams?: KV
): Promise<any> {
    let params: any = {
        status,
    };
    let configs: any = {
        apiRoot,
    };
    if (extraParams) {
        params = {
            ...params,
            ...extraParams,
        };
    }
    if (ids.length > 1) {
        // 批量修改
        params.pickingListIds = ids;
        configs.apiPath = `${extraParams?.apiType}/admin/picking_lists/status`;
    } else {
        // 单个修改
        configs.apiPath = `${extraParams?.apiType}/admin/picking_lists/${type}/${ids[0]}/status`;
    }
    return api.put(params, configs);
}

/**
 * 获取拣货状态tab项对应的数量
 *
 * @param {string} storeId 门店 id
 *
 */
export function fetchTabStatusCount(storeId: string, apiType: string): Promise<any> {
    return new Promise((resolve) => {
        api.get(
            {
                onlineStoreId: storeId,
            },
            {
                apiRoot,
                apiPath: `${apiType}/admin/order/statistics`,
            }
        )
            .then(resolve)
            .catch(errorHandle);
    });
}

interface IFetchPickingListPrams {
    /**
     * 根据关键词查询
     */
    keyword: string;
    /**
     * 根据拣货ID查询
     */
    pickingListIds: string;
    /**
     * 线上门店id
     */
    onlineStoreId: string;
    /**
     * 接口类型
     */
    apiType: string;
}

/**
 * 根据参数查询拣货单列表数据
 */
export function fetchPickingList(params: Partial<IFetchPickingListPrams>): Promise<any> {
    return api.get(
        { ...params },
        {
            apiRoot,
            apiPath: `${params.apiType}/admin/picking_lists`,
        }
    );
}

/**
 * 根据拣货单ID查询单个拣货数据
 */
export function fetchPickingDataById(id: string, apiType: string): Promise<any> {
    return api.get(
        {},
        {
            apiRoot,
            apiPath: `${apiType}/admin/picking_lists/${id}`,
        }
    );
}

/**
 * 获取单据打印所需数据
 * @param ids order id, example ['1', '2']
 */
export function fetchReceiptsInfo(
    ids: Array<string>,
    type: 'picking' | 'packing',
    apiType: string
): Promise<any> {
    const idsArrayString = encodeURI(JSON.stringify(ids));
    return new Promise((resolve) => {
        api.get(
            {},
            {
                apiRoot,
                apiPath: `${apiType}/admin/picking_lists/${type}/receipts?order_ids=${idsArrayString}`,
            }
        )
            .then(resolve)
            .catch(errorHandle);
    });
}

/**
 * 获取打印打包单数据
 */
export function fetchPrintPickingList(ids: Array<string>, apiType: string): Promise<any> {
    const idsArrayString = encodeURI(JSON.stringify(ids));
    return new Promise((resolve) => {
        api.get(
            {},
            {
                apiRoot,
                apiPath: `${apiType}/admin/picking_lists/picking/printPickingList?order_ids=${idsArrayString}`,
            }
        )
            .then(resolve)
            .catch(errorHandle);
    });
}

/**
 * 获取打印箱面纸数据
 */
export function fetchPrintPackList(ids: Array<string>, apiType: string): Promise<any> {
    const idsArrayString = encodeURI(JSON.stringify(ids));
    return new Promise((resolve) => {
        api.get(
            {},
            {
                apiRoot,
                apiPath: `${apiType}/admin/picking_lists/picking/printPackList?order_ids=${idsArrayString}`,
            }
        )
            .then(resolve)
            .catch(errorHandle);
    });
}

/**
 *确认商品入库
 * @param {string} id 订单 ID
 */
export function confirmOrderToStorage(id: string): Promise<any> {
    return api.put(
        {},
        {
            apiRoot,
            apiPath: `/dc-order/admin/orders/${id}/goods`,
        }
    );
}

/**
 * 取消订单
 * @param {string} id 订单 ID
 */
export function cancelOrder(id: string): Promise<any> {
    return api.put(
        {},
        {
            apiRoot,
            apiPath: `/base-order/admin/order/${id}/cancel`,
        }
    );
}

/**
 *
 * 缺货配送
 * @export
 * @param {string} id 订单 ID
 * @param {('picking' | 'order')} type menu type
 * @return {*}  {Promise<any>}
 */
export function stockOutDelivery(
    id: string,
    type: 'picking' | 'order',
    apiType: string
): Promise<any> {
    return api.put(
        {},
        {
            apiRoot,
            apiPath: `${apiType}/admin/order/${type}/${id}/picked`,
        }
    );
}

/**
 * 重发loc to loc
 */
export function locToLoc(id: string): Promise<any> {
    return api.put({}, { apiRoot, apiPath: `/base-order/admin/order/${id}/loc_to_loc/retry` });
}

/**
 * 修改包裹数量回显
 */
export function getPackageNumber(id: string): Promise<any> {
    return api.get(
        {},
        {
            apiRoot,
            apiPath: `/base-order/admin/order/packageNumber/${id}`,
        }
    );
}

/**
 * 修改包裹数量
 */
export function modifyPackageNumber(params: {
    id: string;
    coldNumber: number;
    normalNumber: number;
    status: string;
}): Promise<any> {
    return api.put(
        {
            ...params,
            shipTime: moment().format('YYYY-DD-MM'),
        },
        {
            apiRoot,
            apiPath: `/base-order/admin/order/${params.id}/packageNumber`,
        }
    );
}

import React, { Fragment, useRef } from 'react';
import { Input, Row, Col } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';

const contentMap = [{ key: 'en' }, { key: 'zh' }, { key: 'tc' }];

interface DescribeProps {
    params?: any;
    form?: any;
}

const SpecialAttributeForm: React.FC<DescribeProps> = (props: any) => {
    const {
        form: { getFieldDecorator, getFieldValue },
        offlineCategoriesAttributes,
    } = props;
    const isDetail = useRef(services.navigation.getPathname().split('/')[3] === 'detail');

    return offlineCategoriesAttributes.length > 0 ? (
        <Fragment>
            {offlineCategoriesAttributes
                .sort((a: any, b: any) => a.sort - b.sort)
                .map((attribute: any) => {
                    return (
                        <div
                            key={` offlineCategories.${attribute.id}`}
                            style={{ marginTop: '20px' }}
                        >
                            <Row
                                style={{
                                    padding: '10px',
                                    backgroundColor: '#ddd',
                                    fontWeight: 'bolder',
                                    marginBottom: '16px',
                                }}
                            >
                                <Col span={9}>{`分类：${get(
                                    getFieldValue('categoryId'),
                                    'name'
                                )} ID：${attribute.categoryCode} 排序值：${attribute.sort}`}</Col>
                                {contentMap.map((lang) => (
                                    <Col key={lang.key} span={5} style={{ paddingRight: '16px' }}>
                                        {attribute['nameMap'][lang.key]}
                                    </Col>
                                ))}
                            </Row>
                            <Row type='flex' justify='end'>
                                {contentMap.map((lang: any, index: number) => (
                                    <Col key={lang.key} span={5} style={{ paddingRight: '16px' }}>
                                        {getFieldDecorator(
                                            `offlineCategories.offlineCategories-${attribute.id}.${lang.key}`
                                        )(
                                            <Input
                                                placeholder={services.language.getText(
                                                    'defaultPlaceholderInput'
                                                )}
                                                maxLength={100}
                                                disabled={isDetail.current}
                                            />
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    );
                })}
        </Fragment>
    ) : (
        <Fragment />
    );
};

export default SpecialAttributeForm;

/**
 * 拣货小助手拣货中item
 */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { message, Modal } from 'antd';
import Button, { ButtonProps } from 'antd/lib/button';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { Privilege } from '@/components/privilege';
import { PickingCard } from './base-content';
import { PrintButton } from '@/components/print';
import { PackageReceipt, PickingReceipt } from '@/components/print-template';
import { navigateToDetail } from '../../service';
import { PATH_NAME, PICKING_STATUS } from '../../enums';
import * as pickingApi from '../../api';
import { services } from '@comall-backend-builder/core';
import { useGlobalConfig } from '@/utils/global-config';

interface IFinishPickingButton extends ButtonProps {
    /**
     * 需要打包的订单ID，支持单个和多个
     */
    ids: Array<string>;
    /**
     * 订单id
     */
    orderIds: Array<string>;
    /**
     * 打包前的拦截处理
     */
    onBeforeClick: () => Promise<any>;
    /**
     * 接口处理完成回调
     */
    onSuccess: () => void;
    /**
     * 接口类型
     */
    apiType: string;
}

/**
 * 完成拣货 && 打印打包单 按钮组件
 */

function FinishPickingButton(props: IFinishPickingButton) {
    const { children, type, ids, orderIds = [], onBeforeClick, onSuccess, apiType } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [receiptInfo, setReceiptInfo] = useState<any>([]);
    const [{ storeLogos }, setGlobalConfig] = useGlobalConfig();

    function loadLogo(onlineStoreId: string) {
        const storeLogo = storeLogos.find((item) => item.onlineStoreId === onlineStoreId) || {
            loading: false,
            onlineStoreId,
            logo: '',
        };

        if (!storeLogo.logo && !storeLogo.loading) {
            storeLogo.loading = true;
            setGlobalConfig({
                storeLogos: [
                    ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                    storeLogo,
                ],
            });

            services.api
                .get(
                    {},
                    {
                        apiPath: `/dc-store/admin/onlineStore/findById/${onlineStoreId}`,
                    }
                )
                .then((res: any) => {
                    storeLogo.loading = false;
                    storeLogo.logo = res.logo;
                    setGlobalConfig({
                        storeLogos: [
                            ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                            storeLogo,
                        ],
                    });
                })
                .catch(services.errorHandle);
        }
    }

    const handleClick = async (printRef: any) => {
        try {
            await onBeforeClick();
            setLoading(true);
            pickingApi
                .changePickingStatus(ids, PICKING_STATUS.FINISH_PICK, 'packed', { apiType })
                .then(() => {
                    return pickingApi.fetchPrintPickingList(orderIds, apiType);
                })
                .then(async (receiptData) => {
                    setReceiptInfo(receiptData);
                    if (receiptData.store?.id) {
                        await loadLogo(receiptData.store.id);
                    }
                    await printRef.current.onPrint();
                    onSuccess();
                })
                .catch(errorHandle)
                .finally(() => setLoading(false));
        } catch (e) {
            return;
        }
    };

    const buttonProps = {
        children,
        type,
        loading,
        onClick: handleClick,
    };

    return (
        <PrintButton
            {...buttonProps}
            template={receiptInfo.map((item: any, index: string) => {
                return <PackageReceipt key={index} data={item} />;
            })}
        />
    );
}

FinishPickingButton.defaultProps = {
    onBeforeClick: () => Promise.resolve(),
    onSuccess: () => {},
};

/**
 * 批量完成拣货并打印 按钮组件
 */
export function BatchFinishPickingAndPrintButton(props: any) {
    const { selectedRowKeys, parentProps } = props;

    const confirmHandler = () => {
        return new Promise((resolve, reject) => {
            if (isEmpty(selectedRowKeys)) {
                message.warn(services.language.getText('picking.selectPickingRow'));
                reject();
                return;
            }
            Modal.confirm({
                title: services.language.getText('picking.selectPickingResult'),
                okText: services.language.getText('picking.confirmPicking'),
                cancelText: services.language.getText('cancel'),
                onOk: () => {
                    resolve({});
                },
                onCancel: () => {
                    reject();
                },
            });
        });
    };

    const successHandler = () => {
        parentProps.refresh();
    };

    if (isEmpty(parentProps.result)) {
        return null;
    }

    return (
        <FinishPickingButton
            type='primary'
            orderIds={[]}
            ids={selectedRowKeys}
            onBeforeClick={confirmHandler}
            onSuccess={successHandler}
            apiType={parentProps.apiType}
        >
            {services.language.getText('picking.batchPicking')}
        </FinishPickingButton>
    );
}

/**
 *拣货中数据展示组件
 */

export function PickingItem(props: any) {
    const { id, parentProps } = props;
    const [receiptInfo, setReceiptInfo] = useState<any>([]);

    const printHandler = (printRef: any) => {
        // 获取打印数据
        pickingApi
            .fetchReceiptsInfo([props?.orderStore.orderId], 'picking', parentProps.apiType)
            .then(async (receiptData) => {
                setReceiptInfo(receiptData);
                await printRef.current.onPrint();
            });
    };

    return (
        <PickingCard
            type={props?.type}
            data={props}
            actions={
                <>
                    <Privilege
                        path={
                            parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                                ? 'picking.assistant.finished_picking'
                                : 'picking_philips.assistant_philips.finished_picking_philips'
                        }
                    >
                        <Button
                            type='primary'
                            onClick={() => {
                                navigateToDetail(id, parentProps.pickPathname);
                            }}
                            style={{ marginRight: '16px' }}
                        >
                            {services.language.getText('picking.pickingComplete')}
                        </Button>
                    </Privilege>
                    <Privilege
                        path={
                            parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                                ? 'picking.assistant.print_picking_list'
                                : 'picking_philips.assistant_philips.print_picking_list_philips'
                        }
                    >
                        <PrintButton
                            onClick={printHandler}
                            template={receiptInfo.map((item: any, index: string) => {
                                return <PickingReceipt key={index} data={item} />;
                            })}
                        >
                            {services.language.getText('picking.printPickingPaper')}
                        </PrintButton>
                    </Privilege>
                </>
            }
            orderPathname={parentProps.orderPathname}
            pickPathname={parentProps.pickPathname}
        />
    );
}

import React, { Component } from 'react';

import { StringMode } from '@comall-backend-builder/types';
import { Select } from 'antd';
import { services } from '@comall-backend-builder/core';
export class AutoCompleteDefaultMode extends StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        const { entity, options, allowClear } = controlInfo;
        return options.length ? (
            <SelectDefault allowClear={allowClear} entity={entity} options={options} />
        ) : (
            <Select
                allowClear={allowClear}
                placeholder={services.language.getText('defaultPlaceholderSelect')}
            />
        );
    }
}

interface IProps {
    options: any;
    entity: any;
    allowClear: boolean;
}
class SelectDefault extends Component<IProps, any> {
    state = {
        selectValue: '',
    };

    componentDidMount = () => {
        const { entity, options } = this.props;
        this.setState({
            selectValue: options[0].id,
        });
        entity.filtersChange({ channelId: options[0].id });
        entity.search(entity.params);
    };
    handleChange = (value: string) => {
        const { entity } = this.props;
        entity.filtersChange({ channelId: value });
        this.setState({
            selectValue: value,
        });
    };
    componentDidUpdate() {
        const { entity, options } = this.props;
        if (entity.filters && !entity.filters.channelId) {
            entity.filtersChange({ channelId: options[0].id });
            this.setState({
                selectValue: options[0].id,
            });
        }
    }
    render() {
        const { options, allowClear } = this.props;
        return (
            <Select
                allowClear={allowClear}
                defaultValue={options[0].id}
                onChange={this.handleChange}
                value={this.state.selectValue}
            >
                {options.map((i: any) => (
                    <Select.Option key={i.id} value={i.id}>
                        {i.name}
                    </Select.Option>
                ))}
            </Select>
        );
    }
}

import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Form, Modal } from 'antd';
import moment from 'moment';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { Privilege } from '@/components/privilege';
import { services } from '@comall-backend-builder/core';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';

export const EditOrderShipTime: React.FC<AnyObject> = (props) => {
    const { id, shipTime: defaultShipTime, parentProps } = props;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const initialShipTime = () => defaultShipTime || moment().format('YYYY-MM-DD');
    const [shipTime, setShipTime] = useState(initialShipTime);
    useEffect(() => setShipTime(initialShipTime), [open]);

    async function confirm() {
        try {
            setLoading(true);
            await services.api.post(
                {
                    shipTime,
                },
                {
                    apiPath: `/base-order/admin/order/${id}/shipTime`,
                }
            );
            setOpen(false);
            parentProps.entity.search();
            parentProps.fetchStatistics();
        } catch (e) {
            errorHandle(e as ErrorInfo);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Privilege path='order.order.edit_ship_time'>
            <>
                <Button onClick={() => setOpen(true)}>
                    {language.getText('common.modify')}
                    {language.getText('order.shipTime')}
                </Button>
                <Modal
                    title={`${language.getText('common.modify')}${language.getText(
                        'order.shipTime'
                    )}`}
                    visible={open}
                    okButtonProps={{ disabled: loading }}
                    onOk={confirm}
                    onCancel={() => setOpen(false)}
                >
                    <Form
                        layout='horizontal'
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{ span: 12 }}
                    >
                        <Form.Item label={language.getText('order.shipTime')}>
                            <DatePicker
                                allowClear={false}
                                disabledDate={(current) => {
                                    // 今天到未来30天以内
                                    return (
                                        moment()
                                            .subtract(1, 'day')
                                            .isAfter(current) ||
                                        moment()
                                            .add(29, 'day')
                                            .isBefore(current)
                                    );
                                }}
                                value={moment(shipTime)}
                                onChange={(shipTime) =>
                                    setShipTime(shipTime?.format('YYYY-MM-DD') ?? defaultShipTime)
                                }
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        </Privilege>
    );
};

import { services } from '@comall-backend-builder/core';
import { find } from 'lodash';
import { ProductLimitTip } from '@/interfaces';
import { CHANNEL } from '@/constants';

function proceedData(data: any) {
    const request: Array<ProductLimitTip> = [];
    request.push(
        {
            id: data.categoryId,
            type: '1',
            templateContent: data.categoryLimitTip,
        },
        {
            id: data.singleProductId,
            type: '2',
            templateContent: data.singleProductLimitTip,
        }
    );

    return request;
}
export default [
    {
        key: '/dc-goods/admin/limit/tips/templates',
        loader: {
            get: async function(data: any, config: any) {
                const limitChannelId: any = services.localStorage.get('limitChannelId');
                data.channelId = limitChannelId;
                const result: Array<ProductLimitTip> = await services.api.get(data, config);
                let transformed: any = {};
                find(result, function(item) {
                    if (item.type === '1') {
                        transformed.categoryId = item.id;
                        transformed.categoryLimitTip = item.templateContent;
                    } else {
                        transformed.singleProductId = item.id;
                        transformed.singleProductLimitTip = item.templateContent;
                    }
                });
                return transformed;
            },
            put: async function(data: any, config: any) {
                return await services.api.put(proceedData(data), config);
            },
        },
    },
    {
        key: '/limit',
        loader: {
            get: async function(data: any, config: any) {
                const limitChannelId: any = services.localStorage.get('limitChannelId');
                if (data.id) {
                    if (limitChannelId === CHANNEL.MACAU) {
                        config.apiPath = '/dc-goods/admin/limit/' + data.id;
                        data.channelId = limitChannelId;
                        return await services.api.get(data, config);
                    }
                    if (limitChannelId === CHANNEL.GUIDE_SHOPPING) {
                        config.apiPath = '/dc-goods/admin/limit/' + data.id;
                        data.channelId = limitChannelId;
                        return await services.api.get(data, config);
                    }
                    if (limitChannelId === CHANNEL.SELECT) {
                        config.apiPath = '/dc-goods/admin/select/limit/' + data.id;
                        const response: any = await services.api.get(data, config);
                        const result: any = {
                            type: response.type,
                            singleLimitNum: response.limitNum,
                            brandLimitNum: response.limitNum,
                        };
                        return result;
                    }
                } else {
                    if (limitChannelId === CHANNEL.MACAU) {
                        config.apiPath = '/dc-goods/admin/limit';
                        data.channelId = limitChannelId;
                    }
                    if (limitChannelId === CHANNEL.GUIDE_SHOPPING) {
                        config.apiPath = '/dc-goods/admin/limit';
                        data.channelId = limitChannelId;
                    }
                    if (limitChannelId === CHANNEL.SELECT) {
                        config.apiPath = '/dc-goods/admin/select/limit';
                    }
                    return await services.api.get(data, config);
                }
            },
            put: async function(data: any, config: any) {
                const limitChannelId: any = services.localStorage.get('limitChannelId');
                let params = {
                    ...data,
                };
                if (limitChannelId === CHANNEL.MACAU) {
                    config.apiPath = '/dc-goods/admin/limit/' + data.id;
                    data.channelId = limitChannelId;
                }
                if (limitChannelId === CHANNEL.GUIDE_SHOPPING) {
                    config.apiPath = '/dc-goods/admin/limit/' + data.id;
                    data.channelId = limitChannelId;
                }
                if (limitChannelId === CHANNEL.SELECT) {
                    config.apiPath = '/dc-goods/admin/select/limit/' + data.id;
                    if (data.singleLimitNum) {
                        params.limitNum = data.singleLimitNum;
                    }
                    if (data.brandLimitNum) {
                        params.limitNum = data.brandLimitNum;
                    }
                    // const pattern = /^[1-9][0-9]{0,5}$/;
                    // if (!pattern.test(params.limitNum)) {
                    //     AntMessage.error(services.language.getText('productLimit.maxNumber'));
                    // }
                    delete params['singleLimitNum'];
                    delete params['brandLimitNum'];
                }
                return await services.api.put(params, config);
            },
        },
    },
];

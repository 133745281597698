import { CHANNEL, STORE_ATTRIBUTES_VALUES } from '@/constants';
import { Loader, services } from '@comall-backend-builder/core';
import { toString, forEach, map, each, isEmpty } from 'lodash';
import { mockFetch } from '../mock';
function formatOnlineStore(data: any) {
    forEach(data, (item) => {
        item.label = item.name;
        item.value = item.id;
        if (item.isLeaf) {
            item.disabled = !!item.pickOnlineStoreId;
            return;
        }
        item.children && formatOnlineStore(item.children);
    });
    return data;
}

async function formatOnlineStoreOptions(params: any, config: any) {
    const result: any = await services.api.get(params, config);
    forEach(result, (item: any) => (item.name = item.onlineStoreName));
    return result;
}

async function formatOnlineStoreTree(params: any, config: any) {
    const result: any = await services.api.get({ ...params, tree_type: 2 }, config);
    return formatOnlineStoreTreeData(result);
}

function formatOnlineStoreTreeData(result: any) {
    result.onlineStoreTreeVos = map(result.onlineStoreTreeVos || result.children, (channel) => {
        channel.onlineStoreTreeVos = map(
            channel.onlineStoreTreeVos || channel.children,
            (store) => {
                return {
                    ...store,
                    parentId: channel.id,
                    disabled: !!store.pickOnlineStoreId,
                    isLeaf: true,
                    value: store.id,
                    label: store.name,
                };
            }
        );
        return {
            ...channel,
            parentId: result.id,
            children: channel.onlineStoreTreeVos,
            isLeaf: false,
            value: channel.id,
            label: channel.name,
        };
    });
    result = {
        ...result,
        parentId: '-1',
        isLeaf: false,
        children: result.onlineStoreTreeVos,
        value: result.id,
        label: result.name,
    };
    return result;
}

export const loader = [
    {
        key: '/dc-store/admin/onlineStore',
        loader: {
            get: async function(data: any, config: any) {
                if (data.id) {
                    const result: any = await services.api.get(
                        {},
                        { apiPath: `/dc-store/admin/onlineStore/findById/${data.id}` }
                    );
                    result.memberLevelType = result.memberLevelType
                        ? result.memberLevelType.toString()
                        : '0';
                    result.memberLevelIds = result.memberLevelVolist
                        ? result.memberLevelVolist.map((item: any) => item.memberLevelId)
                        : [];
                    result.channelId = toString(result.channelId);
                    result.shippingMethodIds = (result.shippingMethodIds || '').split(',');
                    if (services.navigation.getPathname().includes('detail')) {
                        result.logo = [{ path: result.logo }];
                    }
                    return result;
                }
                data.keyword = data.codeOrName || data.keyword;
                delete data.codeOrName;
                const respData: any = await services.api.get(data, config);
                if (!data.id) {
                    forEach(respData.result, (item) => {
                        item.code = item.onlineStoreCode;
                        item.name = item.onlineStoreName;
                    });
                }
                return respData;
            },
            put: async function(data: any, config: any) {
                config.apiPath = '/dc-store/admin/onlineStore/update';
                const params = {
                    id: data.id,
                    channelId: data.channelId,
                    onlineStoreCode: data.onlineStoreCode,
                    onlineStoreName: data.languageName.zh,
                    languageName: data.languageName,
                    isShow: data.isShow,
                    shippingMethodIds: data.shippingMethodIds.join(','),
                    imageId: data.logo && data.logo.length > 0 ? data.logo[0].id : '',
                    memberLevelIds:
                        data.memberLevelType === STORE_ATTRIBUTES_VALUES.GENERAL_STORE
                            ? undefined
                            : data.memberLevelIds.join(','),
                    memberLevelType: Number(data.memberLevelType),
                };
                return await services.api.put(params, config);
            },
        },
    },
    {
        key: '/dc-store/admin/online_store_organizations/userlist',
        loader: {
            get: async function(data: any, config: any) {
                return await mockFetch([
                    {
                        id: '10',
                        parent_id: '-1',
                        name: '总部',
                        children: [
                            {
                                id: '100',
                                parent_id: '10',
                                name: '港澳',
                                children: [
                                    {
                                        id: '1002',
                                        parent_id: '100',
                                        name: '香港',
                                        children: [
                                            {
                                                id: '67',
                                                parent_id: '1002',
                                                name: 'DFS旗下香港Select店',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                            {
                                                id: '203',
                                                parent_id: '1002',
                                                name: 'DFS旗下香港T广场尖东店',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                            {
                                                id: '3369827595368529920',
                                                parent_id: '1002',
                                                name: '1234',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                        ],
                                        stores: [
                                            {
                                                id: '67',
                                                code: '67',
                                                name: 'DFS旗下香港Select店',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1002',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                            {
                                                id: '203',
                                                code: '203',
                                                name: 'DFS旗下香港T广场尖东店',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1002',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                            {
                                                id: '3369827595368529920',
                                                code: 'test',
                                                name: '1234',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1002',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                        ],
                                        is_store_leaf: true,
                                        is_leaf: false,
                                    },
                                    {
                                        id: '1001',
                                        parent_id: '100',
                                        name: '澳门',
                                        children: [
                                            {
                                                id: '501',
                                                parent_id: '1001',
                                                name: '游客专属澳门本地送货服务',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                            {
                                                id: '579',
                                                parent_id: '1001',
                                                name: 'DFS旗下澳门T广场星際酒店',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                            {
                                                id: '584',
                                                parent_id: '1001',
                                                name: 'DFS旗下澳门T广场美妆世界永利皇宫店',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                            {
                                                id: '599',
                                                parent_id: '1001',
                                                name: 'DFS旗下澳门T广场四季名店',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                            {
                                                id: '600',
                                                parent_id: '1001',
                                                name: 'DFS旗下澳门T广场四季名店导购店',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                            {
                                                id: '3314506939127250944',
                                                parent_id: '1001',
                                                name: 'TestStar',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                            {
                                                id: '3318299395249618944',
                                                parent_id: '1001',
                                                name: '游客专属邮寄服务',
                                                children: null,
                                                stores: null,
                                                is_store_leaf: true,
                                                is_leaf: true,
                                            },
                                        ],
                                        stores: [
                                            {
                                                id: '501',
                                                code: '501',
                                                name: '游客专属澳门本地送货服务',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1001',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                            {
                                                id: '579',
                                                code: '579',
                                                name: 'DFS旗下澳门T广场星際酒店',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1001',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                            {
                                                id: '584',
                                                code: '584',
                                                name: 'DFS旗下澳门T广场美妆世界永利皇宫店',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1001',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                            {
                                                id: '599',
                                                code: '599',
                                                name: 'DFS旗下澳门T广场四季名店',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1001',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                            {
                                                id: '600',
                                                code: 'GS599',
                                                name: 'DFS旗下澳门T广场四季名店导购店',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1001',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                            {
                                                id: '3314506939127250944',
                                                code: '123',
                                                name: 'TestStar',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1001',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                            {
                                                id: '3318299395249618944',
                                                code: 'HD999',
                                                name: '游客专属邮寄服务',
                                                organization_id: null,
                                                retail_format_id: '0',
                                                bu_id: null,
                                                parent_id: '1001',
                                                online_warehouse_location: null,
                                                offline_store_location: null,
                                                online_stock_location: null,
                                                sap_auth_location: null,
                                                sap_auth_location_group_id: null,
                                            },
                                        ],
                                        is_store_leaf: true,
                                        is_leaf: false,
                                    },
                                ],
                                stores: null,
                                is_store_leaf: false,
                                is_leaf: false,
                            },
                        ],
                        stores: null,
                        is_store_leaf: false,
                        is_leaf: false,
                    },
                ]);
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/findStoreOrganizations',
        loader: {
            get: async function(data: any, config: any) {
                const result = await services.api.get(data, config);
                return [result];
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/findStoreOrganizations/tree',
        loader: {
            get: async function(data: any, config: any) {
                config.apiPath = '/dc-store/admin/onlineStore/findStoreOrganizations';
                if (data.id) {
                    data.pickStoreId = data.id;
                    delete data.id;
                }
                const result: any = await services.api.get(data, config);
                const newResult = [result];
                if (data.pickStoreId) {
                    formatOnlineStore(newResult);
                }
                return newResult;
            },
        },
    },
    {
        key: '/dc-store/admin/location',
        loader: {
            get: async function(params: any) {
                return await mockFetch({
                    sapMcsLocation: '588',
                    stockMcsLocation: '599',
                });
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/findAll',
        loader: {
            get: async function(params: any, config: any) {
                return formatOnlineStoreOptions(params, config);
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/allTree',
        loader: {
            get: async function(params: any, config: any) {
                const result = await formatOnlineStoreTree(params, config);
                return [result];
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/own',
        loader: {
            get: async function(params: any, config: any) {
                config.apiPath = '/dc-store/admin/onlineStore/tree';
                if (params && params.id) {
                    params.pickStoreId = params.id;
                    delete params.id;
                }
                const result = await formatOnlineStoreTree(params, config);
                return [result];
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/own/gs',
        loader: {
            get: async function(params: any, config: any) {
                params.channelId = CHANNEL.GUIDE_SHOPPING;
                return Loader.load('get', {
                    params: { ...params },
                    apiPath: '/loader/dc-store/admin/onlineStore/own',
                });
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/findOrderOrganizationByUser',
        loader: {
            get: async function(params: any, config: any) {
                const result: any = await services.api.get(params, config);
                each(result, (item) => {
                    item.division = item.divisionNumber;
                });
                return result;
            },
        },
    },
    {
        key: '/dc-goods/admin/tag_groups/user/privilege',
        loader: {
            get: async function(params: any, config: any) {
                config.apiPath = '/dc-goods/admin/tag_groups/user/privilege';
                let result: any = await services.api.get(params, config);
                if (!isEmpty(result)) {
                    const info = formatOnlineStoreTreeData(result[0]);
                    return [info];
                }
                return result;
            },
        },
    },
    {
        key: '/dc-user/admin/online-store/member-level',
        loader: {
            get: async function(data: any, config: any) {
                config.apiPath = '/dc-user/admin/memberLevel';
                let result: any = await services.api.get({ page: 1, perPage: 100 }, config);
                if (!isEmpty(result.result)) {
                    return result.result.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                    }));
                }
                return [];
            },
        },
    },
];

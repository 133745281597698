import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';
import { recordImportLogs } from '@/services';
import {
    flexibleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
    formFooter,
    tableMixin,
} from '@/configs/mixin';

import { THIRD_PARTY_COUPON_STATUS_OPTIONS, IMPORT_TEMPLATE_URL } from '@/constants';
export const config: Config = {
    entities: {
        thirdPartyCoupon: {
            apiPath: '/dc-ecoupon/admin/otherCoupons',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.name>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.quantity>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.name>>',
                },
            },
        },
        thirdPartyCouponInfo: {
            apiPath: '/dc-ecoupon/admin/otherCoupons',
            properties: {
                id: { type: 'string' },
                couponType: { type: 'string', defaultValue: '3' },
                name: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.name>>',
                    rules: [{ required: true, whitespace: true }, { max: 100 }],
                    controlConfig: {
                        maxLength: 100,
                    },
                },
                instructionsMap: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties(
                            'string',
                            'couponManage.thirdPartyCoupon.instructions',
                            {
                                rules: [{ required: true, whitespace: true }, { max: 1000 }],
                                controlConfig: {
                                    maxLength: 1000,
                                },
                            }
                        ),
                    },
                },
                nameMap: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties(
                            'string',
                            'couponManage.thirdPartyCoupon.displayName',
                            {
                                rules: [{ required: true, whitespace: true }, { max: 200 }],
                                controlConfig: {
                                    maxLength: 200,
                                },
                            }
                        ),
                    },
                },
                picUrl: {
                    type: 'array.brandImage',
                    displayName: '<<couponManage.thirdPartyCoupon.image>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1000,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage:
                            '<<couponManage.thirdPartyCoupon.imgLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    },
                },
            },
        },

        thirdPartyCouponData: {
            apiPath: '/dc-ecoupon/admin/otherCoupons/find',
            properties: {
                id: { type: 'string' },
                couponNum: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.couponNo>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.mobile>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.thirdPartyCoupon.status>>',
                    options: THIRD_PARTY_COUPON_STATUS_OPTIONS,
                },
                grantTimeStr: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.grantTime>>',
                },
                grantStoreName: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.grantStoreName>>',
                },
            },
            filters: {
                couponNum: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.couponNo>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<couponManage.thirdPartyCoupon.mobile>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.thirdPartyCoupon.status>>',
                    options: THIRD_PARTY_COUPON_STATUS_OPTIONS,
                },
            },
        },
    },
    components: {
        ThirdPartyCouponView: {
            component: 'Viewport',
        },
        ThirdPartyCouponPage: {
            component: 'Card',
            entity: 'thirdPartyCoupon',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        ...flexibleFilterForm,
                        fields: [{ property: 'name' }],
                        privilege: {
                            path: 'coupon.otherCoupon.otherCoupon_list',
                            level: 'full',
                        },
                    },
                    {
                        component: 'FlexLayout',
                        direction: 'horizontal',
                        style: {
                            display: 'block',
                            marginBottom: 15,
                        },
                        items: [
                            {
                                component: 'Button',
                                route: '/third-party-coupon/add',
                                icon: 'plus',
                                type: 'primary',
                                text: '<<couponManage.thirdPartyCoupon.add.title>>',
                                style: {
                                    marginRight: 15,
                                },
                                privilege: {
                                    path: 'coupon.otherCoupon.otherCoupon_create',
                                    level: 'full',
                                },
                            },
                            {
                                component: 'OSSExportButton',
                                text: '<<productList.downloadTemplate>>',
                                type: 'link',
                                privilege: {
                                    path: `coupon.otherCoupon.download_import_template`,
                                    level: 'full',
                                },
                                url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.THIRD_PARTY_COUPON}`,
                            },
                        ],
                    },
                    {
                        ...tableMixin,
                        columns: [{ property: 'name' }, { property: 'quantity' }],
                        actionColumn: {
                            title: '<<actions>>',
                            fixed: 'right',
                            items: [
                                {
                                    text: '<<edit>>',
                                    type: 'link',
                                    path: '/third-party-coupon/edit/{{row.id}}',
                                    privilege: {
                                        path: `coupon.otherCoupon.otherCoupon_update`,
                                        level: 'full',
                                    },
                                },
                                {
                                    text: '<<couponManage.thirdPartyCoupon.import>>',
                                    type: 'component',
                                    component: 'TableActionImport',

                                    config: {
                                        style: {
                                            marginRight: '8px',
                                        },
                                        apiPath: `/dc-file/excels?location_id=2`,
                                        showSuccessMessage: false,
                                        showErrorMessage: true,
                                        onUploadComplete(response: any, row: any) {
                                            if (response) {
                                                recordImportLogs({
                                                    name: services.language.getText(
                                                        'couponManage.thirdPartyCoupon.import'
                                                    ),
                                                    apiPath: `/dc-ecoupon/admin/otherCoupons/importOtherCoupon`,
                                                    params: {
                                                        resourceId: response.id,
                                                        couponId: row.id,
                                                    },
                                                });
                                            }
                                        },
                                    },
                                    privilege: {
                                        path: `coupon.otherCoupon.otherCoupon_import`,
                                        level: 'full',
                                    },
                                },
                                {
                                    text: '<<couponManage.thirdPartyCoupon.data.title>>',
                                    type: 'link',
                                    path: '/third-party-coupon/data/{{row.id}}',
                                    privilege: {
                                        path: `coupon.otherCoupon.otherCoupon_detail`,
                                        level: 'full',
                                    },
                                },
                            ],
                        },
                        privilege: {
                            path: 'coupon.otherCoupon.otherCoupon_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ThirdPartyCouponAddPage: {
            component: 'Card',
            title: '基础信息',
            bordered: false,
            entity: 'thirdPartyCouponInfo',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'CreateFormPlus',
                        className: 'none-sub-form brand-edit-from',
                        labelCol: 6,
                        controlCol: 8,
                        footer: formFooter,
                        onSubmitSuccess: () => {
                            message.success(services.language.getText('saveFully'));
                            services.navigation.goBack();
                        },
                        fields: [
                            { property: 'name' },
                            ...getLanguageColumns('nameMap'),
                            ...getLanguageColumns('instructionsMap'),
                            { property: 'picUrl' },
                            { property: 'couponType', className: 'hidden' },
                        ],
                    },
                ],
            },
        },
        ThirdPartyCouponDataPage: {
            component: 'Card',
            entity: 'thirdPartyCouponData',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        ...flexibleFilterForm,
                        fields: [
                            { property: 'couponNum' },
                            { property: 'mobile' },
                            { property: 'status' },
                        ],
                        style: { marginBottom: '16px' },
                    },
                    {
                        ...tableMixin,
                        columns: [
                            { property: 'couponNum' },
                            { property: 'status' },
                            { property: 'mobile' },
                            { property: 'grantTimeStr' },
                            { property: 'grantStoreName' },
                        ],
                        // privilege: {
                        //     path: `coupon.cash_coupon.view_list`,
                        //     level: 'full',
                        // },
                    },
                ],
            },
        },
        ThirdPartyCouponEditPage: {
            component: 'Card',
            title: '基础信息',
            bordered: false,
            entity: 'thirdPartyCouponInfo',
            loaderType: 'get',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'ModifyFormPlus',
                        className: 'none-sub-form brand-edit-from',
                        labelCol: 6,
                        controlCol: 8,
                        footer: formFooter,
                        onSubmitSuccess: () => {
                            message.success(services.language.getText('saveFully'));
                            services.navigation.goBack();
                        },
                        fields: [
                            { property: 'name' },
                            ...getLanguageColumns('nameMap'),
                            ...getLanguageColumns('instructionsMap'),
                            { property: 'picUrl' },
                            { property: 'couponType', className: 'hidden' },
                        ],
                    },
                ],
            },
        },
    },
};

import { formats } from '@comall-backend-builder/types';
import { last, isString } from 'lodash';

export class ArrayTreeOptionNodeFormat extends formats.ArrayFormat {
    /**
     * 将数据格式化为请求参数
     */
    public formatParams(key: any, value: any) {
        // 只需要最后一个storeId
        return { [key]: isString(value) ? value : last(value) };
    }
}

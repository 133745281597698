import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 售后单状态值
 */
export enum AFTER_SALE_STATUS_VALUES {
    /**
     * 全部（前端特有状态用于查询）
     */
    ALL = 'ALL',
    /**
     * 待审核
     */
    WAIT_VERIFY = 'WAIT_APPROVAL',
    /**
     * 审核通过
     */
    APPROVED = 'APPROVAL_PASS',
    /**
     * 审核不通过
     */
    REJECTED = 'APPROVAL_FAIL',
    /**
     * 已完成
     */
    COMPLETED = 'APPROVAL_SUCCESS',
}

/**
 * 售后单状态名
 */
export const AFTER_SALE_STATUS_NAMES = {
    [AFTER_SALE_STATUS_VALUES.ALL]: language.getText('afterSale.statusMap.all'),
    [AFTER_SALE_STATUS_VALUES.WAIT_VERIFY]: language.getText('afterSale.statusMap.waitVerify'),
    [AFTER_SALE_STATUS_VALUES.APPROVED]: language.getText('afterSale.statusMap.approved'),
    [AFTER_SALE_STATUS_VALUES.REJECTED]: language.getText('afterSale.statusMap.rejected'),
    [AFTER_SALE_STATUS_VALUES.COMPLETED]: language.getText('afterSale.statusMap.completed'),
};

/**
 * 售后单状态候选项
 */
export const AFTER_SALE_OPTIONS = generateOptions(
    AFTER_SALE_STATUS_VALUES,
    AFTER_SALE_STATUS_NAMES
);

/**
 * 会员类型值
 */
export enum MEMBER_TYPE_VALUES {
    /**
     * 游客
     */
    TOURIST = '1',
    /**
     * 会员
     */
    MEMBER = '2',
    /**
     * 员工
     */
    STAFF = '3',
}

/**
 * 会员类型名
 */
export const MEMBER_TYPE_NAMES = {
    [MEMBER_TYPE_VALUES.TOURIST]: language.getText('afterSale.memberTypeOption.tourist'),
    [MEMBER_TYPE_VALUES.MEMBER]: language.getText('afterSale.memberTypeOption.member'),
    [MEMBER_TYPE_VALUES.STAFF]: language.getText('afterSale.memberTypeOption.staff'),
};

/**
 * 会员类候选项
 */
export const MEMBER_TYPE_OPTIONS = generateOptions(MEMBER_TYPE_VALUES, MEMBER_TYPE_NAMES);

/**
 * 售后方式值
 */
export enum AFTER_SALE_TYPE_VALUES {
    /**
     * 退货
     */
    RETURN = 'RETURN_GOODS',
    /**
     * 换货
     */
    REPLACE = 'EXCHANGE_GOODS',
}

/**
 * 售后方式名
 */
export const AFTER_SALE_TYPE_NAMES = {
    [AFTER_SALE_TYPE_VALUES.RETURN]: language.getText('afterSale.afterSaleType.return'),
    [AFTER_SALE_TYPE_VALUES.REPLACE]: language.getText('afterSale.afterSaleType.replace'),
};

/**
 * 售后方式候选项
 */
export const AFTER_SALE_TYPE_OPTIONS = generateOptions(
    AFTER_SALE_TYPE_VALUES,
    AFTER_SALE_TYPE_NAMES
);

/**
 * 售后单状态值
 */
export enum REFUND_STATUS_VALUES {
    /**
     * 全部（前端特有状态用于查询）
     */
    ALL = 'ALL',
    /**
     * 待审核
     */
    WAIT_VERIFY = 'WAITING',
    /**
     * 审核通过
     */
    APPROVED = 'AGREE',
    /**
     * 审核不通过
     */
    REJECTED = 'REJECT',
    /**
     * 已完成
     */
    COMPLETED = 'REFUNDED',
}

/**
 * 售后单状态名
 */
export const REFUND_STATUS_NAMES = {
    [REFUND_STATUS_VALUES.ALL]: language.getText('afterSale.statusMap.all'),
    [REFUND_STATUS_VALUES.WAIT_VERIFY]: language.getText('afterSale.statusMap.waitVerify'),
    [REFUND_STATUS_VALUES.APPROVED]: language.getText('afterSale.statusMap.approved'),
    [REFUND_STATUS_VALUES.REJECTED]: language.getText('afterSale.statusMap.rejected'),
    [REFUND_STATUS_VALUES.COMPLETED]: language.getText('afterSale.statusMap.completed'),
};

/**
 * 售后单状态候选项
 */
export const REFUND_OPTIONS = generateOptions(REFUND_STATUS_VALUES, REFUND_STATUS_NAMES);

/**
 * 退款方式状态值
 */
export enum REFUND_METHODS {
    /**
     * 原路退回
     */
    BACK = '0',
    /**
     * 退回为代金券
     */
    VOUCHER = '1',
}

/**
 * 退款方式状态名
 */
export const REFUND_METHODS_NAMES = {
    [REFUND_METHODS.BACK]: language.getText('afterSale.back'),
    [REFUND_METHODS.VOUCHER]: language.getText('afterSale.voucher'),
};

/**
 * 退款方式候选项
 */
export const REFUND_METHODS_OPTIONS = generateOptions(REFUND_METHODS, REFUND_METHODS_NAMES);

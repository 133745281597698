import React, { useState, useEffect } from 'react';
import { Button, Card, Steps, Form, List, message, Spin } from 'antd';
import { FormItemProps, FormProps } from 'antd/es/form';
import { TabsProps, TabPaneProps } from 'antd/es/tabs';
import { CardProps } from 'antd/es/card';
import { ListProps } from 'antd/es/list';
import { ExtendedParentComponentProps } from '@comall-backend-builder/components-basis';
import { services, TypesManager, Loader } from '@comall-backend-builder/core';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/es/form/Form';
import { get, isFunction, omit, defaults, uniqueId } from 'lodash';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { LoadSourceMeta, LoadSourceOptions } from './load-source-options';
import { TYPE_CONDITION_VALUES, REWARD_TYPE_VALUES, APPLY_TYPE_VALUES } from '@/constants';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';

export interface BaseSuperFormItem {
    key: string;
    renderAble?: boolean | ((values: AnyObject, entity: Entity) => boolean);
}

export interface SuperFormField extends AnyObject, BaseSuperFormItem {
    renderType: 'field';
    /**
     * 字段名称
     */
    property: string;
    source?: LoadSourceMeta;
    formItem?: FormItemProps;
    fieldOpts?: GetFieldDecoratorOptions & { suffix?: string; itemMode?: 'view' | 'edit' };
    /**
     * 启用内部加载器（支持根据参数或依耐项变化刷新数据）
     *
     * @default true
     */
    enableInternalLoader?: boolean;
}

export interface SuperFormTabs extends TabsProps, BaseSuperFormItem {
    renderType: 'tabs';
    items: SuperFormItem[];
}

export interface SuperFormTabPane extends TabPaneProps, Omit<BaseSuperFormItem, 'key'> {
    renderType: 'tabPane';
    items?: SuperFormItem[];
    dictItems?: { '1': SuperFormItem[]; '2': SuperFormItem[] };
    activeKey?: string;
}

export interface SuperFormCards extends ListProps<AnyObject>, BaseSuperFormItem {
    renderType: 'cards';
    items: SuperFormItem[];
}

export interface SuperFormCardPane extends CardProps, BaseSuperFormItem {
    renderType: 'cardPane';
    items: SuperFormItem[];
}

export type SuperFormItem =
    | SuperFormField
    | SuperFormTabs
    | SuperFormTabPane
    | SuperFormCards
    | SuperFormCardPane;

export interface SuperFormProps extends FormProps, ExtendedParentComponentProps {
    mode: 'add' | 'edit' | 'view';
    form: WrappedFormUtils<AnyObject>;
    items: SuperFormItem[];
    watchState: AnyObject;
    requestStatus: string;
    onValidate?(values: AnyObject, entity: Entity): boolean;
}

/**
 * 支持多种布局方式的表单组件
 */
export const SalesPromotionForm = compose(
    connect((state, props: SuperFormProps) => ({
        watchState: get(state, `entities.${props.entity!.id}`) || {},
        requestStatus: props.entity!.requestStatus,
    })),
    Form.create({
        name: 'SuperForm',
        onValuesChange(props: SuperFormProps, _, values: AnyObject) {
            const { entity } = props;
            if (entity) {
                entity.setFields({
                    ...(entity.fields ?? {}),
                    ...(values ?? {}),
                });
            }
        },
    })
)((props: SuperFormProps) => {
    const {
        items,
        entity,
        watchState,
        entities,
        routes,
        params,
        mode,
        form,
        requestStatus,
        onValidate,
        ...formProps
    } = props;

    const editable = ['add', 'edit'].includes(mode);
    const loadable = ['edit', 'view'].includes(mode);

    const [submitting, setSubmitting] = useState(false);
    const [activeKey, setActiveKey] = useState('1');

    useEffect(() => {
        if (entity && loadable) entity.get(entity.params);
    }, []);

    useEffect(() => {
        if (submitting) {
            if (requestStatus === 'success') {
                message.success(services.language.getText('common.saveSuccess'));
                services.navigation.goBack();
            } else if (requestStatus === 'failed') {
                setSubmitting(false);
            }
        }
    }, [submitting, requestStatus]);

    const entityValues = get(entity, 'fields', {});
    const formValues = form.getFieldsValue();
    const values = defaults(entityValues, formValues);

    /**
     * 判断field是否渲染
     * @param item field value
     * @returns boolean
     */
    function isRenderAble(item: SuperFormItem) {
        const { renderAble } = item;

        return entity
            ? renderAble !== undefined
                ? isFunction(renderAble)
                    ? renderAble(values, entity)
                    : renderAble
                : true
            : false;
    }

    function renderItem(item: SuperFormItem) {
        if (!isRenderAble(item)) return null;

        switch (item.renderType) {
            case 'field':
                return renderField(item);
            case 'tabs':
                return renderTabs(item);
            case 'tabPane':
                return renderTabPane(item);
            case 'cards':
                return renderCards(item);
            case 'cardPane':
                return renderCardPane(item);
        }
    }

    /**
     * 渲染form field
     * @param item
     * @returns
     */
    function renderField(item: SuperFormField) {
        const {
            renderType,
            key,
            property,
            enableInternalLoader = true,
            formItem = {},
            formatField,
            fieldOpts = {},
            ...fieldProps
        } = item;

        const value = get(entityValues, property);
        let field = get(entity, `metainfo.properties.${property}`);
        if (!field) return null;

        // 根据values处理options
        if (formatField && isFunction(formatField)) {
            field = { ...field, ...formatField(values, entity) };
        }

        const fieldType = TypesManager.get(field.type);
        const mergedFieldProps = getMergedFieldProps(field, fieldProps);

        if (mode === 'view') {
            return (
                <Form.Item key={key} label={field.displayName} {...formItem}>
                    {fieldType.getDisplayComponent(value, mergedFieldProps)}
                </Form.Item>
            );
        }

        const rules = initFieldOptsRules(field, fieldOpts);

        /**
         * 高阶校验 将form的其它fields传进去校验
         * 会覆盖原有validator
         * @returns
         */
        const getValidatorFn = () => {
            // @ts-ignore
            if (fieldOpts.advancedValidator && isFunction(fieldOpts.advancedValidator)) {
                return {
                    validator: (
                        _rule: any,
                        value: any,
                        callback: any // @ts-ignore
                    ) => fieldOpts.advancedValidator(entityValues, value, callback),
                };
            }
            return null;
        };

        const transformInitVal = (value: any) => {
            if (property === 'typeRewardGiveaways') {
                return fieldOpts.initialValue;
            } else {
                return value === undefined ? fieldOpts.initialValue : value;
            }
        };

        const mergedFieldOpts = Object.assign({}, fieldOpts, {
            rules: [
                ...rules,
                getValidatorFn(),
                {
                    validator: fieldType.validate,
                },
            ].filter(Boolean),
            initialValue: transformInitVal(value),
        });

        if (mergedFieldProps.source && enableInternalLoader) {
            return (
                <LoadSourceOptions
                    key={key}
                    entity={entity}
                    options={mergedFieldProps.options}
                    source={mergedFieldProps.source}
                    onReload={() => {
                        if (mergedFieldProps.source.cleanValueOnReload) {
                            form.setFieldsValue({
                                [property]: fieldOpts.initialValue,
                            });
                        }
                    }}
                >
                    {(options) => (
                        <Form.Item label={field.displayName} {...formItem}>
                            {form.getFieldDecorator(
                                property,
                                mergedFieldOpts
                            )(
                                fieldType.getControlComponent({
                                    ...mergedFieldProps,
                                    name: property,
                                    options,
                                })
                            )}
                        </Form.Item>
                    )}
                </LoadSourceOptions>
            );
        }
        const renderCmpType = (type: string) => {
            if (type === 'freeGiftThresholdPrice' || type === 'freeGiftThresholdNums') {
                return (
                    <Form.Item
                        key={key}
                        label={field.displayName}
                        {...formItem}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {form.getFieldDecorator(
                                property,
                                mergedFieldOpts
                            )(
                                fieldType.getControlComponent({
                                    ...mergedFieldProps,
                                    name: property,
                                })
                            )}
                            {item.property === 'freeGiftThresholdPrice' && (
                                <span style={{ paddingLeft: '10px' }}>
                                    {language.getText('salesPromotion.conditionsReward.unit')}
                                </span>
                            )}
                            {item.property === 'freeGiftThresholdNums' && (
                                <span style={{ paddingLeft: '10px' }}>
                                    {language.getText('salesPromotion.conditionsReward.letter')}
                                </span>
                            )}
                        </div>
                    </Form.Item>
                );
            }
            const { suffix, itemMode, initialValue } = fieldOpts;
            if (itemMode === 'view') {
                return (
                    <Form.Item key={key} label={field.displayName} {...formItem}>
                        {fieldType.getDisplayComponent(initialValue, mergedFieldProps)}
                    </Form.Item>
                );
            }
            return (
                <Form.Item key={key} label={field.displayName} {...formItem}>
                    {suffix ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {form.getFieldDecorator(
                                property,
                                mergedFieldOpts
                            )(
                                fieldType.getControlComponent({
                                    ...mergedFieldProps,
                                    name: property,
                                })
                            )}
                            {suffix && <span style={{ paddingLeft: '10px' }}>{suffix}</span>}
                        </div>
                    ) : (
                        form.getFieldDecorator(
                            property,
                            mergedFieldOpts
                        )(
                            fieldType.getControlComponent({
                                ...mergedFieldProps,
                                name: property,
                            })
                        )
                    )}
                </Form.Item>
            );
        };

        return renderCmpType(item.property);
    }

    /**
     * 处理form field props
     * @param field
     * @param fieldProps
     * @returns
     */
    function getMergedFieldProps(field: AnyObject, fieldProps: AnyObject) {
        const { controlConfig = {}, displayConfig = {} } = field;
        const restProps = omit(field, ['type', 'displayName', 'controlConfig', 'displayConfig']);

        return {
            entity,
            entities,
            ...restProps,
            ...(mode === 'view' ? displayConfig : controlConfig),
            ...fieldProps,
        };
    }

    /**
     * 初始化form field 校验规则
     * @param field
     * @param fieldOpts
     * @returns
     */
    function initFieldOptsRules(field: AnyObject, fieldOpts: GetFieldDecoratorOptions) {
        return Array.isArray(fieldOpts.rules)
            ? fieldOpts.rules.map((rule) =>
                  rule.required
                      ? {
                            ...rule,
                            message:
                                rule.message ||
                                `${field.displayName}${services.language.getText(
                                    'components.Form.required'
                                )}`,
                        }
                      : rule
              )
            : [];
    }

    function renderTabs(item: SuperFormTabs) {
        const { items } = item;
        return (
            <div>
                <Steps
                    current={Number(activeKey) - 1}
                    style={{ marginBottom: 60, boxShadow: '0px -1px 0 0 #e8e8e8 inset' }}
                    type='navigation'
                >
                    {items.map((item: any) => {
                        return <Steps.Step key={uniqueId()} title={item.tab} />;
                    })}
                </Steps>
                {items.map(renderItem)}
            </div>
        );
    }

    /**
     * 根据奖励类型和促销类型渲染不同的奖励规则配置
     */
    const renderDictForm = () => {
        const conditionType = form.getFieldValue('conditionType');
        const rewardType = form.getFieldValue('rewardType');
        switch (rewardType) {
            case REWARD_TYPE_VALUES.DISCOUNT:
                return 1;
            case REWARD_TYPE_VALUES.MONEY:
                return 2;
            case REWARD_TYPE_VALUES.STEP_MONEY:
                return conditionType === TYPE_CONDITION_VALUES.MONEY ? 3 : 4;
            case REWARD_TYPE_VALUES.STEP_DISCOUNT:
                return conditionType === TYPE_CONDITION_VALUES.MONEY ? 5 : 6;
            case REWARD_TYPE_VALUES.SATISFY_MONEY:
                return conditionType === TYPE_CONDITION_VALUES.MONEY ? 7 : 8;
            case REWARD_TYPE_VALUES.GIFT:
                return 9;
            case REWARD_TYPE_VALUES.CHOOSE_PRODUCT:
                return 10;
            case REWARD_TYPE_VALUES.MANY_PIECES:
                return 12;
            default:
                return 1;
        }
    };

    function renderTabPane(item: SuperFormTabPane) {
        const { items, dictItems, activeKey: _activeKey } = item;
        return (
            <div style={{ display: Number(activeKey) === Number(_activeKey) ? 'block' : 'none' }}>
                {_activeKey === '3' //@ts-ignore
                    ? dictItems && dictItems[renderDictForm()].map(renderItem) // @ts-ignore
                    : items && items.map(renderItem)}
            </div>
        );
    }

    function renderCards(item: SuperFormCards) {
        const { renderType, items, ...cardsProps } = item;
        return (
            <List
                {...cardsProps}
                dataSource={items}
                renderItem={(item) => <List.Item key={item.key}>{renderItem(item)}</List.Item>}
            />
        );
    }

    function renderCardPane(item: SuperFormCardPane) {
        const { renderType, items, ...cardProps } = item;
        return <Card {...cardProps}>{items.map(renderItem)}</Card>;
    }

    /**
     * form 提交
     */
    function handleSubmit() {
        form.validateFields((errors: any, values: AnyObject) => {
            if (errors || (onValidate && !onValidate(values, entity!))) return;

            setSubmitting(true);
            switch (mode) {
                case 'add':
                    entity?.add(values, entity.params);
                    break;
                case 'edit':
                    entity?.modify(values, entity.params);
                    break;
            }
        });
    }

    /**
     * 适用商品类型和排除商品类型中 校验商品列表sku是否合法
     */
    async function checkSku() {
        const {
            applyGoods,
            applyType,
            excludeType,
            excludeGoods,
            storeIds,
        } = form.getFieldsValue();

        const checkSkuAsync = async (sku: string[]) => {
            const result = await Loader.load('post', {
                apiPath: '/dc-promotion/admin/promotion/check/sku',
                params: {
                    sku,
                    storeIds,
                },
            });
            return result;
        };

        try {
            if (applyType === APPLY_TYPE_VALUES.GOODS && applyGoods) {
                await checkSkuAsync(applyGoods);
            }
            if (excludeType === APPLY_TYPE_VALUES.GOODS && excludeGoods) {
                await checkSkuAsync(excludeGoods);
            }

            return Promise.resolve(true);
        } catch (e) {
            // @ts-ignore
            services.errorHandle(e);

            return Promise.reject(false);
        }
    }

    /**
     * 校验优先级是否重复
     * @returns
     */
    async function checkPriority() {
        try {
            await Loader.load('post', {
                apiPath: '/dc-promotion/admin/promotion/check',
                params: {
                    ...entityValues,
                },
            });

            return Promise.resolve(true);
        } catch (e) {
            // @ts-ignore
            services.errorHandle(e);

            return Promise.reject(false);
        }
    }

    async function handleNext() {
        const validateFieldNames = {
            '1': ['type', 'discName', 'effectTime', 'storeIds', 'priority'],
            '2': [
                'applyType',
                'applyGoods',
                'applyBrand',
                'applyOfflineCategory',
                'applyVirtualCategory',
                'conditionType',
                'rewardType',
            ],
        };
        const successFn = () => {
            setActiveKey((value) => {
                let num = Number(value) + 1;
                return num.toString();
            });
        };

        switch (activeKey) {
            case '2':
                if (!(await checkSku())) {
                    return;
                }
            // eslint-disable-next-line no-fallthrough
            case '1':
                form.validateFieldsAndScroll(validateFieldNames[activeKey], async (err) => {
                    const res = await checkPriority();
                    if (!err && res) {
                        successFn();
                    }
                });
                break;
            case '3':
                form.validateFieldsAndScroll((err) => {
                    if (!err) {
                        successFn();
                    }
                });
                break;
            default:
                setActiveKey((value) => {
                    const num = Number(value) + 1;
                    return num.toString();
                });
        }
    }

    const formEl = (
        <Form {...omit(formProps, ['dispatch'])} key={String(requestStatus === 'pending')}>
            {items.map(renderItem)}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '15px',
                    margin: '15px',
                }}
            >
                {Number(activeKey) > 1 && (
                    <Button
                        onClick={() => {
                            setActiveKey((value) => {
                                const num = Number(value) - 1;
                                if (num === 1) {
                                    return '1';
                                } else {
                                    return num.toString();
                                }
                            });
                        }}
                    >
                        {services.language.getText('salesPromotion.previousStep')}
                    </Button>
                )}
                {Number(activeKey) < 4 && (
                    <Button type='primary' onClick={handleNext}>
                        {services.language.getText('salesPromotion.nextStep')}
                    </Button>
                )}
                {Number(activeKey) === 4 && editable && (
                    <Button type='primary' onClick={handleSubmit}>
                        {services.language.getText('components.Button.submit')}
                    </Button>
                )}
            </div>
        </Form>
    );

    return requestStatus === 'pending' ? <Spin>{formEl}</Spin> : formEl;
}) as React.FC<SuperFormProps>;

import React, { useState, useEffect } from 'react';
import { Form as AntForm, Button, Modal, Table, Tag } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';
import { cloneDeep } from 'lodash-es';

import { OrderInfoProductProps } from '.';
import './index.less';
import { services } from '@comall-backend-builder/core';

export default (props: OrderInfoProductProps) => {
    const { activeKey } = props;
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = props.form;
    const memberId = props.memberInfo?.memberId || props.touristsMemberId;

    const [showCoupon, setShowCoupon] = useState(false);
    const { cashCoupons: value = [] } = getFieldsValue();
    const appliedIds = value.map((i: any) => i.id);

    const couponList =
        props.checkoutData?.availableVouchers?.filter(
            (i: any) => !value.find((j: any) => i.voucherNum === j.voucherNum)
        ) || [];

    useEffect(() => {
        if (activeKey === 3 && value?.length) {
            setFieldsValue({
                cashCoupons: [],
            });
        }
    }, [activeKey]);

    async function handleSelectCoupon(row: any) {
        const selectedVoucherNums: any = value.map((i: any) => i.voucherNum);
        selectedVoucherNums.push(row.voucherNum);
        setShowCoupon(false);
        await props
            .handleCheckout({
                selectedVoucherNums,
                memberId,
                onlineStoreId: props.params?.onlineStoreIds,
            })
            .then(() => {
                const newItem: any = { voucherNum: row.voucherNum, balance: row.balance };
                if (!value || !value.length) {
                    setFieldsValue({
                        cashCoupons: [newItem],
                    });
                } else {
                    const newValue = cloneDeep(value);
                    newValue.push(newItem);
                    setFieldsValue({ cashCoupons: newValue });
                }
            });
    }

    async function handleRemove(itemIndex: any) {
        const newValue = value.filter((i: any, index: number) => index !== itemIndex);
        const selectedVoucherNums = newValue.map((i: any) => i.voucherNum);
        await props
            .handleCheckout({
                selectedVoucherNums,
                memberId,
                onlineStoreId: props.params?.onlineStoreIds,
            })
            .then(() => {
                setFieldsValue({ cashCoupons: newValue });
            });
    }

    async function handleOpenSelect() {
        setShowCoupon(true);
    }

    const columns = [
        {
            title: services.language.getText('couponManage.cashCoupon.couponNo'),
            dataIndex: 'voucherNum',
        },
        {
            title: services.language.getText('couponRule.customerType'),
            dataIndex: 'type',
            render: (val: string) => {
                return services.language.getText('offlineOrder.create.cashCoupon');
            },
        },
        {
            title: services.language.getText('couponManage。voucherManagement.viewData.balance'),
            dataIndex: 'balance',
            render: (val: number) => {
                return `$${val}`;
            },
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (val: number, row: any) => {
                return (
                    <Button
                        disabled={appliedIds.includes(row.id)}
                        type='primary'
                        size='small'
                        onClick={() => handleSelectCoupon(row)}
                    >
                        {language.getText('offlineOrder.create.selectText')}
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <AntForm.Item label={language.getText('offlineOrder.create.voucherDiscount')}>
                {getFieldDecorator('cashCoupons')(
                    <>
                        <Button
                            className='select-member-coupon-btn'
                            onClick={handleOpenSelect}
                            type='link'
                            disabled={!props.memberInfo?.phone}
                        >
                            {language.getText('offlineOrder.create.selectMemberCashCoupon')}
                        </Button>

                        <div className='coupon-selected-card'>
                            {!!value &&
                                value.map((i: any, index: number) => {
                                    return (
                                        <Tag
                                            key={i.id + index}
                                            className='coupon-selected-card-tag'
                                            closable
                                            onClose={() => handleRemove(index)}
                                        >
                                            {services.language.getText(
                                                'offlineOrder.create.cashCoupon'
                                            )}
                                            ：{`$${i.balance}`}
                                        </Tag>
                                    );
                                })}
                        </div>

                        <Modal
                            wrapClassName='select-address-modal'
                            visible={showCoupon}
                            onCancel={() => setShowCoupon(false)}
                            closable={false}
                            footer={[
                                <Button onClick={() => setShowCoupon(false)}>
                                    {language.getText('common.cancel')}
                                </Button>,
                            ]}
                            width={800}
                        >
                            <Table
                                scroll={{ y: 650 }}
                                pagination={false}
                                columns={columns}
                                dataSource={couponList}
                            />
                        </Modal>
                    </>
                )}
            </AntForm.Item>
        </>
    );
};

import React, { useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { Button } from '@comall-backend-builder/components-basis';
import { language, errorHandle, api } from '@comall-backend-builder/core/lib/services';
import { KV } from '@/interfaces';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
const formItemLayout = {
    labelCol: {
        sm: { span: 6 },
    },
    wrapperCol: {
        sm: { span: 16 },
    },
};
function changePasswordApi(id: string, params: KV) {
    return api.put(params, {
        apiRoot: ENV.API_ROOT,
        apiPath: `/dc-user/admin/users/updatePassword/${id}`,
    });
}

/**
 *  权限管理 - 用户管理 - 修改密码按钮
 */
export const TableActionChangePassword = Form.create()(TableActionChangePasswordComponent);
export function TableActionChangePasswordComponent(props: KV) {
    const { className, row } = props;
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmDirty, setConfirmDirty] = useState(false);
    const {
        getFieldDecorator,
        validateFields,
        validateFieldsAndScroll,
        resetFields,
        getFieldValue,
    } = props.form;
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
        value = value.replace(/\s*/g, ''); // 移除空格
        setConfirmDirty((prev) => prev || !value);
    };
    const confirmHandler = async () => {
        validateFieldsAndScroll(async (err: any, values: any) => {
            if (!err) {
                try {
                    setLoading(true);
                    await changePasswordApi(row.id, { password: values.password });
                    resetFields();
                    setVisible(false);
                    message.success(
                        language.getText('privilegeManage.users.changePasswordSuccess')
                    );
                } catch (e) {
                    errorHandle(e as ErrorInfo);
                } finally {
                    setLoading(false);
                }
            }
        });
    };
    return (
        <>
            <Button className={className} type='link' onClick={() => setVisible(true)}>
                {language.getText('privilegeManage.users.resetPassword')}
            </Button>
            <Modal
                title={language.getText('privilegeManage.users.resetPassword')}
                visible={visible}
                onOk={confirmHandler}
                onCancel={() => setVisible(false)}
                confirmLoading={loading}
            >
                <Form {...formItemLayout}>
                    <Form.Item label={language.getText('privilegeManage.users.password')}>
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: language.getText(
                                        'privilegeManage.users.loginPasswordEmpty'
                                    ),
                                },
                                {
                                    pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#@!~%^&*.\-`$_]).{8,20}/,
                                    message: language.getText('privilegeManage.users.passwordRule'),
                                },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (value && confirmDirty) {
                                            validateFields(['confirmPassword'], {
                                                force: true,
                                            });
                                        }
                                        callback();
                                    },
                                },
                            ],
                        })(
                            <Input.Password
                                autoComplete='new-password'
                                maxLength={20}
                                placeholder={language.getText(
                                    'privilegeManage.users.passwordPlaceholder'
                                )}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        label={language.getText('privilegeManage.users.confirmPassword')}
                        required
                    >
                        {getFieldDecorator('confirmPassword', {
                            rules: [
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (value !== getFieldValue('password')) {
                                            callback(
                                                language.getText(
                                                    'privilegeManage.users.confirmPasswordRule'
                                                )
                                            );
                                        } else {
                                            callback();
                                        }
                                    },
                                },
                            ],
                        })(
                            <Input.Password
                                autoComplete='new-password'
                                maxLength={20}
                                onBlur={changeHandler}
                                placeholder={language.getText(
                                    'privilegeManage.users.confirmPasswordPlaceholder'
                                )}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

import { services } from '@comall-backend-builder/core/lib';

/**
 * 拣货邮件相关loader
 */
export default [
    {
        key: '/dc-message-center/admin/pick_email_config',
        loader: {
            get: async function(data: any, config: any) {
                let response: any = await services.api.get(data, config);
                response.result = (response!.result || []).map((item: any) => ({
                    ...item,
                    status: item.status === true ? '1' : '0',
                }));
                return response;
            },
        },
    },
];

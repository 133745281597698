import { get as lodashGet } from 'lodash';
import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/dc-order/admin/deposit/findList',
        loader: {
            get: async function(data: any, config: any) {
                if (data.createTime) {
                    data.createTimeBegin = data.createTime.start;
                    data.createTimeEnd = data.createTime.end;
                    delete data.createTime;
                }
                if (data.updateTime) {
                    data.updateTimeBegin = data.updateTime.start;
                    data.updateTimeEnd = data.updateTime.end;
                    delete data.updateTime;
                }
                const res: any = await services.api.get(data, config);

                return {
                    ...res,
                    result: res.result.map((item: any) => ({
                        ...item,
                        id: item.id,
                        storeName: lodashGet(item, 'storeName'),
                        serialNo: lodashGet(item, 'serialNo'),
                        amount: lodashGet(item, 'amount'),
                        status: lodashGet(item, 'status'),
                        orderNumber: lodashGet(item, 'orderNumber'),
                        orderStatus: lodashGet(item, 'orderStatus'),
                        createTime: lodashGet(item, 'createTime'),
                        updateTime: lodashGet(item, 'updateTime'),
                        depositStatusVal: lodashGet(item, 'depositStatusVal'),
                        orderStatusName: lodashGet(item, 'orderStatusName'),
                    })),
                };
            },
        },
    },
    {
        key: '/dc-order/admin/deposit/cancel',
        loader: {
            put: async (data: any, config: any) => {
                return await services.api.put(data, {
                    ...config,
                    apiPath: `/dc-order/admin/deposit/${data.id}/cancel`,
                });
            },
        },
    },
    {
        key: '/dc-order/admin/deposit/person_verification',
        loader: {
            put: async (data: any, config: any) => {
                return await services.api.put(data, {
                    ...config,
                    apiPath: `/dc-order/admin/deposit/${data.id}/person_verification`,
                });
            },
        },
    },
    {
        key: '/dc-order/admin/deposit/getDetail',
        loader: {
            get: async (data: any) => {
                return await services.api.get(data, {
                    apiPath: `/dc-order/admin/deposit/getDetail`,
                });
            },
        },
    },
];

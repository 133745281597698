import React, { useState, useEffect, useMemo } from 'react';
import { Loader, services } from '@comall-backend-builder/core';
import { Button, Form, Input, Modal, InputNumber, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useAntdTable, useToggle } from 'ahooks';
import { interpolate, language } from '@comall-backend-builder/core/lib/services';
import { isString, noop } from 'lodash';

import './index.less';

export interface CouponGroupProps {
    entity?: AnyObject;
    row?: AnyObject;
    value?: AnyObject[];
    params?: AnyObject;
    maxCount?: number;
    onChange?(value: AnyObject[]): void;
}

export const CouponGroup: React.FC<CouponGroupProps> = (props) => {
    const {
        value = [],
        params: userParams = {},
        entity,
        row,
        maxCount = 10,
        onChange = noop,
    } = props;

    const [open, openActions] = useToggle();
    const [name, setName] = useState<string>();
    const [selectedRowKey, setSelectedRowsKey] = useState<string>();

    const { loading, tableProps, search, pagination } = useAntdTable((parmas) => {
        // 支持翻译参数
        // 如 { id: '{{entity.fields.id}}' } => { id: '1' }
        const formattedParams = Object.fromEntries(
            Object.entries(userParams).map(([k, v]) => {
                if (isString(v)) {
                    v = interpolate(v, {
                        entity,
                        row,
                    });
                }
                return [k, v];
            })
        );

        return Loader.load('get', {
            apiRoot: ENV.API_ROOT,
            apiPath: '/dc-ecoupon/admin/coupons/querySimple',
            params: {
                page: parmas.current,
                perPage: parmas.pageSize,
                name,
                couponType: 1,
                provideType: 1,
                enable: true,
                ...formattedParams,
            },
        }).then((res: any) => ({
            list: res.result,
            total: res.totalNum,
        }));
    });

    const columns: ColumnProps<AnyObject>[] = [
        {
            title: language.getText('components.CouponGroup.couponName'),
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: language.getText('components.CouponGroup.effectiveStartTime'),
            key: 'effectStartTime',
            dataIndex: 'effectStartTime',
        },
        {
            title: language.getText('components.CouponGroup.effectiveEndTime'),
            key: 'effectEndTime',
            dataIndex: 'effectEndTime',
        },
    ];

    const editColumns: ColumnProps<AnyObject>[] = [
        ...columns,
        {
            title: language.getText('components.CouponGroup.number'),
            key: 'number',
            dataIndex: 'number',
            width: 100,
            render(_, record, index) {
                return (
                    <InputNumber
                        min={1}
                        max={99}
                        value={record.number}
                        onChange={(val = 1) =>
                            onChange(
                                value.map((item, idx) =>
                                    idx === index ? { ...item, number: val } : item
                                )
                            )
                        }
                    />
                );
            },
        },
        {
            title: language.getText('common.handle'),
            key: 'handle',
            dataIndex: 'handle',
            align: 'center',
            render(_, __, index) {
                return (
                    <div className='coupon-group-handles'>
                        <Button
                            type='link'
                            disabled={index === 0}
                            onClick={() => onChange(swapValue(value, index, index - 1))}
                        >
                            {language.getText('common.moveUp')}
                        </Button>
                        <Button
                            type='link'
                            disabled={index === value.length - 1}
                            onClick={() => onChange(swapValue(value, index, index + 1))}
                        >
                            {language.getText('common.moveDown')}
                        </Button>
                        <Button
                            type='link'
                            onClick={() => onChange(value.filter((_, idx) => idx !== index))}
                        >
                            {language.getText('common.delete')}
                        </Button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => setSelectedRowsKey(undefined), [loading]);

    function swapValue(value: AnyObject[], fromIndex: number, toIndex: number) {
        const nextValue = [...value];
        nextValue[fromIndex] = value[toIndex];
        nextValue[toIndex] = value[fromIndex];
        return nextValue;
    }

    return (
        <div className='coupon-group'>
            <Button
                className='coupon-group-trigger'
                type='primary'
                disabled={value?.length >= maxCount}
                onClick={() => {
                    openActions.toggle();
                    search.reset();
                }}
            >
                {language.getText('components.CouponGroup.addCoupon')}
            </Button>
            <Modal
                width={800}
                title={language.getText('components.CouponGroup.couponList')}
                visible={open}
                okButtonProps={{
                    disabled: !selectedRowKey,
                }}
                onOk={() => {
                    openActions.toggle();
                    const selectedValue = tableProps.dataSource.find(
                        (item) => item.id === selectedRowKey
                    );
                    const coupon = {
                        couponId: selectedValue.id,
                        name: selectedValue.name,
                        effectStartTime: selectedValue.effectStartTime,
                        effectEndTime: selectedValue.effectEndTime,
                        number: 1,
                    };
                    onChange([...value, coupon]);
                }}
                onCancel={openActions.toggle}
            >
                <Form className='coupon-group-form' layout='inline'>
                    <Form.Item
                        label={services.language.getText('offlineOrder.selectGoods.keywords')}
                    >
                        <Input
                            style={{ width: 250 }}
                            placeholder={services.language.getText('common.placeInput')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' loading={loading} onClick={search.submit}>
                            {services.language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={() => setName('')}>
                            {services.language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
                <Table
                    {...tableProps}
                    rowKey='id'
                    columns={columns}
                    rowSelection={{
                        type: 'radio',
                        selectedRowKeys: selectedRowKey ? [selectedRowKey] : undefined,
                        onChange: (selectedRowKeys = []) => {
                            setSelectedRowsKey(selectedRowKeys[0] as string);
                        },
                    }}
                    pagination={{
                        ...pagination,
                        onChange(page, pageSize = 10) {
                            pagination.onChange(page, pageSize);
                        },
                    }}
                />
            </Modal>
            <Table
                columns={editColumns}
                rowKey={(record, index) => `${record.id}-${record.number}-${index}`}
                dataSource={value}
            />
        </div>
    );
};

import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { get } from 'lodash';

class StoreLocation extends Component<any, any> {
    render() {
        const { name, entity } = this.props;
        const data = get(entity, `fields.${name}`, []);
        const locationAttr =
            name === 'onlineSapStoreVolist' ? 'sapMcsStoreVolist' : 'stockMcsStoreVolist';
        return (
            <>
                {data.length > 0 ? (
                    data.map((item: any) => (
                        <div key={item.retailStoreName}>
                            <span style={{ paddingRight: 20 }}>{item.retailStoreName}</span>
                            <span style={{ paddingRight: 20 }}>
                                {item[locationAttr].map((loc: any) => loc.mcsStoreId).join(',')}
                            </span>
                        </div>
                    ))
                ) : (
                    <span>-</span>
                )}
            </>
        );
    }
}

/**
 * 状态类型
 */
export class StoreLocationMode extends modes.StringMode {
    getControlComponent(controlInfo: any) {
        return <StoreLocation {...controlInfo} />;
    }
}

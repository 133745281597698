/**
 * 渠道营业时间选择星期
 */
export enum Weeks {
    MONDAY = 'week.monday',
    TUESDAY = 'week.tuesday',
    WEDNESDAY = 'week.wednesday',
    THURSDAY = 'week.thursday',
    FRIDAY = 'week.friday',
    SATURDAY = 'week.saturday',
    SUNDAY = 'week.sunday',
}

/**
 * 订单状态
 */
export enum OrderStatus {
    ASSEMBLED = '待拣货',
    PICKED = '待打包',
    PACKED = '待提货',
    COMPLETED = '已提货',
    CANCELLED = '已取消',
    WAIT_CONFIRM = '待确认',
    REFUNDING = '退款中',
    RETURNED = '已退货',
    ABNORMAL_ORDERS = '异常订单',
}

import { language, uuid } from '@comall-backend-builder/core/lib/services';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { UploadImageVideo, UploadType } from '../upload-image-video';
import './index.less';

export interface MerchantBannerValue {
    id?: string;
    mobile?: string;
    pc?: string;
    target?: string;
}

export interface MerchantBannerListEditProps {
    value?: MerchantBannerValue[];
    onChange?(value: MerchantBannerValue[]): void;
}

export const MerchantBannerListEdit: React.FC<MerchantBannerListEditProps> = (props) => {
    const { value: _value, onChange } = props;
    let value: MerchantBannerValue[] =
        !_value || _value.length === 0
            ? [{ mobile: undefined, pc: undefined, target: undefined }]
            : _value;
    value = value.map((item) => (item.id ? item : { ...item, id: uuid() }));

    const uploadProps = {
        maxCount: 1,
        uploadType: ['image'] as UploadType[],
        uploadLimitSizeErrorMessage: '<<displayCategory.imageOverstep>>',
        limitTips: {
            className: 'hidden',
        },
        img: {
            uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
            maxSize: 5 * 1024,
            fileName: 'fileName',
            accept: 'image/png,image/jpg,image/jpeg,image/svg',
        },
    };

    return (
        <>
            {value.map(({ id, mobile, pc, target }, index) => {
                // pc mobile 必须都上传或者都不上传

                // pc 存在但 mobile 不存在
                const isMobileError = value.length > 1 && pc && !mobile;
                // mobile 存在但 pc 不存在
                const isPcError = value.length > 1 && mobile && !pc;
                // pc mobile target 都存在但 target 格式错误
                const isTargetError = pc && mobile && target && !/^https:\/\/.+/.test(target);

                return (
                    <Form key={id}>
                        <Form.Item
                            className='merchant-banner-list-edit-form-item'
                            label={language.getText('businessCooperator.mpBanner')}
                            labelCol={{
                                span: 8,
                            }}
                            validateStatus={isMobileError ? 'error' : 'success'}
                        >
                            <UploadImageVideo
                                {...uploadProps}
                                value={
                                    mobile
                                        ? [{ id, url: mobile, video: false }]
                                        : (undefined as any)
                                }
                                onChange={(files = []) => {
                                    const nextValue = value.map((item, i) =>
                                        i === index ? { ...item, mobile: files[0]?.url } : item
                                    );
                                    onChange?.(nextValue);
                                }}
                            />
                            {isMobileError && (
                                <div className='ant-form-explain merchant-banner-list-edit-error'>
                                    {language.getText('common.pleaseUpload')}
                                    {language.getText('businessCooperator.mpBanner')}
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item
                            className='merchant-banner-list-edit-form-item'
                            label={language.getText('businessCooperator.pcBanner')}
                            labelCol={{
                                span: 8,
                            }}
                            validateStatus={isPcError ? 'error' : 'success'}
                        >
                            <UploadImageVideo
                                {...uploadProps}
                                value={pc ? [{ id, url: pc, video: false }] : (undefined as any)}
                                onChange={(files = []) => {
                                    const nextValue = value.map((item, i) =>
                                        i === index ? { ...item, pc: files[0]?.url } : item
                                    );
                                    onChange?.(nextValue);
                                }}
                            />
                            {isPcError && (
                                <div className='ant-form-explain merchant-banner-list-edit-error'>
                                    {language.getText('common.pleaseUpload')}
                                    {language.getText('businessCooperator.pcBanner')}
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item
                            className='merchant-banner-list-edit-form-item'
                            label={language.getText('businessCooperator.jumpLink')}
                            labelCol={{
                                span: 8,
                            }}
                            validateStatus={isTargetError ? 'error' : 'success'}
                        >
                            <Input
                                placeholder={language.getText('businessCooperator.linkPlaceholder')}
                                value={target}
                                onChange={(e) => {
                                    const nextValue = value.map((item, i) =>
                                        i === index ? { ...item, target: e.target.value } : item
                                    );
                                    onChange?.(nextValue);
                                }}
                            />
                            {isTargetError && (
                                <div className='ant-form-explain merchant-banner-list-edit-error'>
                                    {language.getText('businessCooperator.linkPlaceholder')}
                                </div>
                            )}
                        </Form.Item>
                        <div className='merchant-banner-list-edit-buttons'>
                            {value.length > 1 && (
                                <Button
                                    className='merchant-banner-list-edit-button'
                                    onClick={() => {
                                        const nextValue = value.filter((_, i) => i !== index);
                                        onChange?.(nextValue);
                                    }}
                                >
                                    {language.getText('common.delete')}
                                </Button>
                            )}
                            {value.length - 1 === index && value.length < 10 && (
                                <Button
                                    className='merchant-banner-list-edit-button'
                                    onClick={() => {
                                        const nextValue = [
                                            ...value,
                                            {
                                                mobile: undefined,
                                                pc: undefined,
                                                target: undefined,
                                            },
                                        ];
                                        onChange?.(nextValue);
                                    }}
                                >
                                    {language.getText('common.add')}
                                </Button>
                            )}
                        </div>
                    </Form>
                );
            })}
        </>
    );
};

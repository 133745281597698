import React, { Component } from 'react';
import {
    TimePicker as AntTimePicker,
    Select as AntSelect,
    InputNumber as AntInputNumber,
} from 'antd';
import moment from 'moment';
import { cloneDeep, range } from 'lodash';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { Weeks } from '../../interfaces';

const AntOption = AntSelect.Option;

interface TimeRangesPickerProps {
    ranges: Array<any>;
    format: string;
    name: string;
    maxRangesCount: number;
    minuteStep: number;
    allowEmpty: boolean;
    style: any;
    onChange(key: any, name: string): void;
    defaultStart: string;
    defaultEnd: string;
    value: any;
    singleNum: boolean;
}
interface RangesProps {
    startWeek: keyof typeof Weeks;
    endWeek: keyof typeof Weeks;
    startTime: string;
    endTime: string;
    quantity: number;
}
interface TimeRangesPickerStates {
    /**
     * 当前选中的营业时间
     */
    radioValue: number;

    /**
     * 当前选中的营业时间
     */
    location: 'right' | 'bottom';
}

/*
 * value格式：[
 *   {
 *       startWeek:''
 *       startTime: '',
 *       endTime: ''
 *       endWeek:'',
 *       checked: true,
 *       pickUpTime: [
 *          {
 *               startTime: '',
 *               endTime: '',
 *               quantity: 0,
 *           }
 *       ]
 *   }
 * ]
 * */
export class SelectTimeRangesPicker extends Component<
    TimeRangesPickerProps,
    TimeRangesPickerStates
> {
    ranges: Array<RangesProps> = [];
    defaultStartWeek: keyof typeof Weeks = 'MONDAY';
    defaultEndWeek: keyof typeof Weeks = 'SUNDAY';
    defaultStart = '10:00';
    defaultEnd = '22:00';
    defaultquantity = 0;
    format: string = this.props.format;
    weeksList: Array<any> = [];
    pickTimes: Array<any> = [];
    defaultQuantity = 99999;

    constructor(props: TimeRangesPickerProps) {
        super(props);
        let week: keyof typeof Weeks;
        for (week in Weeks) {
            this.weeksList.push({
                id: week,
                name: services.language.getText(Weeks[week]),
            });
        }
        this.state = {
            radioValue: 0,
            location: 'bottom',
        };

        // this.addTimePicker = this.addTimePicker.bind(this);
    }

    onRadioChange = (index: number, e: any) => {
        const { onChange, name } = this.props;
        this.setState({
            radioValue: e.target.value,
        });
        onChange(this.ranges, name);
    };

    /**
     *
     * 新增营业时间
     */
    addTimePicker(index: number) {
        const { onChange, name, maxRangesCount } = this.props;
        if (this.ranges.length < maxRangesCount) {
            this.ranges.push({
                startWeek: this.defaultStartWeek,
                endWeek: this.defaultEndWeek,
                startTime: this.defaultStart,
                endTime: this.defaultEnd,
                quantity: this.defaultquantity,
            });
            setTimeout(() => {
                this.setState({
                    radioValue: index + 1,
                });
            });
            onChange(this.ranges, name);
        }
    }
    deleteTimePicker(index: number) {
        const { onChange, name } = this.props;
        this.ranges.splice(index, 1);
        this.setState({
            radioValue: 0,
        });
        onChange(this.ranges, name);
    }

    /**
     *
     * 新增可提货时间段
     */

    onTimeChange(status: any, index: number, time: any, timeString: any) {
        const { onChange, name, value } = this.props;
        if (status === 'start') {
            if (timeString === '') {
                this.ranges[index].startTime = value[0].startTime;
            } else {
                this.ranges[index].startTime = timeString;
            }
            onChange(this.ranges, name);
        } else if (status === 'end') {
            if (timeString === '') {
                this.ranges[index].endTime = value[0].endTime;
            } else {
                this.ranges[index].endTime = timeString;
            }
            onChange(this.ranges, name);
        }
    }
    /**
     * 可提货时间
     */
    onPickUpNumChange(index: number, pickUpIndex: number, value: any) {
        const { onChange, name } = this.props;
        this.ranges[index].quantity = value;
        onChange(this.ranges, name);
    }

    changeWeeks(status: any, index: number, timeString: any) {
        const { onChange, name } = this.props;
        if (status === 'startWeek') {
            this.ranges[index].startWeek = timeString;
            onChange(this.ranges, name);
        } else if (status === 'endWeek') {
            this.ranges[index].endWeek = timeString;
            onChange(this.ranges, name);
        }
    }
    startDisabledHours(index: number, pickUpIndex?: number) {
        let endTime: string;
        // 营业时间
        endTime = this.ranges[index].endTime;
        return range(moment(endTime, this.format).hours() + 1, 24);
    }
    endDisabledHours(index: number, pickUpIndex?: number) {
        let startTime: string;
        // 营业时间
        startTime = this.ranges[index].startTime;
        return range(0, moment(startTime, this.format).hours());
    }
    startDisabledMinutes(index: number, pickUpIndex: number, selectedHour: any) {
        let endTime: string;
        // 营业时间
        endTime = this.ranges[index].endTime;
        const end = moment(endTime, this.format);
        return range(selectedHour === end.hours() ? end.minutes() + 1 : 60, 60);
    }
    endDisabledMinutes(index: number, pickUpIndex: number, selectedHour: any) {
        let startTime: string;
        // 营业时间
        startTime = this.ranges[index].startTime;
        const start = moment(startTime, this.format);
        return range(0, selectedHour === start.hours() ? start.minutes() : 0);
    }

    render() {
        const { value, maxRangesCount, format, singleNum } = this.props;
        if (value) {
            this.ranges = cloneDeep(value);
        }
        const props = {
            format,
            minuteStep: 1,
            allowEmpty: false,
            style: {
                width: '110px',
            },
        };

        return (
            <div className='time-ranges-picker'>
                {this.ranges.map((item, index) => {
                    // const timeQuantumTotal: number = item.storeChannelPicks.length;
                    return (
                        <div className='ranges-box' key={index}>
                            <div className='business-part'>
                                <AntSelect
                                    value={services.language.getText(Weeks[item.startWeek])}
                                    style={{ width: '110px' }}
                                    onChange={this.changeWeeks.bind(this, 'startWeek', index)}
                                >
                                    {this.weeksList.map((item, index) => (
                                        <AntOption key={index} value={item.id}>
                                            {item.name}
                                        </AntOption>
                                    ))}
                                </AntSelect>
                                <span style={{ margin: '0 10px' }}>
                                    {services.language.getText('to')}
                                </span>
                                <AntSelect
                                    value={services.language.getText(Weeks[item.endWeek])}
                                    style={{ width: '110px' }}
                                    onChange={this.changeWeeks.bind(this, 'endWeek', index)}
                                >
                                    {this.weeksList.map((item, index) => (
                                        <AntOption key={index} value={item.id}>
                                            {item.name}
                                        </AntOption>
                                    ))}
                                </AntSelect>
                                <span style={{ margin: '0 5px' }}></span>
                                <AntTimePicker
                                    {...props}
                                    value={moment(item.startTime, format)}
                                    onChange={this.onTimeChange.bind(this, 'start', index)}
                                    disabledHours={this.startDisabledHours.bind(this, index)}
                                    disabledMinutes={this.startDisabledMinutes.bind(this, index, 0)}
                                />
                                <span style={{ margin: '0 8px' }}>
                                    {services.language.getText('to')}
                                </span>

                                <AntTimePicker
                                    {...props}
                                    value={moment(item.endTime, format)}
                                    onChange={this.onTimeChange.bind(this, 'end', index)}
                                    disabledHours={this.endDisabledHours.bind(this, index)}
                                    disabledMinutes={this.endDisabledMinutes.bind(this, index, 0)}
                                />
                                {singleNum && (
                                    <>
                                        <AntInputNumber
                                            min={0}
                                            onChange={this.onPickUpNumChange.bind(
                                                this,
                                                index,
                                                index
                                            )}
                                            style={{
                                                margin: '0 8px',
                                                width: '80px',
                                            }}
                                            placeholder={services.language.getText(
                                                'defaultPlaceholderInput'
                                            )}
                                            value={item.quantity}
                                        />
                                        {services.language.getText('storeInfo.quantity')}
                                    </>
                                )}
                                <span style={{ margin: '0 8px' }}>
                                    {index + 1 < maxRangesCount &&
                                    index + 1 === this.ranges.length ? (
                                        <Button
                                            text={services.language.getText('add')}
                                            type='link'
                                            onClick={this.addTimePicker.bind(this, index)}
                                        />
                                    ) : null}
                                    {index > 0 &&
                                    index + 1 < maxRangesCount &&
                                    index + 1 === this.ranges.length ? (
                                        <span style={{ color: '#1890ff', margin: '0 5px' }}>|</span>
                                    ) : null}
                                    {index > 0 ? (
                                        <Button
                                            text={services.language.getText('delete')}
                                            type='link'
                                            onClick={this.deleteTimePicker.bind(this, index)}
                                        />
                                    ) : null}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

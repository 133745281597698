import { get, isEqual, reduce } from 'lodash';
import { connect } from 'react-redux';
import { Tabs } from '@comall-backend-builder/components-basis';

export class MemorableTabsComponent extends Tabs {
    static defaultProps = {
        cache: false,
        defaultActiveKey: '0',
    };

    constructor(props) {
        super(props);
        const { filters, defaultActiveKey } = props;
        const activeKey = get(filters, 'activeKey', defaultActiveKey);
        this.state = {
            activeKey,
        };
    }
    componentWillReceiveProps(nextProps) {
        const { filters } = nextProps;
        const { activeKey } = this.state;
        const nextActiveKey = get(filters, 'activeKey', activeKey);
        if (!isEqual(activeKey, nextActiveKey)) {
            this.setState({
                activeKey: nextActiveKey,
            });
        }
    }

    onChange = (key) => {
        const { entity, items } = this.props;
        const fields = reduce(
            entity.metainfo.filters,
            (result, filter, key) => {
                result[key] = undefined;
                return result;
            },
            {
                activeKey: key,
                ...items[key].params,
            }
        );
        entity.filtersChange(fields);
        entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
    };
}

const mapStateToProps = (state, ownProps) => {
    const filters = get(ownProps, 'entity.filters');
    return { filters };
};

export const MemorableTabs = connect(mapStateToProps)(MemorableTabsComponent);

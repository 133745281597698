import React, { Component, createElement } from 'react';
import { Tabs } from 'antd';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, getLanguageColumns } from '@/configs/mixin';
import { IMPORT_TEMPLATE_URL } from '@/constants';
import { OptionType } from '@/interfaces';
import { KV } from '@/interfaces';
import { recordImportLogs } from '@/services';
import {
    SHELF_KEYS,
    ON_SHELF_TABLE_COMPONENT_ID,
    OFF_SHELF_TABLE_COMPONENT_ID,
} from '../constants';
import { ON_SHELF_TABLE, OFF_SHELF_TABLE } from '@/configs/pro-table-constants';
import { StoreTreeSelector } from '@/components/store-tree-selector';
import { getStoreInfo } from '@/services/utils';

interface Props {
    entities: { [prop: string]: Entity };
    filterConfig: KV;
}

interface State {
    activeKey: string;
}

export class ProductShelfList extends Component<Props, State> {
    state = {
        activeKey: SHELF_KEYS.ON_SHELF_KEY,
    };

    /**
     * 当前 Entity
     */
    activeEntity: Entity = this.props.entities.productOnShelf;

    /**
     * 门店编码集合
     */
    onlineStoreCodes = '';

    onTabChange = (activeKey: string) => {
        if (activeKey === SHELF_KEYS.ON_SHELF_KEY) {
            this.activeEntity = this.props.entities.productOnShelf;
        }
        if (activeKey === SHELF_KEYS.OFF_SHELF_KEY) {
            this.activeEntity = this.props.entities.productOffShelf;
        }
        this.setState({ activeKey }, () => {
            this.refreshData();
        });
    };

    renderTab = () => {
        const { entities, filterConfig } = this.props;
        const { activeKey } = this.state;

        const createButtons = (type: 'ON_SHELF' | 'OFF_SHELF') => {
            const buttonTextMap = {
                ON_SHELF: {
                    id: SHELF_KEYS.OFF_SHELF_KEY,
                    name: services.language.getText('productShelf.importOffShelfFile'),
                    templateNameCsku: services.language.getText(
                        'productShelf.downloadTemplateCskuOffShelf'
                    ),
                    templateNameRsku: services.language.getText(
                        'productShelf.downloadTemplateRskuOffShelf'
                    ),
                    batchName: services.language.getText('productShelf.batchOffShelf'),
                    tableComponentId: ON_SHELF_TABLE_COMPONENT_ID,
                },
                OFF_SHELF: {
                    id: SHELF_KEYS.ON_SHELF_KEY,
                    name: services.language.getText('productShelf.importOnShelfFile'),
                    templateNameCsku: services.language.getText(
                        'productShelf.downloadTemplateCskuOnShelf'
                    ),
                    templateNameRsku: services.language.getText(
                        'productShelf.downloadTemplateRskuOnShelf'
                    ),
                    batchName: services.language.getText('productShelf.batchOnShelf'),
                    tableComponentId: OFF_SHELF_TABLE_COMPONENT_ID,
                },
            };
            return {
                component: 'FlexLayout',
                direction: 'horizontal',
                style: {
                    display: 'block',
                    marginBottom: 15,
                },
                items: [
                    {
                        component: 'ProductShelfButton',
                        text: buttonTextMap[type].batchName,
                        status: buttonTextMap[type].id,
                        tableComponentId: buttonTextMap[type].tableComponentId,
                        privilege: {
                            path: 'goods.productscroll.shelves',
                            level: 'full',
                        },
                    },
                    {
                        component: 'ExportButtonWithFilter',
                        text: services.language.getText('productShelf.exportProduct'),
                        icon: 'download',
                        style: {
                            marginRight: 15,
                            marginLeft: 15,
                        },
                        isAsync: true,
                        showSuccessTips: true,
                        showErrorMessage: true,
                        source: {
                            apiPath: '/dc-goods/admin/productscroll/export/take',
                        },
                        range: {
                            apiPath: '/dc-goods/admin/productscroll/count',
                            perPage: 100000,
                        },
                        formatter: (params: any) => {
                            const { brandId, ...rest } = params;
                            if (brandId) {
                                rest.brandId = brandId.map((item: any) => item.id).join(',');
                            }
                            return rest;
                        },
                        privilege: {
                            path: 'goods.productscroll.export_product',
                            level: 'full',
                        },
                    },
                    {
                        component: 'Upload',
                        apiPath: '/dc-file/excels?location_id=1',
                        showSuccessMessage: false,
                        className: 'upload-link',
                        triggerComponent: {
                            component: 'Button',
                            icon: 'upload',
                            text: `${buttonTextMap[type].name}`,
                            style: {
                                marginRight: 15,
                            },
                        },
                        onUploadComplete(response: any) {
                            if (response) {
                                recordImportLogs({
                                    name: '',
                                    apiPath: '/dc-goods/admin/productscroll/importData',
                                    params: {
                                        resourceId: response.id,
                                        operationType: buttonTextMap[type].id,
                                        onlineStoreId: getStoreInfo().id,
                                    },
                                });
                            }
                        },
                        privilege: {
                            path: 'goods.productscroll.import_product',
                            level: 'full',
                        },
                    },
                    {
                        component: 'OSSExportButton',
                        text: services.language.getText('productShelf.downloadTemplate'),
                        type: 'link',
                        url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.PRODUCT_SHELF}`,
                        style: {
                            marginBottom: 10,
                        },

                        privilege: {
                            path: 'goods.productscroll.download_template',
                            level: 'full',
                        },
                    },
                ],
            };
        };

        const createTable = (type: 'ON_SHELF' | 'OFF_SHELF') => {
            const buttonTextMap = {
                ON_SHELF: {
                    id: SHELF_KEYS.OFF_SHELF_KEY,
                    name: services.language.getText('productShelf.offShelf'),
                },
                OFF_SHELF: {
                    id: SHELF_KEYS.ON_SHELF_KEY,
                    name: services.language.getText('productShelf.onShelf'),
                },
            };
            const componentNameMap = {
                ON_SHELF: ON_SHELF_TABLE,
                OFF_SHELF: OFF_SHELF_TABLE,
            };
            //不展示原因(已上架)
            const shelfColumn =
                type === 'ON_SHELF'
                    ? [{ property: 'notShowReason', width: 300 }]
                    : [{ property: 'abnormalReason', width: 300 }]; //限制上架原因(已下架)
            return {
                ...tableMixin,
                component: 'ProTable',
                componentName: componentNameMap[type],
                loadFirstPage: false,
                rowSelection: {
                    type: 'checkbox',
                    onChange() {},
                },
                scroll: {
                    x: 'max-content',
                },
                columns: [
                    { property: 'onlineStoreName' },
                    ...getLanguageColumns('productNameLanguage', { width: 250 }),
                    { property: 'csku' },
                    { property: 'rsku' },
                    { property: 'isShowFront' }, //前端是否展示
                    ...shelfColumn,
                    ...getLanguageColumns('brandNameLanguage', { width: 250 }),
                    { property: 'categories' },
                    { property: 'symbol' },
                    { property: 'price' },
                    { property: 'sourceType' },
                    { property: 'stockQuantity' },
                    { property: 'safeQuantity' },
                    { property: 'orderQuantity' },
                    { property: 'availableStock' },
                ],
                actionColumn: {
                    title: services.language.getText('actions'),
                    fixed: 'right',
                    items: [
                        {
                            type: 'component',
                            component: 'ProductShelfTableActionButton',
                            text: buttonTextMap[type].name,
                            config: {
                                refreshData: this.refreshData,
                                status: buttonTextMap[type].id,
                                type: 'link',
                                style: {
                                    marginRight: 15,
                                },
                            },
                            privilege: {
                                path: 'goods.productscroll.shelves',
                                level: 'full',
                            },
                        },
                        {
                            text: services.language.getText('productShelf.viewLogs'),
                            type: 'component',
                            component: 'ProductShelfLogs',
                            config: {
                                entities,
                                type: 'link',
                            },
                            privilege: {
                                path: 'goods.productscroll.view_logs',
                                level: 'full',
                            },
                        },
                    ],
                },
            };
        };

        // 已上架配置
        const onShelfFilterComponent = createElement(
            ComponentsManager.get(filterConfig.component),
            {
                entity: entities.productOnShelf,
                ...filterConfig,
            }
        );

        const onShelfButtonConfig = createButtons('ON_SHELF');
        const onShelfButtonComponent = createElement(
            ComponentsManager.get(onShelfButtonConfig.component),
            {
                entity: entities.productOnShelf,
                ...onShelfButtonConfig,
            }
        );

        const onShelfTableConfig = createTable('ON_SHELF');
        const onShelfTableComponent = createElement(
            ComponentsManager.get(onShelfTableConfig.component),
            {
                entity: entities.productOnShelf,
                ...onShelfTableConfig,
                componentId: ON_SHELF_TABLE_COMPONENT_ID,
            }
        );

        // 已下架配置
        const offShelfFilterComponent = createElement(
            ComponentsManager.get(filterConfig.component),
            {
                entity: entities.productOffShelf,
                ...filterConfig,
            }
        );

        const offShelfButtonConfig = createButtons('OFF_SHELF');
        const offShelfButtonComponent = createElement(
            ComponentsManager.get(offShelfButtonConfig.component),
            {
                entity: entities.productOffShelf,
                ...offShelfButtonConfig,
            }
        );

        const offShelfTableConfig = createTable('OFF_SHELF');
        const offShelfTableComponent = createElement(
            ComponentsManager.get(offShelfTableConfig.component),
            {
                ...offShelfTableConfig,
                entity: entities.productOffShelf,
                componentId: OFF_SHELF_TABLE_COMPONENT_ID,
            }
        );

        return (
            <Tabs
                destroyInactiveTabPane
                animated={false}
                activeKey={activeKey}
                onChange={this.onTabChange}
            >
                <Tabs.TabPane
                    tab={services.language.getText('productShelf.alreadyOnShelf')}
                    key={SHELF_KEYS.ON_SHELF_KEY}
                >
                    {onShelfFilterComponent}
                    {onShelfButtonComponent}
                    {onShelfTableComponent}
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={services.language.getText('productShelf.alreadyOffShelf')}
                    key={SHELF_KEYS.OFF_SHELF_KEY}
                >
                    {offShelfFilterComponent}
                    {offShelfButtonComponent}
                    {offShelfTableComponent}
                </Tabs.TabPane>
            </Tabs>
        );
    };

    onChangeStore = (onlineStores: OptionType[]) => {
        const onlineStoreCodes = onlineStores.map((item) => item.id).join(',');
        if (!onlineStoreCodes) return;
        this.onlineStoreCodes = onlineStoreCodes;
        this.refreshData();
    };

    refreshData = () => {
        const { activeKey } = this.state;
        const activeEntity = this.activeEntity;
        activeEntity.filtersChange({
            ...activeEntity.filters,
            onlineStoreIds: this.onlineStoreCodes,
            onShelves: activeKey,
        } as any);
        activeEntity.pageChange({ page: 1 });
        activeEntity.search(activeEntity.params);
    };

    render() {
        return (
            <div className='product-shelf-list'>
                <div className='product-shelf-header' style={{ position: 'relative' }}>
                    <StoreTreeSelector
                        checkable={false}
                        selectable={true}
                        onChange={this.onChangeStore}
                    />
                </div>
                <div className='product-shelf-body'>{this.renderTab()}</div>
            </div>
        );
    }
}

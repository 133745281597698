import { Button, Input, message } from 'antd';
import React, { useState, useRef } from 'react';

import './index.less';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
import { ModalRef, QueryOrderModal } from './query-order-modal';
import { CodeModal } from './code-modal';
import { Privilege } from '@/components/privilege';

export interface DataItem {
    id: string;
    parentOrderNumber: string;
    merchantName: string;
    pickUpInfo: { firstName: string; lastName: string; mobile: string };
    thirdPartyCreateTime: string;
}

export const formatMobile = (phone: string) => {
    return phone.substring(phone.length - 4).padStart(8, '*');
};

export const SelfHarversting = () => {
    const [orderNumber, setOrderNumber] = useState<string>('');
    const [data, setData] = useState<DataItem[]>([]);
    const [mobile, setMobile] = useState<string>('');
    const [orderId, setOrderId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const queryModal = useRef<ModalRef>();
    const codeModal = useRef<ModalRef>();

    const search = async () => {
        if (orderNumber.length < 6) {
            message.error(services.language.getText('orderManagement.errorMsg'));
            return;
        }
        setLoading(true);
        try {
            const res: any = await services.api.get(
                {
                    organizationId: '1002',
                    orderStatus: 'DELIVERING',
                    outerOrderNumber: orderNumber,
                    page: 1,
                    perPage: 20,
                },
                {
                    apiPath: '/base-order/admin/order/find',
                }
            );
            if (isEmpty(res.result)) {
                message.error(services.language.getText('orderManagement.emptyMsg'));
                return;
            }
            queryModal.current?.open();
            setData(res.result);
        } finally {
            setLoading(false);
        }
    };

    const confirmReceipt = async (phone: string, id: string) => {
        setMobile(phone);
        codeModal.current?.open();
        setOrderId(id);
        try {
            await services.api.post(
                {},
                { apiPath: `/base-order/admin/order/${id}/send_verification` }
            );
        } catch (err) {
            message.error(services.language.getText('orderManagement.codeErrorMsg'));
        }
    };

    const resetValue = () => {
        setOrderNumber('');
    };

    return (
        <>
            <main className='self-view'>
                <h1 className='title'>{services.language.getText('orderManagement.pageTitle')}</h1>
                <h3 className='order-number'>
                    {services.language.getText('orderManagement.orderNumber')}:
                </h3>
                <Input
                    className='order-input'
                    placeholder={services.language.getText('orderManagement.placeholder')}
                    minLength={6}
                    value={orderNumber}
                    onChange={(e) => {
                        setOrderNumber(e.target.value); // 更新訂單號碼
                    }}
                />
                <Privilege path='order.self_pickup_view.self_pickup_order_view_list'>
                    <Button
                        type='primary'
                        onClick={() => search()}
                        className='submit-btn'
                        loading={loading}
                    >
                        {services.language.getText('orderManagement.queryOrder')}
                    </Button>
                </Privilege>
            </main>
            <QueryOrderModal ref={queryModal} data={data} confirmReceipt={confirmReceipt} />
            <CodeModal ref={codeModal} mobile={mobile} orderId={orderId} resetValue={resetValue} />
        </>
    );
};

import { StringTreeNodeCascaderMode } from '@comall-backend-builder/types';
import React from 'react';
import { Cascader } from 'antd';
import { each } from 'lodash';

export class CascaderShopMode extends StringTreeNodeCascaderMode {
    public getControlComponent(controlInfo: any) {
        const { options } = controlInfo;
        each(options, (item) => {
            item.value = item.id;
            item.lable = item.name;
        });
        return <Cascader options={options} />;
    }
}

/*
 * @Author: 朱璐
 * @Date: 2022-10-17 09:38:20
 * @Description: 会员资料基础信息
 */

import React, { useEffect } from 'react';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { message } from 'antd';

export const MembershipBaseInfo = (props: any) => {
    useEffect(() => {
        props.entity.get(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {React.createElement(ComponentsManager.get('ModifyFormPlus'), {
                ...props,
                fields: [
                    {
                        property: 'memberId',
                        modifiable: false,
                    },
                    {
                        property: 'phone',
                        modifiable: false,
                    },
                    {
                        property: 'surname',
                        modifiable: false,
                    },
                    {
                        property: 'name',
                        modifiable: false,
                    },
                    {
                        property: 'email',
                        modifiable: false,
                    },
                    {
                        property: 'birthday',
                        modifiable: false,
                    },
                    {
                        property: 'inviteCode',
                        modifiable: false,
                    },
                    {
                        property: 'memberNo',
                        modifiable: false,
                    },
                    {
                        property: 'familySize',
                        controlConfig: {
                            style: {
                                width: 300,
                            },
                        },
                    },
                    {
                        property: 'levelName',
                        modifiable: false,
                    },
                    {
                        property: 'storeName',
                        modifiable: false,
                    },
                    {
                        property: 'regTimeStr',
                        modifiable: false,
                    },
                    {
                        property: 'lastModifiedTime',
                        modifiable: false,
                    },
                    {
                        property: 'sourceSystem',
                        controlConfig: {
                            style: {
                                width: 300,
                            },
                            maxLength: 100,
                        },
                    },
                    {
                        property: 'source',
                        controlConfig: {
                            style: {
                                width: 300,
                            },
                            maxLength: 100,
                        },
                    },
                    {
                        property: 'checkEmail',
                        modifiable: false,
                    },
                    {
                        property: 'isPush',
                        modifiable: false,
                    },
                    {
                        property: 'point',
                        modifiable: false,
                    },
                    {
                        property: 'couponCount',
                        modifiable: false,
                    },
                    {
                        property: 'remark',
                        controlConfig: {
                            style: {
                                width: 300,
                            },
                            maxLength: 100,
                        },
                    },
                ],
                submit: true,
                onSubmitSuccess: () => {
                    message.success(services.language.getText('saveFully'));
                    services.navigation.goBack();
                },
            })}

            {React.createElement(ComponentsManager.get('MembershipOrderModal'), { ...props })}
        </>
    );
};

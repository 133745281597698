import React, { useState, useMemo, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Button, DatePicker, Form, InputNumber, message, Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import moment from 'moment';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { Privilege } from '@/components/privilege';
import { services } from '@comall-backend-builder/core';
import { PickingCard } from './base-content';
import { PrintButton } from '@/components/print';
import { PackageReceipt } from '@/components/print-template';
import { ORDER_STATUS, PATH_NAME } from '../../enums';
import * as pickingApi from '../../api';
import { DELIVERY_TYPE_VALUES } from '@/constants';
import { PackageTags } from '@/components/print-template/package-tags';
import { useGlobalConfig } from '@/utils/global-config';

interface IPackageButton extends ButtonProps {
    /**
     * 订单ID
     */
    ids: Array<string>;
    /**
     * 批量打包
     */
    batch?: boolean;
    /**
     * 单个打包需要输入日期
     *
     * @default true
     */
    inputDate?: boolean;
    /**
     * 打包前的拦截处理
     */
    onBeforeClick: () => Promise<any>;
    /**
     * 接口处理完成回调
     */
    onSuccess: (data: any) => void;
    /**
     * 接口类型
     */
    apiType: string;
}

/**
 * 打包按钮，支持多个订单
 */
export function PackageButton(props: IPackageButton) {
    const {
        ids,
        batch,
        inputDate = true,
        onBeforeClick,
        onSuccess,
        apiType,
        ...buttonProps
    } = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const defaultDate = useMemo(() => {
        const date = moment();
        // 默认加1天
        date.add(1, 'day');

        if (date.hours() >= 17) {
            // 大于等于17点加1天
            date.add(1, 'day');
        }
        if (date.isoWeekday() === 7) {
            // 周日加1天
            date.add(1, 'day');
        }

        return date.format('YYYY-MM-DD');
    }, [open]);
    const defaultFormValue = useMemo(
        () => ({
            /**
             * 常温商品数量
             */
            normalNumber: 0,
            /**
             * 冷冻商品数量
             */
            coldNumber: 0,
            /**
             * 预计送货日期
             */
            shipTime: defaultDate,
        }),
        [open]
    );
    const [formValue, setFormValue] = useState(defaultFormValue);
    useEffect(() => setFormValue(defaultFormValue), [defaultFormValue]);

    const cleanFloat = (v = 0) => +String(v || 0).replace(/\..*$/, '');

    const handleClick = async () => {
        try {
            // 修改状态
            setLoading(true);
            await pickingApi
                .changeOrderStatus({
                    ids,
                    status: ORDER_STATUS.PACKED,
                    apiType,
                    restParams: formValue,
                })
                .then(onSuccess)
                .finally(() => setLoading(false))
                .catch((...args) => {
                    errorHandle(...args);
                    return Promise.reject();
                });
            setOpen(false);
        } catch (e) {
            return;
        } finally {
            setLoading(false);
        }
    };

    const displayDate = !batch && inputDate;

    return (
        <>
            <Modal
                title={
                    displayDate
                        ? services.language.getText('picking.packageModal.title')
                        : services.language.getText('picking.packageModal.batchTitle')
                }
                visible={open}
                destroyOnClose
                okButtonProps={{
                    loading,
                }}
                onOk={handleClick}
                onCancel={() => setOpen(false)}
            >
                <Form
                    layout='horizontal'
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{ span: 12 }}
                >
                    <Form.Item label={services.language.getText('picking.packageModal.normal')}>
                        <InputNumber
                            min={0}
                            maxLength={5}
                            value={formValue.normalNumber}
                            onChange={(normalNumber) =>
                                setFormValue((v) => ({
                                    ...v,
                                    normalNumber: cleanFloat(normalNumber),
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item label={services.language.getText('picking.packageModal.freeze')}>
                        <InputNumber
                            min={0}
                            maxLength={5}
                            value={formValue.coldNumber}
                            onChange={(coldNumber) =>
                                setFormValue((v) => ({
                                    ...v,
                                    coldNumber: cleanFloat(coldNumber),
                                }))
                            }
                        />
                    </Form.Item>
                    {displayDate && (
                        <Form.Item label={services.language.getText('picking.packageModal.date')}>
                            <DatePicker
                                allowClear={false}
                                disabledDate={(current) => {
                                    // 今天到未来30天以内
                                    return (
                                        moment()
                                            .subtract(1, 'day')
                                            .isAfter(current) ||
                                        moment()
                                            .add(29, 'day')
                                            .isBefore(current)
                                    );
                                }}
                                value={moment(formValue.shipTime)}
                                onChange={(shipTime) =>
                                    setFormValue((v) => ({
                                        ...v,
                                        shipTime: shipTime?.format('YYYY-MM-DD') ?? defaultDate,
                                    }))
                                }
                            />
                        </Form.Item>
                    )}
                </Form>
            </Modal>
            <Button
                {...buttonProps}
                onClick={async () => {
                    await onBeforeClick();
                    setOpen(true);
                }}
            />
        </>
    );
}

PackageButton.defaultProps = {
    onBeforeClick: () => Promise.resolve(),
    onSuccess: () => {},
};

/**
 * 批量打包订单
 */
function BatchPackageButton(props: any) {
    const { selectedRowKeys, parentProps } = props;
    const onBeforeClick = () => {
        if (isEmpty(selectedRowKeys)) {
            message.warn(services.language.getText('picking.selectOrder'));
            return Promise.reject();
        }
        return Promise.resolve();
    };
    // 刷新数据
    const successHandler = () => {
        parentProps.refresh();
    };
    if (isEmpty(parentProps.result)) {
        return null;
    }
    return (
        <PackageButton
            type='primary'
            batch
            ids={selectedRowKeys}
            onBeforeClick={onBeforeClick}
            onSuccess={successHandler}
            apiType={parentProps.apiType.order}
        >
            {services.language.getText('picking.packageButton')}
        </PackageButton>
    );
}

/**
 * 待打包批量打印打包单
 */
export function BatchPrintPackageButton(props: any) {
    const { selectedRowKeys, parentProps } = props;
    const [loading, setLoading] = useState(false);
    const [receiptInfo, setReceiptInfo] = useState<any>([]);
    const [{ storeLogos }, setGlobalConfig] = useGlobalConfig();

    function loadLogo(onlineStoreId: string) {
        const storeLogo = storeLogos.find((item) => item.onlineStoreId === onlineStoreId) || {
            loading: false,
            onlineStoreId,
            logo: '',
        };

        if (!storeLogo.logo && !storeLogo.loading) {
            storeLogo.loading = true;
            setGlobalConfig({
                storeLogos: [
                    ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                    storeLogo,
                ],
            });

            return services.api
                .get(
                    {},
                    {
                        apiPath: `/dc-store/admin/onlineStore/findById/${onlineStoreId}`,
                    }
                )
                .then((res: any) => {
                    storeLogo.loading = false;
                    storeLogo.logo = res.logo;
                    setGlobalConfig({
                        storeLogos: [
                            ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                            storeLogo,
                        ],
                    });
                })
                .catch(services.errorHandle);
        }
    }

    const printHandler = (printRef: any) => {
        if (isEmpty(selectedRowKeys)) {
            message.warn(services.language.getText('picking.selectOrder'));
            return;
        }
        setLoading(true);
        // 获取打印数据
        pickingApi
            .fetchPrintPickingList(selectedRowKeys, parentProps.apiType.picking)
            .then(async (receiptData) => {
                setReceiptInfo(receiptData);
                await Promise.all(
                    Array.from<string>(
                        new Set(receiptData.map((item: any) => item.store?.id).filter(Boolean))
                    )
                        .map(loadLogo)
                        .filter(Boolean)
                );
                await printRef.current.onPrint();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <PrintButton
            style={{
                marginLeft: 10,
            }}
            template={receiptInfo.map((item: any, index: string) => {
                return <PackageReceipt key={index} data={item} />;
            })}
            loading={loading}
            onClick={printHandler}
        >
            {services.language.getText('picking.printPackagings')}
        </PrintButton>
    );
}

export function WaitPackageBatchActions(props: any) {
    if (isEmpty(props.parentProps.result)) {
        return null;
    }
    return (
        <>
            <Privilege
                path={
                    props.parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                        ? 'picking.assistant.batch_packaging_complete'
                        : 'picking_philips.assistant_philips.batch_packaging_complete_philips'
                }
            >
                <BatchPackageButton {...props} />
            </Privilege>
            <Privilege path='picking.assistant.batch_print_packaging'>
                <BatchPrintPackageButton {...props} />
            </Privilege>
            {/* <Privilege path='picking.assistant.export_wait_packaging'>
                <ExportWaitPackageOrder {...props} />
            </Privilege> */}
        </>
    );
}

/**
 * 批量打印箱面纸
 */
function BatchPrintPackageTags(props: AnyObject) {
    const { selectedRowKeys, parentProps } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [receiptInfos, setReceiptInfos] = useState<AnyObject[][]>([]);

    // 打印标签
    const printLabel = async (printRef: AnyObject) => {
        try {
            if (isEmpty(selectedRowKeys)) {
                message.warn(services.language.getText('picking.selectOrder'));
                return;
            }
            setLoading(true);
            const result = await pickingApi.fetchPrintPackList(
                selectedRowKeys,
                parentProps.apiType.picking
            );
            setReceiptInfos(result);
            await printRef.current.onPrint();
        } finally {
            setLoading(false);
        }
    };

    return (
        <PrintButton
            loading={loading}
            template={
                <>
                    {receiptInfos.map(({ printList }: AnyObject, index) => (
                        <PackageTags key={index} data={printList} />
                    ))}
                </>
            }
            onClick={printLabel}
        >
            {services.language.getText('picking.batchPrintTags')}
        </PrintButton>
    );
}

export function WaitPickupBatchActions(props: any) {
    if (isEmpty(props.parentProps.result)) {
        return null;
    }
    return (
        <>
            <Privilege path='picking.assistant.print_pack_list'>
                <BatchPrintPackageTags {...props} />
            </Privilege>
        </>
    );
}

/**
 * 待打包数据展示组件
 */

export function WaitPackageItem(props: any) {
    const { id, showPacked, parentProps } = props;
    const [loading, setLoading] = useState(false);
    const [receiptInfo, setReceiptInfo] = useState<any>([]);
    const [{ storeLogos }, setGlobalConfig] = useGlobalConfig();

    function loadLogo(onlineStoreId: string) {
        const storeLogo = storeLogos.find((item) => item.onlineStoreId === onlineStoreId) || {
            loading: false,
            onlineStoreId,
            logo: '',
        };

        if (!storeLogo.logo && !storeLogo.loading) {
            storeLogo.loading = true;
            setGlobalConfig({
                storeLogos: [
                    ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                    storeLogo,
                ],
            });

            return services.api
                .get(
                    {},
                    {
                        apiPath: `/dc-store/admin/onlineStore/findById/${onlineStoreId}`,
                    }
                )
                .then((res: any) => {
                    storeLogo.loading = false;
                    storeLogo.logo = res.logo;
                    setGlobalConfig({
                        storeLogos: [
                            ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                            storeLogo,
                        ],
                    });
                })
                .catch(services.errorHandle);
        }
    }

    const printHandler = (printRef: any) => {
        setLoading(true);
        // 获取打印数据
        pickingApi
            .fetchPrintPickingList([id], parentProps.apiType.picking)
            .then(async (receiptData) => {
                setReceiptInfo(receiptData);
                await Promise.all(
                    Array.from<string>(
                        new Set(receiptData.map((item: any) => item.store?.id).filter(Boolean))
                    )
                        .map(loadLogo)
                        .filter(Boolean)
                );
                await printRef.current.onPrint();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // 刷新数据
    const successHandler = () => {
        parentProps.refresh();
    };

    return (
        <PickingCard
            data={props}
            type='order'
            actions={
                <>
                    {showPacked && (
                        <>
                            <Privilege
                                path={
                                    parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                                        ? 'picking.assistant.packaging_complete'
                                        : 'picking_philips.assistant_philips.packaging_complete_philips'
                                }
                            >
                                <PackageButton
                                    onSuccess={successHandler}
                                    type='primary'
                                    ids={[id]}
                                    inputDate={
                                        props.delivery.deliveryType ===
                                        DELIVERY_TYPE_VALUES.DELIVERY
                                    }
                                    apiType={parentProps.apiType.order}
                                >
                                    {services.language.getText('picking.packagingComplete')}
                                </PackageButton>
                            </Privilege>{' '}
                        </>
                    )}
                    <Privilege
                        path={
                            parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                                ? 'picking.assistant.print_packaging'
                                : 'picking_philips.assistant_philips.print_packaging_philips'
                        }
                    >
                        <PrintButton
                            onClick={printHandler}
                            template={receiptInfo.map((item: any, index: string) => {
                                return <PackageReceipt key={index} data={item} />;
                            })}
                            loading={loading}
                        >
                            {services.language.getText('picking.printPackaging')}
                        </PrintButton>
                    </Privilege>
                </>
            }
            orderPathname={parentProps.orderPathname}
            pickPathname={parentProps.pickPathname}
        />
    );
}

import {
    tableMixin,
    flexibleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
    formFooter,
} from '@/configs/mixin';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { PRODUCT_INVENTORY_TABLE_PRODUCT } from '@/configs/pro-table-constants';
import { SPECS_TYPE_OPTIONS, IMPORT_TEMPLATE_URL, PRODUCT_SOURCE_TYPE_OPTIONS } from '@/constants';
import { services } from '@comall-backend-builder/core';
import { recordImportLogs } from '@/services';
import { getStoreInfo } from '@/services/utils';

export const config: Config = {
    entities: {
        productInventory: {
            apiPath: '/dc-stock/admin/rskus/stock/manage/findAllByLoginUser',
            properties: {
                id: { type: 'string' },
                productLanguageName: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties('string', 'productInventory.productName'),
                    },
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                },
                goodsId: {
                    type: 'string',
                    displayName: 'Goods ID',
                },
                specsType: {
                    type: 'string',
                    displayName: '<<productInventory.specsType>>',
                },
                source: {
                    type: 'string',
                    displayName: '<<productInventory.sourceType>>',
                },
                stockQuantity: {
                    type: 'string',
                    displayName: '<<productInventory.existQuantity>>',
                },
                safeQuantity: {
                    type: 'string',
                    displayName: '<<productInventory.safeQuantity>>',
                },
                warnQuantity: {
                    type: 'string',
                    displayName: '<<productInventory.warnQuantity>>',
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<productInventory.lastedModifyTime>>',
                },
            },
            filters: {
                searchCode: {
                    type: 'string',
                    displayName: '<<productInventory.keyWords>>',
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                    controlConfig: {
                        placeholder: '<<cskuPlaceHolder>>',
                    },
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productInventory.specsType>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                source: {
                    type: 'string.options.select',
                    displayName: '<<productInventory.sourceType>>',
                    options: PRODUCT_SOURCE_TYPE_OPTIONS,
                },
                merchantId: {
                    type: 'string.options.select',
                    displayName: '<<productList.merchantName>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant/findMerchantList',
                    },
                },
            },
        },
        productInventoryEdit: {
            apiPath: '/loader/dc-stock/admin/rskus/stock/edit',
            properties: {
                id: { type: 'string' },
                productLanguageName: {
                    type: 'object.subForm',
                    properties: {
                        ...getLanguageProperties('string', 'productInventory.productName'),
                    },
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                    modifiable: false,
                },
                rsku: {
                    type: 'string',
                    displayName: 'RSKU',
                    modifiable: false,
                },
                goodsId: {
                    type: 'string',
                    displayName: 'Goods ID',
                    modifiable: false,
                },
                specsType: {
                    type: 'string',
                    displayName: '<<productInventory.specsType>>',
                    modifiable: false,
                },
                source: {
                    type: 'string',
                    displayName: '<<productInventory.sourceType>>',
                    modifiable: false,
                },
                orderQuantity: {
                    type: 'number.integer',
                    displayName: '<<productInventory.reserveQuantity>>',
                },
                safeQuantity: {
                    type: 'number.integer',
                    displayName: '<<productInventory.safeQuantity>>',
                    controlConfig: {
                        max: 99999999,
                        min: 0,
                    },
                },
                warnQuantity: {
                    type: 'number.integer',
                    displayName: '<<productInventory.warnQuantity>>',
                    controlConfig: {
                        max: 99999999,
                        min: 0,
                    },
                },
                warrantyPeriod: {
                    type: 'number.tip',
                    displayName: '<<productInventory.warrantyPeriod>>',
                    defaultValue: 0,
                    controlConfig: {
                        addonbefore: '<<productInventory.nearOverdue>>',
                        addonafter: '<<days>>',
                        placeholder: '<<defaultPlaceholderInput>>',
                        max: 99999999,
                    },
                },
                remark: {
                    type: 'string',
                    displayName: '<<productInventory.remark>>',
                    controlConfig: { maxLength: 100 },
                },
            },
        },
        productInventoryChangeLog: {
            apiPath: '/loader/dc-stock/admin/rskus/stock/findStockChangeLog',
            properties: {
                batchNo: {
                    type: 'string',
                    displayName: '<<productInventory.batchNo>>',
                },
                changeType: {
                    type: 'string',
                    displayName: '<<productInventory.changeType>>',
                },
                changeQuantity: {
                    type: 'string',
                    displayName: '<<productInventory.changeQuantity>>',
                },
                changeReason: {
                    type: 'string',
                    displayName: '<<productInventory.changeReason>>',
                },
                changeTime: {
                    type: 'string',
                    displayName: '<<productInventory.changeTime>>',
                },
                operator: {
                    type: 'string',
                    displayName: '<<productInventory.operator>>',
                },
            },
        },
        productInventoryChangeLogDetail: {
            apiPath: '/loader/dc-stock/admin/rskus/stock/findStockChangeLogInfo',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<productInventory.productName>>',
                },
                csku: {
                    type: 'string',
                    displayName: 'SKU',
                },
            },
        },
    },
    components: {
        ProductInventoryView: {
            component: 'Viewport',
        },
        ProductInventory: {
            component: 'FlexLayout',
            entity: 'productInventory',
            direction: 'vertical',
            className: 'product-inventory-view',
            items: [
                {
                    component: 'ProductPriceList',
                    params: {},
                    filterConfig: {
                        component: 'ProductInventoryFilter',
                        style: { marginBottom: 15 },
                        privilege: {
                            path: 'goods.goods_stock.view_list',
                            level: 'full',
                        },
                    },
                    buttonConfig: {
                        component: 'ProductInventoryButtons',
                        style: { marginBottom: 15 },
                    },
                    tableConfig: {
                        component: 'ProductInventoryTables',
                        privilege: {
                            path: 'goods.goods_stock.view_list',
                            level: 'full',
                        },
                    },
                },
            ],
        },
        ProductInventoryFilter: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'searchCode',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<productInventory.keyWordsPlaceHolder>>',
                    },
                },
                { property: 'csku' },
                {
                    property: 'specsType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'source',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'merchantId',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            ],
        },
        ProductInventoryButtons: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    showErrorMessage: true,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<productInventory.importSupplement>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText(
                                    'productInventory.importSupplement'
                                ),
                                apiPath: `/dc-stock/admin/rskus/stock/import/stock`,
                                params: {
                                    resourceId: response.id,
                                    onlineStoreId: getStoreInfo().id,
                                },
                            });
                        }
                    },
                    privilege: {
                        path: 'goods.goods_stock.import_goods_replenishment_stock',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productInventory.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 20,
                        marginRight: 20,
                        alignSelf: 'center',
                    },
                    privilege: {
                        path: 'goods.goods_stock.download_import_template',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.PRODUCT_STOCK}`,
                },
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    showErrorMessage: true,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<productInventory.updateSpotInventory>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText(
                                    'productInventory.importSupplement'
                                ),
                                apiPath: `/dc-stock/admin/rskus/stock/import/mcsStock`,
                                params: {
                                    resourceId: response.id,
                                    onlineStoreId: getStoreInfo().id,
                                },
                            });
                        }
                    },
                    privilege: {
                        path: 'goods.goods_stock.import_mcs_stock',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productInventory.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 20,
                        alignSelf: 'center',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.MCS_STOCK}`,
                    privilege: {
                        path: 'goods.goods_stock.download_import_template',
                        level: 'full',
                    },
                },
            ],
        },
        ProductInventoryTables: {
            ...tableMixin,
            component: 'ProTable',
            loadFirstPage: false,
            componentName: PRODUCT_INVENTORY_TABLE_PRODUCT,
            className: 'product-inventory-table',
            columns: [
                ...getLanguageColumns('productLanguageName', { width: 200 }),
                { property: 'csku', width: '140' },
                { property: 'goodsId', width: '140' },
                { property: 'specsType', width: '140' },
                { property: 'stockQuantity', width: '140' },
                { property: 'safeQuantity', width: '140' },
                { property: 'warnQuantity', width: '140' },
                { property: 'source', width: '140' },
            ],
            actionColumn: {
                title: '<<actions>>',
                width: 300,
                fixed: 'right',
                items: [
                    {
                        text: '<<productInventory.changeLog>>',
                        type: 'link',
                        path: '/product-inventory/changeLog/{{row.mcsStockId}}/{{row.csku}}',
                        privilege: {
                            path: 'goods.goods_stock.stock_change_log',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/product-inventory/edit/{{row.storeId}}/{{row.csku}}/{{row.rsku}}',
                        privilege: {
                            path: 'goods.goods_stock.edit_category_safe_stock',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<productInventory.deductions>>',
                        type: 'link',
                        path:
                            '/product-inventory/deductions/{{row.storeId}}/{{row.csku}}/{{row.rsku}}',
                        privilege: {
                            path: 'goods.goods_stock.sub_stock',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<productInventory.supplement>>',
                        type: 'link',
                        path:
                            '/product-inventory/supplement/{{row.storeId}}/{{row.csku}}/{{row.rsku}}',
                        privilege: {
                            path: 'goods.goods_stock.add_stock',
                            level: 'full',
                        },
                    },
                ],
            },
        },

        ChangeLogTables: {
            ...tableMixin,
            entity: 'productInventoryChangeLog',
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20', '100'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('table.total'), {
                        total,
                    });
                },
            },
            className: 'product-inventory-table',
            columns: [
                { property: 'batchNo', width: '140' },
                { property: 'changeType', width: '140' },
                { property: 'changeQuantity', width: '140' },
                { property: 'changeReason', width: '140' },
                { property: 'changeTime', width: '140' },
                { property: 'operator', width: '140' },
            ],
        },
        /**
         * 变动日志
         */
        ProductInventoryChangeLogPage: {
            component: 'Card',
            entities: [
                {
                    name: 'productInventoryChangeLog',
                    entityName: 'productInventoryChangeLog',
                },
                {
                    name: 'productInventoryChangeLogDetail',
                    entityName: 'productInventoryChangeLogDetail',
                },
            ],
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    { component: 'ProductInventoryLogDetail' },
                    { component: 'ChangeLogTables' },
                ],
            },
        },
        ProductInventoryLogDetail: {
            component: 'Detail',
            entity: 'productInventoryChangeLogDetail',
            loaderType: 'get',
            direction: 'inline',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'csku',
                },
            ],
        },
        /**
         * 编辑
         */
        ProductInventoryEditPage: {
            component: 'Card',
            content: {
                component: 'ProductInventoryEditForm',
            },
        },
        ProductInventoryEditForm: {
            component: 'ModifyFormPlus',
            className: 'sub-form',
            loaderType: 'get',
            entity: 'productInventoryEdit',
            labelCol: 4,
            controlCol: 10,
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                ...getLanguageColumns('productLanguageName', { modifiable: false }),
                { property: 'csku' },
                { property: 'goodsId' },
                { property: 'specsType' },
                { property: 'source' },
                { property: 'safeQuantity' },
                { property: 'warnQuantity' },
                { property: 'warrantyPeriod' },
                { property: 'remark' },
                { property: 'rsku', className: 'hidden' },
            ],
        },
        /**
         * 补充
         */
        ProductInventorySupplementPage: {
            component: 'Card',
            content: {
                component: 'ProductInventorySupplementForm',
            },
        },
        /**
         * 扣减
         */
        ProductInventoryDeductionsPage: {
            component: 'Card',
            content: {
                component: 'ProductInventoryDeductionsForm',
            },
        },
    },
};

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';

export class ProductErrorInfoTableMode extends modes.ObjectMode {
    getDisplayComponent(value, displayInfo) {
        const { reasons } = value;
        const { style } = displayInfo;
        if (reasons && reasons.length) {
            let reasonsString = services.language.getText('productInfo.lack') + reasons.join('、');
            return <div style={style}>{reasonsString}</div>;
        } else {
            return <div> - </div>;
        }
    }
}

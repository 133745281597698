import { IMPORT_TEMPLATE_URL, PRODUCT_SOURCE_TYPE_OPTIONS, SPECS_TYPE_OPTIONS } from '@/constants';
import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    flexibleFilterForm,
    getLanguageColumns,
    getLanguageProperties,
    tableMixin,
} from '../mixin';
import { recordImportLogs } from '@/services';
import { services } from '@comall-backend-builder/core';
import { RECOMMEND_PRODUCT_TABLE } from '../pro-table-constants';

export const config: Config = {
    entities: {
        recommendProduct: {
            apiPath: '/loader/dc-goods/admin/recommend',
            properties: {
                sequence: {
                    type: 'string',
                    displayName: '<<productList.sequence>>',
                },
                pictureUrl: {
                    type: 'string.image.text',
                    displayName: '<<productList.heroImagePath>>',
                },
                specsType: {
                    type: 'string.options.radio',
                    displayName: '<<productList.specsType>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                modeType: {
                    type: 'string',
                    displayName: '<<productList.modeType>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<productList.merchantName>>',
                },
                salesChannels: {
                    type: 'string',
                    displayName: '<<productList.salesChannels>>',
                },
                weight: {
                    type: 'string',
                    displayName: '<<productList.productWeight>>',
                },
                volume: {
                    type: 'string',
                    displayName: '<<productList.productVolume>>',
                },
                name: {
                    type: 'object.subForm',
                    properties: { ...getLanguageProperties('string', 'productList.productName') },
                },
                csku: {
                    type: 'string',
                    displayName: '<<productList.csku>>',
                },
                barcode: {
                    type: 'string',
                    displayName: '<<productList.barCode>>',
                },
                brandName: {
                    type: 'object.subForm',
                    properties: { ...getLanguageProperties('string', 'productList.brandName') },
                },
                offlineCategoryName: {
                    type: 'string.format',
                    displayName: '<<productList.saleCategory>>',
                },
                sourceType: {
                    type: 'string.options.select',
                    displayName: '<<productList.productSource>>',
                    options: PRODUCT_SOURCE_TYPE_OPTIONS,
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<keyword>>',
                },
                cskus: {
                    type: 'string',
                    displayName: '<<productList.csku>>',
                },
                specsType: {
                    type: 'string.options.select',
                    displayName: '<<productList.specsType>>',
                    options: SPECS_TYPE_OPTIONS,
                },
                sourceType: {
                    type: 'string.options.select',
                    displayName: '<<productList.productSource>>',
                    options: PRODUCT_SOURCE_TYPE_OPTIONS,
                },
            },
        },
    },
};

export function createRecommendProductComponents(componentName: string, privilege: string) {
    return {
        [`${componentName}RecommendProductPage`]: {
            entity: 'recommendProduct',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: `${componentName}RecommendProductFilter`,
                },
                {
                    component: `${componentName}RecommendProductButtons`,
                },
                {
                    component: `${componentName}RecommendProductTable`,
                },
            ],
            privilege: {
                path: `${privilege}.view_recommend_sku_list`,
                level: 'full',
            },
        },

        [`${componentName}RecommendProductFilter`]: {
            ...flexibleFilterForm,
            submit: {
                text: '<<query>>',
            },
            reset: true,
            style: {
                marginBottom: '15px',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<productList.keywordPlaceholder>>',
                    },
                },
                {
                    property: 'cskus',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<productList.cskuPlaceHolder>>',
                    },
                },
                {
                    property: 'specsType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'sourceType',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            ],
        },

        [`${componentName}RecommendProductButtons`]: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Upload',
                    apiPath: '/dc-file/excels?location_id=2',
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<productList.importProductList>>',
                        style: {
                            marginRight: 15,
                        },
                    },
                    onUploadComplete(response: any) {
                        if (response) {
                            const [
                                ,
                                recommendType,
                                recommendSourceId,
                            ] = window.location.hash.replace(/\/$/, '').match(/([^/]+)\/([^/]+)$/)!;
                            recordImportLogs({
                                name: services.language.getText('productList.productInfo'),
                                apiPath: '/dc-goods/admin/recommend/:recommendType/import',
                                params: {
                                    resourceId: response.id,
                                    recommendType,
                                    recommendSourceId,
                                },
                            });
                        }
                    },
                    privilege: {
                        path: `${privilege}.import_recommend_sku`,
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productList.downloadTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 20,
                        alignSelf: 'center',
                    },
                    privilege: {
                        path: `${privilege}.import_recommend_sku`,
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.RECOMMEND_PRODUCT}`,
                },
            ],
        },

        [`${componentName}RecommendProductTable`]: {
            ...tableMixin,
            componentName: RECOMMEND_PRODUCT_TABLE,
            component: 'ProTable',
            scroll: {
                x: 'max-content',
            },
            columns: [
                { property: 'sequence' },
                {
                    property: 'pictureUrl',
                    width: 150,
                    displayConfig: {
                        style: {
                            width: 50,
                            height: 50,
                            border: '1px solid #eee',
                        },
                    },
                },
                { property: 'csku' },
                { property: 'barcode', show: false },
                ...getLanguageColumns('name'),
                ...getLanguageColumns('brandName'),
                {
                    property: 'offlineCategoryName',
                    displayConfig: { format: (val: any[]) => (val || []).join('/') },
                },
                { property: 'sourceType' },
                { property: 'weight' },
                { property: 'volume' },
                { property: 'specsType' },
                { property: 'modeType' },
                { property: 'merchantName' },
                { property: 'salesChannels' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<delete>>',
                        config: {
                            actionType: 'delete',
                            component: 'TableActionToggleStatus',
                            source: {
                                apiPath: '/dc-goods/admin/recommend/:recommendType',
                            },
                            deleteConfig: {
                                params: {
                                    recommendType: '{{entity.params.recommendType}}',
                                },
                            },
                        },
                        privilege: {
                            path: `${privilege}.delete_recommend_sku`,
                            level: 'full',
                        },
                    },
                ],
            },
        },
    };
}

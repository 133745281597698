import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';

interface State {}
class LocationSelect extends Component<any, State> {
    state = {};
    render() {
        return (
            <>
                <div>
                    <span>SAP授权MCS Location：</span>
                    <span>xxxx</span>
                </div>
                <div>
                    <span>库存来源MCS Location：</span>
                    <span>xxxx</span>
                </div>
            </>
        );
    }
}

/**
 * 门店渠道选择
 */
export class LocationSelectMode extends modes.ArrayMode {
    getControlComponent(controlInfo: any) {
        return <LocationSelect {...controlInfo} />;
    }
}

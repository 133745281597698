import React, { useState } from 'react';
import { connect } from 'react-redux';
import { trim } from 'lodash';
import {
    Card as AntCard,
    Row as AntRow,
    Col as AntCol,
    Modal as AntModal,
    Input as AntInput,
    message as AntMessage,
    Descriptions,
    Table as AntTable,
    Button as AntButton,
} from 'antd';
import {
    // ComponentsManager,
    services,
} from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { OrderStatusBadge } from '@/components/order-status-badge';
import { Countdown } from '@/components/countdown';
import { Privilege } from '@/components/privilege';
import { PrintButton } from '@/components/print';
import { OrderReceipt } from '@/components/print-template';
import {
    DELIVERY_TYPE_NAMES,
    DELIVERY_TYPE_VALUES,
    ORDER_STATUS_NAMES,
    ORDER_STATUS_ALL_VALUES,
} from '@/constants/order';
import { formatUrl } from '@/constants/order/utils';
import { API_MAP } from '@/constants/order/api-map';
import { ORDER_TYPE } from '@/constants/order/enum';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import { useGlobalConfig } from '@/utils/global-config';

const OrderBasic: React.FC<any> = (props) => {
    const [showRemarkModal, setRemarkModal] = useState(false);
    const [remarkValue, setRemarkValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [orderPrice, setOrderPrice] = useState(undefined);
    const [couponListloading, setCouponeListLoading] = useState(false);
    const [{ storeLogos }, setGlobalConfig] = useGlobalConfig();

    const getTitle = () => {
        return (
            <div className='info-title'>
                <OrderStatusBadge
                    orderStatus={props.orderStatus}
                    outerOrderNumber={props.outerOrderNumber}
                    fill={true}
                />
                <label className='order-number'>
                    {services.language.getText('order.parentOrderNumber')}：{' '}
                    {props.parentOrderNumber ?? '-'}
                </label>
                <label className='order-number'>
                    {services.language.getText('order.outerOrderNumber')}：{' '}
                    {props.outerOrderNumber ?? '-'}
                </label>
            </div>
        );
    };

    const getExtra = () => {
        const handleOk = () => {
            if (!remarkValue) {
                AntMessage.error(services.language.getText('order.orderRemarkTips'));
                return;
            }
            setLoading(true);
            services.api
                .post(
                    {
                        content: trim(remarkValue),
                        id: props.id,
                    },
                    {
                        apiPath: formatUrl(API_MAP.ORDER_REMARK, props.config.type),
                    }
                )
                .then(() => {
                    AntMessage.success(services.language.getText('order.orderRemarkTipsSucc'));
                    props.entity.get(props.params);
                })
                .catch(services.errorHandle)
                .finally(() => {
                    setRemarkModal(false);
                    setLoading(false);
                    setRemarkValue('');
                });
        };

        function loadLogo(onlineStoreId: string) {
            const storeLogo = storeLogos.find((item) => item.onlineStoreId === onlineStoreId) || {
                loading: false,
                onlineStoreId,
                logo: '',
            };

            if (!storeLogo.logo && !storeLogo.loading) {
                storeLogo.loading = true;
                setGlobalConfig({
                    storeLogos: [
                        ...storeLogos.filter((item) => item.onlineStoreId !== onlineStoreId),
                        storeLogo,
                    ],
                });

                return services.api
                    .get(
                        {},
                        {
                            apiPath: `/dc-store/admin/onlineStore/findById/${onlineStoreId}`,
                        }
                    )
                    .then((res: any) => {
                        storeLogo.loading = false;
                        storeLogo.logo = res.logo;
                        setGlobalConfig({
                            storeLogos: [
                                ...storeLogos.filter(
                                    (item) => item.onlineStoreId !== onlineStoreId
                                ),
                                storeLogo,
                            ],
                        });
                    })
                    .catch(services.errorHandle);
            }
        }

        const printClick = (printRef: any) => {
            setLoading(true);

            services.api
                .get(
                    {
                        outerOrderNumber: props.outerOrderNumber,
                    },
                    {
                        apiPath: formatUrl('/admin/order/orderPrint', props.config.type),
                    }
                )
                .then(async (res: any) => {
                    setOrderPrice(res);
                    if (res.store?.id) {
                        await loadLogo(res.store.id);
                    }
                    await printRef.current.onPrint();
                })
                .catch(services.errorHandle)
                .finally(() => {
                    setLoading(false);
                });
        };

        return (
            <div className='info-extra-action'>
                <Privilege
                    path={
                        props.config.type === ORDER_TYPE.BASE
                            ? 'order.order.print_order'
                            : 'order_philips.order_philips.print_order_philips'
                    }
                >
                    <PrintButton
                        type='primary'
                        onClick={printClick}
                        template={
                            <OrderReceipt
                                data={{ ...((orderPrice ?? {}) as AnyObject), ...props }}
                                orderPrice={orderPrice}
                            />
                        }
                    >
                        {services.language.getText('order.printOrder')}
                    </PrintButton>
                </Privilege>
                <Privilege
                    path={
                        props.config.type === ORDER_TYPE.BASE
                            ? 'order.order.add_remark'
                            : 'order_philips.order_philips.add_remark_philips'
                    }
                >
                    {(hasPrivilege) => {
                        if (hasPrivilege) {
                            return (
                                <>
                                    <Button
                                        onClick={() => {
                                            setRemarkModal(true);
                                        }}
                                    >
                                        {services.language.getText('order.orderRemarkTitle')}
                                    </Button>
                                    <AntModal
                                        title={services.language.getText('order.orderRemarkTitle')}
                                        visible={showRemarkModal}
                                        onOk={handleOk}
                                        onCancel={() => {
                                            setRemarkModal(false);
                                        }}
                                        confirmLoading={loading}
                                    >
                                        <AntInput.TextArea
                                            rows={4}
                                            maxLength={200}
                                            value={remarkValue}
                                            onChange={(e) => {
                                                setRemarkValue(e.target.value);
                                            }}
                                            placeholder={services.language.getText(
                                                'order.orderRemarkPlaceholder'
                                            )}
                                        />
                                    </AntModal>
                                </>
                            );
                        } else {
                            return <></>;
                        }
                    }}
                </Privilege>
            </div>
        );
    };

    function showOrderDetail() {
        const { id, config } = props;
        services.api
            .get(
                { id },
                {
                    apiPath: formatUrl(API_MAP.FIND_PAYMENT_RECORDS, config.type),
                }
            )
            .then((res: any) => {
                const payInfoList = res.map((i: any) => ({
                    key: i.id,
                    paymentType: i.paymentTypeName,
                    paidAmountNum: i.paidAmount,
                    paidAmount: i.paidAmountStr,
                    time: i.paidTime,
                }));
                AntModal.info({
                    width: 800,
                    icon: null,
                    title: services.language.getText('paymentDetail.showDetail'),
                    okText: services.language.getText('cancel'),
                    content: (
                        <AntTable columns={columns} dataSource={payInfoList} pagination={false} />
                    ),
                    onOk() {},
                });
            })
            .catch(services.errorHandle);
        const columns = [
            {
                title: services.language.getText('organizationNumber'),
                render: (text: any, record: any, index: number) => `${index + 1}`,
                key: 'organizationNumber',
            },
            {
                title: services.language.getText('order.payMode'),
                dataIndex: 'paymentType',
                key: 'paymentType',
            },
            {
                title: services.language.getText('paymentDetail.amount'),
                dataIndex: 'paidAmount',
                key: 'paidAmount',
            },
            {
                title: services.language.getText('paymentDetail.time'),
                dataIndex: 'time',
                key: 'time',
            },
        ];
    }

    function getOrderMember() {
        return [
            `${props.delivery?.orderLastName ?? ''}${props.delivery?.orderFirstName ?? ''}`,
            `${props.delivery?.code ?? ''}${props.delivery?.orderMobile ?? ''}`,
            props.delivery?.orderEmail ?? '',
        ]
            .filter(Boolean)
            .join(' - ');
    }

    function getPickUpMember() {
        return [
            `${props.delivery?.pickUpLastName ?? ''}${props.delivery?.pickUpFirstName ?? ''}`,
            `${props.delivery?.code ?? ''}${props.delivery?.pickUpMobile ?? ''}`,
        ]
            .filter(Boolean)
            .join(' - ');
    }

    const getCoupondetail = async () => {
        setCouponeListLoading(true);
        const { id, config } = props;
        try {
            const res: any = await services.api.get(
                { id },
                {
                    apiPath: formatUrl(API_MAP.FIND_COUPON_DETAIL, config.type),
                }
            );

            const columns = [
                {
                    title: services.language.getText('order.couponList.name'),
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: services.language.getText('order.couponList.type'),
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: services.language.getText('order.couponList.couponNum'),
                    dataIndex: 'couponNum',
                    key: 'couponNum',
                },
            ];

            AntModal.info({
                width: 800,
                icon: null,
                title: services.language.getText('order.couponList.title'),
                okText: services.language.getText('close'),
                content: <AntTable columns={columns} dataSource={res} pagination={false} />,
                onOk() {},
            });
        } catch (err) {
            services.errorHandle(err as ErrorInfo);
        } finally {
            setCouponeListLoading(false);
        }
    };

    return (
        <AntCard
            className='order-info-basic'
            bordered={false}
            loading={!props.id}
            title={getTitle()}
            extra={getExtra()}
            style={props.style}
        >
            <AntRow>
                <AntCol span={4}>
                    <Countdown
                        title={ORDER_STATUS_NAMES[props.orderStatus]}
                        color={
                            props.orderStatus === ORDER_STATUS_ALL_VALUES.CANCELLED ||
                            props.orderStatus === ORDER_STATUS_ALL_VALUES.REFUNDED
                                ? 'transparent'
                                : 'green'
                        }
                    />
                </AntCol>
                <AntCol span={12} className='order-detail'>
                    <Descriptions column={1} size='small'>
                        <Descriptions.Item className='order-detail-title' label={'门店名称'}>
                            {props.orderStore?.onlineStoreName ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('merchant')}
                        >
                            {props.merchantName ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('deliveryType.title')}
                        >
                            {DELIVERY_TYPE_NAMES[
                                props.delivery?.deliveryType as DELIVERY_TYPE_VALUES
                            ] ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('receiveAddress')}
                        >
                            <div style={{ wordWrap: 'break-word', maxWidth: '700px' }}>
                                {props.delivery?.deliveryAddress ?? '-'}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('order.waitDeliveryTime')}
                        >
                            {props.delivery?.preCanPickUpTime ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={services.language.getText('order.member')}>
                            {getOrderMember() ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={services.language.getText('level')}>
                            -
                        </Descriptions.Item>
                        <Descriptions.Item label={services.language.getText('order.createTime')}>
                            {props.thirdPartyCreateTime ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={services.language.getText('order.receiveMember')}>
                            {getPickUpMember() ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={services.language.getText('order.payMode')}>
                            {props.paymentType ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={services.language.getText('order.expressNo')}>
                            {props.delivery?.expressNo ?? '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </AntCol>
                <AntCol span={8} style={{ textAlign: 'right' }}>
                    <div className='order-total-amount'>
                        {services.language.getText('order.dealAmount')}：
                        <span className='order-total-amount-value'>
                            {props.concludeAmount ?? '0.00'}
                        </span>
                    </div>
                    <div className='order-total-amount'>
                        {services.language.getText('order.orderListItem.amount')}：
                        <span className='order-total-amount-value'>{props.paidPayAmount}</span>
                    </div>
                    {//TODO 线上订单和线下订单暂未区分
                    true ? (
                        <>
                            <Privilege
                                path={
                                    props.config.type === ORDER_TYPE.BASE
                                        ? 'order.order.find_payment_records'
                                        : 'order_philips.order_philips.find_payment_records_philips'
                                }
                            >
                                <div className='order-show-detail'>
                                    <span
                                        className='order-show-detail-text'
                                        onClick={showOrderDetail}
                                    >
                                        {services.language.getText('paymentDetail.showDetail')}
                                    </span>
                                </div>
                            </Privilege>
                            {props.config.type === ORDER_TYPE.BASE && (
                                <Privilege path={'order.order.find_order_coupons'}>
                                    <AntButton
                                        className='order-show-detail'
                                        loading={couponListloading}
                                        onClick={getCoupondetail}
                                        type='link'
                                    >
                                        <span className='order-show-detail-text'>
                                            {services.language.getText('order.couponList.title')}
                                        </span>
                                    </AntButton>
                                </Privilege>
                            )}
                        </>
                    ) : null}
                    {/* {React.createElement(ComponentsManager.get('PaymentDetail'), {
                        orderNumber: props.parentOrderNumber || props.outerOrderNumber,
                    })} */}
                </AntCol>
            </AntRow>
        </AntCard>
    );
};

const mapStateToProps = (state: any, ownProps: any) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const OrderInfoBasic = connect(mapStateToProps, null)(OrderBasic);

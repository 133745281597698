import { services } from '@comall-backend-builder/core';
import { get } from 'lodash-es';
export default [
    {
        key: '/dc-goods/productList/seo',
        loader: {
            get: async function(data: any, config: any) {
                let result: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-goods/admin/seomanage/info/byCksuId/${data.id}`,
                });
                let megaDescription = result?.megaDescription
                    ? JSON.parse(result.megaDescription)
                    : {};
                let title = result?.title ? JSON.parse(result.title) : {};
                let keywords = result?.keywords ? JSON.parse(result.keywords) : {};
                result = {
                    csku: get(result, 'csku', ''),
                    cskuId: get(result, 'cskuId', ''),
                    id: get(result, 'id', ''),
                    keywordsZH: get(keywords, 'zh', ''),
                    keywordsEN: get(keywords, 'en', ''),
                    keywordsTC: get(keywords, 'tc', ''),
                    megaDescriptionZH: get(megaDescription, 'zh', ''),
                    megaDescriptionEN: get(megaDescription, 'en', ''),
                    megaDescriptionTC: get(megaDescription, 'tc', ''),
                    pageTitleZH: get(title, 'zh', ''),
                    pageTitleEN: get(title, 'en', ''),
                    pageTitleTC: get(title, 'tc', ''),
                };
                return result;
            },
            put: async function(data: any, config: any) {
                data = {
                    csku: data.csku,
                    cskuId: data.cskuId,
                    id: data.id,
                    title: JSON.stringify({
                        en: get(data, 'pageTitleEN', ''),
                        zh: get(data, 'pageTitleZH', ''),
                        tc: get(data, 'pageTitleTC', ''),
                    }),
                    mega_description: JSON.stringify({
                        en: get(data, 'megaDescriptionEN', ''),
                        zh: get(data, 'megaDescriptionZH', ''),
                        tc: get(data, 'megaDescriptionTC', ''),
                    }),
                    keywords: JSON.stringify({
                        en: get(data, 'keywordsEN', ''),
                        zh: get(data, 'keywordsZH', ''),
                        tc: get(data, 'keywordsTC', ''),
                    }),
                };
                let result: any = await services.api.put(data, {
                    ...config,
                    apiPath: `/dc-goods/admin/seomanage`,
                });
                return result;
            },
        },
    },
];

import { Config, Entity } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '../mixin';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';

export const config: Config = {
    entities: {
        thirdPartyWarehouse: {
            apiPath: '/base-order/admin/third/order/add',
            properties: {
                channel: {
                    type: 'string',
                    displayName: '<<thirdPartyWarehouse.channel>>',
                },
                orderNumber: {
                    type: 'string',
                    displayName: '<<thirdPartyWarehouse.orderNumber>>',
                },
                parentOrderNumber: {
                    type: 'string',
                    displayName: '<<thirdPartyWarehouse.parentOrderNumber>>',
                },
                orderTime: {
                    type: 'string',
                    displayName: '<<thirdPartyWarehouse.orderTime>>',
                },
                goods: {
                    type: 'array.thirdPartyOrderGoods',
                    displayName: '<<thirdPartyWarehouse.goods>>',
                },
            },
            filters: {
                channelId: {
                    type: 'string.options.select',
                    displayName: '<<thirdPartyWarehouse.channel>>',
                    options: [],
                    source: {
                        apiPath: '/base-order/admin/third/channel',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        ThirdPartyWarehousePage: {
            component: 'Card',
            entity: 'thirdPartyWarehouse',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'ThirdPartyWarehouseFilter',
                    },
                    {
                        component: 'ThirdPartyWarehouseActions',
                    },
                    {
                        component: 'ThirdPartyWarehousTable',
                    },
                ],
                privilege: {
                    path: 'channel.channel_third_order_add.view_list',
                    level: 'full',
                },
            },
        },
        ThirdPartyWarehouseFilter: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterFormPlus',
                    style: {
                        marginBottom: 30,
                    },
                    direction: 'inline',
                    submit: {
                        text: '<<query>>',
                    },
                    reset: true,
                    fields: [
                        {
                            property: 'channelId',
                        },
                    ],
                },
            ],
        },
        ThirdPartyWarehouseActions: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 20,
            },
            items: [
                {
                    component: 'ButtonPlus',
                    text: '<<thirdPartyWarehouse.allRestock>>',
                    icon: 'plus',
                    type: 'primary',
                    style: {
                        marginRight: 20,
                    },
                    popconfirm: {
                        title: '<<thirdPartyWarehouse.allRestockTips>>?',
                    },
                    async onClick(_: any, { entity }: { entity: Entity }) {
                        const params = Object.assign(
                            {},
                            entity.params,
                            entity.filters
                        ) as AnyObject;
                        await services.api.put(params, {
                            apiPath: '/base-order/admin/third/order/add/all',
                        });
                        message.success(
                            services.language.getText('thirdPartyWarehouse.restockMsg')
                        );
                        entity.search(params);
                    },
                    privilege: {
                        path: 'channel.channel_third_order_add.add_all',
                        level: 'full',
                    },
                },
                {
                    component: 'ExportButtonPlus',
                    text: '<<thirdPartyWarehouse.exportGoodsData>>',
                    icon: 'download',
                    withEntityParams: true,
                    source: {
                        apiPath: '/base-order/admin/third/order/add/exportSku',
                    },
                    privilege: {
                        path: 'channel.channel_third_order_add.third_order_import',
                        level: 'full',
                    },
                },
            ],
        },
        ThirdPartyWarehousTable: {
            ...tableMixin,
            rowKey: 'parentOrderNumber',
            columns: [
                { property: 'channel' },
                { property: 'parentOrderNumber' },
                { property: 'orderNumber' },
                { property: 'orderTime' },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        text: '<<thirdPartyWarehouse.view>>',
                        type: 'link',
                        path: '/third-party-warehouse/{{row.parentOrderNumber}}',
                        privilege: {
                            path: 'channel.channel_third_order_add.detail',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<thirdPartyWarehouse.restock>>',
                        config: {
                            actionType: 'delete',
                            rowKey: 'parentOrderNumber',
                            deleteConfig: {
                                title: '<<thirdPartyWarehouse.restockTips>>？',
                                method: 'put',
                                params: {
                                    channelId: '{{entity.filters.channelId}}',
                                },
                                callback() {
                                    message.success(
                                        services.language.getText('thirdPartyWarehouse.restockMsg')
                                    );
                                },
                            },
                        },
                        privilege: {
                            path: 'channel.channel_third_order_add.add',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ThirdPartyWarehouseDetailPage: {
            component: 'Card',
            entity: 'thirdPartyWarehouse',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'SuperForm',
                        mode: 'view',
                        labelCol: {
                            md: { span: 24 },
                            lg: { span: 4 },
                        },
                        wrapperCol: {
                            md: { span: 24 },
                            lg: { span: 8 },
                        },
                        items: [
                            {
                                renderType: 'field',
                                property: 'orderNumber',
                                key: 'orderNumber',
                            },
                            {
                                renderType: 'field',
                                property: 'parentOrderNumber',
                                key: 'parentOrderNumber',
                            },
                            {
                                renderType: 'field',
                                property: 'orderTime',
                                key: 'orderTime',
                            },
                            {
                                renderType: 'field',
                                property: 'goods',
                                key: 'goods',
                            },
                        ],
                    },
                ],
            },
        },
    },
};

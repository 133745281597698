import { isValidValue } from '@/services';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';

/**
 * 处理阶梯满减带范围的数组对象，过滤不需要校验的值
 * @param data
 * @param keys
 */
export const getCheckValue = (data: any, keys: string[]) => {
    return data.map((e: any) => {
        let a: any = {};
        Object.keys(e).forEach((i) => {
            if (keys.indexOf(i) > -1) a[i] = e[i];
        });
        return a;
    });
};

/**
 * 阶梯满减空校验
 * @param value
 * @returns
 */
export const validateConditionEmpty = (value: any[]) => {
    return value.some((i: any) => {
        return (
            isEmpty(i) ||
            Object.values(i).some((e: any) => {
                return !isValidValue(e) || Number(e) < 0;
            })
        );
    });
};

/**
 * 阶梯满减范围校验
 * @param value
 * @returns
 */
export const validateConditionRange = async (value: any[], callback: any) => {
    let params = value.map((item: any, index: number) => ({
        ...item,
        sort: index,
    }));

    services.api
        .post(params, {
            apiPath: '/dc-promotion/admin/promotion/check/tieredPricing',
        })
        .then((res) => {
            console.log('res', res);
        })
        .catch((e) => {
            callback(new Error(e?.body.err_msg));
        })
        .finally(callback);
};

/**
 * 校验优先级是否是唯一的
 * @param values form所有fields
 * @param _value priority
 * @param callback
 */
export const validatePriority = (values: any, _value: any, callback: any) => {
    services.api
        .post(values, {
            apiPath: '/dc-promotion/admin/promotion/check',
        })
        .then((res) => {
            console.log('res', res);
        })
        .catch((e) => {
            callback(new Error(e?.body.err_msg));
        })
        .finally(callback);
};

import { services } from '@comall-backend-builder/core/lib';
import { isEmpty } from 'lodash';

function transformData(data: AnyObject) {
    // 转成多语言结构，方便后续添加多语言功能
    if (data.banner) {
        // 过滤空配置
        const banner = data.banner.filter((item: any) => item.pc || item.mobile);
        data.banner = JSON.stringify({
            tc: banner,
            zh: banner,
            en: banner,
        });
    }

    data.transportType = data.transportType.toString();
    data.logo = data.logo ?? '';

    return data;
}

export default [
    {
        // 查询菜单栏管理数据
        key: '/dc-goods/admin/merchant-store',
        loader: {
            put: async (data: any, config: any) => {
                const params = transformData(data);

                return await services.api.put(params, {
                    ...config,
                    apiPath: `/dc-goods/admin/merchant/${params.id}`,
                });
            },
            get: async (data: any, config: any) => {
                if (data.id) {
                    const res: any = await services.api.get(data, {
                        ...config,
                        apiPath: `/dc-goods/admin/merchant/${data.id}`,
                    });

                    if (res.banner) {
                        const banner = JSON.parse(res.banner);
                        res.banner = banner.zh;
                    } else {
                        // 兼容历史数据
                        res.banner = [
                            {
                                mobile: data.bannerPicturePath?.zh,
                                pc: data.pcBannerPicturePath?.zh,
                                target: data.bannerTargetUrl,
                            },
                        ];
                    }
                    res.transportType = res.transportType ? res.transportType.split(',') : [1];

                    return res;
                } else {
                    return await services.api.get(data, {
                        ...config,
                        apiPath: `/dc-goods/admin/merchant/byUser`,
                    });
                }
            },
        },
    },
    {
        //查询合作商户数据
        key: '/dc-goods/admin/store/freight/merchant/findFreightList',
        loader: {
            get: async (data: any, config: any) => {
                const res: any = await services.api.get(
                    { page: 1, perPage: 20, roleType: 2, freightType: 2, merchantId: data.name },
                    {
                        ...config,
                        apiPath: `/dc-goods/admin/store/freight/merchant/findFreightList`,
                    }
                );

                if (res.result && !isEmpty(res.result)) {
                    return res.result.map((item: any) => ({
                        id: item.merchantId,
                        name: item.merchantName,
                    }));
                }
                return [];
            },
        },
    },
];

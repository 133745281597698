/*
 * @Author: zhulu
 * @Date: 2020-08-03 14:27:37
 * @Description: 热词词
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { DESIGN_MANAGE_LANGUAGE_OPTIONS, STATUS_OPTIONS } from '@/constants';
import { createPageLayout, tableMixin, getLanguageColumns, getLanguageProperties } from '../mixin';

import { HOT_WORD_TABLE } from '@/configs/pro-table-constants';
export const config: Config = {
    entities: {
        HotWordsManageEntity: {
            apiPath: '/dc-cms-api/admin/hot_words',
            properties: {
                id: { type: 'string' },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<onlineStore.storeName>>',
                },
                onlineStoreCode: {
                    type: 'string',
                    displayName: '<<onlineStore.storeCode>>',
                },
                quantity: {
                    properties: getLanguageProperties('string', 'designManager.hotWords.num'),
                    type: 'object',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<designManager.hotWords.status>>',
                },
                lastModifyUser: {
                    type: 'string',
                    displayName: '<<designManager.hotWords.lastModifyName>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<designManager.hotWords.lastModifyTime>>',
                },
                hotWords: { type: 'object' },
            },
        },
    },
    components: {
        HotWordsManageView: {
            component: 'Viewport',
            entity: 'HotWordsManageEntity',
        },
        HotWordsManageTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: HOT_WORD_TABLE,
            className: 'hot-brands-table',
            componentId: 'HotWordsManageTable',
            columns: [
                { property: 'onlineStoreName' },
                { property: 'onlineStoreCode' },
                ...getLanguageColumns('quantity'),
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                },
                { property: 'lastModifyUser' },
                { property: 'lastModifyTime' },
            ],
            actionColumn: {
                title: '<<set>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                return {
                                    entity: props.entity,
                                    row,
                                    statusApi: {
                                        '0': '/dc-cms-api/admin/hot_words/{{row.id}}/status',
                                        '1': '/dc-cms-api/admin/hot_words/{{row.id}}/status',
                                    },
                                    statusParams: {
                                        '0': { status: 1 },
                                        '1': { status: 0 },
                                    },
                                    statusText: {
                                        '0': '<<enable>>',
                                        '1': '<<disable>>',
                                    },
                                };
                            },
                        },
                        privilege: {
                            path: 'finish.hot_manage.disable',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'HoverSelect',
                        text: '<<designManager.hotWords.setWords>>',
                        config: {
                            actionType: 'component',
                            component: 'ModalSetWords',
                            componentType: 'hotWords',
                            options: DESIGN_MANAGE_LANGUAGE_OPTIONS,
                        },
                        privilege: {
                            path: 'finish.hot_manage.add',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        HotWordsManagePage: createPageLayout([
            {
                component: 'HotWordsManageTable',
                privilege: {
                    path: 'finish.hot_manage.view_list',
                    level: 'full',
                },
            },
        ]),
    },
};

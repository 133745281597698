import React, { useState, useEffect } from 'react';
import { Modal, Radio, Checkbox } from 'antd';
import { services } from '@comall-backend-builder/core';
import { checkInvalidProduct } from '@/services/utils';
import { OrderInfoProductProps } from '.';
import { ProductGiftItem } from './group-items';
import './index.less';

export default (props: OrderInfoProductProps) => {
    const {
        title,
        visible,
        swap,
        giftProducts = [],
        onOpenChange,
        promotionId,
        merchantId,
        mainProducts,
    } = props;
    const [value, setValue] = useState<string | string[]>([]);

    useEffect(() => {
        if (visible) {
            const checked = giftProducts
                .filter((item: any) => !checkInvalidProduct(item))
                .filter((item: any) => item.selected);

            if (swap !== 1) {
                setValue(checked.map((item: any) => item.rsku));
            } else {
                setValue(checked[0]?.rsku || '');
            }
        }
    }, [visible]);

    return (
        <>
            <Modal
                width='800px'
                title={title}
                visible={visible}
                okText={services.language.getText('common.ok')}
                cancelText={services.language.getText('common.cancel')}
                forceRender
                onOk={async () => {
                    const select = value
                        ? giftProducts
                              .filter((item: any) => value.indexOf(item.rsku) > -1)
                              .map((item: any) => ({
                                  ...item,
                                  itemId: item.id,
                                  isSelect: true,
                                  mulePromotionId: promotionId,
                                  merchantId,
                              }))
                        : [];
                    const _mainProducts = mainProducts.map((item: any) => ({
                        ...item,
                        itemId: item.id,
                        goodsType: 1,
                        isSelect: item.selected,
                        mulePromotionId: promotionId,
                        merchantId,
                    }));
                    onOpenChange();
                    props.addItemMulti([...select, ..._mainProducts]);
                }}
                onCancel={onOpenChange}
            >
                <div className='offline-select-extra-good'>
                    {giftProducts.map((giftItem: any) => {
                        return (
                            <ProductGiftItem
                                giftItem={giftItem}
                                showImg
                                renderHandler={() => (
                                    <div className='product-gift-item-select-wrapper'>
                                        {swap !== 1 ? (
                                            <Checkbox
                                                checked={value.includes(giftItem.rsku)}
                                                disabled={checkInvalidProduct(giftItem)}
                                                onClick={() => {
                                                    setValue((res: any) => {
                                                        if (res.includes(giftItem.rsku)) {
                                                            res = ((res as string[]) || []).filter(
                                                                (i) => i !== giftItem.rsku
                                                            );
                                                        } else {
                                                            res = [...res, giftItem.rsku];
                                                        }
                                                        return res;
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Radio
                                                checked={value === giftItem.rsku}
                                                disabled={checkInvalidProduct(giftItem)}
                                                onClick={() => {
                                                    setValue((res: any) => {
                                                        if (res === giftItem.rsku) {
                                                            return '';
                                                        }
                                                        return giftItem.rsku;
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            />
                        );
                    })}
                </div>
            </Modal>
        </>
    );
};

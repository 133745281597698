import { EntityMetadata } from '@comall-backend-builder/core/lib/parser';
import {
    APPLY_TYPE_OPTIONS,
    EVENT_STATUS_OPTIONS,
    EXCLUDE_TYPE_OPTIONS,
    FORM_EXCHANGE_OPTIONS,
    PROMOTION_TYPE_OPTIONS,
    STYLE_PRESENTATION_OPTIONS,
} from '@/constants';
import { get } from 'lodash';
import { buildPropertiesByLanguage } from '@/services/language-utils';

export const entities: {
    [name: string]: EntityMetadata;
} = {
    salesPromotion: {
        apiPath: '/dc-promotion/admin/promotion',
        properties: {
            id: { type: 'string' },
            discName: {
                type: 'string',
                displayName: '<<salesPromotion.salesMultiPromotion.tableCol.name>>',
            },
            startDate: {
                type: 'string',
                displayName: '<<salesPromotion.salesMultiPromotion.tableCol.startTime>>',
            },
            endDate: {
                type: 'string',
                displayName: '<<salesPromotion.salesMultiPromotion.tableCol.endTime>>',
            },
            // 优先级
            priority: {
                type: 'string',
                displayName: '<<salesPromotion.salesMultiPromotion.tableCol.priority>>',
            },
            storeIds: {
                type: 'array.onlineStoreGroup',
                displayName: '<<salesPromotion.baseMsg.storeIds>>',
                source: {
                    apiPath: '/loader/dc-store/admin/onlineStore/findAll',
                },
                controlConfig: {
                    style: {
                        width: 400,
                    },
                    titles: ['<<couponManage.storeIds.left>>', '<<couponManage.storeIds.right>>'],
                    nameProp: 'onlineStoreName',
                },
            },
            type: {
                type: 'string',
                displayName: '<<salesPromotion.salesMultiPromotion.tableCol.promotionType>>',
            },
            applyType: {
                type: 'string',
                displayName: '<<salesPromotion.salesMultiPromotion.tableCol.scopeGoods>>',
            },
            status: {
                type: 'string.status',
                displayName: '<<salesPromotion.salesMultiPromotion.tableCol.promotionState>>',
            },
        },
        filters: {
            discName: {
                type: 'string',
                displayName: '<<salesPromotion.salesMultiPromotion.filter.namePromotion>>',
            },
            discType: {
                type: 'string.options.select',
                displayName: '<<salesPromotion.salesMultiPromotion.filter.typePromotion>>',
                options: PROMOTION_TYPE_OPTIONS,
            },
            promotionStatus: {
                type: 'string.options.select', // @ts-ignore
                options: EVENT_STATUS_OPTIONS,
                displayName: '<<salesPromotion.salesMultiPromotion.filter.statePromotion>>',
            },
            storeId: {
                displayName: '<<salesPromotion.salesMultiPromotion.filter.applicableStores>>',
                type: 'array.treeNodeIds.tree',
                options: [],
                source: {
                    apiPath: '/loader/dc-store/admin/onlineStore/own',
                },
                controlConfig: {
                    allowClear: true,
                    treeCheckable: true,
                    treeNodeFilterProp: 'name',
                    placeholder: '<<defaultPlaceholderSelect>>',
                },
            },
        },
    },
    salesPromotionDetail: {
        apiPath: '/loader/dc-promotion/admin/promotion',
        properties: {
            label: {
                type: 'string',
                displayName: '<<salesPromotion.baseMsg.promotionLabel>>',
                controlConfig: {
                    placeholder: '由系统自动生成',
                },
            },
            discName: {
                type: 'string',
                displayName: '<<salesPromotion.salesMultiPromotion.tableCol.name>>',
            },
            effectTime: {
                type: 'object.dateTimeRange',
                displayName: '<<salesPromotion.baseMsg.effectTime>>',
            },
            storeIds: {
                type: 'array.onlineStoreGroup',
                displayName: '<<salesPromotion.baseMsg.storeIds>>',
                source: {
                    apiPath: '/loader/dc-store/admin/onlineStore/findAll',
                },
                controlConfig: {
                    style: {
                        width: 400,
                    },
                    titles: ['<<couponManage.storeIds.left>>', '<<couponManage.storeIds.right>>'],
                    nameProp: 'onlineStoreName',
                },
            },
            priority: {
                type: 'number',
                displayName: '<<salesPromotion.baseMsg.priorityPromotion>>',
            },
            ...buildPropertiesByLanguage('detailMap', {
                type: 'string.text.paragraph',
                displayName: '<<salesPromotion.baseMsg.descriptionPromotion>>',
            }),
            type: {
                type: 'string.options.select',
                displayName: '<<salesPromotion.baseMsg.promotionTypesRules>>',
                options: PROMOTION_TYPE_OPTIONS,
            },
            applyType: {
                type: 'string.options.select',
                displayName: '<<couponManage.applyType.title>>',
            },
            applyTypeDisplay: {
                type: 'string.options.select',
                displayName: '<<couponManage.applyType.display>>',
                options: APPLY_TYPE_OPTIONS,
            },
            applyGoods: {
                type: 'string.text.paragraph',
                displayName: '<<couponManage.applyType.goodsTitle>>',
                controlConfig: {
                    placeholder: '<<couponManage.applyType.goodsPlaceholder>>',
                },
            },
            applyBrand: {
                type: 'array.optionIds.autoComplete',
                displayName: '<<couponManage.applyType.brandTitle>>',
                options: [],
                source: {
                    apiPath: '/dc-goods/admin/brands/list',
                },
                controlConfig: {
                    placeholder: '<<common.placeSelect>>',
                    allowClear: true,
                },
            },
            applyOfflineCategory: {
                type: 'array.treeNodeIds.tree',
                displayName: '<<couponManage.applyType.offlineCategoryTitle>>',
                controlConfig: {
                    placeholder: '<<common.placeSelect>>',
                    source: {
                        apiPath: '/loader/dc-goods/admin/offline_categories/offline_category_tree',
                    },
                },
            },
            applyVirtualCategory: {
                type: 'array.treeNodeIds.withParam',
                displayName: '<<couponManage.applyType.virtualCategoryTitle>>',
                controlConfig: {
                    placeholder: '<<common.placeSelect>>',
                    itemConfig: {
                        apiPath:
                            '/loader/guide-cms-service/admin/virtual_categorys/categorys_tree/onlineStore',
                        formatProps: (props: any) => {
                            return {
                                params: {
                                    onlineStoreIds: (
                                        get(props.entity, 'fields.storeIds') || []
                                    ).join(','),
                                },
                            };
                        },
                    },
                },
            },
            applyMerchant: {
                type: 'array.optionIds.select',
                displayName: '<<couponManage.applyType.merchantTitle>>',
                options: [],
                controlConfig: {
                    placeholder: '<<common.placeSelect>>',
                    source: {
                        apiPath: '/dc-goods/admin/merchant/list',
                    },
                },
            },
            excludeType: {
                type: 'string.options.select',
                displayName: '<<couponManage.excludeType.title>>',
                options: EXCLUDE_TYPE_OPTIONS,
            },
            excludeGoods: {
                type: 'string.text.paragraph',
                displayName: '<<couponManage.applyType.goodsTitle>>',
                controlConfig: {
                    placeholder: '<<couponManage.applyType.goodsPlaceholder>>',
                },
            },
            excludeBrand: {
                type: 'array.optionIds.autoComplete',
                displayName: '<<couponManage.applyType.brandTitle>>',
                options: [],
                source: {
                    apiPath: '/dc-goods/admin/brands/list',
                },
                controlConfig: {
                    placeholder: '<<common.placeSelect>>',
                    allowClear: true,
                },
            },
            excludeOfflineCategory: {
                type: 'array.treeNodeIds.tree',
                displayName: '<<couponManage.applyType.offlineCategoryTitle>>',
                controlConfig: {
                    placeholder: '<<common.placeSelect>>',
                    source: {
                        apiPath: '/loader/dc-goods/admin/offline_categories/offline_category_tree',
                    },
                },
            },
            excludeVirtualCategory: {
                type: 'array.treeNodeIds.withParam',
                displayName: '<<couponManage.applyType.virtualCategoryTitle>>',
                controlConfig: {
                    placeholder: '<<common.placeSelect>>',
                    itemConfig: {
                        apiPath:
                            '/loader/guide-cms-service/admin/virtual_categorys/categorys_tree/onlineStore',
                        formatProps: (props: any) => {
                            return {
                                params: {
                                    onlineStoreIds: (
                                        get(props.entity, 'fields.storeIds') || []
                                    ).join(','),
                                },
                            };
                        },
                    },
                },
            },
            excludeMerchant: {
                type: 'array.optionIds.select',
                displayName: '<<couponManage.applyType.merchantTitle>>',
                options: [],
                controlConfig: {
                    placeholder: '<<common.placeSelect>>',
                    source: {
                        apiPath: '/dc-goods/admin/merchant/list',
                    },
                },
            },
            conditionType: {
                type: 'string.options.select',
                displayName: '<<salesPromotion.conditionQualification.typeCondition>>',
            },
            rewardType: {
                type: 'string.options.select',
                displayName: '<<salesPromotion.conditionQualification.typeReward>>',
            },
            showType: {
                type: 'string.options.radio',
                options: STYLE_PRESENTATION_OPTIONS,
                displayName: '<<salesPromotion.conditionsReward.stylePresentation>>',
            },
            // 单品-直减
            discValue: {
                type: 'array.salesMultiPromotion',
                displayName: '',
                contentMap: [
                    {
                        id: 1,
                        title: '<<salesPromotion.conditionsReward.type>>',
                        editable: false,
                        key: 'title',
                    },
                    {
                        id: 2,
                        title: '<<salesPromotion.conditionsReward.reward>>',
                        editable: true,
                        key: 'reward',
                        editConfig: {
                            precision: 2,
                            min: 0.01,
                            step: 0.01,
                            max: 99999999.99,
                        },
                        prefix: '<<salesPromotion.conditionsReward.minus>>',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                ],
                dataSource: [{ title: '<<salesPromotion.conditionsReward.lapse>>' }],
                showAddButton: false,
                showType: 1,
            },
            // 单品-直折
            discRule: {
                type: 'array.salesMultiPromotion',
                displayName: '',
                contentMap: [
                    {
                        id: 1,
                        title: '<<salesPromotion.conditionsReward.type>>',
                        editable: false,
                        key: 'title',
                    },
                    {
                        id: 2,
                        title: '<<salesPromotion.conditionsReward.reward>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99.99 },
                        key: 'reward',
                        prefix: '<<salesPromotion.conditionsReward.discount>>',
                        suffix: '%',
                    },
                ],
                dataSource: [{ title: '<<salesPromotion.conditionsReward.formerFold>>' }],
                showAddButton: false,
                showType: 2,
            },
            ...buildPropertiesByLanguage('promotionLabel', {
                type: 'string',
                displayName: '<<salesPromotion.promotionLabel>>',
            }),
            // 满减/阶梯满减-满金额
            fullReduction: {
                type: 'array.salesMultiPromotion',
                displayName: '',
                contentMap: [
                    {
                        id: 1,
                        title: '<<salesPromotion.conditionsReward.serialNumber>>',
                        editable: false,
                        key: 'index',
                    },
                    {
                        id: 2,
                        title: '<<salesPromotion.conditionsReward.type>>',
                        editable: false,
                        key: 'name',
                        defaultValue: 'salesPromotion.stairFullSubtraction',
                    },
                    {
                        id: 3,
                        title: '<<salesPromotion.conditionsReward.scope>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'conditionValue',
                        prefix: '<<salesPromotion.conditionsReward.full>>',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                    {
                        id: 4,
                        title: '<<salesPromotion.conditionsReward.reward>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'prefrValue',
                        prefix: '<<salesPromotion.conditionsReward.minus>>',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                ],
                dataSource: [],
                showAddButton: true,
                showType: 3,
            },
            // 满减/阶梯满减-满件数
            numberFullPieces: {
                type: 'array.salesMultiPromotion',
                displayName: '',
                contentMap: [
                    {
                        id: 1,
                        title: '<<salesPromotion.conditionsReward.serialNumber>>',
                        editable: false,
                        key: 'index',
                    },
                    {
                        id: 2,
                        title: '<<salesPromotion.conditionsReward.type>>',
                        editable: false,
                        key: 'name',
                        defaultValue: 'salesPromotion.stairFullSubtraction',
                    },
                    {
                        id: 3,
                        title: '<<salesPromotion.conditionsReward.scope>>',
                        editable: true,
                        editConfig: { precision: 0, min: 1, step: 1, max: 99999 },
                        key: 'conditionValue',
                        prefix: '<<salesPromotion.conditionsReward.full>>',
                        suffix: '<<salesPromotion.conditionsReward.letter>>',
                    },
                    {
                        id: 4,
                        title: '<<salesPromotion.conditionsReward.reward>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'prefrValue',
                        prefix: '<<salesPromotion.conditionsReward.minus>>',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                ],
                dataSource: [],
                showAddButton: true,
                showType: 4,
            },
            // 满折/阶梯满折-满金额
            fullFoldFullAmount: {
                type: 'array.salesMultiPromotion',
                displayName: '',
                contentMap: [
                    {
                        id: 1,
                        title: '<<salesPromotion.conditionsReward.serialNumber>>',
                        editable: false,
                        key: 'index',
                    },
                    {
                        id: 2,
                        title: '<<salesPromotion.conditionsReward.type>>',
                        editable: false,
                        key: 'name',
                        defaultValue: 'salesPromotion.fullFold',
                    },
                    {
                        id: 3,
                        title: '<<salesPromotion.conditionsReward.scope>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'conditionValue',
                        prefix: '<<salesPromotion.conditionsReward.full>>',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                    {
                        id: 4,
                        title: '<<salesPromotion.conditionsReward.reward>>',
                        editable: true,
                        key: 'prefrValue',
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99.99 },
                        prefix: '<<salesPromotion.conditionsReward.discount>>',
                        suffix: '%',
                    },
                    {
                        id: 5,
                        title: '<<salesPromotion.conditionsReward.upperLimit>>',
                        editable: true,
                        key: 'reliefCeiling',
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        prefix: '',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                ],
                dataSource: [],
                showAddButton: true,
                showType: 5,
            },
            // 满折/阶梯满折-满件数
            fullSubtractionFullPieces: {
                type: 'array.salesMultiPromotion',
                displayName: '',
                contentMap: [
                    {
                        id: 1,
                        title: '<<salesPromotion.conditionsReward.serialNumber>>',
                        editable: false,
                        key: 'index',
                    },
                    {
                        id: 2,
                        title: '<<salesPromotion.conditionsReward.type>>',
                        editable: false,
                        key: 'name',
                        defaultValue: 'salesPromotion.fullFold',
                    },
                    {
                        id: 3,
                        title: '<<salesPromotion.conditionsReward.scope>>',
                        editable: true,
                        editConfig: { precision: 0, min: 1, step: 1, max: 99999 },
                        key: 'conditionValue',
                        prefix: '<<salesPromotion.conditionsReward.full>>',
                        suffix: '<<salesPromotion.conditionsReward.letter>>',
                    },
                    {
                        id: 4,
                        title: '<<salesPromotion.conditionsReward.reward>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99.99 },
                        key: 'prefrValue',
                        prefix: '<<salesPromotion.conditionsReward.discount>>',
                        suffix: '%',
                    },
                    {
                        id: 5,
                        title: '<<salesPromotion.conditionsReward.upperLimit>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'reliefCeiling',
                        prefix: '',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                ],
                dataSource: [],
                showAddButton: true,
                showType: 6,
            },
            // 每满减-满金额
            amountFull: {
                type: 'array.salesMultiPromotion',
                displayName: '',
                contentMap: [
                    {
                        id: 1,
                        title: '<<salesPromotion.conditionsReward.type>>',
                        editable: false,
                        key: 'title',
                    },
                    {
                        id: 2,
                        title: '<<salesPromotion.conditionsReward.scope>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'conditionValue',
                        prefix: '<<salesPromotion.conditionsReward.eachFull>>',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                    {
                        id: 3,
                        title: '<<salesPromotion.conditionsReward.reward>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'prefrValue',
                        prefix: '<<salesPromotion.conditionsReward.minus>>',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                    {
                        id: 4,
                        title: '<<salesPromotion.conditionsReward.upperLimit>>',
                        editable: true,
                        key: 'reliefCeiling',
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        prefix: '',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                ],
                dataSource: [{ title: '<<salesPromotion.conditionsReward.perFullSubtraction>>' }],
                showAddButton: false,
                showType: 7,
            },
            // 每满减-满件数
            fullNumber: {
                type: 'array.salesMultiPromotion',
                displayName: '',
                contentMap: [
                    {
                        id: 1,
                        title: '<<salesPromotion.conditionsReward.type>>',
                        editable: false,
                        key: 'title',
                    },
                    {
                        id: 2,
                        title: '<<salesPromotion.conditionsReward.scope>>',
                        editable: true,
                        editConfig: { precision: 0, min: 1, step: 1, max: 99999 },
                        key: 'conditionValue',
                        prefix: '<<salesPromotion.conditionsReward.eachFull>>',
                        suffix: '<<salesPromotion.conditionsReward.letter>>',
                    },
                    {
                        id: 3,
                        title: '<<salesPromotion.conditionsReward.reward>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'prefrValue',
                        prefix: '<<salesPromotion.conditionsReward.minus>>',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                    {
                        id: 4,
                        title: '<<salesPromotion.conditionsReward.upperLimit>>',
                        editable: true,
                        editConfig: { precision: 2, min: 0.01, step: 0.01, max: 99999999.99 },
                        key: 'reliefCeiling',
                        prefix: '',
                        suffix: '<<salesPromotion.conditionsReward.unit>>',
                    },
                ],
                dataSource: [{ title: '<<salesPromotion.conditionsReward.perFullSubtraction>>' }],
                showAddButton: false,
                showType: 8,
            },
            typeRewardGiveaways: {
                type: 'string',
                displayName: '<<salesPromotion.conditionsReward.typeRewardGiveaways>>',
            },
            freeGiftThresholdPrice: {
                type: 'number',
                displayName: '<<salesPromotion.conditionsReward.freeGiftThresholdPrice>>',
            },
            freeGiftThresholdNums: {
                type: 'number.positive',
                displayName: '<<salesPromotion.conditionsReward.freeGiftThresholdPrice>>',
            },
            ...buildPropertiesByLanguage('titleGift', {
                type: 'string',
                displayName: '<<salesPromotion.conditionsReward.titleGift>>',
            }),
            ...buildPropertiesByLanguage('changeTitle', {
                type: 'string',
                displayName: '<<salesPromotion.conditionsReward.changeTitle>>',
            }),
            chooseProduct: {
                displayName: '<<salesPromotion.conditionsReward.chooseProduct>>',
                type: 'array.dynamic',
                itemConfig: {
                    formatProps: (props: any) => {
                        return {
                            params: {
                                storeIds: (get(props.entity, 'fields.storeIds') || []).join(','),
                                page: 1,
                                perPage: 10,
                            },
                        };
                    },
                    type: 'select',
                    apiPath: '/loader/dc-goods/admin/cskus/queryCskuByPage',
                    selectParamKey: 'keyword',
                },
            },
            swap: {
                type: 'string.options.radio',
                options: FORM_EXCHANGE_OPTIONS,
                displayName: '<<salesPromotion.conditionsReward.formExchange>>',
            },
            exchangeProduct: {
                type: 'array.exchangeProduct',
                formItem: {
                    labelCol: { span: 5 },
                    wrapperCol: { span: 12 },
                },
            },
            memberLevelIds: {
                type: 'array.optionIds.select',
                displayName: '<<salesPromotion.conditionQualification.memberLevelIds>>',
                options: [],
                source: {
                    apiPath: '/dc-user/admin/memberLevel/findAllMemberLevelList',
                },
                controlConfig: {
                    placeholder: '<<common.pleaseSelect>>',
                    allowClear: true,
                    mode: 'multiple',
                },
            },
            RewardType: {
                type: 'string',
                displayName: '<<salesPromotion.conditionsReward.RewardType>>',
            },
            conditionValue: {
                type: 'number',
                displayName: '<<salesPromotion.conditionsReward.conditionValue>>',
                controlConfig: {
                    placeholder: '<<common.placeInput>>',
                },
            },
            prefrValue: {
                type: 'number',
                displayName: '<<salesPromotion.conditionsReward.prefrValue>>',
                controlConfig: {
                    placeholder: '<<common.placeInput>>',
                },
            },
        },
    },
};

import React, { useRef, useState, useEffect } from 'react';
import {
    Button as AntdButton,
    Form as AntdForm,
    message as AntdMessage,
    Row as AntdRow,
    Col as AntdCol,
    Tabs,
    Form,
    message,
} from 'antd';
import { get, isEmpty } from 'lodash';
import { services, Loader } from '@comall-backend-builder/core';
import { PRODUCT_SOURCE_TYPE_VALUES } from '@/constants';
import BasicMessageForm from './basic-message-form';
import DescribeMessageForm from './describe-message-form';
import SpecialAttributeForm from './special-attribute-form ';
import RelatedAttributeForm from './related-products-form';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { useAsyncEffect } from 'ahooks';
const { TabPane } = Tabs;
const typeMap = {
    normal: PRODUCT_SOURCE_TYPE_VALUES.SELF_BUILT + '',
    virtual: PRODUCT_SOURCE_TYPE_VALUES.VIRTUAL + '',
    group: PRODUCT_SOURCE_TYPE_VALUES.COMBINATION + '',
};
const BasicForm: React.FC = (props: any) => {
    const {
        params: { id },
    } = props;
    const type = useRef(services.navigation.getPathname().split('/')[2]);
    const [currentTab, setCurrentTab] = useState('1');
    const [loading, setLoading] = useState(false);
    // const propParams = useRef(props.params);
    const formRef = useRef(props.form);
    // const [goodsDataKey, setGoodsDataKey] = useState<string>('app');
    const [offlineCategoriesAttributes, setOfflineCategoriesAttributes] = useState<any[]>([]);
    const submit = () => {
        if (loading) return;
        const {
            history: { goBack },
        } = props;
        formRef.current.validateFields((err: any, values: any) => {
            const {
                brandId,
                categoryId,
                merchantId,
                modeType,
                salesChannels,
                validity,
                relatedProducts,
                customPictures,
                fromCskuId,
                cskuGroups = [],
                sellSpecs,
                offlineCategories = {},
                ...other
            } = values;
            let _offlineCategories = {};

            Object.keys(offlineCategories).forEach((element: any) => {
                _offlineCategories = {
                    ..._offlineCategories,
                    [`${element.replace('offlineCategories-', '')}`]: offlineCategories[element],
                };
            });

            const post = {
                ...other,
                offlineCategories: _offlineCategories,
                sellSpecs: Object.values(sellSpecs).filter((i) => i),
                fromCskuId: fromCskuId && fromCskuId.id,
                fromCskuName: fromCskuId && fromCskuId.name,
                brandId: brandId.id,
                categoryId: categoryId.id,
                categoryName: categoryId.name,
                merchantId: merchantId.id,
                merchantName: merchantId.name,
                modeType: modeType.join(','),
                salesChannels: salesChannels.join(','),
                validityEnd: get(validity, 'end'),
                validityStart: get(validity, 'start'),
                relatedProducts: relatedProducts.map((i: any) => i?.id).filter((i: any) => i),
                //@ts-ignore
                sourceType: typeMap[type.current],
                customPictures: customPictures.map((item: any, index: number) => ({
                    ...item,
                    sequence: index + 1,
                })),
                cskuGroups: (cskuGroups || []).map((item: any) => ({
                    ...item,
                    cskuId: item.cskuId || item.id,
                })),
            };
            if (id) post.id = id;
            if (post.specsType === 0) delete post.sellSpecs;
            if (post.specsType === 1) delete post.specsMap;

            if (!err) {
                const call = id ? 'put' : 'post';
                setLoading(true);
                services.api[call](
                    {
                        ...post,
                    },
                    {
                        apiPath: `/dc-goods/admin/cskus/${type.current}`,
                    }
                )
                    .then((res) => {
                        AntdMessage.success(services.language.getText('successFully'));
                        goBack();
                        services.localStorage.remove('basicMessage');
                        services.localStorage.remove('PCeditData');
                        services.localStorage.remove('MPeditData');
                    })
                    .catch((e) => errorHandle(e))
                    .finally(() => setLoading(false));
            }
        });
    };

    const callback = (key: any) => {
        setCurrentTab((prev) => {
            if (key === '3' && isEmpty(props.form.getFieldValue('categoryId'))) {
                message.error('请先完善商品基本信息');
                return prev;
            }
            return key;
        });
    };
    const loadDetail = async (orgId?: string, formFields?: any, notSetFields?: any) => {
        try {
            let response = await Loader.load('get', {
                apiPath: '/loader/dc-goods/admin/cskus/info',
                params: {
                    type: type.current,
                    id: orgId || id,
                },
            });
            const {
                brandId,
                brandName,
                categoryName,
                categoryId,
                validityStart,
                validityEnd,
                salesChannels = '',
                modeType = '',
                merchantId,
                merchantName,
                barcode,
                installmentPayment,
                sellSpecs = [],
                cskuGroups = [],
                relatedProducts = [],
                offlineCategories = {},
            } = response;

            let _offlineCategories = {};

            Object.keys(offlineCategories).forEach((element: any) => {
                _offlineCategories = {
                    ..._offlineCategories,
                    [`offlineCategories-${element}`]: offlineCategories[element],
                };
            });

            let _sellSpecs: any = {};
            sellSpecs.forEach((element: any, index: number) => {
                _sellSpecs = { ..._sellSpecs, [index]: element };
            });
            let result = {
                ...response,
                sellSpecs: _sellSpecs,
                offlineCategories: _offlineCategories,
                cskuGroups: cskuGroups.map((item: any) => ({ ...item, id: item.cskuId })),
                brandId: {
                    id: brandId,
                    name: brandName,
                },
                relatedProducts: {
                    ...relatedProducts.map((item: any) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                    })),
                },
                installmentPayment: installmentPayment === null ? false : installmentPayment,
                barCode: barcode,
                categoryId: { id: categoryId, name: categoryName },
                validity: { start: validityStart, end: validityEnd },
                salesChannels: (salesChannels || '').split(','),
                modeType: (modeType || '').split(','),
                merchantId: { id: merchantId, name: merchantName },
            };
            if (!isEmpty(formFields)) {
                result = { ...result, ...formFields };
            }
            const offlineCategoriesAttributes = await loadOfflineCategoriesAttributes(categoryId);

            formatOfflineCategoriesAttributes(offlineCategoriesAttributes, _offlineCategories);

            const orgFormFields = formRef.current.getFieldsValue();
            if (orgId) {
                result = {
                    ...result,
                    barCode: orgFormFields.barCode,
                    csku: orgFormFields.csku,
                };
            }

            let nFormFields: any = {};
            Object.keys(orgFormFields).forEach((key) => {
                nFormFields[key] = result[key];
            });
            formRef.current.setFieldsValue(nFormFields);
        } catch (e) {
            console.error(e);
        }
    };
    useEffect(() => {
        if (id) {
            loadDetail();
        } // eslint-disable-next-line
    }, [formRef.current]);

    useAsyncEffect(async () => {
        if (currentTab === '3' && !isEmpty(props.form.getFieldValue('categoryId'))) {
            const offlineCategoriesAttributes = await loadOfflineCategoriesAttributes();

            formatOfflineCategoriesAttributes(
                offlineCategoriesAttributes,
                props.form.getFieldValue('offlineCategories')
            );
        }
        // eslint-disable-next-line
    }, [currentTab, props.form.getFieldValue('categoryId')]);

    const loadOfflineCategoriesAttributes = async (id?: string) => {
        try {
            let { attributeList }: any = await services.api.get(
                {},
                {
                    apiPath: `/dc-goods/admin/offline_categories_attribute/${id ||
                        props.form.getFieldValue('categoryId').id}`,
                }
            );
            return attributeList || [];
        } catch (e) {
            //@ts-ignore
            services.errorHandle(e);
        }
    };
    const formatOfflineCategoriesAttributes = (offlineCategoriesAttributes: any, value: any) => {
        if (!isEmpty(value)) {
            let nData: any = [];
            Object.keys(value || {}).forEach((key) => {
                const desiredId = key.replace('offlineCategories-', '');
                nData = offlineCategoriesAttributes.map((d: any) => {
                    const errorId =
                        Object.values(d.idMap).indexOf(desiredId) > -1 && d.id !== desiredId;
                    if (errorId) d.id = desiredId;

                    return d;
                });
            });
            setOfflineCategoriesAttributes(nData || []);
        } else {
            setOfflineCategoriesAttributes(offlineCategoriesAttributes || []);
        }
    };

    const tabPaneConfig = [
        //商品基本信息
        {
            tab: services.language.getText('productAdd.basicMessage.title'),
            key: '1',
            component: (
                <BasicMessageForm
                    {...props}
                    form={props.form}
                    loadDetail={loadDetail}
                    //@ts-ignore
                    sourceType={typeMap[type.current]}
                />
            ),
        },
        //商品描述信息
        {
            tab: services.language.getText('productAdd.describeMessage.title'),
            key: '2',
            component: <DescribeMessageForm form={props.form} />,
        },
        //分类特有属性
        {
            tab: services.language.getText('productAdd.specialAttribute.title'),
            key: '3',
            component: (
                <SpecialAttributeForm
                    {...props}
                    offlineCategoriesAttributes={offlineCategoriesAttributes}
                    form={props.form}
                />
            ),
        },
        //关联商品
        {
            tab: services.language.getText('productAdd.relatedProducts.title'),
            key: '4',
            component: (
                <RelatedAttributeForm
                    {...props}
                    form={props.form} //@ts-ignore
                    sourceType={typeMap[type.current]}
                />
            ),
        },
    ];

    return (
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 10 }} className='product-info-form'>
            <Tabs defaultActiveKey='1' activeKey={currentTab} onChange={callback}>
                {tabPaneConfig.map((tabPane) => (
                    <TabPane tab={tabPane.tab} key={tabPane.key}></TabPane>
                ))}
            </Tabs>
            {tabPaneConfig.map((tabPane) => (
                <div
                    style={{ display: tabPane.key === currentTab ? 'block' : 'none' }}
                    key={tabPane.key}
                >
                    {tabPane.component}
                </div>
            ))}
            {/* 提交 */}
            {props?.config?.type !== 'view' && (
                <AntdRow style={{ marginTop: '20px' }}>
                    <AntdCol span={4}></AntdCol>
                    <AntdCol span={16}>
                        <AntdRow style={{ textAlign: 'right' }}>
                            <AntdButton
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    props.history.goBack();
                                    services.localStorage.remove('pictures');
                                    services.localStorage.remove('PCeditData');
                                    services.localStorage.remove('MPeditData');
                                }}
                            >
                                {services.language.getText('cancel')}
                            </AntdButton>
                            <AntdButton type='primary' onClick={submit} loading={loading}>
                                {services.language.getText('submit')}
                            </AntdButton>
                        </AntdRow>
                    </AntdCol>
                </AntdRow>
            )}
        </Form>
    );
};

export const ProductForm = AntdForm.create()(BasicForm);

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button as AntButton, message, Spin } from 'antd';
import { actions, services } from '@comall-backend-builder/core';
import { generateImg } from '@/services';
import { formatUrlParams } from '@/services/formatter';
import { replaceLocationByQuery } from '@/services/utils';
import './index.less';

const { api } = services;

/**
 * 登录页面
 */
export const Login = connect(null, (dispatch, props: any) => {
    return {
        login: async (user: any) => {
            const {
                entities: { privileges },
            } = props;
            await dispatch(actions.loginSuccessAction(user));
            await dispatch(actions.getPrivilegeAction(privileges, user));
        },
    };
})(
    class login extends PureComponent<any, any> {
        state = {
            showError: false,
            loading: false,
        };
        componentDidMount() {
            const code: string = get(formatUrlParams(window.location.href), 'code');
            if (code) {
                this.loginWithCode(code);
                return;
            } else {
                //@ts-ignore
                const time = (localStorage.getItem('loginNoAccess') || 0) * 1;
                localStorage.removeItem('loginNoAccess');
                const currentTime = new Date().getTime();
                const error: string = get(formatUrlParams(window.location.href), 'error');
                if (currentTime - time <= 1 * 60 * 1000 || error) {
                    return this.setState({ showError: true });
                } else {
                    this.fetchLoginUrl();
                }
            }
        }
        fetchLoginUrl = async () => {
            this.setState({ loading: true });
            services.api
                .get(
                    {},
                    {
                        apiPath: '/dc-user/user_okta/login_url?source=dc',
                        customHeaders: { Authorization: '' },
                    }
                )
                .then((res: any) => {
                    window.location.href = res.url;
                })
                .catch((response: any) => {})
                .finally(() => {
                    this.setState({ loading: false });
                });
        };
        loginWithCode = async (code: string) => {
            try {
                const user: any = await api.post(
                    {
                        code: code,
                        source: 'dc',
                    },
                    { apiPath: '/dc-user/user_okta/login' }
                );
                replaceLocationByQuery('code', 'state');
                await this.props.login(user.data);
                window.location.href = '#/';
                this.setState({ loading: false });
            } catch (e) {
                message.error((e as any).message);
                replaceLocationByQuery('code', 'state');
                this.setState({ showError: true, loading: false });
                //存放noAccess信息
                localStorage.setItem('loginNoAccess', new Date().getTime() + '');
            }
        };
        render() {
            const { showError, loading } = this.state;
            return showError ? (
                <div className='login-container'>
                    <div className='login-top'>
                        <div className='login-logo'>
                            <img className='img' src={generateImg('login_logo')} alt='logo' />
                        </div>
                    </div>
                    <div className='login-center'>
                        <div className='login-form'>
                            <div className='login-title' />
                            <div className='login-tip'>
                                {services.language.getText('login.tip')}
                            </div>
                            <div className='form-button'>
                                <AntButton
                                    onClick={this.fetchLoginUrl}
                                    className='login-button'
                                    htmlType='submit'
                                >
                                    {services.language.getText('login.display')}
                                </AntButton>
                            </div>
                        </div>
                    </div>
                    <div className='login-footer-box'>
                        <div className='login-footer-main'>
                            <span className='login-footer-copyright'>
                                {services.language.getText('copyright')}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100vw',
                        height: '100vh',
                    }}
                >
                    <Spin spinning={loading} />
                </div>
            );
        }
    }
);

import React, { useState } from 'react';
import { services } from '@comall-backend-builder/core';
import { PickingCard } from './base-content';
import { PrintButton } from '@/components/print';
import { PackageTags } from '@/components/print-template/package-tags';
import { fetchPrintPackList, getPackageNumber, modifyPackageNumber } from '../../api';
import { Privilege } from '@/components/privilege';
import { Button, Form, InputNumber, Modal, message } from 'antd';
import { ORDER_TYPE } from '@/constants/order/enum';

const cleanFloat = (v = 0) => +String(v || 0).replace(/\..*$/, '');

/**
 * 待发货
 */
export function WaitPickupItem(props: AnyObject) {
    const { parentProps } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingModal, setModalLoading] = useState<boolean>(false);
    const [receiptInfo, setReceiptInfo] = useState<AnyObject[][]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [formValue, setFormValue] = useState<{ coldNumber: number; normalNumber: number }>({
        coldNumber: 0,
        normalNumber: 0,
    });

    // 打印标签
    const printLabel = async (printRef: AnyObject) => {
        try {
            setLoading(true);
            const result = await fetchPrintPackList([props.id], props.parentProps.apiType.picking);
            const receiptInfo = result[0].printList;
            setReceiptInfo(receiptInfo);
            await printRef.current.onPrint();
        } finally {
            setLoading(false);
        }
    };

    const showModal = async () => {
        try {
            setModalLoading(true);
            setVisible(true);
            const res: any = await getPackageNumber(props.id);
            setFormValue({ coldNumber: res.coldNumber, normalNumber: res.normalNumber });
        } finally {
            setModalLoading(false);
        }
    };

    const submit = async () => {
        try {
            setModalLoading(true);
            await modifyPackageNumber({
                id: props.id,
                status: props.orderStatus,
                ...formValue,
            });
            setVisible(false);
            message.success(services.language.getText('picking.modifiedSuccessfully'));
        } catch (e) {
            return;
        } finally {
            setModalLoading(false);
        }
    };

    return (
        <>
            <PickingCard
                data={props}
                type='order'
                actions={
                    <div style={{ display: 'flex' }}>
                        <Privilege path='picking.assistant.print_pack_list'>
                            <PrintButton
                                type='primary'
                                loading={loading}
                                template={<PackageTags data={receiptInfo} />}
                                onClick={printLabel}
                            >
                                {services.language.getText('picking.printTags')}
                            </PrintButton>
                        </Privilege>
                        {parentProps.type === ORDER_TYPE.BASE && (
                            <Privilege path='picking.assistant.update_bag'>
                                <Button style={{ marginLeft: '10px' }} onClick={showModal}>
                                    {services.language.getText('picking.modifyPackages')}
                                </Button>
                            </Privilege>
                        )}
                    </div>
                }
                orderPathname={props.parentProps.orderPathname}
                pickPathname={props.parentProps.pickPathname}
            />
            <Modal
                visible={visible}
                title={services.language.getText('picking.confirmPackages')}
                onCancel={() => setVisible(false)}
                onOk={submit}
                okButtonProps={{
                    loading: loadingModal,
                }}
            >
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 15 }}>
                    <Form.Item label={services.language.getText('picking.temperatureNums')}>
                        <InputNumber
                            min={0}
                            maxLength={5}
                            value={formValue.normalNumber}
                            onChange={(v) => {
                                setFormValue((val) => ({ ...val, normalNumber: cleanFloat(v) }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={services.language.getText('picking.frozenNums')}>
                        <InputNumber
                            min={0}
                            maxLength={5}
                            value={formValue.coldNumber}
                            onChange={(v) => {
                                setFormValue((val) => ({ ...val, coldNumber: cleanFloat(v) }));
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

import { cloneDeep, isArray, isEmpty, isPlainObject, isString } from 'lodash';

/**
 * 指定属性值转换为字符串
 *
 * @param dataSource 数据源
 * @param propNames 指定属性名
 */
export function dataPropValueToStr(dataSource: AnyObject, propNames: string[]) {
    for (const propName of propNames) {
        const value = dataSource[propName];
        if (value !== null && value !== undefined) {
            dataSource[propName] = String(value);
        }
    }
}

/**
 * 指定属性值为 null 时转换为目标值
 *
 * @param dataSource 数据源
 * @param propNames 指定属性名
 * @param targetValue 目标值
 */
export function transformNull(dataSource: AnyObject, propNames: string[], targetValue?: any) {
    for (const propName of propNames) {
        const value = dataSource[propName];
        if (value === null) {
            dataSource[propName] = targetValue;
        }
    }
}
/**
 * 检测参数值是否是有效的，以下参数值将被视为是无效的：
 * - 自身没有任何可枚举数据的对象
 * - 空字符串
 * - null、undefined
 * - NaN
 * @param {*} value 待验证的参数值
 * @return {boolean} 该参数值是否有效
 */
export function isValidValue(value: unknown): boolean {
    if (isPlainObject(value) || isString(value)) {
        return !isEmpty(value);
    }
    if (isArray(value)) return value.length > 0;
    return value !== null && value !== undefined && !Number.isNaN(value);
}

/**
 * 格式化参数对象，将空数据从参数对象中移除
 *
 * @param {object} params - 对象
 * @returns {object} 干净对象
 */
export function cleanParams<T>(params: T) {
    const newParams = cloneDeep(params);
    // eslint-disable-next-line guard-for-in
    for (const name in newParams) {
        const value = newParams[name];
        if (!isValidValue(value)) {
            delete newParams[name];
        }
    }
    return newParams;
}
/**
 * 处理 dataRange 提交参数
 * @param data { start: string, end: string }
 * @param prefix string
 * @returns
 */

export const formatDate = (data: AnyObject, prefix: string) => {
    if (!isValidValue(data)) return {};
    const { start, end } = data;
    return {
        [`${prefix}Start`]: start,
        [`${prefix}End`]: end,
    };
};

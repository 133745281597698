import { services } from '@comall-backend-builder/core/lib';
import {
    EXCLUSION_TYPE_VALUES,
    ORDER_USE_TYPE_VALUES,
    PROVIDE_TYPE_VALUES,
} from './../../constants/coupon-manage';
import {
    DRAW_TYPE_VALUES,
    USE_TYPE_VALUES,
    RELIEF_TYPE_VALUES,
    APPLY_TYPE_VALUES,
} from '@/constants/coupon-manage';
import { buildFormFieldsByLanguage } from '@/services/language-utils';
import { formatNumber } from '@/services';

/**
 * 格式化合法数字串
 */
function whenLegalNumStr(formatter: (num: string) => string | number) {
    return (num: string) => {
        num = String(num);
        return !num || Number.isNaN(+num) ? num : formatter(num);
    };
}

export function buildForm(mode: string) {
    return {
        component: 'SuperForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'cards',
                itemLayout: 'vertical',
                key: 'cards',
                split: false,
                items: [
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.cards.base>>',
                        key: 'cards.base',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'name',
                                key: 'name',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'effectTime',
                                key: 'effectTime',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'paymentModes',
                                key: 'paymentModes',
                            },
                            {
                                renderType: 'field',
                                property: 'paymentCode',
                                key: 'paymentCode',
                            },
                            {
                                renderType: 'field',
                                property: 'weekIds',
                                key: 'weekIds',
                            },
                            {
                                renderType: 'field',
                                property: 'expirationDay',
                                key: 'expirationDay',
                                min: 0,
                                max: 99999,
                                formatter: whenLegalNumStr((num) => formatNumber(num, 0, false)),
                            },
                            {
                                renderType: 'field',
                                property: 'provideType',
                                key: 'provideType',
                                disabled: mode !== 'add',
                                fieldOpts: {
                                    initialValue: PROVIDE_TYPE_VALUES.NORMAL,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'provideNum',
                                key: 'provideNum',
                                formatter: whenLegalNumStr((num) => formatNumber(num, 0, false)),
                                formItem: {
                                    help: '<<couponManage.provideNum.help>>',
                                },
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'storeIds',
                                key: 'storeIds',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'couponCode',
                                key: 'couponCode',
                                renderable: (values: AnyObject) =>
                                    values.provideType === PROVIDE_TYPE_VALUES.NORMAL,
                                disabled: mode !== 'add',
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.cards.goods>>',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'applyType',
                                key: 'applyType',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyGoods',
                                key: 'applyGoods',
                                renderable: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.GOODS,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                        {
                                            validator: (_rule: any, value: any, callback: any) => {
                                                if (value && value.length > 2000) {
                                                    return new Error(
                                                        services.language.getText(
                                                            'couponManage.common.goodLimit'
                                                        )
                                                    );
                                                }
                                                callback();
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyBrand',
                                key: 'applyBrand',
                                enableInternalLoader: false,
                                renderable: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.BRAND,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyOfflineCategory',
                                key: 'applyOfflineCategory',
                                renderable: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.OFFLINE_CATEGORY,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyVirtualCategory',
                                key: 'applyVirtualCategory',
                                renderable: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.VIRTUAL_CATEGORY,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyMerchant',
                                key: 'applyMerchant',
                                renderable: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.MERCHANT,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'excludeType',
                                key: 'excludeType',
                            },
                            {
                                renderType: 'field',
                                property: 'excludeGoods',
                                key: 'excludeGoods',
                                renderable: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.GOODS,
                                fieldOpts: {
                                    rules: [
                                        {
                                            validator: (_rule: any, value: any, callback: any) => {
                                                if (value && value.length > 2000) {
                                                    return new Error(
                                                        services.language.getText(
                                                            'couponManage.common.goodLimit'
                                                        )
                                                    );
                                                }
                                                callback();
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'excludeBrand',
                                key: 'excludeBrand',
                                enableInternalLoader: false,
                                renderable: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.BRAND,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeOfflineCategory',
                                key: 'excludeOfflineCategory',
                                renderable: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.OFFLINE_CATEGORY,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeVirtualCategory',
                                key: 'excludeVirtualCategory',
                                renderable: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.VIRTUAL_CATEGORY,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeMerchant',
                                key: 'excludeMerchant',
                                renderable: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.MERCHANT,
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.cards.rule>>',
                        key: 'cards.rule',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'reliefType',
                                key: 'reliefType',
                                fieldOpts: {
                                    initialValue: RELIEF_TYPE_VALUES.NOW_REDUCES,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'reliefNum',
                                key: 'reliefNum.1',
                                renderable: (values: AnyObject) =>
                                    values.reliefType === RELIEF_TYPE_VALUES.DISCOUNT,
                                placeholder: '<<couponManage.reliefNum.placeholder>>',
                                min: 0,
                                max: 99,
                                formatter: whenLegalNumStr((num) => formatNumber(num, 0, false)),
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'reliefNum',
                                key: 'reliefNum.2',
                                renderable: (values: AnyObject) =>
                                    values.reliefType === RELIEF_TYPE_VALUES.NOW_REDUCES,
                                formatter: whenLegalNumStr((num) => formatNumber(num, 2, false)),
                                placeholder: '<<couponManage.reliefNum.placeholder>>',
                                min: 0,
                                max: 999999.99,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'useType',
                                key: 'useType',
                                fieldOpts: {
                                    initialValue: USE_TYPE_VALUES.AMOUNT,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'useNum',
                                key: 'useNum',
                                renderable: (values: AnyObject) =>
                                    values.useType === USE_TYPE_VALUES.AMOUNT,
                                formatter: whenLegalNumStr((num) => formatNumber(num, 2, false)),
                                min: 0,
                                max: 999999.99,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                        {
                                            validator: (rule: any, value: any, callback: any) => {
                                                if (!value && value !== 0) return new Error();
                                                if (value <= 0) {
                                                    return new Error(
                                                        services.language.getText(
                                                            'couponManage.useNum.error'
                                                        )
                                                    );
                                                } else {
                                                    callback();
                                                }
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.cards.use>>',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'drawType',
                                key: 'drawType',
                                fieldOpts: {
                                    initialValue: DRAW_TYPE_VALUES.LIMIT,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'drawNum',
                                key: 'drawNum',
                                renderable: (values: AnyObject) =>
                                    values.drawType === DRAW_TYPE_VALUES.LIMIT,
                                formatter: whenLegalNumStr((num) => formatNumber(num, 0, false)),
                                min: 0,
                                max: 99999,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'orderUseType',
                                key: 'orderUseType',
                                fieldOpts: {
                                    initialValue: ORDER_USE_TYPE_VALUES.NONE,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'orderUseNum',
                                key: 'orderUseNum',
                                renderable: (values: AnyObject) =>
                                    values.orderUseType === ORDER_USE_TYPE_VALUES.LIMIT,
                                formatter: whenLegalNumStr((num) => formatNumber(num, 0, false)),
                                min: 0,
                                max: 99999,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'exclusionType',
                                key: 'exclusionType',
                                fieldOpts: {
                                    initialValue: EXCLUSION_TYPE_VALUES.NONE,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.cards.info>>',
                        key: 'cards.info',
                        bordered: false,
                        items: [
                            ...buildFormFieldsByLanguage({
                                renderType: 'field',
                                property: 'couponName',
                                maxLength: 200,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            }),
                            ...buildFormFieldsByLanguage({
                                renderType: 'field',
                                property: 'description',
                                maxLength: 1000,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            }),
                            {
                                renderType: 'field',
                                property: 'couponPic',
                                key: 'couponPic',
                            },
                        ],
                    },
                ],
            },
        ],
    };
}

import { services } from '@comall-backend-builder/core';
const SOURCE_TYPE = [
    { id: 'IMPORT', name: '<<coupon.source.import>>' },
    { id: 'SYSTEM_GIFT', name: '<<coupon.source.system>>' },
];

const OBTAIN_STATUS = [
    { id: 'UNRECEIVED', name: '<<coupon.obtain.unReceived>>' },
    { id: 'RECEIVED', name: '<<coupon.obtain.received>>' },
    { id: 'USED', name: '<<coupon.obtain.used>>' },
    { id: 'EXPIRED', name: '<<coupon.obtain.expired>>' },
];

const ACTIVE_STATUS = [
    {
        id: 'DISABLED',
        versionId: '0',
        statusId: 'Invalid',
        color: '#f5222d',
        value: services.language.getText('couponRule.disable'),
    },
    {
        id: 'VALID',
        versionId: '1',
        statusId: 'Active',
        color: '#1890ff',
        value: services.language.getText('couponRule.enable'),
    },
];

const ENABLE_STATUS = [
    {
        id: '1',
        name: 'statusValue.enable',
        status: 'success',
    },
    {
        id: '0',
        name: 'statusValue.disable',
        status: 'warning',
    },
];

export { SOURCE_TYPE, OBTAIN_STATUS, ACTIVE_STATUS, ENABLE_STATUS };

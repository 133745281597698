import React, { useState, useEffect } from 'react';
import { Button, Card, Form, List, message, Spin, Tabs } from 'antd';
import { FormItemProps, FormProps } from 'antd/es/form';
import { TabsProps, TabPaneProps } from 'antd/es/tabs';
import { CardProps } from 'antd/es/card';
import { ListProps } from 'antd/es/list';
import { ExtendedParentComponentProps } from '@comall-backend-builder/components-basis';
import { services, TypesManager } from '@comall-backend-builder/core';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/es/form/Form';
import { get, isFunction, omit, defaults, isEmpty } from 'lodash';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { LoadSourceOptions } from './load-source-options';
import { TYPE_NAME_VALUES } from '@/constants';

export interface BaseSuperFormItem {
    key: string;
    renderable?: boolean | ((values: AnyObject, entity: Entity) => boolean);
}

export interface SuperFormField extends AnyObject, BaseSuperFormItem {
    renderType: 'field';
    property: string;
    formItem?: FormItemProps;
    fieldOpts?: GetFieldDecoratorOptions;
}

export interface SuperFormTabs extends TabsProps, BaseSuperFormItem {
    renderType: 'tabs';
    items: SuperFormItem[];
}

export interface SuperFormTabPane extends TabPaneProps, Omit<BaseSuperFormItem, 'key'> {
    renderType: 'tabPane';
    items: SuperFormItem[];
}

export interface SuperFormCards extends ListProps<AnyObject>, BaseSuperFormItem {
    renderType: 'cards';
    items: SuperFormItem[];
}

export interface SuperFormCardPane extends CardProps, BaseSuperFormItem {
    renderType: 'cardPane';
    items: SuperFormItem[];
}

export type SuperFormItem =
    | SuperFormField
    | SuperFormTabs
    | SuperFormTabPane
    | SuperFormCards
    | SuperFormCardPane;

export interface SuperFormProps extends FormProps, ExtendedParentComponentProps {
    mode: 'add' | 'edit' | 'view';
    form: WrappedFormUtils<AnyObject>;
    items: SuperFormItem[];
    watchState: AnyObject;
    requestStatus: string;
    onValidate?(values: AnyObject, entity: Entity): boolean;
}

/**
 * 支持多种布局方式的表单组件
 */
export const EventMarketingForm = compose(
    connect((state, props: SuperFormProps) => ({
        watchState: get(state, `entities.${props.entity!.id}`) || {},
        requestStatus: props.entity!.requestStatus,
    })),
    Form.create({
        name: 'SuperForm',
        onValuesChange(props: SuperFormProps, _, values: AnyObject) {
            const { entity } = props;
            if (entity) {
                entity.setFields({
                    ...(entity.fields ?? {}),
                    ...(values ?? {}),
                });
            }
        },
    })
)((props: SuperFormProps) => {
    const {
        items,
        entity,
        watchState,
        entities,
        routes,
        params,
        mode,
        form,
        requestStatus,
        onValidate,
        ...formProps
    } = props;

    const editable = ['add', 'edit'].includes(mode);
    const loadable = ['edit', 'view'].includes(mode);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (entity && loadable) entity.get(entity.params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (submitting) {
            if (requestStatus === 'success') {
                message.success(services.language.getText('common.saveSuccess'));
                services.navigation.goBack();
            } else if (requestStatus === 'failed') {
                setSubmitting(false);
            }
        }
    }, [submitting, requestStatus]);

    const entityValues = get(entity, 'fields', {});
    const formValues = form.getFieldsValue();
    const values = defaults(entityValues, formValues);

    function isRenderable(item: SuperFormItem) {
        const { renderable } = item;

        return entity
            ? renderable !== undefined
                ? isFunction(renderable)
                    ? renderable(values, entity)
                    : renderable
                : true
            : false;
    }

    function renderItem(item: SuperFormItem) {
        if (!isRenderable(item)) return null;

        switch (item.renderType) {
            case 'field':
                return renderField(item);
            case 'tabs':
                return renderTabs(item);
            case 'tabPane':
                return renderTabPane(item);
            case 'cards':
                return renderCards(item);
            case 'cardPane':
                return renderCardPane(item);
        }
    }

    function renderField(item: SuperFormField) {
        const { renderType, key, property, formItem = {}, fieldOpts = {}, ...fieldProps } = item;
        const value = get(entityValues, property);
        const field = get(entity, `metainfo.properties.${property}`);

        if (!field) return null;

        const fieldType = TypesManager.get(field.type);
        const mergedFieldProps = getMergedFieldProps(field, fieldProps);

        if (mode === 'view') {
            return (
                <Form.Item key={key} label={field.displayName} {...formItem}>
                    {fieldType.getDisplayComponent(value, mergedFieldProps)}
                </Form.Item>
            );
        }

        const rules = initFieldOptsRules(field, fieldOpts);
        const mergedFieldOpts = Object.assign({}, fieldOpts, {
            rules: [
                ...rules,
                {
                    validator: fieldType.validate,
                },
            ],
            initialValue: value === undefined ? fieldOpts.initialValue : value,
        });

        if (mergedFieldProps.source) {
            return (
                <LoadSourceOptions
                    key={key}
                    options={mergedFieldProps.options}
                    source={mergedFieldProps.source}
                >
                    {(options) => (
                        <Form.Item label={field.displayName} {...formItem}>
                            {form.getFieldDecorator(
                                property,
                                mergedFieldOpts
                            )(
                                fieldType.getControlComponent({
                                    ...mergedFieldProps,
                                    name: property,
                                    options,
                                })
                            )}
                        </Form.Item>
                    )}
                </LoadSourceOptions>
            );
        }

        const rendenFieldType = () => {
            if (item.property === 'orderAmount' || item.property === 'invitationWay') {
                return (
                    <Form.Item
                        key={key}
                        label={field.displayName}
                        {...formItem}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        {form.getFieldDecorator(
                            property,
                            mergedFieldOpts
                        )(fieldType.getControlComponent({ ...mergedFieldProps, name: property }))}
                    </Form.Item>
                );
            } else {
                return (
                    <Form.Item key={key} label={field.displayName} {...formItem}>
                        {form.getFieldDecorator(
                            property,
                            mergedFieldOpts
                        )(fieldType.getControlComponent({ ...mergedFieldProps, name: property }))}
                    </Form.Item>
                );
            }
        };

        return rendenFieldType();
    }

    function getMergedFieldProps(field: AnyObject, fieldProps: AnyObject) {
        const { controlConfig = {}, displayConfig = {} } = field;
        const restProps = omit(field, ['type', 'displayName', 'controlConfig', 'displayConfig']);

        return {
            entity,
            entities,
            ...restProps,
            ...(mode === 'view' ? displayConfig : controlConfig),
            ...fieldProps,
        };
    }

    function initFieldOptsRules(field: AnyObject, fieldOpts: GetFieldDecoratorOptions) {
        return Array.isArray(fieldOpts.rules)
            ? fieldOpts.rules.map((rule) =>
                  rule.required
                      ? {
                            ...rule,
                            message:
                                rule.message ||
                                `${field.displayName}${services.language.getText(
                                    'components.Form.required'
                                )}`,
                        }
                      : rule
              )
            : [];
    }

    function renderTabs(item: SuperFormTabs) {
        const { renderType, items, ...tabsProps } = item;
        return <Tabs {...tabsProps}>{items.map(renderItem)}</Tabs>;
    }

    function renderTabPane(item: SuperFormTabPane) {
        const { renderType, items, ...tabPaneProps } = item;
        return <Tabs.TabPane {...tabPaneProps}>{items.map(renderItem)}</Tabs.TabPane>;
    }

    function renderCards(item: SuperFormCards) {
        const { renderType, items, ...cardsProps } = item;
        return (
            <List
                {...cardsProps}
                dataSource={items}
                renderItem={(item) => <List.Item key={item.key}>{renderItem(item)}</List.Item>}
            />
        );
    }

    function renderCardPane(item: SuperFormCardPane) {
        const { renderType, items, ...cardProps } = item;
        return <Card {...cardProps}>{items.map(renderItem)}</Card>;
    }

    function handleSubmit() {
        form.validateFields((errors: any, values: AnyObject) => {
            if (errors || (onValidate && !onValidate(values, entity!))) return;

            setSubmitting(true);

            let couponList = [];
            if (!isEmpty(values.prizeSetting?.items)) {
                couponList = values.prizeSetting.items.map((item: any) => ({
                    type: 'COUPON',
                    value: item.couponNum,
                    couponId: item.id || item.couponId,
                    num: 1,
                }));
            }

            let couponOtherList = [];
            if (!isEmpty(values.prizeSetting?.other)) {
                couponOtherList = values.prizeSetting.other.map((item: any) => ({
                    type: 'OTHER_COUPON',
                    value: item.otherNum,
                    couponId: item.id || item.couponId,
                    num: 1,
                }));
            }

            let pointObj = undefined;
            if (values.prizeSetting?.val) {
                pointObj = { type: 'POINTS', value: values.prizeSetting.val };
            }

            const orderAmountObj = { value: values.orderAmount, key: 'ORDER_FULL' };
            const invitationWayObj = { value: values.invitationWay, key: 'INITIAL' };
            const rewardWaysObj = { value: values.rewardWays, key: 'INCENTIVES' };

            // @ts-ignore
            if (entity && entity.fields?.type === TYPE_NAME_VALUES.FREE_ON_FULL_ORDER) {
                values.awardConditionCommends = [orderAmountObj];
            } else if (
                entity &&
                // @ts-ignore
                entity.fields?.type === TYPE_NAME_VALUES.REWARD_FOR_INVITING_OTHERS
            ) {
                values.awardConditionCommends = [invitationWayObj, rewardWaysObj];
            }
            values.awardCommends = pointObj
                ? [pointObj, ...couponList, ...couponOtherList]
                : [...couponList, ...couponOtherList];
            values.effectiveStartTime = values.effectTime.start;
            values.effectiveEndTime = values.effectTime.end;

            switch (mode) {
                case 'add':
                    entity?.add(values, entity.params);
                    break;
                case 'edit':
                    entity?.modify(values, entity.params);
                    break;
            }
        });
    }

    const formEl = (
        <Form {...omit(formProps, ['dispatch'])} key={String(requestStatus === 'pending')}>
            {items.map(renderItem)}
            {editable && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '15px',
                        margin: '15px',
                    }}
                >
                    <Button onClick={services.navigation.goBack}>
                        {services.language.getText('components.Button.cancel')}
                    </Button>
                    <Button type='primary' onClick={handleSubmit}>
                        {services.language.getText('components.Button.submit')}
                    </Button>
                </div>
            )}
        </Form>
    );

    return requestStatus === 'pending' ? <Spin>{formEl}</Spin> : formEl;
}) as React.FC<SuperFormProps>;

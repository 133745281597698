import React, { PureComponent } from 'react';
import { Card as AntCard, List as AntList, Row as AntRow, Col as AntCol, Tag } from 'antd';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
import { Price } from '../../components/price';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import {
    PRODUCT_SOURCE_TYPE_NAMES,
    PRODUCT_SOURCE_TYPE_VALUES,
    SPECS_TYPE_NAMES,
    SPECS_TYPE_VALUES,
} from '@/constants/product';
import { PRODUCT_TYPE_NAMES, PRODUCT_TYPE_VALUES } from '@/constants/picking/product-type';

interface OrderInfoProductProps {
    [key: string]: any;
}

/**
 * 订单详情 - 订单商品
 */
class orderInfoProduct extends PureComponent<OrderInfoProductProps, any> {
    renderProductItem = (item: any, subProduct = false) => {
        const {
            enName,
            name,
            barcode,
            csku,
            weight,
            sourceType,
            specsType,
            quantity,
            lackQuantity,
            pickingQuantity,
            pictureUrl,
            salePrices,
            overrideCode,
            overrideCodeTypeName,
            overrideCodePriceStr,
            itemType,
        } = item;
        const instanceType = get(this.props, 'instanceType');

        function renderQuantity() {
            switch (specsType) {
                case SPECS_TYPE_VALUES.NORMAL:
                    return (
                        <>
                            {services.language.getText(
                                instanceType === 'afterSaleOrdersInfo'
                                    ? 'afterSale.product.orderQuantity'
                                    : 'order.product.orderQuantity'
                            )}
                            ：{quantity}
                            {(pickingQuantity > 0 || lackQuantity > 0) && (
                                <span>
                                    {` (${services.language.getText(
                                        'order.product.lackQuantity'
                                    )}x${lackQuantity}/${services.language.getText(
                                        'order.product.pickingQuantity'
                                    )}x${pickingQuantity})`}
                                </span>
                            )}
                        </>
                    );
                case SPECS_TYPE_VALUES.WEIGH:
                    return (
                        <>
                            {services.language.getText('weight')}：{weight}
                        </>
                    );
            }
        }

        function renderProductTags() {
            return (
                <span
                    style={{
                        paddingLeft: '15px',
                    }}
                >
                    {sourceType === PRODUCT_SOURCE_TYPE_VALUES.COMBINATION && (
                        <Tag color='#448ef8'>
                            {PRODUCT_SOURCE_TYPE_NAMES[PRODUCT_SOURCE_TYPE_VALUES.COMBINATION]}*
                            {quantity}
                        </Tag>
                    )}
                    {sourceType === PRODUCT_SOURCE_TYPE_VALUES.VIRTUAL && (
                        <Tag color='#e89e43'>
                            {PRODUCT_SOURCE_TYPE_NAMES[PRODUCT_SOURCE_TYPE_VALUES.VIRTUAL]}
                        </Tag>
                    )}
                    {itemType === PRODUCT_TYPE_VALUES.GIVEAWAY && (
                        <Tag color='#80B436'>
                            {PRODUCT_TYPE_NAMES[PRODUCT_TYPE_VALUES.GIVEAWAY]}
                        </Tag>
                    )}
                    {itemType === PRODUCT_TYPE_VALUES.EXCHANGE_GOODS && (
                        <Tag color='#80B436'>
                            {PRODUCT_TYPE_NAMES[PRODUCT_TYPE_VALUES.EXCHANGE_GOODS]}
                        </Tag>
                    )}
                </span>
            );
        }

        return (
            <div
                className='order-info-product-item'
                style={{
                    width: '100%',
                    lineHeight: '2.2em',
                    display: 'flex',
                }}
            >
                <div
                    className={classNames('product-item-img', {
                        'out-of-stock': lackQuantity,
                        'product-item-img--sub': subProduct,
                    })}
                >
                    <img className='product-img' alt='' src={pictureUrl} />
                </div>
                <div
                    className={classNames('product-item-info', {
                        'product-item-info--sub': subProduct,
                    })}
                >
                    <AntRow>
                        <AntCol>
                            {services.language.getText('order.product.name')}：{name || enName}
                            {renderProductTags()}
                        </AntCol>
                    </AntRow>
                    <AntRow>
                        <AntCol span={8}>SKU：{csku}</AntCol>
                        <AntCol span={8}>
                            {language.getText('productBarcode')}：{barcode}
                        </AntCol>
                        <AntCol span={8}>
                            {language.getText('productSpecsType.title')}：
                            {SPECS_TYPE_NAMES[specsType as SPECS_TYPE_VALUES]}
                        </AntCol>
                    </AntRow>
                    <AntRow
                        style={{
                            marginBottom: isEmpty(salePrices) ? 0 : 16,
                        }}
                    >
                        <AntCol span={8} style={{ zIndex: 2 }}>
                            {services.language.getText(
                                instanceType === 'afterSaleOrdersInfo'
                                    ? 'afterSale.product.salePrice'
                                    : 'order.product.salePrice'
                            )}
                            ：
                            <Price price={salePrices} dividing={false} />
                        </AntCol>
                        <AntCol span={8}>{renderQuantity()}</AntCol>
                    </AntRow>
                    {overrideCode ? (
                        <>
                            <AntRow style={{ color: '#000' }}>
                                OVVERRIDECODE: {overrideCode ?? '-'}
                            </AntRow>
                            <AntRow style={{ color: '#000' }}>
                                {language.getText('offlineOrder.create.codeType')}:{' '}
                                {overrideCodeTypeName ?? '-'}
                            </AntRow>
                            <AntRow style={{ color: '#000' }}>
                                {language.getText('offlineOrder.create.codePrice')}:{' '}
                                {overrideCodePriceStr ?? '-'}
                            </AntRow>
                        </>
                    ) : null}
                </div>
            </div>
        );
    };
    renderProduct = (item: any) => {
        return (
            <>
                <AntList.Item>{this.renderProductItem(item)}</AntList.Item>
                {item.combinationGoods && item.combinationGoods.length > 0 && (
                    <AntList.Item
                        style={{
                            flexFlow: 'column',
                        }}
                    >
                        {item.combinationGoods.map((subProduct: any) =>
                            this.renderProductItem(subProduct, true)
                        )}
                    </AntList.Item>
                )}
            </>
        );
    };

    render() {
        const { style } = this.props;
        let id, goods;
        switch (this.props?.instanceType) {
            case 'afterSaleOrdersInfo':
                id = this.props.id;
                goods = this.props.itemPos;
                break;
            default:
                id = this.props.id;
                goods = this.props.goods;
                break;
        }
        const cardProps = {
            className: 'order-info-product',
            bordered: false,
            loading: !id,
            title: services.language.getText('order.product.productList'),
            style,
        };

        const listProps = {
            dataSource: goods,
            renderItem: this.renderProduct,
        };

        return (
            <>
                <AntCard {...cardProps}>
                    <AntList {...listProps} />
                </AntCard>
                {/* 退还运费 */}
                {this.props?.instanceType === 'afterSaleOrdersInfo' &&
                    this.props.refundFreightAmount > 0 && (
                        <div className='freight-content'>
                            <div>
                                {services.language.getText('afterSale.refundFreight')}：
                                {this.props.refundFreightAmount}
                            </div>
                        </div>
                    )}
            </>
        );
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const OrderInfoProduct = connect(mapStateToProps, null)(orderInfoProduct);

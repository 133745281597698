/* eslint-disable */
import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Input, Modal, Icon, message } from 'antd';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import './index.less';
interface Props {
    entity: any;
    className: string;
    style: any;
    row: any;
}

interface State {
    thirdSku: string | null;
    previousValCache: string | null;
}

export class SkuMapInput extends Component<Props, State> {
    state = {
        thirdSku: this.props.row.thirdSku,
        previousValCache: this.props.row.thirdSku,
    };

    handleChange = (e: any) => {
        this.setState({ thirdSku: e.target.value });
    };
    handleFocus = (e: any) => {
        this.setState({ previousValCache: e.target.value });
    };
    handleBlur = async (e: any) => {
        const { row, entity } = this.props;
        const newVal = e.target.value;
        const oldVal = this.state.previousValCache;
        if (newVal === oldVal) return;
        try {
            await services.api.put(
                {
                    thirdSku: newVal,
                    csku: row.csku,
                    channelId: row.channelId,
                },
                {
                    apiPath: '/dc-goods/admin/goodsThirdXref/update',
                }
            );
            entity.search(entity.params);
        } catch (error) {
            this.setState({ thirdSku: oldVal });
            const { body: err } = error as any;
            if (err?.err_code === 200601 || err?.err_code === 200602) {
                Modal.confirm({
                    content: err.err_msg,
                    icon: <Icon type='exclamation-circle' theme='filled' />,
                    okText: services.language.getText('confirm'),
                    cancelText: services.language.getText('cancel'),
                    onOk: () => {
                        this.setState({ thirdSku: '' });
                    },
                    onCancel: () => {},
                });
            } else {
                services.errorHandle(error as ErrorInfo);
            }
        }
    };

    componentDidUpdate(prveProps: any) {
        if (this.props.row.thirdSku !== prveProps.row.thirdSku) {
            this.setState({
                thirdSku: this.props.row.thirdSku,
            });
        }
    }

    render() {
        return (
            <Input
                className='sku-map-input'
                style={{ borderColor: 'rgba(0, 0, 0, 0.65)', ...this.props.style }}
                value={this.state.thirdSku || ''}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            />
        );
    }
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isArray, isEmpty } from 'lodash';
import { ButtonProps } from '@comall-backend-builder/components-basis/lib/button';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Loader, actions } from '@comall-backend-builder/core';
import { Button, Modal, message } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

interface TableBatchButtonComponentProps extends ButtonProps {
    entity: Entity;
    tableComponentId: string;
    method: keyof Loader.Loader;
    source: ApiRequestConfig;
    confirmTitle: string;
    confirmContent: string;
    selectedRowKeys: string[];
    rowKeysParamProp: string;
    dispatch(action: actions.ComponentAction): void;
}

const TableBatchButtonComponent: React.FC<TableBatchButtonComponentProps> = (props) => {
    const {
        text,
        entity,
        method = 'get',
        source,
        tableComponentId,
        confirmTitle = `${language.getText('common.ok')}${text}？`,
        confirmContent,
        selectedRowKeys,
        rowKeysParamProp,
        dispatch,
        ...buttonProps
    } = props;
    const [loading, setLoading] = useState(false);

    function batchHandler() {
        if (isEmpty(selectedRowKeys)) {
            message.error(language.getText('common.pleaseSelect'));
            return;
        }
        Modal.confirm({
            title: confirmTitle,
            content: confirmContent,
            async onOk() {
                try {
                    setLoading(true);
                    Loader.load(method, {
                        ...source,
                        params: {
                            [rowKeysParamProp]: selectedRowKeys.join(','),
                        },
                    });
                    entity.search(entity.params);
                    dispatch(actions.tableRowSelectionChangeAction(tableComponentId, []));
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    return (
        <Button {...buttonProps} loading={loading} onClick={batchHandler}>
            {text}
        </Button>
    );
};

function mapState2Props(state: any) {
    if (!state.components) return;

    const tableComponentId = Object.keys(state.components).find(
        (key) => key.startsWith('DataTable') && isArray(state.components[key]?.selectedRowKeys)
    );
    if (!tableComponentId) return;

    const selectedRowKeys = state.components[tableComponentId]?.selectedRowKeys;
    if (isEmpty(selectedRowKeys)) return;

    return {
        tableComponentId,
        selectedRowKeys,
    };
}

/**
 * 表格批处理按钮
 */
export const TableBatchButton = connect(mapState2Props, null)(TableBatchButtonComponent);

import React from 'react';

import { Button, Modal } from 'antd';
import { get } from 'lodash';
import { navigateToDetail } from '../../service';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Privilege } from '@/components/privilege';
import * as pickingApi from '../../api';
import { PickingCard } from './base-content';
import { PATH_NAME, PICKING_STATUS } from '../../enums';

/**
 *缺货订单
 */

export function StockOutItem(props: any) {
    const { id, parentProps } = props;

    /**
     * 缺货配送
     */
    const confirmDelivery = () => {
        Modal.confirm({
            title: services.language.getText('picking.confirmSoldOut'),
            onOk() {
                return pickingApi
                    .stockOutDelivery(id, 'picking', parentProps.apiType.order)
                    .then(() => {
                        parentProps.refresh();
                    })
                    .catch(errorHandle);
            },
        });
    };
    /**
     * 重新拣货
     */
    const pickingAgain = async () => {
        pickingApi
            .changePickingStatus([props.pickingListVo.id], PICKING_STATUS.PICKING, 'repick', {
                apiType: parentProps.apiType.picking,
            })
            .then(() => {
                navigateToDetail(props.pickingListVo.id, parentProps.pickPathname);
            })
            .catch(errorHandle);
    };

    return (
        <PickingCard
            type='order'
            data={{ ...props, orderStatus: 'STOCKOUT' }}
            renderContent={() =>
                props?.allLack ? (
                    <>
                        <label>{services.language.getText('picking.remark')}</label>
                        <span style={{ color: 'red' }}>
                            {services.language.getText('picking.lackRemark')}
                        </span>
                    </>
                ) : (
                    <div style={{ flex: 1 }}>
                        <div className='ant-descriptions-item-label' style={{ flexShrink: 0 }}>
                            {services.language.getText('picking.lack')}：
                        </div>
                        <div>
                            {get(props, 'lack.goods', []).map((item: any) => (
                                <div key={item.barcode}>
                                    <span>{item.name}</span> <span>{item.barcode}</span>{' '}
                                    <span>
                                        {services.language.getText('picking.lackShort')}x
                                        {item.lackQuantity}
                                    </span>{' '}
                                    <span>
                                        {services.language.getText('picking.pick')}x
                                        {item.pickingQuantity}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
            actions={
                <>
                    {/* 重新拣货 */}
                    <Privilege
                        path={
                            parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                                ? 'picking.assistant.loc_to_loc'
                                : 'picking_philips.assistant_philips.loc_to_loc_philips'
                        }
                    >
                        <Button onClick={pickingAgain}>
                            {services.language.getText('picking.pickingAgain')}
                        </Button>
                    </Privilege>{' '}
                    {/* 部分缺货订单可以缺货配送 */}
                    {!props?.allLack && (
                        <Privilege
                            path={
                                parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                                    ? 'picking.assistant.part_refund'
                                    : 'picking_philips.assistant_philips.part_refund_philips'
                            }
                        >
                            <Button type='primary' onClick={confirmDelivery}>
                                {services.language.getText('picking.lackDelivery')}
                            </Button>
                        </Privilege>
                    )}{' '}
                    {/* 取消订单 */}
                    <Privilege
                        path={
                            parentProps.pickPathname === PATH_NAME.PICK_PATH_NAME
                                ? 'picking.assistant.cancel_order'
                                : 'picking_philips.assistant_philips.cancel_order_philips'
                        }
                    >
                        {React.createElement(ComponentsManager.get('CancelOrderModal'), {
                            orderNumber: id,
                            parentProps,
                            menuType: 'picking',
                            type: parentProps.type,
                        })}
                    </Privilege>
                </>
            }
            orderPathname={parentProps.orderPathname}
            pickPathname={parentProps.pickPathname}
        />
    );
}

import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/dc-order/admin/report/liability/export',
        loader: {
            post: async function(data: any, config: any) {
                const params = {
                    storeId: data.storeId,
                    beginCreateDate: data.createDate.start,
                    endCreateDate: data.createDate.end,
                };
                return services.api.download(params, config);
            },
        },
    },
    {
        key: '/dc-store/admin/onlineStore/findAl',
        loader: {
            get: async function(data: any, config: any) {
                let result: any[] = await services.api.get(data, config);
                result = result.map((item: any) => ({ ...item, name: item.onlineStoreName }));

                return result;
            },
        },
    },
];

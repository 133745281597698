import { buildFormFieldsByLanguage } from '@/services/language-utils';

export function buildForm(mode: string) {
    return {
        component: 'SuperForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'field',
                property: 'internalName',
                key: 'internalName',
                maxLength: 50,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'code',
                key: 'code',
                maxLength: 100,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },

            {
                renderType: 'field',
                property: 'channelId',
                key: 'channelId',
                maxLength: 100,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            ...buildFormFieldsByLanguage({
                renderType: 'field',
                property: 'nameLanguageList',
                maxLength: 50,
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            }),
            {
                renderType: 'field',
                property: 'sequence',
                key: 'sequence',
                fieldOpts: {
                    initialValue: '',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            ...buildFormFieldsByLanguage({
                renderType: 'field',
                property: 'descLanguageList',
                maxLength: 200,
                fieldOpts: {
                    rules: [],
                },
            }),
            {
                renderType: 'field',
                property: 'descTime',
                key: 'descTime',
            },
            {
                renderType: 'field',
                property: 'installmentFlag',
                key: 'installmentFlag',
                fieldOpts: {
                    initialValue: '0',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'offlineFlag',
                key: 'offlineFlag',
                fieldOpts: {
                    initialValue: '0',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'icon',
                key: 'icon',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
        ],
    };
}

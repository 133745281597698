import { services } from '@comall-backend-builder/core/lib';
import map from 'lodash/map';
import get from 'lodash/get';
export default [
    {
        key: '/dc-user/admin/member_info',
        loader: {
            get: async function(data: any, config: any) {
                let response: any = await services.api.get(data, config);
                const result = get(response, 'result');
                const newResult = map(result, (item) => {
                    if (item.areaCode) {
                        item.phoneNumber = item.areaCode + item.phoneNumber;
                    }
                    return item;
                });
                return {
                    ...response,
                    result: newResult,
                };
            },
        },
    },
    {
        key: '/dc-price/admin/member_discount',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, config);
                const result = get(response, 'result');
                const newResult = map(result, (item) => {
                    if (item.memberLevel) {
                        item.memberLevel = item.memberLevel.toUpperCase();
                    }
                    return item;
                });
                return {
                    ...response,
                    result: newResult,
                };
            },
        },
    },
];

import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '../mixin';
import { language } from '@comall-backend-builder/core/lib/services';
import { buildForm } from './site-messages-form';
import { SEND_TYPE_OPTIONS } from '@/constants/site-messages';

export const config: Config = {
    entities: {
        siteMessages: {
            apiPath: '/loader/admin/inmail',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<siteMessages.name>>',
                },
                sendTime: {
                    type: 'string.dateTimePlus',
                    displayName: '<<siteMessages.sendTime>>',
                },
                subject: {
                    type: 'string',
                    displayName: '<<siteMessages.subject>>',
                },
                content: {
                    type: 'string.text.paragraph',
                    displayName: '<<siteMessages.content>>',
                },
                isOpen: {
                    type: 'string.options.radio',
                    options: [
                        {
                            id: 'false',
                            name: language.getText('common.close'),
                        },
                        {
                            id: 'true',
                            name: language.getText('common.open'),
                        },
                    ],
                    displayName: '<<siteMessages.isOpen>>',
                },
                urlSubject: {
                    type: 'string',
                    displayName: '<<siteMessages.urlSubject>>',
                },
                targetUrl: {
                    type: 'string',
                    displayName: '<<siteMessages.targetUrl>>',
                },
                sendType: {
                    type: 'string.options.radio',
                    options: SEND_TYPE_OPTIONS,
                    displayName: '<<siteMessages.sendType>>',
                },
                phoneNumbers: {
                    type: 'string.text.paragraph',
                    displayName: '<<siteMessages.phoneNumbers>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<siteMessages.status>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<siteMessages.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
            },
        },
    },
    components: {
        SiteMessagesPage: {
            entity: 'siteMessages',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<siteMessages.add>>',
                            route: '/site-messages/add',
                            privilege: {
                                path: 'notification.inmail_menu_bar.inmail_add',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'SiteMessagesTable',
                    privilege: {
                        path: 'notification.inmail_menu_bar.inmail',
                        level: 'full',
                    },
                },
            ]),
        },
        SiteMessagesAddPage: {
            component: 'Card',
            entity: 'siteMessages',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        SiteMessagesEditPage: {
            component: 'Card',
            entity: 'siteMessages',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
        SiteMessagesTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'sendTime',
                },
                {
                    property: 'subject',
                },
                {
                    property: 'content',
                    displayConfig: {
                        className: 'ant-table-row-cell-ellipsis',
                        style: {
                            display: 'block',
                            maxWidth: '480px',
                        },
                    },
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: [
                            {
                                id: 1,
                                name: language.getText('common.notStarted'),
                                status: 'success',
                            },
                            {
                                id: 2,
                                name: language.getText('common.over'),
                                status: 'default',
                            },
                        ],
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                true: '/dc-order-message/admin/inmail/enable/{{row.id}}',
                                false: '/dc-order-message/admin/inmail/disable/{{row.id}}',
                            },
                            statusText: {
                                false: '<<disable>>',
                                true: '<<enable>>',
                            },
                            linkProperty: 'disable',
                            errorStatus: false,
                        },
                        privilege: {
                            path: 'notification.inmail_menu_bar.inmail_enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/site-messages/edit/{{row.id}}',
                        privilege: {
                            path: 'notification.inmail_menu_bar.inmail_edit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

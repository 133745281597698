import React, { PureComponent } from 'react';
import { Form as AntForm, Select as AntSelect, Input as AntInput } from 'antd';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { AFTER_SALE_TYPE_OPTIONS, AFTER_SALE_TYPE_VALUES } from '@/constants/after-sale';
import { Button } from '@comall-backend-builder/components-basis';
import { message } from 'antd';
import { trim } from 'lodash';
import { getStoreInfo } from '@/services/utils';
import { Privilege } from '@/components/privilege';

interface OrderInfoProductProps {
    [key: string]: any;
}

/**
 * 创建售后单 - 售后单表单
 */
class afterSaleCreateForm extends PureComponent<OrderInfoProductProps, any> {
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {
                const result: any = await services.api.get(
                    {
                        orderNumber: trim(values.outerOrderNumber),
                        onlineStoreId: getStoreInfo().id,
                    },
                    {
                        apiPath: `/base-order/admin/aftermarket/verification`,
                    }
                );
                if (result.code === '1') {
                    message.warning(result.msg);
                } else if (result.code === '200') {
                    result.data.remarks = [];
                    result.data.orderNumber = values.outerOrderNumber;
                    result.data.afterSaleType = values.afterSaleType;
                }
                this.props.entity.setFields({ ...this.props.entity.fields, ...result.data });
            }
        });
    };
    handleSelectChange = (e: any) => {
        this.props.entity.setFields({ ...this.props.entity.fields, afterSaleType: e });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <AntForm
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onSubmit={this.handleSubmit}
                className='after-sale-create-form'
            >
                <AntForm.Item label={services.language.getText('afterSale.createFrom.orderNumber')}>
                    {getFieldDecorator('outerOrderNumber', {
                        rules: [
                            {
                                required: true,
                                message: services.language.getText('deposit.AssociatedOrder'),
                                whitespace: true,
                            },
                        ],
                    })(
                        <AntInput
                            placeholder={services.language.getText('defaultPlaceholderInput')}
                            maxLength={100}
                        />
                    )}
                    <Privilege path='aftermarket.after_sale_view.verification'>
                        <Button type='primary' htmlType='submit'>
                            {services.language.getText('afterSale.createFrom.verifyOrderNum')}
                        </Button>
                    </Privilege>
                </AntForm.Item>
                <AntForm.Item
                    label={services.language.getText('afterSale.createFrom.afterSaleType')}
                >
                    {getFieldDecorator('afterSaleType', {
                        rules: [{ required: true, message: 'Please select your gender!' }],
                        initialValue: AFTER_SALE_TYPE_VALUES.RETURN,
                    })(
                        <AntSelect onChange={this.handleSelectChange}>
                            {AFTER_SALE_TYPE_OPTIONS.map((item) => (
                                <AntSelect.Option value={item.id}>{item.name}</AntSelect.Option>
                            ))}
                        </AntSelect>
                    )}
                </AntForm.Item>
            </AntForm>
        );
    }
}
const afterSaleCreateFormInstance = AntForm.create()(afterSaleCreateForm);

const mapStateToProps = (state: any, ownProps: any) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const AfterSaleCreateForm = connect(mapStateToProps, null)(afterSaleCreateFormInstance);

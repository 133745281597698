import { formats } from '@comall-backend-builder/types/lib';
import { some } from 'lodash';
import { services } from '@comall-backend-builder/core/lib';

export class EntityTableFormat extends formats.ArrayFormat {
    validate(_rule: any, value: any, callback: any): void {
        //如果有正在修改中的项 或 新增的未确定项时
        if (some(value, (item) => item._modify || (item._new && !item._confirmed_new))) {
            callback(new Error(services.language.getText('validateErrors.hasModifyingItem')));
            return;
        }
        callback();
    }
}

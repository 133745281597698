import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 三方管理类型
 */
export enum THIRD_PARTY_TYPE_VALUES {
    /**
     * 三方
     */
    SF = '1',
    /**
     * 自营
     */
    ZY = '2',
}

/**
 * 三方管理类型
 */
export const THIRD_PARTY_TYPE_NAMES = {
    [THIRD_PARTY_TYPE_VALUES.SF]: language.getText('thirdPartyManagement.sf'),
    [THIRD_PARTY_TYPE_VALUES.ZY]: language.getText('thirdPartyManagement.zy'),
};

/**
 * 三方管理类型选项
 */
export const THIRD_PARTY_TYPE_OPTIONS = generateOptions(
    THIRD_PARTY_TYPE_VALUES,
    THIRD_PARTY_TYPE_NAMES
);

export interface Store {
    /**
     * 门店信息
     */
    storeInfo: StoreInfo;
    /**
     * 联系方式
     */
    contactInformation: ContactInformation;
    /**
     * 地址信息
     */
    addressInfo: AddressInfo;
}

export interface StoreInfo {
    /**
     * 门店名称
     */
    name: string;
    /**
     * 门店名称
     */
    code: string;
    /**
     * Division
     */
    organizationId: string;
    /**
     * 主拣货方式
     */
    pickingType: string;
    /**
     * 门店类型
     */
    type: string;
}
export interface ContactInformation {
    /**
     * 联系人
     */
    contacts: string;
    /**
     * 门店电话
     */
    masterMobile: string;
}
export interface AddressInfo {
    /**
     * 门店实景图
     */
    realityImages: Array<string>;
}

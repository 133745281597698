import { services } from '@comall-backend-builder/core';
import { trim } from 'lodash-es';
import React, { useEffect } from 'react';

import { Row as AntRow, Form as AntForm, Col as AntCol, Button, Input as AntInput } from 'antd';
import { Privilege } from '@/components/privilege';
import { OrderInfoProductProps } from '.';
import { language } from '@comall-backend-builder/core/lib/services';

export default function MemberForm(props: OrderInfoProductProps) {
    const { activeKey, memberInfo } = props;
    const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = props.form;

    useEffect(() => {
        const regex = /\?phone=([\d+]+)/;
        const phoneVal = window.location.href.match(regex);
        const initPhoneVal = phoneVal && phoneVal[1];

        if (initPhoneVal) {
            setFieldsValue({ memberPhone: initPhoneVal });
            setTimeout(() => {
                searchMember();
            });
        }
    }, []);

    function searchMember() {
        validateFields(['memberPhone'], async (err: any, values: any) => {
            if (!err) {
                services.api
                    .get(
                        {
                            generalParam: trim(values.memberPhone),
                            onlineStoreId: props.params?.onlineStoreIds,
                        },
                        {
                            apiPath: `/dc-user/admin/members/getByGeneralParam`,
                        }
                    )
                    .then((memberInfo: any) => {
                        props.onLoadMemberInfo(memberInfo);
                        setFieldsValue({
                            consigneeName: memberInfo?.memberId
                                ? memberInfo?.surname + memberInfo?.name
                                : undefined,
                        });
                        setFieldsValue({ consigneeMobile: memberInfo?.phone });
                    })
                    .catch(services.errorHandle);
            }
        });
    }

    return (
        <div
            className='offline-create-member-form'
            style={{ display: activeKey === 0 ? 'block' : 'none' }}
        >
            <AntForm.Item label={language.getText('offlineOrder.memberPhone')}>
                {getFieldDecorator('memberPhone')(
                    <AntInput
                        placeholder={language.getText('offlineOrder.memberPhonePlaceholder')}
                        maxLength={100}
                        autoFocus={true}
                    />
                )}
                <Privilege path='offline.offline_order.mobile'>
                    <Button
                        onClick={searchMember}
                        disabled={!trim(getFieldValue('memberPhone'))}
                        type='primary'
                        style={{ marginRight: 25 }}
                    >
                        {language.getText('query')}
                    </Button>
                </Privilege>
                <Button
                    type='primary'
                    onClick={() =>
                        services.navigation.goto(
                            `/members/create?offlineBackId=${props.params?.onlineStoreIds}`
                        )
                    }
                >
                    {language.getText('offlineOrder.create.crateMember')}
                </Button>
            </AntForm.Item>

            {memberInfo?.phone ? (
                <div className='offline-order-member-info'>
                    <AntRow style={{ marginBottom: 25 }}>
                        <AntCol span={3}></AntCol>
                        <AntCol span={6}>
                            {language.getText('offlineOrder.create.memberID')}：
                            {memberInfo?.memberId ?? '-'}
                        </AntCol>
                        <AntCol span={6}>
                            {language.getText('offlineOrder.create.memberName')}：
                            {memberInfo?.surname + memberInfo?.name ?? '-'}
                        </AntCol>
                        <AntCol span={6}>
                            {language.getText('offlineOrder.create.memberLevel')}：
                            {memberInfo?.levelName ?? '-'}
                        </AntCol>
                    </AntRow>
                    <AntRow>
                        <AntCol span={3}></AntCol>
                        <AntCol span={6}>
                            {language.getText('integral')}：{memberInfo?.point ?? '-'}
                        </AntCol>
                        <AntCol span={6}>
                            {language.getText('offlineOrder.create.cashCoupon')}：
                            {memberInfo?.voucherBalance ?? '-'}
                        </AntCol>
                    </AntRow>
                </div>
            ) : null}
        </div>
    );
}

// 默认搜索词
import { Config } from '@comall-backend-builder/core/lib/parser';
import { STATUS_OPTIONS } from '@/constants';
import { createPageLayout, tableMixin } from '../mixin';

import { DEFAULT_SEARCH_KEY_TABLE } from '@/configs/pro-table-constants';
export const config: Config = {
    entities: {
        RecommendedBrandEntity: {
            apiPath: '/loader/dc-master-data/admin/homeBrand',
            properties: {
                id: { type: 'string' },
                storeName: {
                    type: 'string',
                    displayName: '<<onlineStore.storeName>>',
                },
                storeCode: {
                    type: 'string',
                    displayName: '<<onlineStore.storeCode>>',
                },
                count: {
                    type: 'string',
                    displayName: '<<designManager.recommendedBrand.quantity>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<designManager.searchWords.status>>',
                },
                lastedModifyUser: {
                    type: 'string',
                    displayName: '<<designManager.searchWords.lastModifyName>>',
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<designManager.searchWords.lastModifyTime>>',
                },
            },
        },
    },
    components: {
        RecommendedBrandView: {
            component: 'Viewport',
            entity: 'RecommendedBrandEntity',
        },
        RecommendedBrandTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: DEFAULT_SEARCH_KEY_TABLE,
            className: 'hot-brands-table',
            componentId: 'RecommendedBrandTable',
            columns: [
                { property: 'storeName' },
                { property: 'storeCode' },
                { property: 'count' },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                },
                { property: 'lastedModifyUser' },
                { property: 'lastedModifyTime' },
            ],
            actionColumn: {
                fixed: 'right',
                title: '<<actions>>',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<common.disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                return {
                                    entity: props.entity,
                                    row,
                                    statusApi: {
                                        '0':
                                            '/dc-master-data/admin/homeBrand/enable/{{row.storeId}}',
                                        '1':
                                            '/dc-master-data/admin/homeBrand/disable/{{row.storeId}}',
                                    },
                                    statusParams: {
                                        '0': { status: 1 },
                                        '1': { status: 0 },
                                    },
                                    statusText: {
                                        '0': '<<enable>>',
                                        '1': '<<disable>>',
                                    },
                                };
                            },
                        },
                        privilege: {
                            path: 'finish.home_brand_menu_bar.home_brand_enable',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ModalSelectBrand',
                        config: {
                            actionType: 'component',
                            componentType: 'recommendedBrand',
                        },
                        privilege: {
                            path: 'finish.home_brand_menu_bar.home_brand_detail_save',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        RecommendedBrandPage: createPageLayout([
            {
                component: 'RecommendedBrandTable',
                privilege: {
                    path: 'finish.home_brand_menu_bar.home_brand_list',
                    level: 'full',
                },
            },
        ]),
    },
};

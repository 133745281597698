import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 发放类型值
 */
export enum PROVIDE_TYPE_VALUES {
    /**
     * 普通
     */
    NORMAL = '1',
    /**
     * 兑换
     */
    EXCHANGE = '2',
}

/**
 * 发放类型名
 */
export const PROVIDE_TYPE_NAMES = {
    [PROVIDE_TYPE_VALUES.NORMAL]: language.getText('couponManage.provideType.normal'),
    [PROVIDE_TYPE_VALUES.EXCHANGE]: language.getText('couponManage.provideType.exchange'),
};

/**
 * 发放类型候选项
 */
export const PROVIDE_TYPE_OPTIONS = generateOptions(PROVIDE_TYPE_VALUES, PROVIDE_TYPE_NAMES);

/**
 * 减免类型值
 */
export enum RELIEF_TYPE_VALUES {
    /**
     * 折扣
     */
    DISCOUNT = '1',
    /**
     * 立减
     */
    NOW_REDUCES = '2',
}

/**
 * 减免类型名
 */
export const RELIEF_TYPE_NAMES = {
    [RELIEF_TYPE_VALUES.DISCOUNT]: language.getText('couponManage.reliefType.discount'),
    [RELIEF_TYPE_VALUES.NOW_REDUCES]: language.getText('couponManage.reliefType.nowReduces'),
};

/**
 * 减免类型候选项
 */
export const RELIEF_TYPE_OPTIONS = generateOptions(RELIEF_TYPE_VALUES, RELIEF_TYPE_NAMES);

/**
 * 使用门槛类型值
 */
export enum USE_TYPE_VALUES {
    /**
     * 无使用门槛
     */
    NONE = '1',
    /**
     * 订单金额满
     */
    AMOUNT = '2',
}

/**
 * 使用门槛类型名
 */
export const USE_TYPE_NAMES = {
    [USE_TYPE_VALUES.NONE]: language.getText('couponManage.useType.none'),
    [USE_TYPE_VALUES.AMOUNT]: language.getText('couponManage.useType.amount'),
};

/**
 * 使用门槛类型候选项
 */
export const USE_TYPE_OPTIONS = generateOptions(USE_TYPE_VALUES, USE_TYPE_NAMES);

/**
 * 领取数量限制值
 */
export enum DRAW_TYPE_VALUES {
    /**
     * 不限制，每用户领取数量
     */
    NONE = '1',
    /**
     * 限制，每用户领取数量
     */
    LIMIT = '2',
}

/**
 * 领取数量限制名
 */
export const DRAW_TYPE_NAMES = {
    [DRAW_TYPE_VALUES.NONE]: language.getText('couponManage.drawType.none'),
    [DRAW_TYPE_VALUES.LIMIT]: language.getText('couponManage.drawType.limit'),
};

/**
 * 领取数量限制候选项
 */
export const DRAW_TYPE_OPTIONS = generateOptions(DRAW_TYPE_VALUES, DRAW_TYPE_NAMES);

/**
 * 每订单使用数量限制
 */
export enum ORDER_USE_TYPE_VALUES {
    /**
     * 不限制，每订单使用数量
     */
    NONE = '1',
    /**
     * 限制，每订单使用数量
     */
    LIMIT = '2',
}

/**
 * 每订单使用数量限制名
 */
export const ORDER_USE_TYPE_NAMES = {
    [ORDER_USE_TYPE_VALUES.NONE]: language.getText('couponManage.orderUseType.none'),
    [ORDER_USE_TYPE_VALUES.LIMIT]: language.getText('couponManage.orderUseType.limit'),
};

/**
 * 每订单使用数量限制候选项
 */
export const ORDER_USE_TYPE_OPTIONS = generateOptions(ORDER_USE_TYPE_VALUES, ORDER_USE_TYPE_NAMES);

/**
 * 券互斥限制
 */
export enum EXCLUSION_TYPE_VALUES {
    /**
     * 不限制
     */
    NONE = '1',
    /**
     * 限制
     */
    LIMIT = '2',
}

/**
 * 券互斥限制名
 */
export const EXCLUSION_TYPE_NAMES = {
    [EXCLUSION_TYPE_VALUES.NONE]: language.getText('couponManage.exclusionType.none'),
    [EXCLUSION_TYPE_VALUES.LIMIT]: language.getText('couponManage.exclusionType.limit'),
};

/**
 * 券互斥限制候选项
 */
export const EXCLUSION_TYPE_OPTIONS = generateOptions(EXCLUSION_TYPE_VALUES, EXCLUSION_TYPE_NAMES);

/**
 * 适用商品类型值
 */
export enum APPLY_TYPE_VALUES {
    /**
     * 全部商品
     */
    ALL = '1',
    /**
     * 指定商品
     */
    GOODS = '2',
    /**
     * 指定品牌
     */
    BRAND = '3',
    /**
     * 指定标准分类
     */
    OFFLINE_CATEGORY = '4',
    /**
     * 指定虚拟分类
     */
    VIRTUAL_CATEGORY = '5',
    /**
     * 指定合作商户
     */
    MERCHANT = '6',
}

/**
 * 适用商品类型名
 */
export const APPLY_TYPE_NAMES = {
    [APPLY_TYPE_VALUES.ALL]: language.getText('couponManage.applyType.all'),
    [APPLY_TYPE_VALUES.GOODS]: language.getText('couponManage.applyType.goods'),
    [APPLY_TYPE_VALUES.BRAND]: language.getText('couponManage.applyType.brand'),
    [APPLY_TYPE_VALUES.OFFLINE_CATEGORY]: language.getText(
        'couponManage.applyType.offlineCategory'
    ),
    [APPLY_TYPE_VALUES.VIRTUAL_CATEGORY]: language.getText(
        'couponManage.applyType.virtualCategory'
    ),
    [APPLY_TYPE_VALUES.MERCHANT]: language.getText('couponManage.applyType.merchant'),
};

/**
 * 适用商品类型候选项
 */
export const APPLY_TYPE_OPTIONS = generateOptions(APPLY_TYPE_VALUES, APPLY_TYPE_NAMES);

/**
 * 排除商品类型值
 */
export enum EXCLUDE_TYPE_VALUES {
    /**
     * 指定商品
     */
    GOODS = '2',
    /**
     * 指定品牌
     */
    BRAND = '3',
    /**
     * 指定标准分类
     */
    OFFLINE_CATEGORY = '4',
    /**
     * 指定虚拟分类
     */
    VIRTUAL_CATEGORY = '5',
    /**
     * 指定合作商户
     */
    MERCHANT = '6',
}

/**
 * 排除商品类型名
 */
export const EXCLUDE_TYPE_NAMES = {
    [EXCLUDE_TYPE_VALUES.GOODS]: language.getText('couponManage.excludeType.goods'),
    [EXCLUDE_TYPE_VALUES.BRAND]: language.getText('couponManage.excludeType.brand'),
    [EXCLUDE_TYPE_VALUES.OFFLINE_CATEGORY]: language.getText(
        'couponManage.excludeType.offlineCategory'
    ),
    [EXCLUDE_TYPE_VALUES.VIRTUAL_CATEGORY]: language.getText(
        'couponManage.excludeType.virtualCategory'
    ),
    [EXCLUDE_TYPE_VALUES.MERCHANT]: language.getText('couponManage.excludeType.merchant'),
};

/**
 * 排除商品类型候选项
 */
export const EXCLUDE_TYPE_OPTIONS = generateOptions(EXCLUDE_TYPE_VALUES, EXCLUDE_TYPE_NAMES);

/**
 * 卡券发类型
 */
export enum COUPON_PROVIDE_TYPE_VALUES {
    /** 普通 */
    NORMAL = '1',
    /** 发放 */
    EXCHANGE = '2',
}
export const COUPON_PROVIDE_TYPE__NAMES = {
    [COUPON_PROVIDE_TYPE_VALUES.NORMAL]: language.getText('couponManage.common.provideType.normal'),
    [COUPON_PROVIDE_TYPE_VALUES.EXCHANGE]: language.getText(
        'couponManage.common.provideType.exchange'
    ),
};
export const COUPON_PROVIDE_TYPE_OPTIONS = generateOptions(
    COUPON_PROVIDE_TYPE_VALUES,
    COUPON_PROVIDE_TYPE__NAMES
);

/**
 * 减免类型
 */
export enum DISCOUNT_TYPE_VALUES {
    /** 折扣 */
    DISCOUNT = '1',
    /** 立减 */
    IMMEDIATELY = '2',
}
export const DISCOUNT_TYPE_NAMES = {
    [DISCOUNT_TYPE_VALUES.DISCOUNT]: language.getText('couponManage.common.discountType.discount'),
    [DISCOUNT_TYPE_VALUES.IMMEDIATELY]: language.getText(
        'couponManage.common.discountType.immediately'
    ),
};
export const DISCOUNT_TYPE_OPTIONS = generateOptions(DISCOUNT_TYPE_VALUES, DISCOUNT_TYPE_NAMES);

/**
 * 优惠券类型值
 */
export enum COUPON_TYPE_VALUES {
    /**
     * 普通
     */
    NORMAL = '1',
    /**
     * 运费券
     */
    FREIGHT = '2',
}

/**
 * 优惠券数据状态值
 */
export enum COUPON_DATA_STATUS_VALUES {
    /**
     * 待绑定
     */
    UNBOUNDED = '1',
    /**
     * 待使用
     */
    UNUSED = '2',
    /**
     * 已使用
     */
    USED = '3',
    /**
     * 已过期
     */
    EXPIRED = '4',
}

/**
 * 优惠券数据状态名
 */
export const COUPON_DATA_STATUS_NAMES = {
    [COUPON_DATA_STATUS_VALUES.UNBOUNDED]: language.getText(
        'couponManage.couponDataStatus.unbounded'
    ),
    [COUPON_DATA_STATUS_VALUES.UNUSED]: language.getText('couponManage.couponDataStatus.unused'),
    [COUPON_DATA_STATUS_VALUES.USED]: language.getText('couponManage.couponDataStatus.used'),
    [COUPON_DATA_STATUS_VALUES.EXPIRED]: language.getText('couponManage.couponDataStatus.expired'),
};

/**
 * 优惠券数据状态候选项
 */
export const COUPON_DATA_STATUS_OPTIONS = generateOptions(
    COUPON_DATA_STATUS_VALUES,
    COUPON_DATA_STATUS_NAMES
);

/**
 * 劵状态值
 */
export enum COUPON_STATUS_VALUES {
    /** 未开始 */
    UN_START = '0',
    /** 进行中 */
    PROGRESSING = '1',
    /** 已结束 */
    FINISHED = '2',
}

/**
 * 券状态名
 */
export const COUPON_STATUS_NAMES = {
    [COUPON_STATUS_VALUES.UN_START]: language.getText('couponManage.status.unStart'),
    [COUPON_STATUS_VALUES.PROGRESSING]: language.getText('couponManage.status.progressing'),
    [COUPON_STATUS_VALUES.FINISHED]: language.getText('couponManage.status.finished'),
};

/**
 * 券状态角标颜色
 */
export const COUPON_STATUS_COLORS = {
    [COUPON_STATUS_VALUES.UN_START]: 'success',
    [COUPON_STATUS_VALUES.PROGRESSING]: 'warning',
    [COUPON_STATUS_VALUES.FINISHED]: 'default',
};

/**
 * 券状态候选项
 */
export const COUPON_STATUS_OPTIONS = generateOptions(
    COUPON_STATUS_VALUES,
    COUPON_STATUS_NAMES,
    (v, n) => ({ id: Number(v), name: n, status: COUPON_STATUS_COLORS[v] })
);

/**
 * 券状态候选项
 */
export const COUPON_STATUS_VALUES_OPTIONS = generateOptions(
    COUPON_STATUS_VALUES,
    COUPON_STATUS_NAMES
);

/**
 * 限购排除商品类型值
 */
export enum EXCLUDE_TYPE_LIMIT_VALUES {
    /**
     * 排除指定商品
     */
    GOODS = '1',
    /**
     * 排除指定品牌
     */
    BRAND = '2',
    /**
     * 排除指定标准分类
     */
    OFFLINE_CATEGORY = '3',
    /**
     * 排除指定虚拟分类
     */
    VIRTUAL_CATEGORY = '4',
}

/**
 * 排除商品类型名
 */
export const EXCLUDE_TYPE_LIMIT_NAMES = {
    [EXCLUDE_TYPE_LIMIT_VALUES.GOODS]: language.getText('couponManage.excludeType.goods'),
    [EXCLUDE_TYPE_LIMIT_VALUES.BRAND]: language.getText('couponManage.excludeType.brand'),
    [EXCLUDE_TYPE_LIMIT_VALUES.OFFLINE_CATEGORY]: language.getText(
        'couponManage.excludeType.offlineCategory'
    ),
    [EXCLUDE_TYPE_LIMIT_VALUES.VIRTUAL_CATEGORY]: language.getText(
        'couponManage.excludeType.virtualCategory'
    ),
};

/**
 * 排除商品类型候选项
 */
export const EXCLUDE_TYPE_LIMIT_OPTIONS = generateOptions(
    EXCLUDE_TYPE_LIMIT_VALUES,
    EXCLUDE_TYPE_LIMIT_NAMES
);

import React, { Component } from 'react';
import classNames from 'classnames';
/*
 *
 * status countdown计时中 timeout超时 normal正常 cancel取消 done完成
 * title 展示文案
 * time 剩余时间/超时时间 毫秒数
 * needChange 计时归0是否展示超时计时器
 * color 边框和文字颜色
 * onTimeChange 时间变化时的回调
 *
 * e.g:
 * <Countdown time={this.state.time}
 *            color={this.state.color}
 *            status={this.state.status}
 *            title={this.state.title}
 *            needChange={this.state.needChange}
 *            onTimeChange={this.onTimeChange}>
 *
 * onTimeChange = (second, isTimeout) => {
 *   if(second <= 10 && second > 0 && !isTimeout) {
 *       this.setState({
 *           color: 'red',
 *       });
 *   }else if(second <= 0) {
 *       this.setState({
 *           color: 'red',
 *           status: 'timeout',
 *           title: 'xx超时'
 *       });
 *   }
 * }
 */

export class Countdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            second: undefined,
            minute: undefined,
            timeout: false,
            needChange: true,
        };
        this.timer = null;
    }

    componentWillReceiveProps(props) {
        const { status, time, needChange } = props;
        const prevTime = this.props.time;
        if (status && status !== 'countdown' && status !== 'timeout' && this.timer) {
            clearInterval(this.timer);
        } else if (status === 'countdown' || status === 'timeout') {
            if (prevTime === time) {
                this.setState({
                    second: this.state.second !== undefined ? this.state.second : time / 1000,
                    minute:
                        this.state.minute !== undefined
                            ? this.state.minute
                            : status === 'countdown'
                            ? Math.ceil(time / (1000 * 60))
                            : Math.floor(time / (1000 * 60)),
                    timeout: status === 'timeout',
                    needChange,
                });
            } else {
                this.setState({
                    second: time / 1000,
                    minute:
                        status === 'countdown'
                            ? Math.ceil(time / (1000 * 60))
                            : Math.floor(time / (1000 * 60)),
                    timeout: status === 'timeout',
                    needChange,
                });
            }
        }
    }
    onTimeChange = (second, isTimeout) => {
        const { onTimeChange } = this.props;
        if (onTimeChange) {
            onTimeChange(second, isTimeout);
        }
    };
    componentDidMount() {
        const { time, status, needChange } = this.props;
        this.setState({
            second: time / 1000,
            minute:
                status === 'countdown'
                    ? Math.ceil(time / (1000 * 60))
                    : Math.floor(time / (1000 * 60)),
            timeout: status === 'timeout',
            needChange,
        });
        this.timer = setInterval(() => {
            if (this.state.timeout) {
                this.increase();
                this.onTimeChange(this.state.second, this.state.timeout);
            } else {
                if (this.state.second <= 0) {
                    if (this.state.needChange) {
                        this.setState({
                            timeout: true,
                        });
                        this.increase();
                        this.onTimeChange(this.state.second, this.state.timeout);
                    } else {
                        clearInterval(this.timer);
                    }
                } else {
                    this.decrease();
                    this.onTimeChange(this.state.second, this.state.timeout);
                }
            }
        }, 1000);
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
    decrease() {
        const { second } = this.state;
        this.setState({
            second: second - 1,
            minute: second <= 1 ? Math.floor(second / 60) : Math.ceil(second / 60),
        });
    }
    increase() {
        const { second } = this.state;
        this.setState({
            second: second + 1,
            minute: second < 0 ? 0 : Math.floor(second / 60),
        });
    }
    render() {
        const { title, color, status } = this.props;
        const timer = <div>{this.state.minute === 0 ? '<1' : this.state.minute}分钟</div>;
        return (
            <div className={classNames('countdown-box', color)}>
                <div>{title}</div>
                {status === 'countdown' || status === 'timeout' ? timer : null}
            </div>
        );
    }
}

import { services } from '@comall-backend-builder/core/lib';
import { forEach } from 'lodash';

export default [
    {
        key: '/dc-master-data/admin/organizations',
        loader: {
            get: async function(data: any, config: any) {
                if (data.id) {
                    config.apiRoot = ENV.OAUTH_ROOT;
                    config.apiPath = `/dc-master-data/admin/organizations/${data.id}/children/findAllByLoginUser`;
                } else {
                    config.apiPath = '/dc-master-data/admin/organizations/findAllByLoginUser';
                }

                const result: any = await services.api.get(data, config);

                forEach(result, function(item) {
                    if (item.language === null && item.languageIsoCode === null) {
                        item.defaultLanguage = '-';
                    } else {
                        item.defaultLanguage = item.language + '(' + item.languageIsoCode + ')';
                    }
                    if (item.currency === null && item.currencyIsoCode === null) {
                        item.defaultCurrency = '-';
                    } else {
                        item.defaultCurrency = item.currency + '(' + item.currencyIsoCode + ')';
                    }
                    if (item.timeZone === null && item.zoneId === null) {
                        item.defaultTimeZone = '-';
                    } else {
                        item.defaultTimeZone = '(' + item.zoneId + ')' + item.timeZone;
                    }
                });
                return result;
            },
        },
    },
    {
        key: '/dc-master-data/admin/organizations/findAllByLoginUser',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(data, config);
                let organizationData: any = [];
                forEach(result, function(item) {
                    forEach(item.children, function(child) {
                        forEach(child.children, function(c) {
                            organizationData.push({
                                id: c.categoryId,
                                name: c.name,
                                code: c.categoryId,
                                division: c.divisionNumber,
                            });
                        });
                    });
                });
                return organizationData;
            },
        },
    },
];

import React, { createElement, useMemo } from 'react';
import { Modal as AntModal, message as AntMessage } from 'antd';
import { get } from 'lodash';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { CHANNEL } from '@/constants';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { checkPrivilege } from '@/components/privilege';
import { OrderListItem } from '@/components/order-list-item';
import LoyalForm from './confirm-delivery/confirm-delivery';
import { ORDER_STATUS_NAMES, ORDER_STATUS_ALL_VALUES } from '@/constants/order';
import { ConfirmCompletion } from './confirm-completion';
import { DeliverTheOrder } from './deliver-the-order';
import { SubmitFreightRefund } from './submit-freight-refund';
import { PickedUpConfirm } from './picked-up-confirm';
import { EditOrderShipTime } from './edit-order-ship-time';
import { API_MAP } from '@/constants/order/api-map';
import { formatUrl } from '@/constants/order/utils';
import { ORDER_TYPE, PATH_NAME } from '@/constants/order/enum';
import { PrintOrderButton } from '@/components/print-order-button';

/**
 * 添加备注按钮
 */
const addRemarksBtn = (props: any) => {
    const { id, config } = props;
    if (
        !checkPrivilege(
            config.type === ORDER_TYPE.BASE
                ? 'order.order.add_remark'
                : 'order_philips.order_philips.add_remark_philips'
        )
    ) {
        return null;
    }
    return {
        component: 'RemarkModal',
        buttonType: 'remarkButton',
        config: {
            orderNumber: id,
            type: config.type,
        },
    };
};

/**
 * 缺货配送按钮
 */
const packLackOrderBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (
        !checkPrivilege(
            config.type === ORDER_TYPE.BASE
                ? 'order.order.part_refund'
                : 'order_philips.order_philips.part_refund_philips'
        )
    ) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'stockOutDeliveryButton',
        config: {
            type: 'primary',
            text: services.language.getText('order.stockOutDelivery'),
            onClick: () => {
                const { entity } = parentProps;
                AntModal.confirm({
                    title: services.language.getText('order.stockOutDeliveryTitle'),
                    onOk() {
                        return services.api
                            .put({ id }, { apiPath: formatUrl(API_MAP.ORDER_PICKED, config.type) })
                            .then(() => {
                                entity.search(entity.params);
                                parentProps.fetchStatistics();
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

const loadWjaProducts: (orderId: string, type: string) => Promise<any> = async (
    orderId: string,
    type: string
) => {
    return await services.api.get(
        { orderId },
        {
            apiPath: formatUrl(API_MAP.CONFIRM_COMPLETED_INFO, type),
        }
    );
};

/**
 * 确认提货按钮
 * 确认配送按钮
 */
const confirmTheDeliveryBtn = (props: any) => {
    const { id, channel, parentProps, config } = props;
    if (
        !checkPrivilege(
            config.type === ORDER_TYPE.BASE
                ? 'order.order.confirm_order'
                : 'order_philips.order_philips.confirm_order_philips'
        )
    ) {
        return null;
    }
    const btnText =
        props.deliveryFlag === '1'
            ? services.language.getText('order.confirmDelivery')
            : services.language.getText('order.confirmPickup');
    let formRef = React.createRef();
    return {
        component: 'Button',
        buttonType: 'confirmPickupButton',
        config: {
            type: 'primary',
            text: btnText,
            onClick: async () => {
                const { entity } = parentProps;
                // eshop与guide-shopping需要展示填写loyaTNumber form
                const visiableLoyalForm = [CHANNEL.MACAU, CHANNEL.GUIDE_SHOPPING].includes(
                    get(channel, 'id')
                );
                let wjaProductList = [];
                try {
                    if (visiableLoyalForm) {
                        wjaProductList = (await loadWjaProducts(id, config.type)).wjaOrderItems;
                    }
                } catch (e) {}
                AntModal.confirm({
                    className: 'order-confirm-pick-up-modal',
                    title: services.language.getText('order.confirmPickupTitle'),
                    content: (
                        <div>
                            <span>{services.language.getText('order.confirmPickupContent')}</span>
                            {/* @ts-ignore */}
                            {visiableLoyalForm && (
                                <LoyalForm
                                    extraData={props}
                                    wrappedComponentRef={formRef}
                                    wjaProductList={wjaProductList}
                                />
                            )}
                        </div>
                    ),
                    async onOk() {
                        let submitData;
                        try {
                            //@ts-ignore
                            submitData = formRef && (await formRef.current.formSubmit());
                        } catch (e) {}

                        if (visiableLoyalForm && !submitData) {
                            return new Promise((resolve, reject) => {
                                reject();
                            });
                        }

                        const postData = visiableLoyalForm
                            ? { status: 'COMPLETED', ...submitData }
                            : { status: 'COMPLETED' };

                        return new Promise((resolve, reject) => {
                            services.api
                                .put(
                                    {
                                        ...postData,
                                        id,
                                    },
                                    { apiPath: formatUrl(API_MAP.ORDER_STATUS, config.type) }
                                )
                                .then(() => {
                                    entity.search(entity.params);
                                    resolve({});
                                })
                                .catch((e) => {
                                    errorHandle(e);
                                    reject();
                                });
                        });
                    },
                });
            },
        },
    };
};

/**
 * 退货退款按钮
 */
const refundsBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.returned')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'refundsButton',
        config: {
            type: 'primary',
            text: services.language.getText('order.refunds'),
            onClick: () => {
                const { entity } = parentProps;

                services.api
                    .get(
                        { id },
                        {
                            apiPath: formatUrl(API_MAP.ORDER_RETURNED_CHECK, config.type),
                        }
                    )
                    .then((res) => {
                        AntModal.confirm({
                            title: services.language.getText('order.refundsTitle'),
                            content: (
                                <>
                                    <p style={{ color: 'red' }}>
                                        {services.language.getText('order.refundsTip')}
                                    </p>
                                    {!res && (
                                        <p style={{ color: 'red' }}>
                                            {services.language.getText('order.refundsConfirmTip')}
                                        </p>
                                    )}
                                </>
                            ),
                            onOk() {
                                return services.api
                                    .put(
                                        { id },
                                        { apiPath: formatUrl(API_MAP.ORDER_RETURNED, config.type) }
                                    )
                                    .then(() => {
                                        entity.search(entity.params);
                                    })
                                    .catch(errorHandle);
                            },
                        });
                    })
                    .catch((e) => {
                        errorHandle(e);
                    });
            },
        },
    };
};

/**
 * 重发订单按钮
 */
const resendTheOrderBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.resend_order')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'resendOrderButton',
        config: {
            text: services.language.getText('order.resendOrder'),
            type: 'primary',
            onClick: () => {
                AntModal.confirm({
                    title: services.language.getText('order.resendOrderTitle'),
                    onOk() {
                        return services.api
                            .put(
                                { id },
                                {
                                    apiPath: formatUrl(API_MAP.ORDER_RETRY, config.type),
                                }
                            )
                            .then(() => {
                                const { entity } = parentProps;
                                AntMessage.success(
                                    services.language.getText('order.resendOrderTip')
                                );
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 重发退款请求按钮
 */
const resendTheRefundRequestBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.refund_again')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'continueRefundButton',
        config: {
            text: services.language.getText('order.reReturnOrder'),
            type: 'primary',
            onClick: () => {
                const { entity } = parentProps;
                AntModal.confirm({
                    title: services.language.getText('order.reReturnOrderTitle'),
                    content: (
                        <span style={{ color: 'red' }}>
                            {services.language.getText('order.reReturnOrderContent')}
                        </span>
                    ),
                    onOk() {
                        return services.api
                            .put(
                                { id },
                                {
                                    apiPath: formatUrl(API_MAP.CONTINUE_TO_REFUNF, config.type),
                                }
                            )
                            .then(() => {
                                AntMessage.success(
                                    services.language.getText('order.reReturnOrderSuccess')
                                );
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 已完成退款按钮
 */
const refundCompletedBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.confirm_refund')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'confirmRefundedButton',
        config: {
            text: services.language.getText('order.returnOrder'),
            type: 'primary',
            onClick: () => {
                const { entity } = parentProps;
                AntModal.confirm({
                    title: services.language.getText('order.returnOrderTitle'),
                    content: (
                        <span style={{ color: 'red' }}>
                            {services.language.getText('order.returnOrderContent')}
                        </span>
                    ),
                    onOk() {
                        return services.api
                            .put(
                                { id },
                                {
                                    apiPath: formatUrl(API_MAP.CONFIRM_REUFUNDED, config.type),
                                }
                            )
                            .then(() => {
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};
/**
 * P重发退款请求按钮
 */
const resendThePRefundRequestBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.resend_the_part_refund_request')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'continueRefundButton',
        config: {
            text: services.language.getText('order.rePReturnOrder'),
            type: 'primary',
            onClick: () => {
                const { entity } = parentProps;
                AntModal.confirm({
                    title: services.language.getText('order.reReturnOrderTitle'),
                    content: (
                        <span style={{ color: 'red' }}>
                            {services.language.getText('order.reReturnOrderContent')}
                        </span>
                    ),
                    onOk() {
                        return services.api
                            .put(
                                { id },
                                {
                                    apiPath: formatUrl(
                                        API_MAP.CONTINUE_TO_PART_REFUND,
                                        config.type
                                    ),
                                }
                            )
                            .then(() => {
                                AntMessage.success(
                                    services.language.getText('order.reReturnOrderSuccess')
                                );
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * P已完成退款按钮
 */
const refundPCompletedBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (
        !checkPrivilege(
            config.type === ORDER_TYPE.BASE
                ? 'order.order.part_refund_completed'
                : 'order_philips.order_philips.part_refund_completed_philips'
        )
    ) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'confirmRefundedButton',
        config: {
            text: services.language.getText('order.returnPOrder'),
            type: 'primary',
            onClick: () => {
                const { entity } = parentProps;
                AntModal.confirm({
                    title: services.language.getText('order.returnOrderTitle'),
                    content: (
                        <span style={{ color: 'red' }}>
                            {services.language.getText('order.returnOrderContent')}
                        </span>
                    ),
                    onOk() {
                        return services.api
                            .put(
                                { id },
                                {
                                    apiPath: formatUrl(API_MAP.CONFIRM_PART_REUFUNDED, config.type),
                                }
                            )
                            .then(() => {
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};
/**
 * 取消订单按钮
 */
const cancelTheOrderBtn = (props: any) => {
    const { id, outerOrderNumber, orderStatus, channel, parentProps, config } = props;
    if (
        !checkPrivilege(
            config.type === ORDER_TYPE.BASE
                ? 'order.order.cancel_order'
                : 'order_philips.order_philips.cancel_order_philips'
        )
    ) {
        return null;
    }

    return {
        component: 'CancelOrderModal',
        buttonType: 'cancelOrderButton',
        config: {
            orderNumber: id,
            outerOrderNumber,
            orderStatus,
            channel,
            parentProps,
            menuType: 'order',
            type: config.type,
        },
    };
};

/**
 * 修改订单按钮
 */
const updateTheOrdersBtn = (props: any) => {
    const { id, orderStore, parentProps, config } = props;
    if (!checkPrivilege('order.order.modify_consignor')) {
        return null;
    }
    return {
        component: 'PickupDate',
        buttonType: 'modifyOrderButton',
        config: {
            id: get(orderStore, 'pickOnlineStoreId'),
            orderId: id,
            entity: parentProps.entity,
            params: parentProps.entity.params,
            type: config.type,
        },
    };
};

/**
 * 确认商品入库按钮
 */
const confirmReceiptOfGoodsBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.goods_warehouse"')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'putInButton',
        config: {
            type: 'primary',
            text: services.language.getText('order.confirmReceiptOfGoods'),
            onClick: () => {
                const { entity } = parentProps;
                AntModal.confirm({
                    title: services.language.getText('order.confirmReceiptOfGoodsTitle'),
                    content: services.language.getText('order.confirmReceiptOfGoodsTip'),
                    onOk() {
                        return services.api
                            .put(
                                { id },
                                {
                                    apiPath: formatUrl(API_MAP.ORDER_GOODS, config.type),
                                }
                            )
                            .then(() => {
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * loctoloc 按钮
 */
const locToLocBtn = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.loc_to_loc')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'putInButton',
        config: {
            type: 'primary',
            text: services.language.getText('order.locToLoc'),
            onClick: () => {
                const { entity } = parentProps;
                return services.api
                    .put(
                        { id },
                        {
                            apiPath: formatUrl(API_MAP.LOC_TO_LOC_RETRY, config.type),
                        }
                    )
                    .then(() => {
                        entity.search(entity.params);
                    })
                    .catch(errorHandle);
            },
        },
    };
};

/**
 * 重发pos
 */
const ResendTheOrderPos = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.order_to_pos')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'completePackButton',
        config: {
            type: 'primary',
            text: '重发pos',
            onClick: () => {
                return services.api
                    .put(
                        {},
                        { apiPath: formatUrl(`${API_MAP.POS_RESEND}?orderId=${id}`, config.type) }
                    )
                    .then(() => {
                        const { entity } = parentProps;
                        entity.search(entity.params);
                    })
                    .catch(errorHandle);
            },
        },
    };
};
//重发POS
const OrderCancelToPos = (props: any) => {
    const { id, parentProps, config } = props;
    if (!checkPrivilege('order.order.order_cancel_to_pos')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'completePackButton',
        config: {
            type: 'primary',
            text: services.language.getText('order.orderCancelToPos'),
            onClick: () => {
                return services.api
                    .put(
                        { id },
                        {
                            apiPath: formatUrl(API_MAP.ORDER_CANCEL_TO_POS_RETRY, config.type),
                        }
                    )
                    .then(() => {
                        const { entity } = parentProps;
                        entity.search(entity.params);
                    })
                    .catch(errorHandle);
            },
        },
    };
};

// 线下支付已收款
const receivedPayment = (props: any) => {
    const { parentProps, id, config } = props;
    if (config.type === ORDER_TYPE.PHILIPS) return null;
    if (!checkPrivilege('order.order.order_received_payment')) {
        return null;
    }

    return {
        component: 'ReceivedPaymentModal',
        buttonType: 'receivedPaymentModal',
        config: {
            parentProps,
            id,
            config,
        },
    };
};

const showPaymentVoucherBtn = (props: any) => {
    const { parentProps, parentOrderNumber, payableAmount, concludeAmount } = props;
    if (!checkPrivilege('order.order.show_order_payment_voucher')) {
        return null;
    }
    return {
        component: 'ShowPaymentVoucher',
        buttonType: 'showPaymentVoucher',
        config: {
            parentProps,
            parentOrderNumber,
            payableAmount,
            concludeAmount,
        },
    };
};

/**
 * 显示按钮映射
 */
const showBtnMap: AnyObject = {
    deliverTheOrder: {
        component: DeliverTheOrder,
    },
    confirmCompletion: {
        component: ConfirmCompletion,
    },
    pickedUpConfirm: {
        component: PickedUpConfirm,
    },
    packLackOrder: packLackOrderBtn,
    // packagingComplete: packagingCompleteBtn,
    confirmTheDelivery: confirmTheDeliveryBtn,
    editOrderShipTime: {
        component: EditOrderShipTime,
    },
    refunds: refundsBtn,
    resendTheOrder: resendTheOrderBtn,
    resendTheRefundRequest: resendTheRefundRequestBtn,
    refundCompleted: refundCompletedBtn,
    confirmReceiptOfGoods: confirmReceiptOfGoodsBtn,
    locToLoc: locToLocBtn,
    updateTheOrder: updateTheOrdersBtn,
    cancelTheOrder: cancelTheOrderBtn,
    orderToPos: ResendTheOrderPos,
    orderCancelToPos: OrderCancelToPos,
    resendThePartRefundRequest: resendThePRefundRequestBtn,
    partRefundCompleted: refundPCompletedBtn,
    addRemarks: addRemarksBtn,
    submitFreightRefund: {
        component: SubmitFreightRefund,
    },
    printOrder: {
        component: PrintOrderButton,
    },
    showPaymentVoucher: showPaymentVoucherBtn,
    receivedPayment,
};

export const OrderDataListItem: React.FC<any> = (props) => {
    const getItemHeader = () => {
        const {
            merchantName,
            pickUpInfo = {},
            partner,
            taxFlag,
            payBeforeTime,
            saDepositFlag,
            deliveryType,
            memberLabel,
        } = props;
        return {
            partner: partner,
            merchantName,
            memberLabel,
            deliveryType,
            waitDeliveryTime: get(pickUpInfo, 'preDeliveryTime', '-'),
            preCanPickUpTime: get(pickUpInfo, 'preCanPickUpTime'),
            taxFlag,
            payBeforeTime,
            saDepositFlag,
        };
    };

    const getItemContent = () => {
        const {
            orderStatus,
            displayQuantity,
            displayAmount,
            dealAmount,
            id,
            posOrderNumber,
            txnOrderNumber,
            storeInfo = {},
            stockInfo = {},
            pickedAmount,
            exceptions,
            saInfo,
            orderStore,
            createTime,
            transportation,
            frightNo,
            departureDate,
            depositProportion,
            remainAmount,
            taxFlag,
            parentOrderNumber,
            depositAmount,
            touchPoint,
            channel,
            orderCustomer,
            receiveAddress,
            ...rest
        } = props;

        const status = {
            title: ORDER_STATUS_NAMES[orderStatus],
            color:
                [ORDER_STATUS_ALL_VALUES.CANCELLED, ORDER_STATUS_ALL_VALUES.REFUNDED].indexOf(
                    orderStatus
                ) > -1
                    ? 'transparent'
                    : 'green',
        };

        return {
            status,
            info: {
                number: displayQuantity,
                id,
                posOrderNumber: posOrderNumber,
                txnOrderNumber: txnOrderNumber,
                customer: orderCustomer,
                storeName: get(storeInfo, 'name', ''),
                stock: stockInfo,
                pickedAmount,
                exceptions,
                saInfo,
                orderStore,
                createTime,
                transportation,
                frightNo,
                departureDate,
                depositProportion,
                remainAmount,
                taxFlag,
                parentOrderNumber,
                depositAmount,
                receiveAddress,
                ...rest,
            },
        };
    };

    const getItemFooter = () => {
        return `${services.language.getText(
            'order.orderListItem.expressDeliveryTime'
        )}：${props.expressDeliveryTime || '-'}`;
    };

    const renderOperational = useMemo(() => {
        const { showButtons } = props;
        return (
            <div
                style={{
                    display: 'flex',
                    gap: '15px',
                }}
            >
                {Object.keys(showBtnMap).map((key) => {
                    if (showButtons[key]) {
                        const getConfig = showBtnMap[key];

                        // react component
                        if (getConfig.component) {
                            return createElement(
                                getConfig.component,
                                Object.assign(
                                    {
                                        key,
                                    },
                                    props
                                )
                            );
                        }

                        const config = getConfig(props);
                        if (config) {
                            return createElement(
                                ComponentsManager.get(config.component),
                                Object.assign(
                                    {
                                        key,
                                    },
                                    config.config
                                )
                            );
                        }
                    }
                    return null;
                })}
            </div>
        );
    }, [props.showButtons]);

    return (
        <OrderListItem
            key={props.id}
            header={getItemHeader()}
            content={getItemContent()}
            footer={getItemFooter()}
            renderOperational={() => renderOperational}
            detailPath={get(props.config, 'pathname', PATH_NAME.ORDER_PATH_NAME)}
        />
    );
};

import { generateOptions } from '@/services/generate-options';
import { EntityPropertyOption } from '@comall-backend-builder/core/lib/parser';
import { language } from '@comall-backend-builder/core/lib/services';
// 线上门店localStorage存储的key
export const STORE_TREE_STORAGE_KEY = 'store-tree-selected';
export const PHILIPS_STORE_TREE_STORAGE_KEY = 'philips-store-tree-selected';

/**
 * 运费管理	金额统计范围
 */
export enum DISTRIBUTION_AMOUNT_STATUS_VALUES {
    SELF_FINANCING = '0',
    ALL = '1',
}
/**
 * 运费管理	金额统计范围名
 */
export const DISTRIBUTION_AMOUNT_STATUS_NAMES = {
    [DISTRIBUTION_AMOUNT_STATUS_VALUES.SELF_FINANCING]: language.getText(
        'storeFreightRules.selfFinancing'
    ),
    [DISTRIBUTION_AMOUNT_STATUS_VALUES.ALL]: language.getText('storeFreightRules.all'),
};
/**
 * 运费管理	金额统计范围候选项
 */
export const DISTRIBUTION_AMOUNT_STATUS_OPTIONS = (generateOptions(
    DISTRIBUTION_AMOUNT_STATUS_VALUES,
    DISTRIBUTION_AMOUNT_STATUS_NAMES
) as unknown) as EntityPropertyOption[];

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { Select as AntSelect } from 'antd';
import { map } from 'lodash';

export interface Option {
    /**
     * 候选项id
     */
    id: string;
    /**
     * 候选项显示名称
     */
    name: string;
}
interface productTagProps {
    /**
     * 候选项集合
     */
    options: Array<Option>;
}
/**
 * 状态类型
 */
export class ProductTagComboSelect extends modes.ObjectMode {
    getControlComponent(controlInfo: any) {
        return <ComboSelect {...controlInfo} />;
    }
}

class ComboSelect extends React.PureComponent<productTagProps, any> {
    render() {
        const children = map(this.props.options, (option) => (
            <AntSelect.Option key={option.id}>{option.name}</AntSelect.Option>
        ));
        return (
            <div>
                <div>
                    <AntSelect>{children}</AntSelect>
                </div>
            </div>
        );
    }
}

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';

const { interpolate } = services;
/**
 * 表格中的a
 */
export class LinkTextMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, dispalyInfo: any) {
        const { path } = dispalyInfo;
        return (
            <a href={interpolate(path, dispalyInfo)} target='_blank' rel='noopener noreferrer'>
                {value}
            </a>
        );
    }
}

import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { services } from '@comall-backend-builder/core';
import './index.less';

enum InputType {
    weight = 'weight',
    step = 'step',
    freight = 'freight',
}

export class WeightStepFreight extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { valueInfo: {} };
    }
    onInputValueChange(event: any, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue: number = event;
        if (value !== null) {
            if (type === InputType.weight) {
                this.setState({
                    valueInfo: {
                        ...this.state.valueInfo,
                        startingWeight: inputValue,
                    },
                });
            }
            if (type === InputType.step) {
                this.setState({
                    valueInfo: { ...this.state.valueInfo, stepWeight: inputValue },
                });
            }
            if (type === InputType.freight) {
                this.setState({
                    valueInfo: { ...this.state.valueInfo, stepFreight: inputValue },
                });
            }
        }
        value = this.state.valueInfo;

        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { value } = this.props;
        return (
            <div>
                <span className='step'>
                    <span>{services.language.getText('storeFreightRules.startingWeight')}</span>
                    <InputNumber
                        className='step-input'
                        onChange={(e) => this.onInputValueChange(e, InputType.weight)}
                        value={value?.startingWeight}
                        precision={2}
                        max={99999999}
                        min={0}
                    />
                    <span>KG</span>
                    <span className='step-text'>
                        {services.language.getText('storeFreightRules.stepWeight')}
                    </span>
                    <InputNumber
                        className='step-input'
                        onChange={(e) => this.onInputValueChange(e, InputType.step)}
                        value={value?.stepWeight}
                        precision={2}
                        max={99999999}
                        min={0}
                    />
                    <span>KG</span>
                    <span className='step-text'>
                        {services.language.getText('storeFreightRules.stepFreight')}
                    </span>
                    <InputNumber
                        className='step-input'
                        onChange={(e) => this.onInputValueChange(e, InputType.freight)}
                        value={value?.stepFreight}
                        precision={2}
                        max={99999999}
                        min={0}
                    />
                    <span>{services.language.getText('storeFreightRules.yuan')}</span>
                </span>
            </div>
        );
    }
}

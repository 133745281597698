import builder from '@comall-backend-builder/core';
import { Button as AntButton } from 'antd';

import { Upload } from './upload';
import { FilterFormPlus } from './filter-form-plus';
import { OrganizationsPage } from './organizations';
import { AddStoreForm } from './add-store-form';
import { TagForm } from './tag-form';
import { SelectTimeRangesPicker } from './select-time-ranges-picker';
import { MacauTimeRangesPicker } from './macau-time-ranges-picker';
import { ChannelCategoryView } from './channel-category-view';
import { Login } from './login';
import { Logout } from './logout';
import { UserProfileMini } from './user-profile-mini';
import { NoAuthorizationRefresh } from './no-authorization-refresh';
import { MemorableTabs } from './memorable-tabs';
import { TimeSinglePicker } from './time-single-picker';
import { StoreChannelForm } from './pick-online-store-form';
import { PopoverWrapper } from './popover-wrapper';
import { BrandBlacklistDetail } from './brand-blacklist-detail';
import { LimitTipInfo } from './limit-tip-info';
import { ProductGroupForm } from './product-group-form';
import { DataList } from './data-list';
import { OrderTabsFilter } from './order-tabs-filter';
import { OrderDataListItem } from './order-data-list-item';
import { RemarkModal } from './remark-modal';
import { PickingAssistant } from './picking/picking-assistant';
import { PickingAssistantDetail } from './picking/picking-detail';
import { PickingAssistantTabPaneWrapper } from './picking/picking-assistant/render-item-wrapper';
import { ProductForm } from './product-form';
import { CouponImageUpload } from './coupon-image-upload';
import {
    WaitPickItem,
    BatchPickingAndPrintButton,
    PickingItem,
    BatchFinishPickingAndPrintButton,
    StockOutItem,
    WaitPackageItem,
    WaitPackageBatchActions,
    WaitPickupItem,
    CancelledOrderItem,
    AllOrderItem,
    WaitPickupBatchActions,
} from './picking/picking-assistant/render-items';
import { ProductInfo } from './product-info';
import { ProductBasic } from './product-basic';
import { ProductDisplay } from './product-display';
import { ProductMarket } from './product-market';
import { ProductAllAttributes } from './product-all-attributes';
import { OrderInfoBasic } from './order-info-basic';
import { OrderInfoCheckout } from './order-info-checkout';
import { OrderInfoProgress } from './order-info-progress';
import { OrderInfoRemark } from './order-info-remark';
import { OrderInfoProduct } from './order-info-product';
import { MarkGroupDelete } from './mark-group-delete';
import { MarkGroupOperateProductButton } from './mark-group-operate-product-button';
import { MarkGroupExportButton } from './mark-group-export-button';
import { ExportMarkGroupButtonContent } from './export-mark-group-button-content';
import { TableActionChangePassword } from './privilege/users';
import { RouteWithChannelButton } from './route-with-channel-button';
import { SelectMemberDiscountButton } from './select-member-discount-button';
import { DiscountRemarkContent, DiscountRemarkTitle } from './select-member-discount-remarks';
import { ProductLimitTable } from './product-limit-table';
import PaymentDetail from './pyament-detail';
import { AutoCancelForm } from './auto-cancel-form';
import { TableActionChangeSaPassword } from './sa-changepwd';
import { OnlineStoreForm } from './online-store-form';
import { ProductShelfButton } from './product-shelf/shelf-button';
import { ProductShelfTableActionButton } from './product-shelf/table-action-shelf-button';
import { ProductShelfLogs } from './product-shelf/shelf-logs';
import { ProductShelfList } from './product-shelf/shelf-list';
import { GuideShoppingDiscountList } from './promotion/guide-shopping-discount/list';
import { GuideShoppingDiscountTableActionEdit } from './promotion/guide-shopping-discount/table-action-edit';
import { OrderReceipt } from './order-receipt-template';
import { LoginBack } from './login-back';
import { SsoLogin } from './sso-login';
import { ProductPriceList } from './product-price';
import { RouteWithStoreButton } from './route-with-store-button';
import { CategorySelectorContainer } from './category-selector-container';
import { StoreSelectorContainer } from './store-selector-container';
import { DistributionForm } from './distribution-form';
import { RefundTabsFilter } from './refund-tabs-filter';
import { RefundDataListItem } from './refund-data-list-item';
import {
    OfflineOrderTabsFilter,
    OfflineOrderDataListItem,
    OfflineCreateOrderMember,
    OfflineCreateForm,
} from './offline-order';

import { ProductInventorySupplementForm } from './product-inventory-supplement-form';
import { ProductInventoryDeductionsForm } from './product-inventory-deductions-form';

import { MembershipOrderModal, MembershipBaseInfo, MembershipPoint } from './membership';
import { OverrideCodeForm } from './override-code-form';
import { EvaluationList } from './commodity-evaluation/evaluation-list';
import { EvaluationButton } from './commodity-evaluation/evaluation-button';
import { EvaluationModal } from './commodity-evaluation/evaluation-modal';
import { BatchAuditButton } from './commodity-evaluation/batch-audit-button';

import {
    AfterSaleTabsFilter,
    AfterSaleDataListItem,
    AfterSaleRefundItem,
    AfterSaleInfoBasic,
    AfterInfoRemark,
    AfterSaleCreateForm,
    AfterSaleCreateRemark,
    AfterSaleCreateProduct,
} from './after-sale-order';

import { SalesclerkForm } from './salesclerk-form';

import { SelfViewport } from './self-viewport';

import { SelfHarversting } from './self-harversting';

export { Upload };
export * from './select-time-ranges-picker';
export * from './macau-time-ranges-picker';
export * from './time-single-picker';

builder.registerComponent('DistributionForm', DistributionForm);
builder.registerComponent('ProductGroupForm', ProductGroupForm);
export * from './delivery-freight-rule-set-up';

builder.registerComponent('SelfViewport', SelfViewport);

builder.registerComponent('LoginBack', LoginBack);
builder.registerComponent('Upload', Upload);
builder.registerComponent('FilterFormPlus', FilterFormPlus);
builder.registerComponent('OrganizationsPage', OrganizationsPage);
builder.registerComponent('AntButton', AntButton);
builder.registerComponent('AddStoreForm', AddStoreForm);
builder.registerComponent('TagForm', TagForm);
builder.registerComponent('SelectTimeRangesPicker', SelectTimeRangesPicker);
builder.registerComponent('MacauTimeRangesPicker', MacauTimeRangesPicker);
builder.registerComponent('ChannelCategoryView', ChannelCategoryView);
builder.registerComponent('MemorableTabs', MemorableTabs);
builder.registerComponent('Login', Login);
builder.registerComponent('Logout', Logout);
builder.registerComponent('UserProfileMini', UserProfileMini);
builder.registerComponent('NoAuthorizationRefresh', NoAuthorizationRefresh);
builder.registerComponent('TimeSinglePicker', TimeSinglePicker);
builder.registerComponent('StoreChannelForm', StoreChannelForm);
builder.registerComponent('PopoverWrapper', PopoverWrapper);
builder.registerComponent('BrandBlacklistDetail', BrandBlacklistDetail);
builder.registerComponent('LimitTipInfo', LimitTipInfo);
builder.registerComponent('DataList', DataList);
builder.registerComponent('OrderTabsFilter', OrderTabsFilter);
builder.registerComponent('OrderDataListItem', OrderDataListItem);
builder.registerComponent('RemarkModal', RemarkModal);
builder.registerComponent('PickingAssistant', PickingAssistant);
builder.registerComponent('PickingAssistantDetail', PickingAssistantDetail);
builder.registerComponent('PickingAssistantTabPaneWrapper', PickingAssistantTabPaneWrapper);
builder.registerComponent('WaitPackageBatchActions', WaitPackageBatchActions);
builder.registerComponent('WaitPickupBatchActions', WaitPickupBatchActions);
builder.registerComponent('BatchPickingAndPrintButton', BatchPickingAndPrintButton);
builder.registerComponent('BatchFinishPickingAndPrintButton', BatchFinishPickingAndPrintButton);
builder.registerComponent('WaitPickItem', WaitPickItem);
builder.registerComponent('PickingItem', PickingItem);
builder.registerComponent('StockOutItem', StockOutItem);
builder.registerComponent('WaitPackageItem', WaitPackageItem);
builder.registerComponent('WaitPickupItem', WaitPickupItem);
builder.registerComponent('CancelledOrderItem', CancelledOrderItem);
builder.registerComponent('AllOrderItem', AllOrderItem);
builder.registerComponent('ProductInfo', ProductInfo);
builder.registerComponent('ProductBasic', ProductBasic);
builder.registerComponent('ProductDisplay', ProductDisplay);
builder.registerComponent('ProductMarket', ProductMarket);
builder.registerComponent('ProductAllAttributes', ProductAllAttributes);
builder.registerComponent('OrderInfoBasic', OrderInfoBasic);
builder.registerComponent('OrderInfoCheckout', OrderInfoCheckout);
builder.registerComponent('OrderInfoProgress', OrderInfoProgress);
builder.registerComponent('OrderInfoRemark', OrderInfoRemark);
builder.registerComponent('OrderInfoProduct', OrderInfoProduct);
builder.registerComponent('MarkGroupDelete', MarkGroupDelete);
builder.registerComponent('MarkGroupOperateProductButton', MarkGroupOperateProductButton);
builder.registerComponent('MarkGroupExportButton', MarkGroupExportButton);
builder.registerComponent('ExportMarkGroupButtonContent', ExportMarkGroupButtonContent);
builder.registerComponent('TableActionChangePassword', TableActionChangePassword);
builder.registerComponent('RouteWithChannelButton', RouteWithChannelButton);
builder.registerComponent('SelectMemberDiscountButton', SelectMemberDiscountButton);
builder.registerComponent('DiscountRemarkContent', DiscountRemarkContent);
builder.registerComponent('DiscountRemarkTitle', DiscountRemarkTitle);
builder.registerComponent('ProductLimitTable', ProductLimitTable);
builder.registerComponent('PaymentDetail', PaymentDetail);
builder.registerComponent('AutoCancelForm', AutoCancelForm);
builder.registerComponent('TableActionChangeSaPassword', TableActionChangeSaPassword);
builder.registerComponent('OnlineStoreForm', OnlineStoreForm);
builder.registerComponent('ProductShelfList', ProductShelfList);
builder.registerComponent('ProductShelfButton', ProductShelfButton);
builder.registerComponent('ProductShelfTableActionButton', ProductShelfTableActionButton);
builder.registerComponent('ProductShelfLogs', ProductShelfLogs);
builder.registerComponent('ProductForm', ProductForm);
builder.registerComponent('GuideShoppingDiscountList', GuideShoppingDiscountList);
builder.registerComponent(
    'GuideShoppingDiscountTableActionEdit',
    GuideShoppingDiscountTableActionEdit
);
builder.registerComponent('CouponImageUpload', CouponImageUpload);
builder.registerComponent('OrderReceipt', OrderReceipt);
builder.registerComponent('ProductPriceList', ProductPriceList);
builder.registerComponent('RouteWithStoreButton', RouteWithStoreButton);
builder.registerComponent('CategorySelectorContainer', CategorySelectorContainer);
builder.registerComponent('StoreSelectorContainer', StoreSelectorContainer);
builder.registerComponent('AfterSaleTabsFilter', AfterSaleTabsFilter);
builder.registerComponent('AfterSaleDataListItem', AfterSaleDataListItem);
builder.registerComponent('AfterSaleRefundItem', AfterSaleRefundItem);
builder.registerComponent('AfterSaleInfoBasic', AfterSaleInfoBasic);
builder.registerComponent('AfterInfoRemark', AfterInfoRemark);
builder.registerComponent('AfterSaleCreateForm', AfterSaleCreateForm);
builder.registerComponent('AfterSaleCreateRemark', AfterSaleCreateRemark);
builder.registerComponent('AfterSaleCreateProduct', AfterSaleCreateProduct);
builder.registerComponent('RefundTabsFilter', RefundTabsFilter);
builder.registerComponent('RefundDataListItem', RefundDataListItem);
builder.registerComponent('OfflineOrderTabsFilter', OfflineOrderTabsFilter);
builder.registerComponent('OfflineOrderDataListItem', OfflineOrderDataListItem);
builder.registerComponent('OfflineCreateOrderMember', OfflineCreateOrderMember);
builder.registerComponent('OfflineCreateBasicForm', OfflineCreateForm);

builder.registerComponent('ProductInventorySupplementForm', ProductInventorySupplementForm);
builder.registerComponent('ProductInventoryDeductionsForm', ProductInventoryDeductionsForm);

builder.registerComponent('MembershipOrderModal', MembershipOrderModal);
builder.registerComponent('MembershipBaseInfo', MembershipBaseInfo);
builder.registerComponent('MembershipPoint', MembershipPoint);
builder.registerComponent('OverrideCodeForm', OverrideCodeForm);
builder.registerComponent('SalesclerkForm', SalesclerkForm);
builder.registerComponent('EvaluationList', EvaluationList);
builder.registerComponent('EvaluationButton', EvaluationButton);
builder.registerComponent('EvaluationModal', EvaluationModal);
builder.registerComponent('BatchAuditButton', BatchAuditButton);
builder.registerComponent('SsoLogin', SsoLogin);
builder.registerComponent('SelfHarversting', SelfHarversting);

import React from 'react';
import { PROVISIONING_OBJECTS_VALUES, RELEASE_PERIOD_VALUES } from '@/constants/voucher-management';

export function buildForm(mode: string) {
    return {
        component: 'SuperForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'cards',
                itemLayout: 'vertical',
                key: 'cards',
                split: false,
                items: [
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.voucherManagement.base>>',
                        key: 'cards.base',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'name',
                                key: 'name',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'effectTime',
                                key: 'effectTime',
                                disabled: mode === 'edit' ? true : false,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'mainDistributionStoreId',
                                key: 'mainDistributionStoreId',
                                disabled: mode === 'edit' ? true : false,
                                fieldOpts: {
                                    rules: [{ required: true }],
                                },
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.voucherManagement.rewardSetting>>',
                        key: 'cards.rewardSetting',
                        disabled: mode === 'edit' ? true : false,
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'provisioningObjects',
                                key: 'provisioningObjects',
                                disabled: mode === 'edit' ? true : false,
                                fieldOpts: {
                                    initialValue: PROVISIONING_OBJECTS_VALUES.MEMBERS,
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'amountPayment',
                                key: 'amountPayment',
                                disabled: mode === 'edit' ? true : false,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: /^[1-9]\d{0,5}$/,
                                            message: '<<errorMessages.pleaseInputPositive>>',
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'releasePeriod',
                                key: 'releasePeriod',
                                disabled: mode === 'edit' ? true : false,
                                formItem: {
                                    help: React.createElement('div', null, [
                                        React.createElement(
                                            'div',
                                            null,
                                            '<<couponManage.voucherManagement.helpWeek>>'
                                        ),
                                        React.createElement(
                                            'div',
                                            null,
                                            '<<couponManage.voucherManagement.helpMonth>>'
                                        ),
                                    ]),
                                },
                                fieldOpts: {
                                    initialValue: RELEASE_PERIOD_VALUES.MONTH,
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'numberIssues',
                                key: 'numberIssues',
                                disabled: mode === 'edit' ? true : false,
                                fieldOpts: {
                                    rules: [
                                        { required: true },
                                        {
                                            pattern: /^[1-9]\d{0,5}$/,
                                            message: '<<errorMessages.pleaseInputPositive>>',
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        renderType: 'cardPane',
                        title: '<<couponManage.voucherManagement.groupRewards.title>>',
                        key: 'cards.groupRewards',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'items',
                                key: 'items',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };
}

/*
 * @Author: zhulu
 * @Date: 2021-04-23 15:15:52
 * @Descripttion: 订单确认提货的表单的WJA商品信息
 */
import React, { useEffect } from 'react';
import { Input, Select } from 'antd';
import { get, cloneDeep } from 'lodash';
import classnames from 'classnames';
import { services } from '@comall-backend-builder/core';
import './index.less';

const { Option } = Select;
const metalOption = [
    { id: '18K ROSE GOLD', name: '18K ROSE GOLD' },
    { id: '18K WHITE GOLD', name: '18K WHITE GOLD' },
    { id: '18K GOLD PLATED & SS', name: '18K GOLD PLATED & SS' },
    { id: 'PLATINUM (950)', name: 'PLATINUM (950)' },
    { id: 'YELLOW GOLD', name: 'YELLOW GOLD' },
    { id: 'ROSE GOLD', name: 'ROSE GOLD' },
    { id: 'WHITE GOLD', name: 'WHITE GOLD' },
];
const jewelOption = [
    { id: 'DIAMOND IS MOUNTED', name: 'DIAMOND IS MOUNTED' },
    { id: 'DIAMOND IS NOT MOUNTED', name: 'DIAMOND IS NOT MOUNTED' },
];
const watchMovementOption = [
    { id: 'Manual', name: 'Manual' },
    { id: 'Automatic', name: 'Automatic' },
    { id: 'Quartz', name: 'Quartz' },
    { id: 'Complication Watch', name: 'Complication Watch' },
    { id: 'Not applicable', name: 'Not applicable' },
];
interface ProductItemI {
    pictureUrl: string;
    orderItemId: string;
    csku: string;
    productName: string;
    productType: string;
    salePrice: { amount: string; isocode: string; unit: string };
    styleId: string;
    styleDescription: string;
    brandName: string;
    quantity: number;
    lackQuantity: number;
    pickedQuantity: number;
    value?: any;
    rsku: string;
}

const ProductItem = ({
    data,
    onchange,
}: {
    data: ProductItemI;
    onchange: (value: any) => void;
}) => {
    const change = (value: any, name: string, ind: number) => {
        data.value[ind] = {
            ...data.value[ind],
            [name]: value,
        };
        onchange(data.value);
    };
    return (
        <div className='product-item'>
            <div
                className={classnames('product-item__img-wrapper', {
                    gift: data.productType === '2',
                })}
            >
                <img className='product-item__img' src={data.pictureUrl} alt='' />
            </div>
            <div style={{ width: '100%' }}>
                <div className='product-item__info'>
                    <div className='product-input-item'>
                        <span className='product-input-item__label'>
                            {services.language.getText('order.confirmTheDelivery.csku')}:
                            {data.csku || ''}
                        </span>
                        <span className='product-input-item__label-ellipsis'>
                            {services.language.getText('order.confirmTheDelivery.productName')}:
                            {data.productName || ''}
                        </span>
                    </div>
                    <div className='product-input-item'>
                        <span className='product-input-item__label'>
                            RSKU:
                            {data.rsku || ''}
                        </span>
                        <span className='product-input-item__label'>
                            {services.language.getText('order.confirmTheDelivery.productPrice')}:
                            {`${get(data, 'salePrice.unit') || ''} ${get(
                                data,
                                'salePrice.amount'
                            ) || ''}`}
                        </span>

                        <span className='product-input-item__label-ellipsis'>
                            {services.language.getText('order.confirmTheDelivery.quantity')}:
                            {data.pickedQuantity || 0}
                        </span>
                    </div>
                </div>
                {Array.from({ length: data.pickedQuantity || 0 }).map((_, ind) => {
                    return (
                        <div key={`product-item-${ind}`} className='line'>
                            <div className='mb'>StyleID: {data.styleId}</div>
                            <div className='product-input-item mb'>
                                <span className='product-input-item__label'>Serial No：</span>
                                <Input
                                    maxLength={100}
                                    placeholder='Serial No'
                                    value={get(data, `value[${ind}].serialNo`)}
                                    onChange={(e) => change(e.target.value, 'serialNo', ind)}
                                />
                            </div>
                            <div className='product-input-item mb'>
                                <span className='product-input-item__label'>Metal：</span>
                                <Select
                                    placeholder={services.language.getText(
                                        'defaultPlaceholderSelect'
                                    )}
                                    style={{ width: '100%' }}
                                    value={get(data, `value[${ind}].metal`)}
                                    onChange={(value: any) => change(value, 'metal', ind)}
                                >
                                    {metalOption.map((item) => (
                                        <Option key={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='product-input-item mb'>
                                <span className='product-input-item__label'>Jewel：</span>
                                <Select
                                    placeholder={services.language.getText(
                                        'defaultPlaceholderSelect'
                                    )}
                                    style={{ width: '100%' }}
                                    value={get(data, `value[${ind}].jewel`)}
                                    onChange={(value: any) => change(value, 'jewel', ind)}
                                >
                                    {jewelOption.map((item) => (
                                        <Option key={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div className='product-input-item mb'>
                                <span className='product-input-item__label'>Carat/Remark：</span>
                                <Input
                                    placeholder='Carat/Remark'
                                    value={get(data, `value[${ind}].caratRemark`) || ''}
                                    onChange={(e) => change(e.target.value, 'caratRemark', ind)}
                                    maxLength={1000}
                                />
                            </div>
                            <div className='product-input-item mb'>
                                <span className='product-input-item__label'>Watch Movement：</span>
                                <Select
                                    placeholder={services.language.getText(
                                        'defaultPlaceholderSelect'
                                    )}
                                    style={{ width: '100%' }}
                                    value={get(data, `value[${ind}].watchMovement`)}
                                    onChange={(value: any) => change(value, 'watchMovement', ind)}
                                >
                                    {watchMovementOption.map((item) => (
                                        <Option key={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
//@ts-ignore
export default React.forwardRef(({ wjaProductList = [], value, onChange }, ref) => {
    useEffect(() => {
        let initValue = Array.from({ length: wjaProductList.length || 0 }).fill([]);
        if (wjaProductList.length > 0) {
            wjaProductList.map((item: any, index: number) => {
                item.value = Array.from({ length: item.pickedQuantity || 0 }).fill({
                    orderItemId: item.orderItemId,
                    styleId: item.styleId,
                    rsku: item.rsku,
                });
                initValue[index] = item.value;
                return item;
            });
            onChange(initValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const change = (args: any) => {
        const { value: nItemValue, index } = args;
        let nValue = cloneDeep(value);
        if (nValue) {
            nValue[index] = [...nItemValue];
        } else {
            nValue = Array.from({ length: wjaProductList.length || 0 }).fill([]);
            nValue[index] = [...nItemValue];
        }
        onChange(nValue);
    };
    return (
        <div>
            <h4>{services.language.getText('order.confirmTheDelivery.title')}：</h4>
            <div className='product-list' style={{ maxHeight: '500px', overflow: 'auto' }}>
                {wjaProductList &&
                    wjaProductList.map((item: ProductItemI, i: number) => {
                        return (
                            <ProductItem
                                data={item}
                                key={item.orderItemId}
                                onchange={(value) => change({ value, index: i })}
                            />
                        );
                    })}
            </div>
        </div>
    );
});

import { camelCase, get } from 'lodash';
import { services, builder } from '@comall-backend-builder/core';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { UserService } from '@/utils/user-service';

/**
 * 参数对象
 */
type ParamsObject = {
    /**
     * 参数键值对
     */
    [key: string]: any;
};
/**
 * 请求参数结构
 */
type ApiRequestParams = ParamsObject;

/**
 * 自定义请求头参数
 */
type CustomHeader = {
    /**
     * 请求头键值对
     */
    [key: string]: string | number;
};

const whitelistRE = /\/keycloak\//;
const requestWithCheckToken = (config: Parameters<typeof services.api.request>[0]) => {
    if (whitelistRE.test(config.apiPath)) {
        return services.api.request(config);
    }
    return new Promise((resolve, reject) => {
        // 检测更新token会使请求进入等待状态
        UserService.onCheckToken(() => services.api.request(config).then(resolve, reject));
    });
};

services.swizzle(services.api, {
    errorHandle(error: ErrorInfo) {
        let { response } = error;
        //@ts-ignore
        if (get(response, 'statusCode') === 401) {
            UserService.init(true);
        }

        return Promise.reject(response);
    },

    /**
     * 生成请求头信息
     *
     * @param customHeaders 自定义请求头
     */
    generateHeaders(customHeaders?: CustomHeader) {
        const { user } = builder.getStore().getState();
        // @ts-ignore
        const { accessToken, token } = user;
        return Object.assign(
            {},
            {
                accessToken: token,
                Authorization: `Bearer ${accessToken}`,
                'REQUEST-ID': services.uuid(),
                'Accept-Language': services.language.getCurrentLanguage().id,
            },
            customHeaders
        );
    },

    get(params: ParamsObject, config?: ApiRequestConfig) {
        const mixedConfig = Object.assign({}, config, {
            params,
            method: 'get',
        });

        return requestWithCheckToken(mixedConfig)
            .then((response: any) => {
                return services.api.formatObjectKeys(response.body, camelCase);
            })
            .catch(services.api.errorHandle);
    },

    post(params: ParamsObject, config?: ApiRequestConfig) {
        const mixedConfig = Object.assign({}, config, {
            params,
            method: 'post',
        });

        return requestWithCheckToken(mixedConfig)
            .then((response: any) => {
                return services.api.formatObjectKeys(response.body, camelCase);
            })
            .catch(services.api.errorHandle);
    },

    patch(params: ParamsObject, config?: ApiRequestConfig) {
        const mixedConfig = Object.assign({}, config, {
            params,
            method: 'patch',
        });

        return requestWithCheckToken(mixedConfig)
            .then((response: any) => {
                return services.api.formatObjectKeys(response.body, camelCase);
            })
            .catch(services.api.errorHandle);
    },

    put(params: ApiRequestParams, config?: ApiRequestConfig) {
        const mixedConfig = Object.assign({}, config, {
            params,
            method: 'post',
            customHeaders: { xmo: 'PUT' },
        });

        return requestWithCheckToken(mixedConfig)
            .then((response: any) => {
                return services.api.formatObjectKeys(response.body, camelCase);
            })
            .catch(services.api.errorHandle);
    },

    delete(params: ApiRequestParams, config?: ApiRequestConfig) {
        const mixedConfig = Object.assign({}, config, {
            params,
            method: 'post',
            customHeaders: { xmo: 'DELETE' },
        });

        return requestWithCheckToken(mixedConfig)
            .then((response: any) => {
                return services.api.formatObjectKeys(response.body, camelCase);
            })
            .catch(services.api.errorHandle);
    },
});

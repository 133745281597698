import { API_TYPE, PATH_NAME } from '@/containers/picking/enums';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        pickingDetail: {
            apiPath: '/dc-order-picking/admin/picking_lists',
            properties: {
                id: {
                    type: 'string',
                    displayName: '',
                },
                pickingListNumber: {
                    type: 'string',
                    displayName: '<<picking.pickingListNumber>>',
                },
            },
        },
    },
    components: {
        PickingAssistantDetailPage: {
            entity: 'pickingDetail',
            component: 'Card',
            content: {
                component: 'PickingAssistantDetail',
                apiType: API_TYPE.PICKING,
                pickPathname: PATH_NAME.PICK_PATH_NAME,
            },
        },
    },
};

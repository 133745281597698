/*
 * @Author: zhulu
 * @Date: 2022-05-17 15:45:51
 * @Description: 商品关联的form
 */
import React, { Fragment, useCallback, useEffect } from 'react';
import { Form, Table, Input, Button, Card, Checkbox, Row, Col, message } from 'antd';
import { concat, set, filter, cloneDeep, isEmpty, some, findIndex } from 'lodash';
import { services } from '@comall-backend-builder/core';
import SelectAutoComplete from '@/components/select-auto-complete';
import { language } from '@comall-backend-builder/core/lib/services';
import { Privilege } from '@/components/privilege';
type Item = {
    key: string;
    label: string;
};
const ProductGroupForm = Form.create()(
    ({
        form: { getFieldDecorator, setFieldsValue, getFieldValue, validateFieldsAndScroll },
        params: { id },
        history,
    }: any) => {
        const selectStyle = useCallback(async (item: Item, type: 'add' | 'remove') => {
            switch (type) {
                case 'add':
                    let styleItem: any = await services.api.get(
                        {},
                        {
                            apiPath: `/dc-goods/admin/product_spec/${item.key}`,
                        }
                    );
                    styleItem.id = item.key;
                    styleItem.styles = (styleItem.styles || []).map((i: any) => ({
                        ...i,
                        name: i.name.zh,
                    }));
                    setFieldsValue({
                        styleItems: concat([], getFieldValue('styleItems') || [], styleItem),
                    });
                    break;
                case 'remove':
                    setFieldsValue({
                        styleItems: filter(getFieldValue('styleItems'), (i) => item.key !== i.id),
                        style: filter(getFieldValue('style'), (i) => item.key !== i.id),
                    });
            } // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        /**
         * 生成款式
         */
        const createStyle = async () => {
            // check 选择款式
            const checkStyleList = () => {
                let select: any = [];
                let params: any[] = [];
                (getFieldValue('styleItems') || []).forEach((style: any) => {
                    let _select = (style.styles || []).filter((i: any) => i.flag);

                    select = concat([], select, _select);
                    params.push(_select);
                });
                return { length: select.length > 0, params };
            };
            const check = checkStyleList();
            if (!check.length) return;
            try {
                const styleList = await services.api.post([...check.params], {
                    apiPath: `/dc-goods/admin/product_group/buildDesign`,
                });
                setFieldsValue({ styleGroupProducts: styleList });
            } catch (e) {
                //@ts-ignore
                services.errorHandle(e);
            }
        };

        useEffect(() => {
            const loadDetail = async () => {
                try {
                    const result: any = await services.api.get(
                        {},
                        {
                            apiPath: `/dc-goods/admin/product_group/${id}`,
                        }
                    );
                    result.styleItems = (result.styleItems || []).map((i: any) => ({
                        ...i,
                        name: i.styleName,
                    }));
                    result.style = result.styleItems;
                    setFieldsValue(result);
                } catch (e) {
                    //@ts-ignore
                    services.errorHandle(e);
                }
            };
            if (id) {
                loadDetail();
            } // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        const submit = useCallback(() => {
            validateFieldsAndScroll((errors: any, values: any) => {
                if (!errors) {
                    values.styleItems = values.styleItems.map((i: any, index: number) => {
                        delete i.displayName;
                        return {
                            ...i,
                            sort: index,
                        };
                    });
                    const call = id ? 'put' : 'post';
                    services.api[call](
                        {
                            ...values,
                        },
                        {
                            apiPath: id
                                ? `/dc-goods/admin/product_group/${id}`
                                : `/dc-goods/admin/product_group`,
                        }
                    )
                        .then((res) => {
                            message.success(services.language.getText('successFully'));
                            history.goBack();
                        })
                        .catch((e: any) => {
                            services.errorHandle(e);
                        });
                }
            }); // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        return (
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 10 }} className='product-group-form'>
                <Form.Item label={language.getText('productGroup.groupName')}>
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: services.language.getText('productGroup.groupNameError'),
                            },
                        ],
                    })(
                        <Input placeholder={services.language.getText('defaultPlaceholderInput')} />
                    )}
                </Form.Item>
                <Form.Item label={language.getText('productGroup.selectStyle')} required>
                    {getFieldDecorator('style', {
                        rules: [
                            {
                                required: true,
                                message: services.language.getText('productGroup.styleError'),
                            },
                        ],
                    })(<SelectStyle selectStyle={selectStyle} />)}
                </Form.Item>
                <div style={{ width: '80%', margin: 'auto' }}>
                    {getFieldDecorator(`styleItems`, { initialValue: [] })(
                        <StyleItems selectStyle={selectStyle} />
                    )}
                    {getFieldValue('styleItems').length > 0 && (
                        <Privilege path='goods.goods_relevance.build_design'>
                            <Button
                                type='primary'
                                onClick={createStyle}
                                style={{ margin: '8px 0' }}
                            >
                                {language.getText('productGroup.createStyle')}
                            </Button>
                        </Privilege>
                    )}
                    <Form.Item label='' labelCol={{ span: 2 }} wrapperCol={{ span: 24 }}>
                        {getFieldDecorator(`styleGroupProducts`, {
                            rules: [
                                {
                                    validator: (_rule: any, _value: any, callback: any) => {
                                        if (
                                            !_value ||
                                            _value.length < 0 ||
                                            some(_value, (i) => !i.products || isEmpty(i.products))
                                        ) {
                                            return new Error(
                                                language.getText('productGroup.groupProductError')
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],
                        })(<StyleGroupProducts selectStyle={selectStyle} />)}
                    </Form.Item>
                </div>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={10} offset={6}>
                        <Button
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            {services.language.getText('cancel')}
                        </Button>
                        <Button type='primary' onClick={submit}>
                            {services.language.getText('submit')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
);
const StyleItems = (props: any) => {
    return (props.value || []).map((style: any, index: number) => {
        return (
            <Card
                key={style.id}
                title={style.styleName}
                type='inner'
                size='small'
                bordered={false}
                extra={
                    <>
                        <Button
                            type='link'
                            disabled={index === 0}
                            onClick={() => {
                                let nVal: any = cloneDeep(props.value);
                                let i = nVal[index];
                                nVal.splice(index, 1);
                                nVal.splice(index - 1, 0, i);
                                props.onChange(nVal);
                            }}
                        >
                            {language.getText('up')}
                        </Button>
                        <Button
                            type='link'
                            disabled={index === props.value.length - 1}
                            onClick={() => {
                                let nVal: any = cloneDeep(props.value);
                                let i = nVal[index];
                                nVal.splice(index, 1);
                                nVal.splice(index + 1, 0, i);
                                props.onChange(nVal);
                            }}
                        >
                            {language.getText('down')}
                        </Button>
                        <Button
                            type='link'
                            onClick={() =>
                                props.selectStyle(
                                    { key: style.id, label: style.styleName },
                                    'remove'
                                )
                            }
                        >
                            {language.getText('delete')}
                        </Button>
                    </>
                }
            >
                <Checkbox.Group
                    value={(style.styles || []).filter((i: any) => i.flag).map((i: any) => i.id)}
                    onChange={(checked: any) => {
                        let _style = cloneDeep(style);
                        _style = _style.styles.map((i: any) => {
                            return { ...i, flag: checked.indexOf(i.id) > -1 };
                        });
                        let nVal: any = cloneDeep(props.value);
                        nVal[index].styles = _style;
                        props.onChange(nVal);
                    }}
                    options={style.styles.map((item: any) => ({
                        ...item,
                        label: item.name,
                        value: item.id,
                    }))}
                />
            </Card>
        );
    });
};
const EditableContext = React.createContext({});

class EditableCell extends React.Component<any, any> {
    renderCell = (_params: any) => {
        const {
            editable,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            onChange,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <SelectAutoComplete
                        value={record[dataIndex]}
                        placeholder={services.language.getText('defaultPlaceholderSelect')}
                        apiPath='/loader/dc-goods/admin/cskus/queryCskuByPage'
                        loadFirstPage={false}
                        params={{
                            page: 1,
                            perPage: 10,
                            sourceTypeStr: '0,2,3',
                        }}
                        selectParamKey='keyword'
                        onChange={(val: any) => onChange(record?.id, val, dataIndex)}
                    />
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

const StyleGroupProducts = (props: any) => {
    const onChange = (id: any, val: any, dataIndex: string) => {
        let { value, onChange } = props;
        let nVal = cloneDeep(value);
        if (nVal !== null && !isEmpty(nVal)) {
            const index = findIndex(value, (i: any) => i.id === id);
            //@ts-ignore
            set(nVal, `${index}.${dataIndex}`, val);
        }
        onChange(nVal);
    };
    let columns: any[] = [
        {
            title: language.getText('productGroup.styleName'),
            dataIndex: 'name',
        },
        {
            title: language.getText('productGroup.product'),
            dataIndex: 'products',
            editable: true,
        },
        {
            title: services.language.getText('actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (_text: any, _record: any, index: number) => {
                return (
                    <>
                        <Button
                            type='link'
                            style={{ padding: '0 4px' }}
                            onClick={() => {
                                let nVal: any = cloneDeep(props.value);
                                nVal.splice(index, 1);
                                props.onChange(nVal);
                            }}
                        >
                            {services.language.getText('delete')}
                        </Button>
                    </>
                );
            },
        },
    ].map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editable: col.editable,
                onChange: onChange,
            }),
        };
    });
    return props.value ? (
        <EditableContext.Provider value={{}}>
            <Table
                components={{ body: { cell: EditableCell } }}
                bordered
                scroll={{ x: 'fit-content' }}
                dataSource={props.value}
                columns={columns}
                pagination={false}
                rowKey={(_: any) => `${_.id}`}
            />
        </EditableContext.Provider>
    ) : (
        <Fragment />
    );
};
const SelectStyle = (props: any) => {
    return (
        <>
            <SelectAutoComplete
                value={props.value}
                mode='multiple'
                placeholder={services.language.getText('defaultPlaceholderSelect')}
                apiPath='/dc-goods/admin/product_spec'
                params={{ page: 1, perPage: 10 }}
                formatResponse={(response: any) => {
                    return response.result.map((i: any) => ({ ...i, name: i.styleName }));
                }}
                onSelect={(item: any) => {
                    if (props.value && props.value.length >= 10) return;
                    props.selectStyle(item, 'add');
                }}
                onDeselect={(item: any) => {
                    props.selectStyle(item, 'remove');
                }}
                max={10}
                onChange={(val: any) => {
                    props.onChange(val);
                }}
                selectParamKey='keyword'
            />
        </>
    );
};
export { ProductGroupForm };

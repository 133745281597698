import { generateOptions } from '@/services/generate-options';
import { language } from '@comall-backend-builder/core/lib/services';

export enum DISPLAY_LOCATION_VALUES {
    /** 首页 */
    HOME = 'HOME',
    /** 指定品牌 */
    SPECIFIC_BRAND = 'SPECIFIC_BRAND',
    /** 指定分类 */
    SPECIFIC_CATEGORY = 'SPECIFIC_CATEGORY',
    /** 指定商户 */
    SPECIFIC_MERCHANT = 'SPECIFIC_MERCHANT',
}
export const DISPLAY_LOCATION_NAMES = {
    [DISPLAY_LOCATION_VALUES.HOME]: language.getText('popupAds.displayLocationMap.home'),
    [DISPLAY_LOCATION_VALUES.SPECIFIC_BRAND]: language.getText('popupAds.displayLocationMap.brand'),
    [DISPLAY_LOCATION_VALUES.SPECIFIC_CATEGORY]: language.getText(
        'popupAds.displayLocationMap.category'
    ),
    [DISPLAY_LOCATION_VALUES.SPECIFIC_MERCHANT]: language.getText(
        'popupAds.displayLocationMap.merchant'
    ),
};
export const DISPLAY_LOCATION_OPTIONS = generateOptions(
    DISPLAY_LOCATION_VALUES,
    DISPLAY_LOCATION_NAMES
);

export enum SEND_FREQUENCY_VALUES {
    ONE = '1',
    TOW = '2',
    THREE = '3',
    ZONE = '0',
}
export const SEND_FREQUENCY_NAMES = {
    [SEND_FREQUENCY_VALUES.ONE]: language.getText('popupAds.sendFrequencyMap.1'),
    [SEND_FREQUENCY_VALUES.TOW]: language.getText('popupAds.sendFrequencyMap.2'),
    [SEND_FREQUENCY_VALUES.THREE]: language.getText('popupAds.sendFrequencyMap.3'),
    [SEND_FREQUENCY_VALUES.ZONE]: language.getText('popupAds.sendFrequencyMap.0'),
};
export const SEND_FREQUENCY_OPTIONS = generateOptions(SEND_FREQUENCY_VALUES, SEND_FREQUENCY_NAMES);

export enum ACTIVITY_STATUS_VALUES {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}
const ACTIVITY_STATUS_STATUS = {
    [ACTIVITY_STATUS_VALUES.NOT_STARTED]: 'success',
    [ACTIVITY_STATUS_VALUES.IN_PROGRESS]: 'warning',
    [ACTIVITY_STATUS_VALUES.COMPLETED]: 'default',
};
export const ACTIVITY_STATUS_NAMES = {
    [ACTIVITY_STATUS_VALUES.NOT_STARTED]: language.getText('popupAds.activityStatusMap.notStarted'),
    [ACTIVITY_STATUS_VALUES.IN_PROGRESS]: language.getText('popupAds.activityStatusMap.inProgress'),
    [ACTIVITY_STATUS_VALUES.COMPLETED]: language.getText('popupAds.activityStatusMap.completed'),
};
export const ACTIVITY_STATUS_OPTIONS = generateOptions(
    ACTIVITY_STATUS_VALUES,
    ACTIVITY_STATUS_NAMES,
    (v, n) => ({ id: v, name: n, status: ACTIVITY_STATUS_STATUS[v] })
);

import React from 'react';
import classnames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { TypesManager, EntitiesManager } from '@comall-backend-builder/core';
import { map, get } from 'lodash';

import { entityCaches } from '../../constant';

export class MultipleTypeMode extends modes.ObjectMode {
    getControlComponent(controlInfo: any) {
        const {
            multipleConfigs,
            className,
            onChange: onParentChange,
            name,
            style,
            value,
            entity,
        } = controlInfo;
        return (
            <div className={classnames('multiple-type-control', className)} style={style}>
                {map(multipleConfigs, (configName: string, key: any) => {
                    const config = get(entity, `metainfo.properties.${configName}`);
                    return (
                        <div className='type-item' key={key}>
                            {TypesManager.get(config.type).getControlComponent({
                                ...config,
                                value: get(value, key),
                                name: name,
                                onChange(keyValue: any, name: string) {
                                    onParentChange(
                                        {
                                            ...value,
                                            [key]: keyValue,
                                        },
                                        name
                                    );
                                },
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
    getDisplayComponent(value: any, displayInfo: any) {
        const { multipleConfigs, className, style, entityName, params, entityCache } = displayInfo;
        let entity = entityCaches[entityCache];
        if (!entity) {
            const Entity = EntitiesManager.get(entityName);
            entity = new Entity(params);
            entityCaches[entityCache] = entity;
        }
        return (
            <div className={classnames('multiple-type-display', className)} style={style}>
                {map(multipleConfigs, (configName: string, key: any) => {
                    const config = get(entity, `metainfo.properties.${configName}`);
                    return (
                        <div className='type-item' key={key}>
                            {TypesManager.get(config.type).getDisplayComponent(get(value, key), {
                                ...config,
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}

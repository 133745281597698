import { STORE_TREE_STORAGE_KEY, PHILIPS_STORE_TREE_STORAGE_KEY } from '../common';

export const API_MAP = {
    // Pand数据
    ORDER_STATISTICS: '/admin/order/statistics',

    // 缺货配送
    ORDER_PICKED: '/admin/order/order/:id/picked',

    // 确认提货
    CONFIRM_COMPLETED_INFO: '/admin/orders/:orderId/confirm_completed_info',

    ORDER_STATUS: '/admin/order/:id/status',

    // 退货退款
    ORDER_RETURNED_CHECK: '/admin/orders/:id/returned/check',

    ORDER_RETURNED: '/admin/order/:id/returned',

    // 重发订单
    ORDER_RETRY: '/admin/order/:id/mcs/retry',

    // 重发退款
    CONTINUE_TO_REFUNF: '/admin/order/:id/continue_to_refund',

    // 已完成退款
    CONFIRM_REUFUNDED: '/admin/order/:id/confirm_reufunded',

    //重发退款
    CONTINUE_TO_PART_REFUND: '/admin/order/:id/continue_to_part_refund',

    // 已完成退款
    CONFIRM_PART_REUFUNDED: '/admin/order/:id/confirm_part_reufunded',

    // 确认商品入库
    ORDER_GOODS: '/admin/orders/:id/goods',

    // loctoloc
    LOC_TO_LOC_RETRY: '/admin/order/:id/loc_to_loc/retry',

    // 重发pos
    POS_RESEND: '/orders/pos/resend',

    ORDER_CANCEL_TO_POS_RETRY: '/admin/order/:id/order_cancel_to_pos/retry',

    // 确认发货
    ORDER_DELIVER: '/admin/order/:id/order_deliver',

    // 确认收货
    ORDER_COMPLETED: '/admin/order/:id/completed',

    // 自提收货
    PICKED_UP_CONFIRM: '/admin/order/:id/picked_up_confirm',

    FIND_PAYMENT_RECORDS: '/admin/order/find_payment_records/:id',

    ORDER_REMARK: '/admin/order/:id/remark',

    ORDER_CANCEL: '/admin/order/:menuType/:orderNumber/cancel',

    ORDER_PICK_UP_TIME: '/admin/order/:orderId/pick_up_time',

    // 人工免运费
    SUBMIT_FREIGHT_REFUND: '/admin/order/:id/submit_freight_refund',

    CANCEL_REASONS: '/admin/orders/cancel_reasons',

    COUNTBYUNPICK: '/admin/order/countByUnpick',

    // 线下转账订单确认收款
    OFFLINE_PAYMENT_PAID: '/admin/order/:id/offline_payment_paid',

    // 线下转账查看凭证
    GET_PAYMENT_VOUCHER: '/admin/order/getPaymentVoucher/:parentOrderNumber',

    // 查看优惠券列表
    FIND_COUPON_DETAIL: '/admin/order/find_coupon_detail/:id',
};

export const STORE_STORAGE_KEY = {
    // 普通store存储key
    STORE_TREE_STORAGE_KEY: STORE_TREE_STORAGE_KEY,

    // 飞利浦store存储key
    PHILIPS_STORE_TREE_STORAGE_KEY: PHILIPS_STORE_TREE_STORAGE_KEY,
};

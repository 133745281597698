import React, { Component } from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import moment from 'moment';
import { cloneDeep, range } from 'lodash';

interface TimeSingleSingleProps {
    ranges: TimeProps;
    format: string;
    name: string;
    maxRangesCount: number;
    minuteStep: number;
    allowEmpty: boolean;
    style: any;
    onChange(key: any, name: string): void;
    defaultStart: string;
    defaultEnd: string;
    value: any;
}
interface TimeProps {
    startTime: string;
    endTime: string;
}
/*
 * value格式：[
 *   {
 *       startTime: '',
 *       endTime: ''
 *   }
 * ]
 * */
export class TimeSinglePicker extends Component<TimeSingleSingleProps, any> {
    ranges = this.props.ranges;
    defaultStart = '00:00';
    defaultEnd = '22:30';
    format: string = this.props.format;

    onTimeChange(status: any, time: any, timeString: any) {
        const { onChange, name, value } = this.props;
        if (status === 'start') {
            if (timeString === '') {
                this.ranges.startTime = value.startTime;
            } else {
                this.ranges.startTime = timeString;
            }
            onChange(this.ranges, name);
        } else if (status === 'end') {
            if (timeString === '') {
                this.ranges.endTime = value.endTime;
            } else {
                this.ranges.endTime = timeString;
            }
            onChange(this.ranges, name);
        }
    }
    startDisabledHours() {
        return range(moment(this.ranges.endTime, this.format).hours() + 1, 24);
    }
    endDisabledHours() {
        return range(0, moment(this.ranges.startTime, this.format).hours());
    }
    startDisabledMinutes(selectedHour: any) {
        const end = moment(this.ranges.endTime, this.format);
        return range(selectedHour === end.hours() ? end.minutes() + 1 : 60, 60);
    }
    endDisabledMinutes(selectedHour: any) {
        const start = moment(this.ranges.startTime, this.format);
        return range(0, selectedHour === start.hours() ? start.minutes() : 0);
    }
    render() {
        const { value, format } = this.props;
        if (value) {
            this.ranges = cloneDeep(value);
        }
        const props = {
            format,
            minuteStep: 30,
            allowEmpty: false,
            style: {
                width: '110px',
            },
        };
        return (
            <div>
                {value ? (
                    <div>
                        <AntTimePicker
                            {...props}
                            value={moment(this.ranges.startTime, format)}
                            onChange={this.onTimeChange.bind(this, 'start')}
                            disabledHours={this.startDisabledHours.bind(this)}
                            disabledMinutes={this.startDisabledMinutes.bind(this)}
                        />
                        <span style={{ margin: '0 10px' }}>至</span>
                        <AntTimePicker
                            {...props}
                            value={moment(this.ranges.endTime, format)}
                            onChange={this.onTimeChange.bind(this, 'end')}
                            disabledHours={this.endDisabledHours.bind(this)}
                            disabledMinutes={this.endDisabledMinutes.bind(this)}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

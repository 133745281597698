import builder, { Type } from '@comall-backend-builder/core';
import { formats } from '@comall-backend-builder/types';

import {
    MultipleTypeMode,
    PriceMode,
    ProductTagComboSelect,
    DeliveryTimeMode,
    TimeSingleMode,
    ProductErrorInfoTableMode,
    ObjectAsyncTreeSelectMode,
    ObjectAsyncTreeSelectFormat,
    EventMarketingItemsFormat,
    EventMarketingItemsMode,
} from './modes';

import { DeliveryTimeFormat } from './formats';

import { RadioStateFormat, RadioStateMode } from './option-radio-state';
import { MobileHasAreaCodeFormat, MobileHasAreaCodeMode } from './phone';

// 简单封装 registerType 方法，使写法更简单、直观
function registerType(name: string, format: any, mode: any) {
    return builder.registerType(name, new Type(new format(), new mode()));
}
registerType('object.price', formats.ObjectFormat, PriceMode);

registerType('object.deliveryTime', DeliveryTimeFormat, DeliveryTimeMode);
registerType('object.multiple', formats.ObjectFormat, MultipleTypeMode);
registerType('object.productTagComboSelect', formats.ObjectFormat, ProductTagComboSelect);
registerType('object.timeSingle', formats.ObjectFormat, TimeSingleMode);
registerType('object.productErrorInfoTable', formats.ObjectFormat, ProductErrorInfoTableMode);
registerType('object.treeSelect.async', ObjectAsyncTreeSelectFormat, ObjectAsyncTreeSelectMode);
registerType('object.option.radio.state', RadioStateFormat, RadioStateMode);
registerType('object.mobile', MobileHasAreaCodeFormat, MobileHasAreaCodeMode);
registerType('object.eventMarketingItems', EventMarketingItemsFormat, EventMarketingItemsMode);

import React from 'react';
import { AntdEntry } from '@comall-backend-builder/components-basis';
import { builder, services } from '@comall-backend-builder/core';
import ReactDOM from 'react-dom';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { forIn, get } from 'lodash';

import '@/initial';
import '@/types';
import '@/containers';
import '@/loaders';
import './pages/index';
import '@/styles/index.less';

import * as componentsLocal from '@/components';
import * as configs from '@/configs';

import './index.less';
import { NoAuth } from './no-auth';
import { GlobalConfigProvider } from './utils/global-config';

// register local components
forIn(componentsLocal, (component, name) => {
    builder.registerComponent(name, component);
});
// Register local configs
forIn(configs, (config) => {
    builder.registerConfig(config as Config);
});
if (get(services.localStorage.get('user'), 'accessToken')) {
    services.localStorage.set('path', window.location.hash);
}

export const renderApp = () => {
    ReactDOM.render(
        <GlobalConfigProvider>{builder.bootstrap(AntdEntry)}</GlobalConfigProvider>,
        document.getElementById('app'),
        () => {
            const logo = document.querySelector('.global-logo');
            if (ENV.ENV !== 'production' && logo) {
                const node = document.createElement('span');
                node.innerHTML = `${ENV.ENV}`;
                node.className = 'global-env-text';
                logo.appendChild(node);
            }
        }
    );
};

export const renderNoAuthApp = () => {
    ReactDOM.render(<NoAuth />, document.getElementById('app'));
};

if (window.location.hash.includes('login-back')) {
    renderApp();
}

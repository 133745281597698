import { mockFetch } from './mock';
import { services } from '@comall-backend-builder/core/lib';

export default [
    {
        key: '/channels',
        loader: {
            get: async function(data: any) {
                return await mockFetch({
                    totalNum: 20,
                    page: data.page,
                    perPage: data.perPage,
                    totalPage: '2',
                    result: [
                        {
                            id: '1',
                            channelName: '在线预订',
                            channelType: '预订业务',
                            status: 'ENABLED',
                        },
                    ],
                });
            },
        },
    },
    {
        key: '/channelContacts',
        loader: {
            get: async function(data: any, config: any) {
                config.apiPath = `/dc-master-data/admin/distribution_channels/${data.id}/touch_points`;
                return await services.api.get(data, config);
            },
        },
    },
];

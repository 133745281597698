import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { InputNumber as AntInputNumber } from 'antd';
interface DeliveryTimeProps {
    [key: string]: any;
}
export class DeliveryDayLine extends Component<DeliveryTimeProps, any> {
    render() {
        const { min, max } = this.props;
        return (
            <span>
                {this.props.prefixText && (
                    <span style={{ marginRight: '5px' }}>{this.props.prefixText}</span>
                )}
                <AntInputNumber
                    {...this.props}
                    style={{ width: 100, marginRight: '5px' }}
                    min={min}
                    max={max}
                />
                {this.props.text}
            </span>
        );
    }
}

export class DeliveryDayDisplay extends Component<DeliveryTimeProps, any> {
    render() {
        return (
            <span>
                {this.props.prefixText && <span>{this.props.prefixText}</span>}
                <span>{this.props.value}</span>
                {this.props.text}
            </span>
        );
    }
}
/**
 * 状态类型
 */
export class DeliveryDayMode extends modes.NumberMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        return <DeliveryDayLine {...controlInfo} />;
    }
    getDisplayComponent(value: any, displayInfo: any) {
        return (
            <DeliveryDayDisplay
                value={value}
                text={displayInfo.text}
                prefixText={displayInfo.prefixText}
            />
        );
    }
}

import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Modal, message } from 'antd';
import { modes } from '@comall-backend-builder/types';

interface IProps {
    className: string;
    style: any;
    info?: string;
}

interface IState {
    visible: boolean;
}

const { language } = services;

/**
 * 三方管理 编辑
 */
export class ClickLook extends Component<IProps, IState> {
    state = {
        visible: false,
    };

    toggleModal = (visible: boolean) => {
        this.setState({
            visible,
        });
    };

    handleClick = () => {
        this.toggleModal(true);
    };

    handleCancel = () => {
        this.toggleModal(false);
    };

    handleCopy = () => {
        const { info } = this.props;
        const input = document.createElement('input');
        input.value = info as string;
        input.style.position = 'absolute';
        input.style.left = '-9999px';
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        input.remove();
        message.success(language.getText('thirdPartyAccount.copySuccess'));
    };

    render() {
        const { className, style, info } = this.props;
        const { visible } = this.state;

        return (
            <>
                <Button className={className} style={style} type='link' onClick={this.handleClick}>
                    {language.getText('view')}
                </Button>
                <Modal
                    width={800}
                    title={language.getText('view')}
                    visible={visible}
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    footer={
                        <Button type='default' onClick={this.handleCancel}>
                            {language.getText('cancel')}
                        </Button>
                    }
                >
                    <div style={{ textAlign: 'center', color: '#000', padding: '25px 0 30px' }}>
                        {info}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Button type='default' onClick={this.handleCopy} style={{ width: '150px' }}>
                            {language.getText('copy')}
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}
/**
 * 状态类型
 */
export class ClickLookMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: string | undefined, displayConfig: any) {
        const { style, className } = displayConfig;

        return <ClickLook style={style} className={className} info={value} />;
    }
}

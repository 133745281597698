import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { interpolate } from '@comall-backend-builder/core/lib/services';
import { isFunction } from 'lodash';

export const RowLink: React.FC<{
    row: any;
    text: string;
    path: string;
    disabledTips?: string;
    dynamicDisabled?(row: any): boolean;
}> = (props) => {
    const { dynamicDisabled, disabledTips, text, path, ...rest } = props;

    if (isFunction(dynamicDisabled) && dynamicDisabled(props.row)) {
        let buttonEl = (
            <Button
                {...rest}
                style={{
                    padding: 0,
                    border: 0,
                }}
                type='link'
                disabled
            >
                {text}
            </Button>
        );

        if (disabledTips) {
            buttonEl = (
                <Tooltip title={disabledTips} trigger='hover'>
                    {buttonEl}
                </Tooltip>
            );
        }

        return buttonEl;
    }

    return (
        <Link {...rest} to={interpolate(path, props)}>
            {text}
        </Link>
    );
};

import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { map, get, last } from 'lodash';
import { Select } from 'antd';
import { services } from '@comall-backend-builder/core';
import classNames from 'classnames';

interface Brand {
    id: string;
    name: string;
    brandId: string;
}
interface State {
    brands: Brand[] | undefined;
    onlineStoreId: string | undefined;
    loading: boolean;
    value: string[] | undefined;
}
class StoreBrand extends Component<any, State> {
    state: State = {
        brands: undefined,
        onlineStoreId: undefined,
        loading: false,
        value: undefined,
    };

    componentDidUpdate(prevProp: any) {
        const { onlineStoreId } = this.state;
        const { loading } = this.state;
        const { entity, row } = this.props;
        let newOnlineStoreId = undefined;

        if (get(entity, 'fields.id')) {
            //编辑时
            newOnlineStoreId = get(entity, 'fields.onlineStoreId');
        } else {
            //新增时
            newOnlineStoreId = last(get(row, `onlineStoreId`, []));
        }

        if (!loading && onlineStoreId !== newOnlineStoreId) {
            this.fetchData(newOnlineStoreId);
            const pickOnlineBrands = get(this.props.entity, 'fields.pickOnlineBrands', []);
            const value = map(pickOnlineBrands, (brand) => brand.brandId);
            this.setState({ value });
        }
    }
    async fetchData(onlineStoreId: any, name = '') {
        this.setState({ loading: true });
        const result: Brand[] = onlineStoreId
            ? await services.api.get(
                  { pageSize: 20, onlineStoreId, name },
                  { apiPath: '/dc-goods/admin/brands/list' }
              )
            : [];

        // const pickOnlineBrands = get(this.props.entity, 'fields.pickOnlineBrands', []);
        // const value = map(pickOnlineBrands, (brand) => brand.brandId);
        this.setState({
            brands: map(result, (brand) => ({
                id: brand.id,
                name: brand.name,
                brandId: brand.brandId,
            })),
            onlineStoreId,
            loading: false,
            // value,
        });
    }

    onChange = (value: any) => {
        const { onChange, name } = this.props;
        const pickOnlineBrands = map(value, (brandId) => ({ brandId }));
        this.setState({ value });
        onChange(pickOnlineBrands, name);
    };

    onSearch = (value: string) => {
        const { onlineStoreId } = this.state;
        this.fetchData(onlineStoreId, value);
    };

    render() {
        const { brands, loading, value } = this.state;
        const { style, placeholder } = this.props;
        const selectProps = { style, placeholder, value };
        return (
            <Select
                showSearch
                allowClear
                filterOption={false}
                {...selectProps}
                loading={loading}
                mode='multiple'
                onChange={this.onChange}
                onSearch={this.onSearch}
            >
                {brands &&
                    map(brands, (item: Brand) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
            </Select>
        );
    }
}

export class StoreBrandMode extends modes.StringMode {
    getDisplayComponent(array: any, displayInfo: any) {
        const { className, style } = displayInfo;
        if (array && array.length) {
            return (
                <div
                    className={classNames('type-array mode-array-options-auto-complete', className)}
                    style={style}
                >
                    {map(array, ({ id, brandName }) => {
                        return (
                            <div key={id} className='string-type'>
                                {brandName}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getControlComponent(controlInfo: any) {
        return <StoreBrand {...controlInfo} />;
    }
}

import React from 'react';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '@/configs/mixin';
import { Button, message } from 'antd';
import { services } from '@comall-backend-builder/core';

const resendButton = (row: any, text: string, props: any) => {
    const { entity } = props;
    return (
        <Button
            type='link'
            onClick={() => {
                services.api
                    .put(
                        {},
                        {
                            apiPath: `/dc-order/admin/orders/sales/sendmcsNew?orderId=${row.orderId}&sendType=${row.mcsType}`,
                        }
                    )
                    .then(() => {
                        message.success('重发成功');
                        entity.search(entity.params);
                    })
                    .catch(services.errorHandle);
            }}
        >
            {text}
        </Button>
    );
};

export const config: Config = {
    entities: {
        resendBlInfo: {
            apiPath: '/dc-order/admin/orders/sales/mcs',
            properties: {
                orderNo: {
                    type: 'string',
                    displayName: '<<resendBl.orderNumber>>',
                },
                orderId: {
                    type: 'string',
                    displayName: '<<resendBl.orderId>>',
                },
                mcsType: {
                    type: 'string',
                    displayName: '<<resendBl.refundSalesTxn>>',
                },
                channel: {
                    type: 'string',
                    displayName: '<<resendBl.channel>>',
                },
            },
            filters: {
                number: {
                    type: 'string',
                    displayName: '<<keyword>>',
                    controlConfig: {
                        placeholder: '<<resendBl.keywordPlaceholder>>',
                        style: {
                            width: 240,
                            marginRight: 36,
                        },
                    },
                },
            },
        },
    },
    components: {
        ResendBlPage: {
            component: 'Card',
            bordered: false,
            title: '<<resendBl.title>>',
            entity: 'resendBlInfo',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    { component: 'ResendBlFilter', style: { marginBottom: 32 } },
                    { component: 'ResendBlTable' },
                ],
            },
        },
        ResendBlFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            fields: [
                {
                    property: 'number',
                },
            ],
        },
        ResendBlTable: {
            ...tableMixin,
            loadFirstPage: false,
            columns: [
                { property: 'orderNo' },
                { property: 'orderId' },
                { property: 'mcsType' },
                { property: 'channel' },
            ],
            actionColumn: {
                title: '<<actions>>',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<resendBl.resend>>',
                        config: {
                            render: (row: any, text: any, className: String, props: any) => {
                                return resendButton(row, text, props);
                            },
                        },
                    },
                ],
            },
        },
    },
};

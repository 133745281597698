import { services } from '@comall-backend-builder/core';
import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import {
    Input,
    InputNumber,
    Modal,
    Radio,
    Form as AntForm,
    Row as AntRow,
    Col as AntCol,
} from 'antd';
import SelectAutoComplete from '@/components/select-auto-complete';
import { isEqual } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
interface GoodParamsInterface {
    purchasesNum?: number;
    /** 0:自定义 1:预设 2:不使用 */
    codeType: 0 | 1 | 2;
    overrideCode?: string;
    overrideMoney?: number;
}
const goodParamsInit: GoodParamsInterface = {
    purchasesNum: undefined,
    codeType: 2,
    overrideCode: undefined,
    overrideMoney: undefined,
};

const codeType = {
    0: language.getText('offlineOrder.create.customCode'),
    1: language.getText('offlineOrder.create.presetsCode'),
    2: language.getText('offlineOrder.create.noCode'),
};
export const GroupItems = React.forwardRef((props: any) => {
    const [dataSource, setDataSource] = useState<any[]>([]);

    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }
        let nDataSource: any[] = (props.value || []).map((item: any, index: number) => {
            let dataSourceItem = find(dataSource, (i) => i.id === item.id);
            let v = {
                ...dataSourceItem,
                ...item,
            };
            return v;
        });
        setDataSource([...nDataSource]);
        if (!isEqual(props.value, nDataSource) && props.value) {
            props.onChange(nDataSource, props.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    /** 弹出框信息 */
    const [modalInfo, setModalInfo] = useState<any>({});
    /** 弹出框表单信息 */
    const [goodParams, setGoodParams] = useState<GoodParamsInterface>(goodParamsInit);
    /** 弹出框显示 */
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // 输入框状态与提示信息
    const [purchasesNumTips, setPurchasesNum] = useState<any>({
        status: null,
        help: null,
    });
    const [overrideCodeTips, setOverrideCode] = useState<any>({
        status: null,
        help: null,
    });
    const [overrideMoneyTips, setOverrideMoney] = useState<any>({
        status: null,
        help: null,
    });
    const resetTips = () => {
        setPurchasesNum({ status: null, help: null });
        setOverrideCode({ status: null, help: null });
        setOverrideMoney({ status: null, help: null });
    };

    /** 弹出框确认 */
    const handleOk = () => {
        let pass = true;
        resetTips();
        //购买数量校验
        if (!goodParams.purchasesNum) {
            setPurchasesNum({
                status: 'error',
                help: language.getText('offlineOrder.tips.purchasesNum'),
            });
            pass = false;
            //库存校验
        } else if (!modalInfo?.stockOut && goodParams?.purchasesNum > modalInfo?.usableQuantity) {
            setPurchasesNum({
                status: 'error',
                help: language.getText('offlineOrder.tips.overInventory'),
            });
            pass = false;
        }
        //overrideCode 校验
        if (goodParams.codeType !== 2 && !goodParams.overrideCode) {
            setOverrideCode({
                status: 'error',
                help: language.getText('offlineOrder.tips.overrideCode'),
            });
            pass = false;
        }
        //自定义 overrideMoney 校验
        if (goodParams.codeType === 0 && !goodParams.overrideMoney) {
            setOverrideMoney({
                status: 'error',
                help: language.getText('offlineOrder.tips.overrideMoney'),
            });
            pass = false;
        }
        if (!pass) return;

        //OverrideCode有效性校验
        if (goodParams.codeType !== 2 && goodParams.overrideCode) {
            /** 验证overrideCode */
            const overrideCodeVerify = () => {
                services.api
                    .get(
                        {
                            overrideCode: goodParams.overrideCode,
                            type: goodParams.codeType,
                            onlineStoreId: props.onlineStoreIds,
                            csku: modalInfo.csku,
                        },
                        {
                            apiPath: `/dc-price/admin/overridecode/middle/applyOverrideCode`,
                        }
                    )
                    .then((res: any) => {
                        if (goodParams.codeType === 1)
                            goodParams.overrideMoney = res?.priceReduction;
                        const goodInfo = { ...goodParams, ...modalInfo };
                        props.onChange([...dataSource, goodInfo]);
                        setVisible(false);
                        setGoodParams(goodParamsInit);
                    })
                    .catch((err) => {
                        const error = JSON.parse(JSON.stringify(err));
                        if (
                            error?.response?.body?.message === '4000001:OverrideCode错误或已过期!'
                        ) {
                            setOverrideCode({ status: 'error', help: 'OverrideCode错误或已过期' });
                        } else {
                            services.errorHandle(err);
                        }
                    });
            };
            overrideCodeVerify();
        } else {
            const goodInfo = { ...goodParams, ...modalInfo };
            props.onChange([...dataSource, goodInfo]);
            setVisible(false);
            setGoodParams(goodParamsInit);
        }
    };
    /** 弹出框取消 */
    const handleCancel = () => {
        setVisible(false);
        resetTips();
        setGoodParams(goodParamsInit);
    };

    /** 选择商品 */
    const selectGood = (val: any) => {
        let selectGood = val.slice(-1)[0];
        setVisible(true);
        setLoading(true);
        services.api
            .get({ rsku: selectGood?.rsku }, { apiPath: '/dc-goods/admin/rskus/getRskuSaleInfo' })
            .then((res: any) => {
                selectGood = { ...selectGood, ...res };
                setModalInfo(selectGood);
            })
            .catch(services.errorHandle)
            .finally(() => {
                setLoading(false);
            });
    };

    /** 修改弹出框参数 */
    const changeGoodParams = (val: any, type: string) => {
        setGoodParams((x) => ({ ...x, [type]: val }));
    };

    const removeGood = (e: any, item: any) => {
        e.preventDefault();
        const newDataSource = dataSource.filter((i) => i.csku !== item.csku);
        props.onChange(newDataSource);
    };
    return (
        <>
            {/* 选择框 */}
            <div className='select-goods-box'>
                <SelectAutoComplete
                    mode='multiple'
                    value={props.value}
                    placeholder={services.language.getText('defaultPlaceholderSelect')}
                    apiPath='/loader/dc-goods/admin/rskus/findMultiLanguageByOnlineStore'
                    params={{ onlineStoreId: props.onlineStoreIds }}
                    selectParamKey='cskuKeyword'
                    onChange={(val: any) => {
                        if (val.length > dataSource.length) {
                            selectGood(val);
                        } else {
                            props.onChange(val);
                        }
                    }}
                />
                {dataSource.length ? (
                    <span>
                        {language.getText('offlineOrder.create.totalPrice')}:
                        {dataSource
                            .reduce(
                                (total, c) =>
                                    total + Number(c?.overrideMoney || c.price) * c.purchasesNum,
                                0
                            )
                            .toFixed(2)}
                    </span>
                ) : null}
            </div>
            {/* 产品列表 */}
            <div className='offline-create-goods-list'>
                {dataSource.map((item) => (
                    <div className='offline-create-goods-item' key={item.id}>
                        <AntCol span={4}>
                            <img className='product-img' alt='' src={item.cskuPictureUrl} />
                        </AntCol>
                        <AntCol span={20}>
                            <AntRow>
                                <div className='offline-create-goods-item-title'>
                                    <span>
                                        {language.getText('productList.productName')}:{' '}
                                        {item?.spuName ?? '-'}
                                    </span>
                                    <a href={'/#'} onClick={(e) => removeGood(e, item)}>
                                        {language.getText('offlineOrder.create.removeGood')}
                                    </a>
                                </div>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {language.getText('productList.csku')}: {item?.csku ?? '-'}
                                </AntCol>
                                <AntCol span={8}>
                                    {language.getText('productList.barCode')}:{' '}
                                    {item?.barcode ?? '-'}
                                </AntCol>
                                <AntCol span={8}>
                                    {language.getText('productList.specsType')}:{' '}
                                    {item?.specsTypeName ?? '-'}
                                </AntCol>
                            </AntRow>
                            <AntRow>
                                <AntCol span={8}>
                                    {language.getText('picking.price')}: {item?.priceStr ?? '-'}
                                </AntCol>
                                <AntCol span={8}>
                                    {language.getText('productList.merchantName')}:{' '}
                                    {item?.merchantNameMap?.zh ?? '-'}
                                </AntCol>
                                <AntCol span={8}>
                                    {language.getText('offlineOrder.purchasesNum')}:{' '}
                                    {item?.purchasesNum ?? '-'}
                                </AntCol>
                            </AntRow>
                            {item?.overrideCode ? (
                                <>
                                    <AntRow style={{ color: '#000' }}>
                                        OVVERRIDECODE: {item?.overrideCode ?? '-'}
                                    </AntRow>
                                    <AntRow style={{ color: '#000' }}>
                                        {language.getText('offlineOrder.create.codeType')}:{' '}
                                        {item?.codeType || item?.codeType === 0
                                            ? // @ts-ignore
                                              codeType[item.codeType]
                                            : '-'}
                                    </AntRow>
                                    <AntRow style={{ color: '#000' }}>
                                        {language.getText('offlineOrder.create.codePrice')}:{' '}
                                        {item?.overrideMoney
                                            ? Number(item.overrideMoney).toFixed(2)
                                            : '-'}
                                    </AntRow>
                                </>
                            ) : null}
                        </AntCol>
                    </div>
                ))}
            </div>
            {/* 弹出层 */}
            <Modal
                title={language.getText('productAdd.basicMessage.selectProduct')}
                width={1000}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
            >
                <AntCol span={4}>
                    <img className='product-img' alt='' src={modalInfo?.cskuPictureUrl} />
                </AntCol>
                <AntCol span={20}>
                    <AntRow style={{ marginBottom: 20 }}>
                        {language.getText('productList.productName')}: {modalInfo?.spuName ?? '-'}
                    </AntRow>
                    <AntRow style={{ marginBottom: 15 }}>
                        <AntCol span={8}>
                            {language.getText('productList.csku')}: {modalInfo?.csku ?? '-'}
                        </AntCol>
                        <AntCol span={8}>
                            {language.getText('productList.barCode')}: {modalInfo?.barcode ?? '-'}
                        </AntCol>
                        <AntCol span={8}>
                            {language.getText('productList.specsType')}:{' '}
                            {modalInfo?.specsTypeName ?? '-'}
                        </AntCol>
                    </AntRow>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntCol span={8}>
                            {language.getText('picking.price')}: {modalInfo?.priceStr ?? '-'}
                        </AntCol>
                        <AntCol span={8}>
                            {language.getText('productList.merchantName')}:{' '}
                            {modalInfo?.merchantNameMap?.zh ?? '-'}
                        </AntCol>
                        <AntCol span={8}>
                            {language.getText('offlineOrder.create.ableSaleNum')}:{' '}
                            {modalInfo?.usableQuantity}
                        </AntCol>
                    </AntRow>
                    <AntRow style={{ marginBottom: 15 }}>
                        {language.getText('offlineOrder.create.stockOut')}:{' '}
                        {!!modalInfo?.stockOut ? language.getText('yes') : language.getText('no')}
                    </AntRow>
                </AntCol>
                <AntForm
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    // style={{ padding: '25px 0 25px' }}
                    // className='offline-create-basic-form'
                >
                    <AntForm.Item
                        label={
                            <>
                                <span style={{ color: 'red' }}>* </span>
                                {language.getText('offlineOrder.purchasesNum')}
                            </>
                        }
                        validateStatus={purchasesNumTips.status}
                        help={purchasesNumTips.help}
                    >
                        <InputNumber
                            id='purchasesNum'
                            placeholder={language.getText('defaultPlaceholderInput')}
                            min={0}
                            max={99999999}
                            precision={0}
                            value={goodParams.purchasesNum}
                            onChange={(e) => changeGoodParams(e, 'purchasesNum')}
                        />
                    </AntForm.Item>
                    <AntForm.Item
                        label={
                            <>
                                <span style={{ color: 'red' }}>* </span>
                                {language.getText('offlineOrder.create.codeType')}
                            </>
                        }
                    >
                        <Radio.Group
                            value={goodParams.codeType}
                            onChange={(e) => changeGoodParams(e.target.value, 'codeType')}
                        >
                            <Radio value={2}>{codeType[2]}</Radio>
                            <Radio value={1}>{codeType[1]}</Radio>
                            <Radio value={0}>{codeType[0]}</Radio>
                        </Radio.Group>
                    </AntForm.Item>
                    {goodParams.codeType !== 2 ? (
                        <AntForm.Item
                            label={'OverrideCode'}
                            validateStatus={overrideCodeTips.status}
                            help={overrideCodeTips.help}
                        >
                            <Input
                                id='overrideCode'
                                placeholder={language.getText('defaultPlaceholderInput')}
                                maxLength={20}
                                value={goodParams.overrideCode}
                                onChange={(e) => changeGoodParams(e.target.value, 'overrideCode')}
                            />
                        </AntForm.Item>
                    ) : null}
                    {goodParams.codeType === 0 ? (
                        <AntForm.Item
                            label={language.getText('offlineOrder.create.overrideMoney')}
                            validateStatus={overrideMoneyTips.status}
                            help={overrideMoneyTips.help}
                        >
                            <InputNumber
                                id='overrideMoney'
                                placeholder={services.language.getText('defaultPlaceholderInput')}
                                type='number'
                                min={0.01}
                                max={99999999}
                                precision={2}
                                onChange={(e) => changeGoodParams(e, 'overrideMoney')}
                            />
                        </AntForm.Item>
                    ) : null}
                </AntForm>
            </Modal>
        </>
    );
});

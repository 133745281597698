/*
 * @Author: 朱璐
 * @Date: 2022-10-25 10:05:15
 * @Description: overrideCode loader
 */
import { services } from '@comall-backend-builder/core';
export default [
    {
        key: '/dc-price/admin/overridecode',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, config);
                if (!data.id) {
                    response.result = response.result.map((item: any) => ({
                        ...item,
                        type: item.type.toString(),
                        codeStatus: item.codeStatus.toString(),
                    }));
                }
                return response;
            },
        },
    },
];

/**
 * 渠道 Map
 */
export const CHANNEL = {
    SELECT: '5',
    MACAU: '3',
    GUIDE_SHOPPING: '7',
};

export * from './common';
export * from './order';
export * from './store';
export * from './product';
export * from './status-options';
export * from './import-template';
export * from './after-sale';
export * from './coupon';
export * from './user';
export * from './design-manage';
export * from './third-party';
export * from './offline-order';
export * from './coupon-manage';
export * from './membership-list';
export * from './event-marketing';
export * from './sales-multi-promotion';
export * from './coupon-center';

import { generateEmptySearchResult } from '@/services';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';

export default [
    {
        //中台查询售后单列表
        key: '/dc-ecoupon/admin/vouchers',
        loader: {
            get: async function(data: any = {}, config: any) {
                if (!data.onlineStoreId) {
                    message.error(services.language.getText('picking.retailStoreSelectTip'));
                    return new Promise((resolove) => resolove(generateEmptySearchResult()));
                }
                const response: any = await services.api.get(data, {
                    ...config,
                    apiPath: '/dc-ecoupon/admin/vouchers',
                });
                return response;
            },
        },
    },
];

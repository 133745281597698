import React from 'react';
import './package-tags.less';
import { chunk } from 'lodash';
import { services } from '@comall-backend-builder/core';

export const PackageTags: React.FC<{
    data: AnyObject[];
}> = (props) => {
    const { data = [] } = props;

    return (
        <>
            <style>{`@page {margin: 0mm;}`}</style>
            {chunk(data, 2).map((page) => (
                <div className='package-tags'>
                    {page.map((item) => (
                        <div className='package-tag'>
                            <div className='package-tag-item'>
                                {services.language.getText('components.PackageTags.orderNumber')}：
                                {item.outerOrderNumber}
                            </div>
                            <div className='package-tag-item'>
                                {services.language.getText('components.PackageTags.deliveryDate')}：
                                {item.expressDeliveryTime.split(' ')[0]}
                            </div>
                            <div className='package-tag-item'>
                                {services.language.getText('components.PackageTags.shippingDate')}：
                                {item.shipTime}
                            </div>
                            <div className='package-tag-item'>
                                {services.language.getText('components.PackageTags.deliveryType')}：
                                {item.orderDeliveryType}
                            </div>
                            <div className='package-tag-item'>
                                <label className='package-tag-checkbox'>
                                    <input type='checkbox' checked={item.haveColdGoods} />
                                    {services.language.getText('components.PackageTags.hasFrozen')}
                                </label>
                                <label className='package-tag-checkbox'>
                                    <input type='checkbox' checked={!item.haveColdGoods} />
                                    {services.language.getText('components.PackageTags.noFrozen')}
                                </label>
                            </div>
                            <div className='package-tag-item'>
                                {services.language.getText('components.PackageTags.units')}：
                                {item.number}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

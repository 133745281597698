import React, { createElement, useEffect, useState } from 'react';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Button } from '@comall-backend-builder/components-basis';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { Modal, Row, Col } from 'antd';
import { KV } from '@/interfaces';
import { tableMixin, paginationMixin } from '@/configs/mixin';

const { language } = services;

interface Props {
    entities: { [prop: string]: Entity };
    entity: Entity;
    row: KV;
}

export const ProductShelfLogs = (props: Props) => {
    const [visible, setVisible] = useState(false);
    const { entities, row } = props;
    const logsEntity = entities.productShelfLogs;

    useEffect(() => {
        if (visible && row) {
            logsEntity.filtersChange({
                csku: row.csku,
                rsku: row.rsku,
                onlineStoreCodes: row.onlineStoreCode,
            });
            logsEntity.pageChange({ page: 1, perPage: paginationMixin.pageSize });
            logsEntity.search(logsEntity.params);
        }
    }, [row, logsEntity, visible]);

    const buttonProps = {
        ...props,
        onClick: () => {
            setVisible(true);
        },
    };

    const tableComponent = createElement(ComponentsManager.get('DataTable'), {
        entity: logsEntity,
        ...tableMixin,
        loadFirstPage: false,
        columns: [
            {
                property: 'type',
                width: 170,
                displayConfig: {
                    converter: {
                        '1': services.language.getText('productShelf.onShelf'),
                        '2': services.language.getText('productShelf.offShelf'),
                    },
                },
            },
            { property: 'createUser', width: 170 },
            { property: 'createTime', width: 220 },
            { property: 'reason' },
        ],
    });

    return (
        <>
            <Button {...buttonProps} />
            <Modal visible={visible} width={1000} footer={false} onCancel={() => setVisible(false)}>
                <div style={{ lineHeight: 3, marginBottom: 20 }}>
                    <Row justify='space-between' gutter={20}>
                        <Col span={8}>
                            {language.getText('productShelf.logsTable.onlineStoreName')}：
                            {row.onlineStoreName}
                        </Col>
                        <Col span={8}>
                            {language.getText('productShelf.logsTable.productName')}：
                            {row.productNameZh}
                        </Col>
                        <Col span={8}>SKU：{row.csku}</Col>
                    </Row>
                </div>
                {tableComponent}
            </Modal>
        </>
    );
};

import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        OfflineOrderInfo: {
            component: 'Card',
            className: 'order-info',
            type: 'inner',
            entity: 'orderList',
            loaderType: 'get',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'OrderInfoBasic',
                    },
                    {
                        component: 'OrderInfoRemark',
                    },
                    {
                        component: 'OrderInfoProgressCard',
                        orderRefundApiPath: '/dc-order/admin/order_refunds',
                    },
                    {
                        component: 'OrderInfoProduct',
                    },
                    {
                        component: 'OrderInfoCheckout',
                    },
                ],
            },
        },
        // OrderInfoProgressCard: {
        //     component: 'OrderInfoProgress',
        // },
    },
};

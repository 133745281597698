import React, { useState, useRef, useEffect } from 'react';
import { Icon, Modal, Carousel } from 'antd';
import './index.less';

interface IProps {
    style?: any;
    /**
     * 图片资源，['img.jpg']
     */
    images: Array<string>;
    /**
     * 图片容器宽度
     */
    imgWidth: string;
    /**
     * 图片容器高度
     */
    imgHeight: string;
}
/**
 * 图片预览组件
 */
export function ImagePreview(props: IProps) {
    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(0);
    const { style, images, imgWidth, imgHeight } = props;

    const handleClick = (img: string, index: number) => {
        setVisible(true);
        setIndex(index);
    };

    return (
        <div style={style} className='image-preview-wrapper'>
            {images.map((img, index) => (
                <div
                    key={img}
                    className='image-preview-item'
                    style={{ width: imgWidth, height: imgHeight }}
                    onClick={() => handleClick(img, index)}
                >
                    <div className='image-preview-item-inner'>
                        <span>
                            <img alt='' src={img} />
                        </span>
                        <Icon type='eye' />
                    </div>
                </div>
            ))}

            <Modal
                className='image-preview-modal'
                centered
                visible={visible}
                footer={null}
                onCancel={() => setVisible(false)}
            >
                <ImgSlider images={images} current={index} />
            </Modal>
        </div>
    );
}

interface ImgSliderProps {
    images: Array<string>;
    current: number;
}

function ImgSlider(props: ImgSliderProps) {
    const { current, images } = props;
    let sliderRef = useRef<any>(null);

    const gotoPrev = () => {
        sliderRef.current && sliderRef.current.prev();
    };

    const gotoNext = () => {
        sliderRef.current && sliderRef.current.next();
    };

    useEffect(() => {
        document.addEventListener('keydown', function(e) {
            if (e.which) {
                if (e.which === 37) {
                    gotoPrev();
                }
                if (e.which === 39) {
                    gotoNext();
                }
            }
        });
    }, [sliderRef]);

    useEffect(() => {
        sliderRef.current && sliderRef.current.goTo(current, true);
    }, [current, sliderRef]);

    return (
        <div className='image-preview-slider'>
            <Icon className='image-preview-slider-prev' type='left' onClick={gotoPrev} />
            <Icon className='image-preview-slider-next' type='right' onClick={gotoNext} />
            <Carousel ref={sliderRef}>
                {images.map((img: string) => (
                    <img key={img} src={img} alt='' />
                ))}
            </Carousel>
        </div>
    );
}

import { formats } from '@comall-backend-builder/types';
// import { services } from '@comall-backend-builder/core/lib';

export class StoreLocationFormat extends formats.StringFormat {
    /**
     * 对数据进行校验
     */
    validate(_rule: any, value: any, callback: any): void {
        callback();
    }
}

import { Button, Input, Modal, message } from 'antd';
import React, { useImperativeHandle, useState } from 'react';
import { formatMobile } from '.';
import { useTimepiece } from './useTimepiece';
import { ModalRef } from './query-order-modal';
import { services } from '@comall-backend-builder/core';
import { interpolate } from '@comall-backend-builder/core/lib/services';
import { Privilege } from '@/components/privilege';

interface Props {
    mobile: string;
    orderId: string;
    resetValue: () => void;
}

export const CodeModal = React.forwardRef<ModalRef | undefined, Props>((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [code, setCode] = React.useState('');
    const { countdown, reset } = useTimepiece();
    const [loading, setLoading] = useState<boolean>(false);
    const { mobile, orderId, resetValue } = props;

    const resend = async () => {
        try {
            await services.api.post(
                {},
                { apiPath: `/base-order/admin/order/${orderId}/send_verification` }
            );
            setCode('');
            reset();
        } catch (err) {
            message.error(services.language.getText('orderManagement.codeErrorMsg'));
        }
    };

    const completeReceipt = async () => {
        if (!code) {
            message.error(services.language.getText('orderManagement.codeInput'));
            return;
        }
        setLoading(true);
        let secondsToGo = 10;
        try {
            await services.api.post(
                {
                    verificationCode: code,
                },
                {
                    apiPath: `/base-order/admin/order/${orderId}/verification/completed`,
                    customHeaders: {
                        Xmo: 'PUT',
                    },
                }
            );
            setOpen(false);
            resetValue();
            setCode('');
            const modal = Modal.success({
                title: services.language.getText('orderManagement.successMsg'),
            });
            const timer = setInterval(() => {
                secondsToGo -= 1;
            }, 1000);

            setTimeout(() => {
                clearInterval(timer);
                modal.destroy();
            }, secondsToGo * 1000);
        } catch (err) {
            console.log('err', err);
            message.error((err as any).body.err_msg || '服務異常');
        } finally {
            setLoading(false);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            open: () => {
                setOpen(true);
                reset();
            },
        }),
        []
    );

    return (
        <Modal visible={open} width={400} footer={<></>} closeIcon={<></>}>
            <div className='verification-code-modal'>
                <img
                    src={require('@/resources/images/warning.jpg')}
                    style={{ width: '40px', height: '40px' }}
                />
                <div className='code-modal-title'>
                    {services.language.getText('orderManagement.codeInput')}
                </div>
                <div className='code-modal-tip'>
                    {interpolate(services.language.getText('orderManagement.consigneeMobile'), {
                        mobile: formatMobile(mobile),
                    })}{' '}
                    {countdown > 0 ? (
                        <span style={{ color: 'blue', fontWeight: 'normal' }}>
                            {Math.round(countdown / 1000)}s
                        </span>
                    ) : (
                        <span
                            style={{ color: 'blue', fontWeight: 'normal', cursor: 'pointer' }}
                            onClick={resend}
                        >
                            {services.language.getText('orderManagement.resend')}
                        </span>
                    )}
                </div>
                <Input
                    placeholder={services.language.getText('orderManagement.codeInput')}
                    style={{ marginTop: '10px' }}
                    value={code}
                    onChange={(e) => {
                        setCode(e.target.value);
                    }}
                />
                <Privilege path='order.self_pickup_view.self_pickup_order_complate'>
                    <Button
                        loading={loading}
                        type='primary'
                        style={{ marginTop: '10px' }}
                        onClick={() => completeReceipt()}
                    >
                        {services.language.getText('orderManagement.confirmReceipt')}
                    </Button>
                </Privilege>
                <Button
                    onClick={() => {
                        setOpen(false);
                        setCode('');
                    }}
                    style={{ marginTop: '10px' }}
                >
                    {services.language.getText('orderManagement.cancel')}
                </Button>
            </div>
        </Modal>
    );
});

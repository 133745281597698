import React, { useState, useMemo, useEffect } from 'react';
import { TreeSelect } from 'antd';
import { isEmpty, isFunction } from 'lodash-es';
import { KV } from '@/interfaces';
import { Loader } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import { ComponentMetadata } from '@comall-backend-builder/core/lib/parser';

export type TreeNode<Node> = Node & {
    isLeaf?: boolean;
    children?: TreeNode<Node>[];
};

export interface ToTreeOptions<T extends KV = KV> {
    id?: string;
    pId?: string;
    isLeaf?: (item: T) => boolean;
}
/**
 * 列表数据转树形结构
 *
 * @param nodes 列表数据
 * @param options 配置项
 */
export function toTree<T extends KV = KV>(
    nodes: T[],
    options: ToTreeOptions<T> = {}
): TreeNode<T> | undefined {
    if (!nodes || !nodes.length) return;
    const { id = 'id', pId = 'parentId', isLeaf = (node) => node.isLeaf } = options;

    const [firstNode, ...restNodes] = nodes;
    const rootNode = {
        ...firstNode,
        selectable: false,
    };
    const idNodeMap: KV = {
        [rootNode[id]]: rootNode,
    };

    for (const node of restNodes) {
        const currentNode = (idNodeMap[node[id]] = {
            ...node,
            isLeaf: isLeaf(node),
        });
        const parentNode = idNodeMap[currentNode[pId]];
        if (parentNode) {
            parentNode.children = parentNode.children || [];
            parentNode.children.push(currentNode);
        }
    }

    return rootNode;
}

interface Props {
    /**
     * 输入组件的 value
     */
    value: string[];
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: string[]) => void;
    /**
     * 接口路径
     */
    apiPath: string;
    /**
     * 配置文件
     */
    itemConfig: ComponentMetadata;
}

export const VirtualCategoryTree: React.FC<Props> = (props) => {
    const { value = [], onChange } = props;

    const [data, setData] = useState<KV[]>([]);

    const treeData = useMemo(() => toTree(data), [data]);

    const [displayValue, setDisplayValue] = useState<string[]>(value);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        const { itemConfig } = props;
        const { apiPath, formatProps } = itemConfig;
        if (!apiPath) return;
        let params = {};
        if (formatProps && isFunction(formatProps)) {
            params = {
                ...formatProps(props),
            };
        }
        const response = await Loader.load('get', {
            ...params,
            apiPath,
        });
        setData((prevData) => [...prevData, ...response]);
    }

    function renderTreeNode(node: any) {
        return (
            <TreeSelect.TreeNode key={node.id} value={node.id} title={node.name} {...node}>
                {!isEmpty(node.children) && node.children.map(renderTreeNode)}
            </TreeSelect.TreeNode>
        );
    }

    return (
        <TreeSelect
            multiple={true}
            placeholder={language.getText('common.pleaseSelect')}
            value={displayValue}
            loadData={loadData}
            onChange={(value) => {
                setDisplayValue(value);
                onChange(value);
            }}
        >
            {treeData && renderTreeNode(treeData)}
        </TreeSelect>
    );
};

import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
export default [
    {
        key: '/dc-goods/admin/offline_categories',
        loader: {
            get: async function(data: any, config: any) {
                return await services.api.get(data, config);
            },
        },
    },
    {
        key: '/dc-goods/admin/offline_categories/tree',
        loader: {
            get: async function(data: any, config: any) {
                let params = data || {};
                params.status = 'ENABLED';
                if (params.id) {
                    params.parentId = params.id;
                    delete params.id;
                }
                config = {
                    apiPath: '/dc-goods/admin/offline_categories',
                };
                const response: any = await services.api.get(data, config);

                let datas = response.result;
                const treeData = [];
                for (let item of datas) {
                    treeData.push({ ...item, isLeaf: item.isLeaf });
                }
                return { id: params.parentId, children: treeData };
            },
        },
    },
    {
        key: '/dc-goods/admin/offline_categories/tree_no_root',
        loader: {
            get: async function(data: any, config: any) {
                let params = data || {};
                params.status = 'ENABLED';
                params.parentId = params.parentId || -2;
                delete params.id;
                config = {
                    apiPath: '/dc-goods/admin/offline_categories',
                };
                const response: any = await services.api.get(data, config);

                let datas = response.result;
                const treeData = [];
                for (let item of datas) {
                    treeData.push({ ...item, isLeaf: item.isLeaf });
                }
                return treeData;
            },
        },
    },
    {
        key: '/dc-goods/admin/offline_categories/tree_select',
        loader: {
            get: async function(data: any, config: any) {
                let params = data || {};
                params.status = 'ENABLED';
                if (params.id) {
                    params.parentId = params.id;
                    delete params.id;
                }
                config = {
                    apiPath: '/dc-goods/admin/offline_categories',
                };
                const response: any = await services.api.get(data, config);
                return response.result || [];
            },
        },
    },
    {
        // 标准分类列表左侧树 非异步请求
        key: '/dc-goods/admin/offline_categories/offline_category_tree',
        loader: {
            get: async (data: any, config: any) => {
                let response: any = await services.api.get({ ...data }, config);

                function format(a: any) {
                    if (isEmpty(a)) return null;
                    if (a.childTree) {
                        a.children = a.childTree.map((i: any) => format(i));
                    }
                    delete a.childTree;
                    return {
                        ...a,
                        title: a.name,
                        key: a.id,
                        // isLeaf: a.hasChild === 1 ? false : true,
                    };
                }
                return isEmpty(response[0]) ? [] : [format(response[0])];
            },
        },
    },
    {
        //设置特有属性
        key: '/dc-goods/admin/offline_categories_attribute',
        loader: {
            get: async (data: any, config: any) => {
                const result: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-goods/admin/offline_categories_attribute/${data.id}`,
                });
                if (result.attributeList.length <= 0) {
                    result.attributeList = [{}];
                }

                return result;
            },
            put: async (data: any, config: any) => {
                const attributeList = data.attributeList.filter(
                    (item: {}) => JSON.stringify(item) !== '{}'
                );
                const result: any = await services.api.put(attributeList, {
                    ...config,
                    apiPath: `/dc-goods/admin/offline_categories_attribute/${data.id}`,
                });
                return result;
            },
        },
    },
];

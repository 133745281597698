import { createElement, PureComponent } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';

export class ProductDisplay extends PureComponent {
    render() {
        const {
            entity,
            componentId,
            onFieldChange,
            onInit,
            renderComponent,
            wrappedComponentRef,
        } = this.props;
        return createElement(ComponentsManager.get(renderComponent), {
            entity,
            componentId,
            className: 'product-display',
            onFieldChange,
            wrappedComponentRef,
            onInit,
            symbol: '',
            direction: 'horizontal',
            fields: [
                { property: 'collection' },
                {
                    property: 'waterResistanceEn',
                },
                {
                    property: 'sizeRecommendationsEn',
                },
                {
                    property: 'sizeRecommendationsZh',
                },
                {
                    property: 'nutrientInformationEn',
                },
                {
                    property: 'nutrientInformationZh',
                },

                {
                    property: 'regionEn',
                },
                {
                    property: 'heelMeasurementEn',
                },
                {
                    property: 'materialCareEn',
                },
                {
                    property: 'materialCareZh',
                },

                {
                    property: 'skinTypeEn',
                },
                {
                    property: 'storageInstructionsEn',
                },
                {
                    property: 'storageInstructionsZh',
                },

                {
                    property: 'diamondCaratWeightEn',
                },
                {
                    property: 'grapeVarietalEn',
                },
                {
                    property: 'vintageEn',
                },
                {
                    property: 'tastingNotesEn',
                },
            ],

            fieldRow: {
                gutter: 16,
            },
            fieldCol: {
                lg: 18,
                md: 21,
                sm: 24,
            },
            labelCol: 24,
            controlCol: 24,
            footer: {
                style: {
                    display: 'none',
                },
            },
        });
    }
}

import { API_TYPE, PATH_NAME } from '@/containers/picking/enums';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        philipsPickingDetail: {
            apiPath: '/dc-order-picking-philips/admin/picking_lists',
            properties: {
                id: {
                    type: 'string',
                    displayName: '',
                },
                pickingListNumber: {
                    type: 'string',
                    displayName: '<<picking.pickingListNumber>>',
                },
            },
        },
    },
    components: {
        PhilipsPickingAssistantDetailPage: {
            entity: 'philipsPickingDetail',
            component: 'Card',
            content: {
                component: 'PickingAssistantDetail',
                apiType: API_TYPE.PICKING_PHILIPS,
                pickPathname: PATH_NAME.PHILIPS_PICK_PATH_NAME,
            },
        },
    },
};

import * as React from 'react';
import classNames from 'classnames';

import { ArrayMode } from '@comall-backend-builder/types';
import { UploadImageVideo } from '@/components/upload-image-video';
import { ImagePreview } from '@/components/image-preview';

export class UploadImageVideoMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, preview } = displayInfo;

        if (array && array.length) {
            // 图片配置信息
            const imageConfig = {
                className: classNames('type-array mode-array-images', className),
                style,
            };

            // 预览组件配置信息
            const previewProps = {
                type: 'modal',
                ...preview,
                imageConfig,
                items: array,
            };

            return <ImagePreview {...previewProps} />;
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <UploadImageVideo {...controlInfo} />;
    }
}

/**
 * Table 中 actionColumn 导出按钮
 * @param {string} renderType link|button 组件渲染方式、默认link按照a连接渲染
 * @param {string} statusKey actionColumn 隐藏样式
 * @param {string} requestType 请求方式、href\request
 * @param {string} modalContent 弹层信息
 */

import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Modal as AntModal, Tooltip as AntTooltip, message as AntMessage } from 'antd';
import { Button } from '@comall-backend-builder/components-basis';

type Props = any;
type State = any;

export class TableExportButton extends PureComponent<Props, State> {
    static defaultProps = {
        type: 'all',
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            finish: true,
        };
    }

    handelExport = () => {
        const { row, type } = this.props;
        this.setState({
            finish: false,
        });
        const fileType = type === 'all' ? 'ALL' : 'FAIL';
        services.api
            .download({ type: fileType }, { apiPath: row.downloadAllApiPath[fileType] })
            .catch(() => {
                AntModal.error({
                    content: services.language.getText('importRecord.exportFailed'),
                    okText: services.language.getText('importRecord.ofCourseIKnown'),
                });
            })
            .finally(() => {
                this.setState({ finish: true });
            });
    };

    handelClick = () => {
        const { finish } = this.state;
        const { type } = this.props;
        if (!finish) {
            AntMessage.info(services.language.getText('importRecord.exporting'));
            return;
        }
        const modalContentId =
            type === 'all' ? 'importRecord.sureToExportAll' : 'importRecord.sureToExportFailures';
        AntModal.confirm({
            content: services.language.getText(modalContentId),
            okText: services.language.getText('confirm'),
            cancelText: services.language.getText('cancel'),
            onOk: this.handelExport,
        });
    };

    render() {
        const { row, type } = this.props;
        const textId = type === 'all' ? 'importRecord.exportAll' : 'importRecord.exportFailures';
        // 区分请求方式 使用href下载和接口请求下载
        if (row.disabled) {
            return (
                <span className='table-export-button' style={{ color: '#8c8c8c' }}>
                    {services.language.getText(textId)}
                </span>
            );
        }
        if (type === 'error' && row.noError) {
            return (
                <AntTooltip title={services.language.getText('importRecord.noFailedExcels')}>
                    <span className='table-export-button' style={{ color: '#8c8c8c' }}>
                        {services.language.getText(textId)}
                    </span>
                </AntTooltip>
            );
        }
        return (
            <Button type='link' className='table-export-button' onClick={this.handelClick}>
                {services.language.getText(textId)}
            </Button>
        );
    }
}

import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CouponGroup } from '@/components/coupon-group';

export class ArrayCouponGroupMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CouponGroup {...controlInfo} />;
    }
}

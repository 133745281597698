import React, { PureComponent, Fragment } from 'react';
import { services, Loader } from '@comall-backend-builder/core';
import { ExtendedParentComponentProps } from '@comall-backend-builder/components-basis';
import { Modal, Button, Form, Input, message as AntMessage } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { cloneDeep, isEmpty, isEqual, trim, pickBy, get, map } from 'lodash';

const { language, interpolate } = services;
let i = 0;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

interface Props extends FormComponentProps, ExtendedParentComponentProps {
    componentType: 'searchWords' | 'hotWords';
    row: { [key: string]: any };
    option?: any;
}
export class modalSetWords extends PureComponent<Props, any> {
    constructor(props: any) {
        super(props);
        const languageKey = this.props.componentType === 'hotWords' ? 'hotWords' : 'searchWords';
        this.state = {
            visible: false,
            searchWords: [''],
            confirmLoading: false,
            keys: [0],
            languageKey,
        };
    }
    componentDidMount() {
        const { option } = this.props;
        let languageKey = this.props.componentType === 'hotWords' ? 'hotWords' : 'searchWords';
        if (option) {
            languageKey = `${languageKey}.${option.id}`;
        }
        this.setState({ searchWords: cloneDeep(get(this.props.row, `${languageKey}`)) });
    }
    componentWillReceiveProps(nextProps: any) {
        if (!isEqual(nextProps.row, this.props.row)) {
            const { option } = this.props;
            let languageKey = this.props.componentType === 'hotWords' ? 'hotWords' : 'searchWords';
            if (option) {
                languageKey = `${languageKey}.${option.id}`;
            }
            this.setState(
                { searchWords: cloneDeep(get(nextProps.row, `${languageKey}`)) },
                this.props.form.resetFields
            );
        }
    }
    toggleModal = (flag: boolean) => () => {
        const { keys } = this.handleValue(this.state.searchWords);
        this.setState({ visible: flag, keys }, this.props.form.resetFields);
    };
    handleSubmit = (e: any) => {
        const { row, form, componentType, option } = this.props;
        let apiPath =
            componentType === 'hotWords'
                ? '/dc-cms-api/admin/hot_words/detail'
                : '/dc-cms-api/admin/searchWords/detail';
        const paramsKey = componentType === 'hotWords' ? 'hotWords' : 'searchWords';
        const subSiteKey = componentType === 'hotWords' ? 'id' : 'id';
        if (option) {
            apiPath = `${apiPath}/${option.id}`;
        }
        form.validateFields((error: any, values: any) => {
            if (!error) {
                const { confirmLoading, keys } = this.state;
                if (confirmLoading) return;
                this.setState({ confirmLoading: true });
                const words = keys.map((item: number) => values['names'][item]);

                const params = {
                    [subSiteKey]: row['id'],
                    [paramsKey]: words,
                    status: row['status'],
                };
                Loader.load('post', {
                    params,
                    apiPath,
                })
                    .finally(() => {
                        this.setState({ confirmLoading: false });
                    })
                    .then(() => {
                        const { entity, params: entityParams = {} } = this.props;
                        entity && entity.search(entityParams);
                        AntMessage.success(services.language.getText('saveFully'), 1.5);
                        this.toggleModal(false)();
                    })
                    .catch(services.errorHandle);
            }
        });
    };
    onAddTag() {
        const { keys } = this.state;
        const nextKeys = keys.concat(++i);
        this.setState({ keys: [...nextKeys] });
    }

    onRemoveTag(index: number) {
        const { keys } = this.state;
        if (keys.length === 1) {
            return;
        }
        const nextKeys = keys.filter((key: any) => {
            return key !== index;
        });
        this.setState({ keys: [...nextKeys] }, () => {
            //处理删除的重复校验
            const newNames = Object.assign(this.props.form.getFieldValue('names'), { index: null });
            this.props.form.setFieldsValue({ names: newNames });
            this.props.form.validateFields();
        });
    }
    handleValue: (
        value?: Array<string>
    ) => { keys: Array<number>; names: { [key: string]: any } } = (value = ['']) => {
        let names: { [key: string]: any } = { 0: '' };
        value = isEmpty(value) ? [''] : value;

        const keys = map(value, (item: string, index) => {
            names[index] = item;
            return index;
        });
        return { keys, names };
    };

    renderItem = () => {
        const { searchWords, keys, languageKey } = this.state;
        const {
            form: { getFieldDecorator, getFieldValue, validateFields },
        } = this.props;
        const { names } = this.handleValue(searchWords);
        //只有初始化时才会做i的赋值
        if (i === 0) i = keys.length - 1;
        return (
            <Fragment>
                {keys.map((k: number, index: number) => {
                    return (
                        <Form.Item
                            label={interpolate(
                                language.getText(`designManager.${languageKey}.defaultKey`),
                                {
                                    key: index + 1,
                                }
                            )}
                            required={false}
                            key={`item-${k}-${index}`}
                            {...formItemLayout}
                            style={{ width: '100%' }}
                        >
                            {getFieldDecorator(`names.${k}`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                    {
                                        validator: (rule: any, value: any, callback: any) => {
                                            try {
                                                if (!value || !trim(value)) {
                                                    callback(
                                                        new Error(
                                                            language.getText(
                                                                `designManager.${languageKey}.required`
                                                            )
                                                        )
                                                    );
                                                }
                                                const names = getFieldValue(`names`);
                                                if (
                                                    Object.keys(pickBy(names, (v) => v === value))
                                                        .length > 1
                                                ) {
                                                    callback(
                                                        new Error(
                                                            language.getText(
                                                                `designManager.${languageKey}.isExist`
                                                            )
                                                        )
                                                    );
                                                } else {
                                                    callback();
                                                }
                                            } catch (err) {
                                                callback(err);
                                            }
                                        },
                                    },
                                ],
                                initialValue: isEmpty(names[k]) ? null : names[k],
                            })(
                                <Input
                                    placeholder={language.getText(
                                        `designManager.${languageKey}.required`
                                    )}
                                    style={{ width: '50%', marginRight: 8 }}
                                    maxLength={50}
                                    onBlur={validateFields}
                                />
                            )}
                            <Button
                                style={
                                    index === keys.length - 1 && keys.length === 1
                                        ? { display: 'none' }
                                        : undefined
                                }
                                type='link'
                                size='small'
                                className='remove-tag-button'
                                onClick={() => this.onRemoveTag(k)}
                            >
                                {language.getText('delete')}
                            </Button>
                            <Button
                                type='link'
                                size='small'
                                className='add-tag-button'
                                style={
                                    !keys || !keys.length || index === keys.length - 1
                                        ? undefined
                                        : { visibility: 'hidden' }
                                }
                                onClick={() => this.onAddTag()}
                            >
                                {language.getText('add')}
                            </Button>
                        </Form.Item>
                    );
                })}
            </Fragment>
        );
    };
    render() {
        const { visible, confirmLoading, languageKey } = this.state;
        const { option } = this.props;
        return (
            <Fragment>
                <Button onClick={this.toggleModal(true)} type='link' style={{ marginLeft: 0 }}>
                    {get(option, 'name') ||
                        language.getText(`designManager.${languageKey}.setWords`)}
                </Button>
                <Modal
                    confirmLoading={confirmLoading}
                    title={`${language.getText(`designManager.${languageKey}.setWords`)}${
                        option ? `（${get(option, 'name')}）` : ''
                    }`}
                    mask={false}
                    visible={visible}
                    maskClosable={true}
                    okText={services.language.getText('submit')}
                    cancelText={services.language.getText('cancel')}
                    onOk={this.handleSubmit}
                    onCancel={this.toggleModal(false)}
                >
                    {this.renderItem()}
                </Modal>
            </Fragment>
        );
    }
}

export const ModalSetWords = Form.create()(modalSetWords);

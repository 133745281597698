import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '@/configs/mixin';
import { IMPORT_TEMPLATE_URL } from '@/constants';
import { recordImportLogs } from '@/services';
import { services } from '@comall-backend-builder/core';

const urlMappingListComponentConfig = {
    UrlMappingList: {
        component: 'Card',
        entity: 'urlMappingManagementList',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'UrlMappingListTable',
                },
            ],
        },
    },
    UrlMappingListTable: {
        ...tableMixin,
        component: 'DataTable',
        scroll: {
            x: 'max-content',
        },
        columns: [{ property: 'oldUrl' }, { property: 'newUrl' }],
    },
};

const urlMappingManagementComponentConfig = {
    UrlMappingManagement: {
        component: 'Card',
        entity: 'urlMappingManagement',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'UrlMappingManagementHeader',
                },
                {
                    component: 'UrlMappingManagementTable',
                },
            ],
        },
    },
    UrlMappingManagementHeader: {
        component: 'FlexLayout',
        direction: 'horizontal',
        style: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 15,
        },
        items: [
            {
                component: 'OSSExportButton',
                text: '<<brands.exportInfo>>',
                type: 'link',
                style: {
                    alignSelf: 'center',
                },
                url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.URKL_MAPPING}`,
            },
        ],
    },
    UrlMappingManagementTable: {
        ...tableMixin,
        component: 'DataTable',
        scroll: {
            x: 'max-content',
        },
        columns: [{ property: 'name' }],
        actionColumn: {
            title: '<<actions>>',
            fixed: 'right',
            items: [
                {
                    type: 'component',
                    component: 'TableActionImport',
                    text: '<<systemManagement.urlMapping.import>>',
                    config: {
                        style: {
                            marginRight: 15,
                        },
                        apiPath: `/dc-file/excels?location_id=2`,
                        showSuccessMessage: false,
                        showErrorMessage: true,
                        onUploadComplete(response: any, row: any) {
                            if (response) {
                                recordImportLogs({
                                    name: services.language.getText(
                                        'virtualCategory.importProduct'
                                    ),
                                    apiPath: `/dc-master-data/admin/urlmapping/import`,
                                    params: {
                                        resourceId: response.id,
                                        channelType: row.id,
                                    },
                                });
                            }
                        },
                    },
                    privilege: {
                        path: 'system_management.url_mapping_view.url_mapping_import',
                        level: 'full',
                    },
                },

                {
                    type: 'component',
                    component: 'TableActionExport',
                    text: '<<systemManagement.urlMapping.export>>',
                    style: {
                        marginRight: 15,
                    },
                    config: {
                        source: {
                            apiPath: `/dc-master-data/admin/urlmapping/export/take?channel_type={{row.id}}`,
                            paramsFilter: [],
                        },
                        range: {
                            apiPath:
                                '/dc-master-data/admin/urlmapping/export/count?channelType={{row.id}}',
                        },
                        showErrorMessage: true,
                        showSuccessTip: true,
                    },
                    privilege: {
                        path: 'system_management.url_mapping_view.url_mapping_export',
                        level: 'full',
                    },
                },
                {
                    text: '<<systemManagement.urlMapping.view>>',
                    type: 'link',
                    path: '/system/url-mapping/{{row.id}}',
                    privilege: {
                        path: 'system_management.url_mapping_view.url_mapping_view',
                        level: 'full',
                    },
                },
            ],
        },
    },
};

export const config: Config = {
    entities: {
        urlMappingManagement: {
            apiPath: '/loader/dc-master-data/admin/urlmapping/list',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<systemManagement.urlMapping.channelType>>',
                },
            },
        },
        urlMappingManagementList: {
            apiPath: '/loader/dc-master-data/admin/urlmapping',
            properties: {
                oldUrl: {
                    type: 'string',
                    displayName: '<<systemManagement.urlMapping.oldUrl>>',
                },
                newUrl: {
                    type: 'string',
                    displayName: '<<systemManagement.urlMapping.newUrl>>',
                },
            },
        },
    },

    components: {
        UrlMappingManagementContainer: {
            component: 'Viewport',
        },
        ...urlMappingManagementComponentConfig,
        ...urlMappingListComponentConfig,
    },
};

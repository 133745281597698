/**
 * 报表相关constants
 */
import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';
import { DELIVERY_TYPE_VALUES } from './order';

/**
 * 配送类型名
 */
export const DELIVERY_TYPE_NAMES = {
    [DELIVERY_TYPE_VALUES.PICK_UP]: language.getText('report.deliveryType.pickUp'),
    [DELIVERY_TYPE_VALUES.DELIVERY]: language.getText('report.deliveryType.delivery'),
    [DELIVERY_TYPE_VALUES.EXPRESS]: language.getText('report.deliveryType.express'),
};
/**
 * 配送类型候选项
 */
export const DELIVERY_TYPE_OPTIONS = generateOptions(DELIVERY_TYPE_VALUES, DELIVERY_TYPE_NAMES);

/**
 * 液态名称
 */
export enum LIQUID_STATE_VALUES {
    /**
     * 其他
     */
    OTHER = 'other',
    /**
     * 飞利浦
     */
    PHILIPS = 'philips',
}

/**
 * 配送类型名
 */
export const LIQUID_STATE_NAMES = {
    [LIQUID_STATE_VALUES.OTHER]: language.getText('report.liquidStateType.other'),
    [LIQUID_STATE_VALUES.PHILIPS]: language.getText('report.liquidStateType.philips'),
};
/**
 * 配送类型候选项
 */
export const LIQUID_STATE_OPTIONS = generateOptions(LIQUID_STATE_VALUES, LIQUID_STATE_NAMES);

/**
 * 售卖渠道值
 */
export enum REPORT_SALE_CHANNEL_VALUES {
    /**
     * 线上
     */
    ONLINE = '0',
    /**
     * 线下
     */
    OFFLINE = '1',
}
/**
 * 售卖渠道名
 */
export const REPORT_SALE_CHANNEL_NAMES = {
    [REPORT_SALE_CHANNEL_VALUES.ONLINE]: language.getText('report.online'),
    [REPORT_SALE_CHANNEL_VALUES.OFFLINE]: language.getText('report.offline'),
};
/**
 * 售卖渠道候选项
 */
export const REPORT_SALE_CHANNEL_OPTIONS = generateOptions(
    REPORT_SALE_CHANNEL_VALUES,
    REPORT_SALE_CHANNEL_NAMES
);

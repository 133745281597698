import { services } from '@comall-backend-builder/core';
import { KV } from '@/interfaces';

export default [
    {
        key: '/dc-goods/admin/tags',
        loader: {
            get: async function(data: any, config: any) {
                if (!data.id) {
                    return await services.api.get(data, config);
                } else {
                    const result: KV = await services.api.get(data, config);
                    result.priorityLevel = parseInt(result.priorityLevel);
                    let transformed: KV = {
                        ...result,
                        productStatus: result.status,
                    };
                    return transformed;
                }
            },
            put: async function(data: any, config: any) {
                data.priorityLevel = data.priorityLevel.toString();
                return await services.api.put(data, config);
            },
            post: async function(data: any, config: any) {
                data.priorityLevel = data.priorityLevel.toString();
                return await services.api.post(data, config);
            },
        },
    },
    {
        key: '/dc-promotion/admin/promotion_tags',
        loader: {
            get: async function(data: any, config: any) {
                return await services.api.get(data, config);
            },
            put: async function(data: any, config: any) {
                return await services.api.put(data, config);
            },
        },
    },
];

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { Button } from '@comall-backend-builder/components-basis';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { OptionType } from '@/interfaces';

export interface BrandBlacklistDetailProps {
    fields: any;
    [key: string]: any;
}

export class brandBlacklistDetail extends Component<BrandBlacklistDetailProps, any> {
    render() {
        const { fields } = this.props;
        const brands: Array<OptionType> = get(fields, 'brandIds', []);
        const formItemLayout = {
            labelCol: {
                span: 3,
            },
            wrapperCol: {
                span: 10,
            },
        };
        return (
            <div className='blacklist-detail'>
                <Form {...formItemLayout}>
                    <Form.Item label={services.language.getText('productLimit.productBrand')}>
                        <div className='brand-total'>总计 {brands.length} 个品牌</div>
                        <div className='brand-list'>
                            {brands.map((item) => (
                                <div key={item.id}>{item.name}</div>
                            ))}
                        </div>
                    </Form.Item>
                    <Form.Item label={services.language.getText('blacklist.blacklistReason')}>
                        <div style={{ wordWrap: 'break-word', wordBreak: 'normal' }}>
                            {get(fields, 'reason')}
                        </div>
                    </Form.Item>
                </Form>
                <div style={{ textAlign: 'center' }}>
                    <Button route='goBack'>{services.language.getText('back')}</Button>
                </div>
            </div>
        );
    }
}
function mapStateToProps(_state: any, props: any) {
    return {
        fields: get(props, 'entity.fields'),
    };
}
export const BrandBlacklistDetail = connect(mapStateToProps)(brandBlacklistDetail);

import { connect } from 'react-redux';
import { defaults, cloneDeep } from 'lodash';

import { CreateForm } from '@comall-backend-builder/components-basis';
import { actions, services } from '@comall-backend-builder/core';
import { Store } from '../../interfaces/store';

const FIELD_NAME_NAME = 'storeInfo.name';
const FIELD_NAME_ORGANIZATION = 'storeInfo.organizations';
const FIELD_NAME_CONTACTS = 'contactInformation.contacts';
const FIELD_NAME_MASTER_MOBILE = 'contactInformation.masterMobile';

function mapStateToProps(state: any, props: any) {
    return {
        footer: {
            className: 'add-store-form-footer',
            items: [
                {
                    type: 'default',
                    text: services.language.getText('cancel'),
                    htmlType: 'button',
                    route: 'goBack',
                },
                {
                    type: 'primary',
                    text: services.language.getText('save'),
                    submitSuccessBehavior: {
                        route: 'goBack',
                    },
                },
            ],
        },
        fields: [
            {
                property: 'storeInfo',
            },
            {
                property: 'contactInformation',
            },
            {
                property: 'addressInfo',
            },
        ],
    };
}

function mapDispatchToProps(dispatch: any, props: any) {
    const { componentId, entity, onFieldChange } = props;
    let form: any;
    return defaults(
        {
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            // 表单域改变
            onFieldChange: (name: any, value: Store) => {
                let _metainfo;
                if (name === 'storeInfo.code') {
                    const { storeInfo, contactInformation } = value;
                    let { code, name, organizationId } = storeInfo;
                    dispatch(actions.formChangeAction(componentId, name, code));
                    if (name) {
                        let { contacts, masterMobile } = contactInformation;
                        dispatch(actions.formChangeAction(componentId, FIELD_NAME_NAME, name));
                        dispatch(
                            actions.formChangeAction(
                                componentId,
                                FIELD_NAME_ORGANIZATION,
                                organizationId
                            )
                        );

                        dispatch(
                            actions.formChangeAction(componentId, FIELD_NAME_CONTACTS, contacts)
                        );
                        dispatch(
                            actions.formChangeAction(
                                componentId,
                                FIELD_NAME_MASTER_MOBILE,
                                masterMobile
                            )
                        );

                        form.setFields({
                            [FIELD_NAME_NAME]: { value: name },
                            [FIELD_NAME_ORGANIZATION]: { value: organizationId },
                            [FIELD_NAME_CONTACTS]: { value: contacts },
                            [FIELD_NAME_MASTER_MOBILE]: { value: masterMobile },
                        });
                        _metainfo = cloneDeep(entity.metainfo);
                        Object.assign(_metainfo.properties.storeInfo.properties.name, {
                            disabled: true,
                        });
                        Object.assign(_metainfo.properties.contactInformation.properties.contacts, {
                            disabled: true,
                        });
                        Object.assign(
                            _metainfo.properties.contactInformation.properties.masterMobile,
                            { disabled: true }
                        );
                        form.validateFields([
                            FIELD_NAME_NAME,
                            FIELD_NAME_ORGANIZATION,
                            FIELD_NAME_CONTACTS,
                            FIELD_NAME_MASTER_MOBILE,
                        ]);
                        entity.setMeta(_metainfo);
                    } else {
                        dispatch(actions.formChangeAction(componentId, FIELD_NAME_NAME, ''));
                        dispatch(
                            actions.formChangeAction(componentId, FIELD_NAME_ORGANIZATION, '')
                        );
                        dispatch(actions.formChangeAction(componentId, FIELD_NAME_CONTACTS, ''));
                        dispatch(
                            actions.formChangeAction(componentId, FIELD_NAME_MASTER_MOBILE, '')
                        );

                        form.setFields({
                            [FIELD_NAME_NAME]: { value: '' },
                            [FIELD_NAME_ORGANIZATION]: { value: '' },
                            [FIELD_NAME_CONTACTS]: { value: '' },
                            [FIELD_NAME_MASTER_MOBILE]: { value: '' },
                        });
                        _metainfo = cloneDeep(entity.metainfo);
                        Object.assign(_metainfo.properties.storeInfo.properties.name, {
                            disabled: false,
                        });
                        Object.assign(_metainfo.properties.storeInfo.properties.organizationId, {
                            disabled: false,
                        });
                        Object.assign(_metainfo.properties.contactInformation.properties.contacts, {
                            disabled: false,
                        });
                        Object.assign(
                            _metainfo.properties.contactInformation.properties.masterMobile,
                            { disabled: false }
                        );
                        entity.setMeta(_metainfo);
                    }
                } else {
                    dispatch(actions.formChangeAction(componentId, name, ''));
                }
            },
        }
    );
}

export const AddStoreForm = connect(mapStateToProps, mapDispatchToProps)(CreateForm);

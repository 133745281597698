import { GlobalData, LoginUrl } from '@/utils/globalData';
import { connect } from 'react-redux';
/**
 * 用户退出登录，重新刷新页面
 * 部署后回话失效，前端主动刷新页面
 * 权限失效获取权限树重新塞进store
 */
export const NoAuthorizationRefresh = connect((state: any, props: any) => {
    if (!state.user.accessToken) {
        if (GlobalData._kc) {
            GlobalData._kc.logout({
                redirectUri: window.location.origin + `/#/${LoginUrl}?status=logout`,
            });
        } else {
            window.location.href = '#/login';
        }
    }

    return {};
})(() => {
    return null;
});

import React, { PureComponent } from 'react';
import { Text } from '@comall-backend-builder/components-basis';
import { Avatar as AntAvatar } from 'antd';
import { Loader } from '@comall-backend-builder/core';
interface userProfileMiniProps {
    loginUserApiPath: any;
    config: any;
    [key: string]: any;
}
interface userProfileMiniState {
    loginUser: loginUserState;
}
interface loginUserState {
    avatar: string;
    realName: string;
}
export class UserProfileMini extends PureComponent<userProfileMiniProps, userProfileMiniState> {
    state = {
        loginUser: {
            avatar: '',
            realName: '',
        },
    };

    componentDidMount = () => {
        const { loginUserApiPath, config } = this.props;
        Loader.load('get', {
            apiPath: loginUserApiPath,
            ...config,
        }).then((res: any) => {
            //TODO 提供售后添加备注中使用，后优化
            window.sessionStorage.setItem('realName', res.realName);
            //TODO 提供线下业务授权码使用，后优化
            window.sessionStorage.setItem('userJob', JSON.stringify(res.userJob));
            // 缓存onlineStoreIds
            window.sessionStorage.setItem(
                'selectOnlineStoreIds',
                JSON.stringify(res.onlineStoreIds)
            );
            this.setState({
                loginUser: res,
            });
        });
    };

    render() {
        const { loginUser } = this.state;
        const {
            avatar: { displayConfig },
            userName: { configs },
        } = this.props;
        if (loginUser && loginUser.avatar) {
            displayConfig.src = loginUser.avatar;
        }
        return (
            <div>
                <AntAvatar {...displayConfig} className='user-avatar' />
                <Text text={loginUser ? loginUser.realName : ''} {...configs} />
            </div>
        );
    }
}

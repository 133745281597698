/*
 * @Author: zhulu
 * @Date: 2021-04-23 14:03:29
 * @Descripttion: 订单确认提货的表单
 */
import React, { useImperativeHandle, forwardRef } from 'react';
import { Input as AntInput } from 'antd';
import Form from 'antd/lib/form/Form';
import { trim, flattenDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';
import WjaProductList from './wja-product-list';
//@ts-ignore
const FCForm = forwardRef(({ form, onSubmit, wjaProductList, extraData }, ref) => {
    useImperativeHandle(ref, () => {
        return {
            ...form,
            formSubmit: async () => {
                const { loyaltCode, paxNumber, orderItemWjaPrm, expressNo } = form.getFieldsValue();
                try {
                    await form.validateFields();
                    return Promise.resolve({
                        loyaltCode: trim(loyaltCode),
                        paxNumber: trim(paxNumber),
                        expressNo: trim(expressNo),
                        orderItemWjaPrm: flattenDeep(orderItemWjaPrm),
                    });
                } catch (e) {
                    return Promise.reject(e);
                }
            },
        };
    });

    return (
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 16 }}>
            <Form.Item label={services.language.getText('order.confirmTheDelivery.loyalTNo')}>
                {form.getFieldDecorator('loyaltCode', {
                    initialValue: extraData.loyaltCode,
                    rules: [
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                value = trim(value);
                                if (value && value.length !== 12) {
                                    return new Error(
                                        services.interpolate(
                                            services.language.getText(
                                                'order.confirmTheDelivery.loaylTInputTips'
                                            ),
                                            { length: 12 }
                                        )
                                    );
                                } else {
                                    callback();
                                }
                            },
                        },
                    ],
                })(
                    <AntInput
                        placeholder={services.language.getText('order.confirmTheDelivery.loyalTNo')}
                    />
                )}
            </Form.Item>

            <Form.Item label={services.language.getText('order.confirmTheDelivery.paxNo')}>
                {form.getFieldDecorator('paxNumber', {
                    rules: [
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                value = trim(value);
                                if (value && !/^\d{1,10}$/.test(value)) {
                                    return new Error(
                                        services.interpolate(
                                            services.language.getText(
                                                'order.confirmTheDelivery.paxNoInputTips'
                                            ),
                                            { length: 10 }
                                        )
                                    );
                                } else {
                                    callback();
                                }
                            },
                        },
                    ],
                })(
                    <AntInput
                        placeholder={services.language.getText('order.confirmTheDelivery.paxNo')}
                    />
                )}
            </Form.Item>
            {extraData.deliveryFlag === '1' && (
                <Form.Item
                    label={services.language.getText('order.confirmTheDelivery.trackingNumber')}
                >
                    {form.getFieldDecorator('expressNo', {
                        rules: [
                            {
                                required: true,
                                message: services.language.getText(
                                    'order.confirmTheDelivery.trackingNumberTip'
                                ),
                            },
                        ],
                    })(
                        <AntInput
                            placeholder={services.language.getText(
                                'order.confirmTheDelivery.trackingNumber'
                            )}
                        />
                    )}
                </Form.Item>
            )}
            {wjaProductList &&
                wjaProductList.length > 0 &&
                form.getFieldDecorator(
                    'orderItemWjaPrm',
                    {}
                )(
                    //@ts-ignore
                    <WjaProductList wjaProductList={wjaProductList} />
                )}
        </Form>
    );
});
const LoyalForm = Form.create<any>()(FCForm);
//@ts-ignore
export default LoyalForm;

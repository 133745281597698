import { Config } from '@comall-backend-builder/core/lib/parser';
import { STORE_SERVICE_OPTIONS } from '@/constants';
import { getLanguageProperties } from '../../mixin';

export const config: Config = {
    entities: {
        storeChannels: {
            apiPath: '/loader/dc-store/admin/pickOnlineStore',
            properties: {
                id: {
                    type: 'string',
                },
                onlineStoreId: {
                    type: 'string.treeOptionNode.cascader',
                    displayName: '<<pickOnlineStore.onlineStoreSelect>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    rules: [{ required: true }],
                },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<pickOnlineStore.onlineStoreSelect>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<storeChannels.name>>',
                    rules: [
                        { required: true },
                        { max: 20, message: '<<storeChannels.nameTip>>' },
                        { whitespace: true, message: '<<storeChannels.nameNoEmpty>>' },
                    ],
                },
                languageAnnouncement: {
                    type: 'object.subForm',
                    properties: getLanguageProperties(
                        'string.text.paragraph',
                        'storeChannels.announcement',
                        {
                            controlConfig: {
                                placeholder: '<<category.displayCategory.namePlaceholder>>',
                            },
                            rules: [{ max: 100, message: '<<storeChannels.announcementTip>>' }],
                        }
                    ),
                },
                businessTimes: {
                    type: 'array.selectTimeRange',
                    displayName: '<<storeChannels.businessTimesAndCapacity>>',
                    rules: [{ required: true }],
                    defaultValue: [
                        {
                            id: null,
                            startWeek: 'MONDAY',
                            endWeek: 'SUNDAY',
                            startTime: '10:00',
                            endTime: '22:00',
                            quantity: 0,
                        },
                    ],
                    controlConfig: {
                        format: 'HH:mm',
                        maxRangesCount: 7,
                        singleNum: true,
                    },
                },
                serviceStatus: {
                    type: 'string.radio',
                    displayName: '<<storeChannels.serviceStatus>>',
                    defaultValue: 'OPENING',
                    options: STORE_SERVICE_OPTIONS,
                    rules: [{ required: true }],
                },
                serviceStatusInTable: {
                    type: 'string.options.select',
                    displayName: '<<storeChannels.serviceStatus>>',
                    options: STORE_SERVICE_OPTIONS,
                },
                firstPickUpDay: {
                    type: 'number.deliveryDay',
                    displayName: '<<storeChannels.fastestDate>>',
                    defaultValue: 1,
                    rules: [
                        { required: true },
                        { pattern: /^\d{0,6}$/, message: '<<storeChannels.numberError0>>' },
                    ],
                },
                deliveryDate: {
                    type: 'number.deliveryDay',
                    displayName: '<<storeChannels.deliveryDate>>',
                    defaultValue: 1,
                    rules: [
                        { required: true },
                        {
                            pattern: /^[1-9]\d{0,5}$/,
                            message: '<<storeChannels.numberError>>',
                        },
                    ],
                },
                orderDeadlineTime: {
                    type: 'string.pickUpTimes',
                    defaultValue: '16:00',
                    displayName: '<<storeChannels.orderDeadlineTime>>',
                    rules: [{ required: true }],
                },
                sort: {
                    type: 'number.integer',
                    displayName: '<<pickOnlineStore.sort>>',
                    rules: [{ required: true, message: '<<pickOnlineStore.sortNotEmpty>>' }],
                    controlConfig: {
                        max: 99999,
                        min: 1,
                    },
                },
                pickOnlineMerchants: {
                    type: 'array.optionIds.select',
                    displayName: '<<privilegeManage.users.ownMerchant>>',
                    options: [],
                    source: {
                        apiPath: '/dc-goods/admin/merchant/list',
                    },
                    controlConfig: {
                        style: { width: 400 },
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                    rules: [{ required: true }],
                },
                onlineBusinessTimeExcluders: {
                    type: 'array.selectDate',
                    displayName: '<<storeChannels.dischargeTime>>',
                },
            },
            filters: {
                storeCodeOrStoreName: {
                    type: 'string',
                    displayName: '<<store.keyWords>>',
                },
                serviceStatus: {
                    type: 'string.options.select',
                    displayName: '<<storeChannels.serviceStatus>>',
                    options: STORE_SERVICE_OPTIONS,
                    controlConfig: {
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        StoreChannelContainer: {
            component: 'Viewport',
            entity: 'storeChannels',
        },
        EditStoreChannelPage: {
            component: 'FlexLayout',
            entity: 'storeChannels',
            loaderType: 'get',
            direction: 'vertical',
            className: 'create-store-channel-layout',
            items: [
                {
                    component: 'EditStoreChannelForm',
                },
            ],
        },
        EditStoreChannelForm: {
            component: 'StoreChannelForm',
            controlConfig: {
                type: 'edit',
            },
        },
        /**新增门店渠道 */
        CreateStoreChannelPage: {
            component: 'FlexLayout',
            entity: 'storeChannels',
            direction: 'vertical',
            className: 'create-store-channel-layout',
            items: [
                {
                    component: 'CreateStoreChannelForm',
                },
            ],
        },
        CreateStoreChannelForm: {
            component: 'StoreChannelForm',
            controlConfig: {
                type: 'add',
            },
        },
        PickOnlineStoreInfoPage: {
            component: 'FlexLayout',
            entity: 'storeChannels',
            loaderType: 'get',
            direction: 'vertical',
            className: 'create-store-channel-layout',
            items: [
                {
                    component: 'ViewStoreChannelForm',
                },
            ],
        },
        ViewStoreChannelForm: {
            component: 'StoreChannelForm',
            controlConfig: {
                type: 'view',
            },
        },
    },
};

import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { InputNumber as AntInputNumber, Select as AntSelect } from 'antd';
import { services } from '@comall-backend-builder/core';
const AntOption = AntSelect.Option;

interface DeliveryTimeProps {
    [key: string]: any;
}
interface DeliveryTimeStates {
    time: number;
    unit: 'MINUTES' | 'DAYS';
    unitShow?: string;
}
export class DeliveryTimeComponent extends Component<DeliveryTimeProps, DeliveryTimeStates> {
    deliveryTime: DeliveryTimeStates = {
        time: 1,
        unit: 'MINUTES',
    };
    constructor(props: DeliveryTimeProps) {
        super(props);
        this.state = {
            time: 1,
            unit: 'MINUTES',
        };
    }

    componentWillReceiveProps(props: any) {
        const { value } = props;
        if (value) {
            this.setState(
                {
                    time: value.time,
                    unit: value.unit,
                },
                () => {
                    if (this.state.unit === 'MINUTES') {
                        this.setState({
                            unitShow: services.language.getText('storeChannels.minutes'),
                        });
                    } else {
                        this.setState({
                            unitShow: services.language.getText('storeChannels.days'),
                        });
                    }
                }
            );
        }
    }
    onChangeTime = (value: any) => {
        const { onChange, name } = this.props;
        this.setState(
            {
                time: value,
            },
            () => {
                this.deliveryTime = {
                    time: this.state.time,
                    unit: this.state.unit,
                };
                if (onChange) {
                    onChange(this.deliveryTime, name);
                }
            }
        );
    };
    onChangeUnit = (value: any) => {
        const { onChange, name } = this.props;

        this.setState(
            {
                unit: value,
            },
            () => {
                this.deliveryTime = {
                    time: this.state.time,
                    unit: this.state.unit,
                };
                if (onChange) {
                    onChange(this.deliveryTime, name);
                }
            }
        );
    };
    render() {
        const data = [
            { id: 'MINUTES', name: services.language.getText('minutes') },
            { id: 'DAYS', name: services.language.getText('days') },
        ];

        return (
            <React.Fragment>
                <span>
                    <AntInputNumber
                        value={this.state.time}
                        min={0}
                        style={{ width: 50, marginRight: '5px' }}
                        onChange={this.onChangeTime.bind(this)}
                    />
                    <AntSelect
                        style={{ width: 70, marginRight: '5px' }}
                        onChange={this.onChangeUnit.bind(this)}
                        value={this.state.unit}
                    >
                        {data.map((item, index) => (
                            <AntOption key={index} value={item.id}>
                                {item.name}
                            </AntOption>
                        ))}
                    </AntSelect>
                    {services.language.getText('storeChannels.earliestDeliveryTimeTip')}
                </span>
            </React.Fragment>
        );
    }
}
/**
 * 状态类型
 */
export class DeliveryTimeMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        return <DeliveryTimeComponent {...controlInfo} />;
    }
}

import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin, flexibleFilterForm } from '../mixin';
import { filterProperties } from '@/constants/picking/properties';
import { API_TYPE, PATH_NAME } from '@/containers/picking/enums';
import { ORDER_TYPE } from '@/constants/order/enum';

export const config: Config = {
    entities: {
        // 拣货 tab entity
        philipsPicking: {
            apiPath: '/loader/dc-order-picking-philips/admin/picking_lists',
            filters: filterProperties,
        },
        // 订单 tab entity
        philipsOrders: {
            apiPath: '/loader/base-order-philips/admin/order/order_list_picking',
            filters: filterProperties,
        },
    },

    components: {
        PhilipsPickingAssistantContainer: {
            component: 'Viewport',
        },
        PhilipsPickingAssistantFilter: {
            ...flexibleFilterForm,
            component: 'FilterFormPlus',
            className: 'global-flexible-filter-form promotion-macau-filter',
            fields: [{ property: 'outerOrderNumber' }, { property: 'deliveryType' }],
        },

        PhilipsPickingAssistantPage: {
            component: 'Card',
            entities: [
                { name: 'philipsPicking', entityName: 'philipsPicking' },
                { name: 'philipsOrders', entityName: 'philipsOrders' },
            ],
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'PickingAssistant',

                        items: [
                            {
                                name: 'WAITING_PICK',
                                component: 'PhilipsPickingAssistantWaitPickPane',
                                statusKey: 'status',
                            },
                            {
                                name: 'PICKING',
                                component: 'PhilipsPickingAssistantPickingPane',
                                statusKey: 'status',
                            },
                            {
                                name: 'STOCKOUT',
                                component: 'PhilipsPickingAssistantStockOutPane',
                                statusKey: 'queryType',
                            },
                            {
                                name: 'PICKED',
                                component: 'PhilipsPickingAssistantWaitPackagePane',
                                statusKey: 'queryType',
                            },
                            {
                                name: 'PACKED',
                                component: 'PhilipsPickingAssistantWaitPickupPane',
                                statusKey: 'queryType',
                            },
                            {
                                name: 'CANCELLED',
                                component: 'PhilipsPickingAssistantCancelledPane',
                                statusKey: 'queryType',
                            },
                            {
                                name: 'ALL',
                                component: 'PhilipsPickingAssistantAllOrderPane',
                                statusKey: 'queryType',
                            },
                        ],

                        config: {
                            pathname: '/picking-assistant-philips/',
                            apiType: API_TYPE.ORDER_PHILIPS,
                            treeType: 1,
                        },
                    },
                ],
                privilege: {
                    path: 'picking_philips.assistant_philips.view_list_philips',
                    level: 'full',
                },
            },
        },
        // 待拣货
        PhilipsPickingAssistantWaitPickPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'philipsPicking',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                renderItem: 'WaitPickItem',
                rowSelection: {},
                form: {
                    type: 'component',
                    component: 'PhilipsPickingAssistantFilter',
                },
                header: {
                    type: 'component',
                    component: 'BatchPickingAndPrintButton',
                },
                pagination: tableMixin.pagination,
                apiType: API_TYPE.PICKING_PHILIPS,
                pickPathname: PATH_NAME.PHILIPS_PICK_PATH_NAME,
                orderPathname: PATH_NAME.PHILIPS_ORDER_PATH_NAME,
                type: ORDER_TYPE.PHILIPS,
            },
        },
        // 拣货中
        PhilipsPickingAssistantPickingPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'philipsPicking',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                renderItem: 'PickingItem',
                pagination: tableMixin.pagination,
                header: {
                    type: 'component',
                    component: 'PhilipsPickingAssistantFilter',
                },
                apiType: API_TYPE.PICKING_PHILIPS,
                pickPathname: PATH_NAME.PHILIPS_PICK_PATH_NAME,
                orderPathname: PATH_NAME.PHILIPS_ORDER_PATH_NAME,
                type: ORDER_TYPE.PHILIPS,
            },
        },
        // 缺货订单
        PhilipsPickingAssistantStockOutPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'philipsOrders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                header: {
                    type: 'component',
                    component: 'PhilipsPickingAssistantFilter',
                },
                renderItem: 'StockOutItem',
                pagination: tableMixin.pagination,
                apiType: {
                    picking: API_TYPE.PICKING_PHILIPS,
                    order: API_TYPE.ORDER_PHILIPS,
                },
                pickPathname: PATH_NAME.PHILIPS_PICK_PATH_NAME,
                orderPathname: PATH_NAME.PHILIPS_ORDER_PATH_NAME,
                type: ORDER_TYPE.PHILIPS,
            },
        },
        // 待打包
        PhilipsPickingAssistantWaitPackagePane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'philipsOrders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                renderItem: 'WaitPackageItem',
                rowSelection: {},
                form: {
                    type: 'component',
                    component: 'PhilipsPickingAssistantFilter',
                },
                header: {
                    type: 'component',
                    component: 'WaitPackageBatchActions',
                },
                pagination: tableMixin.pagination,
                apiType: {
                    picking: API_TYPE.PICKING_PHILIPS,
                    order: API_TYPE.ORDER_PHILIPS,
                },
                pickPathname: PATH_NAME.PHILIPS_PICK_PATH_NAME,
                orderPathname: PATH_NAME.PHILIPS_ORDER_PATH_NAME,
                type: ORDER_TYPE.PHILIPS,
            },
        },
        // 待发货
        PhilipsPickingAssistantWaitPickupPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'philipsOrders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                header: {
                    type: 'component',
                    component: 'PhilipsPickingAssistantFilter',
                },
                apiType: {
                    picking: API_TYPE.PICKING_PHILIPS,
                    order: API_TYPE.ORDER_PHILIPS,
                },
                renderItem: 'WaitPickupItem',
                pagination: tableMixin.pagination,
                pickPathname: PATH_NAME.PHILIPS_PICK_PATH_NAME,
                orderPathname: PATH_NAME.PHILIPS_ORDER_PATH_NAME,
                type: ORDER_TYPE.PHILIPS,
            },
        },
        // 取消订单
        PhilipsPickingAssistantCancelledPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'philipsOrders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                header: {
                    type: 'component',
                    component: 'PhilipsPickingAssistantFilter',
                },
                renderItem: 'CancelledOrderItem',
                pagination: tableMixin.pagination,
                pickPathname: PATH_NAME.PHILIPS_PICK_PATH_NAME,
                orderPathname: PATH_NAME.PHILIPS_ORDER_PATH_NAME,
                type: ORDER_TYPE.PHILIPS,
            },
        },
        // 全部订单
        PhilipsPickingAssistantAllOrderPane: {
            component: 'PickingAssistantTabPaneWrapper',
            entity: 'philipsOrders',
            content: {
                component: 'DataList',
                loadFirstPage: false,
                split: false,
                renderItem: 'AllOrderItem',
                header: {
                    type: 'component',
                    component: 'PhilipsPickingAssistantFilter',
                },
                pagination: tableMixin.pagination,
                apiType: {
                    picking: API_TYPE.PICKING_PHILIPS,
                    order: API_TYPE.ORDER_PHILIPS,
                },
                pickPathname: PATH_NAME.PHILIPS_PICK_PATH_NAME,
                orderPathname: PATH_NAME.PHILIPS_ORDER_PATH_NAME,
                type: ORDER_TYPE.PHILIPS,
            },
        },
    },
};

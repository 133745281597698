/*
 * @Author: zhulu
 * @Date: 2021-08-23 14:11:05
 * @Descripttion: 订单自动取消管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { tableMixin } from '../mixin';
export const autoCancelProps = {
    id: { type: 'string' },
    channelId: {
        type: 'string',
    },
    organizationId: {
        type: 'string',
    },
    channelName: {
        type: 'string',
        displayName: '<<order.autoCancel.channelName>>',
    },
    organizationName: {
        type: 'string',
        displayName: '<<order.autoCancel.organizationName>>',
    },
};

export const config: Config = {
    entities: {
        autoCancelEShop: {
            apiPath: '/dc-order/admin/orders/cancel/system_config/3',
            properties: autoCancelProps,
        },
        autoCancelGs: {
            apiPath: '/dc-order/admin/orders/cancel/system_config/7',
            properties: autoCancelProps,
        },
    },
    components: {
        AutoCancelContainer: {
            component: 'Viewport',
        },
        AutoCancelManagement: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'ChannelCategoryView',
                        channelApiPath:
                            '/loader/dc-master-data/admin/distribution_channels/findAllByLoginUser/freebie-group',
                        tabItems: {
                            '3': {
                                content: {
                                    component: 'AutoCancelEShop',
                                },
                            },
                            '7': {
                                content: {
                                    component: 'AutoCancelGS',
                                },
                            },
                        },
                        showAll: false,
                        memorable: false,
                        params: {},
                    },
                ],
                privilege: {
                    path: 'order.order_auto_cancel.view_list',
                    level: 'full',
                },
            },
        },
        AutoCancelEShop: {
            entity: 'autoCancelEShop',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'AutoCancelTable' }],
        },
        AutoCancelGS: {
            entity: 'autoCancelGs',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'AutoCancelTable' }],
        },
        AutoCancelTable: {
            ...tableMixin,
            scroll: { x: 'widthFix' },
            columns: [{ property: 'organizationName' }],
            actionColumn: {
                title: '<<actions>>',
                width: 100,
                fixed: 'right',
                items: [
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/order-auto-cancel/edit/{{row.channelId}}/{{row.organizationId}}',
                        privilege: {
                            path: 'order.order_auto_cancel.setting',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        AutoCancelEdit: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'AutoCancelForm',
                    },
                ],
                privilege: {
                    path: 'goods.goods.view_list',
                    level: 'full',
                },
            },
        },
    },
};

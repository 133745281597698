import { builder, languagePackage } from '@comall-backend-builder/core';
import { defaultsDeep } from 'lodash';
import antdZhCNLanguage from 'antd/es/locale/zh_CN';
// import antdEnUSLanguage from 'antd/es/locale/en_US';
// import antdJaJPLanguage from './rest_antd_jp';
// import enUS from './en-US';
import zhCN from './zh-CN';
// import jaJP from './ja-JP';
import { AVAILABLE_LANGUAGES } from '@comall-backend-builder/core/lib/services/language';

export { zhCN };

AVAILABLE_LANGUAGES.pop();

// builder.registerLanguage({
//     id: 'en',
//     name: 'English',
//     momentLocale: 'en',
//     antdLocale: antdEnUSLanguage,
//     context: defaultsDeep(enUS, languagePackage.enUS),
// });

builder.registerLanguage({
    id: 'zh',
    name: '简体中文',
    momentLocale: 'zh',
    antdLocale: antdZhCNLanguage,
    context: defaultsDeep(zhCN, languagePackage.zhCN),
});

// builder.registerLanguage({
//     id: 'jp',
//     name: '日本語',
//     momentLocale: 'jp',
//     antdLocale: antdJaJPLanguage,
//     context: defaultsDeep(jaJP, languagePackage.enUS),
// });

import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { isFunction } from 'lodash';

interface IDisplayConfig {
    style?: any;
    className?: string;
    /**
     * 内容格式化
     */
    formatContent?: (content: any) => Array<string>;
}

/**
 * 文本容器，方便处理格式化和自定义样式
 */
export class StringTextBoxMode extends modes.StringMode {
    getDisplayComponent(value: any, displayConfig: IDisplayConfig) {
        const { className, style, formatContent } = displayConfig;
        let content: any = value;
        if (isFunction(formatContent)) {
            content = formatContent(value);
        }
        return (
            <div className={className} style={style}>
                {content.map((item: any) => (
                    <p key={item}>{item}</p>
                ))}
            </div>
        );
    }
}

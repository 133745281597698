import React, { Component } from 'react';
export class LimitTipInfo extends Component<any, any> {
    render() {
        return (
            <div className='limit-tip-info'>
                <div className='limit-tip-item-pr'>
                    <div className='header'>提示语参数说明：</div>
                    <div className='item'>[X] : 分类限购数 或 单品限购数</div>
                    <div className='item'>[Category] ：分类名称 </div>
                    <div className='item'>[Sku] ：商品名称</div>
                    <div className='item'>[A] : 每个月内已经购买的 分类总商品数量 或 单品数量 </div>
                    <div className='item'>
                        [B] : =[X] -[ A] , 剩余可购买的 分类商品数量 或 单品数量
                    </div>
                </div>
                <div className='limit-tip-item'>
                    <div className='header'>前台提示的示例：</div>
                    <div className='item'>
                        抱歉，您在每个月内只能预定 24 件 美妆&香水 类商品，您已购买 16 件，还可购买
                        8 件，需要先删掉一些哦~
                    </div>
                    <div className='item'>
                        抱歉，您在每个月内可订购单个 美妆&香水 类产品的最多 6 件 ，您已购买 2 件
                        海蓝之谜修护精萃液，还可购买 4 件。
                    </div>
                </div>
            </div>
        );
    }
}

import { services } from '@comall-backend-builder/core';
import { formats } from '@comall-backend-builder/types';
import { get, isObject } from 'lodash';

export class WeightFormat extends formats.ObjectFormat {
    /**
     * 对数据进行校验
     */
    validate(_rule: any, value: any, callback: any): void {
        if (isObject(value) && Object.keys(value).length === 0) {
            return callback(services.language.getText('storeFreightRules.weightStepFreighError'));
        }
        if (!get(value, 'startingWeight')) {
            return callback(services.language.getText('storeFreightRules.startingWeightError'));
        }
        if (!get(value, 'stepWeight')) {
            return callback(services.language.getText('storeFreightRules.stepWeightError'));
        }
        if (!get(value, 'stepFreight') && get(value, 'stepFreight') !== 0) {
            return callback(services.language.getText('storeFreightRules.stepFreightError'));
        }
        callback();
    }
    /**
     * 将数据格式化为 number 类型的值
     */
    format(value: any): any {
        return value || '';
    }
    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any) {
        return { [key]: value ? value : {} };
    }
}

import { Config } from '@comall-backend-builder/core/lib/parser';
import { formFooter } from '@/configs/mixin';
import './index.less';
import { MEMBER_SPECIAL_TYPE_OPTIONS, MEMBER_SPECIAL_TYPE_VALUES } from '@/constants/user';

export const config: Config = {
    entities: {
        levelInfo: {
            apiPath: '/loader/dc-user/admin/memberLevel',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<membershipLevel.cardPicName>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 20,
                    },
                    rules: [{ required: true, whitespace: true }],
                },
                type: {
                    type: 'string.options.radio',
                    displayName: '<<membershipLevel.type.title>>',
                    options: MEMBER_SPECIAL_TYPE_OPTIONS,
                    defaultValue: MEMBER_SPECIAL_TYPE_VALUES.NORMAL,
                },
                levelNumber: {
                    type: 'string',
                    displayName: '<<membershipLevel.level>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 20,
                    },
                    rules: [
                        { required: true, whitespace: true },
                        { pattern: /^[^\s]*$/, message: '<<membershipLevel.levelError>>' },
                    ],
                },
                expLower: {
                    type: 'string',
                    displayName: '<<membershipLevel.expStart>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 8,
                    },
                    rules: [
                        { required: true },
                        { pattern: /^[0-9]+$/, message: '<<membershipLevel.integerTips>>' },
                    ],
                },
                expUpper: {
                    type: 'string',
                    displayName: '<<membershipLevel.expEnd>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 8,
                    },
                    rules: [
                        { required: true },
                        { pattern: /^[0-9]+$/, message: '<<membershipLevel.integerTips>>' },
                    ],
                },
                emailWhite: {
                    type: 'string',
                    displayName: '<<membershipLevel.emailWhite>>',
                    controlConfig: {
                        placeholder: '<<membershipLevel.emailWhitePlaceholder>>',
                    },
                },
                levelRemark: {
                    type: 'string.text.paragraph',
                    displayName: '<<membershipLevel.levelDescribe>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 100,
                    },
                },
                picUrl: {
                    type: 'array.brandImage',
                    displayName: '<<membershipLevel.picUrl>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1000,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.logoLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                        limitTips: {
                            className: 'hidden',
                        },
                    },
                    // rules: [{ required: true }],
                },
                memberPricePicUrl: {
                    type: 'array.brandImage',
                    displayName: '<<membershipLevel.memberPriceIcon>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1000,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.logoLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                        limitTips: {
                            className: 'hidden',
                        },
                    },
                },
                cardPicUrl: {
                    type: 'array.brandImage',
                    displayName: '<<membershipLevel.cardPicUrl>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1000,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.logoLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                        limitTips: {
                            className: 'hidden',
                        },
                    },
                    // rules: [{ required: true }],
                },
                // status: {
                //     type: 'object.option.radio.state',
                //     displayName: '<<displayCategory.status>>',
                //     options: [
                //         {
                //             id: '1',
                //             name: '<<enable>>',
                //         },
                //         {
                //             id: '0',
                //             name: '<<disable>>',
                //         },
                //     ],
                //     defaultValue: '1',
                //     rules: [{ required: true }],
                // },
            },
        },
    },
    components: {
        LevelCreatePage: {
            component: 'Card',
            content: {
                component: 'LevelCreateForm',
            },
        },
        LevelCreateForm: {
            component: 'CreateFormPlus',
            entity: 'levelInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            className: 'none-sub-form brand-edit-from',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                {
                    property: 'name',
                },
                { property: 'type' },
                {
                    property: 'levelNumber',
                },
                {
                    property: 'emailWhite',
                    visible: (values: any) =>
                        `${values.type}` === MEMBER_SPECIAL_TYPE_VALUES.SPECIAL,
                },
                {
                    property: 'expLower',
                    visible: (values: any) =>
                        `${values.type}` === MEMBER_SPECIAL_TYPE_VALUES.NORMAL,
                },
                {
                    property: 'expUpper',
                    visible: (values: any) =>
                        `${values.type}` === MEMBER_SPECIAL_TYPE_VALUES.NORMAL,
                },
                {
                    property: 'levelRemark',
                },
                {
                    property: 'picUrl',
                },
                {
                    property: 'memberPricePicUrl',
                },
                {
                    property: 'cardPicUrl',
                },
                // {
                //     property: 'status',
                // },
            ],
        },
        LevelEditPage: {
            component: 'Card',
            loaderType: 'get',
            content: {
                component: 'LevelEditForm',
            },
        },
        LevelEditForm: {
            component: 'ModifyFormPlus',
            entity: 'levelInfo',
            className: 'none-sub-form brand-edit-from',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            loaderType: 'get',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                {
                    property: 'name',
                    modifiable: false,
                },
                { property: 'type', modifiable: false },
                {
                    property: 'levelNumber',
                    modifiable: false,
                },
                {
                    property: 'emailWhite',
                    visible: (values: any) =>
                        `${values.type}` === MEMBER_SPECIAL_TYPE_VALUES.SPECIAL,
                },
                {
                    property: 'expLower',
                    visible: (values: any) =>
                        `${values.type}` === MEMBER_SPECIAL_TYPE_VALUES.NORMAL,
                },
                {
                    property: 'expUpper',
                    visible: (values: any) =>
                        `${values.type}` === MEMBER_SPECIAL_TYPE_VALUES.NORMAL,
                },
                {
                    property: 'levelRemark',
                },
                {
                    property: 'picUrl',
                },
                {
                    property: 'memberPricePicUrl',
                },
                {
                    property: 'cardPicUrl',
                },
                // {
                //     property: 'status',
                // },
            ],
        },
    },
};

import { Button } from 'antd';
import React from 'react';
import { GlobalData, LoginUrl } from './utils/globalData';

export const NoAuth = () => {
    return (
        <div
            className='login-container'
            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
        >
            <div style={{ paddingTop: '100px', textAlign: 'center' }}>
                <img alt='' src='./logo-dark.png' />
                <span>{ENV.ENV}</span>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '20px' }}>
                您的账号暂无权限，请联系管理员配置
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    style={{ textAlign: 'center', marginTop: '20px', width: '100px' }}
                    onClick={() => {
                        localStorage.removeItem('keycloak');
                        GlobalData._kc &&
                            GlobalData._kc.logout({
                                redirectUri:
                                    window.location.origin + `/#/${LoginUrl}?status=logout`,
                            });
                    }}
                >
                    退出登录
                </Button>
            </div>
        </div>
    );
};

import { cleanParams } from '@/services/data-formatter';
import { services } from '@comall-backend-builder/core';
import { each, map, get as lodashGet, isArray, isObject } from 'lodash';
const typeMap = {
    virtual: 3,
    normal: '',
    group: 2,
};
export const getProductList = async function(data: any, config: any) {
    const type = services.navigation.getPathname().split('/')[2];
    if (data.modifyTime) {
        data.modifyBeginTime = data.modifyTime.start;
        data.modifyEndTime = data.modifyTime.end;
        delete data.modifyTime;
    }
    if (isArray(data.brandId)) {
        data.brandId = data.brandId.map((item: any) => item.id).join(',');
    }
    data.offlineCategoryId = isObject(data.offlineCategoryId)
        ? lodashGet(data, `offlineCategoryId[0].id`)
        : data.offlineCategoryId || '';
    if (!data.sourceType && type) {
        //@ts-ignore
        if (typeMap[type]) data.sourceType = typeMap[type];
    }
    data = cleanParams(data);
    const response: any = await services.api.get(data, {
        ...config,
        apiPath: `${config.apiPath}/${type}/list`,
    });
    return transformResponse(data.status, response);
};

export function transformResponse(status: string, response: any) {
    let products: Array<any> = [];
    each(response.result, (item) => {
        const product = {
            ...item,
            onlineCategoryIds: map(item.onlineCategories, ({ id, display }) => ({
                id,
                name: display,
            })),
            name: {
                zh: item.name,
                en: item.enName,
                jp: item.jpName,
                tc: item.tcName,
                fr: item.frName,
                it: item.itName,
                ko: item.koName,
            },
            brandName: {
                zh: item.brandName,
                en: item.brandNameEn,
                jp: item.brandNameJp,
                tc: item.brandNameTc,
                fr: item.brandNameFr,
                it: item.brandNameIt,
                ko: item.brandNameKo,
            },
            errorReasonInfo: {
                reasons: item.errorReason,
                id: item.id,
                status,
            },
            offlineCategoryNames: map(item.offlineCategoryName, (value, key) => {
                return {
                    id: key,
                    name: value,
                };
            }),
            pictures: item.defaultImageUrl,
            enriched: item.enriched
                ? services.language.getText('yes')
                : services.language.getText('no'),
        };
        products.push(product);
    });
    response.result = products;
    return response;
}

export default [
    /**
     * 商品列表
     */
    {
        key: '/dc-goods/admin/cskus',
        loader: {
            get: async function name(data: any, config: any) {
                return await getProductList(data, config);
            },
        },
    },
    /**
     * 商品详情
     */
    {
        key: '/dc-goods/admin/cskus/info',
        loader: {
            get: async function name(data: any, config: any) {
                return await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-goods/admin/cskus/${data.type}/info/${data.id}`,
                });
            },
        },
    },
    {
        key: '/dc-goods/admin/cskus/seo',
        loader: {
            get: async function(data: any, config: any) {
                if (!data.sourceType) {
                    data.sourceType = -1;
                }
                return await getProductList(data, { ...config, apiPath: '/dc-goods/admin/cskus' });
            },
        },
    },
    {
        //非承重商品
        key: '/dc-goods/admin/cskus/queryCskuByPage/normal',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(
                    { ...data, page: 1, perPage: 20, specsType: 0 },
                    { ...config, apiPath: '/dc-goods/admin/cskus/queryCskuByPage' }
                );
                let result = response.result.map((item: any) => ({
                    ...item,
                    id: item.cskuId,
                    name: item.name,
                }));
                return result;
            },
        },
    },
    {
        key: '/dc-goods/admin/cskus/skuByPage',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(
                    { page: 1, perPage: 10, ...data },
                    config
                );
                let result = response.result.map((item: any) => ({
                    ...item,
                    name: item.name,
                }));
                return result;
            },
        },
    },
    {
        key: '/dc-goods/admin/brands/list',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(
                    { ...data, page: 1, perPage: 10 },
                    {
                        ...config,
                        apiPath: '/dc-goods/admin/brands',
                    }
                );

                return result.result || [];
            },
        },
    },
];

import React, { Component, createElement } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { connect } from 'react-redux';
import { defaults, find, get, isEqual } from 'lodash';
import { services, actions, Loader } from '@comall-backend-builder/core';
import { Row as AntRow, Col as AntCol, message as AntMessage } from 'antd';
import { ComponentsManager } from '@comall-backend-builder/core';
import { STORE_SERVICE_OPTIONS } from '@/constants';
import { getLanguageColumns } from '@/configs/mixin';

interface StoreChannelFormProps {
    [key: string]: any;
}

class StoreChannelFormContent extends Component<StoreChannelFormProps, any> {
    state = { pickStore: undefined, loading: false };
    component: any = null;
    componentConfig: any = null;
    time: any = null;
    storeName: string = '';
    disabledOptions: any = '';
    newOptions: any = null;
    inited = false;
    async componentDidMount() {
        const {
            entity,
            controlConfig: { type },
        } = this.props;
        if (type === 'add') {
            const id = get(entity, 'params.id');
            const pickStore = await Loader.load('get', {
                apiPath: '/loader/dc-store/admin/pickStore',
                params: { id },
            });
            this.setState({
                pickStore,
            });
        }
    }
    componentWillReceiveProps(props: StoreChannelFormProps) {
        const { state } = props;

        if (state.requestStatus === 'pending') {
            this.setState({
                loading: true,
            });
        } else if (state.requestStatus === 'success' || state.requestStatus === 'failed') {
            this.setState({
                loading: false,
            });
        }
    }
    getConfig = () => {
        const { fieldsConfig, ...rest } = this.props;
        this.inited = true;
        return {
            ...rest,
            labelCol: 8,
            controlCol: 9,
            className: 'none-sub-form',
            fields: fieldsConfig,
        };
    };
    handleSubmit(event: any) {
        const {
            getForm,
            entity,
            controlConfig: { type },
        } = this.props;
        const form = getForm();
        const fields = form.getFieldsValue();
        if (type === 'edit') {
            form.setFieldsValue({
                firstPickUpDay: fields.firstPickUpDay === null ? 1 : fields.firstPickUpDay,
                businessTimes:
                    fields.businessTimes === undefined
                        ? [
                              {
                                  startWeek: 'MONDAY',
                                  endWeek: 'SUNDAY',
                                  startTime: '00:00',
                                  endTime: '22:30',
                                  storeChannelPicks: [
                                      {
                                          startTime: '00:00',
                                          endTime: '22:30',
                                          quantity: 1,
                                      },
                                  ],
                              },
                          ]
                        : fields.businessTimes,
            });
        }

        if (this.props.state.requestStatus === 'pending') {
            return;
        }

        if (event) {
            event.preventDefault();
        }

        form.validateFields((err: any, values: string) => {
            if (!err) {
                // if (!this.checkMaxQuantityOfDay()) return;
                const { onSubmitStart, onSubmit } = this.props;
                if (onSubmitStart) {
                    onSubmitStart();
                }
                if (onSubmit) {
                    onSubmit(event, {
                        ...entity.fields,
                        ...fields,
                    });
                    // AntMessage.success(services.language.getText('storeChannels.successFully'));
                    // goBack();
                }
            }
        });
    }
    checkMaxQuantityOfDay() {
        const { getForm } = this.props;
        const form = getForm();
        const fields = form.getFieldsValue();
        let currentError = false; //当前选中的营业时间运力是否满足每日最大运力
        let pickTimesErrorLength = 0; //营业时间运力是否满足每日最大运力的异常数
        const getCurrentStoreBusiness =
            find(form.getFieldValue('businessTimes'), { checked: true }) ||
            form.getFieldValue('businessTimes')[0];
        form.getFieldValue('businessTimes').map((item: any) => {
            const allPickTime = get(item, 'storeChannelPicks', []).map(
                (item: any) => item.quantity
            );
            // 获取当前运力总和
            let allPickTimeNumber = allPickTime.reduce(
                (total: number, currentValue: number, _: number, __: Array<any>) => {
                    return Number(total) + Number(currentValue);
                }
            );
            if (allPickTimeNumber > form.getFieldValue('maxQuantityOfDay')) {
                pickTimesErrorLength++;
                if (isEqual(item, getCurrentStoreBusiness)) currentError = true;
            }
            return item;
        });
        if (currentError) {
            form.setFields({
                businessTimes: {
                    value: fields.businessTimes,
                    errors: [new Error('可提货单数总和不可超过门店每日最大可提货单数')],
                },
            });
            return false;
        }
        if (pickTimesErrorLength > 0) {
            AntMessage.error('可提货单数总和不可超过门店每日最大可提货单数');
            return false;
        }

        return true;
    }
    render() {
        const { pickStore } = this.state;
        const {
            entity,
            renderComponent,
            controlConfig: { type },
        } = this.props;

        const pickStoreName =
            get(pickStore, 'pickStoreName') || get(entity, 'fields.pickStore.pickStoreName');
        const retailStoreId =
            get(pickStore, 'retailStoreId') || get(entity, 'fields.pickStore.retailStoreId');
        const storeMap = [
            {
                label: services.language.getText('pickOnlineStore.pickStore'),
                value: pickStoreName,
            },
            {
                label: services.language.getText('pickOnlineStore.pickRetailStoreId'),
                value: retailStoreId,
            },
        ];

        return (
            <div className='store-channel-form'>
                {storeMap.map((item) => (
                    <AntRow className='ant-form-item' key={item.label}>
                        <AntCol span={8} className='ant-form-item-label'>
                            <label>{item.label}</label>
                        </AntCol>
                        <AntCol span={9} className='ant-form-item-control-wrapper'>
                            <div className='ant-form-item-control'>{item.value}</div>
                        </AntCol>
                    </AntRow>
                ))}
                {createElement(ComponentsManager.get(renderComponent), this.getConfig())}
                {type !== 'view' && (
                    <AntRow>
                        <AntCol span={20} offset={4}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '80%',
                                }}
                            >
                                <Button
                                    text={services.language.getText('cancel')}
                                    route='goBack'
                                    style={{ marginRight: 16 }}
                                />
                                <Button
                                    loading={this.state.loading}
                                    onClick={this.handleSubmit.bind(this)}
                                    type='primary'
                                    text={services.language.getText('save')}
                                />
                            </div>
                        </AntCol>
                    </AntRow>
                )}
            </div>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const {
        componentId,
        entity,
        controlConfig: { type },
    } = props;
    const componentState = state.components[componentId];
    const fieldValues = state.components[componentId] && state.components[componentId].fields;
    const fields: any = [
        {
            property: 'onlineStoreId',
            className: type === 'add' ? '' : 'channels-invisible',
            controlConfig: {
                style: {
                    width: 400,
                },
                displayRender: (label: string[], selectedOptions: string[]) => {
                    return label[label.length - 1];
                },
                showComponent: type === 'add',
            },
        },
        {
            property: 'onlineStoreName',
            modifiable: false,
            className: type !== 'add' ? '' : 'channels-invisible',
        },
        {
            property: 'pickOnlineMerchants',
            modifiable: type !== 'view',
        },
        {
            property: 'sort',
            modifiable: type !== 'view',
            controlConfig: {
                style: {
                    width: 400,
                },
                min: 1,
                max: 99999,
                placeholder: services.language.getText('numberPlaceHolder'),
            },
        },
        ...getLanguageColumns('languageAnnouncement', {
            modifiable: type !== 'view',
            controlConfig: { style: { width: 400 } },
        }),
        {
            property: 'businessTimes',
            modifiable: type !== 'view',
        },
        {
            property: 'serviceStatus',
            modifiable: type !== 'view',
            displayConfig: {
                statusConfig: STORE_SERVICE_OPTIONS,
            },
        },

        {
            property: 'orderDeadlineTime',
            modifiable: type !== 'view',
            controlConfig: { allowClear: true, allowEmpty: false },
        },
        {
            property: 'firstPickUpDay',
            modifiable: type !== 'view',
            controlConfig: {
                text: services.language.getText('storeChannels.afterDay'),
                prefixText: services.language.getText('storeChannels.orderDate'),
                min: 0,
                max: 999999,
            },
        },
        {
            property: 'deliveryDate',
            modifiable: type !== 'view',
            controlConfig: {
                text: services.language.getText('storeChannels.DeliveryDayTip'),
                min: 1,
                max: 999999,
            },
        },
        {
            property: 'onlineBusinessTimeExcluders',
            modifiable: type !== 'view',
        },
    ];

    return {
        entity,
        fieldValues,
        fieldsConfig: fields,
        componentId,
        componentState,
        renderComponent: type === 'add' ? 'CreateFormPlus' : 'ModifyFormPlus',
        state: {
            requestStatus: entity.requestStatus,
        },
    };
}

function mapDispatchToProps(dispatch: any, props: any) {
    const {
        componentId,
        entity,
        onSubmit,
        controlConfig: { type },
    } = props;
    let form: any;
    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: (event: any, fields: any) => {
                console.info(fields);
                if (type === 'add') {
                    entity.add(fields, props.params, {
                        route: 'goBack',
                    });
                } else {
                    entity.modify(fields, props.params, {
                        route: 'goBack',
                    });
                }
            },
            onSubmitStart: () => {
                if (type === 'add') {
                    dispatch(actions.componentSubmitAction(componentId, 'create'));
                } else {
                    dispatch(actions.componentSubmitAction(componentId, 'modify'));
                }
            },
        }
    );
}

export const StoreChannelForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreChannelFormContent);

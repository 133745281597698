import React, { PureComponent } from 'react';
import { Modal, Tree } from 'antd';
import { services } from '@comall-backend-builder/core';
import './index.less';

const { TreeNode } = Tree;

interface DeliveryFreightRuleRegionsSelectorProps {
    freightRule: any;
    onChange: (params: any) => void;
}

interface DeliveryFreightRuleRegionsSelectorStates {
    /**
     * 是否展示弹层
     */
    visible: boolean;
    checkedKeys: any;
    selectedKeys: any;
    treeData: any;
}

export class DeliveryFreightRuleRegionsSelector extends PureComponent<
    DeliveryFreightRuleRegionsSelectorProps,
    DeliveryFreightRuleRegionsSelectorStates
> {
    state = {
        showRegions: [],
        changeRegions: [],
        visible: false,
        checkedKeys: [],
        selectedKeys: [],
        treeData: [
            {
                title: '0-0',
                key: '0-0',
                children: [],
            },
        ],
    };

    // 避免报错
    componentWillUnmount() {
        this.setState = () => {};
    }

    onCheck = (checkedKeys: any, e: any) => {
        const { onChange } = this.props;
        let title: any[] = [];
        e.checkedNodes?.map((item: any) => title.push(item.props.title));
        this.setState({ checkedKeys });
        onChange({
            deliveryArea: title.join('、'),
            ids: checkedKeys,
        });
    };
    onSelect = (selectedKeys: any, info: any) => {
        this.setState({ selectedKeys });
    };
    renderTreeNodes = (data: any[]) =>
        data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode title={item.name} key={item.id}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.name} key={item.id} {...item} />;
        });
    onLoadFirstRegions = () => {
        return (
            <div className='regions-selector'>
                <Tree
                    checkable
                    onCheck={this.onCheck}
                    checkedKeys={this.state.checkedKeys}
                    onSelect={this.onSelect}
                    selectedKeys={this.state.selectedKeys}
                >
                    {this.renderTreeNodes(this.state.treeData)}
                </Tree>
            </div>
        );
    };

    /**
     * 弹层确认事件
     */
    onOk = () => {
        this.toggleModal();
    };

    /**
     * 控制弹层展示
     */
    toggleModal = () => {
        const { visible } = this.state;
        //判断弹层是新打开还是关闭
        this.setState({
            visible: !visible,
        });

        /**
         * 获取所有城市
         */
        // componentDidMount() {
        services.api
            .get(
                {},
                {
                    apiPath: `/dc-goods/admin/store/freight/getDeliveryRegion`,
                }
            )
            .then((res: any) => {
                this.setState({ treeData: res.region });
            });
        // }
    };

    onLoadSelectFirstRegions = () => {
        const { freightRule } = this.props;
        let showDeliveryScopesText: any = '';
        showDeliveryScopesText = freightRule.deliveryArea;

        return showDeliveryScopesText !== '' ? (
            <div style={{ width: '250px', lineHeight: '24px' }}>
                <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={this.toggleModal}>
                    <span style={{ marginLeft: '5px', color: '#1890ff' }}>
                        {freightRule.deliveryArea}
                    </span>
                </div>
            </div>
        ) : (
            <div style={{ color: '#1890ff', cursor: 'pointer' }} onClick={this.toggleModal}>
                <span>{services.language.getText('storeFreightRules.addConfigurable')}</span>
            </div>
        );
    };

    render() {
        const modalContent = this.onLoadFirstRegions();
        const modalProps = {
            width: 735,
            title: `${services.language.getText('storeFreightRules.select')}`,
            visible: this.state.visible,
            okText: `${services.language.getText('storeFreightRules.ok')}`,
            cancelText: `${services.language.getText('storeFreightRules.cancel')}`,
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                {this.onLoadSelectFirstRegions()}
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

import React, { useCallback, useState } from 'react';
import { Form, message, Modal, Input, Icon } from 'antd';
import { ComponentsManager, Loader, services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { extendsParentProps } from '@comall-backend-builder/components-basis/lib/component-props';
import { KV } from '@/interfaces';
import { Privilege } from '@/components/privilege';
import './index.less';

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

const DepositModal = (props: KV) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState('');
    const [help, setHelp] = useState('');
    const [loading, setLoading] = useState(false);
    //put不一样的请求
    const [requestStatus, setrequestStatus] = useState<number>(0);

    //给创建button一个事件
    const handleTriggerClick = useCallback(
        async (btnStatus) => {
            setrequestStatus(btnStatus);
            setVisible(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    );

    //创建列表button组件
    const renderTrigger = () => {
        const { row } = props;
        if (row.depositStatusVal === '1' && row.orderStatusName === 'PACKED') {
            // 取消核销
            Object.assign(props.CancelComponent, { onClick: () => handleTriggerClick(1) });
            const { component, ...otherProps } = props.CancelComponent;
            let componentProps = extendsParentProps({ ...otherProps }, props);
            return (
                <Privilege path='order.deposit_manager.deposit_cancel'>
                    {React.createElement(ComponentsManager.get(component), componentProps)}
                </Privilege>
            );
        } else if (row.depositStatusVal === '0' && row.orderStatusName === 'PACKED') {
            //人工核销
            Object.assign(props.PeopleComponent, { onClick: () => handleTriggerClick(2) });
            const { component, ...otherProps } = props.PeopleComponent;
            let componentProps = extendsParentProps({ ...otherProps }, props);
            return (
                <Privilege path='order.deposit_manager.deposit_people'>
                    {React.createElement(ComponentsManager.get(component), componentProps)}
                </Privilege>
            );
        } else {
            return '';
        }
    };

    //表单组件变化值
    const onChange = (even: any) => {
        if (even.target.value.length > 0) {
            setHelp('');
        } else {
            setHelp('请输入原因');
        }
        setValue(even.target.value);
        //@ts-ignore
    };
    // modal确认doingsomething
    const onModalOk = useCallback(async () => {
        if (value && value.length > 0) {
            setHelp('');
        } else {
            setHelp('请输入原因');
        }
        if (!(value && value.length > 0)) return;
        setLoading(true);
        if (requestStatus === 1) {
            Loader.load('put', {
                apiPath: `/loader/dc-order/admin/deposit/cancel`,
                params: {
                    id: get(props, 'row.id', ''),
                    remark: value,
                },
            })
                .then(() => {
                    message.success(services.language.getText('saveFully'));
                    const { entity, params } = props;
                    entity.search(params);
                    setValue('');
                    setVisible(false);
                    setLoading(false);
                })
                .catch((error: any) => {
                    services.errorHandle(error);
                    setLoading(false);
                });
        } else if (requestStatus === 2) {
            Loader.load('put', {
                apiPath: '/loader/dc-order/admin/deposit/person_verification',
                params: {
                    id: get(props, 'row.id', ''),
                    remark: value,
                },
            })
                .then(() => {
                    message.success(services.language.getText('saveFully'));
                    const { entity, params } = props;
                    entity.search(params);
                    setValue('');
                    setVisible(false);
                    setLoading(false);
                })
                .catch((error: any) => {
                    services.errorHandle(error);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, props]);

    //modal取消
    const onModalCancel = () => {
        setValue('');
        setVisible(false);
    };
    const Header = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>
                <Icon type='exclamation-circle' style={{ fontSize: '20px', color: '#FAAD14' }} />
            </span>
            <span style={{ marginLeft: '9px' }}>
                {requestStatus === 1
                    ? services.language.getText('deposit.Cancelancelafterverification')
                    : services.language.getText('deposit.ArtificialCancelAfterVerification')}
            </span>
        </div>
    );
    return (
        <>
            {renderTrigger()}
            <Modal
                className='deposit'
                visible={visible}
                onOk={onModalOk}
                onCancel={onModalCancel}
                confirmLoading={loading}
                closable={false}
                title={Header}
            >
                <div
                    style={{
                        marginLeft: '28px',
                        marginBottom: '10px',
                        fontSize: '13px',
                        color: '#333',
                    }}
                >
                    {requestStatus === 1
                        ? services.language.getText('deposit.peopleCancelMessage')
                        : services.language.getText('deposit.cancelMessage')}
                </div>
                <Form.Item
                    label={services.language.getText('deposit.reason')}
                    {...formItemLayout}
                    required
                    help={help}
                    validateStatus={help ? 'error' : 'success'}
                >
                    <TextArea onChange={onChange} maxLength={1000} value={value} />
                </Form.Item>
                <span style={{ marginLeft: '77px', fontSize: '10px', color: '#767676' }}>
                    {services.language.getText('deposit.maximumInput')}
                </span>
            </Modal>
        </>
    );
};

export { DepositModal };

import React, { useState, useEffect, useRef } from 'react';

import { Button, DatePicker, Modal, Table, message } from 'antd';
import { formats, modes } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';
import { cloneDeep, isEmpty, map } from 'lodash';
import { KV } from '@/interfaces';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

const SelectDateComponent = (props: any) => {
    const [open, setOpen] = useState(false); // 是否显示弹窗
    const [data, setData] = useState<KV[]>([]);
    const [dateValue, setDateValue] = useState<RangePickerValue | undefined>(undefined);
    const dateDict = useRef<KV>({});

    useEffect(() => {
        const dateList = props.value || [];
        setData(dateList);
    }, [props.value]);

    const columns = [
        {
            title: language.getText('storeChannels.startTime'),
            dataIndex: 'startDate',
        },
        {
            title: language.getText('storeChannels.endTime'),
            dataIndex: 'endDate',
        },
        {
            title: language.getText('actions'),
            dataIndex: 'actions',
            render: (text: any, record: any, index: number) => {
                return (
                    <Button
                        type='link'
                        onClick={() => {
                            const nVal: any = cloneDeep(data);
                            nVal.splice(index, 1);
                            props.onChange(nVal);
                        }}
                    >
                        {language.getText('delete')}
                    </Button>
                );
            },
        },
    ];

    const resetDate = () => {
        dateDict.current = {};
        setDateValue(undefined);
    };

    return (
        <>
            <Button
                onClick={() => {
                    resetDate();
                    setOpen(true);
                }}
                type='primary'
            >
                {language.getText('storeChannels.add')}
            </Button>
            <Modal
                visible={open}
                onOk={() => {
                    if (isEmpty(dateDict.current)) {
                        message.warning(language.getText('storeChannels.warning'));
                        return;
                    }
                    data.unshift(dateDict.current);
                    setOpen(false);
                    props.onChange(data);
                }}
                onCancel={() => setOpen(false)}
                title={language.getText('storeChannels.selectDischargeTime')}
                bodyStyle={{
                    paddingTop: '20px',
                }}
            >
                <DatePicker.RangePicker
                    format='YYYY-MM-DD'
                    value={dateValue}
                    onChange={(date, dateString) => {
                        const filterEmpty = dateString.filter((item) => item);
                        if (isEmpty(filterEmpty)) {
                            resetDate();
                            return;
                        }
                        dateDict.current = {};
                        dateDict.current['startDate'] = dateString[0];
                        dateDict.current['endDate'] = dateString[1];
                        setDateValue(date);
                    }}
                />
            </Modal>

            {!isEmpty(data) && <Table dataSource={data} columns={columns} rowKey={'startTime'} />}
        </>
    );
};

export class SelectDateMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SelectDateComponent {...controlInfo} />;
    }

    public getDisplayComponent(array: any[], displayInfo: any) {
        array = array?.map((item) => `${item.startDate} - ${item.endDate}`);
        return super.getDisplayComponent(array, displayInfo);
    }
}

export class SelectDateFormat extends formats.ArrayFormat {
    /**
     * 对数据进行校验
     */
    validate(rule: any, value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: Array<any> | undefined) {
        const result = map(value, (item) => ({
            ...item,
        }));
        return { [key]: result };
    }
}

import React, { Component, createElement } from 'react';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services, ComponentsManager } from '@comall-backend-builder/core/lib';
import { recordImportLogs } from '@/services';

export interface MarkGroupOperateProductButtonProps {
    className: string;
    entity: Entity;
    row: any;
    type: 'export' | 'import';
}

export class MarkGroupOperateProductButton extends Component<
    MarkGroupOperateProductButtonProps,
    any
> {
    render() {
        let { className, row, type, entity } = this.props;
        const ExportProps = {
            className,
            text: services.language.getText('markGroup.exportProduct'),
            type: 'link',
            source: {
                apiPath: `/dc-goods/admin/tag_groups/${row.id}/csku/export`,
                paramsFilter: [],
            },
        };
        const ImportProps = {
            className,
            entity,
            apiPath: `/dc-file/excels?location_id=1`,
            showSuccessMessage: false,
            triggerComponent: {
                component: 'Button',
                type: 'link',
                children: services.language.getText('markGroup.importProduct'),
            },
            onUploadComplete(response: any) {
                if (response) {
                    recordImportLogs({
                        name: services.language.getText('markGroup.MarkGroupAddButton'),
                        apiPath: `/dc-goods/admin/tag_groups/${row.id}/csku/up_excel`,
                        params: { resourceId: response.id },
                    });
                }
            },
        };
        return (
            <React.Fragment>
                {type === 'export'
                    ? createElement(ComponentsManager.get('ExportButton'), ExportProps)
                    : createElement(ComponentsManager.get('Upload'), ImportProps)}
            </React.Fragment>
        );
    }
}

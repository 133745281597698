import React, { PureComponent } from 'react';
import { Card as AntCard, Table as AntTable } from 'antd';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';

/**
 * 订单详情 - 订单备注信息
 */
class orderInfoRemark extends PureComponent {
    render() {
        const { id, style, remarks = [] } = this.props;
        const cardProps = {
            className: 'order-info-remark',
            bordered: false,
            loading: !id,
            title: services.language.getText('order.orderInfoRemark.remark'),
            style,
        };

        const tableProps = {
            rowKey: 'createTime',
            pagination: false,
            columns: [
                {
                    title: services.language.getText('order.orderInfoRemark.content'),
                    dataIndex: 'content',
                    key: 'content',
                },
                {
                    title: services.language.getText('order.orderInfoRemark.createTime'),
                    dataIndex: 'createTime',
                    key: 'createTime',
                    width: '25%',
                },
                {
                    title: services.language.getText('order.orderInfoRemark.userName'),
                    dataIndex: 'userName',
                    key: 'userName',
                    width: '15%',
                },
            ],
            dataSource: remarks,
        };
        return !!remarks.length ? (
            <AntCard {...cardProps}>
                <AntTable {...tableProps} />
            </AntCard>
        ) : null;
    }
}

const mapStateToProps = (state, ownProps) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const OrderInfoRemark = connect(mapStateToProps, null)(orderInfoRemark);

import React, { Fragment, useState } from 'react';
import { services, Loader } from '@comall-backend-builder/core';
import { ExtendedParentComponentProps } from '@comall-backend-builder/components-basis';
import { Modal, Button, Form, message as AntMessage, Select, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { trim, get, isEmpty } from 'lodash';

const { language, interpolate } = services;
let i = 0;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};

interface Props extends FormComponentProps, ExtendedParentComponentProps {
    componentType: 'recommendedBrand';
    row: { [key: string]: any };
    option?: any;
}

const localStorageKey = 'recommended-brand-brandList';
const apiPath = '/dc-master-data/admin/homeBrand';

const ModalSelect: React.FC<Props> = (props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [brandList, setBrandList] = useState<any[]>([]);
    const [selectBrandList, setSelectBrandList] = useState<any[]>([{ id: '' }]);

    const getBrandList = async () => {
        if (!services.localStorage.get(localStorageKey)) {
            const res: any = await services.api.get(
                {},
                {
                    apiPath: '/dc-goods/admin/brands/list',
                }
            );

            services.localStorage.set(localStorageKey, res);
            setBrandList(res);
        } else {
            const res: any = services.localStorage.get(localStorageKey);
            setBrandList(res);
        }
    };

    const getDetailBrand = async () => {
        const { row } = props;
        const res: any = await services.api.get(
            {},
            {
                apiPath: `${apiPath}/${row.storeId}`,
            }
        );
        setSelectBrandList(res);
    };

    const toggleModal = (flag: boolean) => async () => {
        setVisible(flag);
        props.form.resetFields();
        if (flag) {
            await getBrandList();
            await getDetailBrand();
        }
    };

    const handleSubmit = () => {
        const { row, form } = props;

        form.validateFields((error: any, values: any) => {
            if (!error) {
                if (confirmLoading) return;
                setConfirmLoading(true);

                const params = values.formData.map((item: any) => ({
                    ...item,
                    objId: item.objId,
                    type: 3,
                }));

                Loader.load('put', {
                    params,
                    apiPath: `${apiPath}/${row.storeId}`,
                })
                    .finally(() => {
                        setConfirmLoading(false);
                    })
                    .then(() => {
                        const { entity, params: entityParams = {} } = props;
                        entity && entity.search(entityParams);
                        AntMessage.success(services.language.getText('saveFully'), 1.5);
                        toggleModal(false)();
                    })
                    .catch(services.errorHandle);
            }
        });
    };

    const onAddTag = () => {
        const nextKeys = selectBrandList.concat(++i);
        setSelectBrandList([...nextKeys]);
    };

    const onRemoveTag = (i: number) => {
        if (selectBrandList.length === 1) {
            return;
        }
        const nextKeys = selectBrandList.filter((_item: any, index: number) => {
            return index !== i;
        });
        setSelectBrandList([...nextKeys]);
    };

    const renderItem = () => {
        const {
            componentType,
            form: { getFieldDecorator },
        } = props;

        return (
            <Fragment>
                {selectBrandList.map((k: any, index: number) => {
                    return (
                        <>
                            <Form.Item
                                label={interpolate(
                                    language.getText(`designManager.${componentType}.brand`),
                                    {
                                        key: index + 1,
                                    }
                                )}
                                required={true}
                                key={`item-${k}-${index}`}
                                {...formItemLayout}
                                style={{ width: '100%' }}
                            >
                                {getFieldDecorator(`formData.${index}.objId`, {
                                    rules: [
                                        {
                                            validator: (rule: any, value: any, callback: any) => {
                                                try {
                                                    if (!value || !trim(value)) {
                                                        callback(
                                                            new Error(
                                                                language.getText(
                                                                    `designManager.${componentType}.error`
                                                                )
                                                            )
                                                        );
                                                    }
                                                    callback();
                                                } catch (err) {
                                                    callback(err);
                                                }
                                            },
                                        },
                                    ],
                                    initialValue: k.objId || '',
                                })(
                                    <Select
                                        showSearch
                                        filterOption={(input, option: any) => {
                                            return (option?.props.children ?? '').includes(input);
                                        }}
                                        placeholder={language.getText(
                                            `designManager.${componentType}.placeholder`
                                        )}
                                        style={{ width: '50%', marginRight: 8 }}
                                    >
                                        {brandList.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                <Button
                                    style={
                                        index === selectBrandList.length - 1 &&
                                        selectBrandList.length === 1
                                            ? { display: 'none' }
                                            : undefined
                                    }
                                    type='link'
                                    size='small'
                                    className='remove-tag-button'
                                    onClick={() => onRemoveTag(index)}
                                >
                                    {language.getText('delete')}
                                </Button>
                                {!(selectBrandList.length >= 8) && (
                                    <Button
                                        type='link'
                                        size='small'
                                        className='add-tag-button'
                                        style={
                                            !selectBrandList ||
                                            !selectBrandList.length ||
                                            index === selectBrandList.length - 1
                                                ? undefined
                                                : { visibility: 'hidden' }
                                        }
                                        onClick={() => onAddTag()}
                                    >
                                        {language.getText('add')}
                                    </Button>
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} style={{ display: 'none' }}>
                                {getFieldDecorator(`formData.${index}.id`, {
                                    initialValue: isEmpty(k.id) ? 0 : k.id,
                                })(
                                    <Input
                                        style={{ width: '50%', marginRight: 8 }}
                                        maxLength={50}
                                    />
                                )}
                            </Form.Item>
                        </>
                    );
                })}
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Button onClick={toggleModal(true)} type='link' style={{ marginLeft: 0 }}>
                {get(props.option, 'name') ||
                    language.getText(`designManager.${props.componentType}.setBrand`)}
            </Button>
            <Modal
                confirmLoading={confirmLoading}
                title={`${language.getText(`designManager.${props.componentType}.setBrandTitle`)}${
                    props.option ? `（${get(props.option, 'name')}）` : ''
                }`}
                mask={false}
                visible={visible}
                maskClosable={true}
                okText={services.language.getText('submit')}
                cancelText={services.language.getText('cancel')}
                onOk={handleSubmit}
                onCancel={toggleModal(false)}
            >
                {renderItem()}
            </Modal>
        </Fragment>
    );
};

export const ModalSelectBrand = Form.create()(ModalSelect);

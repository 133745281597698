import { generateEmptySearchResult } from '@/services';
import { services } from '@comall-backend-builder/core/lib';
import { message } from 'antd';
import { set, get as lodashGet } from 'lodash';

/**
 * 订单管理-订单列表
 */
export default [
    {
        key: '/dc-order/admin/offlineOrder',
        loader: {
            get: async function(data: any = {}, config: any) {
                if (data.orderNo) {
                    // 详情
                    const result: object = await services.api.get(
                        {
                            outerOrderNumber: data.orderNo,
                        },
                        {
                            ...config,
                            apiPath: `/base-order/admin/order/info`,
                        }
                    );
                    const consigneeCode = lodashGet(result, 'consignee.code');
                    const deliveryCode = lodashGet(result, 'delivery.code');
                    set(result, 'consignee.code', consigneeCode ? `(${consigneeCode})` : '');
                    set(result, 'delivery.code', deliveryCode ? `(${deliveryCode})` : '');
                    return result;
                } else {
                    // 列表
                    // 如果没有选择机构则提示
                    if (!data.onlineStoreIds) {
                        message.error(services.language.getText('picking.retailStoreSelectTip'));
                        return new Promise((resolove) => resolove(generateEmptySearchResult()));
                    }
                    if (data.orderStatus === 'ABNORMAL_ORDERS') {
                        data.queryType = data.orderStatus;
                        delete data.orderStatus;
                    }
                    if (data.createTime) {
                        data.beginTime = data.createTime.start;
                        data.endTime = data.createTime.end;
                        delete data.createTime;
                    }
                    // if (data.waitDeliveryTime) {
                    //     data.preStartDeliveryTime = data.waitDeliveryTime.start;
                    //     data.preEndDeliveryTime = data.waitDeliveryTime.end;
                    //     delete data.waitDeliveryTime;
                    // }
                    const response: any = await services.api.get(data, {
                        ...config,
                        apiPath: '/base-order/admin/order/offline_find',
                    });
                    return response;
                }
            },
        },
    },
    {
        key: '/dc-goods/admin/rskus/findMultiLanguageByOnlineStore',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, config);
                let result = (response?.result || []).map((item: any) => ({
                    ...item,
                    // id: item.cskuId,
                    name: item.spuName,
                }));
                return result;
            },
        },
    },
];

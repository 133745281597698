import React, { PureComponent } from 'react';
import { Card as AntCard, Table as AntTable } from 'antd';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { AFTER_SALE_TYPE_VALUES } from '@/constants/after-sale';

/**
 * 售后单详情 - 退款项目
 */
class afterSaleRefundItem extends PureComponent {
    render() {
        const { id, style, orderPayableVo = {}, aftermarketListVo = {} } = this.props;
        const cardProps = {
            className: 'order-info-remark',
            bordered: false,
            loading: !id,
            title: services.language.getText('afterSale.refundItem.title'),
            style,
        };

        const tableProps = {
            rowKey: 'paymentNo',
            pagination: false,
            columns: [
                {
                    title: services.language.getText('afterSale.refundItem.paymentSerialNum'),
                    dataIndex: 'paymentNo',
                    key: 'paymentNo',
                },
                {
                    title: services.language.getText('afterSale.refundItem.paymentMethods'),
                    dataIndex: 'paymentType',
                    key: 'paymentType',
                    width: '20%',
                },
                {
                    title: services.language.getText('afterSale.refundItem.refundAmount'),
                    dataIndex: 'paidAmount',
                    key: 'paidAmount',
                    width: '20%',
                },
                {
                    title: services.language.getText('afterSale.refundItem.refundIntegral'),
                    dataIndex: 'pointAmount',
                    key: 'pointAmount',
                    width: '20%',
                },
                {
                    title: services.language.getText('afterSale.refundItem.refundVoucher'),
                    dataIndex: 'couponAmount',
                    key: 'couponAmount',
                    width: '20%',
                },
            ],
            dataSource: [orderPayableVo],
        };
        return aftermarketListVo?.returnGoodsSign === AFTER_SALE_TYPE_VALUES.RETURN ? (
            <AntCard {...cardProps}>
                <AntTable {...tableProps} />
            </AntCard>
        ) : null;
    }
}

const mapStateToProps = (state, ownProps) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const AfterSaleRefundItem = connect(mapStateToProps, null)(afterSaleRefundItem);

/*
 * @Author: zhulu
 * @Date: 2022-10-13 14:22:07
 * @Description: 会员资料管理列表新增
 */

import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { createPageLayout, flexibleFilterForm, tableMixin, formFooter } from '@/configs/mixin';
import {
    FAMILYSIZE_OPTIONS,
    IMPORT_TEMPLATE_URL,
    SELECTED_DEFAULT_OPTIONS,
    STATUS_OPTIONS,
} from '@/constants';
import { MEMBER_SHIP_LIST } from '@/configs/pro-table-constants';
import { recordImportLogs } from '@/services';

export const config: Config = {
    entities: {
        membershipList: {
            apiPath: '/loader/dc-user/admin/membershipList',
            properties: {
                id: { type: 'string', displayName: '<<membership.id>>' },
                memberId: { type: 'string', displayName: '<<membership.id>>' },
                createTimeStr: {
                    type: 'string',
                    displayName: '<<membership.createTime>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<membership.email>>',
                },
                levelName: {
                    type: 'string',
                    displayName: '<<membership.level>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<membership.firstName>>',
                },
                phone: {
                    type: 'string',
                    displayName: '<<membership.mobile>>',
                },
                point: {
                    type: 'string',
                    displayName: '<<membership.point>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<membership.status>>',
                },
                surname: {
                    type: 'string',
                    displayName: '<<membership.lastName>>',
                },
                storeName: {
                    type: 'string',
                    displayName: '<<membership.storeName>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<membership.lastModifiedTime>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<membership.name>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<membership.mobile>>',
                },
                mail: {
                    type: 'string',
                    displayName: '<<membership.email>>',
                },
                lastModifyTime: {
                    type: 'object.dateTimeRange',
                    displayName: '<<membership.lastModifiedTime>>',
                },
            },
        },
        membershipInfo: {
            apiPath: '/dc-user/admin/members',
            properties: {
                email: {
                    type: 'string',
                    displayName: '<<membership.email>>',
                    controlConfig: {
                        maxLength: 100,
                    },
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<membership.emptyMsg.email>>',
                        },
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (!value) return new Error();
                                const EMAIL_REG = /^([a-z0-9A-Z]+[-_.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+(([a-zA-Z]{2,})|(\.\w+))$/;
                                if (
                                    (value !== undefined && !EMAIL_REG.test(value)) ||
                                    value.length > 100
                                ) {
                                    return new Error(
                                        services.language.getText('membership.errorMsg.email')
                                    );
                                } else {
                                    callback();
                                }
                            },
                        },
                    ],
                },
                name: {
                    type: 'string',
                    displayName: '<<membership.firstName>>',
                    controlConfig: {
                        maxLength: 20,
                    },
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<membership.emptyMsg.firstName>>',
                        },
                    ],
                },
                mobile: {
                    type: 'object.mobile',
                    displayName: '<<membership.mobile>>',
                    rules: [
                        {
                            required: true,
                            message: '<<membership.emptyMsg.phone>>',
                        },
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (!value.mobile || !value.areaCode) return new Error();
                                const REG = /^[0-9]{1,20}$/;
                                if (
                                    (value.mobile !== undefined && !REG.test(value.mobile)) ||
                                    value.mobile.length > 20
                                ) {
                                    return new Error(
                                        services.language.getText('membership.errorMsg.phone')
                                    );
                                } else {
                                    callback();
                                }
                            },
                        },
                    ],
                },
                regStoreId: {
                    type: 'string.treeNode.cascader',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                    },
                    rules: [{ required: true, message: '请选择门店' }],
                    displayName: '<<membership.storeName>>',
                },
                birthday: {
                    type: 'string.date',
                    displayName: '<<membership.birthday>>',
                    rules: [
                        {
                            required: true,
                            message: '<<membership.emptyMsg.birthday>>',
                        },
                    ],
                },
                surname: {
                    type: 'string',
                    displayName: '<<membership.lastName>>',
                    controlConfig: {
                        maxLength: 20,
                    },
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<membership.emptyMsg.lastName>>',
                        },
                    ],
                },
                familySize: {
                    type: 'string.options.select',
                    options: FAMILYSIZE_OPTIONS,
                    displayName: '<<membership.familySize>>',
                    rules: [{}],
                },
                remark: {
                    type: 'string.text.paragraph',
                    displayName: '<<membership.remark>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 100,
                    },
                },
                sourceSystem: {
                    type: 'string',
                    controlConfig: {
                        maxLength: 100,
                    },
                    displayName: '<<membership.sourceSystem>>',
                },
                source: {
                    type: 'string',
                    controlConfig: {
                        maxLength: 100,
                    },
                    displayName: '<<membership.source>>',
                },
                checkEmail: {
                    type: 'string',
                    controlConfig: {
                        maxLength: 100,
                    },
                    displayName: '<<membership.checkEmail>>',
                },
                isPush: {
                    type: 'string.options.select',
                    displayName: '<<membership.isPush>>',
                    options: SELECTED_DEFAULT_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                    },
                },
            },
        },
    },
    components: {
        MembershipListContainer: {
            component: 'Viewport',
        },
        MembershipList: {
            entity: 'membershipList',
            ...createPageLayout([
                {
                    component: 'MembershipListFilter',
                    style: { marginBottom: 15 },
                    privilege: {
                        path: 'member.member_view.view_list',
                        level: 'full',
                    },
                },
                {
                    component: 'MembershipListAction',
                    style: { marginBottom: 15 },
                },
                {
                    privilege: {
                        path: 'member.member_view.view_list',
                        level: 'full',
                    },
                    component: 'MembershipListTable',
                },
            ]),
        },
        MembershipCreatePage: {
            ...createPageLayout([{ component: 'MembershipCreateForm' }]),
        },
        MembershipCreateForm: {
            component: 'CreateFormPlus',
            entity: 'membershipInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            onSubmitSuccess(formInfo: any) {
                const offlineBackId = window.location.href.match(/\?offlineBackId=(\d+)/);
                if (offlineBackId && offlineBackId[1]) {
                    let phone = '';
                    if (formInfo.mobile?.value?.areaCode?.code) {
                        phone += formInfo.mobile?.value?.areaCode?.code;
                    }
                    if (formInfo.mobile?.value?.mobile) {
                        phone += formInfo.mobile?.value?.mobile;
                    }
                    services.navigation.goto(
                        `/offlineOrders/create/${offlineBackId[1]}/?phone=${phone}`
                    );
                } else {
                    services.navigation.goBack();
                }
            },
            fields: [
                { property: 'mobile' },
                { property: 'surname' },
                { property: 'name' },
                { property: 'email' },
                { property: 'birthday' },
                { property: 'regStoreId' },
                { property: 'familySize' },
                { property: 'remark' },
                { property: 'sourceSystem' },
                { property: 'source' },
                { property: 'checkEmail' },
                { property: 'isPush' },
            ],
        },
        MembershipListFilter: {
            ...flexibleFilterForm,
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membership.name>>',
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membership.mobile>>',
                    },
                },
                {
                    property: 'mail',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membership.email>>',
                    },
                },
                {
                    property: 'lastModifyTime',
                    controlConfig: {
                        style: { width: '100%' },
                        allowClear: true,
                        showSearch: true,
                        placeholder: '<<membership.email>>',
                    },
                },
            ],
        },
        MembershipListAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Button',
                    text: '<<membership.add>>',
                    icon: 'plus',
                    type: 'primary',
                    route: '/members/create',
                    style: {
                        marginRight: 15,
                    },
                    privilege: {
                        path: 'member.member_view.add',
                        level: 'full',
                    },
                },
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<membership.importingMembers>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('membership.importingMembers'),
                                apiPath: `/dc-user/admin/members/memberImport`,
                                params: { resourceId: response.id },
                            });
                        }
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'member.member_view.import',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productInventory.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 10,
                        marginRight: 10,
                        alignSelf: 'center',
                    },
                    privilege: {
                        path: 'member.member_view.download_import_template',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.MEMBER_INFO}`,
                },
                {
                    component: 'Upload',
                    apiPath: `/dc-file/excels?location_id=2`,
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<membership.importUpdateMembers>>',
                    },
                    onUploadComplete(response: any, row: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('membership.importingMembers'),
                                apiPath: `/dc-user/admin/members/memberImport/update`,
                                params: { resourceId: response.id },
                            });
                        }
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'member.member_view.import_update',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productInventory.exportTemplate>>',
                    type: 'link',
                    style: {
                        marginLeft: 10,
                        alignSelf: 'center',
                    },
                    privilege: {
                        path: 'member.member_view.download_import_update_template',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.UPDATE_MEMBER_INFO}`,
                },
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<membership.exportMembers>>',
                    icon: 'download',
                    type: 'primary',
                    style: {
                        flexGrow: 0,
                        marginLeft: 15,
                    },
                    source: {
                        apiPath: '/dc-user/admin/members/export/take',
                    },
                    formatter: (params: any) => {
                        const newParams: any = { ...params };
                        if (newParams.lastModifyTime) {
                            newParams.modifyStartTime = newParams.lastModifyTime.start;
                            newParams.modifyEndTime = newParams.lastModifyTime.end;
                        }
                        delete newParams.lastModifyTime;
                        return {
                            ...newParams,
                        };
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'member.member_view.export',
                        level: 'full',
                    },
                },
            ],
        },
        MembershipListTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: MEMBER_SHIP_LIST,
            columns: [
                { property: 'phone' },
                { property: 'surname' },
                { property: 'name' },
                { property: 'email' },
                { property: 'memberId' },
                { property: 'levelName' },
                { property: 'point' },
                { property: 'createTimeStr' },
                { property: 'lastModifyTime' },
                { property: 'storeName' },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        text: '<<view>>',
                        type: 'link',
                        path: '/members/info/{{row.id}}',
                        privilege: {
                            path: 'member.member_view.view',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<statusValue.disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                return {
                                    entity: props.entity,
                                    row,
                                    statusApi: {
                                        '0': '/dc-user/admin/members/{{row.id}}/enable',
                                        '1': '/dc-user/admin/members/{{row.id}}/disable',
                                    },
                                    statusText: {
                                        '0': '<<membership.enable>>',
                                        '1': '<<membership.disable>>',
                                    },
                                };
                            },
                        },
                        privilege: {
                            path: 'member.member_view.disable',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

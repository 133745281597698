import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 发放对象类型值
 */
export enum PROVISIONING_OBJECTS_VALUES {
    /**
     * 每会员
     */
    MEMBERS = '1',
    /**
     * 每会员x家庭人口
     */
    FAMILY = '2',
}

/**
 * 发放对象类型名
 */
export const PROVISIONING_OBJECTS_NAMES = {
    [PROVISIONING_OBJECTS_VALUES.MEMBERS]: language.getText(
        'couponManage.voucherManagement.provisioningObjects.members'
    ),
    [PROVISIONING_OBJECTS_VALUES.FAMILY]: language.getText(
        'couponManage.voucherManagement.provisioningObjects.family'
    ),
};

/**
 * 发放对象类型候选项
 */
export const PROVISIONING_OBJECTS_OPTIONS = generateOptions(
    PROVISIONING_OBJECTS_VALUES,
    PROVISIONING_OBJECTS_NAMES
);

/**
 * 发放周期
 */
export enum RELEASE_PERIOD_VALUES {
    /**
     * 每周
     */
    WEEK = '1',
    /**
     * 每月
     */
    MONTH = '2',
}

/**
 * 发放周期
 */
export enum RELEASE_PERIOD_STR_VALUES {
    /**
     * 每周
     */
    WEEK = 'WEEKLY',
    /**
     * 每月
     */
    MONTH = 'MONTHLY',
}

/**
 * 发放周期类型名
 */
export const RELEASE_PERIOD_NAMES = {
    [RELEASE_PERIOD_VALUES.WEEK]: language.getText(
        'couponManage.voucherManagement.releasePeriod.week'
    ),
    [RELEASE_PERIOD_VALUES.MONTH]: language.getText(
        'couponManage.voucherManagement.releasePeriod.month'
    ),
};

/**
 * 发放周期类型名-映射值是WEEKLY MONTHLY
 */
export const RELEASE_PERIOD_STR_NAMES = {
    [RELEASE_PERIOD_STR_VALUES.WEEK]: language.getText(
        'couponManage.voucherManagement.releasePeriod.week'
    ),
    [RELEASE_PERIOD_STR_VALUES.MONTH]: language.getText(
        'couponManage.voucherManagement.releasePeriod.month'
    ),
};

/**
 * 发放周期类型候选项
 */
export const RELEASE_PERIOD_OPTIONS = generateOptions(RELEASE_PERIOD_VALUES, RELEASE_PERIOD_NAMES);

/**
 * 发放周期类型选项-映射值是WEEKLY MONTHLY
 */
export const RELEASE_PERIOD_STR_OPTIONS = generateOptions(
    RELEASE_PERIOD_STR_VALUES,
    RELEASE_PERIOD_STR_NAMES
);

/**
 * 劵状态值
 */
export enum VOUCHER_STATUS_VALUES {
    /** 未开始 */
    UN_START = 0,
    /** 进行中 */
    PROGRESSING = 1,
    /** 已结束 */
    FINISHED = 2,
}

/**
 * 券状态名
 */
export const VOUCHER_STATUS_NAMES = {
    [VOUCHER_STATUS_VALUES.UN_START]: language.getText('couponManage.status.unStart'),
    [VOUCHER_STATUS_VALUES.PROGRESSING]: language.getText('couponManage.status.progressing'),
    [VOUCHER_STATUS_VALUES.FINISHED]: language.getText('couponManage.status.finished'),
};

/**
 * 券状态角标颜色
 */
export const VOUCHER_STATUS_COLORS = {
    [VOUCHER_STATUS_VALUES.UN_START]: 'success',
    [VOUCHER_STATUS_VALUES.PROGRESSING]: 'warning',
    [VOUCHER_STATUS_VALUES.FINISHED]: 'default',
};

/**
 * 券状态候选项
 */
export const VOUCHER_STATUS_OPTIONS = generateOptions(
    VOUCHER_STATUS_VALUES,
    VOUCHER_STATUS_NAMES,
    (v, n) => ({ id: v, name: n, status: VOUCHER_STATUS_COLORS[v] })
);

import * as React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';
import { SpecialAttribute } from '@/containers/special-attribute';

export class SpecialAttributeMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SpecialAttribute {...controlInfo} />;
    }
}

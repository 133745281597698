import { isEmpty, some } from 'lodash';
/*
 * @Author: zhulu
 * @Date: 2021-12-06 14:05:06
 * @Description:订单创建邮件通知管理
 */
import { flexibleFilterForm, tableMixin, formFooter } from '@/configs/mixin';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { STATUS_OPTIONS } from '@/constants';

function hasDuplicates(array: string[]) {
    return some(array, function(elt: string, index: number) {
        return array.indexOf(elt) !== index;
    });
}

const detailConfig = {
    entity: 'emailMessage',
    labelCol: 6,
    controlCol: 8,
    fields: [
        {
            property: 'onlineStoreCode',
            modifiable: false,
        },
        {
            property: 'onlineStoreName',
            modifiable: false,
        },
        { property: 'emailList' },
    ],
};
export const config: Config = {
    entities: {
        emailMessage: {
            apiPath: '/loader/dc-message-center/admin/pick_email_config',
            properties: {
                id: {
                    type: 'string',
                },
                onlineStoreId: { type: 'string' },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<emailMessage.storeName>>',
                },
                onlineStoreCode: {
                    type: 'string',
                    displayName: '<<emailMessage.storeCode>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<emailMessage.status>>',
                },
                emailList: {
                    type: 'array.email.input',
                    displayName: '<<emailMessage.emailList>>',
                    rules: [
                        {
                            required: true,
                        },
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (isEmpty(value)) return new Error();
                                const EMAIL_REG = /^([a-z0-9A-Z]+[-_.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+(([a-zA-Z]{2,})|(\.\w+))$/;
                                if (value.some((item: string) => !EMAIL_REG.test(item))) {
                                    return new Error(
                                        services.language.getText('emailMessage.emailError')
                                    );
                                }
                                if (hasDuplicates(value)) {
                                    return new Error(
                                        services.language.getText('emailMessage.emailRepeat')
                                    );
                                }
                                callback();
                            },
                        },
                    ],
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<emailMessage.keyword>>',
                    controlConfig: {
                        placeholder: '<<emailMessage.keywordPlaceholder>>',
                    },
                },
            },
        },
    },

    components: {
        EmailMessageContainer: {
            component: 'Viewport',
        },
        EmailMessageManagement: {
            component: 'Card',
            entity: 'emailMessage',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'EmailMessageFilter',
                        privilege: {
                            path: 'system.pick_email_notice.view_list',
                            level: 'full',
                        },
                    },
                    {
                        component: 'EmailMessageTable',
                        privilege: {
                            path: 'system.pick_email_notice.view_list',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        EmailMessageFilter: {
            ...flexibleFilterForm,
            fields: [{ property: 'keyword' }],
        },
        EmailMessageTable: {
            ...tableMixin,
            columns: [
                { property: 'onlineStoreName' },
                { property: 'onlineStoreCode' },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<statusValue.disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                return {
                                    entity: props.entity,
                                    row,
                                    statusApi: {
                                        '0':
                                            '/dc-message-center/admin/pick_email_config/{{row.id}}',
                                        '1':
                                            '/dc-message-center/admin/pick_email_config/{{row.id}}',
                                    },
                                    statusParams: {
                                        '0': { status: true },
                                        '1': { status: false },
                                    },
                                    statusText: {
                                        '0': '<<statusActions.enable>>',
                                        '1': '<<statusActions.disable>>',
                                    },
                                };
                            },
                        },
                        privilege: {
                            path: 'system.pick_email_notice.enable_disable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<view>>',
                        type: 'link',
                        path: '/email-message-management/info/{{row.id}}',
                        privilege: {
                            path: 'system.pick_email_notice.view_detail',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/email-message-management/edit/{{row.id}}',
                        privilege: {
                            path: 'system.pick_email_notice.edit',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        EmailMessageInfo: {
            component: 'Card',
            content: {
                component: 'EmailMessageDetail',
            },
        },
        EmailMessageDetail: {
            ...detailConfig,
            loaderType: 'get',
            component: 'Detail',
            align: 'left',
            footer: {
                items: [
                    {
                        type: 'primary',
                        text: '<<back>>',
                        route: 'goBack',
                        style: { float: 'right' },
                    },
                ],
            },
        },
        EmailMessagePage: {
            component: 'Card',
            content: { component: 'EmailMessageEdit' },
        },
        EmailMessageEdit: {
            ...detailConfig,
            loaderType: 'get',
            entity: 'emailMessage',
            component: 'ModifyForm',
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
        },
    },
};

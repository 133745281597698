export * from './component';
export * from './enum';
export * from './store';
export * from './product-limit';
export * from './brand';

//一些常用类型
export type KV<T = any> = {
    [key: string]: T;
};

export type OptionType = {
    id: string;
    name: string;
};

import React, { Component } from 'react';
import { modes } from '@comall-backend-builder/types';
import { InputNumber as AntInputNumber } from 'antd';
import { services } from '@comall-backend-builder/core';
interface DeliveryTimeProps {
    [key: string]: any;
}
export class TagLevelComponent extends Component<DeliveryTimeProps, any> {
    render() {
        return (
            <span>
                <AntInputNumber {...this.props} />
                <p
                    style={{
                        color: 'rgba(0, 0, 0, 0.3)',
                        lineHeight: '1.2em',
                        fontSize: '12px',
                        marginTop: '10px',
                    }}
                >
                    {services.language.getText('productTag.levelTip')}
                </p>
            </span>
        );
    }
}
/**
 * 状态类型
 */
export class TagLevelMode extends modes.NumberMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        return <TagLevelComponent {...controlInfo} />;
    }
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        if (value === undefined || value === null || !value) {
            return this.getNotAvailableDisplayComponent(displayInfo);
        } else {
            return this.getAvailableDisplayComponent(value, displayInfo);
        }
    }
}

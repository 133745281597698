import { formats } from '@comall-backend-builder/types';

export class UrlImageFormat extends formats.ArrayFormat {
    /**
     * 将数据格式化为请求参数
     */
    public formatParams(key: any, value: any) {
        let path: string | undefined;

        if (value && value.length) {
            path = value[0].path || value[0].url;
        }
        return { [key]: path };
    }

    /**
     * 对数据进行校验
     */
    public validate(_rule: any, _value: any, callback: any) {
        callback();
    }
}

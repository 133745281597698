export function buildForm(mode: string) {
    return {
        component: 'SuperForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'field',
                property: 'name',
                key: 'name',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'code',
                key: 'code',
                disabled: mode !== 'add',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'effectTime',
                key: 'effectTime',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'storeIds',
                key: 'storeIds',
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
            {
                renderType: 'field',
                property: 'applyCouponParamsList',
                key: 'applyCouponParamsList',
                params: {
                    storeIds: '{{entity.fields.storeIds}}',
                },
                fieldOpts: {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
            },
        ],
    };
}

import { services } from '@comall-backend-builder/core';
import { formats } from '@comall-backend-builder/types';

export class InputArrayFormat extends formats.ArrayFormat {
    /**
     * 将数据格式化为请求参数
     */
    public formatParams(key: any, value: any) {
        return { [key]: value };
    }

    /**
     * 对数据进行校验
     */
    public validate(_rule: any, _value: any, callback: any) {
        if (_value.length <= 0) return;
        let editCount = 0;
        let count = 0;
        _value.forEach((item: any) => {
            const unEmptyLength = item.content.replace(/\s+/g, '').length;
            const contentLength: number = item.content.length;
            if (item.change && unEmptyLength !== 0) {
                editCount += contentLength;
            }
            count += contentLength;
        });
        if (editCount === 0) {
            callback(new Error(services.language.getText('productTag.inputArray.emptyTip')));
        } else if (count > 50) {
            callback(new Error(services.language.getText('productTag.inputArray.lengthTip')));
        } else {
            callback();
        }
    }
}

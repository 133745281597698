import * as components from '@comall-backend-builder/components-basis';
import { builder, services } from '@comall-backend-builder/core';
import { defaultTypes } from '@comall-backend-builder/types';

import { forIn, get } from 'lodash';
import { message } from 'antd';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import '@/services/api';
import '@/languages';
import { UserService } from './utils/user-service';

/**
 * 初始化sso登录
 */
if (!window.location.hash.includes('login-back')) {
    UserService.init();
}

builder.replaceErrorHandle((error: ErrorInfo) => {
    const errMsg = get(error, 'body.err_msg');
    const errMsg2 = get(error, 'body.message');

    if (errMsg2 && errMsg2.startsWith('50010')) {
        message.error({
            key: 'network',
            content: services.language.getText('memberExamine.selectLevelName'),
        });
    } else {
        message.error({
            key: 'network',
            content: errMsg || errMsg2 || services.language.getText('internalSeverError'),
        });
    }
});
// Customized singleton instance
services.localStorage.prefix = 'DCH-backend-';
// Initialize global settings
builder.init({
    // 'api.token': 'demo-api-token',
    'features.i18n': true,
});

// Register basis components
forIn(components, (component, name) => {
    builder.registerComponent(name, component);
});

// Register default types
defaultTypes.forEach((type, name) => {
    builder.registerType(name, type);
});

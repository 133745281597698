import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 线下订单状态值
 */
export enum OFFLINE_ORDER_STATUS_VALUES {
    /**
     * 全部（前端特有状态用于查询）
     */
    ALL = 'ALL',
    /**
     * 待付款
     */
    WAIT_PAY = 'WAIT_PAY',
    /**
     * 已付款
     */
    PAID = 'PAID',
    /**
     * 已完成
     */
    COMPLETED = 'COMPLETED',
    /**
     * 已取消
     */
    CANCELLED = 'CANCELLED',
    /**
     * 已退款
     */
    REFUNDED = 'RETURNED',
}

/**
 * 线下订单状态名
 */
export const OFFLINE_ORDER_STATUS_NAMES = {
    [OFFLINE_ORDER_STATUS_VALUES.ALL]: language.getText('offlineOrder.statusMap.all'),
    [OFFLINE_ORDER_STATUS_VALUES.WAIT_PAY]: language.getText('offlineOrder.statusMap.waitPay'),
    [OFFLINE_ORDER_STATUS_VALUES.PAID]: language.getText('offlineOrder.statusMap.paid'),
    [OFFLINE_ORDER_STATUS_VALUES.COMPLETED]: language.getText('offlineOrder.statusMap.completed'),
    [OFFLINE_ORDER_STATUS_VALUES.CANCELLED]: language.getText('offlineOrder.statusMap.cancelled'),
    [OFFLINE_ORDER_STATUS_VALUES.REFUNDED]: language.getText('offlineOrder.statusMap.refunded'),
};

/**
 * 线下订单状态候选项
 */
export const OFFLINE_ORDER_STATUS_OPTIONS = generateOptions(
    OFFLINE_ORDER_STATUS_VALUES,
    OFFLINE_ORDER_STATUS_NAMES
);

/**
 * 线下订单状态值
 */
export enum OFFLINE_ORDER_STATUS_PART_VALUES {
    /**
     * 待付款
     */
    WAIT_PAY = 'WAIT_PAY',
    /**
     * 已付款
     */
    PAID = 'PAID',
    /**
     * 已完成
     */
    COMPLETED = 'COMPLETED',
    /**
     * 已取消
     */
    CANCELLED = 'CANCELLED',
    /**
     * 已退款
     */
    REFUNDED = 'RETURNED',
}

/**
 * 线下订单状态名
 */
export const OFFLINE_ORDER_STATUS_PART_NAMES = {
    [OFFLINE_ORDER_STATUS_VALUES.WAIT_PAY]: language.getText('offlineOrder.statusMap.waitPay'),
    [OFFLINE_ORDER_STATUS_VALUES.PAID]: language.getText('offlineOrder.statusMap.paid'),
    [OFFLINE_ORDER_STATUS_VALUES.COMPLETED]: language.getText('offlineOrder.statusMap.completed'),
    [OFFLINE_ORDER_STATUS_VALUES.CANCELLED]: language.getText('offlineOrder.statusMap.cancelled'),
    [OFFLINE_ORDER_STATUS_VALUES.REFUNDED]: language.getText('offlineOrder.statusMap.refunded'),
};

/**
 * 线下订单状态候选项
 */
export const OFFLINE_ORDER_STATUS_PART_OPTIONS = generateOptions(
    OFFLINE_ORDER_STATUS_PART_VALUES,
    OFFLINE_ORDER_STATUS_PART_NAMES
);

/**
 * 线下订单配送类型值
 */
export enum OFFLINE_DELIVERY_TYPE_VALUES {
    /**
     * 立即自提
     */
    // IMMEDIATELY = '-1',
    /**
     * 自提
     */
    PICK_UP = '0',
    /**
     * 配送
     */
    DELIVERY = '1',
    /**
     * 快递
     */
    // EXPRESS = '2',
}

/**
 * 配送类型名
 */
export const OFFLINE_DELIVERY_TYPE_NAMES = {
    // [OFFLINE_DELIVERY_TYPE_VALUES.IMMEDIATELY]: language.getText('deliveryType.immediately'),
    [OFFLINE_DELIVERY_TYPE_VALUES.PICK_UP]: language.getText('deliveryType.pickUp'),
    [OFFLINE_DELIVERY_TYPE_VALUES.DELIVERY]: language.getText('deliveryType.delivery'),
    // [OFFLINE_DELIVERY_TYPE_VALUES.EXPRESS]: language.getText('deliveryType.express'),
};

/**
 * 配送类型候选项
 */
export const OFFLINE_DELIVERY_TYPE_OPTIONS = generateOptions(
    OFFLINE_DELIVERY_TYPE_VALUES,
    OFFLINE_DELIVERY_TYPE_NAMES
);

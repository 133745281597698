import { connect } from 'react-redux';
import { mapValues, reduce, isEmpty, get, map, assign, set, clone } from 'lodash';
import { Fields, Field } from '@comall-backend-builder/core/lib/types';

import { CustomForm, BasicFormProps } from './basic-form';

/**
 *  配置示例
 *
 *  component: 'FilterFormPlus',
 *  labelCol: 4,
 *  fields: [
 *      {
 *          label: 'a',
 *          property: 'a',
 *      },
 *      {
 *          label: 'b',
 *          property: 'b',
 *      },
 *      {
 *          label: 'c',
 *          property: 'c',
 *      },
 *      {
 *          label: 'd',
 *          property: 'd',
 *      },
 *      {
 *          label: 'e',
 *          property: 'e',
 *      },
 *  ],
 *  direction: 'horizontal',
 *  simple: {
 *      direction: 'horizontal',
 *      fieldCol: { span: 8 },
 *      fields: ['a', 'b', 'c'],
 *  },
 *  fieldCol: { span: 8 },
 *  submit: {
 *      text: '查询',
 *  },
 *  reset: true,
 */

function getField(path: string, fields: Fields) {
    let propertyNames = path.split('.');
    let field: Field;
    let fieldName: string[] = [];
    for (let name of propertyNames) {
        fieldName.push(name);
        field = fields[fieldName.join('.')];
        if (!field) {
            break;
        }
        fields = field.fields;
    }
    return field!;
}
function mapStateToProps(_state: any, props: any): Partial<BasicFormProps> {
    const { entity, fields: fieldsFromProps, mode } = props;
    let submit = props.submit;
    let reset = props.reset;
    let {
        id: entityId,
        metainfo: { filters: entityFilters },
    } = entity;
    const fieldValues = entity.filters || {};

    let filters: { [key: string]: any } = {};
    map(fieldsFromProps, (field) => {
        let { property, ...config } = field;

        // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
        let entityProperty = get(entityFilters, property.replace('.', '.properties.'));
        if (entityProperty) {
            set(filters, property, assign({}, entityProperty, config));
        } else {
            throw new Error(`Property ${property} not found in Entity ${entityId}`);
        }
    });

    const fields = mapValues(filters, (config, name) => {
        let field = { ...config };

        field.value = get(fieldValues, name);

        return field;
    });

    if (!submit || mode === 'change') {
        submit = false;
    }

    return {
        state: {
            fields: fields,
        },
        fieldSource: 'filters',
        submit: submit,
        reset,
    };
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { entity, submit, mode, fields: fieldsFromProps, resetPagination } = props;
    return {
        onInit: () => {
            let {
                id: entityId,
                metainfo: { filters: entityFilters },
                filters: cachedFilters,
            } = entity;

            let filters: { [key: string]: any } = {};
            map(fieldsFromProps, (field) => {
                let { property, ...config } = field;

                // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
                let entityProperty = get(entityFilters, property.replace('.', '.properties.'));
                if (entityProperty) {
                    set(filters, property, assign({}, entityProperty, config));
                } else {
                    throw new Error(`Property ${property} not found in Entity ${entityId}`);
                }
            });

            //如果存在缓存数据则直接按照已缓存的请求进行初始化
            const defaultFilters =
                cachedFilters ||
                reduce(
                    filters,
                    (result: any, filter, key) => {
                        if (filter.defaultValue !== undefined) {
                            result[key] = filter.defaultValue;
                        }
                        return result;
                    },
                    {}
                );

            !isEmpty(defaultFilters) && entity.filtersChange({ ...defaultFilters });
        },

        // 表单域改变
        onFieldChange: (name: string, value: any) => {
            const fields = Object.assign({}, entity.filters, { [name]: value });
            entity.filtersChange(fields);
            if (mode === 'change') {
                if (resetPagination !== false) {
                    entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                }
                entity.search(props.params);
            }
        },

        // 重新加载属性候选值
        onReloadOptions: (fieldName: string, fields: any) => {
            const field = getField(fieldName, fields);
            const sourceDefination = field.source;
            let dependences = sourceDefination.dependences;
            let params = reduce(
                dependences,
                (values, dependence) => {
                    values[dependence] = getField(dependence, fields).value;
                    return values;
                },
                clone(props.params)
            );
            entity.loadFilterOptions(fieldName, sourceDefination, params);
        },

        // 提交表单
        onSubmit: () => {
            if (!!submit) {
                if (resetPagination !== false) {
                    entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                }
                entity.search(props.params);
            }
        },

        // 重置清空
        onReset: (_event: any, fields: any) => {
            let {
                id: entityId,
                metainfo: { filters: entityFilters },
            } = entity;

            let filters: { [key: string]: any } = {};
            map(fieldsFromProps, (field) => {
                let { property, ...config } = field;

                // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
                let entityProperty = get(entityFilters, property.replace('.', '.properties.'));
                if (entityProperty) {
                    set(filters, property, assign({}, entityProperty, config));
                } else {
                    throw new Error(`Property ${property} not found in Entity ${entityId}`);
                }
            });
            const defaultFilters = reduce(
                fields,
                (result: any, filter, key) => {
                    if (filter.defaultValue !== undefined) {
                        result[key] = filter.defaultValue;
                    } else {
                        result[key] = undefined;
                    }
                    return result;
                },
                {}
            );

            if (Object.keys(defaultFilters).length) {
                let res = { ...entity.filters, ...defaultFilters };
                entity.filtersChange(res);
            }
        },
    };
}

export const FilterFormPlus = connect(mapStateToProps, mapDispatchToProps)(CustomForm);

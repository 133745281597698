/*
 * @Author: zhulu
 * @Date: 2022-05-06 16:58:14
 * @Description:
 */
import { services } from '@comall-backend-builder/core';
import React, { useEffect, useState } from 'react';
import { cloneDeep, find } from 'lodash';
import { Input, Table, Button } from 'antd';
import SelectAutoComplete from '@/components/select-auto-complete';
import { isEqual } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
const EditableContext = React.createContext({});

class EditableCell extends React.Component<any, any> {
    renderCell = (params: any) => {
        const {
            editable,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            onChange,
            ...restProps
        } = this.props;

        return (
            <td {...restProps}>
                {editable ? (
                    <Input
                        style={{ width: '240px' }}
                        placeholder={language.getText('defaultPlaceholderInput')}
                        value={record[dataIndex]}
                        onChange={(e) => onChange(index, e.target.value, dataIndex)}
                    />
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}
export const GroupItems = (props: any) => {
    const [dataSource, setDataSource] = useState<any[]>([]);
    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }
        let nDataSource: any[] = (props.value || []).map((item: any, index: number) => {
            let dataSourceItem = find(dataSource, (i) => i.id === item.id);
            let v = {
                ...dataSourceItem,
                ...item,
            };
            return v;
        });
        setDataSource([...nDataSource]);
        if (!isEqual(props.value, nDataSource) && props.value) {
            props.onChange(nDataSource, props.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);
    const onChange = (index: any, val: any, dataIndex: any) => {
        let nVal: any = cloneDeep(dataSource);
        nVal[index][dataIndex] = val;
        props.onChange(nVal);
    };
    const columns = [
        {
            title: language.getText('productAdd.basicMessage.childProductName'),
            dataIndex: 'name',
        },
        {
            title: 'SKU',
            dataIndex: 'csku',
        },
        {
            title: language.getText('productAdd.basicMessage.groupCount'),
            dataIndex: 'quantity',
            editable: true,
        },
        {
            title: language.getText('actions'),
            dataIndex: 'actions',
            render: (text: any, record: any, index: number) => {
                return (
                    <Button
                        type='link'
                        onClick={() => {
                            let nVal: any = cloneDeep(dataSource);
                            nVal.splice(index, 1);
                            props.onChange(nVal);
                        }}
                    >
                        {language.getText('delete')}
                    </Button>
                );
            },
        },
    ].map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editable: col.editable,
                onChange: onChange,
            }),
        };
    });
    return (
        <>
            <SelectAutoComplete
                mode='multiple'
                value={props.value}
                disabled={props.disabled || false}
                placeholder={services.language.getText('defaultPlaceholderSelect')}
                apiPath='/loader/dc-goods/admin/cskus/queryCskuByPage'
                params={{ page: 1, perPage: 10, sourceTypeStr: '0,3' }}
                selectParamKey='keyword'
                onChange={(val: any) => {
                    props.onChange(val);
                }}
            />
            <EditableContext.Provider value={{}}>
                <Table
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={(_) => _.id}
                    pagination={false}
                />
            </EditableContext.Provider>
        </>
    );
};

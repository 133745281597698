import { get as lodashGet } from 'lodash';
import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/dc-goods/admin/goodsThirdXref/pageByChannel',
        loader: {
            get: async function(data: any, config: any) {
                const res: any = await services.api.get(data, config);
                return {
                    ...res,
                    result: res.result.map((item: any) => ({
                        ...item,
                        id: item.csku,
                        name: {
                            en: lodashGet(item, 'cskuNameMap.en'),
                            jp: lodashGet(item, 'cskuNameMap.jp'),
                            tc: lodashGet(item, 'cskuNameMap.tc'),
                            zh: lodashGet(item, 'cskuNameMap.zh'),
                            fr: lodashGet(item, 'cskuNameMap.fr'),
                            it: lodashGet(item, 'cskuNameMap.it'),
                            ko: lodashGet(item, 'cskuNameMap.ko'),
                        },
                        csku: lodashGet(item, 'csku'),
                        channelName: lodashGet(item, 'channelName'),
                        thirdSku: lodashGet(item, 'thirdSku'),
                        lastedModifyTime: lodashGet(item, 'lastModifiedTime'),
                        sourceType: lodashGet(item, 'sourceTypeName'),
                        specsType: lodashGet(item, 'specsTypeName'),
                    })),
                };
            },
        },
    },
    {
        key: '/dc-master-data/admin/distribution_channels?status=ENABLED',
        loader: {
            get: async function(data: any, config: any) {
                const res: any = await services.api.get(data, config);
                return res;
            },
        },
    },
];

import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        thirdPartySync: {
            apiPath: '/base-order/admin/third/stock/sync',
            properties: {
                channelId: {
                    type: 'string.options.select',
                    displayName: '<<thirdPartyWarehouse.channel>>',
                    options: [],
                    source: {
                        apiPath: '/base-order/admin/third/channel',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                cskuList: {
                    type: 'string.text.paragraph',
                    displayName: '<<thirdPartySync.cskuList>>',
                },
            },
        },
    },
    components: {
        ThirdPartySyncPage: {
            component: 'Card',
            entity: 'thirdPartySync',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'SuperForm',
                        mode: 'add',
                        cancelable: false,
                        backOnDone: false,
                        footerStyle: {
                            marginLeft: '0px',
                            paddingLeft: '16.6%',
                            justifyContent: 'left',
                        },
                        okText: '<<thirdPartySync.update>>',
                        successText: '<<thirdPartySync.successMsg>>',
                        labelCol: {
                            md: { span: 24 },
                            lg: { span: 4 },
                        },
                        wrapperCol: {
                            md: { span: 24 },
                            lg: { span: 8 },
                        },
                        items: [
                            {
                                renderType: 'field',
                                property: 'channelId',
                                key: 'channelId',
                                placeholder: '<<defaultPlaceholderSelect>>',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'cskuList',
                                key: 'cskuList',
                                placeholder: '<<thirdPartySync.cskuListPlaceholder>>',
                                maxLength: 5000,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                ],
                privilege: {
                    path: 'channel.channel_third_stock_sync.channel_third_stock',
                    level: 'full',
                },
            },
        },
    },
};

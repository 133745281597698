import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ImagePreview } from '@/components/image-preview';

export class StoreImageMode extends modes.ArrayImagesMode {
    public getDisplayComponent(value: any, displayInfo: any): any {
        const { imgWidth, imgHeight } = displayInfo;
        if (value) {
            const formattedValue = value.map((item: any) => item.path);
            return (
                <ImagePreview images={formattedValue} imgWidth={imgWidth} imgHeight={imgHeight} />
            );
        } else {
            return null;
        }
    }
}

import React from 'react';
import { Button, ButtonProps } from '@comall-backend-builder/components-basis/lib/button';

interface IProps extends ButtonProps {
    /**
     * 文件地址
     */
    url: string;
    children: React.ReactNode;
}

/**
 * OSS链接文件下载组件
 */
export function OSSExportButton(props: IProps) {
    const { url } = props;
    const handleClick = () => {
        window.open(`${url}`);
    };
    return <Button {...props} onClick={handleClick} />;
}

/**
 * 生成候选项
 *
 * @param values 值
 * @param names 名
 */
export function generateOptions<VS extends AnyObject, NS extends AnyObject>(
    values: VS,
    names: NS
): {
    id: VS[keyof VS];
    name: NS[VS[keyof VS]];
}[];
/**
 * 生成候选项
 *
 * @param values 值
 * @param names 名
 * @param generator 自定义生成器
 */
export function generateOptions<VS extends AnyObject, NS extends AnyObject, OP extends AnyObject>(
    values: VS,
    names: NS,
    generator: (value: VS[keyof VS], name: NS[VS[keyof VS]]) => OP
): OP[];

export function generateOptions(
    values: AnyObject,
    names: AnyObject,
    generator = (value: string, name: string) => ({
        id: value,
        name,
    })
) {
    const options = [];

    for (const key of Object.keys(values)) {
        // 取出值
        const value = values[key];
        // 用值取出名
        const name = names[value];
        // 名存在就生成选项
        if (name) {
            const option = generator(value, name);
            options.push(option);
        }
    }

    return options;
}

/*
 * @Author: zhulu
 * @Date: 2023-02-03 10:26:46
 * @Description: 动态增减数组长度
 */
/*eslint-disable react-hooks/exhaustive-deps */
import { ComponentMetadata } from '@comall-backend-builder/core/lib/parser';
import { uuid } from '@comall-backend-builder/core/lib/services';
import { modes } from '@comall-backend-builder/types';
import { cloneDeep, findIndex, isEmpty, isFunction } from 'lodash';
import React, { useMemo, useCallback, useEffect } from 'react';
import SelectAutoComplete from '@/components/select-auto-complete';
import { Button, Input } from 'antd';
import { services } from '@comall-backend-builder/core';

interface DynamicFieldSetProps<T> {
    /**
     * 输入组件的 value
     */
    value: T[];
    /**
     * 输入组件的 name
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: T[], name: string) => void;
    /** 子项渲染 */
    itemConfig: ComponentMetadata;
    /** 最大项 */
    maxLimit: number;
}
const DynamicFieldSet = (props: DynamicFieldSetProps<any>) => {
    const { itemConfig, value = [], onChange, name, maxLimit = 10 } = props;
    useEffect(() => {
        if (value.length === 0) {
            onChange([{ __uuid: uuid() }], name);
        }
    }, []);
    /** 为value包装id */
    const formatValue = useMemo(() => {
        let _value = [];
        _value = value.map((item) => {
            if (!item) {
                return { __uuid: uuid() };
            } else {
                return { ...item, __uuid: item.__uuid || uuid() };
            }
        });
        return _value;
    }, [value]);
    /** 删除项 */
    const onRemove = useCallback(
        (item: any) => {
            if (formatValue.length <= 1) {
                return;
            }

            const index = findIndex(formatValue, { __uuid: item.__uuid });
            let nValue = cloneDeep(value);
            nValue.splice(index, 1);
            onChange(nValue, name);
        },
        [value]
    );
    /** 新增项 */
    const onAdd = useCallback(() => {
        if (formatValue.length === maxLimit) {
            return;
        }
        let nValue = cloneDeep(formatValue);
        onChange([...nValue, { __uuid: uuid() }], name);
    }, [formatValue]);

    const onItemChange = useCallback(
        (_value) => {
            const index = findIndex(formatValue, { __uuid: _value.__uuid });
            let nValue = cloneDeep(formatValue);
            nValue[index] = _value;
            onChange(nValue, name);
        },
        [formatValue]
    );

    const renderItem = (item: any) => {
        let complementProps = {
            placeholder: '',
            ...itemConfig,
            name: `${name}${item.__uuid}`,
            onChange: (val: any) => onItemChange({ ...item, ...val }),
            key: item.__uuid,
        };
        const _value = cloneDeep(item);
        delete _value.__uuid;

        if (itemConfig.formatProps && isFunction(itemConfig.formatProps)) {
            complementProps = {
                ...complementProps,
                ...itemConfig.formatProps(props),
            };
        }
        switch (itemConfig.type) {
            case 'select':
                return (
                    <SelectAutoComplete
                        {...complementProps}
                        value={isEmpty(_value) ? null : _value}
                        placeholder={
                            complementProps?.placeholder ||
                            services.language.getText('common.placeSelect')
                        }
                    />
                );
            default:
                return (
                    <Input
                        {...complementProps}
                        value={_value}
                        placeholder={
                            complementProps?.placeholder ||
                            services.language.getText('common.placeInput')
                        }
                    />
                );
        }
    };
    return (
        <div>
            {formatValue.map((item) => {
                return (
                    <div
                        key={item.__uuid}
                        style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}
                    >
                        {renderItem(item)}

                        {formatValue.length > 1 && (
                            <Button onClick={() => onRemove(item)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        )}
                    </div>
                );
            })}
            {formatValue.length < maxLimit && (
                <Button onClick={onAdd}>{services.language.getText('add')}</Button>
            )}
        </div>
    );
};
export class DynamicFieldArrayMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
        };
        return <DynamicFieldSet {...props} />;
    }
}

/*
 * @Author: zhulu
 * @Date: 2022-04-20 09:41:52
 * @Description:
 */
import * as React from 'react';
import { modes, formats } from '@comall-backend-builder/types';
import { get } from 'lodash';
import { AsyncTreeSelect } from '@/components/async-tree-select';

export class ObjectAsyncTreeSelectMode extends modes.ObjectTreeNodeTreeMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        console.info('controlInfo', controlInfo);
        return (
            <AsyncTreeSelect
                {...controlInfo}
                {...get(controlInfo, `entity.metainfo.properties.${controlInfo.name}`, {})}
            />
        );
    }
    /**
     *
     */
}

export class ObjectAsyncTreeSelectFormat extends formats.ObjectFormat {
    /**
     * 对数据进行校验
     */
    validate(rule: any, value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any) {
        const result = {
            id: get(value, 'value', value.id),
            name: get(value, 'label', value.name),
        };
        return { [key]: result };
    }
}

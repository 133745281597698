import React, { Component } from 'react';
import { TimePicker as AntTimePicker, Select as AntSelect } from 'antd';
import moment from 'moment';
import { cloneDeep, range } from 'lodash';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { Weeks } from '../../interfaces';
const AntOption = AntSelect.Option;

interface TimeRangesPickerProps {
    ranges: Array<any>;
    format: string;
    name: string;
    maxRangesCount: number;
    minuteStep: number;
    allowEmpty: boolean;
    style: any;
    onChange(key: any, name: string): void;
    defaultStart: string;
    defaultEnd: string;
    value: any;
}
interface RangesProps {
    startWeek: keyof typeof Weeks;
    endWeek: keyof typeof Weeks;
    startTime: string;
    endTime: string;
}
/*
 * value格式：[
 *   {
 *       startTime: '',
 *       endTime: ''
 *   }
 * ]
 * */
export class MacauTimeRangesPicker extends Component<TimeRangesPickerProps, any> {
    ranges: Array<RangesProps> = [];
    defaultStartWeek: keyof typeof Weeks = 'MONDAY';
    defaultEndWeek: keyof typeof Weeks = 'MONDAY';
    defaultStart = '00:00';
    defaultEnd = '22:30';
    format: string = this.props.format;
    weeksList: Array<any> = [];
    constructor(props: TimeRangesPickerProps) {
        super(props);
        let week: keyof typeof Weeks;
        for (week in Weeks) {
            this.weeksList.push({
                id: week,
                name: services.language.getText(Weeks[week]),
            });
        }
        this.addPicker = this.addPicker.bind(this);
    }
    addPicker() {
        const { onChange, name, maxRangesCount } = this.props;
        if (this.ranges.length < maxRangesCount) {
            this.ranges.push({
                startWeek: this.defaultStartWeek,
                endWeek: this.defaultEndWeek,
                startTime: this.defaultStart,
                endTime: this.defaultEnd,
            });
            onChange(this.ranges, name);
        }
    }
    deletePicker(index: number) {
        const { onChange, name } = this.props;
        this.ranges.splice(index, 1);
        onChange(this.ranges, name);
    }
    onTimeChange(status: any, index: number, time: any, timeString: any) {
        const { onChange, name, value } = this.props;
        if (status === 'start') {
            if (timeString === '') {
                this.ranges[index].startTime = value[0].startTime;
            } else {
                this.ranges[index].startTime = timeString;
            }
            onChange(this.ranges, name);
        } else if (status === 'end') {
            if (timeString === '') {
                this.ranges[index].endTime = value[0].endTime;
            } else {
                this.ranges[index].endTime = timeString;
            }
            onChange(this.ranges, name);
        }
    }
    changeWeeks(status: any, index: number, timeString: any) {
        const { onChange, name } = this.props;
        if (status === 'startWeek') {
            this.ranges[index].startWeek = timeString;
            onChange(this.ranges, name);
        } else if (status === 'endWeek') {
            this.ranges[index].endWeek = timeString;
            onChange(this.ranges, name);
        }
    }
    startDisabledHours(index: number) {
        return range(moment(this.ranges[index].endTime, this.format).hours() + 1, 24);
    }
    endDisabledHours(index: number) {
        return range(0, moment(this.ranges[index].startTime, this.format).hours());
    }
    startDisabledMinutes(index: number, selectedHour: any) {
        const end = moment(this.ranges[index].endTime, this.format);
        return range(selectedHour === end.hours() ? end.minutes() + 1 : 60, 60);
    }
    endDisabledMinutes(index: number, selectedHour: any) {
        const start = moment(this.ranges[index].startTime, this.format);
        return range(0, selectedHour === start.hours() ? start.minutes() : 0);
    }

    render() {
        const { value, maxRangesCount, format } = this.props;
        if (value) {
            this.ranges = cloneDeep(value);
        }
        const props = {
            format,
            minuteStep: 30,
            allowEmpty: false,
            style: {
                width: '90px',
            },
        };
        return (
            <div>
                {this.ranges.map((item, index) => (
                    <div key={index}>
                        <AntSelect
                            value={services.language.getText(Weeks[item.startWeek])}
                            style={{ width: '90px' }}
                            onChange={this.changeWeeks.bind(this, 'startWeek', index)}
                        >
                            {this.weeksList.map((item, index) => (
                                <AntOption key={index} value={item.id}>
                                    {item.name}
                                </AntOption>
                            ))}
                        </AntSelect>
                        <span style={{ margin: '0 10px' }}>至</span>
                        <AntSelect
                            value={services.language.getText(Weeks[item.endWeek])}
                            style={{ width: '90px' }}
                            onChange={this.changeWeeks.bind(this, 'endWeek', index)}
                        >
                            {this.weeksList.map((item, index) => (
                                <AntOption key={index} value={item.id}>
                                    {item.name}
                                </AntOption>
                            ))}
                        </AntSelect>
                        <span style={{ margin: '0 10px' }}></span>
                        <AntTimePicker
                            {...props}
                            value={moment(item.startTime, format)}
                            onChange={this.onTimeChange.bind(this, 'start', index)}
                            disabledHours={this.startDisabledHours.bind(this, index)}
                            disabledMinutes={this.startDisabledMinutes.bind(this, index)}
                        />
                        <span style={{ margin: '0 10px' }}>至</span>
                        <AntTimePicker
                            {...props}
                            value={moment(item.endTime, format)}
                            onChange={this.onTimeChange.bind(this, 'end', index)}
                            disabledHours={this.endDisabledHours.bind(this, index)}
                            disabledMinutes={this.endDisabledMinutes.bind(this, index)}
                        />
                        <span style={{ margin: '0 10px' }}>
                            {index + 1 < maxRangesCount && index + 1 === this.ranges.length ? (
                                <Button
                                    text={services.language.getText('add')}
                                    type='link'
                                    onClick={this.addPicker}
                                />
                            ) : null}
                            {index > 0 &&
                            index + 1 < maxRangesCount &&
                            index + 1 === this.ranges.length ? (
                                <span style={{ color: '#1890ff', margin: '0 5px' }}>|</span>
                            ) : null}
                            {index > 0 ? (
                                <Button
                                    text={services.language.getText('delete')}
                                    type='link'
                                    onClick={this.deletePicker.bind(this, index)}
                                />
                            ) : null}
                        </span>
                    </div>
                ))}
            </div>
        );
    }
}

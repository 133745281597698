import React, { useState, isValidElement, createElement } from 'react';
import { connect } from 'react-redux';
import { trim } from 'lodash';
import {
    Card as AntCard,
    Row as AntRow,
    Col as AntCol,
    Modal as AntModal,
    Input as AntInput,
    message as AntMessage,
    Descriptions,
} from 'antd';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { Button } from '@comall-backend-builder/components-basis';
import { Countdown } from '@/components/countdown';
import { Privilege } from '@/components/privilege';
import { DELIVERY_TYPE_NAMES, DELIVERY_TYPE_VALUES } from '@/constants/order';
import {
    AFTER_SALE_TYPE_NAMES,
    AFTER_SALE_STATUS_NAMES,
    AFTER_SALE_STATUS_VALUES,
    AFTER_SALE_TYPE_VALUES,
} from '@/constants/after-sale';
import { checkPrivilege } from '@/components/privilege';

const AfterSaleBasic: React.FC<any> = (props) => {
    const { id, aftermarketListVo } = props;
    const [showRemarkModal, setRemarkModal] = useState(false);
    const [remarkValue, setRemarkValue] = useState('');
    const [loading, setLoading] = useState(false);

    const remarkButton = () => {
        const handleOk = () => {
            if (!remarkValue) {
                AntMessage.error(services.language.getText('order.orderRemarkTips'));
                return;
            }
            setLoading(true);
            services.api
                .post(
                    {
                        content: trim(remarkValue),
                        aftermarketId: aftermarketListVo.aftermarketId,
                    },
                    {
                        apiPath: '/base-order/admin/aftermarket/remark',
                    }
                )
                .then(() => {
                    AntMessage.success(services.language.getText('order.orderRemarkTipsSucc'));
                    props.entity.get(props.params);
                })
                .catch(services.errorHandle)
                .finally(() => {
                    setRemarkModal(false);
                    setLoading(false);
                    setRemarkValue('');
                });
        };

        return (
            <div className='remark-button-row'>
                <Privilege path='aftermarket.after_sale_view.add_remark'>
                    {(hasPrivilege) => {
                        if (hasPrivilege) {
                            return (
                                <>
                                    <Button
                                        onClick={() => {
                                            setRemarkModal(true);
                                        }}
                                    >
                                        {services.language.getText('order.orderRemarkTitle')}
                                    </Button>
                                    <AntModal
                                        title={services.language.getText('order.orderRemarkTitle')}
                                        visible={showRemarkModal}
                                        onOk={handleOk}
                                        onCancel={() => {
                                            setRemarkModal(false);
                                        }}
                                        confirmLoading={loading}
                                    >
                                        <AntInput.TextArea
                                            rows={4}
                                            maxLength={200}
                                            value={remarkValue}
                                            onChange={(e) => {
                                                setRemarkValue(e.target.value);
                                            }}
                                            placeholder={services.language.getText(
                                                'order.orderRemarkPlaceholder'
                                            )}
                                        />
                                    </AntModal>
                                </>
                            );
                        } else {
                            return <></>;
                        }
                    }}
                </Privilege>
            </div>
        );
    };

    /**
     * 审核通过按钮
     */
    const approveBtn = (props: any) => {
        const { aftermarketListVo, entity } = props;
        if (!checkPrivilege('aftermarket.after_sale_view.approval_pass')) {
            return null;
        }
        return {
            component: 'Button',
            buttonType: 'completePackButton',
            config: {
                type: 'primary',
                text: services.language.getText('afterSale.statusMap.approved'),
                style: {
                    marginLeft: 15,
                },
                onClick: () => {
                    AntModal.confirm({
                        title: services.language.getText('afterSale.tips.approve'),
                        onOk: () => {
                            services.api
                                .get(
                                    { aftermarketId: aftermarketListVo.aftermarketId },
                                    { apiPath: `/base-order/admin/aftermarket/pass` }
                                )
                                .then(() => {
                                    entity.get(props.params);
                                })
                                .catch(errorHandle);
                        },
                    });
                },
            },
        };
    };

    /**
     * 审核不通过按钮
     */
    const rejectBtn = (props: any) => {
        const { aftermarketListVo, entity } = props;
        if (!checkPrivilege('aftermarket.after_sale_view.approval_fail')) {
            return null;
        }
        return {
            component: 'Button',
            buttonType: 'completePackButton',
            config: {
                type: 'primary',
                text: services.language.getText('afterSale.statusMap.rejected'),
                style: {
                    marginLeft: 15,
                },
                onClick: () => {
                    AntModal.confirm({
                        title: services.language.getText('afterSale.tips.reject'),
                        onOk: () => {
                            services.api
                                .get(
                                    { aftermarketId: aftermarketListVo.aftermarketId },
                                    { apiPath: `/base-order/admin/aftermarket/fail` }
                                )
                                .then(() => {
                                    entity.get(props.params);
                                })
                                .catch(errorHandle);
                        },
                    });
                },
            },
        };
    };

    /**
     * 处理完成按钮
     */
    const completeBtn = (props: any) => {
        const { aftermarketListVo, entity } = props;
        if (!checkPrivilege('aftermarket.after_sale_view.approval_success')) {
            return null;
        }
        return {
            component: 'Button',
            buttonType: 'completePackButton',
            config: {
                type: 'primary',
                text: services.language.getText('afterSale.statusMap.complete'),
                style: {
                    marginLeft: 15,
                },
                onClick: () => {
                    AntModal.confirm({
                        title: services.language.getText('afterSale.tips.complete'),
                        onOk: () => {
                            services.api
                                .get(
                                    { aftermarketId: aftermarketListVo.aftermarketId },
                                    { apiPath: `/base-order/admin/aftermarket/success` }
                                )
                                .then(() => {
                                    entity.get(props.params);
                                })
                                .catch(errorHandle);
                        },
                    });
                },
            },
        };
    };
    /**
     * 显示按钮映射
     */
    const showBtnMap: { [name: string]: (data: any) => any } = {
        cancelTheOrder: approveBtn,
        packLackOrder: rejectBtn,
        deliverTheOrder: completeBtn,
    };

    return (
        <AntCard
            className='after-sale-info-basic'
            bordered={false}
            loading={!id}
            // extra={getExtra()}
            style={props.style}
        >
            <AntRow>
                <AntCol span={2}>
                    <Countdown
                        title={
                            AFTER_SALE_STATUS_NAMES[
                                aftermarketListVo?.aftermarketStatus as AFTER_SALE_STATUS_VALUES
                            ]
                        }
                        color={
                            aftermarketListVo?.status === AFTER_SALE_STATUS_NAMES.APPROVAL_FAIL
                                ? 'transparent'
                                : 'green'
                        }
                    />
                </AntCol>
                <AntCol span={12} className='order-detail'>
                    <Descriptions column={1} size='small'>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('afterSale.afterSaleNum')}
                        >
                            {aftermarketListVo?.aftermarketNumber ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('afterSale.parentOrderNumber')}
                        >
                            {aftermarketListVo?.parentOrderNumber ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('afterSale.orderNumber')}
                        >
                            <Privilege path='order.order.view_detail'>
                                {(hasPrivilege) => {
                                    if (hasPrivilege) {
                                        return (
                                            <a
                                                href={`#/orders/info/${aftermarketListVo?.outerOrderNumber}`}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                {aftermarketListVo?.outerOrderNumber}
                                            </a>
                                        );
                                    } else {
                                        return <span>{aftermarketListVo?.outerOrderNumber}</span>;
                                    }
                                }}
                            </Privilege>
                        </Descriptions.Item>
                        <Descriptions.Item label={services.language.getText('afterSale.customer')}>
                            {/* {getOrderMember() ?? '-'} */}
                            {aftermarketListVo?.orderCustomer ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={services.language.getText('afterSale.createTime')}
                        >
                            {aftermarketListVo?.createTime ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('merchant')}
                        >
                            {aftermarketListVo?.merchantName ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('deliveryType.title')}
                        >
                            {DELIVERY_TYPE_NAMES[
                                aftermarketListVo?.deliveryFlag as DELIVERY_TYPE_VALUES
                            ] ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            className='order-detail-title'
                            label={services.language.getText('afterSale.receiveAddress')}
                        >
                            {aftermarketListVo?.receiveAddress ?? '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={services.language.getText('afterSale.afterSaleType.title')}
                        >
                            {
                                AFTER_SALE_TYPE_NAMES[
                                    aftermarketListVo?.returnGoodsSign as AFTER_SALE_TYPE_VALUES
                                ]
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label={services.language.getText('afterSale.reviewer')}>
                            {aftermarketListVo?.approvalUser ?? '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </AntCol>
                <AntCol span={10} style={{ textAlign: 'right' }}>
                    {remarkButton()}
                    {Object.keys(showBtnMap).map((key) => {
                        if (aftermarketListVo?.showButtons[key]) {
                            const configOrElement = showBtnMap[key](props);
                            if (!configOrElement) return null;
                            // React 组件
                            if (isValidElement(configOrElement)) return configOrElement;
                            // 配置组件
                            return createElement(
                                ComponentsManager.get(configOrElement.component),
                                Object.assign({}, { key }, configOrElement.config)
                            );
                        }
                        return null;
                    })}
                </AntCol>
            </AntRow>
        </AntCard>
    );
};

const mapStateToProps = (state: any, ownProps: any) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const AfterSaleInfoBasic = connect(mapStateToProps, null)(AfterSaleBasic);

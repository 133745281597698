import { modes } from '@comall-backend-builder/types';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, isArray, some, find, map } from 'lodash';
import {
    default as AntSelect,
    SelectProps as AntSelectProps,
    SelectValue as AntSelectValue,
} from 'antd/lib/select';

import {
    basicPropTypes,
    controlPropTypes,
    CustomStyleComponentProps,
} from '@comall-backend-builder/components-basis/lib/component-props';
import { services } from '@comall-backend-builder/core';

export class StringOptionAutoCompleteMode extends modes.StringOptionSelectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <Select {...controlInfo} />;
    }
}

export interface Option {
    /**
     * 候选项id
     */
    id: string;
    /**
     * 候选项显示名称
     */
    name: string;
}

export interface SelectProps
    extends Pick<
            AntSelectProps,
            | 'mode'
            | 'value'
            | 'placeholder'
            | 'disabled'
            | 'allowClear'
            | 'showSearch'
            | 'optionFilterProp'
            | 'onSelect'
            | 'onDeselect'
        >,
        CustomStyleComponentProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: AntSelectValue, name: string) => void;

    /**
     * 候选项集合
     */
    options: Array<Option>;

    /**
     * 默认选中项索引
     */
    defaultValueIndex?: number;
}

export class Select extends Component<SelectProps> {
    static propTypes = {
        ...basicPropTypes,
        ...controlPropTypes,

        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),

        // 待选项
        options: PropTypes.array.isRequired,
    };

    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value: any) {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(value, name);
        }
    }

    componentWillMount() {
        const { name, value, options, onChange, defaultValueIndex } = this.props;
        if (defaultValueIndex !== undefined && !value && defaultValueIndex < options.length) {
            onChange(options[defaultValueIndex].id, name);
        }
    }

    shouldComponentUpdate(nextProps: any) {
        return !isEqual(this.props, nextProps);
    }

    componentWillReceiveProps(nextProps: any) {
        const { name, value, options, onChange, defaultValueIndex } = nextProps;

        if (value && options.length) {
            if (isArray(value)) {
                if (some(value, (id) => !find(options, { id }))) {
                    onChange([], name);
                }
            } else {
                if (!some(options, (option) => option.id + '' === value + '')) {
                    onChange(undefined, name);
                }
            }
        }

        if (defaultValueIndex !== undefined && !value && !isEqual(this.props.options, options)) {
            onChange(options[defaultValueIndex].id, name);
        }
    }

    render() {
        const {
            className,
            style,
            mode,
            name,
            value,
            placeholder = services.language.getText('common.pleaseSelect'),
            disabled,
            options,
            allowClear,
            showSearch,
            optionFilterProp,
            onSelect,
            onDeselect,
        } = this.props;

        const selectProps = {
            // basicPropTypes
            className,
            style,

            // controlPropTypes
            mode,
            name,
            placeholder,
            disabled,
            showSearch,
            allowClear,
            optionFilterProp,
            onChange: this.handleChange,
            onSelect,
            onDeselect,
            value,
        };

        if (value !== undefined && value !== null) {
            selectProps.value = isArray(value) ? value : value + '';
        } else {
            selectProps.value = undefined;
        }

        const children = map(options, (option) => (
            <AntSelect.Option key={option.id} label={option.name} value={option.id}>
                {option.name}
            </AntSelect.Option>
        ));

        return <AntSelect {...selectProps}>{children}</AntSelect>;
    }
}

import { formatProductReportParams } from '@/services';
import { services } from '@comall-backend-builder/core/lib';
import { get as lodashGet } from 'lodash';

const formatBrandParams = (data: any) => {
    if (!data) return data;
    if (data.name) {
        data.brandName = data.name;
    }
    delete data.name;
    return data;
};

export default [
    {
        //已停用列表
        key: '/dc-goods/admin/spus/blockup',
        loader: {
            get: async function(data: any, config: any) {
                const params = formatProductReportParams(data);
                const response: any = await services.api.get({ ...params }, config);
                response.result = lodashGet(response, 'result', []).map((item: any) => {
                    return { ...item, status: '已停用' };
                });
                return response;
            },
        },
    },
    {
        //已停用分类统计
        key: '/dc-goods/admin/spus/blockup/findCountByCategory',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(data, config);
                return (result || []).map((item: any, index: number) => {
                    return {
                        ...item,
                        id: `${item.departmentGroupDescription}-${index}`,
                        name: item.departmentGroupDescription,
                    };
                });
            },
        },
    },
    {
        //已停用地区统计
        key: '/dc-goods/admin/spus/blockup/findCountByDivision',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(data, config);
                return (result || []).map((item: any, index: number) => {
                    return {
                        ...item,
                        id: `${item.divisionNumbers}-${index}`,
                        name: item.divisionNumbers,
                    };
                });
            },
        },
    },
    {
        //已停用品牌
        key: '/dc-goods/admin/spus/blockup/findGroupByBrandName',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(formatBrandParams(data), config);
                return (result || []).map((item: any, index: number) => {
                    return { ...item, id: `${item.brandName}-${index}`, name: item.brandName };
                });
            },
        },
    },
    {
        //待完善列表
        key: '/dc-goods/admin/spus/imperfect',
        loader: {
            get: async function(data: any, config: any) {
                const params = formatProductReportParams(data);
                const response: any = await services.api.get({ ...params }, config);
                response.result = lodashGet(response, 'result', []).map((item: any) => {
                    return { ...item, status: '待完善' };
                });
                return response;
            },
        },
    },
    {
        //待完善分类统计
        key: '/dc-goods/admin/spus/imperfect/findCountByCategory',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(data, config);
                return (result || []).map((item: any, index: number) => {
                    return { ...item, id: `${item.category}-${index}`, name: item.category };
                });
            },
        },
    },
    {
        //待完善地区统计
        key: '/dc-goods/admin/spus/imperfect/findCountByDivision',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(data, config);
                return (result || []).map((item: any, index: number) => {
                    return {
                        ...item,
                        id: `${item.divisionNumbers}-${index}`,
                        name: item.divisionNumbers,
                    };
                });
            },
        },
    },
    {
        //待完善品牌
        key: '/dc-goods/admin/spus/imperfect/findGroupByBrandName',
        loader: {
            get: async function(data: any, config: any) {
                const result: any = await services.api.get(formatBrandParams(data), config);
                return (result || []).map((item: any, index: number) => {
                    return {
                        ...item,
                        id: `${item.brandName}-${index}`,
                        name: item.brandName,
                    };
                });
            },
        },
    },
];

import { get } from 'lodash';
import { formats } from '@comall-backend-builder/types';

export class StringImagePickerFormat extends formats.StringImagePickerFormat {
    public formatParams(key: any, value: any) {
        let path = '';
        if (value && typeof value === 'string') {
            path = value;
        } else if (Array.isArray(value)) {
            path = get(value, '[0].path') || get(value, '[0].url');
        }
        return { [key]: path };
    }
}

import React, { Component } from 'react';
import { get, last, isEmpty, forEach } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Form, Modal, Cascader } from 'antd';
const { api, errorHandle, language, interpolate } = services;

interface IProps {
    className: string;
    text: string;
    style: any;
    row: any;
    apiPath: string;
    entity: Entity;
    params: any;
    form: any;
}

interface IState {
    visible: boolean;
    value: null | Array<any>;
    options: Array<any>;
    submitLoading: boolean;
}

const CATEGORY_TREE_API = '/guide-cms-service/admin/virtual_categorys/categorys_tree';
const SAVE_CATEGORY_API = '/dc-cms-api/admin/guess_like/{{id}}?id={{categoryId}}';

function fetchCategory(parentId = '1', onlineStoreId = ''): Promise<Array<any>> {
    return new Promise((resolve) => {
        api.get(
            {
                parentId,
                onlineStoreId: onlineStoreId,
            },
            {
                apiPath: CATEGORY_TREE_API,
            }
        )
            .then((data: any) => {
                forEach(data, (item) => {
                    item.isLeaf = item.hasChild === 1 ? false : true;
                });
                resolve(data);
            })
            .catch(errorHandle);
    });
}

function formateCategoryValue(value: any) {
    if (!value) return null;
    if (Array.isArray(value)) {
        return value.filter((item) => item.id !== '1'); // 过滤根分类
    }
    return [value];
}

function getInitvalue(value: any) {
    const category = formateCategoryValue(value);
    const initValue = category ? category.map((item) => item.id) : undefined;
    return initValue;
}

/**
 * 猜你喜欢商品设置虚拟分类
 */
export class TableActionGuessYouLikeC extends Component<IProps, IState> {
    state = {
        visible: false,
        value: null,
        options: [],
        submitLoading: false,
    };

    static getDerivedStateFromProps(nextProps: IProps, state: IState) {
        const category = get(nextProps, 'row.category');
        if (!state.value) {
            return {
                value: formateCategoryValue(category),
            };
        }
        return null;
    }

    clearCategory = () => {
        const { row } = this.props;
        const initValue = getInitvalue(row.category);
        this.props.form.setFieldsValue({
            category: initValue,
        });
    };

    submit = async (categoryId: any) => {
        const { row, entity } = this.props;
        try {
            this.setState({
                submitLoading: true,
            });
            await api.put(
                {},
                { apiPath: interpolate(SAVE_CATEGORY_API, { id: row.id, categoryId }) }
            );
            entity.search(entity.params);
            this.toggleModal(false);
        } catch (e) {
            //@ts-ignore
            errorHandle(e);
        } finally {
            this.setState({
                submitLoading: false,
            });
        }
    };

    toggleModal = (visible: boolean) => {
        this.setState({
            visible,
        });
    };

    handleClick = () => {
        this.toggleModal(true);
        fetchCategory('1', get(this.props, 'row.onlineStoreId')).then((data) => {
            this.setState({
                options: data,
            });
        });
    };

    handleCancel = () => {
        this.toggleModal(false);
    };

    handleOk = () => {
        const { value } = this.state;
        const category = this.props.form.getFieldValue('category');
        // 未选择
        // if (isEmpty(category) && isEmpty(value)) {
        //     message.error(language.getText('search.guessYouLikeSettings.pleaseSelect'));
        //     return;
        // }
        // 未更新
        if (isEmpty(category) && !isEmpty(value)) {
            this.toggleModal(false);
            return;
        }
        this.submit(last(category) || '0');
    };

    handleChange = (value: any, selectedOptions: any) => {
        this.setState({
            value: selectedOptions,
        });
        this.props.form.setFieldsValue({
            category: value,
        });
    };

    onLoadData = async (selectedOptions: any) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];

        try {
            targetOption.loading = true;

            const data = await fetchCategory(targetOption.id, get(this.props, 'row.onlineStoreId'));
            targetOption.loading = false;
            targetOption.children = data;
            this.setState({
                options: [...this.state.options],
            });
        } catch (e) {
            //@ts-ignore
            errorHandle(e);
        }
    };

    displayRender = (label: any, selectedOptions: any) => {
        //@ts-ignore
        return (this.state.value || []).map((item) => item.name).join('/');
    };

    render() {
        const { className, style, text, form } = this.props;
        const { visible, options, submitLoading, value } = this.state;
        const { getFieldDecorator } = form;

        const cascaderProps = {
            options,
            allowClear: true,
            fieldNames: { label: 'name', value: 'id', children: 'children' },
            // placeholder: row.category || value ? ' ' : language.getText('common.defaultPlaceholderSelect'),
            placeholder: value ? ' ' : language.getText('defaultPlaceholderSelect'),
            loadData: this.onLoadData,
            onChange: this.handleChange,
            displayRender: this.displayRender,
        };

        return (
            <>
                <Button className={className} style={style} type='link' onClick={this.handleClick}>
                    {text}
                </Button>
                <Modal
                    title={language.getText('designManager.guessYouLikeSetting.setting')}
                    visible={visible}
                    confirmLoading={submitLoading}
                    maskClosable={false}
                    afterClose={this.clearCategory}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    okText={language.getText('common.submit')}
                >
                    <Form layout='horizontal' labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                        <Form.Item
                            label={language.getText(
                                'designManager.guessYouLikeSetting.virtualCategory'
                            )}
                        >
                            {getFieldDecorator('category')(<Cascader {...cascaderProps} />)}
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export const TableActionGuessYouLike = Form.create({ name: 'guess-you-like-modal-form' })(
    TableActionGuessYouLikeC
);

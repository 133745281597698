import React, { createElement } from 'react';
import { Modal as AntModal } from 'antd';
import { get, isEmpty } from 'lodash';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { checkPrivilege } from '@/components/privilege';
import { OfflineOrderListItem } from '@/components/offline-order-list-item';

import { OFFLINE_ORDER_STATUS_VALUES, OFFLINE_ORDER_STATUS_NAMES } from '@/constants';
import { PrintOrderButton } from '@/components/print-order-button';

/**
 * 取消订单按钮
 */
const cancelTheOrderBtn = (props: any) => {
    return {
        component: 'CancelOfflineOrderModal',
        config: props,
    };
};

/**
 * 添加付款信息
 */
const addPaymentInfoBtn = (props: any) => {
    const { id, outerOrderNumber, parentProps, payableAmount } = props;
    if (!checkPrivilege('offline.offline_order.offline_order_add_payment_record')) {
        return null;
    }

    return {
        component: 'AddPaymentInfoModal',
        config: {
            id,
            outerOrderNumber,
            parentProps,
            payableAmount,
        },
    };
};

/**
 * 已收款按钮
 */
const collectedBtn = (props: any) => {
    const { id, parentProps, payableAmount, concludeAmount, paidPayAmount } = props;
    if (!checkPrivilege('offline.offline_order.offline_order_paid')) {
        return null;
    }

    const comparedPayAmount = () => {
        if (payableAmount && paidPayAmount) {
            const formartA = payableAmount.substring(1);
            const formartB = paidPayAmount.substring(1);
            return Number(formartA) > Number(formartB)
                ? language.getText('offlineOrder.tipMsg')
                : '';
        }
        return '';
    };
    return {
        component: 'Button',
        config: {
            type: 'primary',
            text: language.getText('offlineOrder.collected'),
            style: {
                marginRight: 15,
            },
            onClick: () => {
                const { entity } = parentProps;
                AntModal.confirm({
                    title: language.getText('offlineOrder.confirmCollected'),
                    content: (
                        <>
                            <div>
                                {language.getText('order.dealAmount')}:{' '}
                                <span>{concludeAmount ?? '-'}</span>
                            </div>
                            <div>
                                {language.getText('offlineOrder.waitPayAccount')}:{' '}
                                <span>{payableAmount ?? '-'}</span>
                            </div>
                            <div>
                                {language.getText('offlineOrder.actualPayAccount')}:{' '}
                                <span
                                    style={{
                                        color: payableAmount !== paidPayAmount ? 'red' : 'inherit',
                                    }}
                                >
                                    {paidPayAmount ?? '-'}
                                </span>
                            </div>
                            <div>{comparedPayAmount()}</div>
                        </>
                    ),
                    onOk() {
                        return services.api
                            .put({}, { apiPath: `/base-order/admin/order/${id}/offline_paid` })
                            .then(() => {
                                entity.search(entity.params);
                                parentProps.fetchStatistics();
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 发送短信按钮
 */
const offlineSendPickupMessage = (props: any) => {
    const { id, parentProps } = props;
    if (!checkPrivilege('offline.offline_order.offline_send_sms')) {
        return null;
    }
    return {
        component: 'Button',
        config: {
            text: language.getText('offlineOrder.sendMessage.button'),
            style: {
                marginRight: 15,
            },
            onClick: () => {
                const { entity } = parentProps;
                AntModal.warning({
                    title: language.getText('offlineOrder.sendMessage.title'),
                    okText: language.getText('common.ok'),
                    cancelText: language.getText('common.cancel'),
                    okCancel: true,
                    onOk() {
                        return services.api
                            .put({}, { apiPath: `/base-order/admin/order/sendPickUpMessage/${id}` })
                            .then(() => {
                                entity.search(entity.params);
                                parentProps.fetchStatistics();
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 确认完成按钮
 */
const confirmCompletedBtn = (props: any) => {
    const { id, parentProps } = props;
    if (!checkPrivilege('offline.offline_order.offline_order_completed')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'stockOutDeliveryButton',
        config: {
            type: 'primary',
            text: language.getText('offlineOrder.confirmCompleted'),
            style: {
                marginRight: 15,
            },
            onClick: () => {
                const { entity } = parentProps;
                AntModal.confirm({
                    title: language.getText('order.confirmReceipt.title'),
                    content: language.getText('order.confirmReceipt.content'),
                    onOk() {
                        return services.api
                            .put({}, { apiPath: `/base-order/admin/order/${id}/offline_completed` })
                            .then(() => {
                                entity.search(entity.params);
                                parentProps.fetchStatistics();
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 退货退款信息
 */
const refundAndReturnBtn = (props: any) => {
    const { id, parentProps } = props;
    if (!checkPrivilege('offline.offline_order.offline_order_return_and_refund')) {
        return null;
    }

    return {
        component: 'RefundAndReturnModal',
        config: {
            id,
            parentProps,
        },
    };
};

/**
 * 显示按钮映射
 */
const showBtnMap: AnyObject = {
    offlineCancelOrder: cancelTheOrderBtn,
    offlineSendPickupMessage: offlineSendPickupMessage,
    offlineAddPaymentRecord: addPaymentInfoBtn,
    offlinePayOrder: collectedBtn,
    offlineCompleteOrder: confirmCompletedBtn,
    offlineReturnAndRefundOrder: refundAndReturnBtn,
    printOrder: {
        component: PrintOrderButton,
    },
};

export const OfflineOrderDataListItem: React.FC<any> = (props) => {
    const getItemHeader = () => {
        const {
            merchantName,
            pickUpInfo = {},
            partner,
            taxFlag,
            payBeforeTime,
            saDepositFlag,
            deliveryType,
            memberLabel,
        } = props;
        return {
            partner: partner,
            merchantName,
            memberLabel,
            deliveryType,
            waitDeliveryTime: get(pickUpInfo, 'preDeliveryTime', '-'),
            preCanPickUpTime: get(pickUpInfo, 'preCanPickUpTime'),
            taxFlag,
            payBeforeTime,
            saDepositFlag,
        };
    };

    const getItemContent = () => {
        const {
            orderStatus,
            displayQuantity,
            displayAmount,
            dealAmount,
            id,
            posOrderNumber,
            txnOrderNumber,
            storeInfo = {},
            stockInfo = {},
            pickedAmount,
            exceptions,
            saInfo,
            orderStore,
            createTime,
            transportation,
            frightNo,
            departureDate,
            depositProportion,
            remainAmount,
            taxFlag,
            parentOrderNumber,
            depositAmount,
            touchPoint,
            channel,
            orderCustomer,
            receiveAddress,
            ...rest
        } = props;

        const status = {
            title: OFFLINE_ORDER_STATUS_NAMES[orderStatus as OFFLINE_ORDER_STATUS_VALUES],
            color:
                orderStatus === OFFLINE_ORDER_STATUS_VALUES.CANCELLED ||
                orderStatus === OFFLINE_ORDER_STATUS_VALUES.REFUNDED
                    ? 'transparent'
                    : 'green',
        };

        return {
            status,
            info: {
                number: displayQuantity,
                id,
                posOrderNumber: posOrderNumber,
                txnOrderNumber: txnOrderNumber,
                customer: orderCustomer,
                storeName: get(storeInfo, 'name', ''),
                stock: stockInfo,
                pickedAmount,
                exceptions,
                saInfo,
                orderStore,
                createTime,
                transportation,
                frightNo,
                departureDate,
                depositProportion,
                remainAmount,
                taxFlag,
                parentOrderNumber,
                depositAmount,
                receiveAddress,
                ...rest,
            },
        };
    };

    const getItemFooter = () => {
        return `${services.language.getText(
            'order.orderListItem.expressDeliveryTime'
        )}：${props.expressDeliveryTime || '-'}`;
    };

    const renderOperational = (): React.ReactElement => {
        const { showButtons } = props;
        return (
            <>
                {Object.keys(showBtnMap).map((key) => {
                    if (showButtons[key]) {
                        const getConfig = showBtnMap[key];

                        if (getConfig.component) {
                            return createElement(
                                getConfig.component,
                                Object.assign(
                                    {},
                                    {
                                        key,
                                    },
                                    props
                                )
                            );
                        }

                        const config = getConfig(props);
                        if (isEmpty(config)) return null;
                        return createElement(
                            ComponentsManager.get(config.component),
                            Object.assign(
                                {},
                                {
                                    key,
                                },
                                config.config
                            )
                        );
                    }
                    return null;
                })}
            </>
        );
    };
    return (
        <OfflineOrderListItem
            key={props.id}
            header={getItemHeader()}
            content={getItemContent()}
            footer={getItemFooter()}
            renderOperational={renderOperational}
        />
    );
};

// 默认搜索词
import { Config } from '@comall-backend-builder/core/lib/parser';
import { COMPONENT_TYPE, DESIGN_MANAGE_LANGUAGE_OPTIONS, STATUS_OPTIONS } from '@/constants';
import { createPageLayout, tableMixin, getLanguageColumns, getLanguageProperties } from '../mixin';

import { DEFAULT_SEARCH_KEY_TABLE } from '@/configs/pro-table-constants';
export const config: Config = {
    entities: {
        NoticeBarManagement: {
            apiPath: '/loader/dc-master-data/admin/homeFeature',
            properties: {
                id: { type: 'string' },
                storeName: {
                    type: 'string',
                    displayName: '<<onlineStore.storeName>>',
                },
                storeCode: {
                    type: 'string',
                    displayName: '<<onlineStore.storeCode>>',
                },
                quantity: {
                    properties: getLanguageProperties('string', 'designManager.noticeBarWords.num'),
                    type: 'object',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<designManager.searchWords.status>>',
                    // @ts-ignore
                    options: STATUS_OPTIONS,
                },
                lastedModifyUser: {
                    type: 'string',
                    displayName: '<<designManager.searchWords.lastModifyName>>',
                },
                lastedModifyTime: {
                    type: 'string',
                    displayName: '<<designManager.searchWords.lastModifyTime>>',
                },
            },
        },
    },
    components: {
        NoticeBarManagementView: {
            component: 'Viewport',
            entity: 'NoticeBarManagement',
        },
        NoticeBarManagementTable: {
            ...tableMixin,
            component: 'ProTable',
            componentName: DEFAULT_SEARCH_KEY_TABLE,
            className: 'hot-brands-table',
            componentId: 'SearchWordsManageTable',
            columns: [
                { property: 'storeName' },
                { property: 'storeCode' },
                ...getLanguageColumns('quantity'),
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                },
                { property: 'lastedModifyUser' },
                { property: 'lastedModifyTime' },
            ],
            actionColumn: {
                fixed: 'right',
                title: '<<set>>',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<common.disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                return {
                                    entity: props.entity,
                                    row,
                                    statusApi: {
                                        '0':
                                            '/dc-master-data/admin/homeFeature/enable/{{row.storeId}}',
                                        '1':
                                            '/dc-master-data/admin/homeFeature/disable/{{row.storeId}}',
                                    },
                                    statusParams: {
                                        '0': { status: 1 },
                                        '1': { status: 0 },
                                    },
                                    statusText: {
                                        '0': '<<enable>>',
                                        '1': '<<disable>>',
                                    },
                                };
                            },
                        },
                        privilege: {
                            path: 'finish.notification_bar.enable',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'HoverSelect',
                        text: '<<designManager.noticeBarWords.setMenus>>',
                        config: {
                            actionType: 'component',
                            component: 'ModalSetMenuWords',
                            componentType: COMPONENT_TYPE.NOTICE_BAR_WORDS,
                            options: DESIGN_MANAGE_LANGUAGE_OPTIONS,
                        },
                        privilege: {
                            path: 'finish.notification_bar.update',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        NoticeBarManagementPage: createPageLayout([
            {
                component: 'NoticeBarManagementTable',
                privilege: {
                    path: 'finish.notification_bar.list',
                    level: 'full',
                },
            },
        ]),
    },
};

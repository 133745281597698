import React from 'react';
import { buildFormFieldsByLanguage } from '@/services/language-utils';
import { services } from '@comall-backend-builder/core';
import {
    APPLY_TYPE_OPTIONS,
    APPLY_TYPE_VALUES,
    TYPE_CONDITION_OPTIONS,
    PROMOTION_TYPE_VALUES,
    TYPE_CONDITION_VALUES,
    REWARD_TYPE_OPTIONS,
    REWARD_TYPE_VALUES,
} from '@/constants';
import { dictItems } from './sales-form-item';

function createTabTitle(index: string, title: string) {
    return React.createElement(
        'div',
        {
            style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        [React.createElement('div', { className: 'tab-name' }, title)]
    );
}

export function buildForm(mode: string) {
    return {
        component: 'SalesPromotionForm',
        mode,
        labelCol: {
            md: { span: 24 },
            lg: { span: 4 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 8 },
        },
        items: [
            {
                renderType: 'tabs',
                itemLayout: 'vertical',
                key: 'cards',
                split: false,
                className: 'customer',
                items: [
                    {
                        tab: createTabTitle(
                            '1',
                            services.language.getText('salesPromotion.baseMsg.title')
                        ),
                        activeKey: '1',
                        renderType: 'tabPane',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'type',
                                key: 'promotionTypesRules',
                                disabled: mode !== 'add',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'discName',
                                key: 'discName',
                                maxLength: 50,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'effectTime',
                                key: 'effectTime',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'storeIds',
                                key: 'storeIds',
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'priority',
                                key: 'priorityPromotion',
                                step: 1,
                                fieldOpts: {
                                    // advancedValidator: validatePriority,
                                    rules: [
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: /^[1-9]\d{0,7}$/,
                                            message: services.language.getText(
                                                'salesPromotion.baseMsg.errorPriorityPromotion'
                                            ),
                                        },
                                    ],
                                },
                            },
                            ...buildFormFieldsByLanguage({
                                renderType: 'field',
                                property: 'detailMap',
                                maxLength: 100,
                                fieldOpts: {
                                    rules: [{ whitespace: true, required: false }],
                                },
                            }),
                        ],
                    },
                    {
                        tab: createTabTitle(
                            '2',
                            services.language.getText('salesPromotion.conditionQualification.title')
                        ),
                        activeKey: '2',
                        renderType: 'tabPane',
                        bordered: false,
                        items: [
                            {
                                renderType: 'field',
                                property: 'memberLevelIds',
                                key: 'memberLevelIds',
                            },
                            {
                                renderType: 'field',
                                property: 'applyType',
                                key: 'applicableCommodityType',
                                formatField: (values: AnyObject) => {
                                    return {
                                        options:
                                            values.type + '' === PROMOTION_TYPE_VALUES.SINGLE
                                                ? APPLY_TYPE_OPTIONS.filter(
                                                      (item) => item.id !== APPLY_TYPE_VALUES.ALL
                                                  )
                                                : APPLY_TYPE_OPTIONS,
                                    };
                                },
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyGoods',
                                key: 'applyGoods',
                                renderAble: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.GOODS,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyBrand',
                                key: 'applyBrand',
                                enableInternalLoader: false,
                                renderAble: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.BRAND,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyOfflineCategory',
                                key: 'applyOfflineCategory',
                                renderAble: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.OFFLINE_CATEGORY,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyVirtualCategory',
                                key: 'applyVirtualCategory',
                                renderAble: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.VIRTUAL_CATEGORY,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'applyMerchant',
                                key: 'applyMerchant',
                                renderAble: (values: AnyObject) =>
                                    values.applyType === APPLY_TYPE_VALUES.MERCHANT,
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'excludeType',
                                key: 'excludeType',
                            },
                            {
                                renderType: 'field',
                                property: 'excludeGoods',
                                key: 'excludeGoods',
                                renderAble: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.GOODS,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeBrand',
                                key: 'excludeBrand',
                                enableInternalLoader: false,
                                renderAble: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.BRAND,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeOfflineCategory',
                                key: 'excludeOfflineCategory',
                                renderAble: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.OFFLINE_CATEGORY,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeVirtualCategory',
                                key: 'excludeVirtualCategory',
                                renderAble: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.VIRTUAL_CATEGORY,
                            },
                            {
                                renderType: 'field',
                                property: 'excludeMerchant',
                                key: 'excludeMerchant',
                                renderAble: (values: AnyObject) =>
                                    values.excludeType === APPLY_TYPE_VALUES.MERCHANT,
                            },
                            {
                                renderType: 'field',
                                property: 'conditionType',
                                key: 'typeCondition',
                                disabled: mode !== 'add',
                                formatField: (values: AnyObject) => {
                                    let options = [];

                                    switch (values.type) {
                                        case PROMOTION_TYPE_VALUES.SINGLE:
                                            options = TYPE_CONDITION_OPTIONS.filter(
                                                (item) => item.id === TYPE_CONDITION_VALUES.NO
                                            );
                                            break;
                                        case PROMOTION_TYPE_VALUES.MULTI:
                                            options = TYPE_CONDITION_OPTIONS.filter(
                                                (item) => item.id !== TYPE_CONDITION_VALUES.NO
                                            );
                                            break;
                                        default:
                                            options = TYPE_CONDITION_OPTIONS.filter(
                                                (item) => item.id === TYPE_CONDITION_VALUES.MONEY
                                            );
                                    }

                                    return {
                                        options,
                                    };
                                },
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                            {
                                renderType: 'field',
                                property: 'rewardType',
                                key: 'rewardType',
                                disabled: mode !== 'add',
                                formatField: (values: AnyObject) => {
                                    let options: any[] = [];
                                    switch (values.type) {
                                        case PROMOTION_TYPE_VALUES.SINGLE:
                                            options = REWARD_TYPE_OPTIONS.filter(
                                                (item) =>
                                                    [
                                                        REWARD_TYPE_VALUES.DISCOUNT,
                                                        REWARD_TYPE_VALUES.MONEY,
                                                        REWARD_TYPE_VALUES.CHOOSE_PRODUCT,
                                                    ].indexOf(item.id) > -1
                                            );
                                            break;
                                        case PROMOTION_TYPE_VALUES.MULTI:
                                            if (
                                                values.conditionType === TYPE_CONDITION_VALUES.MONEY
                                            ) {
                                                options = REWARD_TYPE_OPTIONS.filter(
                                                    (item) =>
                                                        [
                                                            REWARD_TYPE_VALUES.DISCOUNT,
                                                            REWARD_TYPE_VALUES.MONEY,
                                                            REWARD_TYPE_VALUES.MANY_PIECES,
                                                        ].indexOf(item.id) < 0
                                                );
                                            } else {
                                                options = REWARD_TYPE_OPTIONS.filter(
                                                    (item) =>
                                                        [
                                                            REWARD_TYPE_VALUES.DISCOUNT,
                                                            REWARD_TYPE_VALUES.MONEY,
                                                        ].indexOf(item.id) < 0
                                                );
                                            }
                                            break;
                                        default:
                                            options = REWARD_TYPE_OPTIONS.filter(
                                                (item) =>
                                                    [
                                                        REWARD_TYPE_VALUES.CHOOSE_PRODUCT,
                                                        REWARD_TYPE_VALUES.GIFT,
                                                    ].indexOf(item.id) > -1
                                            );
                                    }
                                    return {
                                        options,
                                    };
                                },
                                fieldOpts: {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        tab: createTabTitle(
                            '3',
                            services.language.getText('salesPromotion.conditionsReward.title')
                        ),
                        activeKey: '3',
                        renderType: 'tabPane',
                        bordered: false,
                        dictItems: dictItems,
                    },
                    {
                        tab: createTabTitle(
                            '4',
                            services.language.getText('salesPromotion.complete.title')
                        ),
                        activeKey: '4',
                        renderType: 'tabPane',
                        bordered: false,
                        items: [],
                    },
                ],
            },
        ],
    };
}

/*
 * @Author: 朱璐
 * @Date: 2022-10-21 16:49:00
 * @Description: 店员管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { USER_JOB_OPTIONS, USER_JOB_VALUES } from '@/constants';
import {
    createPageLayout,
    flexibleFilterForm,
    tableMixin,
    deletePopConfirmConfig,
} from '@/configs/mixin';
export const config: Config = {
    entities: {
        salesclerk: {
            apiPath: '/loader/dc-user/admin/userJob',
            properties: {
                id: { type: 'string' },
                userName: {
                    type: 'string',
                    displayName: '<<salesclerk.userName>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<salesclerk.email>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<salesclerk.code>>',
                },
                userJob: {
                    type: 'string.options.select',
                    displayName: '<<salesclerk.userJob>>',
                    options: USER_JOB_OPTIONS,
                },
            },
            filters: {
                userName: {
                    type: 'string',
                    displayName: '<<salesclerk.userName>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<salesclerk.email>>',
                },
            },
        },
        salesclerkInfo: {
            apiPath: '/dc-user/admin/userJob',
            properties: {
                userId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<salesclerk.userName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-user/admin/users/options',
                    },
                    rules: [{ required: true, message: '<<salesclerk.emptyMsg.userName>>' }],
                },
                userJob: {
                    type: 'string.options.radio',
                    displayName: '<<salesclerk.userJob>>',
                    options: USER_JOB_OPTIONS,
                    defaultValue: USER_JOB_VALUES.CLERK,
                },
                code: {
                    type: 'string',
                    rules: [
                        {
                            required: true,
                            whiteSpace: true,
                            message: '<<salesclerk.emptyMsg.code>>',
                        },
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (!/[0-9a-zA-Z]{4}/.test(value)) {
                                    callback(services.language.getText('salesclerk.errorMsg.code'));
                                }
                                callback();
                            },
                        },
                    ],
                    controlConfig: {
                        maxLength: 4,
                    },
                    displayName: '<<salesclerk.code>>',
                },
            },
        },
    },
    components: {
        SalesclerkContainer: {
            component: 'Viewport',
        },
        SalesclerkList: {
            entity: 'salesclerk',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'userName',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<salesclerk.userName>>',
                            },
                        },
                        {
                            property: 'email',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<salesclerk.email>>',
                            },
                        },
                    ],
                    privilege: { path: 'offline.salesclerk.view_list', level: 'full' },
                },
                {
                    component: 'SalesClerkAction',
                },
                {
                    component: 'SalesclerkTable',
                    privilege: { path: 'offline.salesclerk.view_list', level: 'full' },
                },
            ]),
        },
        SalesClerkAction: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'SalesclerkForm',
                    text: 'salesclerk.add',
                    type: 'add',
                    content: {
                        component: 'SalesclerkCreateForm',
                    },
                    privilege: { path: 'offline.salesclerk.add', level: 'full' },
                },
            ],
        },
        SalesclerkTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'userName',
                    modifiable: false,
                },
                {
                    property: 'email',
                    modifiable: false,
                },
                {
                    property: 'userJob',
                    modifiable: false,
                },
                {
                    property: 'code',
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<salesclerk.modifyCode>>',
                        config: {
                            actionType: 'component',
                            component: 'SalesclerkForm',
                            renderConfig: (row: any, props: any) => {
                                if (row.userJob === USER_JOB_VALUES.MANAGER) {
                                    return {
                                        ...props,
                                        row,
                                        text: 'salesclerk.modifyCode',
                                        type: 'modify',
                                        content: {
                                            component: 'SalesclerkModifyForm',
                                        },
                                    };
                                } else {
                                    return { content: null, type: 'modify' };
                                }
                            },
                        },
                        privilege: { path: 'offline.salesclerk.modify', level: 'full' },
                    },
                    {
                        type: 'component',
                        text: '<<delete>>',
                        component: 'TableActionDelete',
                        config: {
                            ...deletePopConfirmConfig,
                            apiPath: '/dc-user/admin/userJob/{{row.id}}',
                        },
                        privilege: { path: 'offline.salesclerk.delete', level: 'full' },
                    },
                ],
            },
        },
        SalesclerkCreateForm: {
            component: 'CreateFormPlus',
            entity: 'salesclerkInfo',
            labelCol: 6,
            controlCol: 8,
            fields: [
                { property: 'userId' },
                { property: 'userJob' },
                {
                    property: 'code',
                    visible: (values: any) => values.userJob === USER_JOB_VALUES.MANAGER,
                },
            ],
        },
        SalesclerkModifyForm: {
            component: 'CreateFormPlus',
            entity: 'salesclerkInfo',
            labelCol: 6,
            controlCol: 8,
            fields: [
                {
                    property: 'code',
                },
            ],
        },
    },
};

import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/dc-master-data/admin/channel/log',
        loader: {
            get: async function(data: any, config: any) {
                if (data.id) {
                    const response: any = await services.api.get(
                        {},
                        {
                            ...config,
                            apiPath: `/dc-master-data/admin/channel/log/${data.id}`,
                        }
                    );
                    return response;
                } else {
                    const response: any = await services.api.get(data, {
                        ...config,
                        apiPath: `/dc-master-data/admin/channel/log`,
                    });
                    return response;
                }
            },
        },
    },
    {
        key: '/dc-master-data/admin/channel/log/channelList',
        loader: {
            get: async function(data: any, config: any) {
                const response: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-master-data/admin/channel/log/channelList`,
                });

                const res = response.map((item: string) => ({
                    id: item,
                    name: item,
                }));

                return res;
            },
        },
    },
];

import React, { Component, createElement, Fragment } from 'react';
import { Tree, Button } from 'antd';
import { StoreTreeSelector } from '@/components/store-tree-selector';
import { ComponentsManager, Loader } from '@comall-backend-builder/core';
import { Privilege } from '@/components/privilege';
import { get, isEmpty } from 'lodash';
import { eventCenter } from '@/services/events';
import './index.less';
interface State {
    onlineStoreCode: string;
    treeData: any[];
    selectedKeys: any[];
    expandedKeys: any[];
}

export class CategorySelectorContainer extends Component<any, State> {
    static defaultProps = {
        noStore: false,
    };
    state = {
        onlineStoreCode: '',
        treeData: [],
        selectedKeys: [],
        expandedKeys: [],
    };
    componentWillMount() {
        eventCenter.on(
            'tree_refresh',
            () => {
                this.loadTreeData();
            },
            null
        );
    }
    componentWillUnmount(): void {
        eventCenter.off('tree_refresh', null, null);
    }
    componentDidMount() {
        // 标准分类没有门店选择
        if (this.props.noStore) {
            this.loadTreeData();
        }
    }
    onChangeStore = (onlineStores: any[]) => {
        const onlineStoreCodes = onlineStores.map((item) => item.id).join(',');
        if (!onlineStoreCodes) return;
        this.setState({ onlineStoreCode: onlineStoreCodes }, () => {
            this.refreshData();
            this.loadTreeData();
        });
    };

    /** 刷新table组件 */
    refreshData = () => {
        this.props.entity.filtersChange({
            onlineStoreId: this.state.onlineStoreCode,
        } as any);
        this.props.entity.pageChange({
            page: 1,
            perPage: this.props.entity?.paging?.perPage || 10,
        });
        this.props.entity.search({
            onlineStoreId: this.state.onlineStoreCode,
        });
    };
    allTreeKeys: any[] = [];
    loadTreeData = async () => {
       
        const result = await Loader.load('get', {
            apiPath: this.props.treeSource,
            params: { onlineStoreId: this.state.onlineStoreCode },
        });

        //@ts-ignore
        let arr: any[] = [];
        const format = (a: any) => {
            if (a.children.length > 0) {
                a.children.forEach((i: any) => format(i));
            }
            arr.push(a);
        };
        if (!isEmpty(result)) {
            format(result[0]);
        }
        this.allTreeKeys = arr.map((i: any) => i.key);
        this.setState({ treeData: result, expandedKeys: this.allTreeKeys });
    };
    treeSelect = (selectedKeys: any) => {
        const { entity, topLevelParentId, params } = this.props;
        this.setState({
            selectedKeys,
        });
        let parentId = get(selectedKeys, '[0]', topLevelParentId);
        const perPage = get(entity, 'paging.perPage');
        entity.pageChange({ page: 1, perPage });
        entity.filtersChange({ ...entity.filters, parentId });
        entity.search({
            ...params,
            ...entity.filters,
        });
    };
    onExpend = (expandedKeys: any) => {
        this.setState({ expandedKeys });
    };
    renderTree = () => {
        const { treeData, expandedKeys } = this.state;
        if (!this.state.onlineStoreCode && !this.props.noStore) return <Fragment />;

        return (
            <Tree
                showIcon
                treeData={treeData}
                onExpand={this.onExpend}
                expandedKeys={expandedKeys}
                onSelect={this.treeSelect}
            ></Tree>
        );
    };
    render() {
        return (
            <div className='product-shelf-list '>
                {!this.props.noStore && (
                    <div className='product-shelf-header' style={{ position: 'relative' }}>
                        <StoreTreeSelector
                            checkable={false}
                            selectable={true}
                            onChange={this.onChangeStore}
                        />
                    </div>
                )}
                <div className='product-shelf-body'>
                    <div className='virtual-category-selector-container'>
                        <Privilege {...this.props.listPrivilege}>
                            <div
                                style={{
                                    width: '30%',
                                    maxHeight: '645px',
                                    overflowY: 'scroll',
                                    flexShrink: 0,
                                }}
                            >
                                <div style={{ marginBottom: '8px' }}>
                                    <Button
                                        style={{ marginRight: '16px' }}
                                        onClick={() => {
                                            this.setState({ expandedKeys: this.allTreeKeys });
                                        }}
                                    >
                                        全部展开
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.setState({ expandedKeys: [] });
                                        }}
                                    >
                                        全部收起
                                    </Button>
                                </div>
                                {this.renderTree()}
                            </div>
                        </Privilege>
                        <div style={{ flex: 1, overflow: 'hidden' }}>
                            {createElement(ComponentsManager.get(this.props.rightComponent), {
                                entity: this.props.entity,
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

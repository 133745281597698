import React, { useState, useEffect } from 'react';
import { Form as AntForm, Button, Input, Modal, Table, Tag } from 'antd';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { trim, cloneDeep } from 'lodash-es';

import { OrderInfoProductProps } from '.';
import './index.less';
import { services } from '@comall-backend-builder/core';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';

const couponTypeMap: any = {
    1: services.language.getText('offlineOrder.create.commonCoupon'),
    2: services.language.getText('offlineOrder.create.freightCoupon'),
    3: services.language.getText('offlineOrder.create.cashCoupon'),
};

export default (props: OrderInfoProductProps) => {
    const { activeKey, hasOverrideCode } = props;
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = props.form;
    const memberId = props.memberInfo?.memberId || props.touristsMemberId;

    const [showCoupon, setShowCoupon] = useState(false);
    const [code, setCode] = useState('');
    const [couponList, setCouponList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [applyLoading, setApplyLoading] = useState(false);
    const value = (getFieldsValue()?.coupons || []) as AnyObject[];
    const appliedIds = value.map((i: any) => i.id);

    useEffect(() => {
        if (activeKey === 3 && value?.length) {
            setFieldsValue({ coupons: [] });
        }
    }, [activeKey]);

    function filterCodeIds(coupons: AnyObject[] = []) {
        return coupons
            .filter((item) => item.source === 1 && item.type === 1)
            .map((item) => item.id);
    }

    function filterDataIds(coupons: AnyObject[] = []) {
        return coupons
            .filter((item) => item.source === 1 && item.type === 2)
            .map((item) => item.id);
    }

    function pickIds(coupons: AnyObject[] = []) {
        const couponList = coupons
            .filter((i: any) => i.type === 1)
            .map((i: any) => ({ id: i.id, type: i.idType, source: i.source }));
        const freightList = coupons
            .filter((i: any) => i.type === 2)
            .map((i: any) => ({ id: i.id, type: i.idType, source: i.source }));
        return {
            codeIds: [...filterCodeIds(couponList), ...filterCodeIds(freightList)],
            dataIds: [...filterDataIds(couponList), ...filterDataIds(freightList)],
            couponList: couponList.map((item) => ({ id: item.id, type: item.type })),
            freightList: freightList.map((item) => ({ id: item.id, type: item.type })),
        };
    }

    function handleInput(e: any) {
        setCode(e.target.value);
    }
    async function onApply() {
        setApplyLoading(true);
        try {
            const res: any = await services.api.post(
                {
                    memberId,
                    isVisitor: props.isVisitor,
                    onlineStoreId: props.params?.onlineStoreIds,
                    couponCode: code,
                    ...pickIds(value),
                },
                {
                    apiPath: `/dc-checkout-api/admin/offline/coupons/codeCanUse`,
                }
            );
            handleSelectCoupon({ ...res, source: 1 });
            setApplyLoading(false);
        } catch (error) {
            errorHandle(error as ErrorInfo);
            setApplyLoading(false);
            throw error;
        }
    }

    async function handleSelectCoupon(row: any) {
        const { couponList, freightList } = pickIds([...value, row]);

        setShowCoupon(false);
        await props
            .handleCheckout({
                memberId,
                onlineStoreId: props.params?.onlineStoreIds,
                couponIdList: couponList,
                freightIdList: freightList,
            })
            .then(() => {
                if (!value || !value.length) {
                    setFieldsValue({ coupons: [row] });
                } else {
                    const newValue = cloneDeep(value);
                    newValue.push(row);
                    setFieldsValue({ coupons: newValue });
                }
            });
    }

    async function handleRemove(itemIndex: any) {
        const newValue = value.filter((i: any, index: number) => index !== itemIndex);
        const { couponList, freightList } = pickIds(newValue);

        props
            .handleCheckout({
                memberId,
                isVisitor: props.isVisitor,
                onlineStoreId: props.params?.onlineStoreIds,
                couponIdList: couponList,
                freightIdList: freightList,
            })
            .then(() => {
                setFieldsValue({ coupons: newValue });
            });
    }

    async function handleOpenSelect() {
        setShowCoupon(true);
        setLoading(true);
        const { codeIds, dataIds, couponList, freightList } = pickIds(value);

        try {
            const res: any = await services.api.post(
                {
                    memberId,
                    onlineStoreId: props.params?.onlineStoreIds,
                    isVisitor: props.isVisitor,
                    codeIds,
                    dataIds,
                    couponList,
                    freightList,
                },
                {
                    apiPath: `/dc-checkout-api/admin/offline/coupons/findOwnCouponsCanUse`,
                }
            );
            setCouponList(res);
            setLoading(false);
        } catch (error) {
            errorHandle(error as ErrorInfo);
            setLoading(false);
            throw error;
        }
    }

    const columns = [
        {
            title: services.language.getText('couponManage.common.couponCode'),
            dataIndex: 'couponNum',
        },
        {
            title: services.language.getText('offlineOrder.create.couponTitle'),
            dataIndex: 'couponName',
        },
        {
            title: services.language.getText('couponRule.customerType'),
            dataIndex: 'type',
            render: (val: string) => {
                return couponTypeMap[val];
            },
        },
        {
            title: services.language.getText('offlineOrder.create.discountAmount'),
            dataIndex: 'reliefType',
            render: (val: number, row: any) => {
                return row.reliefType === 1 ? `${100 - row.reliefNum}%OFF` : `-$${row.reliefNum}`;
            },
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (val: number, row: any) => {
                return (
                    <Button
                        disabled={appliedIds.includes(row.id)}
                        type='primary'
                        size='small'
                        onClick={() => handleSelectCoupon(row)}
                    >
                        {language.getText('offlineOrder.create.selectText')}
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <AntForm.Item label={language.getText('offlineOrder.create.coupon')}>
                {getFieldDecorator('coupons')(
                    <>
                        <div>
                            <Input
                                onChange={handleInput}
                                placeholder={language.getText(
                                    'offlineOrder.create.inputCouponCode'
                                )}
                                disabled={!props.memberInfo?.phone || hasOverrideCode}
                            />
                            <Button
                                type='primary'
                                disabled={
                                    !trim(code) || !props.memberInfo?.phone || hasOverrideCode
                                }
                                className='coupon-code-btn'
                                loading={applyLoading}
                                onClick={onApply}
                            >
                                {language.getText('offlineOrder.create.couponCodeBtn')}
                            </Button>
                        </div>
                        {props.memberInfo?.phone && !hasOverrideCode && (
                            <Button
                                className='select-member-coupon-btn'
                                onClick={handleOpenSelect}
                                type='link'
                            >
                                {language.getText('offlineOrder.create.selectMemberCoupon')}
                            </Button>
                        )}
                        <div className='coupon-selected-card'>
                            {!!value &&
                                value.map((i: any, index: number) => {
                                    return (
                                        <Tag
                                            key={i.id + index}
                                            className='coupon-selected-card-tag'
                                            closable
                                            onClose={() => handleRemove(index)}
                                        >
                                            {couponTypeMap[i.type]}：
                                            {i.reliefType === 1
                                                ? `${100 - i.reliefNum}%OFF`
                                                : `-$${i.reliefNum}`}
                                        </Tag>
                                    );
                                })}
                        </div>

                        <Modal
                            wrapClassName='select-address-modal'
                            visible={showCoupon}
                            onCancel={() => setShowCoupon(false)}
                            closable={false}
                            footer={[
                                <Button onClick={() => setShowCoupon(false)}>
                                    {language.getText('common.cancel')}
                                </Button>,
                            ]}
                            width={800}
                        >
                            <Table
                                loading={loading}
                                scroll={{ y: 650 }}
                                pagination={false}
                                columns={columns}
                                dataSource={couponList}
                            />
                        </Modal>
                    </>
                )}
            </AntForm.Item>
        </>
    );
};

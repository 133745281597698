import React, { createElement, isValidElement } from 'react';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { AfterSaleListItem } from '@/components/after-sale-list-item';
import { AFTER_SALE_STATUS_NAMES, AFTER_SALE_STATUS_VALUES } from '@/constants/after-sale';
import { checkPrivilege } from '@/components/privilege';
import { Modal } from 'antd';

/**
 * 添加备注按钮
 */
const addRemarksBtn = (props: any) => {
    const { aftermarketId } = props;
    if (!checkPrivilege('aftermarket.after_sale_view.add_remark')) {
        return null;
    }
    return {
        component: 'RemarkModal',
        buttonType: 'remarkButton',
        config: {
            apiPath: '/base-order/admin/aftermarket/remark',
            aftermarketId,
        },
    };
};

/**
 * 审核通过按钮
 */
const approveBtn = (props: any) => {
    const {
        aftermarketId,
        parentProps: { entity },
    } = props;
    if (!checkPrivilege('aftermarket.after_sale_view.approval_pass')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'completePackButton',
        config: {
            type: 'primary',
            text: services.language.getText('afterSale.statusMap.approved'),
            style: {
                marginRight: 15,
            },
            onClick: () => {
                Modal.confirm({
                    title: services.language.getText('afterSale.tips.approve'),
                    onOk: () => {
                        services.api
                            .get(
                                { aftermarketId: aftermarketId },
                                { apiPath: `/base-order/admin/aftermarket/pass` }
                            )
                            .then(() => {
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 审核不通过按钮
 */
const rejectBtn = (props: any) => {
    const {
        aftermarketId,
        parentProps: { entity },
    } = props;
    if (!checkPrivilege('aftermarket.after_sale_view.approval_fail')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'completePackButton',
        config: {
            type: 'primary',
            text: services.language.getText('afterSale.statusMap.rejected'),
            style: {
                marginRight: 15,
            },
            onClick: () => {
                Modal.confirm({
                    title: services.language.getText('afterSale.tips.reject'),
                    onOk: () => {
                        services.api
                            .get(
                                { aftermarketId: aftermarketId },
                                { apiPath: `/base-order/admin/aftermarket/fail` }
                            )
                            .then(() => {
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 处理完成按钮
 */
const completeBtn = (props: any) => {
    const {
        aftermarketId,
        parentProps: { entity },
    } = props;
    if (!checkPrivilege('aftermarket.after_sale_view.approval_success')) {
        return null;
    }
    return {
        component: 'Button',
        buttonType: 'completePackButton',
        config: {
            type: 'primary',
            text: services.language.getText('afterSale.statusMap.complete'),
            style: {
                marginRight: 15,
            },
            onClick: () => {
                Modal.confirm({
                    title: services.language.getText('afterSale.tips.complete'),
                    onOk: () => {
                        services.api
                            .get(
                                { aftermarketId: aftermarketId },
                                { apiPath: `/base-order/admin/aftermarket/success` }
                            )
                            .then(() => {
                                entity.search(entity.params);
                            })
                            .catch(errorHandle);
                    },
                });
            },
        },
    };
};

/**
 * 显示按钮映射
 */
const showBtnMap: { [name: string]: (data: any) => any } = {
    cancelTheOrder: approveBtn,
    packLackOrder: rejectBtn,
    deliverTheOrder: completeBtn,
    addRemarks: addRemarksBtn,
};

export const AfterSaleDataListItem: React.FC<any> = (props) => {
    const getItemHeader = () => {
        const {
            merchantName,
            deliveryFlag,
            aftermarketNumber,
            aftermarketId,
            memberLabel,
            ...rest
        } = props;
        return {
            merchantName,
            deliveryFlag,
            aftermarketNumber,
            memberLabel,
            aftermarketId,
            ...rest,
        };
    };

    const getItemContent = () => {
        const {
            aftermarketStatus,
            returnGoodsSign,
            outerOrderNumber,
            orderCustomer,
            receiveAddress,
            parentOrderNumber,
            createTime,
            ...rest
        } = props;

        const status = {
            title: AFTER_SALE_STATUS_NAMES[aftermarketStatus as AFTER_SALE_STATUS_VALUES],
            color:
                aftermarketStatus === AFTER_SALE_STATUS_VALUES.REJECTED ? 'transparent' : 'green',
        };

        return {
            status,
            info: {
                returnGoodsSign,
                outerOrderNumber,
                parentOrderNumber,
                customer: orderCustomer,
                receiveAddress,
                createTime,
                ...rest,
            },
        };
    };

    const getItemFooter = () => {
        return `${services.language.getText('afterSale.reviewer')}：${props.approvalUser || '-'}`;
    };

    const renderOperational = (): React.ReactElement => {
        const { showButtons } = props;
        return (
            <>
                {Object.keys(showBtnMap).map((key) => {
                    if (showButtons[key]) {
                        const configOrElement = showBtnMap[key](props);
                        if (!configOrElement) return null;
                        // React 组件
                        if (isValidElement(configOrElement)) return configOrElement;
                        // 配置组件
                        return createElement(
                            ComponentsManager.get(configOrElement.component),
                            Object.assign({}, { key }, configOrElement.config)
                        );
                    }
                    return null;
                })}
            </>
        );
    };
    return (
        <AfterSaleListItem
            key={props.id}
            header={getItemHeader()}
            content={getItemContent()}
            footer={getItemFooter()}
            renderOperational={renderOperational}
        />
    );
};

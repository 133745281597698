import React from 'react';
import { Popover as AntPopover } from 'antd';
import { get, forEach } from 'lodash';
import { ComponentConfig } from '@comall-backend-builder/components-basis/lib/flex-layout';
import { ComponentsManager } from '@comall-backend-builder/core';
import { PopoverProps as AntPopoverProps } from 'antd/lib/popover';

interface Props extends AntPopoverProps {
    triggerComponent: ComponentConfig;
    content: [] | Array<ComponentConfig>;
    loading: boolean;
}

export function PopoverWrapper(props: Props) {
    const { triggerComponent, content, loading, ...otherProps } = props;
    const { component, ...triggerProps } = triggerComponent;
    let actualContent: any;
    if (typeof content === 'string') {
        actualContent = content;
    } else {
        actualContent = [];

        forEach(content, function(item) {
            actualContent.push(
                React.createElement(ComponentsManager.get(item.component), {
                    ...content,
                    component: undefined,
                })
            );
        });
    }
    return (
        <span
            onClick={(e) => {
                const type = get(e.target, 'type');
                if (type !== 'button') {
                    e.stopPropagation();
                }
            }}
        >
            <AntPopover content={actualContent} {...otherProps}>
                {React.createElement(ComponentsManager.get(component), {
                    ...triggerProps,
                    loading,
                })}
            </AntPopover>
        </span>
    );
}

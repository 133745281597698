import React, { useState } from 'react';

import { MemberExamine } from '@/interfaces/member';
import { Form, Modal, Button, InputNumber } from 'antd';
import { services } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { WrappedFormInternalProps } from 'antd/es/form/Form';
const { language, errorHandle, api } = services;
interface IProps extends WrappedFormInternalProps {
    row: MemberExamine;
    text: string;
    entity: Entity;
}
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
/**
 * 会员价商品
 */

export const TableMemberPriceEdit = (props: IProps) => {
    const { row, entity, text, form } = props;

    const [visible, setVisible] = useState(false);

    const { getFieldDecorator, validateFields } = form;
    const handleOnOk = async () => {
        validateFields(async (errors: any, values: any) => {
            if (!errors) {
                try {
                    await api.put(
                        {},
                        {
                            apiPath: `/dc-price/admin/member_prices/${row.id}/member_price?memberPrice=${values.memberPrice}`,
                        }
                    );
                    entity.search({ ...entity.params });
                    setVisible(false);
                } catch (error) {
                    errorHandle(error as any);
                }
            }
        });
    };
    return (
        <>
            <Button
                type='link'
                onClick={() => {
                    setVisible(true);
                }}
            >
                {text}
            </Button>
            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={handleOnOk}
                title={language.getText('membershipLevel.memberPriceProduct.set')}
            >
                <Form {...formItemLayout}>
                    <Form.Item label={language.getText('membershipLevel.memberPriceProduct.set')}>
                        {getFieldDecorator('memberPrice', {
                            rules: [
                                {
                                    required: true,
                                    message: language.getText(
                                        'membershipLevel.memberPriceProduct.emptyError'
                                    ),
                                },
                            ],
                        })(
                            <InputNumber
                                precision={2}
                                max={99999999.99}
                                min={0.01}
                                placeholder={language.getText(
                                    'membershipLevel.memberPriceProduct.set'
                                )}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
const TableMemberPriceEditForm = Form.create<any>()(TableMemberPriceEdit);
export { TableMemberPriceEditForm };

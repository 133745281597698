import React from 'react';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { isFunction } from 'lodash';
import { Button } from '@comall-backend-builder/components-basis';
import { ButtonProps } from '@comall-backend-builder/components-basis/lib/button';
import { Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';

export interface ButtonPlusProps extends Omit<ButtonProps, 'disabled' | 'onClick'> {
    row: any;
    entity: Entity;
    entities: Entity[];

    /**
     * 点击按钮后需二次确认
     */
    popconfirm?: PopconfirmProps;
    /**
     * 支持动态返回禁用状态
     */
    disabled?: boolean | ((props: ButtonPlusProps) => boolean);
    /**
     * 支持接收包含 entity row 等关键信息的点击回调
     */
    onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>, props: ButtonPlusProps): void;
}

/**
 * 支持完整的 antd ButtonProps。
 *
 * 事件除了支持接收 event 外，还支持接收完整的 props。
 */
export const ButtonPlus: React.FC<ButtonPlusProps> = (props) => {
    const { disabled, popconfirm, onClick, ...btnProps } = props;
    const disabledValue = isFunction(disabled) ? disabled(props) : disabled;

    if (popconfirm) {
        return (
            <Popconfirm {...popconfirm} onConfirm={(event) => onClick?.(event, props)}>
                <Button {...btnProps} disabled={disabledValue} />
            </Popconfirm>
        );
    }

    return (
        <Button
            {...btnProps}
            disabled={disabledValue}
            onClick={(event) => onClick?.(event, props)}
        />
    );
};

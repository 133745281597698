import { services } from '@comall-backend-builder/core';

export default [
    {
        key: '/dc-master-data/admin/urlmapping/list',
        loader: {
            get: async function() {
                return {
                    page: 1,
                    per_page: 10,
                    result: [
                        {
                            id: 'FOOD',
                            name: 'FOOD',
                        },
                        {
                            id: 'DCHLIVING',
                            name: 'DCH Living',
                        },
                        {
                            id: 'AHAA',
                            name: 'AHAA',
                        },
                    ],
                    total_num: 3,
                    total_page: 1,
                };
            },
        },
    },
    {
        key: '/dc-master-data/admin/urlmapping',
        loader: {
            get: async function(data: any, config: any) {
                const params = {
                    ...data,
                    channelType: decodeURIComponent(data.id),
                };
                delete params.id;
                const response: any = await services.api.get(
                    { ...params },
                    {
                        ...config,
                        apiPath: `/dc-master-data/admin/urlmapping`,
                    }
                );
                return response;
            },
        },
    },
];

import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        channelContacts: {
            apiPath: '/loader/channelContacts',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<channels.channelName>>',
                },
                type: {
                    type: 'string',
                    displayName: '<<channels.channelType>>',
                },
            },
        },
    },

    components: {
        ChannelContactsContainer: {
            component: 'FlexLayout',
            entity: 'channelContacts',
            loaderType: 'search',
            direction: 'vertical',
            items: [
                {
                    component: 'ChannelContactsTitle',
                },
                {
                    component: 'ChannelContactsTable',
                },
            ],
        },
        ChannelContactsTitle: {
            component: 'Text',
            text: '<<channelContacts.channel>>：{{params.name}}',
            style: {
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '50px',
            },
        },
        ChannelContactsTable: {
            component: 'DataTable',
            columns: [{ property: 'name' }, { property: 'type', align: 'end' }],
        },
    },
};

import React from 'react';
import { Form as AntForm, InputNumber } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

import CouponSelector from './coupon-selector';
import CashCouponSelector from './cash-coupon-selector';
import { OrderInfoProductProps } from '.';

export default function CouponForm(props: OrderInfoProductProps) {
    const { activeKey } = props;
    const { getFieldDecorator } = props.form;

    return (
        <div style={{ display: activeKey === 3 ? 'block' : 'none' }}>
            {/* COUPON信息 */}
            <div className='offline-order-create-title'>
                {language.getText('offlineOrder.create.coupon')}
            </div>
            {/* Coupon */}
            <CouponSelector {...props} />
            {/* 折扣信息 */}
            <div className='offline-order-create-title'>
                {language.getText('offlineOrder.create.discountInfo')}
            </div>
            {/* 积分折扣 */}
            <AntForm.Item label={language.getText('offlineOrder.create.pointDiscount')}>
                {getFieldDecorator('pointQuantity')(
                    <InputNumber
                        disabled={!props.memberInfo?.phone}
                        placeholder={services.language.getText(
                            'offlineOrder.placeholder.payAccount'
                        )}
                        type='number'
                        min={0.0}
                        precision={2}
                        max={99999999}
                    />
                )}
            </AntForm.Item>
            {/* 代金券折扣 */}
            <CashCouponSelector {...props} />
        </div>
    );
}

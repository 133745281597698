import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Button as AntdButton,
    Form as AntdForm,
    InputNumber as AntdInputNumber,
    message as AntdMessage,
    Row as AntdRow,
    Col as AntdCol,
} from 'antd';
import { RadioGroup, Text } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';

const radioOptions = [
    { name: services.language.getText('channels.autoCancelEdit.on'), id: '1' },
    { name: services.language.getText('channels.autoCancelEdit.off'), id: '0' },
];

interface ItemInfo {
    value: string;
    status: string;
}

const BasicForm: React.FC = (props: any) => {
    const propParams = useRef(props.params);
    const [zeroPick, setZeroPick] = useState<ItemInfo>({ value: '', status: '' });
    const [waitDays, setWaitDays] = useState<ItemInfo>({ value: '', status: '' });

    useEffect(() => {
        const { channelId, organizationId, ...params } = propParams.current;
        services.api
            .get(
                { ...params },
                {
                    apiPath: `/dc-order/admin/orders/cancel/system_config/${channelId}/${organizationId}`,
                }
            )
            .then((res: any) => {
                res.zeroPick && setZeroPick(res.zeroPick);
                res.waitDays && setWaitDays(res.waitDays);
            });
    }, []);

    const submit = useRef(() => {
        const {
            params: { channelId, organizationId },
            history: { goBack },
        } = props;
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                services.api
                    .put(
                        {
                            ...values,
                        },
                        {
                            apiPath: `/dc-order/admin/orders/cancel/system_config/${channelId}/${organizationId}`,
                        }
                    )
                    .then((res) => {
                        AntdMessage.success(services.language.getText('successFully'));
                        goBack();
                    });
            }
        });
    });

    const validateNum = useCallback((rule, value, callback) => {
        const realValue = parseInt(value);
        if ((value || value === 0) && (!realValue || realValue <= 0)) {
            return new Error(services.language.getText('channels.autoCancelEdit.inputErrorTips'));
        } else {
            callback();
        }
    }, []);

    return (
        <AntdForm labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <AntdForm.Item
                label={services.language.getText('channels.autoCancelEdit.outOfStockOrderSwitch')}
            >
                {props.form.getFieldDecorator('zeroPick.status', {
                    rules: [{ required: true }],
                    initialValue: zeroPick.status,
                })(
                    <RadioGroup
                        options={radioOptions}
                        name='zeroPick'
                        onChange={(value, name) => {
                            setZeroPick({ ...zeroPick, status: value });
                        }}
                    />
                )}
            </AntdForm.Item>
            {zeroPick.status === '1' && (
                <AntdForm.Item
                    label={services.language.getText('channels.autoCancelEdit.outOfStockOrder')}
                >
                    <Text
                        text={services.language.getText(
                            'channels.autoCancelEdit.outOfStockOrderPrefix'
                        )}
                    />
                    {props.form.getFieldDecorator('zeroPick.value', {
                        rules: [
                            {
                                required: true,
                                message: services.language.getText(
                                    'channels.autoCancelEdit.outOfStockOrderInputRequired'
                                ),
                            },
                            { validator: validateNum },
                        ],
                        initialValue: zeroPick.value === '0' ? '' : zeroPick.value,
                    })(<AntdInputNumber className='auto-cancel-form-input' min={0} />)}
                    <Text
                        text={services.language.getText(
                            'channels.autoCancelEdit.outOfStockOrderSuffix'
                        )}
                    />
                    <div className='auto-cancel-form-hint'>
                        {services.language.getText(
                            'channels.autoCancelEdit.outOfStockOrderHelpTips'
                        )}
                    </div>
                </AntdForm.Item>
            )}

            <AntdForm.Item
                label={services.language.getText(
                    'channels.autoCancelEdit.pendingPickupOrderSwitch'
                )}
            >
                {props.form.getFieldDecorator('waitDays.status', {
                    rules: [{ required: true }],
                    initialValue: waitDays.status,
                })(
                    <RadioGroup
                        options={radioOptions}
                        name='waitDays'
                        onChange={(value, name) => {
                            setWaitDays({ ...waitDays, status: value });
                        }}
                    />
                )}
            </AntdForm.Item>
            {waitDays.status === '1' && (
                <AntdForm.Item
                    label={services.language.getText('channels.autoCancelEdit.pendingPickupOrder')}
                >
                    <Text
                        text={services.language.getText(
                            'channels.autoCancelEdit.pendingPickupOrderPrefix'
                        )}
                    />
                    {props.form.getFieldDecorator('waitDays.value', {
                        rules: [
                            {
                                required: true,
                                message: services.language.getText(
                                    'channels.autoCancelEdit.pendingPickupOrderInputRequired'
                                ),
                            },
                            { validator: validateNum },
                        ],
                        initialValue: waitDays.value === '0' ? '' : waitDays.value,
                    })(<AntdInputNumber className='auto-cancel-form-input' min={0} />)}
                    <Text
                        text={services.language.getText(
                            'channels.autoCancelEdit.pendingPickupOrderSuffix'
                        )}
                    />
                    <div className='auto-cancel-form-hint'>
                        {services.language.getText(
                            'channels.autoCancelEdit.pendingPickupOrderHelpTips'
                        )}
                    </div>
                </AntdForm.Item>
            )}
            <AntdRow>
                <AntdCol span={6}></AntdCol>
                <AntdCol span={18}>
                    <AntdButton type='primary' onClick={submit.current}>
                        {services.language.getText('submit')}
                    </AntdButton>
                </AntdCol>
            </AntdRow>
        </AntdForm>
    );
};

export const AutoCancelForm = AntdForm.create()(BasicForm);

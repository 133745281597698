import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { services } from '@comall-backend-builder/core';
import { chunk } from 'lodash';

import AhaaGoodsTag from '@/resources/images/ahaa-goods-tag.png';
import { encode } from '@/services/seo';

import './goods-tags.less';

/**
 * 数据分页
 */
const PAGE_SIZE = 8;
function paging(data: AnyObject[]) {
    return chunk(data, PAGE_SIZE);
}

/**
 * 创建 ahaa 商品详情页 url
 */
function createAhaaProductDetailURL(name: string, csku: string) {
    return `${ENV.AHAA_URL}/product/${encode(name)}/p/${csku}`;
}

const GoodsTag: React.FC<{
    data: AnyObject;
}> = (props) => {
    const { data } = props;

    return (
        <div className='goods-tag'>
            <div className='goods-header'>
                <div className='goods-name'>{data.cskuVo.tcName}</div>
                <div className='goods-introduce-wrap'>
                    <div className='goods-introduce'>{data.cskuVo.tcDescription}</div>
                </div>
                <div className='goods-spec'>
                    <div className='goods-expires'>
                        {services.language.getText('components.GoodsTags.expires')}：
                        {data.cskuVo.period}
                    </div>
                    <div className='goods-size'>
                        {services.language.getText('components.GoodsTags.size')}：
                        {data.cskuVo.length && data.cskuVo.width && data.cskuVo.height
                            ? `${data.cskuVo.length}x${data.cskuVo.width}x${
                                  data.cskuVo.height
                              }（${services.language.getText('components.GoodsTags.mm')}）`
                            : ''}
                    </div>
                    <div className='goods-brand'>
                        {services.language.getText('components.GoodsTags.brand')}：
                        {data.cskuVo.brandNameTc}
                    </div>
                </div>
            </div>
            <div className='goods-logo'>
                <img className='goods-logo-img' src={AhaaGoodsTag} />
            </div>
            <div className='goods-footer'>
                <div className='goods-symbol'>$</div>
                <div className='goods-price'>
                    <div className='goods-selling-price'>{data.price.price}</div>
                    <div className='goods-suggest-price'>
                        {services.language.getText('components.GoodsTags.suggestPrice')} $
                        {data.price.suggestedRetailPrice}
                    </div>
                </div>
                <div className='goods-qr-code'>
                    <QRCodeSVG
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        value={createAhaaProductDetailURL(data.cskuVo.tcName, data.cskuVo.csku)}
                    />
                </div>
            </div>
        </div>
    );
};

/**
 * 每页最多8个标签
 */
const GoodsTagPage: React.FC<{
    page: AnyObject[];
}> = (props) => {
    const { page = [] } = props;

    return (
        <div className='goods-tag-page'>
            {page.slice(0, PAGE_SIZE).map((item) => (
                <GoodsTag key={item.id} data={item} />
            ))}

            <div className='goods-tag-row-line goods-tag-row-line-1'></div>
            <div className='goods-tag-row-line goods-tag-row-line-2'></div>
            <div className='goods-tag-row-line goods-tag-row-line-3'></div>
            <div className='goods-tag-row-line goods-tag-row-line-4'></div>
            <div className='goods-tag-row-line goods-tag-row-line-5'></div>

            <div className='goods-tag-col-line goods-tag-col-line-1'></div>
            <div className='goods-tag-col-line goods-tag-col-line-2'></div>
            <div className='goods-tag-col-line goods-tag-col-line-3'></div>
        </div>
    );
};

export const GoodsTags: React.FC<{
    data?: AnyObject[];
}> = (props) => {
    const { data = [] } = props;
    const pages = paging(data);

    return (
        <>
            {pages.map((page, index) => (
                <GoodsTagPage key={index} page={page} />
            ))}
        </>
    );
};

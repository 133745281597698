/**
 * 订单发运报表
 */

import { ORDER_STATUS_ALL_OPTIONS } from '@/constants/order';
import { DELIVERY_TYPE_OPTIONS } from '@/constants/report';
import { formatDate } from '@/services/data-formatter';
import { Config, EntityProperties } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '../mixin';
import { getFilterProperties, getFields } from './utils';

const filterKeys = [
    'onlineStoreIds',
    'orderTime',
    'shipTime',
    'deliveryTime',
    'orderDeliveryType',
    'orderStatus',
    'pickUpMember',
    'merchantIds',
    'liquidState',
];

const getEntityProperties: () => EntityProperties = () => {
    let nVal: AnyObject = {};
    [
        'onlineStoreCode',
        'name',
        'outerOrderNumber',
        'createTime',
        'orderStatus',
        'expressDeliveryTime',
        'orderDeliveryType',
        'parentRegionName',
        'childRegionName',
        'deliveryAddress',
        'pickUpMobile',
        'pickUpMember',
        'normalNumber',
        'coldNumber',
        'remark',
        'shipTime',
    ].forEach((i: any) => {
        switch (i) {
            case 'orderDeliveryType':
                nVal[i] = {
                    type: 'string.options.select',
                    displayName: `<<report.orderShipping.${i}>>`,
                    options: DELIVERY_TYPE_OPTIONS,
                };
                break;
            case 'orderStatus':
                nVal[i] = {
                    type: 'string.options.select',
                    displayName: `<<report.orderShipping.${i}>>`,
                    options: ORDER_STATUS_ALL_OPTIONS,
                };
                break;
            default:
                nVal[i] = { type: 'string', displayName: `<<report.orderShipping.${i}>>` };
        }
    });
    return nVal;
};

export const config: Config = {
    entities: {
        orderShippingReport: {
            apiPath: '/loader/dc-order/admin/order/orderShipExcelPage',
            properties: getEntityProperties(),
            filters: getFilterProperties(filterKeys, true),
        },
    },
    components: {
        OrderShippingPage: {
            entity: 'orderShippingReport',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: getFields(getFilterProperties(filterKeys)),
                    privilege: {
                        path: 'forms.order_ship.list',
                        level: 'full',
                    },
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: { marginBottom: '16px' },
                    items: [
                        {
                            component: 'ExportButtonWithFilter',
                            text: '<<report.exportResult>>',
                            icon: 'download',
                            type: 'primary',
                            style: {
                                flexGrow: 0,
                                marginRight: 15,
                            },
                            source: {
                                apiPath: '/dc-order/admin/orderShip/exportStatement/orderShip/take',
                            },
                            formatter: (params: any) => {
                                const newParams: any = { ...params };
                                return {
                                    ...newParams,
                                    ...formatDate(newParams.orderTime, 'createTime'),
                                    ...formatDate(newParams.shipTime, 'expressDeliveryTime'),
                                    ...formatDate(newParams.deliveryTime, 'shipTime'),
                                };
                            },
                            isAsync: true,
                            showSuccessTips: true,
                            showErrorMessage: true,
                            privilege: {
                                path: 'forms.order_ship.export',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    ...tableMixin,
                    columns: getFields(getEntityProperties()),
                    privilege: {
                        path: 'forms.order_ship.list',
                        level: 'full',
                    },
                },
            ]),
        },
    },
};

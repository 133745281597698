import { get as lodashGet } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { PRODUCT_SOURCE_TYPE_NAMES, SPECS_TYPE_NAMES } from '@/constants';
interface Price {
    id: string;
    name: { [key: string]: any };
    csku: string;
    rsku: string;
    /**
     * 商品条码
     */
    barcode: string;
    /**
     * Division
     */
    organization: string;
    /**
     * 币种
     */
    ISOCode: string;
    /**
     * duty free 原价
     */
    price: string;
    /**
     * duty free 促销价
     */
    promotionPrice: string;
    /**
     * duty paid 原价
     */
    inclusivePrice: string;
    /**
     * duty paid 促销价
     */
    inclusivePromotionPrice: string;
    /**
     * taxCode
     */
    taxCode: string;
    /**
     * 最后修改时间
     */
    lastedModifyTime: string;
    /**
     * duty金额
     */
    duty: string;
    /**
     * 是否dutyFreeOnly
     */
    dutyFreeOnly: string;
    /**
     * 原价ppu
     */
    ppu: string;
    /**
     * 促销价ppu
     */
    promotionPpu: string;
}
function formatResult(res: any) {
    if (res.rangePrices === null) {
        res.rangePrices = [];
    }
    if (res.rangePrices?.length === 0) {
        res.rangePrices?.push({
            id: null,
            price: null,
            startTime: null,
            endTime: null,
            time: null,
        });
    }
    return {
        ...res,
        rangePrices: res?.rangePrices?.map((item: any) => ({
            id: item.id,
            price: item.price,
            startTime: item.startTime,
            endTime: item.endTime,
            time: item.time === null ? null : { start: item.startTime, end: item.endTime },
        })),
        cskuSourceType: lodashGet(PRODUCT_SOURCE_TYPE_NAMES, lodashGet(res, 'cskuSourceType')),
        cskuSpecsType: lodashGet(SPECS_TYPE_NAMES, lodashGet(res, 'cskuSpecsType')),
    };
}

const getPriceList = async (data: any, config: any) => {
    const res: any = await services.api.get(data, config);
    return {
        ...res,
        result: res.result.map((item: any) => ({
            ...item,
            id: item.id,
            name: {
                en: lodashGet(item, 'cskuVo.enName'),
                jp: lodashGet(item, 'cskuVo.jpName'),
                tc: lodashGet(item, 'cskuVo.tcName'),
                zh: lodashGet(item, 'cskuVo.name'),
                fr: lodashGet(item, 'cskuVo.frName'),
                it: lodashGet(item, 'cskuVo.itName'),
                ko: lodashGet(item, 'cskuVo.koName'),
            },
            csku: lodashGet(item, 'cskuVo.csku'),
            rsku: lodashGet(item, 'rsku'),
            price: lodashGet(item, 'price.price'),
            lastedModifyTime: lodashGet(item, 'price.lastedModifyTime'),
            lastedModifyUserRealName: lodashGet(item, 'price.lastedModifyUserRealName'),
            sourceType: lodashGet(PRODUCT_SOURCE_TYPE_NAMES, lodashGet(item, 'cskuVo.sourceType')),
            specsType: lodashGet(SPECS_TYPE_NAMES, lodashGet(item, 'cskuVo.specsType')),
        })),
    } as { [key: string]: any; result: Price[] };
};
export default [
    {
        key: '/dc-goods/admin/rskus/price/store/monitor/findAllByLoginUser',
        loader: {
            get: async (data: any, config: any) => {
                return await getPriceList(data, config);
            },
        },
    },
    {
        key: '/dc-goods/admin/rskus/price/store/manage/findAllByLoginUser',
        loader: {
            get: async (data: any, config: any) => {
                return await getPriceList(data, config);
            },
        },
    },
    {
        //编辑
        key: '/dc-price/admin/price/getForMiddleEdit',
        loader: {
            get: async (data: any, config: any) => {
                const res: any = await services.api.get(data, {
                    ...config,
                    apiPath: `/dc-price/admin/price/getForMiddleEdit`,
                });
                const result = formatResult(res);
                return result;
            },
            put: async (data: any, config: any) => {
                const parmas = {
                    ...data,
                    rangePrices: data?.rangePrices
                        ?.map((item: any) => ({
                            id: item.id,
                            price: item.price,
                            startTime: item.startTime,
                            endTime: item.endTime,
                        }))
                        .filter((i: any) => Boolean(i.price)),
                };
                const result: any = await services.api.put(parmas, {
                    ...config,
                    apiPath: `/dc-price/admin/price/updateForMiddle`,
                });
                return result;
            },
        },
    },
    {
        // 价格变动日志
        key: '/dc-goods/admin/rskus/:id/csku',
        loader: {
            get: async (data: any, config: any) => {
                const res: any = await services.api.get(
                    {},
                    {
                        ...config,
                        apiPath: `/dc-goods/admin/rskus/rsku/${data.rsku}/csku`,
                    }
                );
                return res;
            },
        },
    },
];

import lodashGet from 'lodash/get';
import { services } from '@comall-backend-builder/core';
const sourceTypeMap = {
    0: 'normal',
    2: 'group',
    3: 'virtual',
};
export default [
    {
        key: '/import_records',
        loader: {
            get: async function(data: any, config: any) {
                const { recordType, operationTime, ...params } = data;
                let apiPath, template: string;
                switch (data.recordType) {
                    case 'productShelf': {
                        apiPath = '/dc-goods/admin/product_scroll_record';
                        template =
                            '/dc-goods/admin/product_scroll_record/export/{{type}}/{{id}}.xls';
                        break;
                    }
                    case 'brandInfo': {
                        apiPath = '/dc-goods/admin/brand_info_records';
                        template = '/dc-goods/admin/brand_info_records/export/{{type}}/{{id}}.xls';
                        break;
                    }
                    case 'onlineStoreSelection': {
                        apiPath = '/dc-goods/admin/rskuImport';
                        template = '/dc-goods/admin/rskuImport/export/{{type}}/{{id}}.xls';
                        break;
                    }
                    case 'productInfo': {
                        apiPath = `/dc-goods/admin/spu_info_records/${
                            //@ts-ignore
                            sourceTypeMap[params?.sourceType]
                        }`;
                        template = `/dc-goods/admin/spu_info_records/${
                            //@ts-ignore
                            sourceTypeMap[params?.sourceType]
                        }/export/{{type}}/{{id}}.xls`;
                        break;
                    }
                    case 'productPrice': {
                        apiPath = '/dc-price/admin/priceImport';
                        template = '/dc-price/admin/priceImport/export/{{type}}/{{id}}.xls';
                        break;
                    }
                    case 'productInventory': {
                        apiPath = '/dc-stock/admin/rskus/stock/findStockImportRecord';
                        template = '/dc-stock/admin/rskus/stock/export/{{type}}/{{id}}.xls';
                        break;
                    }
                    case 'virtualCategory':
                        apiPath = '/guide-cms-service/admin/vc_product_import';
                        template =
                            '/guide-cms-service/admin/vc_product_import/export/{{type}}/{{id}}.xls';
                        break;
                    case 'overrideCode':
                        apiPath = '/dc-price/admin/codeitemimport';
                        template = '/dc-price//admin/codeitemimport/export/{{type}}/{{id}}.xls';
                        break;
                    case 'cashCoupon':
                        apiPath = '/dc-ecoupon/admin/vouchers/findVoucherImportRecord';
                        template = '/dc-ecoupon/admin/vouchers/export/{{type}}/{{id}}.xls';
                        break;
                    case 'otherCoupon':
                        apiPath = '/dc-ecoupon/admin/otherCoupons/findOtherCouponImportRecord';
                        template = '/dc-ecoupon/admin/otherCoupons/export/{{type}}/{{id}}.xls';
                        break;
                    case 'voucherIssuingStaff':
                        apiPath = '/dc-master-data/admin/voucherGrant/findMemberImportRecord';
                        template = '/dc-master-data/admin/voucherGrant/export/{{type}}/{{id}}.xls';
                        break;
                    case 'memberInfo':
                        apiPath = '/dc-user/admin/members/memberImport';
                        template = '/dc-user/admin/members/memberImport/export/{{type}}/{{id}}.xls';
                        break;
                    case 'memberPrice':
                        apiPath = '/dc-price/admin/member_prices_import';
                        template =
                            '/dc-price/admin/member_prices_import/export/{{type}}/{{id}}.xls';
                        break;
                    case 'memberProduct':
                        apiPath = '/dc-user/admin/memberLevel/product/import';
                        template =
                            '/dc-user/admin/memberLevel/product/import/export/{{type}}/{{id}}.xls';
                        break;
                    case 'leverMember':
                        apiPath = '/dc-user/admin/memberLevel/memberImport';
                        template = '/dc-user/admin/memberLevel/export/{{type}}/{{id}}.xls';
                        break;
                    case 'memberSource':
                        apiPath = '/dc-user/admin/member_source/import/records';
                        template = '/dc-user/admin/member_source/export/{{type}}/{{id}}.xls';
                        break;
                    case 'normal':
                        apiPath = '/dc-goods/admin/spu_info_records/attr/normal';
                        template =
                            '/dc-goods/admin/spu_info_records/attr/normal/export/{{type}}/{{id}}.xls';
                        break;
                    case 'virtual':
                        apiPath = '/dc-goods/admin/spu_info_records/attr/virtual';
                        template =
                            '/dc-goods/admin/spu_info_records/attr/virtual/export/{{type}}/{{id}}.xls';
                        break;
                    case 'group':
                        apiPath = '/dc-goods/admin/spu_info_records/attr/group';
                        template =
                            '/dc-goods/admin/spu_info_records/attr/group/export/{{type}}/{{id}}.xls';
                        break;
                    case 'urlMapping':
                        apiPath = '/dc-master-data/admin/urlmapping_import';
                        template =
                            '/dc-master-data/admin/urlmapping_import/export/{{type}}/{{id}}.xls';
                        break;
                    case 'display':
                        apiPath = '/guide-cms-service/admin/display_category_import';
                        template =
                            '/guide-cms-service/admin/display_category_import/export/{{type}}/{{id}}.xls';
                        break;
                    case 'memberSourceBlackProducts':
                        apiPath = '/dc-user/admin/member_source/product/import';
                        template =
                            '/dc-user/admin/member_source/product/import/export/{{type}}/{{id}}.xls';
                        break;
                    case 'productPickupLimit':
                        apiPath =
                            '/dc-business-data/admin/excel/import/log/SKU_PICK_METHOD_RESTRICTIONS';
                        template =
                            '/dc-business-data/admin/excel/export/SKU_PICK_METHOD_RESTRICTIONS/{{type}}/{{id}}.xls ';
                        break;
                    case 'skuSeo':
                        apiPath = '/dc-business-data/admin/excel/import/log/SEO';
                        template = '/dc-business-data/admin/excel/export/SEO/{{type}}/{{id}}.xls';
                        break;
                    default:
                        if (data.recordType.startsWith('recommend/')) {
                            apiPath = `/dc-goods/admin/${data.recordType}/import/record`;
                            template = `/dc-goods/admin/${data.recordType}/export/{{type}}/{{id}}.xls`;
                        }
                }
                const res: any = await services.api.get(
                    {
                        ...params,
                        operationBeginTime: lodashGet(operationTime, 'start'),
                        operationEndTime: lodashGet(operationTime, 'end'),
                    },
                    {
                        ...config,
                        apiPath,
                    }
                );
                return {
                    ...res,
                    result: res.result.map((item: any) => ({
                        ...item,
                        disabled: item.status === 'PROCESSING',
                        noError: item.failedQuantity === 0,
                        downloadAllApiPath: {
                            ALL: services.interpolate(template, { id: item.id, type: 'all' }),
                            FAIL: services.interpolate(template, { id: item.id, type: 'fail' }),
                        },
                        importResult:
                            item.status === 'PROCESSING'
                                ? services.interpolate(
                                      services.language.getText('importRecord.processingResult'),
                                      {
                                          totalQuantity: item.totalQuantity,
                                      }
                                  )
                                : services.interpolate(
                                      services.language.getText('importRecord.importResultValue'),
                                      {
                                          failedQuantity: `${item.failedQuantity}`,
                                          totalQuantity: item.totalQuantity,
                                      }
                                  ),
                    })),
                };
            },
        },
    },
];

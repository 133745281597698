import React, { useEffect, useRef, useState } from 'react';
import { Modal as AntModal, Button } from 'antd';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { checkPrivilege } from '@/components/privilege';
import AccessModal from '@/containers/offline-order/offline-create-basic-form/access-modal';
import { message as AntMessage, Select, Input } from 'antd';
import { map, trim } from 'lodash';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import { useToggle } from 'ahooks';
import { OrderStatusMsg } from '../cancel-order-modal/order-status-msg';

export interface LinkedOrderOption {
    orderStatus: string;
    outerOrderNumber: string;
}

export const CancelOfflineOrderModal: React.FC<AnyObject> = (props) => {
    const { id, parentProps } = props;
    const { entity } = parentProps;

    const [open, openActions] = useToggle();
    const [fetchOptionsLoading, fetchOptionsLoadingActions] = useToggle();
    const [confirmLoading, confirmLoadingActions] = useToggle();

    const [reasons, setReasons] = useState<AnyObject[]>([]);
    const [linkedOrderData, setLinkedOrderData] = useState<LinkedOrderOption[]>([]);

    const [reasonId, setReasonId] = useState<number>();
    const [reasonContent, setReasonContent] = useState<string>();

    const accessCodeRef = useRef<string>();

    useEffect(() => {
        if (!open) {
            setReasonId(undefined);
            setReasonContent(undefined);
        }
    }, [open]);

    if (!checkPrivilege('offline.offline_order.offline_order_cancel')) {
        return null;
    }

    /** 取消订单请求 */
    async function cancelOrder() {
        if (!reasonId) {
            AntMessage.error(services.language.getText('order.pleaseSelectCancelOrderReason'));
            return;
        }
        try {
            confirmLoadingActions.toggle();
            await services.api.put(
                {
                    id: reasonId,
                    remark: trim(reasonContent),
                    accessCode: accessCodeRef.current,
                },
                { apiPath: `/base-order/admin/order/${id}/offline_cancel` }
            );
            entity.search(entity.params);
            parentProps.fetchStatistics();
            AntMessage.success(services.language.getText('order.orderCancelSuccess'));
        } catch (err) {
            errorHandle(err as ErrorInfo);
        } finally {
            openActions.toggle();
            confirmLoadingActions.toggle();
        }
    }

    /** 验证授权码 */
    function accessCodeVerify() {
        const confirm = AntModal.confirm({
            icon: null,
            title: (
                <div style={{ textAlign: 'center' }}>
                    {services.language.getText('offlineOrder.requiredAuthorization')}
                </div>
            ),
            content: (
                <AccessModal
                    getValue={(v = '') => {
                        confirm.update({
                            okButtonProps: {
                                disabled: v.length !== 4,
                            },
                        });
                        accessCodeRef.current = v;
                    }}
                />
            ),
            okButtonProps: {
                disabled: true,
            },
            onOk: openModal,
        });
    }

    async function fetchOptions() {
        try {
            fetchOptionsLoadingActions.toggle();

            const [reasons, linkedOrderData] = await Promise.all([
                services.api.get<AnyObject[]>(
                    {},
                    {
                        apiPath: '/base-order/admin/orders/cancel_reasons',
                    }
                ),
                services.api.get<LinkedOrderOption[]>(
                    {},
                    {
                        apiPath: `/base-order/admin/order/${id}/getLinkedOrder`,
                    }
                ),
            ]);

            setReasons(
                reasons.map((item) => ({
                    id: item.id,
                    name: item.nameZh,
                }))
            );
            setLinkedOrderData(linkedOrderData);
        } catch (e) {
            services.errorHandle(e as ErrorInfo);
        } finally {
            fetchOptionsLoadingActions.toggle();
        }
    }

    async function openModal() {
        if (!reasons.length) {
            await fetchOptions();
        }
        openActions.toggle();
    }

    return (
        <>
            <Button
                style={{
                    marginRight: '15px',
                }}
                loading={fetchOptionsLoading}
                onClick={async () => {
                    const user = await services.api.get<AnyObject>(
                        {},
                        {
                            apiPath: '/dc-user/admin/login_user',
                        }
                    );
                    if (user?.userJob?.key === 1) {
                        openModal();
                    } else {
                        accessCodeVerify();
                    }
                }}
            >
                {services.language.getText('order.cancelOrder')}
            </Button>
            <AntModal
                title={services.language.getText('order.conformCancelOrder')}
                visible={open}
                confirmLoading={confirmLoading}
                onOk={cancelOrder}
                onCancel={() => {
                    openActions.toggle();
                }}
            >
                <OrderStatusMsg
                    listData={linkedOrderData}
                    msg={services.language.getText('order.orderStatusMsg')}
                />
                <Select
                    style={{ width: '100%', marginBottom: 15 }}
                    placeholder={services.language.getText('order.pleaseSelectCancelOrderReason')}
                    value={reasonId}
                    onChange={setReasonId}
                >
                    {map(reasons, (item) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
                <Input.TextArea
                    value={reasonContent}
                    placeholder={services.language.getText('order.cancelOrderDescPlaceholder')}
                    rows={3}
                    onChange={(e) => setReasonContent(e.target.value)}
                    maxLength={200}
                />
            </AntModal>
        </>
    );
};

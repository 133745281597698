import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '@/configs/mixin';
import {
    EVENT_STATUS_OPTIONS,
    EVENT_SHOW_STATUS_OPTIONS,
    ACQUISITION_METHOD_OPTIONS,
} from '@/constants';
import { buildPropertiesByLanguage } from '@/services/language-utils';
import { buildForm } from './coupon-center-form';

export const config: Config = {
    entities: {
        couponCenter: {
            apiPath: '/loader/dc-ecoupon/admin/voucherCenter',
            properties: {
                id: { type: 'string' },
                ...buildPropertiesByLanguage('name', {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewData.name>>',
                }),
                name: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewData.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 100,
                    },
                },
                effectTime: {
                    type: 'object.dateTimeRange',
                    displayName: '<<couponManage.couponCenter.viewData.effectTime>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewData.effectiveStartTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewData.effectiveEndTime>>',
                },
                type: {
                    type: 'string.options.radio',
                    displayName: '<<couponManage.couponCenter.viewData.type>>',
                    options: ACQUISITION_METHOD_OPTIONS,
                },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewData.stores>>',
                },
                storeIds: {
                    type: 'string.treeNode.cascader',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                    },
                    rules: [
                        {
                            required: true,
                            message: '<<couponManage.voucherManagement.selectStore>>',
                        },
                    ],
                    displayName: '<<couponManage.couponCenter.viewData.storeIds>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<couponManage.couponCenter.viewData.status>>',
                },
                sort: {
                    type: 'number',
                    displayName: '<<couponManage.couponCenter.viewData.sort>>',
                    controlConfig: {
                        placeholder: '<<couponManage.couponCenter.edit.sortPlaceholder>>',
                    },
                },
                point: {
                    type: 'number',
                    displayName: '<<couponManage.couponCenter.viewData.point>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        min: 0,
                        max: 99999,
                    },
                },
                ...buildPropertiesByLanguage('description', {
                    type: 'string.text.paragraph',
                    displayName: '<<couponManage.couponCenter.viewData.description>>',
                }),
                ...buildPropertiesByLanguage('disclaimer', {
                    type: 'string.text.paragraph',
                    displayName: '<<couponManage.couponCenter.viewData.disclaimer>>',
                }),
                couponId: {
                    type: 'string.couponCenterItems',
                    displayName: '<<couponManage.couponCenter.viewData.couponId>>',
                },
                businessType: {
                    type: 'string.viewText',
                    displayName: '<<couponManage.couponCenter.viewData.businessType>>',
                },
            },
            filters: {
                onlineStoreId: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<couponManage.couponCenter.viewData.stores>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<couponManage.couponCenter.viewData.name>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<couponManage.couponCenter.viewData.status>>',
                    // @ts-ignore
                    options: EVENT_STATUS_OPTIONS,
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            },
        },
    },
    components: {
        CouponCenterView: {
            component: 'Viewport',
        },
        CouponCenterPage: {
            entity: 'couponCenter',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'onlineStoreId',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                        {
                            property: 'name',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderInput>>',
                            },
                        },
                        {
                            property: 'status',
                            controlConfig: {
                                allowClear: true,
                                showSearch: true,
                                placeholder: '<<defaultPlaceholderSelect>>',
                            },
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<couponManage.couponCenter.add>>',
                            route: '/coupon-center/create',
                            privilege: {
                                path: 'coupon.center.add',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'CouponCenterTable',
                    privilege: {
                        path: 'coupon.center.list',
                        level: 'full',
                    },
                },
            ]),
        },
        CouponCenterTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'onlineStoreName',
                },
                {
                    property: 'type',
                    displayConfig: {
                        statusConfig: ACQUISITION_METHOD_OPTIONS,
                    },
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: EVENT_SHOW_STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                true: '/dc-ecoupon/admin/voucherCenter/{{row.id}}/disable',
                                false: '/dc-ecoupon/admin/voucherCenter/{{row.id}}/enable',
                            },
                            statusText: {
                                true: '<<disable>>',
                                false: '<<enable>>',
                            },
                            linkProperty: 'enable',
                            errorStatus: true,
                        },
                        privilege: {
                            path: 'coupon.center.enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/coupon-center/edit/{{row.id}}',
                        privilege: {
                            path: 'coupon.center.update',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<couponManage.couponCenter.viewTitle>>',
                        type: 'link',
                        path: '/coupon-center/data/{{row.id}}',
                        privilege: {
                            path: 'coupon.center.data',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        CouponCenterAddPage: {
            component: 'Card',
            entity: 'couponCenter',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        CouponCenterEditPage: {
            component: 'Card',
            entity: 'couponCenter',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
    },
};

/**
 * 组件声明
 */
export type Component = {
    /**
     * 组件名称
     */
    component: string;
    /**
     * 组件配置
     */
    [key: string]: any;
};

import React, { PureComponent, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
import { interpolate } from '@comall-backend-builder/core/lib/services';

import './index.less';
import { isEmpty } from 'lodash';

/**
 * 表格操作项中的导入按钮功能
 */
export class TableActionImport extends PureComponent<any, any> {
    render() {
        let {
            className,
            row,
            entity,
            text,
            apiPath,
            onUploadComplete,
            style,
            ...uploadProps
        } = this.props;
        if (isEmpty(row)) return null;

        const props = {
            className,
            entity,
            apiPath: interpolate(apiPath, { row }),
            triggerComponent: {
                component: 'Button',
                type: 'link',
                children: text,
            },
            ...uploadProps,
            onUploadComplete(response: any) {
                onUploadComplete(response, row);
            },
        };
        return <span style={style}>{createElement(ComponentsManager.get('Upload'), props)}</span>;
    }
}

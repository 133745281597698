/*
 * @Author: zhulu
 * @Date: 2022-05-27 13:53:42
 * @Description: 运费管理form
 */
import { ComponentsManager, actions } from '@comall-backend-builder/core';
import { store } from '@comall-backend-builder/core/lib/store';
import { language } from '@comall-backend-builder/core/lib/services';
import React, { createElement, useEffect } from 'react';

// 自提|'配送'|'快递'
type FreightType = 'pickup' | 'distribution' | 'courier';
enum FreightTypeEnum {
    'pickup' = '1',
    'distribution' = '2',
    'courier' = '3',
}
const DistributionForm = (
    props: {
        params: {
            freightType: FreightType;
            [key: string]: any;
        };
    } & { [key: string]: any }
) => {
    const { entity, params } = props;
    useEffect(() => {
        store.dispatch(
            actions.formChangeAction(
                'DataFormPlus-111',
                'freightType',
                FreightTypeEnum[params.freightType]
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.freightType, entity]);

    return (
        <>
            {createElement(ComponentsManager.get('CreateFormPlus'), {
                componentId: '111',
                entity,
                labelCol: 6,
                controlCol: 8,
                footer: {
                    items: [
                        {
                            type: 'default',
                            text: language.getText('cancel'),
                            htmlType: 'button',
                            route: 'goBack',
                            style: {
                                marginRight: 10,
                            },
                        },
                        {
                            text: language.getText('submit'),
                        },
                    ],
                    style: {
                        textAlign: 'right',
                    },
                },
                submitSuccessBehavior: {
                    route: 'goBack',
                },
                fields: [
                    { property: 'freightType', className: 'hidden' },
                    {
                        property: 'merchantSelect',
                        visible: (values: any) => props.roleType === '2',
                    },
                    {
                        property: 'onlineStoreSelect',
                        visible: (values: any) => props.roleType === '1',
                    },

                    { property: 'minConsumeAmount' },
                    {
                        property: 'amountStatsRange',
                        visible: (values: any) => props.roleType === '1',
                    },
                    { property: 'unsatisfiedBaseFreight' },
                    { property: 'baseFreight' },
                    { property: 'weightStepFreigh' },
                    {
                        property: 'regionExtraFreightVo',
                        visible: (values: any) => FreightTypeEnum[params.freightType] !== '1',
                    },
                    {
                        property: 'businessRulesVo',
                        visible: (values: any) => FreightTypeEnum[params.freightType] === '2',
                    },
                    {
                        property: 'cutOrderTime',
                        visible: (values: any) => FreightTypeEnum[params.freightType] === '2',
                    },
                    {
                        property: 'earliestDeliveryDate',
                        controlConfig: {
                            text: language.getText('storeFreightRules.afterDay'),
                            prefixText: language.getText('storeFreightRules.orderDate'),
                            min: 0,
                            max: 999999,
                        },
                        visible: (values: any) => FreightTypeEnum[params.freightType] === '2',
                    },
                    {
                        property: 'latestDeliveryDate',
                        controlConfig: {
                            text: language.getText('storeFreightRules.DeliveryDayTip'),
                            min: 1,
                            max: 999999,
                        },
                        visible: (values: any) => FreightTypeEnum[params.freightType] === '2',
                    },
                    {
                        property: 'excludeDateList',
                        visible: (values: any) => FreightTypeEnum[params.freightType] === '2',
                    },
                ],
                ...props,
            })}
        </>
    );
};
export { DistributionForm };

import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 商品类型
 */
export enum PRODUCT_TYPE_VALUES {
    /**
     * 换购
     */
    ASSEMBLY_GOODS = 1,
    /**
     * 赠品
     */
    GIVEAWAY = 3,
    /**
     * 换购
     */
    EXCHANGE_GOODS = 4,
}

/**
 * 商品类型名
 */
export const PRODUCT_TYPE_NAMES = {
    [PRODUCT_TYPE_VALUES.GIVEAWAY]: language.getText('productSourceType.giveaway'),
    [PRODUCT_TYPE_VALUES.EXCHANGE_GOODS]: language.getText('productSourceType.exchange'),
};

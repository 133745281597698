import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';

import './index.less';

const formFooter = {
    items: [
        {
            type: 'default',
            text: '<<back>>',
            htmlType: 'button',
            route: 'goBack',
            style: {
                marginRight: 10,
            },
        },
        {
            text: '<<submit>>',
        },
    ],
    style: {
        textAlign: 'right',
    },
};

export const config: Config = {
    entities: {
        memberSourceInfo: {
            apiPath: '/loader/dc-user/admin/memberSource',
            properties: {
                source: {
                    type: 'string',
                    displayName: '<<memberSource.source>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 60,
                    },
                    rules: [
                        { required: true, whitespace: true },
                        {
                            validator(_: never, value: string, callback: Function) {
                                console.log('value', value);
                                const regex = /^[A-Za-z0-9]+$/;
                                if (!regex.test(value)) {
                                    return callback(
                                        services.language.getText(
                                            'memberSource.sourceValidatorTips'
                                        )
                                    );
                                }
                                callback();
                            },
                        },
                    ],
                },
                remark: {
                    displayName: '<<memberSource.remark>>',
                    type: 'string.text.paragraph',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        maxLength: 60,
                    },
                },
                dchLivingLogo: {
                    type: 'array.brandImage',
                    displayName: '<<memberSource.dchlivingLogo>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1024,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.logoLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                        limitTips: {
                            className: 'hidden',
                        },
                        accept: 'image/png,image/jpg,image/jpeg',
                    },
                },
                foodMartLogo: {
                    type: 'array.brandImage',
                    displayName: '<<memberSource.foodmartLogo>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 5 * 1024,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: '<<brandEdit.logoLimitSize>>',
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                        limitTips: {
                            className: 'hidden',
                        },
                        accept: 'image/png,image/jpg,image/jpeg',
                    },
                },
                sourceLogo: {
                    type: 'array.brandImage',
                    displayName: '<<memberSource.sourceIcon>>',
                    controlConfig: {
                        mode: 'picture-card',
                        uploadType: 'single',
                        multiple: false,
                        maxCount: 1,
                        maxSize: 3 * 1024,
                        fileName: 'fileName',
                        uploadCompleteMessage: null,
                        uploadLimitSizeErrorMessage: services.interpolate(
                            services.language.getText('uploadImageVideo.error.img.size'),
                            { maxSize: 3 }
                        ),
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                        limitTips: {
                            className: 'hidden',
                        },
                        accept: 'image/png,image/jpg,image/jpeg',
                    },
                },
            },
        },
    },
    components: {
        MemberSourcePage: {
            component: 'Card',
            content: {
                component: 'MemberSourceForm',
            },
        },
        MemberSourceForm: {
            component: 'CreateFormPlus',
            entity: 'memberSourceInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            className: 'none-sub-form brand-edit-from',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                {
                    property: 'source',
                },
                { property: 'remark' },
                {
                    property: 'dchLivingLogo',
                },
                {
                    property: 'foodMartLogo',
                },
                {
                    property: 'sourceLogo',
                },
            ],
        },
        MemberSourceEditPage: {
            component: 'Card',
            loaderType: 'get',
            content: {
                component: 'MemberSourceEditForm',
            },
        },
        MemberSourceEditForm: {
            component: 'ModifyFormPlus',
            entity: 'memberSourceInfo',
            className: 'none-sub-form brand-edit-from',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            loaderType: 'get',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                {
                    property: 'source',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'remark' },
                {
                    property: 'dchLivingLogo',
                },
                {
                    property: 'foodMartLogo',
                },
                {
                    property: 'sourceLogo',
                },
            ],
        },
    },
};

/*
 * @Author: zhulu
 * @Description: override-code 商品
 */
import { services } from '@comall-backend-builder/core';
import React, { useEffect, useState } from 'react';
import { cloneDeep, find, isEmpty } from 'lodash';
import { InputNumber, Table, Button } from 'antd';
import SelectAutoComplete from '@/components/select-auto-complete';
import { isEqual } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
const EditableContext = React.createContext({});

class EditableCell extends React.Component<any, any> {
    renderCell = (params: any) => {
        const {
            editable,
            dataIndex,
            title,
            inputType,
            record,
            children,
            onChange,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <InputNumber
                        style={{ width: '240px' }}
                        placeholder={language.getText('defaultPlaceholderInput')}
                        value={record[dataIndex]}
                        type='number'
                        min={0.01}
                        max={999999.99}
                        onChange={(value) => onChange(record.index, value, dataIndex)}
                    />
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}
export const Product = React.forwardRef((props: any) => {
    const [dataSource, setDataSource] = useState<any[]>([]);
    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }
        let nDataSource: any[] = (props.value || []).map((item: any, index: number) => {
            let dataSourceItem = find(dataSource, (i) => i.id === item.id);
            let v = {
                ...dataSourceItem,
                ...item,
                index,
            };
            return v;
        });
        setDataSource([...nDataSource]);
        if (!isEqual(props.value, nDataSource) && props.value) {
            props.onChange(nDataSource, props.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);
    const onChange = (index: any, val: any, dataIndex: any) => {
        let nVal: any = cloneDeep(dataSource);
        nVal[index][dataIndex] = val;
        props.onChange(nVal);
    };
    const columns = [
        {
            title: language.getText('overrideCode.index'),
            dataIndex: 'index',
            render: (text: string, recode: any, index: number) => {
                return <>{text + 1}</>;
            },
        },
        {
            title: language.getText('overrideCode.productName'),
            dataIndex: 'name',
        },
        {
            title: 'SKU',
            dataIndex: 'csku',
        },
        {
            title: language.getText('overrideCode.discountPrice'),
            dataIndex: 'priceReduction',
            editable: true,
        },
        {
            title: language.getText('actions'),
            dataIndex: 'actions',
            render: (text: any, record: any, index: number) => {
                return (
                    <Button
                        type='link'
                        onClick={() => {
                            let nVal: any = cloneDeep(dataSource);
                            nVal.splice(record.index, 1);
                            props.onChange(nVal);
                        }}
                    >
                        {language.getText('delete')}
                    </Button>
                );
            },
        },
    ].map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editable: col.editable,
                onChange: onChange,
            }),
        };
    });
    return (
        <>
            <SelectAutoComplete
                mode='multiple'
                value={props.value}
                disabled={props.disabled || false}
                placeholder={services.language.getText('defaultPlaceholderSelect')}
                apiPath='/loader/dc-goods/admin/cskus/queryCskuByPage'
                params={{ page: 1, perPage: 10 }}
                selectParamKey='keyword'
                onChange={(val: any) => {
                    console.log('val', val);
                    props.onChange(val);
                }}
            />
            <EditableContext.Provider value={{}}>
                <Table
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={(_) => _.id}
                    pagination={false}
                />
            </EditableContext.Provider>
        </>
    );
});

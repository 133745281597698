import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { getLanguageColumns } from '../../mixin';

function createFields(type: 'view' | 'edit') {
    return [
        {
            property: 'id',
            modifiable: false,
        },
        ...getLanguageColumns('nameMap', { modifiable: type === 'edit' }),
        ...getLanguageColumns('deliveryMap', { modifiable: type === 'edit' }),
        { property: 'orderEmail', modifiable: type === 'edit' },
        { property: 'logo', modifiable: type === 'edit' },
        ...getLanguageColumns('descriptionMap', { modifiable: type === 'edit' }),
        { property: 'banner', modifiable: type === 'edit' },
        { property: 'transportType', modifiable: type === 'edit' },
    ];
}

export const config: Config = {
    components: {
        EditMerchantsStorePage: {
            component: 'FlexLayout',
            entity: 'merchantsStore',
            loaderType: 'get',
            direction: 'vertical',
            items: [
                {
                    component: 'EditMerchantsStoreInfo',
                },
            ],
        },
        EditMerchantsStoreInfo: {
            component: 'ModifyFormPlus',
            fields: createFields('edit'),
            className: 'none-sub-form',
            labelCol: 8,
            controlCol: 9,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('saveFully'));
                services.navigation.goto('/businessCooperator');
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        type: 'default',
                        text: '<<cancel>>',
                        style: {
                            marginRight: 20,
                        },
                    },
                    {
                        type: 'primary',
                        text: '<<save>>',
                    },
                ],
                style: {
                    textAlign: 'right',
                    marginTop: 20,
                },
            },
        },
        ViewMerchantsStorePage: {
            component: 'FlexLayout',
            entity: 'merchantsStore',
            loaderType: 'get',
            direction: 'vertical',
            items: [
                {
                    component: 'ViewMerchantsStoreInfo',
                },
            ],
        },
        ViewMerchantsStoreInfo: {
            component: 'ModifyFormPlus',
            fields: createFields('view'),
            className: 'none-sub-form',
            labelCol: 8,
            controlCol: 9,
        },
    },
};

import React, { useState } from 'react';
import { Row as AntRow, Col as AntCol, Button, Checkbox, Card } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';
import classNames from 'classnames';
import { isEmpty, isNumber } from 'lodash';
import { ProductGiftItem, SpecsType, codeType } from './group-items';
import GiftSelector from './gift-selector';
import './index.less';

export default (props: any) => {
    const normalCartBags = props.cartData?.cartBags?.filter((item: any) => item.type !== 300) || [];
    const exchangeProducts = props.cartData?.cartBags?.find((item: any) => item.type === 300) || [];

    return (
        <div>
            {normalCartBags.map((bagItem: any) => {
                return <NormalProductGroupItem {...props} {...bagItem} />;
            })}
            {exchangeProducts && <ExchangeProducts {...props} {...exchangeProducts} />}
        </div>
    );
};

function NormalProductGroupItem(props: any) {
    const { disLabel, discountAmount, items = [], promotionId, giftProducts } = props;
    const displayGiftProducts =
        giftProducts?.[0]?.giftProducts?.filter((item: any) => item.selected) || [];
    const [open, setOpen] = useState(false);

    return (
        <Card
            className='offline-order-cart-bag-item'
            title={
                !!promotionId && (
                    <div className='offline-order-promotion-header'>
                        <div className='offline-order-exchange-title'>{disLabel}</div>
                        {Boolean(giftProducts?.[0]?.giftProducts?.length) ? (
                            <Button type='link' onClick={() => setOpen(true)}>
                                {giftProducts[0].productType === 1
                                    ? language.getText('offlineOrder.selectGoods.selectGift')
                                    : language.getText('offlineOrder.selectGoods.exchangeGift')}
                            </Button>
                        ) : (
                            Boolean(discountAmount?.display) && (
                                <span>
                                    {discountAmount.name}：
                                    <span style={{ color: 'red' }}>${discountAmount.amount}</span>
                                </span>
                            )
                        )}
                    </div>
                )
            }
        >
            <div className='offline-create-goods-list' key={promotionId}>
                {items.map((item: any) => (
                    <GoodsItem {...props} key={item.id + item.csku} goods={item} />
                ))}
            </div>
            <div className='offline-create-goods-list'>
                {displayGiftProducts.map((item: any) => {
                    return (
                        <ProductGiftItem
                            key={item.id + item.csku + item.name}
                            {...props}
                            giftItem={item}
                            renderHandler={() => (
                                <div className='offline-create-goods-item-receive'>
                                    <Checkbox
                                        checked={!!item.isPickedUp}
                                        disabled={item.pickupDisabled}
                                        onChange={(e: any) => props.pickUpGood(e, item)}
                                    >
                                        {language.getText('offlineOrder.create.pickedGoods')}
                                    </Checkbox>
                                </div>
                            )}
                            tag={
                                giftProducts[0].productType === 2
                                    ? language.getText('offlineOrder.selectGoods.exchangeGood')
                                    : language.getText('offlineOrder.selectGoods.giftGood')
                            }
                        />
                    );
                })}
            </div>
            {Boolean(giftProducts?.[0]) && (
                <GiftSelector
                    {...props}
                    {...giftProducts[0]}
                    title={disLabel}
                    mainProducts={items}
                    visible={open}
                    onOpenChange={() => {
                        setOpen(false);
                    }}
                />
            )}
        </Card>
    );
}

function ExchangeProducts(props: any) {
    const { disLabel, items = [] } = props;

    function handleCheck(e: any, goods: any) {
        const checked = e.target.checked;

        if (checked) {
            props.addGood(goods, true);
        } else {
            props.removeGood(goods, true);
        }
    }

    if (isEmpty(items)) return null;

    return (
        <Card
            className='offline-order-cart-bag-item'
            title={<span className='offline-order-exchange-title'>{disLabel}</span>}
        >
            {items?.map((goods: any) => (
                <div className='offline-create-goods-list-exchange'>
                    <Checkbox
                        className='offline-create-goods-list-exchange-check'
                        disabled={goods?.disabled || goods?.isSellOut}
                        checked={goods.selected}
                        onChange={(e) => handleCheck(e, goods)}
                    />
                    <GoodsItem
                        {...props}
                        hideDelete
                        pickupDisabled={!goods.selected}
                        key={goods.csku + goods.id}
                        goods={goods}
                    />
                </div>
            ))}
        </Card>
    );
}

const GoodsItem = (props: any) => {
    const { goods, hideDelete } = props;
    const mergedGiftProducts =
        goods.giftProducts?.flatMap((item: AnyObject) => item.giftProducts) || [];

    return (
        <>
            <div
                className={classNames('offline-create-goods-item', {
                    'offline-create-goods-item-sell-out': goods.isSellOut,
                })}
                key={goods.id}
            >
                <AntRow>
                    <AntCol span={4}>
                        <AntRow>
                            <div className='product-img'>
                                <img src={goods.pic} />
                                {goods.isSellOut && (
                                    <div className='product-sell-out'>
                                        {language.getText('offlineOrder.selectGoods.sellOut')}
                                    </div>
                                )}
                            </div>
                        </AntRow>
                    </AntCol>
                    <AntCol span={16}>
                        <AntCol span={24}>
                            <AntRow>
                                <span>
                                    {language.getText('productList.productName')}：
                                    {goods?.name ?? '-'}
                                </span>
                            </AntRow>
                        </AntCol>
                        <AntCol span={24}>
                            <AntCol span={8}>
                                <AntRow>
                                    {language.getText('productList.csku')}: {goods?.csku ?? '-'}
                                </AntRow>
                                <AntRow>
                                    {language.getText('picking.price')}：
                                    <span>{`$${goods.salePrice}`}</span>
                                </AntRow>
                            </AntCol>
                            <AntCol span={8}>
                                <AntRow>
                                    {language.getText('productList.barCode')}：
                                    {goods?.barCode || goods?.barcode || '-'}
                                </AntRow>
                                <AntRow>
                                    {language.getText('productList.merchantName')}：
                                    {goods?.merchantName ?? '-'}
                                </AntRow>
                            </AntCol>
                            <AntCol span={8}>
                                <AntRow>
                                    {language.getText('productList.specsType')}：
                                    {isNumber(goods?.specsType) ? SpecsType[goods?.specsType] : '-'}
                                </AntRow>
                                <AntRow>
                                    {language.getText('offlineOrder.purchasesNum')}：
                                    {goods.quantity ?? '-'}
                                </AntRow>
                            </AntCol>
                        </AntCol>
                        {goods?.overrideCode ? (
                            <AntCol span={24}>
                                <AntRow style={{ color: '#000' }}>
                                    OVVERRIDECODE: {goods?.overrideCode ?? '-'}
                                </AntRow>
                                <AntRow style={{ color: '#000' }}>
                                    {language.getText('offlineOrder.create.codeType')}：
                                    {goods?.codeType || goods?.codeType === 0
                                        ? codeType[goods.codeType]
                                        : '-'}
                                </AntRow>
                                <AntRow style={{ color: '#000' }}>
                                    {language.getText('offlineOrder.create.codePrice')}：
                                    {goods?.overridePrice
                                        ? Number(goods.overridePrice).toFixed(2)
                                        : '-'}
                                </AntRow>
                            </AntCol>
                        ) : null}
                    </AntCol>
                    <AntCol className='offline-create-goods-item-action' span={4}>
                        {!hideDelete && (
                            <Button
                                type='link'
                                onClick={() => {
                                    props.removeGood(goods);
                                }}
                            >
                                {language.getText('offlineOrder.create.removeGood')}
                            </Button>
                        )}
                        {
                            <div className='offline-create-goods-item-receive'>
                                <Checkbox
                                    checked={!!goods.isPickedUp}
                                    disabled={props.pickupDisabled}
                                    onChange={(e: any) => props.pickUpGood(e, goods)}
                                >
                                    {language.getText('offlineOrder.create.pickedGoods')}
                                </Checkbox>
                            </div>
                        }
                    </AntCol>
                </AntRow>
                {mergedGiftProducts?.map((item: AnyObject) => (
                    <ProductGiftItem
                        key={item.id + item.csku + item.name}
                        {...props}
                        giftItem={item}
                        renderHandler={() => (
                            <div className='offline-create-goods-item-receive'>
                                <Checkbox
                                    checked={!!item.isPickedUp}
                                    disabled={item.pickupDisabled}
                                    onChange={(e: any) => props.pickUpGood(e, item)}
                                >
                                    {language.getText('offlineOrder.create.pickedGoods')}
                                </Checkbox>
                            </div>
                        )}
                    />
                ))}
            </div>
        </>
    );
};

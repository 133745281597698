import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        BrandsInfoPage: {
            component: 'FlexLayout',
            entity: 'brandInfo',
            loaderType: 'get',
            direction: 'vertical',
            items: [
                {
                    component: 'BrandsInfo',
                },
            ],
        },
        BrandsInfo: {
            component: 'Detail',
            fields: [
                {
                    property: 'searchCode',
                },
                {
                    property: 'name',
                },
                {
                    property: 'ename',
                },
                {
                    property: 'description',
                },
                {
                    property: 'logoTop',
                },
                {
                    property: 'logoFeature',
                },
                {
                    property: 'logoBottom',
                },
                {
                    property: 'logoBanner',
                },
            ],
            className: 'brands-info',
            align: 'left',
            labelCol: 24,
            controlCol: 24,
        },
    },
};

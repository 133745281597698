import { ACQUISITION_METHOD_VALUES } from '@/constants';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';

function transFormParams(data: any) {
    data.startTime = data.effectTime.start;
    data.endTime = data.effectTime.end;
    data.onlineStoreId = data.storeIds;
    data.type = data.type === ACQUISITION_METHOD_VALUES.RECEIVE ? 0 : 1;
    delete data.effectTime;
    delete data.storeIds;
    return data;
}

export default [
    {
        key: '/dc-ecoupon/admin/voucherCenter',
        loader: {
            post: async function(data: any, config: any) {
                return await services.api.post(
                    { ...transFormParams(data) },
                    { ...config, apiPath: '/dc-ecoupon/admin/voucherCenter' }
                );
            },
            put: async function(data: any, config: any) {
                return await services.api.put(
                    { ...transFormParams(data) },
                    { ...config, apiPath: `/dc-ecoupon/admin/voucherCenter/${data.id}` }
                );
            },
            get: async function(data: any, config: any) {
                if (data.id) {
                    const res: any = await services.api.get(
                        { ...data },
                        { ...config, apiPath: `/dc-ecoupon/admin/voucherCenter/${data.id}` }
                    );
                    res.effectTime = { start: res.startTime, end: res.endTime };
                    res.storeIds = res.onlineStoreId;
                    res.type =
                        res.type === 0
                            ? ACQUISITION_METHOD_VALUES.RECEIVE
                            : ACQUISITION_METHOD_VALUES.POINT_REDEMPTION;
                    res.couponId = [res.couponVo];
                    return res;
                } else {
                    const res: any = await services.api.get(
                        { ...data },
                        { ...config, apiPath: '/dc-ecoupon/admin/voucherCenter/list' }
                    );

                    if (!isEmpty(res.result)) {
                        const arr = res.result.map((item: any) => ({
                            ...item,
                            name: item.nameZh,
                            type:
                                item.type === 0
                                    ? ACQUISITION_METHOD_VALUES.RECEIVE
                                    : ACQUISITION_METHOD_VALUES.POINT_REDEMPTION,
                        }));
                        return {
                            ...res,
                            result: arr,
                        };
                    } else {
                        return res;
                    }
                }
            },
        },
    },
    {
        key: '/dc-ecoupon/admin/voucherCenter/getData',
        loader: {
            get: async function(data: any, config: any) {
                const res: any = await services.api.get(
                    { ...data },
                    { ...config, apiPath: `/dc-ecoupon/admin/voucherCenter/getData/${data.id}` }
                );

                return res;
            },
        },
    },
];

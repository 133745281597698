import React from 'react';
import { PickingCard } from './base-content';
/**
 * 取消的订单
 */

export function CancelledOrderItem(props: any) {
    return (
        <PickingCard
            data={props}
            type='order'
            orderPathname={props.parentProps.orderPathname}
            pickPathname={props.parentProps.pickPathname}
        />
    );
}

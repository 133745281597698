import { DISTRIBUTION_AMOUNT_STATUS_OPTIONS, DISTRIBUTION_AMOUNT_STATUS_VALUES } from '@/constants';
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { isNumber } from 'lodash';
import { formFooter } from '../mixin';

function checkRegionExtraFreight(value: any = []) {
    for (const item of value) {
        if (!item.ids) {
            message.error(services.language.getText('storeFreightRules.regionExtraFreightErr'));
            return false;
        }
        if (!isNumber(item.extraFreight) || item.extraFreight < 0) {
            message.error(services.language.getText('storeFreightRules.extraFreightErr'));
            return false;
        }
    }
    return true;
}

function checkStartingFreight(value: any) {
    // if (!value) {
    //     message.error(services.language.getText('storeFreightRules.startingWeightErr'));
    //     return false;
    // }
    return true;
}

function checkStepWeight(value: any) {
    // if (!value) {
    //     message.error(services.language.getText('storeFreightRules.stepWeightErr'));
    //     return false;
    // }
    return true;
}
function checkStepFreight(value: any) {
    // if (!value) {
    //     message.error(services.language.getText('storeFreightRules.stepFreightErr'));
    //     return false;
    // }
    return true;
}

function onValidate(data: AnyObject) {
    if (
        !checkRegionExtraFreight(data.regionExtraFreightVo) ||
        !checkStartingFreight(data.weightStepFreigh.startingWeight) ||
        !checkStepWeight(data.weightStepFreigh.stepWeight) ||
        !checkStepFreight(data.weightStepFreigh.stepFreight)
    ) {
        return false;
    }
    return true;
}

export const config: Config = {
    entities: {
        storeFreightRulesEdit: {
            apiPath: '/loader/dc-goods/admin/store/freight/edit',
            properties: {
                id: { type: 'string' },
                freightType: { type: 'string' },
                merchantSelect: { type: 'string' },
                onlineStoreSelect: {
                    type: 'string.treeNode.cascader',
                    displayName: '<<storeFreightRules.onlineStoreName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                    controlConfig: {
                        allowClear: true,
                    },
                    rules: [{ required: true, message: '请选择门店' }],
                },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<storeFreightRules.onlineStoreName>>',
                },
                onlineStoreCode: {
                    type: 'string',
                    displayName: '<<storeFreightRules.onlineStoreCode>>',
                },
                minConsumeAmount: {
                    type: 'number.float',
                    displayName: '<<storeFreightRules.minConsumeAmount>>',
                    rules: [
                        {
                            required: true,
                            message: '<<storeFreightRules.minConsumeAmountNotEmpty>>',
                        },
                    ],
                    extra: '<<storeFreightRules.minConsumeAmountTips>>',
                    controlConfig: {
                        max: 99999999.99,
                    },
                },
                unsatisfiedBaseFreight: {
                    type: 'number.float',
                    displayName: '<<storeFreightRules.unsatisfiedBaseFreight>>',
                    rules: [
                        {
                            required: true,
                            message: '<<storeFreightRules.unsatisfiedBaseFreightNotEmpty>>',
                        },
                    ],
                    controlConfig: {
                        max: 99999999.99,
                        min: 0,
                    },
                },
                baseFreight: {
                    type: 'number.float',
                    displayName: '<<storeFreightRules.baseFreight>>',
                    rules: [
                        {
                            required: true,
                            message: '<<storeFreightRules.baseFreightNotEmpty>>',
                        },
                    ],
                    controlConfig: {
                        max: 99999999.99,
                        min: 0,
                    },
                },
                weightStepFreigh: {
                    type: 'weightStepFreigh',
                    displayName: '<<storeFreightRules.weightStepFreigh>>',
                    rules: [{ required: true }],
                },
                regionExtraFreightVo: {
                    type: 'array.delivery.rules',
                    displayName: '配送规则',
                    rules: [{ required: true }],
                    defaultValue: [
                        {
                            deliveryArea: '',
                            extraFreight: 0,
                            id: '1',
                        },
                    ],

                    controlConfig: {
                        isWeighTharging: false,
                    },
                },
                businessRulesVo: {
                    type: 'array.selectTimeRange',
                    displayName: '<<storeFreightRules.businessTimes>>',
                    rules: [{ required: true }],
                    defaultValue: [
                        {
                            id: null,
                            startWeek: 'MONDAY',
                            endWeek: 'SUNDAY',
                            startTime: '10:00',
                            endTime: '22:00',
                        },
                    ],
                    controlConfig: {
                        format: 'HH:mm',
                        maxRangesCount: 7,
                        singleNum: true,
                    },
                },
                cutOrderTime: {
                    type: 'string.pickUpTimes',
                    defaultValue: '16:00',
                    displayName: '<<storeFreightRules.orderDeadlineTime>>',
                    rules: [{ required: true }],
                },
                earliestDeliveryDate: {
                    type: 'number.deliveryDay',
                    displayName: '<<storeFreightRules.earliestDeliveryTime>>',
                    defaultValue: 1,
                    rules: [
                        { required: true },
                        {
                            pattern: /^\d{0,6}$/,
                            message: '<<storeFreightRules.numberError0>>',
                        },
                    ],
                },
                latestDeliveryDate: {
                    type: 'number.deliveryDay',
                    displayName: '<<storeFreightRules.deliveryDate>>',
                    defaultValue: 1,
                    rules: [
                        { required: true },
                        {
                            pattern: /^[1-9]\d{0,5}$/,
                            message: '<<storeFreightRules.numberError>>',
                        },
                    ],
                },
                amountStatsRange: {
                    type: 'string.options.radio',
                    displayName: '<<storeFreightRules.amountStatsRange>>',
                    options: DISTRIBUTION_AMOUNT_STATUS_OPTIONS,
                    defaultValue: DISTRIBUTION_AMOUNT_STATUS_VALUES.SELF_FINANCING,
                },
                excludeDateList: {
                    type: 'array.selectDate',
                    displayName: '<<storeChannels.dischargeTime>>',
                },
            },
        },
    },
    components: {
        StoreFreightRulesEditPage: {
            component: 'Card',
            loaderType: 'get',
            content: {
                component: 'StoreFreightRulesEditForm',
            },
        },
        StoreFreightRulesAddPage: {
            component: 'Card',
            entity: 'storeFreightRulesEdit',
            content: {
                component: 'DistributionForm',
                roleType: '1',
                onValidate,
            },
        },
        StoreFreightRulesEditForm: {
            component: 'ModifyFormPlus',
            entity: 'storeFreightRulesEdit',
            className: 'none-sub-form',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            loaderType: 'get',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                { property: 'id', className: 'hidden' },
                { property: 'freightType', className: 'hidden' },
                {
                    property: 'onlineStoreName',
                    modifiable: false,
                },
                {
                    property: 'onlineStoreCode',
                    modifiable: false,
                },
                { property: 'minConsumeAmount' },
                { property: 'amountStatsRange' },
                { property: 'unsatisfiedBaseFreight' },
                { property: 'baseFreight' },
                { property: 'weightStepFreigh' },
                {
                    property: 'regionExtraFreightVo',
                    visible: (values: any) => values.freightType !== '1',
                },
                {
                    property: 'businessRulesVo',
                    visible: (values: any) => values.freightType === '2',
                },
                {
                    property: 'cutOrderTime',
                    visible: (values: any) => values.freightType === '2',
                },
                {
                    property: 'earliestDeliveryDate',
                    controlConfig: {
                        text: services.language.getText('storeFreightRules.afterDay'),
                        prefixText: services.language.getText('storeFreightRules.orderDate'),
                        min: 0,
                        max: 999999,
                    },
                    visible: (values: any) => values.freightType === '2',
                },
                {
                    property: 'latestDeliveryDate',
                    controlConfig: {
                        text: services.language.getText('storeFreightRules.DeliveryDayTip'),
                        min: 1,
                        max: 999999,
                    },
                    visible: (values: any) => values.freightType === '2',
                },
                {
                    property: 'excludeDateList',
                    visible: (values: any) => values.freightType === '2',
                },
            ],
            onValidate,
        },
    },
};

import React, { useMemo, useState, useEffect, useRef } from 'react';
import pending from './pending.jpg';
import disabled from './disabled.jpg';
import { Privilege } from '../privilege';

export interface VoiceBroadcastProps {
    /**
     * 音频路径
     */
    src: string;
    /**
     * 持续时长
     */
    duration?: number;
    /**
     * 检查是否可以播放
     */
    checkCanPlay?(): Promise<boolean> | boolean;
}

/**
 * 音频广播
 */
export const VoiceBroadcast: React.FC<VoiceBroadcastProps> = (props) => {
    const { src, duration = 5000, checkCanPlay } = props;

    const [status, setStatus] = useState<'pending' | 'playing' | 'disabled'>('disabled');
    const audioEl = useMemo(() => new Audio(), []);

    const durationTimer = useRef<NodeJS.Timeout>();

    // 初始化
    useEffect(() => {
        audioEl.src = src;

        if (status === 'playing') {
            setStatus('pending');
        }
    }, [src]);

    // 根据状态开始播放或者停止播放声音
    useEffect(() => {
        const clean = () => setStatus('pending');

        switch (status) {
            case 'pending':
                audioEl.pause();
                break;
            case 'playing':
                audioEl.currentTime = 0;
                audioEl.muted = false;
                audioEl.play();
                audioEl.addEventListener('ended', clean);
                break;
            case 'disabled':
                audioEl.pause();
                audioEl.muted = true;
                break;
        }

        return () => audioEl.removeEventListener('ended', clean);
    }, [status]);

    // 轮询
    useEffect(() => {
        if (status === 'pending') {
            durationTimer.current = setInterval(async () => {
                const canPlay = await checkCanPlay?.();
                if (canPlay) setStatus('playing');
            }, duration);
        }

        return () => clearInterval(durationTimer.current);
    }, [status, duration]);

    return (
        <Privilege path='order.order.countByUnpick'>
            <img
                style={{
                    width: '20px',
                    cursor: 'pointer',
                }}
                src={status === 'disabled' ? disabled : pending}
                onClick={() => {
                    if (status !== 'disabled') {
                        setStatus('disabled');
                    } else {
                        setStatus('pending');
                    }
                }}
            />
        </Privilege>
    );
};

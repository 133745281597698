import React, { Component, createElement } from 'react';
import { Tabs } from 'antd';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { getLanguageColumns, tableMixin } from '@/configs/mixin';
import { OptionType } from '@/interfaces';
import { KV } from '@/interfaces';
import { SHELF_KEYS, EVALUATION_TABLE_COMPONENT_ID } from '../constants';
import { EVALUATION_LIST_TABLE } from '@/configs/pro-table-constants';
import { StoreTreeSelector } from '@/components/store-tree-selector';
import { Privilege } from '@/components/privilege';

interface Props {
    entities: { [prop: string]: Entity };
    filterConfig: KV;
}

interface State {
    activeKey: string;
    activeIdList: string[];
}

export class EvaluationList extends Component<Props, State> {
    state = {
        activeKey: SHELF_KEYS.NOT_REVIEWED,
        activeIdList: [],
    };

    /**
     * 当前 Entity
     */
    activeEntity: Entity = this.props.entities.commentsNotModerated;

    /**
     * 门店编码集合
     */
    onlineStoreCodes = '';

    onTabChange = (activeKey: string) => {
        if (activeKey === SHELF_KEYS.NOT_REVIEWED) {
            this.activeEntity = this.props.entities.commentsNotModerated;
        }
        if (activeKey === SHELF_KEYS.BE_APPROVED) {
            this.activeEntity = this.props.entities.commentsModerated;
        }
        if (activeKey === SHELF_KEYS.FAILED_PASS) {
            this.activeEntity = this.props.entities.failedToPass;
        }
        this.setState({ activeKey }, () => {
            this.refreshData();
        });
    };

    renderTab = () => {
        const { entities, filterConfig } = this.props;
        const { activeKey, activeIdList } = this.state;

        const createButtons = () => {
            return {
                component: 'FlexLayout',
                direction: 'horizontal',
                style: {
                    display: 'block',
                    marginBottom: 15,
                },
                items: [
                    {
                        text: services.language.getText('commodityEvaluation.batchAudit.approve'),
                        type: 'component',
                        component: 'BatchAuditButton',
                        config: {
                            style: {
                                marginRight: 15,
                            },
                            activeIdList: activeIdList,
                            status: 1,
                        },
                        privilege: {
                            path: 'opinion_management.sku_evaluation.sku_evaluation_batch',
                            level: 'full',
                        },
                    },
                    {
                        text: services.language.getText('commodityEvaluation.batchAudit.reject'),
                        type: 'component',
                        component: 'BatchAuditButton',
                        config: {
                            activeIdList: activeIdList,
                            status: 2,
                        },
                        privilege: {
                            path: 'opinion_management.sku_evaluation.sku_evaluation_batch',
                            level: 'full',
                        },
                    },
                ],
            };
        };

        const createTable = () => {
            return {
                ...tableMixin,
                component: 'ProTable',
                componentName: EVALUATION_LIST_TABLE,
                loadFirstPage: false,
                rowSelection:
                    activeKey === SHELF_KEYS.NOT_REVIEWED
                        ? {
                              type: 'checkbox',
                              onChange: (activeIdList: string[]) => {
                                  this.setState({
                                      activeIdList,
                                  });
                              },
                          }
                        : null,
                scroll: {
                    x: 'max-content',
                },
                columns: [
                    ...getLanguageColumns('skuNameMap'),
                    { property: 'sku' },
                    { property: 'content' },
                    { property: 'grade' },
                    { property: 'evaluatorNumber' },
                    { property: 'createTime' },
                    { property: 'orderNumber' },
                ],
                actionColumn: {
                    title: services.language.getText('actions'),
                    fixed: 'right',
                    items:
                        activeKey === SHELF_KEYS.NOT_REVIEWED
                            ? [
                                  {
                                      text: services.language.getText(
                                          'commodityEvaluation.resolve'
                                      ),
                                      type: 'component',
                                      component: 'EvaluationButton',
                                      config: {
                                          type: 'link',
                                          style: {
                                              padding: 0,
                                          },
                                          status: 1,
                                      },
                                      privilege: {
                                          path:
                                              'opinion_management.sku_evaluation.sku_evaluation_update',
                                          level: 'full',
                                      },
                                  },
                                  {
                                      text: services.language.getText('commodityEvaluation.reject'),
                                      type: 'component',
                                      component: 'EvaluationButton',
                                      config: {
                                          type: 'link',
                                          status: 2,
                                      },
                                      privilege: {
                                          path:
                                              'opinion_management.sku_evaluation.sku_evaluation_update',
                                          level: 'full',
                                      },
                                  },
                                  {
                                      text: services.language.getText('view'),
                                      type: 'component',
                                      component: 'EvaluationModal',
                                      config: {
                                          entities,
                                          type: 'link',
                                      },
                                      privilege: {
                                          path:
                                              'opinion_management.sku_evaluation.sku_evaluation_get',
                                          level: 'full',
                                      },
                                  },
                              ]
                            : [
                                  {
                                      text: services.language.getText('view'),
                                      type: 'component',
                                      component: 'EvaluationModal',
                                      config: {
                                          entities,
                                          type: 'link',
                                      },
                                      privilege: {
                                          path:
                                              'opinion_management.sku_evaluation.sku_evaluation_get',
                                          level: 'full',
                                      },
                                  },
                              ],
                },
            };
        };

        // 未审核配置
        const commentsNotModeratedFilterComponent = createElement(
            ComponentsManager.get(filterConfig.component),
            {
                entity: entities.commentsNotModerated,
                ...filterConfig,
            }
        );

        const commentsNotModeratedButtonConfig = createButtons();
        const commentsNotModeratedButtonComponent = createElement(
            ComponentsManager.get(commentsNotModeratedButtonConfig.component),
            {
                entity: entities.commentsNotModerated,
                ...commentsNotModeratedButtonConfig,
            }
        );

        const commentsNotModeratedTableConfig = createTable();
        const commentsNotModeratedComponent = createElement(
            ComponentsManager.get(commentsNotModeratedTableConfig.component),
            {
                entity: entities.commentsNotModerated,
                ...commentsNotModeratedTableConfig,
                componentId: EVALUATION_TABLE_COMPONENT_ID,
            }
        );

        // 审核通过配置
        const commentsModeratedFilterComponent = createElement(
            ComponentsManager.get(filterConfig.component),
            {
                entity: entities.commentsModerated,
                ...filterConfig,
            }
        );

        const commentsModeratedTableConfig = createTable();
        const commentsModeratedComponent = createElement(
            ComponentsManager.get(commentsModeratedTableConfig.component),
            {
                entity: entities.commentsModerated,
                ...commentsModeratedTableConfig,
                componentId: EVALUATION_TABLE_COMPONENT_ID,
            }
        );

        // 审核未通过配置
        const failedToPassFilterComponent = createElement(
            ComponentsManager.get(filterConfig.component),
            {
                entity: entities.failedToPass,
                ...filterConfig,
            }
        );

        const failedToPassTableConfig = createTable();
        const failedToPassComponent = createElement(
            ComponentsManager.get(failedToPassTableConfig.component),
            {
                entity: entities.failedToPass,
                ...failedToPassTableConfig,
                componentId: EVALUATION_TABLE_COMPONENT_ID,
            }
        );

        return (
            <Tabs
                destroyInactiveTabPane
                animated={false}
                activeKey={activeKey}
                onChange={this.onTabChange}
            >
                <Tabs.TabPane
                    tab={services.language.getText('commodityEvaluation.statusMap.waitVerify')}
                    key={SHELF_KEYS.NOT_REVIEWED}
                >
                    {commentsNotModeratedFilterComponent}
                    {commentsNotModeratedButtonComponent}
                    <Privilege path='opinion_management.sku_evaluation.sku_evaluation_list'>
                        {commentsNotModeratedComponent}
                    </Privilege>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={services.language.getText('commodityEvaluation.statusMap.approved')}
                    key={SHELF_KEYS.BE_APPROVED}
                >
                    {commentsModeratedFilterComponent}
                    <Privilege path='opinion_management.sku_evaluation.sku_evaluation_list'>
                        {commentsModeratedComponent}
                    </Privilege>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={services.language.getText('commodityEvaluation.statusMap.rejected')}
                    key={SHELF_KEYS.FAILED_PASS}
                >
                    {failedToPassFilterComponent}
                    <Privilege path='opinion_management.sku_evaluation.sku_evaluation_list'>
                        {failedToPassComponent}
                    </Privilege>
                </Tabs.TabPane>
            </Tabs>
        );
    };

    onChangeStore = (onlineStores: OptionType[]) => {
        const onlineStoreCodes = onlineStores.map((item) => item.id).join(',');
        this.onlineStoreCodes = onlineStoreCodes;
        this.refreshData();
    };

    refreshData = () => {
        const { activeKey } = this.state;
        const activeEntity = this.activeEntity;
        activeEntity.filtersChange({
            ...activeEntity.filters,
            onlineStoreIds: this.onlineStoreCodes,
            evaluationStatus: activeKey,
        } as any);
        activeEntity.pageChange({ page: 1 });
        activeEntity.search(activeEntity.params);
    };

    render() {
        return (
            <div className='product-shelf-list'>
                <div className='product-shelf-header' style={{ position: 'relative' }}>
                    <StoreTreeSelector
                        checkable={false}
                        selectable={true}
                        onChange={this.onChangeStore}
                    />
                </div>
                <div className='product-shelf-body'>{this.renderTab()}</div>
            </div>
        );
    }
}

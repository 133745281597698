/*
 * @Author: zhulu
 * @Description: 商品规格
 */
import React, { useEffect, useState } from 'react';
import { cloneDeep, map, get, isEmpty, isEqual, concat, set, uniqueId } from 'lodash';
import { Input, Button, Table } from 'antd';
import { modes, formats } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';
import { DESIGN_MANAGE_LANGUAGE_NAMES, DESIGN_MANAGE_LANGUAGE_VALUES } from '@/constants';
import { UploadImageVideo } from '@/components/upload-image-video';

const EditableContext = React.createContext({});

const EditableCell = (props: any) => {
    const {
        editable,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        onChange: changeValue,
        editConfig = {},
        ...restProps
    } = props;

    const [imageUrl, setImageUrl] = useState(
        get(record, `${dataIndex}`) && get(record, `${dataIndex}`) !== 'null'
            ? [
                  {
                      url: get(record, `${dataIndex}`),
                      id: index,
                      video: false,
                  },
              ]
            : []
    );

    const renderCell = (_params: any) => {
        return (
            <td {...restProps}>
                {editable ? (
                    dataIndex === 'url' ? (
                        <UploadImageVideo
                            name={get(record, `${dataIndex}`)}
                            value={imageUrl}
                            showDownLoad={false}
                            uploadType={['image']}
                            maxCount={1}
                            img={{
                                uploadUrl:
                                    ENV.API_ROOT +
                                    '/dc-file/oss/image_locations/1/images?rename=true',
                                fileName: 'fileName',
                                accept: 'image/png,image/jpg,image/svg,image/jpeg',
                                maxSize: 2 * 1024,
                            }}
                            onSuccessFileInfo={(value) => {
                                if (!isEmpty(value)) {
                                    changeValue(index, get(value, 'url', null), dataIndex);
                                    setImageUrl([
                                        {
                                            url: get(value, 'url', null),
                                            id: index,
                                            video: false,
                                        },
                                    ]);
                                } else {
                                    changeValue(index, null, dataIndex);
                                    setImageUrl([]);
                                }
                            }}
                        />
                    ) : (
                        <Input
                            {...editConfig}
                            placeholder={language.getText('defaultPlaceholderInput')}
                            defaultValue={get(record, `${dataIndex}`)}
                            onBlur={(e) => changeValue(index, e.target.value, dataIndex)}
                        />
                    )
                ) : (
                    children
                )}
            </td>
        );
    };

    return <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>;
};
const contentMap = [{ key: 'en' }, { key: 'zh' }, { key: 'tc' }, { key: 'url' }];
const ProductStyleItems = (props: any) => {
    const [dataSource, setDataSource] = useState<any[]>([]);

    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }
        setDataSource(props.value);
        if (!isEqual(props.value, dataSource) && props.value) {
            props.onChange(props.value, props.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const onChange = (index: any, val: any, dataIndex: string) => {
        let { name, value, onChange } = props;
        let nVal = cloneDeep(value);
        if (nVal !== null && !isEmpty(nVal)) {
            //@ts-ignore
            set(nVal, `${index}.${dataIndex}`, val);
        }
        if (onChange) {
            onChange(nVal, name);
        }
    };

    const n: any[] = contentMap.map((item: any) => ({
        title:
            item.key === 'url'
                ? '款式图片'
                : `${language.getText('productSpec.style')}（${
                      DESIGN_MANAGE_LANGUAGE_NAMES[`${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES]
                  }）`,
        dataIndex: item.key === 'url' ? `${item.key}` : `name.${item.key}`,
        editable: true,
        editConfig: {
            maxLength: 100,
        },
    }));

    let columns: any[] = concat(n, [
        {
            title: language.getText('actions'),
            dataIndex: 'actions',
            render: (_text: any, _record: any, index: number) => {
                return (
                    <>
                        <Button
                            type='link'
                            style={{ padding: '0 4px' }}
                            disabled={index === 0}
                            onClick={() => {
                                let nVal: any = cloneDeep(dataSource);
                                let i = nVal[index];
                                nVal.splice(index, 1);
                                nVal.splice(index - 1, 0, i);
                                props.onChange(nVal, props.name);
                            }}
                        >
                            {language.getText('up')}
                        </Button>
                        <Button
                            type='link'
                            style={{ padding: '0 4px' }}
                            disabled={index === dataSource.length - 1}
                            onClick={() => {
                                let nVal: any = cloneDeep(dataSource);
                                let i = nVal[index];
                                nVal.splice(index, 1);
                                nVal.splice(index + 1, 0, i);
                                props.onChange(nVal, props.name);
                            }}
                        >
                            {language.getText('down')}
                        </Button>
                        <Button
                            type='link'
                            style={{ padding: '0 4px' }}
                            onClick={() => {
                                let nVal: any = cloneDeep(dataSource);
                                nVal.splice(index, 1);
                                props.onChange(nVal, props.name);
                            }}
                        >
                            {language.getText('delete')}
                        </Button>
                    </>
                );
            },
        },
    ]).map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any, index: number) => ({
                ...col,
                index,
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editable: col.editable,
                onChange: onChange,
            }),
        };
    });

    return (
        <>
            <EditableContext.Provider value={{}}>
                <Button
                    type='primary'
                    style={{ marginBottom: '8px' }}
                    onClick={() => {
                        let nVal: any = cloneDeep(dataSource) || [];
                        props.onChange(
                            nVal.concat([{ zh: '', en: '', tc: '', url: '', id: uniqueId() }]),
                            props.name
                        );
                    }}
                >
                    {language.getText('productSpec.addStyle')}
                </Button>
                <Table
                    components={{ body: { cell: EditableCell } }}
                    bordered
                    scroll={{ x: 'fit-content' }}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={'id'}
                    pagination={false}
                />
            </EditableContext.Provider>
        </>
    );
};
export class ProductStyleItemsMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
            mode: 'multiple',
        };
        return <ProductStyleItems {...props} />;
    }
}

export class ProductStyleItemsFormat extends formats.ArrayFormat {
    /**
     * 对数据进行校验
     */
    validate(_rule: any, _value: any, callback: any) {
        callback();
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: Array<any> | undefined) {
        const result = map(value, (item, index) => ({
            ...item,
            sort: index,
        }));
        return { [key]: result };
    }
}

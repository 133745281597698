import { createElement, Component } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';

export class ProductMarket extends Component {
    render() {
        const {
            entity,
            componentId,
            onFieldChange,
            onInit,
            renderComponent,
            wrappedComponentRef,
        } = this.props;

        return createElement(ComponentsManager.get(renderComponent), {
            entity,
            wrappedComponentRef,
            componentId,
            className: 'product-market',
            onFieldChange,
            onInit,
            direction: 'horizontal',
            fields: [
                {
                    property: 'componentsEn',
                },
                {
                    property: 'componentsZh',
                },
                {
                    property: 'ingredientsEn',
                },
            ],
            fieldRow: {
                gutter: 128,
            },
            fieldCol: {
                lg: 18,
                md: 21,
                sm: 24,
            },
            footer: {
                style: {
                    display: 'none',
                },
            },
            labelCol: 24,
            symbol: '',
            controlCol: 24,
        });
    }
}

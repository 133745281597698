import React, { Component } from 'react';
import { connect } from 'react-redux';

interface ProductAllAttributesProps {
    fields: any;
}

export class ProductAllAttributesComponent extends Component<ProductAllAttributesProps, any> {
    render() {
        const { fields } = this.props;
        let html: any = getHtml(fields, 0);
        html = html.replace(/\{|\}|\]|\[|"/g, '');
        return (
            <div dangerouslySetInnerHTML={{ __html: html }} style={{ padding: '10px 30px' }}></div>
        );
    }
}
function getHtml(_obj: any, _deep: number) {
    let html = '';
    if (_obj instanceof Number || _obj instanceof String) {
        html += deepHtml(_deep);
        html += _obj;
        html += '<br/>';
    } else if (_obj instanceof Array) {
        for (let o in _obj) {
            if (_obj[o] instanceof Number || _obj[o] instanceof String) {
                html += deepHtml(_deep) + o + ': ' + _obj[o];
            } else if (_obj[o] instanceof Array || _obj[o] instanceof Object) {
                html += getHtml(_obj[o], _deep + 1);
            } else {
                html += deepHtml(_deep) + o + ': ' + _obj[o];
            }
            html += '<br/>';
        }
    } else if (_obj instanceof Object) {
        for (let im in _obj) {
            html += deepHtml(_deep) + im;
            if (_obj[im] instanceof Number || _obj[im] instanceof String) {
                html += ': ' + _obj[im];
            } else if (_obj[im] instanceof Array || _obj[im] instanceof Object) {
                html += '<br/>' + getHtml(_obj[im], _deep + 1);
            } else {
                html += ': ' + _obj[im];
            }
            html += '<br/>';
        }
    } else {
        html += deepHtml(_deep);
        html += _obj;
        html += '<br/>';
    }
    return html;
}
function deepHtml(_deep: number) {
    let html = '';
    for (let i = 0; i < _deep; i++) {
        html += '&nbsp;&nbsp;&nbsp;&nbsp;';
    }
    return html;
}
function mapStateToProps(_state: any, props: any) {
    const { entity } = props;

    return {
        fields: entity.fields,
    };
}
export const ProductAllAttributes = connect(mapStateToProps)(ProductAllAttributesComponent);

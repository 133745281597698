import React from 'react';
import { Tag } from 'antd';
import { Countdown } from '@/components/countdown';
import { services } from '@comall-backend-builder/core';
import { DELIVERY_TYPE_NAMES, DELIVERY_TYPE_VALUES } from '@/constants/order';
import { AFTER_SALE_TYPE_NAMES, AFTER_SALE_TYPE_VALUES } from '@/constants/after-sale';

const getText = services.language.getText;

interface Props {
    header: {
        merchantName: string; //合作商户
        deliveryFlag: DELIVERY_TYPE_VALUES; // 配送类型
        aftermarketNumber: string; //外部售后单号
        aftermarketId: string; //内部售后单号
        memberLabel: string; // 用户标签
        orderRefundNumber: string; //退款单号
    };
    content: {
        status: {
            title: string; //状态名称
            color: string; //颜色
        };
        info: {
            id: string;
            returnGoodsSign: AFTER_SALE_TYPE_VALUES; // 售后类型
            outerOrderNumber: string; //原始订单编号
            parentOrderNumber?: string; //原始父单编号
            customer: string; //申请客户
            receiveAddress: string; // 收货地址
            createTime: string; //申请时间
            refundFailedReason: string; //退款失败原因，
            refundRemark: string; //退款申请原因
            realRefundAmount: string; //实际退款
            refundVoucherAmount: string; //代金券退款
            refundPoint: string; //积分退款
            refundTypeStr: string; //退款方式
        };
    };
    footer: string;
    renderOperational: () => React.ReactElement;
}

export const RefundListItem: React.FC<Props> = (props) => {
    const {
        header,
        content: { status, info },
        footer,
    } = props;

    return (
        <div className='order-item'>
            {/* header部分 */}
            <h2 className='order-head'>
                <span className='header-item'>
                    <span className='bold-head'>
                        {getText('merchant')}：{header.merchantName}
                    </span>
                </span>
                <span className='header-item'>
                    <span className='bold-head'>
                        {getText('deliveryType.title')}：{DELIVERY_TYPE_NAMES[header.deliveryFlag]}
                    </span>
                </span>
                <span className='header-item'>
                    <span className='bold-head'>
                        {getText('afterSale.afterSaleNum')}：{header.aftermarketNumber || '-'}
                    </span>
                </span>
                <span className='header-item'>
                    <span className='bold-head'>
                        {getText('afterSale.refundOrderNum')}：{header.orderRefundNumber || '-'}
                    </span>
                </span>
                <div className='header-item-logo'>
                    <Tag color='green'>{header.memberLabel}</Tag>
                </div>
            </h2>
            {/* content部分 */}
            <div className='order-list'>
                {/* 审核状态 */}
                <div className='order-list-countdown'>
                    <Countdown {...status} needChange={true} />
                </div>
                {/* 订单信息 */}
                <div className='order-list-info'>
                    {/* 原始父单编号 */}
                    <ItemInfo
                        title={getText('afterSale.parentOrderNumber')}
                        value={info.parentOrderNumber}
                    />
                    {/* 原始订单编号 */}
                    <ItemInfo
                        title={getText('afterSale.orderNumber')}
                        value={info.outerOrderNumber}
                    />
                    {/* <div className='order-list-info-item'>
                        <span className='order-list-label'>
                            {getText('afterSale.orderNumber')}:
                        </span>
                        <Privilege path='order.order.view_detail'>
                            {(hasPrivilege) => {
                                if (hasPrivilege) {
                                    return (
                                        <a
                                            href={`#/orders/info/${info.outerOrderNumber}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {info.outerOrderNumber}
                                        </a>
                                    );
                                } else {
                                    return <span>{info.outerOrderNumber}</span>;
                                }
                            }}
                        </Privilege>
                    </div> */}
                    {/* 申请客户 */}
                    <ItemInfo title={getText('afterSale.customer')} value={info.customer} />
                    <ItemInfo title={getText('afterSale.createTime')} value={info.createTime} />
                    <ItemInfo
                        title={getText('afterSale.receiveAddress')}
                        value={info.receiveAddress}
                    />
                    <ItemInfo
                        title={getText('afterSale.afterSaleType.title')}
                        value={
                            AFTER_SALE_TYPE_NAMES[info.returnGoodsSign] ||
                            AFTER_SALE_TYPE_NAMES[AFTER_SALE_TYPE_VALUES.RETURN]
                        }
                    />
                    <ItemInfo
                        key={info.id}
                        value={info.refundRemark}
                        title={getText('afterSale.refundRemark')}
                    />
                    <ItemInfo
                        key={info.id}
                        title={getText('afterSale.refundAmount')}
                        value={`${getText('afterSale.amount')}${info.realRefundAmount} - ${getText(
                            'afterSale.voucherAmount'
                        )}${info.refundVoucherAmount} - ${getText('afterSale.pointAmount')}${
                            info.refundPoint
                        }`}
                    />
                    {/** 退款方式 */}
                    <ItemInfo
                        key={info.id}
                        value={info.refundTypeStr}
                        title={getText('afterSale.refundMethod')}
                    />
                    {/* 异常订单信息 */}
                    <ItemInfo key={info.id} value={info.refundFailedReason} color='red' />
                    <div className='button-wrapper'>{props.renderOperational()}</div>
                </div>
            </div>
            {/* footer部分 */}
            <div className='list-footer' dangerouslySetInnerHTML={{ __html: footer }} />
        </div>
    );
};

const ItemInfo: React.FC<{ title?: string; value?: string; color?: string }> = (props) => {
    return (
        <>
            {props.value && (
                <div className='order-list-info-item'>
                    <span className='order-list-label'>{props.title ? `${props.title}:` : ''}</span>
                    <span style={{ color: props.color }}>{props.value || '-'}</span>
                </div>
            )}
        </>
    );
};

import { formats } from '@comall-backend-builder/types';

export class DeliveryDayFormat extends formats.ObjectFormat {
    validate(_rule: any, value: any, callback: any): void {
        // if (!isInteger(value) && value) {
        //     callback(new Error('请输入0~999999之间的整数'));
        //     return;
        // } else {
        //     callback();
        // }
        callback();
    }
}

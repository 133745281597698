import { cloneDeep } from 'lodash';
import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    tableMixin,
    multipleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
    formFooter,
} from '../mixin';
import { services } from '@comall-backend-builder/core';
import { PRODUCT_LIST_TABLE_ALL } from '@/configs/pro-table-constants';
import { IMPORT_TEMPLATE_URL, PRODUCT_SOURCE_TYPE_OPTIONS } from '@/constants';
import { recordImportLogs } from '@/services';

const entityProperties = {
    pictureUrl: {
        type: 'string.image.text',
        displayName: '<<productList.heroImagePath>>',
    },
    name: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'productList.productName') },
    },
    csku: {
        type: 'string',
        displayName: '<<productList.csku>>',
    },
    barcode: {
        type: 'string',
        displayName: '<<productList.barCode>>',
    },
    brandName: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'productList.brandName') },
    },
    offlineCategoryName: {
        type: 'string.format',
        displayName: '<<productList.saleCategory>>',
    },

    sourceType: {
        type: 'string.options.select',
        displayName: '<<productList.productSource>>',
        options: PRODUCT_SOURCE_TYPE_OPTIONS,
    },
};

const entityFilters = {
    keyword: {
        type: 'string',
        displayName: '<<keyword>>',
    },
    csku: {
        type: 'string',
        displayName: '<<productList.csku>>',
    },
    offlineCategoryId: {
        type: 'string.treeNode.cascader',
        displayName: '<<productList.saleCategory>>',
        options: [],
        source: {
            loadOnInit: false,
            apiPath: '/loader/dc-goods/admin/offline_categories/tree',
            dataType: 'treeNode',
        },
    },
    sourceType: {
        type: 'string.options.select',
        displayName: '<<productList.productSource>>',
        options: PRODUCT_SOURCE_TYPE_OPTIONS,
    },
    brandId: {
        type: 'array.options.autoComplete',
        displayName: '<<productList.brand>>',
        options: [],
        source: {
            apiPath: '/loader/dc-goods/admin/brands/list',
        },
    },
    hasPicture: {
        type: 'string.options.select',
        displayName: '<<productList.hasPicture>>',
        options: [
            { id: 'false', name: services.language.getText('productList.no') },
            { id: 'true', name: services.language.getText('productList.yes') },
        ],
    },
};

const tableConfig = (componentName: string) => {
    return {
        ...tableMixin,
        componentName,
        component: 'ProTable',
        scroll: {
            x: 'max-content',
        },
        columns: [
            {
                property: 'pictureUrl',
                width: 150,
                displayConfig: {
                    style: {
                        width: 50,
                        height: 50,
                        border: '1px solid #eee',
                    },
                },
            },
            { property: 'csku' },
            { property: 'barcode', show: false },
            ...getLanguageColumns('name'),
            ...getLanguageColumns('brandName'),
            {
                property: 'offlineCategoryName',
                displayConfig: { format: (val: any[]) => (val || []).join('/') },
            },
            { property: 'sourceType' },
        ],
        actionColumn: {
            title: '<<actions>>',
            fixed: 'right',
            items: [
                {
                    text: '<<view>>',
                    type: 'component',
                    component: 'TableActionStateful',
                    config: {
                        actionType: 'link',
                        renderConfig: (row: any, props: any) => {
                            return {
                                path: `/productList/seo/info/${row.id}`,
                            };
                        },
                    },
                    privilege: {
                        path: 'goods.goods_seo.view',
                        level: 'full',
                    },
                },
                {
                    text: '<<edit>>',
                    type: 'component',
                    component: 'TableActionStateful',
                    config: {
                        actionType: 'link',
                        renderConfig: (row: any, props: any) => {
                            return {
                                path: `/productList/seo/edit/${row.id}`,
                            };
                        },
                    },
                    privilege: {
                        path: 'goods.goods_seo.edit',
                        level: 'full',
                    },
                },
            ],
        },
    };
};
const getProductComponent = (type: string) => {
    return {
        [`${type}Container`]: {
            component: 'Viewport',
        },
        [`${type}Management`]: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: type,
                    },
                ],
            },
        },
        [`${type}`]: {
            entity: 'seoProductList',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: `${type}Filter`,
                    privilege: {
                        path: 'goods.goods_seo.view_list',
                        level: 'full',
                    },
                },
                {
                    component: `${type}Buttons`,
                    privilege: {
                        path: 'goods.goods_seo.view_list',
                        level: 'full',
                    },
                },
                {
                    component: `${type}Table`,
                    privilege: {
                        path: 'goods.goods_seo.view_list',
                        level: 'full',
                    },
                },
            ],
        },

        [`${type}Filter`]: {
            ...multipleFilterForm,
            labelCol: {},
            className: 'global-flexible-filter-form',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            simple: {
                fieldCol: {
                    span: 8,
                },
                fields: ['keyword', 'offlineCategoryId', 'brandId'],
            },
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<productList.keywordPlaceholder>>',
                    },
                },
                {
                    property: 'csku',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<productList.cskuPlaceHolder>>',
                    },
                },
                {
                    property: 'brandId',
                    controlConfig: {
                        showArrow: true,
                        showSearch: true,
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'offlineCategoryId',
                    controlConfig: {
                        rootId: '-1',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'sourceType',
                    controlConfig: {
                        showSearch: true,
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                // {
                //     property: 'hasPicture',
                //     controlConfig: {
                //         allowClear: true,
                //         placeholder: '<<defaultPlaceholderSelect>>',
                //     },
                // },
            ],
        },
        [`${type}Table`]: tableConfig(PRODUCT_LIST_TABLE_ALL),
        [`${type}Buttons`]: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'Upload',
                    apiPath: '/dc-file/excels?location_id=2',
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<productList.importProductSeo>>',
                        style: {
                            marginRight: 15,
                        },
                    },
                    onUploadComplete(response: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('productSeo.importSuccessTitle'),
                                apiPath: '/dc-business-data/admin/excel/import/SEO',
                                params: {
                                    resourceId: response.id,
                                },
                            });
                        }
                    },
                    privilege: {
                        path: 'goods.goods_seo.sku_seo_import',
                        level: 'full',
                    },
                },
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<productList.exportProductSep>>',
                    icon: 'download',
                    style: {
                        marginRight: 15,
                    },
                    source: {
                        apiPath: '/dc-goods/admin/seomanage/export/take',
                    },
                    range: {
                        apiPath: '/dc-goods/admin/seomanage/count',
                        perPage: 100000,
                    },
                    formatter: (params: any) => {
                        const { brandId, offlineCategoryId, ...rest } = params;
                        if (brandId) {
                            rest.brandId = brandId.map((item: any) => item.id).join(',');
                        }
                        if (offlineCategoryId) {
                            rest.offlineCategoryId = offlineCategoryId.id;
                        }
                        if (!rest.sourceType && type) {
                            rest.sourceType = -1;
                        }
                        return rest;
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'goods.goods_seo.sku_seo_export',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productList.downloadTemplate>>',
                    type: 'link',
                    privilege: {
                        path: 'goods.goods_seo.view_list',
                        level: 'full',
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.SEO}`,
                },
            ],
        },
    };
};

const createForm = (type: 'view' | 'edit') => {
    return [
        {
            property: 'csku',
            modifiable: false,
        },
        { property: 'id', className: 'hidden' },
        { property: 'cskuId', className: 'hidden' },
        { property: 'pageTitleZH', modifiable: type === 'edit' },
        { property: 'pageTitleTC', modifiable: type === 'edit' },
        { property: 'pageTitleEN', modifiable: type === 'edit' },
        { property: 'megaDescriptionZH', modifiable: type === 'edit' },
        { property: 'megaDescriptionTC', modifiable: type === 'edit' },
        { property: 'megaDescriptionEN', modifiable: type === 'edit' },
        { property: 'keywordsZH', modifiable: type === 'edit' },
        { property: 'keywordsTC', modifiable: type === 'edit' },
        { property: 'keywordsEN', modifiable: type === 'edit' },
    ];
};
export const config: Config = {
    entities: {
        seoProductList: {
            apiPath: '/loader/dc-goods/admin/cskus/seo',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
        seoInfo: {
            apiPath: '/loader/dc-goods/productList/seo',
            properties: {
                csku: { type: 'string', displayName: 'SKU' },
                cskuId: { type: 'string' },
                id: { type: 'string' },
                pageTitleZH: {
                    type: 'string',
                    displayName: '<<productSeo.pageTitleZH>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
                pageTitleTC: {
                    type: 'string',
                    displayName: '<<productSeo.pageTitleTC>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
                pageTitleEN: {
                    type: 'string',
                    displayName: '<<productSeo.pageTitleEN>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
                megaDescriptionZH: {
                    type: 'string',
                    displayName: '<<productSeo.megaDescriptionZH>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
                megaDescriptionTC: {
                    type: 'string',
                    displayName: '<<productSeo.megaDescriptionTC>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
                megaDescriptionEN: {
                    type: 'string',
                    displayName: '<<productSeo.megaDescriptionEN>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
                keywordsZH: {
                    type: 'string',
                    displayName: '<<productSeo.keywordsZH>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
                keywordsTC: {
                    type: 'string',
                    displayName: '<<productSeo.keywordsTC>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
                keywordsEN: {
                    type: 'string',
                    displayName: '<<productSeo.keywordsEN>>',
                    controlConfig: {
                        maxLength: 500,
                    },
                },
            },
        },
    },
    components: {
        ...getProductComponent('ProductSEO'),
        ProductSeoInfo: {
            component: 'Card',
            content: {
                component: 'ProductSeoInfoForm',
            },
        },
        ProductSeoInfoForm: {
            component: 'Detail',
            loaderType: 'get',
            entity: 'seoInfo',
            fields: createForm('view'),
        },
        ProductSeoModify: {
            component: 'Card',
            content: {
                component: 'ProductSeoModifyForm',
            },
        },
        ProductSeoModifyForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            entity: 'seoInfo',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            className: 'none-sub-form brand-edit-from',
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: createForm('edit'),
        },
    },
};

import React, { Component, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { KV } from '@/interfaces';
import { StoreTreeSelector } from '@/components/store-tree-selector';
// import { RetailStoreModal } from '@/components/retail-store-modal';
import { Privilege } from '@/components/privilege';
interface Props {
    entities: any;
    entity: Entity;
    filterConfig: KV;
    buttonConfig: KV;
    tableConfig: any;
    params: any;
    apiPath: string;
    storageKey: string;
    storeKey?: string;
}

interface State {
    params: any;
}

export class StoreSelectorContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            params: {},
        };
    }

    onChangeStore = (onlineStores: any[]) => {
        const onlineStoreCodes = onlineStores.map((item) => item.id).join(',');
        if (!onlineStoreCodes) return;
        const { storeKey } = this.props;
        let key = storeKey || 'storeCode';
        this.setState({ params: { [key]: onlineStoreCodes } }, () => {
            this.refreshData();
        });
    };

    /** 刷新table组件 */
    refreshData = () => {
        const { params } = this.state;
        const { entity } = this.props;

        entity.filtersChange({
            ...entity.filters,
            ...params,
        } as any);
        entity.pageChange({ page: 1, perPage: entity?.paging?.perPage || 10 });

        entity.search(entity.filters);
    };

    /** table渲染 */
    renderTable = () => {
        const { filterConfig, buttonConfig, tableConfig, entity } = this.props;
        // 筛选条件渲染
        const filterComponent = createElement(ComponentsManager.get(filterConfig.component), {
            entity,
            ...filterConfig,
        });

        // 操作按钮渲染
        const buttonComponent = createElement(ComponentsManager.get(buttonConfig.component), {
            entity,
            ...buttonConfig,
        });

        // table 渲染
        const tableComponent = createElement(ComponentsManager.get(tableConfig.component), {
            entity,
            ...tableConfig,
        });

        return (
            <div>
                <Privilege {...filterConfig?.privilege}>{filterComponent}</Privilege>
                <Privilege {...buttonConfig?.privilege}>{buttonComponent}</Privilege>
                <Privilege {...tableConfig?.privilege}>{tableComponent}</Privilege>
            </div>
        );
    };

    render() {
        return (
            <div className='product-shelf-list'>
                <div className='product-shelf-header' style={{ position: 'relative' }}>
                    <StoreTreeSelector
                        checkable={false}
                        selectable={true}
                        onChange={this.onChangeStore}
                    />
                </div>
                <div className='product-shelf-body'>{this.renderTable()}</div>
            </div>
        );
    }
}

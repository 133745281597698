import React from 'react';
import { Progress, Descriptions } from 'antd';
import { get } from 'lodash';
import { Link } from '@comall-backend-builder/components-basis';
import { Privilege } from '@/components/privilege';
import { KV } from '@/interfaces';
import { PICK_STATUS_NAMES, DELIVERY_TYPE_NAMES, ORDER_STATUS_VALUES } from '@/constants/order';
import { ORDER_STATUS_PROGRESS_NAMES, PATH_NAME } from '../../enums';
import './item.less';
import { services } from '@comall-backend-builder/core';

/**
 * 拣货小助手中，拣货数据通用组件
 */

interface IPickingCardProps {
    /**
     * 订单数据
     */
    data: KV;
    /**
     * 操作按钮
     */
    actions?: JSX.Element | null;
    type?: 'order' | 'picking';
    /**
     * 自定义渲染内容
     */
    renderContent?: () => JSX.Element;
    /**
     * 跳转path
     */
    pickPathname: string;
    orderPathname: string;
}

export function PickingCard(props: IPickingCardProps) {
    const { data, type, actions, renderContent, pickPathname, orderPathname } = props;

    const list = [
        {
            label: 'picking.parentOrderNumber',
            key: 'parentOrderNumber',
        },
        {
            label: 'picking.outerOrderNumber',
            key: 'outerOrderNumber',
        },
        {
            label: 'picking.orderCustomer',
            key: 'delivery',
            format: (value: any) => {
                const name = [
                    `${get(value, 'orderLastName', '')}${get(value, 'orderFirstName', '')}`,
                    get(value, 'orderMobile', ''),
                    get(value, 'email', ''),
                ].filter((item) => !!item);
                return name.join(' - ');
            },
        },
        {
            label: 'picking.orderCreateTime',
            key: 'orderTime',
        },
        {
            label: 'picking.deliveryAddress',
            key: 'consignee',
            format: (value: any) => {
                const name = [
                    `${get(value, 'city', '')}`,
                    get(value, 'province', ''),
                    get(value, 'area', ''),
                    get(value, 'address', ''),
                ].filter((item) => !!item);
                return name.join('  ');
            },
        },
    ];

    const pickingListNumber =
        type === 'order' ? data?.pickingListVo?.pickingListNumber : data.pickingListNumber;

    return (
        <div className='pick-item' key={get(data, 'id')}>
            <div className='pick-item-header'>
                {/* 拣货单号 */}
                <div className='header-item'>
                    <label>{services.language.getText('picking.pickNumber')}：</label>
                    <Privilege
                        path={
                            pickPathname === PATH_NAME.PICK_PATH_NAME
                                ? 'picking.assistant.view_detail'
                                : 'picking_philips.assistant_philips.view_detail_philips'
                        }
                    >
                        {(hasPrivilege) => {
                            if (hasPrivilege && pickingListNumber) {
                                if (type) {
                                    return (
                                        <Link
                                            url={`#/${pickPathname}/detail/${get(
                                                data,
                                                'pickingListVo.id'
                                            )}`}
                                            text={pickingListNumber}
                                        />
                                    );
                                } else {
                                    return (
                                        <Link
                                            url={`#/${pickPathname}/detail/${get(data, 'id')}`}
                                            text={pickingListNumber}
                                        />
                                    );
                                }
                            } else {
                                return <span>{pickingListNumber || '-'}</span>;
                            }
                        }}
                    </Privilege>
                </div>
                {/* 配送类型 */}
                <div className='header-item'>
                    <label>{services.language.getText('picking.deliveryFlag')}：</label>
                    <span>
                        {get(DELIVERY_TYPE_NAMES, get(data, 'delivery.deliveryType')) || ''}
                    </span>
                </div>
                {/* 预计提货日期 */}
                <div className='header-item'>
                    <label>{services.language.getText('picking.earliestPickUpTime')}：</label>
                    <span>
                        {/* {formatDateTime(
                            get(data, 'preEarliestCanPickUpTime'),
                            'YYYY-MM-DD HH:mm'
                        ) || '-'} */}
                        {get(data, 'preDeliveryTime') || get(data, 'delivery.preCanPickUpTime')}
                    </span>
                </div>
            </div>
            <div className='pick-item-body'>
                <div className='picking-state'>
                    {props.type && props.type === 'order' ? (
                        <Progress
                            type='circle'
                            percent={data.orderStatus === ORDER_STATUS_VALUES.CANCELLED ? 0 : 100}
                            width={100}
                            strokeWidth={5}
                            format={() => get(ORDER_STATUS_PROGRESS_NAMES, data.orderStatus)}
                        />
                    ) : (
                        <Progress
                            type='circle'
                            percent={100}
                            width={100}
                            strokeWidth={5}
                            format={() => get(PICK_STATUS_NAMES, data.status)}
                        />
                    )}
                </div>
                <div className='picking-info'>
                    <Descriptions column={1}>
                        {list.map((item) => {
                            const value = item.format
                                ? item.format(get(data, `${item.key}`))
                                : get(data, `${item.key}`);

                            return (
                                <Descriptions.Item
                                    key={item.label}
                                    label={services.language.getText(item.label)}
                                >
                                    {item.key === 'outerOrderNumber' ? (
                                        <Privilege
                                            path={
                                                orderPathname === PATH_NAME.ORDER_PATH_NAME
                                                    ? 'picking.assistant.order_view_detail'
                                                    : 'picking_philips.assistant_philips.order_view_detail_philips'
                                            }
                                        >
                                            {(hasPrivilege) => {
                                                if (hasPrivilege) {
                                                    return (
                                                        <a
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                            href={`#/${orderPathname}/info/${value}`}
                                                        >
                                                            {value}
                                                        </a>
                                                    );
                                                } else {
                                                    return <span>{value}</span>;
                                                }
                                            }}
                                        </Privilege>
                                    ) : (
                                        value || '-'
                                    )}
                                </Descriptions.Item>
                            );
                        })}
                    </Descriptions>

                    {renderContent && renderContent()}
                </div>
                <div className='picking-actions'>{actions}</div>
            </div>
            <div className='pick-item-footer'>
                <div className='footer-item'>
                    <label>{services.language.getText('picking.sorterName')}: </label>
                    <span>{get(data, 'sorterName')}</span>
                </div>
            </div>
        </div>
    );
}
